import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';

export default class InfoModal extends React.Component {
  render() {
    const {message, hideModal, ...props} = this.props;

    return <ModalContent>{message}</ModalContent>;
  }
}
