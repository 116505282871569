import React from 'react';
import {toFixed} from 'utils/numberUtils.js';

class PageWithSizeIssue extends React.Component {
  render() {
    const {issueData} = this.props;
    const data = issueData.get('size');

    let size = '';
    if (data > 1024 * 1024) {
      size = toFixed(data / (1024 * 1024), 1) + 'MB';
    } else if (data > 1024) {
      size = toFixed(data / 1024, 0) + 'KB';
    } else if (data !== 'undefined') {
      size = data + ' bytes';
    }

    return (
      <div>
        <strong>Total size:</strong> {size}
      </div>
    );
  }
}

export default PageWithSizeIssue;
