import {takeLatest, put} from 'redux-saga/effects';
import {LOGOUT_REQUEST, logoutComplete} from 'modules/auth';
import {logoutEndpoint} from 'modules/auth/authUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {replace} from 'modules/location';
import {couldNot} from 'utils/errorUtils';

export default function* requestLogoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, function*() {
    yield processRequest('POST', logoutEndpoint(), {
      success: function*() {
        yield put(logoutComplete());
        yield put(replace('/login'));
      },
      error: function*() {
        yield put(logoutComplete(couldNot('logout')));
      }
    });
  });
}
