import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import SettingsPage from 'components/settings/settingsPage';
import Spinner from 'components/spinner';
import {trackEvent} from 'modules/tracking';
import Button from 'components/button';
import {addMessage} from 'modules/alerts';
import {fetchReportSchedule, updateReportSchedule} from 'modules/reports';
import {
  reportScheduleSelector,
  reportScheduleLoadingSelector
} from 'modules/reports/reportSelectors';

export class ScheduleConfig extends React.Component {
  static defaultProps = {
    report: {}
  };

  componentDidMount() {
    const {
      params: {reportId}
    } = this.props.match;

    this.props.fetchReportSchedule(reportId);
  }

  handleValidSubmit = ({schedule: {scheduleInterval, schedulePeriod}}) => {
    if (scheduleInterval === 'day' && schedulePeriod < 5) {
      this.props.addMessage('error', 'Schedules cannot be set to lower than 5 days.');
      return;
    }

    const {
      report,
      updateReportSchedule,
      match: {
        params: {reportId}
      }
    } = this.props;

    trackEvent('Updated schedule config', {
      fromFile: 'scheduleConfig.js',
      forReport: reportId
    });

    updateReportSchedule(reportId, {scheduleInterval, schedulePeriod});
  };

  render() {
    const {
      name,
      match: {params}
    } = this.props;
    const {scheduleInterval, schedulePeriod} = this.props.schedule;

    return (
      <SettingsPage name="Schedule" params={params}>
        <Form
          name={name}
          defaultValue={{
            schedule: {scheduleInterval: !scheduleInterval ? '' : scheduleInterval, schedulePeriod}
          }}
          onValidSubmit={this.handleValidSubmit}
        >
          <SettingItem
            title="Website schedule"
            description="How frequently to automatically run a website"
          >
            {this.props.isLoading ? <Spinner /> : <Field type="schedule" name="schedule" />}
          </SettingItem>
          <Button type="submit">Save</Button>
        </Form>
      </SettingsPage>
    );
  }
}

export default connect(
  createStructuredSelector({
    schedule: reportScheduleSelector,
    isLoading: reportScheduleLoadingSelector
  }),
  {addMessage, fetchReportSchedule, updateReportSchedule}
)(ScheduleConfig);
