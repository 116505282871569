import {takeLatest, put} from 'redux-saga/effects';
import {FETCH_USAGE_DATA_FOR, receiveUsageData, usageDataFetchError} from 'modules/services/servicesActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {serviceUsageDataEndpoint} from 'modules/services/serviceUtils';

export default function* requestUsageDataForSaga() {
  yield takeLatest([FETCH_USAGE_DATA_FOR], function*({serviceId, featureId, pageId}) {
    
    const offset = (pageId - 1) * 50;
    yield processRequest('GET', serviceUsageDataEndpoint(serviceId, featureId, offset), {
      successAction: receiveUsageData,
      error: function*(err) {
        const errorMsg = err || "Failed to load usage data.";
        yield put(usageDataFetchError(errorMsg));
      }
    });
  });
}