import cx from 'classnames';
import ExternalLink from 'modules/location/externalLink';
import React from 'react';
import styles from './trademark.scss';

export default function Trademark({className}) {
  const d = new Date();
  return (
    <p className={cx(styles.label, className)}>
      Silktide is a registered trademark of{' '}
      <ExternalLink href="https://silktide.com/">Silktide Ltd</ExternalLink>.<br />
      &copy; Silktide {d.getFullYear()}. All rights reserved.
    </p>
  );
}
