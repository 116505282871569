exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3IszORzFTukVerX-dWO_Sf{display:flex;margin:0 -10px}._2nCyWmEpnVFQQ6sBmoK29D{width:140px;cursor:pointer;padding:15px;background:#fff;margin:10px;text-align:center;box-shadow:0px 2px 4px rgba(0,0,0,.3)}._2nCyWmEpnVFQQ6sBmoK29D>div{margin-top:10px;font-size:15px}._2nCyWmEpnVFQQ6sBmoK29D._2aNDDl-IKWrPISLM2GkOQv{color:#fff;background:#533be2;box-shadow:inset 0px 2px 4px rgba(0,0,0,.3)}._2nCyWmEpnVFQQ6sBmoK29D ._3BnmcLEo8kWMx0dStcGsRY{font-size:60px;opacity:.5}", ""]);

// exports
exports.locals = {
	"container": "_3IszORzFTukVerX-dWO_Sf",
	"option": "_2nCyWmEpnVFQQ6sBmoK29D",
	"active": "_2aNDDl-IKWrPISLM2GkOQv",
	"icon": "_3BnmcLEo8kWMx0dStcGsRY"
};