import {put, take, takeEvery} from 'redux-saga/effects';
import {RETEST_PAGE} from 'modules/reports/reportConstants';
import {RECEIVE_MISSION} from 'modules/missions';
import {retestBegan, setRetestInspectorPage} from '../inspectorActions';

export default function* retestPageFromInspectorSaga() {
  yield takeEvery(RETEST_PAGE, retestPageFromInspector);
}

function* retestPageFromInspector({pageHash, fromInspector}) {
  // We only care when fromInspector is set.
  if (!fromInspector) return;

  yield put(setRetestInspectorPage(true));

  // The next RECEIVE_MISSION event should contain our new mission.
  // TODO: test for race conditions
  const {
    mission: {missionId}
  } = yield take(RECEIVE_MISSION);

  yield put(retestBegan(missionId));
  yield put(setRetestInspectorPage(false));
}
