import PropTypes from 'prop-types';
import React from 'react';
import styles from './sidebarItem.scss';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

class SubMenu extends React.Component {
  static defaultProps = {};

  render() {
    const {children, ...props} = this.props;

    return (
      <div {...filterInvalidDOMProps(props)} className={styles.subMenu}>
        {children}
      </div>
    );
  }
}

export default SubMenu;
