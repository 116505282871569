exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XudmhejzXrPcNVjrHueRl{display:flex;align-items:center}._2XudmhejzXrPcNVjrHueRl img{margin-right:5px}", ""]);

// exports
exports.locals = {
	"searchEngine": "_2XudmhejzXrPcNVjrHueRl"
};