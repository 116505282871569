import React from 'react';
import LessonIcon from '../lesson/lessonIcon';
import {Link} from 'react-router-dom';
import styles from './lessonSummary.scss';
import {courseLessonPath} from 'modules/courses/utils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {showConfirmModal} from 'modules/modal';
import {useDispatch, useSelector} from 'hooks/redux';
import {enrollOnCourse} from 'modules/courses/actions';
import {push} from 'modules/location';
import Icon from 'components/font-awesome';
import Minutes from 'components/minutes';
import Tooltip from 'components/tooltip';
import cx from 'classnames';

export default function LessonSummary({lesson, disabled}) {
  const dispatch = useDispatch();
  const accountId = useSelector(accountIdSelector);
  const durationMinutes = lesson.get('durationMinutes');

  const lessonPath = courseLessonPath({
    accountId,
    courseId: lesson.get('courseId'),
    lessonId: lesson.get('lessonId')
  });

  const path = disabled ? undefined : lessonPath;

  const showModal = () =>
    dispatch(
      showConfirmModal(
        {
          title: 'Enroll on this course?',
          content: 'To take this lesson you must be enrolled first.',
          submitLabel: 'Enroll and continue',
          cancelLabel: 'Cancel',
          destructiveConfirm: true,
          callback: didConfirm => {
            if (didConfirm) {
              dispatch(enrollOnCourse(lesson.get('courseId')));
              dispatch(push(lessonPath));
            }
          }
        },
        'lms'
      )
    );

  const className = cx(styles.lesson, {
    [styles.lessonLink]: true,
    [styles.completed]: lesson.get('state') === 'completed',
    [styles.enabled]: !disabled
  });

  const percentCorrect = lesson.get('percentCorrect');
  const tooltip = disabled ? (
    <span>You need to enrol on this course before you can access this content.</span>
  ) : null;

  const content = (
    <React.Fragment>
      <div className={styles.icon}>
        <LessonIcon lesson={lesson} />
      </div>
      <div className={styles.name}>
        <Tooltip text={tooltip}>
          <span>{lesson.get('name')}</span>
        </Tooltip>
      </div>
      <div className={styles.progress}>
        {!!percentCorrect && (
          <span>
            <Icon name="fas fa-check" className={styles.progressComplete} />{' '}
            {Math.round(percentCorrect)}% correct
          </span>
        )}
      </div>
      <div className={styles.duration}>
        <Icon name="far fa-clock" /> <Minutes minutes={durationMinutes} />
      </div>
    </React.Fragment>
  );

  if (!path) {
    return (
      <div className={className} onClick={showModal}>
        {content}
      </div>
    );
  }

  return (
    <Link to={path} key={lesson.get('lessonId')} className={className}>
      {content}
    </Link>
  );
}
