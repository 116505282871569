import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {SAVE_DIGEST_SUBSCRIPTIONS} from 'modules/digests/constants';
import {digestSubscriptionsEndpoint} from 'modules/digests/utils';
import {
  receiveDigestSubscriptions,
  saveDigestSubscriptionsComplete,
  removeUnsavedDigestSubscriptions
} from 'modules/digests/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* saveDigestSubscriptionsListener() {
  yield takeLatest(SAVE_DIGEST_SUBSCRIPTIONS, saveDigestSubscriptionsSaga);
}

function* saveDigestSubscriptionsSaga({reportId, userId, digestSubscriptions}) {
  const url = digestSubscriptionsEndpoint({reportId, userId});

  yield processRequest(
    'PUT',
    url,
    {
      *success(data) {
        yield put(receiveDigestSubscriptions(userId, data));
        yield put(removeUnsavedDigestSubscriptions(userId));
        yield put(saveDigestSubscriptionsComplete());
      },
      *error(err) {
        yield put(saveDigestSubscriptionsComplete(err));
      },
      errorMessage: couldNot('save digest subscriptions')
    },
    {
      digestSubscriptions
    }
  );
}
