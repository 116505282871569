import {createSelector} from 'reselect';
import {hasActiveSubscriptionsSelector} from 'modules/subscriptions/subscriptionSelectors';
import {accountSelector, isShadowingSelector} from 'modules/auth/authSelectors';

export const isAccountDisabledSelector = createSelector(
  accountSelector,
  hasActiveSubscriptionsSelector,
  isShadowingSelector,
  (account, hasActiveSubs, isShadowing) => {
    return account && !account.get('isActive') && !hasActiveSubs && !isShadowing;
  }
);
