import Helmet from 'components/helmet';
import Spinner from 'components/spinner';
import Status from 'components/status';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import PageWithHeader from 'components/wrappers/pageWithHeader';
import InspectorWrapper from 'modules/inspector/components/inspectorWrapper';
import InspectorBody from 'modules/inspector/containers/inspectorBody';
import InspectorHeader from 'modules/inspector/containers/inspectorHeader';
import InspectorSidebar from 'modules/inspector/containers/inspectorSidebar';
import InspectorSubHeader from 'modules/inspector/containers/inspectorSubHeader';
import {
  fetchInspectorPage,
  selectPoints,
  updateMarkedHtml
} from 'modules/inspector/inspectorActions';
import {inspectorPageSelector} from 'modules/inspector/inspectorSelectors';
import styles from 'modules/inspector/pages/inspectorPage.scss';
import {pageChannel} from 'modules/pusher/channels';
import Pusher from 'modules/pusher/components/pusher';
import React from 'react';
import {connect} from 'react-redux';
import {retestBegan} from '../inspectorActions';

export class InspectorPage extends React.Component {
  componentDidMount() {
    const {fetchInspectorPage, params, query} = this.props;

    fetchInspectorPage(params, query, () => {
      if (query.pointId) {
        this.props.selectPoints([query.pointId]);
      }
    });
  }

  componentDidUpdate() {
    const {updateMarkedHtml, query} = this.props;
    updateMarkedHtml(query.actionId, query.testId);
  }

  renderBody() {
    return (
      <PageWithFixed
        orientation="vertical"
        fixedSize={styles.subheader}
        fixedElement={<InspectorSubHeader />}
      >
        <InspectorBody />
      </PageWithFixed>
    );
  }

  render() {
    const {errorMessage, pageHash, isLoading, hasSidebar} = this.props;

    return (
      <InspectorWrapper>
        <Helmet title="Inspector" />

        {pageHash && (
          <Pusher
            channel={pageChannel({pageHash})}
            event="page-download-start"
            onUpdate={({pageHash: hash, missionId}) => {
              // hash is pretty much guaranteed to be pageHash because of the channel,
              // lets double check it anyway
              if (pageHash === hash) {
                this.props.retestBegan(missionId);
              }
            }}
          />
        )}

        <PageWithHeader header={<InspectorHeader />}>
          {errorMessage ? (
            <Status appearance="bad">{errorMessage}</Status>
          ) : isLoading ? (
            <Spinner marginTop={100} />
          ) : hasSidebar ? (
            <PageWithFixed
              noOverflow
              orientation="horizontal"
              fixedSize={styles.sidebar}
              fixedElement={<InspectorSidebar />}
            >
              {this.renderBody()}
            </PageWithFixed>
          ) : (
            this.renderBody()
          )}
        </PageWithHeader>
      </InspectorWrapper>
    );
  }
}

export default connect(
  inspectorPageSelector,
  {fetchInspectorPage, updateMarkedHtml, retestBegan, selectPoints}
)(InspectorPage);
