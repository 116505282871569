import {Map} from 'immutable';
import {VIEW_ACTION} from 'modules/actions/actions';
import {LEAVE_TEST, VIEW_TEST} from 'modules/tests/testConstants';
import {LEAVE_REPORT_PAGE, VIEW_REPORT_PAGE} from 'modules/reports/reportConstants';
import {pageNotFoundPath} from 'modules/auth/authUtils';

const initialState = Map({
  params: Map({}),
  query: {},
  urlHistory: [],
  lastRequestedUrl: null
});

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REQUESTED_PAGE: {
      if (!action.href) return state.set('lastRequestedUrl', null);

      // Only remember the url if it starts with an accountId AND it's not one of the blacklisted urls
      // I.E we don't want to remember /{accountId}/resetPassword but we do want to remember /{accountId}/reports or /{accountId}/settings etc.
      const [empty, accountId, firstPathPart] = action.href.split('/', 3);

      if (
        empty === '' &&
        /\d+/.test(accountId) &&
        firstPathPart &&
        ![
          'resetPassword',
          'unlockUser',
          'tool-select',
          'account-expired',
          'page-not-found'
        ].includes(firstPathPart)
      ) {
        return state.set('lastRequestedUrl', action.href);
      }
      return state;
    }

    case LEAVE_TEST:
      return state.removeIn(['params', 'testId']);

    case LEAVE_REPORT_PAGE:
      return state.removeIn(['params', 'reportId']);

    case VIEW_REPORT_PAGE:
      return state.setIn(['params', 'reportId'], action.reportId);

    case VIEW_TEST:
      return state
        .setIn(['params', 'testId'], action.testId)
        .setIn(['params', 'tab'], action.tab)
        .removeIn(['params', 'actionId']);

    case VIEW_ACTION:
      return state
        .setIn(['params', 'testId'], action.testId)
        .setIn(['params', 'reportId'], action.reportId)
        .setIn(['params', 'actionId'], action.actionId);

    case SET_LOCATION_PARAM_ITEM:
      const {param, value} = action;

      if (!value) {
        return state.removeIn(['params', param]);
      }

      return state.setIn(['params', param], value);

    case SET_LOCATION_QUERY_PARAMS:
      const {query} = action;
      return state.set('query', query);

    case PUSH_URL_HISTORY:
      const {url} = action;

      let urlHistory = state.get('urlHistory');
      urlHistory = urlHistory.concat([url]);

      if (urlHistory.length > 20) {
        urlHistory = urlHistory.slice(-20);
      }

      return state.set('urlHistory', urlHistory);
  }
  return state;
}

export const PUSH_URL_HISTORY = 'location:PUSH_URL_HISTORY';
export function pushUrlHistory(url) {
  return {type: PUSH_URL_HISTORY, url};
}

export const SET_LOCATION_PARAM_ITEM = 'location:SET_LOCATION_PARAM_ITEM';
export function setLocationParamItem(param, value) {
  return {
    type: SET_LOCATION_PARAM_ITEM,
    param,
    value
  };
}

export const SET_LOCATION_QUERY_PARAMS = 'location:SET_LOCATION_QUERY_PARAMS';
export function setLocationQueryParams(query) {
  return {
    type: SET_LOCATION_QUERY_PARAMS,
    query: query || ''
  };
}

// Underlying function for push/replace.
// TODO please lord fix the fuckery of this function signature
function routeChangeAction(
  type,
  builder,
  params = {},
  paramsToPersist = [],
  persistQuery,
  query,
  event = null
) {
  return {
    type,
    // `builder` can be a function or a string.
    // function which will return a path. I.e. reportPath = ({id}) => `/reports/${id}`
    builder: typeof builder === 'string' ? () => builder : builder,
    params, // params passed into the builder function
    paramsToPersist, // array of string param names to persist from the current location
    persistQuery,
    query,
    event
  };
}

export const ROUTE_PUSH = 'location:ROUTE_PUSH';
export function push() {
  return routeChangeAction(ROUTE_PUSH, ...arguments);
}

export const ROUTE_REPLACE = 'location:ROUTE_REPLACE';
export function replace() {
  return routeChangeAction(ROUTE_REPLACE, ...arguments);
}

// refresh the current route, triggering onEnter etc.
export function refresh() {
  // We whack a timestamp in the querystring to force a route update.
  const timestamp = `t=${Date.now()}`;
  const {pathname, search} = window.location;
  const newSearch = search.length ? `${search}&${timestamp}` : `?${timestamp}`;
  return replace(pathname + newSearch);
}

// redirect to the page not found page
export function handle404() {
  return push(pageNotFoundPath, {}, ['accountId'], false);
}

export const SET_REQUESTED_PAGE = 'location:SET_REQUESTED_PAGE';
export function setLastRequestedPage(href) {
  return {type: SET_REQUESTED_PAGE, href};
}

export function setQuery(query) {
  return push(location.pathname, {}, [], false, query);
}

export function updateQuery(query) {
  return push(location.pathname, {}, [], true, query);
}

export function replaceSetQuery(query) {
  return replace(location.pathname, {}, [], false, query);
}

export function replaceUpdateQuery(query) {
  return replace(location.pathname, {}, [], true, query);
}
