import _ from 'lodash';
import React from 'react';
import Url from 'components/url/url';
import styles from './documentSummary.scss';
import RelativeDate from 'components/time/relativeDate.js';
import Alert from 'components/alert/alert';

export default class DocumentSummary extends React.Component {
  renderUntestedWarning(result) {
    if (result.status < 1) {
      return (
        <Alert level="warning">This document has not been analysed in Silktide for issues.</Alert>
      );
    }

    const reason = this.generateReasonForError(result.status);
    if (reason === null) {
      return null;
    }

    return (
      <Alert level="danger">
        This document failed to be analyzed in Silktide because {reason}.
      </Alert>
    );
  }

  generateReasonForError(errorCode) {
    switch (errorCode) {
      case 404:
        return 'the URL does not exist';
      case 450:
        return 'it is over 10 MB in size';
      case 451:
        return 'an invalid status code was sent from the host';
      case 501:
        return 'an invalid mime type was sent from the host';
      case 502:
        return 'this would exceed your document quota';
      case 503:
        return 'the path is denied from scanning';
      case 600:
        return 'an unknown processing failure occurred';
      case 601:
        return 'the processing timed out after taking longer than 2 minutes';
      default:
        return errorCode;
    }
  }

  render() {
    const {result} = this.props;
    if (!result) return null;

    return (
      <div className={styles.wrapper}>
        <div>
          {(result.status < 1 || result.status > 199) && this.renderUntestedWarning(result)}
        </div>
        <div>
          <p>
            <strong>Name:</strong>
          </p>
          <p className={styles.itemResponse}>{result.documentName || 'No name specified'}</p>
        </div>
        <div className={styles.item}>
          <p>
            <strong>URL:</strong>
          </p>
          <p className={styles.itemResponse}>
            <Url url={result.originalUrl} link={result.originalUrl} />
          </p>
        </div>
        {result.pageCount > 0 && (
          <div className={styles.item}>
            <p>
              <strong>Pages:</strong>
            </p>
            <p className={styles.itemResponse}>{result.pageCount}</p>
          </div>
        )}
        {result.documentSize > 0 && (
          <div className={styles.item}>
            <p>
              <strong>Size (bytes):</strong>
            </p>
            <p className={styles.itemResponse}>{result.documentSize}</p>
          </div>
        )}
        <div className={styles.item}>
          <p>
            <strong>Discovered:</strong>
          </p>
          <p className={styles.itemResponse}>
            <RelativeDate timestamp={result.foundAtTimestamp} />
          </p>
        </div>
        {result.lastModified > 0 && (
          <div className={styles.item}>
            <p>
              <strong>Last modified:</strong>
            </p>
            <p className={styles.itemResponse}>
              <RelativeDate timestamp={result.lastModified} />
            </p>
          </div>
        )}
        {result.pageCount > 0 && (
          <div className={styles.item}>
            <p>
              <strong>Machine readable?</strong>
            </p>
            <p className={styles.itemResponse}>{result.isMachineReadable ? 'Yes' : 'No'}</p>
          </div>
        )}
        {result.pageCount > 0 && (
          <div className={styles.item}>
            <p>
              <strong>Tagged?</strong>
            </p>
            <p className={styles.itemResponse}>{result.isTagged ? 'Yes' : 'No'}</p>
          </div>
        )}
      </div>
    );
  }
}
