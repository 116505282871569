import React from 'react';
import PropTypes from 'prop-types';
import styles from './entitySelect.scss';
import {randomHash} from 'utils/stringUtils';
import Button from 'components/button';

const propTypes = {
  onToggleSelectAll: PropTypes.func.isRequired,
  allChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClickSelectAll: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired
};

const EntitySelectorToolbar = ({
  onToggleSelectAll,
  allChecked,
  disabled,
  buttonText,
  onClickSelectAll,
  showButton
}) => {
  const hash = randomHash();
  return (
    <div className={styles.toolbar}>
      <div className={styles.checkboxOption}>
        <input
          type="checkbox"
          id={'select-all-' + hash}
          onChange={onToggleSelectAll}
          checked={allChecked}
          disabled={disabled}
          className={styles.regularCheckbox}
        />
        <label htmlFor={'select-all-' + hash}></label>
        <span className={styles.selectAll} onClick={onToggleSelectAll}>
          Select all
        </span>
      </div>
      {showButton && (
        <Button className={styles.allButton} disabled={disabled} onClick={onClickSelectAll}>
          {buttonText} selected
        </Button>
      )}
    </div>
  );
};

EntitySelectorToolbar.propTypes = propTypes;

export default EntitySelectorToolbar;
