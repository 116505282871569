import cx from 'classnames';
import Button from 'components/button/button';
import _ from 'lodash';
import {Field, Form} from 'modules/form/components';
import {getSubConfig} from 'modules/quote/quoteUtils';
import {editorConfig, executiveConfig, marketerConfig} from 'modules/superProduct/constants';
import {BT, Ebay, Hibu, SantanderFull} from 'modules/superProduct/images';
import {requestQuotes} from 'modules/superProduct/superProductActions';
import {pricingCalculatorSelector} from 'modules/superProduct/superProductSelectors';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {formatGivenNumber} from 'utils/numberUtils';
import CardsForCalculator from './cardsForCalculator';
import styles from './pricingCalculator.scss';

export class PricingCalculator extends React.Component {
  state = {
    // Snapshot
    numReportsSlider: 100,
    numPagesPerReportSlider: 100,

    // Monitor
    numPagesSlider: 100,
    numDocumentsSlider: 0,
    numKeywordsSlider: 100,

    planId: null,
    planIndex: null,

    hasMarketing: false,
    showOptional: false,
    annual: false
  };

  handleSubmit = (values, isValid) => {
    const {planId, planIndex} = this.state;

    if (isValid && planId) {
      let data = {...values};

      const submit = () => {
        this.props.onSelectBuy(data, planId, planIndex);
      };

      switch (planIndex) {
        case 0:
          data = {...data, ...editorConfig};
          submit();
          return;
        case 1:
          data = {...data, ...marketerConfig};
          submit();
          return;
        case 2:
          data = {...data, ...executiveConfig};
          submit();
          return;
      }
    }
  };

  componentDidMount() {
    this.debounceGetQuote();
  }

  debounceGetQuote = _.debounce(() => {
    this.getQuote();
  }, 300);

  getQuote = () => {
    const {product} = this.props;

    const formData = this.getFormData();

    if (!formData) {
      return;
    }

    this.props.requestQuotes(product.productId, getSubConfig(formData));
  };

  getFormData = () => {
    if (!this.form) {
      return null;
    }

    return this.form.state.formData;
  };

  handleFormChange = () => {
    this.debounceGetQuote();
  };

  getDefaults() {
    if (!this.isMonitor()) {
      return {
        numPagesPerReport: 100,
        numReports: 100
      };
    }

    return {
      hasMarketing: false,
      hasQuality: true,
      hasAccessibility: false,
      numPages: 100,
      supportLevel: 10,
      hasPrivacy: false,
      numKeywords: 100,
      numDocuments: 0,
      numUptimeMonitors: 0
    };
  }

  getNumberOfPages = () =>
    formatGivenNumber(this.state.numPagesSlider) + (this.state.numPagesSlider >= 500 ? '+' : '');

  toggleOptionalFields = () => {
    const {showOptional} = this.state;
    this.setState({showOptional: !showOptional});
  };

  renderOptionalFieldToggler = () => {
    const {showOptional} = this.state;
    const text = showOptional ? 'Hide all options' : 'View all options';

    return (
      <Button
        plain
        ariaLabel={text}
        onClick={this.toggleOptionalFields}
        className={styles.toggleButton}
      >
        {text}
      </Button>
    );
  };

  renderMonitorOptionalFields = () => {
    const {showOptional, hasMarketing} = this.state;
    if (!showOptional) {
      return null;
    }
    return (
      <div>
        {hasMarketing && (
          <Fragment>
            <hr />

            <div className={styles.questionWrapper}>
              <div className={cx(styles.questionHeader, styles.optionalHeader)}>
                <p>Number of keywords to monitor</p>
              </div>

              <div className={styles.sliderWrapper}>
                <Field
                  name="numKeywords"
                  type="slider"
                  min={100}
                  max={1000}
                  step={100}
                  onChange={val => this.setState({numKeywordsSlider: val})}
                />
                <div className={styles.optionalCount}>
                  <div className={cx(styles.learnMoreBtn, styles.learnMoreCentered)}>
                    <div className={styles.sliderNumber}>
                      {formatGivenNumber(this.state.numKeywordsSlider)}
                    </div>
                    <Button
                      onClick={() => this.props.onSelectHelp('keywords')}
                      plain
                      ariaLabel="Learn about number of keywords"
                    >
                      Learn more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <hr />
        <div className={styles.questionWrapper}>
          <div className={cx(styles.questionHeader, styles.optionalHeader)}>
            <p>Number of PDFs to monitor</p>
          </div>

          <div className={styles.sliderWrapper}>
            <Field
              name="numDocuments"
              type="slider"
              min={0}
              max={10000}
              step={1000}
              onChange={val => this.setState({numDocumentsSlider: val})}
            />
            <div className={styles.optionalCount}>
              <div className={cx(styles.learnMoreBtn, styles.learnMoreCentered)}>
                <div className={styles.sliderNumber}>
                  {formatGivenNumber(this.state.numDocumentsSlider)}
                </div>
                <Button
                  onClick={() => this.props.onSelectHelp('documents')}
                  plain
                  ariaLabel="Learn about number of PDFs"
                >
                  Learn more
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMonitorFields() {
    return (
      <div className={styles.flexColumn}>
        <div className={styles.questionWrapper}>
          <div className={styles.questionHeader}>
            <p>How many webpages do you have?</p>
          </div>

          <div className={styles.sliderWrapper}>
            <Field
              name="numPages"
              type="slider"
              min={100}
              max={500}
              step={100}
              onChange={val => this.setState({numPagesSlider: val})}
            />
            <div className={styles.tooltip}>{this.state.numPagesSlider} pages</div>
          </div>
        </div>
        {this.renderMonitorOptionalFields()}
      </div>
    );
  }

  renderSnapshotFields() {
    return (
      <div>
        <div className={styles.questionWrapper}>
          <p className={styles.questionHeader}>How many reports do you require?</p>

          <div className={styles.sliderWrapper}>
            <Field
              name="numReports"
              type="slider"
              min={10}
              max={500}
              step={10}
              onChange={val => this.setState({numReportsSlider: val})}
            />
            <div className={styles.sliderNumber}>
              {formatGivenNumber(this.state.numReportsSlider)}
            </div>
          </div>
        </div>

        <div className={styles.questionWrapper}>
          <p className={styles.questionHeader}>How many pages per report do you require?</p>

          <div className={styles.sliderWrapper}>
            <Field
              name="numPagesPerReport"
              type="slider"
              min={100}
              max={250}
              step={50}
              onChange={val => this.setState({numPagesPerReportSlider: val})}
            />
            <div className={styles.sliderNumber}>
              {formatGivenNumber(this.state.numPagesPerReportSlider)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  isMonitor = () => {
    const {product} = this.props;
    return (
      product.productTypeId === 'WebsiteMonitor3' || product.productTypeId === 'WebsiteMonitor'
    );
  };

  selectPlan = (planId, planIndex) => {
    this.setState({planId, planIndex}, () => {
      this.form.submit();
    });
  };

  render() {
    const {product} = this.props;

    if (!product) {
      return null;
    }

    return (
      <div>
        <div className={styles.formWrapper}>
          <Form
            className={styles.form}
            autoComplete="off"
            defaultValue={this.getDefaults()}
            ref={input => (this.form = input)}
            onSubmit={this.handleSubmit}
            onChange={field => {
              this.handleFormChange();
            }}
            disableValidSubmit
          >
            <div className={styles.fieldCentralWrapper}>
              {this.isMonitor() && this.renderMonitorFields()}
              {!this.isMonitor() && this.renderSnapshotFields()}
            </div>
          </Form>
        </div>

        <CardsForCalculator
          {...this.props}
          numPages={this.state.numPagesSlider}
          isAnnual={this.state.annual}
          onSelectBuy={this.selectPlan}
          onToggleChange={() => {
            this.setState({annual: !this.state.annual});
          }}
        />

        <p className={styles.vat}>
          Prices in the EU exclude applicable VAT. This will be calculated when you checkout.
        </p>

        <div className={styles.logoStrip}>
          <img src={BT} alt="BT logo" />
          <img src={SantanderFull} alt="Santander logo" />
          <img src={Hibu} alt="Hibu logo" />
          <img src={Ebay} alt="Ebay logo" />
        </div>
      </div>
    );
  }
}

export default connect(pricingCalculatorSelector, {requestQuotes})(PricingCalculator);
