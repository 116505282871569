import {delay} from 'redux-saga';
import {select} from 'redux-saga/effects';
import {foldersEndpoint} from 'modules/folders/folderUtils';
import {receiveFolders} from 'modules/folders';
import {processRequest} from 'utils/saga/fetchUtils';

export function* fetchFoldersIfNeeded() {
  // don't fetch if already have
  const folders = yield select(({folders}) => folders && folders.toJS());
  if (folders && folders.length) {
    return;
  }

  yield processRequest('GET', foldersEndpoint(), {
    successAction: receiveFolders,
    success: function* success() {
      // Only allow load once per 60 seconds
      yield delay(60000);
    }
  });
}
