import {take, select, cancel, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {copyReportToFolder, moveReportToFolder} from 'modules/reports';
import {REPORT_DROP} from 'modules/reports/reportConstants';
export default function* reportDropSaga() {
  yield takeLatest(REPORT_DROP, reportDrop);
}

function* reportDrop({reportId, fromFolder, didDrop, dropResult}) {
  const {folderId: toFolder} = dropResult;
  let {dropEffect} = dropResult;

  // Dragging from 'all websites' folder has to be a copy
  if (fromFolder == 0 || !fromFolder) {
    dropEffect = 'copy';
  }

  if (!didDrop || !dropResult || !toFolder) {
    // we dropped it on an invalid component
    return;
  }

  if (dropEffect == 'copy') {
    yield put(copyReportToFolder(reportId, toFolder));
  }

  if (dropEffect == 'move') {
    yield put(moveReportToFolder(reportId, fromFolder, toFolder));
  }
}
