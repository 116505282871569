import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {requestAssignedTagsComplete} from 'modules/tags/actions';
import {REQUEST_ASSIGNED_TAGS} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';
import {tagsChanged} from '../utils';

export default function* requestAssignedTagsListener() {
  yield takeLatest(REQUEST_ASSIGNED_TAGS, requestAssignedTags);
}

function* requestAssignedTags({entityType, entityId}) {
  try {
    const assignedTags = yield processRequest('GET', `assigned-tags/${entityType}/${entityId}`, {});
    yield put(requestAssignedTagsComplete(null));

    yield call(
      tagsChanged,
      entityType,
      entityId,
      assignedTags.map(entityTag => entityTag.tagId),
      'set'
    );
  } catch (err) {
    yield put(requestAssignedTagsComplete(err));
  }
}
