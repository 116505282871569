import React, {Fragment, useEffect, useState} from 'react';
import posed, {PoseGroup} from 'react-pose';
import CompleteLessonButton from 'modules/courses/components/buttons/completeLessonButton';
import QuizQuestion from './quizQuestion';
import styles from './quiz.scss';
import Button from 'components/button';
import Icon from 'components/font-awesome';
import Alert from 'components/alert/alert';
import Spinner from 'components/spinner';
import _ from 'lodash';

const imageBad = require('./bad.gif');
const imageGood = require('./good.gif');

// const Grow = posed.div({
//   enter: {
//     delay: 300,
//     opacity: 1,
//     scale: 1.2,
//     transition: {
//       y: {type: 'spring', stiffness: 1000, damping: 15},
//       default: {duration: 300}
//     }
//   },
//   exit: {opacity: 0}
// });

function Quiz({lesson, questions, minPercent, submittedAnswers, onCompleteQuiz, savingQuiz}) {
  if (!questions) return null;

  const [animate, setAnimate] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [quizComplete, setQuizComplete] = useState(false);
  const [scrolledToAnswers, setScrolledToAnswers] = useState(false);

  const defaultAnswerState = Object.values(questions).map(({questionId, answer}) => {
    let defaultAnswer;
    if (lesson && !quizComplete) {
      const answers = lesson.get('answers').toJS();
      const [currentQuestion] = answers.filter(({questionId: id}) => id === questionId);
      defaultAnswer = currentQuestion ? currentQuestion.answer : [];
    }

    return {
      questionId,
      correctAnswers: answer,
      answer: defaultAnswer || []
    };
  });

  const [answers, setAnswers] = useState(defaultAnswerState);

  const getPercentage = score => Math.floor((score / questions.length) * 100);
  const percentage = getPercentage(submittedAnswers.filter(({isCorrect}) => isCorrect).length);

  const passed = percentage && percentage >= minPercent;

  // @TODO integrate into own component
  const explainResults = () => {
    if (percentage == 100) {
      return <span>Perfect! You answered everything correctly.</span>;
    }
    if (passed) {
      return <span>Well done! You passed with {percentage}% correct.</span>;
    }
    if (percentage > 0) {
      return <span>Sorry, you didn't pass this time. You got {percentage}% correct.</span>;
    }

    return <span>Sorry, you didn't pass this time. You didn't get any correct.</span>;
  };

  // @TODO SPLIT INTO OWN COMPONENT
  const renderResultsAnimation = () => {
    return passed ? (
      <img src={imageGood} alt="Passing result" />
    ) : (
      <img src={imageBad} alt="Failing result" />
    );
  };

  const handleSubmit = () => {
    setQuizComplete(true);

    const pass = percentage >= minPercent;
    const completedLesson = lesson.set('answers', answers);

    if (pass) {
      onCompleteQuiz(completedLesson.set('state', 'completed'));
    } else {
      onCompleteQuiz(completedLesson.set('state', 'completed'));
    }
  };

  const handleChangeAnswer = (i, answer) => {
    const newAnswers = [...answers];
    newAnswers[i].answer = answer;
    setAnswers(newAnswers);
  };

  const getAnswer = (answers, id) => {
    return answers.filter(({questionId}) => questionId === id)[0].answer || [];
  };

  const showFeedback = !savingQuiz && quizComplete && submittedAnswers;

  // scroll down the page if the quiz is complete and answers are rendered
  useEffect(() => {
    const buttons = document.getElementById('buttons');
    // scroll to bottom
    if (!scrolledToAnswers && showFeedback && buttons && buttons.scrollIntoView) {
      buttons.scrollIntoView({behaviour: 'smooth', block: 'end'});
      setScrolledToAnswers(true);
    }
  });

  const renderSubmit = () => (
    <div className={styles.buttons}>
      <Button onClick={handleSubmit}>Submit answers</Button>
    </div>
  );

  const shouldAnimate = (nextProps, currentProps) => {
    return nextProps.animate && !currentProps.animate;
  };

  const renderButton = () => {
    // if (!animate) {
    //   setTimeout(() => setAnimate(true), 0);
    //   return null;
    // }
    return <CompleteLessonButton lesson={lesson} />;
  };

  return (
    <div id="questions">
      {quizComplete && !savingQuiz && (
        <div className={styles.results} id="buttons">
          {renderResultsAnimation()}
          <h3>{explainResults()}</h3>
          {!passed && <p>Please try again:</p>}
          {!!passed && <p>Press this button to continue:</p>}
          {!!passed && renderButton()}
        </div>
      )}
      {questions.map((question, i) => {
        let hintText;

        const answerSubmitted = submittedAnswers[i] && submittedAnswers[i].answer.length > 0;

        if (submittedAnswers.length && submittedAnswers[i] && submittedAnswers[i].hint) {
          hintText = submittedAnswers.length && submittedAnswers[i].hint;
        }

        return (
          <Fragment key={question.question}>
            <QuizQuestion
              {...question}
              index={i}
              onChange={handleChangeAnswer}
              answered={answered[i] && answered[i].isCorrect !== null}
              answer={getAnswer(answers, question.questionId)}
            />
            {!answerSubmitted && showFeedback && (
              <div className={styles.incorrect}>
                <Icon name="fa fa-times" /> <b>Please select an answer!</b>
              </div>
            )}

            {/* todo check for saving state */}
            {answerSubmitted && showFeedback && submittedAnswers[i].isCorrect && (
              <div className={styles.correct}>
                <Icon name="fa fa-check" /> <b>Correct!</b>
              </div>
            )}
            {answerSubmitted && showFeedback && !submittedAnswers[i].isCorrect && (
              <div className={styles.incorrect}>
                <Icon name="fa fa-times" /> <b>{hintText ? hintText : 'Incorrect'}</b>
              </div>
            )}
          </Fragment>
        );
      })}

      <div className={styles.buttons}>
        {savingQuiz && <Spinner />}
        {!savingQuiz && renderSubmit()}
      </div>
    </div>
  );
}

export default Quiz;
