import PropTypes from 'prop-types';
import React from 'react';
import ProgressBar from 'components/progress';

class Progress extends React.Component {
  render() {
    const {record} = this.props;

    return (
      <ProgressBar
        value={record.progressDone}
        denominator={record.progressTotal}
        small
        label="right"
      />
    );
  }
}

Progress.propTypes = {};

export default Progress;
