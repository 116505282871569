import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import FolderViewModal from 'modules/folderViews/components/folderViewModal';
import {TRIGGER_NEW_FOLDER_VIEW, SAVE_FOLDER_VIEW, receiveFolderView} from 'modules/folderViews';
import {folderViewsEndpoint, folderViewPath} from 'modules/folderViews/folderViewUtils';
import {push} from 'modules/location';
import {processRequest} from 'utils/saga/fetchUtils';
import {showInModal} from 'modules/modal';
import {HIDE_MODAL} from 'modules/modal';
import {statsSelector} from 'modules/stats/statSelectors';
import {toJS, keyBy} from 'utils/immutableUtils';

export default function* addFolderViewSaga() {
  yield takeLatest([TRIGGER_NEW_FOLDER_VIEW], addFolderView);
}

function* addFolderView(action) {
  const {folderId} = action;

  yield put(showInModal(FolderViewModal, {name: 'addFolderViewModal'}));

  // TODO: this should be broken into 2 sagas.
  // One to show the modal, the other to save the data.
  const {type, folderView} = yield take([SAVE_FOLDER_VIEW, HIDE_MODAL]);

  if (type === HIDE_MODAL) {
    return null;
  }

  const stats = toJS(yield select(statsSelector));
  const keyedStats = toJS(keyBy(stats, 'name'));

  // columns are in string format at wheatley is expecting objects
  const columns = folderView.columns.map(name => keyedStats[name]).filter(Boolean);
  const postData = {folderId, ...folderView, columns};

  yield processRequest(
    'POST',
    folderViewsEndpoint(action),
    {
      successAction: receiveFolderView,
      success: function*({viewId}) {
        // redirect to the new folder view - now that it exists
        yield put(push(folderViewPath, {folderId, viewId}));
      },
      errorMessage: 'Failed to add folder view'
    },
    postData
  );
}
