import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {REQUEST_DELETE_ACCOUNT, deleteAccount} from 'modules/accounts';
import {accountsEndpoint, accountEndpoint} from 'modules/accounts/accountUtils';
import {put} from 'redux-saga/effects';

export default generateDeleteSaga('account', REQUEST_DELETE_ACCOUNT, accountEndpoint, {
  success: function*({accountId}) {
    yield put(deleteAccount(accountId));
  }
});
