import Button from 'components/button';
import Gandalf from 'components/gandalf/gandalf';
import {SITE_CREATOR} from 'modules/auth/gandalf';
import {requestDeleteLeaderboard} from 'modules/leaderboards/actions';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

function DeleteLeaderboardButton({children, ...props}) {
  return (
    <Gandalf shallPass={SITE_CREATOR}>
      <Button cta {...props}>
        {children || 'Delete leaderboard'}
      </Button>
    </Gandalf>
  );
}

DeleteLeaderboardButton.propTypes = {
  leaderboardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default connect(
  null,
  (dispatch, {leaderboardId}) => ({
    onClick: event => {
      dispatch(requestDeleteLeaderboard(leaderboardId));
    }
  })
)(DeleteLeaderboardButton);
