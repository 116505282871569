import React from 'react';
import {connect} from 'react-redux';
import {receiveExtraInfo} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import Alert from 'components/alert';

export class AuthCredentialsModal extends React.Component {
  handleValidSubmit = config => {
    this.props.receiveExtraInfo(config);
  };

  renderBefore() {
    const {level, message} = this.props;
    return (
      <div>
        <p style={{marginBottom: 20}}>
          Please enter the username and password used to view this website.
        </p>

        <p style={{marginBottom: 30}}>
          Note: This is <em>not</em> your Silktide username and password.
        </p>
        <Alert level={level}>{message}</Alert>
      </div>
    );
  }

  render() {
    return (
      <ModalForm
        submitLabel="Save"
        title="The website you're trying to test requires a login"
        beforeForm={this.renderBefore()}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field type="text" name="username" label="Username" autoFocus />
        <Field
          type="password"
          name="password"
          label="Password"
          autoComplete="new-password" // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        />
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {receiveExtraInfo}
)(AuthCredentialsModal);
