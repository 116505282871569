import {takeEvery, takeLatest, take, select, put} from 'redux-saga/effects';
import {OPEN_EDIT_USER_ALERTS_MODAL} from 'modules/alertSubscriptions/constants';
import {showInModal} from 'modules/modal';
import EditUserAlertsModal from 'modules/alertSubscriptions/containers/editUserAlertsModal';

export default function* openEditUserAlertsModalSaga() {
  yield takeLatest(OPEN_EDIT_USER_ALERTS_MODAL, openEditUserAlertsModal);
}

function* openEditUserAlertsModal({userId}) {
  yield put(showInModal(EditUserAlertsModal, {userId}));
}
