import _ from 'lodash';
import React from 'react';
import {trackEvent} from 'modules/tracking';
import Button from 'components/button';
import SettingItem from 'components/settings/settingItem';
import {Form, Field} from 'modules/form/components';
import Flex from 'components/layout/flex';

function shouldShowUser(user, reportPermissions) {
  return !reportPermissions.has(user.userId + '');
}

class AddUserToPermissionGroupForm extends React.Component {
  handleValidSubmit = data => {
    const usersValue = data.user;
    const rolesValue = data.role;

    if (usersValue === 'noUsers' || !usersValue) {
      return;
    }

    trackEvent('Added user to report permission', {
      forReport: this.props.reportId,
      userAdded: usersValue,
      role: rolesValue,
      fromFile: 'addUserToPermissionGroupForm.js'
    });

    this.props.setRole(this.props.reportId, usersValue, rolesValue);
  };

  getOptionsForUsers = () => {
    const {users, reportPermissions} = this.props;

    return _.reduce(
      users,
      (result, user) => {
        if (shouldShowUser(user, reportPermissions)) {
          result.push({
            label: user.name,
            value: user.userId
          });
        }

        return result;
      },
      []
    );
  };

  render() {
    const usersOptions = this.getOptionsForUsers();

    if (usersOptions.length === 0) {
      return null;
    }

    return (
      <Form onValidSubmit={this.handleValidSubmit}>
        <SettingItem
          title="Add user"
          description="Choose someone new to give access to this website"
        >
          <Flex container alignTop>
            <Flex grow="3" style={{marginRight: '10px'}}>
              <Field
                type="select"
                name="user"
                options={usersOptions}
                required
                style={{margin: 0}}
              />
            </Flex>
            <Flex grow="2" style={{marginRight: '10px'}}>
              <Field type="role" name="role" useReportRoles required />
            </Flex>
            <Flex grow="1">
              <Button type="submit">Add</Button>
            </Flex>
          </Flex>
        </SettingItem>
      </Form>
    );
  }
}

export default AddUserToPermissionGroupForm;
