import React from 'react';
import {connect} from 'react-redux';
import {ModalForm, Field} from 'modules/form/components';
import {retestReport} from 'modules/reports';
import reportProductStyles from 'modules/reports/components/reportProduct/reportProduct.scss';
import cx from 'classnames';

export class RetestModal extends React.Component {
  static defaultProps = {
    defaultValue: {
      subject: 'report'
    }
  };

  handleSubmit = ({urls}) => {
    const {reportId, retestReport, hideModal} = this.props;

    retestReport(reportId, urlStrToArr(urls));
    hideModal();
  };

  render() {
    const {name, usesUp, remaining, canRetest, canRetestPages, defaultValue} = this.props;

    const usesUpMessage =
      usesUp && remaining ? (
        <span className={cx(reportProductStyles.remaining, reportProductStyles.positive)}>
          ({remaining} left)
        </span>
      ) : (
        ''
      );

    let subjectOptions = [];

    if (canRetest) {
      subjectOptions.push({
        label: <span>Whole site {usesUpMessage}</span>,
        value: 'report'
      });
    }

    if (canRetestPages) {
      subjectOptions.push({
        label: 'Specific pages',
        value: 'urls'
      });
    }

    return (
      <ModalForm
        name={name}
        title="Retest this website"
        defaultValue={defaultValue}
        onValidSubmit={this.handleSubmit}
        submitLabel="Retest"
      >
        <Field type="choice" name="subject" options={subjectOptions} />
        <Field label="Pages" type="textarea" name="urls" showIf={data => data.subject === 'urls'} />
      </ModalForm>
    );
  }
}

function urlStrToArr(urlStr) {
  if (typeof urlStr !== 'string') {
    return [];
  }

  return urlStr.split(/[\r\n]/);
}

export default connect(
  null,
  {retestReport}
)(RetestModal);
