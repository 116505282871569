exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._304mlwc5FiytfcwN_GT5uX{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}", ""]);

// exports
exports.locals = {
	"title": "_304mlwc5FiytfcwN_GT5uX"
};