exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Hye9Tj4B-1dycuGfthZ9g{margin-top:0}._3P6yqLSfM6ZkkizgbRpHvb{padding:0 5px;padding-bottom:20px}", ""]);

// exports
exports.locals = {
	"page": "_1Hye9Tj4B-1dycuGfthZ9g",
	"helpText": "_3P6yqLSfM6ZkkizgbRpHvb"
};