exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._14DPK44q1_v3RV2_-m_L5Y{position:relative;width:60px;height:60px;text-align:left;margin:0 auto;display:block}._14DPK44q1_v3RV2_-m_L5Y:after{border-right:4px solid rgba(0,0,0,.35);border-top:4px solid rgba(0,0,0,.35);border-left:4px solid rgba(0,0,0,0);border-bottom:4px solid rgba(0,0,0,0);opacity:1;z-index:2;content:\"\";height:100%;width:100%;position:absolute;margin:0;transition:all .75s ease 0s;transition:all .75s ease 0s;border-radius:100%;-webkit-transform-origin:center;transform-origin:center;transform-origin:center}._1VvK-36gLjLrL9MF6tABJQ:after{border-right:4px solid rgba(255,255,255,.5);border-top:4px solid rgba(255,255,255,.5);border-left:4px solid rgba(255,255,255,0);border-bottom:4px solid rgba(255,255,255,0)}.ZA5rZMnlUC-AE2xw7E59o{width:20px;height:20px}._3uS95J0570vOJQN4lMcTKn{width:50px;height:50px}._2t8a3E1R_cMfNSdNp0pVrK{margin-left:50%;margin-left:calc(50% - (60px / 2))}.AmU38KD9KF7Iepcs5PC9h{display:inline-block;vertical-align:middle;margin-left:6px}", ""]);

// exports
exports.locals = {
	"csSpinner": "_14DPK44q1_v3RV2_-m_L5Y",
	"light": "_1VvK-36gLjLrL9MF6tABJQ",
	"small": "ZA5rZMnlUC-AE2xw7E59o",
	"large": "_3uS95J0570vOJQN4lMcTKn",
	"centered": "_2t8a3E1R_cMfNSdNp0pVrK",
	"relative": "AmU38KD9KF7Iepcs5PC9h"
};