import {put, select, takeEvery} from 'redux-saga/effects';
import {UPDATE_REPORT_SCHEDULE} from 'modules/reports/reportConstants';
import {updateReportScheduleValue} from 'modules/reports';
import {processRequest} from 'utils/saga/fetchUtils';
import {reportScheduleSelector} from '../reportSelectors';
import {showError, showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

export default function* updateReportScheduleSaga() {
  yield takeEvery(UPDATE_REPORT_SCHEDULE, updateReportSchedule);
}

const methodNiceName = {
  PUT: 'saved',
  POST: 'created',
  DELETED: 'deleted'
};

function* updateReportSchedule({reportId, scheduleInterval, schedulePeriod}) {
  const options = {};
  const url = 'report-schedules/' + reportId;
  const schedule = yield select(state => reportScheduleSelector(state, {reportId}));

  if (!schedule) return;

  const oldInterval = !schedule.scheduleInterval ? '' : schedule.scheduleInterval;

  let meth;
  if (oldInterval === '' && scheduleInterval !== '') {
    meth = 'POST';
  } else if (oldInterval !== '' && scheduleInterval === '') {
    meth = 'DELETE';
  } else {
    meth = 'PUT';
  }

  try {
    const response = yield processRequest(meth, url, options, {scheduleInterval, schedulePeriod});

    if (meth === 'DELETE') {
      yield put(updateReportScheduleValue(reportId, {}));
    } else {
      yield put(updateReportScheduleValue(reportId, response));
    }

    yield showSuccess('Website schedule ' + methodNiceName[meth]);
  } catch (err) {
    console.log(err);
    yield showError(couldNot('save the reports new schedule'));
  }
}
