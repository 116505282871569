import React from 'react';
import styles from './title.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'hooks/redux';
import {coursePath, coursesPath} from 'modules/courses/utils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import Icon from 'components/font-awesome';
import Minutes from 'components/minutes';

export default function LessonTitle({course, lesson}) {
  const name = lesson.get('name');
  const durationMinutes = lesson.get('durationMinutes');
  const accountId = useSelector(accountIdSelector);

  return (
    <div className={styles.title}>
      <div className={styles.left}>
        <div className={styles.breadcrumb}>
          <Icon name="fas fa-chevron-left" />
          <Link to={coursePath({accountId, courseId: lesson.get('courseId')})}>
            Back to {course.get('name')}
          </Link>
        </div>
        <h1>{name}</h1>
      </div>
      <div>
        <Icon name="far fa-clock" /> <Minutes minutes={durationMinutes} />
      </div>
    </div>
  );
}
