exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2pyJPGu_eR8aXGNfj0lr2U{display:flex;flex-wrap:wrap}._1YzvHYkOTmNmAFsmvBp0sE{flex:1;background:#f0f0f0;border:1px solid #ddd;border-radius:5px 0px 0px 5px;font-family:courier,monospace}._3dFTowUTTaYLQ_sjG0qKZU{background:#fff}", ""]);

// exports
exports.locals = {
	"base": "_2pyJPGu_eR8aXGNfj0lr2U",
	"input": "_1YzvHYkOTmNmAFsmvBp0sE",
	"lighterBg": "_3dFTowUTTaYLQ_sjG0qKZU"
};