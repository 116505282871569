import React from 'react';
import StatusComponent from 'components/status';

class Status extends React.Component {
  render() {
    const {children, config} = this.props;
    if (typeof config === 'undefined') {
      return null;
    }

    let {message, appearance} = config;
    if (!appearance) {
      appearance = 'info';
    }

    return (
      <StatusComponent noFit panel appearance={appearance}>
        {children || message}
      </StatusComponent>
    );
  }
}

export default Status;
