import React from 'react';
import PusherJS from 'pusher-js';
import {connect} from 'react-redux';
import {PUSHER} from 'config';
import ReactPusher, {setPusherClient} from 'react-pusher';
import {pusherPrefixSelector, accountIdSelector} from 'modules/auth/authSelectors';

// Set up Pusher client on first load of this file
const pusherClient = new PusherJS(PUSHER.KEY, {
  encrypted: true,
  // Disables stats collection so that connection metrics are not submitted to Pusher’s servers.
  // These stats are used for pushers internal monitoring only and they do not affect the account stats.
  // NOTE this is to prevent the warning `A cookie associated with a cross-site resource at http://support.pusher.com/ was set without the `SameSite` attribute`
  disableStats: true
});
setPusherClient(pusherClient);

export class Pusher extends React.Component {
  prefixed(channel) {
    if (typeof channel === 'function') {
      // should exist the function a string or an object
      channel = channel(this.props.contextProps);
    }

    // Sometimes we don't want to prefix a channel with an accountId.
    // To achieve this, instead of passing channel as a string
    // you pass an object like {literal: 'literal-channel-name'}
    // If it turns out we want this behaviour all over the place, we
    // should rewrite to never include accountId but add it per instance.
    if (typeof channel !== 'string') {
      // if we don't get a string, ensure there is a usable string in the object
      if (typeof channel.literal !== 'string' || !channel.literal.length) {
        // if not, fail - we can't be hitting bad or fake channels
        throw new Error('Invalid pusher channel');
      }
      return (this.props.pusherPrefix || '') + channel.literal;
    }

    const {accountId} = this.props;
    return `${this.props.pusherPrefix}acc${accountId}-${channel}`;
  }

  // This function exists purely for convenience (to log pusher channels and data, etc)
  handleUpdate = (channel, event) => data => {
    // The onUpdate method *should* connect it's own action creators but
    // if the update function does return an action, we should dispatch it
    // console.log('PUSHER MESSAGE', channel, event, data);

    // did message come from testchamber
    if (this.props.pusherPrefix && data.message) {
      data = JSON.parse(data.message);
    }

    const actionToDispatch = this.props.onUpdate(data);

    if (actionToDispatch && actionToDispatch.type && typeof actionToDispatch.type == 'string') {
      this.props.dispatch(actionToDispatch);
    }
  };

  render() {
    const {channel, event} = this.props;
    const prefixedChannel = this.prefixed(channel);

    return (
      <ReactPusher
        event={event}
        channel={prefixedChannel}
        onUpdate={this.handleUpdate(prefixedChannel, event)}
      />
    );
  }
}

export default connect(state => ({
  accountId: accountIdSelector(state),
  pusherPrefix: pusherPrefixSelector(state)
}))(Pusher);
