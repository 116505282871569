import React from 'react';
import Spinner from 'components/spinner';
import {Wrapper, Header, Body, Title} from 'modules/dashboards/components/widget';
import EditWidgetButton from 'modules/dashboards/containers/editWidgetButton';
import LineChart from 'components/layoutComponents/lineChart';

export default function ReportsTrendWidget({widgetType, widget, isEditing, contextTypeId}) {
  const type = widget.getIn(['result', 'type']);
  const values = widget.getIn(['result', 'values']);
  const lineColor = widget.getIn(['result', 'lineColor']);
  const loading = type == null || values == null;

  return (
    <Wrapper>
      <Header>
        <Title>{widget.get('name')}</Title>
        {isEditing && <EditWidgetButton widget={widget} contextTypeId={contextTypeId} />}
      </Header>

      <Body heightUnit={2}>
        {loading ? (
          <Spinner />
        ) : (
          <LineChartWidget type={type} values={values} lineColor={lineColor} />
        )}
      </Body>
    </Wrapper>
  );
}

function LineChartWidget({type, values, lineColor}) {
  return (
    <LineChart
      result={values.toJS()}
      config={{
        decimals: 1,
        palette: [lineColor],
        height: 258, // this is hacky but works for now
        invert: true
      }}
      hideMargin
    />
  );
}
