exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3w2GPvWHl-A2y3UBEGko6q{border:1px solid #ddd}._3w2GPvWHl-A2y3UBEGko6q thead th:hover{background:rgba(200,200,200,.1)}._3w2GPvWHl-A2y3UBEGko6q._3lkq0lQjaV3uVMtlqoy1oi tbody tr:hover{cursor:pointer;background:rgba(200,200,200,.1)}._3w2GPvWHl-A2y3UBEGko6q .xeL7G1erJgTGtmplsexs{text-align:right}", ""]);

// exports
exports.locals = {
	"reportsTable": "_3w2GPvWHl-A2y3UBEGko6q",
	"clickable": "_3lkq0lQjaV3uVMtlqoy1oi",
	"optionsColumn": "xeL7G1erJgTGtmplsexs"
};