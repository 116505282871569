import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {
  FETCH_ALERT_SUBSCRIPTIONS,
  FETCH_ALERT_SUBSCRIPTIONS_COMPLETE,
  RECEIVE_ALERT_SUBSCRIPTIONS,
  RECEIVE_AVAILABLE_STORY_TYPES,
  SET_ALERT_SUBSCRIPTION_STATE
} from './constants';

const initialState = fromJS({
  error: null,
  isLoading: false,
  alertSubscriptions: {},
  alertStoryTypes: []
});

export default function alertSubscriptionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALERT_SUBSCRIPTIONS:
      return state.set('isLoading', true);

    case FETCH_ALERT_SUBSCRIPTIONS_COMPLETE:
      return state.set('isLoading', false).set('error', action.error);

    case RECEIVE_ALERT_SUBSCRIPTIONS:
      if (Array.isArray(action.alertSubscriptions)) {
        return state.set('alertSubscriptions', keyBy(action.alertSubscriptions, 'subscriptionId'));
      } else {
        return state.set('alertSubscriptions', fromJS(action.alertSubscriptions));
      }

    case RECEIVE_AVAILABLE_STORY_TYPES:
      return state.set('alertStoryTypes', fromJS(action.storyTypes));

    case SET_ALERT_SUBSCRIPTION_STATE:
      return state.setIn(
        ['alertSubscriptions', action.alertSubscription.subscriptionId.toString()],
        fromJS(action.alertSubscription)
      );
  }
  return state;
}
