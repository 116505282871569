import React from 'react';
import PropTypes from 'prop-types';
import styles from './quotePresenter.scss';
import cx from 'classnames';

const propTypes = {
  id: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.string,
    account: PropTypes.objectOf(PropTypes.any),
    quote: PropTypes.objectOf(PropTypes.any),
    quoteTable: PropTypes.objectOf(PropTypes.any)
  }).isRequired
};

const PagePresenter = ({id, quote}) => {
  const wrapperStyles = cx(styles.wrapper, styles.printBreak);

  const renderPage = id => {
    try {
      let Page = require(`./pages/${id}.js`);

      if (Page.default) {
        Page = Page.default;
      }

      if (!Page) {
        throw new Error('No Page');
      }

      return <Page quote={quote} />;
    } catch (err) {
      if (__DEV__) {
        console.error(`Page presenter [${id}] could not be loaded`);
        console.error(err);
      }
      return null;
    }
  };

  return (
    <div className={wrapperStyles}>
      <div className={styles.quoteBody}>{renderPage(id)}</div>
    </div>
  );
};

PagePresenter.propTypes = propTypes;

export default PagePresenter;
