import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './tick.scss';

class Tick extends React.Component {
  render() {
    const {selected, hovering} = this.props;
    const classes = cx(styles.tick, {
      [styles.selected]: selected,
      [styles.hovering]: hovering
    });

    return <div className={classes} />;
  }
}

export default Tick;
