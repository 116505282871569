import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {triggerEditFolderView} from 'modules/folderViews';
import Gandalf from 'components/gandalf';
import {SITE_CREATOR} from 'modules/auth/gandalf';

export class EditFolderViewButton extends React.Component {
  handleClick = () => {
    const {folderId, viewId} = this.props;
    this.props.triggerEditFolderView(folderId, viewId);
  };

  render() {
    return (
      <Gandalf shallPass={SITE_CREATOR}>
        <div>
          <Button wire onClick={this.handleClick}>
            Edit
          </Button>
        </div>
      </Gandalf>
    );
  }
}

export default connect(
  null,
  {triggerEditFolderView}
)(EditFolderViewButton);
