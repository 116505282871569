import _ from 'lodash';
import styles from './proportion.scss';
import React from 'react';
import Spinner from 'components/spinner';
import Progress from 'components/progress';
import Tooltip from 'components/tooltip';

class Proportion extends React.Component {
  render() {
    const {config, result} = this.props;
    const {label, appearance, color, tooltip} = config;

    if (!result || _.isNull(result)) {
      return <Spinner centered />;
    }

    const {numerator, denominator} = result;

    const component = (
      <div className={styles.proportion}>
        <div className={styles.value}>
          <div className={styles.label}>{label}</div>
          <div className={styles.progress}>
            <Progress
              value={numerator}
              denominator={denominator}
              appearance={appearance}
              color={color}
            />
          </div>
        </div>
      </div>
    );

    if (!tooltip) return component;

    return <Tooltip text={tooltip}>{component}</Tooltip>;
  }
}

Proportion.handlesLoading = true;

export default Proportion;
