import PropTypes from 'prop-types';
import React from 'react';
import {getOrdinal} from 'utils/numberUtils';
import styles from './styles.scss';
import cx from 'classnames';

class Ordinal extends React.Component {
  render() {
    const {data, options} = this.props;
    const display = data > 0 ? getOrdinal(data) : '-';

    const classNames = cx({
      [styles[options.class]]: true
    });

    return <span className={classNames}>{display}</span>;
  }
}

Ordinal.propTypes = {};

export default Ordinal;
