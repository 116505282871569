import React from 'react';
import Code from 'components/code';

class HtmlIssue extends React.Component {
  render() {
    const {point} = this.props;
    const {html, language} = point;

    if (!html) return null;

    return (
      <div>
        <Code isOpen={true} language={!language ? 'html' : language}>
          {html}
        </Code>
      </div>
    );
  }
}

export default HtmlIssue;
