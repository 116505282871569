import {makeDefaultData} from 'modules/policies/policyUtils';
import {testPath} from 'modules/tests/testUtils';
import {reportEndpoint} from 'modules/reports/reportUtils';

// paths
export function interactionsPath() {
  return testPath(...arguments) + '/interactions';
}

export function interactionPath({interactionId}) {
  return interactionsPath(...arguments) + '/' + interactionId;
}

export function testInteractionPath() {
  return interactionPath(...arguments) + '/test';
}

export function editInteractionPath() {
  return interactionPath(...arguments) + '/edit';
}

// endpoints
export function interactionsEndpoint() {
  return reportEndpoint(...arguments) + '/interactions';
}

export function interactionEndpoint({interactionId}) {
  return interactionsEndpoint(...arguments) + '/' + interactionId;
}

export function testInteractionEndpoint() {
  return interactionEndpoint(...arguments) + '/test';
}

export function editInteractionEndpoint() {
  return interactionsEndpoint(...arguments) + '/edit';
}

export function instructionTypesEndpoint() {
  return 'instruction-types';
}

// utils
export function makeNewWebpageInteraction(openInstruction) {
  return {
    interaction: {
      name: '',
      sortOrder: 1,
      usePastCookies: false,
      setCookies: true,
      isAuthenticator: false,
      isEnabled: true,
      linkDiscoveryStrategy: 'a'
    },
    stages: [
      {
        // we add this so the UI knows to not allow any changes to be made to the very first stage
        locked: true,

        ...makeNewStage('Load page', 1, [
          {
            instructionTypeId: 'Open',
            sortOrder: 1,
            options: makeDefaultData(openInstruction.get('supportedOptions').toJS(), {})
          }
        ])
      }
    ]
  };
}

export function makeNewInstruction(instruction) {
  return {
    instructionTypeId: instruction.get('instructionTypeId'),
    sortOrder: 1,
    options: makeDefaultData(instruction.get('supportedOptions').toJS(), {})
  };
}

export function makeNewStage(name, sortOrder, instructions = []) {
  return {
    stage: {
      name,
      sortOrder
    },
    instructions
  };
}
