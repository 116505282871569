import _ from 'lodash';
import {accountSelector} from 'modules/auth/authSelectors';
import {createSelector} from 'reselect';
import {MONITOR_PRODUCT_ID} from './constants';

export const superProductsSelector = state => state.superProduct;

export const superProductsDataSelector = createSelector(
  superProductsSelector,
  state => {
    return state.get('data');
  }
);

export const superProductquoteSelector = createSelector(
  superProductsSelector,
  state => {
    return {
      quotes: state.getIn(['quotes', 'data']).toJS(),
      loadingQuotes: state.getIn(['quotes', 'isLoading']),
      quoteError: state.getIn(['quotes', 'quoteError'])
    };
  }
);

export const buyRequestStateSelector = createSelector(
  superProductsSelector,
  state => {
    return state.get('buyRequest');
  }
);

export const pricingCalculatorSelector = createSelector(
  superProductquoteSelector,
  buyRequestStateSelector,
  (quotes, buyRequestState) => {
    return {
      ...quotes,
      buyRequestProcessing: buyRequestState.get('isProcessing')
    };
  }
);

export const pricingCalculatorPageSelector = createSelector(
  superProductsSelector,
  superProductquoteSelector,
  accountSelector,
  (productsState, quotes, account) => {
    const product = productsState
      .get('data')
      .find(product => product.get('productId') === MONITOR_PRODUCT_ID);
    return {
      product: product ? product.toJS() : null,
      isLoadingProduct: productsState.get('isLoading'),
      loadingError: productsState.get('loadingError'),
      loadingQuotes: quotes.loadingQuotes,
      quoteError: quotes.quoteError,
      quotes: quotes.quotes,
      account: account ? account.toJS() : {}
    };
  }
);
