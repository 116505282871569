import React from 'react';
import {connect} from 'react-redux';
import ReportGandalf from 'components/gandalf/reportGandalf';
import DeleteIcon from 'components/icons/deleteIcon';
import {confirmDeleteReport} from 'modules/reports';
import cx from 'classnames';
import {ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';

export class ReportDeleteButton extends React.Component {
  handleDeleteReport = reportId => event => {
    const {confirmDeleteReport, folderId} = this.props;

    event.preventDefault();
    event.stopPropagation();

    confirmDeleteReport(reportId, folderId);
  };

  render() {
    const {reportId, className, ...iconProps} = this.props;

    return (
      <ReportGandalf reportId={reportId} shallPass={ROLE_CONTRIBUTOR}>
        <DeleteIcon
          {...iconProps}
          className={cx('delete-report', className)} // used for automated testing
          onClick={this.handleDeleteReport(reportId)}
        />
      </ReportGandalf>
    );
  }
}

export default connect(
  null,
  {confirmDeleteReport}
)(ReportDeleteButton);
