export function isEdge() {
  return navigator.userAgent.indexOf('Edge') > -1;
}

export function isIE() {
  const ua = navigator.userAgent;

  return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
}

export function isFirefox() {
  return /firefox/i.test(navigator.userAgent);
}

export function isChrome() {
  return window.navigator.userAgent.includes('Chrome');
}
