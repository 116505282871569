import React from 'react';
import styles from './align.scss';

class Align extends React.Component {
  render() {
    const {config} = this.props;
    const {align} = config;

    return <div className={styles[align]}>{this.props.children}</div>;
  }
}

export default Align;
