import cx from 'classnames';
import React from 'react';
import styles from 'components/layoutComponents/explorer/explorerData.scss';

export default function RowHeader({maxCols, children, className}) {
  return (
    <tr className={cx(styles.rowGroup, className)}>
      <td colSpan={maxCols}>{children}</td>
    </tr>
  );
}
