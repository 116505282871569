import React from 'react';
import Helmet from 'components/helmet';
import Button from 'components/button';
import Spinner from 'components/spinner';
import styles from 'modules/auth/components/authWrapperShared.scss';

export default class TokenExpiredPage extends React.Component {
  renderExpiredView() {
    const {
      emailRequestFailed,
      emailRequestSuccess,
      requestingEmail,
      onClickResetToken
    } = this.props;

    if (requestingEmail) return null;

    if (emailRequestFailed) {
      return (
        <p className={styles.text}>
          Oops, there seems to have been a problem. Please try again later.
        </p>
      );
    }

    if (emailRequestSuccess) {
      return <p className={styles.text}>Email has been sent with a new reset link.</p>;
    }

    return (
      <div>
        <p className={styles.text}>This password reset request has expired.</p>

        <Button fullWidth destructive onClick={onClickResetToken}>
          Click to send a new reset email
        </Button>
      </div>
    );
  }

  render() {
    const {requestingEmail, className} = this.props;

    return (
      <div className={className}>
        <Helmet title="Reset password" />

        {requestingEmail && <Spinner centered />}

        {this.renderExpiredView()}
      </div>
    );
  }
}
