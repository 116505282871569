import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './explorerSummaries.scss';
import {evalFilterCondition} from 'modules/filters/filterUtils';
import {getProps} from 'utils/immutableUtils';
import {interpolateToJSX} from 'utils/interpolationUtils';
import Pill from 'components/pill';

export default class ExplorerSummaries extends React.Component {
  renderSummary(summary, summaryValues, index) {
    if (!summaryValues) return null;

    const value = summaryValues[summary.data];

    if (_.isUndefined(value) || !value) return null;
    if (!this.canShow(summary)) return null;

    return (
      <ExplorerSummary
        {...getProps(summary, ['label', 'appearance', 'format'])}
        value={value}
        key={index}
      />
    );
  }

  canShow(summary) {
    const {filter} = this.props;
    return evalFilterCondition(filter, summary.matchFilter);
  }

  render() {
    const {summaries, summaryValues} = this.props;

    if (_.isUndefined(summaries)) return null;

    return (
      <div className={styles.summaries}>
        {_.map(summaries, (summary, index) => this.renderSummary(summary, summaryValues, index))}
      </div>
    );
  }
}

function ExplorerSummary({label, value, format, appearance = 'minor'}) {
  return (
    <span className={styles.summary}>
      <Pill appearance={appearance} narrow>
        {!format ? value : interpolateToJSX(format, {value})}
      </Pill>{' '}
      {label}
    </span>
  );
}
