import {List} from 'immutable';
import {createSelector} from 'reselect';
import {accountIdSelector} from 'modules/auth/authSelectors';

export const servicesSelector = state => state.services;

export const servicesDataSelector = createSelector(
  servicesSelector,
  services => services.get('data', List())
);

export const servicesLoadingSelector = createSelector(
  servicesSelector,
  services => services.get('isLoading')
);

export const servicesErrorSelector = createSelector(
  servicesSelector,
  services => services.get('error')
);

export const serviceUsageTableSelector = createSelector(
  servicesSelector,
  accountIdSelector,
  (services, accountId) => {
    return {
      accountId: accountId,
      usageData: services.get('usageData').toJS(),
      usageDataLoading: services.get('usageDataLoading'),
      usageDataFetchError: services.get('usageDataFetchError')
    }
  }
)