exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".aAV1tqrJzbERQCzhkAbCm{display:flex;align-items:center;font-size:16px;font-weight:bold}.aAV1tqrJzbERQCzhkAbCm span{margin-right:6px;margin-bottom:1px;color:#55d272}.aAV1tqrJzbERQCzhkAbCm._3o5uC924AhEEpDZQwkktbY,.aAV1tqrJzbERQCzhkAbCm._3o5uC924AhEEpDZQwkktbY span{color:#df1616}", ""]);

// exports
exports.locals = {
	"keywordsBalance": "aAV1tqrJzbERQCzhkAbCm",
	"balanceExceeded": "_3o5uC924AhEEpDZQwkktbY"
};