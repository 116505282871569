import React from 'react';
import Icon from 'components/font-awesome';
import styles from './cookieIssue.scss';
import ExternalLink from 'modules/location/externalLink';

class CookieIssue extends React.Component {
  render() {
    const {
      point: {cookie, value, isSecure, isHttpOnly},
      action: {actionId, decisions}
    } = this.props;

    return (
      <div>
        <p>
          Cookie: <code>{cookie}</code>
        </p>
        <p>
          Value: <code>{value}</code>
        </p>
        <p className={styles.info}>
          <ExternalLink href="https://www.owasp.org/index.php/SecureFlag">
            {isSecure && (
              <span>
                <Icon name="far fa-lock" /> Secure (SSL)
              </span>
            )}
            {!isSecure && (
              <span>
                <Icon name="far fa-unlock" /> Insecure (not SSL)
              </span>
            )}
          </ExternalLink>{' '}
          <ExternalLink href="https://www.owasp.org/index.php/HttpOnly">
            {isHttpOnly && (
              <span>
                <Icon name="fas fa-check" /> HTTP only
              </span>
            )}
            {!isHttpOnly && (
              <span>
                <Icon name="fas fa-times" /> Not HTTP only
              </span>
            )}
          </ExternalLink>
        </p>
      </div>
    );
  }
}

export default CookieIssue;
