import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Button from 'components/button';
import {uptimeMonitorPath} from 'modules/uptime/utils';

function UptimeMonitorButton({monitorId = 'new', ...props}) {
  return monitorId === 'new' ? (
    <Button icon="plus" tooltip="Add new uptime monitor" {...props}>
      Add new monitor
    </Button>
  ) : (
    <Button icon="pencil-alt" tooltip="Edit this uptime monitor" {...props}>
      Edit
    </Button>
  );
}

export default connect(
  null,
  (dispatch, props) => ({
    onClick: event => {
      dispatch(
        push(uptimeMonitorPath, {monitorId: props.monitorId}, ['reportId'], false, {}, event)
      );
    }
  })
)(UptimeMonitorButton);
