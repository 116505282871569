import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DIGESTS} from 'modules/digests/constants';
import {digestsEndpoint} from 'modules/digests/utils';
import {receiveDigests, requestDigestsComplete} from 'modules/digests/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestDigestsListener() {
  yield takeLatest(REQUEST_DIGESTS, requestDigestsSaga);
}

function* requestDigestsSaga() {
  const url = digestsEndpoint();

  yield processRequest('GET', url, {
    *success(data) {
      yield put(receiveDigests(data));
      yield put(requestDigestsComplete());
    },
    *error(err) {
      yield put(requestDigestsComplete(err));
    },
    errorMessage: couldNot('request digests')
  });
}
