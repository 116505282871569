import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import SettingsWrapper from '../components/settingsWrapper';
import AccountAuditLogPage from '../pages/accountAuditLogPage';
import AccountDecisionsPage from '../pages/accountDecisionsPage';
import AccountSettingsPage from '../pages/accountSettingsPage';
import AccountTagSettingsPage from '../pages/accountTagSettingsPage';
import AccountTagsSettingsPage from '../pages/accountTagsSettingsPage';
import CheckSettingsPage from '../pages/checkSettingsPage';
import InvoicesPage from '../pages/invoicesPage';
import ServiceSettingsPage from '../pages/serviceSettingsPage';
import ServicesSettingsPage from '../pages/servicesSettingsPage';
import SubscriptionsPage from '../pages/subscriptionsPage';
import UserSettingsPage from '../pages/userSettingsPage';
import UsersPage from '../pages/usersPage';
import ReportLogRoute from '../routes/reportLogRoute';
import {settingsRoutesSelector} from '../settingsSelectors';

export class SettingsRoute extends React.Component {
  render() {
    const {
      match: {params, path, url},
      location: {pathname},
      usingServices
    } = this.props;

    return (
      <SettingsWrapper params={params} pathname={pathname}>
        <Switch>
          <Route exact path={`${path}/`} component={UserSettingsPage} />

          {/* TODO all account paths need ACCOUNT_OWNER permissions */}
          <Route exact path={`${path}/account`} component={AccountSettingsPage} />
          {usingServices && (
            <Route exact path={`${path}/account/services`} component={ServicesSettingsPage} />
          )}
          {usingServices && (
            <Route
              exact
              path={`${path}/account/services/:serviceId`}
              component={ServiceSettingsPage}
            />
          )}
          {!usingServices && (
            <Route exact path={`${path}/account/subscriptions`} component={SubscriptionsPage} />
          )}
          <Route exact path={`${path}/account/decisions`} component={AccountDecisionsPage} />
          <Route exact path={`${path}/account/audit-log`} component={AccountAuditLogPage} />
          <Route exact path={`${path}/account/report-log`} component={ReportLogRoute} />
          <Route exact path={`${path}/account/payments`} component={InvoicesPage} />
          <Route exact path={`${path}/account/checks`} component={CheckSettingsPage} />
          <Route exact path={`${path}/account/users`} component={UsersPage} />
          <Route exact path={`${path}/account/tags`} component={AccountTagsSettingsPage} />
          <Route exact path={`${path}/account/tags/:tagId`} component={AccountTagSettingsPage} />
          <Redirect from="*" to={`${url}`} />
        </Switch>
      </SettingsWrapper>
    );
  }
}

export default connect(settingsRoutesSelector)(SettingsRoute);
