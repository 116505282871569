import {put, take, select, call} from 'redux-saga/effects';
import {recieveReportAssignedTags} from 'modules/reports';

// operation can be 'add', 'remove' or 'set'
export function* tagsChanged(entityType, entityId, newTagsIds, operation) {
  if (entityType === 'report') {
    yield put(recieveReportAssignedTags(entityId, newTagsIds, operation));
  }

  // if (entityType === 'user') {
  //   yield put(recieveUserAssignedTags(entityId, newTags, operation));
  // }
}

export function getEntityId(entity, entityType) {
  switch (entityType) {
    case 'report':
      return entity.get('reportId');
  }
}

export function tagsEndpoint() {
  return 'tags';
}

export function tagEndpoint({tagId}) {
  return tagsEndpoint() + '/' + tagId;
}
