import {select, put, takeLatest} from 'redux-saga/effects';
import {SELECT_ARCHIVE} from 'modules/archives';
import {reportIdParamSelector} from 'modules/reports/reportSelectors';
import {push} from 'modules/location';
import {clearReport} from 'modules/reports/reports';
import {generateReportId, archiveRedirectPath, getReportIdParts} from 'modules/reports/reportUtils';

export default function* selectArchiveSaga() {
  yield takeLatest(SELECT_ARCHIVE, selectArchive);
}

function* selectArchive({archiveId}) {
  const reportId = yield select(reportIdParamSelector);

  const reportParts = getReportIdParts(reportId);

  const newReportId = generateReportId(reportParts.reportId, reportParts.segmentId, archiveId);

  if (reportId !== newReportId) {
    yield put(clearReport(reportParts.reportId));
  }

  yield put(push(archiveRedirectPath, {reportId: newReportId}, ['accountId', 'testId']));
}
