exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2zbXhUcTgn7zY3p-NZXknA{margin:0 0 10px 0}._1HwfdYXoYv7Lnp2p1wr6uB{color:#df1616;border-bottom:1px dashed #df1616}._2wPYTz_WHQCsQU6Oe6dbOk{display:flex;align-items:center;justify-content:center;margin:15px 0}._2wPYTz_WHQCsQU6Oe6dbOk span{padding-right:10px}._3u9BxPPFZewZxKP4ZVSCzV{display:inline-block;padding:5px}._2q5iBow1e9lyYENnShAHqY{color:#444}._3yJCuJTRRnexSr_oX0Nk6i{color:#888;margin:15px 0}._14EyUGb0ETC0w08lXMq54z{color:#333}", ""]);

// exports
exports.locals = {
	"text": "_2zbXhUcTgn7zY3p-NZXknA",
	"misspelling": "_1HwfdYXoYv7Lnp2p1wr6uB",
	"languages": "_2wPYTz_WHQCsQU6Oe6dbOk",
	"language": "_3u9BxPPFZewZxKP4ZVSCzV",
	"suggestionsHeading": "_2q5iBow1e9lyYENnShAHqY",
	"suggestions": "_3yJCuJTRRnexSr_oX0Nk6i",
	"suggestion": "_14EyUGb0ETC0w08lXMq54z"
};