import {takeLatest, put} from 'redux-saga/effects';
import {REJECT_QUOTE, setQuoteState} from 'modules/quote/quoteActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {quoteRejectEndpoint} from 'modules/quote/quoteUtils';
import {couldNot} from 'utils/errorUtils';
import {showError} from 'utils/saga/alerts';

export default function* rejectQuoteSaga() {
  yield takeLatest([REJECT_QUOTE], function*({quoteId, quoteKey, reason}) {
    yield processRequest('POST', quoteRejectEndpoint(quoteId, quoteKey), {
      successAction: (data) => setQuoteState(data.state),
      error: function*(err) {
        yield put(showError(couldNot('reject quote')));
      }
    }, {
      reason
    });
  });
}