import React from 'react';
import Panel from 'components/panel';
import Margin from 'components/margin';
import ForEachChild from 'components/forEachChild';
import {TypeField} from 'modules/form/hooks/useForm';
import InlineFields from 'modules/form/components/inlineFields';

// called Webpage because it's not directly tied to an interaction because
// the startUrl field is not part of an "Interaction"
export default function WebpageInteractionField({field, showUrlField}) {
  return (
    <Panel marginBottom>
      <ForEachChild render={child => <Margin marginBottom="15px">{child}</Margin>}>
        <TypeField autoFocus field={field.fields.name} label="Name" type="text" required />
        <TypeField
          field={showUrlField}
          label="Start URL"
          type="url"
          show={showUrlField.blurred || showUrlField.submitted}
        />

        <InlineFields center marginTop={20} marginBottom={0}>
          <TypeField
            field={field.fields.usePastCookies}
            description="Use past cookies?"
            type="checkbox"
          />
          <TypeField
            field={field.fields.setCookies}
            description="Save cookies found?"
            type="checkbox"
          />
          <TypeField
            field={field.fields.isAuthenticator}
            description="Is authenticator?"
            type="checkbox"
          />
        </InlineFields>
      </ForEachChild>
    </Panel>
  );
}
