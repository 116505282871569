import {put, select, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';

import {VIEW_PAYMENTS_PAGE} from 'modules/settings';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {requestPaymentDetails, receivePaymentDetails, paymentDetailsFailed} from '../payments';
import {hasPaymentDetailsSelector, paymentDetailsLoadingSelector} from '../paymentSelectors';

export default function* paymentDetailsSaga() {
  yield takeEvery(VIEW_PAYMENTS_PAGE, fetchPaymentDetailsIfRequired);
}

function* fetchPaymentDetailsIfRequired() {
  const accountId = yield select(accountIdSelector);
  const hasLoadedPaymentDetails = yield select(hasPaymentDetailsSelector);
  const isLoading = yield select(paymentDetailsLoadingSelector);

  if (!hasLoadedPaymentDetails && !isLoading) {
    yield put(requestPaymentDetails());
  }
}
