import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {confirmDeleteUptimeContact} from 'modules/uptime/actions';

function DeleteUptimeContactButton({monitorId, userId, ...props}) {
  return (
    <Button icon="trash" danger tooltip="Stop this user from being notified" {...props}>
      Delete
    </Button>
  );
}

export default connect(
  null,
  (dispatch, props) => ({
    onClick: event => {
      dispatch(confirmDeleteUptimeContact(props.userId, props.monitorId, props.reportId));
    }
  })
)(DeleteUptimeContactButton);
