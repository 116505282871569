import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import filterInvalidDomProps from 'filter-invalid-dom-props';
import {push} from 'modules/location';
import {shareReport} from 'modules/share';
import {isVirtualUser} from 'modules/auth/gandalf';
import {reportSelector} from 'modules/reports/reportSelectors';
import {triggerExportReport, requestRetestReport} from 'modules/reports';
import {lastPathSelector, locationParamsSelector} from 'modules/location/locationSelectors';
import {userSelector, accountSelector, isShadowingSelector} from 'modules/auth/authSelectors';
import styles from './sidebar.scss';

const sidebarSelector = createSelector(
  userSelector,
  accountSelector,
  isShadowingSelector,
  locationParamsSelector,
  reportSelector,
  lastPathSelector,
  (user, account, {isShadowing}, params, report, lastPath) => ({
    params,
    account,
    isShadowing,
    isVirtual: isVirtualUser(user),
    report,
    lastPath
  })
);

export class Sidebar extends React.Component {
  render() {
    const {
      children,
      className,
      buttons,
      account,
      isVirtual,
      report,
      lastPath,
      ...props
    } = this.props;

    return (
      <aside {...filterInvalidDomProps(props)} className={cx(styles.sidebar, className)}>
        {children}

        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </aside>
    );
  }
}

export default connect(
  sidebarSelector,
  {push, triggerExportReport, requestRetestReport, shareReport}
)(Sidebar);

// const dropdownButton = (
//   <Button
//     plain
//     icon="chevron-down"
//     className={cx(styles.sidebarHeaderButtons, styles.dropdownButton)}
//   />
// );
// const backButton = (
//   <Button
//     plain
//     icon="chevron-left"
//     iconSize="2x"
//     className={cx(styles.sidebarHeaderButtons, styles.backButton)}
//   />
// );

// const retestOption = (
//   <React.Fragment>
//     <Icon name="repeat" />
//     Retest
//   </React.Fragment>
// );
// const settingOption = (
//   <React.Fragment>
//     <Icon name="cog" />
//     Settings
//   </React.Fragment>
// );

// function SidebarHeader({label, showBack, report, onClickOption = () => () => {}}) {
//   const showDropdown = !report.isEmpty() && report.get('reportId');

//   return (
//     <div className={styles.sidebarHeader}>
//       {showBack && backButton}
//       <h2>{label}</h2>
//       {showDropdown && (
//         <Dropdown button={dropdownButton} bodyClassName={styles.sidebarHeaderDropdownBody}>
//           <VirtualGandalf>
//             <DropdownOption onClick={onClickOption('virtual')}>{settingOption}</DropdownOption>
//           </VirtualGandalf>
//           <VirtualGandalf not>
//             <DropdownOption onClick={onClickOption('settings')}>{settingOption}</DropdownOption>
//           </VirtualGandalf>

//           <ReportGandalf shallPass={ROLE_VIEWER} reportId={report.get('reportId')}>
//             <DropdownOption onClick={onClickOption('export')}>
//               <Icon name="file" /> Export
//             </DropdownOption>
//           </ReportGandalf>

//           <ReportGandalf shallPass={ROLE_VIEWER} reportId={report.get('reportId')}>
//             <DropdownOption onClick={onClickOption('share')}>
//               <Icon name="share-alt" /> Share
//             </DropdownOption>
//           </ReportGandalf>

//           <VirtualGandalf>
//             <DropdownOption onClick={onClickOption('virtual')}>{retestOption}</DropdownOption>
//           </VirtualGandalf>
//           <ReportGandalf shallPass={ROLE_CONTRIBUTOR} reportId={report.get('reportId')}>
//             <DropdownOption onClick={onClickOption('retest')}>{retestOption}</DropdownOption>
//           </ReportGandalf>
//         </Dropdown>
//       )}
//     </div>
//   );
// }

// function VirtualGandalfComponent({isVirtual, not, children}) {
//   if (!not ? !isVirtual : isVirtual) return null;
//   return children;
// }

// const virtualGandalfSelector = createSelector(
//   isVirtualUserSelector,
//   isVirtual => ({isVirtual})
// );

// const VirtualGandalf = connect(virtualGandalfSelector)(VirtualGandalfComponent);
