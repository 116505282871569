import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {entitlementDataSelector} from 'modules/entitlements/entitlementSelectors';
import {formatGivenNumber} from 'utils/numberUtils';

import Choice from 'components/choice';
import styles from './reportProduct.scss';

export class ReportProduct extends React.Component {
  static defaultProps = {
    entitlements: []
  };

  renderProduct({name, description}, remaining, isInfinite, maxSize, serviceId) {
    return (
      <div>
        <h2 className={styles.title}>
          {name}
          {maxSize && !serviceId && <span> ({formatGivenNumber(maxSize)} pages)</span>}
          {!isInfinite && (
            <span className={cx(styles.remaining, styles.positive)}>{remaining} left</span>
          )}
        </h2>
      </div>
    );
  }

  entitlementToOption = (entitlement, idx) => {
    const {value} = this.props;
    const {product, summarizedId, remaining, isInfinite, maxSize, serviceId} = entitlement;

    return {
      value: summarizedId,
      label: this.renderProduct(product, remaining, isInfinite, maxSize, serviceId),
      className: cx(styles.product, {
        [styles.selected]: summarizedId === value
      })
    };
  };

  getOptions() {
    return (
      this.props.entitlements
        // filter any products that we can no longer use
        .filter(c => c.isInfinite || c.remaining > 0)
        .map(this.entitlementToOption)
    );
  }

  render() {
    return <Choice {...this.props} multi={false} options={this.getOptions()} />;
  }
}

export default connect(state => {
  const entitlements = entitlementDataSelector(state);

  return {
    entitlements: entitlements && entitlements.toJS()
  };
})(ReportProduct);
