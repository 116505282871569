import React from 'react';
import CodeComponent from 'components/code';

class Code extends React.Component {
  render() {
    const {
      data,
      options: {language = 'html'}
    } = this.props;

    if (!data) return null;

    return (
      <div>
        <CodeComponent isOpen={true} language={language}>
          {data}
        </CodeComponent>
      </div>
    );
  }
}

export default Code;
