import React from 'react';
import FieldsComponent from 'components/fields';

class FieldsIssue extends React.Component {
  render() {
    const {
      point: {fields}
    } = this.props;

    return (
      <div>
        <FieldsComponent fields={fields} />
      </div>
    );
  }
}

export default FieldsIssue;
