import React from 'react';
import styles from './spellingIssue.scss';
import InsideContext from '../shared/insideContext.js';
import CountryFlag from 'components/countryFlag';

class SpellingIssue extends React.Component {
  renderSuggestions(suggestions) {
    if (!suggestions.length) {
      return null;
    }

    return (
      <div className={styles.suggestions}>
        Suggestion: <span className={styles.suggestion}>{suggestions[0]}</span>
      </div>
    );
  }

  renderLanguage(languageCode) {
    return <CountryFlag code={languageCode} key={languageCode} />;
  }

  renderLanguages(languages) {
    if (!languages || languages.length < 2) {
      return null;
    }

    return (
      <div className={styles.languages}>
        <span>Not found in: </span>
        {languages.map(this.renderLanguage)}
      </div>
    );
  }

  render() {
    const {point, isActive} = this.props;
    const {word, suggestions, before, after, languages, insideContext} = point;

    return (
      <span>
        <p className={styles.text}>
          <span>{before}</span>
          <span className={styles.misspelling}>{word}</span>
          <span>{after}</span>
        </p>
        {this.renderLanguages(languages)}
        <InsideContext insideContext={insideContext} />
        {isActive && this.renderSuggestions(suggestions)}
      </span>
    );
  }
}

export default SpellingIssue;
