import {OPEN_HELP, CLOSE_HELP, CLEAR_URL} from './constants';

export function openHelp(helpUrl) {
  return {type: OPEN_HELP, helpUrl};
}

export function closeHelp() {
  return {type: CLOSE_HELP};
}

export function clearUrl() {
  return {type: CLEAR_URL};
}
