import React from 'react';
import cx from 'classnames';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {whichSidebarSelector} from 'modules/reports/reportSidebarSelectors';
import Icon from 'components/font-awesome';
import {push} from 'modules/location';
import {getTestSidebarIcon} from 'modules/reports/reportConstants';
import {Item, SubMenu} from 'components/sidebar/listSidebar';
import styles from 'components/sidebar/listSidebar/sidebarItem.scss';
import {reportPath} from 'modules/reports/reportUtils';
import {toggleSidebar} from 'modules/app';
import {testPath} from 'modules/tests/testUtils';

// Renders a single level of sidebar tests,
// plus potentially another container for the next level down.
export class TestsSidebarNested extends React.Component {
  // Due to this returning an array, it's not worth abstracting to a new component
  // as we have to add additional dom element wrappers.
  renderItem = navItem => {
    const {scoreType} = this.props;
    const testId = navItem.get('testId');
    const {
      match: {params}
    } = this.props;

    if (navItem.get('isHidden')) return null;
    const status = navItem.get('status');

    return (
      <TestSidebarItem
        newStyle
        testId={testId}
        href={testPath({...params, testId})}
        className={cx(styles.nestedItem, {[styles.isChild]: !!this.props.parentId})}
        key={`${testId}_menu`}
        active={navItem.get('isActive')}
        after={navItem.get('type') == 'Section' && <Icon name="chevron-right" />}
        hoverable
        clickable
        onClick={() => {
          if (this.props.screenSizes.tablet && !testId.includes('-summary')) {
            this.props.toggleSidebar(true);
          }
        }}
      >
        {navItem.get('name')} {status === 'new' && <span className={styles.statusNew}>NEW</span>}
        {status === 'demo' && (
          <span className={styles.statusDemo}>
            <Icon name="lock" />
          </span>
        )}
      </TestSidebarItem>
    );
  };

  renderItems(navItems) {
    return navItems.sortBy(item => item.get('sortIndex')).map(this.renderItem);
  }

  render() {
    const {
      scoreType,
      navItems,
      parentId,
      parentTest,
      match: {params}
    } = this.props;
    const items = this.renderItems(navItems);

    if (!parentId) return <div>{items}</div>;

    return (
      <div>
        {/* This is the sidebar back button */}
        <TestSidebarItem
          newStyle
          testId={parentId}
          href={reportPath({...params})}
          className={styles.nestedItem}
          key={parentId}
          icon="chevron-left"
          active={parentTest.get('isActive')}
          hoverable
          clickable
        >
          {parentTest.get('name')}
        </TestSidebarItem>
        <SubMenu>{items}</SubMenu>
      </div>
    );
  }
}

function TestSidebarItem({testId, children, className, ...itemProps}) {
  const iconSrc = getTestSidebarIcon(testId);

  return (
    <Item {...itemProps} className={cx(className, {[styles.indent]: !iconSrc})}>
      {iconSrc && <img src={iconSrc} />}
      {children}
    </Item>
  );
}

export default compose(
  connect(
    whichSidebarSelector,
    {push, toggleSidebar}
  ),
  connectContext(ScreenSizeContext, 'screenSizes'),
  withRouter
)(TestsSidebarNested);
