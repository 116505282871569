import React from 'react';
import cx from 'classnames';
import Spinner from './circularSpinner';
import Center from 'components/center';
import ModalContent from 'modules/modal/components/modalContent';

export default function ModalSpinner({message, ...modalProps}) {
  return (
    <ModalContent {...modalProps}>
      <Center style={{height: 160, textAlign: 'center'}}>
        <div>
          <Spinner />
          {message && <div style={{marginTop: 10}}>{message}</div>}
        </div>
      </Center>
    </ModalContent>
  );
}
