import React from 'react';
import styles from './pages.scss';
import Logos from 'modules/quote/images/proposal-logos.jpg';
import WhoMap from 'modules/quote/images/who-map.jpg';
import QuoteTopBar from '../quoteTopBar.js';

const SilktideAboutUs = ({quote}) => (
  <div className={styles.page}>
    <QuoteTopBar quote={quote} />
    <h1>About us</h1>
    <p>
      Silktide is a web intelligence company headquartered in the UK. Founded in 2001, we make
      software to help people understand and improve their websites. Our clients include FORTUNE 500
      corporations, government organizations, councils, universities, and over one thousand SMEs.
    </p>
    <img src={Logos} alt="Silktide customer logos" className={styles.centeredImg} />
    <p>
      In 2018 Silktide tested over 5 million websites a month, ranging from 5 pages to 400,000 pages
      each. Our customers can be found in 900 cities around the world.
    </p>
    <h5>Silktide customers by location (Jan 2019)</h5>
    <img src={WhoMap} alt="Silktide customers by location" className={styles.map} />
  </div>
);

export default SilktideAboutUs;
