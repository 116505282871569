export function userAchievementsEndpoint({userId}) {
  return `users/${userId}/achievements`;
}

export function readAchievementEndpoint({achievementId}) {
  return `unread-achievements/${achievementId}`;
}

export function unreadAchievementsEndpoint() {
  return 'unread-achievements';
}

// `value` is our current progress
// `current` is the target of the current achievement (less than `value`)
// `next` is the target of the next achievement (more than `value`)
export const calculateProgress = (value, currentTarget, nextTarget) => {
  // ensure value is an integer
  value = parseInt(value, 10);

  // default current and next if they don't exist
  const current = !currentTarget ? value : currentTarget.target;
  const next = !nextTarget ? value : nextTarget.target;

  // Say our current progress `value` is 4, but the achievement was awarded at 2, and the next achievement
  // is awarded at 6. That means that when we were at 2, we had 0%, and when we are at 6, we have 100% progress.
  return ((value - current) / (next - current)) * 100;
};

// This function selects at most 2 closest targets based on the `progress` value.
// `progress` is an integer
// `targets` is an array of targets [{target: 1}, {target: 4}, ...]
// The return value is an array containing the current target as index 0 and the next target as index 1.
// It is possible that either or both indexes can be undefined
export const getSurroundingTargets = (progress, targets) => {
  // ensure progress value is an integer
  progress = parseInt(progress, 10);

  // if `progress` is smaller than our first target, then we haven't met the criteria for the first target.
  if (progress < targets[0].target) {
    // current doesn't exist, next achievement is the first possible availible
    return [undefined, targets[0]];
  }

  // if we have reached the last achievement, there is no next achievement available
  if (progress >= targets[targets.length - 1].target) {
    return [targets[targets.length - 1], undefined];
  }

  // find the first target where `progress` is big enough
  for (var i = 0, l = targets.length; i < l; i++) {
    if (progress < targets[i].target) {
      // return current and next targets
      return [targets[i - 1], targets[i]];
    }
  }
  return [undefined, undefined];
};
