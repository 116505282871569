import React from 'react';
import styles from './heading.scss';

class Heading extends React.Component {
  render() {
    const {config} = this.props;
    const {level, label, description} = config;

    const heading = React.createElement('h' + level, {className: styles.heading}, [label]);

    return (
      <div className={styles.wrapper}>
        {heading}
        {description && <p className={styles.description}>{description}</p>}
      </div>
    );
  }
}

export default Heading;
