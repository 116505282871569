import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {formatGivenNumber, isFloat} from 'utils/numberUtils';

import styles from './change.scss';
import Tooltip from 'components/tooltip';

const Change = ({diff, up, down, equal, logic}) => {
  const absDiff = Math.abs(diff);
  const roundedDiff = formatGivenNumber(absDiff, isFloat(absDiff) ? 1 : 0);

  let component = null;
  let message = '';
  switch (true) {
    case diff < 0:
      component = down;
      message = `Down ${roundedDiff} since your last report`;
      break;
    case diff == 0:
      component = equal;
      message = `No change since your last report`;
      break;
    case diff > 0:
      component = up;
      message = `Up ${roundedDiff} since your last report`;
      break;
  }

  let appearance;
  if (diff != 0) {
    if (logic === 'zero-good') {
      appearance = diff < 0 ? 'good' : 'bad';
    }
    if (logic === 'zero-bad') {
      appearance = diff > 0 ? 'good' : 'bad';
    }
  } else {
    appearance = 'nochange';
  }

  const classes = cx(styles.wrapper, {
    [styles.good]: appearance === 'good',
    [styles.bad]: appearance === 'bad',
    [styles.nochange]: appearance === 'nochange'
  });

  return (
    <Tooltip text={message} padding={5}>
      <span className={classes}>
        {component}
        {absDiff !== 0 && <span className={styles.diff}>{roundedDiff}</span>}
      </span>
    </Tooltip>
  );
};

Change.propTypes = {
  diff: PropTypes.number,
  logic: PropTypes.string
};

export default Change;
