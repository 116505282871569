exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2EuLIBWmGzj8-w48gdY3Nc{text-align:right}._3P-DPjQLht34qH7HH6svRv{display:flex;padding:30px 20px;align-items:center}._3P-DPjQLht34qH7HH6svRv h1,._3P-DPjQLht34qH7HH6svRv h2,._3P-DPjQLht34qH7HH6svRv h3,._3P-DPjQLht34qH7HH6svRv h4,._3P-DPjQLht34qH7HH6svRv h5,._3P-DPjQLht34qH7HH6svRv h6{font-weight:bold;font-size:25px;margin-bottom:0;margin-right:auto}._3P-DPjQLht34qH7HH6svRv>*{margin-right:10px}._3P-DPjQLht34qH7HH6svRv>*:last-child{margin-right:0}", ""]);

// exports
exports.locals = {
	"optionsColumn": "_2EuLIBWmGzj8-w48gdY3Nc",
	"header": "_3P-DPjQLht34qH7HH6svRv"
};