import {put, takeEvery} from 'redux-saga/effects';
import {push} from 'modules/location';
import {SHOW_PRODUCTS} from 'modules/products';
import {onboardingPath} from 'modules/onboarding/onboardingUtils';

export default function* showBuyModalSaga() {
  yield takeEvery(SHOW_PRODUCTS, function*() {
    yield put(push(onboardingPath()));
  });
}
