import React from 'react';
import Link from './link';
import cx from 'classnames';
import {splitProps} from 'utils/propUtils';
import {getStylesFromProps} from 'components/decorators/localStyles';
import buttonStyles from 'components/button/button.scss';
import linkStyles from './linkStyles.scss';

export default function LinkButton({children, className, ...props}) {
  const [styleProps, otherProps] = splitProps(props, Object.keys(buttonStyles));
  const classes = cx(
    buttonStyles.base,
    getStylesFromProps(buttonStyles, styleProps),
    linkStyles.base,
    getStylesFromProps(linkStyles, styleProps),
    className
  );

  return (
    <Link className={classes} {...otherProps}>
      {children}
    </Link>
  );
}
