import _ from 'lodash';
import {createSelector} from 'reselect';
import {testIdSelector} from 'modules/tests/testSelectors';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {latestActionResultFilters} from 'modules/actions/actionSelectors';
import {normalizeFilters, filterKey, printFilters} from 'modules/filters/filterUtils';
import {parse} from 'query-string';

export const filtersSelector = state => state.filters;

const filterParamsSelector = state => {
  return parse(state.router.location.search || '');
};

export const defaultFilterSelector = createSelector(
  filtersSelector,
  testIdSelector,
  reportIdSelector,
  (filters, testId, reportId) => {
    return filters.get(filterKey({testId, reportId})) || {};
  }
);

// either returns props.filter or calculates filter from state
export const currentFilterSelector = createSelector(
  (state, props = {}) => props.filter,
  defaultFilterSelector,
  filterParamsSelector,
  testIdSelector,
  latestActionResultFilters,
  (filterProp, defaultFilter, filter, testId, latestActionResultFilters) => {
    // Add print filters if we're on print-tests route
    // slightly hacky but works for now.
    if (window.location.href.includes('print-tests')) {
      filter = {...filter, ...printFilters, ...latestActionResultFilters};
    }

    return normalizeFilters(
      filterProp || {...defaultFilter, ...filter, ...latestActionResultFilters}
    );
  }
);

export const filterSelector = createSelector(
  currentFilterSelector,
  filter => ({
    filter
  })
);
