import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';

export default class NoEntitlementsModal extends React.Component {
  handleClose = () => {
    this.props.hideModal();
  };

  render() {
    return (
      <ModalContent title="Unable to retest" buttons={['Close']} onClickButton={this.handleClose}>
        <p>Sorry, you don't have any entitlements remaining to retest this website.</p>
      </ModalContent>
    );
  }
}
