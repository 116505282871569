import React from 'react';
import cx from 'classnames';
import Card from 'components/card';
import Icon from 'components/font-awesome';
import ImagePoller from 'components/image/imagePoller';
import styles from './reportCard.scss';
import ReportDeleteButton from 'components/button/reportDeleteButton';
import {friendlyDateTime} from 'utils/dateTimeUtils';
import Gandalf from 'components/gandalf';
import {SITE_CREATOR} from 'modules/auth/gandalf';
import {getProductNameFromSkus} from 'modules/products/productUtils';

const LoadingSpinner = (
  <div style={{margin: '47px 0', color: 'white', opacity: '0.5'}}>
    <Icon name="far fa-globe" size="4x" />
    <p style={{marginTop: '5px'}}>No screenshot</p>
  </div>
);

const hasFailedScreenshot = (
  <div style={{margin: '47px 0', color: 'white', opacity: '0.5'}}>
    <Icon name="far fa-exclamation-triangle" size="4x" />
    <p style={{marginTop: '5px'}}>Failed</p>
  </div>
);

export default class ReportCard extends React.Component {
  getImage(thumbnail) {
    const {report} = this.props;

    if (thumbnail === '#') return;

    if (report && report['website.hasFailed']) {
      return hasFailedScreenshot;
    }

    if (!thumbnail) {
      return LoadingSpinner;
    }

    return (
      <ImagePoller
        src={thumbnail}
        draggable="false"
        className={cx(styles.screenshot, 'bounceInUp')}
      >
        {LoadingSpinner}
      </ImagePoller>
    );
  }

  renderDelete = () => {
    const {
      report: {reportId},
      folderId
    } = this.props;

    return (
      <Gandalf shallPass={SITE_CREATOR}>
        <ReportDeleteButton
          reportId={reportId}
          folderId={folderId}
          className={styles.delete}
          white
        />
      </Gandalf>
    );
  };

  render() {
    const {report, className, ...props} = this.props;

    return (
      <Card
        {...props}
        id={`report-${report.name.replace(/\s+/g, '-').toLowerCase()}`}
        title={report.name}
        className={cx(styles.reportCard, className)}
        image={this.getImage(report['website.thumbnail'])}
        headerChildren={this.renderDelete()}
        label={getProductNameFromSkus([report.sku])}
        tooltip={friendlyDateTime(report.lastRunDate, 'Last tested ')}
      />
    );
  }
}
