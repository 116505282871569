import React from 'react';
import styles from './pages.scss';
import QuoteTopBar from '../quoteTopBar.js';

const SilktideFeatures = ({quote}) => (
  <div className={styles.page}>
    <QuoteTopBar quote={quote} />
    <h1>Features</h1>
    <h2>Included as standard</h2>
    <div className={styles.twoColumn}>
      <div>
        <ul>
          <li>
            <strong>Automated testing</strong> of all your webpages every 5 days.
          </li>
          <li>
            <strong>Unlimited on-demand testing</strong> of any of your pages.
          </li>
          <li>
            <strong>Unlimited users</strong> with configurable permissions.
          </li>
          <li>
            <strong>Text and email alerts</strong> configured to alert members of your team to
            relevant issues.
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <strong>Leaderboards</strong> to compare any of the websites you test.
          </li>
          <li>
            <strong>Policies</strong> to define and enforce your custom rules.
          </li>
          <li>
            <strong>Integration</strong> with supported Content Management Systems.
          </li>
          <li>
            <strong>Online help and support</strong> via live chat and email.
          </li>
        </ul>
      </div>
    </div>
    <h2>Optional modules</h2>
    <table className={styles.table}>
      <thead>
        <tr>
          <th style={{width: '140px'}}>Module</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Quality</strong>
          </td>
          <td>
            Covers spelling, grammar, broken links, readability, prohibited content, inventory of
            your website content.
          </td>
        </tr>
        <tr>
          <td>
            <strong>Accessibility</strong>
          </td>
          <td>
            For making accessible to users with disabilities, covering the latest WCAG 2.1 standard
            <sup>*</sup>.
          </td>
        </tr>
        <tr>
          <td>
            <strong>Marketing</strong>
          </td>
          <td>
            Covers SEO, local search ranking, backlinks, mobile optimization, speed, UX best
            practice.
          </td>
        </tr>
        <tr>
          <td>
            <strong>Privacy (GDPR)</strong>
          </td>
          <td>Helps comply with the General Data Protection Regulation (GDPR).</td>
        </tr>
      </tbody>
    </table>
    <p className={styles.footnote}>
      <sup>*</sup> WCAG 2.1 includes all of WCAG 2.0, with additional checks. The US' Section 508
      adopted WCAG 2.0 in 2018. The EU Directive on the Accessibility of Websites and Mobile
      Applications adopted WCAG 2.1 in 2018.
    </p>
  </div>
);

export default SilktideFeatures;
