exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1gk83JgzcT76KRTgrlWMpF{color:#777}._1gk83JgzcT76KRTgrlWMpF h4{color:#000;font-weight:bold}._2NQB1JUWajM72CMTxnmSr7{float:right;margin:10px 0}._1I3mclIADxt7LEqwR6UPXa{margin:10px 5px 20px;font-size:18px}", ""]);

// exports
exports.locals = {
	"planName": "_1gk83JgzcT76KRTgrlWMpF",
	"cancelButton": "_2NQB1JUWajM72CMTxnmSr7",
	"lead": "_1I3mclIADxt7LEqwR6UPXa"
};