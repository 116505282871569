import React from 'react';

class Source extends React.Component {
  render() {
    return null; // Intentionally invisible
  }
}

// Important: we need to handle loading ourselves or we'll be shown as a hideous spinner when the page loads
Source.handlesLoading = true;

export default Source;
