import React from 'react';
import L from 'leaflet';
import {Marker, Popup, TileLayer, GeoJSON} from 'react-leaflet';
import Map from 'components/map';
import Control from 'react-leaflet-control';
import {magicallyInterpolate} from 'utils/interpolationUtils';
import styles from './choropleth.scss';

const FEATURE_OPACITY = 0.6; // opacity for feature color

export default class ChoroPleth extends React.Component {
  state = {
    selectedFeature: {}
  };

  loadGeoJSON() {
    const {geometry} = this.props.config;
    const json = require('./' + geometry + '.geo.json');
    return json;
  }

  getFilteredGeoData() {
    const json = this.loadGeoJSON();

    // TODO: inefficient
    const features = this.props.result.map(result => {
      const feature = json.features.find(feature => feature.id == result.geometry);
      return {...feature, value: result.value};
    });

    return {...json, features};
  }

  getStyle = (feature, isHovered = false) => {
    const isSelected = this.state.selectedFeature == feature;

    return {
      fillColor: getColor(feature.value, this.props.config.max || 100),
      weight: 1,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: isHovered || isSelected ? 0.8 : FEATURE_OPACITY
    };
  };

  setupFeature = (feature, layer) => {
    layer.on({
      mouseover: this.hoverHandler(true, feature, layer),
      mouseout: this.hoverHandler(false, feature, layer)
      // click: this.clickHandler(feature, layer)
    });
  };

  hoverHandler = (isOver, feature, layer) => e => {
    layer.setStyle(this.getStyle(feature, isOver));

    this.setState({selectedFeature: isOver ? feature : {}});
  };

  clickHandler = feature => e => {
    // weird
    L.DomEvent.stopPropagation(e);

    this.setState({selectedFeature: feature});
  };

  render() {
    const {
      config: {max, unit}
    } = this.props;

    return (
      <Map onClick={this.clickHandler({})}>
        <GeoJSON
          data={this.getFilteredGeoData()}
          style={this.getStyle}
          onEachFeature={this.setupFeature}
        />
        <Control>
          <InfoBox
            value={this.state.selectedFeature.value}
            unit={unit}
            config={this.props.config}
          />
        </Control>
        <Control position="bottomright">
          <Legend maxVal={max || 100} />
        </Control>
      </Map>
    );
  }
}

function InfoBox({value, config, unit}) {
  const hasValue = value !== undefined;

  if (!hasValue) {
    return null;
  }

  return (
    <div className={styles.InfoBox}>
      {magicallyInterpolate({...config, props: {value}})}
      {unit}
    </div>
  );
}

function Legend({maxVal = 100}) {
  const amount = colors.length;
  const split = Math.round(maxVal / amount);

  return (
    <div className={styles.Legend}>
      {colors.map((color, i) => (
        <div
          key={color}
          style={{
            background: color,
            opacity: FEATURE_OPACITY,
            color: i > 4 ? 'white' : 'black',
            padding: '5px 20px',
            textAlign: 'center'
          }}
        >
          {i * split} - {i == amount - 1 ? maxVal : (i + 1) * split}
        </div>
      ))}
    </div>
  );
}

const colors = [
  '#fdf3c7',
  // '#FFEDA0',
  '#FED976',
  '#FEB24C',
  '#FD8D3C',
  '#FC4E2A',
  '#E31A1C',
  '#BD0026',
  '#800026',
  '#4d0017'
];

// returns a color from above array based on value
function getColor(val, maxVal = 100) {
  // v is between 0 and 100.
  const idx = Math.floor(val / (maxVal / colors.length));
  return colors[Math.min(idx, colors.length - 1)];
}
