import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_COURSES} from 'modules/courses/constants';
import {coursesEndpoint} from 'modules/courses/utils';
import {requestCoursesComplete} from 'modules/courses/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestCoursesListener() {
  yield takeLatest(REQUEST_COURSES, requestCoursesSaga);
}

function* requestCoursesSaga({type, ...action}) {
  const url = coursesEndpoint(action);

  yield processRequest(
    'GET',
    url,
    {
      *success(data) {
        yield put(requestCoursesComplete(null, data));
      },
      *error(err) {
        yield put(requestCoursesComplete(err));
      },
      errorMessage: couldNot('request courses')
    },
    action
  );
}
