import {takeEvery, put, call} from 'redux-saga/effects';
import {fetchAccountDecisions} from '../decisionUtils';
import {FETCH_ACCOUNT_DECISIONS, receiveAccountDecisions} from '../decisions';

export default function* fetchAccountDecisionsListen() {
  yield takeEvery(FETCH_ACCOUNT_DECISIONS, fetchAccountDecisionsSaga);
}

function* fetchAccountDecisionsSaga({filters}) {
  const accountDecisions = yield call(fetchAccountDecisions, filters);

  yield put(receiveAccountDecisions(accountDecisions));
}
