import {reportPath} from 'modules/reports/reportUtils';
import {actionPath} from 'modules/actions/actionUtils';

const pathBuilders = {
  report: reportPath,
  task: actionPath, // TODO support old name for now incase backend hasn't been updated (10th jan 2019)
  action: actionPath
};

export function linkDescriptionToPath({type: linkType, ...params}) {
  const pathBuilder = pathBuilders[linkType];
  if (!pathBuilder) {
    throw new Error(`no path builder for link type ${linkType}`);
  }
  return pathBuilder(params);
}
