import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

class HeightFixer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minHeight: 0
    };
  }

  updateHeight() {
    const el = findDOMNode(this);
    const {offsetHeight} = el;

    if (offsetHeight > this.state.minHeight) {
      this.setState({minHeight: offsetHeight});
    }
  }

  componentDidMount() {
    this.updateHeight();
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  componentWillUpdate() {
    this.updateHeight();
  }

  render() {
    const {children} = this.props;
    const {minHeight} = this.state;

    return <div style={{minHeight: minHeight + 'px'}}>{children}</div>;
  }
}

HeightFixer.propTypes = {};

export default HeightFixer;
