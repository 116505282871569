exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".po4Je74zq2RiVBjJLY3iD{padding:30px;font-size:18px;text-align:center;flex-direction:column}.po4Je74zq2RiVBjJLY3iD ._3jdCRqbpLkiNypV5XpocQ7{display:block;margin-bottom:25px}.-AB1UPdv6TRqO537pwTQL{background:#fff;border-top:0}@media print{.po4Je74zq2RiVBjJLY3iD{page-break-inside:avoid}.-AB1UPdv6TRqO537pwTQL{border:0}}", ""]);

// exports
exports.locals = {
	"container": "po4Je74zq2RiVBjJLY3iD",
	"face": "_3jdCRqbpLkiNypV5XpocQ7",
	"panel": "-AB1UPdv6TRqO537pwTQL"
};