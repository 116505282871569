import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'components/helmet';
import {nextStep, prevStep} from 'modules/onboarding';
import {onboardingSelector} from 'modules/onboarding/onboardingSelectors';

export class OnboardingPage extends React.Component {
  render() {
    const {component: Component, ...props} = this.props;

    if (!Component) return null;

    return (
      <div>
        <Helmet title="Sign up" />

        <Component {...props} />
      </div>
    );
  }
}

export default connect(
  onboardingSelector,
  {nextStep, prevStep}
)(OnboardingPage);
