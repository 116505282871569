import {take} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {VIEW_TREND_SCREEN, trendScreensEndpoint, receiveTrendScreens} from '..';

export default function* fetchTrendScreensSaga() {
  const action = yield take(VIEW_TREND_SCREEN);
  yield processRequest('GET', trendScreensEndpoint(action), {
    successAction: receiveTrendScreens
  });
}
