import {filterSelector} from 'modules/filters/filterSelectors';
import {leaderboardsSelector} from 'modules/leaderboards/selectors';
import {createSelector} from 'reselect';

const auditLogStateSelector = state => {
  return state.auditLog;
};

export const accountAuditLogsSelector = createSelector(
  auditLogStateSelector,
  filterSelector,
  leaderboardsSelector,
  (state, {filter}, leaderboards) => {
    return {
      isLoading: state.get('isLoading'),
      auditLogs: state.get('auditLogs'),
      filter,
      leaderboards,
      leaderboardName: leaderboards
        ? leaderboards.get(filter.actionEntityId, new Map()).get('name', '')
        : null
    };
  }
);
