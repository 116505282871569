import React from 'react';
import Flex from 'components/layout/flex';

function InlineFields({
  children,
  center,
  basis = 150,
  spacing = 10,
  marginTop = 0,
  marginBottom = 15
}) {
  const hspacing = Math.floor(spacing / 2);
  const margin = '0 ' + hspacing + 'px';

  return (
    <Flex
      container
      justify="space-between"
      wrap
      margin={marginTop + 'px -' + hspacing + 'px ' + marginBottom + 'px -' + hspacing + 'px'}
    >
      {React.Children.map(children, child => (
        <Flex
          grow={1}
          shrink={0}
          basis={basis}
          margin={margin}
          container={center}
          justify={center ? 'center' : undefined}
        >
          {child}
        </Flex>
      ))}
    </Flex>
  );
}

export default InlineFields;
