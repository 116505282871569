import React from 'react';
import {connect} from 'react-redux';
import AuthWrapper from 'modules/auth/components/authWrapper';
import ResetPasswordContainer from 'modules/auth/pages/resetPasswordPage';
import {validatePasswordResetToken} from 'modules/passwords';
import {setLocationParamItem} from 'modules/location';

export class ResetPasswordRoute extends React.Component {
  componentWillMount() {
    const {
      match: {params},
      setLocationParamItem
    } = this.props;

    this.props.validatePasswordResetToken(params.userId, params.token);

    if (params && params.accountId) {
      setLocationParamItem('userId', params.userId);
    }
  }

  render() {
    const {
      match: {params},
      location: {pathname}
    } = this.props;

    return (
      <AuthWrapper pathname={pathname}>
        <ResetPasswordContainer userId={params.userId} token={params.token} />
      </AuthWrapper>
    );
  }
}

export default connect(
  null,
  {validatePasswordResetToken, setLocationParamItem}
)(ResetPasswordRoute);
