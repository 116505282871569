import React from 'react';
import {connect} from 'react-redux';
import {updateReport} from 'modules/reports';
import {trackEvent} from 'modules/tracking';
import ScheduleConfig from '../../components/reportSettings/scheduleConfig';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';

class ReportSettingsScheduleConfigPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed schedule config page', {
      forReport: this.props.match.params.reportId,
      fromFile: 'scheduleConfigPage.js'
    });
  }

  render() {
    return <ScheduleConfig {...this.props} name="scheduleConfig" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateReport}
)(ReportSettingsScheduleConfigPage);
