exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1xEq7QwfDbQB78MSgaZW8e{background:#533be2;border-radius:0;position:relative;padding:80px 0}.k9ico3xoVxadQi5W8-3C_{opacity:.4;width:100%;height:100%;background-size:cover;background-position:50%;overflow:hidden;position:absolute;top:0;left:0;z-index:1}._2fO4hRtfJVp67AIMIf-jH1{color:#fff;text-align:center;position:relative;z-index:2;text-shadow:0 0 20px #000}._2fO4hRtfJVp67AIMIf-jH1 h1{color:  #fff;font-size:40px}._2fO4hRtfJVp67AIMIf-jH1 p{font-size:130%}._2fO4hRtfJVp67AIMIf-jH1>*:last-child{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"banner": "_1xEq7QwfDbQB78MSgaZW8e",
	"image": "k9ico3xoVxadQi5W8-3C_",
	"inner": "_2fO4hRtfJVp67AIMIf-jH1"
};