import {call, put, takeEvery} from 'redux-saga/effects';
import POST from 'utils/saga/post';
import {RETEST_REPORT} from 'modules/reports/reportConstants';
import {processResponse} from 'utils/saga/fetchUtils';
import {receiveMission} from 'modules/missions';

export default function* retestReportSaga() {
  yield takeEvery(RETEST_REPORT, retestReport);
}

function* retestReport({reportId, urls}) {
  const res = yield call(POST, `reports/${reportId}/retest`, {urls});

  yield processResponse(res, {
    successAction: receiveMission
  });
}
