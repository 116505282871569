import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import styles from './teaser.scss';
import {contactSales} from './utils';
import {useParams} from 'react-router';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

const TeaserCount = ({children}) => {
  const params = useParams();

  return (
    <div className={styles.teaserCount}>
      {children}
      <Button onClick={() => contactSales(params.accountId)} wire>
        Contact us
      </Button>
    </div>
  );
};

TeaserCount.propTypes = propTypes;

export default TeaserCount;
