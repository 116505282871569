export function basePath({accountId} = {}) {
  return `/${accountId}`;
}

export function pageNotFoundPath() {
  return `${basePath(...arguments)}/page-not-found`;
}

export function forgottenPasswordPath() {
  return `/forgottenpassword`;
}

export function loginPath() {
  return '/login';
}

export function loginEndpoint() {
  return 'users/login?checkBoth=1';
}

export function logoutEndpoint() {
  return 'users/logout';
}

export function selfEndpoint() {
  return 'users/self';
}

export function twoFactorAuthPath() {
  return '/login/two-factor-auth';
}
