import React from 'react';
import {connect} from 'react-redux';
import CardForm from 'modules/payments/components/cardForm';
import Totals from 'modules/products/components/totals';
import Button from 'components/button';
import Logos from 'components/logos';
import OnboardingWrapper from './onboardingWrapper';
import OnboardingFormWrapper from 'modules/onboarding/components/onboardingFormWrapper';
import CircularSpinner from 'components/spinner/circularSpinner';
import styles from './onboardingWrapper.scss';
import image from './payment.png';
import {viewPaymentsPage} from 'modules/settings';
import Center from 'components/center';
import {paymentPageSelector} from 'modules/onboarding/onboardingSelectors';
import {requestProducts} from 'modules/products';

export class PaymentPage extends React.Component {
  static defaultProps = {
    name: 'onboardingPayment'
  };

  componentDidMount() {
    this.props.requestProducts({clearExisting: false});
    this.props.viewPaymentsPage();
  }

  renderIntro(subscription, hasPaymentDetails) {
    if (hasPaymentDetails) {
      return (
        <span>
          Please confirm your card details below to complete your '<b>{subscription}</b>'
          subscription. You can change or cancel at any time.
        </span>
      );
    }
    return (
      <span>
        Enter your card details below to complete your '<b>{subscription}</b>' subscription. You can
        change or cancel at any time.
      </span>
    );
  }

  renderRhs() {
    return (
      <div>
        <img className={styles.image} src={image} />
        <Logos className={styles.logos} />
      </div>
    );
  }

  renderFooter() {
    const {subscription, tax} = this.props;

    return (
      <Totals
        price={subscription.get('price')}
        priceWithTax={subscription.get('priceWithTax')}
        vatRate={tax.get('percentage')}
      />
    );
  }

  render() {
    const {formData, nextStep, errorMessage, loading, subscription, hasPaymentDetails} = this.props;

    return (
      <OnboardingWrapper rhs={this.renderRhs()} className={styles.payment}>
        {loading && <CircularSpinner />}
        {!loading && (
          <OnboardingFormWrapper
            title="You're almost there"
            intro={this.renderIntro(subscription.get('name'), hasPaymentDetails)}
          >
            <CardForm
              id="paymentForm"
              name={name}
              hasPaymentDetails={hasPaymentDetails}
              onValidSubmit={nextStep}
            />
            {this.renderFooter()}
          </OnboardingFormWrapper>
        )}
      </OnboardingWrapper>
    );
  }
}

export default connect(
  paymentPageSelector,
  {requestProducts, viewPaymentsPage}
)(PaymentPage);
