import InspectorHeaderButton from 'modules/inspector/components/inspectorHeaderButton';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {shareReport} from 'modules/share';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

export default function InspectorShareButton(props) {
  const reportId = useSelector(reportIdSelector);
  const dispatch = useDispatch();
  if (!reportId) return null;

  return (
    <InspectorHeaderButton
      icon="share-alt"
      marginRight
      onClick={() => dispatch(shareReport(reportId))}
      {...props}
    >
      Share
    </InspectorHeaderButton>
  );
}
