import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button';
import styles from './toggle.scss';
import cx from 'classnames';
import Affix from 'components/overlay/affix';
import {findDOMNode} from 'react-dom';
import KeyCodes from 'constants/keyCodes';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

class Toggle extends React.Component {
  state = {
    open: false,
    first: false
  };

  componentDidMount() {
    this.buttonRef = null;
    this.animationFrame = null;
  }

  handleToggle = e => {
    e && e.stopPropagation();
    e && e.preventDefault();

    this.toggle();
  };

  toggle(explicitValue) {
    /* eslint-disable-next-line react/no-access-state-in-setstate */
    let open = !this.state.open;

    if (_.isBoolean(explicitValue)) open = explicitValue;

    open ? this.willOpen() : this.willClose();
    this.setState({open});

    return open;
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }

  willOpen() {
    this.startListeners();
    this.props.onOpen();
  }

  willClose() {
    this.stopListeners();
    this.props.onClose();
  }

  // handleMouseUp = e => {
  //   const el = findDOMNode(this);

  //   // If the clicked element is an input we want to allow it to have focus (e.g. search in dropdowns)
  //   if (!el.contains(e.target) && e.target.tagName.toLowerCase() !== 'input') {
  //     // we use request animation  frame as
  //     // otherwise, this function seems to prevent
  //     // children from handling the click event.
  //     this.animationFrame = requestAnimationFrame(() => {
  //       this.toggle(false);
  //     });
  //   }
  // };

  handleKeyUp = e => {
    if (e.keyCode === KeyCodes.ESCAPE) {
      this.close();
    }
  };

  startListeners() {
    // document.addEventListener('mouseup', this.handleMouseUp, false);
    document.addEventListener('keyup', this.handleKeyUp, false);
  }

  stopListeners() {
    // document.removeEventListener('mouseup', this.handleMouseUp, false);
    document.removeEventListener('keyup', this.handleKeyUp, false);
  }

  componentWillUnmount() {
    this.stopListeners();

    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }

  renderButton = () => {
    const {button} = this.props;
    const {ref: originalRef} = button;

    const props = {
      onClick: this.handleToggle,
      className: cx(styles.button, _.get(button, 'props.className')),
      ref: ref => {
        this.buttonRef = ref;

        // this component doesn't always rerender even after we've got the ref
        // this forces a rerender the first time but not other times (to prevent infinite loop)
        if (ref && !this.state.first) {
          // force a rerender but ONLY once
          this.setState({first: true});
        }
        originalRef && originalRef(ref);
      }
    };

    if (_.isString(button)) {
      return <Button {...props}>{button}</Button>;
    }

    return React.cloneElement(button, props);
  };

  render() {
    const {
      className,
      children,
      onOpen,
      onClose,
      spin,
      button,
      wrapperClass,
      offsetTop,
      offsetLeft,
      ...props
    } = this.props;

    const classes = cx(styles.toggle, className, {
      [styles.open]: this.state.open
    });

    return (
      <div className={classes} {...filterInvalidDOMProps(props)}>
        {this.renderButton()}

        <Affix
          offsetTop={offsetTop}
          offsetLeft={offsetLeft}
          onClickOutside={() => {
            this.toggle(false);
          }}
          to={this.buttonRef}
          show={this.state.open}
          className={wrapperClass}
          {...props}
        >
          {children}
        </Affix>
      </div>
    );
  }
}

Toggle.propTypes = {
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  spin: PropTypes.bool
};

Toggle.defaultProps = {
  onOpen: _.noop,
  onClose: _.noop,
  spin: false
};

export default Toggle;
