import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  loaded: false,
  data: {}
});

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATIONS:
      return fromJS({
        loaded: true,
        data: keyBy(action.notifications, 'notificationId')
      });
    case RECEIVE_NOTIFICATION:
      return state.setIn(
        ['data', action.notification.notificationId.toString()],
        fromJS(action.notification)
      );
    case VIEW_NOTIFICATIONS:
      // Mark unshown as shown
      return state.update('data', data =>
        data.map(notification =>
          notification.update('shownTime', shownTime => shownTime || Date.now())
        )
      );
    case OPEN_NOTIFICATION:
      return state.setIn(['data', action.notificationId.toString(), 'readTime'], Date.now());
  }

  return state;
}

export const RECEIVE_NOTIFICATIONS = 'notifications:RECEIVE_NOTIFICATIONS';
export function receiveNotifications(notifications) {
  return {
    type: RECEIVE_NOTIFICATIONS,
    notifications
  };
}

export const RECEIVE_NOTIFICATION = 'notifications:RECEIVE_NOTIFICATION';
export function receiveNotification(notification) {
  return {
    type: RECEIVE_NOTIFICATION,
    notification
  };
}

// Saga hooks into this to redirect to the subject of the notification.
export const OPEN_NOTIFICATION = 'notifications:OPEN_NOTIFICATION';
export function openNotification(notificationId, useReplace = false) {
  return {
    type: OPEN_NOTIFICATION,
    notificationId,
    useReplace
  };
}

export const VIEW_NOTIFICATIONS = 'notifications:VIEW_NOTIFICATIONS';
export function viewNotifications() {
  return {type: VIEW_NOTIFICATIONS};
}
