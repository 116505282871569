import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './relativeDate.scss';
import {
  timestamp as getTimestamp,
  friendlyRelativeDate,
  friendlyDateTime,
  localeDate
} from 'utils/dateTimeUtils';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Tooltip from 'components/tooltip';
import Pill from 'components/pill';
import {createStructuredSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';
import {connect} from 'react-redux';

const ONE_SECOND = 1;
const ONE_MINUTE = 60;
const ONE_HOUR = 3600;

class RelativeDate extends React.Component {
  static defaultProps = {
    showTooltip: true,
    show: true
  };

  static propTypes = {
    showTooltip: PropTypes.bool,
    show: PropTypes.bool
  };

  componentDidMount() {
    const {timestamp} = this.props;

    // Timestamp 0 = epoch - ignore. Empty timestamp should be ignored.
    if (!timestamp) return;

    const milliseconds = this.getTickSeconds() * 1000;

    this.timer = setInterval(this.tick, milliseconds);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getTickSeconds() {
    const {timestamp} = this.props;

    const elapsed = getTimestamp() - timestamp;

    if (elapsed < ONE_MINUTE) {
      return ONE_SECOND;
    }
    if (elapsed < ONE_HOUR) {
      return ONE_MINUTE;
    }

    return ONE_HOUR;
  }

  tick = () => {
    this.forceUpdate();
  };

  render() {
    const {
      prefix,
      suffix,
      timestamp,
      className,
      tooltipPreferSide,
      showTooltip,
      plain,
      show,
      wrapperClass,
      absolute,
      locale,
      account,
      ...props
    } = this.props;

    if (!timestamp) return null;

    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    const userLocale = account && account.getIn(['languages', 0]);
    const dateString =
      locale && userLocale
        ? localeDate(timestamp, userLocale, options)
        : (absolute ? friendlyDateTime : friendlyRelativeDate)(timestamp);

    const tooltipText = friendlyDateTime(timestamp);

    const classes = cx(styles.time, className, {
      [styles.hide]: !show
    });

    if (plain) {
      return (
        <span className={wrapperClass}>
          {prefix}
          {dateString}
          {suffix}
        </span>
      );
    }

    return (
      <Tooltip className={wrapperClass} text={showTooltip && tooltipText} side={tooltipPreferSide}>
        <Pill tiny className={classes} {...filterInvalidDOMProps(props)}>
          {prefix}
          {dateString}
          {suffix}
        </Pill>
      </Tooltip>
    );
  }
}

RelativeDate.propTypes = {
  timestamp: PropTypes.any.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

export default connect(
  createStructuredSelector({
    account: accountSelector
  })
)(RelativeDate);
