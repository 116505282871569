import React from 'react';
import cx from 'classnames';
import styles from './totals.scss';
import Price from './price';

export default function Totals({
  price,
  priceWithTax,
  concise = false,
  vatRate,
  planName = 'Subtotal'
}) {
  // If there is no tax, don't show breakdown
  concise = concise || price === priceWithTax;

  const vat = priceWithTax - price;

  return (
    <div className={styles.totals}>
      {!concise && (
        <span>
          <Total name={planName} value={price} interval="month" />
          <Total name={'VAT (' + vatRate + '%)'} value={vat} interval="month" />
        </span>
      )}
      <Total value={priceWithTax} interval="month" className={styles.summaryTotal} />
    </div>
  );
}

function Total({value = 0, name = 'Total', interval = null, className}) {
  return (
    <div className={cx(styles.total, className)}>
      <span className={styles.totalName}>{name}</span>
      <Price className={styles.totalValue} price={value} interval={interval} currency="USD" />
    </div>
  );
}
