import React from 'react';
import {connect} from 'react-redux';
import Table from 'components/table/sortableTable';
import Button from 'components/button';
import {editReport} from 'modules/reports';
import styles from './reportsTable.scss';
import DraggableReportTableRow from './draggableReportTableRow';
import cx from 'classnames';
import {triggerEditFolderView} from 'modules/folderViews';
import ReportDeleteButton from 'components/button/reportDeleteButton';

export class ReportsTable extends React.Component {
  normalizeName(name) {
    // we know that things prefixed with 'report' need changing (this is because of the backend)
    if (name.substr(0, 7) == 'report.') {
      return name.replace(/^report\./, '');
    }
    return name;
  }

  getColumns() {
    const {view, editable} = this.props;
    const {columns} = view;

    if (!columns) {
      return [];
    }

    const cols = columns.map(({type, label, name}) => {
      return {field: this.normalizeName(name), label, type};
    });

    if (editable) {
      cols.push({
        type: 'jsx',
        field: 'reportId',
        label: (
          <Button wire onClick={this.handleEdit}>
            Edit
          </Button>
        ),
        className: styles.optionsColumn,
        jsx: ({data}) => <ReportDeleteButton reportId={data} folderId={this.props.view} />
      });
    }

    return cols;
  }

  handleEdit = event => {
    const {folderId, viewId} = this.props.view;

    event && event.preventDefault();
    event && event.stopPropagation();

    this.props.triggerEditFolderView(folderId, viewId);
  };

  render() {
    const {results, view, onClick, className, sideScroll} = this.props;
    const columns = this.getColumns();

    if (!columns.length) {
      return null;
    }

    return (
      <div style={{width: '100%', overflowX: 'auto'}}>
        <Table
          rowComponent={DraggableReportTableRow}
          rowKey="reportId"
          className={cx(styles.reportsTable, className, {
            [styles.clickable]: !!onClick
          })}
          rows={results}
          columns={columns}
          onClick={onClick}
          sideScroll={sideScroll}
        />
      </div>
    );
  }
}

export default connect(
  null,
  {editReport, triggerEditFolderView}
)(ReportsTable);
