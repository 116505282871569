/*
 * When we change route, we often want the route `params` (reportId, accountId, etc)
 *
 * 'connected-react-router' does NOT provide these by default, so we need to add our own mechanism
 *
 * we normally change route by doing
 *
 *     push( urlBuilder(urlProperties) > url ) > action
 *
 * now we do
 *
 *     push( urlBuilder, urlProperties ) > action
 *
 * the new method automatically merges `params` with `urlProperties` before triggering the action
 */

import _ from 'lodash';
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {push, replace} from 'connected-react-router';
import {ROUTE_PUSH, ROUTE_REPLACE, pushUrlHistory, setLocationQueryParams} from 'modules/location';
import {locationParamsSelector} from 'modules/location/locationSelectors';
import {getProps} from 'utils/immutableUtils';
import {addQueryString} from 'utils/urlUtils';
import {locationSelector, queryParamsSelector} from 'modules/location/locationSelectors';
import {parse} from 'query-string';

export default function* routeChangeSaga() {
  yield takeEvery([ROUTE_PUSH, ROUTE_REPLACE], routeChange);
}

function* routeChange(action) {
  const {builder, params, paramsToPersist, persistQuery, query, event} = action;
  // Get the current route params.
  const currentParams = yield select(locationParamsSelector);
  const currentQueryParams = yield select(queryParamsSelector);

  const pathParams = persistParams(currentParams, params, paramsToPersist);
  const queryParams = persistQuery ? currentQueryParams : {};

  const {token} = currentQueryParams;

  // Generate the url (we want to persist the token for virtual users)
  let url = addQueryString(builder(pathParams), {
    ...queryParams,
    ...query,
    token
  });

  yield call(checkUrlHasNoUndefined, url);

  if (event && (event.ctrlKey || event.metaKey)) {
    window.open(url);
    return;
  }

  yield put(pushUrlHistory(url));

  // we do this in the rootRoute, but we also need to do it here before we fire the route change,
  // otherwise components that are connected to the redux router query state will have their
  // selectors run too early thus they get the wrong query props.

  // NOTE we can't use the query passed into addQueryString because the builder gets given pathParams
  //      which maps pathParams to query params, so we have to analyse what the builder returns

  const questionIndex = url.indexOf('?');
  if (questionIndex >= 0) {
    const newQuery = parse(url.slice(questionIndex));
    yield put(setLocationQueryParams(newQuery));
  }

  // Call the corresponding connected-react-router action with the url
  const routerAction = action.type === ROUTE_PUSH ? push : replace;
  yield put(routerAction(url));
}

// The new route params are a combination of those set in the action
// and any we want to persist from the current route
function persistParams(currentParams, newParams, paramsToPersist = []) {
  newParams = _.omitBy(newParams, _.isNil);

  // We always want to persist accountId through route changes.
  const paramsToAlwaysPersist = ['accountId'];

  const keptParams = getProps(currentParams, [...paramsToPersist, ...paramsToAlwaysPersist]);
  return {...keptParams, ...newParams};
}

function* checkUrlHasNoUndefined(url) {
  // If we have an undefined in the URL and it's before the ? - we seem to have an issue
  const undefinedIndex = url.indexOf('undefined');
  const questionIndex = url.indexOf('?');

  if (undefinedIndex !== -1 && (questionIndex === -1 || undefinedIndex < questionIndex)) {
    const locationState = yield select(locationSelector);
    const payload = {locationHistory: locationState.urlHistory || []};

    console.log('An undefined was found in the URL', payload);
    window.Rollbar &&
      window.Rollbar.error &&
      window.Rollbar.error('An undefined was found in the URL', payload);
  }
}
