import React from 'react';
import ReactDOM from 'react-dom';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

const iframeStyle = {
  display: 'block',
  border: '0',
  width: '100%',
  height: 'auto',
  transition: 'height 300ms ease-in-out'
};

export default class Frame extends React.Component {
  static defaultProps = {
    isOpen: true
  };

  componentDidMount() {
    this.lastHead = null;
    this.lastBody = null;
    this.iframeHead = this.node.contentDocument.head;
    this.iframeRoot = this.node.contentDocument.body;
    this.mounted = false;

    this.forceUpdate();
  }

  componentDidUpdate() {
    if (!this.mounted) {
      this.callRefs();
      this.mounted = true;

      setTimeout(() => {
        this.toggleHeight(this.props.isOpen);
      }, 100);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.toggleHeight(nextProps.isOpen);
    }
    this.callRefs();
  }

  callRefs() {
    if (this.props.bodyRef && this.iframeRoot !== this.lastBody) {
      this.props.bodyRef(this.iframeRoot);
      this.lastBody = this.iframeRoot;
    }
    if (this.props.headRef && this.iframeHead !== this.lastHead) {
      this.props.headRef(this.iframeHead);
      this.lastHead = this.iframeHead;
    }
  }

  toggleHeight(isOpen) {
    if (isOpen) {
      this.node.style.height = this.iframeRoot.firstChild.clientHeight + 'px';
    } else {
      this.node.style.height = '0px';
    }
  }

  render() {
    const {children, head, ...rest} = this.props;

    return (
      <iframe {...filterInvalidDOMProps(rest)} ref={node => (this.node = node)} style={iframeStyle}>
        {this.iframeHead && ReactDOM.createPortal(head, this.iframeHead)}
        {this.iframeRoot && ReactDOM.createPortal(children, this.iframeRoot)}
      </iframe>
    );
  }
}
