import {
  FETCH_ALERT_SUBSCRIPTIONS,
  FETCH_ALERT_SUBSCRIPTIONS_COMPLETE,
  RECEIVE_ALERT_SUBSCRIPTIONS,
  RECEIVE_AVAILABLE_STORY_TYPES,
  SET_ALERT_SUBSCRIPTION,
  SET_ALERT_SUBSCRIPTION_STATE,
  OPEN_EDIT_USER_ALERTS_MODAL
} from './constants';

export function fetchAlertSubscriptions(reportId) {
  return {type: FETCH_ALERT_SUBSCRIPTIONS, reportId};
}

export function fetchAlertSubscriptionsComplete(error = null) {
  return {type: FETCH_ALERT_SUBSCRIPTIONS_COMPLETE, error};
}

export function receiveAlertSubscriptions(alertSubscriptions) {
  return {type: RECEIVE_ALERT_SUBSCRIPTIONS, alertSubscriptions};
}

export function receiveAvailableStoryTypes(storyTypes) {
  return {type: RECEIVE_AVAILABLE_STORY_TYPES, storyTypes};
}

export function setAlertSubscription(params, storyTypes, isSubscribed) {
  return {type: SET_ALERT_SUBSCRIPTION, params, storyTypes, isSubscribed};
}
export function setAlertSubscriptionState(alertSubscription) {
  return {type: SET_ALERT_SUBSCRIPTION_STATE, alertSubscription};
}

export function openEditUserAlertsModal(userId) {
  return {type: OPEN_EDIT_USER_ALERTS_MODAL, userId};
}
