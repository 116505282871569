import _ from 'lodash';
import {isIterable, mapValues} from 'utils/collectionUtils';

export function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Given an input array like ['a', 'b', 'c', 'd'] it will produce an output
 * like: 'a, b, c and d'. The final operand can be specified if necessary.
 */
export function createHumanReadableList(list, operand = 'and') {
  const last = list.pop();
  if (list.length == 0) {
    return last;
  }
  return list.join(', ') + ' ' + operand + ' ' + last;
}

export function toString(obj) {
  if (obj && obj.toString) {
    return obj.toString();
  }
}

/**
 * This was taken from 'normalizeUtils' and normalizr isn't something that we
 * use anymore I believe. It's only used in one place but without better knowledge
 * of what the API returns I can't tell if it's genuinely needed. Putting here
 * for safety.
 */
export function stringifyValue(value) {
  if (typeof value === 'number') {
    return value.toString();
  }

  if (isIterable(value)) {
    return mapValues(value, stringifyValue);
  }

  return value;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export function escapeRegex(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function randomHash(len = 40) {
  var str = '';
  while (str.length <= len) {
    str += Math.random()
      .toString(16)
      .slice(2, 6);
  }
  return str.slice(0, len);
}

export function getNextSort(prop, currentSort = '') {
  const [nextProp, sort] = currentSort.split(/\s+/, 2);

  if (!sort || prop != nextProp) {
    return `${prop} asc`;
  }
  if (sort === 'asc') {
    return `${prop} desc`;
  }
  if (sort === 'desc') {
    // we used undefined and not null because undefined values will get unset in the query string
    return `${nextProp}`;
  }

  // handles edge cases for any malformed input
  return `${prop} asc`;
}

// test if form number is numerical string
export function isNumerical(str) {
  return /^[0-9]+$/.test(str);
}

//
export function removeChars(str, arr) {
  return str
    .split('')
    .filter(char => !arr.includes(char))
    .join('');
}

export function removeNonAlphaNumeric(str) {
  return str
    .split('')
    .filter(char => isNumerical(char))
    .join('');
}
