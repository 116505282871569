exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3z83CFoyUbKjdYvXPifmFI:not(._8V1CQGeh36F6Xgyqmco-B){max-width:800px;margin-bottom:30px}._2TP-DTJistxJwpjvSNSKdO{display:flex;align-items:center}.DBB--FsuOVoYFNnUTQ2KL{margin-right:10px;color:gray;margin-bottom:10px}._31kzP3BJB9oH404Pe0MBS4{display:none}._2fmJSnbRNkRPxBYiIisQQZ{color:#000;font-weight:600;margin-bottom:3px}._3z83CFoyUbKjdYvXPifmFI textarea{min-height:140px}@media(max-width: 425px){.bMtUpRBTG7dpefEjFa7fT{margin-bottom:10px}._1ooqvitY5aDC9i0JH4nQxK{flex-direction:column;margin-bottom:10px}}", ""]);

// exports
exports.locals = {
	"wrapper": "_3z83CFoyUbKjdYvXPifmFI",
	"noMargin": "_8V1CQGeh36F6Xgyqmco-B",
	"linear": "_2TP-DTJistxJwpjvSNSKdO",
	"label": "DBB--FsuOVoYFNnUTQ2KL",
	"hidden": "_31kzP3BJB9oH404Pe0MBS4",
	"description": "_2fmJSnbRNkRPxBYiIisQQZ",
	"item": "bMtUpRBTG7dpefEjFa7fT",
	"itemWrapper": "_1ooqvitY5aDC9i0JH4nQxK"
};