import React from 'react';
import styles from './courseProgress.scss';
import {push} from 'modules/location';
import {coursePath} from 'modules/courses/utils';
import {useDispatch} from 'hooks/redux';
import Icon from 'components/font-awesome';
import Alert from 'components/alert';

const CourseProgress = ({courseId, name, percentComplete}) => {
  const dispatch = useDispatch();

  if (percentComplete === 100) return null;

  return (
    <Alert level="warning" className={styles.courseProgress}>
      <div className={styles.messageWrapper}>
        <Icon name="fa fa-graduation-cap" className={styles.iconWrapper} />
        <div className={styles.submessageWrapper}>
          <p>
            You are enrolled on - <b>{name}</b>{' '}
            <span className={styles.percentage}>({Math.ceil(percentComplete)}% complete)</span>
            <button
              onClick={() =>
                dispatch(
                  push(coursePath, {
                    courseId
                  })
                )
              }
            >
              Go to course
            </button>
          </p>
        </div>
      </div>
    </Alert>
  );
};

export default CourseProgress;
