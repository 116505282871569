import {events} from 'modules/analytics';
import {loggedInSelector} from 'modules/auth/authSelectors';
import {skipAccountStepSelector} from 'modules/onboarding/onboardingSelectors';
import PlanPage from 'modules/onboarding/components/planPage';
import UserPage from 'modules/onboarding/components/userPage';
import PaymentPage from 'modules/onboarding/components/paymentPage';
import OrganisationPage from 'modules/onboarding/components/organisationPage';

export const PLAN = 0;
export const USER = 1;
export const ORGANISATION = 2;
export const PAYMENT = 3;

// This defines the components that are used to gather data for onboarding
export const stepMap = [
  {
    component: PlanPage,
    analyticsEvents: events.ONBOARDING_VIEW_PLANS
  },
  {
    component: UserPage,
    skipStep: loggedInSelector,
    analyticsEvents: events.ONBOARDING_VIEW_CREATE_USER
  },
  {
    component: OrganisationPage,
    skipStep: skipAccountStepSelector,
    analyticsEvents: events.ONBOARDING_VIEW_CREATE_ACCOUNT
  },
  {
    component: PaymentPage,
    analyticsEvents: events.ONBOARDING_VIEW_CREATE_PAYMENT
  }
];
