import * as Actions from './constants';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';

export function requestInteraction(filters) {
  return {type: Actions.REQUEST_INTERACTION, ...filters};
}
export function requestInteractionComplete(error, data) {
  return {type: Actions.REQUEST_INTERACTION_COMPLETE, error, data};
}

export function requestDeleteInteraction(interactionId) {
  return {type: Actions.REQUEST_DELETE_INTERACTION, interactionId};
}

export function requestDeleteInteractionComplete(error, data) {
  return {type: Actions.REQUEST_DELETE_INTERACTION_COMPLETE, error, data};
}

export function requestInstructionTypes(filters) {
  return {type: Actions.REQUEST_INSTRUCTION_TYPES, filters};
}
export function requestInstructionTypesComplete(error, data) {
  return {type: Actions.REQUEST_INSTRUCTION_TYPES_COMPLETE, error, data};
}

export function saveInteraction(interaction) {
  return {type: Actions.SAVE_INTERACTION, interaction};
}
export function saveInteractionComplete(error) {
  return {type: Actions.SAVE_INTERACTION_COMPLETE, error};
}

export function testInteraction(filters) {
  return {type: Actions.TEST_INTERACTION, ...filters};
}

export function testInteractionComplete(error, data) {
  return {type: Actions.TEST_INTERACTION_COMPLETE, error, data};
}

export function clearTestResults() {
  return {type: Actions.CLEAR_TEST_RESULTS};
}

export function viewInteractions() {
  return push(testPath, {testId: 'interactions-screen'}, ['reportId'], false);
}

export function viewInteraction(interactionId) {
  return push(testPath, {testId: 'interactions-screen'}, ['reportId'], true, {interactionId});
}
