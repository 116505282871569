exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2On4L3HwqlllhXjkotcJwC{text-align:right;margin-right:10px}.zOnfNL6IM3We6VdEPKv_N{max-width:100px;margin-right:10px}.lHDgUPz7mTDqVKvaJFmfc{max-width:200px}", ""]);

// exports
exports.locals = {
	"label": "_2On4L3HwqlllhXjkotcJwC",
	"period": "zOnfNL6IM3We6VdEPKv_N",
	"interval": "lHDgUPz7mTDqVKvaJFmfc"
};