import React from 'react';
import {connect} from 'react-redux';
import {viewAction, updateAction} from 'modules/actions/actions';
import {actionWithLayoutSelector} from 'modules/actions/actionSelectors';
import ActionHeader from './actionHeader';
import ActionLayout from './actionLayout';
import Pusher from 'modules/pusher/components/pusher';
import Spinner from 'components/spinner';
import styles from './actionContainer.scss';
import {reportChannel} from 'modules/pusher/channels';
import {testPath} from 'modules/tests/testUtils';
import {push} from 'modules/location/location'; // TODO circular ref
import Status from 'components/status';

export class ActionContainer extends React.Component {
  componentDidMount() {
    this.fetchAction();
  }

  fetchAction = () => {
    const {reportId, actionId, testId} = this.props;
    this.props.viewAction(reportId, actionId, testId);
  };

  updatedActions = actionIds => {
    const {actionId} = this.props;

    // need to figure this one out... just endlessly goes on
    // if (actionIds.indexOf(parseInt(actionId, 10)) !== -1) {
    // this.fetchAction();
    // }
  };

  render() {
    const {action, testId, reportId, updateAction, test, parentTest, push} = this.props;

    if (!action) {
      return (
        <div className={styles.ActionContainer}>
          <Spinner />
        </div>
      );
    }

    const {actionId, layout, state, defaultFilters} = action;

    return (
      <div className={styles.ActionContainer}>
        <Pusher
          channel={reportChannel({params: {reportId}})}
          event="updatedTasks"
          onUpdate={actionIds => this.updatedActions(actionIds)}
        />
        <div className={styles.top}>
          {test && (
            <p className={styles.breadcrumb}>
              <a onClick={() => push(testPath, {reportId, testId: parentTest.get('testId')})}>
                {parentTest ? parentTest.get('name') : ''}
              </a>{' '}
              {parentTest ? '/' : ''}{' '}
              <a onClick={() => push(testPath, {testId}, ['reportId'])}>{test.get('name')}</a>
            </p>
          )}
          <ActionHeader
            action={action}
            testId={testId}
            reportId={reportId}
            updateAction={updateAction}
            layout={layout}
          />
        </div>
        <div className={styles.main}>
          {state !== 'ignored' && (
            <ActionLayout
              reportId={reportId}
              actionId={actionId}
              layout={layout}
              testId={testId}
              defaultFilters={defaultFilters}
            />
          )}
          {state == 'ignored' && (
            <Status appearance="warning">
              Action has been ignored. It won't count towards the score for this site.
            </Status>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  actionWithLayoutSelector,
  {viewAction, updateAction, push}
)(ActionContainer);
