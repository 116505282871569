import React from 'react';
import {changeLogUrl, updatesUrl} from 'constants/externalUrls';
import styles from '../pages/homePage.scss';
import HomePageSection from './homePageSection';
import Icon from 'components/font-awesome';
import Center from 'components/center';
import Button from 'components/button';
import RelativeDate from 'components/time/relativeDate';
import ExternalLink from 'modules/location/externalLink';

export default class RecentUpdates extends React.Component {
  static defaultProps = {
    updates: []
  };

  handleClickUpdate = (row, column) => {
    const {link} = row;
    link && window.open(link, '_blank');
  };

  handleClickMoreUpdates = () => window.open(updatesUrl, '_blank');

  handleClickChangeLog = () => window.open(changeLogUrl, '_blank');

  getRecentUpdates = () => {
    const {updates} = this.props;

    return updates.map((update, idx) => {
      update.rowKey = 'update_' + idx;
      return update;
    });
  };

  renderUpdate = (update, idx) => {
    return (
      <ExternalLink key={idx} href={update.link} className={styles.updateLink}>
        <Icon name="check" className={styles.icon} />
        {update.title}
        <br />
        <RelativeDate showTooltip={false} timestamp={update.pubDate} className={styles.date} />
      </ExternalLink>
    );
  };

  render() {
    const {updates} = this.props;

    if (!updates.length) {
      return null;
    }

    return (
      <HomePageSection title="What's new">
        {this.getRecentUpdates().map(this.renderUpdate)}
        <Center marginBottom={20}>
          <Button onClick={this.handleClickChangeLog} className={styles.btn} wire tiny>
            Full change log
          </Button>
        </Center>
        <hr />
      </HomePageSection>
    );
  }
}
