import React from 'react';
import {connect} from 'react-redux';
import {showInModal} from 'modules/modal';
import Icon from 'components/font-awesome';
import {trackEvent} from 'modules/tracking';
import {ReportGandalf} from 'components/gandalf';
import ConfirmationModal from 'modals/confirmationModal';
import {ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';
import CircularSpinner from 'components/spinner/circularSpinner';
import Dropdown, {DropdownOption} from 'components/dropdown';
import {locationParamsSelector} from 'modules/location/locationSelectors';
import {retestPage, requestIncludePage, requestExcludePage} from 'modules/reports';
// This cell type is only used by layout components.

export class PageOptions extends React.Component {
  retestPage = () => {
    const {reportId, pageHash, testId} = this.getIds();
    this.props.retestPage(reportId, pageHash, testId);
  };

  showConfirmRetest = () => {
    const {reportId} = this.getIds();

    trackEvent('Retest page prompt', {
      modalToTrigger: 'ConfirmationModal',
      fromFile: 'pageOptions.js',
      reportId
    });

    this.props.showInModal(ConfirmationModal, {
      title: 'Are you sure?',
      content: 'Are you sure you wish to retest this page?',
      onSubmit: this.retestPage
    });
  };

  setPageState(actionCreator) {
    const {reportId, pageHash, testId} = this.getIds();
    // Technically metricName  should always be passed as this component is always called inside the ExplorerTable layout component.
    actionCreator(reportId, pageHash, testId);
  }

  excludePage = () => {
    this.setPageState(this.props.requestExcludePage);
  };

  includePage = () => {
    this.setPageState(this.props.requestIncludePage);
  };

  getIds() {
    const {reportId, testId} = this.props;
    const {pageHash} = this.props.record;

    return {reportId, pageHash, testId};
  }

  stopPropagation = e => {
    e.stopPropagation();
  };

  getSpinner() {
    return (
      <div style={{padding: '6px'}}>
        <CircularSpinner small />
      </div>
    );
  }

  getIncludeExcludeMenuItem() {
    const {downloadStatus} = this.props.record;

    if (downloadStatus === 'x') {
      return <DropdownOption onClick={this.includePage}>Stop excluding</DropdownOption>;
    }

    return <DropdownOption onClick={this.excludePage}>Exclude page</DropdownOption>;
  }

  render() {
    const {reportId} = this.props;
    const {isRunning} = this.props.record;
    const icon = <Icon name="chevron-down" />;

    return (
      <span style={{whiteSpace: 'nowrap'}}>
        <span onClick={this.stopPropagation}>
          <ReportGandalf shallPass={ROLE_CONTRIBUTOR} reportId={reportId}>
            <Dropdown button={icon}>
              <DropdownOption onClick={this.showConfirmRetest}>Retest page</DropdownOption>
              {this.getIncludeExcludeMenuItem()}
            </Dropdown>
          </ReportGandalf>
          {isRunning ? this.getSpinner() : null}
        </span>
      </span>
    );
  }
}

export default connect(
  locationParamsSelector,
  {
    retestPage,
    requestIncludePage,
    requestExcludePage,
    showInModal
  }
)(PageOptions);
