import React from 'react';
import styles from '../topic.scss';
import {API} from 'config';
import Lesson from 'components/lesson';

export default class TopicLesson extends React.Component {
  render() {
    const {name, type, difficulty, url, minutes} = this.props;
    let {thumbnail} = this.props;

    if (thumbnail && !thumbnail.match(/:\/\//)) thumbnail = API.ROOT + thumbnail;

    return (
      <div className={styles.topicItemWrapper}>
        <div className={styles.topicItemIcon} />
        <Lesson thumbnail={thumbnail} name={name} minutes={minutes} url={url} type={type} />
        <div className={styles.topicItemIssues}></div>
        <div className={styles.topicProgress} />
      </div>
    );
  }
}
