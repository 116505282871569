import React from 'react';
import {connect} from 'react-redux';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import BreadcrumbBar from 'modules/breadcrumb/components/breadcrumbBar';
import BreadcrumbLink from 'modules/breadcrumb/components/breadcrumbLink';
import SecondaryNav from 'modules/app/components/secondaryNavigation/secondaryNavigation';
import {editLeaderboardPath, leaderboardPath, leaderboardsPath} from '../utils';
import {leaderboardBreadcrumbSelector} from '../selectors';
import {push} from 'modules/location';

function LeaderboardBreadcrumb({
  children,
  leaderboard,
  leaderboardOptions,
  viewLeaderboard,
  viewLeaderboards,
  type
}) {
  return (
    <PageWithFixed
      orientation="vertical"
      fixedElement={
        <SecondaryNav match={{params: {}}}>
          <BreadcrumbBar>
            <BreadcrumbLink onClick={viewLeaderboards} showBreakline>
              Leaderboards
            </BreadcrumbLink>
            {leaderboard && (
              <BreadcrumbLink
                value={leaderboard.get('leaderboardId')}
                options={leaderboardOptions.toJS()}
                onClick={viewLeaderboard}
                showBreakline={!!type}
              />
            )}
            {type === 'editting' && <BreadcrumbLink label="Edit leaderboard" />}
            {type === 'creating' && <BreadcrumbLink label="New leaderboard" />}
          </BreadcrumbBar>
        </SecondaryNav>
      }
    >
      {children}
    </PageWithFixed>
  );
}

export default connect(
  leaderboardBreadcrumbSelector,
  (dispatch, {type}) => ({
    viewLeaderboard(leaderboardId, fromOptions) {
      if (fromOptions && type === 'editting') {
        dispatch(push(editLeaderboardPath, {leaderboardId}));
      } else {
        dispatch(push(leaderboardPath, {leaderboardId}));
      }
    },
    viewLeaderboards() {
      dispatch(push(leaderboardsPath));
    }
  })
)(LeaderboardBreadcrumb);
