import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EntitySelectorList from './entitySelectorList';
import EntitySelectorToolbar from './entitySelectorToolbar';
import SearchBar from 'components/searchBar';
import styles from './entitySelect.scss';
import {ENTITY_SELECTOR_TYPES} from './constants';
import {matchesSearch} from './utils';
import cx from 'classnames';

class EntitySelector extends Component {
  state = {
    searchTerm: '',
    checked: []
  };

  static propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape).isRequired,
    onEntityClick: PropTypes.func.isRequired,
    onCheckedClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    listType: PropTypes.oneOf(ENTITY_SELECTOR_TYPES),
    handleOrderChange: PropTypes.func,
    orderBy: PropTypes.func
  };

  static defaultProps = {
    listType: 'list',
    buttonText: 'Select'
  };

  handleSearchChange = searchTerm => {
    this.setState({
      searchTerm
    });
  };

  handleSearchClear = () => {
    if (this.searchBar) {
      this.searchBar.updateValue('');
      this.handleSearchChange('');
    }
  };

  handleSelect = id => {
    const {checked} = this.state;

    if (checked.includes(id)) {
      const newSelection = checked.filter(checkedId => checkedId !== id);
      this.setState({checked: [...newSelection]});
    } else {
      this.setState({checked: [...this.state.checked, id]});
    }
  };

  handleSelectAll = () => {
    const {entities} = this.props;

    if (this.state.checked.length !== entities.length) {
      this.setState({
        checked: [...entities.map(({value}) => value)]
      });
    } else {
      this.setState({
        checked: []
      });
    }
  };

  onSelect = entity => {
    const {checked} = this.state;
    const newchecked = {...checked, ...entity};

    this.setState({
      checked: newchecked
    });
  };

  setSearchBarRef = ref => (this.searchBar = ref);

  render() {
    const {searchTerm, checked} = this.state;
    const {
      entities,
      listType,
      onEntityClick,
      buttonText,
      onCheckedClick,
      handleOrderChange,
      orderBy,
      label,
      dark
    } = this.props;

    const isEmpty = entities.length === 0;
    const showAllButton = checked.length > 0;

    let filteredEntities = [...entities];

    if (orderBy) {
      filteredEntities = filteredEntities.sort(orderBy);
    }

    filteredEntities = filteredEntities.filter(entity =>
      (typeof entity.keyword == 'string' ? entity.keyword : entity.label)
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase())
    );

    const searchStyles = {
      [styles.darkSearch]: dark
    };

    return (
      <div className={styles.entitySelect}>
        {label && (
          <label>
            <strong>{label}</strong>
          </label>
        )}

        <SearchBar
          ref={this.setSearchBarRef}
          className={cx(styles.searchBar, searchStyles)}
          onUserInput={this.handleSearchChange}
          value={searchTerm}
          dark={dark}
        />
        <EntitySelectorToolbar
          onToggleSelectAll={this.handleSelectAll}
          showButton={showAllButton}
          onClickSelectAll={() => {
            const matchingEntities = entities
              .filter(entity => matchesSearch(entity.keyword || entity.label, searchTerm))
              .map(entity => entity.value.toString());

            const checkedMinusSearch = checked.filter(checkedId =>
              matchingEntities.includes(checkedId.toString())
            );

            onCheckedClick(checkedMinusSearch);
            this.setState({checked: []});
          }}
          buttonText={buttonText}
          allChecked={this.state.checked.length === entities.length && entities.length !== 0}
          disabled={isEmpty}
          searchTerm={searchTerm}
          dark={dark}
        />

        <EntitySelectorList
          entities={filteredEntities}
          type={listType}
          onClick={onEntityClick}
          onCheck={this.handleSelect}
          multiSelectActive={showAllButton}
          checked={this.state.checked}
          buttonText={buttonText}
          searchTerm={searchTerm}
          handleClearSearch={this.handleSearchClear}
          handleOrderChange={handleOrderChange}
          dark={dark}
        />
      </div>
    );
  }
}

export default EntitySelector;
