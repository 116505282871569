exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZGDS3v43TBs0Yc_26c4fx,._1WQGQj_QJPBqBCbQp938YR{display:flex;flex-direction:row;justify-content:start;margin-bottom:15px}.ZGDS3v43TBs0Yc_26c4fx ._21dBBhG-LQB3ARBXlf3c7R,._1WQGQj_QJPBqBCbQp938YR ._21dBBhG-LQB3ARBXlf3c7R{flex:0 0 20px;display:flex;align-items:center;justify-content:center}._1WQGQj_QJPBqBCbQp938YR{margin-bottom:5px}", ""]);

// exports
exports.locals = {
	"inputRow": "ZGDS3v43TBs0Yc_26c4fx",
	"inputHeader": "_1WQGQj_QJPBqBCbQp938YR",
	"closeIcon": "_21dBBhG-LQB3ARBXlf3c7R"
};