import React from 'react';
import cx from 'classnames';

import styles from './onboardingWrapper.scss';
import Flex from 'components/layout/flex';
import Trademark from 'components/legal/trademark';
import OnboardingNavbar from 'components/nav/onboardingNavbar';
import PageWithHeader from 'components/wrappers/pageWithHeader';

export default class OnboardingWrapper extends React.Component {
  render() {
    const {children, rhs, className} = this.props;
    const header = <OnboardingNavbar className={styles.navbar} />;

    return (
      <PageWithHeader header={header} className={styles.page}>
        <div className={cx(styles.wrapper, className)}>
          <div className={cx(styles.section, styles.lhs)}>
            <Flex container column alignTop className={styles.lhsInner}>
              <Flex grow={1}>{children}</Flex>
            </Flex>

            <Trademark className={styles.footerLinks} />
          </div>
          <div className={cx(styles.section, styles.rhs)}>{rhs}</div>
        </div>
      </PageWithHeader>
    );
  }
}
