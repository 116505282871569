import _ from 'lodash';
import {List, Set} from 'immutable';
import {createSelector} from 'reselect';
import {availableTestsSelector} from 'modules/tests/testSelectors';

const checkStateSelector = (state, props) => state.checks;

export const availableChecksSelector = createSelector(
  checkStateSelector,
  state => {
    return state.get('checks');
  }
);

export const isLoadingSelector = createSelector(
  checkStateSelector,
  state => {
    return state.get('isFetching');
  }
);

// filter the testIds from all checks where the testId belongs to a test that is disabled.
// then remove any checks that have no testIds left.
// add 'testNames' property to each check for presentation.
const filteredChecksSelector = createSelector(
  availableTestsSelector,
  availableChecksSelector,
  ({availableTests}, checks) => {
    if (_.isEmpty(availableTests)) {
      return List();
    }
    return checks
      .map(check => {
        const enabledTestIds = check
          .get('testIds')
          .filter(testId => availableTests[testId].isIncluded)
          .sort();

        const enabledTestNames = enabledTestIds.map(testId => availableTests[testId].name).sort();

        return check.set('testIds', enabledTestIds).set('testNames', enabledTestNames);
      })
      .filter(check => !check.get('testIds').isEmpty())
      .sortBy(check => check.get('name'));
  }
);

const distinctCheckTestIdsSelector = createSelector(
  availableChecksSelector,
  checks => {
    return checks
      .reduce((set, check) => {
        return set.union(check.get('testIds'));
      }, Set())
      .toList();
  }
);

const availableTestOptionsSelector = createSelector(
  availableTestsSelector,
  ({availableTests}) =>
    Object.keys(availableTests).map(testId => ({
      value: testId,
      label: availableTests[testId].name
    }))
);

export const checkSettingsPageSelector = createSelector(
  filteredChecksSelector,
  availableTestOptionsSelector,
  distinctCheckTestIdsSelector,
  (checks, availableTestOptions, distinctCheckTestIds) => {
    return {
      checks,
      availableTestOptions: availableTestOptions
        .filter(({value}) => distinctCheckTestIds.includes(value))
        .sort((a, b) => {
          return a.label < b.label ? -1 : 1;
        })
    };
  }
);

export const availableChecksComponentSelector = createSelector(
  filteredChecksSelector,
  isLoadingSelector,
  (checks, isLoading) => ({
    isLoading: isLoading || checks.isEmpty(),
    defaultValue: checks.reduce((obj, check) => {
      obj[check.get('checkType')] = check.get('isIncluded');
      return obj;
    }, {})
  })
);
