import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/spinner';
import cx from 'classnames';

import styles from './passwordStrength.scss';

/* Adapted from https://github.com/mmw/react-password-strength */
export default class PasswordStrength extends React.Component {
  static propTypes = {
    changeCallback: PropTypes.func,
    className: PropTypes.string,
    inputProps: PropTypes.object,
    minLength: PropTypes.number,
    scoreWords: PropTypes.array,
    tooShortWord: PropTypes.string,
    style: PropTypes.object,
    userInputs: PropTypes.array,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    score: PropTypes.number,
    isValid: PropTypes.bool
  };

  static defaultProps = {
    changeCallback: null,
    className: '',
    minLength: 7,
    scoreWords: ['weak', 'weak', 'good', 'good', 'strong'],
    tooShortWord: 'too short',
    defaultValue: '',
    value: '',
    score: 0,
    isValid: false
  };

  constructor(props) {
    super(props);

    this.state = {
      password: props.defaultValue
    };
  }

  componentDidMount() {
    const {password} = this.state;

    if (password) {
      // If a defaultValue was set, call handleChange after mounting
      this.handleChange();
    }
  }

  clear() {
    const {changeCallback} = this.props;

    this.input.value = '';
    changeCallback && changeCallback({password: ''});
  }

  isTooShort(password) {
    return password.length < this.props.minLength;
  }

  handleChange(e) {
    const {changeCallback} = this.props;

    changeCallback &&
      changeCallback({
        password: this.input.value
      });
  }

  setInput = ref => {
    this.input = ref;
  };

  render() {
    const {
      score,
      value: password,
      scoreWords,
      inputProps,
      className,
      style,
      tooShortWord,
      isValid,
      invalid,
      isLoading
    } = this.props;

    const wrapperClasses = cx(styles.wrapper, className, {
      [styles[`isStrength${score}`]]: password.length > 0,
      [styles.invalid]: invalid
    });

    const inputClasses = [styles.input];

    if (isValid) {
      inputClasses.push(styles.isPasswordValid);
    } else if (password.length > 0) {
      inputClasses.push(styles.isPasswordInvalid);
    }

    if (inputProps && inputProps.className) {
      inputClasses.push(inputProps.className);
    }

    let strengthDesc;

    if (this.isTooShort(password)) {
      strengthDesc = tooShortWord;
    } else {
      strengthDesc = scoreWords[score];
    }

    return (
      <div className={cx(wrapperClasses, 'securePassword', {['isValid']: isValid})} style={style}>
        <input
          type="password"
          {...inputProps}
          className={cx(...inputClasses)}
          onChange={this.handleChange.bind(this)}
          ref={this.setInput}
          value={password}
        />

        {isLoading && <Spinner small className={styles.loader} />}

        <div className={styles.strengthBar} />
        <span className={cx(styles.strengthDesc, {[styles.loader]: isLoading})}>
          {strengthDesc}
        </span>
      </div>
    );
  }
}
