import React from 'react';
import {useSelector, useDispatch} from 'hooks/redux';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import {requestLesson, saveLessonState} from '../actions';
import {courseLessonPageSelector, savingLessonSelector} from '../selectors';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import CourseSidebar from '../components/courseSidebar';
import {NotFoundPage} from 'modules/location/pages/404Page';
import LessonType from '../components/lessonType';
import LessonTitle from '../components/title/lessonTitle';
import styles from './courses.scss';

export default function CourseLessonPage({
  match: {
    params: {courseId, lessonId}
  }
}) {
  const dispatch = useDispatch();
  const {lesson, course, requestingLesson, savingLesson, savingQuiz} = useSelector(
    courseLessonPageSelector,
    {
      courseId,
      lessonId,
      requestingLesson,
      savingLesson,
      savingQuiz
    }
  );

  React.useEffect(() => {
    dispatch(requestLesson(lessonId));
  }, [lessonId]);

  // set this lesson to be viewed
  React.useEffect(() => {
    if (lesson && !lesson.get('state')) {
      dispatch(saveLessonState(lesson.set('state', 'viewed').toJS()));
    }
  }, [lesson, lessonId]);

  const sidebar = (
    <CourseSidebar
      course={course}
      activeStageId={lesson.get('stageId')}
      activeLessonId={lesson.get('lessonId')}
    />
  );
  // const sidebar = React.useMemo(
  //   () => (
  //     <CourseSidebar
  //       course={course}
  //       activeStageId={lesson.get('stageId')}
  //       activeLessonId={lesson.get('lessonId')}
  //     />
  //   ),
  //   [course, lesson.get('stageId'), lesson.get('lessonId')]
  // );

  if (requestingLesson.get('error')) {
    return <NotFoundPage />;
  }

  const isLoading = !requestingLesson.get('loaded') || requestingLesson.get('loading');

  return (
    <div className={styles.background}>
      <Helmet title={lesson.get('name')} />
      <PageWithSidebar sidebar={null}>
        <div className={styles.container}>
          <PageBody isLoading={isLoading}>
            <LessonTitle course={course} lesson={lesson} />
            <LessonType
              lesson={lesson}
              saving={savingLesson.get('loading')}
              savingQuiz={savingQuiz.get('loading')}
            />
          </PageBody>
        </div>
      </PageWithSidebar>
    </div>
  );
}
