import React, {Fragment} from 'react';
import Flex from 'components/layout/flex';
import Panel from 'components/panel';
import Table from 'components/table';
import Status from 'components/status';
import SearchBar from 'components/searchBar/searchBar';
import Pagination from 'components/pagination';
import UserSelector from 'modules/users/containers/userSelector';
import ReportSelector from 'modules/reports/containers/reportSelector';
import Control from 'components/control';
import Spinner from 'components/spinner';
import {ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';
import {usersPath} from 'modules/users/userUtils';
import Link from 'modules/location/link';
import {reportPath} from 'modules/reports/reportUtils';

export default function DecisionTable({filters, decisions, pagination, isLoading, onSetFilter}) {
  const cols = [
    {
      label: 'Website name',
      width: '150px',
      field: 'reportName',
      type: 'jsx',
      collapse: '600',
      combineWith: 'Decision',
      jsx: ({data, record}) => (
        <Link builder={reportPath} reportId={record.reportId}>
          {data}
        </Link>
      )
    },
    {
      type: 'media',
      data: 'src',
      datatype: 'srcType',
      field: 'src',
      width: '100px',
      collapse: '600'
    },
    {label: 'Decision', field: 'name', type: 'decisionName', hideCollapsedLabel: true},
    {
      label: 'Action',
      field: 'actionName',
      type: 'string',
      collapse: '1000',
      combineWith: 'Decision'
    },
    {
      label: 'Made by',
      field: 'userName',
      width: '150px',
      type: 'string',
      collapse: '600',
      combineWith: 'Decision',
      hideCollapsedLabel: true
    },
    {
      label: 'Made at',
      field: 'started',
      type: 'time',
      collapse: '1000',
      combineWith: 'Made by',
      hideCollapsedLabel: true
    },
    {
      type: 'jsx',
      width: '100',
      collapse: '600',
      jsx: ({record}) => {
        return (
          /* `reportRole` is a hack because this should only be used by account owners, all of which will have a contributor role */
          /* what we should be doing is getting the reportRole for the given reportId, but that requires joining each report to this user type */
          <Control
            reportRole={ROLE_CONTRIBUTOR}
            cta
            small
            square
            icon="undo"
            command={{
              type: 'undoDecision',
              decisionId: record.decisionId,
              reportId: record.reportId
            }}
            context={record}
            loadingOnClick
          >
            Undo
          </Control>
        );
      }
    }
  ];

  // if we are filtering and there are no results
  const noFilteredResults =
    (filters.search || filters.reportId || filters.userId) && !decisions.length;
  const noResults = !noFilteredResults && Array.isArray(decisions) && !decisions.length;

  return (
    <Fragment>
      <Flex container margin="10px">
        <Flex grow={0} basis="200px" right="10px">
          <ReportSelector
            value={filters.reportId}
            onChange={reportId => onSetFilter('reportId', reportId)}
          />
        </Flex>
        <Flex grow={0} basis="200px">
          <UserSelector value={filters.userId} onChange={userId => onSetFilter('userId', userId)} />
        </Flex>
        <Flex grow={0} right basis="200px">
          <SearchBar
            onChange={event => {
              onSetFilter('search', event.target.value);
            }}
          />
        </Flex>
      </Flex>
      {isLoading ? (
        <Spinner marginTop={100} marginBottom={100} />
      ) : noFilteredResults ? (
        <Status appearance="bad">There are no results for the current filter</Status>
      ) : noResults ? (
        <Status appearance="info">No decisions have been made yet</Status>
      ) : (
        <Table rowKey="accountDecisionId" rows={decisions} columns={cols} />
      )}
      <Pagination
        inline
        marginTop
        marginBottom
        {...pagination}
        onPageSelect={page => {
          onSetFilter('page', page);
        }}
      />
    </Fragment>
  );
}
