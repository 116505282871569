import {basePath} from 'modules/auth/authUtils';

export const SUCCESS_MESSAGE = 'Changes saved';

// NOTE paths for report settings are in reportUtils

export function userSettingsPath() {
  return basePath(...arguments) + '/settings';
}

export function accountSettingsPath() {
  return basePath(...arguments) + '/settings/account';
}

export function subscriptionSettingsPath() {
  return basePath(...arguments) + '/settings/account/subscriptions';
}

export function servicesSettingsPath() {
  return accountSettingsPath(...arguments) + '/services';
}

export function serviceSettingsPath(params) {
  return accountSettingsPath(...arguments) + '/services/' + params.serviceId;
}

export function paymentSettingsPath() {
  return accountSettingsPath(...arguments) + '/payments';
}

export function checkSettingsPath() {
  return accountSettingsPath(...arguments) + '/checks';
}

export function tagsSettingsPath() {
  return accountSettingsPath(...arguments) + '/tags';
}

export function tagSettingsPath({tagId}) {
  return accountSettingsPath(...arguments) + '/tags/' + tagId;
}

export function isReportSettingsPath(path) {
  return !!path.match(/reports\/\d+\/settings/);
}
