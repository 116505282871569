import React from 'react';
import Spinner from 'components/spinner';
import RelativeDate from 'components/time/relativeDate.js';
import styles from './pageChange.scss';
import Url from 'components/url/url';
import Code from 'components/code';

import {Diff2Html} from 'diff2html';
import 'diff2html/dist/diff2html.min.css';

export default class PageChange extends React.Component {
  renderSource(result) {
    if (result.diff) {
      return this.renderDiff(result);
    }

    return <Code language="html">{result.newHtml}</Code>;
  }

  renderDiff(result) {
    // https://github.com/rtfpessoa/diff2html#user-content-configuration
    const diffHtml = Diff2Html.getPrettyHtml(result.diff, {
      inputFormat: 'diff',
      showFiles: false,
      matching: 'lines',
      outputFormat: 'side-by-side',
      synchronisedScroll: true
    });

    return <div dangerouslySetInnerHTML={{__html: diffHtml}} />;
  }

  render() {
    const {result} = this.props;

    if (!result) {
      return <Spinner marginTop={50} />;
    }

    const {
      url,
      pageName,
      percentChange,
      oldTime,
      oldScreenshotUrl,
      fullPageScreenshotUrl,
      oldFullPageScreenshotUrl,
      screenshotUrl,
      time
    } = result;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>{pageName}</h1>
          <p>
            <Url url={url} link />
          </p>
        </div>
        <div className={styles.screenshots}>
          {!!oldScreenshotUrl && (
            <div>
              <h2>
                Old page <RelativeDate timestamp={oldTime} absolute />
              </h2>
              {!!oldFullPageScreenshotUrl && (
                <img
                  src={oldFullPageScreenshotUrl}
                  alt="Old screenshot"
                  style={{maxWidth: '100%'}}
                />
              )}
              {!oldFullPageScreenshotUrl && oldScreenshotUrl && (
                <img src={oldScreenshotUrl} alt="Old screenshot" style={{maxWidth: '100%'}} />
              )}
            </div>
          )}
          <div>
            <h2>
              New page <RelativeDate timestamp={time} absolute />{' '}
              {!!oldScreenshotUrl && (
                <span>({Math.round(percentChange * 10) / 10}% difference)</span>
              )}
            </h2>
            {fullPageScreenshotUrl && (
              <img src={fullPageScreenshotUrl} alt="New screenshot" style={{maxWidth: '100%'}} />
            )}
            {!fullPageScreenshotUrl && screenshotUrl && (
              <img src={screenshotUrl} alt="New screenshot" style={{maxWidth: '100%'}} />
            )}
          </div>
        </div>

        {this.renderSource(result)}
      </div>
    );
  }
}
