import React from 'react';
import Icon from 'components/font-awesome';

class Tick extends React.Component {
  render() {
    if (this.props.data) {
      return <Icon name="fas fa-check" />;
    }

    return <Icon name="fas fa-times" />;
  }
}

export default Tick;
