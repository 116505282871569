import React from 'react';
import cx from 'classnames';
import styles from './search.scss';
import SearchIcon from './searchIcon.svg';
import Button from 'components/button';
import AddReportButton from 'modules/reports/containers/addReportButton';

export default function SearchInput({
  searchTerm,
  onChange,
  onSubmit,
  autoFocus,
  label,
  theme,
  placeholder
}) {
  return (
    <div className={cx(styles.searchInputContainer, styles[theme])}>
      {label && <label htmlFor="searchInput">{label}</label>}
      <div className={styles.searchInputWrapper}>
        <img src={SearchIcon} className={styles.searchInputIcon} alt="search icon" />

        <input
          type="text"
          value={searchTerm}
          className={styles.searchInput}
          onChange={handleChange(onChange)}
          onKeyDown={handleKeydown(onSubmit)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          aria-label={placeholder}
        />
      </div>
    </div>
    // <AddReportButton cta={false}>Add a website</AddReportButton>
  );
}

const handleChange = onChange => event => {
  onChange(event.target.value);
};

const handleKeydown = onSubmit => event => {
  if (event.keyCode === 13) {
    onSubmit(event.target.value);
  }
};
