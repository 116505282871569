import {store} from 'index';
import apiFetch from 'api/fetch';
import {userParamSelector} from 'modules/auth/authSelectors';
import {usersEndpoint, userEndpoint} from 'modules/users/userUtils';

export function validateResetTokenEndpoint() {
  return userEndpoint(...arguments) + '/validate-reset-token';
}

export function validateUnlockUserTokenEndpoint() {
  return userEndpoint(...arguments) + '/validate-unlock-token';
}

export function resetPasswordEndpoint() {
  return userEndpoint(...arguments) + '/set-password';
}

export function resetTokenEndpoint() {
  return usersEndpoint(...arguments) + '/password/reset';
}

export async function validatePassword(password) {
  const state = store.getState();
  const user = userParamSelector(state);
  const userData = [user.get('name'), user.get('email')];
  return apiFetch('password-strength', state, {password, userData}, 'post');
}
