exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16qBeGqoC8sdEp5y5gj1ES{padding:20px}._2dG8MpgTtYOr5-d7EhWcvm{padding-bottom:0}", ""]);

// exports
exports.locals = {
	"pageBody": "_16qBeGqoC8sdEp5y5gj1ES",
	"noPaddingBottom": "_2dG8MpgTtYOr5-d7EhWcvm"
};