import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import styles from './profilePage.scss';
import SettingsPage from 'components/settings/settingsPage';
import Progress from 'components/progress';
import Center from 'components/center';
import Spinner from 'components/spinner';
import Grid from 'components/grid';
import AchievementTarget from 'modules/achievements/components/achievementTarget';
import {profilePageSelector} from 'modules/profile/profileSelectors';
import Badge from 'modules/achievements/components/badge';
import {calculateProgress, getSurroundingTargets} from 'modules/achievements/achievementUtils';
import Status from 'components/status';

export class ProfilePage extends React.Component {
  renderBadge = badge => {
    const badgeKey = `achievement-${badge.get('achievementId')}`;

    return (
      <div key={badgeKey} className={styles.badge}>
        <Badge icon={badge.get('icon')} />
        <p>{badge.get('name')}</p>
      </div>
    );
  };

  renderAchievement = achievement => {
    const {name, progress, targets, achievementTrackId} = achievement;
    const [current, next] = getSurroundingTargets(progress, targets);
    const achievementKey = `achievement-${achievementTrackId}`;
    const progressPercentage = calculateProgress(progress, current, next);

    return (
      <div key={achievementKey} className={styles.achievementProgress}>
        <h4 className={styles.title}>{name}</h4>

        <AchievementTarget {...current} />

        {this.renderNextAchievement(next, progressPercentage)}
      </div>
    );
  };

  renderNextAchievement(achievement, percentage) {
    return (
      <div>
        <h5>Next achievement</h5>

        {!achievement ? (
          <p className={styles.complete}>Achievements complete! Welcome, master.</p>
        ) : (
          <Progress
            value={Math.round(percentage)}
            tooltip={achievement.description}
            className={styles.achievementProgressBar}
          >
            <Center fit>
              {achievement.name} - {Math.round(percentage)}%
            </Center>
          </Progress>
        )}
      </div>
    );
  }

  render() {
    const {badges, achievements, loading} = this.props;

    if (loading) {
      return <Spinner marginTop={100} />;
    }

    // badges are pretty much the same as achievements, so if we don't have one, we don't have the other
    if (!badges.size) {
      return (
        <SettingsPage name="Profile">
          <Status appearance="bad">No Achievements</Status>
        </SettingsPage>
      );
    }

    return (
      <SettingsPage name="Profile">
        <div className={styles.badges}>
          <h3>Badges</h3>
          <div className={styles.badgesGrid}>{badges.map(this.renderBadge)}</div>
        </div>

        <div>
          <h3>Achievements</h3>
          <Grid large>{achievements.toJS().map(this.renderAchievement)}</Grid>
        </div>
      </SettingsPage>
    );
  }
}

export default connect(profilePageSelector)(ProfilePage);
