import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import styles from './stat.scss';
import Tooltip from 'components/tooltip';
const DEFAULT_APPEARANCE = 'major';

const propTypes = {
  label: PropTypes.node.isRequired
};

function StatValue({
  onClick,
  label,
  stacked,
  appearance,
  className,
  children,
  short,
  isFilterable,
  isFiltered,
  tooltip
}) {
  const iconClassName = cx(styles.icon, styles[appearance]);
  const style = cx(styles.stat, {
    [styles.short]: short,
    [styles.filterable]: isFilterable,
    [styles.isFiltered]: isFilterable && isFiltered,
    [styles.isNotFiltered]: isFilterable && !isFiltered
  });

  const content = (
    <div className={cx(style, className)} onClick={onClick}>
      <div className={iconClassName} />
      <div className={stacked ? styles.innerStacked : styles.inner}>
        {stacked ? (
          <Fragment>
            {label}
            <div className={styles.value}>{children}</div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={styles.value}>{children}</div>
            {label}
          </Fragment>
        )}
      </div>
    </div>
  );

  return stacked ? (
    <Tooltip text={tooltip || label.props.children} side="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
}

StatValue.defaultProps = {
  onClick: () => {},
  stacked: false,
  appearance: DEFAULT_APPEARANCE,
  short: false,
  isFilterable: false,
  isFiltered: false
};

StatValue.propTypes = propTypes;

export default StatValue;
