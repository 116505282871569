import React, {Fragment} from 'react';
import Button from 'components/button';
import Icon from 'components/font-awesome';
import styles from './checkpointsFooter.scss';

class CheckpointsFooter extends React.Component {
  handleClick(e) {
    e.stopPropagation();

    const {isExpanded, onSetExpanded} = this.props;
    onSetExpanded(!isExpanded);
  }

  render() {
    const {isExpanded} = this.props;
    return (
      <div className={styles.footer}>
        <Button link onClick={e => this.handleClick(e)}>
          {isExpanded && (
            <Fragment>
              <Icon name="minus" aria-hidden="true" /> Hide more
            </Fragment>
          )}
          {!isExpanded && (
            <Fragment>
              <Icon name="minus" aria-hidden="true" /> Show more
            </Fragment>
          )}
        </Button>
      </div>
    );
  }
}

export default CheckpointsFooter;
