import React from 'react';
import Icon from 'components/font-awesome';
import {API} from 'config';
import styles from './upgrade.scss';
import UpgradeButton from 'modules/onboarding/components/upgradeButton';

export default class Upgrade extends React.Component {
  render() {
    const {
      config: {src, label}
    } = this.props;

    return (
      <div className={styles.upgrade}>
        <div className={styles.header}>
          <h4>
            <Icon name="lock" className={styles.lock} />
            &nbsp; Upgrade to a paid plan to unlock this feature. The data below is a preview.
          </h4>
          <UpgradeButton square cta className={styles.buyButton} icon="unlock">
            Upgrade now
          </UpgradeButton>
        </div>

        <div className={styles.body}>
          <p>{label}</p>
        </div>

        <div className={styles.preview}>
          <div className={styles.banner}>Example</div>
          <div className={styles.previewHeader}>
            <div className={styles.macBar}>
              <div />
              <div />
              <div />
            </div>
          </div>

          <img src={API.ROOT + src} />
        </div>
      </div>
    );
  }
}
