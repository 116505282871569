import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {uptimeMonitorContactsEndpoint} from '../utils';
import {receiveUptimeMonitorContacts} from '../actions';
import {FETCH_UPTIME_MONITOR_CONTACTS} from '../constants';

export default function* fetchUptimeMonitorContactsSaga() {
  yield takeLatest(FETCH_UPTIME_MONITOR_CONTACTS, fetchUptimeMonitorContacts);
}

function* fetchUptimeMonitorContacts(action) {
  yield processRequest('GET', uptimeMonitorContactsEndpoint(action), {
    success: function*(uptimeMonitorContacts) {
      yield put(receiveUptimeMonitorContacts(action.monitorId, uptimeMonitorContacts));
    }
  });
}
