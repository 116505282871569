import React from 'react';
import PropTypes from 'prop-types';
import styles from './maxWidth.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const MaxWidth = ({children}) => <div className={styles.pageWrapper}>{children}</div>;

MaxWidth.propTypes = propTypes;

export default MaxWidth;
