import React from 'react';
import LessonComponent from 'components/lesson/lesson';

export default class Lesson extends React.Component {
  render() {
    const {result, config} = this.props;
    if (!result) {
      return null;
    }

    return <LessonComponent {...result} big={config.layout === 'large'} />;
  }
}
