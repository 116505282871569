export function missionsEndpoint() {
  return 'missions';
}

export function missionEndpoint({missionId}) {
  return missionsEndpoint(...arguments) + `/${missionId}`;
}

export function missionIsFinished(mission) {
  return mission.status == 'completed' || mission.status == 'cancelled';
}
