import {takeLatest, put} from 'redux-saga/effects';
import {
  REQUEST_SERVICE_BREAKDOWN,
  receiveService,
  servicesError
} from 'modules/services/servicesActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {serviceBreakdownEndpoint} from 'modules/services/serviceUtils';
import {handle404} from 'modules/location';

export default function* requestServiceBreakdownSaga() {
  yield takeLatest([REQUEST_SERVICE_BREAKDOWN], function*({serviceId}) {
    yield processRequest('GET', serviceBreakdownEndpoint(serviceId), {
      successAction: receiveService,
      error: function*(err) {
        const errorMsg = err || 'Failed to load service.';
        yield put(servicesError(errorMsg));
      },
      *notFound() {
        yield put(handle404());
      }
    });
  });
}
