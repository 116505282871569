import PropTypes from 'prop-types';
import React from 'react';
import ReactHelmet from 'react-helmet';
import {APP_NAME} from 'config';

// Adds title template to standard helmet

export default class Helmet extends React.Component {
  static defaultProps = {
    titleTemplate: '%s | ' + APP_NAME
  };

  render() {
    return <ReactHelmet {...this.props} />;
  }
}
