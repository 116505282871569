import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import {countDecimalPlaces} from 'utils/numberUtils.js';
import {interpolateToJSX} from 'utils/interpolationUtils';
import {trackEvent} from 'modules/tracking';
import Input from 'components/input';

class CheckpointTargetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalErrors: []};
  }

  keyPressCheck(e) {
    if (e.keyCode == 13) {
      this.handleSubmit(e);
    }
  }

  componentWillUnmount() {
    window.onkeypress = null;
  }

  componentDidMount() {
    window.onkeypress = this.keyPressCheck.bind(this);
    _.delay(this.focus.bind(this), 500);
  }

  focus() {
    const {checkpoint} = this.props;
    const {customTargetName} = checkpoint;

    let input = this.refs[customTargetName];
    return input.refs.input.focus();
  }

  handleClickButton = button => {
    switch (button) {
      case 'Clear Target':
        return this.clearTarget();
      case 'Set Target':
        return this.handleSubmit();
    }
  };

  getButtons = () => {
    const {hasTarget, title} = this.props.checkpoint;

    // Show a "Clear target" button if the target can be cleared.
    // Always show a "Set target" button
    return [!!title.noTarget && !!hasTarget && 'Clear target', 'Set target'].filter(Boolean);
  };

  renderModal() {
    let {checkpoint} = this.props;
    if (!checkpoint) return;

    let {targetValue, customTargetName, hasTarget, title} = checkpoint;
    if (!hasTarget) targetValue = null;

    return (
      <ModalContent
        title="Set new target"
        buttons={this.getButtons}
        onClickButton={this.handleClickButton}
      >
        {this.displayErrors()}
        <Input
          ref={customTargetName}
          label={customTargetName}
          type="text"
          defaultValue={targetValue}
        />
      </ModalContent>
    );
  }

  displayErrors() {
    if (this.state.modalErrors.length === 0) {
      return;
    }

    return (
      <div className="modalErrors">
        <ul>
          {this.state.modalErrors.map(error => (
            <li>{error}</li>
          ))}
        </ul>
      </div>
    );
  }

  // Constraints are {min: int, max: int, decimals: int}
  validateTarget(target, constraints) {
    if (!constraints) return true;

    const {min, max, decimals} = constraints;
    let errors = [];

    target = parseFloat(target);
    if (isNaN(target)) {
      errors.push('The target given was not a valid number');
      this.setState({modalErrors: errors});
      return false;
    }

    if (target > max) {
      errors.push('The target value cannot be above ' + max);
    }

    if (target < min) {
      errors.push('The target value cannot be below ' + min);
    }

    if (countDecimalPlaces(target) > decimals) {
      errors.push('The target value cannot have more than ' + decimals + ' decimal places');
    }

    if (errors.length) {
      this.setState({modalErrors: errors});
      return false;
    }
    return true;
  }

  handleSubmit() {
    const {customTargetName, targetConstraints, targetValue} = this.props.checkpoint;
    const newCheckpointTarget = this.refs[customTargetName].getValue();

    if (this.validateTarget(newCheckpointTarget, targetConstraints)) {
      trackEvent('Updated checkpoint', {
        modal: 'checkpointTargetModal',
        oldValue: targetValue,
        newValue: newCheckpointTarget
      });
      this.props.onConfirm(newCheckpointTarget);
      this.reset();
    }
  }

  clearTarget() {
    trackEvent('Cleared checkpoint target', {
      modal: 'checkpointTargetModal',
      oldValue: this.props.checkpoint.targetValue
    });

    this.props.onConfirm(false);
    this.props.hideModal();
  }

  reset() {
    if (this.state.modalErrors.length) {
      this.setState({modalErrors: []});
    }
    this.props.hideModal();
  }

  render() {
    return <span>{this.renderModal()}</span>;
  }
}

export default CheckpointTargetModal;
