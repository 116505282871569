import React from 'react';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// Render a native HTML component, e.g. "div" or "h1".
export default class NativeComponent extends React.Component {
  render() {
    const {type, className, ...props} = this.props.config;
    return React.createElement(
      type,
      {className, ...filterInvalidDOMProps(props)},
      this.props.children
    );
  }
}
