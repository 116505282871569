import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import Fade from 'components/fade';
import Icon from 'components/font-awesome';
import PasswordStrength from 'components/passwordStrength';
import styles from './passwordStrengthInput.scss';
import {validatePassword} from 'modules/passwords/passwordUtils';
import AnimateHeightChange from 'components/animation/animateHeightChange';

export default class PasswordStrengthInput extends React.Component {
  static defaultProps = {
    value: ''
  };

  state = {
    score: 0,
    warnings: [],
    suggestions: [],
    isValid: !this.props.required || !!this.props.value,
    autoComplete: ''
  };

  componentDidMount() {
    this.validatedPassword = '';
    this.debouncedPasswordFeedback = _.debounce(this.getPasswordFeedback, 400);
  }

  getPasswordFeedback(password) {
    if (!password) {
      this.setState({isValid: !this.props.required}, () => {
        this.props.onChange(this.props.value);
        this.props.onBlur();
      });
      return;
    }

    this.validatedPassword = '';

    validatePassword(password).then(result => {
      var feedback = result.passwordFeedback || {};

      this.validatedPassword = password;

      this.setState(
        {
          score: result.score || 0,
          isValid: result.validPassword,
          suggestions: feedback.suggestions || [],
          isSearching: false
        },
        () => {
          this.props.onChange(this.props.value);
          this.props.onBlur();
        }
      );
    });

    this.setState({isSearching: true});
  }

  // this function is used by Field
  isValid() {
    if (this.props.required && !this.props.value) {
      // required but no value
      return {isValid: false, message: 'You should specify a password'};
    } else if (!this.props.required && !this.props.value) {
      // not required and no value
      return {isValid: true, message: undefined};
    }

    const isValid = this.state.isValid && this.validatedPassword === this.props.value;
    return {
      isValid: isValid,
      message: isValid ? '' : 'Password is not strong enough'
    };
  }

  handleChange = ({password}) => {
    const {onChange} = this.props;
    onChange && onChange(password);

    this.debouncedPasswordFeedback(password);
  };

  renderFeedback() {
    const {suggestions} = this.state;
    if (!suggestions || !suggestions.length) return null;

    return (
      <div className={styles.passwordFeedback}>
        <p>You could try:</p>
        <ul>
          {suggestions.map(suggestion => (
            <li key={suggestion}>
              <Icon name="lightbulb-o" /> {suggestion}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const {
      className,
      defaultValue,
      name,
      readOnly,
      onFocus,
      onBlur,
      placeholder,
      autoComplete,
      value,
      invalid
    } = this.props;
    const {score, isValid, isSearching} = this.state;

    return (
      <div>
        <AnimateHeightChange
          contentClassName={styles.animationWrapper}
          children={this.renderFeedback()}
        />

        <PasswordStrength
          className={className}
          score={score}
          changeCallback={this.handleChange}
          isLoading={isSearching}
          defaultValue={defaultValue}
          isValid={isValid}
          invalid={invalid}
          value={value}
          inputProps={{
            name,
            readOnly,
            onFocus,
            onBlur,
            placeholder,
            autoComplete
          }}
        />
      </div>
    );
  }
}
