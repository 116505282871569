import React from 'react';

class TextIssue extends React.Component {
  render() {
    const {
      point: {text},
      action: {actionId, decisions}
    } = this.props;

    const textWithBreaks = text.split('\n').map((item, i) => <p key={i}>{item}</p>);

    return <div>{textWithBreaks}</div>;
  }
}

export default TextIssue;
