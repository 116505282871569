import {Map} from 'immutable';
import {OPEN_HELP, CLOSE_HELP, CLEAR_URL} from './constants';

const initialState = Map({
  isOpen: false,
  helpUrl: null
});

export default function helpReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_HELP:
      return state.set('isOpen', true).set('helpUrl', action.helpUrl);

    case CLOSE_HELP:
      return state.set('isOpen', false);

    case CLEAR_URL:
      return state.set('helpUrl', null);

    default:
      return state;
  }
}
