import React from 'react';
import {connect} from 'react-redux';
import {paymentsPageSelector} from 'modules/payments/paymentSelectors';
import Helmet from 'components/helmet/helmet';
import Loading from 'components/spinner/loading';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import Table from 'components/table/table';
import TableWarning from 'components/table/tableWarning';
import TransitionGroup from 'components/transitionGroup';
import PaymentDetails from 'modules/payments/components/paymentDetails';
import Button from 'components/button';
import {downloadInvoice} from 'modules/accounts';
import {viewPaymentsPage} from 'modules/settings';
import HasPaymentGandalf from 'components/gandalf/hasPaymentGandalf';

export class InvoicesPage extends React.Component {
  state = {generating: false};

  componentWillMount() {
    this.props.viewPaymentsPage();
  }

  renderPayments() {
    const {payments, loading, error} = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <TableWarning message="We're having trouble loading your payment history. Please try again later or contact us." />
      );
    }

    if (!payments.length) {
      return <TableWarning message="You haven't made any payments yet." />;
    }

    const cols = [
      {
        label: 'Description',
        field: 'lineItems',
        type: 'jsx',
        jsx: this.renderLineItems
      },
      {
        label: 'Date',
        field: 'dateDue',
        type: 'date',
        width: '130px',
        collapse: '400',
        combineWith: 'Description'
      },
      {
        label: 'Total Ex Tax',
        field: 'totalExTax',
        type: 'currency',
        width: '120px'
      },
      {
        label: 'Total Inc Tax',
        field: 'totalIncTax',
        type: 'currency',
        width: '120px',
        collapse: '500',
        combineWith: 'Total Ex Tax'
      },
      {
        label: 'Invoice',
        field: 'invoiceId',
        width: '130px',
        type: 'jsx',
        jsx: this.renderDownloadLink
      }
    ];

    // TODO: Add pagination (this currently only shows the last 12 months)
    return <Table columns={cols} rows={payments} rowKey="invoiceId" />;
  }

  onInvoiceDownloaded = () => {
    this.setState({generating: false});
  };

  downloadInvoice = invoiceId => {
    const {downloadInvoice} = this.props;
    this.setState({generating: true});
    downloadInvoice(invoiceId, this.onInvoiceDownloaded);
  };

  renderDownloadLink = ({data, record}) => {
    return <Button onClick={() => this.downloadInvoice(record.invoiceId)}>Download</Button>;
  };

  renderLineItems = ({data, record}) => {
    if (!data || !data.length) return;

    const withDescriptions = data.filter(item => item.description);
    if (!withDescriptions || !withDescriptions.length) {
      return <em style={{color: '#999999'}}>No description</em>;
    }

    return (
      <span>
        {withDescriptions.map(({description, quantity, lineAmount, taxAmount}, i) => {
          return (
            <span key={i}>
              <strong
                dangerouslySetInnerHTML={{
                  __html: `${quantity} x ${description.replace('\n', '<br />')}`
                }}
              />
              <br />
            </span>
          );
        })}
      </span>
    );
  };

  render() {
    const title = 'Invoices';
    const {generating} = this.state;

    return (
      <div>
        <Helmet title={title} />

        <PageHeader title={title} />

        <PageBody>
          <Loading loaded={!generating}>
            <HasPaymentGandalf>
              <PaymentDetails />
            </HasPaymentGandalf>

            <TransitionGroup
              transitionName="fade"
              component="div"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
            >
              {this.renderPayments()}
            </TransitionGroup>
          </Loading>
          {generating && (
            <p style={{textAlign: 'center', marginTop: '20px'}}>
              Invoice is being generated. This will take a moment
            </p>
          )}
        </PageBody>
      </div>
    );
  }
}

export default connect(
  paymentsPageSelector,
  {downloadInvoice, viewPaymentsPage}
)(InvoicesPage);
