exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".W_2N9YUyeg1LHB_frEMKy{margin:10px}.W_2N9YUyeg1LHB_frEMKy h3{margin:0}.W_2N9YUyeg1LHB_frEMKy h3 a{color:#533be2 !important;word-break:break-word}.FcQKclj1nepBlRrYHHtEW{display:inline;margin-left:10px}._3O2nv0iyBFrPkhy0SPmQuu{cursor:pointer;margin-right:10px;background:#df1616;display:inline-block;height:22px;width:22px;color:#fff;text-align:center;line-height:1.1;border-radius:50%}._3O2nv0iyBFrPkhy0SPmQuu:hover{background:#b11111}", ""]);

// exports
exports.locals = {
	"wrapper": "W_2N9YUyeg1LHB_frEMKy",
	"views": "FcQKclj1nepBlRrYHHtEW",
	"close": "_3O2nv0iyBFrPkhy0SPmQuu"
};