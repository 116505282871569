exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1v38FLqoNJQ-qjVZALEwn6{position:relative;display:flex;flex-direction:column}._1v38FLqoNJQ-qjVZALEwn6 ._1BRRBPlSi9_04YhpJNR3QA{display:flex}._1v38FLqoNJQ-qjVZALEwn6 ._1BRRBPlSi9_04YhpJNR3QA>span{flex:1}._1v38FLqoNJQ-qjVZALEwn6 .react-dual-listbox{padding:0;margin-bottom:10px;height:200px}._1v38FLqoNJQ-qjVZALEwn6 .rdl-move-all{display:none}._2GhgSXr8BTRwdXLK7buNll{position:absolute;left:100px;top:-24px}", ""]);

// exports
exports.locals = {
	"listbox": "_1v38FLqoNJQ-qjVZALEwn6",
	"labels": "_1BRRBPlSi9_04YhpJNR3QA",
	"spinner": "_2GhgSXr8BTRwdXLK7buNll"
};