import React from 'react';
import {connect} from 'react-redux';
import {webitPost} from 'modules/inspector/cmsUtils';
import HeaderButton from 'modules/inspector/components/inspectorHeaderButton';
import {inspectorEditCMSButtonSelector} from 'modules/inspector/inspectorSelectors';

class InspectorEditCMSButton extends React.Component {
  handleClickEditCMSButton = () => {
    const {editPageUrl, cms, windowName, windowFeatures} = this.props;

    if (cms === 'WebIt') {
      webitPost(editPageUrl);
    } else {
      window.open(editPageUrl, windowName, windowFeatures);
    }
  };

  render() {
    if (!this.props.editPageUrl) return null;

    return (
      <HeaderButton
        marginRight
        icon="pencil"
        tooltip="Edit in CMS"
        onClick={this.handleClickEditCMSButton}
      />
    );
  }
}

export default connect(inspectorEditCMSButtonSelector)(InspectorEditCMSButton);
