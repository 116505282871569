import _ from 'lodash';
import moment from 'moment';
import {getOrdinal} from 'utils/numberUtils';
import {lowercaseFirstLetter} from 'utils/stringUtils';

/*
 * A human readable reference to the timestamp relative to now.
 * See: https://momentjs.com/docs/#/displaying/calendar-time/
 *
 * e.g. Last Monday at 2:30 AM, Today at 2:30 AM, Yesterday at 2:30 AM
 */
export function friendlyDateTime(timestamp, prefix = '') {
  let date = moment.unix(timestamp);
  let friendlyDate = date.calendar();

  if (!prefix) return friendlyDate;

  return prefix + lowercaseFirstLetter(friendlyDate);
}

export function localeDate(ts, locale = 'en-US', options = {}) {
  const date = new Date(ts * 1000);
  return date.toLocaleDateString(locale, options);
}

/*
 * Returns a human-readable string expressing how long ago timestamp happened
 * See: https://momentjs.com/docs/#/displaying/fromnow/
 *
 * e.g. just now, a minute ago, 2 hours ago, a month ago
 */
export function friendlyRelativeDate(timestamp) {
  let date = moment.unix(timestamp);
  let text = date.fromNow();
  if (text == 'a few seconds ago') {
    return 'just now';
  }
  return text;
}

// Returns the current timestamp
export function timestamp() {
  return Math.round(new Date().getTime() / 1000);
}

// Returns an array of days of month for use in a dropdown (key, label pairs)
export function getDaysOfMonthOptions() {
  const days = [];

  for (let idx = 1; idx <= 31; ++idx) {
    days.push({
      value: idx,
      label: getOrdinal(idx)
    });
  }

  return days;
}

// Usage: getUniqueFormattedTimestamps([...timestamps], 'MMM Y')
// => ['Jan 2017', 'Feb 2017']
// Useful for taking a list of timestamps and turning it into a
// date selector.
export function getUniqueFormattedTimestamps(
  timestamps,
  format = 'MMM Y',
  momentify = moment.unix
) {
  return _.uniq(
    timestamps.map(timestamp => {
      return momentify(timestamp).format(format);
    })
  );
}

// Get the last x months including the current month
// Returns an array of objects in the following format:
// {month: <int (0 based)>, year: <int>, str: <str (controlled by format arg)>}
export function getLastXMonths(x, format = 'MMMM YY') {
  const results = [];

  for (let i = 0; i < x; i++) {
    let now = moment();
    let subDate = now.subtract(i, 'month');

    results.push({
      month: subDate.month(),
      year: subDate.year(),
      str: subDate.format(format)
    });
  }

  return results;
}
