import _ from 'lodash';
import React from 'react';
import Spinner from 'components/spinner';
import Icon from 'components/font-awesome';
import DualListBox from 'react-dual-listbox';
import Button from 'components/button';
import Buttons from 'components/buttons';
import styles from './listbox.scss';
import 'react-dual-listbox/lib/react-dual-listbox.css';

export default class ListBox extends React.Component {
  static defaultProps = {
    options: [],
    selected: [],
    optionsLabel: '',
    selectedLabel: ''
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  blur() {
    const {onBlur} = this.props;

    clearTimeout(this.timeout);

    if (onBlur) {
      this.timeout = setTimeout(onBlur, 0);
    }
  }

  handleChange = value => {
    const {onChange, onBlur} = this.props;

    onChange && onChange(value);

    // DualListBox doesn't trigger a blur event, so we simulate this by triggering
    // a blur when the user clicks on one of the buttons (when a blur would naturally occur anyway)
    this.blur();
  };

  // We're not supposed directly modify HTML elements.
  // However in this case, directl modifying the DOM is the only way to
  // control the selected options (without rewriting the whole component)
  moveVertical = isUp => () => {
    const {onChange, onBlur} = this.props;
    const {selected: selectedList} = this.listbox;
    const selectedOptions = [...selectedList.options];
    const highlightedIdxs = selectedOptions
      .map((opt, idx) => (opt.selected ? idx : null))
      .filter(idx => idx !== null);

    const swapOptions = (currIdx, nextIdx) => {
      var cur = selectedOptions[currIdx];
      selectedOptions[currIdx] = selectedOptions[nextIdx];
      selectedOptions[nextIdx] = cur;

      cur = null;
    };

    (isUp ? highlightedIdxs : highlightedIdxs.reverse()).forEach(idx => {
      // only move up if not in first position
      if (isUp && idx > 0) {
        swapOptions(idx, idx - 1);
      }

      // only move down if not in last position
      if (!isUp && idx < selectedOptions.length - 1) {
        swapOptions(idx, idx + 1);
      }
    });

    onChange && onChange(selectedOptions.map(c => c.value));

    this.listbox.selected.focus();
  };

  renderLabels() {
    const {optionsLabel, selectedLabel} = this.props;

    if (optionsLabel || selectedLabel) {
      return (
        <div className={styles.labels}>
          <span>{optionsLabel}</span>
          <span>{selectedLabel}</span>
        </div>
      );
    }
    return null;
  }

  setListbox = ref => {
    this.listbox = ref;
  };

  render() {
    return (
      <div className={styles.listbox}>
        {this.props.isLoading && <Spinner className={styles.spinner} small />}
        {this.renderLabels()}
        <DualListBox
          ref={this.setListbox}
          {...this.props}
          onChange={this.handleChange}
          preserveSelectOrder
        />

        {!this.props.disableSort && (
          <Buttons right>
            <Button onClick={this.moveVertical(true)}>
              <Icon name="caret-up" />
            </Button>
            <Button onClick={this.moveVertical(false)}>
              <Icon name="caret-down" />
            </Button>
          </Buttons>
        )}
      </div>
    );
  }
}
