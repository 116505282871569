import {select, all} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {resultsEndpoint} from 'modules/results/resultUtils';
import {reportIdSelector} from 'modules/reports/reportSelectors';

// @todo: Need to re-write this one.
export function* getResultsForComponent(resultName, filters) {
  return yield* getResultsFromQuery({
    filters,
    resultIds: [resultName]
  });
}

export function* getResultsFromQuery(payload) {
  const reportId = yield select(reportIdSelector);

  return yield processRequest(
    'POST',
    resultsEndpoint({reportId}),
    {
      success: function*(results) {
        return yield all(results);
      },
      error: function*(err) {
        throw err;
      }
    },
    payload
  );
}
