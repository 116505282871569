exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2kBE1fD6gonin6vUUXlbyQ{background:#fff;padding:40px;border-bottom:1px solid #bbb}._3Cnk4trQlFQHbkYLGSO96A{max-width:700px;margin:0 auto;text-align:center}._3Cnk4trQlFQHbkYLGSO96A h1{font-size:40px;margin:0}._3Cnk4trQlFQHbkYLGSO96A p{font-size:19px;margin:20px 0}._3Cnk4trQlFQHbkYLGSO96A button{font-size:19px}", ""]);

// exports
exports.locals = {
	"banner": "_2kBE1fD6gonin6vUUXlbyQ",
	"bannerInner": "_3Cnk4trQlFQHbkYLGSO96A"
};