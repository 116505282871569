export function digestsEndpoint() {
  return 'digests';
}

export function digestSubscriptionsEndpoint({reportId, userId}) {
  return `reports/${reportId}/digest-subscriptions/${userId}`;
}

export function digestPreviewEndpoint({digestId}) {
  return `digests/${digestId}/preview`;
}
