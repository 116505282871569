import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {SubMenu} from 'components/sidebar/listSidebar';
import ReportSidebarItem from './reportSidebarItem';
import {sidebarTestsSelector} from 'modules/reports/reportSidebarSelectors';
import styles from 'components/sidebar/listSidebar/sidebarItem.scss';

// Renders a single level of sidebar tests,
// plus potentially another container for the next level down.
class TestsSidebar extends React.Component {
  // Due to this returning an array, it's not worth abstracting to a new component
  // as we have to add additional dom element wrappers.
  renderItem = navItem => {
    const {scoreType} = this.props;
    const testId = navItem.get('testId');

    const item = (
      <ReportSidebarItem
        key={`${testId}_menu`}
        navItem={navItem}
        scoreType={scoreType}
        className={cx({[styles.isChild]: !!this.props.parentId})}
      />
    );

    if (!navItem.get('isOpen')) {
      //return item;
    }

    return [
      item,
      <SubMenu key={`${testId}_submenu`}>
        <TestsSidebarContainer parentId={testId} />
      </SubMenu>
    ];
  };

  renderItems(navItems) {
    return navItems.sortBy(item => item.get('sortIndex')).map(this.renderItem);
  }

  render() {
    const {navItems} = this.props;
    const items = this.renderItems(navItems);

    return <div>{items}</div>;
  }
}

const TestsSidebarContainer = connect(sidebarTestsSelector)(TestsSidebar);
export default TestsSidebarContainer;
