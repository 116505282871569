import React from 'react';
import {connect} from 'react-redux';
import {npsSelector} from 'modules/nps/npsSelectors';
import styles from './nps.scss';
import cx from 'classnames';
import {setNPSEnable, setNPSScore} from 'modules/nps';
import Icon from 'components/font-awesome';
import Button from 'components/button';
import Buttons from 'components/buttons';
import KeyCodes from 'constants/keyCodes';
import BooleanInput from 'modules/form/components/inputs/booleanInput';
import {findDOMNode} from 'react-dom';

const initialState = {
  score: null,
  reason: '',
  contactMe: true,
  closeAnimation: false,
  smallImage: false,
  showTextbox: false
};

// preload so there is no lag when displaying
const imageBad = require('./badgif.gif');
const imageMid = require('./midgif.gif');
const imageGood = require('./goodgif.gif');

export class NPS extends React.Component {
  static defaultProps = {
    animate: true
  };

  state = initialState;

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);

    this.handleResize();
  }

  componentWillUnmount() {
    this.setState({showTextbox: false});

    clearTimeout(this.timeout);
    clearTimeout(this.focusTimeout);
    clearTimeout(this.afterThankyouTimeout);

    window.removeEventListener('resize', this.handleResize);
  }

  close() {
    this.setState(initialState);
    this.handleResize();
    this.props.setNPSEnable(false);
  }

  handleResize = () => {
    this.setState({smallImage: window.innerWidth <= 625});
  };

  handleClickScore = event => {
    var idx = 0;
    var elem = event.target;

    if (elem.nodeName !== 'LI') {
      return;
    }

    while (elem.previousSibling) {
      idx++;
      elem = elem.previousSibling;
    }

    this.setState({score: idx, showTextbox: true});

    this.focusTimeout = setTimeout(() => {
      this.textarea.focus();
    }, 500);
  };

  handleClose = event => {
    const {score, reason, contactMe} = this.state;
    const isDismissed = event.target == this.closeIcon;

    // they could have selected a score before closing, if they did, pass this score (otherwise -1)
    this.props.setNPSScore(score == null ? -1 : score, reason, contactMe, !isDismissed);

    if (!this.props.animate) {
      this.close();
    } else {
      this.setState({closeAnimation: true});

      this.timeout = setTimeout(() => {
        this.close();
      }, 500);
    }
  };

  handleTextChange = event => {
    this.setState({reason: event.target.value});
  };

  handleChangeContactMe = val => {
    this.setState({contactMe: val});
  };

  setTextarea = ref => {
    this.textarea = ref;
  };

  renderScores() {
    const scores = Array(11).fill(false);
    const {score} = this.state;

    return (
      <ul className={styles.scores} onClick={this.handleClickScore}>
        {scores.map((_, idx) => {
          const active = score && score >= 0 && idx === score;
          const faded = score != null && score >= 0 && idx !== score;
          const classes = cx(styles['score-' + idx], {
            [styles.faded]: faded,
            [styles.active]: active
          });

          return (
            <li key={`score_${idx}`} className={classes}>
              {idx}
            </li>
          );
        })}
      </ul>
    );
  }

  renderScoresHelper() {
    return (
      <div
        className={cx(styles.helperContainer, {
          [styles.hide]: this.state.showTextbox
        })}
      >
        <p className={styles.notLikely}>Not at all likely</p>
        <p className={styles.likely}>Extremely likely</p>
      </div>
    );
  }

  renderTextarea() {
    return (
      <div className={styles.textareaContainer}>
        <textarea
          ref={this.setTextarea}
          value={this.state.reason}
          className={styles.textarea}
          onChange={this.handleTextChange}
          placeholder={this.getPlaceholder()}
        />
        <img src={this.getImage()} height={this.state.smallImage ? '100px' : '150px'} />
      </div>
    );
  }

  renderSubmit() {
    return (
      <div className={styles.belowTextarea}>
        {this.renderContactMe()}
        <Button className={styles.button} onClick={this.handleClose}>
          Send
        </Button>
      </div>
    );
  }

  renderContactMe() {
    return (
      <div className={styles.contactMe}>
        <BooleanInput value={this.state.contactMe} onChange={this.handleChangeContactMe} /> You can
        contact me about this
      </div>
    );
  }

  getPlaceholder() {
    const {score} = this.state;
    const message = 'What could we do to improve?';

    if (score == 7 || score == 8) {
      return `Thanks! ${message}`;
    }
    if (score == 9 || score == 10) {
      return `Awesome! ${message}`;
    }
    return `Oh no! ${message}`;
  }

  getImage() {
    const {score} = this.state;

    if (score == null || score < 0) {
      return null;
    }

    if (score == 7 || score == 8) {
      return imageMid;
    }
    if (score == 9 || score == 10) {
      return imageGood;
    }
    return imageBad;
  }

  render() {
    const {animate, isOpen} = this.props;
    const {score, closeAnimation} = this.state;

    const classes = cx(styles.nps, {
      [styles.animate]: animate,
      [styles.closeAnimation]: animate && closeAnimation
    });

    if (!isOpen) {
      return null;
    }

    // NOTE the `nps-container` is REQUIRED as it is used by analysis tools to
    // find recordings of people who have submitted a net promoter score.

    return (
      <div className={cx(styles.container, 'nps-container')}>
        <div className={classes}>
          <Icon
            ref={ref => (this.closeIcon = findDOMNode(ref))}
            size="lg"
            name="times"
            onClick={this.handleClose}
            className={styles.closeIcon}
          />

          <div style={{height: '100%'}}>
            <h4 className={styles.heading}>
              How likely are you to recommend Silktide to a friend or colleague?
            </h4>

            {this.renderScores()}
            {this.renderScoresHelper()}

            <div
              className={cx(styles.bottomHalf, {
                [styles.show]: this.state.showTextbox
              })}
            >
              {this.renderTextarea()}
              {this.renderSubmit()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  npsSelector,
  {setNPSEnable, setNPSScore}
)(NPS);
