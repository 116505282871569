import _ from 'lodash';
import {put, call, takeEvery} from 'redux-saga/effects';
import {FETCH_REPORT_LOGS, receiveReportLogs} from 'modules/logs';
import {reportLogsEndpoint} from 'modules/logs/logUtils';
import fetch from 'utils/saga/fetch';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* fetchReportLogsSaga() {
  yield takeEvery(FETCH_REPORT_LOGS, fetchReportLogs);
}

function* fetchReportLogs() {
  const res = yield call(fetch, reportLogsEndpoint());
  yield processResponse(res, {
    successAction: receiveReportLogs,
    errorMessage: couldNot('load report logs')
  });
}
