import React from 'react';
import InspectorTopLevelCategories from 'modules/inspector/components/sidebar/inspectorTopLevelCategories';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import styles from '../components/inspectorSidebar.scss';

export default function FixedTopLevelNav({children, actionId, categoryId}) {
  return (
    <PageWithFixed
      resetScroll={actionId + categoryId}
      orientation="vertical"
      fixedSize={styles.sidebarTopSelector}
      fixedElement={<InspectorTopLevelCategories />}
    >
      {children}
    </PageWithFixed>
  );
}
