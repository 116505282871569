import AbstractField from './AbstractField';

export default class FieldInput extends AbstractField {
  initialise(value) {
    super.initialise();

    // this is spread onto controlled input components
    this.input = {
      value,
      id: this.key,
      onBlur: this.handleInputBlur,
      onChange: this.handleInputChange
    };

    this.initialValue = this.getSerialValue();
  }

  setPathIndex(pathIdx, value) {
    super.setPathIndex(pathIdx, value);
    this.input.id = this.key;
  }

  handleInputBlur = () => {
    if (!this.blurred) {
      this.blurred = true;
      this.rerender(); // this enables the Rule component to show any error messages
    }
  };

  handleInputChange = eventOrValue => {
    const value = this.constructor.isSynthenicEvent(eventOrValue)
      ? eventOrValue.target.value
      : eventOrValue;

    this.setValue(value);
    this.rerender();
  };

  setValue(value) {
    this.input.value = value;
    this.changed = true;
    // when we change the value, the validation state no longer applies, because we need to re-validate
    // the validation happens when the Rule component calls `field.validate`, so even though we are clearing the state,
    // when the Rule component re-renders with a new value, then this field we be re-validated
    this.setValidationResult(null);
  }

  getHtmlFor() {
    return this.input.id;
  }

  getValue() {
    return this.input.value;
  }

  getFormattedValue() {
    return this.formatter ? this.formatter(this.getValue()) : this.getValue();
  }

  hasError() {
    return !this.isValid;
  }
}
