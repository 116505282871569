import {createSelector} from 'reselect';
import {reportSelector} from 'modules/reports/reportSelectors';

const searchCampaignsDomain = state => state.searchCampaigns;
const campaignIdPropSelector = (_, props) => props.campaignId;
const campaignIdRoutePropSelector = (_, props) => props.match.params.campaignId;

export const searchCampaignsSelector = createSelector(
  searchCampaignsDomain,
  state => {
    return state.get('data').toList();
  }
);

export const isSearchCampaignsLoadingSelector = createSelector(
  searchCampaignsDomain,
  state => {
    return state.get('isLoading');
  }
);

export const isSearchCampaignUpdatingSelector = createSelector(
  searchCampaignsDomain,
  (_, props) => props.campaign.campaignId || 'new',
  (state, campaignId) => {
    return state.get('updatingTheme') == campaignId;
  }
);

export const keywordsUsedSelector = createSelector(
  searchCampaignsSelector,
  themes => {
    return themes.reduce((count, theme) => {
      return count + theme.get('keywords').size * theme.get('engines').size;
    }, 0);
  }
);

export const searchCampaignSelector = createSelector(
  searchCampaignsSelector,
  campaignIdRoutePropSelector,
  (themes, campaignId) => {
    return themes.find(theme => theme.get('campaignId') == campaignId);
  }
);

export const searchCampaignsBreadcrumbSelector = createSelector(
  reportSelector,
  searchCampaignsSelector,
  (report, searchCampaigns) => ({
    report,
    searchCampaigns,
    searchCampaignOptions: searchCampaigns
      .toList()
      .sortBy(st => st.get('sortIndex'))
      .map(st => ({
        value: st.get('campaignId'),
        label: st.get('name')
      }))
  })
);
