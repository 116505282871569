import _ from 'lodash';
import React from 'react';
import styles from './progress.scss';
import ProgressComponent from 'components/progress';

export default class Progress extends React.Component {
  render() {
    const {config, result} = this.props;
    const {label, rightLabel, round} = config;
    let value = _.isPlainObject(result) ? result[Math.max(...Object.keys(result))] : 0;

    if (typeof value === 'undefined') {
      value = 0;
    }

    return (
      <div>
        {label && (
          <div className={styles.label}>
            <div className={styles.left}>{label}</div>
            {!!rightLabel && <div className={styles.right}>{rightLabel}</div>}
          </div>
        )}
        <ProgressComponent
          color={config.color}
          value={_.round(value, 1)}
          small={config && config.small}
          label="right"
        />
      </div>
    );
  }
}
