import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

class Strlen extends React.Component {
  render() {
    const {data, options, className} = this.props;
    if (!data) return null;

    const customClasses = options.class ? options.class.split(' ') : [];
    const customClassesMapped = customClasses.map(c => styles[c]);

    const classNames = cx('cell-data wordBreak', className, customClassesMapped);

    return <div className={classNames}>{data.length}</div>;
  }
}

Strlen.propTypes = {
  data: PropTypes.any,
  options: PropTypes.object
};

export default Strlen;
