import React from 'react';
import {addWidget} from '../actions';
import {widgetTypesSelector, savingWidgetSelector} from '../selectors';
import {useDispatch, useSelector} from 'hooks/redux';
import WidgetModal from './widgetModal';

export default function AddWidgetModal({dashboardId, rowIndex, columnIndex, widgetTypeId}) {
  const dispatch = useDispatch();
  const widgetTypes = useSelector(widgetTypesSelector);
  const savingWidgetState = useSelector(savingWidgetSelector);
  const handleSubmit = React.useCallback(
    formData => {
      dispatch(addWidget(dashboardId, rowIndex, columnIndex, widgetTypeId, formData));
    },
    [dashboardId, rowIndex, columnIndex, widgetTypeId]
  );

  return (
    <WidgetModal
      title="Add widget"
      onSave={handleSubmit}
      isLoading={savingWidgetState.get('loading')}
      immSupportedOptions={widgetTypes.getIn([widgetTypeId, 'supportedOptions'])}
    />
  );
}
