import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/font-awesome';
import styles from './badge.scss';

export default class Badge extends React.Component {
  static defaultProps = {
    icon: 'diamond', // TODO choose better default
    animate: true
  };

  static propTypes = {
    icon: PropTypes.string,
    animate: PropTypes.bool
  };

  renderImage() {
    const {icon, src} = this.props;
    let srcImage;
    try {
      if (src) {
        srcImage = require('./' + src + '.gif');
      }
    } catch (err) {
      srcImage = null;
    }

    return srcImage ? (
      <img className={styles.imageInner} src={srcImage} />
    ) : (
      <Icon className={styles.iconInner} name={icon} size="3x" />
    );
  }

  render() {
    const {className, onClick, animate} = this.props;
    const classes = cx(styles.badge, className, {
      [styles.animate]: animate
    });

    return (
      <div className={classes} onClick={onClick}>
        <div className={styles.inner1}>
          <div className={styles.inner2}>
            <div className={styles.inner3}>
              <div className={styles.inner4}>{this.renderImage()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
