import {reportEndpoint, reportPath} from 'modules/reports/reportUtils';

export function trendScreensEndpoint() {
  return reportEndpoint(...arguments) + '/trend-screens';
}

export function trendScreenEndpoint({trendScreenId}) {
  return trendScreensEndpoint(...arguments) + `/${trendScreenId}`;
}

export function trendSourcesEndpoint() {
  return trendScreenEndpoint(...arguments) + '/results';
}

export function trendScreenPath({trendScreenId}) {
  return reportPath(...arguments) + `/trends/${trendScreenId}`;
}
