exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1sg6cqPXGCGfDK6jyX9fc2{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:1000;overflow:auto;background:rgba(20,20,35,.9);display:flex}._1sg6cqPXGCGfDK6jyX9fc2._2McO5u5W0775NF-Q3awFlz{left:55px;width:calc(100vw - 55px)}._1sg6cqPXGCGfDK6jyX9fc2._2f4U7xbD3tMIkIRWb_ispt{background:rgba(20,20,35,.5)}._1sg6cqPXGCGfDK6jyX9fc2._14L_4WG0C3l5Tur0VDKr94{align-items:center;justify-content:center}._1sg6cqPXGCGfDK6jyX9fc2._14L_4WG0C3l5Tur0VDKr94._39D9JMwDTqx4EC2hNgOZcq{align-items:flex-start;padding:50px 0}._1sg6cqPXGCGfDK6jyX9fc2.dqL8-Rx1uWvxRQLZSvOrv{opacity:1;-webkit-animation:_11N1HwTO5YHOFvQoIEYgcO 100ms ease-in;animation:_11N1HwTO5YHOFvQoIEYgcO 100ms ease-in}._15OgWc8mh0Z7mjHqPU3lRi{color:#fff;position:fixed;top:13px;right:18px;cursor:pointer;transition:all 500ms}._15OgWc8mh0Z7mjHqPU3lRi._31aaWOM3qE5R23B0S9LvJT{opacity:0}._15OgWc8mh0Z7mjHqPU3lRi:hover{opacity:1;-webkit-transform:scale(1.2);transform:scale(1.2)}@media(max-width: 425px){._1sg6cqPXGCGfDK6jyX9fc2{padding:5px}}@media(max-height: 425px){._1sg6cqPXGCGfDK6jyX9fc2{padding:5px}}@-webkit-keyframes _11N1HwTO5YHOFvQoIEYgcO{0%{opacity:0}100%{opacity:1}}@keyframes _11N1HwTO5YHOFvQoIEYgcO{0%{opacity:0}100%{opacity:1}}", ""]);

// exports
exports.locals = {
	"overlay": "_1sg6cqPXGCGfDK6jyX9fc2",
	"leftMargin55": "_2McO5u5W0775NF-Q3awFlz",
	"subtle": "_2f4U7xbD3tMIkIRWb_ispt",
	"center": "_14L_4WG0C3l5Tur0VDKr94",
	"yOverflow": "_39D9JMwDTqx4EC2hNgOZcq",
	"fade": "dqL8-Rx1uWvxRQLZSvOrv",
	"fadeInOpacity": "_11N1HwTO5YHOFvQoIEYgcO",
	"closeIcon": "_15OgWc8mh0Z7mjHqPU3lRi",
	"hide": "_31aaWOM3qE5R23B0S9LvJT"
};