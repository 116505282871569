import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {reportsForcedPagesSelector} from 'modules/reports/reportSelectors';
import {addForcedPage, updateForcedPage, deleteForcedPage, fetchForcedPages} from 'modules/reports';
import Spinner from 'components/spinner';
import Table from 'components/table';
import Buttons from 'components/buttons';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import ForcedPageButton from 'modules/reports/components/forcedPageButton';
import DeleteForcedPageButton from '../deleteForcedPageButton';
import Status from 'components/status';
import PaginationComponent from 'components/pagination';
import Icon from 'components/font-awesome';
import styles from './forcedPagesList.scss';
import Panel from 'components/panel/panel';
import ExternalLink from 'modules/location/externalLink';

const columns = [
  {
    field: 'url',
    label: 'URL',
    type: 'jsx',
    jsx: ({data}) => {
      return (
        <ExternalLink href={data}>
          {data}
          <Icon name="fa fa-external-link" className={styles.linkIcon} />
        </ExternalLink>
      );
    },
    width: '300px'
  },
  {
    collapse: '700',
    combineWith: 'URL',
    field: 'firstAdded',
    label: 'First Added',
    type: 'date',
    format: 'Do MMM YYYY'
  },
  {
    collapse: '600',
    field: 'username',
    combineWith: 'URL',
    label: 'Added by',
    type: 'string'
  },
  {
    collapse: '600',
    combineWith: 'URL',
    label: '',
    width: '220px',
    type: 'jsx',
    jsx: props => {
      const {
        record: {forcedPageId},
        params,
        pageNum
      } = props;

      return (
        <Buttons noMargin>
          <ForcedPageButton
            icon="pencil"
            key="edit"
            forcedPageId={forcedPageId}
            tooltip="Edit this forced page"
          >
            Edit
          </ForcedPageButton>
          <DeleteForcedPageButton
            forcedPageId={forcedPageId}
            reportId={params.reportId}
            pageNum={pageNum}
          />
        </Buttons>
      );
    }
  }
];

class ForcedPagesList extends Component {
  state = {
    pageNum: 1
  };

  componentDidMount() {
    const {fetchForcedPages, match} = this.props;
    const {reportId} = match.params;
    fetchForcedPages(reportId);
  }

  renderPageHeader = () => (
    <PageHeader
      title="Forced pages"
      inline={[
        <ForcedPageButton key="add" primary icon="plus" tooltip="Add a new forced page">
          Add forced page
        </ForcedPageButton>
      ]}
    />
  );

  renderTable = () => {
    const {forcedPages, match, pagination} = this.props;
    const {pageNum} = this.state;

    if (forcedPages && forcedPages.length) {
      return (
        <Fragment>
          <Panel marginBottom padding={false}>
            <Table
              rowKey="forcedPageId"
              rows={forcedPages}
              columns={columns}
              cellParams={{...match, pageNum}}
            />
          </Panel>
          {pagination && pagination.maxPage > 1 && (
            <PaginationComponent
              {...pagination}
              onPageSelect={pageNum => {
                this.props.fetchForcedPages(match.params.reportId, null, pageNum);

                this.setState({pageNum});
              }}
            />
          )}
        </Fragment>
      );
    }

    return (
      <Panel>
        <Status appearance="info">No forced pages have been configured</Status>
      </Panel>
    );
  };

  render() {
    const {forcedPagesLoading} = this.props;

    return (
      <Fragment>
        {this.renderPageHeader()}
        <PageBody>
          <p className={styles.description}>
            Add URLs that our crawler is forced to visit when scanning your site.
          </p>
          <br />
          {forcedPagesLoading ? <Spinner marginTop={20} marginBottom={20} /> : this.renderTable()}
        </PageBody>
      </Fragment>
    );
  }
}

export default connect(
  reportsForcedPagesSelector,
  {fetchForcedPages, addForcedPage, updateForcedPage, deleteForcedPage}
)(ForcedPagesList);
