import React from 'react';
import {connect} from 'react-redux';
import {updateReport} from 'modules/reports';
import {updateWebsite} from 'modules/tests';
import AlertsConfig from '../../components/reportSettings/alertsConfig';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';

export class ReportSettingsAlertsPage extends React.Component {
  render() {
    return <AlertsConfig {...this.props} name="settingsAlerts" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateReport, updateWebsite}
)(ReportSettingsAlertsPage);
