import {Map, fromJS} from 'immutable';
import {analytics, events} from 'modules/analytics';
import {DELETE_REPORT} from 'modules/reports/reportConstants';

const initialState = Map({
  isLoading: true,
  data: Map()
});

export default function homepageReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_HOME_PAGE:
      return state.set('data', fromJS(action.data)).set('isLoading', false);
    case VIEW_HOME_PAGE:
      return state.set('isLoading', true);
    case DELETE_REPORT:
      const recentReports = state.getIn(['data', 'recentReports'], []);
      return state.setIn(
        ['data', 'recentReports'],
        recentReports.filter(report => report.get('reportId') != action.reportId)
      );
  }
  return state;
}

export const VIEW_HOME_PAGE = 'homePage:VIEW_HOME_PAGE';
export function viewHomePage() {
  return {
    type: VIEW_HOME_PAGE
  };
}

export const LEAVE_HOME_PAGE = 'homePage:LEAVE_HOME_PAGE';
export function leaveHomePage() {
  return {
    type: LEAVE_HOME_PAGE
  };
}

export const RECEIVE_HOME_PAGE = 'homePage:RECEIVE_HOME_PAGE';
export function receiveHomePage(data) {
  return {
    type: RECEIVE_HOME_PAGE,
    data
  };
}

export const OPENED_GETTING_STARTED = 'homePage:OPENED_GETTING_STARTED';
export function openedGettingStarted() {
  return {
    type: OPENED_GETTING_STARTED,
    ...analytics(events.OPENED_GETTING_STARTED)
  };
}
