exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1WSTCfUemrGjr_zVQpPL8P{background:#fff;color:#2f2d2d;font-size:14px;overflow:hidden;padding-bottom:60px;text-align:center;margin:0px}@media screen and (max-width: 767px){._1WSTCfUemrGjr_zVQpPL8P{padding:0px 32px 60px 32px}}.ZPpw4Ohga1WX6oaZTJObz{background:#fff;margin-top:0px;padding:60px 40px 60px 40px}.ZPpw4Ohga1WX6oaZTJObz h3{color:#2f2d2d;font-size:24px;margin-bottom:40px;text-align:center}@media screen and (max-width: 499px){.ZPpw4Ohga1WX6oaZTJObz h3{text-align:left}}@media screen and (max-width: 499px){.ZPpw4Ohga1WX6oaZTJObz{padding:60px 32px}}._2m8nxrTieuEfVrtWijSHfr{display:flex;flex-direction:row;justify-content:space-between;margin:0 auto;max-width:600px}@media(max-width: 499px){._2m8nxrTieuEfVrtWijSHfr{display:flex;flex-direction:column}._2m8nxrTieuEfVrtWijSHfr ul{padding-left:0px}}._10FhAEzVXpE4sxFjKLnkQ0{width:calc(50% - 8px)}@media(max-width: 767px){._10FhAEzVXpE4sxFjKLnkQ0{width:50%}}@media(max-width: 499px){._10FhAEzVXpE4sxFjKLnkQ0{width:100%}}._1KhruKNH-RRFkcUkT6rH5E,._3Tfyl3iUxUjPMd6OoRb0ww{list-style-type:none;margin:0;padding:0}._1KhruKNH-RRFkcUkT6rH5E h4{font-size:21px;margin-left:-4px}._1KhruKNH-RRFkcUkT6rH5E p{max-width:250px}._1KhruKNH-RRFkcUkT6rH5E span{align-items:center;display:flex}._1KhruKNH-RRFkcUkT6rH5E span span{margin-right:16px}._1yYyve-AwPOIEVJCkFEzO2{align-items:center;background:#533be2;color:#fff;border-radius:50%;display:flex;font-size:18px;justify-content:center;height:38px;max-width:38px;width:38px}", ""]);

// exports
exports.locals = {
	"footnote": "_1WSTCfUemrGjr_zVQpPL8P",
	"summary": "ZPpw4Ohga1WX6oaZTJObz",
	"benefitWrapper": "_2m8nxrTieuEfVrtWijSHfr",
	"benefits": "_10FhAEzVXpE4sxFjKLnkQ0",
	"benefitList": "_1KhruKNH-RRFkcUkT6rH5E",
	"tickList": "_3Tfyl3iUxUjPMd6OoRb0ww",
	"benefitIcon": "_1yYyve-AwPOIEVJCkFEzO2"
};