exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._31M8VxFKqQGcDAFV8LBot-{margin:5px 0 2px 0;font-weight:bold;font-size:18px;line-height:22px;margin-bottom:10px}._31M8VxFKqQGcDAFV8LBot-._2-zq_PxcJDXbLlxpv1PSCF{font-size:15px;margin:0}.XoMK4-ebQHxBFSIybLSW_{margin:4px 0 2px 0;font-weight:bold;font-size:18px;color:#533be2}._2hVFIFebUD3cK1sp2eDHmY{margin:2px 0 5px 0;color:#6f747d}._2hVFIFebUD3cK1sp2eDHmY._3T5ObyWQsGYDKph80Vapqa{margin-left:32px}._38ZLrGQWbEBbY4i9uNbc9R{color:#737373;font-weight:bold;font-size:16px;margin-left:10px}", ""]);

// exports
exports.locals = {
	"actionName": "_31M8VxFKqQGcDAFV8LBot-",
	"smaller": "_2-zq_PxcJDXbLlxpv1PSCF",
	"actionNameLink": "XoMK4-ebQHxBFSIybLSW_",
	"actionMeta": "_2hVFIFebUD3cK1sp2eDHmY",
	"hasPriority": "_3T5ObyWQsGYDKph80Vapqa",
	"subName": "_38ZLrGQWbEBbY4i9uNbc9R"
};