import {createSelector} from 'reselect';

const policyDomainSelector = state => state.policies;

export const scopesSelector = createSelector(
  policyDomainSelector,
  domain => domain.get('scopes')
);

export const checksSelector = createSelector(
  policyDomainSelector,
  domain => domain.get('checks')
);

export const checksLoadedSelector = createSelector(
  policyDomainSelector,
  domain => !domain.get('requestingChecks') && !!domain.getIn(['checks', 'page']).size //&&
  // !!domain.getIn(['checks', 'document']).size
);

export const policySelector = createSelector(
  policyDomainSelector,
  domain => domain.get('policy')
);

export const libraryPolicySelector = createSelector(
  policyDomainSelector,
  domain => domain.get('libraryPolicy')
);

export const scopeOptionsSelector = createSelector(
  scopesSelector,
  scopes => scopes.map(scope => ({label: scope.get('name'), value: scope.get('scope')})).toJS()
);
