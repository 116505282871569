import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const EntitySelectorCard = ({label, onClick}) => (
  <div className="entity-selector-card">
    <h2>{label}</h2>
    <button onClick={onClick}>Add +</button>
  </div>
);

EntitySelectorCard.propTypes = propTypes;

export default EntitySelectorCard;
