import React from 'react';
import styles from './checkpointsHeader.scss';

class CheckpointsHeader extends React.Component {
  render() {
    const {label, count} = this.props;

    return (
      <h2 className={styles.header}>
        {label} <span className="badge">{count}</span>
      </h2>
    );
  }
}

export default CheckpointsHeader;
