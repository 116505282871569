import React from 'react';
import styles from './styles.scss';

export default function FakeLink(props) {
  return (
    <span
      className={styles.fakeLink}
      onClick={event => {
        if (props.options.onClick) {
          props.options.onClick(event, props);
        }
      }}
    >
      {props.data}
    </span>
  );
}
