import React from 'react';
import {useDispatch, useSelector} from 'hooks/redux';
import {fetchReports} from 'modules/reports';
import {reportsOptionsSelector} from 'modules/reports/reportSelectors';
import Select from 'react-select';

export default function ReportSelector({value, onChange}) {
  const dispatch = useDispatch();
  const reportOptions = useSelector(reportsOptionsSelector);
  const [reportId, setReportId] = React.useState(value || null);

  React.useEffect(() => {
    dispatch(fetchReports(true, ['name', 'reportId']));
  }, []);

  React.useEffect(() => {
    setReportId(value);
    onChange(value);
  }, [value]);

  return (
    <Select
      placeholder="Filter website"
      value={reportId}
      options={reportOptions.toJS()}
      onChange={option => {
        const selectedReportId = !option ? null : option.value;
        setReportId(selectedReportId);
        onChange(selectedReportId);
      }}
    />
  );
}
