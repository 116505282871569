import React from 'react';
import {connect} from 'react-redux';
import styles from './explorerTitle.scss';
import {interpolateToJSX} from 'utils/interpolationUtils';
import ExplorerViews from './explorerViews.js';
import Flex from 'components/layout/flex';
import {isPrintMode} from 'modules/filters/filterUtils';
import Pill from 'components/pill';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';

export class ExplorerTitle extends React.Component {
  static defaultProps = {
    result: {}
  };

  handleClear = () => {
    const {views, viewFilter, setFilterParams} = this.props;

    setFilterParams({
      [viewFilter]: views[0].name || ''
    });
  };

  render() {
    const {
      currentView,
      filter,
      views,
      viewFilter,
      setFilterParams,
      isArchiveActive,
      result: {summaries}
    } = this.props;
    const {title, selectable} = currentView;
    const isNotSelectable = selectable === undefined;

    if (!title) return null;

    return (
      <Flex container className={styles.wrapper}>
        <Flex>
          <h3>
            {isNotSelectable && (
              <span className={styles.close} onClick={this.handleClear}>
                &times;
              </span>
            )}
            {interpolateToJSX(title, filter)}
            {!!summaries && summaries.numRows !== undefined && (
              <span>
                {' '}
                <Pill appearance="minor">{summaries.numRows}</Pill>
              </span>
            )}
          </h3>
        </Flex>
        {!isPrintMode(filter) && !isArchiveActive && (
          <Flex>
            <ExplorerViews
              className={styles.views}
              views={views}
              filter={filter}
              currentView={currentView}
              viewFilter={viewFilter}
              setFilterParams={setFilterParams}
            />
          </Flex>
        )}
      </Flex>
    );
  }
}

export default connect((state, props) => ({
  isArchiveActive: isArchiveActiveSelector(state, props)
}))(ExplorerTitle);
