import React from 'react';
import Icon from 'components/font-awesome';

class PhoneIssue extends React.Component {
  getNormalizedPhone = () => {
    if (!this.props.point) {
      return '';
    }

    if (this.props.point.normalizedDetail) {
      return this.props.point.normalizedDetail;
    }

    return this.props.point.normalizedPhone;
  };

  getPhone = () => {
    if (!this.props.point) {
      return '';
    }

    if (this.props.point.detail) {
      return this.props.point.detail;
    }

    return this.props.point.phone;
  };

  getLocation = () => {
    if (!this.props.point) {
      return '';
    }

    if (this.props.point.props) {
      return this.props.point.props.location;
    }

    return this.props.point.location;
  };

  render() {
    const normalizedPhone = this.getNormalizedPhone();
    const phone = this.getPhone();
    const location = this.getLocation();

    return (
      <div>
        <p>
          <a href={'tel:' + normalizedPhone}>{phone}</a>
        </p>
        <p>
          <Icon name="fas fa-map-marker-alt" /> {location}
        </p>
      </div>
    );
  }
}

export default PhoneIssue;
