import PropTypes from 'prop-types';
import React from 'react';

function loadInput(type) {
  try {
    let Input = require(`./inputs/${type}Input.js`);

    if (Input.default) {
      Input = Input.default;
    }

    if (!Input) {
      throw new Error('No Input');
    }

    return Input;
  } catch (err) {
    if (__DEV__) {
      console.error(`InputForType[${type}] could not be loaded`);
      console.error(err);
    }
    return null;
  }
}

export default class InputForType extends React.Component {
  render() {
    const Input = loadInput(this.props.type);
    const input = !Input ? null : (
      <Input
        {...this.props}
        ref={ref => {
          this.inputRef = ref;
        }}
      />
    );

    if (this.props.width) {
      return <div style={{width: this.props.width}}>{input}</div>;
    }
    return input;
  }
}
