exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2a2GCjVluNmPe1FLo5rd_t{margin:0 5px 15px 5px}._3SaU2M80pG30C2vnPZ0Ped{padding:0;margin:0}._3C5gqNi3-hu_FBLGuKPjer{margin:5px 0 0 0;opacity:.5}", ""]);

// exports
exports.locals = {
	"wrapper": "_2a2GCjVluNmPe1FLo5rd_t",
	"heading": "_3SaU2M80pG30C2vnPZ0Ped",
	"description": "_3C5gqNi3-hu_FBLGuKPjer"
};