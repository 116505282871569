exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".lRnJZqvTMm96TkixgnGN5{width:100%;margin:0;padding:10px;border:0;background-color:transparent;color:#999}.lRnJZqvTMm96TkixgnGN5:focus{outline:none}", ""]);

// exports
exports.locals = {
	"input": "lRnJZqvTMm96TkixgnGN5"
};