import React from 'react';
import Control from 'components/control/control';

export default class UndoDecision extends React.Component {
  componentWillMount() {
    this.instruction = {
      type: {type: 'value', value: 'undoDecision'},
      decisionId: {type: 'value', value: this.props.decisionId}
    };
  }

  render() {
    const {small, context} = this.props;
    return (
      <Control
        instruction={this.instruction}
        appearance="bad"
        label="Undo"
        icon="undo"
        small={small}
        context={context}
      />
    );
  }
}
