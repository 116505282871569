import {fromJS} from 'immutable';

const initialState = fromJS({
  subscriptions: [],
  loading: false,
  fetched: false,
  error: false
});

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTIONS:
      return state.mergeIn(['subscriptions'], fromJS(action.subscriptions)).set('fetched', true);

    case UPDATE_SUBSCRIPTION:
      const {subscriptionId, subscription} = action;
      const subscriptionIdx = state
        .get('subscriptions')
        .findIndex(sub => sub.get('subscriptionId') === subscriptionId);

      if (subscriptionIdx < 0) {
        return state;
      }

      return state.updateIn(['subscriptions', subscriptionIdx], () => fromJS(subscription));

    case CLEAR_SUBSCRIPTIONS:
      return state.set('subscriptions', fromJS([])).set('fetched', false);

    case REQUEST_SUBSCRIPTIONS:
      return state.set('loading', true);

    case REQUEST_SUBSCRIPTIONS_COMPLETE:
      return state
        .set('fetched', true)
        .set('loading', false)
        .set('error', action.error);
  }
  return state;
}

export const RECEIVE_SUBSCRIPTIONS = 'subscriptions:RECEIVE_SUBSCRIPTIONS';
export function receiveSubscriptions(subscriptions) {
  return {type: RECEIVE_SUBSCRIPTIONS, subscriptions};
}

export const UPDATE_SUBSCRIPTION = 'subscriptions:UPDATE_SUBSCRIPTION';
export function updateSubscription(subscriptionId, subscription) {
  return {type: UPDATE_SUBSCRIPTION, subscriptionId, subscription};
}

export const CLEAR_SUBSCRIPTIONS = 'subscriptions:CLEAR_SUBSCRIPTIONS';
export function clearSubscriptions() {
  return {type: CLEAR_SUBSCRIPTIONS};
}

export const REQUEST_SUBSCRIPTIONS = 'subscriptions:REQUEST_SUBSCRIPTIONS';
export function requestSubscriptions() {
  return {type: REQUEST_SUBSCRIPTIONS};
}

export const REQUEST_SUBSCRIPTIONS_COMPLETE = 'subscriptions:REQUEST_SUBSCRIPTIONS_COMPLETE';
export function requestSubscriptionsComplete(error = null) {
  return {type: REQUEST_SUBSCRIPTIONS_COMPLETE, error};
}

export const REQUEST_DELETE_SUBSCRIPTION = 'subscriptions:REQUEST_DELETE_SUBSCRIPTION';
export function requestDeleteSubscription(subscription) {
  return {
    type: REQUEST_DELETE_SUBSCRIPTION,
    ...subscription
  };
}

export const REQUEST_DELETE_SUPER_SUBSCRIPTION = 'subscriptions:REQUEST_DELETE_SUPER_SUBSCRIPTION';
export function requestDeleteSuperSubscription(subscriptionId) {
  return {type: REQUEST_DELETE_SUPER_SUBSCRIPTION, subscriptionId};
}
