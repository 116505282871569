import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import styles from './info.scss';
import Button from 'components/button';
import {API} from 'config';
import {push} from 'modules/location';
import {triggerEditTest} from 'modules/tests';
import {
  reportSettingsPath,
  uptimeSettingsPath,
  searchCampaignsSettingsPath
} from 'modules/reports/reportUtils';
import {dismissedInfo} from 'modules/userOptions/userOptionSelectors';
import {shallPass, ACCOUNT_OWNER} from 'modules/auth/gandalf';

export class Info extends React.Component {
  handleAction = type => () => {
    switch (type) {
      case 'settings':
        const {
          params: {testId},
          triggerEditTest
        } = this.props;
        triggerEditTest(testId);
        break;
      case 'website-settings':
        this.props.push(reportSettingsPath(this.props.params));
        break;
      case 'search-engine-settings':
        this.props.push(searchCampaignsSettingsPath(this.props.params));
        break;
      case 'uptime-monitor-settings':
        this.props.push(uptimeSettingsPath(this.props.params));
        break;
    }
  };

  render() {
    const {config, isVirtual} = this.props;
    const {html} = config;
    let {src, alt} = config;

    const actions = config.actions.filter(action => {
      return !isVirtual && (!action.role || shallPass(this.props.loggedInUser, ACCOUNT_OWNER));
    });

    if (src && !src.match(/:\/\//)) src = API.ROOT + src;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <span dangerouslySetInnerHTML={{__html: html}} />

          <div>
            {actions &&
              actions.map(action => {
                return (
                  <Button key={action.type} major onClick={this.handleAction(action.type)}>
                    {action.label}
                  </Button>
                );
              })}
          </div>
        </div>
        {src && (
          <div className={styles.right}>
            <div>
              <img src={src} alt={alt || src} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  dismissedInfo,
  {triggerEditTest, push}
)(Info);
