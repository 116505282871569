import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import cx from 'classnames';
import IssueCount from 'components/issueCount';

class Title extends React.Component {
  render() {
    const {data, className, record, options} = this.props;
    const {numConfirmed, numPotential} = record;
    const {scores} = options;
    if (!data) return <span />;

    return (
      <div className={cx(styles.title, className)}>
        {(!!numConfirmed || !!numPotential) && scores && (
          <IssueCount
            style={{marginRight: 5}}
            confirmed={numConfirmed}
            potential={numPotential}
            singular
          />
        )}
        <span title={data} className={styles.text}>
          {data}
        </span>
      </div>
    );
  }
}

Title.propTypes = {
  data: PropTypes.any.isRequired
};

export default Title;
