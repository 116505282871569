exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3l5-igVRxB2kMtNXfHJIwx{display:flex;justify-content:center;align-items:center}._3l5-igVRxB2kMtNXfHJIwx._3dPJfXgJf4OjxD4HSHeUtt{width:100%;height:100%}._3l5-igVRxB2kMtNXfHJIwx._2fIk6sP0jIK4f7u5BujZv1{flex-wrap:wrap}._3l5-igVRxB2kMtNXfHJIwx._2YaDe8CXtshxIKylA2vDCB{flex-direction:column}", ""]);

// exports
exports.locals = {
	"center": "_3l5-igVRxB2kMtNXfHJIwx",
	"fit": "_3dPJfXgJf4OjxD4HSHeUtt",
	"wrap": "_2fIk6sP0jIK4f7u5BujZv1",
	"column": "_2YaDe8CXtshxIKylA2vDCB"
};