export const REQUEST_INTERACTION = 'interactions:REQUEST_INTERACTION';
export const REQUEST_INTERACTION_COMPLETE = 'interactions:REQUEST_INTERACTION_COMPLETE';
export const RECEIVE_INTERACTION = 'interactions:RECEIVE_INTERACTION';

export const REQUEST_INSTRUCTION_TYPES = 'interactions:REQUEST_INSTRUCTION_TYPES';
export const REQUEST_INSTRUCTION_TYPES_COMPLETE = 'interactions:REQUEST_INSTRUCTION_TYPES_COMPLETE';
export const RECEIVE_INSTRUCTION_TYPES = 'interactions:RECEIVE_INSTRUCTION_TYPES';

export const SAVE_INTERACTION = 'interactions:SAVE_INTERACTION';
export const SAVE_INTERACTION_COMPLETE = 'interactions:SAVE_INTERACTION_COMPLETE';

export const REQUEST_DELETE_INTERACTION = 'interactions:REQUEST_DELETE_INTERACTION';
export const REQUEST_DELETE_INTERACTION_COMPLETE =
  'interactions:REQUEST_DELETE_INTERACTION_COMPLETE';

export const TEST_INTERACTION = 'interactions:TEST_INTERACTION';
export const CLEAR_TEST_RESULTS = 'interactions:CLEAR_TEST_RESULTS';
export const TEST_INTERACTION_COMPLETE = 'interactions:TEST_INTERACTION_COMPLETE';
