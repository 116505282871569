import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_DELETE_POLICY} from 'modules/policies/constants';
import {showInModal, hideModal} from 'modules/modal/modal';
import ModalSpinner from 'components/spinner/modalSpinner';
import {showSuccess, showError} from 'utils/saga/alerts';
import {testPath} from 'modules/tests/testUtils';

export default function* deletePolicySaga() {
  yield takeLatest(REQUEST_DELETE_POLICY, function*(action) {
    yield put(showInModal(ModalSpinner));

    yield processRequest('DELETE', 'policies/' + action.policyId, {
      success: function*() {
        yield put(hideModal());
        yield showSuccess('Policy deleted');
        yield put(push(testPath, {testId: 'my-policies-screen'}, ['reportId']));
      },
      error: function*() {
        yield put(hideModal());
        yield showError(couldNot('delete policy'));
      }
    });
  });
}
