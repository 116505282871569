import {delay} from 'redux-saga';
import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {searchResultsEndpoint} from '../utils';
import {FETCH_SEARCH_RESULTS} from '../constants';
import {receiveSearchResults} from '../actions';

export default function* fetchSearchResultsSaga() {
  yield takeLatest(FETCH_SEARCH_RESULTS, fetchSearchResults);
}

function* fetchSearchResults({searchTerm, tags, shouldDebounce, limit, offset, append}) {
  if (!searchTerm && !tags.length) {
    yield put(receiveSearchResults([]));
    return;
  }

  // this acts as a debounce because we're using takeLatest
  if (shouldDebounce) yield delay(300);

  const url = searchResultsEndpoint();
  const data = yield processRequest('POST', url, {}, {term: searchTerm, tags, limit, offset});

  if (data.reports && data.reports.length) {
    yield put(receiveSearchResults(data.reports, append));
  } else if (!append) {
    yield put(receiveSearchResults([]));
  }
}
