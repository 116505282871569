import cx from 'classnames';
import Icon from 'components/font-awesome';
import KeyCodes from 'constants/keyCodes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './policyScopeInput.scss';

export default class PolicyScopeInput extends React.Component {
  static defaultProps = {
    value: 'page'
  };

  static propTypes = {
    value: PropTypes.string
  };

  componentWillMount() {
    const {onChange} = this.props;
    onChange && onChange(this.props.value);
  }

  handleClick = scope => event => {
    const {onChange} = this.props;
    onChange && onChange(scope);
  };

  render() {
    const {value} = this.props;

    return (
      <div className={styles.container}>
        <button
          type="button"
          onClick={this.handleClick('page')}
          className={cx(styles.option, {[styles.active]: value === 'page'})}
        >
          <Icon name="fal fa-browser" className={styles.icon} />
          <div>Page</div>
        </button>
        {/* <button
          type="button"
          onClick={this.handleClick('document')}
          className={cx(styles.option, {[styles.active]: value === 'document'})}
        >
          <Icon name="fal fa-file-word" className={styles.icon} />
          <div>Document</div>
        </button> */}
      </div>
    );
  }
}
