import {reportEndpoint, reportSettingsPath} from 'modules/reports/reportUtils';

export function searchCampaignsEndpoint({reportId}) {
  return reportEndpoint({reportId}) + '/seo-campaigns';
}

export function searchCampaignEndpoint({reportId, campaignId}) {
  return reportEndpoint({reportId}) + '/seo-campaigns/' + campaignId;
}

export function searchCampaignsPath() {
  return reportSettingsPath(...arguments) + '/search-campaigns';
}

export function searchCampaignPath({campaignId}) {
  return reportSettingsPath(...arguments) + '/search-campaigns/' + campaignId;
}
