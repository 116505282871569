import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Tooltip from 'components/tooltip';
import styles from './agencyPlanChooser.scss';
import sharedStyles from 'modules/products/components/planChooserShared.scss';
import Center from 'components/center';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Icon from 'components/font-awesome';
import {nextStep} from 'modules/onboarding';
import TaxExplainer from 'components/legal/taxExplainer';

export class AgencyPlanChooser extends React.Component {
  static propTypes = {
    plans: PropTypes.array.isRequired
  };

  handleClickPlan = planId => event => {
    this.props.nextStep(planId);
  };

  render() {
    const {plans, paymentDetails} = this.props;
    return (
      <div className={sharedStyles.wrapper}>
        <Flex container spaceAround wrap="true" className={styles.plansWrapper}>
          {plans.map(plan => (
            <Flex grow={1} key={plan.planId} className={styles.plan}>
              <div className={styles.head}>
                <span className={styles.price}>${plan.price}</span>
                <span className={styles.interval}>per {plan.interval}</span>
              </div>
              <div className={styles.body}>
                <Tooltip text="One report covers a single website at the time you test it.">
                  <div className={styles.point}>{plan.quantity} reports</div>
                </Tooltip>
                <div className={styles.desc}>per {plan.interval}</div>
                <Tooltip text="The maximum number of webpages tested for a single website.">
                  <div className={styles.point}>{plan.size} webpages</div>
                </Tooltip>
                <div className={styles.desc}>per report</div>
                <Button
                  id={`submitBuyAgencyPlan-${plan.planId}`}
                  onClick={this.handleClickPlan(plan.planId)}
                  paymentDetails={paymentDetails}
                  cta
                  large
                  longer
                >
                  Buy
                </Button>
              </div>
            </Flex>
          ))}
        </Flex>
        <TaxExplainer />
      </div>
    );
  }
}

export default connect(
  null,
  {nextStep}
)(AgencyPlanChooser);
