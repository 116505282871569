import React from 'react';
import cx from 'classnames';
import Button from 'components/button';
import {useSelector, useDispatch} from 'hooks/redux';
import {showAddWidgetModal} from 'modules/dashboards/actions';
import {widgetTypesContextSelector} from 'modules/dashboards/selectors';
import {ModalContent} from 'modules/modal';
import Flex from 'components/layout/flex';
import Icon from 'components/font-awesome';
import styles from './selectWidgetModal.scss';

export default function SelectWidgetModal({contextTypeId, dashboardId, rowIndex, columnIndex}) {
  const dispatch = useDispatch();
  const widgetTypes = useSelector(widgetTypesContextSelector, {contextTypeId});
  const [widgetTypeId, setWidgetTypeId] = React.useState(widgetTypes.first().get('widgetTypeId'));
  const handleSubmit = () => {
    dispatch(showAddWidgetModal(dashboardId, rowIndex, columnIndex, widgetTypeId));
  };
  return (
    <ModalContent
      title="Choose a widget to add"
      footer={<Button onClick={handleSubmit}>Select</Button>}
    >
      {widgetTypes.toList().map(widgetType => {
        return (
          <WidgetOption
            key={widgetType.get('widgetTypeId')}
            active={widgetTypeId === widgetType.get('widgetTypeId')}
            onClick={value => setWidgetTypeId(value)}
            widgetType={widgetType}
          />
        );
      })}
    </ModalContent>
  );
}

function WidgetOption({active, onClick, widgetType}) {
  return (
    <Button
      noStyle
      fullWidth
      autoFocus={active}
      onClick={() => {
        onClick(widgetType.get('widgetTypeId'));
      }}
    >
      <Flex container>
        <Flex>
          <div className={cx(styles.option, {[styles.active]: active})}>
            <Icon className={styles.icon} name={widgetType.get('icon') || 'plus'} />
          </div>
        </Flex>
        <Flex grow={1}>
          <strong>{widgetType.get('name')}</strong>
          <p>{widgetType.get('description')}</p>
        </Flex>
      </Flex>
    </Button>
  );
}
