import React from 'react';
import styles from './draggableReportTableRow.scss';
import cx from 'classnames';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {handleReportDrop} from 'modules/reports';
import {REPORT_DRAG_TYPE} from 'modules/reports/reportUtils';
import Draggable from 'components/dragging/draggable';
import {draggableReportRowSelector} from 'modules/reports/reportSelectors';
import ReportCard from './reportCard';

export class DraggableReportCard extends React.Component {
  render() {
    const {isBeingDragged, ...props} = this.props;
    const classes = cx({
      [styles.isBeingDragged]: isBeingDragged
    });

    return <ReportCard {...props} className={classes} />;
  }

  // we expose report here so that reportsPageDragLayer can render it.
  // TODO: seems a bit coupled.
  // We could rewrite to trigger a beganDraggingReport action and store reportId
  handleBeginDrag = () => ({report: this.props.report});

  handleEndDrag = monitor => {
    const {
      report: {reportId},
      folderId: fromFolder
    } = this.props;

    this.props.handleReportDrop(reportId, fromFolder, monitor);
  };
}

export default compose(
  connect(
    draggableReportRowSelector,
    {handleReportDrop}
  ),
  Draggable(REPORT_DRAG_TYPE)
)(DraggableReportCard);
