import {select, put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {FETCH_LANGUAGES_IF_NECESSARY, receiveLanguages} from 'modules/languages';
import {languagesEndpoint} from 'modules/languages/languageUtils';
import {languagesSelector} from 'modules/languages/languageSelectors';

export default function* fetchLanguagesSaga() {
  yield takeLatest(FETCH_LANGUAGES_IF_NECESSARY, fetchLanguages);
}

function* fetchLanguages() {
  const languages = yield select(languagesSelector);

  if (!languages.size) {
    yield processRequest('GET', languagesEndpoint(), {
      successAction: receiveLanguages
    });
  }
}
