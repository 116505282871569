import {Map, fromJS} from 'immutable';
import uuid from 'node-uuid';

const initialState = fromJS({
  alerts: {},

  decisionId: null,
  decisionAlert: null
});

export default function alertsReducer(state = initialState, action) {
  const {id, message} = action;

  switch (action.type) {
    case ADD_MESSAGE:
      return action.message.severity === 'decision'
        ? state.set('decisionAlert', message.content).set('decisionId', id)
        : state.setIn(['alerts', id], message);

    case REMOVE_MESSAGE:
      return state.get('decisionId') === id
        ? state.set('decisionAlert', null).set('decisionId', null)
        : state.deleteIn(['alerts', id]);
  }

  return state;
}

export const ADD_MESSAGE = 'alerts:ADD_MESSAGE';
export function addMessage(severity, content, expirySeconds = 5) {
  const id = uuid.v4();
  // severities are 'success', 'error', etc.. full list is defined in 'components/alerts/alerts.scss'
  const message = {severity, content};

  return {
    type: ADD_MESSAGE,
    id,
    message,
    expirySeconds
  };
}

export const REMOVE_MESSAGE = 'alerts:REMOVE_MESSAGE';
export function removeMessage(id) {
  return {
    type: REMOVE_MESSAGE,
    id
  };
}

export function addDecisionMessage(decision, context) {
  return addMessage('decision', {...decision, context});
}
