import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import Panel from 'components/panel';
import ExplorerData from './explorerData';
import ExplorerTitle from './explorerTitle';
import ExplorerHeader from './explorerHeader';

export default class Explorer extends React.Component {
  getCurrentView() {
    const {views, viewFilter} = this.props.config;

    if (!views) return [];

    const {filter} = this.props;
    const currentViewName = filter[viewFilter];

    return _.find(views, view => view.name === currentViewName) || views[0];
  }

  render() {
    const {filter, setFilterParams, result} = this.props;
    const {views, viewFilter} = this.props.config;
    const currentView = this.getCurrentView();

    return (
      <div style={{marginBottom: '50px'}}>
        <ExplorerTitle
          currentView={currentView}
          filter={filter}
          views={views}
          viewFilter={viewFilter}
          setFilterParams={setFilterParams}
          result={result}
        />
        <Panel padding={false} hideBorderTop>
          <ExplorerHeader {...this.props} currentView={currentView} />
          <ExplorerData {...this.props} currentView={currentView} />
        </Panel>
      </div>
    );
  }
}
