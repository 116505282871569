import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {assignTagsComplete} from 'modules/tags/actions';
import {ASSIGN_TAGS} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';
import {tagsChanged} from '../utils';

export default function* assignTagsListener() {
  yield takeLatest(ASSIGN_TAGS, assignTags);
}

function* assignTags({entityType, entityId, tagIds, callback}) {
  try {
    const response = yield processRequest(
      'POST',
      `assigned-tags/${entityType}/${entityId}`,
      {},
      {tagIds}
    );
    const responseTagIds = response.map(entityTag => entityTag.tagId);
    yield put(assignTagsComplete(null, entityType, entityId, responseTagIds));

    yield call(tagsChanged, entityType, entityId, responseTagIds, 'add');

    if (callback) yield call(callback, true);
  } catch (err) {
    yield put(assignTagsComplete(err));
    if (callback) yield call(callback, false);
  }
}
