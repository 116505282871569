import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {confirmDeleteMonitor} from 'modules/uptime/actions';

function DeleteUptimeButton({monitorId = 'new', reportId, ...props}) {
  return (
    <Button icon="trash" danger tooltip="Delete this uptime monitor" {...props}>
      Delete
    </Button>
  );
}

export default connect(
  null,
  (dispatch, props) => ({
    onClick: event => {
      dispatch(confirmDeleteMonitor(props.monitorId, props.reportId));
    }
  })
)(DeleteUptimeButton);
