// monitors
export const FETCH_UPTIME_MONITORS = 'uptime:FETCH_UPTIME_MONITORS';
export const CLEAR_UPTIME_MONITORS = 'uptime:CLEAR_UPTIME_MONITORS';
export const RECEIVE_UPTIME_MONITORS = 'uptime:RECEIVE_UPTIME_MONITORS';

// monitor contacts
export const FETCH_UPTIME_MONITOR_CONTACTS = 'uptime:FETCH_UPTIME_MONITOR_CONTACTS';
export const UPDATE_UPTIME_MONITOR_CONTACTS = 'uptime:UPDATE_UPTIME_MONITOR_CONTACTS';
export const RECEIVE_UPTIME_MONITOR_CONTACTS = 'uptime:RECEIVE_UPTIME_MONITOR_CONTACTS';

// monitor contact
export const RECEIVE_UPTIME_MONITOR_CONTACT = 'uptime:RECEIVE_UPTIME_MONITOR_CONTACT';
export const UPDATE_UPTIME_MONITOR_CONTACT = 'uptime:UPDATE_UPTIME_MONITOR_CONTACT';

// monitor
export const RECEIVE_UPTIME_MONITOR = 'uptime:RECEIVE_UPTIME_MONITOR';
export const UPDATE_UPTIME_MONITOR = 'uptime:UPDATE_UPTIME_MONITOR';

// delete monitor
export const DELETE_UPTIME_MONITOR = 'uptime:DELETE_UPTIME_MONITOR';
export const REQUEST_DELETE_UPTIME_MONITOR = 'uptime:REQUEST_DELETE_UPTIME_MONITOR';
export const CONFIRM_DELETE_UPTIME_MONITOR = 'uptime:CONFIRM_DELETE_UPTIME_MONITOR';

export const DELETE_UPTIME_CONTACT = 'uptime:DELETE_UPTIME_CONTACT';
export const REQUEST_DELETE_UPTIME_CONTACT = 'uptime:REQUEST_DELETE_UPTIME_CONTACT';
export const CONFIRM_DELETE_UPTIME_CONTACT = 'uptime:CONFIRM_DELETE_UPTIME_CONTACT';

export const REQUEST_CAN_ADD_UPTIME_MONITOR = 'uptime:REQUEST_CAN_ADD_UPTIME_MONITOR';
export const RECEIVE_CAN_ADD_UPTIME_MONITOR = 'uptime:RECEIVE_CAN_ADD_UPTIME_MONITOR';
