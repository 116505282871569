import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import styles from '../topic.scss';
import ActionPriority from 'modules/actions/components/actionPriority';
import Controls from 'components/layoutComponents/renderers/controls';
import ProgressBar from 'components/progress';
import Pill from 'components/pill';

export default class TopicAction extends React.Component {
  renderInspectorButton = () => {
    const {showInspectorButon, testId, actionId} = this.props;
    if (!showInspectorButon) return null;
    return (
      <div className={styles.topicInspectorButton}>
        <Controls
          testId={testId}
          actionId={actionId}
          record={this.props}
          options={{
            controls: [
              {
                icon: 'fas fa-search',
                appearance: 'major',
                tooltip: 'View any affected webpage(s)',
                instruction: {
                  action: {
                    type: 'value',
                    value: 'inspector'
                  },
                  context: {
                    type: 'object',
                    value: {
                      actionId: {
                        type: 'prop',
                        name: 'actionId'
                      }
                    }
                  },
                  testId: {
                    type: 'prop',
                    name: 'testId'
                  },
                  actionId: {
                    type: 'prop',
                    name: 'actionId'
                  }
                }
              }
            ]
          }}
        />
      </div>
    );
  };

  render() {
    const {
      priority,
      name,
      difficulty,
      onClick,
      actionId,
      state,
      progressDone,
      progressTotal,
      done,
      scoreless,
      nodifficulty,
      nosubname,
      href,
      subName,
      numOpenIssues
    } = this.props;

    const percentage = progressTotal ? (progressDone / progressTotal) * 100 : null;

    return (
      <Link className={styles.topicItemWrapper} to={href}>
        <div className={styles.topicItemIcon}>
          <ActionPriority value={priority} actionState={state} />
        </div>
        <div className={styles.topicItemName}>
          <span className={styles.topicItemLink}>{name}</span>{' '}
          {subName && !nosubname && <span className={styles.subName}>{subName}</span>}
        </div>
        <div className={styles.topicItemIssues}>
          <Pill appearance="minor" className={styles.topicItemIssuesPill}>
            {numOpenIssues}
          </Pill>
        </div>
        {!scoreless && (
          <div className={styles.topicProgress}>
            <ProgressBar value={progressDone} denominator={progressTotal} small label="right" />
          </div>
        )}
        {/* {this.renderInspectorButton()} */}
      </Link>
    );
  }
}
