import React from 'react';
import Pill from 'components/pill';
import Table from 'components/table';
import Panel from 'components/panel';
import Button from 'components/button';
import Status from 'components/status';
import Spinner from 'components/spinner';
import SearchEngine from 'components/searchEngine';

const KEYWORDS_LIMIT = 3;
const SEARCH_ENGINES_LIMIT = 3;

const COLS = [
  {
    label: 'Name',
    field: 'name',
    type: 'jsx',
    jsx: ({data}) => <span className="fauxLink">{data}</span>
  },
  {
    label: 'Keywords',
    field: 'keywords',
    type: 'jsx',
    jsx: ({data}) => {
      return (
        <div>
          {data
            .map(item => item.keyword)
            .slice(0, KEYWORDS_LIMIT)
            .join(', ')}
          {data.length > KEYWORDS_LIMIT && <div className="note">plus others</div>}
        </div>
      );
    }
  },
  {
    label: 'Engines',
    field: 'engines',
    type: 'jsx',
    jsx: ({data}) => {
      return (
        <div>
          {data
            .map(item => (
              <SearchEngine key={item.engineId} engine={item.engine}>
                {item.location.split(',')[0] ||
                  (item.options.countryCode ? item.options.countryCode.toUpperCase() : '')}
              </SearchEngine>
            ))
            .slice(0, SEARCH_ENGINES_LIMIT)}
          {data.length > SEARCH_ENGINES_LIMIT && <div className="note">plus others</div>}
        </div>
      );
    }
  },
  {
    width: '100px',
    label: 'Checks',
    type: 'jsx',
    jsx: ({record}) => {
      return <Pill appearance="minor">{record.keywords.length * record.engines.length}</Pill>;
    }
  }
];

export default class SearchCampaignsConfig extends React.Component {
  render() {
    const {themes, isLoading} = this.props;

    return (
      <React.Fragment>
        <Panel padding={false}>
          <Table
            rowKey="campaignId"
            rows={this.props.themes}
            columns={COLS}
            onClick={row => {
              this.props.onSelectTheme(row.campaignId);
            }}
          />
          {isLoading && !themes.length && <Spinner marginTop={50} marginBottom={50} />}
          {!isLoading && !themes.length && (
            <Status appearance="info">
              There are no search campaigns yet.{' '}
              <Button onClick={this.props.onAddTheme}>Add campaign</Button>
            </Status>
          )}
        </Panel>
      </React.Fragment>
    );
  }
}
