exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1qTwhr5iyxLKxePn24cw5x{font-weight:bold;background:#ffb}", ""]);

// exports
exports.locals = {
	"match": "_1qTwhr5iyxLKxePn24cw5x"
};