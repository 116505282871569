import PropTypes from 'prop-types';
import React from 'react';
import {toFixed} from 'utils/numberUtils.js';

class Bytes extends React.Component {
  render() {
    const {data, options} = this.props;
    const {decimals, ignorezero} = options;

    let text = '';

    if (ignorezero && !data) {
      return null;
    }

    if (data > 1024 * 1024) {
      text = toFixed(data / (1024 * 1024), decimals || 1) + 'MB';
    } else if (data > 1024) {
      text = toFixed(data / 1024, decimals || 0) + 'KB';
    } else if (data !== 'undefined') {
      text = data + ' bytes';
    }

    return <span>{text}</span>;
  }
}

Bytes.propTypes = {};

export default Bytes;
