import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import Panel from 'components/panel';
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import {push} from 'modules/location';
import {connectContext} from 'modules/app/appUtils';
import {evalFilterCondition} from 'modules/filters/filterUtils';
import {choosePluralVersion, interpolate} from 'utils/interpolationUtils';
import {StatValue, StatLabel} from 'components/stat';

export class Stat extends Component {
  resultPending = result => {
    return this.props.config.extrapolate && result == null;
  };

  handleClick = () => {
    const {setFilterParams, config} = this.props;
    const {setFilters} = config;

    if (Array.isArray(setFilters) ? !!setFilters.length : setFilters) {
      setFilterParams(setFilters);
    }
  };

  isFilterable() {
    return !!(
      this.props.config.filter ||
      (this.props.config.hasFilters && this.props.config.hasFilters.length > 0)
    );
  }

  isFiltered() {
    const {config, filter} = this.props;
    const {hasFilters, filter: filterParam} = config;

    if (hasFilters && hasFilters.length > 0) {
      return evalFilterCondition(filter, hasFilters);
    }

    // Backward compatible behaviour. Expect we'll replace these eventually with hasFilters
    return !!filter[filterParam];
  }

  withToolTip = component => (
    <Tooltip
      text={
        <div>
          {this.isFilterable() && <div>Filter by:</div>}
          {this.props.config.tooltip}
        </div>
      }
    >
      {component}
    </Tooltip>
  );

  getLastResultOrNull = result => {
    let results;
    if (typeof result === 'number' || typeof result === 'string') {
      results = {[Math.floor(Date.now() / 1000)]: result};
    }

    // Handle new result format: 17/11/17
    if (result && result.hasOwnProperty('values')) {
      results = result.values;
    }

    if (!results) return null;

    const timestamps = Object.keys(results).sort();
    const lastValue = results[timestamps[timestamps.length - 1]];

    return lastValue;
  };

  renderValue() {
    const {result} = this.props;
    const value = this.getLastResultOrNull(result);
    const pending = this.resultPending(result);
    const {plural, singular, zero} = this.props.config;
    const context = {value};
    const string = choosePluralVersion(value, singular, plural, zero);

    if (pending) {
      return <span>Pending</span>;
    }

    if (value == null) {
      return <Spinner centered />;
    }

    return interpolate(string, context);
  }

  getMappedProps = () => {
    const {config} = this.props;
    const {appearance, label, short, stacked} = config;
    const isFilterable = this.isFilterable();
    const isFiltered = this.isFiltered();

    return {
      stacked,
      appearance,
      onClick: this.handleClick,
      short,
      isFilterable,
      isFiltered,
      label: <StatLabel nowrap={stacked}>{label}</StatLabel>
    };
  };

  getComponent = () => (
    <div>
      <Panel padding={false}>
        <StatValue {...this.getMappedProps()}>{this.renderValue()}</StatValue>
      </Panel>
    </div>
  );

  render() {
    // if stacked a tooltip is added anyway internally to account for text-overflow: ellipsis
    return this.props.config.tooltip && !this.props.config.stacked
      ? this.withToolTip(this.getComponent())
      : this.getComponent();
  }
}

Stat.handlesLoading = true;

export default compose(
  connect(
    null,
    {push}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(Stat);
