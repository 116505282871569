import React from 'react';
import {connect} from 'react-redux';
import {Elements} from 'react-stripe-elements';
import {couldNot} from 'utils/errorUtils';
import {paymentDetailsFormDataSelector} from 'modules/products/productSelectors';
import {clearError} from 'modules/products';
import StripeCard from 'modules/products/components/stripeCard.js';
import formStyles from 'modules/products/components/paymentDetailsForm.scss';
import {Form, Field} from 'modules/form/components';
import Button from 'components/button';
import Totals from 'modules/products/components/totals';
import ErrorMessage from 'modules/form/components/errorMessage';
import PaymentDetails from 'modules/payments/components/paymentDetails';
import OnboardingSubmitButtons from 'modules/onboarding/components/onboardingSubmitButtons';
import {StripeProvider} from 'react-stripe-elements';
import {getStripeKey} from 'modules/payments/paymentUtils';

export class CardForm extends React.Component {
  static defaultProps = {
    name: 'paymentProcess',
    onSubmit: (data, isValid) => null,
    onLoading: isLoading => null
  };

  state = {
    isValid: false,
    stripeError: null
  };

  setCard = ref => {
    this.card = ref;
  };

  hasCard() {
    return this.card && this.card.context;
  }

  getCard() {
    if (!this.hasCard()) {
      return null;
    }

    // NOTE This is weird. `this.card.context` used to contain {stripe and registeredElements} (for v3).
    //      However, it now contains {stripe and getRegisteredElements} (still v3)...
    //
    //      I can't find any information online about this, so I am supporting both methods for BC (just in case)

    let {registeredElements, getRegisteredElements} = this.card.context;

    if (!registeredElements && typeof getRegisteredElements == 'function') {
      registeredElements = getRegisteredElements();
    }

    // `registeredElements` looks like [{element: StripeElement, type: string}, ...]
    return registeredElements.reduce((reduction, value) => {
      return !reduction && value.type == 'card' ? value : reduction;
    }, undefined);
  }

  handleChange = (name, value, data, isValid) => {
    this.setState({isValid});
  };

  handleSubmit = (data, isValid) => {
    // This component can be rendered without a card field (for confirming card details).
    // If the form doesn't have a card field, there is no card to validate, so we simply submit the form
    if (!this.hasCard()) {
      if (isValid) {
        this.props.onSubmit(data, true);
        this.props.onValidSubmit(data);
      }
      return;
    }

    const {stripe} = this.card.context;
    const card = this.getCard();

    if (!card) {
      this.setState({stripeError: 'An unexpected error occurred'});
      return;
    }

    this.setState({stripeError: null});

    if (!isValid) {
      this.props.onSubmit(data, false);
      return;
    }

    this.props.onLoading(true);

    // submit stripe detail
    stripe
      .createToken(card.element, {name: data.name})
      .then(({error, token}) => {
        this.props.onLoading(false);

        if (error) {
          this.setState({stripeError: error.message});

          this.props.onSubmit(data, false);
          return;
        }

        // set card token
        data.token = token;

        this.props.clearError();

        // send `data` plus `token` to the 'buy' endpoint
        this.props.onSubmit(data, true);

        this.props.onValidSubmit(data);
      })
      .catch(err => {
        console.error(err);

        this.props.onLoading(false);

        this.setState({stripeError: couldNot('save card details')});

        this.props.onSubmit(data, false);
      });

    // do NOT call `onValidSubmit` after this call
    return false;
  };

  renderDetails() {
    return (
      <div>
        <PaymentDetails hideUpdateButton />
        <hr />
      </div>
    );
  }

  renderFields() {
    return (
      <div>
        <Field label="Name on card" type="text" name="name" required autoFocus />

        <Elements>
          <div className={formStyles.cardField}>
            <StripeCard ref={this.setCard} />
          </div>
        </Elements>

        <ErrorMessage message={this.state.stripeError} />
      </div>
    );
  }

  render() {
    const {isValid} = this.state;
    const {hasPaymentDetails, isLoading, id} = this.props;

    return (
      <Form
        id={id}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        className={formStyles.checkout}
        defaultValue={this.props.paymentDetails}
      >
        <StripeProvider apiKey={getStripeKey()}>
          {/* StripeProvider expects a single child */}
          <div>
            {hasPaymentDetails ? this.renderDetails() : this.renderFields()}

            <OnboardingSubmitButtons isLoading={isLoading} isValid={hasPaymentDetails || isValid} />
          </div>
        </StripeProvider>
      </Form>
    );
  }
}

export default connect(
  paymentDetailsFormDataSelector,
  {clearError}
)(CardForm);
