import React from 'react';
import {connect} from 'react-redux';
import {Form, Field} from 'modules/form/components';
import {addUser, updateUser} from 'modules/users';
import {REGULAR_USER} from 'modules/auth/gandalf';
import {userSettingsPageSelector} from '../settingsSelectors';
import SettingsPage from 'components/settings/settingsPage';
import SettingItem from 'components/settings/settingItem';
import Button from 'components/button';
import styles from 'components/settings/settingsPage.scss';
import {viewMySettingsPage} from 'modules/settings';

export class UserSettingsPage extends React.Component {
  static defaultProps = {
    title: 'My settings',
    user: {
      // used to tell if adding/updating - not a field
      userId: null,

      name: '',
      email: '',
      phoneNumberFull: '',
      role: REGULAR_USER,

      // only for ACCOUNT_OWNERs and above
      password: '',
      confirmPassword: ''
    }
  };

  state = {
    isValid: false,
    showConfirm: false,
    passwordMatches: true, // hide message initially
    blurConfirm: false
  };

  handleChange = (key, value, data) => {
    const [isValid, messages] = this.form.isFormValid();

    const [isPasswordValid, message] = this.form.fields.password.validate();
    const passwordMatches = !data.password || data.password === data.confirmPassword;

    this.setState({
      passwordMatches,
      isValid: isValid && passwordMatches,
      showConfirm: isPasswordValid && data.password
    });
  };

  setForm = ref => {
    this.form = ref;
  };

  componentWillMount() {
    this.props.viewMySettingsPage();
  }

  handleSubmit = (data, isValid) => {
    if (!isValid) {
      return;
    }

    const {
      addUser,
      updateUser,
      user: {userId}
    } = this.props;

    const [countryPart, phoneNumber] = data.phoneNumberFull.split(')', 2);

    const hasCountryCode = countryPart.includes('(');

    data.phoneNumberCountryCode = hasCountryCode ? countryPart.replace('(', '') : '';

    data.phoneNumber = phoneNumber
      ? phoneNumber
      : !data.phoneNumberCountryCode
      ? data.phoneNumberFull
      : '';

    // ensure password matches.
    if (data.password !== data.confirmPassword) {
      this.setState({passwordMatches: false});
      return;
    }
    this.setState({passwordMatches: true});

    // put autowatch back into options
    let {autoWatch, ...user} = data;
    user.options = {...this.props.user.options, autoWatch};

    if (userId == null) {
      addUser(user);
    } else {
      updateUser(userId, user);
    }
  };

  handleBlur = () => {
    if (!this.state.blurConfirm) {
      this.setState({blurConfirm: true});
    }
  };

  render() {
    const {user, title, allowResetPassword, allowEditRole, isUpdating} = this.props;

    // pull autoWatch out of options
    let {
      options: {autoWatch},
      ...formData
    } = user;
    formData.autoWatch = autoWatch;
    formData.phoneNumberFull = formData.phoneNumberCountryCode
      ? '(' + formData.phoneNumberCountryCode + ')' + formData.phoneNumber
      : formData.phoneNumber;

    return (
      <SettingsPage name={title}>
        <Form
          ref={this.setForm}
          defaultValue={formData}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        >
          <SettingItem title="Name" description="Your full name">
            <Field type="text" name="name" required />
          </SettingItem>
          <SettingItem title="Email" description="Your email address">
            <Field type="email" name="email" required />
          </SettingItem>
          <SettingItem
            title="Mobile phone number (optional)"
            description="Provide the country code (e.g. +44)"
          >
            <Field type="tel" name="phoneNumberFull" />
          </SettingItem>
          {allowEditRole && (
            <SettingItem title="Role" description="The role to assign to this user">
              <Field type="role" name="role" />
            </SettingItem>
          )}

          <SettingItem
            title="Watch new websites"
            description="Automatically watch all new websites added to this account"
            linear
            reverse
          >
            <Field type="boolean" name="autoWatch" />
          </SettingItem>

          {allowResetPassword && (
            <div>
              <hr className={styles.divider} />
              <h3 className={styles.sectionTitle}>Reset password (optional)</h3>

              <SettingItem
                title="Password"
                description="Must be 8 characters or longer and contain at least one uppercase character, a number or a non-alphanumeric character"
              >
                <Field name="password" type="passwordStrength" autoComplete="new-password" />
              </SettingItem>

              {this.state.showConfirm && (
                <SettingItem title="Confirm password">
                  <Field
                    name="confirmPassword"
                    type="password"
                    onBlur={this.handleBlur}
                    onChange={this.handleBlur}
                    invalid={this.state.blurConfirm && !this.state.isValid}
                    validationMessage={
                      this.state.blurConfirm && !this.state.passwordMatches
                        ? 'Your passwords do not match'
                        : null
                    }
                  />
                </SettingItem>
              )}
            </div>
          )}

          <Button type="submit" isLoading={isUpdating}>
            Save
          </Button>
        </Form>
      </SettingsPage>
    );
  }
}

export default connect(
  userSettingsPageSelector,
  {addUser, updateUser, viewMySettingsPage}
)(UserSettingsPage);
