import React from 'react';
import Button from 'components/button';
import Spinner from 'components/spinner';
import {useDispatch, useSelector} from 'hooks/redux';
import {
  addDashboardRow,
  requestDashboard,
  requestWidgetTypes,
  requestDashboardResults
} from '../actions';
import {
  dashboardSelector,
  requestingDashboardSelector,
  requestingWidgetTypesSelector
} from '../selectors';
import Widget from './widget';
import EmptyWidget from '../components/widgets/empty';
import {
  Row as DashboardRow,
  Column as DashboardColumn
} from 'modules/dashboards/components/dashboard';
import WidgetsProvider from './widgetsProvider';
import DashboardRowOptions from './dashboardRowOptions';

export default function EditDashboard({dashboardId, ...context}) {
  const dispatch = useDispatch();
  const dashboardDefinition = useSelector(dashboardSelector, {dashboardId});
  const requestingDashboardState = useSelector(requestingDashboardSelector);
  const requestingWidgetTypesState = useSelector(requestingWidgetTypesSelector);

  React.useEffect(() => {
    dispatch(requestWidgetTypes());
  }, []);

  React.useEffect(() => {
    // re-request results when the dashboard changes
    // the dashboard changes when widgets are added/updated/removed or when the dashboard changes
    dispatch(requestDashboardResults(dashboardId, context));
  }, [dashboardDefinition && dashboardDefinition.get('widgets')]);

  React.useEffect(() => {
    dispatch(requestDashboard(dashboardId));
  }, [dashboardId]);

  if (
    !dashboardDefinition ||
    requestingDashboardState.get('loading') ||
    requestingWidgetTypesState.get('loading')
  ) {
    return <Spinner marginTop={20} marginBottom={20} />;
  }

  const dashboard = dashboardDefinition.get('dashboard');

  return (
    <div>
      {dashboard.get('rows').map((rowData, rowIndex) => {
        return (
          <DashboardRow key={rowIndex} rowIndex={rowIndex}>
            {Array(rowData.get('columns'))
              .fill()
              .map((_, columnIndex) => {
                return (
                  <DashboardColumn key={columnIndex} columnIndex={columnIndex}>
                    <WidgetsProvider
                      dashboardId={dashboardId}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                    >
                      {widgets =>
                        !widgets.size ? (
                          <EmptyWidget
                            dashboardId={dashboardId}
                            rowIndex={rowIndex}
                            columnIndex={columnIndex}
                            contextTypeId={context.contextTypeId}
                          />
                        ) : (
                          widgets.map(widget => {
                            return (
                              <Widget
                                key={widget.get('widgetId')}
                                widget={widget}
                                contextTypeId={context.contextTypeId}
                                isEditing
                              />
                            );
                          })
                        )
                      }
                    </WidgetsProvider>
                  </DashboardColumn>
                );
              })}

            <DashboardColumn key="add-column" endColumn>
              <DashboardRowOptions dashboardId={dashboardId} rowIndex={rowIndex} />
            </DashboardColumn>
          </DashboardRow>
        );
      })}

      <DashboardRow key="add-row" endRow>
        <Button
          icon="plus"
          onClick={() => {
            dispatch(addDashboardRow(dashboardId));
          }}
        >
          Add row
        </Button>
      </DashboardRow>
    </div>
  );
}
