import _ from 'lodash';
import {capitalizeFirstLetter} from 'utils/stringUtils';

export function isIterable(value) {
  return _.isObject(value) || _.isArray(value);
}

// Iterate over `iterable` regardless of whether it is an array or object
export function mapValues(iterable, cb) {
  if (!isIterable(iterable)) throw 'not iterable';
  const iterator = Array.isArray(iterable) ? _.map : _.mapValues;
  return iterator(iterable, cb);
}

export function prefixObjectKeys(obj, prefix) {
  const results = {};
  _.each(obj, (value, key) => {
    results[prefix + capitalizeFirstLetter(key)] = value;
  });
  return results;
}
