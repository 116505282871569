exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3xOYeDzzKG2-vpk8ZKCOMW{0%{opacity:0}100%{opacity:1}}@keyframes _3xOYeDzzKG2-vpk8ZKCOMW{0%{opacity:0}100%{opacity:1}}@-webkit-keyframes hIsh4XVYEothzsTAtmY8I{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes hIsh4XVYEothzsTAtmY8I{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.hpKlBt8HfRqWvE_upIY7J{text-decoration:none}._19TrXABESIJVRDfumG10HO{color:#333;display:flex;flex-direction:row}._19TrXABESIJVRDfumG10HO ._3KgEk1vZif9NIKqkqkky1H{align-self:center;color:#333;min-width:90px;width:90px}._19TrXABESIJVRDfumG10HO ._2_884vnnzqt-SEHZjZhBgh{-webkit-animation:_3xOYeDzzKG2-vpk8ZKCOMW 1s;animation:_3xOYeDzzKG2-vpk8ZKCOMW 1s;-webkit-animation-iteration-count:1;animation-iteration-count:1;align-self:center}._19TrXABESIJVRDfumG10HO ._2_884vnnzqt-SEHZjZhBgh h2{font-weight:600;margin:-20px 0 6px 0}._19TrXABESIJVRDfumG10HO ._2_884vnnzqt-SEHZjZhBgh p{color:#333;opacity:.7;margin:0}@media print{._19TrXABESIJVRDfumG10HO ._2_884vnnzqt-SEHZjZhBgh p{opacity:.9}}._19TrXABESIJVRDfumG10HO span{font-size:12px;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"link": "hpKlBt8HfRqWvE_upIY7J",
	"summaryScore": "_19TrXABESIJVRDfumG10HO",
	"score": "_3KgEk1vZif9NIKqkqkky1H",
	"name": "_2_884vnnzqt-SEHZjZhBgh",
	"fade-in": "_3xOYeDzzKG2-vpk8ZKCOMW",
	"rotating": "hIsh4XVYEothzsTAtmY8I"
};