import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import BingIcon from './bing-icon.png';
import GoogleIcon from './google-icon.png';

class SearchEngine extends React.Component {
  renderIcon(data) {
    switch (data) {
      case 'google':
        return <img src={GoogleIcon} alt="Google" />;
      case 'bing':
        return <img src={BingIcon} alt="Bing" />;
      default:
        return data;
    }
  }

  render() {
    const {engine, className, children} = this.props;
    if (!engine) return null;

    return (
      <div className={cx(styles.searchEngine, className)}>
        {this.renderIcon(engine)}
        {children}
      </div>
    );
  }
}

export default SearchEngine;
