import {take, call, put, cancel, fork, select, takeLatest} from 'redux-saga/effects';
import {MOVE_FOLDER_TO_FOLDER, receiveFolders} from 'modules/folders';
import {moveFolderToFolderEndpoint} from 'modules/folders/folderUtils';
import {selectFolder, foldersSelector} from 'modules/folders/folderSelectors';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* moveFolderToFolderSaga() {
  yield takeLatest([MOVE_FOLDER_TO_FOLDER], moveFolderToFolder);
}

// Waits for a load of the websites list and then loads list of folders.
function* moveFolderToFolder({fromFolder, toFolder}) {
  const url = moveFolderToFolderEndpoint({fromFolder, toFolder});

  yield processRequest(
    'PUT',
    url,
    {
      successAction: receiveFolders
    },
    {position: yield getNewPosition(toFolder)}
  );
}

function* getNewPosition(toFolderId) {
  if (!toFolderId) {
    const folders = yield select(foldersSelector);
    return folders.last().get('right') + 0.5;
  }

  const toFolder = yield select(selectFolder(toFolderId));
  return toFolder.get('right') - 0.5;
}
