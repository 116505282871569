import _ from 'lodash';
import {delay} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';
import {showError, showSuccess} from './alerts';
import PUT from 'utils/saga/put';
import POST from 'utils/saga/post';
import DELETE from 'utils/saga/delete';
import fetch from 'utils/saga/fetch';
import confirm from 'utils/saga/confirm';
import ModalSpinner from 'components/spinner/modalSpinner';
import {showInModal, hideModal} from 'modules/modal/modal';
import {addQueryString} from 'utils/urlUtils';
import {fatalError} from 'modules/app/app';

const httpMethods = {get: fetch, post: POST, put: PUT, delete: DELETE};

// Processes a fetch response and calls provided handlers (if defined).
// Note: if you provide an action creator, it will be provided with the responses json
// as it's only argument.
export function* processResponse(
  res,
  {success, error, successAction, errorAction, successMessage, errorMessage, notFound} = {}
) {
  // If this function wasn't called with the first argument as yield fetch(...)
  // we need to wait for the fetch to complete
  res = yield res;
  let json;
  let isOkay = res.ok;

  if (notFound && res.status === 404) return yield call(notFound);

  try {
    json = yield res.json();
  } catch (e) {
    isOkay = res.status == 204;

    if (__DEV__ && !isOkay && res.status === 0) {
      console.error('Think your back-end is offline.');
    } else if (__DEV__ && !isOkay) {
      console.warn('could not parse response json (response body was probably empty)', e, res);
    }
  }

  if (res.status === 500 && json && json.errorCode === 'notAvailable') {
    yield put(fatalError(json.message));
  }

  if (!successMessage && !successAction && !success) {
    success = function*(data) {
      return data;
    };
  }

  if (!errorMessage && !errorAction && !error) {
    error = function*(err) {
      throw err;
    };
  }

  if (isOkay) {
    if (successMessage) yield call(showSuccess, successMessage);
    if (successAction) yield put(successAction(json));
    if (success) return yield call(success, json, res);
  } else {
    if (errorMessage) yield call(showError, errorMessage);
    if (errorAction) yield put(errorAction(json));

    if (error) {
      // save us having to do this in every handler
      if (__DEV__) {
        console.error(json);
      }
      return yield call(error, json, res);
    }
  }
}

export function* makeRequest(method, url, options = {}, data = {}) {
  method = method.toLowerCase();
  const meth = httpMethods[method];

  if (!meth) {
    console.error(`Invalid request method ${method}`);
  }

  // pass data in query if GET
  if (method == 'get' && !_.isEmpty(data)) {
    url = addQueryString(url, data);
  }

  return yield call(meth, url, data, {timeout: options.timeout});
}

// the same as processResponse, except `res` is created via `method`, `url` and `data`
export function* processRequest(method, url, options = {}, data = {}) {
  const res = yield makeRequest(method, url, options, data);

  return yield processResponse(res, options);
}

// generates a very simple saga for fetching data from an endpoint when
// a certain action is triggered.
// Note: endpoint creator gets passed the whole action object
export function generateFetchSaga(triggerActionType, endpointCreator, requestOptions) {
  return function*() {
    yield takeLatest(triggerActionType, function*(action) {
      yield processRequest('GET', endpointCreator(action), requestOptions, {});
    });
  };
}

// usage:
// export default generateDeleteSaga('nicename', REQUEST_DELETE_NAME, nameEndpoint, {
//   successAction: deleteName
// });
export function generateDeleteSaga(
  niceName,
  triggerActionType,
  endpointCreator,
  requestOptions = {},
  actionName = 'Delete', // Allows you to override e.g. 'Cancel' could be used
  confirmOptions = {}
) {
  return function*() {
    yield takeLatest(triggerActionType, function*(action) {
      const confirmed = yield* confirm({
        title: `${actionName} ${niceName}`,
        content: `Are you sure you wish to ${actionName.toLowerCase()} this ${niceName}?`,
        ...confirmOptions
      });

      if (!confirmed) return;

      yield delay(1);
      yield put(showInModal(ModalSpinner));
      yield processRequest('DELETE', endpointCreator(action), {
        ...requestOptions,
        success: function*(...args) {
          yield put(hideModal());
          // provide original action to success handler
          if (requestOptions.success) {
            yield call(requestOptions.success, action, ...args);
          }
        },
        error: function*(...args) {
          yield put(hideModal());
          // provide original action to success handler
          if (requestOptions.error) {
            yield call(requestOptions.error, ...args);
          }
        }
      });
    });
  };
}
