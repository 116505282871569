import React from 'react';
import InspectorCategoryHeading from 'modules/inspector/components/sidebar/inspectorCategoryHeading';
import InspectorCategoryActions from 'modules/inspector/components/sidebar/inspectorCategoryActions';

class InspectorCategorySidebar extends React.Component {
  render() {
    return (
      <div>
        <InspectorCategoryHeading />
        <InspectorCategoryActions />
      </div>
    );
  }
}

export default InspectorCategorySidebar;
