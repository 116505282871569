import {createSelector, createStructuredSelector} from 'reselect';
import {makeLoadingStateSelector} from 'modules/app/reduxHelpers';

export const domainSelector = state => state.interactions;

// prop selectors
export const instructionTypeIdPropSelector = (_, props) => props.instructionTypeId;

// data selector
export const interactionSelector = createSelector(
  domainSelector,
  state => state.get('interaction')
);

export const instructionTypesSelector = createSelector(
  domainSelector,
  state => state.get('instructionTypes')
);

export const interactionTestResultsSelector = createSelector(
  domainSelector,
  state => state.get('interactionTestResults')
);

export const instructionTypeSelector = createSelector(
  instructionTypeIdPropSelector,
  instructionTypesSelector,
  (instructionTypeId, instructionTypes) => {
    return instructionTypes.find(
      type => type.get('instructionTypeId').toLowerCase() === instructionTypeId.toLowerCase()
    );
  }
);

export const openInstructionTypeSelector = (state, props) => {
  return instructionTypeSelector(state, {instructionTypeId: 'open'});
};

export const instructionTypeOptionsSelector = createSelector(
  instructionTypesSelector,
  instructionTypes => {
    return instructionTypes.map(type => ({
      label: type.get('name'),
      value: type.get('instructionTypeId')
    }));
  }
);

// loading state selectors
export const saveInteractionLoadingSelector = makeLoadingStateSelector(
  domainSelector,
  'saveInteraction'
);

export const testInteractionLoadingSelector = makeLoadingStateSelector(
  domainSelector,
  'testInteraction'
);

export const requestInteractionLoadingSelector = makeLoadingStateSelector(
  domainSelector,
  'requestInteraction'
);

export const requestInstructionTypesLoadingSelector = makeLoadingStateSelector(
  domainSelector,
  'requestInstructionTypes'
);

// component selectors
export const interactionRouteSelector = createSelector(
  requestInteractionLoadingSelector,
  loadingState => ({
    notFound: loadingState.get('loaded') && loadingState.get('error') === 'NOT_FOUND'
  })
);

export const interactionsRouteSelector = createSelector(
  requestInstructionTypesLoadingSelector,
  requestLoadingState => {
    return requestLoadingState.toJS();
  }
);

export const editInteractionPageSelector = createStructuredSelector({
  loadingState: requestInteractionLoadingSelector,
  interaction: interactionSelector
});

export const testInteractionPageSelector = createStructuredSelector({
  interactionTestResults: interactionTestResultsSelector,
  loadingState: requestInteractionLoadingSelector,
  runLoadingState: testInteractionLoadingSelector,
  interaction: interactionSelector
});

export const interactionFormSelector = createSelector(
  openInstructionTypeSelector,
  saveInteractionLoadingSelector,
  (openInstruction, saveLoadingState) => {
    return {
      openInstruction,
      isSaving: saveLoadingState.get('loading')
    };
  }
);
