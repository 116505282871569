import {useState, useRef, useCallback, useLayoutEffect} from 'react';

export default function useResizeObserver(elemRef, debounce = 100) {
  const timeout = useRef(null);
  const [dims, setDims] = useState(createElementDimensions(elemRef));

  const handleResize = useCallback(() => {
    const newState = createElementDimensions(elemRef);

    if (newState.height != dims.height && newState.width != dims.width) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(function() {
        setDims(newState);
      }, debounce);
    }
  }, [elemRef]);

  useLayoutEffect(() => {
    const newState = createElementDimensions(elemRef);
    setDims(newState);

    if (window.ResizeObserver) {
      const observer = new ResizeObserver(handleResize);
      observer.observe(elemRef.current);

      return () => {
        observer.unobserve(elemRef.current);
      };
    } else {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [elemRef]);

  return dims;
}

function createElementDimensions(elemRef) {
  if (elemRef && elemRef.current) {
    return {
      width: elemRef.current.clientWidth,
      height: elemRef.current.clientHeight
    };
  }
  return {width: 'auto', height: 'auto'};
}
