import React from 'react';
import {connect} from 'react-redux';
import InspectorActionRow from './inspectorActionRow';
import InspectorCategoryItem from './inspectorCategoryItem';
import InspectorEmptyActions from './inspectorEmptyActions';
import {inspectorCategoryActionsSelector} from 'modules/inspector/inspectorSelectors';
import styles from './inspectorStyle.scss';

function InspectorCategoryActions({categories, actions, actionCounts}) {
  if (actionCounts.totalActions == 0) {
    return <InspectorEmptyActions />;
  }

  // NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
  // if (actionCounts.hiddenActions == actionCounts.totalActions) {
  //   return <InspectorEmptyActions filtered />;
  // }

  // Either `categories` or `actions` will be populated, not both.
  // This is decided by the route query params and the selectors
  return (
    <div className={styles.categoryActions}>
      {/* some categories contain other categories */}
      {categories.map(category => (
        <InspectorCategoryItem key={category.get('categoryId')} category={category} />
      ))}

      {/* some categories contain actions */}
      {actions.map(action => (
        <InspectorActionRow key={action.get('actionId')} action={action} />
      ))}
    </div>
  );
}

export default connect(inspectorCategoryActionsSelector)(InspectorCategoryActions);
