import React from 'react';

export const RADIAL = 'radial';
export const LINEAR = 'linear';

export const LEFT = 'left';
export const RIGHT = 'right';
export const TOP = 'top';
export const BOTTOM = 'bottom';

const presets = {
  greyRadial: {
    type: RADIAL,
    colourMap: [
      {
        position: 0,
        colour: '#444444'
      },
      {
        position: 100,
        colour: '#000000'
      }
    ]
  }
};

export default function GradientBackground(props) {
  const {preset, children} = props;
  // take gradient info from either the preset map or from the arguments directly
  const {type, direction, colourMap} = presets[preset] || props;

  // create the styles object
  const styles = createInlineStyles(type, direction, colourMap);
  return <div style={styles}>{children}</div>;
}

function createInlineStyles(type, direction, colourMap) {
  // set the gradient type
  const gradientType = type === RADIAL ? 'radial-gradient' : 'linear-gradient';

  let gradientDirection = '';
  if (type === RADIAL) {
    gradientDirection = 'ellipse at center';
  } else {
    switch (direction) {
      case LEFT:
      case RIGHT:
      case TOP:
      case BOTTOM:
        gradientDirection = 'to ' + direction;
        break;
      default: {
        // convert direction to an integer and make sure the value is between 0 and 359;
        const degrees = parseInt(direction, 10) % 360;
        gradientDirection = degrees + 'deg';
        break;
      }
    }
  }

  const propertyValue = [gradientDirection];

  colourMap.forEach(colourPoint => {
    if (!colourPoint || colourPoint.position == null || !colourPoint.colour) {
      return;
    }
    const colourPosition = Math.round(colourPoint.position) + '%';
    propertyValue.push(colourPoint.colour + ' ' + colourPosition);
  });

  return {
    width: '100%',
    height: '100%',
    background: gradientType + '(' + propertyValue.join(', ') + ')'
  };
}
