import {Map, fromJS} from 'immutable';
import {LOGIN} from 'modules/auth';

// This modules only stores the options of the logged in user.

const INITIAL_STATE = Map();

export default function userOptionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      const {
        user: {options}
      } = action;
      return state.merge(fromJS(options));

    case RECEIVE_USER_OPTION:
      return state.set(action.option, action.value);
  }
  return state;
}

export const SET_USER_OPTION = 'userOptions:SET_USER_OPTION';
export function setUserOption(option, value) {
  return {type: SET_USER_OPTION, option, value};
}

export const RECEIVE_USER_OPTION = 'userOptions:RECEIVE_USER_OPTION';
export function receiveUserOption(option, value) {
  return {type: RECEIVE_USER_OPTION, option, value};
}
