import React from 'react';
import cx from 'classnames';
import styles from './mediaIssue.scss';
import AnimateOnChange from 'components/animation/animateOnChange';

class SitemapIssue extends React.Component {
  shouldAnimate = (nextProps, currentProps) => {
    return nextProps.isOpen && !currentProps.isOpen;
  };

  render() {
    const {isOpen} = this.props;

    const classes = cx(styles.wrapper, {
      [styles.open]: isOpen
    });

    return (
      <div className={classes}>
        <div>
          <AnimateOnChange
            isOpen={isOpen}
            comparator={this.shouldAnimate}
            animationClassName="animated pulse"
          >
            This page needs adding to the structured sitemap
          </AnimateOnChange>
        </div>
      </div>
    );
  }
}

export default SitemapIssue;
