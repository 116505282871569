import Icon from 'components/font-awesome';
import Gandalf, {ReportGandalf} from 'components/gandalf';
import Sidebar, {Item} from 'components/sidebar/listSidebar';
import {userSelector} from 'modules/auth/authSelectors';
import {featuresSelector} from 'modules/auth/authSelectors';
import {ACCOUNT_OWNER, SITE_CREATOR} from 'modules/auth/gandalf';
import {ROLE_CONTRIBUTOR, ROLE_MANAGER} from 'modules/gandalf/gandalfUtils';
import {confirmDeleteReport} from 'modules/reports';
import {reportSelector} from 'modules/reports/reportSelectors';
import {lastLocationSelector} from 'modules/reports/reportSelectors';
import {
  advancedSettingsPath,
  alertSubscriptionsPath,
  authenticationSettingsPath,
  cmsSettingsPath,
  forcedPagesSettingsPath,
  generalSettingsPath,
  languageSettingsPath,
  notificationsSettingsPath,
  permissionsSettingsPath,
  reportPath,
  scheduleSettingsPath,
  tagsSettingsPath,
  uptimeSettingsPath
} from 'modules/reports/reportUtils';
import {searchCampaignsPath} from 'modules/searchCampaigns/utils';
import {testsSelector} from 'modules/tests/testSelectors';
import {showAlertsFeature} from 'modules/tests/testSelectors';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {createSelector} from 'reselect';
import styles from './reportSettingsSidebar.scss';

const reportSettingsSidebarSelector = createSelector(
  reportSelector,
  lastLocationSelector,
  testsSelector,
  showAlertsFeature,
  (report, lastLocation, tests, showAlerts) => {
    return {
      report,
      lastLocation,
      hideAlerts: !showAlerts,
      availableTestKeys: Object.keys(tests.toJS()),
      canRetestPages: report.getIn(['retestOptions', 'canRetestPages'], false),
      searchCampaignsFlag: true
    };
  }
);

export class ReportSettingsSidebar extends React.Component {
  renderLink = (title, hrefFn, props) => {
    const {
      match: {params},
      location: {pathname}
    } = this.props;
    const href = hrefFn(params);

    return (
      <Item href={href} active={pathname === href} newStyle {...props}>
        {title}
      </Item>
    );
  };

  handleClickDeleteReport = () => {
    const {params, confirmDeleteReport} = this.props;
    confirmDeleteReport(params.reportId, null, true);
  };

  render() {
    const {
      availableTestKeys,
      report,
      canRetestPages,
      hideAlerts,
      lastLocation,
      params
    } = this.props;

    const isMonitorOrSnapshot = [
      'MasterMonitor',
      'MasterMonitor2',
      'MasterMonitor3',
      'LegacyMonitor',
      'MasterSnapshot',
      'LegacySnapshot'
    ].includes(report.get('sku'));

    return (
      <Sidebar>
        <h2>Website settings</h2>

        <Item href={lastLocation || reportPath(params)} title="Back to website" bigger>
          <Icon name="chevron-left" className={styles.backIcon} /> Back
        </Item>

        <ReportGandalf shallPass={ROLE_MANAGER} reportId={report.get('reportId')}>
          {this.renderLink('Website', generalSettingsPath)}

          {this.renderLink('Schedule', scheduleSettingsPath)}
        </ReportGandalf>

        {hideAlerts
          ? this.renderLink('Notifications', notificationsSettingsPath)
          : this.renderLink('Alerts', alertSubscriptionsPath)}

        <ReportGandalf shallPass={ROLE_MANAGER} reportId={report.get('reportId')}>
          {isMonitorOrSnapshot && this.renderLink('Search campaigns', searchCampaignsPath)}

          {availableTestKeys.includes('uptime') && this.renderLink('Uptime', uptimeSettingsPath)}

          {this.renderLink('Permissions', permissionsSettingsPath)}

          {this.renderLink('Languages', languageSettingsPath)}

          {this.renderLink('Authentication', authenticationSettingsPath)}

          {canRetestPages && this.renderLink('CMS', cmsSettingsPath)}

          <Gandalf shallPass={ACCOUNT_OWNER}>
            {this.renderLink('Forced pages', forcedPagesSettingsPath)}
          </Gandalf>

          {this.renderLink('Advanced', advancedSettingsPath)}
        </ReportGandalf>

        <Gandalf shallPass={SITE_CREATOR}>
          <Item icon="trash" onClick={this.handleClickDeleteReport} newStyle>
            Delete report
          </Item>
        </Gandalf>
      </Sidebar>
    );
  }
}

export default compose(
  connect(
    reportSettingsSidebarSelector,
    {confirmDeleteReport}
  ),
  withRouter
)(ReportSettingsSidebar);
