import React from 'react';

export default class StaggeredRender extends React.Component {
  static defaultProps = {
    delay: 300
  };

  state = {
    rendered: 0
  };

  componentDidMount() {
    this.setTimeout();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentWillReceiveProps() {
    this.setTimeout();
  }

  setTimeout = () => {
    const {children, delay} = this.props;
    const amount = React.Children.count(children);
    if (!this.timeout && amount > this.state.rendered) {
      this.timeout = setTimeout(this.renderNext, delay);
    }
  };

  renderNext = () => {
    const {children, onAllRendered, onProgress} = this.props;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const rendered = this.state.rendered + 1;

    onProgress && onProgress(rendered);

    if (rendered === React.Children.count(children)) {
      onAllRendered();
    }

    this.timeout = null;
    this.setState({rendered}, this.setTimeout);
  };

  render() {
    const children = React.Children.toArray(this.props.children);
    return <div>{children.slice(0, this.state.rendered)}</div>;
  }
}
