import React from 'react';
import PropTypes from 'prop-types';
import CountryFlag from 'components/countryFlag';
import styles from './flagOption.scss';

const propTypes = {
  label: PropTypes.string,
  code: PropTypes.string.isRequired
};

function FlagOption({label, code}) {
  return (
    <div>
      {label && code && <CountryFlag code={code} className={styles.flag} />}
      {label && <span className={styles.flagOption}>{label}</span>}
    </div>
  );
}

FlagOption.propTypes = propTypes;
FlagOption.defaultProps = {
  label: null
};

export default FlagOption;
