import React from 'react';
import Icon from 'components/font-awesome';
import Tooltip from 'components/tooltip';
import styles from './help.scss';
import cx from 'classnames';

export default class Help extends React.Component {
  render() {
    const {message, url, align, className, onClick} = this.props;

    if (!message && !url) {
      return null;
    }

    const icon = <Icon name="fas fa-question-circle" />;

    let el = null;
    if (url) {
      el = icon;
    } else {
      el = (
        <Tooltip text={message} padding={5}>
          {icon}
        </Tooltip>
      );
    }

    return (
      <div className={cx(styles.helpIcon, className)} onClick={onClick}>
        {el}
      </div>
    );
  }
}
