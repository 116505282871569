import React from 'react';
import styles from './typoIssue.scss';
import InsideContext from '../shared/insideContext.js';
import MediaComponent from 'components/media';

export default class PolicyIssue extends React.Component {
  render() {
    const {issueData} = this.props;
    const before = issueData.get('before');
    const match = issueData.get('match');
    const after = issueData.get('after');
    const insideContext = issueData.get('insideContext');
    const src = issueData.get('src');
    const srcType = issueData.get('srcType');
    const isError = issueData.get('isError');
    
    if (isError) {
      return (
        <div>          
          <div className={styles.text}>
            <code className={styles.errorMessage}>{match}</code>
          </div>        
        </div>
      );
    }

    return (
      <div>
        {src && <MediaComponent src={src} srcType={srcType} />}
        <div className={styles.text}>
          <span>{before}</span>
          <span className={styles.typo}>{match}</span>
          <span>{after}</span>
        </div>        
        <InsideContext insideContext={insideContext} />
      </div>
    );
  }
}
