exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1lXRjSLVJqo__fU58CcVE_{cursor:pointer}._22wcfalOv99LhepEFW-UQr{vertical-align:middle;border-radius:10px;width:16px;margin-right:10px}._22wcfalOv99LhepEFW-UQr:before{position:relative;left:-2px}.Jr23Pw9lxswW1Nv04VRiO{margin-right:10px}.rUYgTNTTa2apt4cR-z0-p{color:#ddd}", ""]);

// exports
exports.locals = {
	"buttonWrapper": "_1lXRjSLVJqo__fU58CcVE_",
	"priorityIcon": "_22wcfalOv99LhepEFW-UQr",
	"labelTextButton": "Jr23Pw9lxswW1Nv04VRiO",
	"angleDown": "rUYgTNTTa2apt4cR-z0-p"
};