import React from 'react';
import {connect} from 'react-redux';
import {fromJS} from 'immutable';
import {push} from 'modules/location';
import {Form, Field} from 'modules/form/components';
import Panel from 'components/panel/panel';
import Switch from 'components/switch/switch';
import SwitchOption from 'components/switch/switchOption';
import SettingItem from 'components/settings/settingItem';
import SavePanel from 'modules/form/components/savePanel';
import ErrorMessage from 'modules/form/components/errorMessage';
import {isSearchCampaignUpdatingSelector} from 'modules/searchCampaigns/selectors';
import {currentAccountTypeSearchEngineSelector} from 'modules/accountTypes/accountTypeSelectors';
import {createSelector} from 'reselect';
import {searchCampaignsPath} from 'modules/searchCampaigns/utils';
import PresetEngines from 'modules/searchCampaigns/components/presetEngines';

const KEYWORD_ERROR = `You should enter at least one keyword and one location in the Locations tab.`;

export class SearchCampaignConfig extends React.Component {
  state = {
    tab: 'searchTargets',
    hasChanged: false,
    messages: {},
    isValid: true,
    enginesAdded: []
  };

  componentWillMount() {
    this.formRef = React.createRef();

    this.setState({
      enginesAdded: this.props.campaign.engines.map(engine => engine.location)
    });

    this.isNewTheme = !(
      typeof this.props.campaign.campaignId === 'number' && this.props.campaign.campaignId > 0
    );
  }

  handleValidSubmit = data => {
    const {defaultCountryCode} = this.props;
    const newData = fromJS(data)
      .update('engines', engines => {
        return engines.map(engine => {
          const code = engine.get('countryCode');
          return engine.set('countryCode', code ? code : defaultCountryCode);
        });
      })
      .update('keywords', keywords => {
        return keywords.filter(item => {
          return item.get('keyword').length > 0;
        });
      })
      .toJS();

    this.props.onSave(newData, () => {
      // reset any local changes after a save
      this.setState({localKeywordsUsed: 0});
    });
  };

  handleChange = (name, value, data, isValid, hasChanged, messages) => {
    if (this.props.onKeywordsUsedChanged) {
      this.props.onKeywordsUsedChanged(data.engines.length * data.keywords.length);
    }

    this.setState({
      hasChanged,
      isValid,
      messages,
      enginesAdded: data.engines.map(engine => engine.location)
    });
  };

  handleAddPresetEngine = engine => {
    const {engines} = this.formRef.current.get();
    this.formRef.current.set('engines', [...engines, engine]);
    this.setState({enginesAdded: [...this.state.enginesAdded, engine.location]});
  };

  validateForm = ({keywords}) => {
    const validKeywords = keywords.filter(item => item.keyword.trim());

    if (!validKeywords.length) {
      return {
        isValid: false,
        message: 'You should enter at least one keyword and a location in the Locations tab.'
      };
    }

    return {isValid: true};
  };

  render() {
    const {isLoading} = this.props;
    return (
      <Form
        ref={this.formRef}
        defaultValue={this.props.campaign}
        onChange={this.handleChange}
        onValidSubmit={this.handleValidSubmit}
        customValidation={this.validateForm}
      >
        <Panel marginBottom>
          <SettingItem title="Name" description="What should this campaign be called?" noMargin>
            <Field
              autoFocus
              name="name"
              type="text"
              placeholder='e.g. "my campaign"'
              required
              customMessages={{
                required: `You should enter a name for this campaign`
              }}
            />
          </SettingItem>
        </Panel>

        <Switch tabbed large>
          <SwitchOption
            active={this.state.tab === 'searchTargets'}
            onClick={() => this.setState({tab: 'searchTargets'})}
          >
            Keywords
          </SwitchOption>
          <SwitchOption
            active={this.state.tab === 'searchEngines'}
            onClick={() => this.setState({tab: 'searchEngines'})}
          >
            Locations
          </SwitchOption>
        </Switch>

        <Panel marginBottom>
          <SettingItem
            hidden={this.state.tab !== 'searchTargets'}
            title="Keywords"
            description="What should this website rank for in search engines?"
            noMargin
          >
            <Field
              required
              name="keywords"
              type="searchTargets"
              placeholder='e.g. "florists in San Francisco", "wedding flowers San Francisco"'
              help="Optionally enter any keywords you would like to target for SEO, separated by commas."
              customMessages={{
                required: KEYWORD_ERROR
              }}
            />
            {!this.state.isValid && (
              <ErrorMessage
                message={
                  this.state.messages.keywords || (!this.state.messages.name && KEYWORD_ERROR)
                }
                style={{margin: 0}}
              />
            )}
          </SettingItem>

          <SettingItem
            hidden={this.state.tab !== 'searchEngines'}
            title="Location presets"
            description="Choose from some pre-existing locations or add your own below"
            noMargin
          >
            <PresetEngines added={this.state.enginesAdded} onClick={this.handleAddPresetEngine} />
          </SettingItem>
        </Panel>

        <Panel hidden={this.state.tab !== 'searchEngines'}>
          <SettingItem
            hidden={this.state.tab !== 'searchEngines'}
            title="Locations"
            description="What locations should we check these keywords in?"
            noMargin
          >
            <Field
              required
              name="engines"
              type="searchEngines2"
              customMessages={{
                required: `You should enter at least one location and one keyword in the Keywords tab.`
              }}
            />
            {!this.state.isValid && (
              <ErrorMessage message={this.state.messages.engines} style={{margin: 0}} />
            )}
          </SettingItem>
        </Panel>

        <SavePanel
          floating
          onCancel={this.props.viewSearchCampaigns}
          isLoading={isLoading}
          isValid={this.state.isValid}
          show={this.isNewTheme || this.state.hasChanged}
        />
      </Form>
    );
  }
}

export default connect(
  createSelector(
    isSearchCampaignUpdatingSelector,
    currentAccountTypeSearchEngineSelector,
    (isLoading, limits) => ({...limits, isLoading})
  ),
  dispatch => ({
    viewSearchCampaigns() {
      dispatch(push(searchCampaignsPath, {}, ['reportId']));
    }
  })
)(SearchCampaignConfig);
