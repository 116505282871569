import {Map, fromJS} from 'immutable';
import {createSelector} from 'reselect';
import {testsSelector} from 'modules/tests/testSelectors';
import {usersSelector} from 'modules/users/userSelectors';
import {DEFAULT_CONTACT_OPTIONS} from 'modules/uptime/utils';

const uptimeMonitorDomain = state => state.uptime;
const moditorIdRoutePropSelector = (_, props) => props.match.params.monitorId;

export const uptimeMonitorsSelector = createSelector(
  uptimeMonitorDomain,
  state => (state.get('uptimeMonitors') ? state.get('uptimeMonitors') : Map())
);

export const uptimeMonitorContactsSelector = createSelector(
  uptimeMonitorDomain,
  state => (state.get('uptimeContacts') ? state.get('uptimeContacts') : Map())
);

export const isLoadingSelector = createSelector(
  uptimeMonitorDomain,
  state => state.get('isLoading')
);

export const canAddUptimeMonitorSelector = createSelector(
  uptimeMonitorDomain,
  state => state.get('canAdd')
);

export const isCheckingCanAddUptimeMonitorSelector = createSelector(
  uptimeMonitorDomain,
  state => state.get('isCheckingCanAdd')
);

export const uptimeMonitorRoutePropSelector = createSelector(
  moditorIdRoutePropSelector,
  uptimeMonitorsSelector,
  (monitorId, uptimeMonitors) => uptimeMonitors.get(monitorId)
);

export const uptimeMonitorContactsRoutePropSelector = createSelector(
  moditorIdRoutePropSelector,
  uptimeMonitorContactsSelector,
  (monitorId, uptimeContacts) => uptimeContacts.get(monitorId)
);

export const usersSplitByUptimeContactsSelector = createSelector(
  usersSelector,
  uptimeMonitorContactsSelector,
  (users, uptimeContacts) => {
    return users.reduce(
      (result, user) => {
        const userHasMatchingUptimeContacts = uptimeContacts.find(
          uptimeContact => uptimeContact.get('userId').toString() === user.get('userId').toString()
        );

        const whichGroup = userHasMatchingUptimeContacts
          ? 'usersWithContacts'
          : 'usersWithoutContacts';

        return result.update(whichGroup, userGroup =>
          userGroup.push(user.set('uptimeContact', userHasMatchingUptimeContacts))
        );
      },
      fromJS({
        usersWithContacts: [],
        usersWithoutContacts: []
      })
    );
  }
);

export const saveUptimeMonitorSelector = createSelector(
  uptimeMonitorRoutePropSelector,
  usersSplitByUptimeContactsSelector,
  testsSelector,
  canAddUptimeMonitorSelector,
  isCheckingCanAddUptimeMonitorSelector,
  (
    uptimeMonitor,
    usersSplitByContacts,
    tests,
    canAddUptimeMonitor,
    isCheckingCanAddUptimeMonitor
  ) => {
    return {
      uptimeMonitor,
      canAddUptimeMonitor,
      isCheckingCanAddUptimeMonitor,
      websiteConfig: tests ? tests.get('website') : Map(),
      usersWithContacts: usersSplitByContacts.get('usersWithContacts'),
      usersWithoutContacts: usersSplitByContacts.get('usersWithoutContacts')
    };
  }
);

export const uptimeContactMethodSelector = createSelector(
  (_, props) => props.userId.toString(),
  uptimeMonitorContactsSelector,
  (userId, uptimeContacts) => {
    const uptimeContactsObj = uptimeContacts.get(userId);
    return {
      contactOptions: !uptimeContactsObj
        ? DEFAULT_CONTACT_OPTIONS
        : uptimeContactsObj.get('contactOptions')
    };
  }
);
