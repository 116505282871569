import React from 'react';
import styles from './checkboxes.scss';

export default class CheckboxOption extends React.Component {
  handleClickOption = (value, checked, disabled) => event => {
    const {onClick, radio} = this.props;

    if (disabled) {
      return;
    }

    if (event.target.nodeName === 'LABEL') {
      // Ignore the label click event because it triggers a click event on the INPUT.
      // If we click the div instead, then nodeName will be "DIV" and we can pass.
      return;
    }

    if (radio) {
      onClick && onClick(value);
    } else {
      onClick && onClick(value, !checked);
    }
  };

  render() {
    const {radio, name, value, children, checked, disabled} = this.props;

    return (
      <div
        className={styles.checkboxOption}
        onClick={this.handleClickOption(value, !!checked, !!disabled)}
      >
        <label>
          <input
            type={radio ? 'radio' : 'checkbox'}
            name={name}
            readOnly
            checked={!!checked}
            disabled={!!disabled}
          />
          {children}
        </label>
      </div>
    );
  }
}
