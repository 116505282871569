import React from 'react';
import {connect} from 'react-redux';
import Pill from 'components/pill';
import styles from './action.scss';
import ActionDescription from 'modules/actions/components/actionDescription';
import {actionHeaderSelector} from 'modules/actions/actionSelectors';

// NOTE this is still called taskName because that is the type of the layout component (and how the file will be resolved)
import ActionName from 'components/layoutComponents/renderers/taskName';
import ExternalLink from 'modules/location/externalLink';

export class Action extends React.Component {
  static defaultProps = {
    links: [],
    tags: []
  };

  handleClickAction = event => {
    const {onClick, actionId} = this.props;
    onClick && onClick(actionId);
  };

  renderTag = tag => (
    <Pill key={tag} appearance="muted" square style={{margin: '5px'}}>
      {tag}
    </Pill>
  );

  renderLink = ({url, label}) => (
    <ExternalLink key={label} href={url} style={{margin: '5px'}}>
      {label}
    </ExternalLink>
  );

  render() {
    const {
      actionId,
      description,
      longDescription,
      issueCount,
      hideDescription,
      showPriority,
      notLink,
      smaller
    } = this.props;

    return (
      <div key={`action_${actionId}//`} className={styles.action}>
        <div style={{display: 'flex'}}>
          <ActionName
            record={this.props}
            style={{flex: '1'}}
            notLink={true}
            showPriority={showPriority}
            smaller={smaller}
          />
          {/* <div>
            {issueCount >= 0 && (
              <Pill appearance="minor" showZero>
                {issueCount}
              </Pill>
            )}
          </div> */}
        </div>

        {!hideDescription && (
          <ActionDescription description={description} longDescription={longDescription} />
        )}
      </div>
    );
  }
}

export default connect(actionHeaderSelector)(Action);
