import {basePath} from 'modules/auth/authUtils';

/**
 * Internal Chell routes
 */
export function productBuyPath() {
  return basePath(...arguments) + `/product/buy`;
}

export function productBuyConfigPath({productId}) {
  return productBuyPath(...arguments) + `/${productId}`;
}

/**
 * Endpoints
 */
export function superProductsEndpoint() {
  return `super-products`;
}

export function superProductsQuotesEndpoint(productId) {
  return `super-products/${productId}/quote-multi`;
}

export function superProductsBuyEndpoint(productId, planId) {
  return `super-products/${productId}/plans/${planId}/checkout`;
}
