import React from 'react';
import Button from 'components/button';
import styles from './onboardingWrapper.scss';

export default class OnboardingSubmitButtons extends React.Component {
  static defaultProps = {
    nextLabel: 'Next',
    previousLabel: 'Previous',
    showPrevious: false
  };

  render() {
    const {nextLabel, previousLabel, showPrevious, isLoading, isValid} = this.props;

    return (
      <div className={styles.buttonContainer}>
        <Button
          cta
          large
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}
          className={styles.nextBtn}
          iconClass={styles.nextIcon}
          onClick={this.handleNextStep}
          iconSide={isLoading ? 'left' : 'right'}
          icon={isLoading ? 'spinner' : 'chevron-right'}
        >
          {nextLabel}
        </Button>
        {showPrevious && (
          <Button
            grey
            plain
            icon="chevron-left"
            className={styles.prevBtn}
            onClick={this.handlePrevStep}
          >
            {previousLabel}
          </Button>
        )}
      </div>
    );
  }
}
