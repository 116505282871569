import PropTypes from 'prop-types';
import React from 'react';
import styles from './alerts.scss';
import cx from 'classnames';
import Icon from 'components/font-awesome';

const iconMap = {
  error: 'remove',
  success: 'check'
};

class Alert extends React.Component {
  static defaultProps = {};

  render() {
    const {children, severity, className, ...props} = this.props;

    const classes = cx(styles.alert, className, {
      [styles.error]: severity === 'error',
      [styles.success]: severity === 'success'
    });

    const iconName = iconMap[severity];

    return (
      <a {...props} className={classes}>
        {iconName && <Icon name={iconName} className={styles.icon} />}
        {children}
      </a>
    );
  }
}

export default Alert;
