import {createSelector} from 'reselect';
import {userIdPropSelector, usersSelector} from 'modules/users/userSelectors';
import {toJS} from 'utils/immutableUtils';
import {userSelector} from 'modules/auth/authSelectors';
import {
  userBadgesSelector,
  userAchievementsSelector,
  isLoadingSelector
} from 'modules/achievements/achievementSelectors';

export const profilePageSelector = createSelector(
  userBadgesSelector,
  userAchievementsSelector,
  isLoadingSelector,
  (badges, achievements, loading) => ({
    badges,
    achievements,
    loading
  })
);

export const profileSidebarSelector = createSelector(
  usersSelector,
  userIdPropSelector,
  (users, userId) => {
    return {user: toJS(users.get(userId))};
  }
);

export const profileRoutePageSelector = createSelector(
  userIdPropSelector,
  usersSelector,
  isLoadingSelector,
  (userIdProp, users, loading) => {
    const user = toJS(users.get(userIdProp));
    return {
      user,
      loading,
      should404: !loading && !user
    };
  }
);
