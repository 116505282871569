import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Button from 'components/button';
import {createStructuredSelector} from 'reselect';
import {reportSelector} from 'modules/reports/reportSelectors';
import {
  searchCampaignsSelector,
  isSearchCampaignsLoadingSelector
} from 'modules/searchCampaigns/selectors';
import PageHeader from 'components/header/pageHeader';
import PageBody from 'components/page/pageBody';
import {searchCampaignPath} from 'modules/searchCampaigns/utils';
import SearchCampaignsConfig from '../../components/reportSettings/searchCampaignsConfig';
import SearchCampaignsBreadcrumb from 'modules/searchCampaigns/containers/searchCampaignsBreadcrumb';
import MaxWidth from 'components/maxWidth';

export const ReportSettingsSearchCampaignsPage = ({
  addTheme,
  isLoading,
  searchCampaigns,
  selectTheme
}) => (
  <div>
    <SearchCampaignsBreadcrumb>
      <MaxWidth>
        <PageHeader
          title="Your search campaigns"
          inline={[
            <Button key="add" primary icon="plus" onClick={addTheme}>
              Add campaign
            </Button>
          ]}
        />
        <PageBody>
          <SearchCampaignsConfig
            isLoading={isLoading}
            themes={searchCampaigns.toJS()}
            onSelectTheme={selectTheme}
            onAddTheme={addTheme}
          />
        </PageBody>
      </MaxWidth>
    </SearchCampaignsBreadcrumb>
  </div>
);

const reportSettingsSearchCampaignsPageSelector = createStructuredSelector({
  report: reportSelector,
  searchCampaigns: searchCampaignsSelector,
  isLoading: isSearchCampaignsLoadingSelector
});

const mapDispatchToProps = dispatch => ({
  selectTheme: campaignId => {
    dispatch(push(searchCampaignPath, {campaignId}, ['reportId']));
  },
  addTheme: () => {
    dispatch(push(searchCampaignPath, {campaignId: 'new'}, ['reportId']));
  }
});

export default connect(
  reportSettingsSearchCampaignsPageSelector,
  mapDispatchToProps
)(ReportSettingsSearchCampaignsPage);
