import React from 'react';
import styles from './linkIssue.scss';
import Tooltip from 'components/tooltip';
import ExternalLink from 'modules/location/externalLink';

class HttpProtocolIssue extends React.Component {
  render() {
    const {issueData} = this.props;
    const src = issueData.get('src');
    const type = issueData.get('type');
    const httpVersion = issueData.get('httpVersion');

    if (type === 'Page') {
      return (
        <div>
          <p className={styles.text}>This issue applies to the whole page</p>

          <br />

          <Tooltip text={'The main HTML of this page is using ' + httpVersion}>
            <p className={styles.annotation}>{httpVersion}</p>
          </Tooltip>
        </div>
      );
    }

    return (
      <div>
        <p className={styles.link}>
          <ExternalLink href={src}>{src}</ExternalLink>
        </p>

        <Tooltip text={'The resource is using ' + httpVersion}>
          <p className={styles.annotation}>{httpVersion}</p>
        </Tooltip>
      </div>
    );
  }
}

export default HttpProtocolIssue;
