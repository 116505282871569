import {takeLatest, put} from 'redux-saga/effects';
import {
  REQUEST_QUOTES,
  receiveQuote,
  handleQuoteError
} from 'modules/superProduct/superProductActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {superProductsQuotesEndpoint} from 'modules/superProduct/superProductUtils';

export default function* requestProductQuoteSaga() {
  yield takeLatest([REQUEST_QUOTES], function*({productId, subConfigs, paymentOptions}) {
    yield processRequest(
      'POST',
      superProductsQuotesEndpoint(productId),
      {
        successAction: receiveQuote,
        error: function*(err) {
          const errorMsg = err.message || 'Failed to load quote.';
          yield put(handleQuoteError(errorMsg));
        }
      },
      {
        subConfigs,
        ...paymentOptions
      }
    );
  });
}
