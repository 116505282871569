exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._15V5PX-69RUKsUAlP98XCU p{text-align:center}._15V5PX-69RUKsUAlP98XCU p a{color:#030303;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"copyright": "_15V5PX-69RUKsUAlP98XCU"
};