exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ExYHXTrC1SOICmwioxOS7{margin:10px 0 20px;padding:0 0 10px 0;border-bottom:1px solid #ddd;text-transform:uppercase;font-size:16px !important;color:#666 !important;font-weight:bold;min-height:auto !important}._225qSRIWrABVJGk__IXqFh{border:0;margin-bottom:10px}@media print{._2ExYHXTrC1SOICmwioxOS7{border:0}}", ""]);

// exports
exports.locals = {
	"divider": "_2ExYHXTrC1SOICmwioxOS7",
	"plain": "_225qSRIWrABVJGk__IXqFh"
};