import {call, put, takeLatest} from 'redux-saga/effects';
import POST from 'utils/saga/post';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {setWatchingReport} from 'modules/reports';
import {UPDATE_WATCHING_REPORT} from 'modules/reports/reportConstants';

export default function* updateWatchingReportSaga() {
  yield takeLatest(UPDATE_WATCHING_REPORT, updateWatchingReport);
}

function* updateWatchingReport({reportId, isWatching}) {
  const res = yield call(POST, `reports/${reportId}/watches`, {isWatching});

  yield processResponse(res, {
    successAction: setWatchingReport(reportId),
    successMessage: isWatching
      ? 'You are now watching this report'
      : 'You are no longer watching this report',
    errorMessage: couldNot(`update your watch preference. Please try again later`)
  });
}
