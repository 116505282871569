import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {connect} from 'react-redux';
import {doInstruction} from 'modules/instructions';
import styles from './timeline.scss';
import Control from 'components/control';

function Story({
  triggerResizeBool,
  storyHash,
  children,
  doInstruction,
  shouldShowInstructionButton,
  ...story
}) {
  return (
    <div className={cx(styles.story)}>
      <StoryHead {...story} doInstruction />
      <StoryBody>
        {children}

        {shouldShowInstructionButton && (
          <div style={{textAlign: 'center', marginBottom: '20px'}}>
            <Control
              className={styles.showMoreControl}
              instruction={story.instruction}
              label="View all"
              small
              appearance="major"
            />
          </div>
        )}
      </StoryBody>
    </div>
  );
}

function StoryHead({icon, iconBackgroundColor, name, title, time, instruction, doInstruction}) {
  return (
    <div className={styles.storyHead} onClick={() => doInstruction(instruction)}>
      <img className={styles.icon} style={{background: iconBackgroundColor}} src={icon} />
      <span className={styles.title}>{title}</span>
    </div>
  );
}

function StoryBody({children}) {
  return <div className={cx(styles.storyBody)}>{children}</div>;
}

export default connect(
  null,
  {doInstruction}
)(Story);
