import _ from 'lodash';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PageWithFixed from './pageWithFixed';
import cx from 'classnames';
import styles from 'components/sidebar/listSidebar/sidebar.scss';
import {toggleSidebar, toggleAccountMenu} from 'modules/app';
import {isSidebarHiddenObjectSelector} from 'modules/app/appSelectors';
import AccountMenu from 'components/nav/accountMenu';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class PageWithSidebar extends React.Component {
  static defaultProps = {
    slideThreshold: 20
  };

  renderAccountMenu() {
    return <AccountMenu />;
  }

  renderChildren() {
    // All children should have a min width of 320px,
    // and they should all fade when they are too small.
    const {children, eitherSidebarActive} = this.props;
    const classes = cx(styles.minWidthChildren, {
      [styles.faded]: eitherSidebarActive
    });

    return (
      <div
        className={styles.wrapperStyle}
        onClick={() =>
          this.props.screenSizes.tablet &&
          !this.props.isSidebarHidden &&
          this.props.toggleSidebar(true)
        }
      >
        <div className={classes}>{children}</div>
      </div>
    );
  }

  render() {
    const {sidebar, className} = this.props;

    return (
      <PageWithFixed
        orientation="horizontal"
        className={className}
        // onClickFixed={this.handleClickSidebar('sidebar')}
        fixedElement={sidebar}
        fixedSize={cx(styles.mainSidebar, {
          [styles.small]: this.props.isSidebarHidden
        })}
      >
        {this.renderChildren()}
      </PageWithFixed>
    );
  }
}

export default compose(
  connect(
    isSidebarHiddenObjectSelector,
    {toggleSidebar, toggleAccountMenu}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(PageWithSidebar);
