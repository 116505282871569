import {createSelector} from 'reselect';
import {createPropSelector} from 'utils/selectorUtils';
import {Map} from 'immutable';
import {testIdSelector} from 'modules/tests/testSelectors';
import {getResult, getResultError} from './resultUtils';
import {currentFilterSelector} from 'modules/filters/filterSelectors';
import {removeStoreStateSelector} from 'modules/removeStore/removeStoreSelectors';

export const resultsSelector = state => state.results.get('data') || Map();
export const resultIdSelector = createPropSelector('resultId');

export const resultTestIdSelector = createSelector(
  createPropSelector('config.testId'),
  testIdSelector,
  (configTestId, testId) => configTestId || testId
);

export const resultsForCurrentSelector = createSelector(
  resultsSelector,
  currentFilterSelector,
  (results, filters) => {
    return results.keySeq().reduce((carry, value) => {
      carry[value] = getResult(results, value, filters);
      return carry;
    }, {});
  }
);
// Creates a selector for a result,
// using the current testId and filters
export const createResultSelector = function(selectResultId) {
  return createSelector(
    // a selector which returns the resultId we want
    selectResultId,
    resultsSelector,
    currentFilterSelector,
    removeStoreStateSelector,
    (resultId, results, filters, removeStore) => {
      if (!resultId) return null;

      let result = getResult(results, resultId, filters);
      return getResultPerformingRemoveStoreActions(resultId, result, removeStore);
    }
  );
};

export const createResultErrorSelector = function(selectResultId) {
  return createSelector(
    // a selector which returns the resultId we want
    selectResultId,
    resultsSelector,
    currentFilterSelector,
    (resultId, results, filters) => {
      if (!resultId) return null;

      const error = getResultError(results, resultId, filters);
      return error;
    }
  );
};

// Uses createResultSelector, then pulls out the `value`
export const createResultValueSelector = function(...args) {
  return createSelector(
    createResultSelector(...args),
    result => {
      return result;
    }
  );
};

// The result specified by props.resultId
export const resultSelector = createResultSelector(resultIdSelector);

/**
 * This will allow us to loop through items for this result and remove anything
 * that has been set to be ignored for the time being (until expiry date)
 * @param {*} result
 * @param {*} removeStore
 */
function getResultPerformingRemoveStoreActions(resultId, result, removeStore) {
  if (result !== null && removeStore.has(resultId) && result.rows && result.rows.length > 0) {
    const removeStoreItems = removeStore.get(resultId);
    removeStoreItems.forEach(removeStoreItem => {
      if (removeStoreItem.expires > Date.now()) {
        const newRows = result.rows.reduce((carry, rowData) => {
          if (!rowData || rowData[removeStoreItem.key] !== removeStoreItem.value) {
            carry.push(rowData);
          }
          return carry;
        }, []);

        result = {...result, rows: newRows, numRows: newRows.length};
      }
    });
  }

  return result;
}
