export const REQUEST_COURSES = 'courses:REQUEST_COURSES';
export const REQUEST_COURSES_COMPLETE = 'courses:REQUEST_COURSES_COMPLETE';

export const REQUEST_COURSE = 'courses:REQUEST_COURSE';
export const REQUEST_COURSE_COMPLETE = 'courses:REQUEST_COURSE_COMPLETE';

export const REQUEST_LESSON = 'courses:REQUEST_LESSON';
export const REQUEST_LESSON_COMPLETE = 'courses:REQUEST_LESSON_COMPLETE';

export const SAVE_LESSON_STATE = 'courses:SAVE_LESSON_STATE';
export const SAVE_LESSON_STATE_COMPLETE = 'courses:SAVE_LESSON_STATE_COMPLETE';

export const COURSE_ENROLL = 'courses:ENROL_ON_COURSE';
export const COURSE_ENROLL_COMPLETE = 'courses:ENROL_ON_COURSE_COMPLETE';
export const COURSE_UNENROLL = 'courses:UNENROLL_FROM_COURSE';
export const COURSE_UNENROLL_COMPLETE = 'courses:UNENROLL_FROM_COURSE_COMPLETE';

export const SET_ENROLLED_USERS = 'courses:SET_ENROLLED_USERS';
export const SET_ENROLLED_USERS_COMPLETE = 'courses:SET_ENROLLED_USERS_COMPLETE';

export const SAVE_QUIZ_STATE = 'courses:SAVE_QUIZ_STATE';
export const SAVE_QUIZ_STATE_COMPLETE = 'courses:SAVE_QUIZ_STATE_COMPLETE';

// Used for stubbing the UI when testing
export const REDUX_EXAMPLE_COURSES = {
  '1': {
    courseId: 1,
    name: 'Course 1',
    imageUrl: 'https://placekitten.com/360/180',
    description: 'This is *a* description',
    stages: [
      {
        stageId: 1,
        courseId: 1,
        name: 'Stage 1',
        sortOrder: 1,
        lessons: [
          {
            lessonId: 1,
            stageId: 1,
            courseId: 1,
            lessonTypeId: 'video',
            name: 'Stage 1 Lesson 1',
            sortOrder: 1,
            options: {
              src: 'https://www.youtube.com/watch?v=mQZY7EmjbMA',
              captionSrc: '',
              quiz: {
                minPercent: 100,
                questions: [
                  {
                    question: 'What is the best?',
                    type: 'choice',
                    options: ['Apple Mac', 'Windows', 'Linux'],
                    answer: ['Apple Mac'],
                    hint: 'Are you dense?'
                  },
                  {
                    question: 'Which of these are superheroes?',
                    type: 'multiChoice',
                    options: ['Superman', 'Goku', 'Captain America', 'Boris Johnson'],
                    answer: ['Superman', 'Goku', 'Captain America'],
                    hint: null
                  }
                ]
              }
            },
            state: 'completed',
            stateProps: {
              videoDuration: 400
            }
          }
        ]
      }
    ]
  },
  '2': {
    courseId: 2,
    name: 'Course 2',
    imageUrl: 'https://placekitten.com/360/180',
    description: 'This is _also_ a description',
    stages: [
      {
        courseId: 2,
        stageId: 2,
        name: 'Stage 1',
        sortOrder: 1,
        lessons: [
          {
            lessonId: 2,
            courseId: 2,
            stageId: 2,
            lessonTypeId: 'video',
            name: 'Stage 1 Lesson 1',
            sortOrder: 1,
            options: {
              src: 'https://www.youtube.com/watch?v=mQZY7EmjbMA',
              captionSrc: ''
            },
            state: 'completed',
            stateProps: {
              videoDuration: 400
            }
          }
        ]
      },
      {
        stageId: 3,
        courseId: 2,
        name: 'Stage 2',
        sortOrder: 1,
        lessons: [
          {
            lessonId: 3,
            stageId: 3,
            courseId: 2,
            lessonTypeId: 'video',
            name: 'Stage 2 Lesson 1',
            sortOrder: 1,
            options: {
              src: 'https://fast.wistia.com/medias/crf5xxf2dv',
              captionSrc: ''
            },
            state: 'completed',
            stateProps: {
              videoDuration: 400
            }
          },
          {
            lessonId: 4,
            stageId: 3,
            courseId: 2,
            lessonTypeId: 'video',
            name: 'Stage 2 Lesson 2',
            sortOrder: 1,
            options: {
              src: 'https://fast.wistia.com/medias/crf5xxf2dv',
              captionSrc: ''
            },
            state: 'completed',
            stateProps: {
              videoDuration: 400
            }
          }
        ]
      }
    ]
  }
};
