import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Select from 'components/select';
import Icon from 'components/font-awesome';
import {requestTags, createTag} from 'modules/tags/actions';
import {tagsInputSelector} from 'modules/tags/selectors';
import CircularSpinner from 'components/spinner/circularSpinner';
import styles from './tagsInput.scss';

export class SuperTagsInput extends React.Component {
  componentDidMount() {
    if (!this.props.hasLoaded && !this.props.tagsLoading) {
      this.props.requestTags();
    }
  }

  handleChangeEvent = option => {
    if (!option && this.props.multi) {
      this.props.onChange([]);
    } else if (Array.isArray(option)) {
      // if the name === the label, that is react-select telling us that this option was just created
      let createdTags = option.filter(opt => opt.value === opt.label);
      let existingTags = option.filter(opt => opt.value !== opt.label);
      let newValues = existingTags.map(item => {
        return item.value;
      });

      if (createdTags.length) {
        // we need to create a new tag, and then when they have been created, we need to call onChange with the tagId
        // NOTE can only create 1 tag at a time, but the react-select api passes us everything so we filter
        const newTag = createdTags[0];

        // TODO loading spinner?
        this.props.createTag({label: newTag.label}, createdTag => {
          newValues.push(createdTag.tagId);
          this.props.onChange(newValues);
        });
      } else {
        this.props.onChange(newValues);
      }
    } else {
      this.props.onChange(option.value);
    }
  };

  render() {
    const {onChange, isLoading, hasLoaded, tagOptions, creatingTags, ...props} = this.props;

    // TODO add loading icon to select box instead so selectbox is always visible
    if (isLoading || !hasLoaded) {
      return <CircularSpinner />;
    }

    return (
      <React.Fragment>
        {(!tagOptions || !tagOptions.size) && (
          <p>
            You currently have no tags created. Type into the search box below and hit enter to
            create a tag and assign it to this website
          </p>
        )}
        <div className={styles.superTagInput}>
          {creatingTags && <Icon name="spinner" spin className={styles.icon} />}
          <Select
            multi
            clearable
            allowCreate
            {...props}
            onChange={this.handleChangeEvent}
            options={tagOptions.toJS()}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  tagsInputSelector,
  {requestTags, createTag}
)(SuperTagsInput);
