import React, {useContext} from 'react';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Spinner from 'components/spinner';
import Dropdown from 'components/dropdown';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import styles from './switch.scss';

export default function Switch({children, className, stacked, tabbed, large, dark, ...props}) {
  const {tablet} = useContext(ScreenSizeContext);

  const classNames = cx(styles.switch, className, {
    [styles.stacked]: stacked,
    [styles.tabbed]: tabbed,
    [styles.large]: large,
    [styles.darkSwitch]: dark
  });

  if (!children || !children.length) {
    return null;
  }

  const filters = children[0].props.filter;
  const activeChild = [...children].reduce((returnChild, child) => {
    if (returnChild) return returnChild;
    if (child.props.active) return child;
    if (!child.props.config) return null;

    const {value, filter} = child.props.config;
    return value === filters[filter] ? child : null;
  }, null);

  // only apply the mobile style to tabbed switches
  if (tablet && tabbed && React.Children.count(children) > 2) {
    return SwitchDropdown({...props, tablet, children, activeChild, className: classNames});
  }

  return (
    <div {...filterInvalidDOMProps(props)} className={classNames}>
      {children}
    </div>
  );
}

function SwitchDropdown({className, activeChild, children, tablet, ...props}) {
  const button = activeChild ? (
    <div {...filterInvalidDOMProps(props)} className={className}>
      {React.cloneElement(activeChild, {
        showCaret: tablet,
        config: {...activeChild.props.config, showCaret: tablet}
      })}
    </div>
  ) : (
    <Spinner small />
  );
  return (
    <Dropdown options={[]} button={button}>
      {React.Children.map(children, child =>
        // kinda nasty but alternatives are low
        // children are generally dictated by higher level switch layoutComponent
        React.cloneElement(child, {
          disabled: child.props.disabled,
          config: {...child.props.config, dropdownOption: true}
        })
      )}
    </Dropdown>
  );
}

Switch.defaultProps = {
  theme: 'default'
};
