import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ENTITY_SELECTOR_TYPES} from './constants';
import EntitySelectorListItem from './entitySelectorListItem';
import styles from './entitySelect.scss';
import Button from 'components/button';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import cx from 'classnames';

const propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  type: PropTypes.oneOf(ENTITY_SELECTOR_TYPES).isRequired,
  onClick: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  multiSelectActive: PropTypes.bool.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleOrderChange: PropTypes.func
};

const renderList = (entity, props, index) => {
  const {
    type,
    onClick,
    multiSelectActive,
    onCheck,
    checked,
    selected,
    buttonText,
    handleOrderChange,
    searchTerm,
    ...rest
  } = props;

  const dragEnabled = handleOrderChange && searchTerm.length === 0 && checked.length === 0;

  const renderItem = isDragging => (
    <EntitySelectorListItem
      index={index}
      key={entity.value}
      onClick={multiSelectActive ? onCheck : onClick}
      buttonDisabled={multiSelectActive}
      selected={selected}
      buttonText={buttonText}
      isDragging={isDragging}
      dragEnabled={dragEnabled}
      {...rest}
      {...entity}
    >
      {multiSelectActive && (
        <div className={styles.checkboxOption}>
          <input
            type="checkbox"
            checked={checked.includes(entity.value)}
            onChange={() => onCheck(entity.value)}
            className={styles.regularCheckbox}
          />
          <label></label>
        </div>
      )}
    </EntitySelectorListItem>
  );

  switch (type) {
    // the idea being we can render different types of child component
    case 'list':
      if (dragEnabled) {
        return (
          <Draggable key={entity.value} draggableId={entity.value} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {renderItem(snapshot.isDragging)}
              </div>
            )}
          </Draggable>
        );
      }
      return renderItem();
    default:
      return null;
  }
};

class EntitySelectorList extends Component {
  static propTypes = propTypes;

  static defaultProps = {
    handleOrderChange: null
  };

  render() {
    const {props} = this;

    const classNames = {
      [styles.dark]: props.dark
    };

    return (
      <div className={cx(styles.entitySelectorList, classNames)}>
        {props.searchTerm.length > 0 && (
          <div className={styles.filterControls}>
            <span>
              <b>Searching for:</b> {props.searchTerm}
            </span>
            <Button className={styles.clearSearch} onClick={props.handleClearSearch}>
              Clear search
            </Button>
          </div>
        )}
        <DragDropContext onDragEnd={props.handleOrderChange}>
          <Droppable droppableId="droppable" disabled={true}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.entities &&
                  props.entities.map((entity, index) => renderList(entity, props, index))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default EntitySelectorList;
