import {call, put, takeEvery} from 'redux-saga/effects';
import {receiveReport} from 'modules/reports';
import {
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE
} from 'modules/reports/reportConstants';
import {reportEndpoint} from 'modules/reports/reportUtils';
import PUT from 'utils/saga/put';
import {processResponse} from 'utils/saga/fetchUtils';
import {showSuccess} from 'utils/saga/alerts';

export default function* updateReportSaga() {
  yield takeEvery(UPDATE_REPORT, updateReport);
}

function* updateReport({reportId, data, successMessage}) {
  const res = yield call(PUT, reportEndpoint({reportId}), data);
  yield processResponse(res, {
    successAction: receiveReport,
    // TODO: we shouldn't need the below actions
    // - refactor reducer.
    success: function*() {
      if (successMessage) {
        yield showSuccess(successMessage);
      }
      yield put({type: UPDATE_REPORT_SUCCESS});
    },
    error: function*() {
      yield put({type: UPDATE_REPORT_FAILURE});
    }
  });
}
