import {testPath} from 'modules/tests/testUtils';

export function policyPath({policyId}) {
  return testPath(...arguments) + `/policies/${policyId}`;
}

export function makeDefaultData(options, data) {
  const defaultData = options.reduce((result, option) => {
    if ('default' in option) {
      result[option.name] = option.default;
    } else if ('enum' in option) {
      result[option.name] = option.enum[0];
    } else if (option.type === 'repeated' && option.children.length) {
      const min = Number.isInteger(option.min) ? option.min : 1;
      const defaultArray = data[option.name] || Array(min).fill({});
      result[option.name] = [];

      defaultArray.forEach((item, idx) => {
        result[option.name][idx] = makeDefaultData(option.children, item);
      });
    } else {
      if (option.type === 'bool') {
        result[option.name] = false;
      } else {
        result[option.name] = '';
      }
    }
    return result;
  }, {});

  return {...defaultData, ...data};
}
