import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {plainUsersSelector} from 'modules/users/userSelectors';

const groupsStateSelector = state => state.groups;

export const groupsSelector = createSelector(
  groupsStateSelector,
  state => ({
    groups: toJS(state.get('groups').toList())
  })
);

export const usersAndGroupsSelector = createSelector(
  plainUsersSelector,
  groupsSelector,
  ({users}, {groups}) => {
    return {
      // adds a standard id to each.
      // userId e.g. 12
      // groupId e.g. g13
      usersAndGroups: [...users, ...groups].map(obj => ({
        ...obj,
        //stringify
        id: '' + (obj.userId || 'g' + obj.groupId),
        isUser: !!obj.userId,
        isGroup: !!obj.groupId
      }))
    };
  }
);

// attaches user or group data to each member in props[prop].
// props[prop] is an array of user and group ids
export const attachUserAndGroup = prop =>
  createSelector(
    usersAndGroupsSelector,
    (state, props) => props[prop],
    ({usersAndGroups}, ids) => {
      const results = ids.map(id => findUserOrGroup(usersAndGroups, id));
      return {
        [prop]: results
      };
    }
  );

function findUserOrGroup(usersAndGroups, id) {
  return toJS(usersAndGroups.find(u => u.id == id.toString()));
}
