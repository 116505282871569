import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './progress.scss';
import cx from 'classnames';
import Tooltip from 'components/tooltip';

export default class Progress extends React.Component {
  static defaultProps = {
    appearance: 'major', // set to falsey for appearance based on score
    denominator: 100,
    minimumWidth: 0,
    color: '',
    width: null, // Used if the progress is inline, otherwise will be 100% (using css)
    label: null // Either "left" or "right" to show percentage beside bar
  };

  static propTypes = {
    value: PropTypes.number.isRequired,
    denominator: PropTypes.number,
    appearance: PropTypes.string,
    minimumWidth: PropTypes.number,
    color: PropTypes.string,
    width: PropTypes.any
  };

  getPercent() {
    const {value, minimumWidth} = this.props;
    let {denominator} = this.props;

    if (!denominator || value < 0) {
      return null;
    }

    let percent = (value / denominator) * 100;

    // If our percentage is not 100%, but we'd round up to 100, force down to 99.9%.
    // Otherwise we give the user the wrong impression that we're "done".
    if (percent < 100 && _.round(percent, 1) >= 100) {
      percent = 99.9;
    }

    return Math.max(minimumWidth, _.round(percent, 1));
  }

  getAppearance(percent) {
    const {appearance} = this.props;

    if (appearance) {
      return appearance;
    }

    if (percent <= 33) return 'bad';
    if (percent <= 66) return 'warning';

    return 'good';
  }

  getLabel() {
    const {displayAsPercentage, denominator, value, tooltip} = this.props;

    if (tooltip) {
      return tooltip;
    }

    if (displayAsPercentage) {
      return this.getPercent() + '%';
    }

    return `${value} / ${denominator}`;
  }

  render() {
    const {
      children,
      className,
      striped,
      small,
      animate,
      color,
      style = {},
      width,
      label
    } = this.props;
    const percent = this.getPercent();
    const classes = cx(styles.bar, styles[this.getAppearance(percent)], {
      [styles.striped]: striped,
      [styles.animate]: animate
    });

    const barStyles = {width: _.isNull(percent) ? 0 : percent + '%'};
    if (color) {
      barStyles.backgroundColor = color;
    }

    if (width != null) {
      style.width = width;
    }

    const progressBar = (
      <Tooltip text={this.getLabel()}>
        <div
          className={cx(styles.progress, className, {
            [styles.small]: small
          })}
          style={style}
        >
          <div className={classes} style={barStyles} />
          <div className={styles.innerContent}>{children}</div>
        </div>
      </Tooltip>
    );

    if (label === 'left') {
      return (
        <div className={styles.leftLabel}>
          {_.isNull(percent) ? <span>?</span> : <span>{percent}%</span>}
          {progressBar}
        </div>
      );
    }
    if (label === 'right') {
      return (
        <div className={styles.rightLabel}>
          {progressBar}
          {_.isNull(percent) ? <span>?</span> : <span>{percent}%</span>}
        </div>
      );
    }

    return progressBar;
  }
}
