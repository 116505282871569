import React from 'react';
import styles from './circularSpinner.scss';
import cx from 'classnames';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export class CircularSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: !props.delay};
    this.timeout = null;
  }

  componentDidMount() {
    const {delay} = this.props;

    if (delay) {
      this.timeout = setTimeout(() => this.setState({show: true}), delay);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const {className, marginTop, marginBottom, marginLeft, style, ...props} = this.props;

    if (!this.state.show) {
      return null;
    }

    const classes = cx('spin', styles.csSpinner, className);

    return (
      <span
        {...filterInvalidDOMProps(props)}
        style={{...style, marginTop, marginBottom, marginLeft}}
        className={classes}
      />
    );
  }
}

export default localStyles(styles)(CircularSpinner);
