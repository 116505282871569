import {select, take} from 'redux-saga/effects';

/**
 * Selects from state, if empty, waits for action then tries again
 * recursively until selector returns something.
 *
 * `maxAttempts` allows you to specify the maximum number of times to
 * wait for the action before giving up and returning undefined.
 */

// TODO: what if API error?
export default function* selectOrWait(
  selector,
  actionToWaitFor,
  selectorProps = {},
  maxAttempts,
  attempts = 0
) {
  const selection = yield select(selector, selectorProps);

  if (selection) {
    if (!selection.isEmpty || (selection.isEmpty && !selection.isEmpty())) {
      return selection;
    }
  }

  // If we've waited the max number of attempts then just return
  if (maxAttempts && attempts >= maxAttempts) return;

  // wait for action
  yield take(actionToWaitFor);

  // start again
  return yield selectOrWait(selector, actionToWaitFor, selectorProps, maxAttempts, ++attempts);
}
