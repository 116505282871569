import {exportReportComplete} from 'modules/reports';
import {EXPORT_REPORT} from 'modules/reports/reportConstants';
import {exportReportEndpoint} from 'modules/reports/reportUtils';
import {call, put, take, select, takeEvery} from 'redux-saga/effects';
import {push} from 'modules/location';
import {processRequest} from 'utils/saga/fetchUtils';
import {showError} from 'utils/saga/alerts';
import {currentFilterSelector} from 'modules/filters/filterSelectors';

export default function* exportReportSaga() {
  yield takeEvery(EXPORT_REPORT, exportReport);
}

export function* exportReport({type, reportId, filters, ...exportData}) {
  const url = exportReportEndpoint({reportId});

  const currentFilters = yield select(currentFilterSelector);
  const postData = {...exportData, filters: {...currentFilters, ...filters}};

  return yield processRequest(
    'POST',
    url,
    {
      // the exporter times out at 120 seconds, so we'll add an extra 10 for the request
      timeout: 120000 + 10000,

      success: function*({exportUrl}) {
        yield put(exportReportComplete({exportUrl}));
      },

      error: function*({error, errorMessage = 'An error occurred when exporting this test'}) {
        yield put(exportReportComplete());
        yield call(showError, errorMessage);
      }
    },
    postData
  );
}
