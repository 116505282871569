import React from 'react';
import Frame from 'components/frame';
import cx from 'classnames';
import Prism from 'prismjs';
import {API} from 'config';
import styles from './code.scss';

export default class Code extends React.Component {
  static codeHead = (
    <React.Fragment>
      <link rel="stylesheet" type="text/css" href={API.ROOT + 'css/inspector-source.css'} />
      <link rel="stylesheet" type="text/css" href={API.ROOT + 'css/prism.css'} />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          body {
            padding: 0;
            margin: 0
          }

          pre {
            margin: 0 !important;
            padding: 20px !important;
          }
        `
        }}
      />
    </React.Fragment>
  );

  state = {err: null};

  componentDidCatch(err) {
    this.setState({err});
  }

  setBodyRef = ref => {
    if (ref) {
      Prism.highlightAllUnder(ref);
    }
  };

  render() {
    const {children, language, isOpen} = this.props;

    if (this.state.err) return <Error {...this.state.error} />;

    // Special case: plain text highlighting (e.g. for cookie names, identifiers)
    if (language === 'text') {
      return <code>{children}</code>;
    }

    if (!Prism.languages[language]) {
      throw new Error('Unsupported language "' + language + '"');
    }

    return (
      <Frame isOpen={isOpen} head={this.constructor.codeHead} bodyRef={this.setBodyRef}>
        <pre className={cx(styles.code, styles.noMargin, 'language-' + language)}>
          <code className={'language-' + language}>{children}</code>
        </pre>
      </Frame>
    );
  }
}

function Error(props) {
  return <p>{JSON.stringify(props)}</p>;
}
