import React from 'react';
import {connect} from 'react-redux';
import {notificationsSelector} from 'modules/notifications/notificationSelectors';
import NotificationListItem from './notificationListItem';
import styles from './notificationsList.scss';

function NotificationsList({notifications}) {
  return (
    <div className={styles.notificationsList}>
      {notifications.map(n => (
        <NotificationListItem notification={n} key={n.notificationId} />
      ))}
    </div>
  );
}

export default connect(notificationsSelector)(NotificationsList);
