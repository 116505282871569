import React from 'react';
import FieldsComponent from 'components/fields';

class Fields extends React.Component {
  render() {
    const {data, max = 2} = this.props;
    if (!data) return null;

    return <FieldsComponent fields={data} max={max} />;
  }
}

export default Fields;
