import {saveDashboardComplete} from 'modules/dashboards/actions';
import {SAVE_DASHBOARD} from 'modules/dashboards/constants';
import {dashboardEndpoint} from 'modules/dashboards/utils';
import {call, put, select, take, takeLatest} from 'redux-saga/effects';
import {couldNot} from 'utils/errorUtils';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* saveDashboardListener() {
  yield takeLatest(SAVE_DASHBOARD, saveDashboardSaga);
}

function* saveDashboardSaga({dashboard}) {
  const url = dashboardEndpoint({dashboardId: dashboard.getIn(['dashboard', 'dashboardId'])});

  yield processRequest(
    'PUT',
    url,
    {
      *success(data) {
        yield put(saveDashboardComplete(null, data));
      },
      *error(err) {
        yield put(saveDashboardComplete(err));
      }
    },
    dashboard // immutable can be JSON stringified
  );
}
