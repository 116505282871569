import {put, take, takeEvery} from 'redux-saga/effects';
import {showInModal, HIDE_MODAL} from 'modules/modal';
import {
  TRIGGER_EXPORT_EXPLORER,
  EXPORT_REPORT,
  EXPORT_TYPES
} from 'modules/reports/reportConstants';
import {displayExportingFeedback} from './helpers/displayExportingFeedback';
import ExportExplorerModal from 'modules/reports/components/modals/exportExplorerModal';

const titles = {
  [EXPORT_TYPES.CSV]: 'Download CSV',
  [EXPORT_TYPES.EXCEL]: 'Download Excel Spreadsheet'
};

export default function* triggerExportExplorerSaga() {
  yield takeEvery(TRIGGER_EXPORT_EXPLORER, triggerExportExplorer);
}

function* triggerExportExplorer({reportId, testId, table, filters}) {
  yield put(
    showInModal(ExportExplorerModal, {
      name: 'exportReportModal',
      reportId,
      testId,
      table,
      filters
    })
  );

  const {type, exportType} = yield take([EXPORT_REPORT, HIDE_MODAL]);

  if (type == HIDE_MODAL) {
    return;
  }

  yield displayExportingFeedback({
    title: 'Exporting',
    successMessage: 'The table finished exporting',
    submitLabel: titles[exportType]
  });
}
