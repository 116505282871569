import React from 'react';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import {TypeField} from 'modules/form/hooks/useForm';
import {getInputType} from 'modules/form/components/schemaForm';
import DeleteIcon from 'components/icons/deleteIcon';
import filterFieldRules from 'modules/form/rules/filter';
import styles from 'modules/policies/containers/policyRule.scss';

export default function SchemaFields({
  field,
  supportedOptions,
  onAddRepeatingField,
  autoFocus,
  renderField
}) {
  const isInline = areFieldsInline(supportedOptions);

  return (
    <Flex
      container
      align="flex-start"
      basis="240px"
      row={isInline}
      column={!isInline}
      margin={isInline ? '0 -10px 10px -10px' : '0 0 10px 0'}
      grow={1}
      wrap
    >
      {supportedOptions.map((fieldSchema, i) => {
        const flex = fieldSchema.get('flex');
        const name = fieldSchema.get('name');
        const subfield = field.fields[name];

        if (!subfield) {
          console.warn(
            'Unexpected. The schema specifies a field to be rendered (' +
              fieldSchema.get('name') +
              ") but the field name doesn't exist in the fields (" +
              Object.keys(field.fields) +
              ')'
          );
          return null;
        }

        return (
          <FlexibleField key={subfield.key} flex={flex} isInline={isInline}>
            {fieldSchema.get('type') === 'repeated' ? (
              <RepeatingField
                autoFocus={autoFocus && i === 0}
                field={subfield}
                fieldSchema={fieldSchema}
                onAddRepeatingField={onAddRepeatingField}
              />
            ) : renderField ? (
              renderField(subfield, fieldSchema)
            ) : (
              <SchemaField autoFocus={autoFocus && i === 0} field={subfield} schema={fieldSchema} />
            )}
          </FlexibleField>
        );
      })}
    </Flex>
  );
}

export function SchemaField({field, schema, ...props}) {
  const fieldProps = {};
  let [rules] = filterFieldRules(schema.toJS());

  if (schema.has('enum')) {
    const enumOptions = schema.get('enum');
    const enumLabels = schema.get('enumLabels');
    const fieldOptions = enumOptions.map((value, i) => ({value, label: enumLabels.get(i)}));

    fieldProps.options = fieldOptions.toJS();
  }

  if (schema.get('type') === 'bool') {
    fieldProps.description = schema.get('description');
  }

  if (rules.min === 1) {
    rules.required = true;
    delete rules.min;
  }

  if (schema.get('disabled')) {
    fieldProps.disabled = true;
    // remove all rules if the field is locked. do not allow it to fail, because the use CANNOT edit it.
    fieldProps.show = false;
    rules = {};
  }

  return (
    <TypeField
      field={field}
      label={schema.get('label')}
      type={getInputType(schema.toJS())}
      {...props}
      {...fieldProps}
      {...rules}
    />
  );
}

function RepeatingField({field, fieldSchema, onAddRepeatingField, autoFocus}) {
  return (
    <React.Fragment>
      {field.fields.map((subfield, i) => {
        return (
          <Flex key={subfield.key} container align="flex-start" wrap>
            <Flex basis="160px">
              <label className={styles.label}>
                {i === 0 ? fieldSchema.get('label') : fieldSchema.get('operator')}
              </label>
            </Flex>

            <SchemaFields
              autoFocus={autoFocus && i === 0}
              field={subfield}
              supportedOptions={fieldSchema.get('children')}
            />

            {field.fields.length > fieldSchema.get('min', 1) && (
              <Flex basis="50px" style={{textAlign: 'center'}}>
                <DeleteIcon
                  size="lg"
                  onClick={() => {
                    field.removeIndex(i);
                    field.rerender();
                  }}
                />
              </Flex>
            )}
          </Flex>
        );
      })}

      <AddRepeatingField
        field={field}
        fieldSchema={fieldSchema}
        onAddRepeatingField={onAddRepeatingField}
      />
    </React.Fragment>
  );
}

function AddRepeatingField({field, fieldSchema, onAddRepeatingField}) {
  const fieldCount = field.getFields().length;
  const show = fieldSchema.get('max') == null || fieldCount < fieldSchema.get('max');
  if (!show) return null;

  return (
    <Flex container align="flex-start" margin="10px 0">
      <Flex basis="160px"></Flex>
      <Flex grow={1}>
        <Button
          icon="plus"
          onClick={() => {
            onAddRepeatingField(fieldSchema, field);
          }}
        >
          {fieldSchema.get('addLabel') || 'Add'}
        </Button>
      </Flex>
    </Flex>
  );
}

function FlexibleField({children, flex, isInline}) {
  const fullWidthStyle = {width: '100%'};
  const [grow, shrink, basis] = (flex || '').trim().split(/\s+/, 3);
  return (
    <Flex
      grow={grow}
      basis={basis}
      shrink={shrink}
      margin={isInline ? '0 10px 10px 10px' : '0 0 10px 0'}
      style={!flex ? fullWidthStyle : undefined}
      wrap
    >
      {children}
    </Flex>
  );
}

function areFieldsInline(children) {
  if (children && children.toJS) {
    return !!children.filter(opt => opt.get('flex')).size;
  } else {
    return !!children.filter(opt => opt.flex).length;
  }
}
