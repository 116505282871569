import React from 'react';
import UrlComponent from 'components/url/url';
import RelativeDate from 'components/time/relativeDate';
import styles from './styles.scss';
import pageNameStyles from './pageName.scss';
import Tooltip from 'components/tooltip';
import ControlLink from 'components/control/controlLink';
import {withRouter} from 'react-router';

class PageName extends React.Component {
  renderSubType(subType) {
    if (!subType) return null;

    const label = 'Special test for 404 pages';

    return (
      <Tooltip text="This made-up web address was tested to see how the website handles 'Page Not Found' errors.">
        <span className={pageNameStyles.label}>{label}</span>
      </Tooltip>
    );
  }

  render() {
    const {
      record,
      data,
      options,
      filters,
      match: {params}
    } = this.props;
    const {lastChecked} = record;

    const url = (data && data.url) || record.url;
    const pageName = (data && data.pageName) || record.pageName;
    const subType = (data && data.subType) || record.subType;

    const relativeDate = lastChecked ? <RelativeDate timestamp={lastChecked} /> : null;

    let decodedURL;

    try {
      // page name can be a url
      decodedURL = decodeURIComponent(pageName);
    } catch (err) {
      decodedURL = pageName;
    }

    let pageNameComponent = <b>{decodedURL}</b>;

    if (options.instruction) {
      pageNameComponent = (
        // we spread params cos we need testId and record doesn't have it
        // the controls renderer does the same which is why it works automatically with the inspector button
        <ControlLink
          instruction={options.instruction}
          context={{...params, ...record}}
          filters={filters}
        >
          {pageNameComponent}
        </ControlLink>
      );
    }

    return (
      <div className={styles.pageName}>
        {pageName ? (
          <p>
            {pageNameComponent}
            {this.renderSubType(subType)}
          </p>
        ) : null}
        <p>
          <UrlComponent url={url} className={pageNameStyles.url} stopPropagation link />
        </p>
      </div>
    );
  }
}

PageName.propTypes = {};

export default withRouter(PageName);
