import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/font-awesome';
import Table from 'components/table';
import {revolveIndex} from 'utils/numberUtils';
import styles from './table.scss';
import _ from 'lodash';

// these are the Icon names for the sortable states
const headerIcons = {
  desc: 'sort-desc',
  asc: 'sort-asc',
  both: ''
};

export default class SortableTable extends React.Component {
  state = {
    sortBy: '',
    sortOrder: 'asc'
  };

  handleClickColumn = ({field}, e) => {
    const {sortBy, sortOrder} = this.state;

    // if field changes, reset sort order
    if (sortBy != field) {
      this.setState({sortBy: field, sortOrder: 'desc'});
      return;
    }

    // we need to revolve the sort order through the keys of `headerIcons`
    const keys = Object.keys(headerIcons);
    const nextIdx = revolveIndex(keys.indexOf(sortOrder), keys.length, +1);

    this.setState({
      sortBy: field,
      sortOrder: keys[nextIdx]
    });
  };

  getHeaderWithIcon(label) {
    const icon = headerIcons[this.state.sortOrder];

    return (
      <div>
        {label}
        <Icon className={styles.sortIcon} name={icon} />
      </div>
    );
  }

  renderColumn = column => {
    const {sortBy} = this.state;
    const {field, label} = column;

    if (sortBy && field == sortBy) {
      return {
        ...column,
        label: this.getHeaderWithIcon(label)
      };
    }

    return column;
  };

  render() {
    let {rows, columns} = this.props;
    const {sortBy, sortOrder} = this.state;

    if (sortBy) {
      rows = _.orderBy(rows, [sortBy], [sortOrder]);

      columns = columns.map(this.renderColumn);
    }

    return (
      <Table
        {...this.props}
        currentSort={sortBy ? sortBy + ':' + sortOrder : undefined}
        rows={rows}
        columns={columns}
        onClickColumn={this.handleClickColumn}
      />
    );
  }
}
