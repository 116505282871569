import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import {HIDE_MODAL} from 'modules/modal';
import {REQUEST_ADD_FOLDER, SAVE_FOLDER, receiveFolders, saveFolderError} from 'modules/folders';
import {selectFolder} from 'modules/folders/folderSelectors';
import FolderModal from 'modules/folders/components/folderModal';
import {foldersEndpoint} from 'modules/folders/folderUtils';
import {showInModal} from 'modules/modal';
import POST from 'utils/saga/post';
import {processResponse} from 'utils/saga/fetchUtils';

export default function* addFoldersSaga() {
  yield takeLatest([REQUEST_ADD_FOLDER], addFolder);
}

function* addFolder(action) {
  const {parentId} = action;
  let parentFolder = null;

  if (parentId) {
    const folder = yield select(selectFolder(parentId));

    parentFolder = folder && folder.toJS();
  }

  yield put(showInModal(FolderModal, {name: 'addFolderModal', parentFolder}));

  const saveAction = yield take([SAVE_FOLDER, HIDE_MODAL]);

  if (saveAction.type === HIDE_MODAL) {
    return;
  }

  const data = {parentId, ...saveAction.folder};
  const res = yield call(POST, foldersEndpoint(), data);

  yield processResponse(res, {
    successAction: receiveFolders,
    errorAction: saveFolderError
  });
}
