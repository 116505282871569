import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {openHelp} from 'modules/help/actions';
import {doCommand} from 'modules/instructions';
import TopicHeader from './components/topicHeader';
import TopicItems from './components/topicItems';
import TopicShowMore from './components/topicShowMore';
import styles from './topic.scss';

export class Topic extends React.Component {
  state = {
    showFew: true
  };

  toggleShowMore = () => {
    this.setState(state => ({showFew: !state.showFew}));
  };

  render() {
    const {items, borderless, indented, ...other} = this.props;
    const {showFew} = this.state;

    let itemsToShow = items;
    if (showFew) {
      itemsToShow = items.slice(0, 3);
    }

    const showShowMoreButton = items.length > 3;
    const className = cx(styles.wrapper, styles.indented, {
      [styles.borderless]: borderless ? true : false
    });

    return (
      <div className={className}>
        <TopicHeader {...other} />
        <TopicItems items={itemsToShow} />
        {showShowMoreButton && (
          <TopicShowMore onClick={this.toggleShowMore} showingMore={!showFew} />
        )}
      </div>
    );
  }
}

Topic.handlesLoading = true;

export default connect(
  null,
  {doCommand, openHelp}
)(Topic);
