import {takeLatest, put} from 'redux-saga/effects';
import {SEND_CONTACT_US_EMAIL} from 'modules/app/app';
import {processRequest} from 'utils/saga/fetchUtils';
import {contactUsEmailEndpoint} from 'modules/app/appUtils';

export default function* sendContactUsEmailSaga() {
  yield takeLatest([SEND_CONTACT_US_EMAIL], function*({contactType, formData, successCallback, failCallback}) {
    yield processRequest('POST', contactUsEmailEndpoint(), {
      success: function*(response) {
        successCallback(response);
      },
      error: function*(err) {
        failCallback(err);
      }
    }, {
      contactType,
      formData
    });
  });
}