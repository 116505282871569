import React from 'react';
import _ from 'lodash';
import {trackEvent} from 'modules/tracking';
import {Form, Field} from 'modules/form/components';
import SettingsPage from 'components/settings/settingsPage';
import SettingItem from 'components/settings/settingItem';
import Button from 'components/button';
import {getProps} from 'utils/immutableUtils';
import CopyToClipboard from 'components/copyToClipboard';
import styles from 'components/copyToClipboard/copyToClipboard.scss';
import {isSupportedCMS, getCMSDownloadLink, getCMSHelpGuideLink} from 'utils/cmsUtils';
import ExternalLink from 'modules/location/externalLink';

// list of all properties we need from websiteConfig
const CONFIG_FIELDS = ['cms'];

export default class CMSConfig extends React.Component {
  handleValidSubmit = value => {
    let data = this.getData();

    if (data.cms === value) {
      return;
    }

    data.cms = value;

    const {
      match: {
        params: {reportId}
      }
    } = this.props;

    trackEvent('Updated cms config', {
      fromFile: 'cmsConfig.js',
      forReport: reportId
    });

    this.props.updateWebsite(data);
  };

  getData() {
    const {websiteConfig} = this.props;
    if (!websiteConfig) {
      // Don't return data until everything is loaded
      return null;
    }
    return getProps(websiteConfig, CONFIG_FIELDS);
  }

  render() {
    const {
      name,
      match: {params},
      cmsConfig,
      websiteConfig
    } = this.props;

    const data = this.getData();

    if (!data || !cmsConfig) {
      return null;
    }

    const installationGuideLink = 'https://monitor.support.silktide.com/guides/cms-integrations/';

    return (
      <SettingsPage name="CMS Integration" params={params}>
        <Form name={name} defaultValue={data} onValidSubmit={this.handleValidSubmit}>
          <div style={{maxWidth: '800px'}}>
            <h3>How it works</h3>
            <p>Silktide can integrate with a range of supported Content Management Systems.</p>
            <p>
              Once integrated, publishing an update in your CMS will tell Silktide to immediately
              re-test any affected pages. If you are viewing a page in Silktide you can also click a
              button to immediately edit that page in your CMS.
            </p>
            <p>
              <b>
                <ExternalLink href="https://monitor.support.silktide.com/guides/cms-integrations/">
                  Learn more about CMS integrations
                </ExternalLink>
              </b>
            </p>

            <hr />

            <h3>Website API key</h3>
            <p>To integrate your CMS with this specific website, use the following key.</p>

            <br />

            <SettingItem title="Website API Key">
              <CopyToClipboard value={cmsConfig.reportApiKey} className={styles.lighterBg} />
            </SettingItem>

            {cmsConfig.accountApiKey && (
              <div>
                <hr />
                <h3>Account API key</h3>
                <p>To integrate your CMS with multiple websites, use the following key.</p>

                <br />

                <SettingItem title="Account API Key">
                  <CopyToClipboard value={cmsConfig.accountApiKey} className={styles.lighterBg} />
                </SettingItem>
              </div>
            )}
          </div>
        </Form>
      </SettingsPage>
    );
  }
}
