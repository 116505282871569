exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TiJ8lpvIPzEpnryX8q7Sd{padding:10px;font-size:13px !important}._1q_1WJejzKgXrk5BNZpqEJ{margin:0 !important}", ""]);

// exports
exports.locals = {
	"code": "_1TiJ8lpvIPzEpnryX8q7Sd",
	"noMargin": "_1q_1WJejzKgXrk5BNZpqEJ"
};