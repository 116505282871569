import {put, takeLatest} from 'redux-saga/effects';
import {SEND_UNLOCK_EMAIL, sendUnlockEmailComplete} from 'modules/passwords';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {addMessage} from 'modules/alerts';
import {unlockUserEndpoint} from 'modules/users/userUtils';

export default function* sendUnlockEmailSaga() {
  yield takeLatest(SEND_UNLOCK_EMAIL, sendUnlockEmail);
}

function* sendUnlockEmail({userId, email}) {
  yield processRequest(
    'POST',
    unlockUserEndpoint(),
    {
      success: function*() {
        yield put(sendUnlockEmailComplete(true));
        yield put(addMessage('success', 'Email sent'));
      },
      error: function*() {
        yield put(sendUnlockEmailComplete(false));
        yield put(addMessage('error', couldNot('send email')));
      }
    },
    {userId, email}
  );
}
