import {createSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';
import {
  DEFAULT_SCHEDULE,
  BRANDING_LOGO_URL,
  FOLDER_VIEWS_ENABLED,
  ACADEMY_SCORES_OWNER_ONLY
} from 'modules/accountOptions/accountOptionConstants';

const accountOptionsStateSelector = (state, props) => state.accountOptions;

export const accountOptionsSelector = createSelector(
  accountOptionsStateSelector,
  accountOptionsState => {
    return accountOptionsState.get('data');
  }
);

// this creates selectors for specific account options
const createAccountOptionSelector = optionName =>
  createSelector(
    accountOptionsSelector,
    accountOptions => {
      if (!accountOptions) {
        return undefined;
      }

      const accountOption = accountOptions.get(optionName);

      if (!accountOption || !accountOption.get('public')) {
        return undefined;
      }

      return accountOption.get('value');
    }
  );

export const accountDefaultScheduleSelector = createAccountOptionSelector(DEFAULT_SCHEDULE);

export const accountViewsEnabledSelector = createAccountOptionSelector(FOLDER_VIEWS_ENABLED);

const accountBrandingLogoSelector = createAccountOptionSelector(BRANDING_LOGO_URL);

export const academyScoresOwnerOnlySelector = createAccountOptionSelector(
  ACADEMY_SCORES_OWNER_ONLY
);

const accountBrandingLogoLoadingSelector = createSelector(
  accountOptionsStateSelector,
  accountState => {
    return (
      accountState.get('isFetchingOptions') ||
      accountState.get('isUploadingImage') ||
      accountState.get('isDeletingImage')
    );
  }
);

export const accountBrandingLogoUploaderSelector = createSelector(
  accountBrandingLogoSelector,
  accountBrandingLogoLoadingSelector,
  (brandingLogoUrl, loading) => ({brandingLogoUrl, loading})
);
