import {List} from 'immutable';
import {accountIdSelector, featuresSelector} from 'modules/auth/authSelectors';
import {dashboardSelector} from 'modules/dashboards/selectors';
import {queryParamsSelector} from 'modules/location/locationSelectors';
import {reportsOptionsSelector} from 'modules/reports/reportSelectors';
import {statOptionsSelector} from 'modules/stats/statSelectors';
import {createSelector} from 'reselect';

export const leaderboardDomainSelector = state => state.leaderboards;

export const leaderboardIdPropSelector = (_, props) => {
  return props.leaderboardId == null ? null : props.leaderboardId.toString();
};

export const leaderboardsSelector = createSelector(
  leaderboardDomainSelector,
  leaderboardState => leaderboardState.get('leaderboards')
);

export const leaderboardReportsSelector = createSelector(
  leaderboardDomainSelector,
  leaderboardState => leaderboardState.get('results')
);

export const maxReportsSelector = createSelector(
  leaderboardDomainSelector,
  leaderboardState => leaderboardState.get('maxReports')
);

export const leaderboardDefaultColumnsSelector = createSelector(
  leaderboardDomainSelector,
  leaderboardState => leaderboardState.get('defaultColumns')
);

export const leaderboardSelector = createSelector(
  leaderboardsSelector,
  leaderboardIdPropSelector,
  (leaderboards, leaderboardId) => {
    return leaderboardId == null ? undefined : leaderboards.get(leaderboardId);
  }
);

export const isResultsReadySelector = createSelector(
  leaderboardDomainSelector,
  leaderboardIdPropSelector,
  (leaderboardState, leaderboardId) => {
    return (
      leaderboardState.get('resultsFor') === leaderboardId &&
      !leaderboardState.getIn(['fetching', 'leaderboardResults'])
    );
  }
);

export const isLeaderboardsPageReadySelector = createSelector(
  leaderboardsSelector,
  leaderboardDomainSelector,
  (leaderboards, state) => {
    const isLeaderboardsReady = !state.getIn(['fetching', 'leaderboards']);

    return isLeaderboardsReady && !!leaderboards;
  }
);

export const isLeaderboardPageReadySelector = createSelector(
  leaderboardSelector,
  leaderboardReportsSelector,
  leaderboardDomainSelector,
  (leaderboard, results, state) => {
    const isLeaderboardReady = !state.getIn(['fetching', 'leaderboard']);
    const isLeaderboardResultsReady = !state.getIn(['fetching', 'leaderboardResults']);

    const reports = results ? results.get('reports').toList() : null;
    const columns = results ? results.get('columns') : null;

    return !!(isLeaderboardReady && isLeaderboardResultsReady && leaderboard && reports && columns); // ensure we return a bool
  }
);

export const leaderboardRouteSelector = createSelector(
  leaderboardDomainSelector,
  state => ({
    // we should request the leaderboards if they are not currently being fetched and that have not already been laoded
    shouldRequestLeaderboards:
      (!state.get('leaderboards') || state.get('leaderboards').isEmpty()) &&
      !state.getIn(['fetching', 'leaderboards'])
  })
);

export const leaderboardBreadcrumbSelector = createSelector(
  leaderboardsSelector,
  leaderboards => ({
    leaderboardOptions: leaderboards
      .toList()
      .map(lb => ({
        value: lb.get('leaderboardId'),
        label: lb.get('name')
      }))
      .sortBy(option => option.value)
  })
);

export const isExportingSelector = createSelector(
  leaderboardDomainSelector,
  leaderboardState => leaderboardState.getIn(['fetching', 'leaderboardExport'])
);

export const leaderboardPageSelector = createSelector(
  leaderboardSelector,
  leaderboardReportsSelector,
  isLeaderboardPageReadySelector,
  maxReportsSelector,
  isExportingSelector,
  featuresSelector,
  accountIdSelector,
  (leaderboard, results, isReady, maxReports, isExporting, {features}, accountId) => {
    const reports = results ? results.get('reports').toList() : null;
    const columns = results ? results.get('columns') : null;

    return {
      reports,
      columns,
      leaderboard,
      maxReports,
      isReady,
      isExporting,
      canViewLeaderboardWidgets: features.get('LeaderboardWidgetsFeatureFlag'),
      accountId
    };
  }
);

export const leaderboardsPageSelector = createSelector(
  accountIdSelector,
  leaderboardsSelector,
  isLeaderboardsPageReadySelector,
  (accountId, leaderboards, isReady) => ({
    accountId,
    isReady,
    leaderboards: leaderboards.toList()
  })
);

export const editLeaderboardFormSelector = createSelector(
  reportsOptionsSelector,
  statOptionsSelector,
  leaderboardSelector,
  leaderboardReportsSelector,
  leaderboardDefaultColumnsSelector,
  dashboardSelector,
  featuresSelector,
  (reports, stats, leaderboard, leaderboardResults, defaultColumns, dashboard, {features}) => {
    return {
      leaderboard,
      dashboard,
      stats: stats.filter(stat => stat.value !== 'report.name'),
      defaultColumns: defaultColumns.filter(stat => stat.get('name') !== 'report.name'),
      leaderboardReports: leaderboardResults.get('reports', List()),
      canEditLeaderboardWidgets: features.get('EditLeaderboardWidgetsFeatureFlag'),
      reports
    };
  }
);
