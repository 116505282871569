import React from 'react';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Center from 'components/center/center';
import Dropdown from 'components/dropdown/dropdown';
import {useSelector} from 'hooks/redux';
import {widgetOptionsSelector} from 'modules/dashboards/selectors';
import AddWidgetButton from 'modules/dashboards/containers/addWidgetButton';
import styles from './dashboards.scss';

export default function DashboardColumn({
  children,
  contextTypeId,
  columnIndex,
  endColumn,
  onAddWidget
}) {
  return (
    <Flex
      className={styles.column}
      basis={endColumn ? '52px' : '100px'}
      grow={!endColumn ? 1 : 0}
      self={!endColumn ? undefined : 'center'}
      left={columnIndex === 0 ? undefined : '20px'}
    >
      {children}
      {contextTypeId && onAddWidget && (
        <Center>
          <AddWidgetButton contextTypeId={contextTypeId} onAddWidget={onAddWidget} />
        </Center>
      )}
    </Flex>
  );
}
