import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_SCOPES} from '../constants';
import {setScopes, requestScopesComplete} from '../actions';

export default function* requestPolicyScopesSaga() {
  yield takeLatest(REQUEST_SCOPES, requestPolicyScopes);
}

function* requestPolicyScopes() {
  try {
    const scopes = yield processRequest('GET', 'policy-scopes');
    yield put(setScopes(scopes));
    yield put(requestScopesComplete());
  } catch (err) {
    yield put(requestScopesComplete(err));
  }
}
