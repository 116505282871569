exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WZg-pDDJJEOwxYxrjzztz{padding:0 65px;max-width:800px}@media(max-width: 767px){.WZg-pDDJJEOwxYxrjzztz{padding:0 30px}}h1._1gECrnV0MvCuAvCZ1n09mb{font-size:1.8em;margin-bottom:40px;line-height:1.5;text-align:center}._3STcRVZqqVEuyO7OfpgHaC{margin-bottom:20px;color:#434343}._3STcRVZqqVEuyO7OfpgHaC h2{font-weight:300;font-size:1.2em}._3STcRVZqqVEuyO7OfpgHaC h2::before{content:\"\\25BA\";padding-right:1em;color:#cbcbcb;font-size:.8em;display:inline-block;width:2em}._3STcRVZqqVEuyO7OfpgHaC.DqOmbgVKDZXC90Y4NRbNN h2::before{content:\"\\25BC\"}._3STcRVZqqVEuyO7OfpgHaC p{padding-left:2.5em}", ""]);

// exports
exports.locals = {
	"wrapper": "WZg-pDDJJEOwxYxrjzztz",
	"title": "_1gECrnV0MvCuAvCZ1n09mb",
	"faq": "_3STcRVZqqVEuyO7OfpgHaC",
	"open": "DqOmbgVKDZXC90Y4NRbNN"
};