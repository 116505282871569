export const REQUEST_DIGESTS = 'digests:REQUEST_DIGESTS';
export const REQUEST_DIGESTS_COMPLETE = 'digests:REQUEST_DIGESTS_COMPLETE';
export const RECEIVE_DIGESTS = 'digests:RECEIVE_DIGESTS';

export const REQUEST_DIGEST_SUBSCRIPTIONS = 'digests:REQUEST_DIGEST_SUBSCRIPTIONS';
export const REQUEST_DIGEST_SUBSCRIPTIONS_COMPLETE =
  'digests:REQUEST_DIGEST_SUBSCRIPTIONS_COMPLETE';
export const RECEIVE_DIGEST_SUBSCRIPTIONS = 'digests:RECEIVE_DIGEST_SUBSCRIPTIONS';

export const SAVE_DIGEST_SUBSCRIPTIONS = 'digests:SAVE_DIGEST_SUBSCRIPTIONS';
export const SAVE_DIGEST_SUBSCRIPTIONS_COMPLETE = 'digests:SAVE_DIGEST_SUBSCRIPTIONS_COMPLETE';

// when we add a digest subscription, we save to a temporary store, and then pass them all along when the users clicks "save"
export const ADD_UNSAVED_DIGEST_SUBSCRIPTION = 'digests:ADD_UNSAVED_DIGEST_SUBSCRIPTION';
export const EDIT_DIGEST_SUBSCRIPTION = 'digests:EDIT_DIGEST_SUBSCRIPTION';
export const REMOVE_UNSAVED_DIGEST_SUBSCRIPTIONS = 'digests:REMOVE_UNSAVED_DIGEST_SUBSCRIPTIONS';
export const REMOVE_DIGEST_SUBSCRIPTION = 'digests:REMOVE_DIGEST_SUBSCRIPTION';

export const REQUEST_DIGEST_PREVIEW = 'digest:REQUEST_DIGEST_PREVIEW';
