import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import FolderViewModal from 'modules/folderViews/components/folderViewModal';
import {TRIGGER_EDIT_VIEW, SAVE_FOLDER_VIEW, receiveFolderView} from 'modules/folderViews';
import {folderViewEndpoint} from 'modules/folderViews/folderViewUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {showInModal} from 'modules/modal';
import {HIDE_MODAL} from 'modules/modal';
import {selectFolderView} from 'modules/folderViews/folderViewSelectors';
import {statsSelector} from 'modules/stats/statSelectors';
import {toJS, keyBy} from 'utils/immutableUtils';
import {fromJS} from 'immutable';
import {fetchFolderViews} from 'modules/folderViews';

export default function* addFolderViewSaga() {
  yield takeLatest([TRIGGER_EDIT_VIEW], editFolderView);
}

function* editFolderView(action) {
  const {folderId, viewId} = action;

  const view = toJS(yield select(selectFolderView(viewId)));

  const selectedOptions = view.columns.map(({name}) => name);

  const modalView = {...view, columns: selectedOptions};

  yield put(
    showInModal(FolderViewModal, {
      name: 'editFolderViewModal',
      folderView: modalView
    })
  );

  const {type, folderView} = yield take([SAVE_FOLDER_VIEW, HIDE_MODAL]);

  if (type === HIDE_MODAL) {
    return null;
  }

  // our new `column` is just a string. We need the full representation
  const stats = toJS(yield select(statsSelector));
  const keyedStats = toJS(keyBy(stats, 'name'));

  // columns are in string format at wheatley is expecting objects
  const columns = folderView.columns.map(name => keyedStats[name]);

  const newView = {
    ...view,
    columns,
    name: folderView.name,
    type: folderView.type
  };

  yield processRequest(
    'PUT',
    folderViewEndpoint(action),
    {
      successAction: receiveFolderView,
      success: function*(data) {
        yield put(fetchFolderViews(folderId, viewId));
      },
      errorMessage: 'Could not save the new columns'
    },
    newView
  );
}
