exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rrqfcymH3Avs1PP-ASNb4{margin:0;font-size:18px;font-weight:bold}._2sSZ9_ceRm-3P3Kzkim44r{font-size:12px;position:relative;top:-2px;margin-left:10px}.LzipD82JPTa44tajI0j5p{margin:5px 0 0 0;word-break:break-word}", ""]);

// exports
exports.locals = {
	"decisionName": "_2rrqfcymH3Avs1PP-ASNb4",
	"pill": "_2sSZ9_ceRm-3P3Kzkim44r",
	"content": "LzipD82JPTa44tajI0j5p"
};