import React from 'react';
import {connect} from 'react-redux';
import {startOnboarding} from 'modules/onboarding';
import OnboardingPage from 'modules/onboarding/pages/onboardingPage';
import {parseQuery} from 'utils/urlUtils';

export class OnboardingRoute extends React.Component {
  componentWillMount() {
    const {
      location: {search}
    } = this.props;

    this.props.startOnboarding(parseQuery(search));
  }
  render() {
    return <OnboardingPage />;
  }
}

export default connect(
  null,
  {startOnboarding}
)(OnboardingRoute);
