import React from 'react';
import styles from './student.scss';
import Button from 'components/button';
import courseStyles from '../../pages/courses.scss';
import UserImage from 'components/user/userImage';
import Icon from 'components/font-awesome';
import {useSelector, useDispatch} from 'hooks/redux';
import {usersSelector} from 'modules/users/userSelectors';
import {fetchUsers} from 'modules/users/users';
import Spinner from 'components/spinner';
import {EntityMultiSelector} from 'components/entitySelect';
import {fromJS} from 'immutable';
import Alert from 'components/alert';
import Flex from 'components/layout/flex';
import {keyBy} from 'utils/immutableUtils';
import {setEnrolledUsers} from 'modules/courses/actions';
import {enrollingUsersSelector} from 'modules/courses/selectors';

export default function EnrollStudents({courseId, students, dark}) {
  const dispatch = useDispatch();
  const enrollingUsers = useSelector(enrollingUsersSelector);
  const studentIds = students.map(student => student.get('userId').toString()).toJS();
  const [value, setValue] = React.useState(studentIds);
  const users = useSelector(usersSelector);

  const valueEqualsInitial = value.every(studentId => {
    return studentIds.includes(studentId);
  });

  const studentIdsEqualsInitial = studentIds.every(studentId => {
    return value.includes(studentId);
  });

  const doesEqualInitial = valueEqualsInitial && studentIdsEqualsInitial;

  // fetch users if they don't exist
  React.useEffect(() => {
    if (!users.size) {
      dispatch(fetchUsers());
    }
  }, [users.size]);

  if (!users.size) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <EntityMultiSelector
        selectedLabel="Enrolled users"
        unselectedLabel="Unenrolled users"
        addLabel="Enroll"
        removeLabel="Unenroll"
        options={mapUsers(users.merge(keyBy(students.toJS(), 'userId')), value)}
        value={value}
        onChange={setValue}
        dark={dark}
      />
      <Flex container>
        <Flex right={10}>
          <Button
            isLoading={enrollingUsers.get('loading')}
            disabled={doesEqualInitial}
            onClick={() => {
              dispatch(setEnrolledUsers(courseId, value));
            }}
          >
            Save
          </Button>
        </Flex>
        <Flex grow={1} align="center" justify="center">
          {!doesEqualInitial && (
            <Alert noMargin padding="10" level="info">
              You have unsaved changes
            </Alert>
          )}
        </Flex>
      </Flex>
    </React.Fragment>
  );
}

function mapUsers(users, selectedIds) {
  return users
    .toList()
    .map(user => {
      return {
        value: user.get('userId').toString(),
        keyword: user.get('name'),
        label: (
          <div>
            <UserImage size={50} inline userId={user.get('userId')}>
              {() => (
                <div>
                  {user.get('name')}

                  {selectedIds.includes(user.get('userId').toString()) && (
                    <div style={{paddingTop: '5px', opacity: '0.7'}}>
                      {user.has('percentComplete') && (
                        <div>
                          <Icon name="fas fa-check" className={courseStyles.progressComplete} />{' '}
                          {Math.round(user.get('percentComplete'))}% complete
                        </div>
                      )}
                      {user.has('percentCorrect') && (
                        <div>
                          <Icon name="fas fa-star" className={courseStyles.progressCorrect} />{' '}
                          {Math.round(user.get('percentCorrect'))}% correct
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </UserImage>
          </div>
        )
      };
    })
    .toJS();
}
