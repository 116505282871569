import _ from 'lodash';
import React from 'react';
import Chart from 'components/chart';
import {GraphWrapper} from '../shared/graphs';
import {highchartsConfig} from 'utils/graphUtils';

export default class BarChart extends React.Component {
  getResult() {
    return this.props.result; // _.sortBy(this.props.result, 'value');
  }

  getChartConfig(result) {
    const {
      config: {label, xaxis, yaxis, height, disableylabel, min, max, invert = false}
    } = this.props;

    return highchartsConfig({
      chart: {
        type: invert ? 'column' : 'bar',
        height: typeof height === 'number' ? height : undefined
      },
      tooltip: false,
      title: {text: label},
      series: this.getSeries(result),
      xAxis: {
        categories: _.map(result, item => item.label),
        title: {
          text: xaxis
        },
        labels: {
          style: {fontSize: '12px'}
        }
      },
      yAxis: {
        min: min,
        max: max,
        title: {
          text: yaxis
        },
        labels: {
          style: {fontSize: '12px'},
          enabled: !disableylabel
        }
      },
      legend: {
        enabled: false
      },
      // plotOptions: {series: {dataLabels: {shadow: false}}},
      plotOptions: {
        series: {
          dataLabels: {
            style: {
              textShadow: false,
              textOutline: false
            }
          }
        }
      }
    });
  }

  getSeries(result) {
    const data = _.map(result, item => {
      return {
        name: item.label,
        y: item.value,
        color: item.color
      };
    });

    return [
      {
        data
      }
    ];
  }

  render() {
    const {hideMargin} = this.props;
    const result = this.getResult();
    if (!result) return null;

    return (
      <GraphWrapper hideMargin={hideMargin}>
        <Chart config={this.getChartConfig(result)} />
      </GraphWrapper>
    );
  }
}
