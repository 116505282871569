import _ from 'lodash';
import emailValidator from 'email-validator';
import {ensureHasScheme} from 'utils/urlUtils';

// https://gist.github.com/dperini/729294 (notice I removed the "user:pass" authentication requirement)
const urlRegExp = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#]\S*)?$/i;

// Keyed by validation prop name
const FieldValidators = {
  required: value => isUsable(value),
  min: (value, arg) => value >= arg,
  max: (value, arg) => value <= arg,
  minLength: (value = '', arg) => value.length >= arg,
  maxLength: (value = '', arg) => value.length <= arg,

  // these are not required, so they can pass if the value is falsy
  url: value => !value || urlRegExp.test(value),
  email: value => !value || emailValidator.validate(value),

  searchEngines: (value = []) => {
    return (
      !value.length ||
      value.reduce((wasLastValid, val) => {
        const isValid =
          typeof val == 'object' &&
          !!val.engine &&
          !!val.options.language &&
          (val.engine === 'google' ? !!val.options.countryCode : true);

        return wasLastValid && isValid;
      }, true)
    );
  }
};

// Keyed by validation prop name
const ValidatorMessages = {
  url: 'Enter a web address, e.g. example.com',
  email: 'Enter an email address, e.g. username@example.com',
  required: 'You must enter something here',
  searchEngines: 'You must select a location from the list',

  min: min => `Enter ${min} or above`,
  max: max => `Enter ${max} or below`,
  minLength: min => `Enter at least ${min} characters`,
  maxLength: max => `Enter up to ${max} characters`
};

// Keyed by field `type`, these are called for each field type before a form is submitted.
const FormatFieldTypes = {
  number(string) {
    if (typeof string != 'string') return string;

    let number = string;

    // Limit the string to 15 characters so we don't run into issues with `Number.MAX_SAFE_INTEGER`
    if (string.length < 16) {
      number = parseFloat(string);

      // We must return undefined if "not a number", otherwise react complains that it can't render NaN
      if (isNaN(number)) {
        return undefined;
      }
    }

    return number;
  },

  url(string) {
    return ensureHasScheme(string);
  }
};

// evaluates the multiple rules that could be defined per field
export function validateField(value, rules, customMessages, type) {
  const normaliser = FormatFieldTypes[type];

  if (normaliser) {
    value = normaliser(value);
  }

  for (var validatorName in rules) {
    const arg = rules[validatorName];
    const isValid = FieldValidators[validatorName](value, arg);

    if (!isValid) {
      let message = customMessages[validatorName] || ValidatorMessages[validatorName];

      if (typeof message === 'function') {
        message = message(arg);
      }
      return {isValid: false, message};
    }
  }

  return {isValid: true};
}

// This extracts the validation props from the Component Props.
// Seeing as it has access to the props, it can add default validators too
// I.E type="url" should automatically have the `url={true}` prop present
export function getValidatorsFromProps(props) {
  const {type} = props;

  return Object.keys(FieldValidators).reduce((reduction, validatorName) => {
    if (typeof props[validatorName] !== 'undefined') {
      reduction[validatorName] = props[validatorName];
    }
    return reduction;
  }, getDefaultValidators(type));
}

// normalisesthe data for each field based on field type
export function formatFields(data, fields) {
  const formattedData = {...data};

  for (var name in fields) {
    if (!fields[name]) continue;

    var {type} = fields[name].props;
    var normaliser = FormatFieldTypes[type];

    if (normaliser && formattedData[name]) {
      formattedData[name] = normaliser(formattedData[name]);
    }
  }
  return formattedData;
}

// Validators are Props that are defined on Field components.
// I.E. <Field type="text" name="name" required maxLength={10} />
// Default validators are props that are silently applied automatically
// I.E <Field type="url" /> will automatically have `url={true}` applied
function getDefaultValidators(type) {
  switch (type) {
    case 'url':
      return {url: true};
    case 'email':
      return {email: true};
    default:
      return {};
  }
}

// an implementation for the `required` validator
function isUsable(value) {
  if (typeof value == 'string' || Array.isArray(value) || _.isPlainObject(value)) {
    return !_.isEmpty(value);
  } else if (typeof value == 'number') {
    return !isNaN(value);
  } else if (typeof value == 'boolean') {
    return true;
  } else {
    // null or undefined
    return value != null;
  }
}

export function deflatten(obj) {
  var output = {};

  for (var key in obj) {
    let val = obj[key];
    let path = key.split('.');
    let local = output;

    for (var i = 0; i < path.length; i++) {
      let next = path[i];
      let theLocal = local[next];

      // if we are NOT on the last item
      // and the next thing isnt an object or array
      if (i === path.length - 1) {
        local[next] = val;
      } else {
        if (!theLocal) {
          if (/^\d+$/.test(path[i + 1])) {
            local[next] = [];
          } else {
            local[next] = {};
          }
        }
        local = local[next];
      }
    }
  }
  return output;
}

export function flatten(obj) {
  const result = {};

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] == 'object' && obj[i] !== null) {
      var flat = flatten(obj[i]);
      for (var x in flat) {
        if (!flat.hasOwnProperty(x)) continue;

        result[i + '.' + x] = flat[x];
      }
    } else {
      result[i] = obj[i];
    }
  }
  return result;
}
