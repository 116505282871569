import PropTypes from 'prop-types';
import React from 'react';
import styles from './explorerSearch.scss';
import SearchBar from 'components/searchBar';

class ExplorerSearch extends React.Component {
  handleSearchText = value => {
    const {search} = this.props;
    const searchFilter = search.filter;

    this.props.setFilterParams({
      [searchFilter]: value
    });
  };

  render() {
    const {search, filter} = this.props;
    if (!search) return null;

    const searchFilter = search.filter;
    const placeholder = search.label || 'Search';

    return (
      <SearchBar
        name={searchFilter}
        value={filter[searchFilter] || ''}
        placeholder={placeholder}
        onEnter={this.handleSearchText}
        className={styles.search}
        roundedEdges
        autoFocus={false}
      />
    );
  }
}

export default ExplorerSearch;
