import PropTypes from 'prop-types';
import React from 'react';
import devices from './devices';
import styles from './device.scss';
import Scale from 'components/layout/scale';
import GradientBackground from 'components/background/gradientBackground';

// Takes a model prop i.e. 'iphone' and renders children inside
// a container which looks like that device.

export default class Device extends React.Component {
  static defaultProps = {
    viewportColor: 'white'
  };

  render() {
    const {children, model, viewportColor: backgroundColor} = this.props;
    const device = devices[model];

    if (!device) return children;

    const {width, height, image, padding} = device;

    const outerStyle = {
      backgroundImage: `url(${require('./images/' + image)})`,
      padding: padding.join('px ') + 'px'
    };

    const innerStyle = {
      width,
      height,
      backgroundColor
    };

    return (
      <GradientBackground preset="greyRadial">
        <Scale>
          <div className={styles.device} style={outerStyle}>
            <div className={styles.inner} style={innerStyle}>
              {typeof children === 'function' ? children({width, height}) : children}
            </div>
          </div>
        </Scale>
      </GradientBackground>
    );
  }
}
