import React from 'react';

import styles from 'modules/products/components/planFeatures.scss';
import Flex from 'components/layout/flex';
import Icon from 'components/font-awesome';

const renderItem = (text, i) => {
  return (
    <li key={i}>
      <Icon name="check-circle" className={styles.icon} />
      {text}
    </li>
  );
};

const PlanFeatures = ({features}) => {
  const col1Features = features.slice(0, features.length / 2);
  const col2Features = features.slice(features.length / 2, features.length);

  return (
    <Flex container className={styles.planFeatures}>
      <Flex grow={1}>
        <ul>{col1Features.map(renderItem)}</ul>
      </Flex>
      <Flex grow={1}>
        <ul>{col2Features.map(renderItem)}</ul>
      </Flex>
    </Flex>
  );
};

export default PlanFeatures;
