import React from 'react';
import styles from './colorContrastIssue.scss';
import Icon from 'components/font-awesome';
import ExternalLink from 'modules/location/externalLink';

class ColorContrastIssue extends React.Component {
  parseColor(color) {
    var arr = [];
    color.replace(/[\d+\.]+/g, function(v) {
      arr.push(parseFloat(v));
    });
    return {
      hex:
        '#' +
        arr
          .slice(0, 3)
          .map(this.toHex)
          .join(''),
      opacity: arr.length == 4 ? arr[3] : 1
    };
  }

  toHex(int) {
    var hex = int.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  normaliseColor(string) {
    if (!string) {
      return ''; // Normalise null -> string
    }
    if (string[0] === '#') {
      return string;
    }
    return this.parseColor(string).hex;
  }

  render() {
    const {issueData, isOpen} = this.props;

    const text = issueData.get('text');
    const metadata = issueData.get('metadata').toJS();
    const {
      bgImg,
      color,
      bgColor,
      overlayColor,
      bgRepeat,
      bgSize,
      bgPosition,
      contrast,
      requiredContrast,
      fontSizePixels,
      fontSize: unroundedFontSize,
      fontWeight,
      isLarge
    } = metadata;

    const fontSize = Math.round(unroundedFontSize * 10) / 10;

    let backgroundImg = 'none';
    if (bgImg) {
      if (bgImg.match(/^http/)) {
        backgroundImg = 'url(' + bgImg + ')';
      } else {
        backgroundImg = bgImg;
      }
    }

    const previewStyles = {
      color: color,
      fontSize: fontSizePixels,
      fontWeight: fontWeight,
      backgroundColor: bgColor,
      backgroundImage: backgroundImg,
      backgroundRepeat: bgRepeat,
      backgroundPosition: bgPosition,
      backgroundSize: bgSize || (!bgRepeat || bgRepeat === 'no-repeat' ? 'cover' : 'auto'),
      minHeight: bgImg ? 100 : 0
    };

    const overlayStyles = {
      backgroundColor: overlayColor
    };

    const largeFontSize = isLarge ? 18 : 14;

    const checkUrl =
      'https://webaim.org/resources/contrastchecker/?fcolor=' +
      encodeURI(this.normaliseColor(color).replace('#', '')) +
      '&bcolor=' +
      encodeURI(this.normaliseColor(bgColor).replace('#', ''));

    let recommendation = '';
    if (fontSize > largeFontSize - 3 && fontSize < largeFontSize) {
      recommendation = (
        <span>
          Make text slightly larger (+{Math.round((largeFontSize - fontSize) * 10) / 10}pts) or
          improve contrast
        </span>
      );
    } else {
      recommendation = <span>Improve contrast</span>;
    }

    return (
      <div>
        <div className={styles.preview} style={previewStyles}>
          <div className={styles.overlay} style={overlayStyles}>
            <span>{text}</span>
          </div>
        </div>
        <div>
          <span className={styles.info}>
            {isLarge && (
              <span>
                <Icon name="fas fa-font" /> Large text ({fontSize}pt)
              </span>
            )}
            {!isLarge && (
              <span>
                <Icon name="fal fa-font" /> Small text ({fontSize}pt)
              </span>
            )}
          </span>
          <span className={styles.info}>
            <Icon name="fas fa-adjust" />{' '}
            {contrast && (
              <span>
                Contrast: {contrast} / {requiredContrast}
              </span>
            )}
            {!contrast && <span>Required contrast: {requiredContrast}</span>}
          </span>
          {!!recommendation && <div className={styles.recommendations}>{recommendation}</div>}
          {backgroundImg === 'none' && (
            <div className={styles.recommendations}>
              <ExternalLink href={checkUrl}>Get recommendations</ExternalLink>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ColorContrastIssue;
