import React from 'react';
import Button from 'components/button';
import {toJS} from 'utils/immutableUtils';
import {useSelector, useDispatch} from 'hooks/redux';
import {ModalContent, hideModal} from 'modules/modal';
import {Field, Form} from 'modules/form/components';
import {reportSelector} from 'modules/reports/reportSelectors';
import AssignedTags from 'modules/tags/components/assignedTags';
import {viewTest} from 'modules/tests/tests';
import {locationParamsSelector, queryParamsSelector} from 'modules/location/locationSelectors';

export default function ReportAssignedTagsModal() {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const report = useSelector(reportSelector);
  const params = useSelector(locationParamsSelector);
  const query = useSelector(queryParamsSelector);
  const entityTags = toJS(report.get('assignedTags'));

  return (
    <AssignedTags entityType="report" entityId={report.get('reportId')} entityTags={entityTags}>
      {({
        setAssignedEntitiesTags,
        isAssigningEntityTags,
        onEntityTagsChanged,
        hasEntityTagsChanged,
        isLoadingEntityTags
      }) => {
        const footer = (
          <Button
            type="submit"
            disabled={!hasEntityTagsChanged}
            isLoading={isAssigningEntityTags}
            onClick={() => {
              formRef.current.submit();
            }}
          >
            Save
          </Button>
        );
        return (
          <ModalContent title="Website tags" footer={footer}>
            <Form
              ref={formRef}
              defaultValue={{entityTags}}
              onValidSubmit={data => {
                setAssignedEntitiesTags(data.entityTags, success => {
                  if (success) {
                    dispatch(hideModal());
                    // request screen results as soon as we've finished
                    // TODO ideally we wanna request JUST the ReportTagsSelector result
                    dispatch(viewTest(params, query));
                  }
                });
              }}
            >
              <Field
                type="tags"
                name="entityTags"
                isLoading={isLoadingEntityTags}
                onChange={val => {
                  onEntityTagsChanged(val);
                }}
              />
            </Form>
          </ModalContent>
        );
      }}
    </AssignedTags>
  );
}
