import React from 'react';
import styles from './styles.scss';

class Dimensions extends React.Component {
  render() {
    let {data, unit} = this.props;

    if (typeof data !== 'object' || Object.keys(data).length === 0) {
      return <span className="label appearance-minor">N/A</span>;
    }

    if (typeof units === 'undefined') {
      unit = 'px';
    }

    const renderedDimensions = [];
    Object.keys(data).map(index => {
      renderedDimensions.push(
        <span className={styles.dimension}>
          {data[index]}
          {unit}
        </span>
      );
      renderedDimensions.push(' x ');
    });
    // remove the last 'x'
    renderedDimensions.pop();

    return <div>{renderedDimensions}</div>;
  }
}

export default Dimensions;
