/**
 * These constants are mirrored in Marvel, see: AccountOptions.php
 */
export const BRANDING_LOGO_URL = 'brandingLogoUrl';
export const ACADEMY_SCORES_OWNER_ONLY = 'academyScoresOwnerOnly';
export const DEFAULT_SCHEDULE = 'defaultSchedule';
export const FOLDER_VIEWS_ENABLED = 'folderViewsEnabled';
export const DEFAULT_ROLE_FOR_EVERYONE = 'defaultRoleForEveryone';
export const PASSWORD_EXPIRY_DAYS = 'passwordExpiryDays';
export const ACCESSIBILITY_STANDARD = 'accessibilityStandard';
export const ACCESSIBILITY_STANDARD_OPTIONS = [
  {value: '', label: 'Use latest'},
  {value: 'WCAG 2.0', label: 'WCAG 2.0'},
  {value: 'WCAG 2.1', label: 'WCAG 2.1'}
];

export const ACCOUNT_OPTIONS = {
  [ACCESSIBILITY_STANDARD]: '',
  [BRANDING_LOGO_URL]: '',
  [DEFAULT_SCHEDULE]: {
    schedulePeriod: 1,
    scheduleInterval: 'week'
  },
  [FOLDER_VIEWS_ENABLED]: false,
  [DEFAULT_ROLE_FOR_EVERYONE]: '',
  [ACADEMY_SCORES_OWNER_ONLY]: false
};
