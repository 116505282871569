import React from 'react';
import Pusher from 'modules/pusher/components/pusher';
import {globalListenerChannel} from 'modules/pusher/channels';

export default class PageReloadListener extends React.Component {
  reloadEventReceived = () => {
    window.location.reload();
  };

  render() {
    return (
      <Pusher
        channel={globalListenerChannel()}
        event="reload"
        onUpdate={this.reloadEventReceived}
      />
    );
  }
}
