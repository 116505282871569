import React from 'react';
import PropTypes from 'prop-types';
import styles from './teaser.scss';
import Icon from 'components/font-awesome';
import {getSalesUrl} from './utils';
import ExternalLink from 'modules/location/externalLink';
import {useParams} from 'react-router';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const TeaserBanner = ({children, link}) => {
  const params = useParams();
  const url = link || getSalesUrl(params.accountId);

  return (
    <ExternalLink href={url} className={styles.teaserBanner}>
      <Icon name="fa fa-lock" />
      {children}
    </ExternalLink>
  );
};

TeaserBanner.propTypes = propTypes;

export default TeaserBanner;
