import _ from 'lodash';
import React from 'react';
import cx from 'classnames';

export default localStyles => WrappedComponent =>
  class extends React.Component {
    render() {
      const {className, ...props} = this.props;

      const classes = cx(
        getStylesFromProps(localStyles, {
          ...props,
          base: true // always add styles.base
        }),
        className
      );

      return <WrappedComponent {...props} className={classes} />;
    }
  };

// returns all classNames from a styles object which
// have a corresponding truth prop.
// e.g. the `large` property, when set to true, will add the `large` class
//  providing .large is defined in styles.
export function getStylesFromProps(styles, props, prefix = '') {
  return _.reduce(
    props,
    (memo, val, propName) => {
      if (!!val && styles.hasOwnProperty(prefix + propName)) {
        memo.push(styles[prefix + propName]);
      }
      return memo;
    },
    []
  );
}
