import {VIEW_NOTIFICATIONS} from '..';
import {put, select, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {notificationsShownEndpoint} from '../notificationUtils';

export default function* viewNotificationSaga() {
  yield takeLatest(VIEW_NOTIFICATIONS, viewNotifications);
}

function* viewNotifications() {
  // Inform backend that notifications have been viewed
  yield processRequest('POST', notificationsShownEndpoint());
}
