import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/select';
import _ from 'lodash';

export default class MultiselectInput extends React.Component {
  handleChange = options => {
    const {onChange} = this.props;

    onChange && onChange(options.map(option => option.value));
  };

  getOptions() {
    const {options, value, allowCreate} = this.props;

    // A litte hacky:
    // if we allow new options, then the options may not have been
    // updated to allow this new tag, so this joins them together
    if (allowCreate) {
      return _.union(options, value);
    }

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        multi
        clearable
        options={this.getOptions()}
        onChange={this.handleChange}
      />
    );
  }
}
