import React from 'react';
import {connect} from 'react-redux';
import {trendDatesSelector, changeDateRange} from '..';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import styles from './trendDateRange.scss';

export class TrendDateRange extends React.Component {
  state = {
    focusedInput: null
  };

  handleFocusChange = focusedInput => {
    this.setState({focusedInput});
  };

  handleDatesChange = ({startDate, endDate}) => {
    if (!startDate || !endDate) return;

    this.props.changeDateRange(startDate.unix(), endDate.unix());
  };

  isOutsideRange = () => false;

  render() {
    const {startTime, endTime} = this.props;
    return (
      <DateRangePicker
        startDate={moment.unix(startTime)}
        endDate={moment.unix(endTime)}
        onDatesChange={this.handleDatesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={this.handleFocusChange}
        endDateId="endDate"
        startDateId="startDate"
        displayFormat="MMM D"
        enableOutsideDays
        isOutsideRange={this.isOutsideRange}
        noBorder
      />
    );
  }
}

export default connect(
  trendDatesSelector,
  {changeDateRange}
)(TrendDateRange);
