import PropTypes from 'prop-types';
import React from 'react';
import TransitionGroup from './transitionGroup';
import cx from 'classnames';
import styles from './transitionReplace.scss';

class TransitionReplace extends React.Component {
  render() {
    const {className, ...props} = this.props;

    const classes = cx(className, styles.transitionReplace);

    return <TransitionGroup className={classes} {...props} />;
  }
}

TransitionReplace.propTypes = {};

export default TransitionReplace;
