import React from 'react';
import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {SET_DASHBOARD_COLUMN_COUNT} from 'modules/dashboards/constants';
import {setDashboardColumnCountComplete} from 'modules/dashboards/actions';
import {dashboardNumWidgetsSelector} from 'modules/dashboards/selectors';
import confirm from 'utils/saga/confirm';
import {choosePluralVersion} from 'utils/interpolationUtils';

export default function* saveDashboardListener() {
  yield takeLatest(SET_DASHBOARD_COLUMN_COUNT, saveDashboardSaga);
}

function* saveDashboardSaga(action) {
  const numWidgets = yield select(dashboardNumWidgetsSelector, action);
  const remainingWidgets = numWidgets - action.colCount;

  if (action.colCount < numWidgets) {
    yield confirm({
      title: `You must delete ${choosePluralVersion(
        remainingWidgets,
        'a widget',
        'some widgets'
      )} first`,
      content: (
        <React.Fragment>
          <p>{`In order to set the number of columns for this row to ${
            action.colCount
          }, you must remove ${remainingWidgets} ${choosePluralVersion(
            remainingWidgets,
            'widget',
            'widgets'
          )} from this row first.`}</p>
          <p>{`To delete a widget, click the settings icon for the widget that you want to delete and then choose the delete option`}</p>
        </React.Fragment>
      ),
      submitLabel: 'Ok'
    });
  } else {
    yield put(
      setDashboardColumnCountComplete(action.dashboardId, action.rowIndex, action.colCount)
    );
  }
}
