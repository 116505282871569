import cx from 'classnames';
import React from 'react';
import styles from './inspectorUrl.scss';
import RelativeDate from 'components/time/relativeDate';
import UrlComponent from 'components/url/url';
import InspectorEditableUrlComponent from 'modules/inspector/components/inspectorEditableUrl';

class InspectorUrl extends React.Component {
  handleClick = () => {
    const {showEditableUrl} = this.props;

    if (!showEditableUrl) {
      window.open(this.props.url, '_blank');
    }
  };

  render() {
    const {url, time, onEditUrlSubmit, showEditableUrl} = this.props;

    if (!url) {
      return <div className={cx(styles.url, styles.urlLoading)}>... Loading ...</div>;
    }

    return (
      <div
        className={cx(styles.url, {[styles.urlStandalone]: showEditableUrl})}
        onClick={this.handleClick}
      >
        {!showEditableUrl && <UrlComponent url={url} link={true} className={styles.atag} />}
        {time !== -1 && time != undefined && (
          <div className={styles.timestamp}>
            <RelativeDate timestamp={time} />
          </div>
        )}
        {showEditableUrl && (
          <InspectorEditableUrlComponent url={url} onEditUrlSubmit={onEditUrlSubmit} />
        )}
      </div>
    );
  }
}

export default InspectorUrl;
