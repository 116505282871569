export const ATTEMPT_CONFIRM_CHECKOUT = 'app:ATTEMPT_CONFIRM_CHECKOUT';
export function attemptConfirmCheckout(checkoutRequestId) {
  return {
    type: ATTEMPT_CONFIRM_CHECKOUT,
    checkoutRequestId
  }
}

export const RECEIVE_ATTEMPT_CONFIRM_CHECKOUT_RESPONSE = 'app:RECEIVE_ATTEMPT_CONFIRM_CHECKOUT_RESPONSE';
export function receiveAttemptConfirmCheckoutResponse(response) {
  return {
    type: RECEIVE_ATTEMPT_CONFIRM_CHECKOUT_RESPONSE,
    response
  }
}

export const HANDLE_BAD_CHECKOUT_ERROR = 'app:HANDLE_BAD_CHECKOUT_ERROR';
export function handleBadCheckoutError(error) {
  return {
    type: HANDLE_BAD_CHECKOUT_ERROR,
    error
  }
}