import React from 'react';
import styles from './wappalyzerIcon.scss';
import {API} from 'config';

class WappalyzerIcon extends React.Component {
  render() {
    const {data} = this.props;

    if (!data) {
      return null;
    }

    let src = data;
    // Hello! Delete this whole if statement below! It's just needed to have a safe migration! Everything should be
    // base64 now!
    if (data.indexOf('data:') === -1) {
      src = API.ROOT + 'images/technology-icons/' + data;
    }

    return <img src={src} className={styles.icon} />;
  }
}

export default WappalyzerIcon;
