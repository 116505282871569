import React from 'react';
import cx from 'classnames';
import styles from './actionName.scss';
import RelativeDate from 'components/time/relativeDate';
import ActionPriority from 'modules/actions/components/actionPriority';

// NOTE: this file is still called taskName because that is the TYPE of the layout component

class ActionName extends React.Component {
  renderDateInformation() {
    const {opened, created} = this.props.record;

    if (opened) {
      return (
        <span>
          opened <RelativeDate timestamp={opened} plain />
        </span>
      );
    }

    if (created) {
      return (
        <span>
          created <RelativeDate timestamp={created} plain />
        </span>
      );
    }

    return null;
  }

  render() {
    const {record, className, style, showPriority, notLink, smaller} = this.props;
    const {actionId, name, subName, severity, priority, state, tagId} = record;

    return (
      <div className={className} style={style}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {showPriority && <ActionPriority value={priority} actionState={state} />}
          <p
            className={cx(
              notLink ? styles.actionName : styles.actionNameLink,
              smaller && styles.smaller
            )}
          >
            {name}
            {subName && <span className={styles.subName}>{subName}</span>}
          </p>
        </div>
      </div>
    );
  }
}

ActionName.propTypes = {};

export default ActionName;
