import {takeLatest, put} from 'redux-saga/effects';
import {push} from 'modules/location';
import {processRequest} from 'utils/saga/fetchUtils';
import {receiveUptimeMonitor} from 'modules/uptime/actions';
import {UPDATE_UPTIME_MONITOR} from 'modules/uptime/constants';
import {uptimeMonitorPath} from '../utils';
import {showSuccess, showError} from 'utils/saga/alerts';

export default function* updateUptimeMonitorSaga() {
  yield takeLatest(UPDATE_UPTIME_MONITOR, updateUptimeSaga);
}

function* updateUptimeSaga({reportId, monitorId, data}) {
  const url = monitorId
    ? `uptime-monitors/${reportId}/${monitorId}`
    : `uptime-monitors/${reportId}`;

  yield processRequest(
    monitorId ? 'PUT' : 'POST',
    url,
    {
      success: function*(uptimeMonitor) {
        yield showSuccess('Uptime monitor was sucessfully ' + (monitorId ? 'editted' : 'added'));

        yield put(receiveUptimeMonitor(uptimeMonitor));
        yield put(push(uptimeMonitorPath, {monitorId: uptimeMonitor.monitorId}, ['reportId']));
      },
      error: function*(err = {}) {
        if (err.exception === 'NotEnoughUptimeMonitorBalanceException') {
          yield showError(
            'The uptime monitor limit has been reached. Please contact your account manager to increase this limit.'
          );
        } else {
          yield showError('Failed to update monitor, please try again shortly.');
        }
      }
    },
    data
  );
}
