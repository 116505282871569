exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Hrb9DTL_iWVzmEYuTTd5v{cursor:pointer;color:#888}.Hrb9DTL_iWVzmEYuTTd5v:hover{color:#444}.Hrb9DTL_iWVzmEYuTTd5v+.Hrb9DTL_iWVzmEYuTTd5v{margin-left:5px}._1Odgui_-6qNCmwK3FO9mg4{color:#df1616}._1Odgui_-6qNCmwK3FO9mg4:hover{color:#444}._1Mfx7oMOppwmdPsrK56To0{color:#fff;opacity:.75;text-shadow:0 0 3px rgba(0,0,0,.7)}._1Mfx7oMOppwmdPsrK56To0:hover{opacity:1;color:#fff;text-shadow:0 0 5px #000}", ""]);

// exports
exports.locals = {
	"base": "Hrb9DTL_iWVzmEYuTTd5v",
	"severe": "_1Odgui_-6qNCmwK3FO9mg4",
	"white": "_1Mfx7oMOppwmdPsrK56To0"
};