import {List, Map} from 'immutable';
import {createSelector} from 'reselect';
import {createParamSelector} from 'modules/location/locationSelectors';
import {findImmutableFolder} from './folderUtils';
import {isDescendantOf} from 'utils/nestedSetUtils';
import {accountTypeIdSelector, userSelector} from 'modules/auth/authSelectors';
import {ECOMMERCE_TRIAL} from 'modules/accounts/accountConstants';
import {shallPass, ACCOUNT_OWNER} from 'modules/auth/gandalf';

export const draggingSelector = state => state.folders.get('dragging');

export const foldersSelector = state => state.folders.get('data') || List();

export const folderIdSelector = createParamSelector('folderId');

export const selectFolder = folderId =>
  createSelector(
    foldersSelector,
    folders => findImmutableFolder(folders, folderId) || Map()
  );

export const folderSelector = createSelector(
  foldersSelector,
  folderIdSelector,
  (folders, folderId) => findImmutableFolder(folders, folderId)
);

export const currentFolder = createSelector(
  folderSelector,
  folder => ({
    folder
  })
);

export const folderSidebarSelector = createSelector(
  foldersSelector,
  folders => {
    return {
      folders: folders.toJS()
    };
  }
);

const folderIdPropSelector = (state, props) => props.folderId;
const folderFromPropSelector = createSelector(
  foldersSelector,
  folderIdPropSelector,
  findImmutableFolder
);

// returns information needed by parts of the sidebar
// uses props.folderId of the connected component
const folderSidebarStateSelector = createSelector(
  folderFromPropSelector,
  folderSelector,
  (folder, activeFolder) => {
    if (!folder || !activeFolder) return {};

    const isActive = activeFolder.get('folderId') == folder.get('folderId');
    const isOpen = isActive || isDescendantOf(folder, activeFolder);

    return {isActive, isOpen};
  }
);

export const folderSidebarItemSelector = createSelector(
  folderSidebarStateSelector,
  folderSelector,
  draggingSelector,
  folderFromPropSelector,
  ({isActive, isOpen}, folder, userIsDragging, thisFolder) => {
    if (!folder) return {};

    return {
      isActive,
      isOpen,
      userIsDragging
    };
  }
);

export const sidebarFoldersSelector = createSelector(
  userSelector,
  folderSidebarStateSelector,
  (user, {isActive, isOpen}) => ({
    isOpen,
    isActive,
    showFolderSettings: shallPass(user, ACCOUNT_OWNER)
  })
);

export const editButtonSelector = createSelector(
  accountTypeIdSelector,
  accountType => ({
    shouldDisplay: accountType != ECOMMERCE_TRIAL
  })
);

export const addReportButtonSelector = createSelector(
  folderFromPropSelector,
  folder => ({
    allowed: !folder ? true : folder.get('type') === 'folder'
  })
);
