import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {SAVE_INTERACTION} from 'modules/interactions/constants';
import {interactionsEndpoint, interactionEndpoint} from 'modules/interactions/utils';
import {saveInteractionComplete, viewInteractions} from 'modules/interactions/actions';
import {reportIdParamSelector} from 'modules/reports/reportSelectors';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* saveInteractionListener() {
  yield takeLatest(SAVE_INTERACTION, saveInteractionSaga);
}

function* saveInteractionSaga({interaction}) {
  // the interaction has a property called `interaction` and `stages`...
  const {interactionId} = interaction.interaction;
  const reportId = yield select(reportIdParamSelector);

  // if !interactionId, we are creating a new interaction
  const url = !interactionId
    ? interactionsEndpoint({reportId})
    : interactionEndpoint({reportId, interactionId});

  yield processRequest(
    !interactionId ? 'POST' : 'PUT',
    url,
    {
      *success(data) {
        yield put(saveInteractionComplete(null));
        yield put(viewInteractions());
      },
      *error(err) {
        yield put(saveInteractionComplete(err));
      },
      errorMessage: couldNot('save interaction')
    },
    interaction
  );
}
