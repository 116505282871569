exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CeZbeOscJbqDx3lVhTqdg>*{padding-left:40px;padding-right:40px;border-top:1px solid #d6d6d6;border-radius:0 0 10px 10px}.CeZbeOscJbqDx3lVhTqdg>*:first-child{padding-top:30px;border-top:none}._3dYd9cKpaS_pxkfh6Vf8Ut{padding-top:30px;padding-bottom:30px}._3dYd9cKpaS_pxkfh6Vf8Ut>img{max-width:100%;margin:0 auto;display:block;border:1px solid #ddd}._3dYd9cKpaS_pxkfh6Vf8Ut p{margin:20px 0 0 0;text-align:center}._2N4XikvlrKE2-Ry5vKopNE{margin-top:280px;text-align:center}._2N4XikvlrKE2-Ry5vKopNE h4{font-size:24px}._2N4XikvlrKE2-Ry5vKopNE p{font-size:18px}", ""]);

// exports
exports.locals = {
	"content": "CeZbeOscJbqDx3lVhTqdg",
	"img": "_3dYd9cKpaS_pxkfh6Vf8Ut",
	"errorMessage": "_2N4XikvlrKE2-Ry5vKopNE"
};