exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Aw0b4DsOZBVDZlrqAY3OH{margin-left:4px}._3hSlGtucBuEl9du2-y2RXh{position:relative}", ""]);

// exports
exports.locals = {
	"flagOption": "_3Aw0b4DsOZBVDZlrqAY3OH",
	"flag": "_3hSlGtucBuEl9du2-y2RXh"
};