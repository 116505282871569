import React from 'react';
import cx from 'classnames';
import styles from './explorerGrid.scss';
import ExplorerDataRenderer from 'components/layoutComponents/explorer/explorerDataRenderer';

export default class ExplorerGridItem extends React.Component {
  render() {
    const {className, fields, record, ...props} = this.props;
    const itemClasses = cx(styles.item, {[className]: !!className});

    return (
      <ExplorerDataRenderer
        {...props}
        fields={fields}
        record={record}
        className={itemClasses}
        styles={styles}
        wrapperComponent="div"
        dataComponent="div"
      />
    );
  }
}
