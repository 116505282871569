import {DragSource} from 'react-dnd';
import {makeDNDDecorator, handlerMapToSource} from './dndDecorator';

export default function Draggable(typeName, handlerMap = dragHandlerMap) {
  const dragSource = DragSource(typeName, handlerMapToSource(handlerMap), collect);
  return makeDNDDecorator('connectDragSource', dragSource);
}

// source handler -> component handler
const dragHandlerMap = {
  beginDrag: 'handleBeginDrag',
  endDrag: 'handleEndDrag'
};

// This is a generic collect function for dragging
// I'm not sure why react-dnd doesnt default it to this
// returns props which get injected into the draggable component.
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isBeingDragged: monitor.isDragging()
  };
}
