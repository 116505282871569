import {takeLatest, put} from 'redux-saga/effects';
import {RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS, receiveQuoteWithPaymentOptions, handleReQuoteLoadError} from 'modules/quote/quoteActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {quoteEndpoint} from 'modules/quote/quoteUtils';

export default function* refetchQuoteWithPaymentOptionsSaga() {
  yield takeLatest([RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS], function*({quoteId, quoteKey, paymentOptions}) {
    yield processRequest('GET', quoteEndpoint(quoteId, quoteKey), {
      successAction: receiveQuoteWithPaymentOptions,
      error: function*(err) {
        const errorMsg = err.message || "Failed to fetch quote.";
        yield put(handleReQuoteLoadError(errorMsg));
      }
    }, paymentOptions);
  });
}