exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rT4wNpH1A4XI_s6i8IIBn{position:relative}._2rT4wNpH1A4XI_s6i8IIBn>*{top:0;left:0;position:absolute;z-index:2}._2rT4wNpH1A4XI_s6i8IIBn>*+*{display:none}._2rT4wNpH1A4XI_s6i8IIBn>:first-child{position:relative;z-index:1}", ""]);

// exports
exports.locals = {
	"transitionReplace": "_2rT4wNpH1A4XI_s6i8IIBn"
};