import React from 'react';
import styles from './term.scss';
import Icon from 'components/font-awesome';

class Term extends React.Component {
  render() {
    const {config} = this.props;

    if (typeof config === 'undefined') {
      return null;
    }

    return (
      <div className={styles.label}>
        <Icon name="exclamation-triangle" className={styles.icon} />
        {config.label}
      </div>
    );
  }
}

export default Term;
