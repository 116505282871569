import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './checkpointExplanation.scss';
import {interpolate, interpolateToJSX} from 'utils/interpolationUtils';

export default class CheckpointExplanation extends React.Component {
  static propTypes = {
    checkpoint: PropTypes.object.isRequired
  };

  getExplanation(text, checkpoint) {
    let html = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    html = interpolateToJSX(html, checkpoint);

    return (
      <div className={styles.section}>
        <p>{html}</p>
      </div>
    );
  }

  render() {
    const {checkpoint, expanded} = this.props;
    const {description} = checkpoint;

    return (
      <div className={cx(styles.explanation)}>
        <div className={cx('row', styles.content)}>
          <div className="col-md-12">{this.getExplanation(description, checkpoint)}</div>
        </div>
      </div>
    );
  }
}
