import {List} from 'immutable';
import {createSelector} from 'reselect';
import {reportSelector, reportsSelector, reportIdSelector} from 'modules/reports/reportSelectors';
import {foldersSelector} from 'modules/folders/folderSelectors';
import {featuresSelector} from 'modules/auth/authSelectors';
import {archiveOptionsSelector} from 'modules/archives/archiveSelectors';
import {isAccountDisabledSelector} from 'modules/accounts/accountDisabledSelector';
import {accountHelpUrlSelector} from 'modules/auth/authSelectors';
import {notificationsCountSelector} from 'modules/notifications/notificationSelectors';

export const appSelector = state => state.app;

export const appIsReady = createSelector(
  appSelector,
  app => {
    return app.get('ready');
  }
);

export const sidebarIsHiddenSelector = createSelector(
  appSelector,
  app => {
    return app.get('hideSidebar');
  }
);

export const accountMenuIsHiddenSelector = createSelector(
  appSelector,
  app => {
    return app.get('hideAccountMenu');
  }
);

export const fatalErrorSelector = createSelector(
  appSelector,
  app => {
    return app.get('fatalError');
  }
);

export const isSidebarHiddenObjectSelector = createSelector(
  sidebarIsHiddenSelector,
  accountMenuIsHiddenSelector,
  (isSidebarHidden, isAccountMenuHidden) => ({
    isSidebarHidden,
    isAccountMenuHidden,
    eitherSidebarActive: !isSidebarHidden || !isAccountMenuHidden
  })
);

const foldersBreadcrumbOptionsSelector = createSelector(
  foldersSelector,
  folders =>
    folders
      .map(folder => ({
        label: folder.get('name'),
        value: folder.get('folderId').toString()
      }))
      .toList()
      .unshift({
        value: 'all-websites',
        label: 'All websites'
      })
      // .unshift({
      //   value: 'add-folder',
      //   label: (
      //     <React.Fragment>
      //       <Icon name="plus" /> Add folder
      //     </React.Fragment>
      //   )
      // })
      .toJS()
);

const reportsBreadcrumbOptionsSelector = createSelector(
  reportsSelector,
  reports =>
    reports
      .filter(
        // TODO weird, the reports we get from fetchReports don't have a `reportType`, but they are 100% reportType 'report'
        report => report.get('reportType') !== 'dashboard'
      )
      .map(report => ({
        label: report.get('name'),
        value: report.get('reportId').toString()
      }))
      .toList()
      .unshift({value: 'all-reports', label: 'All Sites'})
      .toJS()
);

export const segmentsBreadcrumbOptionsSelector = createSelector(
  reportSelector,
  report => {
    const segments = report.get('segments', List());

    return segments
      .map(segment => ({label: segment.get('name'), value: segment.get('segmentId').toString()}))
      .toList()
      .toJS();
  }
);

// TODO move to breadcrumb selectors
export const secondaryNavigationSelector = createSelector(
  foldersBreadcrumbOptionsSelector,
  reportsBreadcrumbOptionsSelector,
  segmentsBreadcrumbOptionsSelector,
  archiveOptionsSelector,
  (folderOptions, reportOptions, segmentOptions, archiveOptions) => {
    return {
      folders: folderOptions,
      reports: reportOptions,
      segments: segmentOptions,
      archives: archiveOptions
    };
  }
);

export const mainNavigationSelector = createSelector(
  featuresSelector,
  reportIdSelector,
  isAccountDisabledSelector,
  accountHelpUrlSelector,
  notificationsCountSelector,
  ({features}, currentReportId, isAccountDisabled, helpUrl, notificationInfo) => {
    return {
      hasTrendsReport: features && features.get('TrendDashboardFlag'),
      hasLeaderboards: features && features.get('LeaderboardFeatureFlag'),
      hasAcademy: features && features.get('AcademyFeatureFlag'),
      currentReportId,
      isAccountDisabled,
      helpUrl,
      notificationInfo
    };
  }
);
