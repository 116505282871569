import Rollbar from 'rollbar-browser';
import {ROLLBAR, ENVIRONMENT} from 'config';

const rollbarConfig = {
  accessToken: ROLLBAR.KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: ENVIRONMENT,
    build: typeof window.SILKTIDE_BUILD !== "undefined" ? window.SILKTIDE_BUILD : "unknown"
  }
};

if (typeof window.SILKTIDE_VERSION !== "undefined") {
  rollbarConfig.client = {
    javascript: {
      code_version: window.SILKTIDE_VERSION
    }
  };
}

export default function setupRollbar() {
  if (ENVIRONMENT !== 'local') {
    Rollbar.init(rollbarConfig);
  }
}
