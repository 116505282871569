import {take, select, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {
  VIEW_TREND_SCREEN,
  CHANGE_DATE_RANGE,
  CHANGE_ACCURACY,
  trendSourcesEndpoint,
  receiveTrendSources,
  trendFiltersSelector
} from '..';

export default function* fetchTrendSourcesSaga() {
  yield takeLatest(VIEW_TREND_SCREEN, fetchTrendSources);
}

function* fetchTrendSources(action) {
  let {startTime, endTime, accuracy} = yield select(trendFiltersSelector);

  while (true) {
    yield processRequest(
      'GET',
      trendSourcesEndpoint(action),
      {
        successAction: ({results, startTime, endTime, accuracy}) =>
          receiveTrendSources(results, startTime, endTime, accuracy)
      },
      {startTime, endTime, accuracy}
    );

    // fetch again if date range or accuracy changes
    const changeAction = yield take([CHANGE_DATE_RANGE, CHANGE_ACCURACY]);

    startTime = changeAction.startTime || startTime;
    endTime = changeAction.endTime || endTime;
    accuracy = changeAction.accuracy || accuracy;
  }
}
