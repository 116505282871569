import {select, put, takeEvery} from 'redux-saga/effects';
import {TOGGLE_FILTER_PARAMS, setFilterParams} from 'modules/filters';
import {currentFilterSelector} from 'modules/filters/filterSelectors';

export default function*() {
  yield takeEvery(TOGGLE_FILTER_PARAMS, toggleFilterParams);
}

function* toggleFilterParams({param}) {
  const currentFilter = yield select(currentFilterSelector);
  const params = {
    [param]: !currentFilter[param]
  };

  yield put(setFilterParams(params));
}
