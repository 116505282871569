import {createSelector} from 'reselect';
import {geolocationSelector} from 'modules/geolocation/geolocationSelectors';
import {stepMap, PLAN, USER, ORGANISATION, PAYMENT} from './onboardingConstants';
import {productsSelector, taxSelector} from 'modules/products/productSelectors';
import {accountSelector, loggedInSelector} from 'modules/auth/authSelectors';

export const onboardingState = state => state.onboarding;

export const currentStepSelector = createSelector(
  onboardingState,
  state => {
    const currentStepIdx = state.get('currentStep');
    return {...stepMap[currentStepIdx], index: currentStepIdx};
  }
);

export const onboardingSelector = createSelector(
  onboardingState,
  currentStepSelector,
  (state, step) => {
    if (!step) return {};

    const {component} = step;

    return {
      component,
      formData: state.getIn(['stepData', step.index]),
      errorMessage: state.get('errorMessage', null),
      loading: state.get('loading')
    };
  }
);

export const currentStepIndexSelector = createSelector(
  onboardingState,
  state => state.get('currentStep')
);

export const onboardingDataSelector = createSelector(
  onboardingState,
  state => {
    return {
      plan: state.getIn(['stepData', PLAN]),
      user: state.getIn(['stepData', USER]),
      account: state.getIn(['stepData', ORGANISATION]),
      payment: state.getIn(['stepData', PAYMENT])
    };
  }
);

export const selectedPlanSelector = createSelector(
  onboardingState,
  productsSelector,
  taxSelector,
  (state, products, tax) => {
    const planId = state.getIn(['stepData', PLAN]);

    return {
      subscription: products.get(planId),
      tax
    };
  }
);

export const skipAccountStepSelector = createSelector(
  onboardingDataSelector,
  loggedInSelector,
  accountSelector,
  (formData, loggedIn, account) => {
    return !!(loggedIn && account && account.get('addressCountry')) || formData.account;
  }
);

export const plainParamsSelector = createSelector(
  onboardingState,
  onboarding => onboarding.get('params').toJS()
);

export const paymentPageSelector = createSelector(
  selectedPlanSelector,
  accountSelector,
  ({subscription, tax}, account) => {
    return {
      hasPaymentDetails: account && account.get('hasPaymentDetails'),
      subscription,
      tax
    };
  }
);

export const organisationPageSelector = createSelector(
  (state, props) => props.formData || {},
  geolocationSelector,
  (formDataProp, geolocation) => {
    // add geolocation to form data if we don't already know the country
    if (!formDataProp.addressCountry && geolocation) {
      formDataProp.addressCountry = geolocation;
    }
    return {formData: formDataProp};
  }
);
