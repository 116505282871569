exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2bpnrWdHnwu40oXqClxi2G{display:flex;flex-direction:column;background:#533be2}._9Uf0H1O08AY50r0zbAc7p{display:flex;flex-direction:column;background:#533be2;color:#fff;padding:20px 40px 40px 40px}._9Uf0H1O08AY50r0zbAc7p h2,._9Uf0H1O08AY50r0zbAc7p p{font-size:24px;line-height:30px;margin-top:20px;text-align:center}._9Uf0H1O08AY50r0zbAc7p h2{font-size:21px;line-height:30px;margin-top:40px;text-transform:uppercase}._9Uf0H1O08AY50r0zbAc7p p{font-weight:400}._9Uf0H1O08AY50r0zbAc7p .B2mj_tpjUKFHs-U8phDm5{font-size:28px;line-height:30px;margin:40px 0px 20px}._9Uf0H1O08AY50r0zbAc7p .MJ95JwM9r1mVpiCfofHHo{color:#fff;cursor:pointer;font-size:16px;font-weight:400;letter-spacing:.4px;margin-top:8px}._9Uf0H1O08AY50r0zbAc7p .MJ95JwM9r1mVpiCfofHHo:hover{text-decoration:underline}._9Uf0H1O08AY50r0zbAc7p ._1LviFhV68as_22YLms6OoM{background:#fff;color:#533be2;display:inline-block;font-size:16px;font-weight:600;height:60px;margin:16px auto;text-transform:uppercase;width:166px}._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG{list-style-type:none;padding:16px 80px;max-width:600px;margin:0 auto;font-size:20px}._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG span:first-child{background:#fff;font-size:20px;height:33px;min-width:33px;margin-right:16px;width:33px}._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG span:first-child::before{color:#533be2}@media(max-width: 767px){._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG{-webkit-column-count:1 !important;-moz-column-count:1 !important;column-count:1 !important;padding:16px 40px}._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG li{font-size:14px;padding:0px 16px}}@media(max-width: 499px){._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG{padding:16px 0px}._9Uf0H1O08AY50r0zbAc7p ._2bZme7H4NwQ24IjBD4BPzG li{line-height:24px;margin:10px 0px}}@media(max-width: 499px){._9Uf0H1O08AY50r0zbAc7p{padding:20px 16px 40px 16px}}", ""]);

// exports
exports.locals = {
	"pricingWrapper": "_2bpnrWdHnwu40oXqClxi2G",
	"contactSection": "_9Uf0H1O08AY50r0zbAc7p",
	"pageNumber": "B2mj_tpjUKFHs-U8phDm5",
	"showHelp": "MJ95JwM9r1mVpiCfofHHo",
	"contactButton": "_1LviFhV68as_22YLms6OoM",
	"list": "_2bZme7H4NwQ24IjBD4BPzG"
};