import cx from 'classnames';
import React from 'react';
import styles from './styles.scss';

class List extends React.Component {
  render() {
    const {data, options} = this.props;
    const {max, linebreak} = options;
    if (!data) return null;

    const className = cx(styles.list, {[styles.linebreak]: linebreak});
    return <span className={className}>{this.renderItems(data, max)}</span>;
  }

  renderItems(list, max) {
    if (max) {
      list = list.slice(0, max);
    }

    return list.map((label, index) => {
      return (
        <span className={styles.listItem} key={index}>
          {label}
        </span>
      );
    });
  }
}

List.propTypes = {};

export default List;
