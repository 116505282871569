import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {shallPass} from 'modules/auth/gandalf';
import {gandalfSelector} from 'modules/gandalf/gandalfSelectors';
import cx from 'classnames';

export class Gandalf extends React.Component {
  renderChildren() {
    const {children, className} = this.props;
    return renderChildren(children, className);
  }

  render() {
    const {negate, user, restrictSuperUsers} = this.props;

    // check user has access
    const shouldPass = shallPass(user, this.props.shallPass, restrictSuperUsers);
    if (negate ? !shouldPass : shouldPass) {
      return this.renderChildren();
    }

    return null;
  }
}

export function renderChildren(children, className) {
  if (React.Children.count(children) === 1 && !Array.isArray(children)) {
    if (!className) {
      return children;
    }

    // add the new className along with any existing ones
    return React.cloneElement(children, {
      className: cx(children.props.className, className)
    });
  }

  return <span>{children}</span>;
}

export default connect(gandalfSelector)(Gandalf);
