import React from 'react';
import styles from './column.scss';
import cx from 'classnames';

class Column extends React.Component {
  processWidth(width) {
    if (!width.match(/px|%/)) {
      return width + 'px';
    }
    return width;
  }

  render() {
    const {config, className} = this.props;
    const {center, middle, width, minWidth, maxWidth} = config;
    const style = {};

    if (width) {
      const colWidth = parseInt(width, 10);
      const percentage = (colWidth / 12) * 600;

      style.flex = `${colWidth} 1 ${percentage}px`;
    }
    if (minWidth) {
      style.minWidth = this.processWidth(minWidth);
    }
    if (maxWidth) {
      style.maxWidth = this.processWidth(maxWidth);
    }

    return (
      <div
        className={cx(className, styles.column, {
          [styles.center]: center,
          [styles.middle]: middle
        })}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Column;
