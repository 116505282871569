import createSagaMiddleware from 'redux-saga';
import rootSaga from 'modules/sagas';

const sagaMiddleware = createSagaMiddleware();

export default sagaMiddleware;

export function runSagas() {
  return sagaMiddleware.run(rootSaga);
}
