import {all, take, select, put, fork, call, takeLatest} from 'redux-saga/effects';
import {userSelector} from 'modules/auth/authSelectors';
import {shallPass, SITE_CREATOR} from 'modules/auth/gandalf';
import {REQUEST_ADD_REPORT} from 'modules/reports/reportConstants';
// import {hasOwnReports} from 'modules/reports/reportUtils';
// import {RECEIVE_FOLDER_RESULTS} from 'modules/folderViews';
import NewReportModal from 'modules/reports/components/modals/newReportModal';
import {showInModal, hideModal} from 'modules/modal';
import {trackEvent} from 'modules/tracking';
import ModalSpinner from 'components/spinner/modalSpinner';
import {
  hasRemainingProductsSelector,
  entitlementsLoadingSelector
} from 'modules/entitlements/entitlementSelectors';
import {RECEIVE_ENTITLEMENTS, ENTITLEMENTS_ERROR} from 'modules/entitlements';
import {showProducts} from 'modules/products';
import confirmModal from 'utils/saga/confirm';

export default function* showNewReportModalSaga() {
  yield all([fork(showOnTrigger)]);
}

function* showOnTrigger() {
  yield takeLatest(REQUEST_ADD_REPORT, showNewReportModal);
}

// // Show the new report modal on login if the user doesn't have any reports
// function* showOnFirstLoad() {
//   const {reports, folderId} = yield take(RECEIVE_FOLDER_RESULTS);

//   if (!hasOwnReports(reports) && folderId == '1') {
//     yield* showNewReportModal({folderId: 1}, false);
//   }
// }

function* showNewReportModal({folderId}, showLoading = true) {
  const user = yield select(userSelector);

  // gandalf
  if (!shallPass(user, SITE_CREATOR)) {
    return;
  }

  // if entitlements haven't finished loading yet, wait for them
  if (yield select(entitlementsLoadingSelector)) {
    if (showLoading) {
      yield put(showInModal(ModalSpinner, {}));
    }
    yield take([RECEIVE_ENTITLEMENTS, ENTITLEMENTS_ERROR]);
    if (showLoading) {
      yield put(hideModal());
    }
  }

  // we need entitlements to show products in the modal
  const hasEntitlements = yield select(hasRemainingProductsSelector);

  // if no entitlements, trigger the buy modal instead.
  if (!hasEntitlements) {
    const didConfirm = yield call(confirmModal, {
      title: "You don't have any websites remaining",
      content:
        "You've used up all the websites in your current subscriptions. To test another website you'll need to add a new subscription.",
      submitLabel: 'Add a new subscription'
    });

    if (didConfirm) {
      yield put(showProducts());
    }
  } else {
    yield put(showInModal(NewReportModal, {name: 'newReportModal', folderId}));
    yield call(trackEvent, 'View new report modal');
  }
}
