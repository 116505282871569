import {createSelector} from 'reselect';
import {loggedInSelector, accountSelector, plainUserSelector} from 'modules/auth/authSelectors';

export const quoteSelector = state => state.quote;

export const quoteDataSelector = createSelector(
  quoteSelector,
  (state) => {
    return state.get('data');
  }
)

export const quoteViewPageSelector = createSelector(
  quoteSelector,
  (state) => {
    return {
      isLoading: state.get('isLoading'),
      loadingError: state.get('loadingError'),
      quote: state.get('data').toJS(),
      isRecalculating: state.get('reQuoteLoading'),
      recalcError: state.get('reQuoteLoadingError')
    }
  }
)

export const dataCollectorSelector = createSelector(
  loggedInSelector,
  accountSelector,
  plainUserSelector,
  (isLoggedIn, account, {user}) => {
    return {
      loggedInAccount: isLoggedIn && account ? account.toJS() : null,
      loggedInUser: user
    }
  }
)