import React from 'react';
import styles from '../timeline.scss';
import PagePointItem from '../storyPoints/pagePointItem';

export default function RemovedPage({story, storyPoints}) {
  // const [items, setItems] = useState(Object.keys(storyPoints));

  return (
    <React.Fragment>
      <ul className={styles.pagesList}>
        {Object.keys(storyPoints).map(pointId => (
          <PagePointItem key={pointId} {...storyPoints[pointId]} removed />
        ))}
      </ul>
    </React.Fragment>
    // <ShowMoreButton items={Object.keys(storyPoints)} setItems={item => setItems(item)} />
  );
}
