import {take, call, put, cancel, fork, select} from 'redux-saga/effects';
import {SWITCH_ACCOUNT, LOGOUT} from 'modules/auth';
import {VIEW_FOLDERS_INDEX_PAGE, VIEW_FOLDERS_PAGE} from 'modules/folders';
import {ORDER_COMPLETE} from 'modules/products';
import {fetchFoldersIfNeeded} from './helpers/fetchFoldersIfNeeded';

export default function* fetchFoldersIfNeededSaga() {
  while (true) {
    const action = yield fork(fetchFolders);

    // Folders change in some cases. Restart.
    yield take([SWITCH_ACCOUNT, LOGOUT, ORDER_COMPLETE]);
    yield cancel(action);
  }
}

function* fetchFolders() {
  while (true) {
    yield take([VIEW_FOLDERS_INDEX_PAGE, VIEW_FOLDERS_PAGE]);
    yield call(fetchFoldersIfNeeded);
  }
}
