import * as Rules from './Rules';

export default function onValidate(value, rules, customMessages) {
  const ruleOrder = Object.keys(Rules);

  // for each rule in order
  for (let i = 0; i < ruleOrder.length; i++) {
    const ruleName = ruleOrder[i]; // required or minLength, etc

    // if rule has been specified
    if (ruleName in rules) {
      const ruleArg = rules[ruleName];
      const warnings = getRuleWarnings(ruleArg, ruleName);

      if (warnings.length > 0) {
        console.warn(warnings.join(' '));
        continue;
      }

      const {passes, output} = Rules[ruleName];

      // and if rule test fails
      if (!passes(value, ruleArg)) {
        // return error message for failing rule
        return output(ruleArg, value, customMessages[ruleName]);
      }
    }

    // else continue until all rules have been tested
  }

  // all rules passed, no error message is returned
  return null;
}

function getRuleWarnings(ruleArg, ruleName) {
  const warnings = [];

  if (ruleArg === undefined) {
    warnings.push(
      `Unexpected \`undefined\` passed as a rule property to Field. E.G. <FieldComponent ${ruleName}={undefined} />.`
    );
  }

  const rule = Rules[ruleName];
  if (!rule || typeof rule.passes !== 'function' || typeof rule.output !== 'function') {
    warnings.push(
      `Unexpected rule "${ruleName}" should exist and should contain \`passes\` and \`output\` functions.`
    );
  }

  return warnings;
}
