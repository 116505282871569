exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2j2m5LIcALUx72UBn8ing6{position:fixed;width:100%;bottom:50px;left:50%;z-index:9000;margin-left:-250px}", ""]);

// exports
exports.locals = {
	"userAchievement": "_2j2m5LIcALUx72UBn8ing6"
};