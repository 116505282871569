import React from 'react';
import styles from './explainer.scss';
import Flex from 'components/layout/flex';
import {interpolateToJSX, choosePluralVersion} from 'utils/interpolationUtils';

export default class Explainer extends React.Component {
  renderBit = (bitOfExplanation, idx) => {
    if (Array.isArray(bitOfExplanation)) {
      return <span key={idx} className={styles.spacer} />;
    }

    const {context, singular, plural} = bitOfExplanation;

    return (
      <span key={idx}>
        {interpolateToJSX(choosePluralVersion(context.number, singular, plural) + ' ', context)}
      </span>
    );
  };

  render() {
    return null;

    const {result} = this.props;

    if (!result || !result.length) {
      return null;
    }

    return (
      <Flex grow={1}>
        <div className={styles.explainer}>{result.map(this.renderBit)}</div>
      </Flex>
    );
  }
}
