exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1R6gleofnlEUiFiyKW6XG-{margin:0 -8px;background:#d0e0ff;box-shadow:inset 0 5px 10px rgba(0,0,0,.2);color:#002040;padding:14px;text-align:center;transition:all .2s;display:flex;justify-content:center;flex-wrap:wrap}._1R6gleofnlEUiFiyKW6XG- p{margin:0}._1R6gleofnlEUiFiyKW6XG-._2YxADrRXeL0WmFdE2xyaIB,._1R6gleofnlEUiFiyKW6XG- ._2YxADrRXeL0WmFdE2xyaIB{cursor:pointer;text-decoration:underline}._1R6gleofnlEUiFiyKW6XG->span{margin:0 4px}._1R6gleofnlEUiFiyKW6XG-:not(.USEqNl6krQKduE4oCytWQ):hover{background:#c0f0f0}._1R6gleofnlEUiFiyKW6XG-._1aoAgcH49ouVRbbc1KIF8P{align-items:center}._1R6gleofnlEUiFiyKW6XG- ._1fgaviQwiGhJQ8PzyT0lgq{text-decoration:underline}@media print{._1R6gleofnlEUiFiyKW6XG-{display:none}}._2IxYltTrhyai85GvN61KOR:hover{background:#000;cursor:pointer}", ""]);

// exports
exports.locals = {
	"banner": "_1R6gleofnlEUiFiyKW6XG-",
	"clickable": "_2YxADrRXeL0WmFdE2xyaIB",
	"noHover": "USEqNl6krQKduE4oCytWQ",
	"middle": "_1aoAgcH49ouVRbbc1KIF8P",
	"underline": "_1fgaviQwiGhJQ8PzyT0lgq",
	"close": "_2IxYltTrhyai85GvN61KOR"
};