import React from 'react';
import Score from 'components/score';
import Icon from 'components/font-awesome/icon';

export default function ScoreCell({data}) {
  if (typeof data === 'undefined') return null;

  if (data === null) {
    return <Icon name="exclamation-triangle" style={{color: 'red'}} />;
  }

  return (
    <div>
      <Score value={data} small />
    </div>
  );
}
