import {Map} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

const initialState = Map({
  accountTypes: Map()
});

export default function accountTypeReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT_TYPES:
      return state.mergeIn(['accountTypes'], keyBy(action.accountTypes, 'accountTypeId'));
  }

  return state;
}

export const RECEIVE_ACCOUNT_TYPES = 'accountTypes:RECEIVE_ACCOUNT_TYPES';
export const receiveAccountTypes = accountTypes => ({
  type: RECEIVE_ACCOUNT_TYPES,
  accountTypes
});

export const FETCH_ACCOUNT_TYPES = 'accountTypes:FETCH_ACCOUNT_TYPES';
export const fetchAccountTypes = accountTypes => ({
  type: FETCH_ACCOUNT_TYPES,
  accountTypes
});
