import React from 'react';
import errorCodes from 'constants/errorCodes';
import {interpolate} from 'utils/interpolationUtils';

export default function getErrorMessage(errorCode = '', context = {}, defaultMessage) {
  const message = errorCodes[errorCode];

  if (typeof message == 'undefined') {
    if (__DEV__) {
      console.error('Error code "' + errorCode + '" does not exist');
    }
    return defaultMessage || 'Something went wrong';
  }
  if (typeof message == 'function' || message instanceof React.Component) {
    const Message = message;
    return <Message context={context} />;
  }

  if (typeof message == 'string') {
    return interpolate(message, context);
  }

  return message;
}
