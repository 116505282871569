import React from 'react';
import UserImage from 'components/user/userImage';

export default class NotificationImage extends React.Component {
  static defaultProps = {
    height: 50
  };

  render() {
    const {image, height} = this.props;

    if (image.startsWith('user:')) {
      return <UserImage userId={image.replace('user:', '')} size={height} />;
    }

    return <img src={image} height={height} />;
  }
}
