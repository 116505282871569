import PropTypes from 'prop-types';
import React from 'react';
import TickSelector from 'components/checkboxes/tickSelector';

export default class BooleanInput extends React.Component {
  static defaultProps = {
    value: false,
    description: ''
  };

  static propTypes = {
    value: PropTypes.bool,
    description: PropTypes.string
  };

  // componentWillMount() {
  //   const {onChange} = this.props;
  //   onChange && onChange(this.props.value);
  // }

  handleClick = value => event => {
    const {onChange} = this.props;
    onChange && onChange(value);
  };

  render() {
    const {value, description} = this.props;

    return (
      <TickSelector selected={value} onClick={this.handleClick(!value)}>
        {description}
      </TickSelector>
    );
  }
}
