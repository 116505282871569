import React from 'react';
import UrlComponent from 'components/url/url';
import cx from 'classnames';
import styles from './styles.scss';
import ControlLink from 'components/control/controlLink';
import {connect} from 'react-redux';
import {locationSelector} from 'modules/location/locationSelectors';

class Url extends React.Component {
  render() {
    const {className, data: url, options, record, filters, params} = this.props;
    const {link, instruction} = options;

    if (instruction) {
      return (
        <ControlLink
          className={className}
          instruction={instruction}
          context={{...params, ...record}}
          filters={filters}
        >
          {url}
        </ControlLink>
      );
    }

    return url ? (
      <UrlComponent
        className={cx(styles.url, styles.link)}
        url={url}
        link={options.external ? url : link}
      />
    ) : null;
  }
}

export default connect(locationSelector)(Url);
