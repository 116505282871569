import React from 'react';
import styles from './copyright.scss';
import ExternalLink from 'modules/location/externalLink';

export default function Copyright() {
  return (
    <div className={styles.copyright}>
      <p>
        Brought to you by <ExternalLink href="://silktide.com">Silktide Ltd</ExternalLink>.
      </p>
      <p>Silktide is registered in England and Wales. Company no: 4242422. VAT no: 772 526320.</p>
    </div>
  );
}
