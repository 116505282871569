import React from 'react';
import PropTypes from 'prop-types';
import styles from './searchTermsInput.scss';
import Input from 'components/input';
import Button from 'components/button';
import {DeleteIcon} from 'components/icons';
import {escapeRegex} from 'utils/stringUtils';

export default class SearchTermsInput extends React.Component {
  static defaultProps = {
    value: ''
  };

  static propTypes = {
    value: PropTypes.string
  };

  state = {
    newSearchTermText: ''
  };

  onChange = searchTerms => {
    this.props.onChange(searchTerms.join('\n'));
  };

  renderSearchTerm = (searchTerm, idx) => {
    return (
      <div className={styles.inputRow} key={'search_term_' + idx}>
        <div style={{width: '100%', marginRight: '10px'}}>
          <Input value={searchTerm} disabled />
        </div>

        <div className={styles.closeIcon}>
          <DeleteIcon onClick={this.handleRemoveSearchTerm(idx)} />
        </div>
      </div>
    );
  };

  renderNewSearchTerm = () => {
    const {placeholder} = this.props;
    const {newSearchTermText} = this.state;

    const searchTerms = this.getSearchTermsAsArray();

    return (
      <div className={styles.inputRow}>
        <div style={{width: '100%', marginRight: '10px'}}>
          <Input
            value={newSearchTermText}
            onChange={(value, item) => {
              this.setState({newSearchTermText: value.target.value});
            }}
            autoComplete="new-password"
            placeholder={placeholder}
          />
        </div>

        <Button icon="plus" iconSize="lg" iconOnly onClick={this.handleClickAddSearchTerm} />
      </div>
    );
  };

  handleChange = (idx, value) => {
    const searchTerms = this.getSearchTermsAsArray();
    searchTerms[idx] = value;
    this.onChange(searchTerms);
  };

  handleClickAddSearchTerm = () => {
    const {newSearchTermText} = this.state;

    const searchTerms = this.getSearchTermsAsArray();

    searchTerms.push(newSearchTermText);

    this.onChange(searchTerms);

    this.setState({isAdding: false, newSearchTermText: ''});
  };

  handleRemoveSearchTerm = idx => event => {
    const searchTerms = this.getSearchTermsAsArray();

    searchTerms.splice(idx, 1);
    this.onChange(searchTerms);
  };

  getSearchTermsAsArray() {
    const {value, separateBy} = this.props;

    return value.split(new RegExp(separateBy.map(escapeRegex).join('|'))).filter(Boolean);
  }

  render() {
    const searchTerms = this.getSearchTermsAsArray();

    return (
      <div>
        {searchTerms.map(this.renderSearchTerm)}

        {this.renderNewSearchTerm()}
      </div>
    );
  }
}
