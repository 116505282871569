import React from 'react';
import Count from './count.js';

class CopyCount extends React.Component {
  render() {
    const options = this.props.options;
    options.appearance = this.props.data <= 1 ? 'good' : 'bad';

    return <Count {...this.props} options={options} />;
  }
}

export default CopyCount;
