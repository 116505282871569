import _ from 'lodash';
import cx from 'classnames';
import {connect} from 'react-redux';
import Checkpoint from './checkpoint';
import styles from './checkpoints.scss';
import React from 'react';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import CheckpointsHeader from './checkpointsHeader';
import CheckpointsFooter from './checkpointsFooter';
import {ignoreCheckpoint, changeCheckpointTarget} from 'modules/checkpoints';
import {reportObjectSelector} from 'modules/reports/reportSelectors';

export class Checkpoints extends React.Component {
  constructor(props) {
    super(props);
    this.lastUpdatedCheckpointId = null;
    this.alreadyScrolled = false;

    this.state = {
      expandedTestId: null,
      expandedCheckpointId: null,
      priorities: _.get(props, 'config.priorities'),
      expandPriorities: false
    };
  }

  onExplainCheckpoint = (testId, checkpointId) => {
    if (this.state.expandedTestId == testId && this.state.expandedCheckpointId == checkpointId) {
      this.setState({expandedTestId: null, expandedCheckpointId: null});
      return;
    }

    this.setState({
      expandedTestId: testId,
      expandedCheckpointId: checkpointId
    });
  };

  onViewDetail = ({...checkpoint}) => {
    this.props.push(testPath, {...checkpoint}, ['reportId']);
  };

  onUpdateCheckpoint = checkpointId => {
    this.lastUpdatedCheckpointId = checkpointId;
    this.alreadyScrolled = false;

    clearTimeout(this.timeout);

    this.timeout = _.delay(() => {
      this.lastUpdatedCheckpointId = null;
    }, 10000);
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timeout);
  }

  componentDidUpdate() {
    if (!this.mounted) return;

    if (_.isNull(this.lastUpdatedCheckpointId) || this.alreadyScrolled === true) return;
    const domRef = this.refs[this.lastUpdatedCheckpointId];
    if (domRef) {
      const domNode = domRef;
      window.scrollTo(0, domNode.offsetTop);
      this.alreadyScrolled = true;
    }
  }

  sortResults(result) {
    const results = {Outstanding: [], Done: [], Ignored: []};

    _.each(result, checkpoint => {
      if (checkpoint.isIgnored) {
        results.Ignored.push(checkpoint);
      } else if (checkpoint.isDone) {
        results.Done.push(checkpoint);
      } else {
        results.Outstanding.push(checkpoint);
      }
    });

    results.Outstanding = _.orderBy(results.Outstanding, ['severity', 'score'], ['desc', 'asc']);

    return results;
  }

  handleSetExpanded = isExpanded => {
    this.setState({expandPriorities: isExpanded});
  };

  renderCheckpoint = (checkpoint, count) => {
    const {expandedTestId, expandedCheckpointId} = this.state;
    const {ignoreCheckpoint, changeCheckpointTarget, config, report} = this.props;
    const {expandable} = config;

    return (
      <Checkpoint
        ignoreCheckpoint={ignoreCheckpoint}
        changeCheckpointTarget={changeCheckpointTarget}
        key={checkpoint.testId + '_' + checkpoint.checkpointId}
        ref={checkpoint.checkpointId}
        lastUpdatedCheckpointId={this.lastUpdatedCheckpointId}
        onUpdateCheckpoint={this.onUpdateCheckpoint}
        onExplainCheckpoint={this.onExplainCheckpoint}
        onViewDetail={this.onViewDetail}
        checkpoint={checkpoint}
        expandable={!!expandable}
        expanded={
          !expandable ||
          (expandedCheckpointId == checkpoint.checkpointId && expandedTestId == checkpoint.testId)
        }
        report={report}
      />
    );
  };

  renderCheckpointsForType(type, sortedResults) {
    const results = sortedResults[type];

    if (results.length === 0) return;

    return (
      <span>
        <CheckpointsHeader label={type} count={results.length} />
        <div className={styles.panel}>{results.map(this.renderCheckpoint)}</div>
      </span>
    );
  }

  renderPriorities(sortedResults, priorities) {
    const results = sortedResults.Outstanding;

    if (results.length === 0) return;

    const count = 0;
    return (
      <span>
        <CheckpointsHeader label="Top priorities" count={Math.min(priorities, results.length)} />
        <div className={styles.panel}>
          {results.slice(0, priorities).map(this.renderCheckpoint)}
        </div>
      </span>
    );
  }

  renderCheckpointsFooter() {
    const {priorities, expandPriorities} = this.state;

    if (!priorities) return;

    return (
      <CheckpointsFooter isExpanded={expandPriorities} onSetExpanded={this.handleSetExpanded} />
    );
  }

  render() {
    const {result, config} = this.props;
    const {priorities, expandPriorities} = this.state;
    const sortedResults = this.sortResults(result);

    if (result.length === 0) {
      return (
        <div className={cx(styles.panel, styles.empty)}>
          <span className="lead">No checkpoints at this time</span>
        </div>
      );
    }

    if (priorities && !expandPriorities) {
      return (
        <div className={styles.checkpoints}>
          {this.renderPriorities(sortedResults, priorities)}
          {this.renderCheckpointsFooter()}
        </div>
      );
    }

    return (
      <div className={styles.checkpoints}>
        {this.renderCheckpointsForType('Outstanding', sortedResults)}
        {this.renderCheckpointsForType('Done', sortedResults)}
        {this.renderCheckpointsForType('Ignored', sortedResults)}
        {this.renderCheckpointsFooter()}
      </div>
    );
  }
}

export default connect(
  reportObjectSelector,
  {
    push,
    ignoreCheckpoint,
    changeCheckpointTarget
  }
)(Checkpoints);
