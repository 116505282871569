import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {lowercaseFirstLetter} from 'utils/stringUtils';
import CircularSpinner from 'components/spinner/circularSpinner';
import {connect} from 'react-redux';
import {layoutComponentSelector} from 'modules/tests/layoutComponentSelectors';
import {havePropsChanged} from 'utils/propUtils';
import Alert from 'components/alert/alert';

// Require context for layoutComponents (allows us to use subdirs)
const req = require.context('components/layoutComponents', true, /.*\.js$/);

function getComponentByType(type) {
  if (!type) return null;

  type = lowercaseFirstLetter(type);

  try {
    return req('./' + type + '.js');
  } catch (e) {
    return req('./' + type + '/index.js');
  }
}

class LayoutComponent extends React.Component {
  static childContextTypes = {
    filter: PropTypes.object,
    params: PropTypes.object
  };

  state = {error: null};

  componentDidCatch(error, info) {
    this.setState({error: {error, info}});
  }

  shouldComponentUpdate = nextProps => {
    return havePropsChanged(this.props, nextProps, [
      'shouldBeShown',
      'isLoading',
      'error',
      'filter',
      'result',
      'config',
      'params'
    ]);
  };

  getChildContext() {
    return {
      filter: this.props.filter,
      params: this.props.params
    };
  }

  componentWillMount() {
    this.loadComponent(this.props.type);
  }

  componentWillReceiveProps({type}) {
    if (type !== this.props.type) {
      this.loadComponent(type);
    }
  }

  loadComponent(type) {
    try {
      const componentImport = getComponentByType(type);
      this.Component =
        componentImport && componentImport.default ? componentImport.default : componentImport;
    } catch (e) {
      if (e && e.code === 'MODULE_NOT_FOUND') {
        // If we don't define this component, fall back to a native HTML component (e.g. div, h1, etc).
        this.Component = req('./nativeComponent/nativeComponent.js');
      } else {
        console.error(e);
      }
    }

    // render only happens on state/props change but we don't wanna store a component in either of those.
    this.forceUpdate();
  }

  renderChildren() {
    const {config, ...props} = this.props;
    const {children} = config;

    if (!children) return;

    return _.map(children, childConfig => {
      return (
        <LayoutComponentContainer {...props} config={childConfig} key={childConfig.componentId} />
      );
    }).filter(Boolean);
  }

  render() {
    const {error} = this.state;
    if (error) {
      return (
        <div className="alert alert-danger">
          <h1>Unable to render {this.props.type || ''} layout component</h1>
          <pre>{error.error.stack}</pre>
        </div>
      );
    }

    const {Component} = this;

    const {
      config,
      shouldBeShown,
      isLoading,
      result,
      params,
      error: resultError,
      className,
      setFilterParams,
      filter,
      viewingContext,
      isArchiveActive,
      isTeaser
    } = this.props;

    if (resultError) {
      if (__DEV__) {
        return (
          <Alert icon="exclamation-triangle" level="danger">
            <b>ResultError[{config.type}]:</b> {resultError}
          </Alert>
        );
      } else {
        console.error('Layout component result error: ', resultError);
        return null;
      }
    }

    if (!Component) {
      console.error('Failed to load layoutComponent', config);
      return null;
    }

    if (!Component.handlesLoading && isLoading) {
      return <CircularSpinner marginTop={60} marginBottom={60} />;
    }

    if (!shouldBeShown) return null;

    const {nobrowserprint} = this.props.config;
    const noBrowserPrint = nobrowserprint === ''; // TODO add support for === 'monitor' || 'snapshot' etc
    const classes = cx({['noPrint']: noBrowserPrint}, className);

    return (
      <Component
        className={classes}
        params={params}
        isLoading={isLoading}
        result={result}
        key={'component-' + config.componentId}
        config={config}
        setFilterParams={isArchiveActive ? () => {} : setFilterParams}
        filter={filter}
        viewingContext={viewingContext}
        isTeaser={isTeaser}
      >
        {this.renderChildren()}
      </Component>
    );
  }
}

const LayoutComponentContainer = connect(layoutComponentSelector)(LayoutComponent);
export default LayoutComponentContainer;
