import React from 'react';
import styles from './styles.scss';
import checked from './checked.png';
import Button from 'components/button';
import {toFixed} from 'utils/numberUtils.js';

class ImageDownloadable extends React.Component {
  constructor() {
    super();
    this.state = {errored: false};
  }

  handleError = () => {
    this.setState({errored: true});
  };

  renderBytesBlock = bytes => {
    let text = '';

    if (bytes > 1024 * 1024) {
      text = toFixed(bytes / (1024 * 1024), 1) + ' MB';
    } else if (bytes > 1024) {
      text = toFixed(bytes / 1024, 0) + ' KB';
    } else if (bytes !== 'undefined') {
      text = bytes + ' bytes';
    }
    // this is as dark grey as we can go without breaking accessibility guidelines.
    return <div style={{textAlign: 'center', color: '#525252'}}>{text}</div>;
  };

  renderErrorMessage = () => {
    const {options} = this.props;
    return <div>{(options && options.empty) || 'Image unavailable'}</div>;
  };

  onClickDownload = e => {
    e.stopPropagation();

    const {record, options} = this.props;
    const downloadlink = record[options.downloadfield];

    window.open(downloadlink, '_blank');
  };

  renderDownloadLink = downloadlink => {
    if (downloadlink === 'undefined') {
      return null;
    }

    return (
      <Button
        iconOnly
        icon="download"
        tiny
        tooltip="Download optimized image"
        onClick={this.onClickDownload}
      />
    );
  };

  render() {
    const {data, record, options} = this.props;
    const {failmessage, downloadfield, bytesfield} = options;

    const bytes = record[bytesfield];
    const downloadlink = record[downloadfield];

    if (!data) {
      if (failmessage) {
        return <div style={{opacity: 0.5}}>{failmessage}</div>;
      }
      return null;
    }

    if (this.state.errored) {
      return this.renderErrorMessage();
    }

    const style = {background: '#fff url(' + checked + ') repeat'};
    return (
      <div style={{verticalAlign: top}}>
        <div style={style}>
          <img className={styles.img} src={data} onError={this.handleError} />
        </div>

        <div
          style={{display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '5px'}}
        >
          {bytes && this.renderBytesBlock(bytes)}
          {downloadlink && this.renderDownloadLink(downloadlink)}
        </div>
      </div>
    );
  }
}

export default ImageDownloadable;
