import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import ModalFooter from 'modules/modal/components/modalFooter';
import {contactUs, upgradeAccount} from 'modules/app/appUtils';
import Button from 'components/button';
import styles from './upgradeModal.scss';
import config from 'config';

const upgradeImage = require('./Insites-Upgrade.png');

export default class UpgradeModal extends React.Component {
  handleClickPlans = () => {
    upgradeAccount();
  };

  handleClickContact = () => {
    contactUs();
  };

  renderFeatures = () => {
    return (
      <div>
        <p>Upgrade to a paid plan to unlock:</p>
        <ul>
          <li>Test all your websites</li>
          <li>Over 30 tests</li>
          <li>Scheduled reports</li>
          <li>Users and permisisons</li>
          <li>Email alerts</li>
          <li>Mobile testing</li>
          <li>
            And <ExternalLink href={config.WEBSITE}>much more</ExternalLink>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const footer = (
      <div>
        <Button cta className={styles.plansButton} onClick={this.handleClickPlans}>
          View plans
        </Button>
        <Button onClick={this.handleClickContact}>Contact us</Button>
      </div>
    );

    return (
      <ModalContent title="Upgrade now" footer={footer}>
        {this.renderFeatures()}
        <img className={styles.image} src={upgradeImage} />
      </ModalContent>
    );
  }
}
