import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import Dropdown, {DropdownOption} from 'components/dropdown';
import {navigateToCategory} from 'modules/inspector/inspectorActions';
import {inspectorTopLevelCategoriesSelector} from 'modules/inspector/inspectorSelectors';
import styles from './inspectorStyle.scss';
import Score from 'components/score/score';
import Flex from 'components/layout/flex';

function InspectorTopLevelCategories({
  topLevelCategories,
  categoryId,
  isLoading,
  navigateToCategory
}) {
  const dropdownRef = React.useRef(null);

  if (isLoading) return null;

  const activeCategory = topLevelCategories.find(
    category => category.get('categoryId') === categoryId
  );

  const button = (
    <Button plain icon="caret-down" iconSide="right" className={styles.topLevelCategoryButton}>
      {!activeCategory ? (
        'Whole page'
      ) : (
        <Fragment>
          {activeCategory.get('name')}{' '}
          {activeCategory.has('score') && (
            <Score small inline value={activeCategory.get('score')} />
          )}{' '}
        </Fragment>
      )}
    </Button>
  );

  return (
    <div className={styles.topLevelCategories}>
      <Dropdown
        ref={dropdownRef}
        button={button}
        className={styles.topLevelCategoryToggle}
        bodyClassName={styles.topLevelCategoryDropdown}
      >
        <DropdownOption
          key={'whole-page'}
          value={''}
          onClick={() => {
            dropdownRef.current.close();
            navigateToCategory('');
          }}
        >
          Whole page
        </DropdownOption>
        {topLevelCategories.map(category => {
          return (
            <DropdownOption
              key={category.get('categoryId')}
              value={category.get('categoryId')}
              onClick={() => {
                dropdownRef.current.close();
                navigateToCategory(category.get('categoryId'));
              }}
            >
              <Flex container>
                <Flex grow={1}>{category.get('name')}</Flex>
                {category.has('score') && (
                  <Flex grow={0} basis="60px">
                    <Score small inline value={category.get('score')} />
                  </Flex>
                )}
              </Flex>
            </DropdownOption>
          );
        })}
      </Dropdown>
    </div>
  );
}

export default connect(
  inspectorTopLevelCategoriesSelector,
  dispatch => ({
    navigateToCategory: function(categoryId) {
      dispatch(navigateToCategory(categoryId));
    }
  })
)(InspectorTopLevelCategories);
