import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import ReactPlayer from 'react-player';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {parseQuery} from 'utils/urlUtils';

export class VideoPlayerModal extends React.Component {
  renderVideo() {
    const {
      videoUrl,
      screenSizes: {largeMobile, tablet}
    } = this.props;
    const isDesktop = !largeMobile && !tablet;
    const height = largeMobile ? '175px' : tablet ? '360px' : '420px';
    const style = {
      maxWidth: isDesktop ? 'auto' : '100%',
      minWidth: isDesktop ? '100%' : 'auto'
    };
    // If using Wistia the URL should be like (note the lack of medias/):
    //  https://fast.wistia.com/embed/7r63rwfz8h
    // instead of
    //  https://fast.wistia.com/embed/medias/7r63rwfz8h1
    return <ReactPlayer url={videoUrl} controls style={style} height={height} />;
  }

  getVideoIdFromUrl(url) {
    const questionPos = url.indexOf('?');
    const queryString = url.substr(questionPos);
    const qs = parseQuery(queryString);
    return qs.v || url;
  }

  render() {
    const {videoTitle} = this.props;

    return <ModalContent title={videoTitle || 'View video'}>{this.renderVideo()}</ModalContent>;
  }
}

export default connectContext(ScreenSizeContext, 'screenSizes')(VideoPlayerModal);
