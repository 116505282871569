import React from 'react';

import HomePageSection from './homePageSection';

export default class HelpCta extends React.Component {
  render() {
    return (
      <HomePageSection title="Need help?">
        <p>
          You can chat to us at any time by clicking the icon in the bottom right corner of every
          screen.
        </p>
      </HomePageSection>
    );
  }
}
