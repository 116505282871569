import React from 'react';
import {connect} from 'react-redux';
import {triggerInviteUsers} from 'modules/users';
import Button from 'components/button';

export class InviteUsersButton extends React.Component {
  handleClick = () => {
    this.props.triggerInviteUsers();
  };

  render() {
    return (
      <Button icon="plus" onClick={this.handleClick} className={this.props.className}>
        {this.props.children || 'Invite users'}
      </Button>
    );
  }
}

export default connect(
  null,
  {triggerInviteUsers}
)(InviteUsersButton);
