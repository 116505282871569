import React from 'react';
import styles from './countryFlag.scss';
import countryCodes from './countryCodes';
import languageCodes from './languageCodes';
import cx from 'classnames';

class CountryFlag extends React.Component {
  getCountry(code) {
    const countryCode = code.toUpperCase();
    return countryCodes[countryCode] || countryCode;
  }

  getLanguage(code) {
    const langCode = code.toLowerCase();
    return languageCodes[langCode] || langCode;
  }

  getImage(code) {
    try {
      return require(`./icons/${code.toLowerCase()}.png`);
    } catch (err) {
      return require(`./icons/unknown.png`);
    }
  }

  render() {
    const {code, showTitle, className} = this.props;
    const [languagePart, countryPart] = code.split('-');
    const languageString = this.getLanguage(languagePart);

    const base64 = this.getImage(countryPart);
    const tooltip = languageString + ' - ' + this.getCountry(countryPart);
    const title = languageString;

    const classes = cx(styles.countryFlag, className);

    if (!base64) {
      return <span>{tooltip}</span>;
    }

    return (
      <span className={classes}>
        <img src={base64} title={tooltip} alt={`${title}`} />
        {showTitle && <span className={styles.title}>{title}</span>}
      </span>
    );
  }
}

export default CountryFlag;

// https://cdn0.iconfinder.com/data/icons/195-flat-flag-psd-icons/70/{name}.png
//
// [
//   "United-Kingdom","United-States-of-America","France","Germany","Brazil","China","Spain","Russia","Italy",
//   "Japan","Netherlands","Australia","Korea-South","Canada","Poland","India","Portugal","Turkey","Finland",
//   "Argentina","Czech-Republic","Mexico","Denmark","Sweden","Chile","Liberia","Algeria","Belgium","Switzerland",
//   "Saudi-Arabia","Austria","Costa-Rica","Ukraine","Indonesia","Slovakia","Israel","Bulgaria","Thailand",
//   "Singapore","Colombia","Romania","Vietnam","Egypt","Estonia","Grecee","United-Arab-Emirates","Philippines",
//   "Pakistan","Norway","Mali","Burkina-Faso","Uruguay","Armenia","Cape-Verde","Taiwan","Belarus","Lithuania",
//   "Peru","Kazakhstan","Nicaragua","Ireland","Hungary","Kuwait","Tunisia","Malaysia","Tanzania","Burundi",
//   "Serbia","Venezuela","Ethiopia","Myanmar","Georgia","Morocco","Korea-North","Latvia","Equatorial-Guinea",
//   "Dominica","Iceland","South-Sudan","Barbados","Kenya","Ecuador","Chad","Madagascar","Oman","Cameroon",
//   "Albania","Azerbaijan","Congo-Democratic","Qatar","Trinidad-and-Tobago","Sao-Tome-and-Principe","Iraq","East-Timor",
//   "Guatemala","Antigua-and-Barbuda","Cote-dIvoire","Paraguay","Togo","Niger","Uganda","Rwanda","Senegal","Benin",
//   "Suriname","Bhutan","Palau","Bahamas","Liechtenstein","Maldives","Zambia","Gambia","Central-African-Republic",
//   "Nigeria","South-Africa","Afghanistan","Lebanon","Bolivia","Croatia","Cyprus","New-Zealand","Zimbabwe",
//   "Bosnia-and-Herzegovina","Honduras","Ghana","Uzbekistan","Syria","Papua-New-Guinea","Guinea-Bissau","Mauritania",
//   "Namibia","Sudan","Libya","Slovenia","Iran","Vatican-City","Solomon-Islands","Marshall-Islands","Luxembourg",
//   "Malta","Macedonia","Jamaica","Brunei","Bahrain","Dominican-Republic","Congo-Republic","Cambodia","Jordan",
//   "Sri-Lanka","Bangladesh","Somalia","Moldova","Andorra","Kyrgyzstan","Panama","Kosovo","El-Salvador","Mongolia",
//   "Mozambique","Monaco","Guinea","Nepal","Laos","Turkmenistan","Botswana","Sierra-Leone","Gabon","Eritrea",
//   "Lesotho","Seychelles","Djibouti","Malawi","Swaziland","Mauritius","Vanuatu","Haiti","Tajikistan","Yemen",
//   "Fiji","Guyana","Samoa","Nauru","Comoros","Belize","San-Marino","Micronesia-Federated","Grenada","Cuba",
//   "Montenegro","Kiribati","Saint-Kitts-and-Nevis","Tuvalu","Saint-Lucia","Tonga","Saint-Vincent-and-the-Grenadines,
//   "Japan"
// ]
