import PropTypes from 'prop-types';
import React from 'react';
import TickSelector from 'components/checkboxes/tickSelector';
import {toggle, normalizeOptions} from 'utils/arrayUtils';
import styles from './choice.scss';
import cx from 'classnames';

export default class Choice extends React.Component {
  static defaultProps = {
    options: []
  };

  static propTypes = {
    options: PropTypes.array
  };

  clickHandler = value => event => {
    const {multi, onChange, value: curr} = this.props;
    const newValue = multi ? toggle(curr, value) : value;

    if (multi || newValue !== curr) onChange && onChange(newValue);
  };

  renderOption = ({label, value, className}) => {
    const {multi, name, value: curr} = this.props;
    const selected = multi ? curr.indexOf(value) > -1 : curr === value;
    const classes = cx(styles.tickSelector, className);

    return (
      <TickSelector
        key={value}
        selected={selected}
        className={classes}
        onClick={this.clickHandler(value)}
      >
        {label}
      </TickSelector>
    );
  };

  setChoice = ref => {
    this.choice = ref;
  };

  render() {
    const {options, onFocus, onBlur} = this.props;

    // passing a `tabindex` makes the div focusable - meaning we
    // get focus and blur events emitted from this component!
    return (
      <div
        tabIndex="0"
        onBlur={onBlur}
        onFocus={onFocus}
        className={styles.choice}
        ref={this.setChoice}
      >
        {normalizeOptions(options).map(this.renderOption)}
      </div>
    );
  }
}
