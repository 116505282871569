import React from 'react';
import Button from 'components/button';

export default function InspectorHeaderButton({tooltip, children, dark, marginRight, ...props}) {
  // the span wrapper stops the buttons loading animation
  // from stopping the tooltip from showing
  const button = (
    <Button {...props} dark short={!dark} style={{marginRight: marginRight ? '10px' : ''}}>
      {children}
    </Button>
  );

  return button;

  // TODO: tooltip is severely broken when enabled here.
  // if (!tooltip) {
  //   return button;
  // }
  //
  // return (
  //   <Tooltip text={tooltip} side='bottom'>{button}</Tooltip>
  // )
}
