import React from 'react';
import {connect} from 'react-redux';
import {folderSidebarSelector} from 'modules/folders/folderSelectors';
import {Sidebar} from 'components/sidebar/listSidebar';
import SidebarFolders from './sidebarFolders';
import {REPORT_DRAG_TYPE} from 'modules/reports/reportUtils';
import {FOLDER_DRAG_TYPE, handleSidebarFolderDrop} from 'modules/folders/folderUtils';
import Droppable from 'components/dragging/droppable';
import AddFolderButton from './addFolderButton';
import {compose} from 'redux';

export class FolderSidebar extends React.Component {
  handleDrop = handleSidebarFolderDrop;

  renderButtons() {
    return <AddFolderButton />;
  }

  render() {
    const {folders} = this.props;
    return (
      <Sidebar buttons={this.renderButtons()}>
        <h2>Folders</h2>
        <SidebarFolders folders={folders} isRoot={true} />
      </Sidebar>
    );
  }
}

export default compose(
  connect(folderSidebarSelector),
  Droppable([REPORT_DRAG_TYPE, FOLDER_DRAG_TYPE])
)(FolderSidebar);
