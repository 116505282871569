exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1s_xHv077Nd6IXcQzpK6uo{float:left}@media(min-width: 581px){.Z_Ck9WXFOy9hlKe0XEVKx{position:absolute;top:55px;right:-40px;width:450px}}@media(max-width: 580px){.Z_Ck9WXFOy9hlKe0XEVKx{width:100%;max-width:400px}}", ""]);

// exports
exports.locals = {
	"plansButton": "_1s_xHv077Nd6IXcQzpK6uo",
	"image": "Z_Ck9WXFOy9hlKe0XEVKx"
};