import React, {useRef} from 'react';
import useResizeObserver from 'hooks/useResizeObserver';
import styles from './grid.scss';
import cx from 'classnames';

export default function Grid({
  children,
  className,
  carousel,
  center,
  small,
  noEdges,
  targetWidth = 280
}) {
  const elemRef = useRef(null);
  const box = useResizeObserver(elemRef);
  const cols =
    box.width == 'auto'
      ? window.innerWidth
      : Math.max(1, Math.min(12, Math.floor(box.width / targetWidth)));

  return (
    <div
      ref={elemRef}
      className={cx(styles.grid, className, styles['col' + cols], {
        [styles.small]: small,
        [styles.noEdges]: noEdges,
        [styles.carousel]: carousel,
        [styles.center]: center
      })}
    >
      {React.Children.map(children, child => (
        <div className={styles.item}>{child}</div>
      ))}
    </div>
  );
}
