import React from 'react';
import {getOrdinal} from 'utils/numberUtils';
import styles from './seoContentIssue.scss';
import ExternalLink from 'modules/location/externalLink';

class SeoContentIssue extends React.Component {
  renderExamples(examples) {
    return examples.map(this.renderExample);
  }

  renderExample = (example, i) => {
    return (
      <tr key={i}>
        <td>{getOrdinal(example.rank)}</td>
        <td>
          <ExternalLink href={example.url}>{this.renderText(example.text)}</ExternalLink>
          {example.url != example.text && (
            <div>
              <a href={example.url} target="_blank" className={styles.host}>
                {example.host}
              </a>
            </div>
          )}
        </td>
      </tr>
    );
  };

  renderSummary(summaryType) {
    return (
      <div>
        <h4>{summaryType === 'mitigation' ? 'Mitigation' : 'Impact'}</h4>
        <p>{this.renderSignificance(summaryType, this.props.point.significance)}</p>
      </div>
    );
  }

  renderText(text) {
    return text.split('\n').map((item, i) => {
      return <p key={i}>{item}</p>;
    });
  }

  renderSignificance(summaryType, significance) {
    let description = 'negligible';

    if (significance >= 30) {
      description = 'very high';
    } else if (significance >= 20) {
      description = 'high';
    } else if (significance >= 10) {
      description = 'moderate';
    }

    if (summaryType === 'mitigation') {
      return (
        <span>
          Mitigating this factor will have a {description} impact ({Math.round(significance)}%) on
          ranking based on similar websites and keywords.
        </span>
      );
    }
    return (
      <span>
        Would have a {description} impact ({Math.round(significance)}%) based on similar websites
        and keywords.
      </span>
    );
  }

  render() {
    const {
      point: {text, ownRank, examples, summaryType},
      action: {actionId, decisions}
    } = this.props;

    return (
      <div>
        <h4>Comparison</h4>
        <p>This website (bottom) versus higher-ranking competitors who do this:</p>

        <table className={styles.table}>
          {this.renderExamples(examples)}
          <tr>
            <td>{getOrdinal(ownRank)}</td>
            <td>
              {text ? <strong>{this.renderText(text)}</strong> : <em>None specified</em>}
              <div className={styles.host}>This website</div>
            </td>
          </tr>
        </table>

        {this.renderSummary(summaryType)}
      </div>
    );
  }
}

export default SeoContentIssue;
