import _ from 'lodash';
import React from 'react';
import Button from 'components/button';
import styles from './dropdown.scss';
import DefaultDropdown from 'components/dropdown';

class LayoutDropdown extends React.Component {
  handleSelect = option => {
    const {setFilterParams} = this.props;
    const filterName = this.props.config.filter;

    setFilterParams({
      [filterName]: option
    });
  };

  getOptions() {
    const {result} = this.props;

    return _.map(result, (label, value) => {
      return {label, value};
    });
  }

  renderButton(label) {
    return (
      <Button icon="caret-down" wire iconSide="right">
        {label ? label + ' ' : ''}
      </Button>
    );
  }

  render() {
    const {filter, setFilterParams, result, config} = this.props;

    // @todo Add a better loading placeholder here
    // We don't use the default one as it's massive compared to our usual size
    if (!result) return '...';

    const filterName = config.filter;
    const filterValue = filter[filterName] || ''; // "" needed as unspecified filters in our query strings are removed
    const label = config.label || result[filterValue];
    const options = this.getOptions();

    if (!options) return null;

    return (
      <DefaultDropdown
        options={options}
        bodyClassName={styles.dropdownBody}
        bodyOptionsClassName={styles.bodyOptions}
        onChange={this.handleSelect}
        button={this.renderButton(label)}
      />
    );
  }
}

LayoutDropdown.handlesLoading = true;

export default LayoutDropdown;
