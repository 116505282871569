import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import Icon from 'components/font-awesome';
import Spinner from 'components/spinner';
// import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {requestBalances} from 'modules/balances/actions';
import styles from './keywordsBalance.scss';
import {
  isBalancesLoadingSelector,
  hasBalancesLoadedSelector,
  searchEngineKeywordBalanceSelector
} from '../selectors';

function KeywordsBalance({
  className,
  reportId,
  sku,
  serviceId,
  requestBalances,
  isLoading,
  hasLoaded,
  keywordsUsed,
  keywordsBalance
}) {
  // const dispatch = useDispatch();
  // const isLoading = useSelector(isBalancesLoadingSelector);
  // const hasLoaded = useSelector(hasBalancesLoadedSelector);
  // const keywordsBalance = useSelector(searchEngineKeywordBalanceSelector);

  React.useEffect(() => {
    // dispatch(requestBalances(reportId, {sku, serviceId}));
    if (sku || serviceId) {
      requestBalances(reportId, {sku, serviceId});
    }
  }, [reportId, sku, serviceId]);

  if (isLoading || !hasLoaded) {
    return <Spinner small className={className} />;
  }

  const hasExceeded = keywordsBalance >= 0 && keywordsUsed > keywordsBalance;

  return (
    <div className={cx(styles.keywordsBalance, className, {[styles.balanceExceeded]: hasExceeded})}>
      <Icon name={hasExceeded ? 'times' : 'check'} size="lg" /> Used {keywordsUsed} of{' '}
      {keywordsBalance < 0 ? 'unlimited' : keywordsBalance} checks
    </div>
  );
}

export default connect(
  createStructuredSelector({
    isLoading: isBalancesLoadingSelector,
    hasLoaded: hasBalancesLoadedSelector,
    keywordsBalance: searchEngineKeywordBalanceSelector
  }),
  {requestBalances}
)(KeywordsBalance);
