import React, {useEffect} from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import Loading from 'components/spinner/loading';

function DigestPreview({loading, subject, body, onClose}) {
  useEffect(() => {
    return () => {
      if (onClose) onClose();
    };
  }, []);

  return (
    <ModalContent title={loading ? 'Loading digest preview' : subject}>
      {loading ? <Loading /> : <div dangerouslySetInnerHTML={{__html: body}} />}
    </ModalContent>
  );
}

export default DigestPreview;
