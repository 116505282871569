import {takeEvery, takeLatest, take, select, put, call} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SET_ALERT_SUBSCRIPTION} from 'modules/alertSubscriptions/constants';
import {setAlertSubscriptionState} from 'modules/alertSubscriptions/actions';
import {
  alertSubscriptionEndpoint,
  alertSubscriptionsEndpoint
} from 'modules/alertSubscriptions/utils';

export default function* setAlertSubscriptionSaga() {
  yield takeLatest(SET_ALERT_SUBSCRIPTION, setAlertSubscription);
}

function* setAlertSubscription({params, storyTypes, isSubscribed}) {
  for (var i = 0; i < storyTypes.length; i++) {
    const {storyTypeId, subscriptionId, userId} = storyTypes[i];
    yield call(setStoryType, params.reportId, subscriptionId, storyTypeId, userId, isSubscribed);
  }
}

function* setStoryType(reportId, subscriptionId, storyTypeId, userId, isSubscribed) {
  const params = {reportId, subscriptionId};

  yield processRequest(
    subscriptionId ? 'PUT' : 'POST',
    subscriptionId ? alertSubscriptionEndpoint(params) : alertSubscriptionsEndpoint(params),
    {
      success: function*(data) {
        yield put(setAlertSubscriptionState(data));
      },
      error: function*(err) {}
    },
    {
      subscriptionId,
      storyTypeId,
      channelId: userId,
      channelTypeId: 'user',
      isSubscribed
    }
  );
}
