import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {havePropsChanged} from 'utils/propUtils';
import {FieldValidators} from 'modules/form/formUtils';
import {Form, Field} from 'modules/form/components';

// These are props provided by the schema that we can translate *directly* over to the field components
const availableInputProps = {
  label: true,
  placeholder: true,
  disabled: true,
  readonly: true,
  size: true,
  rows: true,
  cols: true,
  description: true // this is for booleanInput
};

export default class SchemaForm extends React.Component {
  static defaultProps = {
    schema: [],
    fields: []
  };

  static propTypes = {
    schema: PropTypes.object.isRequired,
    fields: PropTypes.array
  };

  submit() {
    this.form.submit();
  }

  componentWillMount() {
    this.updateFields();
  }

  componentWillReceiveProps(props) {
    if (havePropsChanged(this.props, props, ['schema', 'fields'])) {
      this.updateFields();
    }
  }

  updateFields() {
    this.fields = translateSchema(_.pick(this.props.schema, this.getSelectedFields()));
  }

  getSelectedFields() {
    const schemaFields = Object.keys(this.props.schema);

    // if specific fields were passed, use them
    return !this.props.fields.length
      ? schemaFields
      : _.intersection(this.props.fields, schemaFields);
  }

  render() {
    // we extract `schema` and `fields` so it is not in `props`
    const {schema, fields, defaultValue, ...props} = this.props;

    const fromCallee = _.pick(defaultValue, this.getSelectedFields());
    const fromSchema = _.reduce(
      this.getSelectedFields(),
      (reduction, value) => {
        reduction[value] = this.props.schema[value].default;
        return reduction;
      },
      {}
    );

    return (
      <Form
        {...props}
        ref={ref => {
          this.form = ref;
        }}
        defaultValue={{...fromSchema, ...fromCallee}}
      >
        {this.fields.map(fieldProps => (
          <Field {...fieldProps} />
        ))}
      </Form>
    );
  }
}

function translateSchema(schema) {
  return Object.keys(schema)
    .map(name => {
      const schemata = schema[name];
      if (!schemata) return null;

      const inputProps = getInputProps(schemata);

      return {
        name,
        key: 'schemaField_' + name,
        type: getInputType(schemata),
        ...inputProps,
        ...getValidators(schemata)
      };
    })
    .filter(Boolean); // remove any null values
}

export function getInputType(schemata) {
  let {type = 'text', multi, multiline, arrayType} = schemata;

  if (schemata.enum) {
    type = 'select';
  }

  // conditional type changes
  switch (type) {
    case 'choice':
      if (multi) type = 'multichoice';
      break;
    case 'select':
      if (multi) type = 'multiselect';
      break;
    case 'string':
      type = multiline ? 'textarea' : 'text';
      break;
    case 'int':
    case 'float':
      type = 'number';
      break;
    case 'timestamp':
      type = 'date';
      break;
    case 'bool':
      type = 'checkbox';
    case 'json':
      if (schemata.fieldType) {
        type = schemata.fieldType;
      }
      break;
  }

  return type;
}

function getInputProps(schemata) {
  const inputProps = Object.keys(schemata).reduce((props, prop) => {
    if (availableInputProps[prop]) {
      props[prop] = schemata[prop];
    }
    return props;
  }, []);

  if (schemata.enum) {
    // compose the 'options' property from 'enum' (and 'enumLabels' if available)
    const values = schemata.enum;
    const labels = schemata.enumLabels || values;
    inputProps.options = values.map((value, i) => {
      return {label: labels[i], value};
    }, []);
  }

  return inputProps;
}

// We need to translate the backend validation rules into front end validation rules.
// The possible validators are defined in `formUtils`. The only real difference is that
// the front end has a different concept for the min/max of a value and the min/max of
// a length. E.G. The `maxLength` validator will work on arrays and strings, `min` will
// work on numbers (and possibly dates? if they are timestamps at least)
function getValidators({type, nullable, min, max, multiline}) {
  const validatorProps = {};

  // if the backend sets the min as 1, it means required
  // sometime nullable can be false (meaning required) but min is set to 0 (spooky paradox)
  if (min === 1 || (nullable === false && min !== 0)) {
    validatorProps.required = true;
  }

  if (['password', 'string', 'email', 'url'].includes(type)) {
    // the string lengths only count if not multiline.
    // multiline inputs tend to be the urls split by newline
    if (!multiline) {
      if (min != undefined && min != 0) validatorProps.minLength = min;
      if (max != undefined) validatorProps.maxLength = max;
    }

    // NOTE these are automatically added by the fields anyway
    // if (type == 'url') validatorProps.url = true;
    // if (type == 'email') validatorProps.email = true;
    // if (type == 'password') validatorProps.password = true;
  } else {
    validatorProps.min = min;
    validatorProps.max = max;
  }

  // we pretty much ignore all of the other backend rules for now,
  // like `crop` and `fixedLength` and `autoIncrement`, etc
  // TODO feel free to get stuck in and give it a go

  return validatorProps;
}
