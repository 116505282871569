exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Sbhk9-gdewW7wn0fqFw8-{border:0;width:100%;height:100%;min-height:100%}._1uoBdqEOLOkW9UrM0g6DAJ{position:absolute;top:100px;left:50%}._37bU88zLSjsUQ17t1q3Sgm{position:absolute;top:0;left:0;right:0;bottom:0;background:#fff}._1bFzfV7jIPylAB9XcTpPM5{width:100%;height:100%;position:relative}", ""]);

// exports
exports.locals = {
	"iframe": "_2Sbhk9-gdewW7wn0fqFw8-",
	"spinner": "_1uoBdqEOLOkW9UrM0g6DAJ",
	"hideIframeWrapper": "_37bU88zLSjsUQ17t1q3Sgm",
	"container": "_1bFzfV7jIPylAB9XcTpPM5"
};