import moment from 'moment';
import {List} from 'immutable';
import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {reportSelector} from 'modules/reports/reportSelectors';
import {locationSelector} from 'modules/location/locationSelectors';
import {getReportIdParts} from 'modules/reports/reportUtils';

export const activeArchiveIdSelector = createSelector(
  locationSelector,
  ({params}) => {
    const reportIdParts = getReportIdParts(params.reportId);
    return reportIdParts.archiveId || 'live';
  }
);

const archivesSelector = createSelector(
  reportSelector,
  report => report.get('archives', List())
);

const hasArchivesSelector = createSelector(
  archivesSelector,
  archives => !archives.isEmpty()
);

export const isArchiveActiveSelector = createSelector(
  activeArchiveIdSelector,
  archiveId => {
    return archiveId && archiveId !== 'live';
  }
);

export const archiveSelector = createSelector(
  activeArchiveIdSelector,
  reportSelector,
  (archiveId, report) => {
    // The `live` archive doesn't actually exist,
    // it just signifies to get the data from the usual endpoints
    if (archiveId === 'live') {
      return {archiveId: 'live', startTime: 0};
    }

    const reportArchives = report.get('archives', List());
    const archive = reportArchives.find(archive => archive.get('archiveId') === archiveId);
    return toJS(archive) || {};
  }
);

export const archiveOptionsSelector = createSelector(
  archivesSelector,
  archives => {
    const archiveOptions = archives.map(s => ({
      value: s.get('archiveId'),
      label: moment.unix(s.get('startTime')).format('MMMM Do YYYY, h:mm a')
    }));

    return [{value: 'live', label: 'Latest results'}, ...archiveOptions];
  }
);

export const archiveDropdownSelector = createSelector(
  archiveSelector,
  archiveOptionsSelector,
  hasArchivesSelector,
  (archiveId, archiveOptions, isArchiveActive) => {
    return {archiveId, archiveOptions, isArchiveActive};
  }
);
