exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2TiFxtxbd2qV-ref6_HhaS{width:100%;height:auto}", ""]);

// exports
exports.locals = {
	"icon": "_2TiFxtxbd2qV-ref6_HhaS"
};