import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import {SWAP_FOLDER_VIEWS, receiveFolderView} from 'modules/folderViews';
import {reorderViewsEndpoint} from 'modules/folderViews/folderViewUtils';
import {folderViewsSelector} from 'modules/folderViews/folderViewSelectors';
import {push} from 'modules/location';
import {processRequest} from 'utils/saga/fetchUtils';
import {showInModal} from 'modules/modal';
import {HIDE_MODAL} from 'modules/modal';
import {statsSelector} from 'modules/stats/statSelectors';
import {toJS, keyBy, swap} from 'utils/immutableUtils';
import {receiveFolderViews} from 'modules/folderViews';

export default function* addFolderViewSaga() {
  // When SWAP_FOLDER_VIEWS gets called, the reducer reorders them.
  // We also need to let the server know the new order.
  // So:

  yield takeLatest(SWAP_FOLDER_VIEWS, persistFolderViewOrder);
}

function* persistFolderViewOrder({folderId, idxA, idxB}) {
  // By the time the saga picks up on the action, the reduce will have already run.
  const folderViews = yield select(folderViewsSelector);
  const viewIds = folderViews.map(folderView => folderView.get('viewId'));
  const postData = {viewIds: viewIds.toJS()};

  yield processRequest(
    'POST',
    reorderViewsEndpoint({folderId}),
    {
      error: function*(err) {
        // revert to previous state if we failed
        const oldFolderViews = swap(folderViews, idxA, idxB);
        yield put(receiveFolderViews(oldFolderViews));
      },
      errorMessage: 'Something went wrong'
    },
    postData
  );
}
