import React from 'react';

import cx from 'classnames';
import ModalFooter from './modalFooter';
import Flex from 'components/layout/flex';
import styles from './modalContent.scss';

// Component used to build modal content.
// Due to the nature of our Modal system (animating between states etc)
// we pass it the content it should render rather than a full
// <Modal> component.

// Usage:
// <ModalContent footer={<div>blah<div>} />
// or
// <ModalContent buttons={['save', 'cancel']} onClickButton={...} />

export default function ModalContent({
  title,
  description,
  children,
  footer,
  buttons,
  onClickButton,
  focusButton,
  className,
  huge,
  isLoading
}) {
  return (
    <Flex container column>
      <Flex grow={1}>
        <Flex container column spaceAround>
          <Flex className={cx(styles.content, className)}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.description}>{description}</p>}
            {children}
          </Flex>
        </Flex>
      </Flex>
      {(footer || buttons) && (
        <ModalFooter
          buttons={!footer && buttons}
          isLoading={isLoading}
          onClick={onClickButton}
          focusButton={focusButton}
          children={footer}
        />
      )}
    </Flex>
  );
}
