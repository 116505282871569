import {takeEvery, takeLatest, take, select, put, call} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {statsSelector} from 'modules/stats/statSelectors';
import {fetchLeaderboardResults} from 'modules/leaderboards/actions';
import {keyBy, toJS} from 'utils/immutableUtils';
import {showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

import {REQUEST_UPDATE_LEADERBOARD} from '../constants';
import {receiveLeaderboard} from '../actions';
import {leaderboardEndpoint} from '../utils';
import {queryParamsSelector} from 'modules/location/locationSelectors';

export default function* updateLeaderboardSaga() {
  yield takeLatest(REQUEST_UPDATE_LEADERBOARD, updateLeaderboard);
}

function* updateLeaderboard({type, leaderboardId, onSuccess, ...requestData}) {
  const accountId = yield select(accountIdSelector);
  const query = yield select(queryParamsSelector);
  const statColumns = keyBy(toJS(yield select(statsSelector)), 'name');

  requestData.columns = requestData.columns
    .filter(statId => statColumns.has(statId))
    .map(statId => ({
      name: statId.indexOf('.') < 0 ? 'report.' + statId : statId,
      label: statColumns.getIn([statId, 'label'])
    }));

  yield processRequest(
    'PUT',
    leaderboardEndpoint({accountId, leaderboardId}),
    {
      *success(data) {
        yield put(receiveLeaderboard(data.leaderboard));
        yield put(
          fetchLeaderboardResults(
            leaderboardId,
            query.limit,
            query.offset,
            query.order,
            query.search
          )
        );
        yield showSuccess('leaderboard updated');
        yield call(onSuccess, true, data.leaderboard);
      },
      *error(err) {
        yield call(onSuccess, false, err);
      },
      errorMessage: couldNot('save leaderboard')
    },
    requestData
  );
}
