import {List} from 'immutable';
import {createSelector} from 'reselect';
import {getProductNameFromSkus} from 'modules/products/productUtils';

export const entitlementsSelector = state => state.entitlements;

export const entitlementDataSelector = createSelector(
  entitlementsSelector,
  entitlements => entitlements.get('data', List())
);

// @todo: Ran out of time for now, so this will do. But realistically, we want to get a better way of figuring out your
// most important product.
export const topEntitlementNameSelector = createSelector(
  entitlementDataSelector,
  entitlements => {
    const skus = entitlements.reduce((skusHolder, entitlement) => {
      skusHolder.push(entitlement.get('sku'));
      return skusHolder;
    }, []);

    const productName = getProductNameFromSkus(skus);
    return productName || 'Silktide';
  }
);

export const hasRemainingProductsSelector = createSelector(
  entitlementDataSelector,
  data =>
    data &&
    data.filter &&
    data.filter(c => {
      // return true if we can use this product
      return c.get('isInfinite') || c.get('remaining') > 0;
    }).size
);

export const entitlementsLoadingSelector = createSelector(
  entitlementsSelector,
  entitlements => entitlements.get('isLoading')
);

export const entitlementsErrorSelector = createSelector(
  entitlementsSelector,
  entitlements => entitlements.get('error')
);
