import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {requestLogin} from 'modules/auth';
import {loginTwoFactorPageSelector} from 'modules/auth/authSelectors';
import Spinner from 'components/spinner/circularSpinner';
import Helmet from 'components/helmet';
import styles from 'modules/auth/components/authWrapperShared.scss';
import Brand, {SILKTIDE_LOGO} from 'components/brand/brand';
import {homePath} from 'modules/app/appUtils';
import {replace} from 'modules/location';
import {Form, Field} from 'modules/form/components';
import ExternalLink from 'modules/location/externalLink';
import Button from 'components/button';
import {loginPath} from 'modules/auth/authUtils';
import {forgottenPasswordPath} from 'modules/auth/authUtils';

export class TwoFactorPage extends React.Component {
  componentDidMount() {
    const {details} = this.props;

    if (!details || !(details.email && details.password)) {
      // if we don't have any loggin details saved, redirect to the login page.
      this.props.replace(loginPath);
    }
  }

  handleLogin = ({code}) => {
    const {email, password} = this.props.details;
    this.setState({email});
    this.props.requestLogin(email, password, code, true);
  };

  renderLogin() {
    const {error, loggingIn} = this.props;

    if (loggingIn) {
      return <Spinner centered />;
    }

    return (
      <div>
        <div className={styles.logoWrapper}>
          <ExternalLink href="https://silktide.com/" title="Silktide">
            <Brand className={styles.logo} version={SILKTIDE_LOGO} />
          </ExternalLink>
        </div>

        <p className={styles.header}>Please enter the two-factor authentication code</p>

        <Form onValidSubmit={this.handleLogin}>
          <Field
            autoFocus
            name="code"
            type="text"
            label="Code"
            className={styles.textInput}
            required
          />

          {error && (
            <p id="loginErrorMessage" className={styles.errorText}>
              {error}
            </p>
          )}

          <div className={styles.buttonContainer}>
            <Button type="submit" className={styles.submit}>
              Verify
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    if (this.props.loggedIn) {
      return <div />;
    }

    return (
      <div className={this.props.className}>
        <Helmet title="Log in" />

        {this.renderLogin()}
      </div>
    );
  }
}

export default connect(
  loginTwoFactorPageSelector,
  {requestLogin, replace}
)(TwoFactorPage);
