import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './center.scss';
import filterInvalidDomProps from 'filter-invalid-dom-props';

// Center uses flex to center it's children vertically and horizontally.
// Use the `fit` attribute to make this wrapper fill the space around it.
// Any other props are passed directly to the wrapper.
class Center extends React.Component {
  static defaultProps = {
    style: {}
  };

  render() {
    const {
      fit,
      children,
      className,
      marginTop,
      marginBottom,
      style,
      wrap,
      column,
      ...props
    } = this.props;

    const containerClass = cx(styles.center, className, {
      [styles.fit]: fit,
      [styles.wrap]: wrap,
      [styles.column]: column
    });

    const divStyle = {...style};

    if (marginTop) {
      divStyle.marginTop = marginTop;
    }

    if (marginBottom) {
      divStyle.marginBottom = marginBottom;
    }

    return (
      <div className={containerClass} {...filterInvalidDomProps(props)} style={divStyle}>
        {children}
      </div>
    );
  }
}

export default Center;
