import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {parse} from 'query-string';
import {viewTest, leaveTest} from 'modules/tests';
import ConnectedTestPage from 'modules/tests/pages/testPage';
import ActionsRoute from 'modules/actions/routes/actionsRoute';
import PolicyRoute from 'modules/policies/routes/policyRoute';
import InteractionsRoute from 'modules/interactions/routes/interactionsRoute';

export class TestsRoute extends React.Component {
  componentDidMount() {
    this.viewTest(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.viewTest(nextProps);
    }
  }

  viewTest(nextProps) {
    const {
      match: {params},
      location: {search}
    } = nextProps;

    const filters = parse(search);
    this.props.viewTest(params, filters);
  }

  componentWillUnmount() {
    this.props.leaveTest();
  }

  render() {
    const {
      match: {path}
    } = this.props;

    // TODO ideally this shouldn't be given `match` or `location`, it should receive the specific data
    //      I.E reportId etc but they are currently required by sub components
    return (
      <Switch>
        <Route exact path={`${path}`} component={TestsRoute2} />
        {/* Support old tasks path for now - prevent old links from breaking */}
        <Route path={`${path}/tasks/:actionId`} component={ActionsRoute} />
        <Route path={`${path}/actions/:actionId`} component={ActionsRoute} />

        <Route path={`${path}/policies/:policyId`} component={PolicyRoute} />
        <Route path={`${path}/interactions`} component={InteractionsRoute} />
      </Switch>
    );
  }
}

class TestsRoute2 extends React.Component {
  shouldComponentUpdate(nextProps) {
    const thisLocation = this.props.location;
    const nextLocation = nextProps.location;

    return (
      thisLocation.pathname != nextLocation.pathname ||
      thisLocation.search != nextLocation.search ||
      this.props.match.url != nextProps.match.url
    );
  }

  render() {
    const {match, location} = this.props;
    const {params} = match;
    return (
      <ConnectedTestPage
        match={match}
        location={location}
        reportId={params.reportId}
        testId={params.testId}
      />
    );
  }
}

export default connect(
  null,
  {leaveTest, viewTest}
)(TestsRoute);
