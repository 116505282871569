import {call, put, takeEvery} from 'redux-saga/effects';
import POST from 'utils/saga/post';
import {RETEST_PAGE} from 'modules/reports/reportConstants';
import {retestPageEndpoint} from 'modules/reports/reportUtils';
import {processResponse} from 'utils/saga/fetchUtils';
import {receiveMission} from 'modules/missions';

export default function* retestPageSaga() {
  yield takeEvery(RETEST_PAGE, retestPage);
}

function* retestPage({reportId, pageHash, prioritiseTestId}) {
  const data = prioritiseTestId && {testId: prioritiseTestId};

  const res = yield call(POST, retestPageEndpoint({reportId, pageHash}), data);

  yield processResponse(res, {
    successAction: receiveMission
  });
}
