import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  screens: {},
  // only the sources for the current screen with current filters are stored
  sources: {},
  startTime: null,
  endTime: null,
  accuracy: 'hour',
  loading: false
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_TREND_SCREEN:
      return state.set('loading', true);

    case RECEIVE_TREND_SCREENS:
      return state.set('screens', keyBy(action.trendScreens, 'trendScreenId'));

    case RECEIVE_TREND_SOURCES:
      return state
        .set('loading', false)
        .set('startTime', action.startTime)
        .set('endTime', action.endTime)
        .set('accuracy', action.accuracy)
        .set('sources', keyBy(action.trendSources, 'trendSourceId'));

    case CHANGE_DATE_RANGE:
      return state
        .set('startTime', action.startTime)
        .set('endTime', action.endTime)
        .set('loading', true);

    case CHANGE_ACCURACY:
      return state.set('loading', true).set('accuracy', action.accuracy);
  }

  return state;
}

export const VIEW_TREND_SCREEN = 'trends:VIEW_TREND_SCREEN';
export function viewTrendScreen(reportId, trendScreenId) {
  return {
    type: VIEW_TREND_SCREEN,
    reportId,
    trendScreenId
  };
}

export const RECEIVE_TREND_SCREENS = 'trends:RECEIVE_TREND_SCREENS';
export function receiveTrendScreens(trendScreens) {
  return {
    type: RECEIVE_TREND_SCREENS,
    trendScreens
  };
}

export const RECEIVE_TREND_SOURCES = 'trends:RECEIVE_TREND_SOURCES';
export function receiveTrendSources(trendSources, startTime, endTime, accuracy) {
  return {
    type: RECEIVE_TREND_SOURCES,
    trendSources,
    startTime,
    endTime,
    accuracy
  };
}

export const CHANGE_DATE_RANGE = 'trends:CHANGE_DATE_RANGE';
export function changeDateRange(startTime, endTime) {
  return {
    type: CHANGE_DATE_RANGE,
    startTime,
    endTime
  };
}

export const CHANGE_ACCURACY = 'trends:CHANGE_ACCURACY';
export function changeAccuracy(accuracy) {
  return {
    type: CHANGE_ACCURACY,
    accuracy
  };
}
