import {takeLatest, put, all, take, select, call} from 'redux-saga/effects';
import {requestAssignedEntitiesComplete} from 'modules/tags/actions';
import {
  REQUEST_ASSIGNED_ENTITIES,
  UNASSIGN_TAGS_COMPLETE,
  ASSIGN_TAGS_COMPLETE
} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* requestAssignedEntitiesListener() {
  yield takeLatest(REQUEST_ASSIGNED_ENTITIES, requestAssignedEntities);
  yield takeLatest([UNASSIGN_TAGS_COMPLETE, ASSIGN_TAGS_COMPLETE], updateAssignedEntities);
}

function* updateAssignedEntities({entityType, tagIds}) {
  yield all(
    tagIds.map(tagId => {
      return call(requestAssignedEntities, {tagId, entityType});
    })
  );
}

function* requestAssignedEntities({tagId, entityType}) {
  try {
    const assignedEntities = yield processRequest('GET', `tags/${tagId}/${entityType}`, {});
    yield put(requestAssignedEntitiesComplete(null, tagId, entityType, assignedEntities));
  } catch (err) {
    yield put(requestAssignedEntitiesComplete(err));
  }
}
