import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Panel from 'components/panel';
import {push} from 'modules/location';
import Button from 'components/button';
import Table from 'components/table';
import Status from 'components/status';
import Spinner from 'components/spinner';
import Switch from 'components/switch/switch';
import SwitchOption from 'components/switch/switchOption';
import SavePanel from 'modules/form/components/savePanel';
import {
  updateMonitor,
  fetchUptimeMonitorContacts,
  updateUptimeMonitorContact,
  requestCanAddUptimeMonitor
} from 'modules/uptime/actions';
import {Form, Field} from 'modules/form/components';
import PageHeader from 'components/header/pageHeader';
import SettingsPage from 'components/settings/settingsPage';
import UptimeContactMethod from 'modules/uptime/components/uptimeContactMethod';
import DeleteUptimeContactButton from 'modules/uptime/components/deleteUptimeContactButton';
import {saveUptimeMonitorSelector} from 'modules/uptime/selectors';
import {uptimeSettingsPath} from 'modules/reports/reportUtils';
import UsersSelectbox from 'modules/users/components/usersSelectbox';
import UserImage from 'components/user/userImage';
import {DEFAULT_CONTACT_OPTIONS} from 'modules/uptime/utils';

const TABLE_COLUMNS = [
  {
    label: 'User',
    field: 'userId',
    type: 'jsx',
    jsx: ({data, record}) => {
      return (
        <UserImage userId={data} size={40} inline>
          {user => <span>{user.name}</span>}
        </UserImage>
      );
    }
  },
  {
    label: 'Email',
    field: 'uptimeContact.contactOptions.email',
    type: 'jsx',
    jsx: ({data, record, reportId}) => {
      return (
        <UptimeContactMethod
          value={data}
          method="email"
          reportId={reportId}
          monitorId={record.uptimeContact.monitorId}
          userId={record.userId}
        />
      );
    }
  },
  {
    label: 'Text message',
    field: 'uptimeContact.contactOptions.phoneNumber',
    type: 'jsx',
    jsx: ({data, record, reportId}) => {
      return (
        <UptimeContactMethod
          value={data}
          method="phoneNumber"
          reportId={reportId}
          monitorId={record.uptimeContact.monitorId}
          userId={record.userId}
        />
      );
    }
  },
  {
    field: 'userId',
    type: 'jsx',
    width: '120px',
    jsx: ({data, record, reportId}) => {
      return (
        <DeleteUptimeContactButton
          reportId={reportId}
          monitorId={record.uptimeContact.monitorId}
          userId={data}
        />
      );
    }
  }
];

export class SaveUptimeMonitor extends React.Component {
  state = {
    tab: 'monitor',
    isValid: true,
    hasChanged: false
  };

  componentWillMount() {
    const {reportId, monitorId} = this.props.match.params;

    if (monitorId !== 'new') {
      this.props.fetchUptimeMonitorContacts(reportId, monitorId);
    } else {
      this.props.requestCanAddUptimeMonitor(reportId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {reportId, monitorId} = nextProps.match.params;

    if (this.props.match.params.reportId != reportId && reportId === 'new') {
      this.props.requestCanAddUptimeMonitor(reportId);
    }

    // if we were redirected from a 'new' page to an edit page, then auto set the "contacts" tab
    if (this.props.match.params.monitorId === 'new' && monitorId !== 'new') {
      this.setState({tab: 'contacts'});
    }

    // if the monitor has changed and it is NOT 'new'
    if (this.props.match.params.monitorId !== monitorId && monitorId !== 'new') {
      this.props.fetchUptimeMonitorContacts(reportId, monitorId);
    }
  }

  handleChange = (name, value, data, isValid, hasChanged, messages) => {
    this.setState({
      hasChanged,
      isValid
    });
  };

  handleValidSubmit = data => {
    const {updateMonitor, match} = this.props;
    const {monitorId, reportId} = match.params;

    if (monitorId == 'new') {
      updateMonitor(data, reportId);
    } else {
      updateMonitor(data, reportId, monitorId);
    }
  };

  handleClickGoBack = () => {
    this.props.push(uptimeSettingsPath, {}, ['reportId']);
  };

  renderMonitorPanel(isEditing) {
    return (
      <Panel hidden={this.state.tab === 'contacts' && isEditing}>
        <Field
          name="name"
          type="text"
          label="Monitor name"
          required
          minLength="3"
          maxLength="100"
          autoFocus
          placeholder="e.g. Main application"
        />

        <Field name="url" type="url" label="URL for page to check" required />

        <hr />

        <Field
          name="mustContainHtml"
          type="text"
          maxLength="1000"
          label="Must contain HTML"
          placeholder={'e.g. <p>Make your websites better</p>'}
        />

        <Field
          name="mustNotContainHtml"
          type="text"
          maxLength="1000"
          label="Must not contain HTML"
          placeholder="e.g. <p>Page not found</p>"
        />

        <Field
          name="maxAllowedSeconds"
          type="number"
          label="Max allowed seconds"
          min="5"
          max="999"
          width="100px"
          parseInt
        />
      </Panel>
    );
  }

  renderContactsPanel(isEditing) {
    const {reportId} = this.props.match.params;

    return (
      <Panel hidden={this.state.tab !== 'contacts' || !isEditing}>
        {!!this.props.usersWithoutContacts.size && (
          <UsersSelectbox
            users={this.props.usersWithoutContacts.toJS()}
            buttonLabel="Add user"
            helpMessage="Click to add users to be contacted by this uptime monitor"
            onSelectUser={userId => {
              const {reportId, monitorId} = this.props.match.params;

              // create user contact record
              this.props.updateUptimeMonitorContact(
                {
                  userId,
                  contactOptions: DEFAULT_CONTACT_OPTIONS
                },
                reportId,
                monitorId
              );
            }}
          />
        )}
        {!this.props.usersWithContacts.size ? (
          <Status appearance="info">
            Add users above to configure their notification preferences
          </Status>
        ) : (
          <Table
            cellParams={{reportId}}
            rowKey="userId"
            rows={this.props.usersWithContacts.toJS()}
            columns={TABLE_COLUMNS}
          />
        )}
      </Panel>
    );
  }

  render() {
    const {
      match: {params},
      name,
      title,
      uptimeMonitor,
      websiteConfig,
      canAddUptimeMonitor,
      isCheckingCanAddUptimeMonitor
    } = this.props;

    const isEditing = params.monitorId !== 'new';
    const newDefaultValue = {url: websiteConfig.get('homeUrl'), maxAllowedSeconds: 30};
    const defaultValue = !uptimeMonitor ? newDefaultValue : uptimeMonitor.toJS();

    // @todo: Need to figure out how it is we are going to be limiting these uptime monitors.
    return (
      <SettingsPage
        params={params}
        pageHeader={
          <PageHeader
            title={(isEditing ? 'Edit' : 'New') + ' uptime monitor'}
            goBack={this.handleClickGoBack}
          />
        }
      >
        {!isEditing && isCheckingCanAddUptimeMonitor ? (
          <Panel>
            <Spinner />
          </Panel>
        ) : isEditing || canAddUptimeMonitor ? (
          <Form
            boldLabels
            name={name}
            submitLabel="Save"
            defaultValue={defaultValue}
            title={title}
            onChange={this.handleChange}
            onValidSubmit={this.handleValidSubmit}
          >
            <Switch tabbed large>
              <SwitchOption
                active={this.state.tab === 'monitor'}
                onClick={() => this.setState({tab: 'monitor'})}
              >
                What to monitor
              </SwitchOption>
              <SwitchOption
                disabled={!isEditing}
                active={this.state.tab === 'contacts'}
                onClick={() => this.setState({tab: 'contacts'})}
              >
                Who to notify
              </SwitchOption>
            </Switch>

            {this.renderMonitorPanel(isEditing)}
            {this.renderContactsPanel(isEditing)}

            <SavePanel
              floating
              isLoading={isCheckingCanAddUptimeMonitor}
              onCancel={this.handleClickGoBack}
              isValid={this.state.isValid}
              show={!isEditing || this.state.hasChanged}
            />
          </Form>
        ) : (
          <Panel>
            <Status appearance="bad">
              Uptime monitor usage limit reached.
              <br />
              Please contact support if you wish to increase this limit.
            </Status>
          </Panel>
        )}
      </SettingsPage>
    );
  }
}

export default connect(
  saveUptimeMonitorSelector,
  {
    push,
    updateMonitor,
    fetchUptimeMonitorContacts,
    updateUptimeMonitorContact,
    requestCanAddUptimeMonitor
  }
)(SaveUptimeMonitor);
