exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-dftoNzfT3qIYH2SH9EkW{font-size:15px;border:0;margin:0;padding:10px;font-style:italic;quotes:\"\\201C\" \"\\201D\" \"\\2018\" \"\\2019\"}._2-dftoNzfT3qIYH2SH9EkW:before{opacity:.4;content:open-quote;font-size:40px;line-height:5px;margin-right:10px;vertical-align:-0.4em}._2-dftoNzfT3qIYH2SH9EkW:after{opacity:.4;content:close-quote;font-size:40px;line-height:5px;margin:0;vertical-align:-0.4em}._2-dftoNzfT3qIYH2SH9EkW p{display:inline}._3bh_pDmpJ1MyAUJRc3a5CB{margin:0;opacity:.5}", ""]);

// exports
exports.locals = {
	"sentence": "_2-dftoNzfT3qIYH2SH9EkW",
	"caption": "_3bh_pDmpJ1MyAUJRc3a5CB"
};