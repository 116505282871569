import React, {useContext} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Tooltip from 'components/tooltip';
import Icon from 'components/font-awesome';
import styles from './mainNavigation.scss';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import ExternalLink from 'modules/location/externalLink';

export default function MainNavigationLink({
  children,
  className,
  onClick,
  iconSrc,
  iconAlt,
  icon,
  href,
  small,
  external,
  active,
  tooltip,
  logo,
  marginTopAuto
}) {
  const {largeMobile} = useContext(ScreenSizeContext);
  const classes = cx(styles.navIcon, className, {
    [styles.active]: active,
    [styles.smaller]: small,
    [styles.bottomBreak]: marginTopAuto,
    [styles.logo]: logo
  });

  const childNode = (
    <React.Fragment>
      {iconSrc && <img src={iconSrc} width="32px" alt={iconAlt} />}
      {icon && <Icon name={icon} size="lg" style={{color: 'white'}} />}
      {children}
    </React.Fragment>
  );

  const link = href ? (
    external ? (
      <ExternalLink className={classes} href={href}>
        {childNode}
      </ExternalLink>
    ) : (
      <Link className={classes} to={href} aria-label={iconAlt}>
        {childNode}
      </Link>
    )
  ) : (
    <div className={classes} onClick={onClick}>
      {childNode}
    </div>
  );

  return largeMobile || !tooltip ? (
    link
  ) : (
    <Tooltip side="right" text={tooltip}>
      {link}
    </Tooltip>
  );
}
