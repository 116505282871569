import React from 'react';
import Spinner from 'components/spinner';
import {Wrapper, Header, Body, Title, Value} from 'modules/dashboards/components/widget';
import EditWidgetButton from 'modules/dashboards/containers/editWidgetButton';

export default function ReportMetricWidget({widgetType, widget, isEditing, contextTypeId}) {
  const type = widget.getIn(['result', 'type']);
  const value = widget.getIn(['result', 'value']);
  const loading = type == null || value == null;

  return (
    <Wrapper>
      <Header>
        <Title>{widget.get('name')}</Title>
        {isEditing && <EditWidgetButton widget={widget} contextTypeId={contextTypeId} />}
      </Header>

      <Body heightUnit={1}>{loading ? <Spinner /> : <Value type={type} value={value} />}</Body>
    </Wrapper>
  );
}
