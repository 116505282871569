import _ from 'lodash';
import React from 'react';
import styles from './newStat.scss';
import Icon from 'components/font-awesome';
import Spinner from 'components/spinner';
import {choosePluralVersion, interpolate} from 'utils/interpolationUtils';

export default class NewStat extends React.Component {
  renderValue(value) {
    const {config} = this.props;

    if (value == null || value < 0) {
      return <Spinner centered />;
    }

    const {plural, singular, zero} = config;
    const string = choosePluralVersion(value, singular, plural, zero);

    return <span>{interpolate(string, {value})}</span>;
  }

  renderPercentage(value, color) {
    return (
      <div className={styles.bar}>
        <div className={styles.progress} style={{backgroundColor: color, width: value + '%'}}></div>
      </div>
    );
  }

  render() {
    const {result, config} = this.props;
    const {icon, color, percentage, suffix} = config;

    return (
      <div className={styles.wrapper}>
        {percentage && this.renderPercentage(result, color)}
        <div className={styles.stat}>
          {icon && <Icon name={icon} className={styles.icon} style={{color: color}} />}
          <div className={styles.value}>{this.renderValue(result)}</div>
          {suffix && <div className={styles.suffix}>{suffix}</div>}
        </div>
      </div>
    );
  }
}

NewStat.handlesLoading = true;
