import React from 'react';
import {connect} from 'react-redux';
import Modal from '../components/modal';
import {hideModal} from '..';
import ConfirmationModal from 'modals/confirmationModal';
import {ESCAPE} from 'constants/keyCodes';

export class ModalContainer extends React.Component {
  state = {
    confirmingClose: false
  };

  componentWillReceiveProps(nextProps) {
    // reset confirmingClose when modal changes.
    if (nextProps.ContentElement !== this.props.ContentElement) {
      this.setState({confirmingClose: false});
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    const {closeOnEsc} = this.props;

    if (closeOnEsc && e.keyCode === ESCAPE) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const {confirmClose, hideModal} = this.props;

    if (confirmClose && !this.state.confirmingClose) {
      this.setState({confirmingClose: true});
      return;
    }

    hideModal();
  };

  handleConfirmClose = button => {
    this.props.hideModal();
  };

  resetConfirmingClose = () => {
    this.setState({confirmingClose: false});
  };

  renderConfirmClose() {
    const {confirmClose, closeOnEsc, hideModal} = this.props;

    const message = typeof confirmClose === 'string' ? confirmClose : null;

    return (
      <Modal onClose={hideModal}>
        <ConfirmationModal
          submitLabel="Close"
          cancelLabel="Back"
          destructiveConfirm
          onCancel={this.resetConfirmingClose}
          onSubmit={this.handleConfirmClose}
          content={message}
        />
      </Modal>
    );
  }

  render() {
    const {ContentElement, wider, wide, huge, props, hideModal} = this.props;

    if (!ContentElement) return null;

    if (this.state.confirmingClose) {
      return this.renderConfirmClose();
    }

    return (
      <Modal
        onClose={this.handleClose}
        // onClickOutside={this.handleClose}
        huge={huge}
        wide={wide}
        wider={wider}
        gradientBackground={props.gradientBackground}
        dismissable={props.dismissable}
      >
        <ContentElement {...props} hideModal={hideModal} />
      </Modal>
    );
  }
}

export default connect(
  state => state.modal.toJS(),
  {hideModal}
)(ModalContainer);
