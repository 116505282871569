import {Map, List, fromJS} from 'immutable';
import * as Consts from './constants';
import {keyBy} from 'utils/immutableUtils';
import {getEntityId} from './utils';
import {createLoadingState, startLoadingState, endLoadingState} from 'modules/app/reduxHelpers';
import {SWITCH_ACCOUNT} from 'modules/auth/auth';

const initialState = fromJS({
  tags: {},
  tagsSummary: {},
  assignedEntities: {},
  pagination: null, // pagination for tags

  editingTag: createLoadingState(),
  creatingTag: createLoadingState(),
  assigningTags: createLoadingState(),
  requestingTags: createLoadingState(),
  requestingTagsSummary: createLoadingState(),
  requestingAssignedTags: createLoadingState(),
  requestingAssignedEntities: createLoadingState()
});

export default function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_ACCOUNT:
      return initialState;

    case Consts.REQUEST_TAGS:
      return startLoadingState(state, 'requestingTags');

    case Consts.REQUEST_TAGS_COMPLETE:
      return endLoadingState(state, 'requestingTags', action.error)
        .set('tags', keyBy(action.pagedTags.tags, 'tagId'))
        .set('pagination', action.pagedTags.pagination);

    case Consts.REQUEST_TAGS_SUMMARY:
      return startLoadingState(state, 'requestingTagsSummary');

    case Consts.REQUEST_TAGS_SUMMARY_COMPLETE:
      return endLoadingState(state, 'requestingTagsSummary', action.error).set(
        'tagsSummary',
        fromJS(action.tagsSummary)
      );

    case Consts.CREATE_TAG:
      return startLoadingState(state, 'creatingTag');

    case Consts.CREATE_TAG_COMPLETE:
      return endLoadingState(state, 'creatingTag', action.error).update('tags', tags => {
        return tags.set(action.tag.tagId.toString(), fromJS(action.tag));
      });

    case Consts.ASSIGN_TAGS:
      return startLoadingState(state, 'assigningTags');

    case Consts.ASSIGN_TAGS_COMPLETE:
      return endLoadingState(state, 'assigningTags', action.error);

    case Consts.UNASSIGN_TAGS:
      return startLoadingState(state, 'assigningTags');

    case Consts.UNASSIGN_TAGS_COMPLETE:
      return (
        endLoadingState(state, 'assigningTags', action.error)
          // this is for removing entityIds from tags
          .update('assignedEntities', assignedEntities => {
            action.tagIds.forEach(tagId => {
              const key = [tagId.toString(), action.entityType, 'rows'];
              if (assignedEntities.hasIn(key)) {
                assignedEntities = assignedEntities.updateIn(key, List(), entities => {
                  const entityIdx = entities.findIndex(
                    entity => getEntityId(entity, action.entityType) == action.entityId
                  );

                  return entityIdx < 0 ? entities : entities.splice(entityIdx, 1);
                });
              }
            });
            return assignedEntities;
          })
      );

    case Consts.REQUEST_ASSIGNED_TAGS:
      return startLoadingState(state, 'requestingAssignedTags');

    case Consts.REQUEST_ASSIGNED_TAGS_COMPLETE:
      return endLoadingState(state, 'requestingAssignedTags', action.error);

    case Consts.REQUEST_ASSIGNED_ENTITIES:
      return startLoadingState(state, 'requestingAssignedEntities', action.tagId);

    case Consts.REQUEST_ASSIGNED_ENTITIES_COMPLETE: {
      const loadingState = endLoadingState(state, 'requestingAssignedEntities', action.error);
      const assignedKey = ['assignedEntities', action.tagId.toString(), action.entityType];

      return loadingState.setIn(assignedKey, fromJS(action.assignedEntities));
    }

    case Consts.EDIT_TAG:
      return startLoadingState(state, 'editingTag');

    case Consts.EDIT_TAG_COMPLETE: {
      const loadingState = endLoadingState(state, 'editingTag', action.error);

      return action.error
        ? loadingState
        : loadingState.updateIn(['tags', action.tagId.toString()], tag =>
            tag.set('label', action.label).set('color', action.color)
          );
    }

    case Consts.DELETE_TAG_COMPLETE: {
      return state
        .deleteIn(['tags', action.tagId.toString()])
        .update('tagsSummary', tagsSummary => {
          return tagsSummary.filter(tagSummary => {
            return tagSummary.get('tagId') != action.tagId;
          });
        });
    }

    default:
      return state;
  }
}
