exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RXcu5ag6OFq4_tRDp62cI{display:flex;align-items:center;max-height:55px;border-bottom:1px solid #d7d7d7;box-shadow:0 2px 5px rgba(0,0,0,.05);overflow:hidden;height:55px}.pZZOcX6As4ngIMdX1YPQb{margin-left:auto;margin-right:6px}._2Gh-91QPqCckylFCE6p7cd{margin:0}._3asCmojmcIoNKdxeG9Zaiz{display:inline-block;border-radius:0;color:#444;font-size:15px;padding:0 25px;height:53px;margin:0;border-top:3px solid transparent;border-bottom:3px solid transparent;background:transparent}._3asCmojmcIoNKdxeG9Zaiz>span{color:#666;padding:0;vertical-align:initial}._3asCmojmcIoNKdxeG9Zaiz:hover{background:transparent;border-bottom:3px solid #533be2}._3asCmojmcIoNKdxeG9Zaiz:focus{box-shadow:none !important}@media(max-width: 1000px)and (min-width: 910px){._3asCmojmcIoNKdxeG9Zaiz{padding:0 12px}}@media print{.RXcu5ag6OFq4_tRDp62cI{display:none}}", ""]);

// exports
exports.locals = {
	"SecondaryNavigation": "RXcu5ag6OFq4_tRDp62cI",
	"wrapperClass": "pZZOcX6As4ngIMdX1YPQb",
	"buttonsClass": "_2Gh-91QPqCckylFCE6p7cd",
	"buttonClass": "_3asCmojmcIoNKdxeG9Zaiz"
};