export {default} from './layoutsReducer';

export const RECEIVE_LAYOUT = 'layouts:RECEIVE_LAYOUT';
export function receiveLayout(layoutId, layout, reportId, testId) {
  return {
    type: RECEIVE_LAYOUT,
    layoutId,
    layout,
    reportId,
    testId
  };
}

export const FETCH_LAYOUT = 'layouts:FETCH_LAYOUT';
export function fetchLayout(testId, forceGetNewLayout = false) {
  return {
    type: FETCH_LAYOUT,
    testId,
    forceGetNewLayout
  };
}
