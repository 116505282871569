import React, {Fragment} from 'react';
import EffectToggle from './inspectorEffectToggle';
import Dropdown, {DropdownOption} from 'components/dropdown';

export default function DropdownEffectToggle({effectId, config, children}) {
  return (
    <EffectToggle component={DropdownOption} effectId={effectId} config={config}>
      {enabled => (
        <Fragment>
          <input type="checkbox" checked={enabled} readOnly /> {children}
        </Fragment>
      )}
    </EffectToggle>
  );
}
