import React from 'react';
import FormattedDate from 'components/time/formattedDate';

class Date extends React.Component {
  getFormat() {
    const {options} = this.props;

    if (options && options.format) {
      return options.format;
    }

    return 'Do MMM YYYY';
  }

  render() {
    const {data} = this.props;

    if (!data) {
      return null;
    }

    return (
      <div>
        <FormattedDate timestamp={data} format={this.getFormat()} />
      </div>
    );
  }
}

export default Date;
