import React from 'react';
import PropTypes from 'prop-types';
import styles from './accordion.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const Accordion = ({children}) => {
  return <div className={styles.accordion}>{children}</div>;
};

Accordion.propTypes = propTypes;

Accordion.defaultProps = {};

export default Accordion;
