import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './overlay.scss';
import {Portal} from 'react-portal';
import cx from 'classnames';
import Measure from 'react-measure';
import Icon from 'components/font-awesome';

class Overlay extends React.Component {
  static defaultProps = {
    onClickClose: () => null,
    showCloseScrollLimit: 0,
    isOpen: true
  };

  state = {
    yOverflow: false,
    showClose: false
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.handleResize.cancel && this.handleResize.cancel();
  }

  handleScroll = _.throttle(() => {
    if (!this.overlay) {
      this.setState({showClose: true});
      return;
    }
    const {showClose} = this.state;
    const {scrollTop} = this.overlay;
    const shouldShow = scrollTop > this.props.showCloseScrollLimit;

    if (showClose !== shouldShow) {
      this.setState({showClose: shouldShow});
    }
  }, 250);

  handleResize = _.throttle(() => {
    if (!this.overlay) return;
    const height = this.overlay.firstChild.clientHeight;
    this.setState({yOverflow: height >= window.innerHeight});
  }, 250);

  handleClick = event => {
    if (!this.overlay) return;

    event.stopPropagation();

    const {onClick, onSelfClick} = this.props;
    const isSelfClick = this.overlay == event.target;

    if (isSelfClick && onSelfClick) {
      onSelfClick(event);
    } else {
      onClick && onClick(event);
    }
  };

  handleMeasure = () => {
    // We get access to `height` in the arguments from react-measure,
    // but the animation seem to be messing the value up, which affect the centering alg.
    // Instead we use the `clientHeight` of the modal itself

    if (!this.overlay) return;

    const height = this.overlay.firstChild.clientHeight;
    this.setState({yOverflow: height > window.innerHeight});
  };

  setOverlay = ref => {
    this.overlay = ref;
  };

  render() {
    const {className, children, center, subtle, showClose, fade, isOpen, leftMargin55} = this.props;
    const classes = cx(styles.overlay, className, {
      [styles.fade]: fade,
      [styles.center]: center,
      [styles.subtle]: subtle,
      [styles.leftMargin55]: leftMargin55,
      [styles.yOverflow]: this.state.yOverflow
    });

    if (!isOpen) return null;

    return (
      <Portal>
        <div
          ref={this.setOverlay}
          className={classes}
          onClick={this.handleClick}
          onScroll={this.handleScroll}
        >
          <Measure onMeasure={this.handleMeasure} whitelist={['height']}>
            {children}
          </Measure>
          <Icon
            className={cx(styles.closeIcon, {
              [styles.hide]: !(showClose && this.state.showClose)
            })}
            onClick={event => {
              event.stopPropagation();
              this.props.onClickClose();
            }}
            name="times"
            size="2x"
          />
        </div>
      </Portal>
    );
  }
}

export default Overlay;
