import React from 'react';
import styles from './banner.scss';

export default class Banner extends React.Component {
  render() {
    const {src, height, children} = this.props;
    const opacity = this.props.opacity || 0.2;

    const bannerStyles = {
      minHeight: height
    };
    const imageStyles = {
      opacity: opacity,
      backgroundImage: 'url(' + src + ')'
    };

    return (
      <div className={styles.banner} style={bannerStyles}>
        <div className={styles.image} style={imageStyles}></div>
        <div className={styles.inner}>{children}</div>
      </div>
    );
  }
}
