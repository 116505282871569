import React from 'react';
import {sidebarFoldersSelector} from 'modules/folders/folderSelectors';
import {connect} from 'react-redux';
import FolderSidebarItem from './folderSidebarItem';
import styles from './folderSidebarItem.scss';

class SidebarFolders extends React.Component {
  renderItem(folder) {
    const {showFolderSettings} = this.props;

    return (
      <FolderSidebarItem
        {...folder}
        key={folder.folderId}
        showFolderSettings={showFolderSettings}
      />
    );
  }

  renderSubFolders(parentId, folders) {
    return (
      <ConnectedSidebarFolders
        folderId={parentId} // sidebarFoldersSelector is expecting a folderId rather than a parentId
        parentId={parentId}
        key={parentId + 'child'}
        folders={folders}
      />
    );
  }

  render() {
    const {folders = [], isOpen, isRoot} = this.props;

    if ((!isOpen && !isRoot) || !folders || !folders.length) return null;

    return (
      <div className={styles.sidebarFolders}>
        {folders.map(({children, ...folder}) => [
          this.renderItem(folder),
          children && children.length && this.renderSubFolders(folder.folderId, children)
        ])}
      </div>
    );
  }
}

const ConnectedSidebarFolders = connect(sidebarFoldersSelector)(SidebarFolders);
export default ConnectedSidebarFolders;
