import {basePath} from 'modules/auth/authUtils';
import {shallPass, ACCOUNT_OWNER, SUPER_USER} from 'modules/auth/gandalf';

export function usersPath() {
  return basePath(...arguments) + '/settings/account/users';
}

export function usersEndpoint() {
  return 'users';
}

export function userEndpoint({userId}) {
  return usersEndpoint(...arguments) + `/${userId}`;
}

export function isEditingSelf(editeeUser, editorUser) {
  return editorUser.userId === editeeUser.userId;
}

// determine if the editor user should be able to change the editee users password
export function canResetPassword(editeeUser, editorUser) {
  return (
    isEditingSelf(editeeUser, editorUser) ||
    (shallPass(editorUser, ACCOUNT_OWNER) && shallPass(editorUser, editeeUser.role))
  );
}

// determine if the editor user should be able to change the editee users role
export function canEditRole(editeeUser, editorUser) {
  return (
    !isEditingSelf(editeeUser, editorUser) &&
    (shallPass(editorUser, ACCOUNT_OWNER) && shallPass(editorUser, editeeUser.role))
  );
}

export function unlockUserEndpoint() {
  return usersEndpoint(...arguments) + '/send-unlock-email';
}

// Copied from utils/userUtils but not used anywhere
//
// export function hasRole(user, role) {
//   return user.roles && user.roles.indexOf(role) > -1
// }
//
// export function matchesARole(user, roles) {
//   return _.some(roles, role => hasRole(user, role));
// }
