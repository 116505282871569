import React from 'react';

export default class Minutes extends React.Component {
  static defaultProps = {
    minutesOnly: false
  };

  render() {
    const {minutesOnly} = this.props;
    const minutesFraction = this.props.minutes;
    const minutes = Math.floor(minutesFraction);
    const seconds = Math.round((minutesFraction - minutes) * 60);

    return (
      <span>
        {minutes}
        {!minutesOnly && `:${('00' + seconds).slice(-2)}`}
        {minutesOnly && 'm'}
      </span>
    );
  }
}
