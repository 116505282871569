exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._22fyztWmvYqZ3yCUglxG0t{color:#030303}.R8-dcs_7GYVkOSsIpxwXq{color:#666}._2xjdAujVWtuef-LCTr75MS{width:55%}@media(max-width: 768px){._2xjdAujVWtuef-LCTr75MS{width:100%}}", ""]);

// exports
exports.locals = {
	"label": "_22fyztWmvYqZ3yCUglxG0t",
	"muted": "R8-dcs_7GYVkOSsIpxwXq",
	"searchFilter": "_2xjdAujVWtuef-LCTr75MS"
};