import cx from 'classnames';
import Button from 'components/button';
import Center from 'components/center';
import Card from 'components/itemCard/card';
import {TickList, TickListItem} from 'components/layoutComponents/tickList';
import Spinner from 'components/spinner';
import _ from 'lodash';
import {pricingPlans} from 'modules/superProduct/constants';
import React, {Component} from 'react';
import styles from './cardsForCalculator.scss';

class CardsForCalculator extends Component {
  isCalculating = () => {
    const {quotes, loadingQuotes, quoteError} = this.props;

    return !_.size(quotes) || loadingQuotes || quoteError;
  };

  render() {
    const {quotes, onContactUs, numPages} = this.props;
    const quoteIndex = 0; // first value in array is the monthly payment plan
    return (
      <div>
        <div className={styles.wrapper}>
          {quotes.length ? (
            pricingPlans.map((plan, i) => {
              const quote = (quotes[i] && quotes[i][quoteIndex]) || null;

              const isExecutive = plan.name === 'Executive';

              return (
                <Card
                  className={cx(styles.cardWrapper, isExecutive ? styles.popular : '')}
                  key={quote ? quote.quoteTable.amountSubtotal : 'enterprise'}
                >
                  <Center>
                    <div className={styles.card}>
                      {isExecutive && <span className={styles.popularText}>POPULAR</span>}
                      <h4>{plan.name}</h4>
                      {!this.isCalculating() ? (
                        <div className={styles.quote}>
                          <b>{quote ? `$${quote.quoteTable.amountSubtotal}` : "Let's Talk"}</b>
                          {quote && <i>/ mo</i>}
                        </div>
                      ) : (
                        <Spinner className={styles.spinner} />
                      )}
                      {quote ? (
                        <Button
                          onClick={() => {
                            this.props.onSelectBuy(quote.plan.planId, i);
                          }}
                        >
                          Buy now
                        </Button>
                      ) : (
                        <Button onClick={onContactUs}>Contact us</Button>
                      )}
                      {plan.coverDescription}
                      {/* TODO add features */}
                      <TickList className={styles.list}>
                        {plan.covers.map(feature => (
                          <TickListItem hollow key={feature}>
                            {feature}
                          </TickListItem>
                        ))}
                      </TickList>
                      {/* TODO add features */}
                      <ul className={styles.featureList}>
                        <li>{quote ? `${numPages} webpages` : ''}</li>
                        {plan.extras && plan.extras.map(text => <li key={text}>{text}</li>)}
                      </ul>
                    </div>
                  </Center>
                </Card>
              );
            })
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

export default CardsForCalculator;
