exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".b-YlgFdlM4U631h1j3bR1{font-size:12px;padding:1px 5px;border-radius:3px;border-width:1px;border-style:solid;overflow:hidden;font-weight:bold;white-space:nowrap;display:inline-block}", ""]);

// exports
exports.locals = {
	"tag": "b-YlgFdlM4U631h1j3bR1"
};