import {delay} from 'redux-saga';
import {put, select, takeLatest} from 'redux-saga/effects';
import {push} from 'modules/location';
import {SIGNUP, signupComplete} from '..';
import {processRequest} from 'utils/saga/fetchUtils';
import getErrorMessage from 'api/errors';
import handleLoginSuccess from 'modules/auth/sagas/utils/handleLoginSuccess';
import {plainSignupParamsSelector} from 'modules/signup/signupSelectors';

export default function* signupSaga() {
  yield takeLatest([SIGNUP], signup);
}

function* signup({type, ...signupData}) {
  // https://github.com/silktide/chell/issues/1109
  if (!signupData.organizationName) {
    signupData.organizationName = signupData.contactName;
  }

  const signupParams = yield select(plainSignupParamsSelector);

  yield processRequest(
    'POST',
    'sign-up',
    {
      success: function*(res) {
        yield put(signupComplete());
        yield handleLoginSuccess(res);
      },
      error: function*({errorCode, invalidProperty} = {}) {
        const message = getErrorMessage(errorCode, {
          value: signupData[invalidProperty]
        });

        yield put(signupComplete(message));

        // clear the error message after 5 seconds
        yield delay(5000);
        yield put(signupComplete());
      }
    },
    {...signupParams, ...signupData}
  );
}
