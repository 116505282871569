import React from 'react';
import {useDispatch} from 'hooks/redux';
import Button from 'components/button';
import {showSelectWidgetModal} from 'modules/dashboards/actions';

export default function AddWidgetButton({dashboardId, rowIndex, columnIndex, contextTypeId}) {
  const dispatch = useDispatch();

  return (
    <Button
      circle
      icon="plus"
      tooltip="Add widget"
      onClick={() => {
        dispatch(showSelectWidgetModal(dashboardId, rowIndex, columnIndex, contextTypeId));
      }}
    />
  );
}
