import {put} from 'redux-saga/effects';
import {push} from 'modules/location';
import {deleteTheme} from '../actions';
import {searchCampaignEndpoint, searchCampaignsPath} from '../utils';
import {REQUEST_DELETE_THEME} from '../constants';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';

export default generateDeleteSaga('search campaign', REQUEST_DELETE_THEME, searchCampaignEndpoint, {
  success: function*({campaignId}) {
    yield put(deleteTheme(campaignId));
    yield put(push(searchCampaignsPath, {campaignId}, ['reportId']));
  }
});
