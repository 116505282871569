exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3nsEjnfCiXmAC_N8Vixfaa{position:relative}._3nsEjnfCiXmAC_N8Vixfaa:before{display:block;content:\"\";width:100%;padding-top:125%}._3nsEjnfCiXmAC_N8Vixfaa>._3N0KonGuutEoxS0wl-6Wz2{position:absolute;top:0;left:0;right:0;bottom:0}._3nsEjnfCiXmAC_N8Vixfaa ._36Hg8Dkvgrqo0mR6A5R2dF{position:absolute;top:25%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}", ""]);

// exports
exports.locals = {
	"imgPlaceholder": "_3nsEjnfCiXmAC_N8Vixfaa",
	"content": "_3N0KonGuutEoxS0wl-6Wz2",
	"spinner": "_36Hg8Dkvgrqo0mR6A5R2dF"
};