import {LOGIN} from 'modules/auth';
import {trackEvent} from 'modules/tracking';
import {LOCATION_CHANGE} from 'connected-react-router';
import _ from 'lodash';

const debounceTrackEvent = _.debounce(trackEvent, 500);

export default function trackingMiddleware() {
  return store => next => action => {
    switch (action.type) {
      case LOGIN:
        const {user, token} = action;

        if (token && _.isObject(user)) {
          trackEvent('Logged in', {fromFile: 'trackingMiddleware.js'}, true);
        }
        break;

      case LOCATION_CHANGE:
        const {payload} = action;

        // Route changing can be very spammy, we want the final route, not really the ten different routes it may have hit within a few  milliseconds redirecting.
        debounceTrackEvent('Route change', {
          location: payload.location.pathname,
          fromFile: 'trackingMiddleware.js'
        });
        break;
    }

    next(action);
  };
}
