import {fromJS, List} from 'immutable';
import * as Consts from './constants';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  digests: {},
  digestSubscriptions: {},
  originalDigestSubscriptions: {},

  // unsaved state
  unsavedDigestSubscriptions: {},
  removeDigestSubscriptions: {},

  loadingDigests: false,
  digestsError: null,

  savingDigestSubscriptions: false,
  saveDigestSubscriptionsError: null,

  loadingDigestSubscriptions: false,
  digestSubscriptionsError: null,
  lastReceivedDigestSubscriptions: null
});

export default function digestsReducer(state = initialState, action) {
  switch (action.type) {
    case Consts.REQUEST_DIGESTS:
      return state.set('loadingDigests', true).set('digestsError', null);

    case Consts.REQUEST_DIGESTS_COMPLETE:
      return state.set('loadingDigests', false).set('digestsError', action.error);

    case Consts.RECEIVE_DIGESTS:
      return state.mergeIn(['digests'], keyBy(action.digests, 'digestId'));

    case Consts.REQUEST_DIGEST_SUBSCRIPTIONS:
      return state.set('loadingDigestSubscriptions', true).set('digestSubscriptionsError', null);

    case Consts.REQUEST_DIGEST_SUBSCRIPTIONS_COMPLETE:
      return state
        .set('loadingDigestSubscriptions', false)
        .set('digestSubscriptionsError', action.error);

    case Consts.RECEIVE_DIGEST_SUBSCRIPTIONS: {
      const lastReceivedState = state.set('lastReceivedDigestSubscriptions', Date.now());
      const keyedDigestSubs = keyBy(action.digestSubscriptions, 'digestSubscriptionId');

      return lastReceivedState
        .setIn(['digestSubscriptions', action.userId], keyedDigestSubs)
        .setIn(['originalDigestSubscriptions', action.userId], keyedDigestSubs);
    }

    case Consts.SAVE_DIGEST_SUBSCRIPTIONS:
      return state.set('savingDigestSubscriptions', true).set('saveDigestSubscriptionsError', null);

    case Consts.SAVE_DIGEST_SUBSCRIPTIONS_COMPLETE:
      return state
        .set('savingDigestSubscriptions', false)
        .set('saveDigestSubscriptionsError', action.error);

    case Consts.ADD_UNSAVED_DIGEST_SUBSCRIPTION:
      if (!state.hasIn(['unsavedDigestSubscriptions', action.userId])) {
        return state.setIn(
          ['unsavedDigestSubscriptions', action.userId],
          fromJS([action.digestSubscription])
        );
      } else {
        return state.updateIn(
          ['unsavedDigestSubscriptions', action.userId],
          unsavedDigestSubscriptions =>
            unsavedDigestSubscriptions.push(fromJS(action.digestSubscription))
        );
      }

    case Consts.EDIT_DIGEST_SUBSCRIPTION: {
      const {digestSubscriptionId} = action.digestSubscription;
      if (digestSubscriptionId) {
        // editting a saved digestSubscription
        return state.setIn(
          ['digestSubscriptions', action.userId, digestSubscriptionId.toString()],
          fromJS(action.newData)
        );
      } else {
        // editting an unsaved digestSubscription
        return state.updateIn(['unsavedDigestSubscriptions', action.userId], unsavedDigestSubs => {
          return unsavedDigestSubs.map(sub => {
            // if sub matches action.digestSubscription, modify
            if (unsavedSigestSubscriptionMatches(sub, action.digestSubscription)) {
              return fromJS(action.newData);
            } else {
              return sub;
            }
          });
        });
      }
    }

    case Consts.REMOVE_UNSAVED_DIGEST_SUBSCRIPTIONS:
      return state
        .removeIn(['unsavedDigestSubscriptions', action.userId])
        .removeIn(['removeDigestSubscriptions', action.userId]);

    case Consts.REMOVE_DIGEST_SUBSCRIPTION:
      // if no digestSubscriptionId then this is an unsaved description
      if (!action.digestSubscription.digestSubscriptionId) {
        return state.updateIn(
          ['unsavedDigestSubscriptions', action.userId],
          unsavedDigestSubscriptions => {
            return unsavedDigestSubscriptions.filter(unsavedDigestSubscription => {
              return !unsavedSigestSubscriptionMatches(
                unsavedDigestSubscription,
                action.digestSubscription
              );
            });
          }
        );
      }

      // if there is a digestSubscriptionId, then we add to temporary store to remove
      if (!state.hasIn(['removeDigestSubscriptions', action.userId])) {
        return state.setIn(
          ['removeDigestSubscriptions', action.userId],
          List([action.digestSubscription.digestSubscriptionId])
        );
      } else {
        return state.updateIn(
          ['removeDigestSubscriptions', action.userId],
          removeDigestSubscriptions =>
            removeDigestSubscriptions.push(action.digestSubscription.digestSubscriptionId)
        );
      }
    default:
      return state;
  }
}

function unsavedSigestSubscriptionMatches(unsavedDigestSubscription, matchingDigestSubscription) {
  return (
    matchingDigestSubscription.digestId === unsavedDigestSubscription.get('digestId') &&
    matchingDigestSubscription.segmentId === unsavedDigestSubscription.get('segmentId') &&
    matchingDigestSubscription.schedulePreset === unsavedDigestSubscription.get('schedulePreset') &&
    fromJS(matchingDigestSubscription.options).equals(unsavedDigestSubscription.get('options'))
  );
}
