exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2QtDYEcuT2vQtph7IWXP3I{background:#55d272;border-radius:50%;color:#fff;display:flex;font-size:13px;justify-content:center;align-items:center;height:23px;min-width:23px;width:23px}._2QtDYEcuT2vQtph7IWXP3I::before{position:relative;top:1px}", ""]);

// exports
exports.locals = {
	"tickIcon": "_2QtDYEcuT2vQtph7IWXP3I"
};