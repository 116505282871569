import PropTypes from 'prop-types';
import React from 'react';
import styles from 'modules/form/components/field.scss';
import InputForType from 'modules/form/components/inputForType';
import Gandalf from 'components/gandalf';
import cx from 'classnames';
import Help from 'components/help';
import {validateField, getValidatorsFromProps} from 'modules/form/formUtils';
import {escapeRegex} from 'utils/stringUtils';

export default class Field extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    customMessages: PropTypes.object,
    validationMessage: PropTypes.string
  };

  static defaultProps = {
    customMessages: {}
  };

  state = {
    validationMessage: null,
    showValidation: true
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleChange = event => {
    const {onChange} = this.props;

    // we are expecting a value, but we can support event objects
    const value = event && event.target ? event.target.value : event;

    // this will trigger a render within the Form component,
    // with `this.props.value` eventually becoming `value`
    onChange && onChange(value);
  };

  handleFocus = event => {
    const {onFocus} = this.props;
    this.setState({showValidation: false});
    onFocus && onFocus(event);
  };

  handleBlur = event => {
    const {onBlur} = this.props;
    onBlur && onBlur(event);

    // some third part fields change the value *after* the blur event,
    // so we have to wait just a little to ensure we get the updated value
    this.timeout = setTimeout(() => {
      this.validate(true);
    }, 0);
  };

  validate(updateField = false) {
    // hidden fields cannot be validated, as there is no UI for the user to correct the error
    if (this.props.type == 'hidden' || this.props.hide) {
      return [true, undefined];
    }

    const {isValid, message} = validateField(
      this.getValue(),
      getValidatorsFromProps(this.props),
      this.props.customMessages,
      this.props.type
    );

    if (!isValid) {
      if (updateField) {
        this.setMessage({message, isValid});
      }
      return [isValid, message];
    }

    // if we are still valid, we can check the isValid function of the *Input.js
    return this.checkInnerInputValidation(updateField);
  }

  checkInnerInputValidation(updateField) {
    const isValidFunction =
      this.typeRef &&
      this.typeRef.inputRef &&
      this.typeRef.inputRef.isValid &&
      this.typeRef.inputRef.isValid.bind(this.typeRef.inputRef);

    const {isValid, message} = isValidFunction
      ? isValidFunction()
      : {isValid: true, message: undefined};

    if (updateField) {
      this.setMessage({message, isValid});
    }

    return [isValid, message];
  }

  getValue() {
    const {value, separateBy} = this.props;

    if (typeof value == 'string' && separateBy) {
      if (Array.isArray(separateBy)) {
        // trying to achieve split by multiple delimeters `value.split(/,| /)`
        return this.props.value
          .split(new RegExp(separateBy.map(escapeRegex).join('|')))
          .filter(Boolean);
      } else if (typeof separateBy == 'string') {
        return this.props.value.split(separateBy).filter(Boolean);
      }
    }
    return value;
  }

  setMessage = ({message, isValid}) => {
    this.setState({
      showValidation: true,
      validationMessage: isValid ? undefined : message
    });
  };

  renderMessages() {
    const {floatingMessage} = this.props;
    const {showValidation, validationMessage} = this.state;

    // use the validation message override if it is set
    if (this.props.validationMessage || (showValidation && validationMessage)) {
      return (
        <div className={cx(styles.validationMessages, {[styles.floatingMessage]: floatingMessage})}>
          {this.props.validationMessage || validationMessage}
        </div>
      );
    }

    return null;
  }

  renderInput(props) {
    const {showValidation, validationMessage} = this.state;
    const {separateBy} = this.props;
    var maxLength;

    // remove maxLength from the field if `separateBy` is present
    if (
      Array.isArray(separateBy) ||
      (typeof separateBy === 'string' && typeof this.props.maxLength !== 'undefined')
    ) {
      ({maxLength, ...props} = props);
    }

    return (
      <InputForType
        {...props}
        ref={ref => {
          this.typeRef = ref;
        }}
        invalid={(validationMessage && showValidation) || this.props.invalid}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }

  renderField() {
    const {label, help, inline, muted, className, noMargin, ...inputProps} = this.props;

    if (inputProps.type == 'hidden') {
      return this.renderInput(inputProps);
    }

    return (
      <div
        className={cx(
          styles.formField,
          {[styles.inline]: inline, [styles.noMargin]: noMargin},
          className
        )}
      >
        {label && (
          <label className={styles.label}>
            {label}
            {help && <Help message={help} />}
          </label>
        )}

        {this.renderInput(inputProps)}
        {muted && <p className={styles.muted}>{muted}</p>}

        {this.renderMessages()}
      </div>
    );
  }

  render() {
    const {shallPass, hide} = this.props;

    if (hide) return null;

    if (shallPass) {
      return <Gandalf shallPass={shallPass}>{this.renderField()}</Gandalf>;
    }

    return this.renderField();
  }
}
