import {select, put, takeLatest} from 'redux-saga/effects';
import {
  DELETE_ACCOUNT_BRANDING_LOGO,
  deleteAccountBrandingLogoComplete,
  deleteAccountOption
} from 'modules/accountOptions';
import {BRANDING_LOGO_URL} from 'modules/accountOptions/accountOptionConstants';
import requestDeleteAccountOption from './helpers/deleteAccountOption';
import {showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';

export default function* deleteAccountBrandingLogoSaga() {
  yield takeLatest(DELETE_ACCOUNT_BRANDING_LOGO, deleteAccountBrandingLogo);
}

const optionId = BRANDING_LOGO_URL;

function* deleteAccountBrandingLogo() {
  const accountId = yield select(accountIdSelector);

  try {
    yield* requestDeleteAccountOption({accountId, optionId});

    yield put(deleteAccountOption(optionId));
  } catch (err) {
    yield showError(couldNot('delete branding image'));
  } finally {
    yield put(deleteAccountBrandingLogoComplete());
  }
}
