import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'modules/location/linkButton';
import {editLeaderboardPath} from 'modules/leaderboards/utils';
import Gandalf from 'components/gandalf/gandalf';
import {SITE_CREATOR} from 'modules/auth/gandalf';

// editLeaderboardPath also allows this to be an "Add new leaderboard" button
function EditLeaderboardButton({children, ...props}) {
  return (
    <Gandalf shallPass={SITE_CREATOR}>
      <LinkButton builder={editLeaderboardPath} setQuery={{}} {...props}>
        {children || 'Edit leaderboard'}
      </LinkButton>
    </Gandalf>
  );
}

EditLeaderboardButton.propTypes = {
  leaderboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EditLeaderboardButton;
