import React from 'react';
import cx from 'classnames';
import styles from './videoCaptions.scss';

function VideoParagraph({time, parts}) {
  return (
    <p>
      {parts.map(({startTime, endTime, text}) => (
        <span
          key={startTime}
          className={cx({[styles.active]: time >= startTime && time < endTime})}
        >
          {' '}
          {text}{' '}
        </span>
      ))}
    </p>
  );
}

export default function VideoCaptions({time, srt}) {
  return (
    <div className={styles.wrapper}>
      {srt.map(parts => (
        <VideoParagraph time={time} parts={parts} key={parts[0].startTime} />
      ))}
    </div>
  );
}
