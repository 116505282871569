exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._23FOOeP8oMCo2wYl0tR2-N{display:flex;margin:0 -10px}._1B8-83EABOElyHCeC2Yvzh{width:140px;padding:15px;background:#fff;margin:10px;text-align:center;box-shadow:0px 2px 4px rgba(0,0,0,.3)}._1B8-83EABOElyHCeC2Yvzh>div{margin-top:10px;font-size:15px}._1B8-83EABOElyHCeC2Yvzh.qb4fTZkW9MpvMpnEldTKQ{color:#fff;background:#533be2;box-shadow:inset 0px 2px 4px rgba(0,0,0,.3)}._1B8-83EABOElyHCeC2Yvzh ._3cj_aSODK_l37b7E_dp-2d{font-size:60px;opacity:.5}", ""]);

// exports
exports.locals = {
	"container": "_23FOOeP8oMCo2wYl0tR2-N",
	"option": "_1B8-83EABOElyHCeC2Yvzh",
	"active": "qb4fTZkW9MpvMpnEldTKQ",
	"icon": "_3cj_aSODK_l37b7E_dp-2d"
};