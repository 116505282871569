import _ from 'lodash';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import {setConfigOptions} from 'modules/configOptions';
import {receiveDefaultFilters} from 'modules/filters';
import {defaultFilterSelector} from 'modules/filters/filterSelectors';
import {FETCH_LAYOUT, receiveLayout} from 'modules/layouts';
import {testLayoutSelector} from 'modules/layouts/layoutsSelectors';
import {locationParamsSelector} from 'modules/location/locationSelectors';
import {RECEIVE_REPORT} from 'modules/reports/reportConstants';
import {reportIdParamSelector} from 'modules/reports/reportSelectors';
import {receiveResults} from 'modules/results';
import {VIEW_TEST} from 'modules/tests/testConstants';
import {testSelector} from 'modules/tests/testSelectors';
import {
  getVisibleResultIds,
  reportResultsEndpoint,
  testLayoutEndpoint,
  testResultsEndpoint,
  willHaveLayout
} from 'modules/tests/testUtils';
import {put, select, takeLatest} from 'redux-saga/effects';
import {layoutConfigComponentToOptions} from 'utils/layoutUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import selectOrWait from 'utils/saga/selectOrWait';

// A test doesn't contain a layout.
// The first time we view a test, we must download the layout and initial results.
export default function* fetchLayoutAndResultsSaga() {
  yield takeLatest([VIEW_TEST, FETCH_LAYOUT], fetchLayoutAndResults);
}

function* fetchLayoutAndResults(action) {
  let {testId, filters} = action;

  const defaultFilters = yield select(defaultFilterSelector);
  filters = {...defaultFilters, ...filters};

  const reportId = yield select(reportIdParamSelector);
  const params = yield select(locationParamsSelector);
  const test = yield selectOrWait(testSelector, RECEIVE_REPORT, {testId});

  // if the test doesn't have a layout, ignore it
  if (!willHaveLayout(test)) return;

  // have we loaded the layout yet?
  const layoutForTest = yield select(testLayoutSelector);

  const forceFetchLayout = yield select(isArchiveActiveSelector) || action.forceGetNewLayout;

  if (!layoutForTest || forceFetchLayout) {
    // load the layout (could include results)
    yield requestLayout(reportId, testId, filters);
  } else {
    const resultIds = getVisibleResultIds(layoutForTest, params, filters);
    if (!resultIds || !resultIds.length) return;

    const results = yield fetchResultsByIds(reportId, resultIds, filters);

    yield put(receiveResults(results));
  }

  // Fetch the config values defined inside this layout
  // yield fetchLayoutConfig(reportId, testId);
}

function* requestLayout(reportId, testId, filters) {
  const layoutResponse = yield fetchLayout(reportId, testId, filters);

  // if the layout response contains `results`, then the layout is contained
  // within `layout`, otherwise the entire response IS the layout
  const layoutObject = layoutResponse.results ? layoutResponse.layout : layoutResponse;
  const resultFilters = {...layoutObject.defaultFilters, ...filters};

  yield storeLayout(reportId, testId, layoutObject);

  // These are the result IDs that we already have (don't need to request)
  const resultsAlreadyGot =
    layoutResponse.results && layoutResponse.results.length
      ? layoutResponse.results.map(result => result.resultId)
      : [];

  // The results we get are only initial results (IE speedy results that we can get quickly - for performance)
  if (resultsAlreadyGot.length) {
    yield put(receiveResults(layoutResponse.results));
  }

  // These are the immediate resultIds we need to fetch. We may already have them in `resultsAlreadyGot`
  const resultToGetFirst = layoutObject.immediateResultIds.filter(
    resultId => !resultsAlreadyGot.includes(resultId)
  );

  // These are the resultIds minus any `resultsAlreadyGot` and `immediateResultIds`
  const resultsToGet = layoutObject.resultIds.filter(
    resultId => !resultsAlreadyGot.includes(resultId) && !resultToGetFirst.includes(resultId)
  );

  yield fetchLayoutResults(reportId, resultToGetFirst, resultFilters);
  yield fetchLayoutResults(reportId, resultsToGet, resultFilters);
}

function* fetchLayoutResults(reportId, resultIds, filters) {
  if (resultIds.length) {
    const results = yield fetchResultsByIds(reportId, resultIds, filters);

    if (results.length) {
      yield put(receiveResults(results));
    }
  }
}

function* fetchLayoutConfig(reportId, testId) {
  const layout = yield select(testLayoutSelector);
  const options = layoutConfigComponentToOptions(testId, layout);

  if (options.length) {
    const config = yield fetchConfig(reportId, options);

    if (config && config.options) {
      yield put(setConfigOptions(config.options));
    }
  }
}

/* Redux functions */

function* storeLayout(megaId, testId, layoutObject) {
  const {layoutId, defaultFilters} = layoutObject;
  yield put(receiveLayout(layoutId, layoutObject, reportId, testId));

  const reportId = megaId.indexOf('-') >= 0 ? megaId.split('-', 2)[0] : megaId;
  yield put(receiveDefaultFilters(reportId, testId, defaultFilters));
}

/* Endpoint functions */

// fetch all the results for a test
function* fetchConfig(reportId, options) {
  const endpoint = 'reports/' + reportId + '/configOptions';
  return yield processRequest('GET', endpoint, {}, {options});
}

// The old layout endpoint doesn't require `filters`, but the new layouts endpoint
// fetches results at the same time as fetching the layout so it needs `filters`.
function* fetchLayout(reportId, testId, filters) {
  const endpoint = testLayoutEndpoint({reportId, testId});
  return yield processRequest('GET', endpoint, {}, filters);
}

export function* fetchResults(reportId, testId, filters) {
  const endpoint = testResultsEndpoint({reportId, testId, filters});
  return yield processRequest('GET', endpoint, {}, {filters});
}

export function* fetchResultsByIds(reportId, resultIds, filters) {
  if (!resultIds.length) return [];

  const endpoint = reportResultsEndpoint({reportId});
  return yield processRequest('POST', endpoint, {}, {resultIds, filters});
}
