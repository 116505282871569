exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wtNdq8Yu5KWADM-UgObil{text-align:center}.wtNdq8Yu5KWADM-UgObil p{margin:10px 0 0}.wtNdq8Yu5KWADM-UgObil img{-o-object-fit:scale-down;object-fit:scale-down;max-height:100px}._1uMTtxDsCMNayokRG6JVou{margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"wrapper": "wtNdq8Yu5KWADM-UgObil",
	"open": "_1uMTtxDsCMNayokRG6JVou"
};