import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {removeReportFromFolder} from 'modules/reports';
import {REQUEST_REMOVE_REPORT_FROM_FOLDER} from 'modules/reports/reportConstants';
import {reportFoldersEndpoint} from 'modules/reports/reportUtils';

export default function* removeReportFromFolderSaga() {
  yield takeLatest(REQUEST_REMOVE_REPORT_FROM_FOLDER, removeReportFolder);
}

function* removeReportFolder({reportId, folderId}) {
  const url = reportFoldersEndpoint({reportId, folderId});

  yield processRequest('DELETE', url, {
    success: function*(data) {
      yield put(removeReportFromFolder(reportId, folderId));
    },

    errorMessage: 'Something went wrong'
  });
}
