import React, {Fragment} from 'react';
import InspectorActionPointRow from 'modules/inspector/components/sidebar/inspectorActionPointRow';
import posed, {PoseGroup, tween} from 'react-pose';

const Item = posed.div({
  enter: {x: 0, opacity: 1},
  exit: {x: '-100%', opacity: 0},
  flip: {
    transition: tween
  }
});

export default function PointsList({action, points}) {
  return (
    <Fragment>
      {points.map(point => (
        <Item key={point.get('pointId')}>
          <InspectorActionPointRow
            key={`point_${point.get('pointId')}`}
            point={point}
            action={action}
          />
        </Item>
      ))}
    </Fragment>
  );
}
