import React from 'react';
import {connect} from 'react-redux';
import SettingsPage from 'components/settings/settingsPage';
import AvailableChecks from 'modules/tests/components/availableChecks';
import PageHeader from 'components/header/pageHeader';
import {checkSettingsPageSelector} from 'modules/checks/checkSelectors';
import {Form, Field} from 'modules/form/components';
import {requestChecks} from 'modules/checks';
import PaginationComponent from 'components/pagination';
import Pill from 'components/pill';
import Flex from 'components/layout/flex';
import Select from 'components/select';
import Spinner from 'components/spinner';
import styles from './checkSettingsPage.scss';
import Status from 'components/status/status';

export class CheckSettingsPage extends React.Component {
  static defaultProps = {
    title: 'Enabled checks for all websites'
  };

  state = {
    pageNo: 1,
    rowsPerPage: 50,
    filters: {name: '', testIds: ''},
    numRowsOptions: [
      {value: 5, label: '5'},
      {value: 10, label: '10'},
      {value: 20, label: '20'},
      {value: 50, label: '50'},
      {value: 100, label: '100'},
      {value: 250, label: '250'}
    ]
  };

  componentWillMount() {
    this.props.requestChecks();
  }

  handleChangeFilter = (filterName, value) => {
    const filters = {...this.state.filters, [filterName]: value};
    this.setState({filters, pageNo: 1});
  };

  filterCheck = check => {
    const {name, testIds} = this.state.filters;
    const nameIsEmpty = !name || !name.trim().length;
    const testIdsAreEmpty = !testIds || !testIds.length;
    return (
      (nameIsEmpty ||
        check
          .get('name')
          .toLowerCase()
          .includes(name.trim().toLowerCase())) &&
      (testIdsAreEmpty || check.get('testIds').some(testId => testIds.includes(testId)))
    );
  };

  renderHeader(numRows) {
    const {title, availableTestOptions} = this.props;

    return (
      <PageHeader title={title}>
        <p className={styles.message}>
          You can enable or disable which checks are used for all websites here. This won't affect
          existing reports until they are retested.
        </p>

        <Form inline className={styles.filterBar} onChange={this.handleChangeFilter}>
          <Field label="Filter by name" name="name" type="text" />
          <Field
            className={styles.testsFilter}
            label="Test"
            name="testIds"
            type="multiselect"
            options={availableTestOptions}
            clearable
          />
          <Flex container>
            <Pill appearance="major" style={{margin: '0 10px 10px 10px'}}>
              {numRows} results
            </Pill>
          </Flex>
        </Form>
      </PageHeader>
    );
  }

  fixBullshitHTMLScroll = () => {
    // TODO Please don't judge me. I am a good person, honest. On the inside. [AM]
    //
    // The AvailableChecks page scrolls when we click on the select box at the bottom of the page.
    // This is not easy to fix without rewriting <Select /> to use <Position /> or preventing the
    // scroll in the first place (which is no so easy)

    document.querySelector('html').scrollTop = 0;
  };

  renderPaginationBar(numRows) {
    const {pageNo, rowsPerPage, numRowsOptions} = this.state;
    const maxPage = Math.ceil(numRows / rowsPerPage);

    if (maxPage <= 1) {
      return null;
    }

    return (
      <Flex container className={styles.marginBottom}>
        <Flex container>
          <PaginationComponent
            inline
            page={pageNo}
            numRows={numRows}
            rowsPerPage={rowsPerPage}
            maxPage={maxPage}
            onPageSelect={pageNo => {
              this.setState({pageNo});
            }}
          />
        </Flex>
        <Flex container>
          <p className={styles.resultsPerPage}>Results per page</p>
        </Flex>
        <Flex className={styles.marginRight}>
          <Select
            options={numRowsOptions}
            onChange={option => {
              this.setState({rowsPerPage: option.value, pageNo: 1});
            }}
            onOpen={this.fixBullshitHTMLScroll}
            onClose={this.fixBullshitHTMLScroll}
            value={rowsPerPage}
            clearable={false}
            disableFiltering
          />
        </Flex>
      </Flex>
    );
  }

  renderContent(filteredChecks) {
    const {checks} = this.props;

    if (!checks.size) {
      return <Spinner marginTop={100} />;
    }

    const numRows = filteredChecks.size;

    if (numRows === 0) {
      return (
        <Status appearance="good">There are no results with the filters you have selected</Status>
      );
    }

    const {pageNo, rowsPerPage} = this.state;
    const startIdx = (pageNo - 1) * rowsPerPage;

    return (
      <div>
        <AvailableChecks checks={filteredChecks.slice(startIdx, startIdx + rowsPerPage)} />

        {this.renderPaginationBar(numRows)}
      </div>
    );
  }

  render() {
    const {checks} = this.props;
    const filteredChecks = checks.filter(this.filterCheck);

    return (
      <SettingsPage pageHeader={this.renderHeader(filteredChecks.size)}>
        {this.renderContent(filteredChecks)}
      </SettingsPage>
    );
  }
}

export default connect(
  checkSettingsPageSelector,
  {requestChecks}
)(CheckSettingsPage);
