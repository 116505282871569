export const ADD_REPORT = 'reports:ADD_REPORT';
export const ADD_REPORT_FAILURE = 'reports:ADD_REPORT_FAILURE';
export const REPORTS_REQUEST = 'reports:REPORTS_REQUEST';
export const REPORTS_RESPONSE = 'reports:REPORTS_RESPONSE';
export const DELETE_REPORT = 'reports:DELETE_REPORT';
export const CONFIRM_DELETE_REPORT = 'reports:CONFIRM_DELETE_REPORT';
export const UPDATE_REPORT = 'reports:UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'reports:UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = 'reports:UPDATE_REPORT_FAILURE';
export const UPDATE_REPORT_RESET = 'reports:UPDATE_REPORT_RESET';
export const REQUEST_ADD_REPORT = 'REPORTS:REQUEST_ADD_REPORT';
export const VIEW_REPORT_PAGE = 'reports:VIEW_REPORT_PAGE';
export const RECEIVE_CMS_CONFIG = 'reports:RECEIVE_CMS_CONFIG';
export const CLEAR_CMS_CONFIG = 'reports:CLEAR_CMS_CONFIG';
export const FETCH_REPORT = 'reports:FETCH_REPORT';
export const FETCH_REPORTS = 'reports:FETCH_REPORTS';
export const LEAVE_REPORT_PAGE = 'reports:LEAVE_REPORT_PAGE';
export const VIEW_REPORT_INDEX_PAGE = 'reports:VIEW_REPORT_INDEX_PAGE';
export const VIEW_REPORT_LIST = 'reports:VIEW_REPORT_LIST';
export const VIEW_REPORT_SETTINGS_PAGE = 'reports:VIEW_REPORT_SETTINGS_PAGE';
export const VIEW_REPORT_CMS_SETTINGS_PAGE = 'reports:VIEW_REPORT_CMS_SETTINGS_PAGE';
export const REQUEST_RETEST_REPORT = 'reports:REQUEST_RETEST_REPORT';
export const RETEST_REPORT = 'reports:RETEST_REPORT';
export const REPORT_RETEST_BEGAN = 'reports:REPORT_RETEST_BEGAN';
export const RETEST_PAGE = 'reports:RETEST_PAGE';
export const REQUEST_CHANGE_PAGE_STATE = 'reports:REQUEST_CHANGE_PAGE_STATE';
export const REQUEST_EDIT_PAGE = 'reports:REQUEST_EDIT_PAGE';
export const RECEIVE_REPORTS = 'reports:RECEIVE_REPORTS';
export const RECEIVE_REPORT = 'reports:RECEIVE_REPORT';
export const RECEIVE_EXTRA_INFO = 'reports:RECEIVE_EXTRA_INFO';
export const REPORT_DROP = 'reports:REPORT_DROP';
export const REPORT_TO_FOLDER = 'reports:REPORT_TO_FOLDER';
export const REQUEST_REMOVE_REPORT_FROM_FOLDER = 'reports:REQUEST_REMOVE_REPORT_FROM_FOLDER';
export const REMOVE_REPORT_FROM_FOLDER = 'reports:REMOVE_REPORT_FROM_FOLDER';
export const TRIGGER_EXPORT_REPORT = 'reports:TRIGGER_EXPORT_REPORT';
export const TRIGGER_EXPORT_EXPLORER = 'reports:TRIGGER_EXPORT_EXPLORER';
export const EXPORT_REPORT = 'reports:EXPORT_REPORT';
export const EXPORT_REPORT_PDF = 'reports:EXPORT_REPORT_PDF';
export const EXPORT_REPORT_COMPLETE = 'reports:EXPORT_REPORT_COMPLETE';
export const VIEW_PRINT_TESTS_PAGE = 'reports:VIEW_PRINT_TESTS_PAGE';
export const UPDATE_WATCHING_REPORT = 'reports:UPDATE_WATCHING_REPORT';
export const SET_WATCHING_REPORT = 'reports:SET_WATCHING_REPORT';
export const CLEAR_REPORT = 'reports:CLEAR_REPORT';
export const UPDATE_REPORT_SCHEDULE = 'reports:UPDATE_REPORT_SCHEDULE';
export const UPDATE_REPORT_SCHEDULE_VALUE = 'reports:UPDATE_REPORT_SCHEDULE_VALUE';
export const FETCH_REPORT_SCHEDULE = 'reports:FETCH_REPORT_SCHEDULE';
export const RECIEVE_REPORT_ASSIGNED_TAGS = 'reports:RECIEVE_REPORT_ASSIGNED_TAGS';
export const FETCH_FORCED_PAGES = 'reports:FETCH_FORCED_PAGES';
export const RECEIVE_FORCED_PAGES = 'reports:RECEIVE_FORCED_PAGES';
export const RECEIVE_FORCED_PAGE = 'reports:RECEIVE_FORCED_PAGE';
export const ADD_FORCED_PAGE = 'reports:ADD_FORCED_PAGE';
export const EDIT_FORCED_PAGE = 'reports:EDIT_FORCED_PAGE';
export const DELETE_FORCED_PAGE = 'reports:DELETE_FORCED_PAGE';
export const CONFIRM_DELETE_FORCED_PAGE = 'reports:CONFIRM_DELETE_FORCED_PAGE';

export const EXPORT_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
  EXCEL: 'excel',
  PRINT: 'print',
  SHARE: 'share'
};

// These have been configured to work directly with wkhtmltopdf.
// The string values must correspond with the `--page-size` option.
//
// CLI --page-size option (http://wkhtmltopdf.org/usage/wkhtmltopdf.txt)
// C++ enum `pageSizeMap` (https://github.com/wkhtmltopdf/wkhtmltopdf/blob/master/src/lib/pdfsettings.cc)
export const PAGE_SIZES = {
  A4: 'A4',
  LETTER: 'Letter'
};

export const EXPORT_TESTS = {
  ALL: 'all',
  THIS: 'this',
  CHOOSE: 'choose'
};

// export const requireTestSidebarIcon = require.context(
//   'modules/app/components/mainSidebar/icons',
//   false,
//   /^\.\/nav_icon_.+\.svg/
// );

export function getTestSidebarIcon(testId) {
  return null;
  // const normalisedTestId = testId.replace(/^comparison-/, '').replace(/^demo-/, '');
  // if (!TestIconPaths[normalisedTestId]) return null;
  // return requireTestSidebarIcon('./' + TestIconPaths[normalisedTestId]);
}

// export const TestIconPaths = {
//   seo: 'nav_icon_SEO.svg',
//   gdpr: 'nav_icon_GDPR.svg',
//   speed: 'nav_icon_speed.svg',
//   faults: 'nav_icon_faults.svg',
//   mobile: 'nav_icon_mobile.svg',
//   writing: 'nav_icon_writing.svg',
//   grammar: 'nav_icon_writing.svg',
//   spelling: 'nav_icon_writing.svg',
//   mentions: 'nav_icon_mentions.svg',
//   inventory: 'nav_icon_inventory.svg',
//   'mobile-speed': 'nav_icon_speed.svg',
//   'desktop-speed': 'nav_icon_speed.svg',
//   accessibility: 'nav_icon_accessibility.svg',
//   'broken-links': 'nav_icon_broken-links.svg',
//   'best-practice': 'nav_icon_best-practice.svg',
//   'search-ranking': 'nav_icon_search-ranking.svg'
// };
