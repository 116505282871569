import PropTypes from 'prop-types';
import React from 'react';

class DrillDown extends React.Component {
  render() {
    const description = this.props.options.description;

    return <span>{description}</span>;
  }
}

DrillDown.propTypes = {};

export default DrillDown;
