exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-hxp6Nc95lUaLQsulF2sh{position:fixed;bottom:0;right:0;width:25vw;min-width:250px;z-index:9999;border-radius:4px}._1NDPnE5Hb_FVT9_9oeFqq{cursor:pointer;display:block;background:#533be2;padding:15px;margin:20px;color:#fff;border-radius:4px;-webkit-filter:blur(0);filter:blur(0);box-shadow:10px 10px 10px rgba(0,0,0,.4);transition:-webkit-transform .2s ease-out;transition:transform .2s ease-out;transition:transform .2s ease-out, -webkit-transform .2s ease-out;-webkit-transform:scale(1);transform:scale(1)}._1NDPnE5Hb_FVT9_9oeFqq:hover{-webkit-transform:scale(1.1);transform:scale(1.1)}.iQ66CsPkWiR2Q48hR5h4-{margin-right:10px}._2mjEoj8fWdtI37r59rSlYP{background:#15b272}._3DbdCt5JKAsuhDF-jyxdPG{background:#df1616}._3j6ykGR7_2JgYjbgAwxk4E,._3RQFQTQGAJbiD-7RO5I74l,.hYzcolJQJxgq5HRHIZ7fK._1_zF-u9bw9KKnU5C971_Aw{transition:-webkit-transform max-height .2s ease-out;transition:transform max-height .2s ease-out;transition:transform max-height .2s ease-out, -webkit-transform max-height .2s ease-out;-webkit-transform:scaleY(0);transform:scaleY(0)}._3j6ykGR7_2JgYjbgAwxk4E._3aV3NNpgeE6rFX2wjR5C8x,._3RQFQTQGAJbiD-7RO5I74l._1KaWmYYw46C9KbzTUiGEBB,.hYzcolJQJxgq5HRHIZ7fK{-webkit-transform:scaleY(1);transform:scaleY(1)}", ""]);

// exports
exports.locals = {
	"wrapper": "_1-hxp6Nc95lUaLQsulF2sh",
	"alert": "_1NDPnE5Hb_FVT9_9oeFqq",
	"icon": "iQ66CsPkWiR2Q48hR5h4-",
	"success": "_2mjEoj8fWdtI37r59rSlYP",
	"error": "_3DbdCt5JKAsuhDF-jyxdPG",
	"enter": "_3j6ykGR7_2JgYjbgAwxk4E",
	"appear": "_3RQFQTQGAJbiD-7RO5I74l",
	"leave": "hYzcolJQJxgq5HRHIZ7fK",
	"leaveActive": "_1_zF-u9bw9KKnU5C971_Aw",
	"enterActive": "_3aV3NNpgeE6rFX2wjR5C8x",
	"appearActive": "_1KaWmYYw46C9KbzTUiGEBB"
};