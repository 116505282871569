import React from 'react';
import styles from './switch.scss';
import Icon from 'components/font-awesome';
import cx from 'classnames';
import Tooltip from 'components/tooltip';

export default class SwitchOption extends React.Component {
  handleClick = event => {
    if (this.props.disabled) return;

    this.props.onClick(event);
  };

  render() {
    const {
      children,
      activeClass,
      active,
      className,
      disabled,
      showCaret,
      tooltip,
      dark
    } = this.props;

    const extraStyles = [className];

    if (children && children.length > 1) {
      extraStyles.push(styles.firstMargin);
    }

    if (active) {
      extraStyles.push(activeClass);
    }

    if (disabled) {
      extraStyles.push(styles.disabled);
    }

    if (dark) {
      extraStyles.push(styles.dark);
    }

    const option = (
      <div
        className={cx(styles.option, {[styles.active]: active}, extraStyles)}
        onClick={this.handleClick}
      >
        {children}
        {showCaret && <Icon name="caret-down" className={styles.dropdownCaret} />}
      </div>
    );

    if (tooltip) {
      // return option;
      return (
        <Tooltip className={cx(styles.tooltip)} text={tooltip}>
          {option}
        </Tooltip>
      );
    }

    return option;
  }
}
