import {takeLatest, put, call} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SAVE_LIBRARY_POLICY} from '../constants';
import {policyPath} from '../policyUtils';
import {setLibraryPolicy, saveLibraryPolicyComplete} from '../actions';
import {push} from 'modules/location';
import {showSuccess, showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

export default function* saveLibraryPolicySaga() {
  yield takeLatest(SAVE_LIBRARY_POLICY, saveLibraryPolicy);
}

function* saveLibraryPolicy({libraryPolicyId, policy, options, redirectToPolicy, complete}) {
  const url = 'library-policies/' + libraryPolicyId + '/create-from';
  try {
    const result = yield processRequest('POST', url, {}, {policy, options});

    yield put(setLibraryPolicy(result));
    yield put(saveLibraryPolicyComplete());

    if (redirectToPolicy) {
      yield put(push(policyPath, {policyId: result.policyId}, ['reportId', 'testId']));
    }

    yield call(complete);
    yield showSuccess('New policy was added');
  } catch (err) {
    yield showError(couldNot('create policy'));
    yield put(saveLibraryPolicyComplete(err));
  }
}
