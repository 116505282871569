import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Icon from 'components/font-awesome';
import styles from './searchBar.scss';

export default class SearchBar extends React.Component {
  static defaultProps = {
    autoFocus: true
  };

  static propTypes = {
    onEnter: PropTypes.func,
    autoFocus: PropTypes.bool,
    onUserInput: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {value: props.value};

    // Debounce this as it starts to get REALLY laggy else.
    this.debouncedOnChange = _.debounce(this.handleChange, 300, {
      maxWait: 3000
    });
  }

  componentDidMount() {
    if (this.props.onEnter) {
      this.filterTextInput.addEventListener('keyup', this.handleKeyup);
    }

    if (this.props.autoFocus) {
      this.focusTimeout = setTimeout(() => this.filterTextInput.focus(), 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);

    if (this.props.onEnter) {
      this.filterTextInput.removeEventListener('keyup', this.handleKeyup);
    }
  }

  componentWillReceiveProps({value}) {
    if (value !== this.props.value) {
      this.setState({value});
    }
  }

  getValue() {
    return this.filterTextInput.value;
  }

  handleKeyup = e => {
    const {onEnter} = this.props;

    if (onEnter && e.keyCode === 13) {
      onEnter(e.target.value);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  handleChange = () => {
    if (this.filterTextInput) {
      const value = this.getValue();
      const {onUserInput} = this.props;

      this.setState({value});

      onUserInput && onUserInput(value);
    }
  };

  handleInputRef = ref => {
    this.filterTextInput = ref;
  };

  updateValue = val => {
    this.filterTextInput.value = val;
  };

  render() {
    const {
      className,
      inputClassName,
      marginRight,
      roundedEdges,
      placeholder,
      value,
      ...props
    } = this.props;

    return (
      <form className={cx(styles.searchBar, className)} onSubmit={this.handleSubmit}>
        <input
          type="search"
          className={cx(inputClassName, {
            [styles.searchBarRounded]: roundedEdges
          })}
          placeholder={placeholder || 'Search'}
          ref={this.handleInputRef}
          autoComplete="off"
          onChange={this.debouncedOnChange}
          {...filterInvalidDOMProps(props)}
          defaultValue={this.state.value}
          aria-label={placeholder || 'Search'}
        />
        <Icon name="search" aria-hidden="true" />
      </form>
    );
  }
}
