import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import EditInteractionPage from '../pages/editInteractionPage';
import TestInteractionPage from '../pages/testInteractionPage';
import NotFoundPage from 'modules/location/pages/404Page';
import {interactionRouteSelector} from '../selectors';
import {requestInteraction} from '../actions';

class InteractionRoute extends React.Component {
  componentDidMount() {
    const {interactionId} = this.props.match.params;
    this.props.requestInteraction({interactionId});
  }

  componentWillReceiveProps(nextProps) {
    const {interactionId: lastId} = this.props.match.params;
    const {interactionId: nextId} = nextProps.match.params;

    if (nextId != lastId) {
      nextProps.requestInteraction({interactionId: nextId});
    }
  }

  render() {
    const {match, notFound} = this.props;
    const {path} = match;

    if (notFound) {
      return <NotFoundPage />;
    }

    return (
      <Switch>
        <Route path={`${path}/edit`} component={EditInteractionPage} />
        <Route path={`${path}/test`} component={TestInteractionPage} />
      </Switch>
    );
  }
}

export default connect(
  interactionRouteSelector,
  {requestInteraction}
)(InteractionRoute);
