import React from 'react';
import Button from 'components/button';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {receiveExtraInfo} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import {validateKeywordsAndEngines} from 'utils/form/keywordValidation';
import KeywordsBalance from 'modules/balances/containers/keywordsBalance';
import {keywordsUsedSelector} from 'modules/searchCampaigns/selectors';
import styles from './newReportModal/newReportModal.scss';

export class AskForModal extends React.Component {
  state = {
    localKeywordsUsed: this.props.keywordsUsed
  };

  componentWillMount() {
    const {askFor} = this.props;

    /*
    engines: 
      0: 
        engine: "google"
        location: "New York"
        options: {
          location: "New York, New York, United States",
          language: "en",
          countryCode: "US",
          latitude: 40.713051
          longitude: 0,
        }
    keywords: 
      0:
        keyword: "Fake Wine Barrel"
        targetRank: 1
    */

    this.defaultValue = {};

    if (askFor.includes('keywords.keywords') || askFor.includes('website.keywords')) {
      this.defaultValue.keywords = [{keyword: '', targetRank: 1}];
    }

    if (
      askFor.includes('keywords.searchEngines') ||
      askFor.includes('website.searchEnginesConfig')
    ) {
      this.defaultValue.engines = [
        {
          engine: 'google',
          location: 'New York',
          language: 'en',
          options: {
            location: 'New York, New York, United States',
            language: 'en',
            countryCode: 'US',
            latitude: 40.713051,
            longitude: -74.0059413
          }
        }
      ];
    }
  }

  handleValidSubmit = data => {
    // TODO: should this call receiveExtraInfo, or just call updateReport directly?
    this.props.receiveExtraInfo(data);
  };

  satisfyAsk = field => {
    switch (field) {
      case 'website.comparisonWebsites':
        return (
          <span>
            <hr />
            <h3>Competitors</h3>
            <Field
              key={field}
              label="What websites does this website compete with?"
              name="competitors"
              type="textarea"
              placeholder="e.g. http://competitor-example.com"
              help="Optionally enter websites to compare against, one on each line."
              autoFocus
            />
          </span>
        );
      case 'keywords.keywords':
      case 'website.keywords':
        return (
          <Field
            key={field}
            label="What keywords should this website rank for in search engines?"
            name="keywords"
            type="searchTargets"
            placeholder='e.g. "florists in San Francisco", "wedding flowers San Francisco"'
            help="Optionally enter any keywords you would like to target for SEO, one on each line."
            legacyFormat
          />
        );
      case 'keywords.searchEngines':
      case 'website.searchEnginesConfig':
        return (
          <Field
            key={field}
            label="What locations should we check these keywords in?"
            name="engines"
            type="searchEngines2"
            help="Select one or more search engines to measure your ranking for."
            searchEngines
          />
        );
    }
  };

  render() {
    const {askFor} = this.props;

    const containsKeywords =
      (askFor.includes('keywords.keywords') && askFor.includes('keywords.searchEngines')) ||
      (askFor.includes('website.keywords') && askFor.includes('website.searchEnginesConfig'));

    const customValidation = containsKeywords
      ? validateKeywordsAndEngines
      : () => ({isValid: true});

    return (
      <ModalForm
        defaultValue={this.defaultValue}
        submitLabel={<Button id="submitAskForModal">Start testing</Button>}
        title="Search keywords"
        onChange={(name, value, data) => {
          if (data.keywords && data.engines) {
            this.setState({
              localKeywordsUsed:
                data.keywords.filter(field => !!field.keyword).length * data.engines.length
            });
          }
        }}
        onValidSubmit={this.handleValidSubmit}
        customValidation={customValidation}
      >
        {containsKeywords && (
          <KeywordsBalance
            className={styles.keywordsBalance}
            keywordsUsed={this.state.localKeywordsUsed}
            sku={this.props.report.sku}
            serviceId={this.props.report.serviceId}
          />
        )}
        {askFor.map(this.satisfyAsk)}
      </ModalForm>
    );
  }
}

export default connect(
  createStructuredSelector({
    keywordsUsed: keywordsUsedSelector
  }),
  {receiveExtraInfo}
)(AskForModal);
