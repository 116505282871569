import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {exportReportPdf, exportReportCsvs, printReport} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import {exportReportSelector} from 'modules/reports/reportSelectors';
import {PAGE_SIZES, EXPORT_TYPES, EXPORT_TESTS} from 'modules/reports/reportConstants';
import {setUserOption} from 'modules/userOptions';
import USER_OPTIONS from 'modules/userOptions/userOptionConstants';
import {isSuperUser} from 'modules/auth/gandalf';

const exportOptions = [
  {label: 'PDF', value: EXPORT_TYPES.PDF},
  {label: 'Print', value: EXPORT_TYPES.PRINT, superUserOnly: true}

  // right now, only explorers can be exported as CSV. This is taken care of by ExportExplorerModal
  // {label: 'CSV', value: EXPORT_TYPES.CSV}
];

const paperSizeOptions = [
  {label: 'A4', value: PAGE_SIZES.A4},
  {label: 'Letter', value: PAGE_SIZES.LETTER}
];

const testOptions = [
  {label: 'All screens', value: EXPORT_TESTS.ALL},
  {label: 'This screen', value: EXPORT_TESTS.THIS},
  {label: 'Choose screens', value: EXPORT_TESTS.CHOOSE}
];

/*
You can pass an `exportType` which forces this modal to only export that type of report.
If not passed, the modal will dislay a selectbox of allowed types which the user can choose.
*/

export class ExportReportModal extends React.Component {
  static defaultProps = {
    defaultValue: {
      exportType: EXPORT_TYPES.PDF,
      whichTest: EXPORT_TESTS.ALL,
      paperSize: PAGE_SIZES.A4
    }
  };

  static propTypes = {
    reportId: PropTypes.any.isRequired
  };

  state = {exportType: EXPORT_TYPES.PDF};

  componentWillMount() {
    this.updateExportOptions();
  }

  componentWillUpdate() {
    this.updateExportOptions();
  }

  updateExportOptions() {
    const isSuper = isSuperUser(this.props.user);
    this.exportOptions = exportOptions.filter(option => !option.superUserOnly || isSuper);
  }

  handleChangePaperSize = value => {
    // update persistant user option
    this.props.setUserOption(USER_OPTIONS.PAPER_SIZE, value);
  };

  handleValidSubmit = ({whichTest, testIds, exportType, paperSize}) => {
    const {exportReportPdf, exportReportCsvs, printReport, reportId, hideModal} = this.props;
    const selectedTests = this.getTestIds(whichTest, testIds);

    switch (exportType) {
      case EXPORT_TYPES.PRINT:
        hideModal();
        printReport(reportId, selectedTests.length ? selectedTests : null);
        break;

      case EXPORT_TYPES.PDF:
        exportReportPdf(reportId, selectedTests, paperSize);
        break;

      case EXPORT_TYPES.CSV:
        exportReportCsvs(reportId, selectedTests);
        break;

      default:
        throw new Error('Invalid exportType "' + exportType + '"');
    }
  };

  getTestIds(which, testIds) {
    switch (which) {
      case EXPORT_TESTS.ALL:
        return [];
      case EXPORT_TESTS.THIS:
        return [this.props.defaultTestId];
      case EXPORT_TESTS.CHOOSE:
        return testIds;
    }
  }

  render() {
    const {defaultValue, testNameOptions, defaultPaperSize} = this.props;

    if (defaultPaperSize) {
      defaultValue.paperSize = defaultPaperSize;
    }

    return (
      <ModalForm
        title="Export"
        submitLabel="Export"
        defaultValue={defaultValue}
        onValidSubmit={this.handleValidSubmit}
        hideSubmit={this.state.exportType == EXPORT_TYPES.SHARE}
      >
        {this.state.exportType !== EXPORT_TYPES.SHARE && (
          <Field name="whichTest" type="choice" options={testOptions} required />
        )}
        <Field
          label="Select tests"
          name="testIds"
          type="multiselect"
          options={testNameOptions}
          required
          showIf={data => data.whichTest == EXPORT_TESTS.CHOOSE}
        />
        <Field
          label="Format"
          name="exportType"
          type="select"
          options={this.exportOptions}
          required
          disableFiltering
          onChange={exportType => this.setState({exportType})}
        />
        <Field
          label="Paper size"
          name="paperSize"
          type="select"
          options={paperSizeOptions}
          onChange={this.handleChangePaperSize}
          required
          disableFiltering
          showIf={data => data.exportType == EXPORT_TYPES.PDF}
        />
      </ModalForm>
    );
  }
}

export default connect(
  exportReportSelector,
  {
    exportReportPdf,
    exportReportCsvs,
    setUserOption,
    printReport
  }
)(ExportReportModal);
