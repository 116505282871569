import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './quiz.scss';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  answered: PropTypes.bool
};

const MultiChoiceAnswers = ({options, onChange, answered, answer: currentAnswer}) => {
  const handleChange = answer => {
    if (currentAnswer.includes(answer)) {
      const newSelected = [...currentAnswer];
      newSelected.splice(currentAnswer.indexOf(answer), 1);
      onChange(newSelected);
    } else {
      onChange([...currentAnswer, answer]);
    }
  };

  return options.map(answer => (
    <li
      key={answer}
      onClick={() => {
        if (!answered) handleChange(answer);
      }}
      className={answered ? styles.answered : ''}
    >
      <input
        type="checkbox"
        value={answer}
        checked={currentAnswer.includes(answer)}
        disabled={answered}
        onChange={() => {}}
      />
      {answer}
    </li>
  ));
};

MultiChoiceAnswers.propTypes = propTypes;

export default MultiChoiceAnswers;
