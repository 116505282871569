import React from 'react';
import styles from 'modules/decisions/components/decisionAlerts.scss';
import Button from 'components/button';
// import Icon from 'components/font-awesome';
// import Media from 'components/media';

export default class DecisionAlert extends React.Component {
  static defaultProps = {
    onUndo: () => null,
    onClose: () => null
  };

  state = {clicked: false};

  render() {
    const {decision, onClose, onUndo} = this.props;
    const {name, src, srcType} = decision;

    return (
      // <Icon name="times" className={styles.closeIcon} onClick={onClose} />
      // {src &&
      //   srcType && (
      //     <div className={styles.icon}>
      //       <Media src={src} srcType={srcType} />
      //     </div>
      //   )}
      <div className={styles.decisionAlert}>
        <div className={styles.content}>
          <p>{name}</p>
          <Button
            wire
            short
            disabled={this.state.clicked}
            onClick={e => {
              if (!this.state.clicked) {
                this.setState({clicked: true});
                onUndo(e);
              }
            }}
          >
            Undo
          </Button>
        </div>
      </div>
    );
  }
}
