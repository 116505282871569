import _ from 'lodash';
import {connect} from 'react-redux';
import {doCommand} from 'modules/instructions';
import React, {useState} from 'react';
import Status from 'components/status/status';
import {getTimelinePoint} from './timelineUtils';
import Story from './story';
import styles from './timeline.scss';
import RelativeDate from 'components/time/relativeDate';

function Timeline({params, result, setFilterParams, doCommand}) {
  const {maxPage, minPage, numRows, page, rowsPerPage, rows} = result;

  if (!rows || !rows.length) {
    return <Status appearance="good">No timeline data</Status>;
  }

  const groupedStories = _.reverse(
    _.sortBy(_.groupBy(rows, story => story.time), storyGroup => {
      // because we grouped by `time`, all the times in this storyGroup are the same
      // so we can just grab the first
      return storyGroup[0].time;
    })
  );

  return (
    <div className={styles.timeline}>
      {Object.keys(groupedStories).map(index => {
        const time = groupedStories[index][0].time;
        return (
          <React.Fragment key={time}>
            <div className={styles.timeWrapper}>
              <RelativeDate timestamp={time} absolute />
            </div>
            <div>
              {_.sortBy(groupedStories[index], [{importance: 'desc'}]).map(renderStory(doCommand))}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

const renderStory = doCommand => ({points, ...row}) => {
  const StoryTypeComponent = getTimelinePoint(row.storyTypeId);
  if (!StoryTypeComponent) return null;

  return (
    <Story key={row.storyHash} points={points} {...row}>
      <StoryTypeComponent story={row} storyPoints={points} doCommand={doCommand} />
    </Story>
  );
};

export default connect(
  null,
  {doCommand}
)(Timeline);
