import CanBuyGandalf from 'components/gandalf/canBuyGandalf';
import Gandalf from 'components/gandalf/gandalf';
import {Item, Sidebar} from 'components/sidebar/listSidebar';
import {isAccountDisabledSelector} from 'modules/accounts/accountDisabledSelector';
import {accountAuditLogPath} from 'modules/auditLog/utils';
import {usingServicesSelector} from 'modules/auth/authSelectors';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import {accountDecisionsPath} from 'modules/decisions/decisionUtils';
import {reportLogPath} from 'modules/logs/logUtils';
import {
  accountSettingsPath,
  checkSettingsPath,
  paymentSettingsPath,
  servicesSettingsPath,
  subscriptionSettingsPath,
  tagsSettingsPath,
  userSettingsPath
} from 'modules/settings/settingsUtils';
import {requestSubscriptions} from 'modules/subscriptions';
import {usersPath} from 'modules/users/userUtils';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

export class SettingsSidebar extends React.Component {
  static defaultProps = {
    links: [
      {anchor: 'My settings', hrefMaker: userSettingsPath},
      {
        anchor: 'Account settings',
        hrefMaker: accountSettingsPath,
        shallPass: ACCOUNT_OWNER
      },
      {
        anchor: 'Services',
        hrefMaker: servicesSettingsPath,
        shallPass: ACCOUNT_OWNER,
        usingServices: true
      },
      {
        anchor: 'Tags',
        hrefMaker: tagsSettingsPath,
        shallPass: ACCOUNT_OWNER
      },
      // {
      //   anchor: 'Enabled checks',
      //   hrefMaker: checkSettingsPath,
      //   shallPass: ACCOUNT_OWNER
      // },
      {
        anchor: 'Invoices',
        hrefMaker: paymentSettingsPath,
        shallPass: ACCOUNT_OWNER,
        showForDisabledAccount: true
      },
      {
        anchor: 'Subscriptions',
        hrefMaker: subscriptionSettingsPath,
        wrapperComponent: CanBuyGandalf,
        shallPass: ACCOUNT_OWNER,
        usingServices: false,
        showForDisabledAccount: true
      },
      {anchor: 'Decisions', hrefMaker: accountDecisionsPath, shallPass: ACCOUNT_OWNER},
      // @ TODO integrate at a later date
      // {anchor: 'Audit log', hrefMaker: accountAuditLogPath, shallPass: ACCOUNT_OWNER},
      {anchor: 'Users', hrefMaker: usersPath, shallPass: ACCOUNT_OWNER},
      {anchor: 'Usage', hrefMaker: reportLogPath, shallPass: ACCOUNT_OWNER}
    ]
  };

  componentWillMount() {
    if (!this.props.usingServices) {
      this.props.requestSubscriptions();
    }
  }

  renderLink = link => {
    const href = link.hrefMaker(this.props.params);
    const Wrapper = link.wrapperComponent || DummyWrapper;
    const GandalfWrapper = link.shallPass ? Gandalf : DummyWrapper;

    if (!link.showForDisabledAccount && this.props.accountIsDisabled) {
      return null;
    }

    if (
      typeof link.usingServices !== 'undefined' &&
      link.usingServices !== this.props.usingServices
    ) {
      return null;
    }

    return (
      <GandalfWrapper shallPass={link.shallPass} key={link.anchor}>
        <Wrapper>
          <Item href={href} active={this.props.pathname === href} newStyle>
            {link.anchor}
          </Item>
        </Wrapper>
      </GandalfWrapper>
    );
  };

  render() {
    return (
      <Sidebar>
        <h2>Settings</h2>
        {this.props.links.map(this.renderLink)}
      </Sidebar>
    );
  }
}

/*
 * Simply returns it's children straight away i.e. there is no wrapper... witch craft
 */
const DummyWrapper = ({children}) => {
  return children;
};

export default connect(
  createSelector(
    isAccountDisabledSelector,
    usingServicesSelector,
    (accountIsDisabled, usingServices) => {
      return {accountIsDisabled, usingServices};
    }
  ),
  {requestSubscriptions}
)(SettingsSidebar);
