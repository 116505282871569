import _ from 'lodash';
import {createSelector} from 'reselect';
import {testIdSelector} from './testSelectors';
import {createPropSelector} from 'utils/selectorUtils';
import {
  createResultValueSelector,
  createResultErrorSelector,
  resultsForCurrentSelector
} from 'modules/results/resultSelectors';
import evaluate from 'token-js-parser';
import {toJS} from 'utils/immutableUtils';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import {configOptionsDataSelector} from 'modules/configOptions/configOptionsSelectors';

const layoutComponentTypeSelector = createPropSelector('config.type');
const resultIdSelector = createPropSelector('config.resultId');
const hasResultSelector = createPropSelector('config.resultId');
const showAstSelector = createPropSelector('config.show');
const filterSelector = createPropSelector('filter');

const layoutComponentResultSelector = createResultValueSelector(resultIdSelector);
const layoutComponentResultErrorSelector = createResultErrorSelector(resultIdSelector);

const isLoadingSelector = createSelector(
  hasResultSelector,
  layoutComponentResultSelector,
  (hasResult, result) => {
    return !!hasResult && (_.isNull(result) || typeof result === 'undefined');
  }
);

const shouldBeShownSelector = createSelector(
  showAstSelector,
  filterSelector,
  resultsForCurrentSelector,
  layoutComponentResultSelector,
  configOptionsDataSelector,
  (showAst, filter, results, result, config) => {
    if (showAst) {
      const evalResult = evaluate(showAst, {
        filters: filter,
        results,
        result,
        config: toJS(config)
      });
      if (Array.isArray(evalResult) && evalResult.length === 0) {
        return false;
      }
      return !!evalResult;
    }
    return true;
  }
);

export const layoutComponentSelector = createSelector(
  testIdSelector,
  layoutComponentTypeSelector,
  shouldBeShownSelector,
  layoutComponentResultSelector,
  layoutComponentResultErrorSelector,
  isLoadingSelector,
  isArchiveActiveSelector,
  (testId, type, shouldBeShown, result, error, isLoading, isArchiveActive) => {
    return {
      testId,
      type,
      shouldBeShown,
      result,
      error,
      isLoading,
      isArchiveActive
    };
  }
);
