import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.shape(PropTypes.any),
  onClick: PropTypes.func
};

const ExternalLink = React.forwardRef(
  ({href, children, className, onClick, title, ...props}, ref) => (
    <a
      ref={ref}
      href={href}
      target="_blank"
      rel="noopener"
      className={className}
      title={title}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  )
);

ExternalLink.propTypes = propTypes;
ExternalLink.defaultProps = {
  onClick: () => {}
};

export default ExternalLink;
