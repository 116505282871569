import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {uptimeMonitorsEndpoint} from '../utils';
import {receiveUptimeMonitors} from '../actions';
import {FETCH_UPTIME_MONITORS} from '../constants';

export default function* fetchUptimeMonitorsSaga() {
  yield takeLatest(FETCH_UPTIME_MONITORS, fetchUptimeMonitors);
}

function* fetchUptimeMonitors(action) {
  yield processRequest('GET', uptimeMonitorsEndpoint(action), {
    success: function*(uptimeMonitors) {
      yield put(receiveUptimeMonitors(uptimeMonitors));
    }
  });
}
