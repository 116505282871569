import React from 'react';
import {connect} from 'react-redux';
import SelectInput from './selectInput';
import {roleInputSelector} from 'modules/auth/authSelectors';
import {ACCOUNT_OWNER, SITE_CREATOR, REGULAR_USER, shallPass} from 'modules/auth/gandalf';
import {ROLE_MANAGER, ROLE_CONTRIBUTOR, ROLE_VIEWER} from 'modules/gandalf/gandalfUtils';

const userRoles = [
  {value: ACCOUNT_OWNER, label: 'Owner'},
  {value: SITE_CREATOR, label: 'Creator'},
  {value: REGULAR_USER, label: 'User'}
];

const reportRoles = [
  {value: ROLE_VIEWER, label: 'Viewer'},
  {value: ROLE_CONTRIBUTOR, label: 'Contributor'},
  {value: ROLE_MANAGER, label: 'Manager'}
];

export class RoleInput extends React.Component {
  getOptions() {
    const {loggedInUser, useReportRoles} = this.props;

    if (useReportRoles) {
      return reportRoles;
    }

    return userRoles.filter(option => shallPass(loggedInUser, option.value, true));
  }

  render() {
    return <SelectInput {...this.props} options={this.getOptions()} disableFiltering />;
  }
}

export default connect(roleInputSelector)(RoleInput);
