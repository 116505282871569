import {getProps} from 'utils/immutableUtils';
import {toJS} from 'utils/immutableUtils';
import {reportIsAllowed} from 'modules/gandalf/gandalfUtils';

export const SUPER_USER = 'super';
export const ACCOUNT_OWNER = 'owner';
export const SITE_CREATOR = 'creator';
export const REGULAR_USER = 'user';

const ROLE_PRECEDENCE = {
  [SUPER_USER]: 4,
  [ACCOUNT_OWNER]: 3,
  [SITE_CREATOR]: 2,
  [REGULAR_USER]: 1
};

export function isAccountGandalf(shallPassStr) {
  return shallPassStr in ROLE_PRECEDENCE;
}

export function isSuperUser(user) {
  return shallPass(user, SUPER_USER, true); // remember to send the "restrict" flag or we could get into an infinite loop
}

// checks if current authUser.role === role.
// super users automatically have access unless restrictSuperUsers is true
export function shallPass(user, role = SUPER_USER, restrictSuperUsers = false) {
  // check for auto super user access
  if (!restrictSuperUsers && isSuperUser(user)) {
    return true;
  }

  if (!user) return false;

  // we use getProps so that user can be either immutable or an object
  const {role: userRole} = getProps(user, ['role']);

  // check user has access
  return ROLE_PRECEDENCE[userRole] >= ROLE_PRECEDENCE[role];
}

// shallPass accepts account roles, and reportIsAllowed accepts report roles, this accepts both
export function shallPassAny(role, {user, report}) {
  if (isAccountGandalf(role)) {
    return shallPass(user, role);
  } else {
    return reportIsAllowed(role, report.getIn(['ownPermissions', 'role']));
  }
}

export function isVirtualUser(user) {
  user = toJS(user);
  return user && user.virtualType;
}
