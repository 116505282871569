import React from 'react';
import {connect} from 'react-redux';
import LoginForm from '../components/loginForm';
import {requestLogin} from 'modules/auth';
import {loginPageSelector} from 'modules/auth/authSelectors';
import Spinner from 'components/spinner/circularSpinner';
import Helmet from 'components/helmet';
import ExternalLink from 'modules/location/externalLink';
import styles from 'modules/auth/components/authWrapperShared.scss';
import Brand, {SILKTIDE_LOGO} from 'components/brand/brand';
import {homePath} from 'modules/app/appUtils';
import {replace} from 'modules/location';
import {parseQuery} from 'utils/urlUtils';

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    const query = parseQuery(window.location.search);

    this.state = {
      email: query.email !== undefined ? query.email : ''
    };
  }

  componentWillMount() {
    const accountId = this.props.user.get('accountId');

    if (accountId) {
      this.props.replace(homePath, {accountId});
      return;
    }
  }

  handleLogin = ({email, password}) => {
    this.setState({email});
    this.props.requestLogin(email, password);
  };

  renderLogin() {
    const {error, loggingIn} = this.props;

    if (loggingIn) {
      return <Spinner centered />;
    }

    return (
      <div>
        <div className={styles.logoWrapper}>
          <ExternalLink href="https://silktide.com/" title="Silktide">
            <Brand className={styles.logo} version={SILKTIDE_LOGO} />
          </ExternalLink>
        </div>

        <p className={styles.header}>Please log in to continue</p>

        <LoginForm
          id="loginForm"
          defaultValue={this.state}
          name="loginForm"
          onValidSubmit={this.handleLogin}
          errorMessage={error}
        />
      </div>
    );
  }

  render() {
    if (this.props.loggedIn) {
      return <div />;
    }

    return (
      <div className={this.props.className}>
        <Helmet title="Log in" />

        {this.renderLogin()}
      </div>
    );
  }
}

export default connect(
  loginPageSelector,
  {requestLogin, replace}
)(LoginPage);
