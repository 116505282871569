exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HiR6IHz2JJNKgb4Blucmj{display:flex;align-items:flex-end;margin:0 0 20px}.HiR6IHz2JJNKgb4Blucmj .WK3lthw12YpJg1iMWC8D-{flex:1 1 auto}.HiR6IHz2JJNKgb4Blucmj ._11EW_khYxGm92e1ieg-Hvb{margin:0 0 10px}.HiR6IHz2JJNKgb4Blucmj ._11EW_khYxGm92e1ieg-Hvb span{display:inline-block;margin:0 8px 0 0}.HiR6IHz2JJNKgb4Blucmj ._11EW_khYxGm92e1ieg-Hvb a{font-weight:400;color:#b0c0f0;text-decoration:underline;-webkit-text-decoration-color:rgba(176,192,240,.5);text-decoration-color:rgba(176,192,240,.5)}.HiR6IHz2JJNKgb4Blucmj ._11EW_khYxGm92e1ieg-Hvb a:hover,.HiR6IHz2JJNKgb4Blucmj ._11EW_khYxGm92e1ieg-Hvb a:focus{color:#fff}.HiR6IHz2JJNKgb4Blucmj h1{font-size:34px;margin:0}", ""]);

// exports
exports.locals = {
	"title": "HiR6IHz2JJNKgb4Blucmj",
	"left": "WK3lthw12YpJg1iMWC8D-",
	"breadcrumb": "_11EW_khYxGm92e1ieg-Hvb"
};