import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'components/helmet/helmet';
import PageHeader from 'components/header/pageHeader';
import PageBody from 'components/page';
import PolicyForm from '../containers/policyForm';
import {savePolicy} from 'modules/policies/actions';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import {testPath} from 'modules/tests/testUtils';
import {push} from 'modules/location';
import {parse} from 'query-string';

export class NewPolicyPage extends React.Component {
  handleValidSubmit = data => {
    this.props.savePolicy(data, true);
  };

  render() {
    const {match, location, push} = this.props;
    const query = parse(location.search, {arrayFormat: 'index', parseBooleans: true});

    return (
      <WithReportSidebar match={match} location={location}>
        <Helmet title="New policy" />
        <PageHeader
          title="New policy"
          goBack={() => {
            push(testPath, {}, ['reportId', 'testId']);
          }}
        />

        <PageBody noPaddingBottom>
          <PolicyForm onValidSubmit={this.handleValidSubmit} query={query} />
        </PageBody>
      </WithReportSidebar>
    );
  }
}

export default connect(
  null,
  {savePolicy, push}
)(NewPolicyPage);
