import React from 'react';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import SettingsPage from 'components/settings/settingsPage';
import Button from 'components/button';

const RECEIVE_EMAILS_OPTIONS = [{label: 'No', value: 'No'}, {label: 'Yes', value: 'Yes'}];

export default class NotificationsConfig extends React.Component {
  handleValidSubmit = config => {
    this.props.updateWatchingReport(this.props.report.reportId, config.receiveEmails === 'Yes');
  };

  render() {
    const {
      name,
      match: {params},
      websiteConfig,
      report
    } = this.props;

    if (!websiteConfig) {
      return null;
    }

    return (
      <SettingsPage name="Notifications" params={params}>
        <Form
          name={name}
          defaultValue={{
            receiveEmails: report.isWatching ? 'Yes' : 'No'
          }}
          onValidSubmit={this.handleValidSubmit}
        >
          <SettingItem
            title="Receive email notifications?"
            description="Do you wish to receive email notifications when this report completes?"
          >
            <Field name="receiveEmails" type="select" options={RECEIVE_EMAILS_OPTIONS} />
          </SettingItem>
          <Button type="submit">Save</Button>
        </Form>
      </SettingsPage>
    );
  }
}
