import {takeLatest, put} from 'redux-saga/effects';
import {FETCH_SUPER_PRODUCTS, recevieSuperProducts, handleSuperProductsLoadFailure} from 'modules/superProduct/superProductActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {superProductsEndpoint} from 'modules/superProduct/superProductUtils';

export default function* requestPurchaseableServicesSaga() {
  yield takeLatest([FETCH_SUPER_PRODUCTS], function*() {
    yield processRequest('GET', superProductsEndpoint(), {
      successAction: recevieSuperProducts,
      error: function*(err) {
        const errorMsg = err || "Failed to load super products.";
        yield put(handleSuperProductsLoadFailure(errorMsg));
      }
    });
  });
}