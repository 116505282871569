import {Map} from 'immutable';
import {homePath} from './appUtils';
import {push} from 'modules/location';

const defaultState = Map({
  ready: false,
  hideSidebar: window.innerWidth < 768,
  hideAccountMenu: true,
  fatalError: null
});

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    case READY:
      return state.set('ready', true);

    case TOGGLE_SIDEBAR: {
      const value = typeof action.force == 'boolean' ? action.force : !state.get('hideSidebar');

      // if we are about to show the sidebar, and the accountMenu is open, close it
      if (!value && !state.get('hideAccountMenu')) {
        state = state.set('hideAccountMenu', true);
      }
      return state.set('hideSidebar', value);
    }

    case TOGGLE_ACCOUNT_MENU: {
      let value = typeof action.force == 'boolean' ? action.force : !state.get('hideAccountMenu');

      // always hide this if the screen is bigger than tablet size
      if (window.innerWidth >= 768) {
        value = true;
      }

      // if we are about to show the account menu, and the sidebar is open, close it
      if (!value && !state.get('hideSidebar')) {
        state = state.set('hideSidebar', true);
      }

      return state.set('hideAccountMenu', value);
    }

    case FATAL_ERROR:
      return state.set('fatalError', action.message);
  }
  return state;
}

export const READY = 'APP:READY';
export function ready() {
  return {
    type: READY
  };
}

export const TOGGLE_SIDEBAR = 'APP:TOGGLE_SIDEBAR';
export function toggleSidebar(force) {
  return {
    type: TOGGLE_SIDEBAR,
    force
  };
}

export const TOGGLE_ACCOUNT_MENU = 'APP:TOGGLE_ACCOUNT_MENU';
export function toggleAccountMenu(force) {
  return {
    type: TOGGLE_ACCOUNT_MENU,
    force
  };
}

export const FATAL_ERROR = 'APP:FATAL_ERROR';
export function fatalError(message) {
  return {
    type: FATAL_ERROR,
    message
  };
}

export function goHome() {
  return push(homePath);
}

export const SEND_CONTACT_US_EMAIL = 'APP:SEND_CONTACT_US_EMAIL';
export function sendContactUsEmail(contactType, formData, successCallback, failCallback) {
  return {
    type: SEND_CONTACT_US_EMAIL,
    contactType,
    formData,
    successCallback,
    failCallback
  };
}
