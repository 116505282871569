import React from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import styles from './authWrapper.scss';
import sharedStyles from './authWrapperShared.scss';

export default class HeaderLink extends React.Component {
  render() {
    const {path, prompt, linkText} = this.props;

    return (
      <div className={cx(styles.isDesktop, sharedStyles.headerLinks)}>
        <p>
          {prompt}
          &nbsp;
          <Link className={sharedStyles.inlineLink} to={path}>
            {linkText}
          </Link>
        </p>
      </div>
    );
  }
}
