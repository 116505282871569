import React from 'react';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import {connect} from 'react-redux';
import {notificationsCountSelector} from 'modules/notifications/notificationSelectors';
import Button from 'components/button';
import styles from './notificationsButton.scss';
import cx from 'classnames';
import ScaleOnHover from 'components/animation/scaleOnHover';

export class NotificationsButton extends React.Component {
  getTitle() {
    const {unshownCount} = this.props;
    return `Notifications` + (!unshownCount ? '' : ` (${unshownCount} unread)`);
  }

  render() {
    const {unshownCount, count, className, smallIcon, ...props} = this.props;

    // Don't render if no notifications
    if (!count) return null;

    const classes = cx(styles.notificationsButton, className, {
      [styles.small]: smallIcon,
      [styles.unread]: unshownCount > 0
    });

    return (
      <Button {...props} plain className={classes} title={this.getTitle()}>
        <ScaleOnHover>
          <div className={styles.circle}>{unshownCount || '0'}</div>
        </ScaleOnHover>
      </Button>
    );
  }
}

export default connect(
  notificationsCountSelector,
  null,
  null,
  {forwardRef: true}
)(NotificationsButton);
