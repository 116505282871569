import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

import {EXPORT_LEADERBOARD} from '../constants';
import {leaderboardExported} from '../actions';
import {exportLeaderboardEndpoint} from '../utils';

export default function* exportLeaderboardSaga() {
  yield takeLatest(EXPORT_LEADERBOARD, exportLeaderboard);
}

function* exportLeaderboard({leaderboardId}) {
  yield processRequest('GET', exportLeaderboardEndpoint(leaderboardId), {
    *success({url}) {
      window.open(url, '_blank');
      yield put(leaderboardExported());
      yield showSuccess('Leaderboard exported');
    },
    *error() {
      yield put(leaderboardExported());
      couldNot('export leaderboard');
    },
    errorMessage: couldNot('export leaderboard')
  });
}
