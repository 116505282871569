import {basePath} from 'modules/auth/authUtils';

export function leaderboardsPath(...args) {
  return basePath(...args) + '/leaderboards';
}

export function leaderboardPath({leaderboardId, ...params}) {
  // return leaderboardsPath(params) + '/' + leaderboardId + '?order=report.name%20asc';
  return leaderboardsPath(params) + '/' + leaderboardId;
}

export function editLeaderboardPath(params = {}) {
  const pathPart = params.leaderboardId ? 'edit' : 'new';
  const path = params.leaderboardId ? leaderboardPath(params) : leaderboardsPath(params);
  return path + '/' + pathPart;
}

export function leaderboardsEndpoint() {
  return 'leaderboards';
}

export function leaderboardEndpoint({leaderboardId}) {
  return `leaderboards/${leaderboardId}`;
}

export function exportLeaderboardEndpoint(leaderboardId) {
  return `leaderboards/${leaderboardId}/export`;
}

export function leaderboardResultsEndpoint({leaderboardId}) {
  return `${leaderboardEndpoint({leaderboardId})}/reports`;
}
