import {Map} from 'immutable';
import {CLEAR_REPORT} from 'modules/reports/reportConstants';
import {RECEIVE_LAYOUT} from './layouts';
import {RECEIVE_ACTION} from 'modules/actions/actions';

const initialState = Map({
  // we track which reportId we are storing results for
  // so that we can clear them only when user goes to a new report
  reportId: null,
  data: Map()
});

export default function layoutsReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_REPORT:
      return initialState;

    case RECEIVE_LAYOUT:
      return state.setIn(['data', action.layoutId], action.layout);

    case RECEIVE_ACTION:
      if (!action.action.layout || !action.action.layout.layoutId) {
        return state;
      }
      return state.setIn(['data', action.action.layout.layoutId], action.action.layout.layout);
  }

  return state;
}
