import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {trackEvent} from 'modules/tracking';
import {updateReportLogState} from 'modules/logs';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import {reportLogPageSelector} from 'modules/logs/reportLogSelectors';
import Spinner from 'components/spinner';
import Gandalf from 'components/gandalf';
import ReportLog from '../components/reportLog';

export class ReportLogPage extends React.Component {
  constructor(props) {
    super(props);

    trackEvent('Viewed report log', {
      fromFile: 'reportLogContainer.js'
    });
  }

  setBillable = (missionId, billableState) => {
    const {updateReportLogState} = this.props;

    updateReportLogState(missionId, {
      isBillable: billableState
    });
  };

  setHidden = (missionId, hiddenState) => {
    const {updateReportLogState} = this.props;

    updateReportLogState(missionId, {
      isHidden: hiddenState
    });
  };

  render() {
    const {isFetchingLogs} = this.props;

    if (isFetchingLogs) {
      return <Spinner centered />;
    }

    return (
      <Gandalf shallPass={ACCOUNT_OWNER}>
        <ReportLog {...this.props} setHidden={this.setHidden} setBillable={this.setBillable} />
      </Gandalf>
    );
  }
}

export default connect(
  reportLogPageSelector,
  {updateReportLogState, push}
)(ReportLogPage);
