import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Badge from 'components/badge';
import styles from './achievement.scss';

export default class Achievement extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    animate: PropTypes.bool
  };

  renderBadge() {
    const {icon, src, animate} = this.props;
    return <Badge className={styles.badge} icon={icon} src={src} animate={animate} />;
  }

  render() {
    const {className, onClick, title, description, icon, animate} = this.props;
    const classes = cx(styles.achievement, className, {
      [styles.animate]: animate
    });

    return (
      <div className={classes} onClick={onClick}>
        {this.renderBadge()}
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.inner}>
              <div className={styles.title}>
                <div className={styles.achievementText}>Achievement Unlocked</div>
                <div className={styles.titleText}>{title}</div>
              </div>
            </div>
            <div className={styles.inner}>{description}</div>
          </div>
        </div>
      </div>
    );
  }
}
