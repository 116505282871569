import React from 'react';
import styles from './tutor.scss';
import Tutor from './tutor';

export default function Tutors({tutors}) {
  return (
    <div className={styles.tutors}>
      {tutors.map(tutor => (
        <Tutor key={tutor.get('email')} tutor={tutor} />
      ))}
    </div>
  );
}
