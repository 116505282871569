import {fromJS} from 'immutable';
import {analytics, events} from 'modules/analytics';
import {ADD_REPORT} from 'modules/reports/reportConstants';
import emailValidator from 'email-validator';

const initialState = fromJS({
  requesting: false,
  errorMessage: false,
  // Query params from route load stored in the format the backend expects
  // These are merged with form fields and posted to the backend on signup
  entryParams: {
    urlToTest: null, // url query string from signup route (optional)
    source: null,
    contactName: null,
    contactEmail: null,
    referredBy: null
  }
});

export default function signupReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP:
      return state.merge({requesting: true, errorMessage: false});

    case SIGNUP_COMPLETE:
      return state.merge({
        requesting: false,
        errorMessage: action.errorMessage
      });

    case VIEW_SIGNUP_PAGE:
      const {type, ...params} = action;
      return state.mergeIn(['entryParams'], params);

    case ADD_REPORT:
      return state.setIn(['entryParams', 'urlToTest'], null);
  }

  return state;
}

export const SIGNUP = 'signup:SIGNUP';
export function signup(data) {
  return {type: SIGNUP, ...data};
}

export const SIGNUP_COMPLETE = 'signup:SIGNUP_COMPLETE';
export function signupComplete(errorMessage = false) {
  return {
    type: SIGNUP_COMPLETE,
    errorMessage,
    ...analytics(errorMessage ? events.SIGNUP_FAILED : events.SIGNUP_SUCCESS)
  };
}

export const VIEW_SIGNUP_PAGE = 'signup:VIEW_SIGNUP_PAGE';
// TODO: this arg list is getting a bit long...
export function viewSignupPage(urlToTest = null, source = null, referredBy = null) {
  // Because some people are super special, we need to handle if they enter their email address in the URL box...
  if (emailValidator.validate(urlToTest)) {
    urlToTest = null;
  }

  return {
    type: VIEW_SIGNUP_PAGE,
    urlToTest,
    source,
    referredBy,
    ...analytics(events.SIGNUP_START, {source})
  };
}
