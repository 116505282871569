import React from 'react';
import {createStore, applyMiddleware, compose} from 'redux';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import rootReducer from 'modules/app/rootReducer';
import {appIsReady} from 'modules/app/appSelectors';
import {createBrowserHistory} from 'history';
import {runSagas} from 'middleware/sagaMiddleware';
import middleware from 'middleware';
import {Route} from 'react-router-dom';
import {routerMiddleware, ConnectedRouter} from 'connected-react-router';
import rootRoute from 'modules/app/routes/rootRoute';
import {accountSelector} from 'modules/auth/authSelectors';
import {triggerPageViewEvent} from 'modules/app/appUtils';
import {reportSelector} from 'modules/reports/reportSelectors';

// Setup rollbar error logging
require('./setupRollbar')();

// Fix promises on old browsers.
require('es6-promise').polyfill();

// Enable smooth scrolling
require('smoothscroll-polyfill').polyfill();

const history = createBrowserHistory();

const composeEnhancer =
  __DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const store = createStore(
  rootReducer(history),
  undefined,
  composeEnhancer(applyMiddleware(routerMiddleware(history), ...middleware))
);

if (window.gtag) {
  history.listen(loc => {
    const state = store.getState();
    const account = accountSelector(state);
    const report = reportSelector(state);

    triggerPageViewEvent(loc, account, report);
  });
}

if (__DEV__) {
  window._React = React;
  window._store = store;
}

function start() {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route path="/:accountId?" component={rootRoute} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('chellroot')
  );
}

function startIfReady() {
  if (appIsReady(store.getState())) {
    start();
    return true;
  }
}

function startWhenReady() {
  if (startIfReady()) {
    return;
  }

  const unsubscribe = store.subscribe(() => {
    startIfReady() && unsubscribe();
  });
}

runSagas();
startWhenReady();
