import PropTypes from 'prop-types';
import React from 'react';
import SuggestionDatePicker from 'components/datePicker/suggestionDatePicker';

export default class SuggestionDateInput extends React.Component {
  handleChange = value => {
    const {onChange} = this.props;

    onChange && onChange(value);
  };

  render() {
    return <SuggestionDatePicker {...this.props} onChange={this.handleChange} />;
  }
}
