import React from 'react';
import {editWidget} from '../actions';
import {useDispatch, useSelector} from 'hooks/redux';
import {widgetSelector, widgetTypesSelector, savingWidgetSelector} from '../selectors';
import WidgetModal from './widgetModal';

export default function EditWidgetModal({dashboardId, widgetId}) {
  const dispatch = useDispatch();
  const widgetTypes = useSelector(widgetTypesSelector);
  const savingWidgetState = useSelector(savingWidgetSelector);
  const widget = useSelector(widgetSelector, {dashboardId, widgetId});

  const handleSubmit = React.useCallback(
    formData => {
      dispatch(editWidget(dashboardId, widgetId, formData));
    },
    [dashboardId, widgetId]
  );

  return (
    <WidgetModal
      title={`Edit ${widget.get('name')}`}
      onSave={handleSubmit}
      widgetName={widget.get('name')}
      widgetOptions={widget.get('options').toJS()}
      isLoading={savingWidgetState.get('loading')}
      immSupportedOptions={widgetTypes.getIn([widget.get('widgetTypeId'), 'supportedOptions'])}
    />
  );
}
