import React from 'react';

function Form({component = 'form', children, form, onSubmit, ...props}) {
  const FormComponent = component;

  const handleSubmit = event => {
    event.preventDefault();

    if (typeof onSubmit === 'function' && form.attemptSubmit()) {
      onSubmit(form.getFormattedValue(), event);
    }
  };

  return (
    <FormComponent noValidate onSubmit={handleSubmit} {...props}>
      {children}
    </FormComponent>
  );
}

export default React.memo(Form);
