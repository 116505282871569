import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Tooltip from 'components/tooltip';

class Tag extends React.Component {
  render() {
    const {label, appearance, tooltip} = this.props;

    const classes = appearance === 'text' ? '' : cx('label', `appearance-${appearance}`);

    const span = <span className={classes}>{label}</span>;

    if (!tooltip) return span;

    return <Tooltip text={tooltip}>{span}</Tooltip>;
  }
}

class Tags extends React.Component {
  constructor(options) {
    super(options);
    this.state = {showAll: false};
  }

  render() {
    const {options, record} = this.props;
    const {tags} = options;
    const max = 3;

    if (!_.isArray(tags)) return <span />;

    const sortedTags = _.sortBy(tags, 'appearance');
    let count = 0;
    return (
      <div>
        {sortedTags.map((tag, index) => {
          const value = record[tag.data];

          if (value == tag.value) {
            count++;
            if (count <= max || this.state.showAll) {
              return <Tag key={index} {...tag} />;
            }
          }
        })}

        {!this.state.showAll && count > max && (
          <button className="label appearance-minor" onClick={() => this.setState({showAll: true})}>
            <span className="sr-only">More</span>+{count - max}
          </button>
        )}
      </div>
    );
  }
}

Tags.propTypes = {};

export default Tags;
