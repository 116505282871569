// Annoying hack as connected-react-router doesn't expose route params in LOCATION_CHANGE events
export function getAccountIdFromPath(path) {
  const matches = path.match(/^\/(\d*)?\//);
  return matches && matches[1];
}

export function accountsEndpoint() {
  return 'accounts';
}

export function accountEndpoint({accountId}) {
  return accountsEndpoint() + '/' + accountId;
}

export function invoiceDownloadEndpoint(invoiceId) {
  return 'invoices/download/' + invoiceId;
}

export const PAYMENT_TYPES = {
  '': {name: 'None', color: 'minor'},
  f: {name: 'Free', color: 'subtle'},
  n: {name: 'Internal', color: 'subtle'},
  i: {name: 'Invoice', color: 'good'},
  s: {name: 'Stripe', color: 'major'},
  t: {name: 'Trial', color: 'warning'},
  c: {name: 'Cancelled', color: 'bad'}
};

export const PAYMENT_INVOICE = 'i';
