import QualityHelp from '../components/qualityHelp';
import MarketingHelp from '../components/marketingHelp';
import AccessibilityHelp from '../components/accessibilityHelp';
import PagesHelp from '../components/pagesHelp';
import KeywordsHelp from '../components/keywordsHelp';
import DocumentsHelp from '../components/documentsHelp';


export const helpMap = {
  quality: QualityHelp,
  marketing: MarketingHelp,
  accessibility: AccessibilityHelp,
  pages: PagesHelp,
  keywords: KeywordsHelp,
  documents: DocumentsHelp
}