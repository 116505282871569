import {clearData, fetchInspectorPageComplete} from 'modules/inspector/inspectorActions';
import {FETCH_INSPECTOR_PAGE} from 'modules/inspector/inspectorConstants';
import {replace} from 'modules/location';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {couldNot} from 'utils/errorUtils';
import {keyBy} from 'utils/immutableUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {makePath} from 'utils/urlUtils';
import {inspectorEndpoint} from '../inspectorUtils';

export default function* fetchInspectorPageSaga() {
  yield takeLatest(FETCH_INSPECTOR_PAGE, fetchInspectorPage);
}

function* fetchInspectorPage({params, query, onComplete}) {
  const url = makePath(inspectorEndpoint(params), query);
  const handlers = {
    error: handleError,
    success: createResponseHandler(query)
  };

  yield put(clearData());

  // we fetch the points and page content separately, because often the
  // points take an extra few seconds but the html is blazingly fast
  yield all([
    call(processRequest, 'GET', url, handlers)
    // NOTE disabled because of a race condition - we need to do this properly later
    // call(processRequest, 'GET', url, handlers, {fields: 'points'}),
    // call(processRequest, 'GET', url, handlers, {fields: 'page,source'})
  ]);

  if (onComplete) {
    yield call(onComplete);
  }
}

function* handleError() {
  yield put(fetchInspectorPageComplete(couldNot('fetch page data'), null));
}

function createResponseHandler(query) {
  return function* handleResponse(data) {
    const formattedData = formatInspectorPageData(data);

    yield put(fetchInspectorPageComplete(null, formattedData));

    // if we are getting a relative page, we need to set the returned pageHash in the url
    if (data.keys.pageHash && query.pageHash !== data.keys.pageHash) {
      yield put(replace(makePath(location.pathname, {...query, pageHash: data.keys.pageHash})));
    }
  };
}

function formatInspectorPageData(data) {
  // points could be null if we are just requesting the page
  if (data.points) {
    // key actions and categories by their unique ID so we can find them quickly
    data.points = keyBy(data.points, 'pointId').filter(point => {
      return !point.get('decisionId');
    });
    data.actions = keyBy(data.actions, 'actionId');
    // also remove categories with scores that are less than 0
    data.categories = keyBy(data.categories, 'categoryId');
  }

  return data;
}
