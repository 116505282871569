import React from 'react';
import styles from './settingItem.scss';
import cx from 'classnames';

class SettingItem extends React.Component {
  render() {
    const {title, description, children, linear, reverse, hidden, noMargin} = this.props;

    const label = (
      <div className={styles.label}>
        <div className={styles.description}>{title}</div>
        {description}
      </div>
    );

    return (
      <div
        className={cx(styles.wrapper, {
          [styles.linear]: linear,
          [styles.reverse]: reverse,
          [styles.hidden]: hidden,
          [styles.noMargin]: noMargin
        })}
      >
        {!reverse && label}
        <div className={styles.item}>{children}</div>
        {reverse && label}
      </div>
    );
  }
}

export default SettingItem;
