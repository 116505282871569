import {OPEN_NOTIFICATION, RECEIVE_NOTIFICATIONS} from '..';
import {put, select, takeLatest} from 'redux-saga/effects';
import {
  notificationSelector,
  notificationsLoadedSelector
} from 'modules/notifications/notificationSelectors';
import {push, replace} from 'modules/location';
import {linkDescriptionToPath} from 'modules/location/locationUtils';
import selectOrWait from 'utils/saga/selectOrWait';
import {processRequest} from 'utils/saga/fetchUtils';
import {notificationReadEndpoint} from '../notificationUtils';

export default function* openNotificationSaga() {
  yield takeLatest(OPEN_NOTIFICATION, openNotification);
}

function* openNotification({notificationId, useReplace}) {
  // wait for notifications to load if necessary
  yield selectOrWait(notificationsLoadedSelector, RECEIVE_NOTIFICATIONS);

  const {notification} = yield select(notificationSelector, {notificationId});
  if (!notification) {
    throw new Error('Could not open notification');
  }

  // Notify backend of acknowledge
  yield processRequest('POST', notificationReadEndpoint({notificationId}));

  const routeAction = useReplace ? replace : push;
  yield put(routeAction(linkDescriptionToPath(notification.link)));
}
