import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/spinner';
import styles from './summaryScores.scss';
import SummaryScore from './summaryScore.js';
import SummaryScoreCard from './summaryScoreCard';
import cx from 'classnames';

const propTypes = {
  result: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      forTestId: PropTypes.string,
      name: PropTypes.string,
      score: PropTypes.number,
      sectionId: PropTypes.string
    })
  )
};

class SummaryScores extends React.Component {
  renderSummaryScore = (summaryScore, type) => {
    const {forTestId, name, description, score} = summaryScore;
    switch (type) {
      case 'cards':
        return (
          <SummaryScoreCard
            key={forTestId}
            params={this.props.params}
            testId={forTestId}
            name={name}
            description={description}
            score={score}
          />
        );
      default:
        return (
          <SummaryScore
            key={forTestId}
            params={this.props.params}
            testId={forTestId}
            name={name}
            description={description}
            score={score}
          />
        );
    }
  };

  render() {
    const {result, config} = this.props;
    let layout;
    if (config) {
      layout = config.layout;
    }

    return result.length === 0 ? (
      <Spinner centered />
    ) : (
      <div className={cx(styles.summaryScores, layout === 'cards' ? styles.cardLayout : null)}>
        {result.map(summaryScore => this.renderSummaryScore(summaryScore, layout))}
      </div>
    );
  }
}

SummaryScores.propTypes = propTypes;

export default SummaryScores;
