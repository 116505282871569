import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {ADD_WIDGET, EDIT_WIDGET} from 'modules/dashboards/constants';
import {saveWidgetComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {dashboardSelector} from 'modules/dashboards/selectors';
import {
  dashboardEndpoint,
  addWidgetToDashboard,
  editWidgetInDashboard
} from 'modules/dashboards/utils';
import {hideModal} from 'modules/modal';

export default function* saveWidgetListener() {
  yield takeLatest([ADD_WIDGET, EDIT_WIDGET], saveWidgetSaga);
}

function* saveWidgetSaga({type, dashboardId, ...props}) {
  const isNew = type === ADD_WIDGET;
  const url = dashboardEndpoint({dashboardId});

  // The endpoint requires us to pass all the data for a dashboard each time.
  // It would be nice if we could edit a particular widget, but y'know...
  const dashboard = yield select(dashboardSelector, {dashboardId});

  const newDashboard = isNew
    ? yield call(addWidgetToDashboard, dashboard, props)
    : yield call(editWidgetInDashboard, dashboard, props);

  yield processRequest(
    'PUT',
    url,
    {
      *success(dashboard) {
        yield put(saveWidgetComplete(null, dashboard));
        yield put(hideModal());
      },
      *error(err) {
        yield put(saveWidgetComplete(err));
      },
      errorMessage: couldNot('save widget')
    },
    newDashboard.toJS()
  );
}
