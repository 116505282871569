export const RETEST_BEGAN = 'inspector:RETEST_BEGAN';
export const RETEST_COMPLETE = 'inspector:RETEST_COMPLETE';

export const FETCH_INSPECTOR_PAGE = 'inspector:FETCH_INSPECTOR_PAGE';
export const FETCH_INSPECTOR_PAGE_COMPLETE = 'inspector:FETCH_INSPECTOR_PAGE_COMPLETE';

export const SET_MARKED_HTML = 'inspector:SET_MARKED_HTML';
export const UPDATE_MARKED_HTML = 'inspector:UPDATE_MARKED_HTML';

export const SET_EFFECT = 'inspector:SET_EFFECT';
export const CLEAR_DATA = 'inspector:CLEAR_DATA';
export const SELECT_POINTS = 'inspector:SELECT_POINTS';
export const REMOVE_CONTEXT = 'inspector:REMOVE_CONTEXT';
export const SET_DECISION_ID = 'inspector:SET_DECISION_ID';
export const SET_NO_REDIRECT_INSPECTOR_ON_MISSING =
  'inspector:SET_NO_REDIRECT_INSPECTOR_ON_MISSING';
export const EXIT_INSPECTOR = 'inspector:EXIT_INSPECTOR';
// NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
// export const ACTION_FILTER_CHANGED = 'inspector:ACTION_FILTER_CHANGED';

/* Standalone inspector */

export const INITIATE_STANDALONE_REQUEST = 'inspector:INITIATE_STANDALONE_REQUEST';
export const FETCH_STANDALONE_INSPECTOR_DATA = 'inspector:FETCH_STANDALONE_INSPECTOR_DATA';
export const FETCH_STANDALONE_INSPECTOR_DATA_COMPLETE =
  'inspector:FETCH_STANDALONE_INSPECTOR_DATA_COMPLETE';
export const SET_STANDALONE_QUEUE_POS = 'inspector:SET_STANDALONE_QUEUE_POS';
export const SET_STANDALONE_REQUEST_STATUS = 'inspector:SET_STANDALONE_REQUEST_STATUS';
export const CLEAR_STANDALONE_REQUEST_STATE = 'inspector:CLEAR_STANDALONE_REQUEST_STATE';
export const SET_STANDALONE_REQUEST_RESPONSE = 'inspector:SET_STANDALONE_REQUEST_RESPONSE';
export const SET_RETEST_INSPECTOR_PAGE = 'inspector:SET_RETEST_INSPECTOR_PAGE';

// Each key describes effects to be applied. I.E:
//  - the key `dyslexia` will apply the effect `dyslexia`.
//  - the key `glare` will apply the effect `brightness`.
//  - multiple keys can be applied at the same time, but NOT if they contain the same effects.
//    (e.g. if glare is enabled and then cataracts is enabled, then glare will be disabled because they both contain `brightness`)
//
// List of effects are:
//  - dyslexia: Number (time in milliseconds)
//  - brightness: Number (maps to CSS filter `brightness`)
//  - blur: String (maps to CSS filter `blur`)
//  - colorFilter: String (one of ["protanopia", "protanomaly", "deuteranopia", "deuteranomaly", "tritanopia", "tritanomaly", "achromatopsia", "achromatomaly"])
//  - removeCSS: bool (not used - the presence of the key enables the removal of CSS)
export const EFFECT_CONFIG = {
  dyslexia: {
    dyslexia: 100
  },

  glare: {
    blur: '0.5px',
    brightness: 1.2
  },

  lossOfVision1: {
    blur: '1px',
    contrast: 0.9,
    brightness: 1.1
  },

  lossOfVision2: {
    blur: '1.5px',
    contrast: 0.8,
    saturate: 0.6,
    brightness: 1.1
  },

  lossOfVision3: {
    blur: '2px',
    contrast: 0.7,
    saturate: 0.3,
    brightness: 1.1
  },

  protanopia: {
    colorFilter: 'protanopia'
  },

  tritanopia: {
    colorFilter: 'tritanopia'
  },

  monochrome: {
    colorFilter: 'achromatopsia'
  },

  searchEngine: {
    removeCSS: 'searchEngine'
  }
};
