import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_LESSON} from 'modules/courses/constants';
import {lessonEndpoint} from 'modules/courses/utils';
import {requestLessonComplete} from 'modules/courses/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {fromJS, List, Map} from 'immutable';

export default function* requestLessonListener() {
  yield takeLatest(REQUEST_LESSON, requestLessonSaga);
}

function* requestLessonSaga({type, ...action}) {
  const url = lessonEndpoint(action);

  yield processRequest(
    'GET',
    url,
    {
      *success(lessonResponse) {
        const {lesson, questions, answers} = lessonResponse || {};

        const newLesson = fromJS(lesson)
          .update('stateProps', stateProps => {
            return stateProps instanceof Map ? stateProps : Map();
          })
          .set('questions', fromJS(questions || {}))
          .set('answers', fromJS(answers || {}));

        yield put(requestLessonComplete(null, newLesson.toJS()));
      },
      *error(err) {
        yield put(requestLessonComplete(err));
      },
      errorMessage: couldNot('request lesson')
    },
    action
  );
}
