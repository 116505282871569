exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2psiiw9SOWc393X8R7tkdi{width:140px;padding:15px;background:#fff;margin:10px;text-align:center;box-shadow:0px 2px 4px rgba(0,0,0,.3)}._2psiiw9SOWc393X8R7tkdi>div{margin-top:10px;font-size:15px}._2psiiw9SOWc393X8R7tkdi.swNX_gsc7C69J3W_9s-iZ{color:#fff;background:#533be2;box-shadow:inset 0px 2px 4px rgba(0,0,0,.3)}._2psiiw9SOWc393X8R7tkdi:focus{outline:1px dotted #212121;outline:5px auto -webkit-focus-ring-color}._2psiiw9SOWc393X8R7tkdi ._2ZxauGIZUaX1udS8A9udoI{font-size:60px;opacity:.5}", ""]);

// exports
exports.locals = {
	"option": "_2psiiw9SOWc393X8R7tkdi",
	"active": "swNX_gsc7C69J3W_9s-iZ",
	"icon": "_2ZxauGIZUaX1udS8A9udoI"
};