import _ from 'lodash';
import React from 'react';
import SlideBetween from 'components/animation/slideBetween';
import {revolveIndex} from 'utils/numberUtils';
import Icon from 'components/font-awesome';
import Button from 'components/button';
import styles from './dotSwitcher.scss';
import cx from 'classnames';

export default class DotSwitcher extends React.Component {
  static defaultProps = {
    arrows: false,
    dots: true
  };

  state = {index: 0};

  getTotal() {
    return React.Children.count(this.props.children);
  }

  incrementHandler = increment => () => {
    this.setState(state => ({
      index: revolveIndex(state.index, this.getTotal(), increment)
    }));
  };

  indexSetter = index => () => {
    this.setState({index});
  };

  renderDot = i => {
    const className = cx(styles.dot, {
      [styles.active]: i === this.state.index
    });

    return (
      <a className={className} key={i} onClick={this.indexSetter(i)}>
        <Icon name="circle" key={'dot' + i} />
      </a>
    );
  };

  // Set `arrows` to true to show arrows on either side.
  // Set `dots` to true to show dots at the bottom.
  // Defaults to showing dots without arrows.
  render() {
    const {children, className, arrows, dots} = this.props;
    const total = this.getTotal();

    return (
      <div className={cx(styles.base, className)}>
        <SlideBetween currentIndex={this.state.index}>{children}</SlideBetween>

        {total > 1 && arrows && (
          <div className={styles.arrows}>
            <Arrow onClick={this.incrementHandler(-1)} direction="left" />
            <Arrow onClick={this.incrementHandler(1)} />
          </div>
        )}

        {total > 1 && dots && <div className={styles.dots}>{_.times(total, this.renderDot)}</div>}
      </div>
    );
  }
}

function Arrow({direction = 'right', ...props}) {
  return <Button plain icon={`arrow-${direction}`} className={styles.arrow} {...props} />;
}
