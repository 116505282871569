import React from 'react';
import Tooltip from 'components/tooltip';
import Icon from 'components/font-awesome';
import LinkButton from 'modules/location/linkButton';
import {evalInstruction} from 'utils/instructionUtils';
import {prefixObjectKeys} from 'utils/collectionUtils';
import {inspectorPagePath} from 'modules/inspector/inspectorUtils';
import {getLinkBuilder} from './controlUtils';

// Must pass a `command` or the props to build a command (I.E instruction, context, filters)
export default function ControlLinkButton({
  command,
  instruction,
  context,
  filters,
  className,
  tooltip,
  icon,
  children,
  ...buttonStyleProps
}) {
  // get the command from the props or calculate it from instruction
  const {type, context: actionContext, ...params} =
    command || evalInstruction(instruction, context, filters);

  // if context is not iterable, we'll just get an empty object back
  const prefixedContext = prefixObjectKeys(actionContext, 'context');
  // build the query from the context and the params (NOTE we may only want to do this for specific types)
  const query = {...prefixedContext, ...params};
  // get the url builder using the type I.E "inspector" type
  const builder = getLinkBuilder(type);
  if (!builder) return null;

  const linkButton = (
    // we pass `params` because the command might have props it needs to pass to the builder function
    // the builder function will ignore any params it doesn't care about
    <LinkButton
      className={className}
      builder={builder}
      query={query}
      {...params}
      {...buttonStyleProps}
    >
      {icon && <Icon name={icon} />}
      {children}
    </LinkButton>
  );

  if (tooltip) {
    return (
      <Tooltip text={tooltip}>
        <div>{linkButton}</div>
      </Tooltip>
    );
  }

  return linkButton;
}
