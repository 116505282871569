import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Button from 'components/button';
import {ModalContent} from 'modules/modal';
import {Field, Form} from 'modules/form/components';
import {tagsSelector, editingTagSelector} from 'modules/tags/selectors';
import {editTag} from 'modules/tags/actions';
import ErrorMessage from 'modules/form/components/errorMessage';

function EditTagModal({editTag, editingTag, tags, tagId}) {
  const formRef = React.useRef(null);
  const tag = tags.get(tagId);
  const label = tag.get('label');
  const color = tag.get('color');
  const [hasChanged, setHasChanged] = React.useState(false);

  const footer = (
    <Button
      type="submit"
      disabled={!hasChanged}
      isLoading={editingTag.get('loading')}
      onClick={() => {
        formRef.current.submit();
      }}
    >
      Save
    </Button>
  );

  return (
    <ModalContent title="Edit tag" footer={footer}>
      <Form
        ref={formRef}
        defaultValue={{label, color}}
        onChange={(name, value, data) => {
          setHasChanged(data.label !== label || data.color !== color);
        }}
        onValidSubmit={data => {
          editTag(tag.get('tagId'), data.label, data.color);
        }}
      >
        <Field label="Tag label" type="text" name="label" />
        <Field label="Tag color" type="colorPalette" name="color" />

        {editingTag.hasIn(['error', 'message']) && (
          <ErrorMessage>{editingTag.getIn(['error', 'message'])}</ErrorMessage>
        )}
      </Form>
    </ModalContent>
  );
}

export default connect(
  createStructuredSelector({
    tags: tagsSelector,
    editingTag: editingTagSelector
  }),
  {editTag}
)(EditTagModal);
