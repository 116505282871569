import React from 'react';
import Tooltip from 'components/tooltip';

export default class Proportion extends React.Component {
  render() {
    const {data} = this.props;
    if (!data) {
      return null;
    }

    const {numerator, denominator} = data;

    if (!denominator) {
      return null;
    }

    let percentage = Math.round((numerator / denominator) * 1000) / 10;
    if (percentage === 100 && numerator < denominator) {
      percentage = 99.9;
    } else if (percentage < 0) {
      percentage = 0;
    }

    return <span>{percentage}%</span>;

    // @todo Get this working
    // const message = numerator + " / " + denominator
    // return <Tooltip text={message}>{percentage}%</Tooltip>
  }
}
