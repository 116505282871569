exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wqQi9_oK5PE19xqXlq4Yc{display:flex;position:relative;margin:0;width:110px;height:40px;color:#fff;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#bbb;border-radius:20px;overflow:hidden}._3wqQi9_oK5PE19xqXlq4Yc input{position:absolute;right:-99999999999px;opacity:0}._3wqQi9_oK5PE19xqXlq4Yc input:focus+.pFWK9HPG_Xd_L6q-hjHN{outline:auto;outline:5px auto -webkit-focus-ring-color}._3wqQi9_oK5PE19xqXlq4Yc input:checked+.pFWK9HPG_Xd_L6q-hjHN{background-color:#533be2;left:-81px}._3wqQi9_oK5PE19xqXlq4Yc .pFWK9HPG_Xd_L6q-hjHN{height:40px;position:relative;z-index:1;min-width:200px;cursor:pointer;display:flex;align-items:center;justify-content:center;left:-12px;transition:all 300ms ease}._3wqQi9_oK5PE19xqXlq4Yc .pFWK9HPG_Xd_L6q-hjHN .IEB1IVRVGtM_T5BFYuCNf{margin:0 5px;min-width:32px;min-height:32px;border-radius:50%;background-color:#fff;box-shadow:0px 0px 20px 1px rgba(0,0,0,.5)}", ""]);

// exports
exports.locals = {
	"switch": "_3wqQi9_oK5PE19xqXlq4Yc",
	"slider": "pFWK9HPG_Xd_L6q-hjHN",
	"handle": "IEB1IVRVGtM_T5BFYuCNf"
};