import _ from 'lodash';
import React from 'react';
import Button from 'components/button';
import Dropdown from 'components/dropdown';

const DROPDOWN_BUTTON = <Button icon="chevron-down" plain tiny whiteOnHover />;

class ExplorerViews extends React.Component {
  handleSelect = option => {
    const {viewFilter, setFilterParams} = this.props;

    setFilterParams({
      [viewFilter]: option
    });
  };

  getOptions() {
    const {views} = this.props;
    const filteredViews = _.filter(views, view => !_.isUndefined(view.selectable));

    return _.map(filteredViews, view => {
      return {
        label: view.title,
        value: view.name
      };
    });
  }

  render() {
    const {className} = this.props;
    const options = this.getOptions();

    if (!options || options.length < 2) return null;

    return (
      <Dropdown
        button={DROPDOWN_BUTTON}
        options={options}
        onChange={this.handleSelect}
        className={className}
      />
    );
  }
}

export default ExplorerViews;
