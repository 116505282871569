import {LOCATION_CHANGE} from 'connected-react-router';
import {Map, fromJS} from 'immutable';
import _ from 'lodash';
import * as core from './authCore';

export const LOGIN = 'auth:LOGIN';
export const LOGOUT = 'auth:LOGOUT';
export const LOGIN_REQUEST = 'auth:LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'auth:LOGOUT_REQUEST';
export const LOGIN_FAILED = 'auth:LOGIN_FAILED';
export const LOAD_TOKEN = 'auth:LOAD_TOKEN';
export const SET_ACCOUNTID_IN_PATH = 'auth:SET_ACCOUNTID_IN_PATH';
export const SET_PROSPECT_LOGIN_URL = 'auth:SET_PROSPECT_LOGIN_URL';
export const UPDATE_SELF_CONSENTS = 'auth:UPDATE_SELF_CONSENTS';
export const UPDATE_SELF_CONSENTS_COMPLETE = 'auth:UPDATE_SELF_CONSENTS_COMPLETE';

const initialState = Map();

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REQUEST_SELF:
      return core.loginRequest(state);

    case SET_PROSPECT_LOGIN_URL:
      return state.set('prospectLoginUrl', action.prospectLoginUrl);

    case RECEIVE_USING_SERVICES:
      return state.set('usingServices', action.usingServices);

    case LOGIN:
      return core.login(state, action.user, action.token);

    case LOGOUT:
    case LOGIN_FAILED:
      return core.logout(state, action.error);

    case LOAD_TOKEN:
      return core.loadToken(state, action.token);

    case SWITCH_ACCOUNT:
    case STORE_ACCOUNT:
      return core.storeAccount(state, action.accountId);

    // Worried about performance of hooking into this action (gets fired a lot)
    case LOCATION_CHANGE:
    case SET_ACCOUNTID_IN_PATH:
      return core.setAccountIdFromLocation(state, action.payload.location);

    case STORE_LOGIN_DETAILS_FOR_2FA:
      return state.set('2faDetails', fromJS(action.details));

    case SET_LOGGING_IN:
      return state.set('loggingIn', action.isLoggingIn);
  }

  return state;
}

// Actions
export function requestLogin(email, password, code, isOnTwoFactorPage = false) {
  return {
    type: LOGIN_REQUEST,
    email,
    password,
    code,
    isOnTwoFactorPage
  };
}

// the api returned a user and token
export function login(user, token) {
  return {
    type: LOGIN,
    token,
    user
  };
}

export function setProspectLoginUrl(prospectLoginUrl) {
  return {
    type: SET_PROSPECT_LOGIN_URL,
    prospectLoginUrl
  };
}

export function loginFailed(errorMessage = null) {
  return {
    type: LOGIN_FAILED,
    error: errorMessage
  };
}

export function logout() {
  return {type: LOGOUT_REQUEST};
}

export const RECEIVE_USING_SERVICES = 'app:RECEIVE_USING_SERVICES';
export function receiveUsingServices(usingServices) {
  return {
    type: RECEIVE_USING_SERVICES,
    usingServices
  };
}

export function logoutComplete(errorMessage) {
  return {type: LOGOUT, errorMessage};
}

export function loadToken(token) {
  if (!token) return;

  return {
    type: LOAD_TOKEN,
    token
  };
}

export const REQUEST_SELF = 'auth:REQUEST_SELF';
export function loadSelf() {
  return {type: REQUEST_SELF};
}

export const STORE_ACCOUNT = 'auth:STORE_ACCOUNT';
export function storeAccount(accountId) {
  return {
    type: STORE_ACCOUNT,
    accountId
  };
}

export function setAccountIdInPath(location) {
  return {
    type: SET_ACCOUNTID_IN_PATH,
    payload: {location}
  };
}

export const SWITCH_ACCOUNT = 'auth:SWITCH_ACCOUNT';
export function switchAccount(accountId, userId) {
  return {
    type: SWITCH_ACCOUNT,
    accountId,
    userId // we provide this so that usersReducer knows which userId to keep
  };
}

export const SWITCH_ACCOUNT_IF_ALLOWED = 'auth:SWITCH_ACCOUNT_IF_ALLOWED';
export function switchAccountIfAllowed(accountId) {
  return {
    type: SWITCH_ACCOUNT_IF_ALLOWED,
    accountId
  };
}

export const STORE_LOGIN_DETAILS_FOR_2FA = 'auth:STORE_LOGIN_DETAILS_FOR_2FA';
export function storeLoginDetailsFor2FA(details) {
  return {type: STORE_LOGIN_DETAILS_FOR_2FA, details}; // email, password
}

export const SET_LOGGING_IN = 'auth:SET_LOGGING_IN';
export function setLoggingIn(isLoggingIn) {
  return {type: SET_LOGGING_IN, isLoggingIn};
}

// Events when the logged in user changes
export const AUTH_CHANGE = [LOGIN, SWITCH_ACCOUNT];

export function updateSelfConsents(userId, consents) {
  return {type: UPDATE_SELF_CONSENTS, userId, consents};
}
export function updateSelfConsentsComplete(userId, consents) {
  return {type: UPDATE_SELF_CONSENTS_COMPLETE, userId, consents};
}
