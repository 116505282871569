import React from 'react';
import PropTypes from 'prop-types';
import {Portal} from 'react-portal';
import getOffsets from 'positions';
import {findDOMNode} from 'react-dom';

export default class Position extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    menuAnchor: PropTypes.string,
    node: PropTypes.object.isRequired,
    nodeAnchor: PropTypes.string,
    offsetLeft: PropTypes.number,
    offsetTop: PropTypes.number
  };

  static defaultProps = {
    menuAnchor: 'top right',
    nodeAnchor: 'bottom left',
    offsetLeft: 0,
    offsetTop: 0
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    document.addEventListener('click', this.handleClickAnywhere);

    this.menu = this.portal;
    this.node = findDOMNode(this.props.node);

    // portal has no api for changing styles
    this.menu.style.position = 'absolute';
    this.menu.style.zIndex = '9999';

    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleClickAnywhere);
  }

  setPortal = ref => {
    this.portal = findDOMNode(ref);
  };

  handleClickAnywhere = event => {
    const {onClickOutside} = this.props;
    const didClickOutside = !event.path || !event.path.includes(this.portal);

    if (didClickOutside) {
      onClickOutside && onClickOutside();
    }
  };

  handleScroll = event => {
    this.update();
  };

  update() {
    const {menu, node} = this;

    const {offsetLeft, offsetTop} = this.props;
    let {menuAnchor, nodeAnchor} = this.props;
    let {top, left} = getOffsets(menu, menuAnchor, node, nodeAnchor);

    ({menuAnchor, nodeAnchor} = this.getForcedDirection(top, left, menuAnchor, nodeAnchor));
    ({top, left} = getOffsets(menu, menuAnchor, node, nodeAnchor));

    menu.style.top = `${top + offsetTop}px`;
    menu.style.left = `${left + offsetLeft}px`;
  }

  getForcedDirection(top, left, menuAnchor, nodeAnchor) {
    let [menuVer, menuHor] = menuAnchor.split(' ', 2);
    let [nodeVer, nodeHor] = nodeAnchor.split(' ', 2);

    // menu is bigger than the screen, so no amount of moving will help
    if (this.menu.clientHeight > window.innerHeight) {
      return {menuAnchor, nodeAnchor};
    }

    if (top + this.menu.clientHeight > window.innerHeight) {
      // force to top side
      menuVer = 'bottom';
      nodeVer = 'top';
    } else if (top < 0) {
      // force to bottom side
      menuVer = 'top';
      nodeVer = 'bottom';
    }

    if (left + this.menu.clientWidth > window.innerWidth) {
      // force to left side
      menuHor = 'right';
      nodeHor = 'right';
    } else if (left < 0) {
      // force to right side
      menuHor = 'left';
      nodeHor = 'left';
    }

    return {
      menuAnchor: [menuVer, menuHor].join(' '),
      nodeAnchor: [nodeVer, nodeHor].join(' ')
    };
  }

  render = () => {
    const {children, menuAnchor, node, nodeAnchor, ...props} = this.props;

    return (
      <Portal isOpen ref={this.setPortal} {...props}>
        {children}
      </Portal>
    );
  };
}
