exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TK6u4UxwM59HQ9l3Gaj6y{margin:5px 0 0;color:#444;text-align:left;display:flex}.xqb3cycKmZv9CIPWdQt9L{flex:1 0 auto}._1MvWkIKwjkocBcEWuNZdHe{flex:0 1 auto}", ""]);

// exports
exports.locals = {
	"label": "_1TK6u4UxwM59HQ9l3Gaj6y",
	"left": "xqb3cycKmZv9CIPWdQt9L",
	"right": "_1MvWkIKwjkocBcEWuNZdHe"
};