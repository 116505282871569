import React from 'react';
import Score from './score';
import styles from './score.scss';

export default function ScoreDescription({score, name, description, titleComponent = 'h3'}) {
  const Heading = titleComponent;

  return (
    <div className={styles.scoreDescription}>
      <div className={styles.innerScore}>
        <Score value={score} hideLabel />
      </div>
      <div className={styles.innerText}>
        <Heading className={styles.heading}>{name}</Heading>
        {description && <p className={styles.description}>{description}</p>}
      </div>
    </div>
  );
}
