import {take, select, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {SET_NPS_DUE, SET_IDLE, setNPSEnable} from 'modules/nps';
import {isIdleSelector, npsDueSelector, isOpenSelector} from 'modules/nps/npsSelectors';
import {isAccountDisabledSelector} from 'modules/accounts/accountDisabledSelector';

export default function* getNPSDueSaga() {
  yield takeLatest([SET_NPS_DUE, SET_IDLE], getNPSDue);
}

function* getNPSDue({user}) {
  const isOpen = yield select(isOpenSelector);
  const isIdle = yield select(isIdleSelector);
  const npsDue = yield select(npsDueSelector);
  const isDisabled = yield select(isAccountDisabledSelector);
  const isDueNow = npsDue && Date.now() >= npsDue * 1000;

  // On the tool select page, we do not want to see the NPS feedback widget!
  const isBlockedPage = window.location && window.location.href.indexOf('tool-select') !== -1;

  if (!isDisabled && !isOpen && isIdle && isDueNow && !isBlockedPage) {
    yield put(setNPSEnable(true));
  }
}
