import React from 'react';
import styles from '../timeline.scss';

function LinkPointItem({storyHash, storyPointId, time, props}) {
  const {brokenLink, pageName, screenshotUrl, url} = props;
  return (
    <li className={styles.pagesListItem}>
      <img src={screenshotUrl} width={150} />
      <span>{brokenLink}</span>
    </li>
  );
}

export default LinkPointItem;
