import React from 'react';
import styles from './wappalyzerIssue.scss';
import ExternalLink from 'modules/location/externalLink';

class WappalyzerIssue extends React.Component {
  render() {
    const {
      point: {name, icon, helpUrl, version, categories, text},
      action: {actionId, decisions}
    } = this.props;

    return (
      <div>
        <p>
          <ExternalLink href={helpUrl}>
            <img src={'https://www.wappalyzer.com/images/icons/' + icon} className={styles.icon} />
          </ExternalLink>
        </p>
        <p className={styles.name}>
          <ExternalLink href={helpUrl}>
            {name} {version}
          </ExternalLink>
        </p>
        <p className={styles.categories}>
          {categories} {text}
        </p>
      </div>
    );
  }
}

export default WappalyzerIssue;
