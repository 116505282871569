import {delay} from 'redux-saga';
import {select, take, put, takeLatest} from 'redux-saga/effects';
import {RECEIVE_FOLDER_VIEWS} from 'modules/folderViews';
import {VIEW_FOLDER_PAGE} from 'modules/folders';
import {folderViewPath} from 'modules/folderViews/folderViewUtils';
import {folderViewsSelector} from 'modules/folderViews/folderViewSelectors';
import {replace} from 'modules/location';
import selectOrWait from 'utils/saga/selectOrWait';
import {locationParamsSelector} from 'modules/location/locationSelectors';

export default function* redirectToFirstViewSaga() {
  yield takeLatest(VIEW_FOLDER_PAGE, redirectToFirstView);
}

// If we view a folder view without a viewId, redirect to first view
function* redirectToFirstView({folderId}) {
  // `viewId` is not passed through the VIEW_FOLDER_PAGE action (maybe it should be?)
  const {viewId} = yield select(locationParamsSelector);

  if (viewId) return;

  let views = yield selectOrWait(folderViewsSelector, RECEIVE_FOLDER_VIEWS);
  // Get the first view for this folder
  let firstView = views.find(view => view.get('folderId') == folderId);

  // We don't have a view for this folder so wait for views to be received
  if (!firstView) {
    yield take(RECEIVE_FOLDER_VIEWS);

    views = yield select(folderViewsSelector);
    firstView = views.find(view => view.get('folderId') == folderId);
  }

  // Without this delay, sometimes the redirect doesn't work.
  yield delay(200);

  yield put(replace(folderViewPath, {folderId, viewId: firstView.get('viewId')}));
}
