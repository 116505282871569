import {Map, fromJS} from 'immutable';
import * as Consts from './constants';
import {createLoadingState, startLoadingState, endLoadingState} from 'modules/app/reduxHelpers';

const initialState = fromJS({
  // data state
  interaction: null,
  instructionTypes: [],
  interactionTestResults: {},

  // loading state (contains all the loading state for each async operation)
  requestInteraction: createLoadingState(),
  requestInstructionTypes: createLoadingState(),
  saveInteraction: createLoadingState(),
  testInteraction: createLoadingState()
});

export default function interactionsReducer(state = initialState, action) {
  switch (action.type) {
    case Consts.REQUEST_INTERACTION:
      return startLoadingState(state, 'requestInteraction').set('interaction', null);

    case Consts.REQUEST_INTERACTION_COMPLETE: {
      const loadingState = endLoadingState(state, 'requestInteraction', action.error);
      return action.data ? loadingState.set('interaction', fromJS(action.data)) : loadingState;
    }

    case Consts.REQUEST_INSTRUCTION_TYPES:
      return startLoadingState(state, 'requestInstructionTypes').set('instructionTypes', []);

    case Consts.REQUEST_INSTRUCTION_TYPES_COMPLETE: {
      const loadingState = endLoadingState(state, 'requestInstructionTypes', action.error);
      return action.data ? loadingState.set('instructionTypes', fromJS(action.data)) : loadingState;
    }

    case Consts.SAVE_INTERACTION:
      return startLoadingState(state, 'saveInteraction');

    case Consts.SAVE_INTERACTION_COMPLETE:
      return endLoadingState(state, 'saveInteraction', action.error);

    case Consts.TEST_INTERACTION:
      return startLoadingState(state, 'testInteraction');

    case Consts.TEST_INTERACTION_COMPLETE:
      const loadingState = endLoadingState(state, 'testInteraction', action.error);
      return action.data
        ? loadingState.set('interactionTestResults', fromJS(action.data))
        : loadingState;

    case Consts.CLEAR_TEST_RESULTS:
      return state
        .set('testInteraction', createLoadingState())
        .set('interactionTestResults', Map());

    default:
      return state;
  }
}
