import React from 'react';
import Toggle from 'components/toggle';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import {DropdownBody} from 'components/dropdown';
import NotificationsList from './notificationsList';
import NotificationsButton from './notificationsButton';
import {connect} from 'react-redux';
import {userIdSelector} from 'modules/auth/authSelectors';
import {viewNotifications, receiveNotification} from 'modules/notifications';
import Pusher from 'modules/pusher/components/pusher';
import {userChannel} from 'modules/pusher/channels';

export class NotificationsDropdown extends React.Component {
  handleOpen = () => {
    this.props.viewNotifications();
  };

  render() {
    const {userId, smallIcon} = this.props;
    return (
      <div className={this.props.className}>
        <Toggle
          menuAnchor="bottom left"
          nodeAnchor="bottom right"
          offsetTop={-10}
          offsetLeft={-14}
          onOpen={this.handleOpen}
          button={<NotificationsButton smallIcon />}
        >
          <DropdownBody>
            <NotificationsList />
          </DropdownBody>
        </Toggle>
        <Pusher
          channel={userChannel({userId})}
          event="notification"
          onUpdate={receiveNotification}
        />
      </div>
    );
  }
}

export default connect(
  state => ({userId: userIdSelector(state)}),
  {viewNotifications}
)(NotificationsDropdown);
