import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {canRetestEndpoint} from 'modules/reports/reportUtils';
import {retestReport} from 'modules/reports';
import {REQUEST_RETEST_REPORT} from 'modules/reports/reportConstants';
import RetestModal from 'modules/missions/components/retestModal';
import NoEntitlementsModal from 'modules/missions/components/noEntitlementsModal';
import {showInModal} from 'modules/modal';

export default function* requestRetestSaga() {
  yield takeLatest(REQUEST_RETEST_REPORT, requestRetest);
}

function* requestRetest({reportId, reportType, preventOpenModal}) {
  if (reportType === 'website') {
    const {canRetest, canRetestPages, usesUp, remaining} = yield fetchCanRetest(reportId);

    if (preventOpenModal) return;

    if (canRetest || canRetestPages) {
      yield put(
        showInModal(RetestModal, {
          reportId,
          canRetest,
          canRetestPages,
          usesUp,
          remaining,
          defaultValue: {
            subject: canRetest ? 'report' : 'urls'
          }
        })
      );
    } else {
      yield put(showInModal(NoEntitlementsModal));
    }
  } else {
    yield put(retestReport(reportId));
  }
}

function* fetchCanRetest(reportId) {
  return yield processRequest('GET', canRetestEndpoint({reportId}), {});
}
