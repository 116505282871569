import React from 'react';
import Panel from 'components/panel';
import StatValue from './statValue';
import StatLabel from './statLabel';

const StatPanel = ({label, children, ...props}) => (
  <Panel hidePadding>
    <StatValue label={<StatLabel nowrap={props.stacked}>{label}</StatLabel>} {...props}>
      {children}
    </StatValue>
  </Panel>
);

export default StatPanel;
