import React from 'react';
import PropTypes from 'prop-types';
import styles from './tickList.scss';

const propTypes = {
  columnCount: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

const tickList = ({children, columnCount, className, ...rest}) => (
  <ul className={className} style={{columnCount}} {...rest}>
    {children}
  </ul>
);

tickList.propTypes = propTypes;

tickList.defaultProps = {
  columnCount: 1
};

export default tickList;
