import {put, select, takeLatest} from 'redux-saga/effects';
import {UPDATE_FILTERS} from 'modules/reportFilters';
import {fetchFolderViews} from 'modules/folderViews';
import {folderIdSelector} from 'modules/folderViews/folderViewSelectors';
import {createParamSelector} from 'modules/location/locationSelectors';

export default function* updateFiltersSaga() {
  yield takeLatest(UPDATE_FILTERS, refetchFolderViews);
}

function* refetchFolderViews() {
  const folderId = yield select(folderIdSelector);
  const viewId = yield select(createParamSelector('viewId'));

  yield put(fetchFolderViews(folderId, viewId));
}
