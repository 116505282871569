import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import Spinner from 'components/spinner';
import {havePropsChanged} from 'utils/propUtils';

// This was originally abstracted by an Iframe component
// but it broke refs. TODO: find out why.
import styles from './iframe.scss';

// String we look for in message.protocol to assert we should handle it.
const MESSAGING_PROTOCOL = 'IMP'; // Iframe Messaging Protocol, yo!
const READY_METHOD_NAME = 'iframeIsReady';

function IMPMessage(methodName, args = []) {
  return {methodName, arguments: args, protocol: MESSAGING_PROTOCOL};
}

/*
  const data = {selectedIssue: 12};
  const actions = {
    selectIssue() {}
  };
  function handleReady() {}

  <InteractiveIframe
    data={data}
    actions={actions}
    onReady={handleReady}
  />

  Renders an iframe using html prop as data:text/html
  falling back to a url prop for older browsers or when html isn't set

  Takes a data prop and passes it to the iframe once it's ready and on change.
  Triggers actions on receipt of messages.

  If we receive a message with method: 'selectIssue', we trigger
  props.actions.selectIssue() with the passed arguments

  A message looks like:
  {
    method: 'selectIssue',
    arguments: [1, {issueId: 1, issueName: 'blah'}]
    protocol: 'IMP'
  }
*/
export default class InteractiveIframe extends React.Component {
  static defaultProps = {
    html: ''
  };

  state = {
    ready: false,
    hasCompletedInitialLoad: false
  };

  // Listen for messages from iframe
  componentDidMount() {
    window.addEventListener('message', this.handleReceiveMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleReceiveMessage);
  }

  hasSrcChanged(nextProps) {
    return this.props.html !== nextProps.html;
  }

  // Only update if html/url changes. Otherwise we update by sending messages when component receives props.
  // I.E. shouldComponentUpdate almost always returns false but we procedurally interact with the iframe.
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.hasSrcChanged(nextProps) ||
      nextState.ready !== this.state.ready ||
      nextProps.isLoading !== this.props.isLoading
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.hasSrcChanged(nextProps) && this.state.hasCompletedInitialLoad) {
      this.disableOnload = true;
      this.injectHtml(nextProps);
    }

    if (havePropsChanged(this.props, nextProps, ['data'])) {
      this.postDataToIframe(nextProps.data);
    }
  }

  /*
    Handle a message event. If event.data.source is 'inspectorPage',
    it is from the iframe so we trigger the event handler.
   */
  handleReceiveMessage = event => {
    const {protocol, methodName, arguments: args = []} = event.data;

    // Confirm message is for us.
    if (protocol !== MESSAGING_PROTOCOL) return;

    // Capture the iframe ready event and handle internally.
    if (methodName === READY_METHOD_NAME) {
      this.handleIframeReady();
      return;
    }

    // Otherwise, trigger the method if it exists.
    this.runAction(methodName, args);
  };

  runAction(methodName, args = []) {
    // Otherwise, trigger the method if it exists.
    const method = this.props.actions[methodName];

    if (!method && __DEV__) {
      console.warn(
        `InteractiveIframe received message with methodName: "${methodName}" but could not handle it`
      );
      return;
    }

    method(...args);
  }

  postDataToIframe(data) {
    if (!this.iframe) return;

    this.iframe.contentWindow.postMessage(IMPMessage('updateData', [data]), '*');
  }

  handleIframeReady() {
    this.setState({ready: true}, () => {
      // Send initial data over once state.ready is set.
      this.postDataToIframe(this.props.data);

      const {onReady} = this.props;
      onReady && onReady();
    });
  }

  injectHtml = props => {
    const {html} = props;

    if (this.iframe) {
      this.iframe.src = 'about:blank';
      this.iframe.contentWindow.document.open();
      this.iframe.contentWindow.document.write(html);
      this.iframe.contentWindow.document.close();
    }
  };

  setIframe = ref => {
    this.iframe = ref;
  };

  onLoadInitialIframe = event => {
    if (this.state.hasCompletedInitialLoad) {
      return null;
    }
    this.setState({hasCompletedInitialLoad: true}, () => {
      if (this.disableOnload) {
        this.disableOnload = false;
        return;
      }
      this.injectHtml(this.props);
    });
  };

  renderLoader = () => {
    return (
      <div className={styles.hideIframeWrapper}>
        <Spinner className={styles.spinner} />
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <iframe
          src="/iframe.html"
          ref={this.setIframe}
          title={this.props.title}
          onLoad={this.onLoadInitialIframe}
          sandbox="allow-same-origin allow-scripts"
          className={cx(styles.iframe, this.props.className)}
        />
        {(this.props.isLoading || !this.state.ready || !this.state.hasCompletedInitialLoad) &&
          this.renderLoader()}
      </div>
    );
  }
}
