import React from 'react';
import {useDispatch} from 'hooks/redux';
import {showInModal} from 'modules/modal';
import Icon from 'components/font-awesome';
import Flex from 'components/layout/flex';
import Pill from 'components/pill/pill';
import Pills from 'components/pill/pills';
import ReportAssignedTagsModal from 'modules/tags/components/reportAssignedTagsModal';
import Gandalf from 'components/gandalf/gandalf';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import styles from './reportTags.scss';

export default function ReportTags({result, params}) {
  const dispatch = useDispatch();

  if (!result) return null;

  const resultPills = Object.keys(result).map(tagId => ({
    appearance: 'major',
    label: result[tagId].label,
    color: result[tagId].color
  }));

  return (
    <Flex container>
      <Flex grow={0}>
        <Pills data={resultPills} className={styles.tags} />
      </Flex>
      <Gandalf shallPass={ACCOUNT_OWNER}>
        <Flex grow={1}>
          <Pill
            appearance="minor"
            onClick={() => {
              dispatch(showInModal(ReportAssignedTagsModal));
            }}
          >
            <Icon name="tag" className={styles.tagIcon} />
          </Pill>
        </Flex>
      </Gandalf>
    </Flex>
  );
}

ReportTags.handlesLoading = true;
