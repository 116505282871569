import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Pill from 'components/pill';
import Button from 'components/button';
import Spinner from 'components/spinner';
import {updateAvailableChecks} from 'modules/checks';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import {availableChecksComponentSelector} from 'modules/checks/checkSelectors';
import {choosePluralVersion} from 'utils/interpolationUtils';

export class AvailableChecks extends React.Component {
  static defaultProps = {
    params: {},
    availableChecks: [],
    filters: {}
  };

  handleValidSubmit = data => {
    const {updateAvailableChecks} = this.props;
    updateAvailableChecks(data);
  };

  renderDescription(check) {
    return (
      <div>
        Used in {choosePluralVersion(check.get('testNames').size, 'test ', 'tests: ')}
        {check.get('testNames').map(name => {
          return (
            <Pill appearance="good" key={name}>
              {name}
            </Pill>
          );
        })}
      </div>
    );
  }

  renderCheck = check => {
    return (
      <SettingItem
        reverse
        key={check.get('checkType')}
        title={check.get('name')}
        description={this.renderDescription(check)}
      >
        <Field type="boolean" name={check.get('checkType')} value={check.get('check.isIncluded')} />
      </SettingItem>
    );
  };

  render() {
    const {defaultValue, checks, isLoading} = this.props;

    if (isLoading) return <Spinner />;

    return (
      <Form
        style={{marginBottom: '20px'}}
        defaultValue={defaultValue}
        onValidSubmit={this.handleValidSubmit}
      >
        {checks.map(this.renderCheck)}

        <Button type="submit" style={{margin: '0'}}>
          Save
        </Button>
      </Form>
    );
  }
}

export default connect(
  availableChecksComponentSelector,
  {updateAvailableChecks}
)(AvailableChecks);
