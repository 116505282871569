import {Map, Set, fromJS} from 'immutable';
import {
  REQUEST_SCOPES,
  REQUEST_SCOPES_COMPLETE,
  SET_SCOPES,
  REQUEST_CHECKS,
  REQUEST_CHECKS_COMPLETE,
  SET_CHECKS,
  REQUEST_POLICY,
  REQUEST_POLICY_COMPLETE,
  SET_POLICY,
  REQUEST_LIBRARY_POLICIES,
  REQUEST_LIBRARY_POLICIES_COMPLETE,
  SET_LIBRARY_POLICIES,
  REQUEST_LIBRARY_POLICY,
  REQUEST_LIBRARY_POLICY_COMPLETE,
  SET_LIBRARY_POLICY
} from './constants';

const initialState = fromJS({
  scopes: [],

  // the available checks keyed by scope
  checks: {
    page: [],
    document: []
  },

  // only used when editting a policy
  policy: null, // the active policy
  libraryPolicy: null,

  libraryPolicies: []

  // policies are store in the results reducer
});

export default function actionsReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SCOPES:
      return state.set('requestingScopes', true);

    case REQUEST_SCOPES_COMPLETE:
      return state.set('requestingScopes', false);

    case SET_SCOPES:
      return state.set('scopes', fromJS(action.scopes));

    case REQUEST_CHECKS:
      return state.set('requestingChecks', true);

    case REQUEST_CHECKS_COMPLETE:
      return state.set('requestingChecks', false);

    case SET_CHECKS:
      if (!state.hasIn(['checks', action.scope])) {
        throw new Error('Invalid scope passed to policies reducer SET_CHECKS');
      }
      return state.setIn(['checks', action.scope], fromJS(action.checks));

    case REQUEST_POLICY:
      return state.set('requestingPolicy', true).set('policy', null);

    case REQUEST_POLICY_COMPLETE:
      return state.set('requestingPolicy', false);

    case SET_POLICY:
      return state.set('policy', fromJS(action.policy));

    case REQUEST_LIBRARY_POLICIES:
      return state.set('requestingLibraryPolicies', true);

    case REQUEST_LIBRARY_POLICIES_COMPLETE:
      return state.set('requestingLibraryPolicies', false);

    case SET_LIBRARY_POLICIES:
      return state.set('libraryPolicies', fromJS(action.libraryPolicies));

    case REQUEST_LIBRARY_POLICY:
      return state.set('requestingLibraryPolicy', true);

    case REQUEST_LIBRARY_POLICY_COMPLETE:
      return state.set('requestingLibraryPolicy', false);

    case SET_LIBRARY_POLICY:
      return state.set('libraryPolicy', fromJS(action.libraryPolicy));
  }
  return state;
}
