exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3igscDDPrmRcV1jLqBnFIp{margin:10px 15px !important;min-height:425px}._3igscDDPrmRcV1jLqBnFIp p{margin:0}._3igscDDPrmRcV1jLqBnFIp p._1MXq3Au_aMHtmypcE1tn7N{margin-left:10px}._3igscDDPrmRcV1jLqBnFIp ._2l2V-1_Es0e88McqxTKPxe{margin-top:10px}._3igscDDPrmRcV1jLqBnFIp a{word-break:break-all}", ""]);

// exports
exports.locals = {
	"wrapper": "_3igscDDPrmRcV1jLqBnFIp",
	"itemResponse": "_1MXq3Au_aMHtmypcE1tn7N",
	"item": "_2l2V-1_Es0e88McqxTKPxe"
};