import {take, select, cancel, put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {FETCH_ACCOUNT_OPTIONS, receiveAccountOptions} from 'modules/accountOptions';
import {SWITCH_ACCOUNT} from 'modules/auth';
import {accountOptionsEndpoint} from 'modules/accountOptions/accountOptionUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';

export default function* fetchAccountOptionsSaga() {
  yield takeLatest([FETCH_ACCOUNT_OPTIONS, SWITCH_ACCOUNT], fetchAccountOptions);
}

function* fetchAccountOptions() {
  const accountId = yield select(accountIdSelector);

  yield processRequest('GET', accountOptionsEndpoint({accountId}), {
    success: function*(options) {
      yield put(receiveAccountOptions(options));
    }
  });
}
