import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DASHBOARDS} from 'modules/dashboards/constants';
import {dashboardsEndpoint} from 'modules/dashboards/utils';
import {requestDashboardsComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestDashboardsListener() {
  yield takeLatest(REQUEST_DASHBOARDS, requestDashboardsSaga);
}

function* requestDashboardsSaga({type, ...dashboard}) {
  const url = dashboardsEndpoint();

  yield processRequest(
    'GET',
    url,
    {
      *success(dashboards) {
        yield put(requestDashboardsComplete(null, dashboards));
      },
      *error(err) {
        yield put(requestDashboardsComplete(err));
      },
      errorMessage: couldNot('request dashboard')
    },
    dashboard
  );
}
