import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {parseQuery} from 'utils/urlUtils';
import ReportBreadcrumb from 'modules/breadcrumb/containers/reportBreadcrumb';
import {secondaryNavigationSelector} from 'modules/app/appSelectors';
import ReportNavButtons from 'modules/reports/components/reportNavButtons';
import styles from './secondaryNavigation.scss';
import useWindowWidth from 'hooks/useWindowWidth';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import ShareButton from 'modules/app/containers/shareButton';
import RetestButton from 'modules/app/containers/retestButton';
import SidebarToggleTrigger from '../sidebarToggleTrigger';

function SecondaryNavigation({location, match, children, ...breadcrumbProps}) {
  const width = useWindowWidth();
  const {tablet, largeMobile} = useContext(ScreenSizeContext);

  // const query = parseQuery(location.search);
  let reportId, segmentId, archiveId;
  if (match.params.reportId) {
    [reportId, segmentId = 0, archiveId = 'live'] = match.params.reportId.split('-', 3);
  }

  return (
    <div className={styles.SecondaryNavigation}>
      {children}

      {!children && !tablet && (
        <ReportBreadcrumb
          location={location}
          folderId={match.params.folderId || 'all-websites'}
          segmentId={segmentId}
          archiveId={archiveId}
          {...breadcrumbProps}
          reportId={reportId}
        />
      )}
      {!children && tablet && <SidebarToggleTrigger />}

      {children ? null : tablet && match.params.reportId ? (
        <div className={styles.wrapperClass}>
          <ShareButton reportId={match.params.reportId} className={styles.buttonClass} />
          <RetestButton reportId={match.params.reportId} className={styles.buttonClass} />
        </div>
      ) : (
        <ReportNavButtons
          small={width < 910}
          wrapperClass={styles.wrapperClass}
          className={styles.buttonsClass}
          buttonClassName={styles.buttonClass}
        />
      )}
    </div>
  );
}

export default connect(secondaryNavigationSelector)(SecondaryNavigation);
