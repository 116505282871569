import {put} from 'redux-saga/effects';
import {push} from 'modules/location';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {forcedPagesEndpoint, forcedPagesSettingsPath} from 'modules/reports/reportUtils';
import {deleteForcedPage} from 'modules/reports';
import {CONFIRM_DELETE_FORCED_PAGE} from '../reportConstants';
import {showSuccess} from 'utils/saga/alerts';

export default generateDeleteSaga(
  'forced page',
  CONFIRM_DELETE_FORCED_PAGE,
  forcedPagesEndpoint,
  {
    success: function*({reportId, forcedPageId, pageNum}) {
      yield put(deleteForcedPage(reportId, forcedPageId, pageNum));
      yield put(push(forcedPagesSettingsPath, {}, ['reportId']));
      yield showSuccess('Forced page deleted');
    }
  },
  'Delete',
  {
    title: 'Are you sure?',
    content: 'Are you sure you wish to remove this forced page?'
  }
);
