import _ from 'lodash';
import {useEffect} from 'react';

export default function Rule({field, show, inlineRule, customMessages, ...rules}) {
  const shouldShow = typeof show === 'boolean' ? show : field.wasAttempted();

  useEffect(() => {
    return () => {
      // When this field is unmounted, clear any validation errors otherwise
      // the hasError will return true even though the fields error message is not being rendered.
      field.setValidationResult(null);
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(rules)) {
      field.validate(rules, inlineRule, customMessages);
    }
  }, [
    // if the rules change
    JSON.stringify(rules),
    // or if the value changes
    JSON.stringify(field.getValue())
  ]);

  return shouldShow && field.validationResult ? field.validationResult : null;
}
