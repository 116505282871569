import {Map} from 'immutable';

// TODO: this file is a clusterfuck of strangely named methods - spend time refactoring

const endpoint = 'users';

const INITIAL_STATE = Map();

export default function passwordsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VALIDATE_UNLOCK_USER_TOKEN:
    case VALIDATE_PASSWORD_RESET_TOKEN:
      return state.set('requestingToken', true).remove('tokenExpired');
    case VALIDATE_UNLOCK_USER_TOKEN_SUCCESS:
    case VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS:
      return state.remove('tokenExpired').remove('requestingToken');
    case VALIDATE_UNLOCK_USER_TOKEN_FAILURE:
    case VALIDATE_PASSWORD_RESET_TOKEN_FAILURE:
      return state.set('tokenExpired', true).remove('requestingToken');

    case RESET_PASSWORD:
      return state
        .set('requestingSetPassword', true)
        .remove('setPasswordFailed')
        .remove('setPasswordSuccess');
    case RESET_PASSWORD_SUCCESS:
      return state
        .set('setPasswordSuccess', true)
        .remove('setPasswordFailed')
        .remove('requestingSetPassword');
    case RESET_PASSWORD_FAILED:
      return state
        .set('setPasswordFailed', true)
        .remove('setPasswordSuccess')
        .remove('requestingSetPassword');

    case RESET_TOKEN:
      return state
        .set('requestingEmail', true)
        .remove('emailRequestFailed')
        .remove('emailRequestSuccess');
    case RESET_TOKEN_SUCCESS:
      return state
        .set('emailRequestSuccess', true)
        .remove('emailRequestFailed')
        .remove('requestingEmail');
    case RESET_TOKEN_FAILED:
      return state
        .set('emailRequestFailed', true)
        .remove('emailRequestSuccess')
        .remove('requestingEmail');

    case SET_ERROR_MESSAGE:
      return state.set('requestingSetPassword', false).set('errorMessage', action.message);

    case SEND_RESET_EMAIL:
      return state.set('sentResetTokenEmail', true);
    case SEND_RESET_EMAIL_FAILED:
      return state.remove('sentResetTokenEmail');

    case SEND_UNLOCK_EMAIL:
      return state
        .set('attemptSendUnlockEmail', true)
        .set('requestingEmail', true)
        .remove('emailRequestFailed')
        .remove('emailRequestSuccess');
    case SEND_UNLOCK_EMAIL_FAILURE:
      return state.remove('requestingEmail').set('emailRequestFailed', true);
    case SEND_UNLOCK_EMAIL_SUCCESS:
      return state.remove('requestingEmail').set('emailRequestSuccess', true);
  }
  return state;
}

export const VALIDATE_UNLOCK_USER_TOKEN = 'users:VALIDATE_UNLOCK_USER_TOKEN';
export function validateUnlockUserToken(userId, token) {
  if (!userId || !token) throw 'cannot validate token info without userId and token';

  return {
    type: VALIDATE_UNLOCK_USER_TOKEN,
    userId,
    token
  };
}

export const VALIDATE_UNLOCK_USER_TOKEN_SUCCESS = 'users:VALIDATE_UNLOCK_USER_TOKEN_SUCCESS';
export const VALIDATE_UNLOCK_USER_TOKEN_FAILURE = 'users:VALIDATE_UNLOCK_USER_TOKEN_FAILURE';

export const VALIDATE_PASSWORD_RESET_TOKEN = 'users:VALIDATE_PASSWORD_RESET_TOKEN';
export function validatePasswordResetToken(userId, token) {
  if (!userId || !token) throw 'cannot validate token info without userId and token';

  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN,
    userId,
    token
  };
}

export const VALIDATE_PASSWORD_RESET_TOKEN_FAILURE = 'users:VALIDATE_PASSWORD_RESET_TOKEN_FAILURE';
export function resetValidationFailure() {
  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN_FAILURE
  };
}

export const VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS = 'users:VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS';
export function resetValidationSuccess() {
  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS
  };
}

export const RESET_PASSWORD = 'users:RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'users:RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'users:RESET_PASSWORD_FAILED';
export function resetPassword(userId, emailToken, password) {
  return {type: RESET_PASSWORD, userId, emailToken, password};
}

export function resetPasswordComplete(didSucceed) {
  return {type: didSucceed ? RESET_PASSWORD_SUCCESS : RESET_PASSWORD_FAILED};
}

export const SET_ERROR_MESSAGE = 'users:SET_ERROR_MESSAGE';
export function setErrorMessage(message) {
  return {type: SET_ERROR_MESSAGE, message};
}

export const RESET_TOKEN = 'users:RESET_TOKEN';
export function resetToken(userId) {
  if (!userId) {
    throw 'need a userId to proceed';
  }

  return {
    type: RESET_TOKEN,
    userId
  };
}

export const RESET_TOKEN_SUCCESS = 'users:RESET_TOKEN_SUCCESS';
export const RESET_TOKEN_FAILED = 'users:RESET_TOKEN_FAILED';
export function resetTokenComplete(success = true) {
  return {
    type: success ? RESET_TOKEN_SUCCESS : RESET_TOKEN_FAILED
  };
}

export const SEND_RESET_EMAIL = 'users:SEND_RESET_EMAIL';
export function sendResetEmail(email) {
  if (!email) {
    throw 'need an email to proceed';
  }

  return {
    type: SEND_RESET_EMAIL,
    email
  };
}

export const SEND_RESET_EMAIL_FAILED = 'users:SEND_RESET_EMAIL_FAILED';
export function passwordResetFailed() {
  return {
    type: SEND_RESET_EMAIL_FAILED
  };
}

export function triggerSentResetTokenEmailState() {
  return {
    type: SEND_RESET_EMAIL_FAILED
  };
}

export const SEND_UNLOCK_EMAIL = 'users:SEND_UNLOCK_EMAIL';
export function sendUnlockEmail({userId, email}) {
  return {
    type: SEND_UNLOCK_EMAIL,
    userId,
    email
  };
}

export const SEND_UNLOCK_EMAIL_SUCCESS = 'users:SEND_UNLOCK_EMAIL_SUCCESS';
export const SEND_UNLOCK_EMAIL_FAILURE = 'users:SEND_UNLOCK_EMAIL_FAILURE';
export function sendUnlockEmailComplete(success) {
  return {
    type: success ? SEND_UNLOCK_EMAIL_SUCCESS : SEND_UNLOCK_EMAIL_FAILURE
  };
}
