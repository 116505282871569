exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2pdrDYxyvrw3SpAM5Di5eR label{font-weight:normal;font-size:20px}._2pdrDYxyvrw3SpAM5Di5eR ._2xBUbZ0ZiIRAhu4C3NTXG{display:flex;flex-direction:row;align-items:center;margin-bottom:30px}._2pdrDYxyvrw3SpAM5Di5eR ._38OskDsfQxUDpkuOh7MWRs{width:50px;margin:10px}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9{flex:1;display:flex;flex-direction:row;background:transparent;border:0;font-size:50px;padding:5px 10px;margin:10px;color:#030303;border-bottom:2px solid #dadee2;outline:none;min-width:100px;transition:.2s ease-in-out}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9::-webkit-input-placeholder{color:#dadee2}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9::-moz-placeholder{color:#dadee2}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9::-ms-input-placeholder{color:#dadee2}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9::placeholder{color:#dadee2}._2pdrDYxyvrw3SpAM5Di5eR ._3bm20YWS6YkHpUKB6xTxT9:focus{border-bottom:2px solid #533be2}._2pdrDYxyvrw3SpAM5Di5eR ._3HSzMk1knx1W-_TE52Peaa{margin-top:50px}._2pdrDYxyvrw3SpAM5Di5eR._2rCvU-5G7MBtE_zhFReb76{margin-top:0px}._2pdrDYxyvrw3SpAM5Di5eR._2rCvU-5G7MBtE_zhFReb76 ._2xBUbZ0ZiIRAhu4C3NTXG{margin-bottom:0px}._2pdrDYxyvrw3SpAM5Di5eR._2rCvU-5G7MBtE_zhFReb76 ._38OskDsfQxUDpkuOh7MWRs{width:30px}._2pdrDYxyvrw3SpAM5Di5eR._2rCvU-5G7MBtE_zhFReb76 ._3bm20YWS6YkHpUKB6xTxT9{font-size:30px;color:#333}@media screen and (max-width: 425px){._38OskDsfQxUDpkuOh7MWRs{width:25px;margin:0}._3bm20YWS6YkHpUKB6xTxT9{font-size:25px}}", ""]);

// exports
exports.locals = {
	"searchInputContainer": "_2pdrDYxyvrw3SpAM5Di5eR",
	"searchInputWrapper": "_2xBUbZ0ZiIRAhu4C3NTXG",
	"searchInputIcon": "_38OskDsfQxUDpkuOh7MWRs",
	"searchInput": "_3bm20YWS6YkHpUKB6xTxT9",
	"searchResults": "_3HSzMk1knx1W-_TE52Peaa",
	"small": "_2rCvU-5G7MBtE_zhFReb76"
};