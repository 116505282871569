export default {
  /* 'device_id' : {
    width: 320,                       // the maximum device screen width (not including the device image)
    height: 480,                      // the maximum device screen height (not including the device image)
    minScale: 0.5,                    // the minimum possible scale (scale between 0 and 1, one being the largest and default)
    padding: [100, 25, 100, 25],      // the padding in pixels, similar to CSS (0 is top, 1 is right, 2 is down and 3 is left)
    img: '.source.jpg', // the location of the device image (relative to the ./images/ directory)
  }, */

  Mobile: {
    width: 375,
    height: 667,
    minScale: 0.2,
    maxScale: 1,
    padding: [106, 26, 106, 26],
    image: 'iphone6.png'
  },

  iPhone5Portrait: {
    width: 320,
    height: 568,
    minScale: 0.2,
    maxScale: 1,
    padding: [90, 21, 100, 22],
    image: 'iphone6.png'
  },

  iPhone5Landscape: {
    width: 568,
    height: 320,
    minScale: 0.2,
    maxScale: 1,
    padding: [22, 90, 40, 90],
    image: 'iphone6landscape.png'
  },

  tablet: {
    width: 1024,
    height: 768,
    minScale: 0.4,
    maxScale: 1,
    padding: [46, 88, 46, 88],
    image: 'ipad-pro.png'
  }
};
