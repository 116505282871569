import React from 'react';
import {connect} from 'react-redux';
import {viewAction, leaveAction} from 'modules/actions/actions';
import ActionPage from 'modules/actions/pages/actionPage';
import {closeQueryParamsSelector} from 'modules/actions/actionSelectors';
import {push} from 'modules/location';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import WithBreadcrumbHeader from 'modules/app/components/withBreadcrumbHeader';
import ReportRetestProgressStrip from 'modules/reports/components/reportRetestProgressStrip';

export class ActionsRoute extends React.Component {
  componentWillMount() {
    const {
      viewAction,
      match: {
        params: {reportId, actionId, testId}
      }
    } = this.props;
    viewAction(reportId, actionId, testId);
  }

  componentWillReceiveProps(nextProps) {
    const {
      viewAction,
      match: {
        params: {reportId, actionId, testId}
      }
    } = nextProps;

    if (
      this.props.match.params.reportId !== reportId ||
      this.props.match.params.actionId !== actionId
    ) {
      viewAction(reportId, actionId, testId);
    }
  }

  componentWillUnmount() {
    this.props.leaveAction();
  }

  render() {
    const {push, queryParams, location, match, report} = this.props;

    return (
      <WithReportSidebar match={match} location={location}>
        <WithBreadcrumbHeader
          push={push}
          location={location}
          match={match}
          reportId={report ? report.get('reportId') : null}
        >
          <ReportRetestProgressStrip />
          <ActionPage location={location} match={match} push={push} queryParams={queryParams} />
        </WithBreadcrumbHeader>
      </WithReportSidebar>
    );
  }
}

export default connect(
  closeQueryParamsSelector,
  {viewAction, push, leaveAction}
)(ActionsRoute);
