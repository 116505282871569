exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._341f2ZGjHNY9pU9E57EwpP{padding:10px;border-bottom:1px solid #e1e1e1;margin:0 -10px;cursor:pointer}._341f2ZGjHNY9pU9E57EwpP:last-of-type{border-bottom:0;margin-bottom:0}._341f2ZGjHNY9pU9E57EwpP:hover{background-color:#f7f7f7;cursor:pointer}._3u4KYnjDvqlJIRWXAs8iZ_{background:#efedfc;color:#533be2;padding:4px;border-radius:4px;transition:all .25s}._3u4KYnjDvqlJIRWXAs8iZ_:focus,._3u4KYnjDvqlJIRWXAs8iZ_:hover{outline:0;background:#c8c0f6}._3mEDhrMX00WwXO1RymtJxl{display:flex;align-items:center}._3VSya_NKMFV9hULt0ecE4z{opacity:.5}.KzXTMa0cVCEUTt9RI-DmI{padding:10px;width:50px}.xniW4h9WLnJBY2RHREFOP{flex:2;padding-right:30px;width:160px;max-width:160px;min-width:160px}._3GNibq5d-k8Hnvpj_-lb6I{margin:0 0 10px;font-size:1.45em}._3hhMeN-EDJV43Wa_oQbl2s{flex:1;flex-grow:1;padding-right:20px}._3hhMeN-EDJV43Wa_oQbl2s h2{margin:0}._3hhMeN-EDJV43Wa_oQbl2s h2 b{font-weight:normal}._3_58IXQY_blTwMujtlt0dT{text-align:right}._1kpwVb1joq-2-Xq3iinkkd{width:100px;margin-right:10px}", ""]);

// exports
exports.locals = {
	"checkpoint": "_341f2ZGjHNY9pU9E57EwpP",
	"link": "_3u4KYnjDvqlJIRWXAs8iZ_",
	"wrapper": "_3mEDhrMX00WwXO1RymtJxl",
	"ignored": "_3VSya_NKMFV9hULt0ecE4z",
	"iconWrapper": "KzXTMa0cVCEUTt9RI-DmI",
	"severityWrapper": "xniW4h9WLnJBY2RHREFOP",
	"title": "_3GNibq5d-k8Hnvpj_-lb6I",
	"titleWrapper": "_3hhMeN-EDJV43Wa_oQbl2s",
	"actionsWrapper": "_3_58IXQY_blTwMujtlt0dT",
	"explain": "_1kpwVb1joq-2-Xq3iinkkd"
};