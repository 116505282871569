import PageHeader from 'components/header/pageHeader';
import Helmet from 'components/helmet/helmet';
import Flex from 'components/layout/flex';
import MaxWidth from 'components/maxWidth';
import PageBody from 'components/page';
import Pagination from 'components/pagination';
import Panel from 'components/panel';
import Pill from 'components/pill';
import SearchBar from 'components/searchBar';
import Spinner from 'components/spinner';
import Status from 'components/status';
import Table from 'components/table';
import UserImage from 'components/user/userImage';
import {push} from 'modules/location';
import React from 'react';
import {connect} from 'react-redux';
import EditLeaderboardButton from '../containers/editLeaderboardButton';
import LeaderboardBreadcrumb from '../containers/leaderboardBreadcrumb';
import {leaderboardsPageSelector} from '../selectors';
import {leaderboardPath} from '../utils';
import styles from './leaderboardPage.scss';

const LEADERBOARD_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    type: 'jsx',
    jsx: ({data}) => <span className="fauxLink">{data}</span>
  },
  {
    label: 'Websites',
    field: 'numReports',
    type: 'count',
    appearance: 'major',
    width: '150px'
  },
  {
    label: 'Created by',
    field: 'createdBy',
    type: 'jsx',
    width: '200px',
    collapse: '600',
    jsx: ({data, record, options}) =>
      !data || data == 1 ? (
        <span style={{fontStyle: 'italic', lineHeight: '40px'}}>Silktide</span>
      ) : (
        <UserImage userId={data} size={40} inline>
          {user => <span>{user.name}</span>}
        </UserImage>
      )
  },
  {
    label: 'Date created',
    field: 'createdAt',
    type: 'time',
    relative: true,
    collapse: '800'
  }
];

function LeaderboardsPage({leaderboards, accountId, viewLeaderboard, isReady}) {
  const [pageNo, setPageNo] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const filteredLeaderboards = leaderboards.filter(leaderboard => {
    if (!searchValue) return true; // no filtering

    return leaderboard
      .get('name', '')
      .toLowerCase()
      .includes(searchValue);
  });

  const startIdx = (pageNo - 1) * 20;
  const maxPage = Math.ceil(filteredLeaderboards.size / 20);
  const pagedLeaderboards = filteredLeaderboards.slice(startIdx, startIdx + rowsPerPage);

  return (
    <React.Fragment>
      <Helmet title="Leaderboards" />
      <MaxWidth>
        <PageHeader
          title="Leaderboards"
          inline={[
            <EditLeaderboardButton key="add" icon="plus">
              Add leaderboard
            </EditLeaderboardButton>
          ]}
        />

        <PageBody>
          <Panel toolbar smallPadding>
            <Flex container>
              <Flex right={10}>
                <h3>
                  <Pill appearance="minor" showZero>
                    {filteredLeaderboards.size}
                  </Pill>{' '}
                  leaderboards
                </h3>
              </Flex>

              <Flex right grow={0} shrink={1} basis="auto">
                <SearchBar
                  value={searchValue}
                  placeholder="Search"
                  onChange={event => setSearchValue((event.target.value || '').toLowerCase())}
                />
              </Flex>
            </Flex>
          </Panel>

          <Panel padding={false} hideBorderTop>
            {!isReady ? (
              <div style={{padding: '50px'}}>
                <Spinner />
              </div>
            ) : !pagedLeaderboards.size ? (
              <Status appearance="info">
                {searchValue
                  ? `We cannot find a website that matches the search value "${searchValue}"`
                  : 'There are no leaderboards'}
              </Status>
            ) : (
              <Table
                rowKey="leaderboardId"
                columns={LEADERBOARD_COLUMNS}
                rows={pagedLeaderboards
                  .sort((a, b) => {
                    if (a.get('leaderboardId') == 1) return -1;
                    if (b.get('leaderboardId') == 1) return 1;
                    return a.get('name') < b.get('name') ? 1 : -1;
                  })
                  .toJS()}
                onClick={row => {
                  viewLeaderboard({accountId, leaderboardId: row.leaderboardId});
                }}
              />
            )}
          </Panel>
          {maxPage > 1 && (
            <Pagination
              marginTop
              page={pageNo}
              rowsPerPage={20}
              numRows={filteredLeaderboards.size}
              maxPage={Math.ceil(filteredLeaderboards.size / 20)}
              onPageSelect={pageNo => setPageNo(pageNo)}
            />
          )}
        </PageBody>
      </MaxWidth>
    </React.Fragment>
  );
}

export default connect(
  leaderboardsPageSelector,
  dispatch => ({
    viewLeaderboard: ({accountId, leaderboardId}) => {
      dispatch(push(leaderboardPath({accountId, leaderboardId})));
    }
  })
)(LeaderboardsPage);
