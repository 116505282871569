exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1vYaYMzPcQO8ze_dIBNeg6 h4{margin-top:0;margin-bottom:15px}._1vYaYMzPcQO8ze_dIBNeg6 h4 span{display:inline-block;margin-left:10px}._2jhmZFUg7itMQlGrYVM9Jr{margin-top:63px}._2BjEHIEB1eMzJ2DWgftbi8{margin-top:31px}._1SUBMFJLXepf7OGPmvIGx{margin-top:54px}", ""]);

// exports
exports.locals = {
	"instructionField": "_1vYaYMzPcQO8ze_dIBNeg6",
	"stageNo": "_2jhmZFUg7itMQlGrYVM9Jr",
	"stageField": "_2BjEHIEB1eMzJ2DWgftbi8",
	"empty": "_1SUBMFJLXepf7OGPmvIGx"
};