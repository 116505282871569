import React from 'react';
import styles from './sentenceIssue.scss';

class SentenceIssue extends React.Component {
  render() {
    const {
      point: {text, wordCount, readingAge},
      action: {actionId, decisions}
    } = this.props;

    return (
      <div>
        <blockquote className={styles.sentence}>{text}</blockquote>
        <p className={styles.caption}>{wordCount} words</p>
      </div>
    );
  }
}

export default SentenceIssue;
