import React from 'react';
import {Sidebar} from 'components/sidebar/listSidebar';
import {connect} from 'react-redux';
import TestsSidebar from '../../containers/testsSidebar';
import TestsSidebarNested from '../../containers/testsSidebarNested';
import Button from 'components/button';
import Center from 'components/center';
import {whichSidebarSelector} from 'modules/reports/reportSidebarSelectors';
import SlideBetween from 'components/animation/slideBetween';
import {redirectToReport} from 'modules/reports';
import {createSelector} from 'reselect';
import {reportPageCountSelector, reportIsTeaserSelector} from 'modules/reports/reportSelectors';
import {TeaserCount} from 'components/teaser';

const reportSidebarSelector = createSelector(
  whichSidebarSelector,
  reportIsTeaserSelector,
  reportPageCountSelector,
  (whichSidebar, isTeaser, testedPageCount) => ({
    ...whichSidebar,
    isTeaser,
    testedPageCount
  })
);

export class ReportSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      showCompleted: false // only used when props.completed is set.
    };
  }

  // // TODO: move to use new comparison utils once newInspector is merged in.
  // shouldComponentUpdate({selectedTestId}, {showCompleted}) {
  //   return showCompleted !== this.state.showCompleted
  // }

  toggleCompleted = () => {
    this.setState({showCompleted: !this.state.showCompleted});
  };

  renderToggleCompleted() {
    const {showToggleCompleted} = this.props;

    if (!showToggleCompleted) {
      return null;
    }

    const {showCompleted} = this.state;

    return (
      <Center>
        <Button plain light onClick={this.toggleCompleted}>
          {showCompleted ? 'Hide completed' : `Show completed`}
        </Button>
      </Center>
    );
  }

  renderButtons(report) {
    const reportType = report.get('reportType');

    return [
      this.renderToggleCompleted()
      // reportType == 'website' ? <ReportMissionSummary key="missions" /> : null
    ];
  }

  handleClickReportName = reportId => event => {
    this.props.redirectToReport(reportId);
  };

  shouldComponentUpdate(nextProps) {
    const thisLocation = this.props.location;
    const nextLocation = nextProps.location;

    return (
      this.props.report != nextProps.report ||
      this.props.navItems != nextProps.navItems ||
      thisLocation.pathname != nextLocation.pathname ||
      thisLocation.search != nextLocation.search ||
      this.props.match.url != nextProps.match.url
    );
  }

  render() {
    const {
      className,
      showToggleCompleted,
      report,
      location: {pathname},
      match: {params},
      isTeaser,
      testedPageCount
    } = this.props;

    if (!report.getIn(['presentationOptions', 'isNestedSidebar'])) {
      return (
        <Sidebar className={className} buttons={this.renderButtons(report)}>
          <h2
            onClick={this.handleClickReportName(report.get('reportId'))}
            title={report.get('name')}
          >
            {report.get('name')}
          </h2>
          <TestsSidebar
            hideCompleted={showToggleCompleted && !this.state.showCompleted}
            key="tests"
          />
        </Sidebar>
      );
    }

    return (
      <Sidebar className={className}>
        <h2 onClick={this.handleClickReportName(report.get('reportId'))} title={report.get('name')}>
          {report.get('name')}
        </h2>
        <SlideBetween currentIndex={this.props.parentId ? 1 : 0} noFit>
          <div>
            <TestsSidebarNested
              testId={params.testId}
              parentId={null}
              hideCompleted={showToggleCompleted && !this.state.showCompleted}
            />
          </div>
          <div>
            <TestsSidebarNested
              testId={params.testId}
              parentId={this.props.parentId}
              hideCompleted={showToggleCompleted && !this.state.showCompleted}
            />
          </div>
        </SlideBetween>
        {!!(isTeaser && testedPageCount) && (
          <TeaserCount>{testedPageCount} pages were tested. Contact us to test more.</TeaserCount>
        )}
      </Sidebar>
    );
  }
}

export default connect(
  reportSidebarSelector,
  {redirectToReport}
)(ReportSidebar);
