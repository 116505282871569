import React from 'react';
import {connect} from 'react-redux';
import {validFilters, toKeyValuePairs} from '../reportFilterUtils';
import {filtersSelector} from '../reportFilterSelectors';
import {createFilter, removeFilter, updateFilters} from '../reportFilters';

import styles from './filterBar.scss';
import {Form, Field} from 'modules/form/components';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import Icon from 'components/font-awesome';
import Flex from 'components/layout/flex';

const ADD_FILTER_BTN = (
  <Button icon="plus-circle" plain className={styles.addFilterBtn}>
    Add filter
  </Button>
);

export class FilterBar extends React.Component {
  state = {
    searchTerm: null
  };

  handleAddFilter = field => this.props.createFilter(field);

  handleRemoveFilter = value => () => {
    this.props.removeFilter(value);
  };

  handleFilterSearch = text => {
    this.setState({searchTerm: text});
  };

  handleFormChange = (name, value, data) => {
    this.props.updateFilters(data);
  };

  getFilterOptions = () => {
    const {searchTerm} = this.state;
    const {filters} = this.props;

    return validFilters
      .map(filter => ({
        value: filter.field,
        label: filter.name
      }))
      .filter(filter => {
        // If we've already applied a filter on this field don't include it as an option
        if (filters.find(f => filter.value === f.field)) {
          return false;
        }

        return (
          !searchTerm || (filter.label.includes(searchTerm) || filter.value.includes(searchTerm))
        );
      });
  };

  renderValueByType = ({type, value, field, options}) => {
    const {inlineFormName} = this.props;

    switch (type) {
      case 'boolean':
        // || false ensures we return false if value is undefined
        return '' + (value || false);
      case 'user':
        return <Field type="user" name={field} className={styles.field} />;
      case 'select':
        return <Field type="select" name={field} options={options} className={styles.field} />;
    }
    return value;
  };

  renderFilter = filter => {
    return (
      <Flex container className={styles.filterDisplay} key={`filter-${filter.field}`}>
        <Flex grow={0}>
          <Flex container column center className={styles.label}>
            {filter.name}
          </Flex>
        </Flex>
        <Flex grow={0}>
          <Flex container column center className={styles.valueWrapper}>
            {this.renderValueByType(filter)}
          </Flex>
        </Flex>
        {false && (
          <Icon
            name="times"
            className={styles.remove}
            onClick={this.handleRemoveFilter(filter.field)}
          />
        )}
      </Flex>
    );
  };

  render() {
    const {filters} = this.props;

    const filterOptions = this.getFilterOptions();
    const formValues = toKeyValuePairs(filters);

    return (
      <div className={styles.filterBar}>
        <Form defaultValue={formValues} onChange={this.handleFormChange}>
          <div className={styles.filterContainer}>{filters.map(this.renderFilter)}</div>
        </Form>
        {false && filterOptions && !!filterOptions.length && (
          <Dropdown
            className={styles.toggle}
            padding={10}
            button={ADD_FILTER_BTN}
            options={filterOptions}
            onChange={this.handleAddFilter}
            onFilter={this.handleFilterSearch}
          />
        )}
      </div>
    );
  }
}

export default connect(
  filtersSelector,
  {
    createFilter,
    removeFilter,
    updateFilters
  }
)(FilterBar);
