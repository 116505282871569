import React from 'react';
import styles from './field.scss';
import Fade from 'components/fade';
import Panel from 'components/panel';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Flex from 'components/layout/flex';
import cx from 'classnames';

export default function SavePanel({
  show,
  floating,
  isValid,
  isLoading,
  onCancel,
  onSubmit,
  floatMargin,
  noSidebar,
  submitLabel = 'Save'
}) {
  return (
    <Fade show={show}>
      <Floating active={floating} noSidebar={noSidebar} value={floatMargin}>
        <div className={styles.savePanel}>
          <Buttons centered noMargin>
            <Button
              wire
              disabled={!isValid}
              isLoading={isLoading}
              type={onSubmit ? 'button' : 'submit'}
              onClick={onSubmit}
            >
              {submitLabel}
            </Button>
            <Button type={onCancel ? 'button' : 'reset'} onClick={onCancel}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </Floating>
    </Fade>
  );
}

function Floating({active, children, noSidebar, value = '120px'}) {
  const elemRef = React.useRef(null);

  React.useEffect(() => {
    const {style} = elemRef.current.parentElement.parentElement;
    // TODO lazy, we should really add this margin to any existing margin
    style.marginBottom = value;

    return () => {
      // TODO and revert this properly
      style.marginBottom = '';
    };
  });

  return active ? (
    <div ref={elemRef} className={cx(styles.floating, {[styles.noSidebar]: noSidebar})}>
      {children}
    </div>
  ) : (
    children
  );
}
