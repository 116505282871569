import {fromJS} from 'immutable';
import {createSelector} from 'reselect';

export function createLoadingState(loaded = false) {
  return fromJS({
    error: null,
    loaded: false,
    loading: false,
    id: null // used to index the data so we know when to fetch more (can be set to any arbitrary value)
  });
}

export const startLoadingState = (state, key, id = null) => {
  return state
    .setIn([key, 'error'], null)
    .setIn([key, 'loading'], true)
    .setIn([key, 'loaded'], false)
    .setIn([key, 'id'], id);
};

export const endLoadingState = (state, key, error) => {
  return state
    .setIn([key, 'loaded'], true)
    .setIn([key, 'loading'], false)
    .setIn([key, 'error'], fromJS(error));
};

export function makeLoadingStateSelector(reducerSelector, loadingStateKey) {
  return createSelector(
    reducerSelector,
    reducerState => {
      return reducerState.get(loadingStateKey);
    }
  );
}

export function makeLoadingStatePartSelector(
  reducerSelector,
  loadingStateKey,
  loadingStatePartKey
) {
  return createSelector(
    makeLoadingStateSelector(reducerSelector, loadingStateKey),
    loadingState => loadingState.get(loadingStatePartKey)
  );
}

export function composeState(state, modifiers) {
  let tempState = state;
  for (let i = 0; i < modifiers.length; i++) {
    tempState = modifiers[i](tempState);
  }
  return tempState;
}

export const startLoading = key => state => startLoadingState(state, key);
export const endLoading = (key, error = null) => state => endLoadingState(state, key, error);
