import {takeEvery, takeLatest, take, select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';

import {FETCH_LEADERBOARDS} from '../constants';
import {receiveLeaderboards, receiveDefaultColumns, fetchLeaderboardsComplete} from '../actions';
import {leaderboardsEndpoint} from '../utils';

export default function* fetchLeaderboardsSaga() {
  yield takeLatest(FETCH_LEADERBOARDS, fetchLeaderboards);
}

function* fetchLeaderboards() {
  const accountId = yield select(accountIdSelector);

  yield processRequest('GET', leaderboardsEndpoint({accountId}), {
    *success(data) {
      yield put(fetchLeaderboardsComplete());
      yield put(receiveLeaderboards(data.leaderboards));
      yield put(receiveDefaultColumns(data.defaultColumns));
    },
    *error(err) {
      yield put(fetchLeaderboardsComplete(err));
    }
  });
}
