import {put, select, takeLatest} from 'redux-saga/effects';
import {showInHugeModal} from 'modules/modal';
import {SHOW_COMPONENT_DETAIL, UPDATE_COMPONENT_DETAIL} from 'modules/tests/testConstants';
import {testIdSelector, testSelector} from 'modules/tests/testSelectors';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import ComponentDetailsModal from 'modules/tests/modals/componentDetailsModal';
import {getResultsForComponent} from 'modules/tests/sagas/helpers/getResultsForResultIds';
import {receiveResults} from 'modules/results';
import {currentFilterSelector} from 'modules/filters/filterSelectors';

export default function* showComponentDetailSaga() {
  yield* [
    takeLatest(SHOW_COMPONENT_DETAIL, triggerShowComponentDetail),
    takeLatest(UPDATE_COMPONENT_DETAIL, updateComponentDetail)
  ];
}

function* triggerShowComponentDetail({componentConfig, limit, offset}) {
  const reportId = yield select(reportIdSelector);
  const testId = yield select(testIdSelector);
  const test = yield select(testSelector, {params: {testId}});

  if (!test || !componentConfig) return;

  // TODO We should probably have a whitelist of valid components
  //      because we don't want to display Span or Tab etc.
  const resultName = componentConfig.get('resultId') + '-modal';
  if (!componentConfig.get('resultId')) return;

  const filters = yield select(currentFilterSelector);

  const results = yield getResultsForComponent(resultName, filters);

  yield put(receiveResults(results, filters));

  // `componentConfig` is the component as it appears in the layout.
  // we need to modify a few things in order to display in a modal.
  const newComponentConfig = componentConfig.merge({
    display: 'detail', // stops "info" icon from displaying
    result: resultName, // the new result name containing the new data
    rowKey: 'rowHash'
  });

  yield put(
    showInHugeModal(ComponentDetailsModal, {
      params: {reportId, testId},
      componentConfig: newComponentConfig,
      limit,
      offset
    })
  );
}

function* updateComponentDetail({componentConfig, filters, limit, offset}) {
  const resultName = componentConfig.get('result');
  if (!resultName) return;

  const currentFilters = yield select(currentFilterSelector);

  filters = {...currentFilters, ...filters};

  const results = yield getResultsForComponent(resultName, filters);

  yield put(receiveResults(results, filters));
}
