import React from 'react';
import styles from './pages.scss';
import QuoteTopBar from '../quoteTopBar.js';

const SilktidePlatformOverview = ({quote}) => (
  <div className={styles.page}>
    <QuoteTopBar quote={quote} />
    <h1>Platform overview</h1>
    <p>
      Silktide is a comprehensive website governance platform. It automatically detects problems and
      opportunities with your websites, notifies your team of what they need to improve, and
      provides management reporting on your website's performance.
    </p>
    <div className={styles.twoColumn}>
      <div>
        <h2>Protect your brand</h2>
        <ul>
          <li>
            <b>Regulate your writing</b> Automated checks for spelling, grammar, readability, and
            reading age.
          </li>
          <li>
            <b>Become accessible</b> Ensure your website is accessible to alland comply with WCAG
            2.1.
          </li>
          <li>
            <b>Avoid damaging errors</b> Protect against broken links, missing files, and hundreds
            of other problems.
          </li>
          <li>
            <b>Comply with GDPR</b> Automate much of the hassle in making your website GDPR
            compliant.
          </li>
          <li>
            <b>Enforce brand guidelines</b> Define and enforce your own custom guidelines, from
            design to content.
          </li>
        </ul>
      </div>
      <div>
        <h2>Grow your presence</h2>
        <ul>
          <li>
            <b>Measure your presence</b> Track your search engine rankings by keyword, location, and
            device.
          </li>
          <li>
            <b>Optimize your SEO</b> Identify specific content and technical changes to increase
            your ranking.
          </li>
          <li>
            <b>Analyze competitors</b> Identify competing websites and advertisers, and profile
            their strengths.
          </li>
          <li>
            <b>Optimize for mobile</b> Test all your pages work effectively on mobile,
            automatically.
          </li>
          <li>
            <b>Maximize your User Experience</b> Automate hundreds of best practice checks for web
            UX.
          </li>
        </ul>
      </div>
    </div>

    <h2>Optimize your operations</h2>

    <div className={styles.twoColumn}>
      <div>
        <ul>
          <li>
            <b>Consolidate your reporting</b> Bring all of your website KPIs into a single
            dashboard.
          </li>
          <li>
            <b>Automate your quality assurance</b> Replace time consuming manual checks and get
            alerted when problems occur.
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <b>Motivate your team</b> Use leaderboards of your peers and competitors to incentivize
            change.
          </li>
          <li>
            <b>Stay up to date</b> Silktide constantly update our platform to cover new laws and
            best practices.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default SilktidePlatformOverview;
