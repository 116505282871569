exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3ddrIaRZT7MaLMGePJHEMB{display:flex;flex-direction:column;position:fixed;z-index:400;width:100%;top:0;left:0;background:#fff;height:100vh}.X2JsgnTsn96o6997hXX9b{display:flex;height:100%}", ""]);

// exports
exports.locals = {
	"container": "_3ddrIaRZT7MaLMGePJHEMB",
	"middle": "X2JsgnTsn96o6997hXX9b"
};