import {createSelector} from 'reselect';
import {userIdSelector} from 'modules/auth/authSelectors';
import achievementReducer from 'modules/achievements/achievements';
import {List} from 'immutable';

const moduleSelector = state => state.achievements;

export const userBadgesSelector = createSelector(
  moduleSelector,
  achievementsState => achievementsState.get('badges', List())
);

export const userAchievementsSelector = createSelector(
  moduleSelector,
  achievementsState => achievementsState.get('achievements', List())
);
export const isLoadingSelector = createSelector(
  moduleSelector,
  achievementsState => achievementsState.get('isLoading', List())
);

// selects the next achievement in the queue
export const currentAchievementSelector = createSelector(
  moduleSelector,
  userIdSelector,
  (achievementsModule, userId) => {
    const currentAchievementId = achievementsModule.get('currentAchievementId');

    return {
      userId,
      achievement: achievementsModule
        .get('achievementQueue')
        .find(ach => ach.get('achievementId') == currentAchievementId)
    };
  }
);

export const unreadAchievements = createSelector(
  moduleSelector,
  achievementsModule => {
    return achievementsModule.get('achievementQueue').toJS();
  }
);
