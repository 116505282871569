import React from 'react';
import Icon from 'components/font-awesome';
import styles from './libraryPolicy.scss';

class LibraryPolicy extends React.Component {
  render() {
    const {record, options} = this.props;    

    return (
        <div className={styles.libraryPolicy}>
            <div className={styles.icon}>
                <Icon name={record.icon} />
            </div>
            <h3>{record.name}</h3>
            <p className={styles.description}>
                {record.description}
            </p>
            <p>
                <a href="#">
                    Add this policy
                </a>
            </p>
        </div>
    )
  }
}

export default LibraryPolicy;
