import {takeLatest, select, put} from 'redux-saga/effects';
import {REQUEST_SERVICES, receiveServices, servicesError} from 'modules/services/servicesActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {servicesEndpoint} from 'modules/services/serviceUtils';

export default function* requestServicesSaga() {
  yield takeLatest([REQUEST_SERVICES], function*() {
    yield processRequest('GET', servicesEndpoint(), {
      successAction: receiveServices,
      error: function*(err) {
        const errorMsg = err || 'Failed to load services.';
        yield put(servicesError(errorMsg));
      }
    });
  });
}
