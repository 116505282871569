import React from 'react';
import {compose} from 'redux';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import FolderSidebar from 'modules/folders/components/folderSidebar';
import {viewFoldersPage, viewFoldersIndexPage} from 'modules/folders';
import {connect} from 'react-redux';
import FolderPage from 'modules/folders/pages/folderPage';
import {setLocationParamItem, push} from 'modules/location';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class FoldersRoute extends React.Component {
  componentWillMount() {
    const {viewFoldersPage, viewFoldersIndexPage, setLocationParamItem, match} = this.props;

    if (match.params && !match.params.folderId) {
      viewFoldersIndexPage();
    }

    if (match.params.folderId) {
      setLocationParamItem('folderId', match.params.folderId);
    }

    viewFoldersPage();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location && nextProps.match.params) {
      this.props.setLocationParamItem('folderId', nextProps.match.params.folderId);
    }
  }

  componentWillUnmount() {
    this.props.setLocationParamItem('folderId', undefined);
  }

  render() {
    const {location, history, push, match} = this.props;
    const sidebar = <FolderSidebar params={match.params} />;
    const {largeMobile} = this.props.screenSizes;

    return (
      <PageWithSidebar sidebar={sidebar}>
        <FolderPage history={history} location={location} match={match} />
      </PageWithSidebar>
    );
  }
}

export default compose(
  connect(
    null,
    {viewFoldersIndexPage, viewFoldersPage, setLocationParamItem, push}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(FoldersRoute);
