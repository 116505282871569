import React from 'react';
import Checkboxes, {CheckboxOption} from 'components/checkboxes';

export default class RadiosInput extends React.Component {
  render() {
    const {value, name, onChange, stacked, options = []} = this.props;

    return (
      <Checkboxes radio name={name} value={value} onChange={onChange} stacked={stacked}>
        {options.map(option => {
          return (
            <CheckboxOption key={option.label} value={option.value}>
              {option.label}
            </CheckboxOption>
          );
        })}
      </Checkboxes>
    );
  }
}
