exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3GQ2YuggN8BGh7nx1xUozq{width:100%;margin:10px;text-align:center}._2NJHb19cKBmAO6fgEH4h66{margin-bottom:0;line-height:2}p._3BS9LJbdbS1Nhom2xBH35U{opacity:.8;margin:15px 0 5px 0}p._3BS9LJbdbS1Nhom2xBH35U a{color:inherit;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"wrapper": "_3GQ2YuggN8BGh7nx1xUozq",
	"title": "_2NJHb19cKBmAO6fgEH4h66",
	"footer": "_3BS9LJbdbS1Nhom2xBH35U"
};