import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/font-awesome';
import styles from './paymentScopeInput.scss';

export default class PolicyScopeInput extends React.Component {
  static defaultProps = {
    value: 'stripe',
    allowStripe: true
  };

  static propTypes = {
    value: PropTypes.string
  };

  componentWillMount() {
    const {onChange} = this.props;
    onChange && onChange(this.props.value);
  }

  handleClick = scope => event => {
    const {onChange} = this.props;
    onChange && onChange(scope);
  };

  render() {
    const {value, allowInvoice, allowStripe} = this.props;

    return (
      <div className={styles.container}>
        <button
          type="button"
          disabled={!allowStripe}
          onClick={this.handleClick('stripe')}
          className={cx(styles.option, {[styles.active]: value === 'stripe'})}
        >
          <Icon name="fal fa-credit-card" className={styles.icon} />
          <div>Card</div>
        </button>
        <button
          type="button"
          disabled={!allowInvoice}
          onClick={this.handleClick('invoice')}
          className={cx(styles.option, {[styles.active]: value === 'invoice'})}
        >
          <Icon name="fal fa-file-invoice" className={styles.icon} />
          <div>Invoice</div>
        </button>
      </div>
    );
  }
}
