import {put, select, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';

import {REQUEST_PAYMENT_DETAILS} from 'modules/payments';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {paymentDetailsEndpoint} from '../paymentUtils';
import {requestPaymentDetails, receivePaymentDetails, paymentDetailsFailed} from '../payments';

export default function* paymentDetailsSaga() {
  yield takeEvery(REQUEST_PAYMENT_DETAILS, requestPaymentDetailsSaga);
}

function* requestPaymentDetailsSaga() {
  const accountId = yield select(accountIdSelector);

  if (!accountId) return;

  yield processRequest('GET', paymentDetailsEndpoint({accountId}), {
    successAction: receivePaymentDetails,
    errorAction: paymentDetailsFailed
  });
}
