import React from 'react';
import Table from 'components/table/table';
import Spinner from 'components/spinner';
import {connect} from 'react-redux';
import {serviceUsageTableSelector} from 'modules/services/serviceSelectors';
import {fetchUsageData} from 'modules/services/servicesActions';
import Status from 'components/status/status';
import {reportPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location';

export class ServiceUsageTable extends React.Component {
  state = {pageNumber: 1};

  componentDidMount() {
    const {serviceId} = this.props;
    this.props.fetchUsageData(serviceId, 'PageMonitorBalance', this.state.pageNumber);
  }

  componentWillReceiveProps(nextProps) {
    const {serviceId, dataFor} = nextProps;

    if (dataFor !== this.props.dataFor) {
      this.setState({pageNumber: 1});
      this.props.fetchUsageData(serviceId, dataFor, 1);
    }
  }

  getBreakdownCols = () => {
    if (this.props.dataFor === 'PageMonitorBalance') {
      return [
        {
          label: 'Website',
          field: 'reportName',
          type: 'jsx',
          jsx: props => {
            return (
              <a
                onClick={() =>
                  this.props.push(reportPath, {
                    reportId: props.record.reportId,
                    accountId: this.props.accountId
                  })
                }
              >
                {props.data}
              </a>
            );
          }
        },
        {
          label: 'Limit',
          field: 'reportSize',
          type: 'int'
        },
        {
          label: 'Used',
          field: 'used',
          type: 'int'
        }
      ];
    }

    return [
      {
        label: 'Website',
        field: 'reportName',
        type: 'jsx',
        jsx: props => {
          return (
            <a
              onClick={() =>
                this.props.push(reportPath, {
                  reportId: props.record.reportId,
                  accountId: this.props.accountId
                })
              }
            >
              {props.data}
            </a>
          );
        }
      },
      {
        label: 'Used',
        field: 'used',
        type: 'int'
      }
    ];
  };

  render() {
    const {usageData, usageDataLoading} = this.props;
    if (usageDataLoading) {
      return <Spinner centered />;
    }

    if (!usageData || !usageData.length) {
      return <Status appearance="warning">No usage has been recorded</Status>;
    }

    return <Table columns={this.getBreakdownCols()} rows={usageData} rowKey="reportId" />;
  }
}

export default connect(
  serviceUsageTableSelector,
  {fetchUsageData, push}
)(ServiceUsageTable);
