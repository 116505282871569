import {createSelector} from 'reselect';
import {userSelector} from 'modules/auth/authSelectors';
import {toJS, getProps} from 'utils/immutableUtils';
import {entitlementDataSelector} from 'modules/entitlements/entitlementSelectors';
import {coursesPageSelector} from 'modules/courses/selectors';

export const homePageStateSelector = state => state.homePage;

export const homePageSelector = createSelector(
  homePageStateSelector,
  userSelector,
  entitlementDataSelector,
  coursesPageSelector,
  (homePageState, user, entitlements, {courses}) => {
    const output = {
      user: toJS(user),
      isLoading: homePageState.get('isLoading'),
      courses: toJS(courses)
    };

    const data = homePageState.get('data');
    if (!data.size) return {...output};

    const {recentReports, recentUsers, updates} = getProps(data, [
      'recentReports',
      'recentUsers',
      'updates'
    ]);

    return {
      ...output,
      updates,
      recentUsers,
      recentReports
    };
  }
);
