exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HFb_A_moy7WC-7H9BH7GV{background:#f9f9f9;display:block;font-size:1.2em;margin:0;line-height:1em;padding:16px 20px;color:dimgray;border:1px solid #e1e1e1;border-bottom:0}.HFb_A_moy7WC-7H9BH7GV .iiTuxmHGEYi-et_IVlIdX{margin-bottom:2px}", ""]);

// exports
exports.locals = {
	"header": "HFb_A_moy7WC-7H9BH7GV",
	"badge": "iiTuxmHGEYi-et_IVlIdX"
};