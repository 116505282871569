export function requireWithFallback(requireContext, defaultFilename) {
  // cache the default image (we may need it a lot, no point in requiring it every time)
  const defaultFile = requireContext(defaultFilename);

  return function(localFilename) {
    try {
      if (!localFilename) {
        throw new Error('Inavlid filename');
      }
      return requireContext(localFilename);
    } catch (err) {
      return defaultFile;
    }
  };
}
