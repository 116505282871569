import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './radialProgress.scss';
import NumberTicker from 'components/animation/numberTicker';
import Tooltip from 'components/tooltip';
import Icon from 'components/font-awesome';
import SvgScore from './svgScore';

const SvgScoreProps = {
  borderSize: 5,
  background: '#cbcbcb'
};

// https://github.com/bameyrick/js-easing-functions/blob/master/src/index.ts
function easeOutQuad(elapsed, initialValue, amountOfChange, duration) {
  const newElapsed = elapsed / duration;
  return -amountOfChange * newElapsed * (newElapsed - 2) + initialValue;
}

export default class RadialProgress extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    animate: PropTypes.bool,
    showText: PropTypes.bool,
    duration: PropTypes.number,
    fillClass: PropTypes.string,
    isLoading: PropTypes.bool,
    tooltip: PropTypes.string,
    size: PropTypes.number
  };

  static defaultProps = {
    animate: false,
    duration: 600,
    showText: true,
    size: 70
  };

  state = {
    animatedValue: 0,
    startAngle: 0
  };

  animating = false;

  animatingLoader = false;

  startedAnimatingAt = null;

  componentDidMount() {
    this.setAnimating(this.props.animate);
    this.setLoading(this.props.isLoading);
  }

  componentWillUnmount() {
    if (this.animating) {
      this.setAnimating(false);
    }
    if (this.animatingLoader) {
      this.setLoading(false);
    }
  }

  componentWillReceiveProps({animate, isLoading}) {
    if (animate !== this.props.animate) {
      this.setAnimating(animate);
    }
    if (isLoading !== this.props.isLoading) {
      this.setLoading(isLoading);
    }
  }

  setAnimating(on) {
    this.animating = on;

    if (on) {
      this.startedAnimatingAt = Date.now();
      this.animatingFrame = window.requestAnimationFrame(this.handleAnimationFrame);
    } else {
      window.cancelAnimationFrame(this.animatingFrame);
    }
  }

  setLoading(on) {
    this.animatingLoader = on;

    if (on) {
      this.loadingFrame = window.requestAnimationFrame(this.handleLoadingFrame);
    } else {
      window.cancelAnimationFrame(this.loadingFrame);
    }
  }

  handleLoadingFrame = () => {
    const {startAngle} = this.state;

    this.setState({startAngle: startAngle < 360 ? startAngle + 3 : 0});
    this.setLoading(true);
  };

  handleAnimationFrame = () => {
    const {value, duration, animate} = this.props;
    const elapsed = Date.now() - this.startedAnimatingAt;

    if (animate && elapsed < duration) {
      this.setState({animatedValue: easeOutQuad(elapsed, 0, value, duration)});

      // set cant use `setAnimating` because that restarts the `startedAnimatingAt` time
      this.animatingFrame = window.requestAnimationFrame(this.handleAnimationFrame);
    } else {
      // now that animatedValue === value, we set `animating` to false so the
      // render method uses the actual value rather than the animated value
      this.setAnimating(false);

      // set state to 0 so that the animation works again if we re-enable the `animate` prop
      this.setState({animatedValue: 0});
    }
  };

  renderScore() {
    const score = this.animating ? this.state.animatedValue : this.props.value;

    if (score == null || score < 0) {
      return null;
    }

    // percentages of 0 and 100 will yield an angle of 0 (which will render nothing)
    const progress = Math.min(Math.max(score, 1), 99.99);
    const endAngle = 360 * (progress / 100); // convert progress percentage to degrees
    const text = this.props.showText ? Math.floor(score).toString() : '';

    return (
      <SvgScore
        {...SvgScoreProps}
        text={text}
        fillClass={this.props.fillClass}
        startAngle={0}
        endAngle={endAngle}
        size={this.props.size}
        borderSize={this.props.borderSize || SvgScoreProps.borderSize}
      />
    );
  }

  render() {
    const {tooltip, isLoading, fillClass, size, value} = this.props;

    if (isLoading) {
      const {startAngle} = this.state;

      return (
        <SvgScore
          {...SvgScoreProps}
          colour="blue"
          fillClass={fillClass}
          startAngle={startAngle}
          endAngle={startAngle + 180}
          size={size}
          borderSize={this.props.borderSize || SvgScoreProps.borderSize}
        />
      );
    }

    if (tooltip) {
      return <Tooltip text={tooltip}>{this.renderScore()}</Tooltip>;
    }

    return this.renderScore();
  }
}
