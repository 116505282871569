import React from 'react';
import {goHome} from 'modules/app';
import {connect} from 'react-redux';
import Helmet from 'components/helmet';
import Status from 'components/status';
import Buttons from 'components/buttons';
import Button from 'components/button';
import ContactUsButton from 'components/button/contactUsButton';

export class NotFoundPage extends React.Component {
  render() {
    const {className, goHome} = this.props;

    return (
      <div className={className} style={{height: '100%'}}>
        <Helmet title="Page not found" />

        <Status appearance="bad">
          Sorry, we weren't able to find anything at this address.
          <Buttons style={{marginTop: 20}}>
            <Button onClick={goHome}>View my websites</Button>
            <ContactUsButton />
          </Buttons>
        </Status>
      </div>
    );
  }
}

export default connect(
  null,
  {goHome}
)(NotFoundPage);
