import React from 'react';
import styles from './card.scss';
import cx from 'classnames';

export default class Card extends React.Component {
  render() {
    const {title, subtitle, headingRight, children, className} = this.props;

    return (
      <div className={cx(styles.wrapper, className)}>
        <div className={styles.headingBar}>
          <div className={styles.headingLeft}>
            <div className={cx(styles.title, {[styles.titleNoTopMargin]: subtitle})}>{title} </div>
            {subtitle && <div className={styles.subtitle}>{subtitle} </div>}
          </div>
          <div className={styles.headingRight}>{headingRight}</div>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    );
  }
}
