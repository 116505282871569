import {delay} from 'redux-saga';
import {put, call, select, takeLatest} from 'redux-saga/effects';
import {receiveMission} from 'modules/missions';
import {processRequest} from 'utils/saga/fetchUtils';
import {RETEST_BEGAN} from 'modules/inspector/inspectorConstants';
import {retestComplete} from 'modules/inspector/inspectorActions';
import {missionEndpoint, missionIsFinished} from 'modules/missions/missionUtils';
import {retestMissionIdSelector} from 'modules/inspector/inspectorSelectors';

export default function* pollForRetestMissionSaga() {
  yield takeLatest(RETEST_BEGAN, pollForRetestMission);
}

function* pollForRetestMission() {
  while (true) {
    const retestMissionId = yield select(retestMissionIdSelector);

    // if we get a pusher mission saying the the mission is complete, we will unset the retestMissionId
    // in the reducer. If no mission ID exists after we've started the poll, assume it was pusher that ended it.
    // Therefor we end the retest here.
    if (!retestMissionId) {
      yield put(retestComplete());
      break;
    }

    try {
      // request updated mission
      const mission = yield getMission(retestMissionId);
      yield put(receiveMission(mission));

      if (missionIsFinished(mission)) {
        yield put(retestComplete());
        break;
      }
    } catch (err) {
      // failed to fetch mission, likely a 404 meaning the mission no longer exists
      // the backend doesn't always send a final mission with status `complete` so we have to assume
      // that if we can't get it, it doesn't exist, thus it is complete

      yield put(retestComplete());
      break;
    }

    // set delay to start again in 10 seconds
    yield delay(10000);
  }
}

function* getMission(missionId) {
  const url = missionEndpoint({missionId});

  return yield processRequest('GET', url);
}
