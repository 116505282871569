import {takeEvery} from 'redux-saga/effects';
import {CHANGE_ACTION_STATE, receiveAction} from 'modules/actions/actions';
import {changeActionStateEndpoint} from 'modules/actions/actionUtils';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* changeActionStateSaga() {
  yield takeEvery(CHANGE_ACTION_STATE, changeActionState);
}

function* changeActionState({state, comment, reportId, actionId}) {
  yield processRequest(
    'POST',
    changeActionStateEndpoint({reportId, actionId}),
    {
      // TODO: do something with decision
      successAction: ({action, decision}) => receiveAction(action)
    },
    {comment, state}
  );
}
