import {Map, List, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';
import {
  FETCH_SUPER_PRODUCTS,
  RECEIVE_SUPER_PRODUCTS,
  HANDLE_SUPER_PRODUCTS_LOAD_FAILURE,
  REQUEST_QUOTES,
  RECEIVE_QUOTES,
  HANDLE_QUOTE_ERROR,
  REQUEST_BUY_PLAN,
  HANDLE_BUY_PLAN_RESPONSE
} from './superProductActions';

const initialState = Map({
  data: List(),
  isLoading: false,
  loadingError: null,
  quotes: Map({
    data: Map(),
    isLoading: false,
    quoteError: null
  }),
  buyRequest: Map({
    isProcessing: false
  })
});

export default function superProductReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPER_PRODUCTS:
      return setLoadingState(state, true, null).set('data', List());
    case RECEIVE_SUPER_PRODUCTS:
      return setLoadingState(state, false, null).set('data', fromJS(action.products));
    case HANDLE_SUPER_PRODUCTS_LOAD_FAILURE:
      return setLoadingState(state, false, action.error).set('data', List());
    case REQUEST_QUOTES:
      return setQuotesLoadingState(state, true, null);
    case RECEIVE_QUOTES:
      return setQuotesLoadingState(state, false, null).setIn(
        ['quotes', 'data'],
        fromJS(action.quotes)
      );
    case HANDLE_QUOTE_ERROR:
      return setQuotesLoadingState(state, false, action.error).setIn(['quotes', 'data'], Map());
    case REQUEST_BUY_PLAN:
      return setBuyRequestLoadingState(state, true);
    case HANDLE_BUY_PLAN_RESPONSE:
      return setBuyRequestLoadingState(state, false);
    case SWITCH_ACCOUNT:
      return initialState;
  }

  return state;
}

function setLoadingState(state, loading, error) {
  return state.set('isLoading', loading).set('loadingError', error);
}

function setQuotesLoadingState(state, loading, error) {
  return state.setIn(['quotes', 'isLoading'], loading).setIn(['quotes', 'quoteError'], error);
}

function setBuyRequestLoadingState(state, loading) {
  return state.setIn(['buyRequest', 'isProcessing'], loading);
}
