import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/spinner';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import {saveInteraction, viewInteractions} from '../actions';
import {editInteractionPageSelector} from 'modules/interactions/selectors';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import InteractionForm from '../components/interactionForm';

class EditInteractionPage extends React.Component {
  handleSubmit = interaction => {
    this.props.saveInteraction(interaction);
  };

  handleCancel = () => {
    this.props.viewInteractions();
  };

  render() {
    const {match, location, interaction, loadingState} = this.props;

    if (!loadingState.get('loaded')) {
      if (!loadingState.get('loading')) {
        return null;
      }

      return <Spinner marginTop={100} />;
    }

    // we have loaded
    if (loadingState.get('loading')) {
      // we are reloading. IE we already have data but are loading again
      return <Spinner marginTop={100} />;
    }

    if (loadingState.get('error')) {
      // TODO show nice error
      return null;
    }

    return (
      <WithReportSidebar match={match} location={location}>
        <div>
          <Helmet title="Edit interaction" />
          <PageHeader
            title="Edit interaction"
            goBack={() => {
              this.props.viewInteractions();
            }}
          />

          <PageBody noPaddingBottom>
            <InteractionForm
              interaction={interaction}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
            />
          </PageBody>
        </div>
      </WithReportSidebar>
    );
  }
}

export default connect(
  editInteractionPageSelector,
  {viewInteractions, saveInteraction}
)(EditInteractionPage);
