import React from 'react';
import Icon from 'components/font-awesome';
import styles from './icons.scss';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export class EditIcon extends React.Component {
  render() {
    return <Icon name="pencil" {...filterInvalidDOMProps(this.props)} />;
  }
}

export default localStyles(styles)(EditIcon);
