import {takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {receiveNotifications} from 'modules/notifications';
import {notificationsEndpoint} from 'modules/notifications/notificationUtils';
import {AUTH_CHANGE, LOGOUT} from 'modules/auth';

export default function* fetchNotificationsSaga() {
  yield takeLatest(AUTH_CHANGE, fetchNotifications);
}

function* fetchNotifications() {
  yield processRequest('GET', notificationsEndpoint(), {
    successAction: receiveNotifications
  });
}
