import React from 'react';
import {connect} from 'react-redux';
import ImageUpload from 'components/upload/imageUpload';
import {
  uploadBrandingLogo,
  fetchAccountOptions,
  deleteAccountBrandingLogo
} from 'modules/accountOptions';
import {accountBrandingLogoUploaderSelector} from 'modules/accountOptions/accountOptionSelectors';
import Icon from 'components/font-awesome';
import styles from './accountBrandingLogoUploader.scss';

export class AccountBrandingLogoUploader extends React.Component {
  componentDidMount() {
    const {fetchAccountOptions} = this.props;

    // TODO: find better place to trigger this fetch (needs to re-fetch on SWITCH_ACCOUNT)
    fetchAccountOptions();
  }

  handleDeleteImage = () => {
    const {deleteAccountBrandingLogo} = this.props;

    deleteAccountBrandingLogo();
  };

  render() {
    const {loading, brandingLogoUrl, uploadBrandingLogo} = this.props;

    return (
      <div className={styles.accountBrandingLogo}>
        <ImageUpload
          loading={loading}
          preview={brandingLogoUrl}
          uploadImage={uploadBrandingLogo}
          style={{
            height: '100px',
            width: '100%',
            minWidth: '120px',
            maxWidth: '228px'
          }}
        />
        {brandingLogoUrl && (
          <Icon name="times" className={styles.deleteIcon} onClick={this.handleDeleteImage} />
        )}
      </div>
    );
  }
}

export default connect(
  accountBrandingLogoUploaderSelector,
  {
    uploadBrandingLogo,
    fetchAccountOptions,
    deleteAccountBrandingLogo
  }
)(AccountBrandingLogoUploader);
