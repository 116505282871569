export const ROLE_MANAGER = 'manager';
export const ROLE_CONTRIBUTOR = 'contributor';
export const ROLE_VIEWER = 'viewer';
const ROLE_BALROG = 'balrog';

const roles = {
  [ROLE_MANAGER]: 3,
  [ROLE_CONTRIBUTOR]: 2,
  [ROLE_VIEWER]: 1,
  [ROLE_BALROG]: 0
};

export function reportIsAllowed(neededRole, actualRole) {
  const neededVal = roles[neededRole];
  const actualVal = roles[actualRole];

  if (!neededVal) {
    if (__DEV__) {
      console.error('ReportGandalf shallPass isnt a supported role ', neededRole);
    }
    return false;
  }
  return actualVal && actualVal >= neededVal;
}
