import {takeLatest, call, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {searchCampaignsEndpoint, searchCampaignEndpoint} from '../utils';
import {ADD_THEME, UPDATE_THEME} from '../constants';
import {receiveTheme, showSearchCampaigns, setIsUpdating} from '../actions';
import {showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

export default function* saveSearchCampaignSaga() {
  yield takeLatest([ADD_THEME, UPDATE_THEME], saveSearchCampaign);
}

function* saveSearchCampaign({type, reportId, campaign, onSuccess}) {
  const params = {reportId, campaignId: campaign.campaignId};
  const isAdding = type === ADD_THEME;
  const method = isAdding ? 'POST' : 'PUT';
  const url = isAdding ? searchCampaignsEndpoint(params) : searchCampaignEndpoint(params);

  yield put(setIsUpdating(isAdding ? 'new' : campaign.campaignId));

  try {
    const data = yield processRequest(method, url, {}, campaign);

    if (onSuccess) {
      yield call(onSuccess);
    }
    yield put(setIsUpdating(null));
    yield put(receiveTheme(reportId, data));
    yield put(showSearchCampaigns(reportId));
  } catch (err) {
    console.error(err);
    yield put(setIsUpdating(null));
    yield showError(couldNot('save search campaign'));
  }
}
