import React from 'react';

const pageStyle = {
  background: 'white',
  height: 'calc(100vh - 55px)',
  width: '100%'
};

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  paddingTop: '50px',
  width: '80%'
};

export default function SearchPageWrapper({children}) {
  return (
    <div style={pageStyle}>
      <div style={wrapperStyle}>{children}</div>
    </div>
  );
}
