import {put, takeLatest} from 'redux-saga/effects';
import {generateFetchSaga} from 'utils/saga/fetchUtils';
import {
  VALIDATE_PASSWORD_RESET_TOKEN,
  resetValidationFailure,
  resetValidationSuccess
} from 'modules/passwords';
import {validateResetTokenEndpoint} from 'modules/passwords/passwordUtils';
import {receiveUser} from 'modules/users';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* validatePasswordResetTokenSaga() {
  yield takeLatest(VALIDATE_PASSWORD_RESET_TOKEN, validatePasswordResetToken);
}

function* validatePasswordResetToken({userId, token}) {
  yield processRequest(
    'POST',
    validateResetTokenEndpoint({userId}),
    {
      success: function*(user) {
        yield put(receiveUser(user));
        yield put(resetValidationSuccess());
      },
      errorAction: resetValidationFailure
    },
    {emailToken: token}
  );
}
