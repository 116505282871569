export function betterPromise() {
  let res, rej;
  const prom = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });
  return {
    promise: prom,
    resolve: res,
    reject: rej
  };
}

export function betterPromises(len) {
  if (Number.isInteger(len)) {
    return Array(len)
      .fill(null)
      .map(() => betterPromise());
  }

  if (Array.isArray(len)) {
    return len.reduce((result, key) => {
      result[key] = betterPromise();
      return result;
    }, {});
  }

  throw new Error('invalid parameter passed to betterPromises');
}
