import React from 'react';
import styles from './colorPreview.scss';

export default function ColorPreview({color, label}) {
  return (
    <span>
      <span className={styles.colorPreview} style={{backgroundColor: color}} />
      {label}
    </span>
  );
}
