import Flex from 'components/layout/flex';
import Markdown from 'components/layoutComponents/renderers/markdown';
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Status from 'components/status';
import Table from 'components/table';
import RelativeDate from 'components/time/relativeDate';
import UserImage from 'components/user/userImage';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {plainUsersSelector} from '../../users/userSelectors';
import styles from './auditLogTable.scss';

export function AuditLogTable({filters, auditLogs, pagination, isLoading, onSetFilter, users}) {
  const cols = [
    {
      label: 'Action',
      field: 'action',
      width: '250px',
      type: 'action',
      collapse: '600'
    },
    {
      label: 'Description',
      field: 'description',
      type: 'jsx',
      jsx: ({data}) => (
        <div className={styles.markdown}>
          <Markdown data={data} />
        </div>
      )
    },
    {
      label: 'User',
      type: 'jsx',
      data: 'user',
      field: 'userId',
      width: '250px',
      collapse: '600',
      jsx: ({data}) => {
        const user = users.find(user => user.userId === data);
        return (
          <div className={styles.user}>
            <UserImage userId={data} size={25} />
            <p>{user ? user.name || user.email : 'Removed user'}</p>
          </div>
        );
      }
    },
    {
      label: 'Date',
      width: '150px',
      field: 'time',
      type: 'jsx',
      jsx: ({data}) => <RelativeDate timestamp={data} />
    }
  ];

  const noResults = Array.isArray(auditLogs) && !auditLogs.length;
  return (
    <Fragment>
      {isLoading ? (
        <Spinner marginTop={100} marginBottom={100} />
      ) : noResults ? (
        <Status appearance="info">No audit logs have been recorded yet</Status>
      ) : (
        <Table rowKey="accountAuditLogId" rows={auditLogs} columns={cols} />
      )}
      <Pagination
        inline
        marginTop
        marginBottom
        {...pagination}
        onPageSelect={page => {
          onSetFilter('page', page);
        }}
      />
    </Fragment>
  );
}

export default connect(plainUsersSelector)(AuditLogTable);
