import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/spinner';
import SettingsPage from 'components/settings/settingsPage';
import {fetchAccountDecisionsPage, fetchAccountDecisions} from 'modules/decisions/decisions';
import {accountDecisionsSettingsPageSelector} from 'modules/decisions/decisionSelectors';
import Panel from 'components/panel';
import {StatPanel} from 'components/stat';
import Row from 'components/layoutComponents/row';
import Column from 'components/layoutComponents/column';
import Margin from 'components/margin/margin';
import DecisionTable from 'modules/decisions/components/decisionsTable';
import UserDecisionTable from 'modules/settings/components/userDecisionTable';
import Switch from 'components/switch';
import SwitchOption from 'components/switch/switchOption';
import {toDecimal} from 'utils/numberUtils';
import {APPEARANCES} from 'constants/appearance';

export class AccountSettingsPage extends React.Component {
  static defaultProps = {
    title: 'Account decisions'
  };

  filters = {};

  state = {
    tab: 'all-decisions'
  };

  componentWillMount() {
    this.props.fetchAccountDecisionsPage();
  }

  filterByUser = userId => {
    this.filters.userId = userId;
    this.filters.reportId = null;
    this.setState({tab: 'all-decisions'});
  };

  handleSetFilter = (filterName, filterValue) => {
    this.filters[filterName] = filterValue;

    this.props.fetchAccountDecisions(this.filters);
  };

  renderStats() {
    if (!this.props.accountDecisionStats) return null;

    const {
      numberOfReports = 0,
      totalDecisions = 0,
      averageDecisionsPerReport = 0
    } = this.props.accountDecisionStats;

    const hasNoAverageReports = numberOfReports <= 1;
    const width = hasNoAverageReports ? '6' : '4';

    return (
      <Row>
        <Column config={{width}}>
          <StatPanel
            label={numberOfReports == 1 ? 'Website' : 'Websites'}
            stacked
            short
            appearance={APPEARANCES.bad}
          >
            {numberOfReports == null ? null : Math.round(numberOfReports).toString()}
          </StatPanel>
        </Column>
        <Column config={{width}}>
          <StatPanel
            label={totalDecisions == 1 ? 'Decision' : 'Decisions'}
            stacked
            short
            appearance={APPEARANCES.major}
          >
            {totalDecisions == null ? null : Math.round(totalDecisions).toString()}
          </StatPanel>
        </Column>
        {!hasNoAverageReports && (
          <Column config={{width}}>
            <StatPanel label="Average per website" stacked short>
              {averageDecisionsPerReport == null
                ? null
                : toDecimal(averageDecisionsPerReport, 2).toString()}
            </StatPanel>
          </Column>
        )}
      </Row>
    );
  }

  renderUserDecisions() {
    const {usernameMap} = this.props;

    if (!this.props.accountDecisionStats) return null;

    return (
      <Fragment>
        <UserDecisionTable
          usernameMap={usernameMap}
          filterByUser={this.filterByUser}
          userDecisionsSummary={this.props.accountDecisionStats}
        />
      </Fragment>
    );
  }

  renderDecisions() {
    const {isDecisionsLoading, accountDecisions, usernameMap} = this.props;

    if (!accountDecisions) return null;

    const {rows, ...pagination} = accountDecisions;

    const decisionRows = rows.map(row => {
      return {...row, userName: usernameMap.get(row.userId.toString(), 'Unknown')};
    });

    return (
      <Fragment>
        <DecisionTable
          filters={this.filters}
          isLoading={isDecisionsLoading}
          decisions={decisionRows}
          pagination={pagination}
          onSetFilter={this.handleSetFilter}
        />
      </Fragment>
    );
  }

  renderContent() {
    return (
      <Fragment>
        {this.renderStats()}

        <Margin margin="16px">
          <Switch tabbed large>
            <SwitchOption
              active={this.state.tab === 'all-decisions'}
              onClick={() => this.setState({tab: 'all-decisions'})}
            >
              All decisions
            </SwitchOption>
            <SwitchOption
              active={this.state.tab === 'user-decisions'}
              onClick={() => this.setState({tab: 'user-decisions'})}
            >
              User breakdown
            </SwitchOption>
          </Switch>

          <Panel padding={false}>
            {this.state.tab === 'all-decisions'
              ? this.renderDecisions()
              : this.state.tab === 'user-decisions'
              ? this.renderUserDecisions()
              : null}
          </Panel>
        </Margin>
      </Fragment>
    );
  }

  render() {
    const {title, isLoading} = this.props;

    return (
      <SettingsPage name={<Margin margin="0 16px">{title}</Margin>}>
        {isLoading ? <Spinner marginTop={100} /> : this.renderContent()}
      </SettingsPage>
    );
  }
}

export default connect(
  accountDecisionsSettingsPageSelector,
  {fetchAccountDecisionsPage, fetchAccountDecisions}
)(AccountSettingsPage);
