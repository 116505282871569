import React from 'react';
import Pill from 'components/pill';
import Grid from 'components/grid';
import Overlay from 'components/overlay';
import Spinner from 'components/spinner';
import ImagePoller from 'components/image/imagePoller';
import styles from './context.scss';

export default class ContextPages extends React.Component {
  handleClickPage = pageHash => event => {
    const {onClickPage, onClose} = this.props;

    onClickPage(pageHash);
    onClose();
  };

  renderPage = ({pageHash, pageName, screenshotUrl, numPoints}) => {
    return (
      <div key={pageHash} className={styles.page} onClick={this.handleClickPage(pageHash)}>
        <ImagePoller src={screenshotUrl} className={styles.image}>
          <div className={styles.imageLoader}>
            <Spinner centered className={styles.spinner} />
          </div>
        </ImagePoller>

        <div className={styles.caption}>
          <span className={styles.label}>{pageName}</span>
          <Pill appearance="bad" numeric showZero>
            {numPoints}
          </Pill>
        </div>
      </div>
    );
  };

  render() {
    const {pages, onClose} = this.props;

    return (
      <Overlay fade center showClose showCloseScrollLimit={-1} onClickClose={onClose}>
        <Grid>{pages.map(this.renderPage)}</Grid>
      </Overlay>
    );
  }
}
