import React from 'react';
import PropTypes from 'prop-types';
import MultiChoiceAnswers from './multiChoiceAnswers';
import SingleChoiceAnswers from './singleChoiceAnswers';
import styles from './quiz.scss';

const propTypes = {
  question: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['choice', 'multiChoice']),
  onAnswer: PropTypes.func,
  answered: PropTypes.bool
};

const QuizQuestion = ({question, options, questionTypeId, onChange, answered, index, answer}) => (
  <div className={styles.questionWrapper}>
    <h4>{question}</h4>
    <p className={styles.explanation}>
      {questionTypeId === 'multiChoice' ? 'Select one or more answers:' : 'Select one answer:'}
    </p>
    <form
      name={question}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ul className={styles.questionList}>
        {questionTypeId === 'multiChoice' ? (
          <MultiChoiceAnswers
            options={options}
            onChange={answers => {
              onChange(index, answers);
            }}
            answered={answered}
            answer={answer}
          />
        ) : (
          <SingleChoiceAnswers
            options={options}
            onChange={answer => {
              onChange(index, answer);
            }}
            answered={answered}
            answer={answer}
          />
        )}
      </ul>
    </form>
  </div>
);

QuizQuestion.propTypes = propTypes;

export default QuizQuestion;
