import {addFilterQueryString} from 'modules/filters/filterUtils';
import {reportPath, reportEndpoint} from 'modules/reports/reportUtils';
import evaluate from 'token-js-parser';

export function testPath({testId, reportId, filter, tab}) {
  let url = reportPath(...arguments) + `/${testId}`;

  if (tab) {
    url += `/tabs/${tab}`;
  }

  if (filter) {
    return addFilterQueryString(url, filter);
  }

  return url;
}

export function testsEndpoint({reportId}) {
  return reportEndpoint(...arguments) + '/tests';
}
export function testEndpoint({testId}) {
  return testsEndpoint(...arguments) + `/${testId}`;
}

export function testLayoutEndpoint() {
  return testEndpoint(...arguments) + '/layout';
}

export function testResultsEndpoint({filters = {}}) {
  const endpoint = testEndpoint(...arguments) + '/results';
  return addFilterQueryString(endpoint, filters);
}

export function reportResultsEndpoint({reportId}) {
  return reportEndpoint({reportId}) + '/results';
}

export function multiTestEndpoint() {
  return reportEndpoint(...arguments) + '/test-results';
}

export const isTest = test => test.get('isTest');

// To avoid confusion, we call this willHaveLayout as the layout
// may not have been downloaded yet.
export const willHaveLayout = test => test.get('hasLayout');

export function getQueryTestIds(query) {
  if (query.testIds) {
    return query.testIds.split(',');
  }
  return null;
}

function isLayoutVisible(layout, params, filters) {
  if (layout.show && !evaluate(layout.show, {filters})) {
    return false;
  }

  switch (layout.type) {
    case 'Tab':
      return params.tab === layout.label;

    case 'Portal':
      // TODO select active flags and is layout.id active
      //      leaving this as true for now is okay, it just means that all portals will be seen as visible elements
      return true; // activeFlag === layout.id
  }
  return true;
}

export function getVisibleResultIds(layouts, params = {}, filters = {}) {
  const resultIdsSet = _getVisibleResultIds(layouts, params, filters);
  return [...resultIdsSet].sort();
}

// for the given layout, return all resultIds for all visible components
function _getVisibleResultIds(layouts, params, filters) {
  const resultIds = new Set();

  layouts.forEach(layout => {
    // ignore if invisilbe
    if (!isLayoutVisible(layout, params, filters)) return;

    // we are visible and have a result id, add to resultIds.
    if (layout.resultId) {
      resultIds.add(layout.resultId);
    }

    // we are visible and have children, get children resultIds.
    if (Array.isArray(layout.children)) {
      _getVisibleResultIds(layout.children, params, filters).forEach(id => resultIds.add(id));
    }
  });

  return resultIds;
}
