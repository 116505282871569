import React from 'react';
import {useDispatch} from 'hooks/redux';
import Button from 'components/button';
import {showInModal} from 'modules/modal';
import EnrollStudentsModal from '../students/enrollStudentsModal';

export default function EnrollStudentsModalButton({courseId}) {
  const dispatch = useDispatch();

  return (
    <Button
      primary
      icon="fas fa-pencil"
      onClick={() => {
        dispatch(
          showInModal(
            EnrollStudentsModal,
            {
              courseId
            },
            {},
            {wider: true}
          )
        );
      }}
    >
      Select enrolled students
    </Button>
  );
}
