import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {removeReportFromFolder} from 'modules/reports';
import {REPORT_TO_FOLDER} from 'modules/reports/reportConstants';
import {reportToFolderEndpoint} from 'modules/reports/reportUtils';
import {couldNot} from 'utils/errorUtils';

export default function* reportToFolderSaga() {
  yield takeLatest(REPORT_TO_FOLDER, reportToFolder);
}

function* reportToFolder(action) {
  const {type, reportId, mode, ...postData} = action;
  const url = reportToFolderEndpoint({reportId, mode});

  yield processRequest(
    'PUT',
    url,
    {
      success: function*(data) {
        // We don't need to add this report to a folder, as we'd have to click
        // on the folder to access it, which re-fetches the reports anyway.

        if (mode == 'move' && postData.fromFolder) {
          // we are moving - need to remove the report from this folder
          yield put(removeReportFromFolder(reportId, postData.fromFolder));
        }
      },

      errorMessage: couldNot(mode + ' report to folder')
    },
    postData
  );
}
