import {couldNot} from 'utils/errorUtils';
import {put, take} from 'redux-saga/effects';
import {showInModal, HIDE_MODAL} from 'modules/modal';
import {EXPORT_REPORT_COMPLETE} from 'modules/reports/reportConstants';
import InfoModal from 'components/modal/infoModal';
import ModalSpinner from 'components/spinner/modalSpinner';
import ExportComplete from 'modals/export/exportComplete';

export function* displayExportingFeedback({successMessage, submitLabel, ...modalProps}) {
  // Spin whilst we attempt to export.
  yield put(showInModal(ModalSpinner, modalProps));

  // we don't actually need to make the request, that is handled elsewhere.
  // we just need to wait for the complete action.

  const exportCompleteAction = yield take([EXPORT_REPORT_COMPLETE, HIDE_MODAL]);

  if (exportCompleteAction.type == HIDE_MODAL) {
    // TODO are you sure you want to quit exporting?
    return;
  }

  if (!exportCompleteAction.exportUrl) {
    yield put(
      showInModal(InfoModal, {
        message: couldNot('export report')
      })
    );
    return;
  }

  yield put(
    showInModal(ExportComplete, {
      exportUrl: exportCompleteAction.exportUrl,
      successMessage,
      submitLabel
    })
  );
}
