import React from 'react';
import AuthWrapper from 'modules/auth/components/authWrapper';
import UnlockUserPage from 'modules/auth/pages/unlockUserPage';

export default class UnlockUserRoute extends React.Component {
  render() {
    const {
      location: {pathname},
      match: {params}
    } = this.props;

    return (
      <AuthWrapper pathname={pathname}>
        <UnlockUserPage userId={params.userId} token={params.token} />
      </AuthWrapper>
    );
  }
}
