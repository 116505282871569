import {takeLatest, put} from 'redux-saga/effects';
import {ATTEMPT_CONFIRM_CHECKOUT, receiveAttemptConfirmCheckoutResponse, handleBadCheckoutError} from 'modules/confirmCheckout/confirmCheckoutActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {confirmCheckoutEndpoint} from 'modules/confirmCheckout/confirmCheckoutUtils';

export default function* attemptConfirmCheckoutSaga() {
  yield takeLatest([ATTEMPT_CONFIRM_CHECKOUT], function*({checkoutRequestId}) {
    yield processRequest('POST', confirmCheckoutEndpoint(checkoutRequestId), {
      successAction: receiveAttemptConfirmCheckoutResponse,
      error: function*(err) {
        if (err) {
          let errorMsg = "Failed to confirm checkout.";

          if (err.errorCode === "notFound") {
            errorMsg = "Could not find this checkout session. Please try again later.";
          }

          if (err.message === "Cannot accept a closed quote") {
            errorMsg = err.message;
          }

          yield put(handleBadCheckoutError(errorMsg));
          return;
        }
        
        yield put(handleBadCheckoutError("Failed to confirm checkout."));
      }
    });
  });
}