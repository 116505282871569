import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';

const selector = createStructuredSelector({account: accountSelector});

const HasPaymentGandalf = ({children, account}) => {
  return account.get('hasPaymentDetails') ? children : null;
};

export default connect(selector)(HasPaymentGandalf);
