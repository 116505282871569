exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3loP95HgjLU0ftNsi8q_yX{min-width:120px}@media(max-width: 425px){._3loP95HgjLU0ftNsi8q_yX{margin-top:10px}}", ""]);

// exports
exports.locals = {
	"ignoreButton": "_3loP95HgjLU0ftNsi8q_yX"
};