exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1CDcihMpOhw-gFuCb7xoGU{max-width:400px;margin:0 auto}._30j_jGh19uUqvq4EWI1sJp{margin:-15px 0 0 0;text-align:center}.TKy32JHe6nSlB2mK8mrks{font-size:25px;font-weight:bold;display:block}._2lBQ-rL5ai4lulqzEQobta{margin-top:-4px;display:block;font-size:14px}", ""]);

// exports
exports.locals = {
	"gauge": "_1CDcihMpOhw-gFuCb7xoGU",
	"footer": "_30j_jGh19uUqvq4EWI1sJp",
	"value": "TKy32JHe6nSlB2mK8mrks",
	"label": "_2lBQ-rL5ai4lulqzEQobta"
};