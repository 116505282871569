exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3SR7rcX3Cx62eBeyouxcb1{display:flex;flex-wrap:wrap;width:100%}._3oG_dq61cMJIYdYbsT3Akj{margin-bottom:40px}._3Pg0yJXQ0MSKR7H0wq0orZ{flex:0 0 auto;display:flex;flex-direction:column;width:100%;padding-bottom:20px}@media print{._3Pg0yJXQ0MSKR7H0wq0orZ{margin:0;position:relative;display:block}._3oG_dq61cMJIYdYbsT3Akj{margin:0}}@media(max-width: 425px){._3SR7rcX3Cx62eBeyouxcb1{overflow-x:hidden}}", ""]);

// exports
exports.locals = {
	"test": "_3SR7rcX3Cx62eBeyouxcb1",
	"testPage": "_3oG_dq61cMJIYdYbsT3Akj",
	"content": "_3Pg0yJXQ0MSKR7H0wq0orZ"
};