import React from 'react';
import {connect} from 'react-redux';
import AuthWrapper from 'modules/auth/components/authWrapper';
import ForgottenPasswordPage from 'modules/auth/pages/forgottenPasswordPage';
import {triggerSentResetTokenEmailState} from 'modules/passwords';

export class ForgottenPasswordRoute extends React.Component {
  componentWillMount() {
    this.props.triggerSentResetTokenEmailState();
  }

  render() {
    const {
      location: {pathname}
    } = this.props;

    return (
      <AuthWrapper pathname={pathname}>
        <ForgottenPasswordPage />
      </AuthWrapper>
    );
  }
}

export default connect(
  null,
  {triggerSentResetTokenEmailState}
)(ForgottenPasswordRoute);
