exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Xy1fMeEe7b9dAVNpWdljP{box-shadow:0px 0px 25px -5px #666;background:#fff;padding:20px}.Xy1fMeEe7b9dAVNpWdljP ._2BHQOoCO8HVN014xKAP5gD{font-size:20px;margin:0 0 20px 0}._22JseubvvJxCvSEvLLx7AB{height:40px}._1CHay6iBAzvTN03fzBl-Nl{margin-top:18px;font-weight:bold;text-align:center;color:#333;font-size:18px}._252P38J5Hv4gSG-iFPzkpl{margin-bottom:20px}._21xBTWzl2arSf-i1s2ilF-{margin:-10px}._2MOIcp5emg897eQpSBOfxU{display:inline-block;text-align:center;margin:10px;width:150px;vertical-align:middle}._2MOIcp5emg897eQpSBOfxU img{width:100px;height:100px}._2MOIcp5emg897eQpSBOfxU p{margin-top:10px;height:42px}", ""]);

// exports
exports.locals = {
	"achievementProgress": "Xy1fMeEe7b9dAVNpWdljP",
	"title": "_2BHQOoCO8HVN014xKAP5gD",
	"achievementProgressBar": "_22JseubvvJxCvSEvLLx7AB",
	"complete": "_1CHay6iBAzvTN03fzBl-Nl",
	"badges": "_252P38J5Hv4gSG-iFPzkpl",
	"badgesGrid": "_21xBTWzl2arSf-i1s2ilF-",
	"badge": "_2MOIcp5emg897eQpSBOfxU"
};