exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1pYA3P-OuHkFg-4eTyMa3E{margin-bottom:16px}._1pYA3P-OuHkFg-4eTyMa3E h2{cursor:pointer;font-size:21px;padding-left:16px;position:relative;transition:.2s ease-in-out}._1pYA3P-OuHkFg-4eTyMa3E h2:hover{opacity:.7}._1pYA3P-OuHkFg-4eTyMa3E h2::before{width:0;height:0;border-style:solid;border-width:\"4px 0 4px 7px\";border-color:transparent transparent transparent #000;content:\"\";position:absolute;left:0px;top:7px}@media screen and (max-width: 767px){._1pYA3P-OuHkFg-4eTyMa3E h2{font-size:18px}}._1ogA15z4b5m1QKzPs2_5Ka{padding-left:16px}._2qVRSpLBr-s0I-NLTdCp62{margin:0px 0px 32px 0px}._1ypGtgzeb8i13uy-ILRYoh{height:0px;opacity:0;transition-delay:height .3s}.u9knFxKkvZxPaxn22zBW::before{-webkit-transform:rotate(90deg);transform:rotate(90deg)}", ""]);

// exports
exports.locals = {
	"accordionItem": "_1pYA3P-OuHkFg-4eTyMa3E",
	"accordionContent": "_1ogA15z4b5m1QKzPs2_5Ka",
	"expanded": "_2qVRSpLBr-s0I-NLTdCp62",
	"collapsed": "_1ypGtgzeb8i13uy-ILRYoh",
	"titleExpanded": "u9knFxKkvZxPaxn22zBW"
};