import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import RadialProgress from './radialProgress';
import Icon from 'components/font-awesome';
import styles from './score.scss';

export default class Score extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    target: PropTypes.number,
    size: PropTypes.number
  };

  static defaultProps = {
    value: 0,
    size: 70,
    hideLabel: false
  };

  render() {
    const {value} = this.props;

    // -2 special case (meaning unknowable)
    if (Math.round(value) === -2) {
      return this.renderUnknowableScore();
    }

    return this.renderScore(value);
  }

  // @todo Investigate: looks like we never use this as our Score.js layout component takes over. Why?
  renderUnknowableScore() {
    // set value as 100 so its a nice full beautiful circle :)
    return (
      <div className={styles.container}>
        <RadialProgress
          value={100}
          showText={false}
          animate={this.props.animate}
          fillClass={styles.unknowable}
          size={this.props.size}
          borderSize={this.props.borderSize}
        />
        {/* <Icon name="lock" size="3x" className={styles.lock} /> */}
      </div>
    );
  }

  renderLabel(value) {
    if (value < 0) return '';
    if (value < 30) return 'Very poor';
    if (value < 50) return 'Poor';
    if (value < 60) return 'Fair';
    if (value < 80) return 'Good';
    if (value < 90) return 'Great';
    if (value < 100) return 'Excellent';
    return 'Perfect';
  }

  renderScore() {
    const {small, animate, center, inline} = this.props;
    let {size, value, isLoading, hideLabel} = this.props;
    const scoreType = getScoreType(value);

    if (value === -1 || value == null) {
      isLoading = true;
      value = 0;
    }

    if (small) {
      return (
        <div className={cx(styles.smallWrapper, {[styles.inline]: inline})}>
          <span className={cx(styles.smallRadial, styles[scoreType])} />
          <span className={styles.smallValue}>{Math.round(value * 10) / 10}</span>
        </div>
      );
    }

    return (
      <div
        style={{width: size}}
        className={cx(styles.score, {
          [styles.center]: center
        })}
      >
        <RadialProgress
          {...this.props}
          isLoading={isLoading}
          animate={animate && !isLoading}
          fillClass={isLoading ? styles.loadingFill : styles[scoreType]}
        />
        {!hideLabel && (
          <div className={cx(styles.label, {[styles.bigLabel]: size > 100})}>
            {this.renderLabel(value)}
          </div>
        )}
      </div>
    );
  }
}

export function getScoreType(value) {
  if (value === -2) return 'unknowable';
  if (value < 20) return 'red';
  if (value < 40) return 'orange';
  if (value < 60) return 'yellow';
  if (value < 80) return 'green';
  return 'cyan';
}
