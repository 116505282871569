exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2h8mAYrA45neexUR8lfkPn{padding:30px}._2YNWmDPccHCtZmBHxPy033{margin-bottom:30px}._16tDDWNueR-FzPfu69bp49{margin-top:-10px;margin-bottom:20px;font-size:1.25em}", ""]);

// exports
exports.locals = {
	"content": "_2h8mAYrA45neexUR8lfkPn",
	"title": "_2YNWmDPccHCtZmBHxPy033",
	"description": "_16tDDWNueR-FzPfu69bp49"
};