import React from 'react';
import {List} from 'immutable';
import {push} from 'modules/location';
import {useDispatch} from 'hooks/redux';
import {coursePath, courseLessonPath} from '../utils';
import Icon from 'components/font-awesome';
import Sidebar from 'components/sidebar/listSidebar';
import SidebarItem from 'components/sidebar/listSidebar/sidebarItem';
import SlideBetween from 'components/animation/slideBetween';
import LessonIcon from './lesson/lessonIcon';

export default function CourseSidebar({course, activeStageId, activeLessonId}) {
  const dispatch = useDispatch();
  const activeStage = !activeStageId
    ? null
    : course.get('stages', List()).find(stage => stage.get('stageId') == activeStageId);

  return (
    <Sidebar>
      <h2>{course.get('name')}</h2>

      <SlideBetween currentIndex={!activeStage ? 0 : 1} noFit>
        {/* Sidebar 1 showing stages */}
        {/* @todo Ask Alex MF for help - had to fix these widths to handle long text labels with icons  */}
        <div style={{width: '200px'}}>
          <SidebarItem
            newStyle
            key="overview"
            active={!activeStageId}
            onClick={() => {
              dispatch(
                push(coursePath, {
                  courseId: course.get('courseId')
                })
              );
            }}
          >
            Course overview
          </SidebarItem>
          <StagesSidebar course={course} activeStageId={activeStageId} />
        </div>

        {/* Sidebar 2 showing lessons */}
        {/* @todo Ask Alex MF for help - had to fix these widths to handle long text labels with icons  */}
        {activeStage && (
          <div style={{width: '200px'}}>
            <SidebarItem
              newStyle
              key={course.get('courseId')}
              icon="chevron-left"
              onClick={() => {
                dispatch(
                  push(coursePath, {
                    courseId: activeStage.get('courseId')
                  })
                );
              }}
            >
              Course overview
            </SidebarItem>
            <StageSidebar activeLessonId={activeLessonId} stage={activeStage} />
          </div>
        )}
      </SlideBetween>
    </Sidebar>
  );
}

function StagesSidebar({course, activeStageId}) {
  const dispatch = useDispatch();

  return course.get('stages', List()).map(stage => (
    <SidebarItem
      newStyle
      active={stage.get('stageId') == activeStageId}
      // before={<LessonIcon state={getStageSidebarState(stage)} />}
      after={<Icon name="chevron-right" />}
      key={stage.get('stageId')}
      onClick={() => {
        dispatch(
          // if we click on a stage, navigate to the first lesson in that stage
          push(courseLessonPath, {
            courseId: stage.get('courseId'),
            lessonId: stage
              .get('lessons')
              .first()
              .get('lessonId')
          })
        );
      }}
    >
      {stage.get('name')}
    </SidebarItem>
  ));
}

function StageSidebar({stage, activeLessonId}) {
  const dispatch = useDispatch();

  return stage.get('lessons').map(lesson => (
    <SidebarItem
      newStyle
      active={lesson.get('lessonId') === activeLessonId}
      before={<LessonIcon lesson={lesson} light={true} />}
      key={lesson.get('lessonId')}
      onClick={() => {
        dispatch(
          push(courseLessonPath, {
            courseId: lesson.get('courseId'),
            lessonId: lesson.get('lessonId')
          })
        );
      }}
    >
      {lesson.get('name')}
    </SidebarItem>
  ));
}

// function getStageSidebarState(stage) {
//   const lessonCount = stage.get('lessons').size;
//   const completeCount = stage.get('lessons').filter(lesson => lesson.get('state') === 'completed');
//   const underwayCount = stage.get('lessons').filter(lesson => lesson.get('state') === 'underway');
//   const viewedCount = stage.get('lessons').filter(lesson => lesson.get('state') === 'viewed');

//   if (!lessonCount) return '';
//   if (completeCount === lessonCount) return 'completed';
//   if (underwayCount > 0) return 'underway';
//   if (viewedCount > 0) return 'viewed';
//   return '';
// }
