exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DbQwd-tsSv1RVgqYWh3jK{max-height:60vh;overflow-y:scroll}", ""]);

// exports
exports.locals = {
	"notificationsList": "_3DbQwd-tsSv1RVgqYWh3jK"
};