import React from 'react';
import cx from 'classnames';
import styles from './status.scss';
import Center from 'components/center';
import Face from 'components/face';

export default function Status({className, children, appearance, noFit, panel}) {
  const containerClassName = cx(styles.container, className, {
    [styles[appearance]]: true
  });

  return (
    <Center fit={!noFit} className={cx(containerClassName, {[styles.panel]: panel})}>
      <Face appearance={appearance} className={styles.face} />
      {children}
    </Center>
  );
}
