import React, {useState} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './accordion.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired
};

const AccordionItem = ({children, title}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <h2 onClick={() => setExpanded(!expanded)} className={expanded ? styles.titleExpanded : ''}>
        {title}
      </h2>
      <div className={cx(styles.accordionContent, expanded ? styles.expanded : styles.collapsed)}>
        {children}
      </div>
    </div>
  );
};

AccordionItem.propTypes = propTypes;

AccordionItem.defaultProps = {};

export default AccordionItem;
