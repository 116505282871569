import {call, put, select, takeLatest} from 'redux-saga/effects';
import {receiveTest} from 'modules/tests';
import {UPDATE_TEST} from 'modules/tests/testConstants';
import POST from 'utils/saga/post';
import PUT from 'utils/saga/put';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {testsEndpoint, testEndpoint} from 'modules/tests/testUtils';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {hideModal} from 'modules/modal';
import {showSuccess} from 'utils/saga/alerts';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';

export default function* saveTestSaga() {
  yield takeLatest([UPDATE_TEST], saveTest);
}

function* saveTest({test, overwrite}) {
  const {testId, ...testData} = test;
  const reportId = yield select(reportIdSelector);

  // if no testId, we're adding
  const endpoint = testId ? testEndpoint({reportId, testId}) : testsEndpoint({reportId});

  const res = yield call(testId ? PUT : POST, endpoint, testData);

  const niceType = testId == 'website' ? 'website' : 'test';
  yield processResponse(res, {
    // If the test didn't have a testId, we've just created it.
    success: function*(test) {
      if (niceType == 'website') {
        yield showSuccess(SUCCESS_MESSAGE);
      }

      yield put(receiveTest(reportId, test, overwrite));
    },

    errorMessage: couldNot(`${testId ? 'save' : 'add'} ${niceType}`)
  });

  yield put(hideModal());
}
