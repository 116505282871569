exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FYU-rpfx1_chZ8WtvhEsx{overflow:auto;height:100vh;background-color:#f0f2f5}", ""]);

// exports
exports.locals = {
	"wrapper": "_1FYU-rpfx1_chZ8WtvhEsx"
};