import React from 'react';
import Icon from 'components/font-awesome';
import styles from './icons.scss';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export class DeleteIcon extends React.Component {
  render() {
    const {size, ...props} = this.props;
    return <Icon name="times" size={size} {...filterInvalidDOMProps(props)} />;
  }
}

export default localStyles(styles)(DeleteIcon);
