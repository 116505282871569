import React from 'react';
import {connect} from 'react-redux';
import InspectorCategoryRow from './inspectorCategoryRow';
import styles from './inspectorStyle.scss';
import {inspectorCategoryItemSelector} from 'modules/inspector/inspectorSelectors';
import InspectorActionsSummary from './inspectorActionsSummary';

function InspectorCategoryItem({category, actions, actionsCount, maxActions}) {
  if (!actionsCount) return null;

  return (
    <div className={styles.categoryItem}>
      <InspectorCategoryRow category={category} actionsCount={actionsCount} />

      {!!actionsCount && (
        <InspectorActionsSummary
          actions={actions}
          actionsCount={actionsCount}
          maxActions={maxActions}
        />
      )}
    </div>
  );
}

export default connect(inspectorCategoryItemSelector)(InspectorCategoryItem);
