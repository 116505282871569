import {put, take, select, takeEvery} from 'redux-saga/effects';
import {REMOVE_CONTEXT} from '../inspectorConstants';
import {replace} from 'modules/location';
import {makePath} from 'utils/urlUtils';
import {locationSelector} from 'modules/location/locationSelectors';
import {inspectorMetadataSelector} from 'modules/inspector/inspectorSelectors';

export default function* removeContextFromInspectorSaga() {
  yield takeEvery(REMOVE_CONTEXT, removeContextFromInspector);
}

function* removeContextFromInspector() {
  const {query = {}} = yield select(locationSelector);
  const {pageHash} = yield select(inspectorMetadataSelector);

  // remove any props which start with "context"
  const queryWithoutContext = Object.keys(query).reduce(
    (reduction, key) => {
      if (key.substr(0, 7) !== 'context' && key !== 'tags' && key !== 'severities') {
        reduction[key] = query[key];
      }
      return reduction;
    },
    {pageHash}
  );

  // change query string (this will not trigger a page load)
  yield put(replace(makePath(location.pathname, queryWithoutContext)));
}
