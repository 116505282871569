import {Map, List, fromJS} from 'immutable';
import * as Consts from './constants';
import {keyBy} from 'utils/immutableUtils';
import {createLoadingState, startLoadingState, endLoadingState} from 'modules/app/reduxHelpers';

const NEW_ROW_COLUMN_COUNT = 3;

const initialState = fromJS({
  dashboards: {},
  results: {}, // dashboard results are stored here, keyed by dashboardId
  widgetTypes: {}, // list of all widget types (keyed by widgetTypeId)

  savingWidget: createLoadingState(),
  savingDashboard: createLoadingState(),
  deletingDashboard: createLoadingState(),
  requestingDashboard: createLoadingState(),
  requestingDashboards: createLoadingState(),
  requestingWidgetTypes: createLoadingState(),
  requestingDashboardResults: createLoadingState()
});

export default function dashboardsReducer(state = initialState, action) {
  switch (action.type) {
    case Consts.REQUEST_DASHBOARDS:
      return startLoadingState(state, 'requestingDashboards');
    case Consts.REQUEST_DASHBOARDS_COMPLETE:
      return endLoadingState(state, 'requestingDashboards', action.error).merge(
        'dashboards',
        keyBy(action.dashboards, 'dashboardId')
      );

    case Consts.REQUEST_DASHBOARD:
      return startLoadingState(state, 'requestingDashboard');
    case Consts.REQUEST_DASHBOARD_COMPLETE:
      return endLoadingState(state, 'requestingDashboard', action.error).setIn(
        ['dashboards', action.dashboard.dashboard.dashboardId.toString()],
        fromJS(action.dashboard)
      );

    case Consts.SAVE_DASHBOARD:
      return startLoadingState(state, 'savingDashboard');
    case Consts.SAVE_DASHBOARD_COMPLETE:
      return endLoadingState(state, 'savingDashboard', action.error).setIn(
        ['dashboards', action.dashboard.dashboard.dashboardId.toString()],
        fromJS(action.dashboard)
      );

    case Consts.CONFIRM_DELETE_WIDGET_COMPLETE:
      return state.setIn(
        ['dashboards', action.dashboard.dashboard.dashboardId.toString()],
        fromJS(action.dashboard)
      );

    case Consts.DELETE_DASHBOARD:
      return startLoadingState(state, 'deletingDashboard');
    case Consts.DELETE_DASHBOARD_COMPLETE:
      return endLoadingState(state, 'deletingDashboard', action.error).removeIn([
        'dashboards',
        action.dashboardId.toString()
      ]);

    case Consts.REQUEST_DASHBOARD_RESULTS:
      return startLoadingState(state, 'requestingDashboardResults');
    case Consts.REQUEST_DASHBOARD_RESULTS_COMPLETE:
      return endLoadingState(state, 'requestingDashboardResults', action.error).setIn(
        ['results', action.dashboardId.toString()],
        fromJS(action.results)
      );

    case Consts.REQUEST_WIDGET_TYPES:
      return startLoadingState(state, 'requestingWidgetTypes');
    case Consts.REQUEST_WIDGET_TYPES_COMPLETE:
      return endLoadingState(state, 'requestingWidgetTypes', action.error).setIn(
        ['widgetTypes'],
        keyBy(action.widgetTypes, 'widgetTypeId')
      );

    case Consts.ADD_WIDGET:
    case Consts.EDIT_WIDGET:
      return startLoadingState(state, 'savingWidget');
    case Consts.SAVE_WIDGET_COMPLETE:
      return endLoadingState(state, 'savingWidget', action.error).setIn(
        ['dashboards', action.dashboard.dashboard.dashboardId.toString()],
        fromJS(action.dashboard)
      );

    case Consts.ADD_DASHBOARD_ROW:
      return state.updateIn(
        ['dashboards', action.dashboardId.toString(), 'dashboard', 'rows'],
        rows => rows.push(fromJS({columns: NEW_ROW_COLUMN_COUNT}))
      );

    case Consts.REMOVE_DASHBOARD_ROW:
      return state
        .removeIn([
          'dashboards',
          action.dashboardId.toString(),
          'dashboard',
          'rows',
          action.rowIndex
        ])
        .updateIn(['dashboards', action.dashboardId.toString(), 'widgets'], widgets => {
          return (
            widgets
              // filter out widgets that we are removing
              .filter(widget => {
                return widget.get('row') != action.rowIndex;
              })
              // all widgets that refer to columns with a higher index, need their indexs decrementing
              .map(widget => {
                // update the row indexes of widgets, otherwise they'll be pointing to the wrong place
                if (widget.get('row') > action.rowIndex) {
                  return widget.set('row', widget.get('row') - 1);
                } else {
                  return widget;
                }
              })
          );
        });

    case Consts.ADD_DASHBOARD_COLUMN: {
      const key = [
        'dashboards',
        action.dashboardId.toString(),
        'dashboard',
        'rows',
        action.rowIndex,
        'columns'
      ];
      const columns = state.getIn(key);
      return state.setIn(key, columns + 1);
    }

    case Consts.REMOVE_DASHBOARD_COLUMN:
      return (
        state
          // remove column from the layout
          .updateIn(
            ['dashboards', action.dashboardId.toString(), 'dashboard', 'rows', action.rowIndex],
            row => {
              return row.set('columns', row.get('columns') - 1);
            }
          )
          .updateIn(['dashboards', action.dashboardId.toString(), 'widgets'], widgets => {
            return (
              widgets
                // filter out widgets that we are removing
                .filter(widget => {
                  return (
                    widget.get('row') != action.rowIndex ||
                    widget.get('column') != action.columnIndex
                  );
                })
                // all widgets that refer to columns with a higher index, need their indexs decrementing
                .map(widget => {
                  if (
                    widget.get('row') === action.rowIndex &&
                    widget.get('column') > action.columnIndex
                  ) {
                    return widget.set('column', widget.get('column') - 1);
                  } else {
                    return widget;
                  }
                })
            );
          })
      );

    case Consts.INSERT_DASHBOARD_ROW:
      return (
        state
          // remove column from the layout
          .updateIn(['dashboards', action.dashboardId.toString(), 'dashboard', 'rows'], rows => {
            return rows.splice(action.rowIndex, 0, fromJS({columns: NEW_ROW_COLUMN_COUNT}));
          })
          .updateIn(['dashboards', action.dashboardId.toString(), 'widgets'], widgets => {
            return (
              widgets
                // all widgets that refer to columns with a higher index, need their indexs decrementing
                .map(widget => {
                  if (widget.get('row') >= action.rowIndex) {
                    return widget.set('row', widget.get('row') + 1);
                  } else {
                    return widget;
                  }
                })
            );
          })
      );

    case Consts.SET_DASHBOARD_COLUMN_COUNT_COMPLETE:
      return (
        state
          // remove column from the layout
          .updateIn(
            ['dashboards', action.dashboardId.toString(), 'dashboard', 'rows', action.rowIndex],
            row => {
              return row.set('columns', action.colCount);
            }
          )
          // potentially removing and adding many columns... if adding, fine, if removing, we may have less columns than we have widgets
          // so we need to count the widgets in this row, and count the new count, if the number of widgets > colCount, we need to
          // not modify and show error
          .updateIn(['dashboards', action.dashboardId.toString(), 'widgets'], widgets => {
            let myCol = -1;
            return widgets
              .sort((widgetA, widgetB) => {
                if (widgetA.get('row') === widgetB.get('row')) {
                  if (widgetA.get('column') === widgetB.get('column')) {
                    if (widgetA.get('order') === widgetB.get('order')) {
                      return 0;
                    } else {
                      return widgetA.get('order') - widgetB.get('order');
                    }
                  } else {
                    return widgetA.get('column') - widgetB.get('column');
                  }
                } else {
                  return widgetA.get('row') - widgetB.get('row');
                }
              })
              .map((widget, i) => {
                if (widget.get('row') === action.rowIndex) {
                  if (widget.get('order') === 0) {
                    // if this widget is the first in this column, then we are onto the next column
                    myCol++;
                  }
                  return widget.set('column', myCol);
                } else {
                  return widget;
                }
              });
          })
      );

    default:
      return state;
  }
}
