import {Link} from 'react-router-dom';
import Score from 'components/score';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './summaryScore.scss';
import {testPath} from 'modules/tests/testUtils';

class SummaryScore extends React.Component {
  static propTypes = {
    testId: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  };

  render() {
    const {testId, name, description, score, params, config} = this.props;
    const link = testPath({...params, testId});

    const content = (
      <div className={styles.summaryScore}>
        <div className={styles.score}>
          <Score value={score} animate />
        </div>
        <div className={styles.name}>
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
      </div>
    );

    return testId ? (
      <Link to={link} className={styles.link}>
        {content}
      </Link>
    ) : (
      content
    );
  }
}

export default SummaryScore;
