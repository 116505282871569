import LockedUserMessage from 'components/errorCodes/lockedUserMessage';
import ExternalLink from 'modules/location/externalLink';
import React from 'react';

export default {
  userExists: 'The specified user already exists: {value}',
  invalidCredentials: (
    <span>
      We didn't recognize your email or password. Did you mean to{' '}
      <ExternalLink href="https://app.insites.com/?utm_source=silktide-login&utm_medium=web&utm_campaign=failed-login">
        log in to Insites?
      </ExternalLink>
    </span>
  ),
  hasRoleOrBetter: 'You cannot assign a role you do not have yourself',
  notPermitted: "You don't have permission to delete this folder",
  notEmpty: 'A folder must be empty before you can delete it',
  lockedUser: LockedUserMessage,
  passwordExpired:
    'Your password has expired. You must set a new password before you can login again. An email has been sent to your email address with a link to set a new password.',
  passwordUsedBefore:
    'This password has been used before in the past 12 months. Please choose another password.',
  passwordNotStrong: '{passwordHelp}',
  invalidTwoFactorCode: 'The two-factor authentication code was incorrect. Please try again'
};
