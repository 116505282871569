import {delay} from 'redux-saga';
import {put, takeEvery} from 'redux-saga/effects';
import {SWITCH_ACCOUNT, LOGIN} from 'modules/auth';
import {REQUEST_ADD_REPORT} from 'modules/reports/reportConstants';
import fetchEntitlements from 'modules/entitlements/sagas/helpers/fetchEntitlements';
import {requestEntitlements, ENTITLEMENTS_ERROR} from 'modules/entitlements';

export default function* loadSummarisedEntitlementsSaga() {
  yield takeEvery([SWITCH_ACCOUNT, LOGIN, REQUEST_ADD_REPORT], loadEntitlements);
  yield takeEvery(ENTITLEMENTS_ERROR, recoverEntitlements);
}

function* loadEntitlements() {
  yield put(requestEntitlements());
  yield fetchEntitlements();
}

function* recoverEntitlements() {
  yield delay(120000); // wait 2 minutes
  yield loadEntitlements();
}
