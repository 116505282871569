import {call, put, select, takeEvery} from 'redux-saga/effects';
import {PERFORM_ISSUE_ACTION, hideIssue} from 'modules/results';
import POST from 'utils/saga/post';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {currentFilterSelector} from 'modules/filters/filterSelectors';

export default function* performIssueActionSaga() {
  yield takeEvery([PERFORM_ISSUE_ACTION], performIssueAction);
}

function* performIssueAction({testId, metricName, issueHash, state}) {
  const reportId = yield select(reportIdSelector);
  // results are stored with filters - fetch the current ones.
  const filters = yield select(currentFilterSelector);

  if (!testId || !reportId) {
    return;
  }

  const res = yield call(POST, `reports/${reportId}/tests/${testId}/update-issue`, {
    issueHash,
    state
  });

  yield processResponse(res, {
    errorMessage: couldNot(`perform the requested action`)
  });
}
