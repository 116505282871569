import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class FormattedDate extends React.Component {
  static propTypes = {
    timestamp: PropTypes.number.isRequired,
    format: PropTypes.string.isRequired
  };

  render() {
    const {timestamp, format, ...props} = this.props;
    const date = moment.unix(timestamp);

    return <span {...filterInvalidDOMProps(props)}>{date.format(format)}</span>;
  }
}
