import {fromJS} from 'immutable';

const initialState = fromJS({});

export default function archivesReducer(state = initialState, action) {
  return state;
}

export const SELECT_ARCHIVE = 'archives:SELECT_ARCHIVE';
export function selectArchive(archiveId) {
  return {type: SELECT_ARCHIVE, archiveId};
}
