import {normalizeFilters} from 'modules/filters/filterUtils';
import {
  VIEW_TEST,
  LEAVE_TEST,
  SELECT_TEST_TYPE,
  UPDATE_TEST,
  RECEIVE_TEST,
  SHOW_COMPONENT_DETAIL,
  UPDATE_COMPONENT_DETAIL
} from './testConstants';

export {default} from './testsReducer';

// TODO: rewrite - currently not used.
// export function deleteTest(test) {
//   const {testId, productCode} = test;

//   const onSuccess =
//     productCode &&
//     function(res, {dispatch}) {
//       // TODO: may need to increment product free count.
//       return res;
//     };

//   return del('tests', testId, {onSuccess});
// }

export function viewTest(params, filters) {
  return {
    type: VIEW_TEST,
    ...params,
    filters: normalizeFilters(filters)
  };
}

// Assuming we can only view 1 test at a time, LEAVE_TEST doesn't need a testId.
export function leaveTest() {
  return {
    type: LEAVE_TEST
  };
}

export function selectTestType(testType) {
  return {
    type: SELECT_TEST_TYPE,
    testType
  };
}

export function updateWebsite(test, overwrite = false) {
  return {
    type: UPDATE_TEST,
    test: {...test, testId: 'website'},
    overwrite
  };
}

export function receiveTest(reportId, test, overwrite = false) {
  return {
    type: RECEIVE_TEST,
    reportId,
    test,
    overwrite
  };
}

export function showComponentDetail(componentConfig, limit = 250, offset = 0) {
  return {type: SHOW_COMPONENT_DETAIL, componentConfig, limit, offset};
}

export function updateComponentDetail(componentConfig, filters = {}, limit = 250, offset = 0) {
  return {
    type: UPDATE_COMPONENT_DETAIL,
    componentConfig,
    filters,
    limit,
    offset
  };
}
