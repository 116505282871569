import _ from 'lodash';
import React from 'react';
import Highcharts from 'react-highcharts';
import GraphUtils, {monthNames} from 'utils/graphUtils.js';
import {toFixed} from 'utils/numberUtils.js';

class Sparkline extends React.Component {
  getSparklineConfig = results => {
    const {tooltip, tooltipsuffix, decimals, zerotooltip, positivetooltip} = this.props.options;

    return {
      chart: {
        type: 'area',
        backgroundColor: null,
        borderWidth: 0,
        margin: [2, 0, 2, 0],
        height: 30,
        width: 200,
        style: {
          overflow: 'visible'
        },
        skipClone: true
      },
      title: {
        text: ''
      },
      colors: ['#54cbc7'],
      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      tooltip: {
        enabled: tooltip || false,
        xDateFormat: '%Y-%m-%d',
        formatter() {
          if (this.y === 0 && zerotooltip) {
            return zerotooltip;
          }

          if (this.y > 0 && positivetooltip) {
            return positivetooltip;
          }

          if (decimals) {
            return toFixed(this.y, decimals) + tooltipsuffix;
          }

          return this.y + tooltipsuffix;
        }
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          marker: {
            enabled: false,
            radius: 0
          },
          fillOpacity: 0.25
        },
        column: {
          negativeColor: '#910000',
          borderColor: 'silver'
        }
      },
      series: results
    };
  };

  render() {
    const {data} = this.props;

    if (_.size(data) === 0) {
      return <div />;
    }

    const sparkResults = GraphUtils.getSparklineResults(data);
    if (sparkResults == null) {
      return <div />;
    }

    return <Highcharts config={this.getSparklineConfig(sparkResults)} />;
  }
}

Sparkline.propTypes = {};

export default Sparkline;
