import {useState, useEffect} from 'react';

export default function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

// NOTE prefer using `connectContext(ScreenSizeContext, 'screenSizes')`
// to make components aware of the screen changes
export const WindowWidth = props => {
  const width = useWindowWidth();
  return props.children(width);
};
