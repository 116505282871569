import {analytics, events} from 'modules/analytics';

export const VIEW_MY_SETTINGS_PAGE = 'settings:VIEW_MY_SETTINGS_PAGE';
export function viewMySettingsPage() {
  return {
    type: VIEW_MY_SETTINGS_PAGE,
    ...analytics(events.VIEW_MY_SETTINGS_PAGE)
  };
}

export const VIEW_ORGANIZATION_SETTINGS_PAGE = 'settings:VIEW_ORGANIZATION_PAGE';
export function viewOrganizationSettingsPage() {
  return {
    type: VIEW_ORGANIZATION_SETTINGS_PAGE,
    ...analytics(events.VIEW_ORGANIZATION_SETTINGS_PAGE)
  };
}

export const VIEW_PAYMENTS_PAGE = 'settings:VIEW_PAYMENTS_PAGE';
export function viewPaymentsPage() {
  return {
    type: VIEW_PAYMENTS_PAGE,
    ...analytics(events.VIEW_PAYMENTS_PAGE)
  };
}

export const UPDATE_ACCOUNT_SETTINGS = 'settings:UPDATE_ACCOUNT_SETTINGS';
export function updateAccountSettings(data) {
  return {
    type: UPDATE_ACCOUNT_SETTINGS,
    data
  };
}
