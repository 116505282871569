import ls from 'utils/localStorage';
import {LOCAL_STORAGE_AUTH} from 'config';

export function getAuthToken() {
  return ls.get(LOCAL_STORAGE_AUTH);
}

export function getAccountId() {
  return ls.get('accountId');
}

export function storeAccountId(accountId) {
  return ls.set('accountId', accountId);
}

export function storeAuthToken(token) {
  return ls.set(LOCAL_STORAGE_AUTH, token);
}

export function clearAuth() {
  ls.remove('accountId');
  ls.remove(LOCAL_STORAGE_AUTH);
}
