exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".V95L3NkF2zVTTCnE23QOW>span{align-items:center;flex-direction:column}.V95L3NkF2zVTTCnE23QOW>span p{margin:0}.V95L3NkF2zVTTCnE23QOW button{display:inline-block;margin-left:20px;color:#533be2;cursor:pointer;font-size:15px;padding-left:0;text-decoration:underline}._2CXGSwOzEWQPRIYaw0Auc7{display:flex;flex-direction:row}._2FMjDLYioXhiFHU-p1tnDA{display:flex;align-items:center}.FT3F9ZK68isyZIS-XeIxz{background:#2f209e;border-radius:50%;color:#fff;display:flex;height:40px;justify-content:center;line-height:40px;margin-right:16px;width:40px}._1fgq_RDUp4XpDmjzZUzw1Y{opacity:.8}", ""]);

// exports
exports.locals = {
	"courseProgress": "V95L3NkF2zVTTCnE23QOW",
	"messageWrapper": "_2CXGSwOzEWQPRIYaw0Auc7",
	"submessageWrapper": "_2FMjDLYioXhiFHU-p1tnDA",
	"iconWrapper": "FT3F9ZK68isyZIS-XeIxz",
	"percentage": "_1fgq_RDUp4XpDmjzZUzw1Y"
};