import Spinner from 'components/spinner';
import ModalContent from 'modules/modal/components/modalContent';
import {closeShareReport, shareUrlSelector} from 'modules/share';
import React from 'react';
import {connect} from 'react-redux';
import ShareReportContainer from './shareReportContainer';

export class ShareReportModal extends React.Component {
  handleClickButton = () => {
    this.props.hideModal();
  };

  render() {
    const {token} = this.props;

    if (!token) {
      return (
        <ModalContent>
          <Spinner centered />
        </ModalContent>
      );
    }

    return (
      <ModalContent
        title="Share website"
        buttons={['Close']}
        onClickButton={this.handleClickButton}
      >
        <ShareReportContainer />
      </ModalContent>
    );
  }
}

export default connect(
  shareUrlSelector,
  {closeShareReport}
)(ShareReportModal);
