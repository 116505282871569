import React from 'react';
import {connect} from 'react-redux';
import Field from 'modules/form/components/field';
import {setGlobalConfigOption} from 'modules/configOptions';
import {configOptionsInputSelector} from 'modules/configOptions/configOptionsSelectors';
import {FormContext} from 'components/layoutComponents/form/formContext';

export class Config extends React.Component {
  render() {
    const {label, formType, fieldName, validators} = this.props;

    return (
      <FormContext.Consumer>
        {form => {
          return (
            <Field
              ref={form.setFieldRef(fieldName)}
              value={form.values[fieldName]}
              label={label}
              type={formType}
              name={fieldName}
              onChange={value => {
                form.setValue(fieldName, value);
              }}
              {...validators}
            />
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default connect(
  configOptionsInputSelector,
  {setGlobalConfigOption}
)(Config);
