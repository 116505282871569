import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {COURSE_UNENROLL} from 'modules/courses/constants';
import {courseUnenrollEndpoint} from 'modules/courses/utils';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {unEnrollFromCourseComplete} from '../actions';

export default function* unEnrollFromCourseListener() {
  yield takeLatest(COURSE_UNENROLL, unEnrollFromCourseSaga);
}

function* unEnrollFromCourseSaga({courseId}) {
  const url = courseUnenrollEndpoint({courseId});

  yield processRequest(
    'POST',
    url,
    {
      *notFound() {
        //yield put(saveLessonStateComplete('NOT_FOUND'));
      },
      *success({course, stages, lessons, tutors, students} = {}) {
        // @TODO - make util for this as it is done in the request course saga too
        const newCourse = {...course};
        const newStages = stages.map(stage => {
          stage.lessons = lessons.filter(
            lesson => lesson.stageId.toString() === stage.stageId.toString()
          );
          return stage;
        });

        newCourse.stages = newStages;
        newCourse.tutors = tutors;
        newCourse.students = students;

        yield put(unEnrollFromCourseComplete(null, newCourse));
      },
      *error(err) {
        yield put(unEnrollFromCourseComplete(err));
      },
      errorMessage: couldNot('request course')
    },
    {}
  );
}
