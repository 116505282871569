exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._20lQWaDqQCiy3eGD4Vn914{display:inline-block;padding:1px 5px;border:1px solid #df1616;font-size:12px;border-radius:5px;color:#df1616;margin-left:5px !important}._225XwKdsYuV6HPQ4Gocbvl{color:#888 !important}", ""]);

// exports
exports.locals = {
	"label": "_20lQWaDqQCiy3eGD4Vn914",
	"url": "_225XwKdsYuV6HPQ4Gocbvl"
};