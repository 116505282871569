import PropTypes from 'prop-types';
import React from 'react';
import {getFilename} from 'utils/urlUtils';

class Filename extends React.Component {
  render() {
    const {className, data} = this.props;
    return <span className="text-nowrap">{getFilename(data)}</span>;
  }
}

export default Filename;
