import React from 'react';
import {connect} from 'react-redux';
import {examplesFolderPath} from 'modules/folders/folderUtils';
import {push} from 'modules/location';
import {ECOMMERCE_TRIAL} from 'modules/accounts/accountConstants';
import AddReportButton from 'modules/reports/containers/addReportButton';
import styles from './emptyFolderView.scss';
import {reportPath} from 'modules/reports/reportUtils';
import ExampleReportsView from './exampleReportsView';
import {openedGettingStarted} from 'modules/homePage';
import cx from 'classnames';
import screenshot from './screenshot.jpg';
import ExternalLink from 'modules/location/externalLink';

const GETTING_STARTED_URL =
  'https://monitor.support.silktide.com/getting-started/what-is-silktide/';

export class EmptyFolderView extends React.Component {
  handleGuideClick = () => {
    this.props.openedGettingStarted();
  };

  getMessage() {
    const {hasFilters, homePage} = this.props;

    return homePage
      ? "You don't have any websites yet"
      : !hasFilters
      ? "You don't have any websites in this folder"
      : 'There are no websites matching your filters';
  }

  render() {
    const {folderId, hasFilters, className} = this.props;

    return (
      <div className={cx(className, styles.noSitesContainer)}>
        <div className={styles.addWebsiteContainer}>
          <div>
            <h3>{this.getMessage()}</h3>
          </div>
          <div className={styles.addReportBtn}>
            <AddReportButton folderId={folderId}>Add a website</AddReportButton>
          </div>
        </div>
        {!hasFilters && <hr />}
        {!hasFilters && (
          <div className={styles.guide}>
            <h3>Getting started guide</h3>
            <p>
              Not sure where to begin? Check out our{' '}
              <ExternalLink href={GETTING_STARTED_URL}>getting started guide</ExternalLink>.
            </p>
            <ExternalLink href={GETTING_STARTED_URL} onClick={this.handleGuideClick}>
              <img src={screenshot} alt="Product screenshot" className={styles.screenshot} />
            </ExternalLink>
          </div>
        )}
        {false && <ExampleReportsView />}
      </div>
    );
  }
}

export default connect(
  null,
  {push, openedGettingStarted}
)(EmptyFolderView);
