import React from 'react';
import styles from './title.scss';
import {connect} from 'react-redux';
import {connectToTestAndReport} from 'modules/tests/testAndReportSelectors';
import Flex from 'components/layout/flex';
import Help from 'modules/help/containers/help';

export class Title extends React.Component {
  renderReportTitle() {
    let {helpUrl} = this.props.config;
    const reportName = this.props.report.name;
    const productName = this.props.report.product.displayName || this.props.report.product.name;

    return (
      <Flex grow={1} className={styles.reportTitle}>
        <h1>
          {reportName}
          <Help url={helpUrl || this.props.test.helpUrl} className={styles.help} />
        </h1>
      </Flex>
    );
  }

  renderScreenTitle() {
    let {label, beta, withScore, helpUrl} = this.props.config;
    const {
      test: {name}
    } = this.props;

    return (
      <div className={styles.screenTitle}>
        <h1 className={withScore ? styles.withScore : null}>
          {this.props.children}
          {label} {beta && <sup className={styles.beta}>BETA</sup>}
          <Help url={helpUrl || this.props.test.helpUrl} className={styles.help} />
        </h1>
      </div>
    );
  }

  render() {
    const {report} = this.props.config;
    return report ? this.renderReportTitle() : this.renderScreenTitle();
  }
}

export default connect(connectToTestAndReport)(Title);
