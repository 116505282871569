import {put, call, takeLatest} from 'redux-saga/effects';
import {LOGIN_REQUEST, loginFailed, setProspectLoginUrl, setLoggingIn} from 'modules/auth';
import {storeLoginDetailsFor2FA} from 'modules/auth';
import {loginEndpoint, selfEndpoint, twoFactorAuthPath} from 'modules/auth/authUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import handleLoginSuccess from './utils/handleLoginSuccess';
import {push} from 'modules/location';
import {homePath, toolSelectionPath} from 'modules/app/appUtils';
import getErrorMessage from 'api/errors';

export default function* requestLoginSaga() {
  yield takeLatest([LOGIN_REQUEST], requestLogin);
}

// NOTE if `self` is passed, `loginData` will be empty
function* requestLogin({type, isOnTwoFactorPage, ...loginData}) {
  yield processRequest(
    'POST',
    loginEndpoint(),
    {
      success: function*({user, token, redirect, prospectLoginUrl, usesMultipleTools}) {
        if (redirect) {
          // We have been told the login was 200 (good) but we must redirect elsewhere.
          // This could be because they are a recognised Prospect user etc.
          window.location.href = redirect;
          return;
        }
        yield call(handleLoginSuccess, {user, token});

        if (usesMultipleTools) {
          yield put(setProspectLoginUrl(prospectLoginUrl));
          yield put(push(toolSelectionPath, {accountId: user.account.accountId}));
        } else {
          yield put(push(homePath, {accountId: user.account.accountId}));
        }
      },

      error: function*(err) {
        if (err && err.errorCode === 'invalidTwoFactorCode') {
          if (isOnTwoFactorPage) {
            yield put(loginFailed(!err ? null : getErrorMessage(err.errorCode, err)));
          } else {
            yield put(setLoggingIn(false));
            yield put(storeLoginDetailsFor2FA(loginData));
            yield put(push(twoFactorAuthPath));
          }
        } else {
          yield put(loginFailed(!err ? null : getErrorMessage(err.errorCode, err)));
        }
      }
    },
    loginData
  );
}
