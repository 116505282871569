import {take, call, put, takeEvery} from 'redux-saga/effects';
import {INVITE_USERS, addUser} from 'modules/users';
import {processRequest} from 'utils/saga/fetchUtils';
import {triggerInviteUsers, inviteUsersComplete, receiveUsers} from 'modules/users';
import {hideModal} from 'modules/modal';
import getErrorMessage from 'api/errors';

export default function* editEveryUserSaga() {
  yield takeEvery(INVITE_USERS, inviteUsers);
}

function* inviteUsers({type, ...postData}) {
  yield processRequest(
    'POST',
    'users/invite',
    {
      success: function*({users, failedUsers}) {
        yield put(inviteUsersComplete());

        const haveSomeFailed = !!failedUsers.length;

        yield put(receiveUsers(users));

        if (!haveSomeFailed) {
          yield put(hideModal());
          return;
        }

        const errorMessages = {};

        const retriedUsers = failedUsers.map(({userIdx, errorCode, invalidProperty}) => {
          const user = postData.users[userIdx];
          errorMessages[userIdx] = getErrorMessage(errorCode, {
            value: user[invalidProperty]
          });
          return user;
        });

        yield put(hideModal());

        const {users: userProps, ...theRest} = postData;

        // open the modal again with only the failed users
        yield put(
          triggerInviteUsers({
            defaultValue: {...theRest, ...userProps[0]},
            userErrorMessages: errorMessages[0]
          })
        );
      },

      error: function*() {
        yield put(inviteUsersComplete());
      },

      errorMessage: 'Something went wrong'
    },
    postData
  );
}
