import React from 'react';
import styles from './slideBetween.scss';
import RenderSome from 'components/wrappers/renderSome';
import cx from 'classnames';

// Slides between children elements based on the currentIndex prop.
// Slides right if increment and vice-versa
export default class SlideBetween extends React.Component {
  static defaultProps = {
    animate: true
  };

  constructor(props) {
    super(props);
    this.state = {
      // keep track of how many children have been rendered.
      // once we've rendered one, we never want to stop rendering it.
      renderAmount: props.currentIndex + 1
    };
  }

  componentWillReceiveProps({currentIndex}) {
    const amount = currentIndex + 1;
    if (amount >= this.state.renderAmount) {
      this.setState({renderAmount: amount});
    }
  }

  render() {
    const {currentIndex, children, animate, noFit} = this.props;
    const {renderAmount} = this.state;

    const moveStep = 100 / renderAmount;

    const style = {
      transform: `translate3d(-${currentIndex * moveStep}%, 0, 0)`,
      width: `${renderAmount * 100}%`
    };

    return (
      <div className={cx(styles.viewport, {[styles.noFit]: noFit})}>
        <RenderSome
          amount={renderAmount}
          className={cx(styles.mover, {[styles.animate]: animate})}
          style={style}
        >
          {children}
        </RenderSome>
      </div>
    );
  }
}
