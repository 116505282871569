import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import Spinner from 'components/spinner';
import InteractionRoute from './interactionRoute';
import NewInteractionPage from '../pages/newInteractionPage';
import {requestInstructionTypes} from '../actions';
import {interactionsRouteSelector} from '../selectors';

class InteractionsRoute extends React.Component {
  componentDidMount() {
    // Route .../interactions/ has been loaded. Chances are that we want the InstructionTypes entity
    this.props.requestInstructionTypes();
  }

  render() {
    const {match, loaded, loading, error} = this.props;
    const {path} = match;

    if (!loaded || loading) return <Spinner marginTop={100} />;
    if (error) return <UnexpectedError error={error} />;

    return (
      <Switch>
        <Route path={`${path}/new`} component={NewInteractionPage} />
        <Route path={`${path}/:interactionId`} component={InteractionRoute} />
      </Switch>
    );
  }
}

export default connect(
  interactionsRouteSelector,
  {requestInstructionTypes}
)(InteractionsRoute);

function UnexpectedError({error}) {
  return (
    <p style={{color: 'red'}}>
      An unexpected error occurred. {error && error.toString ? error.toString() : error}
    </p>
  );
}
