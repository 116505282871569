import React from 'react';
import Button from 'components/button';
import Flex from 'components/layout/flex';

export default function DashboardRow({children, endRow}) {
  return (
    <Flex wrap="wrap" container align="start" justify={endRow ? 'center' : undefined} bottom="20px">
      {children}
    </Flex>
  );
}
