import Icon from 'components/font-awesome';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './url.scss';
import {niceUrl, isSecure} from 'utils/urlUtils';

export default class Url extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    stopPropagation: PropTypes.bool
  };

  static defaultProps = {
    stopPropagation: true
  };

  renderScheme(url) {
    if (isSecure(url)) {
      return <span className={styles.https}>https://</span>;
    }

    return <span className={styles.http}>http://</span>;
  }

  stopPropagation = e => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  };

  render() {
    const {url, title, className, link, noIcon} = this.props;
    let {label} = this.props;
    let decodedURL;

    try {
      decodedURL = decodeURIComponent(url);
    } catch (err) {
      decodedURL = url;
    }

    label = label || (
      <span>
        {url && this.renderScheme(url)}
        {title || niceUrl(decodedURL)}
      </span>
    );

    const classes = cx(styles.link, className);

    // Plain URLs don't link anywhere, they just show a URL
    if (!link) {
      return (
        <span className={classes} title={url || title}>
          {label}
        </span>
      );
    }

    return (
      <a
        href={url}
        target="_blank"
        onClick={this.stopPropagation}
        className={classes}
        title={url || title}
      >
        {label}{' '}
        {!noIcon && (
          <Icon name="fas fa-external-link" className={styles.icon} title="Open in new window" />
        )}
      </a>
    );
  }
}
