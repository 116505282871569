import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Spinner from 'components/spinner/circularSpinner';
import {attemptConfirmCheckout} from 'modules/confirmCheckout/confirmCheckoutActions';
import {confirmCheckoutPageSelector} from 'modules/confirmCheckout/confirmCheckoutSelectors';
import Status from 'components/status';
import styles from './confirmCheckoutPage.scss';
import _ from 'lodash';
import {replace} from 'modules/location';

export class ConfirmCheckoutPage extends React.Component {
  componentWillMount() {
    const {checkoutRequestId} = this.props.match.params;

    if (checkoutRequestId !== 'invoice') {
      this.props.attemptConfirmCheckout(checkoutRequestId);
    }

    this.hasSentToGTM = false;
    this.hasResponseFromGTM = false;
  }

  componentDidUpdate() {
    if (!window.gtag) {
      const shouldRedirectHome = this.shouldRedirect(this.props);
      if (shouldRedirectHome) {
        this.props.replace('/');
      }
    } else {
      this.updateGTM();
    }
  }

  updateGTMComplete = () => {
    const {checkoutResponse} = this.props;

    this.hasResponseFromGTM = true;
    localStorage.setItem('gtmQuote', checkoutResponse.quoteId);

    const shouldRedirectHome = this.shouldRedirect(this.props);
    if (shouldRedirectHome) {
      this.props.replace('/');
    }
  };

  updateGTM() {
    if (this.hasSentToGTM) return; // waiting for eventCallback

    const {checkoutResponse} = this.props;
    if (!_.size(checkoutResponse)) return;

    if (localStorage.getItem('gtmQuote') == checkoutResponse.quoteId) return; // we've already sent this

    this.hasSentToGTM = true;

    // pass checkout data to GoogleTagManager
    window.dataLayer.push({
      // event details
      event: 'checkout',
      eventCallback: this.updateGTMComplete,

      // transaction details
      transactionId: checkoutResponse.quoteId.toString(),
      transactionTotal: checkoutResponse.quote.amountSubtotal,
      currency: checkoutResponse.quote.currency.toUpperCase(),
      transactionProducts: [
        {
          sku: checkoutResponse.quote.planId,
          name: checkoutResponse.quote.title,
          price: checkoutResponse.quote.amountSubtotal,
          quantity: 1
        }
      ]
    });
  }

  shouldRedirect(props) {
    if (props.match.params.checkoutRequestId === 'invoice' && props.isLoggedIn) {
      // If you are an invoice and logged in, just go into the tool.
      return true;
    }

    if (
      _.size(props.checkoutResponse) > 0 &&
      props.checkoutResponse.state === 'success' &&
      props.isLoggedIn
    ) {
      // If you are already logged in, just take them back to there account.
      return true;
    }

    return false;
  }

  render() {
    const {match, loadingError, isLoading, isLoggedIn, checkoutResponse} = this.props;

    // If you pay by invoice, just auto confirm it.
    if (match.params.checkoutRequestId === 'invoice') {
      return (
        <div className={styles.wrapper}>
          <Status appearance="good">
            Payment by invoice confirmed. You will receive an email shortly to begin the onboarding
            process.
          </Status>
        </div>
      );
    }

    // If we received an error message from the back-end API.
    if (loadingError) {
      return (
        <div className={styles.wrapper}>
          <Status appearance="bad">{loadingError}</Status>
        </div>
      );
    }

    // If the payment failed for some reason (but the API itself was OK)
    // @todo: Put the reason here for failure!
    if (checkoutResponse && checkoutResponse.state === 'failed') {
      return (
        <div className={styles.wrapper}>
          <Status appearance="bad">
            This transaction failed, please contact support or try again later.
          </Status>
        </div>
      );
    }

    // If we are pending (likely waiting for 2FA from them) then just notify them that we could not confirm it right now.
    if (checkoutResponse && checkoutResponse.state === 'pending') {
      return (
        <div className={styles.wrapper}>
          <Status appearance="bad">
            This transaction could not be confirmed right now, please try again shortly.
          </Status>
        </div>
      );
    }

    // If we are logged in or, the API is being called, show the spinner.
    // We show spinner if logged in as we SHOULD be auto redirected if you are logged in, or notified of failure above.
    if (isLoading || isLoggedIn) {
      return (
        <div className={styles.wrapper}>
          <div style={{marginTop: '30px'}}>
            <Spinner centered />
          </div>
        </div>
      );
    }

    // If we have an accountId provided, tell them to login.
    if (
      checkoutResponse &&
      checkoutResponse.quoteAcceptRequest &&
      checkoutResponse.quoteAcceptRequest.accountId
    ) {
      return (
        <div className={styles.wrapper}>
          <Status appearance="good">
            Your card payment has been confirmed.{' '}
            <a href="/">Click here to login to your account</a>
          </Status>
        </div>
      );
    }

    // If we get here, we got a good payment, BUT you aren't logged in. So just let them know to check emails.
    return (
      <div className={styles.wrapper}>
        <Status appearance="good">
          Your card payment has been confirmed. Check your emails to get started.
        </Status>
      </div>
    );
  }
}

export default connect(
  confirmCheckoutPageSelector,
  {attemptConfirmCheckout, replace}
)(ConfirmCheckoutPage);
