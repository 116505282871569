import React from 'react';
import Slider from 'rc-slider';
import PropTypes from 'prop-types';
import styles from './sliderInput.scss';

const {Handle} = Slider;

const CustomHandle = props => {
  const {value, dragging, index, ...rest} = props;
  return (
    <div className={styles.customHandle}>
      <Handle value={value} {...rest} />
    </div>
  );
};

export default class SliderInput extends React.Component {
  static defaultProps = {
    value: 100,
    min: 100,
    max: 50000,
    step: 100,
    trackStyle: {background: '#7D6AF4', height: 10},
    railStyle: {height: 10, backgroundColor: '#CFD5E5'},
    handleStyle: {
      borderColor: '#533be2',
      backgroundColor: '#533be2',
      height: 36,
      width: 36,
      marginLeft: -18,
      marginTop: -12
    }
  };

  static propTypes = {
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    trackStyle: PropTypes.object,
    railStyle: PropTypes.object,
    handleStyle: PropTypes.object
  };

  componentWillMount() {
    const {onChange} = this.props;
    onChange && onChange(this.props.value);
  }

  handleChange = sliderValue => {
    const {onChange} = this.props;
    onChange && onChange(sliderValue);
  };

  render() {
    const {value, min, max, step, trackStyle, railStyle} = this.props;

    return (
      <Slider
        defaultValue={value}
        min={min}
        max={max}
        step={step}
        onChange={this.handleChange}
        trackStyle={trackStyle}
        railStyle={railStyle}
        handle={CustomHandle}
      />
    );
  }
}
