import React from 'react';
import {connect} from 'react-redux';
import {inspectorSidebarSelector} from 'modules/inspector/inspectorSelectors';
import InspectorCategorySidebar from 'modules/inspector/containers/inspectorCategorySidebar';
import InspectorActionSidebar from 'modules/inspector/containers/inspectorActionSidebar';
import Spinner from 'components/spinner';
import {AnimatePresence} from 'framer-motion';
import SlideHorizontal from 'components/animation/slideHorizontal';
import usePrevious from 'hooks/usePrevious';
import FixedTopLevelNav from './fixedTopLevelNav';

function InspectorSidebar(props) {
  const {isLoading, categories, actionId, categoryId} = props;
  const lastCategoryId = usePrevious(categoryId);
  // isChild is true if categoryId is a child of lastCategoryId
  const isChild = isCategoryChild(categories, lastCategoryId, categoryId);

  return (
    <FixedTopLevelNav actionId={actionId} categoryId={categoryId}>
      <AnimatePresence>
        {isLoading ? (
          <SlideHorizontal id="loading">
            <Spinner marginTop={100} />
          </SlideHorizontal>
        ) : actionId ? (
          <SlideHorizontal id={'action_' + actionId} direction="rtl">
            <InspectorActionSidebar />
          </SlideHorizontal>
        ) : (
          <SlideHorizontal id={'category_' + categoryId} direction={isChild ? 'rtl' : 'ltr'}>
            <InspectorCategorySidebar />
          </SlideHorizontal>
        )}
      </AnimatePresence>
    </FixedTopLevelNav>
  );
}

function isCategoryChild(categories, lastCategoryId = '', thisCategoryId = '') {
  // if last was top level parent, then thisCategoryId must be a child
  if (lastCategoryId === '' && thisCategoryId.length) return true;
  if (!lastCategoryId || lastCategoryId === thisCategoryId) return false;

  let parentCategoryId;
  let recursiveCategoryId = thisCategoryId;

  do {
    parentCategoryId = categories.getIn([recursiveCategoryId, 'parentCategoryId']);

    if (parentCategoryId === lastCategoryId) {
      return true;
    }
    recursiveCategoryId = parentCategoryId;
  } while (parentCategoryId); // while we haven't reached the top level category

  return false;
}

export default connect(inspectorSidebarSelector)(InspectorSidebar);
