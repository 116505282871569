import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import styles from './imageModal.scss';

export default class ImageModal extends React.Component {
  render() {
    const {src, alt = ''} = this.props;

    return (
      <ModalContent>
        <img src={src} alt={alt} className={styles.image} />
      </ModalContent>
    );
  }
}
