import React from 'react';
import {connect} from 'react-redux';
import {sendUnlockEmail} from 'modules/passwords';

export class LockedUserMessage extends React.Component {
  handleClick = () => {
    const {sendUnlockEmail, context} = this.props;

    sendUnlockEmail({email: context.email});
  };

  render() {
    return (
      <span>
        Your account is locked from too many unsucessful attempts. An email has been sent to your
        email address to unlock your account or reset your password. Click{' '}
        <a onClick={this.handleClick}>here</a> to send another email
      </span>
    );
  }
}

export default connect(
  null,
  {sendUnlockEmail}
)(LockedUserMessage);
