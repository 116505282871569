import React from 'react';
import InspectorActionRow from './inspectorActionRow';
import Button from 'components/button';
import styles from './inspectorStyle.scss';

export default function ActionsSummary({actions, actionsCount, maxActions}) {
  const [showActionCount, setShowActionCount] = React.useState(maxActions);
  const isOpen = actionsCount === showActionCount;

  return (
    <div>
      <div className={styles.groupedActions}>
        {actions.slice(0, showActionCount).map(action => (
          <InspectorActionRow key={action.get('actionId')} action={action} />
        ))}
      </div>

      {actionsCount > maxActions ? (
        !isOpen ? (
          <Button onClick={() => setShowActionCount(actionsCount)} className={styles.moreLink}>
            More
          </Button>
        ) : (
          <Button onClick={() => setShowActionCount(maxActions)} className={styles.moreLink}>
            Less
          </Button>
        )
      ) : null}
    </div>
  );
}
