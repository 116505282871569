import React from 'react';
import Code from 'components/code';
import Panel from 'components/panel';
import Button from 'components/button';
import {formatHtml} from 'utils/formatUtils';

export default function InteractionComputedSource({source}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div key="computedSource" style={{marginBottom: '20px'}}>
      <Panel>
        <h3 style={{margin: 0}}>
          HTML{' '}
          <Button
            tiny
            icon={isOpen ? 'minus' : 'plus'}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </h3>
      </Panel>

      <Panel hideBorderBottom={!isOpen} hideBorderTop hidePadding>
        <Code language="html" isOpen={isOpen}>
          {formatHtml(source)}
        </Code>
      </Panel>
    </div>
  );
}
