import PropTypes from 'prop-types';
import React from 'react';
import Arrow from 'components/decorators/arrow';

class Delta extends React.Component {
  render() {
    const {data} = this.props;

    if (!data) return <span />;

    const isPositive = data > 0;

    return (
      <div>
        {isPositive ? <Arrow up /> : <Arrow down />}
        <span>&nbsp;</span>
        {Math.abs(data)}
      </div>
    );
  }
}

Delta.propTypes = {};

export default Delta;
