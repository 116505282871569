import React from 'react';
import Pill from 'components/pill';
import styles from '../timeline.scss';

function SpellingPointItem({storyHash, storyPointId, time, props: {word, pagesCount}}) {
  return (
    <li className={styles.point}>
      {word}
      <Pill>{pagesCount}</Pill>
    </li>
  );
}

export default SpellingPointItem;
