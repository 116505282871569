import React from 'react';
import {connect} from 'react-redux';
import {cancelMission} from 'modules/missions';
import {createSelector} from 'reselect';
import {missionsSelector, cancelledMissionSelector} from 'modules/missions/missionSelectors';
import {archiveSelector} from 'modules/archives/archiveSelectors';
import {reportMissionsSelector} from 'modules/reports/reportSelectors';
import cx from 'classnames';
import upgraderStyles from 'components/layoutComponents/upgrader/upgrader.scss';

const progressSelector = createSelector(
  missionsSelector,
  reportMissionsSelector,
  archiveSelector,
  cancelledMissionSelector,
  (missions, {missionIds}, archive, {lastCancelled, currentlyCancelling}) => {
    const missionId = missionIds[0];
    const mission =
      missionId == lastCancelled || missionId == currentlyCancelling
        ? undefined
        : missions.get(missionId);

    return {
      archive,
      mission,
      isRetesting: !!mission
    };
  }
);

export class reportRetestProgressStrip extends React.Component {
  render() {
    const {archive, mission, isRetesting, cancelMission} = this.props;

    if (archive.archiveId !== 'live' || !isRetesting || !mission) return null;

    const percentDone =
      typeof mission.get('progressDone') == 'number' && mission.get('progressDone')
        ? Math.floor((mission.get('progressDone') / mission.get('progressTotal')) * 1000) / 10
        : null;

    return (
      <div className={cx(upgraderStyles.banner, upgraderStyles.noHover, upgraderStyles.middle)}>
        This report is running and results shown may change
        {percentDone !== null && <span>({percentDone}% done)</span>}
        <span
          className={upgraderStyles.clickable}
          onClick={() => cancelMission(mission.get('missionId'))}
        >
          Cancel report
        </span>
      </div>
    );
  }
}

export default connect(
  progressSelector,
  {cancelMission}
)(reportRetestProgressStrip);
