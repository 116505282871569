import _ from 'lodash';
import React from 'react';
import {compose} from 'redux';
import {connectContext} from 'modules/app/appUtils';
import styles from './simpleStat.scss';
import Spinner from 'components/spinner';
import NumberTicker from 'components/animation/numberTicker';
import {isPrintMode} from 'modules/filters/filterUtils';
import {choosePluralVersion, interpolate} from 'utils/interpolationUtils';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class SimpleStat extends React.Component {
  renderValue(value) {
    const {filter, config} = this.props;

    if (value == null) {
      return <Spinner centered />;
    }

    const {plural, singular, zero} = config;
    const string = choosePluralVersion(value, singular, plural, zero);
    const context = {value};

    // TODO: find somewhere better for this.
    if (
      // if print mode, don't animate.
      isPrintMode(filter) ||
      string.indexOf('ordinal') > -1 ||
      _.isString(value)
    ) {
      return interpolate(string, context);
    }

    return (
      <span>
        {this.props.screenSizes.tablet ? (
          interpolate(string, context)
        ) : (
          <NumberTicker context={context} string={string} />
        )}
      </span>
    );
  }

  // `props.result` can be a number or an object, so we make it consistent
  getNormalizedResult() {
    const {result, config} = this.props;
    const {property} = config;

    if (typeof result === 'number') {
      return {[Math.floor(Date.now() / 1000)]: result};
    }

    if (result && result.hasOwnProperty('numRows')) {
      return {[Math.floor(Date.now() / 1000)]: result.numRows};
    }

    if (result && property && result.hasOwnProperty(property)) {
      return result[property].values;
    }

    return result;
  }

  render() {
    const {config} = this.props;
    const {label, regular} = config;

    const series = this.getNormalizedResult();

    if (!series) {
      return (
        <div className={styles.stat}>
          <div className={styles.label}>{label}</div>
          <div className={styles.content}>{this.props.children}</div>
        </div>
      );
    }

    const timestamps = Object.keys(series).sort();
    const lastValue = series[timestamps[timestamps.length - 1]];

    return (
      <div className={regular ? styles.regularValue : styles.bigValue}>
        {this.renderValue(lastValue)}
      </div>
    );
  }
}

SimpleStat.handlesLoading = true;

export default compose(
  connect(
    null,
    {push}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(SimpleStat);
