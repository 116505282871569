import _ from 'lodash';
import {IntercomAPI} from 'react-intercom';

// TODO: merge into the analytics module.
// (For now, analytics just calls trackEvent internally)
// but it's confusing to have 2 modules which are so similar.
// all calls to trackEvent should be replaced with ...analytics().
// and analytics should deal with posting to intercom

// Note: this isn't an action.
/* @deprecated */
export function trackEvent(summary, optionalParams = {}, delay = false) {
  if (__DEV__) {
    // we don't want to spam nonsense data for developers.
    return;
  }

  // Sometimes an event may wish to delay. For example, login. Sometimes we delay to give the intercom API time to load properly on route change.
  if (delay === true) {
    _.delay(() => trackEvent(summary, optionalParams), 1000);
    return;
  }

  if (!_.has(optionalParams, 'location')) {
    optionalParams.location = window.location.pathname;
  }
  sendViaIntercom(summary, optionalParams);
}

function sendViaIntercom(summary, optionalParams) {
  IntercomAPI('trackEvent', summary, optionalParams);
}
