import React from 'react';
import {connect} from 'react-redux';
import {trackEvent} from 'modules/tracking';
import CMS from '../../components/reportSettings/cmsConfig';
import {updateWebsite} from 'modules/tests';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';
import {viewReportCMSSettingsPage, clearCMSConfig} from 'modules/reports';

export class ReportSettingsCMSPage extends React.Component {
  componentWillMount() {
    this.props.viewReportCMSSettingsPage(this.props.match.params.reportId);
  }

  componentWillUnmount() {
    this.props.clearCMSConfig();
  }

  componentDidMount() {
    trackEvent('Viewed CMS config page', {
      forReport: this.props.match.params.reportId,
      fromFile: 'reportSettingsCMSPage.js'
    });
  }

  render() {
    return <CMS {...this.props} name="settingsCMS" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateWebsite, viewReportCMSSettingsPage, clearCMSConfig}
)(ReportSettingsCMSPage);
