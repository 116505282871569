import React from 'react';
import {Form, Field} from 'modules/form/components';
import euCountries from 'constants/euCountryCodes.json';
import styles from './quoteDataCollector.scss';
import _ from 'lodash';
import {connect} from 'react-redux';
import Money from 'components/money';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import {dataCollectorSelector} from 'modules/quote/quoteSelectors';
import emailValidator from 'email-validator';
import ExternalLink from 'modules/location/externalLink';

export class QuoteDataCollector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentType: this.isStripeAllowed(props) ? 'stripe' : 'invoice',
      disableCheckout: false
    };
  }

  isStripeAllowed(props) {
    if (props.quoteTable && props.quoteTable.currency !== 'usd') {
      return false;
    }

    return true;
  }

  handleSubmit = (data, isValid) => {
    if (!isValid) {
      return;
    }

    this.setState({disableCheckout: true});
    this.props.checkout(data, resp => {
      // If we had some form of checkout error, this function would be called.
      this.setState({disableCheckout: false});
    });
  };

  debounceRecalcRequired = _.debounce(data => {
    this.props.onRecalcRequired(data);
  }, 500);

  handleEmptyField = val => {
    if (!val || !val.length) {
      this.setState({disableCheckout: true});
    } else if (this.state.disableCheckout) {
      this.setState({disableCheckout: false});
    }
  };

  handleFormChange = (field, value) => {
    this.setState({[field]: value});

    if (field === 'vatNumber') {
      this.debounceRecalcRequired({
        vatNumber: value,
        country: this.form.state.formData.addressCountry
          ? this.form.state.formData.addressCountry
          : 'US'
      });
    }

    if (field === 'addressCountry') {
      this.debounceRecalcRequired({
        vatNumber: this.form.state.formData.vatNumber ? this.form.state.formData.vatNumber : '',
        country: value
      });
    }
  };

  renderStripeFields = () => {
    const fields = [
      <Field
        key="organizationName"
        label="Organization name"
        name="organizationName"
        type="text"
        placeholder="Awesome Company Ltd"
        showIf={data => !this.props.loggedInAccount}
        required
      />,
      <Field
        key="contactName"
        label="Your name"
        name="contactName"
        type="text"
        placeholder="David Smith"
        required
        onChange={this.handleEmptyField}
      />,
      <Field
        key="contactEmail"
        help="We will email access details to this address."
        label="Your email"
        name="contactEmail"
        type="email"
        placeholder="mycontactemail@silktide.com"
        required
        onChange={this.handleEmptyField}
      />,
      <Field
        key="addressCountry"
        help="We need this for tax purposes."
        label="Country"
        name="addressCountry"
        type="country"
        required
        onChange={this.handleEmptyField}
      />,
      <Field
        key="vatNumber"
        help="If you have a VAT number enter it here."
        label="VAT number (optional)"
        name="vatNumber"
        type="text"
        showIf={data => euCountries.includes(data.addressCountry)}
      />
    ];
    return fields;
  };

  renderInvoiceFields = () => {
    const fields = [
      <h2 key="bd_1">Your details</h2>,
      <Field
        key="organizationName"
        label="Organization name"
        name="organizationName"
        type="text"
        placeholder="Awesome Company Ltd"
        required
        showIf={data => !this.props.loggedInAccount}
      />,
      <Field
        key="contactName"
        label="Your name"
        name="contactName"
        type="text"
        required
        placeholder="David Smith"
      />,
      <Field
        key="contactEmail"
        help="We will email access details to this address."
        label="Your email"
        name="contactEmail"
        type="email"
        placeholder="mycontactemail@silktide.com"
        required
      />,
      <hr key="hr_1" />,
      <h2 key="bd_2">Billing details</h2>,
      <Field
        key="billingEmail"
        help="We will email invoices to this address."
        label="Billing email"
        name="billingEmail"
        type="email"
        placeholder="mybillingemail@silktide.com"
        required
      />,
      <Field
        key="addressLine1"
        label="Address line 1"
        name="addressLine1"
        type="text"
        placeholder="19 Brunel Parkway"
        required
      />,
      <Field
        key="addressLine2"
        label="Address line 2"
        name="addressLine2"
        type="text"
        placeholder="Pride Park"
      />,
      <Field
        key="addressCity"
        label="City"
        name="addressCity"
        type="text"
        required
        placeholder="Derby"
      />,
      <Field
        key="addressState"
        label="State / Region"
        name="addressState"
        type="text"
        required
        placeholder="Derbyshire"
      />,
      <Field
        key="addressPostcode"
        label="ZIP / Postcode"
        name="addressPostcode"
        type="text"
        placeholder="DE24 8HR"
        required
      />,
      <Field
        key="addressCountry"
        help="We need this for tax purposes."
        label="Country"
        name="addressCountry"
        type="country"
        required
      />,
      <hr key="hr_2" />,
      <h2 key="bd_3">Other details</h2>,
      <Field
        key="vatNumber"
        help="If you have a VAT number enter it here."
        label="VAT number (optional)"
        name="vatNumber"
        type="text"
        showIf={data => euCountries.includes(data.addressCountry)}
      />,
      <Field
        key="purchaseOrderNumber"
        help="If you need a Purchase Order number on your invoices, enter it here."
        label="Purchase Order number (optional)"
        name="purchaseOrderNumber"
        type="text"
      />
    ];
    return fields;
  };

  getDefaults = () => {
    const contactEmail =
      this.getDefaultFieldValue('email') || this.getDefaultFieldValue('contactEmail');
    const contactName =
      this.getDefaultFieldValue('name') || this.getDefaultFieldValue('contactName');
    const billingEmail =
      this.getDefaultFieldValue('email') || this.getDefaultFieldValue('billingEmail');

    return {
      paymentType: this.state.paymentType,
      organizationName: this.getDefaultFieldValue('organizationName'),
      contactName: contactName,
      contactEmail: contactEmail,
      billingEmail: billingEmail,
      addressLine1: this.getDefaultFieldValue('addressLine1'),
      addressLine2: this.getDefaultFieldValue('addressLine2'),
      addressCity: this.getDefaultFieldValue('addressCity'),
      addressState: this.getDefaultFieldValue('addressState'),
      addressPostcode: this.getDefaultFieldValue('addressPostcode'),
      addressCountry: this.getDefaultFieldValue('addressCountry'),
      vatNumber: this.getDefaultFieldValue('vatNumber'),
      accountId: this.getDefaultFieldValue('accountId')
    };
  };

  getDefaultFieldValue(field) {
    const {quoteAccount, loggedInAccount, loggedInUser} = this.props;

    if (loggedInUser && loggedInUser.accountId === quoteAccount.accountId && loggedInUser[field]) {
      return loggedInUser[field];
    }

    if (
      loggedInAccount &&
      loggedInAccount.accountId === quoteAccount.accountId &&
      loggedInAccount[field]
    ) {
      return loggedInAccount[field];
    }

    if (quoteAccount && quoteAccount[field]) {
      return quoteAccount[field];
    }

    // if we are not any set in the logged in account or the quote itself, set to this.
    if (field === 'addressCountry') {
      return 'US';
    }

    return '';
  }

  renderPayAndSubmit = () => {
    const {quote, quoteTable, isRecalculating, recalcError, onCancel} = this.props;
    const {paymentType} = this.state;

    if (isRecalculating) {
      return <Spinner centered />;
    }

    if (recalcError) {
      return <p>An error occured trying to reclaute the price. Please try again later.</p>;
    }

    const billEveryMonths = quote.billEveryMonths;
    const everyString =
      billEveryMonths === 1
        ? 'every month'
        : billEveryMonths === 12
        ? 'every year'
        : 'every ' + billEveryMonths + ' months';

    let submitEnabled = true;

    if (this.form) {
      const {contactName, contactEmail, addressCountry} = this.form.state.formData;

      submitEnabled = contactName && addressCountry && emailValidator.validate(contactEmail);
    }

    return (
      <div>
        <h2>Confirm &amp; pay</h2>
        <p>
          You will be charged{' '}
          <strong>
            <Money value={quoteTable.amountTotal} decimals={2} currency={quoteTable.currency} />
          </strong>{' '}
          {quoteTable.tax && quoteTable.tax.percentage > 0
            ? `(including ${quoteTable.tax.percentage}% ${quoteTable.tax.name})`
            : ''}{' '}
          {everyString}.
        </p>
        <p>
          By continuing you agree to our{' '}
          <ExternalLink
            href={
              paymentType === 'stripe'
                ? 'https://silktide.com/terms-conditions'
                : 'https://silktide.com/terms-conditions/enterprise'
            }
          >
            Terms &amp; Conditions.
          </ExternalLink>
        </p>

        <div style={{margin: '30px 0 0'}} className={styles.submitControls}>
          <Button
            icon={'fal fa-check'}
            iconSide="left"
            disabled={!submitEnabled}
            onClick={() => this.form.submit()}
          >
            {paymentType === 'stripe' ? 'Continue to payment' : 'Place order'}
          </Button>
          {onCancel && (
            <Button
              icon={'fal fa-times'}
              iconSide="left"
              wire
              style={{marginLeft: '20px'}}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    );
  };

  render() {
    let {title, quoteTable, isEnterprise} = this.props;
    const {paymentType} = this.state;

    return (
      <div className={styles.wrapper}>
        <h1>{title}</h1>

        <Form
          className={styles.form}
          autoComplete="off"
          defaultValue={this.getDefaults()}
          ref={input => (this.form = input)}
          onSubmit={this.handleSubmit}
          onChange={this.handleFormChange}
          disableValidSubmit
        >
          <div className={styles.fieldCentralWrapper}>
            {isEnterprise && (
              <Field
                label="How would you like to pay?"
                name="paymentType"
                type="paymentScope"
                allowInvoice={isEnterprise}
                allowStripe={quoteTable && quoteTable.currency === 'usd'}
                className={styles.paymentMethod}
                required
              />
            )}

            <br />

            {paymentType === 'invoice' && this.renderInvoiceFields()}
            {paymentType === 'stripe' && this.renderStripeFields()}
          </div>

          <hr />

          <div style={{textAlign: 'center'}}>{this.renderPayAndSubmit()}</div>
        </Form>
      </div>
    );
  }
}

export default connect(
  dataCollectorSelector,
  {}
)(QuoteDataCollector);
