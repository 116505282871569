import React from 'react';
import {connect} from 'react-redux';
import {
  fetchQuote,
  rejectQuote,
  refetchQuoteWithPaymentOptions,
  acceptQuote
} from 'modules/quote/quoteActions';
import Spinner from 'components/spinner/circularSpinner';
import {quoteViewPageSelector} from 'modules/quote/quoteSelectors';
import Status from 'components/status';
import QuoteActionsBar from '../components/quoteActionsBar';
import QuotePresenter from '../components/quotePresenter';
import PagePresenter from '../components/pagePresenter';
import styles from './quoteViewPage.scss';
import QuoteDataCollector from '../components/quoteDataCollector';
import {PROPOSAL_PAGES} from '../constants';

export class QuoteViewPage extends React.Component {
  state = {showDataCollection: false};

  componentWillMount() {
    const {quoteKey, quoteId} = this.props.match.params;
    this.props.fetchQuote(quoteId, quoteKey);
  }

  recalculateQuote = paymentOptions => {
    const {quoteKey, quoteId} = this.props.match.params;
    this.props.refetchQuoteWithPaymentOptions(quoteId, quoteKey, paymentOptions);
  };

  acceptQuote = (formData, failCallback) => {
    const {match} = this.props;
    const {quoteKey, quoteId} = match.params;

    this.props.acceptQuote(quoteId, quoteKey, formData, failCallback);
  };

  renderDataCollection = () => {
    if (!this.state.showDataCollection) {
      return null;
    }

    const {quote, isRecalculating, recalcError} = this.props;

    return (
      <div className={styles.dataWrapper}>
        <QuoteDataCollector
          title={'Accept proposal'}
          quoteTable={quote.quoteTable}
          quoteAccount={quote.account}
          quote={quote.quote}
          checkout={this.acceptQuote}
          isRecalculating={isRecalculating}
          recalcError={recalcError}
          isEnterprise={true}
          onRecalcRequired={this.recalculateQuote}
          onCancel={() => this.setState({showDataCollection: false})}
        />
      </div>
    );
  };

  renderQuote = () => {
    if (this.state.showDataCollection) {
      return null;
    }

    const {quote, match, rejectQuote} = this.props;
    const {proposalSectionIds} = quote.quote;
    const {quoteId} = match.params;

    // we also want the features page to show
    // if the user has selected platform overview
    const sectionIds = [...proposalSectionIds];

    const quoteSections = sectionIds.map(id => {
      if (PROPOSAL_PAGES.includes(id)) {
        return <PagePresenter key={id} id={id} quote={{id: quoteId, ...quote}} />;
      }
    });

    // If we display the quote reference etc on our cover page, we don't
    // need to duplicate it later in our QuotePresenter
    const hasCoverPage = sectionIds.includes('SilktideCover');

    return [
      <QuoteActionsBar
        key="actions-bar"
        {...match.params}
        onRejectQuote={rejectQuote}
        onAcceptQuote={() => this.setState({showDataCollection: true})}
        quote={quote}
      />,
      ...quoteSections,
      <QuotePresenter
        key="quote"
        {...quote}
        quoteId={match.params.quoteId}
        standalone={!hasCoverPage}
      />
    ];
  };

  getQuoteStateMessage = state => {
    switch (state) {
      case 'rejected':
        return 'This quote has been rejected.';
      case 'accepted':
        return 'This quote has already been accepted.';
      case 'expired':
        return 'This quote has expired.';
      case 'removed':
        return 'This quote has been removed.';
      default:
        return 'This quote is unavailable.';
    }
  };

  render() {
    const {isLoading, loadingError, quote} = this.props;

    if (loadingError) {
      return (
        <div className={styles.wrapper}>
          <Status appearance="bad">
            The quote you are looking for does not exist, please contact your sales representative.
          </Status>
        </div>
      );
    }

    if (isLoading || !quote.quote) {
      return (
        <div className={styles.wrapper}>
          <div style={{marginTop: '30px'}}>
            <Spinner centered />
          </div>
        </div>
      );
    }

    if (quote.quote && quote.quote.state !== 'published') {
      return (
        <div className={styles.wrapper}>
          <Status appearance={quote.quote.state === 'accepted' ? 'good' : 'bad'}>
            {this.getQuoteStateMessage(quote.quote.state)}
          </Status>
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        {this.renderQuote()}
        {this.renderDataCollection()}
      </div>
    );
  }
}

export default connect(
  quoteViewPageSelector,
  {fetchQuote, rejectQuote, refetchQuoteWithPaymentOptions, acceptQuote}
)(QuoteViewPage);
