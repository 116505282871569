import {createSelector} from 'reselect';
import {userSelector} from 'modules/auth/authSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {currentAccountTypeSelector} from 'modules/accountTypes/accountTypeSelectors';

export const gandalfSelector = createSelector(
  userSelector,
  user => ({user})
);

export const reportGandalfSelector = createSelector(
  (state, props) => props.report,
  reportSelector,
  userSelector,
  (reportFromProps, report, user) => ({
    report: reportFromProps || report,
    isVirtual: user && !!user.get('virtualType')
  })
);

export const accountTypeGandalfSelector = createSelector(
  currentAccountTypeSelector,
  accountType => ({accountType: accountType.toJS()})
);
