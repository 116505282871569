exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3pXNO92N_pb2xT7PBCmOqV{padding:40px 100px;background:#fff;border:1px solid #ddd;text-align:center}._3pXNO92N_pb2xT7PBCmOqV h3{margin:0 auto;color:#df1616;font-weight:bold}._28qENlBcBaegvinNONlTj2{margin:20px 0 0 0}.ucZZcWuAImWgSI7U65Nli{position:relative;top:3px}._3low12pRTnu3tm7mOmShem{margin-top:10px;margin-bottom:0;color:#444}@media(max-width: 768px){._3pXNO92N_pb2xT7PBCmOqV{padding:30px}}", ""]);

// exports
exports.locals = {
	"error": "_3pXNO92N_pb2xT7PBCmOqV",
	"actions": "_28qENlBcBaegvinNONlTj2",
	"icon": "ucZZcWuAImWgSI7U65Nli",
	"description": "_3low12pRTnu3tm7mOmShem"
};