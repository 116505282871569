import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {useForm} from 'modules/form/hooks/useForm';
import {fetchReports} from 'modules/reports';
import PolicyRulesForm from 'modules/policies/containers/policyRulesForm';
import PolicySettingsForm from 'modules/policies/containers/policySettingsForm';
import {scopeOptionsSelector} from 'modules/policies/selectors';
import SavePanel from 'modules/form/components/savePanel';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';

function PolicyForm({fetchReports, onClickSubmit, onClickCancel, policy, query}) {
  const initialValue = policy ? policy : getNewPolicy(query);

  if (policy && policy.reports) {
    initialValue.applyToReports = policy.reports;
  }

  const {rules, ...data} = initialValue;
  const [settingsForm] = useForm(data);
  const [rulesForm] = useForm(rules);

  React.useEffect(() => {
    fetchReports(true, ['name', 'reportId']);
  }, []);

  return (
    <div>
      <PolicyRulesForm
        form={rulesForm}
        policyId={policy ? policy.policyId : 'new'}
        scope={policy ? policy.scope : query.scope ? query.scope : 'page'}
        ruleOperator={settingsForm.fields.ruleOperator.getValue()}
      />

      <div
        style={{
          backgroundColor: '#e2e4e3',
          margin: '0 -20px',
          borderTop: '1px solid #bbb',
          padding: '20px',
          position: 'relative'
        }}
      >
        <h3>Policy settings</h3>
        <PolicySettingsForm form={settingsForm} />
      </div>

      <div
        style={{
          background: '#e2e4e3',
          height: '100%',
          width: '100%',
          position: 'absolute',
          left: '0'
        }}
      ></div>

      <SavePanel
        floating
        isLoading={false}
        onCancel={onClickCancel}
        onSubmit={() => {
          if (settingsForm.attemptSubmit() && rulesForm.attemptSubmit()) {
            const formData = settingsForm.getValue();
            const ruleData = rulesForm.getValue();

            formData.reports = formData.applyToAllReports ? [] : formData.applyToReports;
            onClickSubmit({...formData, rules: ruleData});
          }
        }}
        submitLabel="Save"
        floatMargin={'65px'}
        isValid
        // isValid={!settingsForm.hasError() && !rulesForm.hasError()}
        // isValid={!policy ? settingsForm.hasChanged() || rulesForm.hasChanged() : true}
        show
      />
    </div>
  );
}

export default connect(
  createStructuredSelector({
    scopeOptions: scopeOptionsSelector
  }),
  (dispatch, props) => ({
    fetchReports: (...args) => {
      dispatch(fetchReports(...args));
    },

    onClickCancel: () => {
      dispatch(push(testPath, {}, ['reportId', 'testId']));
    },

    onClickSubmit: data => {
      props.onValidSubmit(data);
    }
  })
)(PolicyForm);

function getNewPolicy(query) {
  return {
    name: '',
    description: '',
    scope: 'page',
    applyToReports: [],
    isEnabled: true,
    ruleOperator: 'and',
    responsibility: '',
    difficulty: 3,
    priority: 3,
    rules: [],
    ...query,
    applyToAllReports: query.applyToAllReports === 'true'
  };
}
