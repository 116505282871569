import {REQUEST_EDIT_PAGE} from 'modules/reports/reportConstants';
import fetch from 'utils/saga/fetch';
import {editPageEndpoint} from 'modules/reports/reportUtils';
import {call, put, takeEvery} from 'redux-saga/effects';
import {openWindow} from 'utils/navigationUtils';
import {addMessage} from 'modules/alerts';
import {processResponse} from 'utils/saga/fetchUtils';

// Gets CMS edit url from api then redirect to it.
export default function* editPageSaga() {
  yield call(takeEvery, REQUEST_EDIT_PAGE, editPage);
}

export function* editPage({reportId, pageHash}) {
  const res = yield call(fetch, editPageEndpoint({reportId, pageHash}));

  yield* processResponse(res, {
    success: editPageSuccess
  });
}

export function* editPageSuccess({isSupported, url}) {
  if (isSupported && url) {
    return yield call(openWindow, url);
  }

  yield put(addMessage('warning', 'We do not currently support your CMS'));
}
