exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1k897KJ6aPM24Sw0kZvr5a{background:#fff;border:1px solid #ddd;margin:10px;box-shadow:0 0 15px rgba(0,0,0,.2);background-position:center;display:flex;flex-direction:column;align-items:stretch;padding:10px}._1Z5o_SZQ4FHIK-yGzxhMKK{flex:1;width:100%;height:100%;padding:10px;font-size:16px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:flex;align-items:center}._1Z5o_SZQ4FHIK-yGzxhMKK>*{flex:1}._1cWr7JqehJLQFURZSs2f1c{margin:0 5px}._1ObO1cjD29-YGb9h2EDNkN{margin:10px 0 0}", ""]);

// exports
exports.locals = {
	"preview": "_1k897KJ6aPM24Sw0kZvr5a",
	"overlay": "_1Z5o_SZQ4FHIK-yGzxhMKK",
	"info": "_1cWr7JqehJLQFURZSs2f1c",
	"recommendations": "_1ObO1cjD29-YGb9h2EDNkN"
};