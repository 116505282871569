import {fromJS} from 'immutable';
import {analytics, events} from 'modules/analytics';

const initialState = fromJS({
  url: null,
  token: null
});

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SHARE_REPORT_URL:
      return state.set('url', action.url).set('token', action.token);
  }

  return state;
}

export const SHARE_REPORT = 'reports:SHARE_REPORT';
export function shareReport(reportId) {
  return {
    type: SHARE_REPORT,
    reportId,
    ...analytics(events.REPORT_SHARE)
  };
}

export const RECEIVE_SHARE_REPORT_URL = 'reports:RECEIVE_REPORT_SHARE_URL';
export function receiveShareReportUrl({url, token}) {
  return {type: RECEIVE_SHARE_REPORT_URL, url, token};
}

export const CLOSE_SHARE_REPORT = 'reports:CLOSE_SHARE_REPORT';
export function closeShareReport() {
  return {
    type: CLOSE_SHARE_REPORT
  };
}
