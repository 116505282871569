import {takeLatest, put} from 'redux-saga/effects';
import {FETCH_QUOTE, receiveQuote, handleQuoteLoadError} from 'modules/quote/quoteActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {quoteEndpoint} from 'modules/quote/quoteUtils';

export default function* requestQuoteSaga() {
  yield takeLatest([FETCH_QUOTE], function*({quoteId, quoteKey}) {
    yield processRequest('GET', quoteEndpoint(quoteId, quoteKey), {
      successAction: receiveQuote,
      error: function*(err) {
        const errorMsg = err.message || "Failed to load quote.";
        yield put(handleQuoteLoadError(errorMsg));
      }
    });
  });
}