import {
  FETCH_THEMES,
  FETCH_THEMES_COMPLETE,
  ADD_THEME,
  UPDATE_THEME,
  RECIEVE_THEME,
  RECIEVE_THEMES,
  SET_IS_UPDATING,
  REQUEST_DELETE_THEME,
  DELETE_THEME
} from './constants';
import {push} from 'modules/location';
import {searchCampaignsPath} from './utils';

export function fetchThemes(reportId) {
  return {type: FETCH_THEMES, reportId};
}

export function fetchThemesComplete(reportId) {
  return {type: FETCH_THEMES_COMPLETE, reportId};
}

export function addTheme(reportId, campaign, onSuccess) {
  return {type: ADD_THEME, reportId, campaign, onSuccess};
}

export function updateTheme(reportId, campaign, onSuccess) {
  return {type: UPDATE_THEME, reportId, campaign, onSuccess};
}

export function setIsUpdating(campaignId) {
  return {type: SET_IS_UPDATING, campaignId};
}

export function receiveTheme(reportId, theme) {
  return {type: RECIEVE_THEME, reportId, theme, campaignId: theme.campaignId.toString()};
}

export function receiveThemes(reportId, themes) {
  return {type: RECIEVE_THEMES, reportId, themes};
}

export function showSearchCampaigns(reportId) {
  return push(searchCampaignsPath, {reportId});
}

export function requestDeleteTheme(reportId, campaignId) {
  return {type: REQUEST_DELETE_THEME, reportId, campaignId};
}

export function deleteTheme(campaignId) {
  return {type: DELETE_THEME, campaignId};
}
