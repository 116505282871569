import React from 'react';
import {trackEvent} from 'modules/tracking';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import SettingsPage from 'components/settings/settingsPage';
import Button from 'components/button';

class LanguagesConfig extends React.Component {
  static defaultProps = {
    websiteConfig: {}
  };

  handleValidSubmit = ({languages}) => {
    const {websiteConfig, updateWebsite, reportId} = this.props;

    trackEvent('Updated languages config', {
      fromFile: 'languagesConfig.js',
      forReport: reportId
    });

    updateWebsite({...websiteConfig, languages});
  };

  render() {
    const {
      name,
      websiteConfig,
      match: {params}
    } = this.props;
    const {languages} = websiteConfig;

    if (!languages) {
      return null;
    }

    return (
      <SettingsPage name="Languages" params={params}>
        <Form name={name} defaultValue={{languages}} onValidSubmit={this.handleValidSubmit}>
          <SettingItem title="Languages used" description="All languages that this report uses">
            <Field type="languages" name="languages" required />
          </SettingItem>

          <Button type="submit">Save</Button>
        </Form>
      </SettingsPage>
    );
  }
}

export default LanguagesConfig;
