exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Kni_oxtHcQ7oLtW1k20Ql{margin-right:0px}.kdw5Gekb5Pta9dmTotZ7O input{border-radius:50px;height:30px}", ""]);

// exports
exports.locals = {
	"searchBar": "_3Kni_oxtHcQ7oLtW1k20Ql",
	"rounded": "kdw5Gekb5Pta9dmTotZ7O"
};