exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "figure.cN8fcJzSvLeQ6A-UzaHcF,.cN8fcJzSvLeQ6A-UzaHcF figure{margin:0;height:100%}.cN8fcJzSvLeQ6A-UzaHcF{position:relative;transition:box-shadow .2s;box-shadow:0 0 10px rgba(0,0,0,.1);color:#030303;cursor:pointer;overflow:hidden;background:#fff}.cN8fcJzSvLeQ6A-UzaHcF:hover{box-shadow:0 0 10px rgba(0,0,0,.4)}.cN8fcJzSvLeQ6A-UzaHcF._2ezGxQk8Ybgnw7TDwZAnd3{border:1px solid #ddd;border-radius:2px 2px 0 0}.cN8fcJzSvLeQ6A-UzaHcF._2ezGxQk8Ybgnw7TDwZAnd3 ._1Xw-cPv7WSucEVMAOglMSf figcaption{border-top:1px solid #ddd}.cN8fcJzSvLeQ6A-UzaHcF.tAEvz2Cfilm5LjX5_gLrG ._3KZNTu0mx2EDqQ0j9RSWlw>img{width:100%}.cN8fcJzSvLeQ6A-UzaHcF ._3KZNTu0mx2EDqQ0j9RSWlw{width:100%;text-align:center;overflow:hidden;background:#533be2;max-height:180px;border-radius:2px 2px 0 0;display:flex;overflow:hidden;align-items:flex-start;justify-content:center}.cN8fcJzSvLeQ6A-UzaHcF ._3KZNTu0mx2EDqQ0j9RSWlw img{width:100%}.cN8fcJzSvLeQ6A-UzaHcF._2v5tUXoABwlH9UcXd7gaGI ._3KZNTu0mx2EDqQ0j9RSWlw{max-height:150px}.cN8fcJzSvLeQ6A-UzaHcF._2v5tUXoABwlH9UcXd7gaGI._3GGimkmFuaxqiTX_S6QUnA ._3KZNTu0mx2EDqQ0j9RSWlw{width:180px}.cN8fcJzSvLeQ6A-UzaHcF ._1Xw-cPv7WSucEVMAOglMSf{position:relative;background:#fff}.cN8fcJzSvLeQ6A-UzaHcF ._1Xw-cPv7WSucEVMAOglMSf figcaption{padding:15px}.cN8fcJzSvLeQ6A-UzaHcF .WvEmCzYLpzJU8byp4lnuD{display:block;font-size:1.25em;color:#030303;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;text-align:left}.cN8fcJzSvLeQ6A-UzaHcF ._2E7K_eXWP8PB0mAxD62poE{display:block;color:#6e6e6e;font-size:.8em;text-transform:uppercase}._3X1QdXMLsYrcQQzZ4h7du_{position:absolute;left:0;right:0;top:0;bottom:0;display:block;z-index:5}._2iLxhXAZ2ZxsJiZyZu5Qok{display:block;text-decoration:none}@media print{.cN8fcJzSvLeQ6A-UzaHcF{border:1px solid #e1e1e1}.cN8fcJzSvLeQ6A-UzaHcF ._1Xw-cPv7WSucEVMAOglMSf{border:0;border-top:1px solid #e1e1e1}.cN8fcJzSvLeQ6A-UzaHcF .cogBtn{display:none}}", ""]);

// exports
exports.locals = {
	"card": "cN8fcJzSvLeQ6A-UzaHcF",
	"border": "_2ezGxQk8Ybgnw7TDwZAnd3",
	"caption": "_1Xw-cPv7WSucEVMAOglMSf",
	"stretch": "tAEvz2Cfilm5LjX5_gLrG",
	"image": "_3KZNTu0mx2EDqQ0j9RSWlw",
	"small": "_2v5tUXoABwlH9UcXd7gaGI",
	"square": "_3GGimkmFuaxqiTX_S6QUnA",
	"title": "WvEmCzYLpzJU8byp4lnuD",
	"subtitle": "_2E7K_eXWP8PB0mAxD62poE",
	"link": "_3X1QdXMLsYrcQQzZ4h7du_",
	"linkWrapper": "_2iLxhXAZ2ZxsJiZyZu5Qok"
};