import React from 'react';
import {motion} from 'framer-motion';

export default function SlideHorizontal({
  id,
  children,
  direction = 'ltr',
  distance = 100,
  duration = 300
}) {
  return (
    <motion.div
      key={id}
      initial={{opacity: 0, x: direction === 'ltr' ? -distance : distance}}
      animate={{opacity: 1, x: 0}}
      exit={{opacity: 0, x: direction === 'ltr' ? distance : -distance}}
      transition={{duration: duration / 1000}}
    >
      {children}
    </motion.div>
  );
}
