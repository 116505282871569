exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media screen{.vbrF3F-gPMeVN6RAVmLiw{height:100vh;background:#f2f4f3;transition:padding 100ms ease-in-out}}._25R2pcPnwZIPfW4HZhPnuc{flex:0 0 53px}@media print{._25R2pcPnwZIPfW4HZhPnuc{flex:0 0 0;display:none}}.LRH-FugEF9iayVYO7fzdn{padding-bottom:40px}", ""]);

// exports
exports.locals = {
	"wrapper": "vbrF3F-gPMeVN6RAVmLiw",
	"mainHeader": "_25R2pcPnwZIPfW4HZhPnuc",
	"decisionAlertPadding": "LRH-FugEF9iayVYO7fzdn"
};