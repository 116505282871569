import React from 'react';
import Change from 'components/score/change';
import Icon from 'components/font-awesome';

export default function ChangeCell({data, options}) {
  const {logic} = options;
  if (typeof data === 'undefined') return null;

  return (
    <Change
      diff={data}
      up={<Icon name="caret-up" />}
      down={<Icon name="caret-down" />}
      equal={<Icon name="minus" />}
      logic={logic || 'zero-bad'}
    />
  );
}
