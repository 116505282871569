import React from 'react';
import {put, fork, select, take, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import modalSaga from 'utils/saga/modalSaga';
import moment from 'moment';
import {showInModal, hideModal, HIDE_MODAL} from 'modules/modal';
import {showSuccess, showError} from 'utils/saga/alerts';
import confirm from 'utils/saga/confirm';
import {receiveStripeToken} from 'modules/products';

import {
  SHOW_UPDATE_PAYMENT_DETAILS_MODAL,
  REQUEST_PAYMENT_DETAILS_UPDATE,
  PAYMENT_DETAILS_UPDATE_COMPLETE,
  PAYMENT_DETAILS_UPDATE_FAILED,
  paymentDetailsUpdateComplete,
  paymentDetailsUpdateFailed,
  paymentDetailsCancelled,
  requestPaymentDetails
} from 'modules/payments';
import UpdatePaymentDetailsModal from 'modules/products/components/updatePaymentDetailsModal';
import {subscriptionExpiryDateSelector} from 'modules/payments/paymentSelectors';
import {stripeTokenSelector} from 'modules/products/productSelectors';
import {paymentDetailsEndpoint} from 'modules/payments/paymentUtils';
import ModalSpinner from 'components/spinner/modalSpinner';
import {accountIdSelector} from 'modules/auth/authSelectors';

export default function* updatePaymentDetailsSaga() {
  // create a "show modal" saga for the payment details form
  yield fork(modalSaga(SHOW_UPDATE_PAYMENT_DETAILS_MODAL, UpdatePaymentDetailsModal));

  // capture the modal form details and process
  yield takeEvery(REQUEST_PAYMENT_DETAILS_UPDATE, processUpdate);

  // close the modal on success or failure
  yield takeEvery([PAYMENT_DETAILS_UPDATE_COMPLETE, PAYMENT_DETAILS_UPDATE_FAILED], cleanUp);
}

function* processUpdate({paymentDetails}) {
  const accountId = yield select(accountIdSelector);

  if (!accountId) return;

  yield put(showInModal(ModalSpinner));

  yield put(receiveStripeToken(paymentDetails.token));

  const token = yield select(stripeTokenSelector);

  yield processRequest(
    'POST',
    paymentDetailsEndpoint({accountId}),
    {
      successAction: paymentDetailsUpdateComplete,
      errorAction: paymentDetailsUpdateFailed
    },
    {cardToken: token.id}
  );
}

function* cleanUp(action) {
  yield put(hideModal());
  if (action.type === PAYMENT_DETAILS_UPDATE_COMPLETE) {
    yield put(requestPaymentDetails());
    yield showSuccess('Your payment details have been updated');
  } else {
    yield put(showInModal(UpdatePaymentDetailsModal));
    yield showError(
      'Unable to update payment details. Check your details are correct and try again'
    );
  }
}
