import React from 'react';
import {openNotification} from 'modules/notifications';
import {connect} from 'react-redux';

export class NotificationsRoute extends React.Component {
  componentWillMount() {
    const {
      openNotification,
      match: {params}
    } = this.props;

    openNotification(params.notificationId, true);
  }

  render() {
    return null;
  }
}

export default connect(
  null,
  {openNotification}
)(NotificationsRoute);
