import {all, put, call, take, select, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {allReportsEndpoint} from 'modules/reports/reportUtils';
import {folderResultsEndpoint} from 'modules/folderViews/folderViewUtils';
import {
  VIEW_FOLDER_VIEW_PAGE,
  FETCH_FOLDER_VIEWS,
  receiveFolderResults,
  receiveFolderResultsError
} from 'modules/folderViews';
import {AUTH_CHANGE} from 'modules/auth';
import {isEcommerceTrialSelector} from 'modules/auth/authSelectors';
import {EXAMPLES_FOLDER, EXAMPLES_VIEW, EXAMPLE_REPORTS_KEY} from 'modules/folders/folderUtils';
import {filtersSelector} from 'modules/reportFilters/reportFilterSelectors';
import {toKeyValuePairs} from 'modules/reportFilters/reportFilterUtils';
import {queryString} from 'utils/urlUtils';

export default function* fetchViewAndReports() {
  yield all([
    takeEvery([VIEW_FOLDER_VIEW_PAGE, FETCH_FOLDER_VIEWS], fetchFolderViews),
    takeLatest(AUTH_CHANGE, fetchExampleReports)
  ]);
}

function* fetchFolderViews(action) {
  yield call(requestReports, action);
}

function* fetchExampleReports() {
  const isEcommerceTrial = yield select(isEcommerceTrialSelector);
  if (!isEcommerceTrial) {
    return;
  }
  yield take([VIEW_FOLDER_VIEW_PAGE, FETCH_FOLDER_VIEWS]);
  const reqData = {
    folderId: EXAMPLES_FOLDER,
    viewId: EXAMPLES_VIEW,
    key: EXAMPLE_REPORTS_KEY
  };
  yield call(requestReports, reqData);
}

function* requestReports(reqData) {
  const {viewId, folderId, key} = reqData;
  var endpoint;

  if (folderId == 0) {
    const filters = yield select(filtersSelector);
    endpoint =
      allReportsEndpoint(reqData) + '?limit=500&' + queryString(toKeyValuePairs(filters.filters));
  } else {
    endpoint = folderResultsEndpoint(reqData);
  }

  yield processRequest('GET', endpoint, {
    success: function*(data) {
      yield put(receiveFolderResults({...data, viewId, folderId, key}));
    },
    error: function*() {
      yield put(receiveFolderResultsError());
    }
  });
}
