import {delay} from 'redux-saga';
import {takeLatest} from 'redux-saga/effects';
import {TOGGLE_SIDEBAR, TOGGLE_ACCOUNT_MENU} from 'modules/app';

export default function* resizeAfterSidebarSaga() {
  yield takeLatest([TOGGLE_SIDEBAR, TOGGLE_ACCOUNT_MENU], function*() {
    // yield delay(100); // same as the sidebar transistion time + 10ms

    // we end up with an infinite loop if we don't prevent toggling on this resize event
    window.dispatchEvent(new CustomEvent('resize', {detail: {preventToggle: true}}));
  });
}
