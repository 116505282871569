import cx from 'classnames';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Controls from 'components/control/controls';
import Spinner from 'components/spinner';
import UndoDecision from 'modules/decisions/components/undoDecision';
import InspectorIssue from 'modules/inspector/components/inspectorIssue';
import {selectPoints} from 'modules/inspector/inspectorActions';
import {inspectorActionPointSelector} from 'modules/inspector/inspectorSelectors';
import {replaceUpdateQuery} from 'modules/location/location';
import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import styles from '../inspectorSidebar.scss';

function InspectorActionPointRow({
  point,
  action,
  isActive,
  hasSelector,
  isMakingDecision,
  selectPoint
}) {
  const pointRef = useRef();
  const enableAutoscroll = useRef(true);
  const timeout = useRef();

  useEffect(() => {
    if (
      enableAutoscroll.current &&
      isActive &&
      pointRef.current &&
      pointRef.current.scrollIntoView
    ) {
      timeout.current = setTimeout(() => {
        pointRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      }, 500);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [isActive]);

  return (
    <div
      ref={pointRef}
      key={`point_${point.get('pointId')}`}
      onClick={() => {
        enableAutoscroll.current = false;
        selectPoint(point.get('pointId'), !isActive);
      }}
      className={cx(styles.point, {
        [styles.activePoint]: isActive
      })}
    >
      <InspectorIssue
        issueData={point}
        issueHash={`point_${point.get('pointId')}`}
        isActive={isActive}
        count={1}
        componentName={point.get('presentationType') + 'Issue'}
        isVirtual={false}
        action={action.toJS()}
        point={point.toJS()}
        hideLegacy
      />

      {isMakingDecision ? (
        <Spinner small marginBottom={20} />
      ) : !!point.get('decisionId') ? (
        <div className={styles.controls}>
          <Buttons centered>
            <UndoDecision decisionId={point.get('decisionId')} small context={point.toJS()} />
          </Buttons>
        </div>
      ) : (
        action.get('pointControls') && (
          <div className={styles.controls}>
            <Controls controls={action.get('pointControls').toJS()} context={point.toJS()} small>
              {/* This has no onClick handler because the entire row has an onClick handler that is called when this is clicked */}
              {hasSelector && (
                <span>
                  <Button small square wire>
                    View
                  </Button>
                </span>
              )}
            </Controls>
          </div>
        )
      )}
    </div>
  );
}

export default connect(
  inspectorActionPointSelector,
  dispatch => ({
    selectPoint: (pointId, isActive) => {
      dispatch(selectPoints([pointId]));
      dispatch(replaceUpdateQuery({pointId: isActive ? pointId : undefined}));
    }
  })
)(InspectorActionPointRow);
