exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mQQEEOeqWiimEyIOP10xY{padding:10px}._1kOGIjBdjFeZ6y6EYGV2jJ{padding:0 !important}._2nDyA5HN4sEdif-BnbnH-r{min-width:350px;max-height:300px;overflow-y:scroll}", ""]);

// exports
exports.locals = {
	"portalDropdown": "mQQEEOeqWiimEyIOP10xY",
	"noPadding": "_1kOGIjBdjFeZ6y6EYGV2jJ",
	"maxHeight": "_2nDyA5HN4sEdif-BnbnH-r"
};