import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_LIBRARY_POLICIES} from '../constants';
import {setLibraryPolicies, requestLibraryPoliciesComplete} from '../actions';

export default function* requestLibraryPoliciesSaga() {
  yield takeLatest(REQUEST_LIBRARY_POLICIES, requestLibraryPolicies);
}

function* requestLibraryPolicies() {
  try {
    const libraryPolicies = yield processRequest('GET', 'library-policies');
    yield put(setLibraryPolicies(libraryPolicies));
    yield put(requestLibraryPoliciesComplete());
  } catch (err) {
    yield put(requestLibraryPoliciesComplete(err));
  }
}
