import {createSelector} from 'reselect';
import {toString} from 'utils/stringUtils';

export const locationState = state => state.location;

export const locationSelector = createSelector(
  locationState,
  state => {
    return state.toJS();
  }
);

export const locationParamsSelector = createSelector(
  locationState,
  state => {
    return state.get('params').toJS();
  }
);

export const queryParamsSelector = createSelector(
  locationState,
  state => {
    return state.get('query') || {};
  }
);

export const locationAccountIdSelector = createSelector(
  locationParamsSelector,
  params => {
    return params && params.accountId;
  }
);

// Creates a selector which fallsback to a defaultValue
// and optionally calls a normalizer function on the value
function createSubSelector(selector, propName, defaultValue, normalizer = null) {
  return createSelector(
    selector,
    state => {
      if (!state) {
        return undefined;
      }

      let value = state[propName];
      if (normalizer) value = normalizer(value);
      return value || defaultValue;
    }
  );
}

// Checks for prop before checking in params
export function createPropOrParamSelector(propName, defaultValue) {
  return createSelector(
    (state, props = {}) => props[propName],
    createParamSelector(propName, defaultValue),
    (prop, param) => {
      return toString(prop || param);
    }
  );
}

// create a selector which pulls out a certain param from the location state.
export function createParamSelector(paramName, defaultValue, normalizer = null) {
  return createSubSelector(locationParamsSelector, ...arguments);
}

export function createQuerySelector(paramName, defaultValue, normalizer = null) {
  return createSubSelector(queryParamsSelector, ...arguments);
}

export const historySelector = createSelector(
  locationState,
  state => state.get('urlHistory')
);

export const lastPathSelector = createSelector(
  historySelector,
  history => history[history.length - 2]
);

export const lastRequestedPageSelector = createSelector(
  locationState,
  state => state.get('lastRequestedUrl')
);

export const createPrioritisedRouteParamPropSelector = paramName => (_, props) => {
  if (props[paramName]) return props[paramName];
  if (props.params && props.params[paramName]) return props.params[paramName];
  if (props.match && props.match.params && props.match.params[paramName])
    return props.match.params[paramName];

  return undefined;
};
