import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Input from 'components/input';
import Select from 'components/select';
import FlagOption from 'modules/form/components/flagOption';
import {languagesByCode} from './../../constants';
import {
  getCountryCode,
  isFormattedPhoneNumber,
  removeCountryCode,
  getDialCode
} from 'utils/languageUtils';
import {isNumerical, removeNonAlphaNumeric} from 'utils/stringUtils';
import styles from './telInput.scss';
import CountryFlag from 'components/countryFlag/countryFlag';
import {accountSelector} from 'modules/auth/authSelectors';
import {createStructuredSelector} from 'reselect';

const telInputSelector = createStructuredSelector({
  account: accountSelector
});

export class TelInput extends React.Component {
  constructor() {
    super();

    this.state = {
      countryCode: '',
      codeOptions: []
    };
  }

  componentDidMount = () => {
    this.setLanguages();
  };

  componentWillReceiveProps() {
    const {onChange, value} = this.props;

    // if the value in db is not a formatted number or numerical we need to sanitize
    // it before displaying it in the input
    if (!isFormattedPhoneNumber(value) && !isNumerical(value) && value !== '') {
      const sanitized = removeNonAlphaNumeric(value);
      onChange(sanitized);
    }
  }

  getMappedLanguages = languages => {
    return languages.map(({code}) => ({flagCode: code}));
  };

  getCountryCode = str => str.slice(str.length - 2, str.length);

  getCodeOptions = () => {
    const options = [];

    Object.keys(languagesByCode).forEach(num => {
      const country = languagesByCode[num];
      const dialCode = num;
      const labelText = `${country.code} ${dialCode}`;

      const {flagCode} = languagesByCode[dialCode];

      if (flagCode) {
        const flag = <FlagOption code={flagCode} label={labelText} />;

        options.push({
          value: dialCode,
          label: flag
        });
      }
    });

    return options;
  };

  getFlagCode = dialCode => languagesByCode[dialCode].flagCode;

  handleCodeChange = option => {
    // option null if hitting backspace when select open
    if (option) {
      const prefix = option.value;

      const {onChange, value} = this.props;
      let telNumber;

      if (isNumerical(value)) {
        telNumber = value;
      } else if (value.indexOf('+') === 0) {
        telNumber = value.split('+')[1];
      } else {
        telNumber = value.split(')')[1];
      }

      if (!prefix) {
        onChange(`${telNumber || ''}`);
        this.setState({countryCode: ''});
        return;
      }

      onChange(`${prefix}${telNumber || ''}`);
      this.setState({countryCode: prefix});
    }
  };

  getOption = () => {
    if (isFormattedPhoneNumber(this.props.value)) {
      let {countryCode} = this.state;
      const addressCountry = this.props.account.get('addressCountry') || 'US';
      const dialCode = `(+${getDialCode(addressCountry)})`;

      let {flagCode} = countryCode ? languagesByCode[countryCode] : '';

      if (!countryCode && addressCountry && languagesByCode.hasOwnProperty(dialCode)) {
        countryCode = dialCode;
        flagCode = this.getFlagCode(dialCode);
      } else if (!countryCode) {
        countryCode = '(+1)';
        flagCode = this.getFlagCode('(+1)');
      }

      return {
        value: countryCode,
        label: (
          <Fragment>
            {flagCode && <CountryFlag code={flagCode} className={styles.flag} />}
            <span className={countryCode && styles.optionCode}>{countryCode}</span>
          </Fragment>
        )
      };
    }
  };

  handleNumberChange = ({target}) => {
    const {value} = target;

    if (value.indexOf(')') !== -1) {
      return;
    }

    const number = removeCountryCode(target.value);

    if (number.charAt(0) === '0') {
      return;
    }

    if (isNumerical(number) || number === '') {
      const newVal = `${this.state.countryCode}${number}`;
      this.props.onChange(newVal);
    }
  };

  setLanguages = () => {
    const codeOptions = this.getCodeOptions();
    this.setState({countryCode: getCountryCode(this.props.value), codeOptions});
  };

  render() {
    const {codeOptions} = this.state;

    return (
      <Fragment>
        {Object.keys(languagesByCode).length && codeOptions.length ? (
          <div className={styles.wrapper}>
            <Select
              options={codeOptions}
              name="Country Code"
              placeholder="Country Code"
              onChange={this.handleCodeChange}
              value={this.getOption()}
              className={styles.countryCode}
              searchable={false}
              clearable={false}
            />
            <Input
              {...this.props}
              type="tel"
              onChange={this.handleNumberChange}
              value={removeCountryCode(this.props.value)}
            />
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(telInputSelector)(TelInput);
