import React from 'react';
import {connect} from 'react-redux';
import {trendSourcesSelector} from '..';
import LineChart from 'components/graphs/lineChart';

export class TrendGraph extends React.Component {
  getResults() {
    const {trendSources} = this.props;

    return trendSources.reduce((results, source) => {
      results[source.label] = source.results;
      return results;
    }, {});
  }

  render() {
    const {name} = this.props;
    return <LineChart results={this.getResults()} title={name} />;
  }
}

export default connect(trendSourcesSelector)(TrendGraph);
