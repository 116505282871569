import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DIGEST_PREVIEW} from 'modules/digests/constants';
import {digestPreviewEndpoint} from 'modules/digests/utils';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {showInModal, hideModal} from 'modules/modal';
import DigestPreview from '../containers/digestPreview';
import ModalSpinner from 'components/spinner/modalSpinner';

export default function* requestDigestPreviewListener() {
  yield takeLatest(REQUEST_DIGEST_PREVIEW, requestDigestPreviewSaga);
}

function* requestDigestPreviewSaga({reportId, digestId, schedulePreset, options, onClose}) {
  const url = digestPreviewEndpoint({digestId});

  yield put(showInModal(ModalSpinner));

  yield processRequest(
    'POST',
    url,
    {
      *success(data) {
        yield put(
          showInModal(DigestPreview, {
            ...data,
            onClose
          })
        );
      },
      *error(err) {
        yield put(hideModal());
      },
      errorMessage: couldNot('request digest preview')
    },
    {reportId, schedulePreset, options}
  );
}
