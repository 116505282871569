import {DropTarget} from 'react-dnd';
import {makeDNDDecorator, handlerMapToSource} from './dndDecorator';

export default function Droppable(typeName, handlerMap = dropHandlerMap) {
  const dropTarget = DropTarget(typeName, handlerMapToSource(handlerMap), collect);
  return makeDNDDecorator('connectDropTarget', dropTarget);
}

// react-dnd source method -> component method
const dropHandlerMap = {
  drop: 'handleDrop',
  hover: 'handleHover'
};

// Passed as props through to DroppableComponent
export function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isDraggedOver: monitor.isOver(),
    isDraggedOverShallow: monitor.isOver({shallow: true}),
    canDrop: monitor.canDrop()
  };
}
