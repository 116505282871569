import {createSelector} from 'reselect';
import {featuresSelector} from 'modules/auth/authSelectors';
import {tagOptionsSelector, loadingTagsStateSelector} from 'modules/tags/selectors';

const searchDomainSelector = state => state.searchPage;

const recentReportsSelector = createSelector(
  searchDomainSelector,
  searchDomain => searchDomain.get('recentReports')
);
const recentReportsLoadingSelector = createSelector(
  searchDomainSelector,
  searchDomain => searchDomain.get('loadingRecentReports')
);

const searchResultsSelector = createSelector(
  searchDomainSelector,
  searchDomain => searchDomain.get('searchResults')
);
const searchResultsLoadingSelector = createSelector(
  searchDomainSelector,
  searchDomain => searchDomain.get('loadingSearchResults')
);

const noMoreRecentReportsSelector = createSelector(
  searchDomainSelector,
  searchDomain => searchDomain.get('noMoreRecentReports')
);

export const searchPageSelector = createSelector(
  recentReportsSelector,
  recentReportsLoadingSelector,
  searchResultsSelector,
  searchResultsLoadingSelector,
  noMoreRecentReportsSelector,
  featuresSelector,
  tagOptionsSelector,
  loadingTagsStateSelector,
  (
    recentReports,
    recentReportsLoading,
    searchResults,
    searchResultsLoading,
    noMoreRecentReports,
    {features},
    tagOptions,
    tagsLoadingState
  ) => {
    return {
      searchResults,
      recentReports,
      searchResultsLoading,
      recentReportsLoading,
      noMoreRecentReports,
      tagOptions,
      hasTagsLoaded: tagsLoadingState.get('loaded'),
      tagsLoading: tagsLoadingState.get('loading'),
      leaderboardsFlag: features && features.get('LeaderboardFeatureFlag')
    };
  }
);
