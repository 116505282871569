import React from 'react';
import {connect} from 'react-redux';
import {Sidebar} from 'components/sidebar/listSidebar';
import {profileSidebarSelector} from 'modules/profile/profileSelectors';
import UserImage from 'components/user/userImage';
import Center from 'components/center';
import RelativeDate from 'components/time/relativeDate';
// import styles from './profileSidebar.scss';

export class ProfileSidebar extends React.Component {
  render() {
    if (!this.props.user) {
      return null;
    }
    return (
      <Sidebar>
        <div style={{padding: '20px 0'}}>
          <Center>
            <UserImage size={150} user={this.props.user} />
          </Center>
        </div>
        <Center>
          <h3>{this.props.user.name}</h3>
        </Center>
        <Center>
          {!this.props.user.lastActive ? (
            <p>Never logged in</p>
          ) : (
            <p>
              Last seen <RelativeDate plain timestamp={this.props.user.lastActive} />
            </p>
          )}
        </Center>
      </Sidebar>
    );
  }
}

export default connect(profileSidebarSelector)(ProfileSidebar);
