import {takeLatest} from 'redux-saga/effects';
import confirmModal from 'utils/saga/confirm';
import {SHOW_CONFIRM_MODAL} from 'modules/modal';

export default function* showConfirmModalSaga() {
  yield takeLatest(SHOW_CONFIRM_MODAL, showConfirmModal);
}

function* showConfirmModal({options, modalType = null}) {
  const didConfirm = yield* confirmModal(options, modalType);

  if (options.callback) {
    options.callback(didConfirm);
  }
}
