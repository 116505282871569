import PropTypes from 'prop-types';
import React from 'react';
import {toFixed} from 'utils/numberUtils.js';

class Percentage extends React.Component {
  render() {
    const {data, options} = this.props;
    let {decimals} = options;

    if (typeof decimals === 'undefined') {
      decimals = 1;
    }

    return <span>{data || data === 0 ? toFixed(data, decimals) + '%' : ''}</span>;
  }
}

Percentage.propTypes = {};

export default Percentage;
