exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3E6iESuoZSPEsR9YbauQBS{text-align:center;margin:15px 0}._3E6iESuoZSPEsR9YbauQBS h3{margin:0 0 10px 0}._3E6iESuoZSPEsR9YbauQBS p{opacity:.5;margin:0 auto;max-width:400px}@media(max-width: 1200px){._3E6iESuoZSPEsR9YbauQBS{margin-top:50px}}@media(max-width: 425px){._3E6iESuoZSPEsR9YbauQBS{padding:0 20px}}", ""]);

// exports
exports.locals = {
	"wrapper": "_3E6iESuoZSPEsR9YbauQBS"
};