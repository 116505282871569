import React from 'react';
import {trackEvent} from 'modules/tracking';
import {Form, Field} from 'modules/form/components';
import SettingsPage from 'components/settings/settingsPage';
import SettingItem from 'components/settings/settingItem';
import Button from 'components/button';
import {getProps} from 'utils/immutableUtils';
import AssignedTags from 'modules/tags/components/assignedTags';
import {isMonitor} from 'modules/reports/reportUtils';

// list of all properties we need from websiteConfig
const CONFIG_FIELDS = [
  'homeUrl',
  'allowedUrls',
  'deniedUrls',
  'maxConnections',
  'pageOrderStrategy',
  'cmsOptions',
  'comparisonWebsites',
  'inappropriateTerms'
];

const REPORT_FIELDS = ['name', 'scheduleInterval', 'schedulePeriod'];

const pageOrderStrategyOptions = [{value: '', label: 'Default'}, {value: 'r', label: 'Random'}];

const cmsOptions = [{value: '', label: 'None'}, {value: 'Wordpress', label: 'Wordpress'}];

export default class General extends React.Component {
  handleValidSubmit = setTags => ({name, reportTags, ...website}) => {
    const {
      match: {
        params: {reportId}
      },
      report
    } = this.props;
    const {name: originalName} = report;

    trackEvent('Updated general config', {
      fromFile: 'generalConfig.js',
      forReport: reportId
    });

    if (name !== originalName) {
      this.props.updateReport(reportId, {name});
    }

    this.props.updateWebsite(website);

    if (isMonitor(report)) {
      setTags(reportTags);
    }
  };

  getData() {
    const {websiteConfig, report} = this.props;
    if (!websiteConfig || !report) {
      // Don't return data until everything is loaded
      return null;
    }
    return {
      ...getProps(websiteConfig, CONFIG_FIELDS),
      ...getProps(report, REPORT_FIELDS),
      reportTags: report.assignedTags
    };
  }

  render() {
    const {
      name,
      match: {params},
      availableTestKeys,
      report
    } = this.props;
    const data = this.getData();

    if (!data) return null;

    const isInappropriateContentEnabled =
      availableTestKeys && availableTestKeys.indexOf('inappropriate-content-screen') !== -1;

    return (
      <AssignedTags entityType="report" entityId={params.reportId} entityTags={data.reportTags}>
        {({setAssignedEntitiesTags, isAssigningEntityTags, onEntityTagsChanged}) => {
          return (
            <SettingsPage name="Website" params={params}>
              <Form
                name={name}
                defaultValue={data}
                onValidSubmit={this.handleValidSubmit(setAssignedEntitiesTags)}
              >
                <SettingItem title="Website name" description="Descriptive name for this website">
                  <Field type="text" name="name" minLength={1} maxLength={200} />
                </SettingItem>

                <SettingItem title="Homepage" description="The web address for the homepage">
                  <Field type="url" name="homeUrl" />
                </SettingItem>

                {isMonitor(report) && (
                  <SettingItem
                    title="Website tags"
                    description="Select tags to add to this website. Tags are used to select a group of websites"
                  >
                    <Field
                      type="tags"
                      name="reportTags"
                      onChange={val => {
                        onEntityTagsChanged(val);
                      }}
                    />
                  </SettingItem>
                )}

                <SettingItem
                  title="Allowed URLs"
                  description="List of web address paths that can be tested"
                >
                  <Field type="textareaUrls" name="allowedUrls" placeholder="http://" />
                </SettingItem>

                <SettingItem
                  title="Denied URLs"
                  description="List of web address paths that are prohibited"
                >
                  <Field type="textareaUrls" name="deniedUrls" placeholder="http://" />
                </SettingItem>

                <SettingItem
                  title="Competitors"
                  description="Optionally enter sites to compare against, one on each line"
                >
                  <Field type="textareaUrls" name="comparisonWebsites" placeholder="http://" />
                </SettingItem>

                {isInappropriateContentEnabled && (
                  <SettingItem
                    title="Inappropriate terms"
                    description="Optionally enter some terms deemed inappropriate for this site, one on each line"
                  >
                    <Field
                      name="inappropriateTerms"
                      type="textarea"
                      placeholder='e.g. "Out of date content 2018", "Lorem ipsum"'
                      help="Optionally enter any terms you would like to flag as inappropriate, one on each line."
                      separateBy={[',', '\n']}
                    />
                  </SettingItem>
                )}

                {/* {false && (
                  <SettingItem
                    title="Max connections"
                    description="The maximum number of pages to download at once"
                  >
                    <Field type="number" name="maxConnections" min={2} max={10} />
                  </SettingItem>
                )}

                {false && (
                  <SettingItem title="Page order" description="The order in which pages are tested">
                    <Field type="select" name="pageOrderStrategy" options={pageOrderStrategyOptions} />
                  </SettingItem>
                )}

                {false && (
                  <SettingItem title="CMS" description="Integrate with a supported CMS">
                    <Field type="select" name="cms" options={cmsOptions} />
                  </SettingItem>
                )} */}

                <Button type="submit" isLoading={isAssigningEntityTags}>
                  Save
                </Button>
              </Form>
            </SettingsPage>
          );
        }}
      </AssignedTags>
    );
  }
}
