exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3k_4SnpF0NJZONbkLI0eBX{border-radius:4px;position:relative}._31PfgbSLHkMwB1tqNy3kKW{text-align:center}a._3iw0QuMgePionHbKr51SPI{color:#fff;font-size:.6em;opacity:.2;margin:0 5px;cursor:pointer}a._3iw0QuMgePionHbKr51SPI._1xyN0rEJSi3NZpfC19YWvF,a._3iw0QuMgePionHbKr51SPI:hover{opacity:.5}.NEaGB8Auslg6rEZoypn2f{position:absolute;left:0;top:0;width:100%;height:100%;display:flex;opacity:.4;font-size:30px}button.dBjfCli9F4BZ38JSREJ0l{transition:all .3s;opacity:.4;outline:none;background:none;padding:5px}button.dBjfCli9F4BZ38JSREJ0l+.dBjfCli9F4BZ38JSREJ0l{margin-left:auto}._3k_4SnpF0NJZONbkLI0eBX:hover .dBjfCli9F4BZ38JSREJ0l{opacity:1}", ""]);

// exports
exports.locals = {
	"base": "_3k_4SnpF0NJZONbkLI0eBX",
	"dots": "_31PfgbSLHkMwB1tqNy3kKW",
	"dot": "_3iw0QuMgePionHbKr51SPI",
	"active": "_1xyN0rEJSi3NZpfC19YWvF",
	"arrows": "NEaGB8Auslg6rEZoypn2f",
	"arrow": "dBjfCli9F4BZ38JSREJ0l"
};