import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import {ReportGandalf} from 'components/gandalf';
import {ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';

function RetestButton({reportId, isVirtual, isRetesting, children, ...props}) {
  const button = (
    <Button
      icon="redo-alt"
      translucent={isVirtual}
      isLoading={isRetesting && !isVirtual}
      {...props}
    >
      {children || 'Retest'}
    </Button>
  );

  if (isVirtual) {
    return button;
  }

  return (
    <ReportGandalf shallPass={ROLE_CONTRIBUTOR} reportId={reportId}>
      {button}
    </ReportGandalf>
  );
}

RetestButton.propTypes = {
  reportId: PropTypes.string.isRequired,
  isVirtual: PropTypes.bool,
  isRetesting: PropTypes.bool
};

RetestButton.defaultProps = {
  isVirtual: false,
  isRetesting: false
};

export default RetestButton;
