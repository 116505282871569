import React from 'react';
import Icon from 'components/font-awesome';

class AppearancePointIssue extends React.Component {
  render() {
    const {
      point: {text, appearance},
      action: {actionId, decisions}
    } = this.props;

    return (
      <div>
        <p>
          {appearance == 'good' && <Icon name="fas fa-check" />}
          {appearance == 'bad' && <Icon name="fas fa-times" />} {text}
        </p>
      </div>
    );
  }
}

export default AppearancePointIssue;
