exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ZyvVBQKGCfJlzytCEF5nL{background:#fff;border-radius:10px;padding:10px;font-size:1.3em;box-shadow:2px 2px 7px 0px rgba(0,0,0,.57)}._2mOA2uzExXhLp2spoouR1y{background:#fafaf8;overflow:hidden;border-radius:5;box-shadow:2px 2px 7px 0px rgba(0,0,0,.57)}", ""]);

// exports
exports.locals = {
	"InfoBox": "_2ZyvVBQKGCfJlzytCEF5nL",
	"Legend": "_2mOA2uzExXhLp2spoouR1y"
};