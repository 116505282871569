import React from 'react';
import styles from './filterInfo.scss';

// Converts "["Foo", "Bar", "Baz"]" into "Foo, Bar and Baz"
function renderFilterText(filters) {
  let counter = 1;
  return filters.map(filter => {
    if (filters.length === counter) {
      return <b key={filter}>{filter}</b>;
    }

    let joiner = ', ';
    if (filters.length - 1 === counter) {
      joiner = ' and ';
    }

    counter++;
    return (
      <span key={filter}>
        <strong>{filter}</strong>
        {joiner}{' '}
      </span>
    );
  });
}

class FilterInfo extends React.Component {
  render() {
    const {filters, onClose} = this.props;

    if (!filters || filters.length === 0) {
      return null;
    }

    return (
      <h3 className={styles.wrapper}>
        <span className={styles.close} onClick={onClose}>
          X
        </span>
        <span>Show results for: {renderFilterText(filters)}</span>
      </h3>
    );
  }
}

export default FilterInfo;
