import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {exportExplorer} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import {exportReportSelector} from 'modules/reports/reportSelectors';
import {EXPORT_TYPES} from 'modules/reports/reportConstants';

const exportOptions = [
  {label: 'Excel', value: EXPORT_TYPES.EXCEL},
  {label: 'CSV', value: EXPORT_TYPES.CSV}
];

export class ExportExplorerModal extends React.Component {
  static defaultProps = {
    defaultValue: {
      exportType: EXPORT_TYPES.EXCEL
    }
  };

  static propTypes = {
    reportId: PropTypes.any.isRequired
  };

  handleValidSubmit = ({exportType}) => {
    const {exportExplorer, reportId, testId, table, filters} = this.props;

    exportExplorer(reportId, testId, exportType, table, filters);
  };

  render() {
    const {defaultValue, name} = this.props;

    return (
      <ModalForm
        name={name}
        title="Export table"
        submitLabel="Export"
        defaultValue={defaultValue}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field
          label="Format"
          name="exportType"
          type="select"
          options={exportOptions}
          required
          disableFiltering
        />
      </ModalForm>
    );
  }
}

export default connect(
  exportReportSelector,
  {exportExplorer}
)(ExportExplorerModal);
