import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {fetchThemes} from 'modules/searchCampaigns/actions';
import SearchCampaignsPage from '../pages/settings/reportSettingsSearchCampaignsPage';
import SearchCampaignPage from '../pages/settings/reportSettingsSearchCampaignPage';

/*

TODO

Themes
- link text
- pagination?
- search filter?
- breadcrumb?
- new campaign button

Theme
- breadcrumb?
- input styling
- save bar
- campaign info
- 

*/

export class ReportSettingsRoute extends React.Component {
  componentDidMount() {
    this.props.fetchThemes(this.props.match.params.reportId);
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={SearchCampaignsPage} />
        <Route path={`${match.path}/:campaignId`} component={SearchCampaignPage} />
      </Switch>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    fetchThemes: reportId => {
      dispatch(fetchThemes(reportId));
    }
  })
)(ReportSettingsRoute);
