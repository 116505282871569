import React from 'react';
import CodeComponent from 'components/code';
import styles from './decisionName.scss';
import Fields from 'components/fields';
import Pill from 'components/pill';
import Url from 'components/url/url';

export default class DecisionName extends React.Component {
  renderContent = () => {
    const {content, contentType} = this.props.record;

    switch (contentType) {
      case 'html':
        return <CodeComponent language="html">{content}</CodeComponent>;
      case 'code':
        return <code>{content}</code>;
      case 'text':
        return <div>{content}</div>;
      case 'url':
        return <Url url={content} link />;
      case 'fields':
        try {
          return <Fields fields={JSON.parse(content)} max="2" />;
        } catch (error) {
          // JSON parse can fail, if truncacted; should never cause to crash
          return null;
        }
      default:
        return null;
    }
  };

  render() {
    const {data, record} = this.props;
    const {isAccount} = record;

    return (
      <div>
        <div className={styles.decisionName}>
          {data}{' '}
          {isAccount && (
            <Pill appearance="major" tiny className={styles.pill}>
              Account
            </Pill>
          )}
        </div>
        {record.content && <div className={styles.content}>{this.renderContent()}</div>}
      </div>
    );
  }
}
