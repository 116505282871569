import React from 'react';
import Icon from 'components/font-awesome';
import styles from './pageHeader.scss';
import cx from 'classnames';

export default class PageHeader extends React.Component {
  static defaultProps = {
    inline: []
  };

  render() {
    const {children, title, style, inline, className, afterTitle, goBack} = this.props;

    return (
      <div className={cx(styles.topBarWrapper, className)} style={style}>
        <div className={styles.topBar}>
          {goBack && (
            <Icon name="chevron-left" size="2x" className={styles.headerIcon} onClick={goBack} />
          )}
          <h1
            className={cx(styles.pageTitle, {
              [styles.withChildren]: !!inline.length
            })}
          >
            {title}
            {afterTitle}
          </h1>

          {inline.map(
            child =>
              child &&
              React.cloneElement(child, {className: cx(child.props.className, styles.inlineChild)})
          )}
        </div>

        {children}
      </div>
    );
  }
}
