import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {RESET_TOKEN, resetTokenComplete} from 'modules/passwords';
import {resetTokenEndpoint} from 'modules/passwords/passwordUtils';

export default function* resetTokenSaga() {
  yield takeLatest(RESET_TOKEN, resetToken);
}

function* resetToken({userId}) {
  const postData = {userId};

  yield processRequest(
    'POST',
    resetTokenEndpoint(),
    {
      success: function*(data) {
        yield put(resetTokenComplete());
      },

      error: function*(err) {
        yield put(resetTokenComplete(false));
      }
    },
    postData
  );
}
