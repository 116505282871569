import {FETCH_AUDIT_LOGS} from 'modules/auditLog/constants';
import {fetchAuditLogEndpoint} from 'modules/auditLog/utils';
import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {fetchAuditLogsComplete} from '../actions';

export default function* fetchAuditLogsListener() {
  yield takeLatest(FETCH_AUDIT_LOGS, fetchAuditLogs);
}

function* fetchAuditLogs({filter}) {
  yield processRequest(
    'GET',
    fetchAuditLogEndpoint(),
    {
      *success(auditLogs) {
        yield put(fetchAuditLogsComplete({auditLogs}));
      },
      *error(err) {
        yield put(fetchAuditLogsComplete(err));
      }
    },
    filter
  );
}
