import React from 'react';
import Grid from 'components/grid';
import Center from 'components/center';
import Button from 'components/button';
import Status from 'components/status';
import Spinner from 'components/spinner';
import ReportCard from 'modules/reports/components/reportCard';
import styles from './search.scss';
import EmptyFolderView from 'modules/folders/components/emptyFolderView';
import {RECENT_REPORTS_LIMIT} from '../constants';
import {reportPath} from 'modules/reports/reportUtils';
import useReactRouter from 'use-react-router';

export default function SearchResults({term, label, loading, results, onLoadMore, noMore}) {
  const {match} = useReactRouter();

  if (loading && !results.length) return <Spinner marginTop={100} />;

  return (
    <div className={styles.searchResults}>
      {results.length ? (
        <div>
          <h3>{label}</h3>
          <Grid noEdges>
            {results.map(report => {
              return (
                <ReportCard
                  key={report.reportId}
                  report={report}
                  href={reportPath({...match.params, reportId: report.reportId})}
                />
              );
            })}
          </Grid>
          {!noMore && onLoadMore && results.length >= RECENT_REPORTS_LIMIT && (
            <Center marginTop={20}>
              <Button wire isLoading={loading} onClick={onLoadMore}>
                Show more
              </Button>
            </Center>
          )}
        </div>
      ) : term ? (
        <Status appearance="bad">Nothing found matching "{term}"</Status>
      ) : (
        <EmptyFolderView />
      )}
    </div>
  );
}
