import {put, takeLatest} from 'redux-saga/effects';
import {reportPermissionsEndpoint} from 'modules/reportPermissions/reportPermissionUtils';
import {VIEW_PERMISSIONS_CONFIG_PAGE, receivePermissions} from 'modules/reportPermissions';
import fetch from 'utils/saga/fetch';
import {processResponse} from 'utils/saga/fetchUtils';
import {fetchUsers} from 'modules/users';

export default function* fetchPermissionsSaga() {
  yield takeLatest(VIEW_PERMISSIONS_CONFIG_PAGE, fetchPermissions);
}

function* fetchPermissions({reportId}) {
  const res = yield fetch(reportPermissionsEndpoint({reportId}));
  yield processResponse(res, {
    success: function*(permissions) {
      yield put(receivePermissions(permissions));
    }
  });

  // Whenever we're dealing with permissions, we also need to have loaded
  // all users.
  yield put(fetchUsers());
}
