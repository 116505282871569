// Need to import select styles manually because: https://github.com/JedWatson/react-select/issues/602
import 'react-select/dist/react-select.css';
import '../../../styles/app.scss';

import AlertsContainer from 'components/alerts/alertsContainer';
import {userSelector} from 'modules/auth/authSelectors';
import {isSuperUser} from 'modules/auth/gandalf';
import IntercomContainer from 'modules/intercom/intercomContainer';
import ModalContainer from 'modules/modal/containers/modalContainer';
import React from 'react';
import {connect} from 'react-redux';

export class RootLayout extends React.Component {
  render() {
    return (
      <div>
        <ModalContainer />
        {!__DEV__ && <IntercomContainer />}
        <AlertsContainer />
      </div>
    );
  }
}

export default connect(state => {
  const user = userSelector(state);
  return {
    userId: user.get('userId'),
    isSuper: isSuperUser(user)
  };
})(RootLayout);
