import React from 'react';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import SecondaryNavigation from 'modules/app/components/secondaryNavigation/secondaryNavigation';

// TODO pull this out
import styles from 'modules/app/routes/loggedInRoute.scss';

function WithBreadcrumbHeader({children, location, match, reportId, testId, push}) {
  const secondaryNavigation = (
    <SecondaryNavigation location={location} match={match} reportId={reportId} testId={testId} />
  );

  return (
    <PageWithFixed
      orientation="vertical"
      fixedElement={secondaryNavigation}
      fixedSize={styles.mainHeader}
    >
      {children}
    </PageWithFixed>
  );
}

export default WithBreadcrumbHeader;
