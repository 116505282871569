exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3N6C-_TWwnYUl2CEP1Oqd6{flex:1;display:flex;min-height:calc(100vh - calc(45px + (5px * 2)));height:calc(100vh - calc(45px + (5px * 2)))}", ""]);

// exports
exports.locals = {
	"pageViewport": "_3N6C-_TWwnYUl2CEP1Oqd6"
};