exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2JTXNQ3utHI1YlHTurVWXa{padding:0 25px;margin-top:20px}._1HKiw0khdyH1M4V2Bkt8i5{margin:25px 35px}._1tj7Xll1uVRffJcJlQXt8k{min-width:240px !important;max-width:500px !important}._132HQt72YzpFOUBZ4NA-Gj{margin-bottom:20px}._3sWh695VEPyFpbzSUsNuN7{margin-right:20px}._3mcMEbAiCd9LkmSafl-1t9{margin:10px}", ""]);

// exports
exports.locals = {
	"filterBar": "_2JTXNQ3utHI1YlHTurVWXa",
	"message": "_1HKiw0khdyH1M4V2Bkt8i5",
	"testsFilter": "_1tj7Xll1uVRffJcJlQXt8k",
	"marginBottom": "_132HQt72YzpFOUBZ4NA-Gj",
	"marginRight": "_3sWh695VEPyFpbzSUsNuN7",
	"resultsPerPage": "_3mcMEbAiCd9LkmSafl-1t9"
};