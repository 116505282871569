import {fromJS} from 'immutable';
import {SWITCH_ACCOUNT, LOGOUT} from 'modules/auth';

const initialState = fromJS({
  loading: false,
  results: {
    reports: []
  },
  term: ''
});

export default function omnisearchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH:
      if (action.term == '') {
        return initialState;
      }
      return state.set('loading', true).set('term', action.term);

    case RECEIVE_SEARCH_RESULTS:
      return state.set('loading', false).set('results', fromJS(action.results));

    case SWITCH_ACCOUNT:
    case LOGOUT:
      return initialState;
  }

  return state;
}

export const SEARCH = 'omnisearch:SEARCH';
export const search = term => ({type: SEARCH, term});

export const RECEIVE_SEARCH_RESULTS = 'omnisearch:RECEIVE_SEARCH_RESULTS';
export const receiveSearchResults = results => ({
  type: RECEIVE_SEARCH_RESULTS,
  results
});
