import {put, select, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {
  FETCH_USER_ACHIEVEMENTS,
  fetchUserAchievementsComplete,
  recieveUserAchievements
} from 'modules/achievements';
import {userAchievementsEndpoint} from 'modules/achievements/achievementUtils';

export default function* fetchUnreadAchievementsSaga() {
  yield takeEvery(FETCH_USER_ACHIEVEMENTS, function*({userId}) {
    try {
      const achievements = yield requestUserAchievements({userId});

      yield put(recieveUserAchievements(achievements));
      yield put(fetchUserAchievementsComplete());
    } catch (err) {
      yield put(fetchUserAchievementsComplete(err && err.message));
    }
  });
}

export function* requestUserAchievements({userId}) {
  return yield processRequest('GET', userAchievementsEndpoint({userId}), {
    success: function*(achievements) {
      return achievements;
    },
    error: function*(err) {
      throw err;
    }
  });
}
