import React from 'react';
import {List} from 'immutable';
import StageSummary from './stageSummary';

export default function CourseSummary({course, enrolled}) {
  const stages = course.get('stages', List());

  return (
    <div>
      {stages.map(stage => (
        <StageSummary key={stage.get('stageId')} stage={stage} disabled={!enrolled} />
      ))}
    </div>
  );
}
