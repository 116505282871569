exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Q-lohB2iVnitw5FP4hz58{position:relative;height:100px;width:100%;display:flex;justify-content:center}._1HtNqJZr3S77UDeTTaXTkU{position:absolute;top:10px;right:10px;cursor:pointer;transition:all .2s ease-in-out}._1HtNqJZr3S77UDeTTaXTkU:hover{-webkit-transform:scale(1.2);transform:scale(1.2);color:#333}", ""]);

// exports
exports.locals = {
	"accountBrandingLogo": "_1Q-lohB2iVnitw5FP4hz58",
	"deleteIcon": "_1HtNqJZr3S77UDeTTaXTkU"
};