import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {CONFIRM_DELETE_WIDGET} from 'modules/dashboards/constants';
import {dashboardEndpoint, removeWidgetFromDashboard} from 'modules/dashboards/utils';
import {dashboardSelector} from 'modules/dashboards/selectors';
import {confirmDeleteWidgetComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import confirm from 'utils/saga/confirm';

export default function* confirmDeleteWidgetListener() {
  yield takeLatest(CONFIRM_DELETE_WIDGET, confirmDeleteWidgetSaga);
}

function* confirmDeleteWidgetSaga({dashboardId, widgetId}) {
  const shouldDelete = yield confirm({
    title: 'Are you sure?',
    content: 'Are you sure you wish to delete this widget?',
    submitLabel: 'Yes',
    cancelLabel: 'No',
    destructiveConfirm: true
  });

  if (!shouldDelete) return;

  const url = dashboardEndpoint({dashboardId});

  // The endpoint requires us to pass all the data for a dashboard each time.
  // It would be nice if we could edit a particular widget, but y'know...
  const dashboard = yield select(dashboardSelector, {dashboardId});
  const newDashboard = yield call(removeWidgetFromDashboard, dashboard, {widgetId});

  yield processRequest(
    'PUT',
    url,
    {
      *success(dashboard) {
        yield put(confirmDeleteWidgetComplete(null, dashboard));
      },
      *error(err) {
        yield put(confirmDeleteWidgetComplete(err));
      },
      errorMessage: couldNot('delete widget')
    },
    newDashboard.toJS()
  );
}
