import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import styles from './entitySelect.scss';
import cx from 'classnames';
import Icon from 'components/font-awesome';

const propTypes = {
  children: PropTypes.node,
  buttonDisabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isDragging: PropTypes.bool,
  dragEnabled: PropTypes.bool
};

const EntitySelectorListItem = ({
  children,
  buttonDisabled,
  value,
  label,
  onClick,
  buttonText,
  isDragging,
  dragEnabled,
  dark
}) => {
  const dragClass = isDragging ? styles.listItemDrag : null;

  const classNames = {
    [styles.darkListItem]: dark
  };

  return (
    <div
      className={cx(styles.listItem, dragClass, dragEnabled ? styles.draggable : null, classNames)}
      onClick={() => {
        onClick(value);
      }}
    >
      <span className={styles.icon}>
        <Icon name="grip-vertical" />
      </span>
      {children}
      <span>{label}</span>
      <Button disabled={buttonDisabled}>{buttonText}</Button>
    </div>
  );
};

EntitySelectorListItem.propTypes = propTypes;

EntitySelectorListItem.defaultProps = {
  isDragging: false
};

export default EntitySelectorListItem;
