import {take, call, put, takeEvery} from 'redux-saga/effects';
import {REQUEST_SUBSCRIPTIONS} from 'modules/subscriptions';
import {receiveStats} from 'modules/stats';
import {processRequest} from 'utils/saga/fetchUtils';
import {receiveSubscriptions, requestSubscriptionsComplete} from 'modules/subscriptions';
import {subscriptionsEndpoint} from 'modules/subscriptions/subscriptionUtils';

export default function* fetchStatsSaga() {
  yield takeEvery(REQUEST_SUBSCRIPTIONS, requestSubscriptions);
}

function* requestSubscriptions() {
  return yield processRequest('GET', subscriptionsEndpoint(), {
    success: function*(data) {
      yield put(receiveSubscriptions(data));
      yield put(requestSubscriptionsComplete());
    },
    error: function*(err) {
      yield put(requestSubscriptionsComplete(err));
    }
  });
}
