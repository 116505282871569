import cx from 'classnames';
import Button from 'components/button';
import Icon from 'components/font-awesome';
import SearchBar from 'components/searchBar';
import Spinner from 'components/spinner';
import Toggle from 'components/toggle';
import KeyCodes from 'constants/keyCodes';
import _ from 'lodash';
import ExternalLink from 'modules/location/externalLink';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {havePropsChanged} from 'utils/propUtils';
import styles from './dropdown.scss';

const initialState = {
  highlightedIdx: -1,
  toggle: null
};

const DropdownContext = React.createContext();

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = React.createRef();
  }

  static mapOptionsObject = (optionsObject, labelMapper = v => v) => {
    return Object.keys(optionsObject).map(key => {
      return {label: labelMapper(optionsObject[key]), value: key};
    });
  };

  static defaultProps = {};

  state = initialState;

  componentWillReceiveProps(nextProps) {
    if (havePropsChanged(nextProps, this.props, ['options'])) {
      this.setState(initialState);
    }
  }

  componentWillUnmount() {
    this.stopListeners();
  }

  startListeners() {
    document.addEventListener('keyup', this.handleKeyUp, false);
  }

  stopListeners() {
    document.removeEventListener('keyup', this.handleKeyUp, false);
  }

  // Helper so we can do this.dropdown.close() from a parent.
  close() {
    this.toggle && this.toggle.close && this.toggle.close();
  }

  highlight(idx) {
    const highlightedIdx =
      idx < 0 ? this.props.options.length - 1 : Math.abs(idx) % this.props.options.length;

    return this.setState({highlightedIdx});
  }

  handleKeyUp = e => {
    switch (e.keyCode) {
      case KeyCodes.UP:
      case KeyCodes.DOWN:
        e.stopPropagation();
        this.highlight(this.state.highlightedIdx + (KeyCodes.DOWN ? 1 : -1));
        break;

      case KeyCodes.ENTER:
        this.selectHighlighted();
        break;
    }
  };

  selectHighlighted() {
    const {highlightedIdx} = this.state;
    return this.handleClick(this.props.options[highlightedIdx].value);
  }

  handleOpen = event => {
    this.setState({toggle: this.toggle});
    const {onOpen} = this.props;
    this.startListeners();
    onOpen && onOpen(event);
  };

  handleClose = event => {
    const {onClose, onFilter} = this.props;
    this.stopListeners();

    // Unfilter on close
    onFilter && onFilter('');

    // Reset selected index etc.
    this.setState(initialState);

    onClose && onClose(event);
  };

  handleClick(value, event) {
    const {onChange} = this.props;
    if (this.props.stopPropagation) {
      event.stopPropagation();
    }
    onChange(value, event);
    this.close();
  }

  renderOptions() {
    const {options, value: selectedValue} = this.props;
    const {highlightedIdx} = this.state;

    return options.map(({label, value}, idx) => {
      const isHighlighted = highlightedIdx === idx;

      return (
        <DropdownOption
          key={'dropdownOpt_' + value}
          isActive={value === selectedValue}
          isHighlighted={isHighlighted}
          onClick={e => {
            this.handleClick(value, e);
          }}
        >
          {label}
        </DropdownOption>
      );
    });
  }

  renderButton() {
    const {dark, button} = this.props;

    if (!dark || typeof button !== 'string') {
      return button;
    }

    // wrap button to make dark
    return <Button dark>{button}</Button>;
  }

  render() {
    const {
      options = [],
      onFilter,
      className,
      filter,
      children,
      dark,
      bodyClassName,
      bodyOptionsClassName,
      stopPropagation,
      isLoading,
      hideOnEmpty,
      ...props
    } = this.props;
    const hasChildren = !!(Array.isArray(children) ? children.length : children);
    const hasOptions = !!options.length;
    const hasContent = hasChildren || hasOptions;
    if (!hasContent && hideOnEmpty) return null;

    return (
      <Toggle
        className={className}
        button={this.renderButton()}
        ref={this.toggle}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        {...props}
      >
        <DropdownContext.Provider value={this.state.toggle && this.state.toggle.current}>
          <DropdownBody dark={dark} className={bodyClassName}>
            {onFilter && (
              <SearchBar filter={filter} onUserInput={onFilter} className={styles.searchBar} />
            )}
            <div className={cx(styles.dropdownOptions, bodyOptionsClassName)}>
              {hasChildren ? (
                children
              ) : hasOptions ? (
                this.renderOptions()
              ) : isLoading ? (
                <Spinner marginTop="40px" marginBottom="40px" />
              ) : (
                <li className={styles.dropdownNoOptions}>No results found.</li>
              )}
            </div>
          </DropdownBody>
        </DropdownContext.Provider>
      </Toggle>
    );
  }
}

export function DropdownOption({
  id,
  href,
  icon,
  onClick = () => {},
  divider,
  external,
  children,
  className,
  isActive,
  isHighlighted
}) {
  const toggle = useContext(DropdownContext);
  const classes = cx(styles.dropdownOption, className, {
    [styles.divider]: divider,
    [styles.isActive]: isActive,
    [styles.isHighlighted]: isHighlighted
  });

  if (divider) return <div className={classes} />;

  return (
    <div
      id={id}
      className={classes}
      onClick={e => {
        if (toggle) toggle.close();
        onClick(e);
      }}
      role="menuitem"
      tabIndex="-1"
    >
      {icon && <Icon className={styles.dropdownOptionIcon} name={icon} />}
      {href ? (
        external ? (
          <ExternalLink href={href}>{children}</ExternalLink>
        ) : (
          <Link to={href}>{children}</Link>
        )
      ) : (
        // wrap in a a tag so we can consisently style menu items that use onClick
        <a>{children}</a>
      )}
    </div>
  );
}

export function DropdownBody({className, dark, ...props}) {
  const classes = cx(styles.dropdownBody, className, {
    [styles.dark]: dark
  });

  return <div className={classes} {...props} />;
}
