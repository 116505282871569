import React from 'react';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import Control from './control';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Dropdown from 'components/dropdown';
import styles from './control.scss';
import {reportSelector} from 'modules/reports/reportSelectors';
import {ROLE_CONTRIBUTOR, reportIsAllowed} from 'modules/gandalf/gandalfUtils';

const actionSidebarSelector = createSelector(
  reportSelector,
  report => {
    return {
      reportRole: report.getIn(['ownPermissions', 'role'])
    };
  }
);

export class Controls extends React.Component {
  static defaultProps = {
    filters: {},
    small: false
  };

  button = (
    <Button
      small={this.props.small}
      square={this.props.small}
      wire
      narrow={!this.props.small}
      icon="caret-down"
    />
  );

  renderControl = (control, idx) => {
    return (
      <Control
        key={idx}
        {...control}
        small={this.props.small}
        className={styles.control}
        context={this.props.context}
        filters={this.props.filters}
      />
    );
  };

  render() {
    const {children, className, controls, small} = this.props;
    const {button: buttons, dropdown: dropdownOptions} = controls.reduce(
      (groups, control) => {
        if (Array.isArray(groups[control.type])) {
          groups[control.type].push(control);
        }
        return groups;
      },
      {button: [], dropdown: []}
    );

    const isContributor = reportIsAllowed(ROLE_CONTRIBUTOR, this.props.reportRole);

    const isAllDropdownOptionsHidden = controls
      .filter(c => c.type === 'dropdown')
      .every(ctrl => {
        const {value} = ctrl.instruction.type;
        return (value === 'decision' || value === 'undoDecision') && !isContributor;
      });

    return (
      <Buttons centered noMargin={small} className={className}>
        {children}
        {buttons.map(this.renderControl)}
        {!isAllDropdownOptionsHidden && !!dropdownOptions.length && (
          <Dropdown button={this.button}>{dropdownOptions.map(this.renderControl)}</Dropdown>
        )}
      </Buttons>
    );
  }
}

export default connect(actionSidebarSelector)(Controls);
