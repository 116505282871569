import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import ExternalLink, {push} from 'modules/location';
import Icon from 'components/font-awesome';
import PageScreenshot from './pageScreenshot';
import IssueCount from 'components/issueCount';
import FullDate from 'components/time/fullDate';
import ChildSwapper from 'components/wrappers/childSwapper';
import RelativeDate from 'components/time/relativeDate';
import CircularSpinner from 'components/spinner/circularSpinner';
import {getAllIssueCountsFromObject, hasIssues} from 'modules/inspector/issueUtils';
import styles from './pageCard.scss';

export class PageCard extends React.Component {
  getActionIcon() {
    return <Icon name="arrow-circle-right" aria-hidden="true" />;
  }

  renderTitle() {
    const {pageName, url, requestTime, userJourneyId, firstAdded, nextDue} = this.props.page;
    const now = new Date() / 1000;

    const classes = cx(styles.title, {[styles.actionPage]: userJourneyId});

    return (
      <div>
        <h2 className={classes}>
          {!userJourneyId && nextDue < now && this.getSpinner()}
          <ExternalLink href={url}>
            {userJourneyId > 0 && this.getActionIcon()} {pageName || url}
          </ExternalLink>
        </h2>
        <p>
          <RelativeDate timestamp={requestTime} prefix="Updated " />
        </p>
      </div>
    );
  }

  renderImage() {
    const {page} = this.props;
    const {changes, screenshotUrl, screenshotAspectRatio} = page;

    const defaultProps = {
      className: styles.screenshot
    };

    if (!changes) {
      return (
        <PageScreenshot src={screenshotUrl} aspectRatio={screenshotAspectRatio} {...defaultProps} />
      );
    }

    return (
      <ChildSwapper humanizeInterval>
        {_.map(changes, (change, timestamp) => {
          const {screenshotUrl} = change;

          return (
            <div style={{background: 'white'}}>
              <PageScreenshot src={screenshotUrl} {...defaultProps} />
              <small>
                <FullDate className={styles.time} timestamp={timestamp} />
              </small>
            </div>
          );
        })}
      </ChildSwapper>
    );
  }

  getSpinner() {
    return (
      <div className={styles.spinner}>
        <CircularSpinner small />
      </div>
    );
  }

  render() {
    const {page, reportId, ...props} = this.props;
    const issueCounts = getAllIssueCountsFromObject(page);

    return (
      <div className={styles.pageCard} {...filterInvalidDOMProps(props)}>
        {this.renderImage()}
        <div className={styles.caption}>
          <div className={styles.captionLeft}>{this.renderTitle()}</div>

          {hasIssues(issueCounts) && (
            <div className={styles.captionRight}>
              <IssueCount onClick={this.handleClick} {...issueCounts} singular />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PageCard.propTypes = {
  page: PropTypes.shape({
    pageName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    lastChecked: PropTypes.number,
    screenshotUrl: PropTypes.string, // this or changes
    changes: PropTypes.array,
    maxPercentage: PropTypes.number
  }).isRequired,
  pages: PropTypes.array.isRequired,
  reportId: PropTypes.string
};

export default connect(
  null,
  {push}
)(PageCard);
