import React from 'react';
import {connect} from 'react-redux';
import {fetchReportLogs} from 'modules/logs';
import {fetchUsers} from 'modules/users';
import ReportLogPage from '../pages/reportLogPage';

export class ReportLogRoute extends React.Component {
  componentWillMount() {
    this.props.fetchReportLogs();
    this.props.fetchUsers();
  }

  render() {
    const {
      match: {params}
    } = this.props;
    return <ReportLogPage params={params} />;
  }
}

export default connect(
  null,
  {fetchReportLogs, fetchUsers}
)(ReportLogRoute);
