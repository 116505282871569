import React from 'react';
import styles from './stageSummary.scss';
import LessonSummary from './lessonSummary';
import cx from 'classnames';

export default function StageSummary({stage, disabled}) {
  const classNames = {
    [styles.stage]: true,
    [styles.disabled]: disabled
  };

  return (
    <div key={stage.get('stageId')} className={cx(classNames)}>
      <h3>{stage.get('name')}</h3>
      <div className={styles.lessons}>
        {stage.get('lessons').map(lesson => (
          <LessonSummary key={lesson.get('lessonId')} lesson={lesson} disabled={disabled} />
        ))}
      </div>
    </div>
  );
}
