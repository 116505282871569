import React from 'react';
import Spinner from 'components/spinner';
import Helmet from 'components/helmet';
import ResetPasswordForm from './resetPasswordForm';
import styles from 'modules/auth/components/authWrapperShared.scss';

export default class ResetPasswordPage extends React.Component {
  static defaultProps = {
    user: {}
  };

  renderResetPasswordForm() {
    const {
      requestingSetPassword,
      onSetNewPassword,
      setPasswordSuccess,
      setPasswordFailed,
      user,
      errorMessage
    } = this.props;

    if (requestingSetPassword || setPasswordSuccess || setPasswordFailed) {
      return null;
    }

    return (
      <div>
        <p className={styles.header}>Choose a password</p>

        <p className={styles.lead}>
          Please ensure that your password does not contain readily identifiable information. e.g.,
          license plate numbers, spouse's name, date of birth and home address. Do not use the same
          password as your email address.
        </p>

        <ResetPasswordForm onValidSubmit={onSetNewPassword} errorMessage={errorMessage} />
      </div>
    );
  }

  handleLoginRedirect() {
    this.props.push('/');
  }

  renderSuccessMessage() {
    if (!this.props.setPasswordSuccess) {
      return null;
    }

    return (
      <div>
        <Spinner centered />

        <p className={styles.text}>One moment</p>
      </div>
    );
  }

  renderFailedMessage() {
    const {setPasswordFailed} = this.props;

    if (!setPasswordFailed) {
      return null;
    }

    return <p className={styles.text}>Failed to set password, please try again later.</p>;
  }

  render() {
    const {requestingSetPassword, className} = this.props;

    return (
      <div className={className}>
        <Helmet title="Reset password" />

        {requestingSetPassword && <Spinner centered />}

        {this.renderSuccessMessage()}
        {this.renderFailedMessage()}
        {this.renderResetPasswordForm()}
      </div>
    );
  }
}
