import React from 'react';
import cx from 'classnames';
import styles from '../pages/homePage.scss';
import Flex from 'components/layout/flex';

export default function HomePageSection({title, className, children, ...props}) {
  return (
    <Flex {...props} className={cx(styles.section, className)}>
      <h3>{title}</h3>
      {children}
    </Flex>
  );
}
