import React from 'react';
import Panel from 'components/panel';
import Button from 'components/button';
import {Field} from 'modules/form/hooks/useForm';
import {makeNewStage} from 'modules/interactions/utils';
import StageField, {StageRow} from 'modules/interactions/components/stageField';

export default function StagesField({field}) {
  const handleCreateStage = event => {
    field.push(makeNewStage('', field.fields.length));
    field.rerender();
  };
  const handleRemoveState = subfield => {
    field.removeIndex(subfield.getKey());
    field.rerender();
  };

  return (
    <React.Fragment>
      <Panel marginBottom>
        <StageRow>
          <b>#</b>
          <b>Stage</b>
          <b>Instruction</b>
        </StageRow>
        <hr />

        <Field field={field} required>
          {field.fields.map(subfield => {
            return <StageField key={subfield.key} field={subfield} onDelete={handleRemoveState} />;
          })}
        </Field>

        <Button icon="plus" onClick={handleCreateStage}>
          Add stage
        </Button>
      </Panel>
    </React.Fragment>
  );
}
