import {Accordion, AccordionItem} from 'components/accordion';
import Button from 'components/button/button';
import Icon from 'components/font-awesome';
import ContactUsModal from 'components/modal/contactUsModal';
import Spinner from 'components/spinner/circularSpinner';
import Status from 'components/status';
import ConfirmationModal from 'modals/confirmationModal';
import ExternalLink from 'modules/location/externalLink';
import {showInModal} from 'modules/modal';
import QuoteDataCollector from 'modules/quote/components/quoteDataCollector';
import quoteStyles from 'modules/quote/pages/quoteViewPage.scss';
import {getSubConfig} from 'modules/quote/quoteUtils';
import {fetchSuperProducts} from 'modules/superProduct/superProductActions';
import {requestQuotes} from 'modules/superProduct/superProductActions';
import {requestBuyPlan} from 'modules/superProduct/superProductActions';
import {pricingCalculatorPageSelector} from 'modules/superProduct/superProductSelectors';
import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import EnterprisePricing from '../components/EnterprisePricing';
import PricingCalculator from '../components/pricingCalculator';
import {helpMap} from '../utils/helpMap';
import styles from './pricingCalculatorPage.scss';

export class PricingCalculatorPage extends React.Component {
  state = {
    showDataCollection: false,
    pricingCalculationValues: null,
    selectedPlanId: null,
    selectedPlanIndex: null,
    enterpriseActive: false
  };

  componentWillMount() {
    this.props.fetchSuperProducts();
  }

  buyPlan = (formData, failCallback) => {
    const {product} = this.props;

    this.props.requestBuyPlan(
      product.productId,
      this.state.selectedPlanId,
      this.state.pricingCalculationValues,
      formData,
      {country: formData.country, vatNumber: formData.vatNumber},
      failCallback
    );
  };

  triggerContactUsModal = () => {
    this.props.showInModal(ContactUsModal, {
      title: 'Talk to us',
      contactType: 'ecommerce-sales'
    });
  };

  recalculateQuote = formData => {
    const {product} = this.props;

    this.props.requestQuotes(product.productId, getSubConfig(this.state.pricingCalculationValues), {
      country: formData.country,
      vatNumber: formData.vatNumber
    });
  };

  showHelp = helpId => {
    const HelpComponent = helpMap[helpId];

    this.props.showInModal(ConfirmationModal, {
      title: '',
      content: <HelpComponent />,
      submitLabel: 'Close'
    });
  };

  switchTab = () => {
    const {enterpriseActive} = this.state;
    this.setState({enterpriseActive: !enterpriseActive});
  };

  renderDataCollection = () => {
    const {quotes, account, quoteError, loadingQuotes} = this.props;
    const {selectedPlanId, selectedPlanIndex} = this.state;

    const quoteForPlan =
      Object.keys(quotes).length > 0 &&
      quotes[selectedPlanIndex].find(quote => quote.plan.planId === selectedPlanId);

    return (
      <div className={quoteStyles.dataWrapper}>
        <QuoteDataCollector
          title={'Your details'}
          quoteTable={quoteForPlan ? quoteForPlan.quoteTable : null}
          quote={quoteForPlan ? quoteForPlan.plan : null}
          quoteAccount={account}
          checkout={this.buyPlan}
          isRecalculating={loadingQuotes}
          recalcError={quoteError}
          isEnterprise={false}
          onRecalcRequired={this.recalculateQuote}
          onCancel={() => this.setState({showDataCollection: false})}
        />
      </div>
    );
  };

  renderHelmet = () => {
    return (
      <Helmet>
        <title>Silktide pricing</title>
        <meta
          name="description"
          value="Find our what Silktide costs for you. Pricing calculator for Silktide, allowing for variable modules, number of pages and more."
        />
      </Helmet>
    );
  };

  renderContent = () => {
    const {enterpriseActive} = this.state;
    const {isLoadingProduct, loadingError, product} = this.props;

    if (this.state.showDataCollection) {
      return this.renderDataCollection();
    }

    if (isLoadingProduct) {
      return (
        <div style={{marginTop: '20px'}}>
          <Spinner centered />
        </div>
      );
    }

    if (loadingError || !product) {
      return (
        <Status appearance={'bad'}>
          There was an error trying to load this product. Please try again later.
        </Status>
      );
    }

    return (
      // TOPBAR TODO MAKE COMPONENT
      <div>
        <div className={styles.topBar}>
          <div className={styles.topBarContent}>
            <div className={styles.logo}>
              <ExternalLink href="https://silktide.com/">
                <img src="/silktide-logo-white.svg" />
              </ExternalLink>
            </div>
            <div className={styles.homeLink}>
              <ExternalLink href="https://silktide.com/">
                <Icon name="arrow-left" size="lg" />
                Back to website
              </ExternalLink>
            </div>
          </div>
        </div>

        <div className={styles.flex}>
          <div className={styles.tabs}>
            <button
              onClick={this.switchTab}
              className={enterpriseActive ? styles.tabButton : styles.tabButtonActive}
            >
              <img src="/icons/card.svg" />
              Standard
            </button>
            <button
              onClick={this.switchTab}
              className={!enterpriseActive ? styles.tabButton : styles.tabButtonActive}
            >
              <img src="/icons/building.svg" />
              Advanced
            </button>
          </div>

          <div className={styles.pricingWrapper}>
            {!enterpriseActive ? (
              <PricingCalculator
                product={product}
                onContactUs={() => window.open('https://silktide.com/request-a-demo/', '_blank')}
                onSelectHelp={this.showHelp}
                onSelectBuy={(values, selectedPlanId, selectedPlanIndex) =>
                  this.setState({
                    selectedPlanId,
                    selectedPlanIndex,
                    showDataCollection: true,
                    pricingCalculationValues: values
                  })
                }
              />
            ) : (
              <EnterprisePricing
                onClickContact={() => {
                  this.triggerContactUsModal();
                }}
                onClickReports={this.showHelp}
              />
            )}
          </div>

          <div className={styles.accordionWrapper}>
            <h4 className={styles.accordionTitle}>Frequently asked questions</h4>
            <Accordion>
              <AccordionItem title="What's the commitment?">
                <p>Depending on how you signed up, either one month or one year.</p>
                <p>
                  When you sign up you choose to pay either monthly or annually. You can cancel at
                  any time, and you won't be charged again after that point.
                </p>
              </AccordionItem>
              <AccordionItem title="Can I test more than one website?">
                <p>
                  Yes. You can test multiple websites, but the maximum number of websites you can
                  monitor is one fiftieth (1/50th) of the number of pages you buy. So for example,
                  if you buy 100 webpages you can test two websites.
                </p>
              </AccordionItem>
              <AccordionItem title="What if I need more webpages later?">
                <p>
                  You can upgrade your plan at any time. You'll be pro-rated for the period you're
                  in.
                </p>
              </AccordionItem>
              <AccordionItem title="What payment methods do you support?">
                <p>
                  All major credit and debit cards are supported, including VISA, Mastercard, and
                  AMEX.
                </p>
              </AccordionItem>
              <AccordionItem title="Do you offer any discounts for non-profits?">
                <p>Yes. Click the "Talk to us" button above and tell us who you are.</p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.wrapper}>
        {this.renderHelmet()}
        {this.renderContent()}
      </div>
    );
  }
}

export default connect(
  pricingCalculatorPageSelector,
  {fetchSuperProducts, requestBuyPlan, requestQuotes, showInModal}
)(PricingCalculatorPage);
