import React from 'react';
import {connect} from 'react-redux';
import ActionContainer from '../components/actionContainer';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import Status from 'components/status';

export class ActionPage extends React.Component {
  render() {
    const {
      isArchiveReport,
      match: {
        params: {reportId, actionId, testId}
      }
    } = this.props;

    if (isArchiveReport) {
      return <Status appearance="bad">Cannot fetch results for an archived report</Status>;
      1;
    }

    return <ActionContainer reportId={reportId} actionId={actionId} testId={testId} />;
  }
}

export default connect((state, props) => ({
  isArchiveReport: isArchiveActiveSelector(state, props)
}))(ActionPage);
