import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import emailValidator from 'email-validator';
import {updateUser} from 'modules/users';
import {trackEvent} from 'modules/tracking';
import {ModalForm, Field} from 'modules/form/components';
import ConfirmationModal from 'modals/confirmationModal/confirmationModal.js';
import Gandalf from 'components/gandalf';
import {shallPass, ACCOUNT_OWNER, REGULAR_USER} from 'modules/auth/gandalf';
import styles from './editUserModal.scss';

export class EditUserModal extends React.Component {
  static defaultProps = {
    defaultValue: {
      // used to tell if adding/updating - not a field
      userId: null,

      name: '',
      email: '',
      role: REGULAR_USER,

      // only for ACCOUNT_OWNERs and above
      password: '',
      confirmPassword: ''
    }
  };

  state = {
    isValid: false,
    showConfirm: false,
    passwordMatches: true, // hide message initially
    blurConfirm: false
  };

  handleChange = (key, value, data) => {
    const [isValid, messages] = this.form.isFormValid();

    const [isPasswordValid, message] = this.form.fields.password.validate();
    const passwordMatches = !data.password || data.password === data.confirmPassword;

    this.setState({
      passwordMatches,
      isValid: isValid && passwordMatches,
      showConfirm: isPasswordValid && data.password
    });
  };

  setModal = ref => {
    this.modal = ref;
    this.form = ref ? this.modal.form : null;
  };

  handleSubmit = (data, isValid) => {
    if (!isValid) return;

    const {
      addUser,
      updateUser,
      hideModal,
      defaultValue: {userId}
    } = this.props;

    // ensure password matches.
    if (data.password !== data.confirmPassword) {
      this.setState({passwordMatches: false});
      return;
    }
    this.setState({passwordMatches: true});

    if (userId == null) {
      addUser(data);
    } else {
      updateUser(userId, data);
    }

    trackEvent('Updated user', {
      modal: 'editUserModal',
      userUpdated: `${data.name} (${data.email})`
    });

    // TODO this should only be done on success
    hideModal();
  };

  handleBlur = () => {
    if (!this.state.blurConfirm) {
      this.setState({blurConfirm: true});
    }
  };

  render() {
    const {name, defaultValue, isEditingSelf, allowResetPassword, allowEditRole} = this.props;

    return (
      <ModalForm
        name={name}
        ref={this.setModal}
        submitLabel="Save"
        defaultValue={defaultValue}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        title={isEditingSelf ? 'My settings' : 'Edit user'}
        autoComplete="new-password" // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
      >
        <Field type="text" name="name" label="Name" required />
        <Field type="email" name="email" label="Email" required />
        {allowEditRole && <Field type="role" name="role" label="Role" required />}

        {allowResetPassword && (
          <div>
            <hr className={styles.hr} />
            <h3 className={styles.title}>Reset password (optional)</h3>

            <Field
              label="Password"
              name="password"
              type="passwordStrength"
              autoComplete="new-password"
            />
            {this.state.showConfirm && (
              <Field
                label="Confirm password"
                name="confirmPassword"
                type="password"
                onBlur={this.handleBlur}
                onChange={this.handleBlur}
                invalid={this.state.blurConfirm && !this.state.isValid}
                validationMessage={
                  this.state.blurConfirm && !this.state.passwordMatches
                    ? 'Your passwords do not match'
                    : null
                }
              />
            )}
          </div>
        )}
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {updateUser}
)(EditUserModal);
