import React from 'react';
import styles from './divider.scss';
import cx from 'classnames';

class Divider extends React.Component {
  render() {
    const {plain} = this.props.config;
    const classNames = cx(styles.divider, {[styles.plain]: plain});

    return <h3 className={classNames}>{this.props.children}</h3>;
  }
}

export default Divider;
