import React from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import Table from 'components/table';
import Panel from 'components/panel';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Spinner from 'components/spinner';
import Status from 'components/status/status';
import PageHeader from 'components/header/pageHeader';
import SettingsPage from 'components/settings/settingsPage';
import {tagSettingsPageSelector} from 'modules/tags/selectors';
import {viewTags, requestTags, requestAssignedEntities} from 'modules/tags/actions';
import {getProductNameFromSkus} from 'modules/products/productUtils';
import UnassignEntityTagsButton from 'modules/tags/components/unassignEntityTagsButton';
import styles from './checkSettingsPage.scss';

const REPORT_COLS = [
  {
    label: 'Website name',
    field: 'name',
    type: 'string'
  },
  {
    label: 'Product',
    field: 'sku',
    type: 'jsx',
    jsx: ({data, record, options}) => {
      return getProductNameFromSkus([data]);
    }
  },
  {
    label: '',
    field: '',
    type: 'jsx',
    width: '50',
    jsx: ({tagId, record}) => {
      return (
        <UnassignEntityTagsButton
          entityType="report"
          entityId={record.reportId}
          tagIds={[tagId]}
          title="Remove website from tag group"
          content="Do you want to permanently remove this website from this tag? To add it back, go to the website settings and reassign the tag."
          submitLabel="Remove website"
          destructiveConfirm
        />
      );
    }
  }
];

export class AccountTagSettingsPage extends React.Component {
  componentWillMount() {
    const {
      match,
      requestingTags,
      requestTags,
      requestingAssignedEntities,
      requestAssignedEntities
    } = this.props;

    // in case we come directly to this page
    if (!requestingTags.get('loading') && !requestingTags.get('loaded')) {
      requestTags();
    }

    if (
      !requestingAssignedEntities.get('loading') &&
      requestingAssignedEntities.get('id') !== match.params.tagId
    ) {
      requestAssignedEntities(match.params.tagId, 'report');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tagId !== this.props.match.params.tagId) {
      requestAssignedEntities(this.props.match.params.tagId, 'report');
    }
  }

  getTag() {
    const {tags, match} = this.props;
    return tags.get(match.params.tagId);
  }

  renderHeader() {
    const tag = this.getTag();
    const titleName = tag ? '/ ' + tag.get('label') : '';
    const title = <span>Tag {titleName}</span>;

    return (
      <PageHeader
        title={title}
        goBack={() => {
          this.props.viewTags();
        }}
      />
    );
  }

  renderContent() {
    const {match, assignedEntities, requestingAssignedEntities} = this.props;
    const reports = assignedEntities.getIn([match.params.tagId, 'report', 'rows'], List());

    if (!reports.size) {
      if (requestingAssignedEntities.get('loaded')) {
        return <Status appearance="good">This tag has nothing assigned to it</Status>;
      } else if (requestingAssignedEntities.get('loading')) {
        return <Spinner marginTop={100} />;
      }
    }

    return (
      <Panel padding={false}>
        <Table
          cellParams={{tagId: match.params.tagId}}
          rowKey="reportId"
          rows={reports.toJS()}
          columns={REPORT_COLS}
          onClick={this.handleClick}
        />
      </Panel>
    );
  }

  render() {
    return <SettingsPage pageHeader={this.renderHeader()}>{this.renderContent()}</SettingsPage>;
  }
}

export default connect(
  tagSettingsPageSelector,
  {viewTags, requestTags, requestAssignedEntities}
)(AccountTagSettingsPage);
