import {Map, List, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';

const initialState = Map({
  // payment history
  paymentsLoading: false,
  paymentsError: '',
  payments: List(),

  // payment details
  paymentDetailsLoading: false,
  paymentDetailsError: false,
  paymentDetails: Map()
});

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    // payment history
    case FETCHING_PAYMENTS:
      return setFetchState(state, true);

    case PAYMENTS_RECEIVED:
      // only receive payments if we were trying to load them
      if (state.get('paymentsLoading')) {
        const newPayments = state.get('payments').concat(fromJS(action.request));

        return setFetchState(state).set('payments', newPayments);
      }
      break;

    case PAYMENTS_FAILED:
      return setFetchState(state, false, true);

    // payment details
    case REQUEST_PAYMENT_DETAILS:
    case REQUEST_PAYMENT_DETAILS_UPDATE:
      return state.set('paymentDetailsLoading', true).set('paymentDetailsError', false);

    case RECEIVE_PAYMENT_DETAILS:
    case PAYMENT_DETAILS_UPDATE_COMPLETE:
      return state.set('paymentDetailsLoading', false).set('paymentDetails', Map(action.details));

    case PAYMENT_DETAILS_FAILED:
    case PAYMENT_DETAILS_UPDATE_FAILED:
      return state.set('paymentDetailsLoading', false).set('paymentDetailsError', true);

    case PAYMENT_DETAILS_UPDATE_CANCELLED:
      return state.set('paymentDetailsLoading', false).set('paymentDetailsError', false);

    // cleanup
    case SWITCH_ACCOUNT:
      return initialState;
  }

  return state;
}

function setFetchState(state, loading = false, error = false) {
  return state.set('paymentsLoading', loading).set('paymentsError', error);
}

export const FETCHING_PAYMENTS = 'payments:FETCHING_PAYMENTS';
export const loadingPayments = () => ({type: FETCHING_PAYMENTS});

export const PAYMENTS_RECEIVED = 'payments:PAYMENTS_RECEIVED';
export const receivePayments = request => ({
  type: PAYMENTS_RECEIVED,
  request
});

export const PAYMENTS_FAILED = 'payments:PAYMENTS_FAILED';
export const paymentRequestFailed = () => ({type: PAYMENTS_FAILED});

export const REQUEST_PAYMENT_DETAILS = 'payments:REQUEST_PAYMENT_DETAILS';
export const requestPaymentDetails = () => ({type: REQUEST_PAYMENT_DETAILS});

export const RECEIVE_PAYMENT_DETAILS = 'payments:RECEIVE_PAYMENT_DETAILS';
export const receivePaymentDetails = details => ({
  type: RECEIVE_PAYMENT_DETAILS,
  details
});

export const PAYMENT_DETAILS_FAILED = 'payments:PAYMENT_DETAILS_FAILED';
export const paymentDetailsFailed = () => ({type: PAYMENT_DETAILS_FAILED});

export const PAYMENT_DETAILS_UPDATE_CANCELLED = 'payments:PAYMENT_DETAILS_UPDATE_CANCELLED';
export const paymentDetailsCancelled = () => ({
  type: PAYMENT_DETAILS_UPDATE_CANCELLED
});

export const SHOW_UPDATE_PAYMENT_DETAILS_MODAL = 'payments:SHOW_UPDATE_PAYMENT_DETAILS_MODAL';
export const showUpdatePaymentDetailsModal = () => ({
  type: SHOW_UPDATE_PAYMENT_DETAILS_MODAL
});

export const REQUEST_PAYMENT_DETAILS_UPDATE = 'payments:REQUEST_PAYMENT_DETAILS_UPDATE';
export const requestPaymentDetailsUpdate = paymentDetails => ({
  type: REQUEST_PAYMENT_DETAILS_UPDATE,
  paymentDetails
});

export const PAYMENT_DETAILS_UPDATE_COMPLETE = 'payments:PAYMENT_DETAILS_UPDATE_COMPLETE';
export const paymentDetailsUpdateComplete = paymentDetails => {
  return {type: PAYMENT_DETAILS_UPDATE_COMPLETE, details: paymentDetails};
};

export const PAYMENT_DETAILS_UPDATE_FAILED = 'payments:PAYMENT_DETAILS_UPDATE_FAILED';
export const paymentDetailsUpdateFailed = () => {
  return {
    type: PAYMENT_DETAILS_UPDATE_FAILED
  };
};
