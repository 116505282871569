import React from 'react';
import {connect} from 'react-redux';
import styles from './accountMenu.scss';
import {push} from 'modules/location';

export class MenuItem extends React.Component {
  handleClickMenuItem = event => {
    const {push, href, external, onClick} = this.props;

    if (href) {
      if (external) {
        window.open(href, '_blank');
      } else {
        push(href);
      }
    }
    onClick && onClick(event);
  };

  render() {
    if (this.props.divider) {
      return <hr className={styles.divider} />;
    }
    return (
      <div className={styles.menuItem} onClick={this.handleClickMenuItem}>
        {this.props.children}
      </div>
    );
  }
}

export default connect(
  null,
  {push}
)(MenuItem);
