exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1AD6qPmWcQvuXOR3VK8hnI{margin:0;display:flex;align-items:center;border-bottom:1px solid #bbb}._1AD6qPmWcQvuXOR3VK8hnI:first-child{margin-top:30px;border-top:1px solid #bbb}._1AD6qPmWcQvuXOR3VK8hnI>div,._1AD6qPmWcQvuXOR3VK8hnI>div>div{margin:0;padding:0}._20uiqzLGETWP5LaBL0v71C{width:80px}._1DmN49cpxIQT79yV-GFvWi{margin-top:13px;border-radius:30px;background-color:#eee;box-shadow:0 0 10px rgba(0,0,0,.2)}.GAngqyyv81hZL06O8R4sN{flex:5}._1BzAT1mq47r9VejQ-WXNKr{flex:1;height:40px;margin-left:10px !important}._3yW7qqGqHLCxW6AmpsJWJN{flex:5;margin-right:20px}._2n_SI4K_bhE_WpiInQ3lHL{flex:1;color:#df1616;text-align:center}", ""]);

// exports
exports.locals = {
	"summaryWrapper": "_1AD6qPmWcQvuXOR3VK8hnI",
	"summaryImage": "_20uiqzLGETWP5LaBL0v71C",
	"userImage": "_1DmN49cpxIQT79yV-GFvWi",
	"summaryName": "GAngqyyv81hZL06O8R4sN",
	"summaryButton": "_1BzAT1mq47r9VejQ-WXNKr",
	"summaryRole": "_3yW7qqGqHLCxW6AmpsJWJN",
	"summaryDelete": "_2n_SI4K_bhE_WpiInQ3lHL"
};