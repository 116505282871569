import {takeEvery, put} from 'redux-saga/effects';
import {MAKE_DECISION, receiveDecision} from '../decisions';
import {decisionsEndpoint} from '../decisionUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {addDecisionMessage} from 'modules/alerts';

export default function* makeDecisionSaga() {
  yield takeEvery(MAKE_DECISION, makeDecision);
}

function* makeDecision({reportId, testId, decision, props, context = {}}) {
  const url = decisionsEndpoint({reportId});
  const responseDecision = yield processRequest('POST', url, {}, {testId, decision, props});

  yield put(addDecisionMessage(responseDecision, context));
  yield put(receiveDecision(responseDecision, context));
}
