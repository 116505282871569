exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3JG5aLOPkITEfgCBpA3Dkp{display:flex;justify-content:center;margin-bottom:5px}._3JG5aLOPkITEfgCBpA3Dkp.XcsPRiNfrawM15cB-jx6V{flex-direction:column}._8NAWi_o9pS5sEQTPOGB-o{padding:5px;cursor:pointer}._8NAWi_o9pS5sEQTPOGB-o>label{margin:0;cursor:pointer}._8NAWi_o9pS5sEQTPOGB-o>label>input[type=radio],._8NAWi_o9pS5sEQTPOGB-o>label>input[type=checkbox]{margin:0 5px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"checkboxes": "_3JG5aLOPkITEfgCBpA3Dkp",
	"stacked": "XcsPRiNfrawM15cB-jx6V",
	"checkboxOption": "_8NAWi_o9pS5sEQTPOGB-o"
};