export const UNDO_DECISION = 'decisions:UNDO_DECISION';
export const UNDO_DECISION_COMPLETE = 'decisions:UNDO_DECISION_COMPLETE';
// context is for optimistic update
export function undoDecision(reportId, decisionId, context = {}) {
  return {
    type: UNDO_DECISION,
    reportId,
    decisionId,
    context
  };
}

export const MAKE_DECISION = 'decisions:MAKE_DECISION';
// `context` is not sent to the backend, but is used for optimistic updates
//           (see removeStore.js)
export function makeDecision(reportId, testId, decision, props = {}, context = {}) {
  return {
    type: MAKE_DECISION,
    reportId,
    testId,
    decision,
    props,
    context
  };
}

export const RECEIVE_DECISION = 'decisions:RECEIVE_DECISION';
export function receiveDecision(decision, context) {
  return {type: RECEIVE_DECISION, decision, context};
}

export const FETCH_ACCOUNT_DECISIONS_PAGE = 'decisions:FETCH_ACCOUNT_DECISIONS_PAGE';
export function fetchAccountDecisionsPage() {
  return {type: FETCH_ACCOUNT_DECISIONS_PAGE};
}

export const FETCH_ACCOUNT_DECISIONS = 'decisions:FETCH_ACCOUNT_DECISIONS';
export function fetchAccountDecisions(filters) {
  return {type: FETCH_ACCOUNT_DECISIONS, filters};
}

export const RECEIVE_ACCOUNT_DECISION_PAGE = 'decisions:RECEIVE_ACCOUNT_DECISION_PAGE';
export function receiveAccountDecisionPage(accountDecisions, accountDecisionStats) {
  return {type: RECEIVE_ACCOUNT_DECISION_PAGE, accountDecisions, accountDecisionStats};
}

export const RECEIVE_ACCOUNT_DECISIONS = 'decisions:RECEIVE_ACCOUNT_DECISIONS';
export function receiveAccountDecisions(accountDecisions) {
  return {type: RECEIVE_ACCOUNT_DECISIONS, accountDecisions};
}
