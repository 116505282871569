import _ from 'lodash';
import React from 'react';
import {put, take, select, takeEvery} from 'redux-saga/effects';
import {actionPath} from 'modules/actions/actionUtils';
import {reportPath, reportSettingPath} from 'modules/reports/reportUtils';
import {testPath} from 'modules/tests/testUtils';
import {policyPath} from 'modules/policies/policyUtils';
import {
  interactionPath,
  editInteractionPath,
  testInteractionPath
} from 'modules/interactions/utils';
import {DO_COMMAND, toggleFlagState} from 'modules/instructions';
import {replace, push} from 'modules/location';
import {prefixObjectKeys} from 'utils/collectionUtils';
import {
  RECEIVE_DECISION,
  UNDO_DECISION_COMPLETE,
  undoDecision,
  makeDecision
} from 'modules/decisions/decisions';
import {inspectorPageActionPath} from 'modules/inspector/inspectorUtils';
import {locationParamsSelector, queryParamsSelector} from 'modules/location/locationSelectors';
import {triggerExportExplorer} from 'modules/reports';
import {setDecisionId} from 'modules/inspector/inspectorActions';
import {showUpgradeModal} from 'modules/onboarding';
import {defaultLanguagesSelector} from 'modules/auth/authSelectors';
import {requestDeletePolicy} from 'modules/policies/actions';
import {requestDeleteInteraction} from 'modules/interactions/actions';
import {reportSelector} from 'modules/reports/reportSelectors';
import {setFilterParams} from 'modules/filters';
import {updateQuery} from 'modules/location';
import {ROLE_CONTRIBUTOR, reportIsAllowed} from 'modules/gandalf/gandalfUtils';
import {getGoogleSearchUrl, getGlobalDictionarySearchUrl} from 'utils/urlUtils';
import {triggerRetestBrokenLink} from 'modules/checks/checks';
import {showInModal} from 'modules/modal';
import NewPolicyModal from 'modules/policies/containers/newPolicyModal';
import NewLibraryPolicyModal from 'modules/policies/containers/newLibraryPolicyModal';
import confirm from 'utils/saga/confirm';
import {enabledEffectsSelector} from 'modules/inspector/inspectorSelectors';
import {setEffect} from 'modules/inspector/inspectorActions';
import {getAffectedEffects} from 'modules/inspector/inspectorUtils';
import {decisionSelector} from 'modules/decisions/decisionSelectors';

export default function* doCommandSaga() {
  yield takeEvery(DO_COMMAND, doCommand);
}

function* doCommand({command, context, filters, event}) {
  const {reportId: locationReportId} = yield select(locationParamsSelector);
  const {testId: paramsTestId} = yield select(locationParamsSelector);
  const {testId: queryTestId} = yield select(queryParamsSelector);
  const testId = paramsTestId || queryTestId;
  const reportId = command.reportId || locationReportId;

  yield execAction(reportId, testId, command, context, filters, event);
}

export function* execAction(reportId, testId, {type, ...command}, context, filters, event) {
  if (command && command.setFilters) {
    let newFilters = {...filters};
    Object.keys(command.setFilters).forEach(filterName => {
      newFilters[filterName] = context[command.setFilters[filterName]];
    });
    yield put(setFilterParams(newFilters));
  }

  switch (type) {
    case 'new-tab':
      return yield execUrlInstruction(filters[command.filter], '_blank');
    case 'url':
      return yield execUrlInstruction(command.url, command.target);
    case 'action':
      return yield execActionInstruction(command.actionId, command.filters || {}, event);
    case 'test':
      return yield execTestInstruction(command.testId, command.filters || {}, event);
    case 'policy':
      return yield execPolicyInstruction(command.policyId, event);
    case 'interaction':
      return yield execViewInteraction(command.interactionId, event);
    case 'interaction-view':
      return yield execViewSpecialInteraction(command, event);
    case 'interaction-test':
      return yield execTestInteraction(command, event);
    case 'interaction-edit':
      return yield execEditInteraction(command.interactionId, event);
    case 'interaction-delete':
      return yield execDeleteInteraction(command.interactionId, event);
    case 'new-policy':
      return yield execNewPolicyInstruction(event);
    case 'delete-policy':
    case 'policy-delete':
      return yield execDeletePolicyInstruction(command.policyId, event);
    case 'new-library-policy':
      return yield execNewLibraryPolicyInstruction(command, event);
    case 'settings':
      return yield execSettingsInstruction(reportId, command);
    case 'export':
      return yield execExportInstruction(reportId, testId, command, filters);
    case 'report':
      return yield execReportInstruction(command.reportId, command.testId, event);
    case 'filter':
      return yield execFilterInstruction(command, filters);
    case 'inspector':
      return yield execInspectorInstruction(command, event);
    case 'decision':
    case 'undoDecision':
      const report = yield select(reportSelector, {reportId});
      const reportRole = report.getIn(['ownPermissions', 'role']);

      const isDemo = report.get('isDemo');

      if (isDemo) {
        return yield put(showUpgradeModal());
      }

      if (reportRole && !reportIsAllowed(ROLE_CONTRIBUTOR, reportRole)) {
        return;
      }

      if (type == 'decision') {
        return yield execDecisionAction({reportId, ...command}, context);
      } else {
        return yield execUndoAction({reportId, ...command}, context);
      }

    case 'modal':
    case 'dropdown':
      if (command.setFilters) {
        const url = context[command.setFilters.url];

        if (command.setFilters.required && !url) {
          return null;
        }
      }

      return yield execToggleFlag(command.id);

    case 'google':
      window.open(getGoogleSearchUrl(command.query));
      break;

    case 'retest-broken-link':
      yield put(triggerRetestBrokenLink(reportId, command.query));
      break;

    case 'global-dictionary':
      const word = command.query;
      const defaultLanguages = yield select(defaultLanguagesSelector);
      let languageCode = '';
      let countryCode = '';

      if (defaultLanguages && defaultLanguages.get(0)) {
        [languageCode] = defaultLanguages.get(0).split('-');
      }

      window.open(getGlobalDictionarySearchUrl(word, countryCode, languageCode));
      break;

    case 'toggleEffect':
      const enabledEffects = yield select(enabledEffectsSelector);

      if (enabledEffects.includes(command.query)) {
        yield put(setEffect(command.query, false));
      } else {
        const affectedEffects = getAffectedEffects(command.query);
        for (let effectId of affectedEffects) {
          yield put(setEffect(effectId, false));
        }
        yield put(setEffect(command.query, true));
      }
      break;

    default:
      throw new Error('Unsupported type "' + type + '"');
  }
}

function* execUrlInstruction(url, target = '_self') {
  if (!url) {
    throw new Error('Invalid props passed to `execUrl`. Expecting a `url`');
  }
  if (target === '_blank') {
    window.open(url, '_blank', 'noopener');
  } else {
    window.location = url;
  }
}

function* execActionInstruction(actionId, filters = {}, event) {
  if (!actionId) {
    throw new Error('Invalid props passed to `execActionInstruction`. Expecting `actionId`');
  }

  const params = yield select(locationParamsSelector);
  let testId = params.testId;
  if (!testId) {
    const selectedReport = yield select(reportSelector);
    testId = selectedReport.get('defaultTestId');
  }

  yield put(push(actionPath, {actionId, testId}, ['accountId', 'reportId'], false, filters, event));
}

function* execTestInstruction(testId, newFilters = {}, event) {
  if (!testId) {
    throw new Error('Invalid props passed to `execTestInstruction`. Expecting `testId`');
  }

  yield put(push(testPath, {testId}, ['reportId', 'accountId'], false, newFilters, event));
}

function* execPolicyInstruction(policyId, event) {
  if (!policyId) {
    throw new Error('Invalid props passed to `execPolicyInstruction`. Expecting `policyId`');
  }

  yield put(push(policyPath, {policyId}, ['accountId', 'reportId', 'testId'], false, {}, event));
}

function* execViewInteraction(interactionId, event) {
  yield put(
    push(
      interactionPath,
      {interactionId: !interactionId ? 'new' : interactionId},
      ['accountId', 'reportId', 'testId'],
      false,
      {},
      event
    )
  );
}

// I needed this because we have a table that can be clicked on to show another layout file, but that layout file needs specific filters to work,
// but certain rows don't always contain certain filters so we need to only link to the place we want if we have all the data we need.
// The alternative is to allow each table row to specify whether it can be clicked or not, but how do we do that through the layout system. Painfully that's how
function* execViewSpecialInteraction({interactionId, time, name}, event) {
  if (time > 0) {
    yield put(
      push(
        testPath,
        {testId: 'interactions-screen'},
        ['accountId', 'reportId'],
        false,
        {interactionId, time, name},
        event
      )
    );
  }
}

function* execTestInteraction(command, event) {
  const {interactionId} = command;

  yield put(
    push(
      testInteractionPath,
      {interactionId: !interactionId ? 'new' : interactionId},
      ['accountId', 'reportId', 'testId'],
      true,
      command,
      event
    )
  );
}

function* execEditInteraction(interactionId, event) {
  yield put(
    push(
      editInteractionPath,
      {interactionId: !interactionId ? 'new' : interactionId},
      ['accountId', 'reportId', 'testId'],
      false,
      {},
      event
    )
  );
}

function* execDeleteInteraction(interactionId, event) {
  const confirmed = yield* confirm({
    title: `Delete interaction`,
    content: <p>This will delete this interaction. Are you sure you want to do this?</p>,
    submitLabel: 'Delete interaction',
    cancelLabel: 'Cancel',
    destructiveConfirm: true
  });

  if (!confirmed) return;

  yield put(requestDeleteInteraction(interactionId));
}

function* execNewPolicyInstruction(event) {
  yield put(showInModal(NewPolicyModal));
}

function* execDeletePolicyInstruction(policyId, event) {
  const confirmed = yield* confirm({
    title: `Delete policy`,
    content: <p>This will delete this policy. Are you sure you want to do this?</p>,
    submitLabel: 'Delete policy',
    cancelLabel: 'Cancel',
    destructiveConfirm: true
  });

  if (!confirmed) return;

  yield put(requestDeletePolicy(policyId));
}

function* execNewLibraryPolicyInstruction(command, event) {
  yield put(showInModal(NewLibraryPolicyModal, command));
}

function* execReportInstruction(reportId, testId = null, event) {
  if (!reportId) {
    throw new Error('Invalid props passed to `execReportInstruction`. Expecting `reportId`');
  }

  if (testId) {
    yield put(push(testPath, {reportId, testId}, ['accountId'], false, {}, event));
    return;
  }

  yield put(push(reportPath, {reportId}, ['accountId'], false, {}));
}

function* execFilterInstruction({filterName, filterValue, filters = {}}, currentFilters) {
  if (filterName) {
    filters = {...filters, [filterName]: filterValue};
  }
  yield put(updateQuery({...currentFilters, ...filters}));
}

function* execSettingsInstruction(reportId, command) {
  if (!command.settingsId) {
    throw new Error(
      'Invalid props passed to `execSettingsInstruction`. Expecting `settingsId`',
      command
    );
  }
  yield put(push(reportSettingPath, {reportId, settingsId: command.settingsId}));
}

function* execExportInstruction(reportId, testId, command, filters) {
  if (!command.resultId) {
    throw new Error(
      'Invalid props passed to `execExportInstruction`. Expecting `resultId`',
      command
    );
  }
  yield put(triggerExportExplorer(reportId, testId, command.resultId, filters));
}

function* execInspectorInstruction({context, ...props}, event) {
  const queryProps = prefixObjectKeys(context, 'context');

  for (var key in props) {
    queryProps[key] = props[key];
  }

  if (window.innerWidth < 768) {
    return;
  }

  yield put(push(inspectorPageActionPath, {filters: queryProps}, ['reportId'], false, {}, event));
}

function* execDecisionAction(
  {reportId, testId, decision, decisionActionId, ...props},
  context = {}
) {
  yield put(makeDecision(reportId, testId, decision, props, context));

  // TODO race condition
  let {
    decision: {decisionId},
    context: receivedContext
  } = yield take(RECEIVE_DECISION);

  // only accept the RECEIVE_DECISION if its the one we care about
  while (!_.isEqual(context, receivedContext)) {
    console.log('waited make');
    ({
      decision: {decisionId},
      context: receivedContext
    } = yield take(RECEIVE_DECISION));
  }

  yield put(setDecisionId(props, decisionId, context));
}

function* execUndoAction({reportId, decisionId}, context) {
  const decision = yield select(decisionSelector, {decisionId});

  if (!decision && !context.matching) {
    console.error(
      'Cannot undo decision because we need the matching props to undo all the other points'
    );
    return;
  }

  yield put(undoDecision(reportId, decisionId, context));

  // TODO race condition
  let {context: receivedContext} = yield take(UNDO_DECISION_COMPLETE);

  // only accept the RECEIVE_DECISION if its the one we care about
  while (!_.isEqual(context, receivedContext)) {
    console.log('waited undo');
    ({context: receivedContext} = yield take(UNDO_DECISION_COMPLETE));
  }

  const props = decision ? decision.get('matching').toJS() : context.matching;
  yield put(setDecisionId(props, 0, context));
}

function* execToggleFlag(id) {
  yield put(toggleFlagState(id));
}
