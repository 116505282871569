import React from 'react';
import Button from 'components/button';
import styles from './serviceBreakdown.scss';
import Icon from 'components/font-awesome/icon';
import Pill from 'components/pill/pill';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Tooltip from 'components/tooltip';
import cx from 'classnames';
import {serviceSettingsPath} from 'modules/settings/settingsUtils';
import {formatGivenNumber} from 'utils/numberUtils';
import {
  getInitialActiveServicePeriod,
  isServiceAMonitor,
  getDefaultServiceType
} from 'modules/services/serviceUtils';
import {requestDeleteSuperSubscription} from 'modules/subscriptions';
import Card from 'components/itemCard/card';
import Dropdown, {DropdownOption} from 'components/dropdown';

export class ServiceBreakdown extends React.Component {
  viewService = () => {
    const {service, push} = this.props;

    push(serviceSettingsPath, {serviceId: service.serviceId});
  };

  getPillApperance = fb => {
    if (fb.entitled === -1) {
      return 'good';
    }

    const usedPercentage = (fb.claimed / fb.entitled) * 100;
    if (usedPercentage < 70) {
      return 'good';
    } else if (usedPercentage < 100) {
      return 'warning';
    } else {
      return 'depleted';
    }
  };

  getPillMessage = fb => {
    if (fb.entitled === -1) {
      return 'Healthy';
    }

    const usedPercentage = (fb.claimed / fb.entitled) * 100;
    if (usedPercentage < 70) {
      return 'Healthy';
    } else if (usedPercentage < 100) {
      return 'Running low';
    } else {
      return 'depleted';
    }
  };

  renderPlanDetailsRows() {
    const {service} = this.props;
    const defaultServicePeriod = getInitialActiveServicePeriod(service);
    const featureBalances = defaultServicePeriod.featureBalances;

    return featureBalances.map(fb => {
      return (
        <tr key={fb.featureId}>
          <td>{fb.name}</td>
          <td className={styles.lighterText}>
            {fb.entitled === -1 ? 'Unlimited' : formatGivenNumber(fb.entitled)}
          </td>
          <td className={styles.lighterText}>
            {fb.featureId === 'PagesPerReportLimit' ? '-' : formatGivenNumber(fb.claimed)}
          </td>
          <td>
            {fb.featureId === 'PagesPerReportLimit' ? (
              '-'
            ) : (
              <Pill square tiny appearance={this.getPillApperance(fb)}>
                {this.getPillMessage(fb)}
              </Pill>
            )}
          </td>
        </tr>
      );
    });
  }

  renderCoreModulePills() {
    const {service} = this.props;
    const defaultServicePeriod = getInitialActiveServicePeriod(service);
    const featureFlags = defaultServicePeriod.featureFlags;

    const pills = [];

    featureFlags.forEach(flag => {
      if (flag.isCoreModule && flag.isEnabled) {
        pills.push(
          <Pill key={flag.featureId} square appearance="major" className={styles.pill}>
            {flag.name}
          </Pill>
        );
      }
    });

    featureFlags.forEach(flag => {
      if (flag.isCoreModule && !flag.isEnabled) {
        pills.push(
          <Tooltip
            key={flag.featureId}
            text={<span>This module is not currently enabled for this plan.</span>}
            side="top"
          >
            <Pill square appearance="subtle" className={styles.pill}>
              {flag.name} <Icon name="fas fa-question-circle" />
            </Pill>
          </Tooltip>
        );
      }
    });

    return pills;
  }

  handleCancelSubscription = subscriptionId => event => {
    this.props.requestDeleteSuperSubscription(subscriptionId);
  };

  render() {
    const {service, hideView} = this.props;
    const subscription = service.subscription || {};
    const defaultServicePeriod = getInitialActiveServicePeriod(service);
    if (!defaultServicePeriod) {
      return null;
    }

    const isMonitor = isServiceAMonitor(service);
    const subtitle = (
      <span>
        Service type: <strong>{getDefaultServiceType(service)}</strong>
      </span>
    );

    const headingRight = !hideView ? (
      isMonitor && (
        <Button
          tooltip="View, edit, upgrade and downgrade this service plan here."
          wire
          icon="fas fa-search"
          iconSide="left"
          onClick={this.viewService}
        >
          View service
        </Button>
      )
    ) : (
      <Dropdown button={<Button plain iconSize="2x" icon="fal fa-angle-down" />} hideOnEmpty>
        {subscription.subscriptionId && !subscription.canceledDate && (
          <DropdownOption onClick={this.handleCancelSubscription(subscription.subscriptionId)}>
            Cancel subscription
          </DropdownOption>
        )}
      </Dropdown>
    );

    return (
      <Card title={service.name} subtitle={subtitle} headingRight={headingRight}>
        <div style={{display: 'flex'}}>
          <div className={cx(styles.productBodyLeft, {[styles.noBorderRight]: !isMonitor})}>
            <div className={styles.bodyHeader}>
              <Icon name="fas fa-analytics" className={styles.headingIcon} />
              Plan details
              <Tooltip
                className={styles.helpInHeading}
                text={
                  <span>
                    This section gives you an overview of the allowance and usage used so far.
                  </span>
                }
                side="top"
              >
                <Icon name="fas fa-question-circle" />
              </Tooltip>
            </div>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Limit</th>
                  <th>Used</th>
                  <th className={styles.small}>Status</th>
                </tr>
              </thead>
              <tbody>{this.renderPlanDetailsRows()}</tbody>
            </table>
          </div>
          {isMonitor && (
            <div className={styles.productBodyRight}>
              <div className={styles.bodyHeader}>
                <Icon name="fas fa-tags" className={styles.headingIcon} />
                Core modules
                <Tooltip
                  className={styles.helpInHeading}
                  text={
                    <span>
                      This section gives you an overview if which core modules are enabled for this
                      account. You can also see modules that are available to be added.
                    </span>
                  }
                  side="top"
                >
                  <Icon name="fas fa-question-circle" />
                </Tooltip>
              </div>
              <div className={styles.tagsWrappers}>{this.renderCoreModulePills()}</div>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default connect(
  null,
  {push, requestDeleteSuperSubscription}
)(ServiceBreakdown);
