import {List, fromJS} from 'immutable';
import _ from 'lodash';
import {MAKE_DECISION, UNDO_DECISION} from 'modules/decisions/decisions';
import {RECEIVE_MISSION} from 'modules/missions';
import {missionIsFinished} from 'modules/missions/missionUtils';
import * as Consts from './inspectorConstants';

const initialState = fromJS({
  data: null, // The inspector data
  lastError: null, // only contains errors for http code > 200
  actionFilters: {}, // The state of the filter bar, I.E filter by severity
  enabledEffects: [], // The state of the curently enabled effects
  selectedPointIds: [], // The state of the current selector points in the sidebar
  retestMissionId: null, // The mission ID if this inspector page is being retested
  makingDecisions: [], // The state of any points making a decision or undoing a decision

  // Cached versions of the marked html page, so we don't calculate it too often
  // TODO this should be done elsewhere really, needs rethinking
  markedViewHtml: '',
  markedSourceHtml: '',
  retestingInspector: false
});

export default function inspectorReducer(state = initialState, action) {
  switch (action.type) {
    // we will receive a mission when the retest is complete
    case RECEIVE_MISSION:
      const missionId = state.get('retestMissionId');
      if (missionId && action.mission.missionId == missionId && missionIsFinished(action.mission)) {
        return state.set('retestMissionId', null);
      }
      return state;

    case MAKE_DECISION: {
      const pointId = action.context.pointId;
      if (!pointId) return state;

      return state.update('makingDecisions', makingDecisions => {
        return makingDecisions.push(pointId);
      });
    }

    case UNDO_DECISION: {
      const pointId = action.context.pointId;
      if (!pointId) return state;

      return state.update('makingDecisions', makingDecisions => {
        return makingDecisions.push(pointId);
      });
    }

    case Consts.SET_DECISION_ID: {
      const pointId = action.context.pointId;
      if (!pointId) return state;

      const newState = state.update('makingDecisions', makingDecisions => {
        const index = makingDecisions.indexOf(pointId);
        return index < 0 ? makingDecisions : makingDecisions.splice(index, 1);
      });

      const {decisionState, ...matchingProps} = action.props;
      // we made a decision for a point, however, the decision we made *could* affect multiple points
      // we need to match every point that this decision affects, and set its `decisionId`.
      // if there are no matchingProps, then we can only match on pointId

      if (_.size(matchingProps) > 0 && newState.hasIn(['data', 'points'])) {
        // find all points with `matchingProps` and set the decisionIds
        return newState.updateIn(['data', 'points'], points => {
          return points.map(point => {
            const doesPointMatch = Object.keys(matchingProps).every(matchingPropKey => {
              return point.get(matchingPropKey) === matchingProps[matchingPropKey];
            });

            if (!doesPointMatch) return point;

            // set the decisionId for matching point
            return point.set('decisionId', action.decisionId);
          });
        });
      } else if (newState.hasIn(['data', 'points', pointId])) {
        return newState.updateIn(['data', 'points', pointId], point => {
          // const isUndoing = action.decisionId === 0;
          return point.set('decisionId', action.decisionId);
        });
      }
      return newState;
    }

    case Consts.SET_NO_REDIRECT_INSPECTOR_ON_MISSING:
      return state.set('noRedirectInspectorOnMissing', action.shouldNotRedirect);

    case Consts.REMOVE_CONTEXT:
      return state.setIn(['data', 'context'], null);

    case Consts.SELECT_POINTS:
      if (action.pointIds.length === 1) {
        if (state.get('selectedPointIds').includes(action.pointIds[0])) {
          return state
            .update('selectedPointIds', pointIds => {
              return pointIds.filter(pointId => pointId !== action.pointIds[0]);
            })
            .set('noRedirectInspectorOnMissing', false);
        } else {
          return state
            .set('selectedPointIds', fromJS(action.pointIds))
            .set('noRedirectInspectorOnMissing', false);
        }
      }
      return Array.isArray(action.pointIds)
        ? state
            .set('selectedPointIds', fromJS(action.pointIds))
            .set('noRedirectInspectorOnMissing', false)
        : state.get('selectedPointIds').isEmpty()
        ? state
        : state.set('selectedPointIds', List()).set('noRedirectInspectorOnMissing', false);

    case Consts.FETCH_INSPECTOR_PAGE:
      return state.set('lastError', null);

    case Consts.FETCH_INSPECTOR_PAGE_COMPLETE:
      if (action.error) {
        return state.set('lastError', action.error);
      } else if (!state.get('data')) {
        // setting for the first time
        return state.set('data', fromJS(action.data));
      } else {
        // updating, so we want to ensure that we don't overwrite data with null data
        return state.update('data', data => {
          const newData = fromJS(action.data);
          // merging new page data
          if (!data.get('viewHtml') && newData.get('viewHtml')) {
            return data
              .set('editPageData', newData.get('editPageData'))
              .set('editPageUrl', newData.get('editPageUrl'))
              .set('lastUpdated', newData.get('lastUpdated'))
              .set('scale', newData.get('scale'))
              .set('dimensions', newData.get('dimensions'))
              .set('sourceHtml', newData.get('sourceHtml'))
              .set('sourceHeadHtml', newData.get('sourceHeadHtml'))
              .set('viewHtml', newData.get('viewHtml'))
              .set('status', newData.get('status'))
              .set('device', newData.get('device'))
              .set('errorMessage', newData.get('errorMessage'));
          }
          // merging new points data
          if (!data.get('points').size && newData.get('points').size) {
            return data
              .set('actions', newData.get('actions'))
              .set('points', newData.get('points'))
              .set('categories', newData.get('categories'))
              .set('categoryActions', newData.get('categoryActions'))
              .set('status', newData.get('status'))
              .set('device', newData.get('device'))
              .set('errorMessage', newData.get('errorMessage'));
          }
        });
      }

    // NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
    // case Consts.ACTION_FILTER_CHANGED:
    //   return state.setIn(['actionFilters', action.name], action.value);

    case Consts.RETEST_BEGAN:
      return state.set('retestMissionId', action.missionId);

    case Consts.RETEST_COMPLETE:
      return state.set('retestMissionId', null);

    case Consts.CLEAR_DATA:
    case Consts.EXIT_INSPECTOR:
      // TODO don't clear actions or categories or categoryActions
      return initialState;

    case Consts.SET_EFFECT:
      return state.update('enabledEffects', enabledEffects => {
        if (action.isEnabled) {
          if (!enabledEffects.has(action.effectId)) {
            return enabledEffects.push(action.effectId);
          }
        } else {
          if (enabledEffects.includes(action.effectId)) {
            const index = enabledEffects.indexOf(action.effectId);
            return enabledEffects.splice(index, 1);
          }
        }
        return enabledEffects;
      });

    case Consts.SET_MARKED_HTML:
      return state.set(action.id == 'source' ? 'markedSourceHtml' : 'markedViewHtml', action.html);

    case Consts.SET_RETEST_INSPECTOR_PAGE:
      return state.set('retestingInspector', action.isLoading);
  }
  return state;
}
