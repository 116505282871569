import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {featuresSelector} from 'modules/auth/authSelectors';
// import styles from './feature.scss';

/**
 * Used to label something as a feature.
 * I.e. <Feature name="myCoolFeature"><MyCoolFeature /></Feature>
 * Will only show if the user has the feature 'myCoolFeature' enabled.
 */
export class Feature extends React.Component {
  render() {
    const {children, name, features} = this.props;

    if (Array.isArray(features) && features.indexOf(name) > -1) {
      return children;
    }

    return null;
  }
}

export default connect(featuresSelector)(Feature);
