exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1THbOT07MxyLK1Tzl3xLAV{margin:0 0 40px;padding:20px 0 25px;background:#fafafa;border-top:#e4e4e4 solid 1px;border-bottom:#e4e4e4 solid 1px}._1THbOT07MxyLK1Tzl3xLAV h4{text-transform:uppercase;text-align:center;color:#646464;font-size:1em;margin-bottom:15px;font-weight:bold}._1THbOT07MxyLK1Tzl3xLAV img{margin:10px 50px}", ""]);

// exports
exports.locals = {
	"logos": "_1THbOT07MxyLK1Tzl3xLAV"
};