import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {TEST_INTERACTION} from 'modules/interactions/constants';
import {testInteractionEndpoint} from 'modules/interactions/utils';
import {testInteractionComplete} from 'modules/interactions/actions';
import {reportIdParamSelector} from 'modules/reports/reportSelectors';
import {makeRequest, processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* testInteractionSaga() {
  yield takeLatest(TEST_INTERACTION, testInteractionListener);
}

function* testInteractionListener({type, ...filters}) {
  const {interactionId} = filters;
  const reportId = yield select(reportIdParamSelector);
  const url = testInteractionEndpoint({reportId, interactionId});
  const res = yield makeRequest('GET', url);

  return yield processResponse(res, {
    *success(data) {
      yield put(testInteractionComplete(null, data));
    },
    *error(err) {
      if (res.status == 404) {
        yield put(testInteractionComplete('NOT_FOUND'));
      } else {
        yield put(testInteractionComplete(err));
      }
    },
    errorMessage: couldNot('test interaction')
  });
}
