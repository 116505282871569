import {editorConfig, executiveConfig, marketerConfig} from 'modules/superProduct/constants';

/**
 * Internal Chell routes
 */
export function quotePath() {
  return `/quotes/:quoteId/:quoteKey`;
}

/**
 * Endpoints
 */
export function quoteEndpoint(quoteId, quoteKey) {
  return `quotes/` + quoteId + '/' + quoteKey;
}

export function quoteRejectEndpoint(quoteId, quoteKey) {
  return quoteEndpoint(quoteId, quoteKey) + '/reject';
}

export function quoteAcceptByInvoiceEndpoint(quoteId, quoteKey) {
  return quoteEndpoint(quoteId, quoteKey) + '/accept-via-invoice';
}

export function quoteAcceptByStripeEndpoint(quoteId, quoteKey) {
  return quoteEndpoint(quoteId, quoteKey) + '/checkout';
}

export function getSubConfig({numPages}) {
  return [editorConfig, marketerConfig, executiveConfig].map(config => ({...config, numPages}));
}
