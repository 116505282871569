import React from 'react';
import {connect} from 'react-redux';
import styles from './onboardingWrapper.scss';
import image from './organisation-signup.png';
import OnboardingWrapper from 'modules/onboarding/components/onboardingWrapper';
import OnboardingFormWrapper from 'modules/onboarding/components/onboardingFormWrapper';
import OrganisationSignupForm from 'modules/accounts/components/organisationSignupForm';
import {organisationPageSelector} from 'modules/onboarding/onboardingSelectors';
import {geolocate} from 'modules/geolocation';

export class OrganisationPage extends React.Component {
  componentDidMount() {
    this.props.geolocate();
  }

  renderRhs() {
    return (
      <div>
        <img className={styles.image} src={image} />
        <p className={styles.imageCaption}>
          Measure and improve your website's performance over time
        </p>
      </div>
    );
  }

  render() {
    const {formData, nextStep, errorMessage, loading} = this.props;

    return (
      <OnboardingWrapper rhs={this.renderRhs()} className={styles.organisation}>
        <OnboardingFormWrapper
          title="Tell us about your organization"
          intro="We need a few details to set you up"
        >
          <OrganisationSignupForm
            defaultValue={formData}
            onValidSubmit={nextStep}
            errorMessage={errorMessage}
            isLoading={loading}
          />
        </OnboardingFormWrapper>
      </OnboardingWrapper>
    );
  }
}

export default connect(
  organisationPageSelector,
  {geolocate}
)(OrganisationPage);
