import {takeLatest, put} from 'redux-saga/effects';
import {couldNot} from 'utils/errorUtils';
import {reportEndpoint} from 'modules/reports/reportUtils';
import {receiveReport, requestRetestReport} from 'modules/reports';
import {VIEW_REPORT_PAGE, FETCH_REPORT} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {handle404} from 'modules/location';

export default function* fetchReportSaga() {
  yield takeLatest([VIEW_REPORT_PAGE, FETCH_REPORT], fetchReport);
}

function* fetchReport({reportId}) {
  const postData = {};

  yield processRequest(
    'GET',
    reportEndpoint({reportId}),
    {
      *success(report) {
        yield put(receiveReport(report, true));
      },
      *notFound() {
        yield put(handle404());
      },
      errorMessage: couldNot('load website')
    },
    postData
  );
}
