exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-ZRlqiHgAg2Rq38LMJBKJ{padding:50px;text-align:center}.-ZRlqiHgAg2Rq38LMJBKJ h3{margin:60px auto 0;opacity:.5}._23p2CCOSqNwKdYpWDrdPaU{width:100%;max-width:600px;margin-top:50px}@media(max-width: 768px){.-ZRlqiHgAg2Rq38LMJBKJ{padding:20px}}", ""]);

// exports
exports.locals = {
	"progress": "-ZRlqiHgAg2Rq38LMJBKJ",
	"image": "_23p2CCOSqNwKdYpWDrdPaU"
};