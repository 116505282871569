exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CbO3WRY-FO2ufyj4udjxA{width:66px;height:66px;margin-top:-4px}", ""]);

// exports
exports.locals = {
	"homeIcon": "_3CbO3WRY-FO2ufyj4udjxA"
};