import React from 'react';
import Icon from 'components/font-awesome';
import {connect} from 'react-redux';
import {triggerNewFolderView, swapFolderViews} from 'modules/folderViews';
import ResponsiveTabs from 'components/tabs/responsiveTabs';
import SortableTabs from 'components/tabs/sortableTabs';
import styles from './folderTabs.scss';
import {folderViewTabsSelector} from 'modules/folderViews/folderViewSelectors';
import cx from 'classnames';
import DeleteIcon from 'components/icons/deleteIcon';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import Gandalf from 'components/gandalf';

const tabHeaderDropdownOptions = [
  {value: 'edit', label: 'Edit view'},
  {value: 'delete', label: 'Delete view'}
];

export class FolderTabs extends React.Component {
  handleClickTab = tabIdx => event => {
    const {onClickTab} = this.props;

    onClickTab && onClickTab(tabIdx, event);
  };

  handleClickTabIcon = tabIdx => event => {
    const {onClickTabIcon} = this.props;

    event && event.stopPropagation();

    onClickTabIcon && onClickTabIcon(tabIdx, event);
  };

  handleClickIcon = () => {
    const {triggerNewFolderView, folderId} = this.props;

    triggerNewFolderView(folderId);
  };

  handleSort = (oldIndex, newIndex) => {
    const {swapFolderViews, folderId} = this.props;

    swapFolderViews(folderId, oldIndex, newIndex);
  };

  renderTab = showIcon => (child, idx) => {
    const {selected, user} = this.props;

    return React.cloneElement(child, {
      selected: selected == idx,
      onClick: this.handleClickTab(idx),
      icon: showIcon && (
        // need to set `size` to an empty string so it overrides the default value of "2x"
        <Gandalf shallPass={ACCOUNT_OWNER}>
          <DeleteIcon onClick={this.handleClickTabIcon(idx)} />
        </Gandalf>
      )
    });
  };

  renderTabs() {
    const {children} = this.props;

    const showTabsIcons = React.Children.count(children) > 1;

    return (
      <SortableTabs onSort={this.handleSort} hideBorder>
        {children.map(this.renderTab(showTabsIcons))}
      </SortableTabs>
    );
  }

  render() {
    const {folderViewsEnabled, folderId} = this.props;

    if (!folderViewsEnabled) {
      return null;
    }

    return (
      <div className={styles.folderTabs}>
        {this.renderTabs()}

        {folderId != 0 && (
          <ul className={styles.tabIcons}>
            <li>
              <Icon
                name="plus"
                onClick={this.handleClickIcon}
                className={styles.addFolderViewButton}
              />
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default connect(
  folderViewTabsSelector,
  {triggerNewFolderView, swapFolderViews}
)(FolderTabs);
