// import React from 'react';
// import {ReactReduxContext} from 'react-redux';

// export function useSelector(selector, props = {}) {
//   const {store} = React.useContext(ReactReduxContext);
//   return selector(store.getState(), props);
// }

// export function useDispatch() {
//   const {store} = React.useContext(ReactReduxContext);
//   return store.dispatch;
// }

import {useSelector as reduxUseSelector, useDispatch as reduxUseDispatch} from 'react-redux';

// modify useSelector to allow props
export function useSelector(selector, props = {}) {
  return reduxUseSelector(state => selector(state, props));
}

// we modify useSelector so may as well export a copy of useDispatch too
export function useDispatch() {
  return reduxUseDispatch();
}
