exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._19gFL0rmqjg3OUZ0wTeFzx>*{display:inline}._19gFL0rmqjg3OUZ0wTeFzx ._20Cs7bf3OPMRNzyAAOdtMQ{margin-left:10px;cursor:default}._19gFL0rmqjg3OUZ0wTeFzx._1gKg_ZpiTno0wCUfKzwjKa{color:#15b272}._19gFL0rmqjg3OUZ0wTeFzx._3Rb4dk57Pd5_bTGxOrreqE{color:#df1616}._19gFL0rmqjg3OUZ0wTeFzx._3GLWd9HADrBThv184P4gz8{color:#bbb}", ""]);

// exports
exports.locals = {
	"wrapper": "_19gFL0rmqjg3OUZ0wTeFzx",
	"diff": "_20Cs7bf3OPMRNzyAAOdtMQ",
	"good": "_1gKg_ZpiTno0wCUfKzwjKa",
	"bad": "_3Rb4dk57Pd5_bTGxOrreqE",
	"nochange": "_3GLWd9HADrBThv184P4gz8"
};