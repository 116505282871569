import {take, select, put, call, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SET_NPS_SCORE, setNPSDue} from 'modules/nps';
import {showError, showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';
import {npsActionSelector} from 'modules/nps/npsSelectors';
import {showInModal} from 'modules/modal';
import RewardModal from '../components/rewardModal';

export default function* getNPSDueSaga() {
  yield takeLatest(SET_NPS_SCORE, function*({score, reason, contactMe, shouldThank = true}) {
    const action = yield select(npsActionSelector);

    // we must set the npsDue to null immediately, otherwise the popup will be opened again
    yield put(setNPSDue(null));

    yield processRequest(
      'POST',
      'nps',
      {
        success: function*({npsDue, reviewUrl, reviewAmountUsd}) {
          yield put(setNPSDue(npsDue));

          shouldThank && (yield showSuccess('Thank you for your feedback'));

          if (reviewUrl) {
            yield put(showInModal(RewardModal, {reviewUrl, reviewAmountUsd}));
          }
        },
        error: function*() {
          shouldThank && (yield showError(couldNot('submit your feedback')));
          throw err;
        }
      },
      {
        score,
        action,
        comment: reason,
        canRespond: contactMe,
        source: 'web-app'
      }
    );
  });
}
