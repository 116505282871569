import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Helmet from 'components/helmet';
import Flex from 'components/layout/flex';
import Spinner from 'components/spinner';
import styles from './serviceSettingsPage.scss';
import Button from 'components/button';
import {requestServiceBreakdown, clearServices} from 'modules/services/servicesActions';
import {serviceSettingsPageSelector} from 'modules/settings/settingsSelectors';
import Status from 'components/status';
import Dropdown from 'components/dropdown';
import Center from 'components/center';
import Progress from 'components/progress';
import find from 'lodash/find';
import ServiceUsageTable from 'modules/services/components/serviceUsageTable';
import ServiceBreakdown from 'modules/services/components/serviceBreakdown';
import Card from 'components/itemCard/card';
import Alert from 'components/alert/alert';

const dropdownOptions = [
  {label: 'Page breakdown', value: 'PageMonitorBalance'},
  {label: 'PDF breakdown', value: 'PdfMonitorBalance'},
  {label: 'Keyword breakdown', value: 'SearchEngineKeywordBalance'}
];

export class ServiceSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {usageView: 'PageMonitorBalance'};
  }

  componentDidMount() {
    this.props.requestServiceBreakdown(this.props.serviceId);
  }

  componentWillUnmount() {
    this.props.clearServices();
  }

  renderLoadingErrorWarning() {
    return (
      <Status appearance="bad">
        <p>
          There was an error trying to load this service. Please try again. If the problem persists,
          try contacting support.
        </p>
      </Status>
    );
  }

  handleDropdownClick = val => {
    this.setState({usageView: val});
  };

  renderUsageDropdown = () => {
    return (
      <Dropdown
        button={
          <Button wire icon="caret-down">
            {find(dropdownOptions, {value: this.state.usageView}).label}
          </Button>
        }
        preferSide="bottom"
        role="presentation"
        options={dropdownOptions}
        onChange={this.handleDropdownClick}
      />
    );
  };

  renderServiceInformation() {
    const {service, serviceId} = this.props;

    return (
      <div style={{marginTop: '20px'}}>
        <ServiceBreakdown service={service} hideView />

        {/* <Flex container align="top">
          <Flex style={{flex: 6, paddingRight: '10px'}}>
            <Card title={'Cost explorer'} headingRight={<Button tooltip="You can upgrade, downgrade or change subscription length by clicking here." wire icon="fas fa-pencil" iconSide="left">Change plan</Button>}>
              <Center style={{width: '100%'}}>
                <div className={styles.pricingWrapper}>
                  <div className={styles.priceLarge}>
                    $120 per month
                  </div>

                  <div className={styles.priceRecommendation}>
                    <p><a href="#">Switch to annual payment</a> and save <strong>$220 per year!</strong></p>
                  </div>
                </div>
              </Center>
            </Card>
          </Flex>
          
          <Flex style={{flex: 6, paddingLeft: '10px'}}>
            <Card title={'Payment details'} headingRight={<Button tooltip="You can upgrade, downgrade or change subscription length by clicking here." wire icon="fas fa-credit-card" iconSide="left">Update</Button>}>
              <Center style={{width: '100%'}}>
                <div className={styles.pricingWrapper}>
                  <div className={styles.priceLarge}>
                    Mastercard ending 9669
                  </div>

                  <div className={styles.priceRecommendation}>
                    <p style={{textAlign: 'center'}}>Expires: <strong>July 2021</strong></p>
                  </div>
                </div>
              </Center>
            </Card>
          </Flex>
        </Flex> */}

        <Card title={'Usage breakdown'} headingRight={this.renderUsageDropdown()}>
          <ServiceUsageTable serviceId={serviceId} dataFor={this.state.usageView} />
        </Card>
      </div>
    );
  }

  render() {
    const {isLoading, loadingError, service} = this.props;
    const canceledDate = service && service.subscription && service.subscription.canceledDate;
    const endDate = service && service.subscription && service.subscription.endDate;

    if (isLoading) {
      return (
        <div style={{marginTop: '20px'}}>
          <Spinner centered />
        </div>
      );
    }

    if (loadingError || !service) {
      return this.renderLoadingErrorWarning();
    }

    return (
      <div>
        <Helmet title={'Service ' + service.name} />

        <div className={styles.header}>
          <h3 style={{flex: 10}}>Your service overview</h3>

          {canceledDate > 0 && (
            <Alert level="danger" icon="exclamation-triangle" noMargin>
              This service has been cancelled and will end on{' '}
              {moment.unix(endDate).format('MMMM Do YYYY, h:mm:ss a')}
            </Alert>
          )}

          {/* <div style={{flex: 2}}>
            <Progress
              value={58}
              tooltip={"I am cool"}
              className={styles.progress}
            >
              <Center fit>
                560 of 1000 pages
              </Center>
            </Progress>
          </div> */}

          {/* <Button icon="plus" iconSide="left" >Update plan</Button> */}
        </div>

        <div className={styles.pageWrapper}>
          <div className={styles.productsWrapper}>{this.renderServiceInformation()}</div>
        </div>
      </div>
    );
  }
}

export default connect(
  serviceSettingsPageSelector,
  {requestServiceBreakdown, clearServices}
)(ServiceSettingsPage);
