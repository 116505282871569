import React from 'react';
import styles from './inspectorHeader.scss';

export default function InspectorTitle({children}) {
  return (
    <div className={styles.title}>
      <span className={styles.text}>{children}</span>
    </div>
  );
}
