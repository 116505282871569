import {delay} from 'redux-saga';
import {put, takeEvery} from 'redux-saga/effects';
import {ADD_MESSAGE, removeMessage} from 'modules/alerts';

export default function* alertSaga() {
  yield takeEvery(ADD_MESSAGE, addMessage);
}

function* addMessage({id, expirySeconds}) {
  // Wait expirySeconds until we hide the message
  yield delay(expirySeconds * 1000);

  yield put(removeMessage(id));
}
