export const REQUEST_SERVICES = 'services:REQUEST_SERVICES';
export function requestServices() {
  return {
    type: REQUEST_SERVICES
  };
}

export const REQUEST_SERVICE_BREAKDOWN = 'services:REQUEST_SERVICE_BREAKDOWN';
export function requestServiceBreakdown(serviceId) {
  return {
    type: REQUEST_SERVICE_BREAKDOWN,
    serviceId
  };
}

export const RECEIVE_SERVICES = 'services:RECEIVE_SERVICES';
export function receiveServices(services) {
  return {
    type: RECEIVE_SERVICES,
    services
  };
}

export const RECEIVE_SERVICE = 'services:RECEIVE_SERVICE';
export function receiveService(service) {
  return {
    type: RECEIVE_SERVICE,
    service
  };
}

export const RECEIVE_SUBSCRIPTION = 'services:RECEIVE_SUBSCRIPTION';
export function receiveSubscription(subscription) {
  return {
    type: RECEIVE_SUBSCRIPTION,
    subscription
  };
}

export const SERVICES_ERROR = 'services:SERVICES_ERROR';
export function servicesError(error) {
  return {
    type: SERVICES_ERROR,
    error: error
  };
}

export const CLEAR_SERVICES = 'services:CLEAR_SERVICES';
export function clearServices() {
  return {
    type: CLEAR_SERVICES
  };
}

export const FETCH_USAGE_DATA_FOR = 'services:FETCH_USAGE_DATA_FOR';
export function fetchUsageData(serviceId, featureId, pageId) {
  return {
    type: FETCH_USAGE_DATA_FOR,
    featureId,
    serviceId,
    pageId
  };
}

export const RECEIVE_USAGE_DATA_FOR = 'services:RECEIVE_USAGE_DATA_FOR';
export function receiveUsageData(usageData) {
  return {
    type: RECEIVE_USAGE_DATA_FOR,
    usageData
  };
}

export const USAGE_DATA_FETCH_ERROR = 'services:USAGE_DATA_FETCH_ERROR';
export function usageDataFetchError(err) {
  return {
    type: USAGE_DATA_FETCH_ERROR,
    err
  };
}
