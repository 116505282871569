import React from 'react';
import Button from './button';
import {contactUs} from 'modules/app/appUtils';

export default function ContactUsButton({children, ...props}) {
  return (
    <Button onClick={contactUs} {...props} wire>
      {children || 'Contact us'}
    </Button>
  );
}
