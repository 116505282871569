import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {hideModal} from 'modules/modal';
import {createStructuredSelector} from 'reselect';
import {fetchReports} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import {reportsOptionsSelector} from 'modules/reports/reportSelectors';
import {policyPath} from 'modules/policies/policyUtils';

const selector = createStructuredSelector({
  reportsOptions: reportsOptionsSelector
});

export class NewPolicyModal extends React.Component {
  componentWillMount() {
    this.props.fetchReports(true, ['name', 'reportId']);
  }

  handleValidSubmit = data => {
    this.props.push(
      policyPath,
      {policyId: 'new'},
      ['accountId', 'reportId', 'testId'],
      false,
      data
    );
    this.props.hideModal();
  };

  render() {
    const {reportsOptions} = this.props;
    const defaultValue = {scope: 'page', applyToAllReports: true};

    return (
      <ModalForm
        defaultValue={defaultValue}
        title="New policy"
        submitLabel="Create policy"
        onValidSubmit={this.handleValidSubmit}
      >
        <Field label="Name" type="text" name="name" required autoFocus />
        <Field description="Apply to all websites?" type="checkbox" name="applyToAllReports" />
        <Field
          label="Select reports"
          showIf={values => !values.applyToAllReports}
          name="applyToReports"
          type="listbox"
          options={reportsOptions.toJS()}
          required
          disableSort
          isLoading={reportsOptions.isEmpty()}
        />
        <Field label="Type of policy" type="policyScope" name="scope" />
      </ModalForm>
    );
  }
}

export default connect(
  selector,
  {hideModal, push, fetchReports}
)(NewPolicyModal);
