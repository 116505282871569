exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TpraM3oP9VVqwIi1w_-Zs{display:inline-block;padding:4px;border:0;line-height:1em;margin-top:-2px;color:#777;height:auto}._1TpraM3oP9VVqwIi1w_-Zs:hover,._1TpraM3oP9VVqwIi1w_-Zs:active,._1TpraM3oP9VVqwIi1w_-Zs:focus,._1TpraM3oP9VVqwIi1w_-Zs._38Dm7PcKb_8bhMuKurEKUZ{color:#777;outline:0}._1b_nlJDgkZloLUazPMhfKs{color:#fff;opacity:.5;transition:opacity .3s}._1b_nlJDgkZloLUazPMhfKs:hover{color:#fff;opacity:.8}", ""]);

// exports
exports.locals = {
	"base": "_1TpraM3oP9VVqwIi1w_-Zs",
	"open": "_38Dm7PcKb_8bhMuKurEKUZ",
	"light": "_1b_nlJDgkZloLUazPMhfKs"
};