import React from 'react';
import cx from 'classnames';
import Icon from 'components/font-awesome';
import HoverListener from 'components/events/hoverListener';
import styles from './tab.scss';

export default class Tab extends React.Component {
  static defaultProps = {
    options: []
  };

  handleClickIcon = event => {
    const {onClickIcon} = this.props;

    event && event.stopPropagation();

    onClickIcon && onClickIcon(event);
  };

  renderIcon(icon) {
    if (typeof icon === 'string') {
      return <Icon name={icon} className={styles.icon} onClick={this.handleClickIcon} />;
    }

    // assume it's a component
    return React.cloneElement(icon, {
      className: styles.icon
    });
  }

  render() {
    const {children, className, selected, invisible, onClick, align, icon, separator} = this.props;

    const classes = cx(styles.tab, className, {
      [styles.active]: selected,
      [styles.hidden]: invisible,
      [styles.separator]: separator
    });

    return (
      <HoverListener useMouseEnterLeave className={cx({[styles.alignRight]: align === 'right'})}>
        {hovering => (
          <li role="presentation" className={classes} onClick={onClick}>
            <span className={styles.tabHeader}>{children}</span>

            {hovering && icon && this.renderIcon(icon)}
          </li>
        )}
      </HoverListener>
    );
  }
}

// helper func (no utils file to put it in)
export function getActiveTab(params, tab) {
  if (params.tab) return params.tab.toLowerCase();
  if (tab) return tab['default-tab'].toLowerCase();
  return 'summary'; // master default
}

export function getTabFromLayoutChildren(children, tabId) {
  return children.reduce((foundItem, item) => {
    if (foundItem) return foundItem;
    return item.id === tabId ? item : null;
  }, null);
}
