exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-eNfJj8BQ9aOt5WaRduQv{padding:0 8px;font-weight:bold}._241uH41zY7D5x3yyxDgC1Y{margin:30px 0 40px 0}._29nTLG_T3TRJl4iFjFQXun{color:#fff;font-size:20px}", ""]);

// exports
exports.locals = {
	"pageCount": "_2-eNfJj8BQ9aOt5WaRduQv",
	"sliderWrapper": "_241uH41zY7D5x3yyxDgC1Y",
	"buyBtn": "_29nTLG_T3TRJl4iFjFQXun"
};