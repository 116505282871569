import {delay} from 'redux-saga';
import {call, put, race, select, take, takeLatest} from 'redux-saga/effects';
import {VIEW_TEST, LEAVE_TEST} from 'modules/tests/testConstants';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {filterSelector, defaultFilterSelector} from 'modules/filters/filterSelectors';
import {fetchResultsByIds} from 'modules/tests/sagas/fetchLayoutAndResults';
import {receiveResults} from 'modules/results';
import {RECEIVE_DECISION} from 'modules/decisions/decisions';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import {CLEAR_REPORT} from 'modules/reports/reportConstants';
import {DISABLE_BACKGROUND_POLL} from 'config';
import {currentLayoutSelector} from 'modules/layouts/layoutsSelectors';

/**
 * TASK/ACTIONS DO NOT RESULT POLL!!! Stop thinking they do.
 * Pusher messages are sent out instead to tell it which results to fetch.
 */

export default function* resultPollSaga() {
  if (!DISABLE_BACKGROUND_POLL) {
    yield takeLatest([VIEW_TEST], startPoll);
  }
}

function* startPoll(action) {
  // disable the results poll if the results are for an archive
  if (yield select(isArchiveActiveSelector)) return;

  yield delay(10000);

  const layoutObject = yield select(currentLayoutSelector);
  if (!layoutObject) return;

  let reportId = yield select(reportIdSelector);
  if (!reportId) return;

  // yield call(delay, 10000);

  while (true) {
    // wait for 10 seconds - if we get a leave test or a clear report then we cancel.
    // If we get a receive decision or (or the 10 seconds is finished) then continune on to fetch results.
    const {leaveTest, fetchNow, clearReport, timeout} = yield race({
      leaveTest: take(LEAVE_TEST),
      fetchNow: take(RECEIVE_DECISION),
      clearReport: take(CLEAR_REPORT),
      timeout: call(delay, 10 * 1000)
    });

    // if leave test, do not request
    if (leaveTest || clearReport) {
      return;
    }

    // 10 seconds is over, we can request the results again.
    yield call(requestResults, reportId, layoutObject.resultIds);
  }
}

// Fetch the results from the back-end - but cancel if we leave the test or clear the report during.
function* requestResults(reportId, resultIds) {
  const defaultFilters = yield select(defaultFilterSelector);
  const {filter} = yield select(filterSelector);

  if (!resultIds || !resultIds.length) return;

  try {
    const {leaveTest, clearReport, results} = yield race({
      leaveTest: take(LEAVE_TEST),
      clearReport: take(CLEAR_REPORT),
      results: call(fetchResultsByIds, reportId, resultIds, {...defaultFilters, ...filter})
    });

    if (leaveTest || clearReport) {
      return;
    }

    yield put(receiveResults(results));
  } catch (e) {
    console.warn('Poll request failed - retrying soon');
  }
}
