import React from 'react';
import sharedStyles from 'modules/auth/components/authWrapperShared.scss';
import {Form, Field} from 'modules/form/components';
import Button from 'components/button';
import euCountries from 'constants/euCountryCodes.json';
import OnboardingSubmitButtons from 'modules/onboarding/components/onboardingSubmitButtons';

export default class OrganisationSignupForm extends React.Component {
  state = {
    isValid: false,
    showVAT: false
  };

  handleFormChange = (name, value, data, isValid) => {
    this.setState({isValid});
  };

  render() {
    const {isValid} = this.state;
    const {isLoading} = this.props;

    return (
      <Form id="organisationSignupForm" {...this.props} onChange={this.handleFormChange}>
        <Field
          name="organizationName"
          label="Organization name"
          type="text"
          className={sharedStyles.textInput}
          required
          autoFocus={true}
          placeholder="David Smith"
        />
        <Field label="Country" type="country" name="addressCountry" required />

        <Field
          type="text"
          label="VAT number"
          name="vatNumber"
          showIf={data => euCountries.includes(data.addressCountry)}
        />

        <OnboardingSubmitButtons isValid={isValid} isLoading={isLoading} />
      </Form>
    );
  }
}
