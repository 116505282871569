import React from 'react';
import severities from 'modules/inspector/severities';
import styles from './issueCount.scss';
import Icon from 'components/font-awesome';
import Pill from 'components/pill';
import Tooltip from 'components/tooltip';
import {toConcise} from 'utils/numberUtils';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// Renders a badge with a count in for each severity we're passed that's above 0
export class IssueCount extends React.Component {
  renderBadge(severity, value, key) {
    const type = severity.get('type');
    value = parseInt(value, 10);

    return (
      <Pill key={key} appearance={type} narrow className={styles.pill}>
        {toConcise(value)}
      </Pill>
    );
  }

  getSummaryText = () => {
    const {hideTooltip, ...props} = this.props;

    if (hideTooltip) {
      return '';
    }

    return severities.reduce((text, severity) => {
      const propName = severity.get('type');
      let value = props[propName];
      if (value !== undefined) {
        if (value == null) {
          // annoying edgecase
          value = 0;
        }
        return `${text}${text ? ', ' : ''}${value} ${propName}`;
      }
      return text;
    }, '');
  };

  render() {
    const {singular, ...props} = this.props;

    // Just a cheeky little side effect.
    // (psst don't tell Danny)
    // We only show a tick if some issue counts are defined and are all 0
    let hasIssueCounts = false;
    let renderedBadge = false;

    // We loop through all of the possible severities
    // and check if we've been passed a prop with the same
    // name as the severity type. If so, we render a badge
    return (
      <Tooltip text={this.getSummaryText()} className={styles.tooltip}>
        <span {...filterInvalidDOMProps(props)}>
          {severities
            .map(severity => {
              if (renderedBadge && singular) return null;
              const propName = severity.get('type');
              const value = props[propName];
              if (value !== undefined) {
                hasIssueCounts = true;
                if (value > 0) {
                  renderedBadge = true;
                  return this.renderBadge(severity, value, 'issueCount-' + propName);
                }
              }
              return null;
            })
            .toJS()}

          {!renderedBadge && hasIssueCounts && (
            <Pill appearance="transparent" narrow>
              <Icon name="check" className={styles.tick} />
            </Pill>
          )}
        </span>
      </Tooltip>
    );
  }
}

export default localStyles(styles)(IssueCount);
