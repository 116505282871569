import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import Status from 'components/status';
import {doCommand} from 'modules/instructions';
import styles from 'components/topic/topic.scss';
import TopicItems from 'components/topic/components/topicItems';

export class Actions extends React.Component {
  render() {
    const {result, config} = this.props;
    const {done, nodifficulty, nosubname, inspector, headless, borderless, scoreless} = config;

    if (!result) {
      return null;
    }
    const items = result.rows ? result.rows : result;
    const className = cx(styles.wrapper, {[styles.borderless]: borderless ? true : false});

    if (!items.length) {
      const emptyAppearance = done ? 'bad' : 'good';
      const emptyLabel = done ? 'No actions have been done yet' : 'Nothing to do right now';
      return <Status appearance={emptyAppearance}>{emptyLabel}</Status>;
    }

    return (
      <div className={className}>
        <TopicItems
          showInspectorButon={inspector}
          items={items}
          done={done}
          scoreless={scoreless}
          headless={headless}
          nodifficulty={nodifficulty}
          nosubname={nosubname}
        />
      </div>
    );
  }
}

export default connect(
  null,
  {doCommand}
)(Actions);
