exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1kceT9oc7YjrDcIBks5pr5{min-width:230px;height:100%;padding:20px;background:#fff;border-left:1px solid #ddd;overflow:auto}._24urpB_N7JJJWIhk3Lgtq4{padding:5px}._24urpB_N7JJJWIhk3Lgtq4:hover{color:#fff;background:#533be2}._1SOk5P9IqR2zmoNIHqdRad{margin:6px 0}._2I7VCRNwZsxXwxquTdsS-m{display:flex;align-items:center;margin-bottom:10px}._2I7VCRNwZsxXwxquTdsS-m img{max-width:70px}._2I7VCRNwZsxXwxquTdsS-m p{flex:1;margin:0 0 0 10px}._2tzvN8B0R9nixmSQGhcrv8{min-width:105px;padding:10px 15px}", ""]);

// exports
exports.locals = {
	"accountMenu": "_1kceT9oc7YjrDcIBks5pr5",
	"menuItem": "_24urpB_N7JJJWIhk3Lgtq4",
	"divider": "_1SOk5P9IqR2zmoNIHqdRad",
	"userBar": "_2I7VCRNwZsxXwxquTdsS-m",
	"menuButtons": "_2tzvN8B0R9nixmSQGhcrv8"
};