exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rVQNJljVc2hhACtGpwu0U{display:flex}._2rVQNJljVc2hhACtGpwu0U ._24Lirs83L0ROMQHzWTZiWa{flex:0 0 100px;margin-right:20px}._2rVQNJljVc2hhACtGpwu0U ._24Lirs83L0ROMQHzWTZiWa img{width:100%}._2rVQNJljVc2hhACtGpwu0U ._35nBh4VdoXt8PRNjRkPKk-{flex:1;align-self:center}._2rVQNJljVc2hhACtGpwu0U ._35nBh4VdoXt8PRNjRkPKk- ._2dpfsSra-B6BJ10UQs1fz7,._2rVQNJljVc2hhACtGpwu0U ._35nBh4VdoXt8PRNjRkPKk- ._2ZT1C_IxfLIlAApDKezwQ0{margin:0}._2rVQNJljVc2hhACtGpwu0U ._35nBh4VdoXt8PRNjRkPKk- ._2dpfsSra-B6BJ10UQs1fz7{margin-bottom:15px}._2rVQNJljVc2hhACtGpwu0U ._35nBh4VdoXt8PRNjRkPKk- ._2ZT1C_IxfLIlAApDKezwQ0{color:#333}", ""]);

// exports
exports.locals = {
	"achievementTarget": "_2rVQNJljVc2hhACtGpwu0U",
	"icon": "_24Lirs83L0ROMQHzWTZiWa",
	"details": "_35nBh4VdoXt8PRNjRkPKk-",
	"title": "_2dpfsSra-B6BJ10UQs1fz7",
	"description": "_2ZT1C_IxfLIlAApDKezwQ0"
};