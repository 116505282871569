import React from 'react';
import {connect} from 'react-redux';
import {changeAccuracy, accuracySelector} from '..';
import Select from 'components/select';

const options = {hour: 'Hour', day: 'Day', week: 'Week', month: 'Month'};

export class SelectTrendAccuracy extends React.Component {
  handleChangeAccuracy = ({value: accuracy}) => {
    this.props.changeAccuracy(accuracy);
  };

  render() {
    const {accuracy} = this.props;

    return (
      <Select
        options={options}
        value={accuracy}
        onChange={this.handleChangeAccuracy}
        clearable={false}
        style={{minWidth: 100}}
      />
    );
  }
}

export default connect(
  accuracySelector,
  {changeAccuracy}
)(SelectTrendAccuracy);
