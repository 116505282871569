import React from 'react';
import InspectorScore from 'modules/inspector/components/inspectorScore';

function InspectorMetricType({className, type, value}) {
  switch (type) {
    case 'score':
      return <InspectorScore className={className} small value={value} />;

    default:
      return <div className={className}>{value}</div>;
  }
}

export default InspectorMetricType;
