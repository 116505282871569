import React from 'react';
import cx from 'classnames';
import styles from './toggleSwitch.scss';

export default class ToggleSwitch extends React.Component {
  handleChange = event => {
    this.props.onChange(!this.props.checked, event);
  };

  shouldComponentUpdate(nextProps) {
    return this.props.checked !== nextProps.checked;
  }

  render() {
    return (
      <label
        className={cx(styles.switch, this.props.className, {
          [styles.small]: this.props.small
        })}
      >
        <input
          id={this.props.id}
          type="checkbox"
          checked={this.props.checked}
          onChange={this.handleChange}
        />
        <div aria-hidden="true" className={styles.slider}>
          <div>excludes</div>
          <div className={styles.handle}></div>
          <div>includes</div>
        </div>
      </label>
    );
  }
}
