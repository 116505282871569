import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/spinner';
import {trackEvent} from 'modules/tracking';
import {reportPermissionsPageSelector} from 'modules/reportPermissions/reportPermissionSelectors';
import {setRole, removeRole, viewPermissionsConfigPage} from 'modules/reportPermissions';
import PermissionsConfig from '../../components/reportSettings/permissionsConfig';

export class ReportSettingsPermissionsConfigPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed permissions config', {
      forReport: this.props.match.params.reportId,
      fromFile: 'permissionsConfigPage.js'
    });

    this.props.viewPermissionsConfigPage(this.props.match.params.reportId);
  }

  isLoading() {
    const {reportPermissions, isFetchingUsers} = this.props;

    return _.isUndefined(reportPermissions) || isFetchingUsers;
  }

  render() {
    if (this.isLoading()) {
      return <Spinner centered delay={1000} />;
    }

    return <PermissionsConfig {...this.props} />;
  }
}

export default connect(
  reportPermissionsPageSelector,
  {setRole, removeRole, viewPermissionsConfigPage}
)(ReportSettingsPermissionsConfigPage);
