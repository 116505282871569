import _ from 'lodash';
import React from 'react';
import Select from 'components/select';
import ColorPreview from 'components/colorPreview';

const COLOR_OPTIONS = [
  generateOption('#533be2', 'Default'),
  generateOption('#ED4B4B', 'Red'),
  generateOption('#12CBB5', 'Cyan'),
  generateOption('#EBD12C', 'Yellow'),
  generateOption('#EBA52C', 'Orange'),
  generateOption('#55d272', 'Green'),
  generateOption('#237BCE', 'Blue'),
  generateOption('#9522AD', 'Purple'),
  generateOption('#3A3A3A', 'Black')
];

export default class ColorPalletInput extends React.Component {
  handleChangeEvent = option => {
    this.props.onChange(option.value);
  };

  render() {
    const {onChange, ...props} = this.props;

    return (
      <Select
        {...props}
        clearable={false}
        onChange={this.handleChangeEvent}
        options={COLOR_OPTIONS}
      />
    );
  }
}

function generateOption(color, label) {
  return {
    value: color,
    label: <ColorPreview color={color} label={label} />
  };
}
