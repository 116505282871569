import React from 'react';
import styles from './title.scss';
import {Link} from 'react-router-dom';
import {useSelector} from 'hooks/redux';
import {coursesPath} from 'modules/courses/utils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import Icon from 'components/font-awesome';

export default function CourseTitle({children, course}) {
  const name = course.get('name');
  const totalMinutes = course.get('totalMinutes');
  const accountId = useSelector(accountIdSelector);

  return (
    <div className={styles.title}>
      <div className={styles.left}>
        <div className={styles.breadcrumb}>
          <Icon name="fas fa-graduation-cap" />
          <Link to={coursesPath({accountId})}>Silktide Academy</Link>
        </div>
        <h1>{name}</h1>
      </div>
      <div>
        <Icon name="far fa-clock" /> {Math.ceil(totalMinutes)} minutes
      </div>
    </div>
  );
}
