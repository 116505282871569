import _ from 'lodash';
import React from 'react';
import Icon from 'components/font-awesome';
import styles from './actionPriority.scss';

/**
 * This class is designed to extract the presentation part of ActionPriority.js
 * and detach it from the coupled behaviour.
 *
 * We do this so we can re-use priorities elsewhere (e.g. for classifications).
 *
 * @todo Move this somewhere more sensible, and consider refactoring ActionPriority.js to use this
 */

export const PRIORITIES = [
  {
    value: 5,
    label: 'Highest',
    icon: 'fas fa-exclamation-circle',
    color: '#ed4b4b'
  },
  {
    value: 4,
    label: 'High',
    icon: 'fas fa-exclamation-triangle',
    color: '#eba52c'
  },
  {
    value: 3,
    label: 'Medium',
    icon: 'fas fa-exclamation-square',
    color: '#ebd12c'
  },
  {
    value: 2,
    label: 'Low',
    icon: 'fas fa-info-circle',
    color: '#808080'
  },
  {
    value: 1,
    label: 'Lowest',
    icon: 'fas fa-info-circle',
    color: '#c0c0c0'
  },
  {
    value: 0,
    label: 'Done',
    icon: 'fas fa-check',
    color: '#78d87c'
  }
];

export const OPTIONS = PRIORITIES.map(priority => ({
  value: priority.value,
  label: priority.label
}));

export default class Priority extends React.Component {
  render() {
    const priorityNumber = this.props.priority;
    if (_.isNull(priorityNumber)) {
      return null;
    }
    const priority = PRIORITIES[5 - priorityNumber];
    if (!priority) {
      return null;
    }

    return (
      <Icon
        name={priority.icon}
        size="lg"
        className={styles.priorityIcon}
        style={{color: priority.color}}
      />
    );
  }
}
