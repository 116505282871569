import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';

const geolocationStateSelector = state => state.geolocation;

export const geolocationSelector = createSelector(
  geolocationStateSelector,
  geolocationState => geolocationState.get('location', null)
);

export const geolocationObjectSelector = createSelector(
  geolocationStateSelector,
  geolocationState => {
    return {country: geolocationState.get('location', null)};
  }
);
