import {fromJS} from 'immutable';
import {createSelector} from 'reselect';

export const spotlightState = state => state.spotlight;

export const isSearchingSelector = createSelector(
  spotlightState,
  spotlight => spotlight.get('isSearching')
);

export const isOpenSelector = createSelector(
  spotlightState,
  spotlight => spotlight.get('isOpen')
);

export const spotlightSelector = createSelector(
  spotlightState,
  isOpenSelector,
  isSearchingSelector,
  (spotlight, isOpen, isSearching) => {
    const {accounts, users} = spotlight.get('results').toJS();
    const accountHeading = createHeading('Accounts');
    const userHeading = createHeading('Users');
    const accountResults = accounts.map(mapAccount);
    const userResults = users.map(mapUser).filter(Boolean);
    let results = [];

    if (accountResults.length) {
      results = results.concat([accountHeading, ...accountResults]);
    }
    if (userResults.length) {
      results = results.concat([userHeading, ...userResults]);
    }

    return {
      isOpen,
      isSearching,
      results: fromJS(results)
    };
  }
);

function mapAccount(account) {
  return {
    type: 'account',
    key: `account_${account.accountId}`,
    label: account.organizationName,
    data: account,
    isHeading: false
  };
}

function mapUser(user) {
  if (user.virtualType) return null;
  return {
    type: 'user',
    key: `user_${user.userId}`,
    label: user.name,
    data: user,
    isHeading: false
  };
}

function createHeading(label) {
  return {
    isHeading: true,
    label: label,
    key: label
  };
}
