import React from 'react';
import Affix from 'components/overlay/affix';

// This is how affix is to be used. Sometimes it's annoying to have to constantly provide
// a `ref`. This is a small helper where you can simply pass the component to be rendered.
export default class AffixHelper extends React.Component {
  render() {
    const {children, component, ...affixProps} = this.props;
    const componentWithRef = React.cloneElement(component, {
      ref: r => {
        this.buttonRef = r;
      }
    });

    return (
      <React.Fragment>
        {componentWithRef}

        <Affix to={this.buttonRef} {...affixProps}>
          {children}
        </Affix>
      </React.Fragment>
    );
  }
}
