import React from 'react';
import Panel from 'components/panel';
import Center from 'components/center';

export default function Body({children, heightUnit}) {
  return (
    <Panel style={{height: heightUnit * 150 + 'px'}}>
      <Center fit>{children}</Center>
    </Panel>
  );
}
