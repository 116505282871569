// This file exposes iso-fetch in a way that can be used by the api middleware,
// adding API.ROOT && auth header on the way through.
import _ from 'lodash';
import {API} from 'config';
import fetch from 'isomorphic-fetch';
import {tokenSelector, accountIdSelector} from 'modules/auth/authSelectors';

// TODO: remove getState need from fetch call. keep auth in saga?
export default function apiFetch(endpoint, state = {}, data, method) {
  const url = API.ROOT + endpoint;

  let options = {
    headers: {}
  };

  options.headers = addAuthHeader(options.headers, state);

  const methodFunc = data ? post : fetch;

  if (data) {
    return post(url, options, data, method).then(fixResponse);
  }
  return fetch(url, {...options, method: method || 'GET'}).then(fixResponse);
}

function post(url, options, data, method = 'post') {
  options = {
    ...options,
    method,
    body: _.isPlainObject(data) ? JSON.stringify(data) : data,
    headers: addContentTypeHeader(options.headers)
  };

  return fetch(url, options);
}

// TODO: we probably should return the whole res.
// Currently, we can't check the response code from higher up in the stack
export function fixResponse(res) {
  if (!res.ok) throw res.json();
  return res.status === 204 ? {} : res.json();
}

export function createAuthHeader(state, token) {
  const header = `${API.AUTH_SCHEME} token=${token}`;
  const accountId = accountIdSelector(state);
  if (!accountId) {
    return header;
  }
  return header + `, accountId=${accountId}`;
}

function addAuthHeader(headers = {}, state) {
  const token = tokenSelector(state);
  if (token) {
    return {...headers, Authorization: createAuthHeader(state, token)};
  }
  return headers;
}

function addContentTypeHeader(headers = {}) {
  return {...headers, 'Content-Type': 'application/json'};
}
