import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import HeightFixer from './heightFixer';
import TransitionReplace from '../transitionReplace';

/**
 * This class swaps between each child element provided over an interval
 */

const SWING_PERCENTAGE = 25; // used if humanizeInterval.

class ChildSwapper extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    interval: PropTypes.number,
    humanizeInterval: PropTypes.bool
  };

  static defaultProps = {
    interval: 5000,
    humanizeInterval: false
  };

  constructor(props) {
    super(props);
    this.state = {
      idx: 0
    };
  }

  getInterval() {
    const {interval, humanizeInterval} = this.props;

    if (!humanizeInterval) {
      return interval;
    }

    const swing = Math.abs(interval) * (SWING_PERCENTAGE / 100);

    return interval + _.random(-swing, swing);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.swapChild();
    }, this.getInterval());
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  swapChild() {
    const {idx} = this.state;
    const {children} = this.props;

    this.setState({
      idx: (idx + 1) % React.Children.count(children)
    });
  }

  render() {
    const {idx} = this.state;
    const {children, ...props} = this.props;
    const child = children[idx];

    return (
      <HeightFixer>
        <TransitionReplace transitionName="fadeReplace" transitionHeight={false}>
          <div key={idx}>{child}</div>
        </TransitionReplace>
      </HeightFixer>
    );
  }
}

export default ChildSwapper;
