import cx from 'classnames';
import styles from './pageCount.scss';
import React from 'react';
import {formatGivenNumber} from 'utils/numberUtils.js';

class PageCount extends React.Component {
  render() {
    const {result} = this.props;

    if (!result) return null;

    const numPages = result.numerator;
    const numPotentialPages = result.denominator;

    const className = cx(styles.wrapper);
    const title = formatGivenNumber(numPages) + ' pages tested';
    let description = 'This appears to be every page in this site';

    if (numPages < numPotentialPages) {
      description =
        'This is only part of this website. There appears to be at least ' +
        formatGivenNumber(numPotentialPages) +
        ' pages in total.';
    }

    return (
      <div className={className}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    );
  }
}

export default PageCount;
