import React from 'react';
import Flex from 'components/layout/flex';
import styles from './widget.scss';

export default function Title({children}) {
  return (
    <Flex grow={1} className={styles.title}>
      {children}
    </Flex>
  );
}
