import {fromJS, Map} from 'immutable';
import {RECEIVE_REPORT} from 'modules/reports/reportConstants';
import {
  RECEIVE_MISSION,
  RECEIVE_MISSIONS,
  DELETE_MISSION,
  CANCEL_MISSION,
  CLEAR_MISSIONS,
  REMOVE_MISSION
} from './missions';
import {SWITCH_ACCOUNT, LOGOUT} from 'modules/auth';
import {keyBy} from 'utils/immutableUtils';
import {missionIsFinished} from './missionUtils';

const initialState = fromJS({
  isDeleting: false,
  missions: {},
  lastCancelledMissionId: null
});

export default function missionsReducer(state = initialState, action) {
  switch (action.type) {
    // When we receive a single report,
    // it sometimes contains an array of missions for that report.
    case RECEIVE_REPORT:
      if (action.report.missions) {
        // clear mission state when viewing a report
        return receiveMissions(state.set('missions', Map()), action.report.missions);
      }
      break;

    case RECEIVE_MISSIONS:
      return receiveMissions(initialState, action.missions);

    case RECEIVE_MISSION:
      if (missionIsFinished(action.mission)) {
        return state.deleteIn(['missions', action.mission.missionId]);
      } else {
        return receiveMission(state, action.mission);
      }

    case CANCEL_MISSION:
      return state
        .set('isDeleting', action.missionId)
        .set('lastCancelledMissionId', action.missionId);

    case REMOVE_MISSION:
      return state.removeIn(['missions', action.missionId]);

    case CLEAR_MISSIONS:
      return state.set('missions', Map());

    case DELETE_MISSION:
      return state.set('isDeleting', false).deleteIn(['missions', action.missionId]);

    case SWITCH_ACCOUNT:
    case LOGOUT:
      return initialState;
  }

  return state;
}

function receiveMissions(state, missions) {
  if (!missions) return state;

  const keyedMissions = fromJS(keyBy(missions, 'missionId'));
  return state.mergeIn(['missions'], keyedMissions);
}

function receiveMission(state, mission) {
  return receiveMissions(state, [mission]);
}
