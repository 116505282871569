import React from 'react';
import Money from 'components/money';

export default class Currency extends React.Component {
  render() {
    const {data, record} = this.props;
    if (!record || (!record.currencyCode && !record.currency)) {
      return null;
    }

    return (
      <div style={{textAlign: 'right'}}>
        <Money value={data} currency={record.currencyCode || record.currency} />
      </div>
    );
  }
}
