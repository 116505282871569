import React from 'react';
import cx from 'classnames';
import styles from './checkboxes.scss';

export default class Checkboxes extends React.Component {
  handleClickOption = (checkboxValue, isChecked) => {
    const {radio, value, onChange} = this.props;

    if (radio) {
      onChange && onChange(checkboxValue);
      return;
    }
    const valueParts = !value ? [] : value.split(',');
    const valueIdx = valueParts.indexOf(checkboxValue);

    // if the value exists and is not checked (or it is checked but it does not exist)
    if (valueIdx >= 0 !== isChecked) {
      if (isChecked) {
        // if the only value we have is empty '-', remove it
        if (valueParts.length === 1 && valueParts[0] === '-') {
          valueParts.splice(0, 1);
        }

        // if it should exist, add it to the value
        valueParts.push(checkboxValue);
        valueParts.sort();
      } else {
        // if it should not exist, remove it from the value
        valueParts.splice(valueIdx, 1);

        // if we have none left after removing this
        if (!valueParts.length) {
          valueParts.push('-'); // dash indicates that we are empty and we mean it.
          // If we just used an empty string, there would be no difference between an
          // empty string (not yet chosen) and all off (turned all off on purpose)
        }
      }
    }

    onChange && onChange(valueParts.join(','));
  };

  renderOptions() {
    const {value, maxChecked, radio, name} = this.props;
    const valueParts = value.split(',');

    if (valueParts && valueParts[0] === '') {
      valueParts.shift();
    }

    return this.props.children.map(child => {
      const val = child.props.value;
      const checked = radio ? value === val : valueParts.includes(val);
      const disabled = valueParts.length >= maxChecked && !checked && maxChecked > 0;

      return React.cloneElement(child, {
        key: 'checkbox_option_' + val,
        onClick: this.handleClickOption,
        checked,
        disabled,
        radio,
        name
      });
    });
  }

  render() {
    const {stacked, className} = this.props;

    return (
      <div
        className={cx(styles.checkboxes, className, {
          [styles.stacked]: stacked
        })}
      >
        {this.renderOptions()}
      </div>
    );
  }
}
