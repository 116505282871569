import {takeLatest, put, select} from 'redux-saga/effects';
import {reportsEndpoint} from 'modules/reports/reportUtils';
import {receiveReports, fetchReports} from 'modules/reports';
import {FETCH_REPORTS} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* fetchReportSaga() {
  yield takeLatest(FETCH_REPORTS, _fetchReports);
}

function* _fetchReports({simple, fields}) {
  yield processRequest(
    'GET',
    reportsEndpoint(),
    {
      success: function*(response = {}) {
        const {reports} = response;

        if (Array.isArray(reports) && reports.length) {
          yield put(receiveReports(reports));
        }
      }
    },
    {simple, fields: fields.join(',')}
  );
}
