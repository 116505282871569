import {fromJS, Map} from 'immutable';
import {REQUEST_REPORT_SEGMENTS, RECEIVE_REPORT_SEGMENTS} from './segmentConstants';

/**
 * Format:
 *
 * {
 *   segments: {
 *     [reportId + testId]: {segmentId: segmentLabel}
 *   }
 * }
 */
export const initialState = fromJS({
  segments: {}
});

export default function segmentReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_REPORT_SEGMENTS: {
      const key = action.reportId + action.testId;
      return state.setIn(['segments', key], Map());
    }

    case RECEIVE_REPORT_SEGMENTS: {
      const key = action.reportId + action.testId;
      return state.updateIn(['segments', key], _ => fromJS(action.segments));
    }

    default:
      return state;
  }
}
