import {delay} from 'redux-saga';
import {select} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {eventsSelector} from '../analyticsSelectors';
import {analyticsEndpoint} from '../analyticsUtils';
import {analyticsTracked} from '../analytics';

const BATCH_DELAY = 2 * 1000;

export default function* sendAnalyticsSaga() {
  while (true) {
    yield delay(BATCH_DELAY);
    yield sendAnalytics();
  }
}

function* sendAnalytics() {
  const events = yield select(eventsSelector);

  if (!events.length) return;

  yield processRequest(
    'POST',
    analyticsEndpoint(),
    {
      successAction: analyticsTracked
    },
    {events}
  );
}
