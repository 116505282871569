import {takeLatest, put} from 'redux-saga/effects';
import {RECEIVE_REPORT} from 'modules/reports/reportConstants';
import {reportRetestBegan} from 'modules/reports';

export default function* activeReportMissionsSaga() {
  yield takeLatest(RECEIVE_REPORT, activeReportMissions);
}

// When we request a report, we get all the current active missions for that report. We
// need to intercept these missions and see if any of them are website missions.
// If they are, we need to start the pollReportMissionSaga
function* activeReportMissions({report: {missions = []}}) {
  if (!missions || !missions.length) return;

  const websiteMissions = missions.filter(mission => {
    // The page mission doesn't tell us what page it is for.
    // Until it does, we can ensure that the current pageUrl matches the url in the missions name.
    return mission.type === 'Report' && mission.reportType === 'website';
  });

  // We should never have more than 1 website missions for a report happening at the same time
  // so we can safely take the first (if it exists)
  if (websiteMissions.length > 0) {
    yield put(reportRetestBegan(websiteMissions[0].missionId));
  }
}
