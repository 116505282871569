exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZHIhmNYYbKZKnz6BTfE5W{background:#202020;box-shadow:0 0 70px rgba(0,0,0,.4);z-index:2;padding:5px;height:calc(45px + (5px * 2))}._2aUV1kDDj7t2_CJY9uo-6G{height:45px;max-height:45px;display:flex;align-items:center}", ""]);

// exports
exports.locals = {
	"bar": "ZHIhmNYYbKZKnz6BTfE5W",
	"header": "_2aUV1kDDj7t2_CJY9uo-6G"
};