export default class AbstractField {
  static PROMISE_CANCELLED = 'PROMISE_CANCELLED';
  static stringifyPath = path => path.join('.');
  static isSynthenicEvent = event => event && event.nativeEvent;
  static isIterable = value => Array.isArray(value) || AbstractField.isObject(value);
  static isObject = value => value === Object(value) && value.constructor === Object;

  constructor(path = [], fnRerender, fnValidate) {
    this.path = path;
    this.key = this.constructor.stringifyPath(path); // useful when `map`ing fields in React

    this.fnRerender = fnRerender;
    this.fnValidate = fnValidate;
    this.formatter = null;
  }

  initialise() {
    // interaction states
    this.blurred = false; // whether or not this field has been blurred
    this.changed = false; // whether or not onchange has happened
    this.submitted = false; // whether or not this field has been submitted

    // validation state
    this.isValidating = false;
    this.cancelLastPromise = null;
    this.setValidationResult(null);
  }

  getKey() {
    return this.path[this.path.length - 1];
  }

  setFormatter(formatter) {
    if (typeof formatter === 'function') {
      this.formatter = formatter;
    }
  }

  rerender = () => {
    this.fnRerender();
  };

  hasChanged() {
    return this.changed;
  }

  equalsInitial() {
    return this.initialValue === this.getSerialValue();
  }

  wasAttempted() {
    return this.blurred || this.changed || this.submitted;
  }

  getSerialValue() {
    return JSON.stringify(this.getValue());
  }

  setSubmitted() {
    this.submitted = true;
  }

  // this method returns a promise, but we don't use it.
  async validate(rules, inlineRule, customMessages = {}) {
    if (!this.fnValidate) return;

    // cancel the last promise
    if (this.cancelLastPromise) this.cancelLastPromise();

    if (typeof inlineRule === 'function') {
      const inlineResult = await this.runValidationFunction(inlineRule, rules, customMessages);

      if (inlineResult) {
        // inlineRule returned error message
        // the form system doesn't use the return value, it just sets state
        // the return value may be useful to the caller though
        return inlineResult;
      }

      // if inlineRule came back okay, continue with regular handler
    }

    return await this.runValidationFunction(this.fnValidate, rules, customMessages);
  }

  runValidationFunction(fn, rules, customMessages) {
    const result = fn(this.getFormattedValue(), rules, customMessages);

    if (result instanceof Promise) {
      return this.awaitValidationPromise(result);
    } else {
      this.setValidationResult(result);
      this.rerender();
      return Promise.resolve(result);
    }
  }

  awaitValidationPromise(validationPromise) {
    const [promise, cancelPromise] = this.cancellablePromise(validationPromise);

    this.setIsValidating(cancelPromise);

    return promise
      .then(asyncResult => {
        this.setValidationResult(asyncResult);
      })
      .catch(err => {
        if (err === this.PROMISE_CANCELLED) return;

        // validation error occurred, ensure we stop any spinners
        this.setValidationResult(null);

        // TODO handle err somehow, UI? Dev handle?
      })
      .finally(() => {
        this.setIsValidating(null);
      });
  }

  cancellablePromise(promise) {
    let promReject;
    return [
      new Promise((resolve, reject) => {
        promReject = reject;

        promise
          .then(resolve)
          .catch(reject)
          .finally(() => {
            promReject = null;
          });
      }),
      // our cancel fn
      () => {
        if (promReject) promReject(this.PROMISE_CANCELLED);
      }
    ];
  }

  setValidationResult(result) {
    this.validationResult = result;
    this.isValid = !result;
  }

  setIsValidating(cancelPromise) {
    // remember how to cancel the last promise incase we need to start a new one
    this.cancelLastPromise = cancelPromise;
    // set async validation
    this.isValidating = !!cancelPromise;
    // re-render for the "is validating" state
    this.rerender();
  }

  setPathIndex(pathIdx, value) {
    this.path[pathIdx] = value;

    this.key = this.constructor.stringifyPath(this.path);
  }
}
