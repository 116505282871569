import React from 'react';
import styles from './excerpt.scss';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Tooltip from 'components/tooltip';

export default class Excerpt extends React.Component {
  constructor(options) {
    super(options);
    this.state = {isExpanded: false};
  }

  toggleExpanded = e => {
    e.stopPropagation();
    this.setState({isExpanded: !this.state.isExpanded});
  };

  renderCorrection() {
    const {record} = this.props;
    const {before, after, typo, replacements} = record;

    return (
      <p>
        {before}
        <span className={styles.correction}>{replacements[0]}</span>
        {after}
      </p>
    );
  }

  renderButton() {
    const isExpanded = this.state.isExpanded;

    return (
      <Tooltip text={isExpanded ? 'Hide suggestion' : 'Show suggestion'}>
        <Button
          onClick={this.toggleExpanded}
          square
          wire
          small
          icon={isExpanded ? 'minus' : 'plus'}
        />
      </Tooltip>
    );
  }

  render() {
    const {record} = this.props;
    if (!record) return null;

    const {before, after, typo, replacements} = record;

    return (
      <Flex container>
        <Flex grow={1}>
          <p>
            {before}
            <span className={styles.typo}>{typo}</span>
            {after}
          </p>
          {this.state.isExpanded && this.renderCorrection()}
        </Flex>
        {replacements.length > 0 && (
          <Flex grow={0} className={styles.buttonWrapper}>
            {this.renderButton()}
          </Flex>
        )}
      </Flex>
    );
  }
}
