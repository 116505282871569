import {Map, List, fromJS} from 'immutable';
import * as Consts from './constants';
import {keyBy} from 'utils/immutableUtils';
import {createLoadingState, composeState, startLoading, endLoading} from 'modules/app/reduxHelpers';
// import {REDUX_EXAMPLE_COURSES} from './constants';

const initialState = fromJS({
  courses: {},

  requestingCourse: createLoadingState(),
  requestingCourses: createLoadingState(),
  requestingLesson: createLoadingState(),
  savingLesson: createLoadingState(),
  enrollingUser: createLoadingState(),
  enrollingUsers: createLoadingState(),
  savingQuiz: createLoadingState()
});

export default function interactionsReducer(state = initialState, action) {
  switch (action.type) {
    case Consts.REQUEST_COURSES:
      return composeState(state, [startLoading('requestingCourses')]);
    case Consts.REQUEST_COURSES_COMPLETE:
      return composeState(state, [
        endLoading('requestingCourses', action.error),
        mergeInCourses(action.courses)
      ]);

    case Consts.REQUEST_COURSE:
      return composeState(state, [startLoading('requestingCourse')]);
    case Consts.REQUEST_COURSE_COMPLETE:
      return composeState(state, [
        endLoading('requestingCourse', action.error),
        mergeInCourse(action.course)
      ]);

    case Consts.REQUEST_LESSON:
      return composeState(state, [startLoading('requestingLesson')]);
    case Consts.REQUEST_LESSON_COMPLETE:
      return composeState(state, [
        endLoading('requestingLesson', action.error),
        mergeInLesson(action.lesson)
      ]);

    case Consts.SAVE_QUIZ_STATE:
      return composeState(state, [startLoading('savingQuiz')]);
    case Consts.SAVE_QUIZ_STATE_COMPLETE:
      return composeState(state, [
        endLoading('savingQuiz', action.error),
        mergeInLesson(action.lesson)
      ]);

    case Consts.SAVE_LESSON_STATE:
      return composeState(state, [startLoading('savingLesson')]);
    case Consts.SAVE_LESSON_STATE_COMPLETE:
      return composeState(state, [
        endLoading('savingLesson', action.error),
        mergeInLesson(action.lesson)
      ]);

    case Consts.COURSE_UNENROLL:
    case Consts.COURSE_ENROLL:
      return composeState(state, [startLoading('enrollingUser')]);

    case Consts.COURSE_UNENROLL_COMPLETE:
    case Consts.COURSE_ENROLL_COMPLETE:
      return composeState(state, [endLoading('enrollingUser'), mergeInCourse(action.course)]);

    case Consts.SET_ENROLLED_USERS:
      return composeState(state, [startLoading('enrollingUsers')]);

    case Consts.SET_ENROLLED_USERS_COMPLETE:
      return composeState(state, [endLoading('enrollingUsers'), mergeInCourse(action.course)]);

    default:
      return state;
  }
}

const mergeInCourses = coursesToMerge => state => {
  return state.update('courses', courses => {
    return courses.merge(keyBy(coursesToMerge, 'courseId'));
  });
};

const mergeInCourse = courseToMerge => state => {
  return state.setIn(['courses', courseToMerge.courseId.toString()], fromJS(courseToMerge));
};

const mergeInLesson = lessonToMerge => state => {
  return state.updateIn(
    ['courses', lessonToMerge.courseId.toString(), 'stages'],
    List(),
    stages => {
      const stageIndex = stages.findIndex(stage => stage.get('stageId') == lessonToMerge.stageId);

      return stageIndex < 0
        ? stages
        : stages.updateIn([stageIndex, 'lessons'], lessons => {
            const index = lessons.findIndex(
              lesson => lesson.get('lessonId') == lessonToMerge.lessonId
            );
            return index < 0 ? lessons : lessons.mergeIn([index], fromJS(lessonToMerge));
          });
    }
  );
};

// const mergeInLessonState = lessonToMerge => state => {
//   return state.updateIn(['courses', lessonToMerge.courseId.toString(), 'lessons'], lessons => {
//     const index = lessons.findIndex(lesson => lesson.get('lessonId') == lessonToMerge.lessonId);

//     return lesson.mergeIn(index, fromJS(lessonToMerge));
//   });
// };
