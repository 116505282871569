import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './searchEnginesInput.scss';
import Input from 'components/input';
import Select from 'components/select';
import Button from 'components/button';
import {searchForLocation} from 'modules/geosearch';
import {searchDataSelector} from 'modules/geosearch/geosearchSelectors';
import {DeleteIcon} from 'components/icons';
import languages from 'constants/languages';
import googleLanguages from 'constants/googleLanguages';

const searchEngineOptions = [
  {value: 'google', label: 'Google'}
  // {value: 'bing', label: 'Bing'}
];

const defaultRow = {
  engine: 'google',
  location: '',
  language: 'en'
};

const languageMap = {
  bing: languages,
  google: googleLanguages
};

export class SearchEnginesInput extends React.Component {
  static defaultProps = {
    value: []
  };

  static propTypes = {
    value: PropTypes.array
  };

  state = {
    current: -1
  };

  componentDidMount() {
    const {onChange, value} = this.props;
    onChange && onChange(value);
  }

  componentWillUnmount() {
    const {onChange} = this.props;

    this.setState({current: -1});
    onChange && onChange([]);
  }

  renderHeader = showLoc => {
    const rows = Array.isArray(this.props.value) ? this.props.value : [];
    const atLeastOneIsGoogle = rows.reduce((reduction, value) => {
      if (reduction) return true;
      return value.engine === 'google';
    }, false);

    return (
      // <div className={styles.engine}>Engine</div>
      <div className={styles.inputHeader} key={'row_header'}>
        <div className={styles.location}>{atLeastOneIsGoogle && 'Location'}</div>
        <div className={styles.language}>Language</div>
        <div className={styles.closeIcon} />
      </div>
    );
  };

  handleBlur = id => event => {
    this.props.onBlur && this.props.onBlur();
  };

  handleFocus = id => event => {
    this.props.onFocus && this.props.onFocus();
  };

  renderRow = ({engine, location, language}, idx) => {
    const {lastSearchResults, isLoadingLocation} = this.props;
    const {current} = this.state;

    return (
      // <div className={styles.engine}>
      //   <Select
      //     onBlur={this.handleBlur('engine')}
      //     onFocus={this.handleFocus('engine')}
      //     onChange={option => this.handleChange(idx, 'engine', option ? option.value : null)}
      //     options={searchEngineOptions}
      //     value={engine}
      //     searchable={false}
      //     clearable={false}
      //   />
      // </div>
      <div className={styles.inputRow} key={'row_' + idx}>
        <div className={styles.location}>
          {engine === 'google' && (
            <Input
              value={location}
              onBlur={this.handleBlur('location')}
              onFocus={this.handleFocus('location')}
              loading={current == idx && isLoadingLocation}
              getItemValue={item => item.canonical_name.split(',').join(', ')}
              options={current == idx && lastSearchResults ? lastSearchResults : []}
              onChange={(value, item) => {
                this.handleChangeMulti(idx, {
                  location: value,
                  countryCode: item.country_code,

                  // @todo: This is intentional as lat and lng is returned wrong from the API
                  // Speak to Lee and/or Oliver for more info.
                  lat: item.lng,
                  lng: item.lat
                });
              }}
              autoComplete="new-password"
              renderItem={item => item.canonical_name.split(',').join(', ')}
              autocomplete={value => {
                this.props.searchForLocation(value, 10);
                // we want to clear the country if the user modifies the location string
                this.handleChangeMulti(idx, {
                  location: value,
                  countryCode: false,
                  lat: null,
                  lng: null
                });
              }}
            />
          )}
        </div>
        <div className={styles.language}>
          <Select
            onBlur={this.handleBlur('language')}
            onFocus={this.handleFocus('language')}
            onChange={option => this.handleChange(idx, 'language', option ? option.value : null)}
            value={language}
            multi={false}
            clearable={false}
            options={languageMap[engine]}
          />
        </div>
        <div className={styles.closeIcon}>
          <DeleteIcon onClick={this.handleRemoveRow(idx)} />
        </div>
      </div>
    );
  };

  handleChange = (idx, name, value) => {
    const rows = Array.isArray(this.props.value) ? this.props.value : [];

    rows[idx][name] = value;

    this.setState({current: idx});
    this.props.onChange(rows);
  };

  handleChangeMulti = (idx, data) => {
    const rows = Array.isArray(this.props.value) ? this.props.value : [];

    rows[idx] = Object.assign(rows[idx], data);

    this.setState({current: idx});
    this.props.onChange(rows);
  };

  handleClickAddRow = () => {
    const {value} = this.props;
    const rows = Array.isArray(value) ? [...value] : [];

    rows.push({...defaultRow});
    this.props.onChange(rows);
  };

  handleRemoveRow = idx => event => {
    const rows = Array.isArray(this.props.value) ? [...this.props.value] : [];

    rows.splice(idx, 1);

    this.setState({current: this.state.current === idx ? -1 : idx});
    this.props.onChange(rows);
  };

  render() {
    const {value} = this.props;
    const rows = Array.isArray(value) ? value : [];

    return (
      <div>
        {!rows.length && <p>Click the button below to add a location</p>}
        {!!rows.length && this.renderHeader()}
        {rows.map(this.renderRow)}

        <Button icon="plus" iconSize="lg" iconOnly onClick={this.handleClickAddRow} />
      </div>
    );
  }
}

export default connect(
  searchDataSelector,
  {searchForLocation}
)(SearchEnginesInput);
