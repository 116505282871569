import React from 'react';

class Bool extends React.Component {
  render() {
    if (this.props.data) {
      return <span>Yes</span>;
    }

    return <span>No</span>;
  }
}

export default Bool;
