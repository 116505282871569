import React from 'react';
import cx from 'classnames';
import Markdown from 'react-remarkable';
import styles from './actionDescription.scss';
import Prism from 'prismjs';

export default class ActionDescription extends React.Component {
  state = {showLongDesc: false};

  markdownOptions = {
    breaks: false,
    linkTarget: '_blank',
    highlight: function(str, lang) {
      if (Prism.languages[lang]) {
        try {
          return Prism.highlight(str, Prism.languages[lang], lang);
        } catch (err) {
          console.error('PrismJS error', err);
        }
      } else {
        console.error('PrismJS language "' + lang + '" not found');
      }

      return ''; // use external default escaping
    }
  };

  handleShowToggle = () => {
    this.setState({showLongDesc: !this.state.showLongDesc});
  };

  render() {
    const {description, longDescription, showAll} = this.props;

    return (
      <div
        className={cx(styles.actionDescription, {
          [styles.markdownClosed]: !this.state.showLongDesc && !showAll
        })}
      >
        <Markdown options={this.markdownOptions}>
          {this.state.showLongDesc || showAll
            ? description + '\n\n' + longDescription
            : description}

          {longDescription && !this.state.showLongDesc && !showAll && (
            <a onClick={this.handleShowToggle}>Show more</a>
          )}
          {longDescription && this.state.showLongDesc && !showAll && (
            <a className={styles.showLess} onClick={this.handleShowToggle}>
              Show less
            </a>
          )}
        </Markdown>
      </div>
    );
  }
}
