import React from 'react';
import {useDispatch, useSelector} from 'hooks/redux';
import {locationSelector} from 'modules/location/locationSelectors';
import {inspectorActionNextStepsSelector} from 'modules/inspector/inspectorSelectors';
import {
  removeContext,
  navigateToCategory,
  fetchInspectorPage
} from 'modules/inspector/inspectorActions';
import Button from 'components/button';
import styles from './inspectorStyle.scss';

export default function InspectorActionNextSteps({onClickNextPage, viewOtherPagePoints}) {
  const dispatch = useDispatch();
  const {showNextPage, openActionsOnPageCount} = useSelector(inspectorActionNextStepsSelector);
  const {params, query} = useSelector(locationSelector);

  const handleClickNextPage = () => {
    dispatch(fetchInspectorPage(params, {...query, relative: 'next'}));
  };

  const handleClickViewOtherPagePoints = () => {
    dispatch(removeContext());
    dispatch(navigateToCategory(''));
  };

  if (openActionsOnPageCount === 0 && !showNextPage) {
    return null;
  }

  return (
    <div className={styles.whatsNext}>
      <p>What's next?</p>

      {openActionsOnPageCount > 0 && (
        <Button onClick={handleClickViewOtherPagePoints}>
          View {openActionsOnPageCount} other issues on this page
        </Button>
      )}

      {showNextPage && <Button onClick={handleClickNextPage}>Next page</Button>}
    </div>
  );
}
