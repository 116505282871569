import React from 'react';

/**
 * This component is no longer used but has to be kept for backwards
 * compatibilty with old layouts.
 *
 * `return null` prevents anything from being rendered.
 */
export default class ExportAction extends React.Component {
  render() {
    return null;
  }
}
