import React from 'react';
import DifficultyComponent from 'components/difficulty';

class Difficulty extends React.Component {
  render() {
    const value = this.props.data;
    return <DifficultyComponent value={value} />
  }
}

export default Difficulty;
