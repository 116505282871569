import React from 'react';
import Button from 'components/button';
import ModalContent from 'modules/modal/components/modalContent';
import Reward25USD from './reward25.png';
import Reward50USD from './reward50.png';
import styles from './rewardsModal.scss';

const Rewards = {
  25: Reward25USD,
  50: Reward50USD
};

export default function RewardModal({reviewUrl, reviewAmountUsd, hideModal}) {
  return (
    <ModalContent
      title={'Would you like to earn $' + reviewAmountUsd + ' for a review?'}
      onClickButton={button => {
        if (button.props.confirm) {
          window.open(reviewUrl, '_blank');
        } else {
          hideModal();
        }
      }}
      buttons={[
        <Button confirm styles={{width: '100px'}}>
          Yes please!
        </Button>,
        <Button wire styles={{width: '100px'}}>
          No thanks
        </Button>
      ]}
    >
      <img src={Rewards[reviewAmountUsd]} className={styles.rewardCard} />

      <p className={styles.body}>
        Write a review about Silktide on G2 Crowd and receive an Amazon gift card worth $
        {reviewAmountUsd} (or equivalent). It only takes about 5 minutes.
      </p>
    </ModalContent>
  );
}
