import React from 'react';
import evaluate from 'token-js-parser';
import {capitalizeFirstLetter} from 'utils/stringUtils';

class Ast extends React.Component {
  getValue() {
    const {config, filter} = this.props;
    const {expression, ucfirst} = config;
    const value = evaluate(expression, {filters: filter});
    if (!value) {
      return null;
    }

    if (ucfirst) {
      return capitalizeFirstLetter(value);
    }

    return value;
  }

  render() {
    if (!this.props.config.bold) {
      return this.getValue();
    }

    return <strong>{this.getValue()}</strong>;
  }
}

Ast.handlesLoading = true;

export default Ast;
