import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {trackEvent} from 'modules/tracking';
import ListUptimeMonitors from 'modules/uptime/pages/listUptimeMonitors';
import SaveUptimeMonitor from 'modules/uptime/pages/saveUptimeMonitor';
import {fetchUptimeMonitors} from 'modules/uptime/actions';

export class ReportSettingsUptimePage extends React.Component {
  componentDidMount() {
    const {reportId} = this.props.match.params;
    this.props.fetchUptimeMonitors(reportId);

    trackEvent('Viewed Uptime config page', {
      forReport: reportId,
      fromFile: 'reportSettingsUptimePage.js'
    });
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={match.path} component={ListUptimeMonitors} />
        <Route path={`${match.path}/:monitorId`} component={SaveUptimeMonitor} />
      </Switch>
    );
  }
}

export default connect(
  null,
  {fetchUptimeMonitors}
)(ReportSettingsUptimePage);
