import {fromJS} from 'immutable';

const initialState = fromJS({
  flags: {}
});

export default function actionsReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FLAG:
      const currentState = state.getIn(['flags', action.id]);
      return state.setIn(
        ['flags', action.id],
        typeof action.forceEither === 'boolean' ? action.forceEither : !currentState
      );
  }
  return state;
}

// `command` looks like this:
//
// var expected = {
//   action: 'url',
//   url: 'https://silktide.com/'
// };
export const DO_COMMAND = 'instructions:DO_COMMAND';
export function doCommand(command, context = {}, filters = {}, event = null) {
  return {
    type: DO_COMMAND,
    command,
    context,
    filters,
    event
  };
}

// `expression`: the object expression (see instructionUtils.spec.js)
// `context`: the context I.E table row data or layout component config etc.
export const DO_INSTRUCTION = 'instructions:DO_INSTRUCTION';
export function doInstruction(expression, context = {}, filters = {}, event = null) {
  return {type: DO_INSTRUCTION, expression, context, filters, event};
}

export const TOGGLE_FLAG = 'instructions:TOGGLE_FLAG';
export function toggleFlagState(id, forceEither) {
  return {type: TOGGLE_FLAG, id, forceEither};
}
