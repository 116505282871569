import React from 'react';
import IconComponent from 'components/font-awesome';
import styles from './icon.scss';

class Icon extends React.Component {
  render() {
    const {data} = this.props;
    return (
        <span className={styles.icon}>
            <IconComponent name={data} />
        </span>
    );
  }
}

export default Icon;
