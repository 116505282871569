exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XP-R34B86ng7g2IBi_48g{color:#888}", ""]);

// exports
exports.locals = {
	"icon": "_2XP-R34B86ng7g2IBi_48g"
};