exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1XtTkuMH_ar22Ulo13NV95{padding:15px 10px 5px;min-height:70px;border:0;position:relative;display:flex;align-items:center}._1XtTkuMH_ar22Ulo13NV95 h1{font-size:26px;margin:0}._1XtTkuMH_ar22Ulo13NV95 p:first-of-type{margin:10px 0 0 0}._1XtTkuMH_ar22Ulo13NV95>*{flex:1;align-self:center}._1XtTkuMH_ar22Ulo13NV95 hr{margin:20px 0}._1O-bnKFuVE9xi7z8bITtMv{background:#ebebeb;border-bottom:1px solid #ddd;margin-bottom:10px;padding-left:0;padding-right:0}._1O-bnKFuVE9xi7z8bITtMv>div>div:first-child{text-align:center}", ""]);

// exports
exports.locals = {
	"header": "_1XtTkuMH_ar22Ulo13NV95",
	"withCategoryScore": "_1O-bnKFuVE9xi7z8bITtMv"
};