import {take, call, put} from 'redux-saga/effects';
import {REQUEST_UPDATE_REPORT_LOG, receiveReportLogs} from 'modules/logs';
import {reportLogEndpoint} from 'modules/logs/logUtils';
import PUT from 'utils/saga/put';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* updateReportLogSaga() {
  while (true) {
    const action = yield take(REQUEST_UPDATE_REPORT_LOG);
    const {missionId, config} = action;

    const url = reportLogEndpoint({missionId});

    const res = yield call(PUT, url, config);
    yield processResponse(res, {
      successAction: log => receiveReportLogs([log]),
      errorMessage: couldNot('update report log')
    });
  }
}
