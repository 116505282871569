exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3fQyhDrYEvOkHvU_f-ExbD{display:flex;padding:30px 20px 10px 20px;align-items:center;flex-wrap:wrap}._3fQyhDrYEvOkHvU_f-ExbD h1,._3fQyhDrYEvOkHvU_f-ExbD h2,._3fQyhDrYEvOkHvU_f-ExbD h3,._3fQyhDrYEvOkHvU_f-ExbD h4,._3fQyhDrYEvOkHvU_f-ExbD h5,._3fQyhDrYEvOkHvU_f-ExbD h6{font-weight:bold;font-size:25px;margin-bottom:0;margin-right:auto}._3fQyhDrYEvOkHvU_f-ExbD>*{margin-right:10px}._3fQyhDrYEvOkHvU_f-ExbD>*:last-child{margin-right:0}._1kMMtB5431A6RNo5XvT212{margin-bottom:20px;background-color:#fff;box-shadow:0 0 36px #d8dde5;border-radius:4px}.Z9D9G0mVD6i2flqLqfUET{font-size:1.5em;color:#030303}._1mfHpB1eYl_1vf2KNlNMhO{color:gray;text-transform:uppercase;font-size:.8em;display:block}._3SoCNpfzP5uPEafcGPmwfs{padding:0 1em}.Re8zI9-nrPzRFXZK6eE2P{margin-bottom:25px;padding-bottom:8px;font-size:1.4rem;font-weight:400;line-height:28px;border-bottom:1px solid #e4e7ed;color:#171717;margin-top:0}._1N52xAlf9qZcaECyqekVqw{padding:20px;width:100%;margin:0 auto;display:flex}._1wtifz4_y371UQ1duu7Yy9{padding:10px}._175SfyRAyZsz8bnHfPAC9A{font-size:1.5em;text-align:center}._1uxRD7t-vsNku47dKVoUdR{margin-top:20px;font-size:1em}.yKkVsm1RXPZ9M0omt7ZTy{background-color:#f6f7f9;padding:10px 20px;width:100%;display:flex}._1fE35w4MtH7lfsb_h5HHc9{flex:10}._28oJ3jMRyXFcnOFQf-B_JR{flex:2;text-align:right}.Z9D9G0mVD6i2flqLqfUET{font-size:1.5em;color:#030303;padding-top:7px}._3bdC4boAKctxJulTxjUc_u{margin-bottom:40px;background-color:#fff;box-shadow:0 0 36px #d8dde5;border-radius:4px}._2dC6QEahJbwq_-i6YTgLhV{flex:4;width:100%}@media(max-width: 1100px){._2dC6QEahJbwq_-i6YTgLhV{display:none}}", ""]);

// exports
exports.locals = {
	"header": "_3fQyhDrYEvOkHvU_f-ExbD",
	"serviceWrapper": "_1kMMtB5431A6RNo5XvT212",
	"title": "Z9D9G0mVD6i2flqLqfUET",
	"subTitle": "_1mfHpB1eYl_1vf2KNlNMhO",
	"pageWrapper": "_3SoCNpfzP5uPEafcGPmwfs",
	"heading": "Re8zI9-nrPzRFXZK6eE2P",
	"productBody": "_1N52xAlf9qZcaECyqekVqw",
	"pricingWrapper": "_1wtifz4_y371UQ1duu7Yy9",
	"priceLarge": "_175SfyRAyZsz8bnHfPAC9A",
	"priceRecommendation": "_1uxRD7t-vsNku47dKVoUdR",
	"headingBar": "yKkVsm1RXPZ9M0omt7ZTy",
	"headingLeft": "_1fE35w4MtH7lfsb_h5HHc9",
	"headingRight": "_28oJ3jMRyXFcnOFQf-B_JR",
	"productGroup": "_3bdC4boAKctxJulTxjUc_u",
	"progress": "_2dC6QEahJbwq_-i6YTgLhV"
};