import React from 'react';
import ExternalLink from 'modules/location/externalLink';

class DomainIssue extends React.Component {
  render() {
    const {
      point: {domain}
    } = this.props;

    return (
      <div>
        <p>Domain requires HTTP/2</p>
        <p>
          <ExternalLink href={'http://' + domain}>{domain}</ExternalLink>
        </p>
      </div>
    );
  }
}

export default DomainIssue;
