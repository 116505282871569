import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import styles from './select.scss';
import cx from 'classnames';
import _ from 'lodash';
import {normalizeOptions} from 'utils/arrayUtils';

// Select is wrapped to automatically save options on blur.

export default class Select extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
    ]).isRequired,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    placeholder: 'Select...'
  };

  componentDidMount() {
    // Makes it less confusing for the mis-match between Bootstrap inputs and react-select.
    if (this.props.autoFocus || this.props.autofocus) {
      _.defer(this.giveFocus);
    }
  }

  giveFocus = () => {
    // const {selectbox} = this;
    const {allowCreate} = this.props;

    // if we allowCreate, then a different component is rendered, thus the different refs
    if (allowCreate) {
      // selectbox.select.focus();
    } else {
      // selectbox.focus();
    }
  };

  // setSelectbox = ref => {
  //   this.selectbox = ref;
  // };

  render() {
    const {className, allowCreate, options, disableFiltering, placeholder, ...props} = this.props;

    const newProps = {
      ...props,
      placeholder,
      // ref: this.setSelectbox,
      options: normalizeOptions(options),
      openOnFocus: true,
      autoBlur: true,
      className: cx(styles.select, className, {
        [styles.hideTextInput]: disableFiltering
      })
    };

    if (disableFiltering) {
      newProps.onInputKeyDown = function(event) {
        // stop react-select from rendering text changes (forces user to select one of the pre-defined options)
        event.preventDefault();
      };
    }

    if (allowCreate) {
      return <ReactSelect.Creatable {...newProps} tabSelectsValue={false} />;
    }

    return <ReactSelect {...newProps} tabSelectsValue={false} />;
  }
}
