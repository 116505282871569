import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_COURSE, SAVE_ENROLLED_USERS_COMPLETE} from 'modules/courses/constants';
import {courseEndpoint} from 'modules/courses/utils';
import {requestCourseComplete} from 'modules/courses/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestCourseListener() {
  yield takeLatest([REQUEST_COURSE, SAVE_ENROLLED_USERS_COMPLETE], requestCourseSaga);
}

function* requestCourseSaga({courseId}) {
  const url = courseEndpoint({courseId});

  // in case SAVE_ENROLLED_USERS_COMPLETE errors
  if (!courseId) return;

  yield processRequest('GET', url, {
    *notFound() {
      yield put(saveLessonStateComplete('NOT_FOUND'));
    },
    *success({course, stages, lessons, tutors, students} = {}) {
      const newCourse = {...course};
      const newStages = stages.map(stage => {
        stage.lessons = lessons.filter(
          lesson => lesson.stageId.toString() === stage.stageId.toString()
        );
        return stage;
      });

      newCourse.stages = newStages;
      newCourse.tutors = tutors;
      newCourse.students = students;

      yield put(requestCourseComplete(null, newCourse));
    },
    *error(err) {
      yield put(requestCourseComplete(err));
    },
    errorMessage: couldNot('request course')
  });
}
