import {put, takeEvery} from 'redux-saga/effects';
import {
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  vatCountryChanged,
  receiveAccount,
  accountUpdateFailed
} from 'modules/accounts';
import {accountEndpoint} from 'modules/accounts/accountUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {showError} from 'utils/saga/alerts';

export default function* addOrUpdateAccountSaga() {
  yield takeEvery([ADD_ACCOUNT, UPDATE_ACCOUNT], addOrUpdateAccount);
}

export function* addOrUpdateAccount({account: data, type: actionType}) {
  const isAdd = actionType === ADD_ACCOUNT;

  return yield processRequest(
    'PUT',
    accountEndpoint(data),
    {
      success: function*(updatedAccount) {
        yield put(receiveAccount(updatedAccount));

        if (isAdd) {
          yield* handleAddSuccess(updatedAccount);
        } else {
          yield* handleUpdateSuccess(updatedAccount, data);
        }
        return updatedAccount;
      },

      failed: function*({errorCode} = {}) {
        yield put(accountUpdateFailed());
        if (isAdd) {
          yield showError(couldNot('Add account'));
        }
      }
    },
    data
  );
}

function* handleAddSuccess(account) {
  if (account.accountId && account.paymentType === PAYMENT_INVOICE) {
    yield put(push(newContactPath, {accountId: res.accountId}));
  }
}

function* handleUpdateSuccess(after, before) {
  if (before.addressCountry != after.addressCountry) {
    yield put(vatCountryChanged());
  }
}
