import {takeLatest, put} from 'redux-saga/effects';
import {VIEW_REPORT_CMS_SETTINGS_PAGE} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {receiveCMSConfig} from 'modules/reports';

export default function* fetchCMSConfigSaga() {
  yield takeLatest(VIEW_REPORT_CMS_SETTINGS_PAGE, fetchCmsConfig);
}

function* fetchCmsConfig({reportId}) {
  yield processRequest('GET', `reports/${reportId}/cms-config`, {
    success: function*(cmsConfig) {
      yield put(receiveCMSConfig(cmsConfig));
    }
  });
}
