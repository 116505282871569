import React from 'react';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// Renders the specified amount of children.
export default class RenderSome extends React.Component {
  renderChildren() {
    const {amount, children} = this.props;
    return React.Children.toArray(children).slice(0, amount);
  }

  render() {
    return <div {...filterInvalidDOMProps(this.props)}>{this.renderChildren()}</div>;
  }
}
