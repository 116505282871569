exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2UoUX6uFGuj3fVedExgdKy{align-items:center;box-shadow:0 2px 5px rgba(0,0,0,.1);display:flex;flex:1;flex-direction:column;margin:8px;text-align:center}._2UoUX6uFGuj3fVedExgdKy:first-child{margin-left:0}._2UoUX6uFGuj3fVedExgdKy:last-child{margin-right:0}@media screen and (max-width: 1199px){._2UoUX6uFGuj3fVedExgdKy{min-width:48%}._2UoUX6uFGuj3fVedExgdKy:nth-child(odd){margin-left:0px;margin-right:4px}._2UoUX6uFGuj3fVedExgdKy:nth-child(even){margin-left:4px;margin-right:0px}}@media screen and (max-width: 767px){._2UoUX6uFGuj3fVedExgdKy{margin:4px 0px !important;margin:0}}._2UoUX6uFGuj3fVedExgdKy button{min-width:140px}", ""]);

// exports
exports.locals = {
	"summaryScoreCard": "_2UoUX6uFGuj3fVedExgdKy"
};