export function servicesEndpoint() {
  return 'services';
}

export function serviceBreakdownEndpoint(serviceId) {
  return 'service/'+serviceId+'?detailed=1';
}

export function serviceUsageDataEndpoint(serviceId, featureId, offset) {
  return 'service/'+serviceId+'/featureClaims/'+featureId+'?offset='+offset;
}

const SERVICE_TYPES_MAP = {
  WebsiteMonitor: "Monitor",
  LegacyMonitor: "Monitor",
  SnapshotReport: "Snapshot",
  LegacySnapshotReport: "Snapshot",
  BuilderReport: "Builder",
  TeaserReport: "Teaser"
};

export function getDefaultServiceType(service) {
  const servicePeriod = getInitialActiveServicePeriod(service);
  const serviceTypeId = servicePeriod.serviceTypeId;
  if (SERVICE_TYPES_MAP[serviceTypeId]) {
    // Use friendly name if specified
    return SERVICE_TYPES_MAP[serviceTypeId];
  }

  // If we aren't aware of a friendly name, then try and produce one!
  return serviceTypeId.replace(/([A-Z])/g, ' $1').trim();
}

export function getInitialActiveServicePeriod(service) {
  const {servicePeriods} = service;

  if (!servicePeriods || !servicePeriods.length) {
    return null;
  }

  let servicePeriod = null;
  servicePeriods.forEach((sp) => {
    if (sp.isActive) {
      servicePeriod = sp;
      return;
    }
  });

  return servicePeriod;
}

export function isServiceAMonitor(service) {
  return getDefaultServiceType(service).indexOf("Monitor") !== -1;
}