import React from 'react';
import {connect} from 'react-redux';
import {paymentDetailsModalSelector} from 'modules/products/productSelectors';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Status from 'components/status';
import Icon from 'components/font-awesome';
import Center from 'components/center';
// TODO paymentDetailsModal no longer exists, but the css file is still used by this
import styles from './paymentDetailsModal.scss';
import ContactUsButton from 'components/button/contactUsButton';
import ModalContent from 'modules/modal/components/modalContent';
import {requestPaymentDetailsUpdate, requestPaymentDetails} from 'modules/payments';
import CardForm from 'modules/payments/components/cardForm';

// If this is passed props.token, that means we are creating a
// new subscription and should show card details
export class UpdatePaymentDetailsModal extends React.Component {
  static defaultProps = {
    formName: 'paymentDetails',
    continueLabel: 'Next',
    backLabel: 'Previous'
  };

  renderButtons() {
    const {continueLabel, backLabel} = this.props;
    return (
      <Center>
        <Buttons className={styles.buttons}>
          <Button wire onClick={this.handlePrev}>
            {backLabel}
          </Button>
          <Button onClick={this.handleNext}>{continueLabel}</Button>
        </Buttons>
      </Center>
    );
  }

  handleValidSubmit = data => {
    this.props.next(data);
  };

  handleNext = () => {
    const {submit, formName} = this.props;

    // We need to submit and validate the payment form
    // If it's valid, handleValidSubmit will be called.
    submit(formName);
  };

  handlePrev = () => {
    const {paymentDetails} = this.props;

    this.props.prev(paymentDetails);
  };

  render() {
    const {stripeError, paymentDetails, huge} = this.props;
    const header = (
      <h1>
        Payment details <Icon name="lock" />
      </h1>
    );

    return (
      <ModalContent
        title="Payment details"
        header={header}
        // buttons={['Update']}
        // onClickButton={this.handleNext}
      >
        <CardForm onValidSubmit={this.handleValidSubmit} />

        {stripeError && <StripeError {...stripeError} />}
      </ModalContent>
    );
    // {this.renderButtons()}
  }
}

function StripeError({message}) {
  return (
    <Status appearance="bad">
      Something went wrong. Please check your details and try again.
      <p className={styles.errorMessage}>{message} No payment has been taken.</p>
      <p>
        <ContactUsButton>Ask for help</ContactUsButton>
      </p>
    </Status>
  );
}

export default connect(
  paymentDetailsModalSelector,
  {
    next: requestPaymentDetailsUpdate,
    requestPaymentDetails
  }
)(UpdatePaymentDetailsModal);
