import cx from 'classnames';
import AnimateOnChange from 'components/animation/animateOnChange';
import React from 'react';
import styles from './animateHeightChange.scss';

const observerConfig = {childList: true, subtree: true};

// Detects height of children and animates if it changes
export default class AnimateHeightChange extends React.Component {
  constructor() {
    super();

    this.state = {
      height: null,
      fadingEnabled: true
    };
  }

  componentDidUpdate() {
    // We only wanna animate a fade between components if there's been a major change
    // i.e. content being swapped out.
    this.calculateHeightAtNextFrame(true);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationFrameId);
    this.observer.disconnect();
  }

  // When we mount, we want to watch for mutations to the sub DOM and calculate height on change.
  // We have no awareness of child component state - it may update independently to this
  // component and be missed.
  componentDidMount() {
    this.observer = new MutationObserver(() => this.calculateHeightAtNextFrame(false));
    this.observer.observe(this.content, observerConfig);
  }

  calculateHeightAtNextFrame = fadingEnabled => {
    this.animationFrameId = window.requestAnimationFrame(() => this.calculateHeight(fadingEnabled));
  };

  calculateHeight = (fadingEnabled = false) => {
    const {height} = this.state;

    const contentHeight = this.content.clientHeight;

    if (contentHeight !== height) {
      this.setState({height: contentHeight, fadingEnabled});
    }
  };

  // Note: this arguments are passed in from AnimateOnChange when
  // it's props change
  shouldFade({height, fadingEnabled}, {height: oldHeight}) {
    return fadingEnabled && height !== oldHeight;
  }

  setContent = ref => {
    this.content = ref;
  };

  render() {
    const {contentClassName, className, comparator, children} = this.props;
    const {height, fadingEnabled} = this.state;

    return (
      <div style={{height}} className={cx(styles.wrapper, className)}>
        <AnimateOnChange
          element="div"
          height={height}
          fadingEnabled={fadingEnabled}
          animationClassName={styles.fadeBetween}
          comparator={comparator || this.shouldFade}
        >
          <div className={contentClassName} ref={this.setContent}>
            {children}
          </div>
        </AnimateOnChange>
      </div>
    );
  }
}
