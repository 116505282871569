import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment';
import {choosePluralVersion} from 'utils/interpolationUtils';

export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

// Returns true if value *requires* floating point representation
// e.g. 1.1 will return true, 1.0 will return false
export function isFloat(value) {
  return value % 1 !== 0;
}

export function toDecimal(number, digits) {
  if (!number) number = 0;

  if (typeof number === 'string') {
    number = parseFloat(number, 10);
  }
  if (number.toString().indexOf('.') > -1) {
    return parseFloat(number.toFixed(digits), 10);
  }
  return number;
}

// Returns the number of decimal places, including scientific e.g. 3.2e+41
export function countDecimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

// found here http://forums.shopify.com/categories/2/posts/29259
export function getOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return formatGivenNumber(n, 0) + (s[(v - 20) % 10] || s[v] || s[0]);
}

// Convert a byte count into a human-friendly measurement, e.g. KB, MB etc.
export function getBytes(bytes) {
  const kilobyte = 1024;
  const megabyte = 1048576; // 1024^2
  const gigabyte = 1073741824; // 1024^3

  if (bytes < kilobyte) {
    return bytes + ' bytes';
  }
  if (bytes < megabyte) {
    return Math.round(bytes / kilobyte) + ' KB';
  }
  // < 10MB, we use decimal place
  if (bytes < megabyte * 10) {
    return toDecimal(bytes / megabyte, 1) + ' MB';
  }
  if (bytes < gigabyte) {
    return Math.round(bytes / megabyte) + ' MB';
  }
  return toDecimal(bytes / gigabyte, 1) + ' GB';
}

// Formats the input number to the specified number of decimal places
export function formatGivenNumber(number, decimals = 0) {
  if (!_.isNumber(number)) return number;

  // TODO: rebuild as below
  // const fixed = number.toFixed(decimals).toString();
  // const [characteristic, mantissa] = fixed.split('.');

  // if decimal, find out how many places.
  let givenFormat = '000,000,000';
  let decimalPlaces = !_.isUndefined(decimals) ? decimals : countDecimalPlaces(number);

  if (decimalPlaces) {
    givenFormat += '.';
    for (let i = 0; i < decimalPlaces; i++) {
      givenFormat += '0';
    }
  }

  return numeral(number).format(givenFormat);
}

// Round a number, removing decimal places if we don't need them
// e.g. 1.1 => 1.1, but 1.0 => 1
export function toFixed(number, decimals = 0) {
  let n = Number(number).toFixed(decimals);

  if (n.match(/\.0$/)) {
    n = Math.round(n);
  }

  return n;
}

/*
 * This should display numbers intelligently as they get large, e.g.
 *
 * "12" <- below 1000
 * "1.2k" <- below 10k
 * "12k" <- below 1m
 * "1.1m" <- above 1m
 */
export function toConcise(number, decPlaces = 1) {
  const units = ['k', 'm'];

  decPlaces = Math.pow(10, decPlaces);

  for (let i = units.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < units.length - 1) {
        number = 1;
        i++;
      }

      number += units[i];

      break;
    }
  }

  return number + '';
}

// Returns a string representation of the provided number of seconds
export function toDuration(number) {
  if (!number) {
    return;
  }

  const duration = moment.duration(number, 'seconds');

  const years = duration.years();
  const months = duration.months();
  const weeks = duration.weeks();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // number of 60 = 1 min, 0 secs
  // number of 65 = 1 min, 5 secs
  // number of 47580 = 13 hours, 13 mins,
  // and so in with only ever showing two things at a time depending on how long they are... e.g.  1 year, 10 months.  - 10 months, 3 weeks. etc.

  if (years > 0) {
    return (
      years +
      choosePluralVersion(years, ' yr, ', ' yrs, ') +
      months +
      choosePluralVersion(months, ' mnth', ' mnths')
    );
  } else if (months > 0) {
    return (
      months +
      choosePluralVersion(months, ' mnth, ', ' mnths, ') +
      weeks +
      choosePluralVersion(weeks, ' wk', ' wks')
    );
  } else if (weeks > 0) {
    return (
      weeks +
      choosePluralVersion(weeks, ' wk, ', ' wks, ') +
      days +
      choosePluralVersion(days, ' day', ' days')
    );
  } else if (days > 0) {
    return (
      days +
      choosePluralVersion(days, ' day, ', ' days, ') +
      hours +
      choosePluralVersion(hours, ' hr', ' hrs')
    );
  } else if (hours > 0) {
    return (
      hours +
      choosePluralVersion(hours, ' hr, ', ' hrs, ') +
      minutes +
      choosePluralVersion(minutes, ' min', ' mins')
    );
  } else if (minutes > 0) {
    return (
      minutes +
      choosePluralVersion(minutes, ' min, ', ' mins, ') +
      seconds +
      choosePluralVersion(seconds, ' sec', ' secs')
    );
  } else {
    return seconds + choosePluralVersion(seconds, ' sec', ' secs');
  }
}

export function revolveIndex(index, length, amount = 1) {
  let newIndex = (index + amount) % length;

  if (newIndex < 0) {
    newIndex += length;
  }

  if (newIndex >= length) {
    newIndex -= length;
  }

  return newIndex;
}
