import {List} from 'immutable';
import {makePath} from 'utils/urlUtils';
import Sizzle from 'sizzle';
import Prism from 'prismjs';
import Mark from 'mark.js-silktide'; // we forked our own version in order to fix some bugs
import {EFFECT_CONFIG} from './inspectorConstants';

export function inspectorToolPath() {
  return '/tools/wcag';
}

export function isInspectorPath(path) {
  return /^\/\d+\/reports\/.*\/inspector(?:\/.*$|$)/.test(path);
}

export function inspectorToolActionPath({actionId}) {
  return makePath(inspectorToolPath(), {actionId});
}

export function inspectorPagePath({accountId, reportId}) {
  return `/${accountId}/reports/${reportId}/inspector`;
}

export function inspectorPageActionPath({filters, ...params}) {
  return makePath(inspectorPagePath(params), filters);
}

export function inspectorEndpoint({reportId}) {
  return `reports/${reportId}/inspector`;
}

export function getEmptyStatus(state) {
  switch (state) {
    case 'open':
      return {
        appearance: 'good',
        text: "There's nothing left to do"
      };
    case 'approved':
      return {
        appearance: 'info',
        text: 'Nothing has been approved yet'
      };
    case 'ignored':
      return {
        appearance: 'info',
        text: 'Nothing has been ignored yet'
      };
  }
}

export function getAffectedEffects(effectId) {
  const effects = EFFECT_CONFIG[effectId];
  if (!effects) return [];

  const effectKeys = Object.keys(effects);

  const affectedEffects = [];
  for (let key in EFFECT_CONFIG) {
    if (key === effectId) continue;

    const thisEffectKeys = Object.keys(EFFECT_CONFIG[key]);
    if (thisEffectKeys.some(effectKey => effectKeys.includes(effectKey))) {
      affectedEffects.push(key);
    }
  }
  return affectedEffects;
}

// This is the HTML used in the 'Source' view in the inspector. The HTML source needs to be escaped so the
// raw HTML is visible to users, and we need to be able to 'highlight' specific parts of the HTML. However,
// escaping the HTML means it can't be selected using CSS selectors.
//
// A simple workaround for now is:
//
//   1. Find elements using CSS (before escaping the html)
//   2. Use special tokens as placeholders surrounding each element elements
//   3. Escape all HTML
//   4. Replace the special tokens with <mark> tags
//
// This code alone is pretty simple but we have to account for elements that may get selected multiple times
// (giving us multiple wrapped <mark> elements surrounding a single element). For these we can post process
// the DOM we create and combine any nested `mark>mark` elements.
//
// html: raw html of the page (not escaped)
// points: immutable map (see inspector.stories.js for data structure)
//
// we use `points[x].selectors` to wrap specific elements in `html` before escaping everything (except the highlighted elements)
export function markupHtmlSource(html, points) {
  const start = '[[SILKTIDE_MARK|pointId]]'; // `pointId` gets replaced
  const end = '[[SILKTIDE_END_MARK]]';
  const startRegex = /\[\[SILKTIDE_MARK(?:\|([a-zA-Z0-9_-]+))+\]\]/; // matches [[SILKTIDE_MARK|1234|2345]]
  const endRegex = /\[\[SILKTIDE_END_MARK\]\]/;

  const headRegex = /<head[^>]*>([\s\S]*?)<\/head>/i;
  const headHtml = html.match(headRegex);
  const isInvalidHead =
    headHtml !== undefined &&
    headHtml[0] !== undefined &&
    headHtml[0].match(/<(div|iframe)[^>]*>/gi);

  let cleanHtml = html.replace(/↵/g, '\n');
  if (isInvalidHead) {
    cleanHtml = cleanHtml.replace(
      headRegex,
      '<head><!-- Silktide has removed the <head> section as your code is malformed. This will prevent us being able to highlight issues in the <head>. --></head>'
    );
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanHtml, 'text/html');

  // mutate the `doc` DOM by surrounding all necessary elements with the placeholders above
  detectAndMarkElements(doc, points, start, end);

  const documentElement = doc.documentElement ? doc.documentElement : doc;

  const syntaxHighlightedHtml = Prism.highlight(
    documentElement.outerHTML,
    Prism.languages.html,
    'html'
  );
  // all the escaped html has now been syntax highlighted. This means our placeholders have also probably been wrapped in SPAN elements
  // NOTE potentially remove span wrappers from placeholders.

  // We are now left with syntax highlighted html string. We need to replace the place holders with <mark> elements
  // now the HTML is escaped, replace placeholders with <mark> tags, leaving us with plain text and <mark> tags
  const demarkedHtml = demarkElements(syntaxHighlightedHtml, startRegex, endRegex);
  // this html is not syntax highlited AND we've got <marks> all over the DOM where necessary.
  // each <mark> has a point_id_{hash} className for selecting the elements later

  // reparse the dom so we can process duplicate marks
  const escapedDoc = parser.parseFromString(demarkedHtml, 'text/html');

  // detect and remove nested <mark> tags (handles cases where two points use the same selector)
  // there is no need for two <mark> elements, just one that is shows for both points.
  detectAndDenestDoubleMarks(escapedDoc);

  // PrismJS escapes & inside html strings like src="url?foo&bar=baz". we can undo this because they provide css selectors
  [...escapedDoc.querySelectorAll('.tag .attr-value .entity[title="&"]')].forEach(el => {
    el.innerText = '&';
  });
  [...escapedDoc.querySelectorAll(`.tag .attr-value .entity[title='"']`)].forEach(el => {
    el.innerText = '"';
  });

  // return the escaped html source complete with <mark> tags around specific elements defined by `points`
  return escapedDoc.documentElement.outerHTML;
}

// this is duplicated in wheatley (shitty I know) but wheatley serves a vanillaJS file to handle iframes
// no webpack, no es6, so it's hard to combine the two into a single source of truth.
const textSelectorOptions = {
  diacritics: false,
  caseSensitive: false,
  acrossElements: true,
  separateWordSearch: false,
  ignoreJoiners: true,
  filter: onFilterMark,
  accuracy: {
    value: 'exactly',
    // think email addresses
    limiters: [
      '!',
      '@',
      '#',
      '&',
      '*',
      '(',
      ')',
      '-',
      '–',
      '—',
      '+',
      '=',
      '[',
      ']',
      '{',
      '}',
      '|',
      ':',
      ';',
      "'",
      '"',
      '‘',
      '’',
      '“',
      '”',
      ',',
      '.',
      '<',
      '>',
      '/',
      '?',
      ':',
      '.'
      // ''
    ]
  }
};

// Turn <html-element /> (using CSS selectors in `points`)
// into [[MARK|1234]]<html-element />[[END_MARK]]
function detectAndMarkElements(doc, points, startMark, endMark) {
  const textSelector = new Mark(doc, textSelectorOptions);

  points.forEach(point => {
    const selectorsPreJs = point.get('selectors', List());
    if (selectorsPreJs === null) {
      return;
    }

    const selectors = selectorsPreJs.toJS();
    const selectorString = selectors
      .reduce(function(reduction, {type, match, cssSelector, ...options}) {
        const matchAfterAdjustments = getSelectorAfterAdjustments(match, options);

        if (type === 'css') {
          reduction.push(matchAfterAdjustments);
        } else if (type === 'text') {
          var tempIdClass = 'temp-marked-text-selection';
          // NOTE this snippet removes the '#text' part of the selector if it exists (may no longer be needed)
          // cssSelector = cssSelector.slice(0, cssSelector.indexOf('#text')).replace(/>$/, '');

          try {
            var context = Sizzle(cssSelector, doc)[0];
          } catch (e) {
            var context = null;
            console.info('sizzle failed for css sel: ', cssSelector);
          }

          if (cssSelector && context) {
            var contextTextSelector = new Mark(context, textSelectorOptions);

            contextTextSelector.mark(
              matchAfterAdjustments,
              Object.assign({}, textSelectorOptions, options, {
                element: 'span',
                className: tempIdClass
              })
            );
          } else {
            textSelector.mark(
              matchAfterAdjustments,
              Object.assign({}, textSelectorOptions, options, {
                element: 'span',
                className: tempIdClass
              })
            );
          }

          // we add a selector for html surrounding the words we matched
          if (!reduction.includes('.' + tempIdClass)) {
            reduction.push('.' + tempIdClass);
          }
        }
        return reduction;
      }, [])
      .join(',');

    // select all the elements using the selectors

    var elements;
    try {
      elements = Sizzle(selectorString, doc);
    } catch (err) {
      console.info('Selector failed:', selectorString);
      elements = [];
    }

    // attach the pointId to the placeholder string [MARK|pointId]
    const mark = startMark.replace('pointId', point.get('pointId'));

    // mark all the elements that belong to this point so we can identify it later
    elements.forEach(element => {
      if (element.tagName === 'HTML') return; // ignore html for now

      markElement(element, mark, endMark);
    });
    textSelector.unmark();
  });
}

function onFilterMark(node, term, totalCounter, counter) {
  // if (this.preBoundary) {
  //   const nodeValue = node.nodeValue;
  //   const before = this.pointBefore.substr(3);
  //   const splitOnWord = nodeValue.split(term);

  //   let isCorrectInstance = false;
  //   splitOnWord.forEach((preSentence) => {
  //     const preSentencePos = preSentence.indexOf(before);
  //     if (preSentencePos === counter) {
  //       // OK so we found the pre-sentence and it matches the instance of this filter.
  //       isCorrectInstance = true;
  //     }
  //   });
  //   return isCorrectInstance;
  // }

  // if (this.postBoundary) {
  //   // do something
  // }

  return true;
}

function getSelectorAfterAdjustments(selectorString, options) {
  var pre = removePartialWord(options.preBoundary);
  var post = removePartialWord(options.postBoundary);

  return pre + selectorString + post;
}

// A partial word is defined as a word next to "...". So "blah..." or "...blah" are partial words. NOT "blah ..." as
// there is a space. The "..." will only be at the very start or end of the string.
function removePartialWord(sentence) {
  if (typeof sentence !== 'string') return '';

  if (sentence.substr(0, 3) === '...') {
    var match = sentence.match(/\s/);
    if (match && match.index >= 0) {
      // we could +1 to the index to not include the space, but we are trimming anyway
      return sentence.substr(match.index).trimStart();
    }
  }
  if (sentence.substr(-3) === '...') {
    var match = sentence.match(/\s[^\s]*$/);
    if (match && match.index >= 0) {
      return sentence.substr(0, match.index).trimEnd();
    }
  }
  return sentence;
}

// Turns <mark class="point_id_1"><mark class="point_id_2">...</mark></mark>
// into <mark class="point_id_1 point_id_2"></mark>
function detectAndDenestDoubleMarks(doc) {
  var duplicateMark;
  while ((duplicateMark = doc.querySelector('mark>mark'))) {
    var parent = duplicateMark.parentElement;

    // only handle duplicates if the parent doesn't contain anything else. Only if it's a pure `<mark><mark>...</mark></mark>`
    if (parent.children.length > 1) return;

    var ids = duplicateMark.className
      .split(' ')
      .filter(c => c.substr(0, 9) === 'point_id_')
      .join(' ');

    // parent steals childs point_ids
    parent.className += ' ' + ids;

    // parent steals chidrens children
    var innerNodes = [...duplicateMark.childNodes];
    parent.removeChild(duplicateMark);
    innerNodes.forEach(elem => parent.appendChild(elem));

    // destroy duplicate
    duplicateMark = null;
    innerNodes = null;
  }
}

// Turn <html-element />
// into [[MARK|1234]]<html-element />[[END_MARK]]
function markElement(element, start, end) {
  element.parentElement.insertBefore(document.createTextNode(start), element);
  element.parentElement.insertBefore(document.createTextNode(end), element.nextSibling);
}

// Turn [[MARK|1234]]
// into <mark class="point_id_1234">
function demarkElements(html, start, end) {
  return html
    .replace(new RegExp(start, 'g'), function() {
      return '<mark class="insites-mark point_id_' + arguments[1] + '">';
    })
    .replace(new RegExp(end, 'g'), '</mark>');
}

function escapeHtml(html) {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}
