import React from 'react';
import posed, {PoseGroup} from 'react-pose';
import PolicyRuleField from './policyRuleField';
import Panel from 'components/panel';
import {Field} from 'modules/form/hooks/useForm';
import {showConfirmModal} from 'modules/modal';
import {useDispatch} from 'hooks/redux';

const Fade = posed.div({
  enter: {opacity: 1},
  exit: {opacity: 0}
});

function PolicyRulesFields({field, scope, ruleOperator, addRuleButton}) {
  const dispatch = useDispatch();

  return (
    <Field
      field={field}
      required
      customMessages={{
        required: (
          <Panel marginBottom={40} style={{textAlign: 'center'}}>
            <h3 style={{marginBottom: '20px'}}>This policy doesn’t have any rules yet.</h3>

            <div style={{marginBottom: '20px'}}>{addRuleButton}</div>

            <p style={{margin: '0'}}>
              Please click on the "Add rule" button to add your first rule
            </p>
          </Panel>
        )
      }}
      show
    >
      <div style={{marginBottom: '20px'}}>
        <PoseGroup>
          {field.getFields().map((subfield, i) => {
            const rule = subfield.getValue();
            return (
              <Fade key={subfield.key}>
                <PolicyRuleField
                  first={i === 0}
                  field={subfield}
                  scope={scope}
                  ruleOperator={ruleOperator}
                  key={rule.policyCheckId + i}
                  policyCheckId={rule.policyCheckId}
                  onDelete={() => {
                    dispatch(
                      showConfirmModal({
                        title: 'Remove this rule',
                        content: 'Do you want to remove this rule?',
                        submitLabel: 'Yes, remove rule',
                        cancelLabel: 'No, go back',
                        destructiveConfirm: true,
                        callback: didConfirm => {
                          if (didConfirm) {
                            field.removeIndex(i);
                            field.rerender();
                          }
                        }
                      })
                    );
                  }}
                />
              </Fade>
            );
          })}
        </PoseGroup>
      </div>
    </Field>
  );
}

export default PolicyRulesFields;
