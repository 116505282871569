import React from 'react';
import Flex from 'components/layout/flex';
import Spinner from 'components/spinner';
import {useDispatch, useSelector} from 'hooks/redux';
import {requestDashboard, requestWidgetTypes, requestDashboardResults} from '../actions';
import {
  dashboardSelector,
  requestingDashboardSelector,
  requestingWidgetTypesSelector
} from '../selectors';
import Widget from './widget';
import {
  Row as DashboardRow,
  Column as DashboardColumn
} from 'modules/dashboards/components/dashboard';
import WidgetsProvider from './widgetsProvider';

export default function Dashboard({dashboardId, ...context}) {
  const dispatch = useDispatch();
  const dashboardDefinition = useSelector(dashboardSelector, {dashboardId});
  const requestingDashboardState = useSelector(requestingDashboardSelector);
  const requestingWidgetTypesState = useSelector(requestingWidgetTypesSelector);

  React.useEffect(() => {
    dispatch(requestWidgetTypes());
    dispatch(requestDashboardResults(dashboardId, context));
  }, []);

  React.useEffect(() => {
    dispatch(requestDashboard(dashboardId));
  }, [dashboardId]);

  if (
    !dashboardDefinition ||
    requestingDashboardState.get('loading') ||
    requestingWidgetTypesState.get('loading')
  ) {
    return <Spinner marginTop={20} marginBottom={20} />;
  }

  const dashboard = dashboardDefinition.get('dashboard');

  return (
    <div>
      {dashboard.get('rows').map((rowData, rowIndex) => {
        return (
          <DashboardRow key={rowIndex} rowIndex={rowIndex}>
            {Array(rowData.get('columns'))
              .fill()
              .map((_, columnIndex) => {
                return (
                  <DashboardColumn key={columnIndex} columnIndex={columnIndex}>
                    <WidgetsProvider
                      dashboardId={dashboardId}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                    >
                      {widgets =>
                        widgets.map(widget => {
                          return <Widget key={widget.get('widgetId')} widget={widget} />;
                        })
                      }
                    </WidgetsProvider>
                  </DashboardColumn>
                );
              })}
          </DashboardRow>
        );
      })}
    </div>
  );
}
