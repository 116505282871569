exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._32xRRlKEBgUnTKD_j9LBN5{display:inline-block;padding:2px 6px;border:1px solid #533be2;border-radius:5px;color:#533be2;margin:10px 0}", ""]);

// exports
exports.locals = {
	"annotation": "_32xRRlKEBgUnTKD_j9LBN5"
};