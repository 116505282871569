import * as Consts from './constants';
import {showInModal} from 'modules/modal';
import AddWidgetModal from './containers/addWidgetModal';
import EditWidgetModal from './containers/editWidgetModal';
import SelectWidgetModal from './containers/selectWidgetModal';

export function requestDashboards() {
  return {type: Consts.REQUEST_DASHBOARDS};
}
export function requestDashboardsComplete(error, dashboards) {
  return {type: Consts.REQUEST_DASHBOARDS_COMPLETE, error, dashboards};
}

export function requestDashboard(dashboardId) {
  return {type: Consts.REQUEST_DASHBOARD, dashboardId};
}
export function requestDashboardComplete(error, dashboard) {
  return {type: Consts.REQUEST_DASHBOARD_COMPLETE, error, dashboard};
}

export function requestDashboardResults(dashboardId, context) {
  return {type: Consts.REQUEST_DASHBOARD_RESULTS, dashboardId, context};
}
export function requestDashboardResultsComplete(error, dashboardId, results) {
  return {type: Consts.REQUEST_DASHBOARD_RESULTS_COMPLETE, error, dashboardId, results};
}

export function saveDashboard(dashboard) {
  return {type: Consts.SAVE_DASHBOARD, dashboard};
}
export function saveDashboardComplete(error, dashboard) {
  return {type: Consts.SAVE_DASHBOARD_COMPLETE, error, dashboard};
}

export function deleteDashboard(dashboardId) {
  return {type: Consts.DELETE_DASHBOARD, dashboardId};
}
export function deleteDashboardComplete(error, dashboardId) {
  return {type: Consts.DELETE_DASHBOARD_COMPLETE, error, dashboardId};
}

export function requestWidgetTypes() {
  return {type: Consts.REQUEST_WIDGET_TYPES};
}
export function requestWidgetTypesComplete(error, widgetTypes) {
  return {type: Consts.REQUEST_WIDGET_TYPES_COMPLETE, error, widgetTypes};
}

export function showAddWidgetModal(dashboardId, rowIndex, columnIndex, widgetTypeId) {
  return showInModal(AddWidgetModal, {
    dashboardId,
    rowIndex,
    columnIndex,
    widgetTypeId
  });
}

export function showSelectWidgetModal(dashboardId, rowIndex, columnIndex, contextTypeId) {
  return showInModal(SelectWidgetModal, {
    dashboardId,
    rowIndex,
    columnIndex,
    contextTypeId
  });
}

export function showEditWidgetModal(dashboardId, widgetId) {
  return showInModal(EditWidgetModal, {dashboardId, widgetId});
}

export function addWidget(dashboardId, rowIndex, columnIndex, widgetTypeId, formData) {
  return {type: Consts.ADD_WIDGET, dashboardId, rowIndex, columnIndex, widgetTypeId, formData};
}
export function editWidget(dashboardId, widgetId, formData) {
  return {type: Consts.EDIT_WIDGET, dashboardId, widgetId, formData};
}
export function saveWidgetComplete(error, dashboard) {
  return {type: Consts.SAVE_WIDGET_COMPLETE, error, dashboard};
}

export function confirmDeleteWidget(dashboardId, widgetId) {
  return {type: Consts.CONFIRM_DELETE_WIDGET, dashboardId, widgetId};
}
export function confirmDeleteWidgetComplete(error, dashboard) {
  return {type: Consts.CONFIRM_DELETE_WIDGET_COMPLETE, error, dashboard};
}

export function addDashboardRow(dashboardId) {
  return {type: Consts.ADD_DASHBOARD_ROW, dashboardId};
}
export function addDashboardColumn(dashboardId, rowIndex) {
  return {type: Consts.ADD_DASHBOARD_COLUMN, dashboardId, rowIndex};
}
export function removeDashboardRow(dashboardId, rowIndex) {
  return {type: Consts.REMOVE_DASHBOARD_ROW, dashboardId, rowIndex};
}
export function removeDashboardColumn(dashboardId, rowIndex, columnIndex) {
  return {type: Consts.REMOVE_DASHBOARD_COLUMN, dashboardId, rowIndex, columnIndex};
}

export function insertDashboardRow(dashboardId, rowIndex) {
  return {type: Consts.INSERT_DASHBOARD_ROW, dashboardId, rowIndex};
}
export function setDashboardColumnCount(dashboardId, rowIndex, colCount) {
  return {type: Consts.SET_DASHBOARD_COLUMN_COUNT, dashboardId, rowIndex, colCount};
}
export function setDashboardColumnCountComplete(dashboardId, rowIndex, colCount) {
  return {type: Consts.SET_DASHBOARD_COLUMN_COUNT_COMPLETE, dashboardId, rowIndex, colCount};
}
