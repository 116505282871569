import React from 'react';
import IconComponent from 'components/font-awesome';

class Icon extends React.Component {
  render() {
    const {config} = this.props;
    const {name, margin} = config;

    const style = {};

    if (margin === 'right') {
      style.marginRight = '10px';
    }

    return <IconComponent name={name} style={style} />;
  }
}

export default Icon;
