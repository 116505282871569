import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Tabs from 'components/tabs';
import Tab from 'components/tabs/tab';
import styles from './sortableTabs.scss';
import Icon from 'components/font-awesome';

export default class SortableTabs extends React.Component {
  onSortEnd = ({oldIndex, newIndex}, e) => {
    e.preventDefault();

    const {onSort} = this.props;

    if (oldIndex != newIndex) {
      onSort && onSort(oldIndex, newIndex);
    }
  };

  render() {
    return (
      <SortableList
        {...this.props}
        axis="xy"
        distance={3}
        lockToContainerEdges
        onSortEnd={this.onSortEnd}
        helperClass={styles.helper}
        className={styles.sortableTabs}
      >
        {this.props.children}
      </SortableList>
    );
  }
}

const SortableTab = SortableElement(({children, ...props}) => <Tab {...props}>{children}</Tab>);

const SortableList = SortableContainer(({children, ...props}) => {
  return (
    <Tabs {...props}>
      {children.map((child, idx) => {
        const {children: inner, ...innerProps} = child.props;

        return (
          <SortableTab key={child.key} index={idx} {...innerProps}>
            {inner}
          </SortableTab>
        );
      })}
    </Tabs>
  );
});
