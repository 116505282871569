// Account Type Groups
export const FREE = 'free';
export const ECOMMERCE = 'ecommerce';
export const ENTERPRISE = 'enterprise';
export const QA = 'qa';
export const INTERNAL = 'internal';

// Account Type Payment Models
export const FLEXIBLE = 'flexible';
export const FIXED = 'fixed';
//           ECOMMERCE = 'ecommerce'
//           FREE = 'free'

// Account Type States
export const TRIAL = 'trial';
export const PAYING = 'paying';
//           FREE = 'free'
