import React from 'react';
import cx from 'classnames';
import SwitchOptionComponent from 'components/switch/switchOption';
import styles from 'components/switch/switch.scss';

export default class SwitchOption extends React.Component {
  handleClick = () => {
    const {
      setFilterParams,
      config: {filter: filterName, value},
      config
    } = this.props;

    if (setFilterParams) {
      const params = {
        [filterName]: value
      };

      if (config && config.resetfilter) {
        params[config.resetfilter] = config.resetfiltervalue;
      }

      setFilterParams(params);
    }
  };

  render() {
    const {
      children,
      filter,
      config: {value, filter: filterName, showCaret, dropdownOption}
    } = this.props;

    let className = null;
    if (children && children.length > 0) {
      if (children[0].props.config.type === 'Count') {
        // @todo: Horrible temporary hack until we figure out how to pass specific styles from the BE.
        className = styles.countChild;
      }
    }

    return (
      <SwitchOptionComponent
        active={value === filter[filterName]}
        onClick={this.handleClick}
        className={cx(className, {[styles.dropdownOption]: dropdownOption})}
        showCaret={showCaret}
      >
        {children}
      </SwitchOptionComponent>
    );
  }
}
