import React from 'react';
import {connect} from 'react-redux';
import Panel from 'components/panel';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import InteractionForm from '../components/interactionForm';
import {saveInteraction, viewInteractions} from '../actions';

class NewInteractionPage extends React.Component {
  handleSubmit = interaction => {
    this.props.saveInteraction(interaction);
  };

  handleCancel = () => {
    this.props.viewInteractions();
  };

  render() {
    const {match, location} = this.props;
    return (
      <WithReportSidebar match={match} location={location}>
        <div>
          <Helmet title="New interaction" />
          <PageHeader
            title="New interaction"
            goBack={() => {
              this.props.viewInteractions();
            }}
          />

          <PageBody noPaddingBottom>
            <InteractionForm onSubmit={this.handleSubmit} onCancel={this.handleCancel} />
          </PageBody>
        </div>
      </WithReportSidebar>
    );
  }
}

export default connect(
  null,
  {saveInteraction, viewInteractions}
)(NewInteractionPage);
