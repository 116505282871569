import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {viewReportPage, leaveReportPage, viewReportIndexPage, clearReport} from 'modules/reports';
import ReportListeners from '../pages/reportListeners';
import ReportIndexPage from '../pages/reportIndexPage';
import TestsRoute from 'modules/tests/routes/testsRoute';
import ReportSettingsRoute from './reportSettingsRoute';
import InspectorRoute from 'modules/inspector/routes/inspectorRoute';
import PrintReportPage from 'modules/reports/pages/printReportPage';
import {requestReportSegments} from 'modules/segments/segmentActions';
import {fetchAlertSubscriptions} from 'modules/alertSubscriptions/actions';
import {push} from 'modules/location';
import {parseQuery} from 'utils/urlUtils';
import ReportUpgradeChecker from 'modules/reports/components/reportUpgradeChecker';

export class ReportsRoute extends React.Component {
  componentDidMount() {
    this.viewReport(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.reportId !== nextProps.match.params.reportId) {
      this.props.leaveReportPage();

      this.viewReport(nextProps);
    }
  }

  // This is a function that means we can use same functionality in didMount and receiveProps (#obvious idiot)
  viewReport(props) {
    const {
      viewReportPage,
      fetchAlertSubscriptions,
      location: {search},
      match: {
        params: {reportId}
      }
    } = props;
    const query = parseQuery(search);

    viewReportPage(reportId, query.folderId);
    fetchAlertSubscriptions(reportId);
  }

  componentWillUnmount() {
    const {
      match: {
        params: {reportId}
      }
    } = this.props;

    this.props.leaveReportPage();
    this.props.clearReport(reportId);
  }

  shouldComponentUpdate(nextProps) {
    const thisLocation = this.props.location;
    const nextLocation = nextProps.location;

    return (
      thisLocation.pathname != nextLocation.pathname ||
      thisLocation.search != nextLocation.search ||
      this.props.match.url != nextProps.match.url
    );
  }

  render() {
    const {
      match,
      location: {pathname},
      push
    } = this.props;
    const {path, params} = match;

    return (
      <ReportListeners location={location} match={match}>
        <ReportUpgradeChecker location={location} match={match}>
          <Switch>
            <Route exact path={`${path}/inspector`} component={InspectorRoute} />
            {/* NOTE legacy route - people may still have links to this. Delete after a while (12-Mar-2019) */}
            <Route exact path={`${path}/inspector2`} component={InspectorRoute} />

            <Route path={`${path}/settings`} component={ReportSettingsRoute} />
            <Route path={`${path}/print-tests`} component={PrintReportPage} />
            <Route path={`${path}/:testId/tabs/:tab?`} component={TestsRoute} />
            <Route path={`${path}/:testId?`} component={TestsRoute} />
            <Route exact path={path} component={ReportIndexPage} />
          </Switch>
        </ReportUpgradeChecker>
      </ReportListeners>
    );
  }
}

export default connect(
  null,
  {
    viewReportPage,
    viewReportIndexPage,
    leaveReportPage,
    requestReportSegments,
    push,
    clearReport,
    fetchAlertSubscriptions
  }
)(ReportsRoute);
