import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

class ShortLanguage extends React.Component {
  render() {
    const {data, options} = this.props;

    if (!data || !data.length) {
      return null;
    }

    const classNames = cx({
      [styles[options.class]]: true
    });

    let text = data;
    switch (data) {
      case 'en':
        text = 'English';
        break;
      case 'fr':
        text = 'French';
        break;
      case 'es':
        text = 'Spanish';
        break;
    }

    return <span className={classNames}>{text}</span>;
  }
}

ShortLanguage.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default ShortLanguage;
