import React from 'react';
import Remarkable from 'react-remarkable';

export default class Markdown extends React.Component {
  render() {
    const {data} = this.props;
    const options = {linkTarget: '_blank'};

    if (!data) return null;

    return (
      <div>
        <Remarkable options={options}>{data}</Remarkable>
      </div>
    );
  }
}
