import React from 'react';
import {connect} from 'react-redux';
import Icon from 'components/font-awesome';
import ScoreDescription from 'components/score/scoreDescription';
import {inspectorCategoryHeadingSelector} from 'modules/inspector/inspectorSelectors';
import styles from './inspectorStyle.scss';
import Link from 'modules/location/link';
import Margin from 'components/margin/margin';

function InspectorCategoryHeading({category, parentCategory}) {
  if (!category) {
    // we are showing the top level
    return null;
  }

  return (
    <React.Fragment>
      <Margin margin="10px">
        {parentCategory && (
          <Link
            replace
            className={styles.backIcon}
            query={{categoryId: parentCategory.get('categoryId')}}
          >
            <Icon name="chevron-left" /> {parentCategory.get('name')}
          </Link>
        )}

        {!parentCategory && (
          <Link replace className={styles.backIcon} query={{categoryId: ''}}>
            <Icon name="chevron-left" /> Whole page
          </Link>
        )}
      </Margin>

      {category.has('score') && (
        <Margin margin="15px 20px">
          <ScoreDescription
            score={category.get('score')}
            name={category.get('name')}
            description={category.get('description')}
          />
        </Margin>
      )}
    </React.Fragment>
  );
}

export default connect(inspectorCategoryHeadingSelector)(InspectorCategoryHeading);
