import PropTypes from 'prop-types';
import React from 'react';
import styles from './inspectorEditableUrl.scss';
import {connect} from 'react-redux';
import {addMessage} from 'modules/alerts';

export class InspectorEditableUrl extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    onEditUrlSubmit: PropTypes.func
  };

  constructor(props) {
    super(props);

    var decodedURL;

    try {
      decodedURL = decodeURIComponent(props.url);
    } catch (err) {
      decodedURL = props.url;
    }

    this.state = {url: decodedURL};
  }

  onEditUrlSubmit = event => {
    event.preventDefault();

    let newUrl = this.state.url;
    // If we don't have at-least 3 chars then it cannot be valid. Same for if we have no . or if we do, but it's the final character.
    if (
      newUrl.length < 3 ||
      newUrl.indexOf('.') === -1 ||
      newUrl.indexOf('.') === newUrl.length - 1
    ) {
      this.props.addMessage('error', 'The URL provided was not valid. Please try again.');
      return;
    }

    // Always ensure we have some form of http or https at the start.
    if (newUrl.indexOf('http') !== 0) {
      newUrl = 'http://' + newUrl;
    }

    const {onEditUrlSubmit} = this.props;
    onEditUrlSubmit(newUrl);
  };

  onChange = ({target}) => {
    this.setState({url: target.value});
  };

  render() {
    const {url} = this.state;

    return (
      <form onSubmit={this.onEditUrlSubmit}>
        <input type="text" className={styles.input} value={url} onChange={this.onChange} />
      </form>
    );
  }
}

export default connect(
  null,
  {addMessage}
)(InspectorEditableUrl);
