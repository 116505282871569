import React from 'react';
import HoverListener from 'components/events/hoverListener';
import {Motion, spring} from 'react-motion';

export default class ScaleOnHover extends React.Component {
  static defaultProps = {
    stiffness: 120,
    damping: 8,
    scale: 1.2,
    initialScale: 0.1
  };

  render() {
    const {children, stiffness, damping, scale, initialScale} = this.props;
    return (
      <HoverListener>
        {hovered => (
          <Motion
            defaultStyle={{s: initialScale}}
            style={{
              s: spring(hovered ? scale : 1, {stiffness, damping})
            }}
          >
            {context => <div style={{transform: `scale(${context.s})`}}>{children}</div>}
          </Motion>
        )}
      </HoverListener>
    );
  }
}
