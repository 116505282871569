import Buttons from 'components/buttons';
import PageHeader from 'components/header/pageHeader';
import Flex from 'components/layout/flex';
import Spinner from 'components/spinner';
import EmailConsentModal from 'modules/app/containers/emailConsentModal';
import {requestCourses} from 'modules/courses/actions';
import CourseProgress from 'modules/courses/components/courseProgress';
import {getFirstStartedCourse} from 'modules/courses/utils';
import EmptyFolderView from 'modules/folders/components/emptyFolderView';
import {leaveHomePage, viewHomePage} from 'modules/homePage';
import {homePageSelector} from 'modules/homePage/homePageSelectors';
import AddReportButton from 'modules/reports/containers/addReportButton';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import HelpCta from '../components/helpCta';
import RecentUpdates from '../components/recentUpdates';
import RecentUsers from '../components/recentUsers';
import SearchReports from '../components/searchReports';
import styles from './homePage.scss';

export class HomePage extends React.Component {
  componentWillMount() {
    this.props.requestCourses();
    this.props.viewHomePage();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.viewHomePage();
    }
  }

  componentWillUnmount() {
    this.props.leaveHomePage();
  }

  renderPageContent = () => {
    const {isLoading, recentReports, recentUsers, updates, courses} = this.props;

    if (isLoading) {
      return <Spinner large className={styles.loading} />;
    }

    const courseInProgress = Object.values(courses).length ? getFirstStartedCourse(courses) : null;

    return (
      // <RecentReports recentReports={recentReports} />
      <Flex id="homePage" container wrap="true" alignTop className={styles.homePage}>
        <div className={styles.lhs}>
          {courseInProgress && <CourseProgress {...courseInProgress} />}

          {recentReports && recentReports.length ? (
            <SearchReports theme="small" />
          ) : (
            <EmptyFolderView homePage />
          )}
        </div>
        <div className={styles.rhs}>
          <RecentUsers recentUsers={recentUsers} />
          <RecentUpdates updates={updates} />
          <HelpCta />
        </div>
      </Flex>
    );
  };

  render() {
    const {
      recentReports,
      user: {name: userName, lastActive}
    } = this.props;

    const now = Math.round(new Date().getTime() / 1000);
    const age = now - lastActive;

    const title = (age > 60 * 60 ? 'Welcome back' : 'Hello') + ' ' + userName;
    const hasReports = recentReports && !!recentReports.length;

    return (
      <Fragment>
        <EmailConsentModal userId={this.props.userId} />

        <div>
          <PageHeader
            title={title}
            inline={[
              <Buttons key="buttons">{hasReports && <AddReportButton key="add_report" />}</Buttons>
            ]}
          />
          {this.renderPageContent()}
        </div>
      </Fragment>
    );
  }
}

export default connect(
  homePageSelector,
  {viewHomePage, leaveHomePage, requestCourses}
)(HomePage);
