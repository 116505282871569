import React from 'react';
import OnboardingWrapper from 'modules/onboarding/components/onboardingWrapper';
import OnboardingFormWrapper from 'modules/onboarding/components/onboardingFormWrapper';
import UserSignupForm from 'modules/accounts/components/userSignupForm';
import styles from './onboardingWrapper.scss';
import image from './user-signup.png';

export default class UserPage extends React.Component {
  renderRhs() {
    return (
      <div>
        <img className={styles.image} src={image} />
        <p className={styles.imageCaption}>
          Identify spelling errors, broken links, missing files and more
        </p>
      </div>
    );
  }

  render() {
    const {formData, nextStep, errorMessage, loading} = this.props;

    return (
      <OnboardingWrapper rhs={this.renderRhs()} className={styles.user}>
        <OnboardingFormWrapper
          title="Create your account"
          intro="We just need a few details to get started"
        >
          <UserSignupForm
            defaultValue={formData}
            onValidSubmit={nextStep}
            errorMessage={errorMessage}
            isLoading={loading}
          />
        </OnboardingFormWrapper>
      </OnboardingWrapper>
    );
  }
}
