import {take, select, put, takeLatest} from 'redux-saga/effects';
import {RECEIVE_REPORT} from 'modules/reports/reportConstants';
import {retestBegan} from 'modules/inspector/inspectorActions';
import {FETCH_INSPECTOR_PAGE_COMPLETE} from 'modules/inspector/inspectorConstants';
import {retestMissionIdSelector} from '../inspectorSelectors';

export default function* activeInspectorMissionsSaga() {
  yield takeLatest(RECEIVE_REPORT, activeInspectorMissions);
}

// When we request a report, we get all the current active missions for that report. We
// need to intercept these missions and see if any of them are Page missions for the current
// inspector page. If they are, we need to store them as the 'retestMissionId' so that we poll for them
function* activeInspectorMissions({report: {missions = []}}) {
  if (!missions || !missions.length) return;

  const inspectorData = yield take(FETCH_INSPECTOR_PAGE_COMPLETE);

  const pageMissions = missions.filter(mission => {
    // The page mission doesn't tell us what page it is for.
    // Until it does, we can ensure that the current pageUrl matches the url in the missions name.
    return mission.type === 'Pages' && mission.name === 'Testing ' + inspectorData.data.url;
  });

  const currentRetestId = yield select(retestMissionIdSelector);

  if (pageMissions.length > 0 && pageMissions[0].missionId !== currentRetestId) {
    yield put(retestBegan(pageMissions[0].missionId));
  }
}
