import _ from 'lodash';
import styles from './error.scss';
import React from 'react';
import EditTestButton from 'components/test/editTestButton';

export default class Error extends React.Component {
  renderSettingsButton() {
    const {testId} = this.props.params;

    return (
      <div className={styles.actions}>
        <EditTestButton testId={testId}>Change settings</EditTestButton>
      </div>
    );
  }

  renderDescription(description) {
    return <p className={styles.description}>{description}</p>;
  }

  render() {
    const {label, description, action} = this.props.config;

    return (
      <div className={styles.error}>
        <h3>{label}</h3>

        {!!description && this.renderDescription(description)}
        {action === 'settings' && this.renderSettingsButton()}
      </div>
    );
  }
}
