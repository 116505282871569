import {fromJS, Map} from 'immutable';
import {
  FETCH_UPTIME_MONITORS,
  CLEAR_UPTIME_MONITORS,
  RECEIVE_UPTIME_MONITORS,
  RECEIVE_UPTIME_MONITOR,
  RECEIVE_UPTIME_MONITOR_CONTACT,
  RECEIVE_UPTIME_MONITOR_CONTACTS,
  DELETE_UPTIME_MONITOR,
  DELETE_UPTIME_CONTACT,
  REQUEST_CAN_ADD_UPTIME_MONITOR,
  RECEIVE_CAN_ADD_UPTIME_MONITOR
} from './constants';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  uptimeMonitors: null,
  uptimeContacts: Map(), // Map keyed by userId for the current monitorId represented by `contactsMonitorId`
  contactsMonitorId: null, // tells us which monitor ID the data from `uptimeContacts` belongs to
  isLoading: false
});

export default function uptimeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPTIME_MONITORS:
      return state.set('isLoading', true);

    case CLEAR_UPTIME_MONITORS:
      return state.set('uptimeMonitors', null);

    case RECEIVE_UPTIME_MONITOR:
      return state.setIn(
        ['uptimeMonitors', action.uptimeMonitor.monitorId.toString()],
        fromJS(action.uptimeMonitor)
      );

    case RECEIVE_UPTIME_MONITORS:
      const monitors = action.uptimeMonitors;
      if (!monitors || (Array.isArray(monitors) && !monitors.length)) {
        return state.set('isLoading', false).set('uptimeMonitors', Map());
      }
      return state.set('isLoading', false).set('uptimeMonitors', fromJS(action.uptimeMonitors));

    case RECEIVE_UPTIME_MONITOR_CONTACTS:
      return state
        .set('contactsMonitorId', action.monitorId.toString())
        .set(
          'uptimeContacts',
          Array.isArray(action.contacts)
            ? keyBy(action.contacts, 'userId')
            : fromJS(action.contacts)
        );

    case RECEIVE_UPTIME_MONITOR_CONTACT:
      return state.setIn(
        ['uptimeContacts', action.uptimeContacts.userId.toString()],
        fromJS(action.uptimeContacts)
      );

    case DELETE_UPTIME_CONTACT:
      return state.deleteIn(['uptimeContacts', action.userId.toString()]);

    case DELETE_UPTIME_MONITOR:
      return state.deleteIn(['uptimeMonitors', action.monitorId.toString()]);

    case REQUEST_CAN_ADD_UPTIME_MONITOR:
      return state.set('canAdd', false).set('isCheckingCanAdd', true);

    case RECEIVE_CAN_ADD_UPTIME_MONITOR:
      return state.set('canAdd', action.canAdd).set('isCheckingCanAdd', false);
  }
  return state;
}
