import {takeLatest, put} from 'redux-saga/effects';
import {VIEW_REPORT_SETTINGS_PAGE} from 'modules/reports/reportConstants';
import {receiveProxyList} from 'modules/proxies';
import {processRequest} from 'utils/saga/fetchUtils';
import {objectToArray} from 'utils/arrayUtils';

export default function* fetchProxiesSaga() {
  yield takeLatest(VIEW_REPORT_SETTINGS_PAGE, fetchProxies);
}

function* fetchProxies() {
  yield processRequest('GET', 'proxies', {
    success: function*(proxyList) {
      yield put(receiveProxyList(objectToArray(proxyList)));
    }
  });
}
