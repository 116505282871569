import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {createTagComplete} from 'modules/tags/actions';
import {CREATE_TAG} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* requestTagsListener() {
  yield takeLatest(CREATE_TAG, requestTags);
}

function* requestTags({label, onSuccess}) {
  try {
    const tag = {label};
    const newTag = yield processRequest('POST', 'tags', {}, tag);
    yield put(createTagComplete(null, newTag));
    yield call(onSuccess, newTag);
  } catch (err) {
    yield put(createTagComplete(err));
  }
}
