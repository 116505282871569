import _ from 'lodash';
import {Map, fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {newContactPath, PAYMENT_INVOICE} from 'modules/accounts/accountUtils';
import {push} from 'modules/location';
import {accountSelector as authAccountSelector} from 'modules/auth/authSelectors';
import {LOGOUT, SWITCH_ACCOUNT} from 'modules/auth';
import {analytics, events} from 'modules/analytics';

const initialState = Map({
  isFetching: false,
  isUpdating: false,
  updateCompleteMessage: '',
  data: Map()
});

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    // gets called on update/add complete.
    case RECEIVE_ACCOUNT:
      const {account} = action;

      const loadingState = state.set('isUpdating', false);
      const accountKey = ['data', account.accountId.toString()];

      return loadingState.hasIn(accountKey)
        ? loadingState.mergeIn(accountKey, fromJS(account))
        : loadingState.setIn(accountKey, fromJS(account));

    case TRIGGER_UPDATE_ACCOUNT:
      return state.set('updateCompleteMessage', '');

    case ADD_ACCOUNT:
    case UPDATE_ACCOUNT:
      return state.set('isUpdating', true);

    case DELETE_ACCOUNT:
      return state.deleteIn(['data', action.accountId]);

    case LOGOUT:
      return initialState;
  }

  return state;
}

export const RECEIVE_ACCOUNT = 'accounts:RECEIVE_ACCOUNT';
export function receiveAccount(account) {
  return {type: RECEIVE_ACCOUNT, account};
}

export const ADD_ACCOUNT = 'accounts:ADD_ACCOUNT';
export function addAccount(account) {
  return {
    type: ADD_ACCOUNT,
    account
  };
}

export const UPDATE_ACCOUNT = 'accounts:UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'accounts:UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'accounts:UPDATE_ACCOUNT_FAILURE';
export function updateAccount(account) {
  const {accountId} = account;
  if (!accountId) throw 'cannot update account without accountId';

  return {type: UPDATE_ACCOUNT, account};
}

// Ask the API to delete an account.
export const REQUEST_DELETE_ACCOUNT = 'accounts:REQUEST_DELETE_ACCOUNT';
export function requestDeleteAccount(accountId) {
  return {
    type: REQUEST_DELETE_ACCOUNT,
    accountId
  };
}

// API has returned success on deleting an account.
export const DELETE_ACCOUNT = 'accounts:DELETE_ACCOUNT';
export function deleteAccount(accountId) {
  return {
    type: DELETE_ACCOUNT,
    accountId
  };
}

export const VAT_COUNTRY_CHANGED = 'accounts:VAT_COUNTRY_CHANGED';
export function vatCountryChanged() {
  return {
    type: VAT_COUNTRY_CHANGED
  };
}

// Triggers update account modal
export const TRIGGER_UPDATE_ACCOUNT = 'accounts:TRIGGER_UPDATE_ACCOUNT';
export function triggerUpdateAccount() {
  return {type: TRIGGER_UPDATE_ACCOUNT};
}

export const DOWNLOAD_INVOICE = 'accounts:DOWNLOAD_INVOICE';
export function downloadInvoice(invoiceId, cb) {
  return {type: DOWNLOAD_INVOICE, invoiceId, cb};
}
