import React from 'react';
import Button from 'components/button';
import styles from './copyToClipboard.scss';
import cx from 'classnames';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class CopyToClipboard extends React.Component {
  state = {
    copied: false
  };

  handleCopy = () => {
    const {onCopy} = this.props;

    this.select();
    try {
      const success = document.execCommand('copy');
      if (!success) throw new Error('could not copy');
      this.markCopied();
    } catch (e) {
      if (__DEV__) {
        console.error('could not copy text', e);
      }
      // TODO: how should we alert the user that we could not copy?
    }

    onCopy && onCopy();
  };

  // When we mark it as copied, we want to revert the button
  // after a timeout
  markCopied() {
    this.setState({copied: true});
    this.timeout = setTimeout(() => {
      this.setState({copied: false});
    }, 4000);
  }

  select = () => {
    this.input.select();
  };

  componentDidMount() {
    this.select();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  setInput = ref => {
    this.input = ref;
  };

  render() {
    const {value, className} = this.props;
    const {copied} = this.state;

    return (
      <div className={styles.base}>
        <input
          onClick={this.select}
          className={cx(styles.input, className)}
          readOnly
          value={value}
          ref={this.setInput}
        />
        {!this.props.screenSizes.tablet && (
          <Button onClick={this.handleCopy} icon={copied ? 'check' : 'copy'}>
            {copied ? 'Copied' : 'Copy'}
          </Button>
        )}
      </div>
    );
  }
}

export default connectContext(ScreenSizeContext, 'screenSizes')(CopyToClipboard);
