import React from 'react';

export default function ErrorMessage({children, message, style}) {
  if (typeof message !== 'string' || !message.length) {
    if (!children) {
      return null;
    }
    message = children;
  }
  return message && <p style={{...style, color: '#e2605e'}}>{message}</p>;
}
