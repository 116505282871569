import {compose} from 'redux';
import {connect} from 'react-redux';
import {setAlertSubscription} from 'modules/alertSubscriptions/actions';
import {alertSubscriptionButtonSelector} from 'modules/alertSubscriptions/selectors';
import AlertButton from 'modules/alertSubscriptions/components/alertButton';

/**
 * A single AlertButton can subscribe to many story types on the backend. That means that an AlertButton can accept an array of `storyTypeId`.
 * This means that the button will subscribe to both of those story types when clicked. This means that a single button can be associated to
 * one or more alert subscription rows.
 *
 * TL;DR as well as an array of storyTypeId, you also need to pass the subscriptionIds associated with each story type (if they exist).
 *
 * So the first time when the alert button is not associated with any alert subscription rows:
 *   <AlertButton storyTypes={[{storyTypeId: 'Blah'}]} />
 *
 * When this is clicked, a new alert subscription row will be created and the second time it is rendered as
 *   <AlertButton storyTypes={[{storyTypeId: 'Blah', subscriptionId: 4}]} />
 *
 */
export default compose(
  // we must connect the data separately so the the onClick handler has access to any connected props
  connect(alertSubscriptionButtonSelector),
  connect(
    null,
    (dispatch, {params, storyTypes, isSubscribed, isLoading}) => {
      return {
        onClick: event => {
          // disallow click if the element is loading
          if (isLoading) return;

          dispatch(setAlertSubscription({...params}, storyTypes, !isSubscribed));
        }
      };
    }
  )
)(AlertButton);
