exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QCaUBH06dcwCXe5SAB3Xu{margin:0 auto;margin-bottom:40px;box-shadow:0 0 36px #d8dde5;background-color:#fff;border-radius:4px}._1BE0gP4BGf28trVGmBJ1Lz{background-color:#f6f7f9;padding:10px 20px;width:100%;display:flex}._1BW17bxcX9khqLnxOJrbFJ{flex:10}._3Ayyd4lP2WY3DOM2dAGiQ_{flex:2;text-align:right}._2O7BxBY1lnPf1o8x_Ecm7n{color:#030303;margin-top:5px;font-size:1.5em}._3Pneq6DvP3KdUAeV3cI3lY{margin-top:0 !important}.qo-2-rUXbLgm67GAzahXK{text-transform:uppercase;color:#707070;font-size:.8em;display:block}._2cyhY7zc3gMt376aPHYHtm{padding:20px}", ""]);

// exports
exports.locals = {
	"wrapper": "_1QCaUBH06dcwCXe5SAB3Xu",
	"headingBar": "_1BE0gP4BGf28trVGmBJ1Lz",
	"headingLeft": "_1BW17bxcX9khqLnxOJrbFJ",
	"headingRight": "_3Ayyd4lP2WY3DOM2dAGiQ_",
	"title": "_2O7BxBY1lnPf1o8x_Ecm7n",
	"titleNoTopMargin": "_3Pneq6DvP3KdUAeV3cI3lY",
	"subtitle": "qo-2-rUXbLgm67GAzahXK",
	"body": "_2cyhY7zc3gMt376aPHYHtm"
};