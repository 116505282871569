exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1zPqCVSvT8IrADN-Ni-fNV{margin:0 0 30px 0}._1zPqCVSvT8IrADN-Ni-fNV:last-of-type{margin-bottom:0}._1zPqCVSvT8IrADN-Ni-fNV h3{font-size:20px;margin-bottom:20px}._13Cq3sD64HvKOCp-txGAwb ._3G3mEa7tCQd2YeCUx8t5lw{cursor:default}", ""]);

// exports
exports.locals = {
	"stage": "_1zPqCVSvT8IrADN-Ni-fNV",
	"disabled": "_13Cq3sD64HvKOCp-txGAwb",
	"lessons": "_3G3mEa7tCQd2YeCUx8t5lw"
};