import React from 'react';
import {Form, Field} from 'modules/form/components';
import Button from 'components/button';
import {Link} from 'react-router-dom';
import {forgottenPasswordPath} from 'modules/auth/authUtils';
import styles from 'modules/auth/components/authWrapperShared.scss';

export default class LoginForm extends React.Component {
  render() {
    const {
      defaultValue: {email},
      errorMessage
    } = this.props;

    const shouldFocusPassword = typeof email == 'string' && email.length;

    return (
      <Form {...this.props}>
        <Field
          name="email"
          type="email"
          label="Email"
          placeholder="davidsmith@yourcompany.com"
          className={styles.textInput}
          required
          autoFocus={!shouldFocusPassword}
        />
        <Field
          name="password"
          type="password"
          label="Password"
          placeholder="Enter your password"
          className={styles.textInput}
          required
          autoFocus={shouldFocusPassword}
        />

        {errorMessage && (
          <p id="loginErrorMessage" className={styles.errorText}>
            {errorMessage}
          </p>
        )}

        <div className={styles.buttonContainer}>
          <Button type="submit" className={styles.submit}>
            Log in
          </Button>

          <Link className={styles.inlineLink} to={forgottenPasswordPath()}>
            Forgotten your password?
          </Link>
        </div>
      </Form>
    );
  }
}
