import React from 'react';
import {connect} from 'react-redux';
import Panel from 'components/panel';
import Table from 'components/table';
import Select from 'components/select';
import Status from 'components/status';
import styles from './alertsConfig.scss';
import Flex from 'components/layout/flex';
import UserImage from 'components/user/userImage';
import SettingsPage from 'components/settings/settingsPage';
import {alertsConfigSelector} from 'modules/settings/settingsSelectors';
import AlertSubscriptions from 'modules/alertSubscriptions/containers/alertSubscriptions';
import EditUserAlertsButton from 'modules/alertSubscriptions/containers/editUserAlertsButton';
import Help from 'components/help';
import SearchBar from 'components/searchBar/searchBar';
import Pagination from 'components/pagination';

const ROWS_PER_PAGE = 10;
const USER_COLUMNS = [
  {
    label: '',
    field: 'user',
    type: 'jsx',
    jsx: renderUserImage,
    width: '60px',
    collapse: '400'
  },
  {label: 'Name', field: 'user', type: 'jsx', jsx: renderUserName, sortable: 'name'},
  {
    label: 'Active alerts',
    field: 'activeSubs',
    showZero: true,
    type: 'count',
    appearance: 'major'
  },
  {
    label: '',
    type: 'jsx',
    width: '150px',
    jsx: renderEditButton
  }
];

function AlertsConfig(props) {
  const {
    match: {params},
    segments,
    storyTypes,
    usersWithAlerts,
    usersWithoutAlerts,
    isRegularUser
  } = props;

  return (
    <React.Fragment>
      {isRegularUser ? (
        <UserAlerts params={params} segments={segments} storyTypes={storyTypes} />
      ) : (
        <AdminAlerts
          params={params}
          usersWithAlerts={usersWithAlerts}
          usersWithoutAlerts={usersWithoutAlerts}
        />
      )}
    </React.Fragment>
  );
}

function AdminAlerts({params, usersWithAlerts, usersWithoutAlerts}) {
  const firstUser = usersWithoutAlerts[0];
  const [searchValue, setSearch] = React.useState('');
  const [pageNo, setPageNo] = React.useState(1);
  const [selectboxUser, setSelectboxUser] = React.useState(firstUser ? firstUser.userId : null);

  React.useEffect(() => {
    const firstUser = usersWithoutAlerts[0];
    setSelectboxUser(firstUser ? firstUser.userId : null);
  }, [usersWithoutAlerts]);

  const userRows = searchValue
    ? usersWithAlerts.filter(user => {
        return user.name.includes(searchValue) || user.email.includes(searchValue);
      })
    : usersWithAlerts;

  const idx = (pageNo - 1) * ROWS_PER_PAGE;
  const pageRows = userRows.slice(idx, idx + ROWS_PER_PAGE);
  const maxPage = Math.ceil(userRows.length / ROWS_PER_PAGE);

  return (
    <SettingsPage name="Manage user alerts" params={params} className={styles.page}>
      {typeof selectboxUser === 'number' && (
        <React.Fragment>
          <Flex container bottom={15}>
            <Flex basis="300px" right={15}>
              <Select
                clearable={false}
                disableFiltering
                value={selectboxUser}
                onChange={option => setSelectboxUser(option ? option.value : null)}
                options={usersWithoutAlerts.map(user => ({
                  value: user.userId,
                  label: user.name
                }))}
              />
            </Flex>
            <Flex margin="0 0 10px 20px">
              <EditUserAlertsButton userId={selectboxUser}>Add alerts</EditUserAlertsButton>
            </Flex>
            <Flex margin="0 0 10px 10px">
              <Help message="This is a list of users that have no alerts" />
            </Flex>
          </Flex>
        </React.Fragment>
      )}
      <Panel toolbar>
        <SearchBar value={searchValue} onChange={event => setSearch(event.target.value)} />
      </Panel>
      <Panel padding={false} hideBorderTop marginBottom>
        {userRows.length ? (
          <Table rowKey="userId" rows={pageRows} columns={USER_COLUMNS} />
        ) : (
          <Status appearance="info">
            {!searchValue ? 'No users' : 'No users matching "' + searchValue + '"'}
          </Status>
        )}
      </Panel>
      {maxPage > 1 && (
        <Pagination
          page={pageNo}
          numRows={userRows.length}
          rowsPerPage={ROWS_PER_PAGE}
          maxPage={maxPage}
          onPageSelect={pageNo => setPageNo(pageNo)}
        />
      )}
    </SettingsPage>
  );
}

function UserAlerts({params, segments, storyTypes}) {
  return (
    <SettingsPage name="Manage your alerts" params={params} className={styles.page}>
      {segments.map(segment => (
        <AlertSubscriptions key={segment.value} segment={segment} storyTypes={storyTypes} />
      ))}
    </SettingsPage>
  );
}

export default connect(alertsConfigSelector)(AlertsConfig);

function renderUserImage({record}) {
  if (!record) return null;
  return <UserImage user={record} size={40} />;
}

function renderUserName({record}) {
  return (
    <div>
      <div>{record.name}</div>
      {record.email && (
        <a style={{wordBreak: 'break-word'}} href={'mailto:' + record.email}>
          {record.email}
        </a>
      )}
    </div>
  );
}

function renderEditButton({record}) {
  return <EditUserAlertsButton userId={record.userId}>Manage alerts</EditUserAlertsButton>;
}
