import {createSelector} from 'reselect';
import {createPropOrParamSelector} from 'modules/location/locationSelectors';
import {
  entitlementsErrorSelector,
  entitlementsLoadingSelector
} from 'modules/entitlements/entitlementSelectors';
import {Map} from 'immutable';
import {toJS} from 'utils/immutableUtils';
import {monthlyPriceDivisor} from './productUtils';
import {STRIPE} from 'config';
import _ from 'lodash';
import euCountryCodes from 'constants/euCountryCodes.json';
import {accountSelector, accountTypeIdSelector} from 'modules/auth/authSelectors';
import countryCurrencies from 'constants/countryCurrencies.json';
import currencies from 'constants/currencies.json';
import {geolocationSelector} from 'modules/geolocation/geolocationSelectors';

export const planIdSelector = createPropOrParamSelector('planId');

const moduleSelector = state => state.products;
export const productsSelector = state => state.products.get('data');
export const basketSelector = state => state.products.get('basket');
export const productChoicesSelector = state => state.products.get('productChoices');
export const taxSelector = state => state.products.get('tax');
export const pricesSelector = state => state.products.get('prices');
export const stripeTokenSelector = state => state.products.get('stripeToken');
export const stripeErrorSelector = state => state.products.get('stripeError');
export const hasSubscriptionSelector = state => state.products.get('hasSubscription');
export const selectedCurrencySelector = state => state.products.get('selectedCurrency');

export const checkoutError = createSelector(
  moduleSelector,
  entitlementsErrorSelector,
  (products, entitlementError) => {
    const dataError = products.get('dataError');
    return entitlementError || dataError;
  }
);

export const checkoutDataIsLoaded = createSelector(
  moduleSelector,
  entitlementsLoadingSelector,
  (products, entitlementsLoading) => {
    return !entitlementsLoading && !products.get('dataIsLoading');
  }
);

export const plansHaveLoaded = createSelector(
  moduleSelector,
  productsSelector,
  (products, plans) => {
    return !plans.isEmpty() || products.get('dataIsLoading') === false;
  }
);

export const hasProducts = createSelector(
  moduleSelector,
  products => {
    return !products.get('noProducts', false);
  }
);

export const planIdsSelector = createSelector(
  productsSelector,
  products => products.keySeq()
);

export const planIdsInBasketSelector = createSelector(
  basketSelector,
  basket => basket.filter(quantity => quantity > 0).keySeq()
);

export const hasProductsInBasketSelector = createSelector(
  basketSelector,
  basket => basket.some(quantity => quantity > 0)
);

// quantity of the product specified by props.planId
export const quantitySelector = createSelector(
  basketSelector,
  planIdSelector,
  (basket, planId) => basket.get(planId) || 0
);

export const incVatPriceSelector = createSelector(
  productsSelector,
  planIdSelector,
  (products, planId) => products.getIn([planId, 'price'])
);

export const exVatPriceSelector = createSelector(
  productsSelector,
  planIdSelector,
  (products, planId) => products.getIn([planId, 'priceWithTax'])
);

// Returns a user friendly basket summary string
// i.e. Website optimizer, Another product x 2
export const basketSummarySelector = createSelector(
  productsSelector,
  planIdsInBasketSelector,
  basketSelector,
  (products, planIds, basket) => {
    const productsString = planIds
      .map(planId => {
        const quantity = basket.get(planId);
        const name = products.get(planId).get('name');
        if (quantity == 1) return name;
        return `${name} x ${quantity}`;
      })
      .join(', ');

    return `Purchase: ${productsString}`;
  }
);

const currencyPropSelector = (state, props) => props && props.currency;

export const currencySelector = createSelector(
  selectedCurrencySelector,
  accountSelector,
  geolocationSelector,
  currencyPropSelector,
  (selectedCurrency, account, geolocation, currencyProp) => {
    const accountCurrency = account && account.get('currency');
    const currency = selectedCurrency || accountCurrency || countryCurrencies[geolocation] || 'USD';

    // currencyProp allows the rendering component to hardcode a currency
    const hardcodedCurrency = !currencyProp
      ? currencies.find(c => c.currency === currency.toUpperCase())
      : currencies.find(c => c.currency === currencyProp.toUpperCase());

    return {
      // can only change currency if they haven't
      // previously picked one
      canChangeCurrency: !accountCurrency,
      ...(hardcodedCurrency || selectedCurrency)
    };
  }
);

// THe user is only allowed to switch currency between the ones available in products
export const availableCurrenciesSelector = createSelector(
  productsSelector,
  products => {
    const firstProduct = products && products.first();
    if (!firstProduct) return [];

    const prices = firstProduct.get('prices');
    if (!prices) return [];

    const currenciesInPrices = prices.keySeq().toArray();
    return currenciesInPrices.map(key => currencies.find(c => c.currency === key.toUpperCase()));
  }
);

export const currencySwitcherSelector = createSelector(
  currencySelector,
  availableCurrenciesSelector,
  (currencyData, availableCurrencies) => ({
    ...currencyData,
    currencies: availableCurrencies
  })
);

export const productChooserSelector = createSelector(
  productsSelector,
  productChoicesSelector,
  hasProducts,
  (plans, productChoices, hasProducts) => ({
    hasProducts,
    plans: plans && plans.toJS(),
    productChoices: productChoices && productChoices.toJS()
  })
);

// every price will have the same vatRate, so we just grab the first
export const vatRateSelector = createSelector(
  taxSelector,
  tax => {
    return tax.get('percentage');
  }
);

export const vatAppliesSelector = createSelector(
  taxSelector,
  tax => {
    return tax && tax.get('percentage') > 0;
  }
);

const testCardSelector = () => ({
  number: '4242424242424242',
  exp_month: '09',
  exp_year: '20',
  cvc: '123',
  name: 'Test name',
  address_line1: 'Test address',
  address_zip: '123',
  address_country: 'US',
  contactName: 'Test name [Test]',
  contactEmail: `test${Math.random()}@test.com`,
  organizationName: 'Test Organization'
});

export const paymentDetailsFormDataSelector = createSelector(
  testCardSelector,
  accountSelector,
  geolocationSelector,
  state => state.products.get('serverError'),
  (testCard, account, geolocation, serverError) => {
    return {
      account,
      geolocation,
      serverError,
      hasAccount: !!account
    };
  }
);

export const paymentDetailsModalSelector = createSelector(
  stripeErrorSelector,
  stripeError => ({
    stripeError
  })
);

export const vatCountrySelector = createSelector(
  stripeTokenSelector,
  stripeToken => {
    return stripeToken ? stripeToken.card.country : STRIPE.DEFAULT_VAT_COUNTRY;
  }
);

export const paymentDetailsSelector = createSelector(
  stripeTokenSelector,
  token => ({token})
);

export const currentProductSelector = createSelector(
  productsSelector,
  accountTypeIdSelector,
  (products, accountTypeId) => {
    return products.find(product => product.get('accountTypeId') == accountTypeId);
  }
);
