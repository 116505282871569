import React from 'react';
import Form from './Form';

// Curried version of the hook so callers don't need to constantly provide onValidate
export const makeUseFormHook = onValidate => (initialValue, options = {}) =>
  useFormHook(initialValue, onValidate, options);

// Returns a new Form instance on first render and keeps returning this instance
// The Form instance has the power to rerender this hook.
// `onValidate` will not be re-initialised when key changes. Only initialValue
export function useFormHook(initialValue, onValidate, {formName, key}) {
  const form = useUpdateRef(
    forceUpdate => {
      // this is called on mount
      const form = new Form(formName, forceUpdate, onValidate);
      form.initialise(initialValue);
      return form;
    },
    form => {
      // this is called when `key` changes
      form.initialise(initialValue);
    },
    key
  );
  return [form, form.getFields()];
}

export default makeUseFormHook;

// Allows us to use reference that we can render without recreating
function useUpdateRef(createInstance, updateInstance, key) {
  const ref = React.useRef(null);
  const isInitial = React.useRef(true);
  const [_, setTick] = React.useState(0);
  const forceUpdate = React.useCallback(() => setTick(t => t + 1), [setTick]);

  function getRef() {
    if (ref.current === null) {
      ref.current = createInstance(forceUpdate);
    }
    return ref.current;
  }

  React.useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
    } else {
      updateInstance(getRef());
      forceUpdate();
    }
  }, [key]);

  return getRef();
}
