import React from 'react';
import styles from './tag.scss';

export default function Tag({config}) {
  return (
    <span className={styles.tag} style={{borderColor: config.color, color: config.color}}>
      {config.label}
    </span>
  );
}
