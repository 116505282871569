exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".bNI1UmERoQT4rsI2751Dm{height:auto}._3aeDbmMf48Zh_sg3BK3cQJ{border-color:#df1616}", ""]);

// exports
exports.locals = {
	"textareaInput": "bNI1UmERoQT4rsI2751Dm",
	"invalid": "_3aeDbmMf48Zh_sg3BK3cQJ"
};