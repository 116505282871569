import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {useSelector} from 'hooks/redux';
import Action from '../action';
import Icon from 'components/font-awesome';
import Buttons from 'components/buttons';
import UndoDecision from 'modules/decisions/components/undoDecision';
import styles from 'modules/inspector/components/inspectorSidebar.scss';
import {inspectorActionHeadingSelector} from 'modules/inspector/inspectorSelectors';
import Link from 'modules/location/link';
import Controls from 'components/control/controls';
import {locationSelector} from 'modules/location/locationSelectors';
import {controlStateSelector} from 'modules/instructions/instructionSelectors';

function InspectorActionHeading({action, backCategory}) {
  const {query} = useSelector(locationSelector);
  const scopeControls = useSelector(controlStateSelector, {
    context: action,
    filters: query,
    controls: action.get('scopeControls')
  });

  if (!action) return null;

  return (
    <div className={styles.sidebarTitle}>
      <Link
        replace
        query={{
          categoryId: backCategory ? backCategory.get('categoryId') : '',
          actionId: undefined
        }}
      >
        <Icon name="chevron-left" /> {backCategory ? backCategory.get('name') : 'Whole page'}
      </Link>
      <Action
        {...action.toJS()}
        hideTags
        numOpenIssues={undefined} // need to overwrite `numOpenIssues` from the spreaded `action`
      />

      {!!scopeControls.size && (
        <Fragment>
          <br />
          <Controls controls={scopeControls.toJS()} context={action.toJS()} small />
        </Fragment>
      )}

      {!!action.get('decisionId') && action.get('state') !== 'open' && (
        <Buttons centered>
          <UndoDecision decisionId={action.decisionId} />
        </Buttons>
      )}
    </div>
  );
}

export default connect(inspectorActionHeadingSelector)(InspectorActionHeading);
