import React from 'react';
import cx from 'classnames';
import styles from './mediaIssue.scss';
import AnimateOnChange from 'components/animation/animateOnChange';
import MediaComponent from 'components/media';
import Button from 'components/button';
import {toFixed} from 'utils/numberUtils.js';
import ExternalLink from 'modules/location/externalLink';

class ImageNotOptimalIssue extends React.Component {
  shouldAnimate = (nextProps, currentProps) => {
    return nextProps.isOpen && !currentProps.isOpen;
  };

  render() {
    const {issueData, isOpen} = this.props;

    const currentSize = issueData.get('size');
    const src = issueData.get('src');
    const srcType = 'image';
    const newSize = issueData.get('newSize');
    const cachedOptimized = issueData.get('cachedOptimized');

    const classes = cx(styles.wrapper, {
      [styles.open]: isOpen
    });

    let sizeText = '';
    if (currentSize > 1024 * 1024) {
      sizeText = toFixed(currentSize / (1024 * 1024), 1) + 'MB';
    } else if (currentSize > 1024) {
      sizeText = toFixed(currentSize / 1024, 1) + 'KB';
    } else if (currentSize !== 'undefined') {
      sizeText = currentSize + ' bytes';
    }

    let newSizeText = '';
    if (newSize > 1024 * 1024) {
      newSizeText = toFixed(newSize / (1024 * 1024), 1) + 'MB';
    } else if (newSize > 1024) {
      newSizeText = toFixed(newSize / 1024, 1) + 'KB';
    } else if (newSize !== 'undefined' && newSize) {
      newSizeText = newSize + ' bytes';
    }

    return (
      <div className={classes}>
        <div>
          <div>
            <p>
              <strong>Image URL</strong>
            </p>
            <p>
              <ExternalLink href={src} onClick={e => e.stopPropagation()}>
                {src}
              </ExternalLink>
            </p>
          </div>

          <div>
            <p>
              <strong>Original image</strong>
            </p>
            <AnimateOnChange
              isOpen={isOpen}
              comparator={this.shouldAnimate}
              animationClassName="animated pulse"
            >
              <ExternalLink href={src}>
                <MediaComponent src={src} srcType={srcType} />
              </ExternalLink>
            </AnimateOnChange>
          </div>

          {sizeText && (
            <div>
              <p>
                <strong>Original size</strong>
              </p>
              <p>{sizeText}</p>
            </div>
          )}
          {newSizeText && (
            <div>
              <p>
                <strong>Optimized size</strong>
              </p>
              <p>{newSizeText}</p>
            </div>
          )}
          {cachedOptimized && (
            <div>
              <p>
                <strong>Optimized image</strong>
              </p>

              <AnimateOnChange
                isOpen={isOpen}
                comparator={this.shouldAnimate}
                animationClassName="animated pulse"
              >
                <ExternalLink href={cachedOptimized}>
                  <MediaComponent src={cachedOptimized} srcType={srcType} />
                </ExternalLink>
              </AnimateOnChange>

              <p>
                <ExternalLink href={cachedOptimized}>
                  <Button target="_blank" onClick={e => e.stopPropagation()}>
                    Download optimized image
                  </Button>
                </ExternalLink>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ImageNotOptimalIssue;
