export default {
  SIGNUP_START: 'sign-up.start',
  SIGNUP_FAILED: 'sign-up.failed',
  SIGNUP_SUCCESS: 'sign-up.success',
  CONVERSION_START: 'conversion.start',
  CONVERSION_START_PAY: 'conversion.start-pay',
  CONVERSION_ADD_PAYMENT_DETAILS: 'conversion.add-payment-details',
  CONVERSION_CONFIRM: 'conversion.confirm',
  CONVERSION_RESTARTING_PAY: 'conversion.restarting-pay',
  VIEW_MY_SETTINGS_PAGE: 'my-settings.view',
  VIEW_ORGANIZATION_SETTINGS_PAGE: 'organization-settings.view',
  VIEW_GLOBAL_TEST_SETTINGS_PAGE: 'global-test-settings.view',
  VIEW_PAYMENTS_PAGE: 'payments.view',
  VIEW_REFER_FRIENDS: 'refer.view',
  REFERRAL_LINK_COPIED: 'refer.copy',
  VIEW_INSPECTOR: 'inspector.view',
  REPORT_ADDED: 'reports.add',
  REPORT_ADD_FAILURE: 'reports.add-failure',
  REPORT_SHARE: 'reports.share',
  EXPORT_REPORT: 'export.report',
  EXPORT_EXPLORER_CSV: 'export.explorer-csv',
  OPENED_GETTING_STARTED: 'home.opened-getting-started',

  ONBOARDING_VIEW_PLANS: 'onboarding.view-plans',
  ONBOARDING_VIEW_CREATE_USER: 'onboarding.view-create-user',
  ONBOARDING_VIEW_CREATE_ACCOUNT: 'onboarding.view-create-account',
  ONBOARDING_VIEW_CREATE_PAYMENT: 'onboarding.view-create-payment',
  ONBOARDING_COMPLETE: 'onboarding.complete',
  ONBOARDING_FAILED: 'onboarding.failed'
};
