export const REQUEST_SCOPES = 'policies:REQUEST_SCOPES';
export const REQUEST_SCOPES_COMPLETE = 'policies:REQUEST_SCOPES_COMPLETE';
export const SET_SCOPES = 'policies:SET_SCOPES';

export const REQUEST_CHECKS = 'policies:REQUEST_CHECKS';
export const REQUEST_CHECKS_COMPLETE = 'policies:REQUEST_CHECKS_COMPLETE';
export const SET_CHECKS = 'policies:SET_CHECKS';

export const REQUEST_POLICY = 'policies:REQUEST_POLICY';
export const REQUEST_POLICY_COMPLETE = 'policies:REQUEST_POLICY_COMPLETE';
export const SET_POLICY = 'policies:SET_POLICY';
export const SAVE_POLICY = 'policies:SAVE_POLICY';
export const SAVE_POLICY_COMPLETE = 'policies:SAVE_POLICY_COMPLETE';

export const REQUEST_LIBRARY_POLICIES = 'policies:REQUEST_LIBRARY_POLICIES';
export const REQUEST_LIBRARY_POLICIES_COMPLETE = 'policies:REQUEST_LIBRARY_POLICIES_COMPLETE';
export const SET_LIBRARY_POLICIES = 'policies:SET_LIBRARY_POLICIES';
export const SAVE_LIBRARY_POLICY = 'policies:SAVE_LIBRARY_POLICY';
export const SAVE_LIBRARY_POLICY_COMPLETE = 'policies:SAVE_LIBRARY_POLICY_COMPLETE';
export const REQUEST_DELETE_POLICY = 'policies:REQUEST_DELETE_POLICY';

export const REQUEST_LIBRARY_POLICY = 'policies:REQUEST_LIBRARY_POLICY';
export const REQUEST_LIBRARY_POLICY_COMPLETE = 'policies:REQUEST_LIBRARY_POLICY_COMPLETE';
export const SET_LIBRARY_POLICY = 'policies:SET_LIBRARY_POLICY';
