import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {toggleSidebar} from 'modules/app';
import {Item} from 'components/sidebar/listSidebar';
import {REPORT_SCORE_TYPES} from 'modules/reports/reportUtils';
import Pill from 'components/pill';
import {toConcise} from 'utils/numberUtils';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import useReactRouter from 'use-react-router';
import {getTestSidebarIcon} from 'modules/reports/reportConstants';
import styles from 'components/sidebar/listSidebar/sidebarItem.scss';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

const {SCORES, ISSUES} = REPORT_SCORE_TYPES;

function ReportSidebarItem({navItem, scoreType, className, toggleSidebar}) {
  const screenSizes = useContext(ScreenSizeContext);
  const {
    match: {params}
  } = useReactRouter();

  const testId = navItem.get('testId');
  const isSection = navItem.get('type') == 'Section';
  const isMuted = navItem.get('isMuted') || isSection;
  const issueCounts = navItem.get('issueCounts');
  const numConfirmed = issueCounts ? issueCounts.confirmed : null;

  const actionCount = numConfirmed ? (
    <Pill appearance="action" narrow>
      {toConcise(numConfirmed)}
    </Pill>
  ) : null;

  const iconSrc = getTestSidebarIcon(navItem.get('testId'));
  const isSummary = testId == 'summary' || testId == 'website-summary';

  const handleClick = () => {
    // close the sidebar if mobile
    if (screenSizes.tablet) {
      toggleSidebar(true);
    }
  };

  return (
    <Item
      key={testId}
      href={testPath({...params, testId})}
      muted={isMuted}
      hoverable={!isSection}
      clickable={!isSection}
      translucent={isSection}
      className={className}
      onClick={handleClick}
      onClickAfterIcon={handleClick}
      active={navItem.get('isActive')}
      score={isSection ? null : scoreType === SCORES && navItem.get('score')}
      after={isSection ? null : scoreType === ISSUES && actionCount}
    >
      {iconSrc && <img src={iconSrc} className={styles.icon} />}
      {navItem.get('name')}
    </Item>
  );
}

export default connect(
  null,
  {push, toggleSidebar}
)(ReportSidebarItem);
