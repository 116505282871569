exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2GjagpsPsTLicYbQKvJGyA{0%{opacity:0}100%{opacity:1}}@keyframes _2GjagpsPsTLicYbQKvJGyA{0%{opacity:0}100%{opacity:1}}@-webkit-keyframes QRe3_CFjexpU-E81FG3BY{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes QRe3_CFjexpU-E81FG3BY{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}._3XKhOaiPX4tHLKFccparhk{-webkit-animation:_2GjagpsPsTLicYbQKvJGyA 1s;animation:_2GjagpsPsTLicYbQKvJGyA 1s;-webkit-animation-iteration-count:1;animation-iteration-count:1;flex:1;display:flex;align-items:stretch;min-height:110px;width:100%;transition:.5s all;align-items:center;padding:15px}._2A1W83iht3GX4XAL2nDMw7{vertical-align:middle;width:100%}._3JMPIANW_dFXsFCLklO4ow{font-size:1.2em;margin:0 0 0}.MuwKtnIvvUCs7wZk6lCz6{margin:10px 0 0 0}", ""]);

// exports
exports.locals = {
	"proportion": "_3XKhOaiPX4tHLKFccparhk",
	"fade-in": "_2GjagpsPsTLicYbQKvJGyA",
	"value": "_2A1W83iht3GX4XAL2nDMw7",
	"label": "_3JMPIANW_dFXsFCLklO4ow",
	"progress": "MuwKtnIvvUCs7wZk6lCz6",
	"rotating": "QRe3_CFjexpU-E81FG3BY"
};