import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import textStyles from 'components/input/input.scss';
import styles from './textareaInput.scss';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class TextareaInput extends React.Component {
  static defaultProps = {
    spellcheck: false,
    value: ''
  };

  render() {
    const {value, className, name, spellcheck, ...props} = this.props;
    const classes = cx(textStyles.input, styles.textareaInput, className);

    return (
      <textarea
        {...filterInvalidDOMProps(props)}
        className={classes}
        name={name}
        value={value}
        spellCheck={spellcheck}
        autoCorrect={spellcheck.toString()}
      />
    );
  }
}
