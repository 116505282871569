import {createSelector} from 'reselect';

export const passwordState = state => state.passwords;

export const passwordsSelector = createSelector(
  passwordState,
  state => {
    const hasSentResetToken = state.get('sentResetTokenEmail', false);
    return {
      sentEmail: hasSentResetToken
    };
  }
);
