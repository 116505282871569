import {take, select, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {UPDATE_USER, receiveUser, updateUserComplete} from 'modules/users';
import {userEndpoint} from 'modules/users/userUtils';
import getErrorMessage from 'api/errors';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';
import {showError, showSuccess} from 'utils/saga/alerts';

export default function* updateUserSaga() {
  yield takeLatest(UPDATE_USER, updateUser);
}

function* updateUser({user, userId}) {
  yield processRequest(
    'PUT',
    userEndpoint({userId}),
    {
      success: function*(data) {
        yield put(receiveUser(data));
        yield put(updateUserComplete());
        yield showSuccess(SUCCESS_MESSAGE);
      },

      error: function*(err) {
        if (err && err.errorCode) {
          const message = getErrorMessage(err.errorCode, err);
          yield put(updateUserComplete());
          yield showError(message);
        }
      }
    },
    user
  );
}
