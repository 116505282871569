import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import checked from './checked.png';

class Image extends React.Component {
  constructor() {
    super();
    this.state = {errored: false};
  }

  handleError = () => {
    this.setState({errored: true});
  };

  render() {
    const {data} = this.props;
    if (!data) return null;

    if (this.state.errored)
      return <div>{(this.props.options && this.props.options.empty) || 'Image unavailable'}</div>;

    const style = {background: '#fff url(' + checked + ') repeat'};

    return (
      <div
        className={cx(styles.imgBackground, {[styles.clickable]: !!this.props.onClick})}
        style={style}
        onClick={this.props.onClick}
      >
        <img className={styles.img} src={data} onError={this.handleError} />
      </div>
    );
  }
}

export default Image;
