import React from 'react';
import cx from 'classnames';
import styles from './settingsPage.scss';
import PageHeader from 'components/header/pageHeader';

export default class SettingsPage extends React.Component {
  render() {
    const {children, name, pageHeader, className, noBody} = this.props;

    return (
      <div className={styles.settingsPage}>
        {pageHeader || (name && <PageHeader title={name} />)}

        {!!noBody && children}
        {!noBody && <div className={cx(styles.main, className)}>{children}</div>}
      </div>
    );
  }
}
