import {put, select, takeLatest} from 'redux-saga/effects';
import {locationSelector} from 'modules/location/locationSelectors';
import {fetchInspectorPage} from 'modules/inspector/inspectorActions';
import {RETEST_COMPLETE} from 'modules/inspector/inspectorConstants';

export default function* inspectorRetestCompleteSaga() {
  yield takeLatest(RETEST_COMPLETE, inspectorRetestComplete);
}

function* inspectorRetestComplete() {
  const {params, query} = yield select(locationSelector);
  yield put(fetchInspectorPage(params, query));
}
