import React from 'react';
import cx from 'classnames';
import {Collapse} from 'react-collapse';

import styles from './faqs.scss';
import {connect} from 'react-redux';
import {loggedInSelector} from 'modules/auth/authSelectors';
import ExternalLink from 'modules/location/externalLink';

const defaultFaqs = [
  {
    onlyShowIfLoggedIn: true,
    title: 'What is the difference between "Monitor" and "Agency"?',
    body: (
      <span>
        <p>
          Monitor plans are designed to measure and improve a single website continuously. If you
          own the website you want to test, you probably want this plan.
        </p>
        <p>
          Agency plans are designed to test many different websites on an intermittent basis. If you
          sell or build a lot of websites for clients, you probably want an agency plan.
        </p>
        <p>
          Monitor plans will retest your website on a regular basis (every 5 days), notifying your
          team of changes and problems. You can retest the website or part of the website as often
          as you need. You can also increase or decrease the number of pages you need to test at any
          time.
        </p>
        <p>
          Agency plans allow you to run a certain number of one-off reports on any website. You must
          use up a report to re-test a website. Reports test a fixed number of webpages (100).
          They're ideal for showing reports to your prospects or clients.
        </p>
      </span>
    )
  },
  {
    title: 'What if I need more pages?',
    body: (
      <span>
        <p>
          You should{' '}
          <ExternalLink href="https://silktide.com/get-started/request-demo">
            talk to us
          </ExternalLink>{' '}
          about Silktide Monitor, our enterprise solution for larger websites. We can test websites
          with hundreds of thousands of pages if needed.{' '}
        </p>
      </span>
    )
  },
  {
    title: 'Can you test website accessibility?',
    body: (
      <span>
        <p>
          To test your website’s accessibility (including WCAG 2.1, Section 508, ADA, and DDA), you
          should look at Silktide Monitor, our enterprise solution for larger websites.{' '}
          <ExternalLink href="https://silktide.com/get-started/request-demo">
            Contact us for a demo
          </ExternalLink>
          .
        </p>
      </span>
    )
  },
  {
    title: 'What support is available?',
    body: (
      <span>
        <p>
          All our e-commerce plans include support via live chat and email. For more support,
          including onboarding via webinar, and specialized training,{' '}
          <ExternalLink href="https://silktide.com/get-started/request-demo">
            contact us
          </ExternalLink>{' '}
          about Silktide Monitor, our enterprise website testing solution.
        </p>
      </span>
    )
  },
  {
    title: 'How can I pay?',
    body: <p>We accept payment by all major credit cards, including Mastercard and Visa.</p>
  },
  {
    title: 'What if I want to cancel?',
    body: (
      <p>
        You can cancel at any time from inside your Silktide account. Or you can{' '}
        <ExternalLink href="https://silktide.com/contact/">contact us</ExternalLink> and we'll take
        care of it.
      </p>
    )
  },
  {
    title: 'Why should I use Silktide?',
    body: (
      <p>
        Silktide automates a massive range of checks that would otherwise take an extraordinary
        amount of time and expertise to perform. For example, Silktide can check whether every page
        of your website is laid out correctly on mobile phones - a task that could take a person
        hours or even days. Silktide frees up your attention to focus on improving your websites.
      </p>
    )
  }
];

export class Faqs extends React.Component {
  static defaultProps = {faqs: defaultFaqs};

  state = {open: null};

  toggleOpen = i => () => {
    const {open} = this.state;

    if (open === i) {
      i = null;
    }
    this.setState({open: i});
  };

  renderFaq = (faq, i) => {
    const isOpen = this.state.open === i;
    const classes = cx(styles.faq, {[styles.open]: isOpen});

    return (
      <div key={faq.title} className={classes}>
        <h2>
          <a onClick={this.toggleOpen(i)}>{faq.title}</a>
        </h2>
        <Collapse isOpened={isOpen}>{faq.body}</Collapse>
      </div>
    );
  };

  render() {
    const {faqs, className, isLoggedIn} = this.props;
    const {open} = this.state;

    return (
      <div className={cx(styles.wrapper, className)}>
        <h1 className={styles.title}>Frequently Asked Questions</h1>
        {faqs
          .filter(item => !item.onlyShowIfLoggedIn || (item.onlyShowIfLoggedIn && isLoggedIn))
          .map(this.renderFaq)}
      </div>
    );
  }
}

export default connect((state, props) => ({
  isLoggedIn: loggedInSelector(state, props)
}))(Faqs);
