import _ from 'lodash';
import React, {useContext} from 'react';
import Panel from 'components/panel';
import ExplorerData from '../explorer/explorerData';
import LayoutComponent from 'modules/tests/components/layoutComponent';
import styles from './table.scss';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {shallPassAny} from 'modules/auth/gandalf';
import {userSelector} from 'modules/auth/authSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {ReactReduxContext} from 'react-redux';

export default function Table(props) {
  const {config, result, filter, params, setFilterParams, viewingContext} = props;
  const {tablet} = useContext(ScreenSizeContext);
  const {store} = useContext(ReactReduxContext);
  const user = userSelector(store.getState());
  const report = reportSelector(store.getState());
  const isTeaser = report.getIn(['presentationOptions', 'isTeaser']);

  if (config.empty && config.empty.length > 0 && (!result || (!result.numRows && !result.length))) {
    // If we have no rows and we have specified some empty children. Then let us render those out.
    const items = _.map(config.empty, childConfig => {
      return (
        <LayoutComponent
          filter={filter}
          config={childConfig}
          key={childConfig.componentId}
          setFilterParams={setFilterParams}
          params={params}
          viewingContext={viewingContext}
          isTeaser={isTeaser}
        />
      );
    }).filter(Boolean);
    return items;
  }

  const columns = filterColumns(config.columns, tablet, user, report);
  const newProps = {...props, config: {...config, columns}, isTeaser};
  const edgeless = {config};

  const explorerData = <ExplorerData {...newProps} currentView="" />;
  if (edgeless) {
    return explorerData;
  }
  return (
    <Panel hideBorderTop padding={false}>
      {explorerData}
    </Panel>
  );
}

function filterColumns(columns, isMobile, user, report) {
  const filteredColumns = columns.filter(c => isColumnAllowed(c, user, report));

  return isMobile ? filteredColumns.filter(c => !isInspectorButtonColumn(c)) : filteredColumns;
}

function isColumnAllowed({shallpass}, user, report) {
  if (shallpass) {
    return shallPassAny(shallpass, {user, report});
  }

  return true;
}

function isInspectorButtonColumn(column) {
  return (
    column.controls &&
    column.controls.length === 1 &&
    column.controls[0].instruction.type.value === 'inspector'
  );
}
