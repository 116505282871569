import {call, all, takeLatest} from 'redux-saga/effects';
import {showSuccess, showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';
import {UPDATE_ACCOUNT_SETTINGS} from 'modules/settings';
import {addOrUpdateAccount} from 'modules/accounts/sagas/addOrUpdateAccount';
import {updateAccountOptions} from 'modules/accountOptions/sagas/updateAccountOptions';
import {getProps} from 'utils/immutableUtils';
import {ACCOUNT_OPTIONS} from 'modules/accountOptions/accountOptionConstants';
import _ from 'lodash';

export default function* updateAccountSettingsSaga() {
  yield takeLatest(UPDATE_ACCOUNT_SETTINGS, updateAccountSettings);
}

function* updateAccountSettings({data}) {
  // Work out what keys to send to the account vs acount options endpoints. Basically
  // anything that isn't in the ACCOUNT_OPTIONS constant is considered an account property.
  // TODO: _.values and _.keys can be replaced with ES2017 funcs when we support it
  const accountOptionsKeys = _.keys(ACCOUNT_OPTIONS);
  const accountKeys = _.keys(data).filter(key => accountOptionsKeys.indexOf(key) < 0);

  const accountData = getProps(data, accountKeys);
  const accountOptionsData = getProps(data, accountOptionsKeys);

  const [account, accountOptions] = yield all([
    call(addOrUpdateAccount, {account: accountData}),
    call(updateAccountOptions, {options: accountOptionsData})
  ]);

  if (account && accountOptions) {
    yield showSuccess(SUCCESS_MESSAGE);
  } else {
    yield showError(couldNot('update account'));
  }
}
