import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {recentReportsEndpoint} from '../utils';
import {appendRecentReports, receiveRecentReports, noMoreRecentReports} from '../actions';
import {FETCH_RECENT_REPORTS} from '../constants';

export default function* fetchRecentReportsSaga() {
  yield takeLatest(FETCH_RECENT_REPORTS, fetchRecentReports);
}

function* fetchRecentReports({limit, offset, append}) {
  yield processRequest(
    'GET',
    recentReportsEndpoint(),
    {
      success: function*(data) {
        if (Array.isArray(data.recentReports)) {
          if (data.recentReports.length < limit) {
            yield put(noMoreRecentReports());
          }
          yield put((append ? appendRecentReports : receiveRecentReports)(data.recentReports));
        }
      }
    },
    {limit, offset}
  );
}
