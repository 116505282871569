import PropTypes from 'prop-types';
import React from 'react';
import Input from 'components/input';
import {ZERO, NINE} from 'constants/keyCodes';

export default class NumberInput extends React.Component {
  // number inputs don't enforce maxLength: fix it here
  // by preventing entry if it will be exceeded
  handleKeyDown = e => {
    const {value, maxLength, handleKeyDown} = this.props;
    if (value && value.toString().length === maxLength) {
      // Ignore inputted numbers
      if (e.keyCode >= ZERO && e.keyCode <= NINE) {
        e.preventDefault();
        return false;
      }
    }

    handleKeyDown && handleKeyDown(e);
  };

  handleChange = event => {
    if (this.props.parseInt) {
      this.props.onChange(parseInt(event.target.value, 10));
    } else if (this.props.parseFloat) {
      this.props.onChange(parseFloat(event.target.value));
    } else {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    const {maxLength, ...props} = this.props;

    // we use width: maxLength + 2
    // as number inputs have incrementer buttons
    // that take up roughly 2ems of space

    return (
      <Input
        {...this.props}
        onChange={this.handleChange}
        style={maxLength && {width: `${maxLength + 2}em`}}
        type="number"
        ref={ref => (this.input = ref)}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
