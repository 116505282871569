import React from 'react';
import {useSelector} from 'hooks/redux';
import Spinner from 'components/spinner';
import {dashboardSelector, dashboardWidgetsSelector} from '../selectors';

export default function WidgetsProvider({dashboardId, rowIndex, columnIndex, children}) {
  const dashboardDefinition = useSelector(dashboardSelector, {dashboardId});
  const widgets = useSelector(dashboardWidgetsSelector, {dashboardId, rowIndex, columnIndex});

  if (!dashboardDefinition) {
    return <Spinner marginTop={20} marginBottom={20} />;
  }

  return children(widgets);
}
