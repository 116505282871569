import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({groups: {}});

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_GROUPS:
      return state.mergeIn(['groups'], keyBy(action.groups, 'groupId'));
  }
  return state;
}

export const LOAD_GROUPS = 'groups:LOAD_GROUPS';
export function loadGroups() {
  return {type: LOAD_GROUPS};
}

export const RECEIVE_GROUPS = 'groups:RECEIVE_GROUPS';
export function receiveGroups(groups) {
  return {
    type: RECEIVE_GROUPS,
    groups
  };
}

export function groupsEndpoint() {
  return 'groups';
}
