import React from 'react';
import Table from 'components/table';
import Button from 'components/button';
import styles from '../timeline.scss';
import {createInspectorBrokenLink} from 'modules/instructions/instructionUtils';

const LinkStatuses = {
  ['host-not-found']: 'Host not found',
  ['http-error']: 'HTTP error',
  ['timeout']: 'Timeout',
  ['ssl-error']: 'SSL error',
  ['ssl-deprecated']: 'SSL Deprecated',
  ['redirect-loop']: 'Redirect loop',
  ['invalid-url']: 'Invalid url',
  ['rate-limited']: 'Rate limited'
};

const COLS = [
  {
    label: '',
    type: 'jsx',
    width: '50px',
    jsx: ({doCommand, record}) => {
      return (
        <Button
          small
          square
          icon="search"
          onClick={event => {
            const command = createInspectorBrokenLink(record.props.actionId, record.props.fullHash);
            doCommand(command, record, {}, event);
          }}
        />
      );
    }
  },
  {
    label: 'Link',
    field: 'props.brokenLink',
    type: 'url',
    external: true
  },
  {
    label: 'Status',
    field: 'props.linkStatus',
    type: 'string',
    collapse: '440',
    combineWith: 'Broken Link',
    formatData: data => (LinkStatuses[data] ? LinkStatuses[data] : 'Unknown')
  },
  {
    label: 'Code',
    field: 'props.httpCode',
    type: 'count',
    collapse: '440',
    empty: 'Unavailable',
    combineWith: 'Link',
    appearance: 'minor'
  },
  {
    label: 'Occurrences',
    field: 'props.numPoints',
    type: 'count',
    collapse: '600',
    combineWith: 'Link',
    appearance: 'minor'
  }
];

export default function NewBrokenLink({story, storyPoints, doCommand}) {
  return (
    <React.Fragment>
      <div className={styles.table}>
        <Table
          cellParams={{doCommand}}
          rowKey="storyPointId"
          columns={COLS}
          rows={Object.keys(storyPoints).map(storyPointId => storyPoints[storyPointId])}
        />
      </div>
    </React.Fragment>
  );
}
