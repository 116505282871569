import React from 'react';
import Icon from 'components/font-awesome';
import styles from '../topic.scss';

export default class TopicShowMore extends React.Component {
  render() {
    const {onClick, showingMore} = this.props;

    return (
      <div className={styles.showMoreWrapper} onClick={onClick}>
        {showingMore && (
          <span>
            <Icon name="caret-up" /> Show less
          </span>
        )}
        {!showingMore && (
          <span>
            <Icon name="caret-down" /> Show more
          </span>
        )}
      </div>
    );
  }
}
