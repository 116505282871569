import {fromJS, Map} from 'immutable';
import {analytics, events} from 'modules/analytics';

const initialState = fromJS({
  stepData: [],
  currentStep: 0,
  incompleteSignup: null,
  errorMessage: null,
  loading: false,
  params: {}
});

export default function onboardingReducer(state = initialState, action) {
  const currentStep = state.get('currentStep');

  switch (action.type) {
    case START_ONBOARDING:
      if (action.params && action.params.planId) {
        return initialState
          .set('params', fromJS(action.params))
          .setIn(['stepData', 0], action.params.planId)
          .set('currentStep', 1);
      }
      return initialState.set('params', fromJS(action.params));

    case NEXT_STEP: {
      if (action.data) {
        state = state.setIn(['stepData', currentStep], action.data);
      }

      return state.set('currentStep', currentStep + 1).set('errorMessage', null);
    }

    case STEP_LOADING:
      return state.set('loading', true);

    case STEP_LOADED:
      return state.set('loading', false);

    case PREV_STEP: {
      return state
        .setIn(['stepData', currentStep], action.data)
        .set('currentStep', currentStep - 1);
    }

    case JUMP_TO_STEP: {
      // clear the password when we jum pback to user (because we cannot set the
      // default value to the 3rd party passwordStrength component)
      // if we dont, they can submit the form with an "empty" password field, which looks wierd

      // 1 === USER; importing USER from constants breaks this file; dont ask
      if (action.step == 1) {
        let user = state.getIn(['stepData', 1]);
        state = state.setIn(
          ['stepData', 1],
          Map(user)
            .delete('password')
            .toJS()
        );
      }
      return state.set('currentStep', action.step).set('errorMessage', action.errorMessage);
    }
  }
  return state;
}

export const START_ONBOARDING = 'onboarding:START_ONBOARDING';
export function startOnboarding(params = {}) {
  return {
    type: START_ONBOARDING,
    params,
    ...analytics(events.CONVERSION_START)
  };
}

export const NEXT_STEP = 'onboarding:NEXT_STEP';
export function nextStep(data) {
  return {type: NEXT_STEP, data};
}

export const STEP_LOADING = 'onboarding:STEP_LOADING';
export function stepLoading() {
  return {type: STEP_LOADING};
}

export const STEP_LOADED = 'onboarding:STEP_LOADED';
export function stepLoaded() {
  return {type: STEP_LOADED};
}

export const PREV_STEP = 'onboarding:PREV_STEP';
export function prevStep(data) {
  return {type: PREV_STEP, data};
}

export const JUMP_TO_STEP = 'onboarding:JUMP_TO_STEP';
export function jumpToStep(step, errorMessage) {
  return {type: JUMP_TO_STEP, step, errorMessage};
}

export const SHOW_UPGRADE_MODAL = 'onboarding:SHOW_UPGRADE_MODAL';
export function showUpgradeModal() {
  return {type: SHOW_UPGRADE_MODAL};
}
