import _ from 'lodash';
import React from 'react';
import Tick from './tick';
import cx from 'classnames';
import styles from './tick.scss';
import HoverListener from 'components/events/hoverListener';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// A TickSelector seems to be basically a div with a tick on the left
// and any children you provide on the right.

export default class TickSelector extends React.Component {
  render() {
    const {className, children, selected, ...props} = this.props;
    const classes = cx(styles.tickSelector, className);

    return (
      <HoverListener>
        {hovering => (
          <div {...filterInvalidDOMProps(props)} className={classes}>
            <Tick selected={selected} hovering={hovering} />
            <div className={styles.tickSelectorContent}>{children}</div>
          </div>
        )}
      </HoverListener>
    );
  }
}
