import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import {toFixed, formatGivenNumber} from 'utils/numberUtils.js';

const DEFAULT_DECIMALS = 2;

class Float extends React.Component {
  render() {
    let {data, options, className} = this.props;
    const {decimals} = options;

    const classNames = cx(styles.float, className, {
      [styles[options.class]]: true
    });

    if (typeof data === 'undefined') {
      data = 0;
    }

    return <span className={classNames}>{toFixed(data, decimals || DEFAULT_DECIMALS)}</span>;
  }
}

Float.propTypes = {};

export default Float;
