import {take, select, cancel, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {FETCH_USERS, receiveUsers} from 'modules/users';
import {LOGIN, SWITCH_ACCOUNT} from 'modules/auth';
import {usersEndpoint} from 'modules/users/userUtils';
import {showError} from 'utils/saga/alerts';

export default function* fetchUsersSaga() {
  // Fetch users on login/switch account so they can be used in filters
  yield takeLatest([FETCH_USERS, LOGIN, SWITCH_ACCOUNT], fetchUsers);
}

function* fetchUsers(action) {
  yield processRequest('GET', usersEndpoint(action), {
    successAction: receiveUsers

    // if an error occurs, we don't want to show an error message
    // (because this operation happens in the background)
  });
}
