import React from 'react';
import styles from './searchResult.scss';
import UrlComponent from 'components/url/url';
import ExternalLink from 'modules/location/externalLink';

class SearchResult extends React.Component {
  render() {
    const {record} = this.props;
    const {title, description, url, displayedLink} = record;

    return (
      <div>
        <ExternalLink href={url}>
          <h4 className={styles.title}>{title}</h4>
        </ExternalLink>
        <p className={styles.url}>
          <UrlComponent url={url} label={displayedLink} link stopPropagation noIcon />
        </p>
        <p className={styles.description}>{description}</p>
      </div>
    );
  }
}

export default SearchResult;
