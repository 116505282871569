exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3YJlVBL6ArytkCn7ZqA4eU{margin:5px 0;color:#1a0dab;font-size:18px}._3A_HYpP9qEPdCGsY1l2r0a{margin:5px 0}._3A_HYpP9qEPdCGsY1l2r0a a{color:#006621}._3H3O4HG52R9gW1wdzg8BWI{margin:5px 0;color:#545454}", ""]);

// exports
exports.locals = {
	"title": "_3YJlVBL6ArytkCn7ZqA4eU",
	"url": "_3A_HYpP9qEPdCGsY1l2r0a",
	"description": "_3H3O4HG52R9gW1wdzg8BWI"
};