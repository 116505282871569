import _ from 'lodash';
import {Map} from 'immutable';
import hash from 'object-hash/index';
import {isIterable, mapValues} from 'utils/collectionUtils';
import {isNumeric} from 'utils/numberUtils';
import {normalizeFilters} from 'modules/filters/filterUtils';
import {reportEndpoint} from 'modules/reports/reportUtils';
import {toJS} from 'utils/immutableUtils';

const EMPTY_RESULT = null;

export function scoreKey({testId}) {
  return `${testId}.score`;
}

// When we fetch results, we specify the current filters (from the URL bar).
// Results are affected by certain filters which are stored in result.usesFilters
// When we store a result, we want to store it with a hash of all the current filters
// it's actually affected by
export function getFilterHash(filters, usesFilters = []) {
  // Not affected by filters
  if (!usesFilters.length) return '';

  const matchingFilters = _.pick(filters, [...usesFilters]);
  return _.isEmpty(matchingFilters) ? '' : hash(normalizeFilters(matchingFilters));
}

export function getResultError(results, resultId) {
  if (!resultId) return null;

  const resultsForKey = results.get(resultId);
  if (!resultsForKey) return null;

  return resultsForKey.get('error');
}

export function getResult(results = Map(), resultId, filters = []) {
  if (!resultId) return EMPTY_RESULT;

  const resultsForKey = results.get(resultId);

  if (!resultsForKey) return EMPTY_RESULT;

  let result = EMPTY_RESULT;
  if (resultsForKey.has('results')) {
    const filterHash = getFilterHash(filters, toJS(resultsForKey.get('usesFilters')));

    result = resultsForKey.get('results').get(filterHash);
  }

  if (resultsForKey.has('result')) {
    result = resultsForKey.get('result');
  }

  if (result == null) {
    return EMPTY_RESULT;
  }

  return result;
}

export function getResultValue(results, resultId, filters = []) {
  return getResult(...arguments);
}

export function getScore(results, testId) {
  if (!results) return;
  return getResultValue(results, testId + '.score');
}

// Converts integer-like values to integers in results.
export function normalizeResult(result, decimals) {
  if (isIterable(result)) {
    return mapValues(result, res => normalizeResult(res, decimals));
  }
  if (isNumeric(result)) {
    if (decimals) {
      return Number(result).toFixed(decimals);
    }
    return Number(result);
  }

  return result;
}

export function resultsEndpoint() {
  return reportEndpoint(...arguments) + '/results';
}
