import React from 'react';
import Dropdown, {DropdownOption} from 'components/dropdown';
import InspectorHeaderButton from 'modules/inspector/components/inspectorHeaderButton';
import DropdownEffectToggle from './inspectorDropdownEffectToggle';

const button = (
  <div>
    <InspectorHeaderButton icon="eye" marginRight>
      Simulations
    </InspectorHeaderButton>
  </div>
);

export default function InspectorEffectDropdown() {
  // Each `effectId` refers to a key in EFFECT_CONFIG
  return (
    <Dropdown button={button}>
      <DropdownEffectToggle effectId="dyslexia">Dyslexia</DropdownEffectToggle>
      <DropdownEffectToggle effectId="lossOfVision1">Impaired vision (mild)</DropdownEffectToggle>
      <DropdownEffectToggle effectId="lossOfVision2">
        Impaired vision (moderate)
      </DropdownEffectToggle>
      <DropdownEffectToggle effectId="lossOfVision3">Impaired vision (severe)</DropdownEffectToggle>
      <DropdownEffectToggle effectId="glare">Glare</DropdownEffectToggle>

      <DropdownEffectToggle effectId="protanopia">Color blind (red-green)</DropdownEffectToggle>
      <DropdownEffectToggle effectId="tritanopia">Color blind (blue-yellow)</DropdownEffectToggle>
      <DropdownEffectToggle effectId="monochrome">Color blind (no color)</DropdownEffectToggle>

      <DropdownEffectToggle effectId="searchEngine">Search engine</DropdownEffectToggle>
    </Dropdown>
  );
}
