import React from 'react';
import {setEffect} from 'modules/inspector/inspectorActions';
import {enabledEffectsSelector} from 'modules/inspector/inspectorSelectors';
import {useSelector, useDispatch} from 'hooks/redux';
import {getAffectedEffects} from '../inspectorUtils';

export default function EffectToggle({component: Component, effectId, children}) {
  const dispatch = useDispatch();
  const enabledEffects = useSelector(enabledEffectsSelector);

  const handleClick = () => {
    if (enabledEffects.includes(effectId)) {
      dispatch(setEffect(effectId, false));
    } else {
      const affectedEffects = getAffectedEffects(effectId);
      for (let effectId of affectedEffects) {
        if (enabledEffects.includes(effectId)) {
          dispatch(setEffect(effectId, false));
        }
      }
      dispatch(setEffect(effectId, true));
    }
  };

  return <Component onClick={handleClick}>{children(enabledEffects.includes(effectId))}</Component>;
}
