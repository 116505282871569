import React from 'react';
import {connect} from 'react-redux';
import ModalContent from 'modules/modal/components/modalContent';
import LayoutComponent from 'modules/tests/components/layoutComponent';
import {setFilterParams} from 'modules/filters';
import {filterSelector} from 'modules/filters/filterSelectors';
import {updateComponentDetail} from 'modules/tests';
import styles from './componentDetailsModal.scss';
import {hideModal} from 'modules/modal';

export class ComponentDetailsModal extends React.Component {
  handleSetParamFilter = filters => {
    this.props.updateComponentDetail(this.props.componentConfig, filters);

    // must be done in a timeout otherwise new query params may not be set
    setTimeout(() => {
      this.props.setFilterParams(filters);
      // Hide modal after setting filters so we can see what's changed
      this.props.hideModal();
    }, 0);
  };

  render() {
    const {componentConfig, params, filter} = this.props;

    return (
      <ModalContent className={styles.modalContent}>
        <LayoutComponent
          filter={filter}
          params={params}
          config={componentConfig.toJS()}
          setFilterParams={this.handleSetParamFilter}
        />
      </ModalContent>
    );
  }
}

export default connect(
  filterSelector,
  {setFilterParams, updateComponentDetail, hideModal}
)(ComponentDetailsModal);
