exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._27UyLJ6zUY1hddRDOxgoNg{color:#888}._27UyLJ6zUY1hddRDOxgoNg>span{margin-left:5px}._2zWzT6jzByJv58bUyVLahJ{margin-left:10px;position:relative;top:-10px}", ""]);

// exports
exports.locals = {
	"wrapper": "_27UyLJ6zUY1hddRDOxgoNg",
	"withScore": "_2zWzT6jzByJv58bUyVLahJ"
};