import React from 'react';

export default class PageWithMetricIssue extends React.Component {
  render() {
    const {point} = this.props;
    const {value, description} = point;

    if (!value) return null;

    return (
      <div>
        {value} {description}
      </div>
    );
  }
}
