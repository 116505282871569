import {takeLatest, select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {uptimeMonitorContactsEndpoint, uptimeMonitorContactEndpoint} from '../utils';
import {receiveUptimeMonitorContact} from '../actions';
import {UPDATE_UPTIME_MONITOR_CONTACT} from '../constants';
import {uptimeMonitorContactsSelector} from '../selectors';

export default function* saveUptimeMonitorContactsSaga() {
  yield takeLatest(UPDATE_UPTIME_MONITOR_CONTACT, saveUptimeMonitorContacts);
}

// `contactOptions` looks like {"email": true, "phoneNumber": false}
function* saveUptimeMonitorContacts(action) {
  const {
    monitorId,
    reportId,
    data: {userId, contactOptions}
  } = action;
  const uptimeContacts = yield select(uptimeMonitorContactsSelector);

  // if we can't find the contact in the reducer, we need to create it rather than update it
  const isCreating = !uptimeContacts.has(userId.toString());

  const method = isCreating ? 'POST' : 'PUT';
  const url = (isCreating ? uptimeMonitorContactsEndpoint : uptimeMonitorContactEndpoint)({
    reportId,
    monitorId,
    userId
  });

  yield processRequest(
    method,
    url,
    {
      success: function*(uptimeContacts) {
        yield put(receiveUptimeMonitorContact(uptimeContacts));
      }
    },
    {userId, contactOptions}
  );
}
