exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2xTtIxt6Ik3MzMYh2wcFxe{margin-right:5px}", ""]);

// exports
exports.locals = {
	"backIcon": "_2xTtIxt6Ik3MzMYh2wcFxe"
};