import cx from 'classnames';
import styles from './header.scss';
import stylesV2 from './headerV2.scss';
import Flex from 'components/layout/flex';
import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {reportSelector} from 'modules/reports/reportSelectors';

const reportTypeSelector = createSelector(
  reportSelector,
  report => {
    return {
      reportType: report.get('reportType')
    };
  }
);

export class Header extends React.Component {
  renderV1Header() {
    const {children, reportType, config} = this.props;
    const {withScore} = config;

    const className = cx(styles.header, {
      [styles.withScore]: withScore,
      [styles.dashboardHeader]: reportType && reportType === 'dashboard'
    });

    return (
      <Flex container wrap className={className}>
        {children}
      </Flex>
    );
  }

  renderV2Header() {
    const {children, config} = this.props;
    const {withCategoryScore} = config;

    const className = cx(stylesV2.header, {
      [stylesV2.withCategoryScore]: withCategoryScore
    });

    return <div className={className}>{children}</div>;
  }

  render() {
    const {config} = this.props;

    if (config && config.version === '2') {
      return this.renderV2Header();
    }

    return this.renderV1Header();
  }
}

export default connect(
  reportTypeSelector,
  {}
)(Header);
