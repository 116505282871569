import {select, all, put, takeEvery} from 'redux-saga/effects';
import {LEAVE_ACTION, VIEW_ACTION_LAYOUT, FETCH_ACTION_RESULTS} from 'modules/actions/actions';
import {actionWithLayoutSelector} from 'modules/actions/actionSelectors';
import {getResultsFromQuery} from 'modules/tests/sagas/helpers/getResultsForResultIds';
import {receiveResults} from 'modules/results';

export default function* viewActionLayoutSaga() {
  yield takeEvery([LEAVE_ACTION, VIEW_ACTION_LAYOUT, FETCH_ACTION_RESULTS], viewActionLayout);
}

function* viewActionLayout(reduxAction) {
  if (reduxAction.type === LEAVE_ACTION) {
    // If we get a LEAVE_ACTION, then the takeLatest will cancel this saga, and we will exit early
    return;
  }

  const {actionId, filters} = reduxAction;
  const {action} = yield select(actionWithLayoutSelector, {actionId});
  const resultIds = action.resultIds;

  if (!resultIds || !resultIds.length) {
    return;
  }

  const payload = {
    resultIds,
    filters
  };

  const results = yield* getResultsFromQuery(payload);
  yield put(receiveResults(results));
}
