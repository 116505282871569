/* eslint-disable indent */
import Button from 'components/button/button';
import Buttons from 'components/buttons';
import {DropdownBody, DropdownOption} from 'components/dropdown';
import Gandalf from 'components/gandalf';
import PageHeader from 'components/header/pageHeader';
import Helmet from 'components/helmet/helmet';
import Flex from 'components/layout/flex';
import Markdown from 'components/layoutComponents/renderers/markdown';
import MaxWidth from 'components/maxWidth';
import PageBody from 'components/page';
import Pagination from 'components/pagination';
import Panel from 'components/panel';
import Pill from 'components/pill';
import SearchBar from 'components/searchBar';
import Spinner from 'components/spinner';
import Status from 'components/status';
import Table from 'components/table';
import Toggle from 'components/toggle';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import Dashboard from 'modules/dashboards/containers/dashboard';
import {push, updateQuery} from 'modules/location';
import {reportPath} from 'modules/reports/reportUtils';
import React, {Fragment, useRef} from 'react';
import {connect} from 'react-redux';
import {choosePluralVersion} from 'utils/interpolationUtils';
import {getNextSort} from 'utils/stringUtils';
import {leaderboardsAuditLogPath} from '../../auditLog/utils';
import {exportLeaderboard, requestDeleteLeaderboard} from '../actions';
import DeleteLeaderboardButton from '../containers/deleteLeaderboardButton';
import EditLeaderboardButton from '../containers/editLeaderboardButton';
import LeaderboardBreadcrumb from '../containers/leaderboardBreadcrumb';
import {leaderboardPageSelector} from '../selectors';
import {leaderboardsPath} from '../utils';
import styles from './leaderboardPage.scss';

// If this doesn't match the backends defualt, then the very first request will contain the backends default
const ROWS_PER_PAGE = 100;

function LeaderboardPage({
  isReady,
  leaderboard,
  canViewLeaderboardWidgets,
  leaderboardOptions,
  reports,
  maxReports,
  columns,
  params,
  query,
  goBack,
  viewReport,
  updateQuery,
  viewLeaderboards,
  viewAuditLog,
  exportLeaderboard,
  isExporting,
  accountId,
  deleteLeaderboard
}) {
  // default
  const offset = parseInt(query.offset, 10) || 0;

  const toggleRef = useRef();

  if (!leaderboard) return <Spinner marginTop={20} />;

  const leaderboardId = leaderboard.get('leaderboardId');

  const isMainLeaderboard = leaderboardId == 1;

  const pageNo = Math.floor(offset / ROWS_PER_PAGE) + 1;
  const maxPage = Math.ceil(maxReports / ROWS_PER_PAGE);

  const currentSort = query.order
    ? query.order
    : leaderboard.get('defaultSortBy')
    ? leaderboard.get('defaultSortBy') + ' ' + leaderboard.get('defaultSortOrder')
    : 'report.name asc';

  const moreButton = <Button plain wire icon="caret-down"></Button>;

  return (
    <Fragment>
      <Helmet title="Leaderboards" />
      <LeaderboardBreadcrumb leaderboard={leaderboard}>
        <MaxWidth>
          <PageHeader
            title={leaderboard.get('name')}
            inline={[
              <Buttons key="buttons" noMargin>
                <EditLeaderboardButton key="edit" leaderboardId={leaderboardId} />
                <Gandalf shallPass={ACCOUNT_OWNER}>
                  <Toggle button={moreButton} ref={toggleRef}>
                    <DropdownBody>
                      <DropdownOption onClick={() => viewAuditLog(leaderboardId, accountId)}>
                        View audit log
                      </DropdownOption>
                      {!isMainLeaderboard && (
                        <DropdownOption
                          onClick={() => {
                            deleteLeaderboard(leaderboardId);
                            toggleRef.current.close();
                          }}
                        >
                          Delete leaderboard
                        </DropdownOption>
                      )}
                    </DropdownBody>
                  </Toggle>
                </Gandalf>
              </Buttons>
            ]}
          >
            {leaderboard.get('description') && (
              <div style={{padding: '10px 20px'}}>
                <Markdown data={leaderboard.get('description')} />
              </div>
            )}
          </PageHeader>

          <PageBody>
            {canViewLeaderboardWidgets && !!leaderboard.get('dashboardId') && (
              <Dashboard
                contextTypeId="reports"
                leaderboardId={leaderboardId}
                dashboardId={leaderboard.get('dashboardId')}
              />
            )}

            <Panel toolbar smallPadding>
              <Flex container>
                <Flex>
                  <h3>
                    <Pill appearance="minor" showZero>
                      {maxReports}
                    </Pill>{' '}
                    {choosePluralVersion(maxReports, 'website', 'websites')}
                  </h3>
                </Flex>
                <Flex right className={styles.headerRight}>
                  <Button
                    onClick={() => exportLeaderboard(leaderboardId)}
                    className={styles.exportButton}
                    disabled={isExporting}
                    icon="arrow-to-bottom"
                    wire
                  >
                    Export
                  </Button>
                  <SearchBar
                    value={query.search}
                    onChange={event => updateQuery({page: 1, search: event.target.value})}
                  />
                </Flex>
              </Flex>
            </Panel>
            <Panel padding={false} overflow="auto" hideBorderTop>
              {!isReady ? (
                <div style={{padding: '50px'}}>
                  <Spinner />
                </div>
              ) : !reports.size ? (
                <Status appearance="info">
                  {query.search
                    ? 'No matches found for your search'
                    : "Either this leaderboard is empty or you don't have permission to see websites inside it."}
                </Status>
              ) : (
                <div className={styles.leaderboardTable}>
                  <Table
                    sideScroll
                    freezeFirstColumn
                    rowKey="reportId"
                    currentSort={currentSort}
                    rows={reports.toJS()}
                    columns={convertColumnFormatBackendToFrontEnd(columns)}
                    onClick={(row, col, event) => {
                      if (row.canView) {
                        viewReport({...params, reportId: row.reportId}, event);
                      }
                    }}
                    onClickColumn={col => {
                      if (col.sortable) {
                        updateQuery({order: getNextSort(col.sortable, currentSort)});
                      }
                    }}
                  />
                </div>
              )}
            </Panel>
            {maxPage > 1 && (
              <Pagination
                marginTop
                page={pageNo}
                numRows={maxReports}
                rowsPerPage={ROWS_PER_PAGE}
                maxPage={maxPage}
                onPageSelect={pageNo => {
                  const newOffset = Math.floor(ROWS_PER_PAGE * (pageNo - 1));
                  updateQuery({
                    offset: newOffset === 0 ? undefined : newOffset,
                    limit: ROWS_PER_PAGE
                  });
                }}
              />
            )}
          </PageBody>
        </MaxWidth>
      </LeaderboardBreadcrumb>
    </Fragment>
  );
}

export default connect(
  leaderboardPageSelector,
  dispatch => ({
    goBack() {
      dispatch(push(leaderboardsPath));
    },
    viewReport(params, event) {
      dispatch(push(reportPath, params, [], false, {}, event));
    },
    updateQuery(query) {
      dispatch(updateQuery(query));
    },
    viewLeaderboards() {
      dispatch(push(leaderboardsPath));
    },
    exportLeaderboard(leaderboardId) {
      dispatch(exportLeaderboard(leaderboardId));
    },
    viewAuditLog(leaderboardId, accountId) {
      dispatch(push(leaderboardsAuditLogPath({leaderboardId, accountId})));
    },
    deleteLeaderboard(leaderboardId) {
      dispatch(requestDeleteLeaderboard(leaderboardId));
    }
  })
)(LeaderboardPage);

function convertColumnFormatBackendToFrontEnd(columns) {
  return columns
    .toList()
    .map(col => {
      // rename `name` key to `field`
      if (col.get('name') === 'report.name') {
        col = col
          .set('type', 'jsx')
          .set('sticky', true)
          .set('jsx', data => {
            if (data.record && data.record.canView) {
              return (
                <span
                  className="fauxLink"
                  title={data.data}
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {data.data}
                </span>
              );
            } else {
              return data.data;
            }
          });
      }

      return col
        .set('field', col.get('name').replace('report.', ''))
        .set('sortable', col.get('name'))
        .set('type', col.get('type'))
        .delete('name');
    })
    .toJS();
}
