exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38UnAda_QS7l5vg-tuq5Io{min-width:300px;max-height:300px;overflow-y:scroll}._2Inr9ugoWZkAvmVfET8vWI{max-height:unset !important}", ""]);

// exports
exports.locals = {
	"dropdownBody": "_38UnAda_QS7l5vg-tuq5Io",
	"bodyOptions": "_2Inr9ugoWZkAvmVfET8vWI"
};