import React from 'react';
import {API} from 'config';
import styles from './styles.scss';
import Spinner from 'components/spinner';
import Button from 'components/button/button';

export default class FullScreenshot extends React.Component {
  state = {loaded: false, src: null};

  componentDidMount() {
    const {config, filter: filters} = this.props;
    let {viewportUrl, fullPageUrl} = config;

    if (!filters[viewportUrl]) {
      return;
    }

    let src = filters[viewportUrl];
    let isFullPage = false;
    if (filters[fullPageUrl] && filters[fullPageUrl].length) {
      src = filters[fullPageUrl];
      isFullPage = true;
    }

    if (src && !src.match(/:\/\//)) src = API.ROOT + src;
    this.attemptLoad(src, isFullPage);
  }

  attemptLoad = (src, isFullPage) => {
    const image = new Image();
    this.image = image;
    image.onload = this.handleLoad.bind(this, src);
    image.onerror = () => this.handleError(src, isFullPage);
    image.src = src;
  };

  handleLoad = src => {
    this.setState({loaded: true, src});
  };

  handleError = (src, isFullPage) => {
    if (isFullPage) {
      this.setState({fullPage: false, loaded: false});
      this.attemptLoad(src.replace('-full', ''));
    }
  };

  render() {
    const {config, filter: filters} = this.props;
    let {viewportUrl} = config;

    if (!filters[viewportUrl]) {
      return null;
    }

    return (
      <div>
        {this.state.loaded && (
          <div>
            <img className={styles.img} src={this.state.src} alt="Screenshot of website" />
            <div style={{textAlign: 'center', marginTop: '30px'}}>
              <Button
                onClick={() => {
                  window.open(this.state.src, '_blank');
                }}
              >
                Open in new tab
              </Button>
            </div>
          </div>
        )}
        {!this.state.loaded && (
          <Spinner centered className={styles.spinner} marginTop={30} marginBottom={30} />
        )}
      </div>
    );
  }
}
