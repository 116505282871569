import {delay} from 'redux-saga';
import {call, put, race, select, take, takeLatest} from 'redux-saga/effects';
import {VIEW_ACTION, LEAVE_ACTION, fetchActionResults} from 'modules/actions/actions';
import {latestActionResultFilters} from 'modules/actions/actionSelectors';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import {DISABLE_BACKGROUND_POLL} from 'config';

export default function* resultPollSaga() {
  if (!DISABLE_BACKGROUND_POLL) {
    yield takeLatest([VIEW_ACTION], startPoll);
  }
}

function* startPoll({actionId}) {
  // disable the results poll if the results are for an archive
  if (yield select(isArchiveActiveSelector)) return;

  while (true) {
    // wait for 10 seconds - if we get a leave action or a clear report then we cancel.
    // If we get a receive decision or (or the 10 seconds is finished) then continune on to fetch results.
    const {leaveAction, timeout} = yield race({
      leaveAction: take(LEAVE_ACTION),
      timeout: call(delay, 30 * 1000)
    });

    // if leave action, do not request
    if (leaveAction) {
      return;
    }

    const filters = yield select(latestActionResultFilters);
    // 10 seconds is over, we can request the results again.
    yield put(fetchActionResults(actionId, filters));
  }
}
