import React, {useRef, useEffect} from 'react';
import Highcharts from 'react-highcharts';

function Chart({config}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const handlePrintChange = event => {
      const chart = chartRef.current.chart;
      if (event.type === 'beforeprint') {
        chart.container.dataset.width = chart.container.style.width;
        chart.container.dataset.height = chart.container.style.height;
        chart.container.style.width = '';
        chart.container.style.height = '';
        chart.reflow();
      } else {
        chart.container.style.width = chart.container.dataset.width;
        chart.container.style.height = chart.container.dataset.height;
      }
    };

    window.addEventListener('beforeprint', handlePrintChange);
    window.addEventListener('afterprint', handlePrintChange);

    return () => {
      window.removeEventListener('beforeprint', handlePrintChange);
      window.removeEventListener('afterprint', handlePrintChange);
    };
  }, [chartRef]);

  return <Highcharts ref={chartRef} config={config} />;
}

export default Chart;
