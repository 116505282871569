import React from 'react';
import {API} from 'config';

export default class Iframe extends React.Component {
  render() {
    const {result} = this.props;
    if (!result) return null;

    const {height, width, proxy} = this.props.config;

    let url = result.originalUrl;
    if (proxy) {
      url = API.ROOT + 'proxy?url=' + url;
    }

    return <iframe src={url} width={width} height={height} />;
  }
}
