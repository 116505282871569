import {List} from 'immutable';
import {basePath} from 'modules/auth/authUtils';

export function coursesPath() {
  return basePath(...arguments) + '/academy';
}

export function coursePath({courseId}) {
  return coursesPath(...arguments) + '/' + courseId;
}

export function courseLessonPath({lessonId}) {
  return coursePath(...arguments) + '/' + lessonId;
}

export function getCourseLesson(courses, lessonId) {
  return courses.reduce((courseResult, course) => {
    if (courseResult !== null) return courseResult;

    const lesson = course.get('stages', List()).reduce((lessonResult, stage) => {
      if (lessonResult !== null) return lessonResult;

      const stageLesson = stage
        .get('lessons')
        .find(lesson => lesson.get('lessonId').toString() === lessonId.toString());

      return stageLesson ? stageLesson : null;
    }, null);

    return lesson ? lesson : null;
  }, null);
}

export function coursesEndpoint() {
  return 'courses';
}

export function courseEndpoint({courseId}) {
  return coursesEndpoint() + '/' + courseId;
}

export function courseEnrollEndpoint({courseId}) {
  return courseEndpoint({courseId}) + '/follow';
}

export function courseUnenrollEndpoint({courseId}) {
  return courseEndpoint({courseId}) + '/unfollow';
}

export function lessonEndpoint({lessonId}) {
  return 'lessons/' + lessonId;
}

export function lessonStateEndpoint() {
  return lessonEndpoint(...arguments) + '/state';
}

export function getFirstStartedCourse(courses) {
  const [course] = Object.values(courses).filter(
    ({started, percentComplete}) => started && percentComplete !== 100
  );
  return course;
}
