import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import styles from './keyPoints.scss';
import Icon from 'components/font-awesome';
import Flex from 'components/layout/flex';
import Link from 'modules/location/link';
import {testPath} from 'modules/tests/testUtils';
import {actionPath} from 'modules/actions/actionUtils';
import {reportSelector} from 'modules/reports/reportSelectors';
import {magicallyInterpolate} from 'utils/interpolationUtils';
import {createStructuredSelector} from 'reselect';

const GOOD = 'g';
const WARNING = 'w';
const BAD = 'b';
const INFO = 'i';
const KEY_POINT_TYPES = {
  [BAD]: {icon: 'times-circle', class: 'bad', sortOrder: 1},
  [WARNING]: {icon: 'exclamation-circle', class: 'warning', sortOrder: 2},
  [GOOD]: {icon: 'check-circle', class: 'good', sortOrder: 3},
  [INFO]: {icon: 'info-circle', class: 'info', sortOrder: 4}
};

export class KeyPoints extends React.Component {
  static defaultProps = {
    result: []
  };

  renderKeyPoint = keyPoint => {
    const {params} = this.props;
    const iconClass = cx(styles.icon, styles[KEY_POINT_TYPES[keyPoint.type].class + 'Icon'], {
      [styles.iconSmall]: true // For now, experimenting with small always enabled
    });

    const headingClass = cx(styles[KEY_POINT_TYPES[keyPoint.type].class + 'Heading']);
    const defaultTestId = this.props.report.get('defaultTestId');

    const linkBuilder = keyPoint.actionId ? actionPath : testPath;
    const linkParams = keyPoint.actionId
      ? {
          ...params,
          actionId: keyPoint.actionId,
          testId: params.testId ? params.testId : defaultTestId
        }
      : params;

    return (
      <Flex
        container
        alignTop
        key={keyPoint.testId + ' ' + keyPoint.keyPointId}
        className={styles.keyPoint}
      >
        <Flex grow={0}>
          <Icon name={KEY_POINT_TYPES[keyPoint.type].icon} className={iconClass} />
        </Flex>
        <Flex grow={1}>
          <h3 className={headingClass}>
            <Link builder={linkBuilder} {...linkParams}>
              {magicallyInterpolate(keyPoint, false)}
            </Link>
          </h3>
          <p>
            {magicallyInterpolate(
              {
                ...keyPoint,
                singular: keyPoint.descriptionSingular,
                plural: keyPoint.descriptionPlural
              },
              false
            )}
          </p>
        </Flex>
      </Flex>
    );
  };

  render() {
    let {result, config} = this.props;

    const maxKeyPoints = config.max || 6;
    const badFirst = config.badFirst || false;

    if (result && !result.length) {
      return null;
    }

    result = result.sort((a, b) => {
      let diff = KEY_POINT_TYPES[a.type].sortOrder - KEY_POINT_TYPES[b.type].sortOrder;
      return diff ? diff : b.importance - a.importance;
    });

    if (badFirst) {
      const splitAt = Math.ceil(Math.min(result.length, maxKeyPoints * 2) / 2);
      const firstCol = result.slice(0, splitAt);
      const secondCol = result.slice(splitAt, splitAt * 2);

      return (
        <Flex container alignTop wrap="wrap">
          <Flex grow={1} basis={220} className={styles.column}>
            {firstCol.map(this.renderKeyPoint)}
          </Flex>
          <Flex grow={1} basis={220} className={styles.column}>
            {secondCol.map(this.renderKeyPoint)}
          </Flex>
        </Flex>
      );
    }

    let positives = result
      .filter(keyPoint => [GOOD, INFO].includes(keyPoint.type))
      .slice(0, maxKeyPoints);
    let negatives = result
      .filter(keyPoint => [WARNING, BAD].includes(keyPoint.type))
      .slice(0, maxKeyPoints);

    if (!negatives.length) {
      negatives = positives;
      positives = result
        .filter(keyPoint => [GOOD, INFO].includes(keyPoint.type))
        .slice(maxKeyPoints, maxKeyPoints * 2);
    }
    if (!positives.length) {
      positives = result
        .filter(keyPoint => [WARNING, BAD].includes(keyPoint.type))
        .slice(maxKeyPoints, maxKeyPoints * 2);
    }

    return (
      <Flex container alignTop wrap="wrap">
        <Flex grow={1} basis={220} className={styles.column}>
          {negatives.map(this.renderKeyPoint)}
        </Flex>
        <Flex grow={1} basis={220} className={styles.column}>
          {positives.map(this.renderKeyPoint)}
        </Flex>
      </Flex>
    );
  }
}

export default connect(createStructuredSelector({report: reportSelector}))(KeyPoints);
