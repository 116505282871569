import React from 'react';
import Button from 'components/button';
import {useDispatch} from 'hooks/redux';
import {showConfirmModal} from 'modules/modal';
import {unassignTags} from 'modules/tags/actions';

export default function UnassignEntityTagsButton({
  entityType,
  entityId,
  tagIds,
  onComplete,
  children,
  ...confirmationProps
}) {
  const dispatch = useDispatch();

  return (
    <Button
      table
      cta
      icon="times"
      onClick={() => {
        dispatch(
          showConfirmModal({
            ...confirmationProps,
            callback: didConfirm => {
              if (didConfirm) {
                dispatch(unassignTags(entityType, entityId, tagIds));
              }
              onComplete && onComplete(didConfirm);
            }
          })
        );
      }}
    >
      {children}
    </Button>
  );
}
