import {trackEvent} from 'modules/tracking';
import trackWithFacebook from './trackWithFacebook';

// This module handles all analytics for Chell
// - It batches up analytics and posts them to Wheatley
// - It posts to intercom
// - Potentially posts to FB ads

// It inspects every action triggered by redux and if it has
// an __analytics key, we track it.
export const ANALYTICS_KEY = '__analytics';

export {default as events} from './events';

// Batches up events in a format that wheatley understands.
const initialState = [];
export default function analyticsReducer(state = initialState, action) {
  // Clear state after tracked
  if (action.type === ANALYTICS_TRACKED) {
    return initialState;
  }

  // Pull event from action (if it's set)
  const event = action[ANALYTICS_KEY];

  // TODO: seems like a bad place to mutate (also, we can't guarantee reducer order)
  // This may need to be middleware.
  delete action[ANALYTICS_KEY];

  if (!event) return state;

  return [...state, event];
}

// Creates an object that can be merged into an action to add analytics
// Note, this doesn't have a type so isn't *really* an action.
// Usage is something like
// function someAction() {
//    return {
//      type: 'SOME_ACTION',
//      ...analytics('SomeEvent', {userId: 4})
//    }
// }
export function analytics(eventName, props = {}) {
  if (!eventName) throw new Error('Analytics called without eventName');

  // TODO: remove once tracking module is removed.
  // By calling this, we automatically log analytics
  // to intercom as well.
  // TODO: Intercom has a limit of 120 custom events.
  // We should look into adding a whitelist of events
  // we care about sending to Intercom
  trackEvent(eventName, props);

  trackWithFacebook(eventName, props);

  return {
    [ANALYTICS_KEY]: {
      event: eventName,
      props
    }
  };
}

// Triggered after analytics successfully sent to backend
export const ANALYTICS_TRACKED = 'analytics:ANALYTICS_TRACKED';
export function analyticsTracked() {
  return {
    type: ANALYTICS_TRACKED
  };
}

export const TRIGGER_ANALYTICS = 'analytics:TRIGGER_ANALYTICS';
export function triggerAnalytics(event) {
  return {
    type: TRIGGER_ANALYTICS,
    ...analytics(event)
  };
}
