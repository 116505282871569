import {createSelector} from 'reselect';
import {currentAccountTypeSearchEngineSelector} from 'modules/accountTypes/accountTypeSelectors';

const configOptionsStateSelector = state => state.configOptions;

export const configOptionsDataSelector = createSelector(
  configOptionsStateSelector,
  state => state.get('data')
);

export const configOptionsSucessMessageSelector = createSelector(
  configOptionsStateSelector,
  state => {
    return state.get('successMessage');
  }
);

export const configOptionsFormSelector = createSelector(
  configOptionsDataSelector,
  configOptionsSucessMessageSelector,
  (configData, successMessage) => ({configData, successMessage})
);

const configPropSelector = (state, props) => props.config;

export const configOptionsInputSelector = createSelector(
  configPropSelector,
  currentAccountTypeSearchEngineSelector,
  (componentConfig, seLimitConfig) => {
    const {
      // remove <LayoutComponent/> props
      componentId,
      type,
      // remove <Config> props
      name,
      label,
      'form-type': formType,
      'global-name': globalName,
      // we are left with form validators
      ...validators
    } = componentConfig;

    const formattedValidators = Object.keys(validators).reduce((obj, validatorName) => {
      const value = validators[validatorName];
      if (value === 'true') {
        obj[validatorName] = true;
      } else if (value === 'false') {
        obj[validatorName] = false;
      } else {
        obj[validatorName] = value;
      }
      return obj;
    }, {});
    return {
      label,
      formType,
      fieldName: name || globalName,
      validators: formattedValidators,
      ...seLimitConfig
    };
  }
);
