import {takeLatest, put} from 'redux-saga/effects';
import {ADD_FORCED_PAGE} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {forcedPagesEndpoint, forcedPagesSettingsPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location';
import {showError, showSuccess} from 'utils/saga/alerts';

export default function* fetchForcedPagesSaga() {
  yield takeLatest(ADD_FORCED_PAGE, addForcedPage);
}

function* addForcedPageProcessResult() {
  yield put(push(forcedPagesSettingsPath, {}, ['reportId']));
  yield showSuccess('Forced page added, re-run your report to see any changes');
}

function* addForcedPage({reportId, url}) {
  const postData = {
    url
  };

  yield processRequest(
    'POST',
    forcedPagesEndpoint({reportId}),
    {
      *success(response = {}) {
        const {url} = response;
        if (url) {
          yield addForcedPageProcessResult(reportId);
        }
      },
      *error({message}) {
        yield showError(message);
      }
    },
    postData
  );
}
