import {createSelector} from 'reselect';

const helpDomainSelector = state => state.help;

export const helpSidebarSelector = createSelector(
  helpDomainSelector,
  helpState => {
    return {
      isOpen: helpState.get('isOpen'),
      helpUrl: helpState.get('helpUrl')
    };
  }
);
