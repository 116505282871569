import React from 'react';
import cx from 'classnames';
import textStyles from 'components/input/input.scss';
import styles from './textareaInput.scss';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class KeywordsInput extends React.Component {
  static defaultProps = {
    value: ''
  };

  render() {
    const {value, className, name, ...props} = this.props;
    const classes = cx(textStyles.input, styles.textareaInput, className);

    return (
      <textarea {...filterInvalidDOMProps(props)} className={classes} name={name} value={value} />
    );
  }
}
