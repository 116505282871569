import Dropdown, {DropdownOption} from 'components/dropdown';
import CanBuyGandalf from 'components/gandalf/canBuyGandalf';
import UserImage from 'components/user/userImage';
import {ENVIRONMENT} from 'config';
import {roadmapUrl, supportUrl} from 'constants/externalUrls';
import _ from 'lodash';
import {isAccountDisabledSelector} from 'modules/accounts/accountDisabledSelector';
import {connectContext} from 'modules/app/appUtils';
import {accountAuditLogPath} from 'modules/auditLog/utils';
import {logout} from 'modules/auth';
import {accountHelpUrlSelector, usingServicesSelector} from 'modules/auth/authSelectors';
import {ACCOUNT_OWNER, SITE_CREATOR, isSuperUser, shallPass} from 'modules/auth/gandalf';
import {accountDecisionsPath} from 'modules/decisions/decisionUtils';
import {reportLogPath} from 'modules/logs/logUtils';
import {reportPath} from 'modules/reports/reportUtils';
import {
  accountSettingsPath,
  paymentSettingsPath,
  servicesSettingsPath,
  subscriptionSettingsPath,
  userSettingsPath
} from 'modules/settings/settingsUtils';
import {showSpotlight} from 'modules/spotlight';
import {trackEvent} from 'modules/tracking';
import {usersPath} from 'modules/users/userUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {createStructuredSelector} from 'reselect';

export class SettingsDropdown extends React.Component {
  logout = () => {
    trackEvent('Logged out');
    this.props.logout();
  };

  handleClickSpotlight = () => {
    this.props.showSpotlight();
  };

  handleClickLoadInTestChamber = () => {
    const {
      params: {accountId}
    } = this.props;

    const tcUrl =
      ENVIRONMENT === 'local'
        ? 'http://testchamber.marvel.test'
        : 'https://testchamber.insites.com:8443';

    const reportId = this.props.params.reportId || this.props.reportId;
    if (reportId) {
      window.open(tcUrl + '/accounts/' + accountId + '/reports/' + reportId, '__blank');
      return;
    }

    window.open(tcUrl + '/accounts/' + accountId + '/reports', '__blank');
  };

  renderAccountSection() {
    const {user, params, accountOptions} = this.props;
    const {tablet} = this.props.screenSizes;

    if (this.props.isAccountDisabled) {
      const items = [
        <DropdownOption href={paymentSettingsPath(params)} key="Invoices">
          Invoices
        </DropdownOption>
      ];

      if (!this.props.usingServices) {
        items.push(
          <CanBuyGandalf key="subscriptions">
            <DropdownOption href={subscriptionSettingsPath(params)}>Subscriptions</DropdownOption>
          </CanBuyGandalf>
        );
      }

      return items;
    }

    // everybody has these options
    let dropdownOptions = [
      !tablet && (
        <DropdownOption href={userSettingsPath(params)} key="settings">
          My settings
        </DropdownOption>
      )
    ].filter(Boolean);

    if (isSuperUser(user)) {
      dropdownOptions = [
        <DropdownOption onClick={this.handleClickSpotlight} key="spotlightSearch">
          Spotlight search
        </DropdownOption>,
        <DropdownOption onClick={this.handleClickLoadInTestChamber} key="oitc">
          Open in Test Chamber
        </DropdownOption>,
        <DropdownOption divider key="spotlightDivider" />,
        ...dropdownOptions
      ];
    }

    if (shallPass(user, ACCOUNT_OWNER) && !tablet) {
      dropdownOptions = [
        ...dropdownOptions,

        <DropdownOption href={accountSettingsPath(params)} key="accountSettings">
          Account settings
        </DropdownOption>,
        <DropdownOption divider key="settingsDivider" />
      ];

      if (this.props.usingServices) {
        dropdownOptions = [
          ...dropdownOptions,
          <DropdownOption href={servicesSettingsPath(params)} key="Services">
            Services
          </DropdownOption>
        ];
      }

      dropdownOptions = [
        ...dropdownOptions,

        <DropdownOption href={paymentSettingsPath(params)} key="Invoices">
          Invoices
        </DropdownOption>,
        !this.props.usingServices && (
          <CanBuyGandalf key="subscriptions">
            <DropdownOption href={subscriptionSettingsPath(params)}>Subscriptions</DropdownOption>
          </CanBuyGandalf>
        ),
        <DropdownOption href={accountDecisionsPath(params)} key="accountDecisions">
          Decisions
        </DropdownOption>,
        // @ TODO integrate at a later date
        // <DropdownOption href={accountAuditLogPath(params)} key="accountDecisions">
        //   Audit log
        // </DropdownOption>,
        <DropdownOption href={usersPath(params)} key="users">
          Users
        </DropdownOption>,
        <DropdownOption href={reportLogPath(params)} key="usage">
          Usage
        </DropdownOption>
      ];

      if (accountOptions && accountOptions.accountReportId) {
        dropdownOptions = [
          ...dropdownOptions,
          <DropdownOption
            href={reportPath({
              ...params,
              reportId: accountOptions.accountReportId.value
            })}
            key="accountDashboard"
          >
            Account dashboard
          </DropdownOption>
        ];
      }
    } else if (shallPass(user, SITE_CREATOR) && !tablet) {
      dropdownOptions = [
        ...dropdownOptions,
        <DropdownOption href={usersPath(params)} key="users">
          Users
        </DropdownOption>
      ];
    }

    return dropdownOptions.filter(Boolean);
  }

  render() {
    const {user, className, helpUrl, screenSizes} = this.props;
    if (!user) return null;

    // needs a div wrapping it so that the click area isn't
    // just on the icon
    const userIcon = (
      <div>
        <UserImage user={user} size={36} className="userImg" />
      </div>
    );

    return (
      <Dropdown
        id="settingDropdown"
        className={className}
        button={userIcon}
        nodeAnchor="bottom right"
        menuAnchor="bottom left"
        offsetLeft={0}
        offsetTop={screenSizes.largeMobile ? -3 : 56}
      >
        {this.renderAccountSection()}
        <DropdownOption divider key="helpDivider" />
        <DropdownOption key="help" href={helpUrl || supportUrl} external>
          Help
        </DropdownOption>
        <DropdownOption key="roadmap" href={roadmapUrl} external>
          Product roadmap
        </DropdownOption>
        <DropdownOption divider key="logoutDivider" />
        <DropdownOption id="logoutButton" onClick={this.logout}>
          Log out
        </DropdownOption>
      </Dropdown>
    );
  }
}

export default compose(
  connect(
    createStructuredSelector({
      isAccountDisabled: isAccountDisabledSelector,
      usingServices: usingServicesSelector,
      helpUrl: accountHelpUrlSelector
    }),
    {
      logout,
      showSpotlight
    }
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(SettingsDropdown);
