import React from 'react';
import {call, put, takeLatest} from 'redux-saga/effects';
import confirm from 'utils/saga/confirm';
import {deleteReport, requestRemoveReportFromFolder} from 'modules/reports';
import {CONFIRM_DELETE_REPORT} from 'modules/reports/reportConstants';
import {requestDeleteEndpoint} from 'modules/reports/reportUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {showError} from 'utils/saga/alerts';

export default function* deleteReportSaga() {
  yield takeLatest(CONFIRM_DELETE_REPORT, confirmDeleteReport);
}

const confirmationModals = {
  canDelete: {
    id: 'confirm-delete-report',
    title: 'Delete website',
    content: 'Are you sure you want to delete this website? All data will be lost.',
    submitId: 'submit-confirm-delete-report',
    submitLabel: 'Delete website'
  },
  canRemove: {
    id: 'confirm-delete-report',
    title: 'Remove website',
    content:
      'Are you sure you want to remove this website from this folder? The website will not be deleted.',
    submitId: 'submit-confirm-delete-report',
    submitLabel: 'Remove website'
  },
  canDeleteOrRemove: {
    id: 'confirm-delete-report',
    title: 'Delete website',
    content:
      'This website exists in at least one other folder. Do you want to completely delete the website, or just remove it from this folder?',
    submitId: 'submit-confirm-delete-report',
    submitLabel: 'Delete website',
    cancelLabel: 'Remove from this folder'
  }
};

const cannotDelete = 'You cannot delete this website';

function* confirmDeleteReport({reportId, folderId, redirectAfter}) {
  const {canDeleteReport, canRemoveFromFolder} = yield call(requestDelete, reportId);

  let shouldDelete = false;
  let shouldRemoveFromFolder = false;

  switch (true) {
    case canDeleteReport && canRemoveFromFolder && folderId:
      if (yield confirm(confirmationModals.canDeleteOrRemove)) {
        shouldDelete = true;
      } else {
        shouldRemoveFromFolder = true;
      }
      break;

    case canDeleteReport:
      if (yield confirm(confirmationModals.canDelete)) {
        shouldDelete = true;
      }
      break;

    case canRemoveFromFolder && folderId:
      if (yield confirm(confirmationModals.canRemove)) {
        shouldRemoveFromFolder = true;
      }
      break;

    default:
      yield showError(cannotDelete);
  }

  if (shouldDelete) {
    yield put(deleteReport(reportId, redirectAfter));
  }

  if (shouldRemoveFromFolder) {
    yield put(requestRemoveReportFromFolder(reportId, folderId));
  }
}

// this doesn't delete the report, it asks if a delete is possible
function* requestDelete(reportId) {
  const url = requestDeleteEndpoint({reportId});

  return yield processRequest('GET', url, {
    success: function*(data) {
      return data;
    },

    error: function*(err) {
      return {};
    },

    errorMessage: 'Something went wrong'
  });
}
