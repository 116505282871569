import {put} from 'redux-saga/effects';
import {deleteDashboard} from '../actions';
import {dashboardEndpoint} from '../utils';
import {DELETE_DASHBOARD} from '../constants';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';

export default generateDeleteSaga('dashboard', DELETE_DASHBOARD, dashboardEndpoint, {
  success: function*({dashboardId}) {
    yield put(deleteDashboard(dashboardId));
  }
});
