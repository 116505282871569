import {put, select, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';

import {VIEW_PAYMENTS_PAGE} from 'modules/settings';
import {paymentsEndpoint} from '../paymentUtils';
import {loadingPayments, receivePayments, paymentRequestFailed} from '../payments';
import {hasPaymentsSelector, paymentsLoadingSelector} from '../paymentSelectors';

export default function* paymentsSagas() {
  yield takeEvery(VIEW_PAYMENTS_PAGE, fetchPaymentsIfRequired);
}

function* fetchPaymentsIfRequired() {
  const hasLoadedPayments = yield select(hasPaymentsSelector);
  const isLoading = yield select(paymentsLoadingSelector);

  if (!hasLoadedPayments && !isLoading) {
    yield put(loadingPayments());

    yield processRequest('GET', paymentsEndpoint(), {
      successAction: receivePayments,
      errorAction: paymentRequestFailed
    });
  }
}
