import React from 'react';
import {CSSTransitionGroup} from 'react-transition-group';
import styles from './fade.scss';

export default function Fade({children, show}) {
  return (
    <CSSTransitionGroup
      component="div"
      transitionName={styles}
      transitionEnterTimeout={600}
      transitionLeaveTimeout={600}
    >
      {show && children}
    </CSSTransitionGroup>
  );
}
