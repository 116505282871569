import {viewPrintTestsPage} from 'modules/reports';
import {getQueryTestIds} from 'modules/tests/testUtils';
import React from 'react';
import {connect} from 'react-redux';
import Test from 'modules/tests/components/test';
import {printTestsSelector} from 'modules/tests/testSelectors';
import StaggeredRender from 'components/wrappers/staggeredRender';
import {Modal, ModalContent} from 'modules/modal';
import Spinner from 'components/spinner';
import Progress from 'components/progress';
import {parseQuery} from 'utils/urlUtils';

export class PrintTestsPage extends React.Component {
  state = {
    ready: false,
    progress: 0
  };

  componentWillMount() {
    const {
      viewPrintTestsPage,
      location: {search},
      match: {params}
    } = this.props;
    const query = parseQuery(search);

    viewPrintTestsPage(params.reportId, getQueryTestIds(query));
  }

  handleAllRendered = () => {
    this.setState({ready: true}, () => {
      // notify insitesReadyHook
      this.markReady(true);

      setTimeout(function() {
        window.print();
      }, 200);
    });
  };

  componentDidMount() {
    this.promise = new Promise(resolve => (this.markReady = resolve));

    // gutenberg uses this so it knows when to print
    window.insitesReadyHook = () => this.promise;

    var div = document.getElementById('chellroot');

    // We NEED to set a width on the container, otherwise the graphs have absolutely
    // no chance of rendering properly. Let me explain. The graphs are rendered on
    // the normal page (generally between 1000px and 2000px) but some other graphs
    // in stats are smaller. When we print, the graphs stay at the size they were
    // when first rendered. We can send them a "resize" signal to fit their parent,
    // but their parent will be ~1500px because the graph was around ~1500px when
    // it was first rendered. Therefore, the only solutions are to set a max width,
    // which will set the size of all the parents. This way all the parents that each
    // graph is in will be the desired size. Then we just called `reflow` on each
    // graph and BOOM. We remove this style afterwards.

    // TODO this makes the page look weird. Add a hidden page with the desired width,
    // and set it to display on print only

    div.style.width = '210mm';
    div.style.margin = '0 auto';
  }

  componentWillUnmount() {
    window.insitesReadyHook = null;

    var div = document.getElementById('chellroot');

    div.style.width = '';
    div.style.margin = '';
  }

  render() {
    const {testIds, className, ...props} = this.props;
    return (
      <div className={className}>
        {!this.state.ready && (
          <Modal dismissable={false}>
            <ModalContent title="Preparing to print">
              <Spinner marginBottom={30} />
              <Progress
                // we plus 1 because when we actually reach the end, the final thing will be rendered
                value={this.state.progress + 1}
                denominator={testIds.length}
              />
            </ModalContent>
          </Modal>
        )}
        <StaggeredRender
          delay={400}
          onProgress={progress => this.setState({progress})}
          onAllRendered={this.handleAllRendered}
        >
          {testIds.map(testId => (
            <Test key={testId} testId={testId} disablePusher params={{tab: null}} />
          ))}
        </StaggeredRender>
      </div>
    );
  }
}

export default connect(
  printTestsSelector,
  {viewPrintTestsPage}
)(PrintTestsPage);
