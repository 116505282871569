import React from 'react';
import {connect} from 'react-redux';
import styles from './actionHeader.scss';
import {Link} from 'react-router-dom';
import ActionPriority from 'modules/actions/components/actionPriority';
import ActionDescription from 'modules/actions/components/actionDescription';
import ActionProgress from 'modules/actions/components/actionProgress';
import {actionHeaderSelector} from 'modules/actions/actionSelectors';
import Lesson from 'components/lesson';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Responsibility from 'components/responsibility';
import {policyPath} from 'modules/policies/policyUtils';
import Control from 'components/control';
import IgnoreActionButton from './ignoreActionButton';
import Gandalf from 'components/gandalf/gandalf';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';

export class ActionHeader extends React.Component {
  setActionPriority = priority => {
    const {reportId, action, updateAction} = this.props;
    const newAction = {...action, priority};
    updateAction(reportId, newAction);
  };

  // @todo Make this a separate component
  renderResponsibility(responsibility) {}

  render() {
    const {accountId, reportId, testId, action, layout} = this.props;

    const {
      name,
      description,
      longDescription,
      actionId,
      state,
      difficulty,
      responsibility,
      lesson,
      priority,
      progressDone,
      progressTotal,
      subName
    } = action;

    return (
      <div className={styles.ActionHeader}>
        <div className={styles.headingWrapper}>
          <div className={styles.headingLeft}>
            <h1 className={styles.heading}>
              <span className={styles.priority}>
                <ActionPriority
                  value={priority}
                  actionState={state}
                  actionId={actionId.toString()}
                  reportId={reportId}
                  onChange={newPriority => this.setActionPriority(newPriority)}
                  hideOnChangeIcon={true}
                />
              </span>
              <span className={styles.name}>
                {name}
                {subName && <span className={styles.subName}>{subName}</span>}
              </span>
            </h1>
          </div>
          <div className={styles.headingRight}>
            <Flex container>
              {!!action.policyId && (
                <Gandalf shallPass={ACCOUNT_OWNER}>
                  <Flex right={10}>
                    <Link to={policyPath({accountId, reportId, testId, policyId: action.policyId})}>
                      <Button icon="pencil" wire square small>
                        Edit policy
                      </Button>
                    </Link>
                  </Flex>
                </Gandalf>
              )}
              {!!action.policyId && (
                <Gandalf shallPass={ACCOUNT_OWNER}>
                  <Flex right={10}>
                    <Control
                      wire
                      small
                      icon="times"
                      label="Delete policy"
                      command={{action: 'delete-policy', policyId: action.policyId}}
                    />
                  </Flex>
                </Gandalf>
              )}
              <Flex>
                <IgnoreActionButton reportId={reportId} actionId={actionId} state={state} />
              </Flex>
            </Flex>
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.description}>
                <ActionDescription
                  description={description}
                  longDescription={longDescription}
                  showAll={!layout}
                />
              </div>
            </div>
            <div className={styles.right}>{lesson && <Lesson {...lesson} />}</div>
          </div>
          <div className={styles.meta}>
            <div className={styles.metaPoint}>
              <h3>Responsibility:</h3>
              <div>
                <Responsibility value={responsibility} />
              </div>
            </div>
            <div className={styles.metaPoint}>
              <h3>Progress:</h3>
              <ActionProgress done={progressDone} total={progressTotal} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(actionHeaderSelector)(ActionHeader);
