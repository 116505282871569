exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38hxrksLLhyicOzv1tQyON{background:#fff;color:#533be2;border:1px solid #ddd;border-radius:4px;padding:10px;margin-bottom:10px}.s60ZD5nZigKIheQjuNGob{font-weight:bold}.EsdjGvB7TXxsNSP4Bgy9z .s60ZD5nZigKIheQjuNGob{display:none}.LOg7kTV_t4cx8RwQrOmnl{display:inline-block;background:#533be2;color:#fff;padding:2px 6px;border-radius:2px;font-size:80%}", ""]);

// exports
exports.locals = {
	"reasonBlock": "_38hxrksLLhyicOzv1tQyON",
	"label": "s60ZD5nZigKIheQjuNGob",
	"reasonInline": "EsdjGvB7TXxsNSP4Bgy9z",
	"date": "LOg7kTV_t4cx8RwQrOmnl"
};