import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PageScreenshot from 'components/layoutComponents/pages/pageScreenshot';

class Screenshot extends React.Component {
  render() {
    const {data, record, options, className} = this.props;
    const {dynamic} = options;
    const {device} = record;

    const classes = cx(className, 'screenshot', {
      screenshotDynamic: dynamic
    });

    // "#" is special state meaning "impossible".
    // Ideally we might display something different for impossible images in future, for
    // now just stop them from appearing as broken.
    if (!data || data === '#') return null;

    return <PageScreenshot src={data} className={classes} quality={dynamic} />;
    // expandable={!dynamic}
  }
}

Screenshot.propTypes = {};

export default Screenshot;
