import React from 'react';
import LineChart from 'components/graphs/lineChart';
import _ from 'lodash';
import {GraphWrapper} from '../shared/graphs';

export default class LineChartComponent extends React.Component {
  render() {
    const {result, config} = this.props;
    const {
      height,
      label,
      invertY,
      yaxis,
      max,
      min,
      showPercentSign,
      palette,
      decimals,
      hasLegend,
      sizeclass,
      ylabelformat,
      line
    } = config;

    if (!result || _.isEmpty(result)) {
      return null;
    }

    const additionalProps = {};
    if (typeof height === 'number') {
      additionalProps.height = height;
    }

    if (sizeclass && sizeclass === 'v2menu') {
      additionalProps.height = '150px';
      additionalProps.noBorder = true;
    }

    if (sizeclass && sizeclass === 'v3menu') {
      additionalProps.height = '200px';
      additionalProps.noBorder = true;
    }

    if (sizeclass && sizeclass === 'v4menu') {
      additionalProps.height = '150px';
      additionalProps.noBorder = true;
    }

    if (sizeclass && sizeclass === 'v5menu') {
      additionalProps.height = '280px';
    }

    additionalProps.noBorder = true;

    return (
      <GraphWrapper {...additionalProps}>
        <LineChart
          title={label}
          results={result}
          max={max}
          min={min}
          invertY={invertY}
          palette={palette}
          showPercentSign={showPercentSign}
          yaxisLabel={yaxis}
          decimals={decimals}
          hasLegend={hasLegend}
          yLabelFormat={ylabelformat}
          line={line}
          {...additionalProps}
        />
      </GraphWrapper>
    );
  }
}
