import {Map, List} from 'immutable';
import {createSelector} from 'reselect';
import {alphabeticalSorter} from 'utils/immutableUtils';
import {passwordState} from 'modules/passwords/passwordSelectors';
import {createParamSelector, createPropOrParamSelector} from 'modules/location/locationSelectors';
import {toJS} from 'utils/immutableUtils';
import {isSuperUser} from 'modules/auth/gandalf';
import {createPropSelector} from 'utils/selectorUtils';

export const usersStateSelector = state => state.users;
export const usersSelector = state => state.users.get('data');

export const userIdSelector = createSelector(
  createPropOrParamSelector('userId'),
  userId => '' + userId
);
export const tokenSelector = createParamSelector('token');

export const usersOrderedByName = createSelector(
  usersSelector,
  users => users.toList().sort(alphabeticalSorter('name'))
);

// Selects a user based on the current userId param
export const userSelector = createSelector(
  usersSelector,
  userIdSelector,
  (users, userId) => {
    return {user: toJS(users.get(userId) || Map())};
  }
);

export const userResetPasswordSelectors = createSelector(
  userSelector,
  passwordState,
  ({user}, passwordData) => {
    return {
      ...toJS(passwordData),
      user
    };
  }
);

export const inviteUsersModalSelector = createSelector(
  usersStateSelector,
  usersState => ({
    isInvitingUsers: usersState.get('isInvitingUsers', false)
  })
);

export const plainUsersSelector = createSelector(
  usersSelector,
  users => ({
    users: toJS(users.toList())
  })
);

export const userIdPropSelector = createPropOrParamSelector('userId');

export const userOptionsSelector = createSelector(
  usersSelector,
  users => {
    return users
      .toList()
      .sortBy(user => user.get('name'))
      .map(user => ({
        value: user.get('userId'),
        label: user.get('name')
      }));
  }
);

export const usernameMapSelector = createSelector(
  usersSelector,
  users => users.map(user => user.get('name'))
);
