import React from 'react';
import {compose} from 'redux';
import SidebarItem from 'components/sidebar/listSidebar/sidebarItem';
import {startedDraggingFolder, stoppedDraggingFolder, moveFolderToFolder} from 'modules/folders';
import {folderSidebarItemSelector} from 'modules/folders/folderSelectors';
import {connect} from 'react-redux';
import styles from './folderSidebarItem.scss';
import cx from 'classnames';
import Draggable from 'components/dragging/draggable';
import Droppable from 'components/dragging/droppable';
import {REPORT_DRAG_TYPE} from 'modules/reports/reportUtils';
import {FOLDER_DRAG_TYPE, handleSidebarFolderDrop} from 'modules/folders/folderUtils';
import {folderPath} from 'modules/folders/folderUtils';
import {push} from 'modules/location';
import FolderConfigDropdown from './folderConfigDropdown';

export class FolderSidebarItem extends React.Component {
  handleClick = () => {
    const {folderId, push} = this.props;

    push(folderPath, {folderId});
  };

  // this is bound by @DraggableFolder
  handleBeginDrag = () => {
    const {startedDraggingFolder, folderId} = this.props;
    startedDraggingFolder && startedDraggingFolder(folderId);
    return {};
  };

  // this is bound by @DraggableFolder
  handleEndDrag = monitor => {
    if (!monitor.didDrop()) {
      return;
    }

    const {folderId: fromFolder, stoppedDraggingFolder, moveFolderToFolder} = this.props;

    const result = monitor.getDropResult();
    const {folderId: toFolder} = result;

    if (fromFolder == toFolder || toFolder == 0) {
      return;
    }

    moveFolderToFolder({fromFolder, toFolder});
    stoppedDraggingFolder(fromFolder);
  };

  handleDrop = handleSidebarFolderDrop;

  renderAfter() {
    const {folderId, showFolderSettings} = this.props;
    // All websites folder shouldn't have settings
    if (folderId == 0 || folderId == 1) {
      return null;
    }

    return (
      <div className={styles.after}>
        {showFolderSettings && <FolderConfigDropdown folderId={folderId} hideDelete={false} />}
      </div>
    );
  }

  render() {
    // // Hide when being dragged.
    // if (isBeingDragged) return null;

    // In this case, props.children aren't React.Children
    // but the folder.children array.
    const {
      name,
      children,
      isActive,
      isBeingDragged,
      isDraggedOverShallow,
      className,
      folderId
    } = this.props;

    const classes = cx(styles.folderSidebarItem, className, {
      [styles.isBeingDragged]: isBeingDragged,
      [styles.isDraggedOver]: isDraggedOverShallow
    });

    return (
      <SidebarItem
        onClick={this.handleClick}
        label={name}
        active={isActive}
        className={classes}
        after={this.renderAfter()}
        newStyle
      />
    );
  }
}

export default compose(
  connect(
    folderSidebarItemSelector,
    {
      startedDraggingFolder,
      stoppedDraggingFolder,
      moveFolderToFolder,
      push
    }
  ),
  Draggable(FOLDER_DRAG_TYPE, {
    canDrag: ({folderId}) => folderId != 0,
    beginDrag: 'handleBeginDrag',
    endDrag: 'handleEndDrag'
  }),
  Droppable([REPORT_DRAG_TYPE, FOLDER_DRAG_TYPE])
)(FolderSidebarItem);
