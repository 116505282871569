import React from 'react';
import InspectorActionHeading from 'modules/inspector/components/sidebar/inspectorActionHeading';
import InspectorActionPoints from 'modules/inspector/components/sidebar/inspectorActionPoints';
import InspectorActionNextSteps from 'modules/inspector/components/sidebar/inspectorActionNextSteps';

class InspectorActionSidebar extends React.Component {
  render() {
    return (
      <div>
        <InspectorActionHeading />
        <InspectorActionPoints />
        <InspectorActionNextSteps />
      </div>
    );
  }
}

export default InspectorActionSidebar;
