import {List} from 'immutable';
import {createSelector} from 'reselect';
import {alphabeticalSorter} from 'utils/immutableUtils';

export const statsSelector = state => state.stats.sort(alphabeticalSorter('label'));

// Converts stats into [{label, value}, ...] options
export const statOptionsSelector = createSelector(
  statsSelector,
  stats => {
    return (
      stats
        // only use stats with labels
        .filter(stat => stat.get('label') && !/-summary\./.test(stat.get('name')))
        .reduce((memo, stat) => {
          return memo.push({
            label: stat.get('label'),
            value: stat.get('name')
          });
        }, List())
    );
  }
);

export const selectStat = name =>
  createSelector(
    statsSelector,
    stats => stats.find(c => c.get('name') == name)
  );
