import {folderPath, folderEndpoint} from 'modules/folders/folderUtils';

export function folderViewPath({viewId}) {
  return folderPath(...arguments) + `/${viewId}`;
}

export function folderViewsEndpoint() {
  return `${folderEndpoint(...arguments)}/views`;
}

export function reorderViewsEndpoint() {
  return folderViewsEndpoint(...arguments) + '/order';
}

export function folderViewEndpoint({viewId}) {
  return `${folderViewsEndpoint(...arguments)}/${viewId}`;
}

export function folderResultsEndpoint() {
  return `${folderViewEndpoint(...arguments)}/reports`;
}

export function isFolderViewPath(path) {
  return !!path.match(/folders\/\d+\/\d+/);
}
