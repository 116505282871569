import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {getOrdinal} from 'utils/numberUtils';

class Ranking extends React.Component {
  render() {
    const {data} = this.props;

    let appearance = 'good';
    const display = data > 0 ? getOrdinal(data) : 'Unranked';

    if (data == 0) {
      appearance = 'bad';
    } else if (data > 3) {
      appearance = 'warning';
    }

    const classes = ClassNames('label', `appearance-${appearance}`);

    return <span className={classes}>{display}</span>;
  }
}

Ranking.propTypes = {};

export default Ranking;
