import {takeLatest} from 'redux-saga/effects';
import {SEARCH, receiveSearchResults} from '..';
import {searchEndpoint} from '../omnisearchUtils';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* performSearchSaga() {
  yield takeLatest(SEARCH, performSearch);
}

function* performSearch({term}) {
  if (term == '') return;

  yield processRequest(
    'POST',
    searchEndpoint(),
    {
      successAction: receiveSearchResults
    },
    {term}
  );
}
