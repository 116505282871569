import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import countryCodes from 'constants/countryCodes.json';
import SelectInput from './selectInput';

const countryOptions = (() => {
  const list = _.map(countryCodes, (name, code) => {
    return {
      label: name,
      value: code
    };
  });

  return _.orderBy(list, ['label']);
})();

// This is a proxy to selectInput that sets options
// to a list of countries
export default class CountryInput extends React.Component {
  render() {
    return <SelectInput {...this.props} options={countryOptions} />;
  }
}
