import {basePath} from 'modules/auth/authUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {reportEndpoint} from 'modules/reports/reportUtils';

export function decisionsEndpoint() {
  return reportEndpoint(...arguments) + `/decisions`;
}

export function decisionEndpoint({decisionId}) {
  return decisionsEndpoint(...arguments) + `/${decisionId}`;
}

export function accountDecisionsPath() {
  return basePath(...arguments) + '/settings/account/decisions';
}

export function* fetchAccountDecisions(filters) {
  return yield processRequest('GET', 'account-decisions', {}, filters);
}

export function* fetchAccountDecisionSummary() {
  return yield processRequest('GET', 'account-decisions/stats');
}
