import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkboxInput.scss';

export default class CheckboxInput extends React.Component {
  static defaultProps = {
    value: false
  };

  static propTypes = {
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.bool
  };

  handleClick = value => event => {
    const {onChange} = this.props;
    onChange && onChange(value);
  };

  render() {
    const {name, value, description, children} = this.props;
    return (
      <div className={styles.wrapper}>
        <input
          type="checkbox"
          id={name}
          name={name}
          onChange={this.handleClick(!value)}
          checked={value}
        />
        <label htmlFor={name}>
          {description}
          {children}
        </label>
      </div>
    );
  }
}
