import React from 'react';
import Button from 'components/button';
import {connect} from 'react-redux';
import {requestAddFolder} from 'modules/folders';
import Gandalf from 'components/gandalf';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';

export class AddFolderButton extends React.Component {
  static defaultProps = {
    parentId: 0
  };

  handleClick = () => {
    this.props.requestAddFolder(this.props.parentId);
  };

  render() {
    const {parentId, requestAddFolder} = this.props;

    return (
      <Gandalf shallPass={ACCOUNT_OWNER}>
        <Button icon="plus" onClick={this.handleClick}>
          Add folder
        </Button>
      </Gandalf>
    );
  }
}

export default connect(
  null,
  {requestAddFolder}
)(AddFolderButton);
