import React from 'react';
import {connect} from 'react-redux';
import {toggleFlagState} from 'modules/instructions';
import ModalComponent from 'modules/modal/components/modal';
import ModalContent from 'modules/modal/components/modalContent';
import {isDropdownActiveSelector} from 'modules/instructions/instructionSelectors';

export class Modal extends React.Component {
  onCloseModal = () => {
    const {
      setFilterParams,
      config: {id, clearFiltersOnClose}
    } = this.props;

    this.props.toggleFlagState(id, false);

    if (setFilterParams && clearFiltersOnClose && clearFiltersOnClose.length > 0) {
      // reset the filters for what is given.
      const newFilters = clearFiltersOnClose.reduce((carry, item) => {
        carry[item] = null;
        return carry;
      }, {});

      setFilterParams(newFilters);
    }
  };

  render() {
    const {
      children,
      config: {wide},
      isActive
    } = this.props;

    if (!isActive) return null;

    return (
      <ModalComponent wide={wide} onClose={this.onCloseModal}>
        <ModalContent>{children}</ModalContent>
      </ModalComponent>
    );
  }
}

export default connect(
  isDropdownActiveSelector,
  {toggleFlagState}
)(Modal);
