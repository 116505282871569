// Combines all sagas from modules into one file.
// Only loads the files inside the <module>/sagas/*.js Doesn't go any deeper.
import {fork, call, all} from 'redux-saga/effects';

const requireSaga = require.context('./', true, /\.\/\w+\/sagas\/[^\/]*?.js/);

const sagas = requireSaga.keys().reduce((memo, path) => {
  let saga = requireSaga(path);
  if (saga.default) saga = saga.default;

  // Only add if function.
  if (typeof saga === 'function') {
    memo.push(saga);
  }

  return memo;
}, []);

export default function* rootSaga() {
  yield all(sagas.map(saga => forkSafely(saga)));
}

function* forkSafely(saga, ...args) {
  try {
    yield call(saga, ...args);
  } catch (e) {
    if (__DEV__) {
      console.warn(`Exception below thrown in saga "${saga.name}". Restarting.`);
      console.error(e);
    }
    // restart
    yield* forkSafely(...arguments);
  }
}
