import React from 'react';
import sharedStyles from 'modules/auth/components/authWrapperShared.scss';
import styles from './userSignupForm.scss';
import {Form, Field} from 'modules/form/components';
import Button from 'components/button';
import Icon from 'components/font-awesome';
import ErrorMessage from 'modules/form/components/errorMessage';
import OnboardingSubmitButtons from 'modules/onboarding/components/onboardingSubmitButtons';

export default class UserSignupForm extends React.Component {
  state = {
    isValid: false,
    showConfirm: false,
    passwordMatches: true, // hide message initially
    blurConfirm: false
  };

  setForm = ref => {
    this.form = ref;
  };

  handleChange = (key, value, data) => {
    const [isValid, messages] = this.form.isFormValid();

    const [isPasswordValid, message] = this.form.fields.password.validate();
    const passwordMatches = data.password === data.confirmPassword;

    this.setState({
      passwordMatches,
      isValid: isValid && passwordMatches,
      showConfirm: isPasswordValid
    });
  };

  handleSubmit = (data, isValid) => {
    if (isValid && this.state.passwordMatches) {
      this.props.onValidSubmit(data);
    }
  };

  handleBlur = () => {
    if (!this.state.blurConfirm) {
      this.setState({blurConfirm: true});
    }
  };

  render() {
    const {errorMessage, isLoading, ...props} = this.props;
    const {isValid} = this.state;

    return (
      <Form
        id="userSignupForm"
        {...props}
        ref={this.setForm}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        autoComplete="new-password"
        disableValidSubmit
      >
        <Field
          name="contactName"
          label="Your name"
          type="text"
          help="This person will be the account owner"
          className={sharedStyles.textInput}
          placeholder="David Smith"
          required
          autoFocus
        />
        <Field
          name="contactEmail"
          label="Email"
          type="email"
          className={sharedStyles.textInput}
          placeholder="davidsmith@yourcompany.com"
          required
        />
        {errorMessage && (
          <ErrorMessage
            message={errorMessage}
            style={{fontSize: 13, marginTop: -10, marginBottom: 20}}
          />
        )}
        <Field
          name="password"
          label="Password"
          type="passwordStrength"
          autoComplete="new-password"
          className={sharedStyles.textInput}
          required
        />

        {this.state.showConfirm && (
          <Field
            type="password"
            name="confirmPassword"
            label="Confirm password"
            placeholder="Confirm password"
            required
            onBlur={this.handleBlur}
            onChange={this.handleBlur}
            className={sharedStyles.textInput}
            invalid={this.state.blurConfirm && !this.state.isValid}
            validationMessage={
              this.state.blurConfirm && !this.state.passwordMatches
                ? 'Your passwords do not match'
                : null
            }
          />
        )}

        <OnboardingSubmitButtons
          isValid={isValid && this.state.passwordMatches}
          isLoading={isLoading}
        />
      </Form>
    );
  }
}
