import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Spinner from 'components/spinner';
import {searchCampaignSelector} from 'modules/searchCampaigns/selectors';
import {addTheme, requestDeleteTheme, updateTheme} from 'modules/searchCampaigns/actions';
import SettingsPage from 'components/settings/settingsPage';
import SearchCampaignConfig from '../../components/reportSettings/searchCampaignConfig';
import {accountSelector} from 'modules/auth/authSelectors';
import Button from 'components/button';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import {searchCampaignsPath} from 'modules/searchCampaigns/utils';
import SearchCampaignsBreadcrumb from 'modules/searchCampaigns/containers/searchCampaignsBreadcrumb';
import {push} from 'modules/location';

const NEW_THEME = {
  name: '',
  keywords: [{keyword: '', targetRank: 1}],
  engines: [
    {
      engine: 'google',
      options: {
        language: 'en',
        location: '',
        countryCode: '' // default value will be set to account countryCode
      },
      location: '',
      sortIndex: 1
    }
  ]
};

export class ReportSettingsSearchCampaignPage extends React.Component {
  state = {
    localKeywordsUsed: 0
  };

  handleKeywordUsedChanged = localKeywordsUsed => {
    // this is updates the keywords count live while the user is editting the fields
    this.setState({localKeywordsUsed});
  };

  render() {
    const {
      match: {params},
      addTheme,
      updateTheme
    } = this.props;
    const countryCode = this.props.account.get('addressCountry') || 'us';

    // TODO default to user account country
    if (params.campaignId === 'new') {
      return (
        <SettingsPage noBody>
          <SearchCampaignsBreadcrumb
            searchCampaign={this.props.searchCampaign}
            localKeywordsUsed={this.state.localKeywordsUsed}
          >
            <PageHeader title="Add new campaign" goBack={this.props.viewSearchCampaigns} />
            <PageBody>
              <SearchCampaignConfig
                campaign={NEW_THEME}
                onSave={addTheme}
                defaultCountryCode={countryCode}
                onKeywordsUsedChanged={this.handleKeywordUsedChanged}
              />
            </PageBody>
          </SearchCampaignsBreadcrumb>
        </SettingsPage>
      );
    }

    if (!this.props.searchCampaign) {
      return <Spinner />;
    }

    return (
      <React.Fragment>
        <SettingsPage noBody>
          <SearchCampaignsBreadcrumb
            searchCampaign={this.props.searchCampaign}
            localKeywordsUsed={this.state.localKeywordsUsed}
          >
            <PageHeader
              title="Edit campaign"
              goBack={this.props.viewSearchCampaigns}
              inline={[
                <Button
                  key="delete"
                  icon="trash"
                  cta
                  onClick={() => this.props.deleteTheme(params.campaignId)}
                >
                  Delete this campaign
                </Button>
              ]}
            />
            <PageBody>
              <SearchCampaignConfig
                campaign={this.props.searchCampaign.toJS()}
                onSave={updateTheme}
                defaultCountryCode={countryCode}
                onKeywordsUsedChanged={this.handleKeywordUsedChanged}
              />
            </PageBody>
          </SearchCampaignsBreadcrumb>
        </SettingsPage>
      </React.Fragment>
    );
  }
}

const ReportSettingsSearchCampaignPageSelector = createStructuredSelector({
  account: accountSelector,
  searchCampaign: searchCampaignSelector
});

const mapDispatchToProps = (dispatch, props) => ({
  updateTheme: (campaign, onSuccess) => {
    dispatch(updateTheme(props.match.params.reportId, campaign, onSuccess));
  },
  addTheme: (campaign, onSuccess) => {
    dispatch(addTheme(props.match.params.reportId, campaign, onSuccess));
  },
  deleteTheme: campaignId => {
    dispatch(requestDeleteTheme(props.match.params.reportId, campaignId));
  },
  viewSearchCampaigns() {
    dispatch(push(searchCampaignsPath, {}, ['reportId']));
  }
});

export default connect(
  ReportSettingsSearchCampaignPageSelector,
  mapDispatchToProps
)(ReportSettingsSearchCampaignPage);
