import _ from 'lodash';
import React from 'react';
import RelativeDate from 'components/time/relativeDate.js';

class Time extends React.Component {
  render() {
    const {result} = this.props;

    if (!result || _.isNull(result)) {
      return null;
    }

    return <RelativeDate timestamp={result} absolute={true} />;
  }
}

Time.handlesLoading = true;

export default Time;
