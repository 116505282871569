import {Map, List, fromJS} from 'immutable';
import {toJS} from 'utils/immutableUtils';
import {MAKE_DECISION, UNDO_DECISION, RECEIVE_DECISION} from 'modules/decisions/decisions';
import {PERFORM_ISSUE_ACTION} from '../results/results';

const initialState = Map({
  data: Map()
});

function hasAllOfProps(testObject, matchesObject) {
  return Object.keys(matchesObject).reduce(
    (didPreviousMatch, key) => didPreviousMatch && testObject[key] === matchesObject[key],
    true
  );
}

export default function removeStoreReducer(state = initialState, action) {
  switch (action.type) {
    // case RECEIVE_DECISION: {

    //   const {matching} = action.decision;

    //   return state.update('data', results => {

    //     return results.reduce((map, decisionsForResultId, resultId) => {

    //       const newDecisions = decisionsForResultId.filter(
    //         dec => !hasAllOfProps(dec.decision, matching)
    //       );

    //       return map.set(resultId, newDecisions);
    //     }, Map());
    //   });
    // }

    case MAKE_DECISION: {
      // `context` is the table row or record or layout
      // component that the decision happended on.
      const {context} = action;
      const {resultId, key} = context;

      // Check that we have the valid information to perform a removeStore decision
      if (!key || !resultId) {
        return state;
      }

      return appendToDecisions(state, resultId, key, context[key]);
    }

    case UNDO_DECISION: {
      const {context} = action;
      const {resultId, key} = context;

      // Check that we have the valid information to perform a removeStore decision
      if (!key || !resultId) {
        return state;
      }

      // here we are filtering out decisions in the remove store, so *this* undoes live changes
      const newStateForResultId = !state.hasIn(['data', resultId])
        ? state
        : state.updateIn(['data', resultId], results => {
            return results.filter(item => {
              return item.key !== key || item.value !== context[key];
            });
          });

      // next, we need to add a new row to the removeStore, being this undone decision
      return appendToDecisions(newStateForResultId, resultId, key, context[key]);
    }

    case PERFORM_ISSUE_ACTION: {
      const {testId, issueHash, metricName} = action;
      const resultId = testId + '.' + metricName;

      return appendToDecisions(state, resultId, 'issueHash', issueHash);
    }
  }

  return state;
}

function appendToDecisions(state, resultId, key, value) {
  let decisions = toJS(state.get('data'))[resultId] || [];

  // Add the new decision (it will expire 10 seconds from now)
  decisions.push({
    key,
    value,
    expires: Date.now() + 10000
    // decision
  });

  decisions = cleanUpResultRemoveStore(decisions);
  return state.setIn(['data', resultId], decisions);
}

/**
 * Loop through each decision and ensure it has not expired and is not already present (we do not want duplicates)
 * @param {*} decisions
 */
function cleanUpResultRemoveStore(decisions) {
  let keysFound = [];
  return decisions.reduce((carry, item, key) => {
    const uniqueKey = item.key + item.value;
    if (item.expires > Date.now() && keysFound.indexOf(uniqueKey) === -1) {
      carry.push(item);
      keysFound.push(uniqueKey);
    }

    return carry;
  }, []);
}
