import React from 'react';
import styles from './styles.scss';
import User from './user';
import Tooltip from 'components/tooltip';

const AMOUNT_TO_SHOW = 2;

export default class Users extends React.Component {
  renderUsersOutput() {
    const {data} = this.props;

    let showMoreCount = 0;
    let tooltipText = '';

    let users = data.map(userId => (
      <span key={userId} className={styles.usersImageSpacer}>
        <User data={userId} />
      </span>
    ));

    if (users.length > AMOUNT_TO_SHOW) {
      tooltipText = users.slice(AMOUNT_TO_SHOW).map(item => (
        <div style={{margin: '5px 0'}} key={item.key + '_1'}>
          {item}
        </div>
      ));
      users = users.slice(0, AMOUNT_TO_SHOW);
      showMoreCount = data.length - users.length;
    }

    return (
      <span>
        {users.length ? users : <span>No-one</span>}
        {showMoreCount > 0 && (
          <Tooltip className={styles.moreTooltip} text={tooltipText}>
            <span>+{showMoreCount} more</span>
          </Tooltip>
        )}
      </span>
    );
  }

  render() {
    const {users, data} = this.props;

    if (!data) {
      return null;
    }

    const usersOutput = this.renderUsersOutput();
    return <span>{usersOutput}</span>;
  }
}
