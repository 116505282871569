import {put} from 'redux-saga/effects';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {uptimeMonitorContactEndpoint} from '../utils';
import {deleteUptimeContact} from '../actions';
import {CONFIRM_DELETE_UPTIME_CONTACT} from '../constants';

export default generateDeleteSaga(
  'contact',
  CONFIRM_DELETE_UPTIME_CONTACT,
  uptimeMonitorContactEndpoint,
  {
    success: function*({reportId, monitorId, userId}) {
      yield put(deleteUptimeContact(userId, reportId, monitorId));
    }
  },
  'Delete',
  {
    title: 'Are you sure?',
    content: 'Are you sure you wish to remove this user from receiving notifications?'
  }
);
