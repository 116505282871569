import React from 'react';

export default class Checkbox extends React.Component {
  handleClick = el => {
    const {
      setFilterParams,
      config: {filter: filterName}
    } = this.props;

    setFilterParams &&
      setFilterParams({
        [filterName]: el.target.checked === true ? '1' : '0'
      });
  };

  render() {
    const {
      children,
      filter,
      config: {filter: filterName}
    } = this.props;

    return (
      <span>
        <input
          type="checkbox"
          value="1"
          checked={filter[filterName] === '1'}
          onChange={this.handleClick}
        />{' '}
        {children}
      </span>
    );
  }
}
