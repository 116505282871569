import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {SET_ENROLLED_USERS} from 'modules/courses/constants';
import {courseEnrollEndpoint} from 'modules/courses/utils';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {setEnrolledUsersComplete} from '../actions';

export default function* setEnrolledUsersListener() {
  yield takeLatest(SET_ENROLLED_USERS, setEnrolledUsersSaga);
}

function* setEnrolledUsersSaga({courseId, userIds}) {
  const url = courseEnrollEndpoint({courseId});

  if (!Array.isArray(userIds)) return;

  yield processRequest(
    'POST',
    url,
    {
      *success({course, stages, lessons, tutors, students} = {}) {
        // @TODO - make util for this as it is done in the request course saga too
        const newCourse = {...course};
        const newStages = stages.map(stage => {
          stage.lessons = lessons.filter(
            lesson => lesson.stageId.toString() === stage.stageId.toString()
          );
          return stage;
        });

        newCourse.stages = newStages;
        newCourse.tutors = tutors;
        newCourse.students = students;

        yield put(setEnrolledUsersComplete(null, newCourse));
      },
      *error(err) {
        yield put(setEnrolledUsersComplete(err));
      },
      errorMessage: couldNot('save enrolled users')
    },
    {userIds}
  );
}
