import {take, call, put, takeEvery} from 'redux-saga/effects';
import {VIEW_REPORT_LIST} from 'modules/reports/reportConstants';
import {VIEW_FOLDERS_PAGE} from 'modules/folders';
import {FETCH_STATS, receiveStats} from 'modules/stats';
import fetch from 'utils/saga/fetch';
import {processResponse} from 'utils/saga/fetchUtils';

export default function* fetchStatsSaga() {
  yield takeEvery([FETCH_STATS, VIEW_REPORT_LIST, VIEW_FOLDERS_PAGE], fetchStats);
}

function* fetchStats() {
  const res = yield call(fetch, 'stats');
  yield processResponse(res, {successAction: receiveStats});
}
