import React from 'react';
import Select from 'components/select';
import _ from 'lodash';
import styles from './tableOrderBy.scss';

class TableOrderBy extends React.Component {
  renderOptions = options => {
    const results = [];
    _.each(options, (value, key) => {
      results.push({
        label: key,
        value
      });
    });
    return results;
  };

  setFilter = ({value}) => {
    const {config, setFilterParams} = this.props;
    setFilterParams({
      [config.filter]: value
    });
  };

  render() {
    const {result} = this.props;
    return (
      <div className={styles.orderBy}>
        <Select
          className={styles.orderBySelect}
          onChange={this.setFilter}
          options={this.renderOptions(result)}
          searchable={false}
          placeholder="Order table"
          clearable={false}
        />
      </div>
    );
  }
}

TableOrderBy.handlesLoading = true;

export default TableOrderBy;
