import {createPropSelector} from 'utils/selectorUtils';
import {createSelector} from 'reselect';

export const missionDomainSelector = state => state.missions;
export const missionsSelector = state => state.missions.get('missions');

export const missionIdSelector = createPropSelector('missionId');

export const missionSelector = createSelector(
  missionsSelector,
  missionIdSelector,
  (missions, missionId) => missions && missions.get(missionId)
);

export const plainMissionSelector = createSelector(
  missionSelector,
  mission => (mission ? mission.toJS() : {})
);

export const isDeletingMissionSelector = state => state.missions.get('isDeleting');

export const cancelledMissionSelector = createSelector(
  missionDomainSelector,
  missionsDomain => {
    return {
      lastCancelled: missionsDomain.get('lastCancelledMissionId'),
      currentlyDeleting: missionsDomain.get('isDeleting')
    };
  }
);
