import {createSelector} from 'reselect';

const domainSelector = state => state.balances;

export const balancesSelector = createSelector(
  domainSelector,
  balancesState => balancesState.get('data')
);

export const isBalancesLoadingSelector = createSelector(
  domainSelector,
  balancesState => balancesState.get('isRequesting')
);
export const hasBalancesLoadedSelector = createSelector(
  domainSelector,
  balancesState => !!balancesState.get('lastRequested')
);

// https://www.notion.so/silktide/Service-balances-API-fa7ea0b769bc4839b98094474f00a3b0
export const makeBalanceSelector = balanceId =>
  createSelector(
    balancesSelector,
    balances => {
      return balances.get(balanceId);
    }
  );

export const searchEngineKeywordBalanceSelector = makeBalanceSelector('SearchEngineKeywordBalance');
