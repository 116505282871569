// TODO: relocate file to better place

import {createSelector} from 'reselect';
import {userSelector, accountSelector} from 'modules/auth/authSelectors';
import {accountOptionsSelector} from 'modules/accountOptions/accountOptionSelectors';
import {reportSelector, reportMissionsSelector} from 'modules/reports/reportSelectors';
import {locationParamsSelector} from 'modules/location/locationSelectors';
import {isVirtualUser} from 'modules/auth/gandalf';

export const navSelector = createSelector(
  locationParamsSelector,
  userSelector,
  reportSelector,
  accountSelector,
  accountOptionsSelector,
  reportMissionsSelector,
  (params, user, report, account, accountOptions, {missionIds}) => {
    return {
      params,
      missionIds,
      user: user && user.toJS(),
      report: !report.isEmpty() && report.toJS(),
      account: account && account.toJS(),
      accountOptions: accountOptions && accountOptions.toJS(),
      isVirtual: isVirtualUser(user)
    };
  }
);
