import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_LIBRARY_POLICY} from '../constants';
import {setLibraryPolicy, requestLibraryPolicyComplete} from '../actions';

export default function* requestLibraryPolicySaga() {
  yield takeLatest(REQUEST_LIBRARY_POLICY, requestLibraryPolicy);
}

function* requestLibraryPolicy({libraryPolicyId}) {
  try {
    const libraryPolicy = yield processRequest('GET', 'library-policies/' + libraryPolicyId);

    yield put(setLibraryPolicy(libraryPolicy));
    yield put(requestLibraryPolicyComplete());
  } catch (err) {
    yield put(requestLibraryPolicyComplete(err));
  }
}
