import PropTypes from 'prop-types';
import React from 'react';
import styles from './alerts.scss';
import TransitionGroup from 'components/transitionGroup';

class AlertsWrapper extends React.Component {
  static defaultProps = {};

  render() {
    const {children, ...props} = this.props;

    return (
      <TransitionGroup
        {...props}
        transitionAppear
        className={styles.wrapper}
        transitionName={styles}
      >
        {children}
      </TransitionGroup>
    );
  }
}

export default AlertsWrapper;
