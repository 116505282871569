import React from 'react';
import {connect} from 'react-redux';
import {trackEvent} from 'modules/tracking';
import AdvancedConfig from '../../components/reportSettings/advancedConfig';
import {updateWebsite} from 'modules/tests';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';

export class ReportSettingsAdvancedConfigPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed advanced config page', {
      forReport: this.props.match.params.reportId,
      fromFile: 'advancedConfigPage.js'
    });
  }

  render() {
    return <AdvancedConfig {...this.props} name="advancedConfig" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateWebsite}
)(ReportSettingsAdvancedConfigPage);
