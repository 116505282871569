import {takeEvery, put, all, call, select} from 'redux-saga/effects';
import {
  FETCH_ACCOUNT_DECISIONS_PAGE,
  UNDO_DECISION_COMPLETE,
  receiveAccountDecisionPage
} from '../decisions';
import {
  fetchAccountDecisions,
  fetchAccountDecisionSummary,
  accountDecisionsPath
} from '../decisionUtils';
import {locationSelector} from 'modules/location/locationSelectors';

export default function* fetchAccountDecisionsPageListen() {
  yield takeEvery(
    [FETCH_ACCOUNT_DECISIONS_PAGE, UNDO_DECISION_COMPLETE],
    fetchAccountDecisionsPageSaga
  );
}

function* fetchAccountDecisionsPageSaga({type}) {
  const locationData = yield select(locationSelector);

  // if this was triggered via an UNDO && we are not on the account decisions page, ignore it
  if (
    type === UNDO_DECISION_COMPLETE &&
    window.location.pathname !== accountDecisionsPath(locationData.params)
  ) {
    return;
  }

  const [accountDecisions, accountDecisionStats] = yield all([
    call(fetchAccountDecisions),
    call(fetchAccountDecisionSummary)
  ]);

  yield put(receiveAccountDecisionPage(accountDecisions, accountDecisionStats));
}
