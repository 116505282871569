import React from 'react';
import AuthWrapper from 'modules/auth/components/authWrapper';
import LoginPage from 'modules/auth/pages/loginPage';

export default class LoginRoute extends React.Component {
  render() {
    const {
      location: {pathname}
    } = this.props;

    return (
      <AuthWrapper pathname={pathname}>
        <LoginPage />
      </AuthWrapper>
    );
  }
}
