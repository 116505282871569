import {fromJS} from 'immutable';

const initialState = fromJS({
  isOpen: false,
  isSearching: false,
  results: {
    accounts: [],
    users: []
  }
});

export default function spotlightReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SPOTLIGHT:
      return state.set(
        'isOpen',
        typeof action.toggleOn == 'boolean' ? action.toggleOn : !state.get('isOpen')
      );

    case SPOTLIGHT_SEARCH:
      return state.set('isSearching', true);

    case CLEAR_RESULTS:
      return initialState;

    case RECIEVE_RESULTS:
      return state.set('results', fromJS(action.results)).set('isSearching', false);
  }
  return state;
}

export const TOGGLE_SPOTLIGHT = 'spotlight:TOGGLE_SPOTLIGHT';
export function toggleSpotlight(toggleOn) {
  return {type: TOGGLE_SPOTLIGHT, toggleOn};
}

export function showSpotlight() {
  return toggleSpotlight(true);
}

export function hideSpotlight() {
  return toggleSpotlight(false);
}

export const SPOTLIGHT_SEARCH = 'spotlight:SPOTLIGHT_SEARCH';
export function spotlightSearch(searchText) {
  return {type: SPOTLIGHT_SEARCH, searchText};
}

export const RECIEVE_RESULTS = 'spotlight:RECIEVE_SPOTLIGHT_RESULTS';
export function recieveResults(results) {
  return {type: RECIEVE_RESULTS, results};
}

export const CLEAR_RESULTS = 'spotlight:RECIEVCLEAR_RESULTSE_SPOTLIGHT_RESULTS';
export function clearResults() {
  return {type: CLEAR_RESULTS};
}
