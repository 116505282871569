import _ from 'lodash';
import React from 'react';

class String extends React.Component {
  render() {
    const {result} = this.props;

    if (!result || _.isNull(result)) {
      return null;
    }

    return <span>{result}</span>;
  }
}

String.handlesLoading = true;

export default String;
