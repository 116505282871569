import PropTypes from 'prop-types';
import React from 'react';
import {Motion, spring} from 'react-motion';
import {interpolate} from 'utils/interpolationUtils';
import _ from 'lodash';
import {isIE} from 'utils/browserSniff';

class NumberTicker extends React.Component {
  static propTypes = {};

  getInterpolatableContext() {
    return _.pickBy(this.props.context, val => _.isNumber(val));
  }

  // Start off with the context set to 0s
  getDefaultStyle() {
    return _.mapValues(this.getInterpolatableContext(), val => 0);
  }

  getStyle() {
    return _.mapValues(this.getInterpolatableContext(), val => (val === 0 ? val : spring(val)));
  }

  renderInterpolated(context) {
    return <div>{interpolate(this.props.string, context)}</div>;
  }

  render() {
    if (!_.size(this.getInterpolatableContext())) {
      return <span />;
    }

    if (isIE()) {
      return this.renderInterpolated(this.props.context);
    }

    return (
      <Motion defaultStyle={this.getDefaultStyle()} style={this.getStyle()}>
        {context => this.renderInterpolated(context)}
      </Motion>
    );
  }
}

export default NumberTicker;
