exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".XWlc7TGXZFKI7obrv1PTj{box-shadow:0 2px 6px rgba(0,0,0,.1)}.XWlc7TGXZFKI7obrv1PTj ._3lpRbGuV5EFQpY-gsppl4j{padding:15px 20px}.XWlc7TGXZFKI7obrv1PTj h4{font-weight:bold;margin:0}.XWlc7TGXZFKI7obrv1PTj ._2jJZA1FbfLzD2ry3uInclk{font-size:18px;display:block;margin:0 30px 15px 0;text-align:right}.pLM5wsRHQdrZ6CKNk6GNs{margin:5px auto !important}", ""]);

// exports
exports.locals = {
	"policyRule": "XWlc7TGXZFKI7obrv1PTj",
	"panelHead": "_3lpRbGuV5EFQpY-gsppl4j",
	"label": "_2jJZA1FbfLzD2ry3uInclk",
	"toggle": "pLM5wsRHQdrZ6CKNk6GNs"
};