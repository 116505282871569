exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span.dF48noA1eOvCnxBAny5aB{display:inline-block}", ""]);

// exports
exports.locals = {
	"base": "dF48noA1eOvCnxBAny5aB"
};