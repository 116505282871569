exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tB5kBZoOygM5zV-LhPTus{padding-right:15px}@media(max-width: 768px){.tB5kBZoOygM5zV-LhPTus{display:none}}", ""]);

// exports
exports.locals = {
	"summary": "tB5kBZoOygM5zV-LhPTus"
};