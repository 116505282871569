import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/font-awesome';
import cx from 'classnames';
import styles from './spotlight.scss';
import Overlay from 'components/overlay';
import Input from 'components/input';
import KeyCodes from 'constants/keyCodes';
import {revolveIndex} from 'utils/numberUtils';
import UserImage from 'components/user/userImage';
import {findDOMNode} from 'react-dom';
import {havePropsChanged} from 'utils/propUtils';
import {friendlyDateTime, friendlyRelativeDate} from 'utils/dateTimeUtils';
import {capitalizeFirstLetter} from 'utils/stringUtils';
import {homePath} from 'modules/app/appUtils';

export default class Spotlight extends React.Component {
  static defaultProps = {
    // `results` are a list of objects with each object representing a heading or a search result.
    // Headings and search results are all displayed inside the same UL element. Headings have a
    // `isHeading` of true, and both have a `label` and a `key`. Search result objects also have a `data`.
    // property containing the data that the search result references. I.E `data` will be a User or an Account.
    results: [],
    isSearching: false,
    onSearch: searchText => {},
    onSelectResult: ({type, label, key, isHeading, data}) => {}
  };

  static propTypes = {
    results: PropTypes.array,
    isSearching: PropTypes.bool
  };

  state = {
    searchText: '',
    selected: 1 // the 0th value is always a heading
  };

  componentWillMount() {
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  componentWillReceiveProps(nextProps) {
    if (havePropsChanged(this.props, nextProps, ['results'])) {
      // reset the selected highlight if the results change
      this.setState({selected: 1});
      this.list.scrollTop = 0;
    }
  }

  setResultList = ref => {
    this.list = ref;
  };

  handleChangeText = event => {
    const searchText = event.target.value;
    this.setState({searchText});
    this.debouncedSearchFor(searchText);
  };

  handleClick = event => {
    this.props.onSelectResult(this.props.results[this.state.selected]);
  };

  handleMouseEnter = idx => event => {
    this.setState({selected: idx});
  };

  handleKeydown = event => {
    if (event.which == KeyCodes.ENTER) {
      this.props.onSelectResult(this.props.results[this.state.selected]);
    }
    if (event.which == KeyCodes.UP || event.which == KeyCodes.DOWN) {
      event.preventDefault(); // prevent UP and DOWN moving the text input cursor or the scroll window

      this.moveSelectedResult(event.which === KeyCodes.UP ? -1 : 1);
    }
  };

  searchFor = () => {
    const {searchText} = this.state;

    this.props.onSearch(searchText);
  };

  debouncedSearchFor = _.debounce(this.searchFor, 200);

  // a direction of 1 moves the selected value down 1 space
  // a direction of -1 moves the selected value up 1 space
  moveSelectedResult(direction) {
    // only allow positive and negative numbers
    if (typeof direction !== 'number' || !direction) return;

    const results = this.props.results;
    var selected = this.state.selected;

    do {
      selected = revolveIndex(selected, results.length, direction);
    } while (results[selected].isHeading);

    this.setState({selected});

    this.setScroll(selected);
  }

  setScroll(selected) {
    const cont = this.list;
    // cont.firstChild is the UL element
    const child = cont.firstChild.children[selected];

    // the position of the selected element in relation to the scroll container
    const currentTop = child.offsetTop - cont.clientHeight - cont.scrollTop;
    const currentBottom = currentTop + child.clientHeight;

    // edge case - if we are on the 1st element, we also want to show the first heading
    if (this.state.selected === 1) {
      cont.scrollTop = 0;
      return;
    }

    // This is because the scrollTop is offset by the margin-top 10px + the padding 10px
    const offset = 20;

    if (currentTop - offset < 0) {
      cont.scrollTop = child.offsetTop - cont.clientHeight - offset;
    }

    if (currentBottom - offset > cont.clientHeight) {
      cont.scrollTop = child.offsetTop - cont.clientHeight * 2 + child.clientHeight - offset;
    }
  }

  renderResult = ({isHeading, label, key}, idx) => {
    const className = cx({
      [styles.isHeading]: isHeading,
      [styles.isSelected]: this.state.selected == idx
    });

    return (
      <li
        className={className}
        key={key}
        onClick={isHeading ? null : this.handleClick}
        onMouseEnter={isHeading ? null : this.handleMouseEnter(idx)}
      >
        {label}
      </li>
    );
  };

  renderPreview() {
    const data = this.props.results[this.state.selected];
    if (!data) return null;

    const {type, ...previewData} = data;

    switch (type) {
      case 'account':
        return this.renderAccountPreview(previewData);

      case 'user':
        return this.renderUserPreview(previewData);

      default:
        return null;
    }
  }

  renderAccountPreview({label, key, data: account}) {
    return (
      <div className={styles.selectedPreview}>
        {this.renderImage(label, account.contactEmail)}
        <dl>{createAccountDefinitionList(account).map(this.renderDefinitionListItem)}</dl>
      </div>
    );
  }

  renderUserPreview({label, key, data: user}) {
    return (
      <div className={styles.selectedPreview}>
        {this.renderImage(label, user.email)}
        <dl>{createUserDefinitionList(user).map(this.renderDefinitionListItem)}</dl>
      </div>
    );
  }

  renderImage(label, email) {
    return (
      <figure className={styles.userImage}>
        <UserImage user={{email}} size={100} />
        <figcaption>{label}</figcaption>
      </figure>
    );
  }

  renderDefinitionListItem = ({label, value}) => {
    return (
      <div key={label + ':' + value}>
        <dt>{label}</dt>
        <dd>{value}</dd>
      </div>
    );
  };

  render() {
    const {isSearching, results} = this.props;
    const {searchText} = this.state;

    return (
      <div className={styles.spotlight}>
        <div className={styles.searchInput}>
          <Icon name="search" size="2x" className={styles.searchIcon} />
          <Input
            value={searchText}
            onChange={this.handleChangeText}
            placeholder="e.g. Silktide"
            autoFocus
          />
          {isSearching && <Icon name="spinner" spin size="lg" className={styles.spinner} />}
        </div>
        <div
          className={cx(styles.spotlightResults, {
            [styles.showResults]: !!results.length
          })}
        >
          <div className={styles.resultList} ref={this.setResultList}>
            <ul>{results.map(this.renderResult)}</ul>
          </div>
          {this.renderPreview()}
        </div>
      </div>
    );
  }
}

function createAccountDefinitionList(data) {
  return [
    {label: 'Account ID', value: data.accountId},
    {label: 'Contact name', value: data.contactName},
    {label: 'Contact email', value: data.contactEmail},
    {label: 'Account type', value: data.accountTypeId}
  ];
}

function createUserDefinitionList(data) {
  return [
    {label: 'User ID', value: data.userId},
    {label: 'Email', value: data.email},
    {label: 'Role', value: capitalizeFirstLetter(data.role)},
    {
      label: 'Last active',
      value: !data.lastActive ? 'Never' : friendlyRelativeDate(data.lastActive)
    },
    {label: 'Account ID', value: data.accountId},
    {
      label: 'Open Account',
      value: (
        <a
          onClick={() => {
            window.location = homePath({accountId: data.accountId});
          }}
        >
          Open account
        </a>
      )
    }
  ];
}
