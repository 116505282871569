import React from 'react';
import {connect} from 'react-redux';
import {Sidebar, Item} from 'components/sidebar/listSidebar';
import {trendScreensSelector} from '..';
import {testPath} from 'modules/tests/testUtils';
import {push} from 'modules/location';
import Button from 'components/button';
import {trendScreenPath} from 'modules/trends';

export class TrendsSidebar extends React.Component {
  handleClickBackToReport = () => {
    const {
      params: {trendScreenId},
      push
    } = this.props;
    push(testPath, {testId: trendScreenId}, ['reportId']);
  };

  renderTrendScreen = ({trendScreenId, name}) => {
    const {reportId, trendScreenId: activeTrendScreenId} = this.props.params;
    return (
      <Item
        href={trendScreenPath({trendScreenId, reportId})}
        key={trendScreenId}
        active={trendScreenId == activeTrendScreenId}
      >
        {name}
      </Item>
    );
  };

  render() {
    const {trendScreens} = this.props;

    return (
      <Sidebar>
        <Button style={{marginBottom: 30}} onClick={this.handleClickBackToReport} icon="arrow-left">
          Back to website
        </Button>
        {Object.values(trendScreens).map(this.renderTrendScreen)}
      </Sidebar>
    );
  }
}

export default connect(
  trendScreensSelector,
  {push}
)(TrendsSidebar);
