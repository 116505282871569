import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/spinner';
import Helmet from 'components/helmet/helmet';
import PageHeader from 'components/header/pageHeader';
import PageBody from 'components/page';
import Panel from 'components/panel';
import {createStructuredSelector} from 'reselect';
import {policySelector} from '../selectors';
import {requestPolicy, savePolicy} from '../actions';
import PolicyForm from '../containers/policyForm';
import Help from 'modules/help/containers/help';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import {testPath} from 'modules/tests/testUtils';
import {push} from 'modules/location';

const policiesPageSelector = createStructuredSelector({
  policy: policySelector
});

export class EditPolicyPage extends React.Component {
  componentDidMount() {
    const {match, requestPolicy} = this.props;
    requestPolicy(match.params.policyId);
  }

  componentWillReceiveProps(nextProps) {
    const {match, requestPolicy} = this.props;
    if (nextProps.match.params.policyId !== match.params.policyId) {
      requestPolicy(nextProps.match.params.policyId);
    }
  }

  handleValidSubmit = data => {
    this.props.savePolicy(data, true);
  };

  render() {
    const {match, location, push} = this.props;

    return (
      <WithReportSidebar match={match} location={location}>
        <Helmet title="Edit policy" />
        <PageHeader
          title={this.props.policy ? this.props.policy.get('name') : ''}
          afterTitle={
            <Help url="https://monitor.support.silktide.com/policies/adding-rules-to-a-policy/" />
          }
          goBack={() => {
            push(testPath, {}, ['reportId', 'testId']);
          }}
        />

        <PageBody noPaddingBottom>
          {this.props.policy ? (
            <PolicyForm policy={this.props.policy.toJS()} onValidSubmit={this.handleValidSubmit} />
          ) : (
            <Panel>
              <Spinner />
            </Panel>
          )}
        </PageBody>
      </WithReportSidebar>
    );
  }
}

export default connect(
  policiesPageSelector,
  {requestPolicy, savePolicy, push}
)(EditPolicyPage);
