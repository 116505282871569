import {delay} from 'redux-saga';
import {takeLatest, take, put, call, race} from 'redux-saga/effects';
import {RECEIVE_MISSION} from 'modules/missions';
import {RETEST_REPORT, REPORT_RETEST_BEGAN} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {receiveMission, removeMission} from 'modules/missions';
import {missionEndpoint, missionIsFinished} from 'modules/missions/missionUtils';

export default function* pollForReportMissionListener() {
  // if a RETEST_REPORT action happens, a user just clicked a button to retest this report right now
  yield takeLatest(RETEST_REPORT, function*({reportId}) {
    // We just requested to retest a report (using RETEST_REPORT) so the endpoint returns a mission and we wait for it here
    const {mission} = yield take(RECEIVE_MISSION);
    yield call(pollIfNoPusher, mission.missionId);
  });

  // if a REPORT_RETEST_BEGAN action happens, then the page was refreshed, and the report is already being retested
  yield takeLatest(REPORT_RETEST_BEGAN, function*({missionId}) {
    // We recieved a report from the endpoint, and the report had a 'website' mission in its `missions` prop
    yield call(pollIfNoPusher, missionId);
  });
}

function* pollIfNoPusher(missionId) {
  const {receivedMission, timeout} = yield race({
    receivedMission: take(RECEIVE_MISSION),
    timeout: delay(20000)
  });

  if (timeout) {
    try {
      // request updated mission
      const mission = yield getMission(missionId);
      yield put(receiveMission(mission));

      if (missionIsFinished(mission)) {
        yield put(removeMission(missionId));
      } else {
        yield call(pollIfNoPusher, missionId);
      }
    } catch (err) {
      yield put(removeMission(missionId));
      // failed to fetch mission, likely a 404 meaning the mission no longer exists
      // the backend doesn't always send a final mission with status `completed` so we have to assume
      // that if we can't get it, it doesn't exist, thus it is completed
    }
  }
  if (receivedMission) {
    if (missionIsFinished(receivedMission.mission)) {
      yield put(removeMission(missionId));
    } else {
      yield call(pollIfNoPusher, missionId);
    }
  }
}

function* getMission(missionId) {
  const url = missionEndpoint({missionId});

  return yield processRequest('GET', url);
}
