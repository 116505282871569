import _ from 'lodash';

// Removes/adds value to array depending on whether it exists.
export function toggle(array, value) {
  const idx = array.indexOf(value);

  if (idx === -1) {
    array.push(value);
  } else {
    array.splice(idx, 1);
  }

  return array;
}

// Ensures the passed in array is objects in the format {label, value} ready to
// be used by select inputs
export function normalizeOptions(options) {
  if (_.isObject(options[0])) {
    return options;
  }
  return _.map(options, (value, key) => {
    return {label: value, value: _.isString(key) ? key : value};
  });
}

export function objectToArray(object) {
  const results = [];
  for (var key in object) {
    results.push({label: object[key], value: key});
  }
  return results;
}

export function arrayGroupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
