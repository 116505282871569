import React from 'react';
import {connect} from 'react-redux';
import Status from 'components/status';
import ModalContent from 'modules/modal/components/modalContent';
import MissionStatus from 'modules/missions/components/missionStatus';
import {missionListModalSelector} from 'modules/reports/reportSelectors';
import {getProps} from 'utils/immutableUtils';

export class MissionListModal extends React.Component {
  renderMission = (mission, index) => {
    const {users, isDeleting} = this.props;
    const userId = mission.get('userId');
    const user = userId == 0 ? null : users.get(userId + '');
    const missionProps = getProps(mission, ['missionId', 'name', 'progressDone', 'progressTotal']);

    return (
      <MissionStatus
        {...missionProps}
        key={mission.get('missionId')}
        user={user}
        isDeleting={isDeleting === mission.get('missionId')}
      />
    );
  };

  renderReportMission = (report, index, reportMissions) => {
    const missions = report.get('missions');
    const isAlone = index === 0 && reportMissions.size === 1;

    return (
      <div key={report.get('scheduleactionId')}>
        {isAlone && <h3>{report.get('name')}</h3>}
        <div>{missions.toList().map(this.renderMission)}</div>
      </div>
    );
  };

  renderContent() {
    const {reportMissions} = this.props;

    if (!reportMissions.size) {
      return <Status appearance="good">No actions are currently running</Status>;
    }

    return reportMissions.map(this.renderReportMission);
  }

  render() {
    return <ModalContent title="Running now">{this.renderContent()}</ModalContent>;
  }
}

export default connect(missionListModalSelector)(MissionListModal);
