import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import CountryFlag from 'components/countryFlag';

class Languages extends React.Component {
  renderCountryFlag = cur => {
    return <CountryFlag code={cur} key={cur} />;
  };

  render() {
    const {data, options} = this.props;

    if (!data || !data.length) {
      return null;
    }

    if (Array.isArray(data)) {
      return <div className={styles.languageContainer}>{data.map(this.renderCountryFlag)}</div>;
    }

    if (typeof data === 'string') {
      return <CountryFlag code={data} key={data} />;
    }

    return null;
  }
}

Languages.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default Languages;
