import _ from 'lodash';
import {addQueryString} from 'utils/urlUtils';

export function normalizeFilters(filters = []) {
  return _.omitBy(filters, _.isNil);
}

export function propertySearcher(term, properties) {
  term = term.toLowerCase();

  return function(obj) {
    return _.some(properties, function(property) {
      if (_.has(obj, property)) {
        var value = obj[property];
        if (typeof value !== 'string') return false;

        return value.toLowerCase().indexOf(term) > -1;
      }
    });
  };
}

/**
 * Evaluate whether a filter condition is true.
 *
 * Check a filter condition of the form: {a: "foo", b: ["bob", "jane"]}
 * which means: "a" must equal "foo" and "b" must equal "bob" or "jane".
 */
export function evalFilterCondition(filters, condition) {
  if (!condition) return true;

  return _.every(condition, (values, key) => {
    const filterValue = filters[key];

    if (_.isArray(values)) {
      return _.some(values, value => value == filterValue);
    }

    return values == filterValue;
  });
}

export function addFilterQueryString(path, filters) {
  return addQueryString(path, normalizeFilters(filters));
}

export function filterKey({reportId, testId}) {
  return `${reportId}_${testId}`;
}

// filters used in print mode
export const printFilters = {
  media: 'print'
};

export function isPrintMode(filters = {}) {
  return filters.media === 'print';
}
