import React from 'react';
import {reportIsAllowed} from 'modules/gandalf/gandalfUtils';

/**
 * Wrap the element's children in a button if the report's role is allowed.
 * Otherwise, only display the children.
 */
export default function ReportGandalfButton({shallPass, report, onClick, className, children}) {
  if (!!report && reportIsAllowed(shallPass, report.getIn(['ownPermissions', 'role']))) {
    return (
      <button type="button" onClick={onClick} className={className}>
        {children}
      </button>
    );
  }

  return children;
}
