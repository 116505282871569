exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes irBUcYFR7c1sllzF0irAA{0%{opacity:0}100%{opacity:1}}@keyframes irBUcYFR7c1sllzF0irAA{0%{opacity:0}100%{opacity:1}}@-webkit-keyframes gm-2MlzU52w3eZTpYte8O{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes gm-2MlzU52w3eZTpYte8O{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}._3DXyTLxblv0ay0u5qGOMd0>div{flex:1 0}._28AKclOEEMtLQUUEXWY5nq{padding:20px;position:relative;border:0}._28AKclOEEMtLQUUEXWY5nq>span{flex:1 0}._28AKclOEEMtLQUUEXWY5nq h1{font-size:2em;line-height:1.3em;text-overflow:ellipsis;font-size:26px;margin:0}._2fTXI5DhSvHLbrXjFFoGFV{padding-bottom:0}@media print{._28AKclOEEMtLQUUEXWY5nq{min-height:auto;padding:10px 0 0 0;margin:0 8px}}", ""]);

// exports
exports.locals = {
	"dashboardHeader": "_3DXyTLxblv0ay0u5qGOMd0",
	"header": "_28AKclOEEMtLQUUEXWY5nq",
	"withScore": "_2fTXI5DhSvHLbrXjFFoGFV",
	"fade-in": "irBUcYFR7c1sllzF0irAA",
	"rotating": "gm-2MlzU52w3eZTpYte8O"
};