import React, {Fragment, useState} from 'react';
import Panel from 'components/panel';
import Quiz from '../quizes/quiz';
import {toJS} from 'utils/immutableUtils';
import Markdown from 'components/layoutComponents/renderers/markdown';
import CompleteLessonButton from 'modules/courses/components/buttons/completeLessonButton';
import styles from './lessonTypes.scss';
import cx from 'classnames';

function TextLessonType(props) {
  const {
    lesson,
    options: {text}
  } = props;
  const questions = toJS(lesson.get('questions'));
  const hasQuiz = questions && !!questions.length;

  const renderText = () => (
    <div className={cx(styles.readingArea, styles.markdown)}>
      <Markdown data={text} />
    </div>
  );

  const renderQuiz = () => (
    <div className={styles.readingArea}>
      <Quiz {...props} />
    </div>
  );

  if (hasQuiz) {
    return (
      <div>
        <div>
          <div className={styles.readingArea}>
            <p>
              Read the following then answer the questions under the <b>Quiz</b> tab:
            </p>
            <hr />
          </div>
          {renderText()}
        </div>
        <div>
          <div className={styles.readingArea}>
            <p>
              Answer the following questions then press the <b>Submit answers</b> button:
            </p>
            <hr />
            {renderQuiz()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className={styles.readingArea}>
          <p>
            Read the following then press the <b>Complete and continue</b> button below:
          </p>
          <hr />
        </div>
        {renderText()}
        <div className={cx(styles.buttons, styles.marginTop)}>
          {<CompleteLessonButton lesson={lesson} />}
        </div>
      </div>
    </div>
  );
}

export default TextLessonType;
