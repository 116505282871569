import _ from 'lodash';
import React from 'react';
import PriorityComponent from 'modules/actions/components/priority';

class Priority extends React.Component {
  render() {
    const {data} = this.props;
    if (_.isUndefined(data) || data < 0 || data > 5) {
      return null;
    }

    return (
      <span style={{textAlign: 'center'}}>
        <PriorityComponent priority={data} />
      </span>
    );
  }
}

Priority.propTypes = {};

export default Priority;
