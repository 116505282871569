import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Buttons from 'components/buttons';
import Button from 'components/button';
import ModalContent from 'modules/modal/components/modalContent';
import {submitConfirmModal} from 'modules/modal';
import {connect} from 'react-redux';

export class ConfirmationModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string
  };

  static defaultProps = {
    title: 'Are you sure?',
    content: 'Are you sure you wish to continue?',
    submitLabel: 'Yes',
    cancelLabel: null,
    destructiveConfirm: false
  };

  handleConfirm = () => {
    const {submitConfirmModal, onSubmit, hideModal} = this.props;

    submitConfirmModal(true);

    onSubmit && onSubmit();
    hideModal && hideModal();
  };

  handleCancel = () => {
    const {submitConfirmModal, hideModal, onCancel} = this.props;

    submitConfirmModal(false);

    onCancel && onCancel();
    hideModal && hideModal();
  };

  renderFooter() {
    const {
      submitId,
      submitLabel,
      cancelLabel,
      destructiveConfirm,
      submitProps // used for adding styles etc
    } = this.props;

    return (
      <Buttons noMargin>
        {cancelLabel && <Button onClick={this.handleCancel}>{cancelLabel}</Button>}
        <Button
          id={submitId}
          onClick={this.handleConfirm}
          destructive={destructiveConfirm}
          {...submitProps}
        >
          {submitLabel}
        </Button>
      </Buttons>
    );
  }

  render() {
    const {title, content, id} = this.props;

    return (
      <ModalContent id={id} title={title} footer={this.renderFooter()} focusButton>
        {typeof context == 'string' ? <div dangerouslySetInnerHTML={{__html: content}} /> : content}
      </ModalContent>
    );
  }
}

export default connect(
  null,
  {submitConfirmModal}
)(ConfirmationModal);
