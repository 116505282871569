import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

class Arrow extends React.Component {
  render() {
    const {className, ...props} = this.props;

    const classes = ['aro', className];

    if (this.props.up) {
      classes.push('aro--up');
    }

    if (this.props.down) {
      classes.push('aro--down');
    }

    return <div className={cx(classes)} />;
  }
}

export default Arrow;
