import React from 'react';
import Progress from 'components/progress';
import styles from './actionProgress.scss';

export default class ActionProgress extends React.Component {
  render() {
    let {done, total} = this.props;
    let percentage = Math.round(total ? (done / total) * 100 : 0);

    // Sanity checks; incorrect backend data should never cause this to make no sense
    if (done < 0) done = 0;
    if (percentage > 100) percentage = 100;

    let caption = 'Progress';
    if (total > 0) {
      if (percentage == 100 && done < total) {
        caption += ' almost 100%';
      } else {
        caption += ' ' + percentage + '%';
      }
    }

    return (
      <div style={{width: '100%'}}>
        <Progress value={done} denominator={total} label="right" small />
      </div>
    );
  }
}
