import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Buttons from 'components/buttons';
import Button from 'components/button';
import {ConfirmationModal} from './confirmationModal';
import {submitConfirmModal} from 'modules/modal';

export class ConfirmationModalLms extends ConfirmationModal {
  constructor() {
    super();
  }

  renderFooter() {
    const {
      submitId,
      submitLabel,
      cancelLabel,
      destructiveConfirm,
      submitProps // used for adding styles etc
    } = this.props;

    return (
      <Buttons>
        {cancelLabel && (
          <Button onClick={this.handleCancel} wire>
            {cancelLabel}
          </Button>
        )}
        <Button
          id={submitId}
          onClick={this.handleConfirm}
          destructive={destructiveConfirm}
          {...submitProps}
          good
        >
          {submitLabel}
        </Button>
      </Buttons>
    );
  }
}

export default connect(
  null,
  {submitConfirmModal}
)(ConfirmationModalLms);
