import BarChart from 'components/layoutComponents/barChart';
import Pill from 'components/pill';
import Score from 'components/score';
import React from 'react';
import {toJS} from 'utils/immutableUtils';
import {formatGivenNumber} from 'utils/numberUtils.js';

export default function Value({type, value}) {
  switch (type) {
    case 'score':
      return typeof value === 'number' ? (
        <Score value={value} />
      ) : (
        <BarChart
          hideMargin
          config={{
            label: 'Scores',
            xaxis: 'Score range',
            yaxis: 'Number of websites',
            height: 258, // this is hacky but works for now
            invert: true
          }}
          result={value
            .map((value, label) => ({label, value}))
            .toList()
            .sort((a, b) => {
              const numA = parseInt(a.label, 10);
              const numB = parseInt(b.label, 10);
              return numA - numB;
            })
            .toJS()}
        />
      );

    case 'proportion':
      return <Pill appearance="minor">{formatGivenNumber(value, 1)}%</Pill>;

    case 'int':
      return <Pill appearance="minor">{formatGivenNumber(value)}</Pill>;

    default:
      if (__DEV__) {
        return (
          <pre>
            {type}: {JSON.stringify(value, undefined, 4)}
          </pre>
        );
      }
      return null;
  }
}
