import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from 'components/button';
import styles from './checkpoint.scss';
import CheckpointExplanation from './checkpointExplanation';
import CheckpointTargetModal from 'modals/checkpointTargetModal/checkpointTargetModal';
import {interpolate, interpolateToJSX, choosePluralVersion} from 'utils/interpolationUtils';
import {trackEvent} from 'modules/tracking';
import {ReportGandalf, ReportGandalfButton} from 'components/gandalf';
import {ROLE_MANAGER, ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';
import {connect} from 'react-redux';
import {showInModal} from 'modules/modal';

const BETWEEN_SQUARE_BRACKETS = /\[\[(.*?)\]\]/g;

export class Checkpoint extends React.Component {
  static propTypes = {
    checkpoint: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    expandable: PropTypes.bool.isRequired
  };

  handleExplain(e) {
    e.stopPropagation();

    const {checkpointId, testId} = this.props.checkpoint;
    this.props.onExplainCheckpoint(testId, checkpointId);
  }

  handleView = () => {
    const {checkpoint} = this.props;

    trackEvent('Viewed checkpoint details', {
      fromFile: 'checkpoint.js',
      checkpoint: checkpoint.checkpointId
    });
    this.props.onViewDetail(checkpoint);
  };

  handleIgnore(e, isIgnored) {
    const {checkpoint} = this.props;

    e.stopPropagation();

    this.props.ignoreCheckpoint(checkpoint, isIgnored);
    this.props.onUpdateCheckpoint(checkpoint.checkpointId + checkpoint.testId);
  }

  getTitleString(checkpoint) {
    const {title} = checkpoint;
    const number = checkpoint[title.number];

    if (checkpoint.isDone && title.done != undefined) {
      return title.done;
    }
    if (!checkpoint.hasTarget) {
      return title.noTarget;
    }
    if (number === 0 && title.zero != undefined) {
      return title.zero;
    }

    return choosePluralVersion(number, title.singular, title.plural);
  }

  handleUpdateTarget = newTargetValue => {
    const {checkpoint} = this.props;

    this.props.changeCheckpointTarget(checkpoint, newTargetValue);
    this.props.onUpdateCheckpoint(checkpoint.checkpointId + checkpoint.testId);
  };

  getCheckpointClass() {
    const {checkpoint, lastUpdatedCheckpointId} = this.props;

    return cx(styles.checkpoint, {
      [styles.ignored]: checkpoint.isIgnored,
      highlightOut: lastUpdatedCheckpointId === checkpoint.checkpointId + checkpoint.testId
    });
  }

  handleLaunchModal = e => {
    const {checkpoint} = this.props;

    e.stopPropagation();

    trackEvent('Launched update checkpoint modal', {
      fromFile: 'checkpoint.js',
      checkpoint: checkpoint.checkpointId,
      currentValue: checkpoint.targetValue
    });

    this.props.showInModal(CheckpointTargetModal, {
      onConfirm: this.handleUpdateTarget,
      checkpoint
    });
  };

  renderPlainTitle(string, checkpoint) {
    return interpolateToJSX(string, checkpoint);
  }

  renderTitle(checkpoint, report) {
    const string = this.getTitleString(checkpoint);

    if (!string) return 'Not defined';

    const matches = string.match(BETWEEN_SQUARE_BRACKETS);
    if (!matches) {
      return this.renderPlainTitle(string, checkpoint);
    }

    const match = matches[0];
    let stringParts = string.split(match);
    stringParts = stringParts.map(part => interpolateToJSX(part, checkpoint));

    const matchContents = match.substring(2, match.length - 2);

    const checkpointButton = (
      <ReportGandalfButton
        shallPass={ROLE_CONTRIBUTOR}
        report={report}
        onClick={this.handleLaunchModal}
        className={styles.link}
      >
        {interpolateToJSX(matchContents, checkpoint)}
      </ReportGandalfButton>
    );

    return (
      <span>
        {stringParts[0]}
        {checkpointButton}
        {stringParts[1]}
      </span>
    );
  }

  getSeverityIcon(checkpoint) {
    const {severity, isDone} = checkpoint;

    if (!isDone) {
      if (severity > 50) {
        return 'important';
      }
      return 'minor';
    }

    return 'done';
  }

  render() {
    const {checkpoint, expanded, expandable, report} = this.props;
    const {score, checkpointId, severity, description, isIgnored, allowCustomTarget} = checkpoint;

    const icon = this.getSeverityIcon(checkpoint);

    return (
      <div className={this.getCheckpointClass()} onClick={this.handleView}>
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <i
              className={cx('severityIconDropdown', 'severityIcon--' + icon)}
              title={severity + '/100'}
            />
          </div>

          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{this.renderTitle(checkpoint, report)}</h2>
          </div>

          <div className={styles.actionsWrapper}>
            <ReportGandalf shallPass={ROLE_MANAGER} report={report}>
              {!isIgnored && <Button link icon="times" onClick={e => this.handleIgnore(e, true)} />}
              {!!isIgnored && (
                <Button link icon="plus" onClick={e => this.handleIgnore(e, false)} />
              )}
            </ReportGandalf>
          </div>
        </div>

        {description && <CheckpointExplanation checkpoint={checkpoint} />}
      </div>
    );
  }
}

export default connect(
  null,
  {showInModal}
)(Checkpoint);
