import React, {useEffect} from 'react';
import Rule from './Rule';

export default function Field({field, children, label, format, ...rules}) {
  useEffect(() => {
    field.setFormatter(format);
  }, [format]);

  return (
    <React.Fragment>
      {label && <label htmlFor={field.getHtmlFor()}>{label}</label>}

      {children}

      <Rule field={field} {...rules} />
    </React.Fragment>
  );
}
