import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import {toFixed, formatGivenNumber} from '../../../utils/numberUtils.js';

class Seconds extends React.Component {
  render() {
    let {data, options, className} = this.props;
    const {decimals} = options;

    if (typeof data === 'undefined') {
      data = 0;
    }

    return (
      <span className={cx(styles.seconds, className)}>
        {decimals ? toFixed(data, decimals) : formatGivenNumber(data)}s
      </span>
    );
  }
}

Seconds.propTypes = {};

export default Seconds;
