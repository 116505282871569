import {fromJS} from 'immutable';

const initialState = fromJS([]);

export default function statsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_STATS:
      return state.merge(fromJS(action.stats));
  }
  return state;
}

export const RECEIVE_STATS = 'stats:RECEIVE_STATS';
export function receiveStats(stats) {
  return {
    type: RECEIVE_STATS,
    stats
  };
}

export const FETCH_STATS = 'stats:RECEIVE_FETCH_STATS';
export function fetchStats() {
  return {
    type: FETCH_STATS
  };
}
