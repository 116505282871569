exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17_HFV8PE1BQZj1AmfyB6r{word-break:break-word}._2vhTH3bTuKbs8QWD1IgHKS{color:#888}", ""]);

// exports
exports.locals = {
	"link": "_17_HFV8PE1BQZj1AmfyB6r",
	"icon": "_2vhTH3bTuKbs8QWD1IgHKS"
};