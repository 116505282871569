import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import ExternalLink from 'modules/location/externalLink';
import {getRendererComponent} from 'utils/layoutUtils';
import explorerStyles from './explorerDataRenderer.scss';
import evaluate from 'token-js-parser';
import {doInstruction} from 'modules/instructions';
import {connect} from 'react-redux';
import {filterSelector} from 'modules/filters/filterSelectors';
import {createStructuredSelector} from 'reselect';

export class ExplorerDataRenderer extends React.Component {
  static defaultProps = {
    rendererParams: {}
  };

  /**
   *  When rendering each column (th or td), we also need to know if any other columns should be collapsed into the column we are rendering.
   *  In order to do that, we key the combinations of columns by label. That way, we can easily find if any columns should be rendered inside
   *  of the current column using `this.combinations[currentColumn.label]`.
   *
   *   this.combinations = {
   *     Pages: [{...aField}, {...anotherField}]
   *   }
   */
  combinations = [];

  componentWillMount() {
    this.combinations = this.props.fields.reduce((reduction, field) => {
      // The backend makes the keys lowercase but any frontend defined columns will
      // probably forget that little fact, so lets accept both to prevent bugs
      const combineWith = field.combinewith || field.combineWith;

      if (combineWith) {
        if (!reduction[combineWith]) {
          reduction[combineWith] = [];
        }
        reduction[combineWith].push(field);
      }
      return reduction;
    }, {});
  }

  /**
   * See `combinations` above for details. It is not as simple as `this.combinations[currentColumn.label]`, as the columns
   * that we should render may also render other columns, meaning we need to evaluate each combined column, to see if they
   * also need to render any columns.
   *
   * We also only render the combined collumns that are currently closed (hidden)
   */
  getCollapsedColumns(label) {
    const {width} = this.props;
    const topLevelCombinations = this.combinations[label] || [];
    const allColumns = topLevelCombinations.reduce((reduction, subField) => {
      // ignore ourself
      if (label === subField.label) return;

      // if this column has any combinations of its own && those combinations are ACTIVE
      // then return those columns in the list of columns to be rendered
      if (this.combinations[subField.label] && width < subField.collapse) {
        reduction = reduction.concat(this.combinations[subField.label]);
      }
      return reduction;
    }, []);

    // The field has other fields that collapse into it, check if any of them are currently collapsed
    return [...topLevelCombinations, ...allColumns].reduce((reduction, subField) => {
      if (subField.collapse && width && width < subField.collapse) {
        reduction.push(subField);
      }
      return reduction;
    }, []);
  }

  renderField(data, field, record) {
    const {styles, filters} = this.props;
    const componentImport = getRendererComponent(field);

    const Renderer =
      componentImport && componentImport.default ? componentImport.default : componentImport;

    const clickable = field.clickable || record.clickable;
    // if clickable is set, add the clickable style. Also add the cellClassName
    const classes = cx(
      field.cellClassName,
      {
        [styles.clickable]: clickable,
        [explorerStyles.hideOnPrint]: field.hideOnPrint
      },
      typeof field.class === 'string'
        ? field.class.split(/\s+/).map(backendClass => explorerStyles[backendClass])
        : undefined
    );
    const props = {
      data,
      record,
      filters: filters.filter,
      options: field,
      className: classes,
      ...this.props.cellParams
    };

    if (typeof field.formatData === 'function') {
      data = field.formatData(data);
    }

    if (field.empty && !data) {
      return <i className="text-muted">{field.empty}</i>;
    }

    const url = field.href ? record[field.href] : '';

    const renderProps = {...props, data};

    if (url) {
      return (
        <ExternalLink href={url}>
          <Renderer {...renderProps} />
        </ExternalLink>
      );
    }

    return <Renderer {...renderProps} />;
  }

  handleClick = (record, field, filter = {}) => event => {
    const {onClick} = this.props;

    onClick && onClick(record, field, event);
  };

  renderDataComponent = (field, index) => {
    const {record, keyValue, width, dataComponent, filter} = this.props;
    const {type, field: fieldName, className, collapse} = field;
    const dataKey = keyValue + '_' + index + '_' + fieldName + '_' + type;
    const DataComponent = field.sticky && dataComponent == 'td' ? 'th' : dataComponent;

    if (field.show && !evaluate(field.show, {filters: filter})) {
      return null;
    }

    // if this column should be hidden
    if (collapse && width && width < collapse) {
      return null;
    }

    const collapsedFields = this.getCollapsedColumns(field.label);

    // if we have no collapsable combinations or if none of the collapsed columns are currently collapsed
    if (!collapsedFields.length) {
      return this.renderSingle(field, record, dataKey);
    }

    // render column with collapsed sub-columns
    return (
      <DataComponent
        key={dataKey + '_combination'}
        className={cx({
          [explorerStyles.hideOnPrint]: field.hideOnPrint
        })}
      >
        {[field, ...collapsedFields].map(subField => {
          const data = _.get(record, subField.field);
          return (
            <dl
              className={explorerStyles.combinedField}
              key={dataKey + '_combination_' + subField.label + '_' + subField.type}
            >
              {/* only render the label if data exists (otherwise we get a label with no data, which is weird) */}
              {data && !subField.hideCollapsedLabel && <dt>{subField.label}</dt>}
              <dd onClick={this.handleClick(record, subField, filter)}>
                {this.renderField(data, subField, record)}
              </dd>
            </dl>
          );
        })}
      </DataComponent>
    );
  };

  renderSingle(field, record, dataKey) {
    const {dataComponent, filter} = this.props;
    const {styles = {}, type, className, field: fieldName} = field;
    const data = _.get(record, fieldName);
    const DataComponent = field.sticky && dataComponent == 'td' ? 'th' : dataComponent;

    return (
      <DataComponent
        key={dataKey}
        onClick={this.handleClick(record, field, filter)}
        className={cx(styles[type], className, {
          [explorerStyles.sticky]: field.sticky,
          [explorerStyles.opaque]: record.rowDisabled,
          [explorerStyles.hideOnPrint]: field.hideOnPrint
        })}
      >
        {this.renderField(data, field, record)}
      </DataComponent>
    );
  }

  render() {
    const {className, fields, wrapperComponent: WrapperComponent, ...props} = this.props;

    // TODO: move wrapper component out to call site and return an array once we start using react 16
    return (
      <WrapperComponent className={className}>
        {fields.map(this.renderDataComponent)}
      </WrapperComponent>
    );
  }
}

export default connect(
  createStructuredSelector({
    filters: filterSelector
  }),
  {doInstruction}
)(ExplorerDataRenderer);
