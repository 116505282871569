import React from 'react';
import cx from 'classnames';
import Spinner from 'components/spinner';
import styles from './pageBody.scss';

export default function PageBody({children, isLoading, className, noPaddingBottom}) {
  return (
    <div className={cx(styles.pageBody, className, {[styles.noPaddingBottom]: noPaddingBottom})}>
      {isLoading ? <Spinner marginTop={100} /> : children}
    </div>
  );
}
