import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {locationSelector} from 'modules/location/locationSelectors';
import ControlsComponent from 'components/control/controls';

export class Controls extends React.Component {
  render() {
    const {testId, actionId, config, filter} = this.props;

    return (
      <ControlsComponent
        controls={controls.map(c => {
          return {type: 'button', ...c};
        })}
        context={{actionId, testId, ...config}}
        filters={filter}
      />
    );
  }
}

export default connect(
  createSelector(
    locationSelector,
    ({query = {}, params}) => {
      return {testId: params.testId, actionId: params.actionId};
    }
  )
)(Controls);
