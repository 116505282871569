import cx from 'classnames';
import React from 'react';
import styles from './screenshot.scss';
import Spinner from 'components/spinner';
import ExternalLink from 'modules/location/externalLink';

class Screenshot extends React.Component {
  render() {
    let {result, config} = this.props;

    if (typeof result === 'string') {
      return <img src={result} className={cx('img-responsive', styles.mobileStandalone)} />;
    }

    if (!result.desktopScreenshot) {
      return <Spinner centered />;
    }

    const screenshots = [];

    if (result.desktopScreenshot) {
      screenshots.push(
        <ExternalLink href={result.desktopUrl} key={result.desktopPageHash}>
          <img
            src={result.desktopScreenshot}
            key="desktop"
            className={cx('img-responsive', styles.desktop, {
              [styles.hasMobile]: !!result.mobileScreenshot
            })}
          />
        </ExternalLink>
      );
    }

    if (result.mobileScreenshot) {
      screenshots.push(
        <ExternalLink href={result.mobileUrl} key={result.mobilePageHash}>
          <img
            src={result.mobileScreenshot}
            key="mobile"
            className={cx('img-responsive', styles.mobile)}
          />
        </ExternalLink>
      );
    }

    return <div className={cx(styles.wrapper)}>{screenshots}</div>;
  }
}

export default Screenshot;
