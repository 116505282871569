import React from 'react';
import cx from 'classnames';
import Icon from 'components/font-awesome';
import Dropdown from 'components/dropdown';
import styles from './breadcrumb.scss';

export default function BreadcrumbLink({
  label: propLabel,
  value,
  options,
  showBreakline,
  onClick,
  children
}) {
  // `options` assumes [{label, value}, ...]
  // we need to find the matching `label` for the given `value`
  const label = propLabel ||
    children ||
    options.reduce(function(returnValue, option) {
      if (returnValue) return returnValue; // skip if we have found a value
      return option.value === value ? option.label : null;
    }, null) || <Icon name="redo-alt" spin />;

  return (
    <span
      className={cx(styles.link, {
        [styles.showBreakline]: showBreakline,
        [styles.clickable]: !!onClick
      })}
      onClick={event => {
        onClick && onClick(value, false);
      }}
    >
      {label}

      {options && (
        <React.Fragment>
          {' '}
          <Dropdown
            stopPropagation
            onChange={val => onClick(val, true)}
            className={styles.dropdown}
            button={<Icon className={styles.icon} name="chevron-down" />}
            options={options}
          />
        </React.Fragment>
      )}
    </span>
  );
}
