exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1o80xyPs76OlxqG00CPydi,._1P0EoeZJeJTM5qJ_jrTotC{min-height:110px;text-align:left;color:#030303;position:relative;margin:1em}._3WiGT2DLyYsdn3gLhWS-pl{margin-top:30px}._1waj1wpJz6uKwoQ-GUOZfj{margin:40px 0;border-color:#ccc}._3It0xy3hOxwZH7l-wDKZvt{margin:0 0 30px 0}._1P0EoeZJeJTM5qJ_jrTotC{margin:0;margin-bottom:20px}.Toc5SEQw7BlO0tyYPcK5B{background:#fff;opacity:.55;width:100%;height:100%;position:absolute;left:0;top:0;padding-top:60px;z-index:1}.DTBdQ3O8hfvck9jQlSTsy{margin:40px 20px}.mnhnWkzLdYXWD5xttLkTo{max-width:250px;margin:0 auto}._3J6JnBwWl6NGsFNVcIJ8L-{width:100%;font-size:20px;font-weight:bold}._1EQYtYUgVB5_LtuRJeGrtj{display:inline-block;margin-bottom:15px}.gv5EjOd6ewee9Eb_8O_OU{width:45%;min-width:250px}.gv5EjOd6ewee9Eb_8O_OU span:not(:first-child){float:right}._1ceimanE6lbtOLMt-jgnE-{width:45%;min-width:250px}._1_Qbxm9GMx_3T1EhEiJ65j{width:30%;min-width:175px}._8qFeVi18eg9HqwwL3RFDN{width:30%;min-width:150px}.syOnky7CuVGuhpjbrKsxF{display:inline-block;margin-right:10px;font-weight:bold;min-width:70px}._28p-1-kUMb6b9PnfHEh3xt{color:#df1616;font-weight:bold}", ""]);

// exports
exports.locals = {
	"main": "_1o80xyPs76OlxqG00CPydi",
	"paymentDetails": "_1P0EoeZJeJTM5qJ_jrTotC",
	"bottom": "_3WiGT2DLyYsdn3gLhWS-pl",
	"divider": "_1waj1wpJz6uKwoQ-GUOZfj",
	"sectionTitle": "_3It0xy3hOxwZH7l-wDKZvt",
	"fade": "Toc5SEQw7BlO0tyYPcK5B",
	"error": "DTBdQ3O8hfvck9jQlSTsy",
	"details": "mnhnWkzLdYXWD5xttLkTo",
	"title": "_3J6JnBwWl6NGsFNVcIJ8L-",
	"field": "_1EQYtYUgVB5_LtuRJeGrtj",
	"name": "gv5EjOd6ewee9Eb_8O_OU",
	"number": "_1ceimanE6lbtOLMt-jgnE-",
	"type": "_1_Qbxm9GMx_3T1EhEiJ65j",
	"expiry": "_8qFeVi18eg9HqwwL3RFDN",
	"label": "syOnky7CuVGuhpjbrKsxF",
	"expired": "_28p-1-kUMb6b9PnfHEh3xt"
};