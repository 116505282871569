exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2cgQ-5SWiaObTyfEWPi9Fb{padding:20px 20px;min-height:calc(100vh - 55px-110px);padding-top:0}.h3bfjiRHdnR5xbklI8pv1{margin-top:100px}._2LDP2Hb9xr6tkKLtBHbDSw{margin:8px;overflow:hidden}._2jELy2Nh2jSR1oRZupokvY{padding:0}._2IUBaI7OQeIBfYw8TwdxI6{margin-left:8px}._1SseRavYjJVgv9iGwd6LxW{margin-bottom:20px}._1SseRavYjJVgv9iGwd6LxW h2{margin-top:10px}._1SseRavYjJVgv9iGwd6LxW h3{margin-bottom:20px}._2qTupU67kuWKhp6FxpoVR7{width:100%;flex-grow:2;flex-basis:calc(66.6% - (2 * 20px))}._1v4LwGERC9ojR-aLTe2AR0{padding-top:10px;padding-left:20px;margin-left:20px;border-left:solid 1px #ddd;min-height:calc(100vh - (55px - 110px - calc(2 * 20px)));flex-grow:1;flex-basis:calc(33.3% - (2 * 20px))}._26CLkqxf2lXK1N4puQHwPl{display:flex;flex:1 0 160px;justify-content:flex-start;margin-bottom:10px}._26CLkqxf2lXK1N4puQHwPl .gX_bLcOCAArZMALdWbC4K{margin:0;margin-right:10px;width:50px;height:50px}.limAsARF_G0xEzYOuTXXH{display:flex;align-items:flex-start;flex-direction:column;justify-content:center}.limAsARF_G0xEzYOuTXXH .qINPZt00ng6RqFpCU79BD{display:inline-block;margin-top:5px}._1Z419yqd-qKEZUOim9lgyk{margin-top:8px}._3gglZ0H4gC20ruii8A0AKs{margin-top:15px;font-size:.8em;padding:5px 10px}._3LG0s5YVIWW1nsfyeb7zwm{display:block;margin-bottom:10px}._3LG0s5YVIWW1nsfyeb7zwm:last-of-type{margin-bottom:0}._3LG0s5YVIWW1nsfyeb7zwm ._3A42rZj_1UB3K500ahy6jY{margin-right:5px}._3LG0s5YVIWW1nsfyeb7zwm ._1s9beps-ifsN3ibwMMTPn9{margin-left:20px}._3C1SJ-7FlFg0890V0ftNea{display:flex;flex-wrap:wrap}@media screen and (max-width: 1024px){._1v4LwGERC9ojR-aLTe2AR0{flex-basis:100%;border-left:0;padding-left:0;margin-top:30px;margin-left:0;text-align:center}._3C1SJ-7FlFg0890V0ftNea{justify-content:center}._26CLkqxf2lXK1N4puQHwPl{flex:0 0 160px;margin:10px}}", ""]);

// exports
exports.locals = {
	"homePage": "_2cgQ-5SWiaObTyfEWPi9Fb",
	"loading": "h3bfjiRHdnR5xbklI8pv1",
	"card": "_2LDP2Hb9xr6tkKLtBHbDSw",
	"emptyFolder": "_2jELy2Nh2jSR1oRZupokvY",
	"noReports": "_2IUBaI7OQeIBfYw8TwdxI6",
	"section": "_1SseRavYjJVgv9iGwd6LxW",
	"lhs": "_2qTupU67kuWKhp6FxpoVR7",
	"rhs": "_1v4LwGERC9ojR-aLTe2AR0",
	"user": "_26CLkqxf2lXK1N4puQHwPl",
	"image": "gX_bLcOCAArZMALdWbC4K",
	"info": "limAsARF_G0xEzYOuTXXH",
	"infoPill": "qINPZt00ng6RqFpCU79BD",
	"btnGroup": "_1Z419yqd-qKEZUOim9lgyk",
	"btn": "_3gglZ0H4gC20ruii8A0AKs",
	"updateLink": "_3LG0s5YVIWW1nsfyeb7zwm",
	"icon": "_3A42rZj_1UB3K500ahy6jY",
	"date": "_1s9beps-ifsN3ibwMMTPn9",
	"usersContainer": "_3C1SJ-7FlFg0890V0ftNea"
};