import styles from './article.scss';
import React from 'react';

export default class Article extends React.Component {
  render() {
    const {children} = this.props;

    return <div className={styles.article}>{children}</div>;
  }
}
