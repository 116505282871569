import _ from 'lodash';
import {Map} from 'immutable';
import React, {Fragment} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import evaluate from 'token-js-parser';
import {CSSTransitionGroup} from 'react-transition-group';
import Tooltip from 'components/tooltip';
import TableRow from './tableRow';
import Icon from 'components/font-awesome';
import {getItemKey} from 'utils/layoutUtils';
import styles from './table.scss';
import cellStyles from 'components/layoutComponents/renderers/styles.scss';
import {arrayGroupBy} from 'utils/arrayUtils';
import {Teaser} from 'components/teaser';

// TODO PICK FROM BACKEND?
const TEASER_LIMIT = 5;

class Table extends React.Component {
  static propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    hover: PropTypes.bool,
    striped: PropTypes.bool
  };

  static defaultProps = {
    hover: false,
    striped: false,
    currentSort: ''
  };

  constructor(props) {
    super(props);
    this.state = {width: null, isPrinting: false};

    this.table = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    // cancel the debounce
    this.handleResize.cancel();

    if (this.table.current) {
      this.setState({width: this.table.current.clientWidth});
    }
    window.addEventListener('beforeprint', this.handlePrintChange);
    window.addEventListener('afterprint', this.handlePrintChange);
  }

  componentDidUpdate() {
    if (this.table.current.clientWidth !== this.state.width) {
      this.setState({width: this.table.current.clientWidth});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    // cancel the debounce
    this.handleResize.cancel();

    window.removeEventListener('beforeprint', this.handlePrintChange);
    window.removeEventListener('afterprint', this.handlePrintChange);
  }

  handlePrintChange = event => {
    this.setState({isPrinting: event.type === 'beforeprint'});
  };

  handleResize = _.debounce(() => {
    if (this.table) {
      this.setState({width: this.table.current.clientWidth});
    }
  }, 200);

  handleClickColumn = column => e => {
    const {onClickColumn} = this.props;

    onClickColumn && onClickColumn(column, e);
  };

  handleClickRow = (row, column, e) => {
    const {onClick} = this.props;

    onClick && onClick(row, column, e);
  };

  getHeaderStyle({width, style = {}}, index) {
    const newStyle = {...style};

    if (width) {
      let newWidth = width;

      // Assume pixels if unspecified (React complains otherwise)
      if (/^\d+$/.test(width)) {
        newWidth += 'px';
      }
      newStyle.width = newWidth;
    }

    return newStyle;
  }

  getHeaderComponent(label) {
    if (typeof label === 'string') {
      return <span>{label}</span>;
    }

    return label;
  }

  getHeaderContent(column) {
    const {label, tooltip} = column;

    if (tooltip) {
      return <Tooltip text={tooltip}>{this.getHeaderComponent(label)}</Tooltip>;
    }

    return this.getHeaderComponent(label);
  }

  renderHeaderCell = (column, index) => {
    const {collapse, type, className} = column;
    const classes = cx(className, cellStyles[type] || styles[type], {
      [styles.sticky]: column.sticky,
      [styles.hideOnPrint]: column.hideOnPrint
    });
    let title = this.getHeaderContent(column);

    if (column.show && !evaluate(column.show, {filters: this.props.filter})) {
      return null;
    }

    // @todo Fix this bullshit please
    if (type === 'currency') {
      title = <div style={{textAlign: 'right'}}>{title}</div>;
    }

    if (collapse && this.state.width && this.state.width < collapse) {
      return null;
    }

    const {currentSort} = this.props;

    let sortOrder = '';
    let shouldDisplaySort;

    if (!currentSort) {
      shouldDisplaySort = false;
    } else {
      const [sort, _order] =
        currentSort.indexOf(':') < 0 ? currentSort.split(/\s+/, 2) : currentSort.split(':', 2);
      const order =
        _order === 'a' || _order === 'asc'
          ? '-up'
          : _order === 'd' || _order === 'desc'
          ? '-down'
          : '';

      if (sort === column.sortable) {
        sortOrder = 'sort' + order; // used as icon
      }

      shouldDisplaySort = !!column.sortable && !!sortOrder;
    }

    const sortableClass = !!column.sortable ? styles.sortableWrapper : undefined;

    const content = !shouldDisplaySort ? (
      <div className={sortableClass}>{title}</div>
    ) : (
      <div className={sortableClass}>
        {title}
        {/* Don't show sort icon when neither asc or desc. */}
        {sortOrder !== 'sort' && <Icon name={sortOrder} className={styles.sortableIcon} />}
      </div>
    );

    return (
      <th
        key={index}
        className={classes}
        style={this.getHeaderStyle(column, index)}
        onClick={this.handleClickColumn(column)}
      >
        {content}
      </th>
    );
  };

  renderHeader() {
    if (this.props.hideHeader) {
      return null;
    }

    return (
      <thead>
        <tr>{this.props.columns.map(this.renderHeaderCell)}</tr>
      </thead>
    );
  }

  getRowClass(row, highlight) {
    const className = row[highlight];
    return className ? styles[className] : '';
  }

  renderRows() {
    const {
      rowComponent,
      highlight,
      rows,
      rowKey,
      filter,
      configKey,
      classifications,
      renderGroup,
      isTeaser,
      ...rowProps
    } = this.props;

    // Allow user to override which component we use for a row.
    const RowComponent = rowComponent || TableRow;

    const mapRows = this.simpleRender({
      RowComponent,
      highlight,
      filter,
      configKey,
      rowProps,
      rowKey
    });

    if (!rows || !rows.length) return <tbody />;

    const colCount = getColCount(rowProps.columns, filter, this.state.width, this.state.isPrinting);

    const getRowsWithTeaser = rows => {
      const rowsWithTeaser = [...rows];
      let teaserIndex = TEASER_LIMIT;
      if (rows.length < TEASER_LIMIT + 1) {
        teaserIndex = rows.length;
      }

      if (rows.length > 0) {
        rowsWithTeaser.splice(teaserIndex, 0, {isTeaser: true, colCount});
      }

      return rowsWithTeaser;
    };

    if (classifications) {
      const groups = arrayGroupBy(rows, 'classification');
      const groupKeys = Object.keys(groups);
      let teaserRendered = false;

      const renderGroupRows = groupRows => {
        if (!teaserRendered && isTeaser) {
          teaserRendered = true;
          const teaserRows = getRowsWithTeaser(groupRows);
          return teaserRows.map(mapRows);
        }
        return groupRows.map(mapRows);
      };

      return (
        <Transition>
          {groupKeys.map(groupKey => {
            return (
              <React.Fragment key={groupKey}>
                {renderGroup(classifications[groupKey], colCount)}
                {renderGroupRows(groups[groupKey])}
              </React.Fragment>
            );
          })}
        </Transition>
      );
    }

    if (isTeaser && rows.length > 0) {
      const teaserRows = getRowsWithTeaser(rows);
      return <Transition>{teaserRows.map(mapRows)}</Transition>;
    }

    return <Transition>{rows.map(mapRows)}</Transition>;
  }

  simpleRender = ({RowComponent, highlight, filter, configKey, rowProps, rowKey}) => (
    row,
    index
  ) => {
    if (row.isTeaser) {
      return <Teaser key="teaser" width={this.state.width} colCount={row.colCount} />;
    }

    const childKey = row[configKey] != null ? row[configKey] : getItemKey(row, rowKey, index);
    const className = highlight ? this.getRowClass(row, highlight) : '';

    return (
      <RowComponent
        key={childKey}
        keyValue={childKey} // this allows us to read key from row
        row={row}
        filter={filter}
        className={className}
        width={this.state.width}
        {...rowProps}
      />
    );
  };

  render() {
    const {
      className,
      striped,
      hover,
      dark,
      table: tableFlag,
      onClick,
      sideScroll,
      freezeFirstColumn
    } = this.props;
    const classes = cx(styles.table, className, {
      [styles.dark]: dark,
      [styles.table]: tableFlag,
      [styles.hover]: hover || onClick,
      [styles.striped]: striped,
      [cellStyles.sideScrollTable]: sideScroll,
      [cellStyles.freezeFirstColumn]: freezeFirstColumn
    });

    const table = (
      <table className={classes} ref={this.table}>
        {this.renderHeader()}
        {this.renderRows()}
      </table>
    );

    if (freezeFirstColumn) {
      return (
        <div className={cellStyles.tableWrap}>
          <div className={cellStyles.tableScroll}>{table}</div>
        </div>
      );
    }

    return table;
  }
}

export default Table;

// TODO this functionality if basically copied in TableRow.
// Maybe it should exist here instead and we just pass the correct cols to TableRow
function getColCount(columns, filters, tableWidth, isPrinting) {
  return columns.filter(field => {
    if (field.show && !evaluate(field.show, {filters})) {
      return false;
    }
    if (field.collapse && tableWidth && tableWidth < field.collapse) {
      return false;
    }
    if (isPrinting && field.hideOnPrint) {
      return false;
    }
    return true;
  }).length;
}

function Transition({children}) {
  return <tbody>{children}</tbody>;

  // re-enabled when we fix the CSS
  return (
    <CSSTransitionGroup
      transitionName="fade"
      component="tbody"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}
    >
      {children}
    </CSSTransitionGroup>
  );
}
