exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._6qVWnT3x-waW2IumHj46N h1{text-align:center;font-size:30px}._6qVWnT3x-waW2IumHj46N h2{text-align:center;font-weight:bold;margin:0 0 20px}.t7TPA8OWy1oEjaQW3QOt8 label{text-align:center;width:100%}._1aGJgZJFx7mV7e9GkKQuKT{width:300px;margin:0 auto}._2qitzqGdQo6JxC5U97mcY1{max-width:400px;margin:0 auto}.s7ug7FrvycK18Ks6NmCq3 button:disabled{opacity:.6}", ""]);

// exports
exports.locals = {
	"wrapper": "_6qVWnT3x-waW2IumHj46N",
	"fieldCentralWrapper": "t7TPA8OWy1oEjaQW3QOt8",
	"paymentMethod": "_1aGJgZJFx7mV7e9GkKQuKT",
	"form": "_2qitzqGdQo6JxC5U97mcY1",
	"submitControls": "s7ug7FrvycK18Ks6NmCq3"
};