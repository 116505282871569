import React from 'react';
import {connect} from 'react-redux';
import {examplesFolderPath} from 'modules/folders/folderUtils';
import {push} from 'modules/location';
import {ECOMMERCE_TRIAL} from 'modules/accounts/accountConstants';
import styles from './exampleReportsView.scss';
import ReportCard from 'modules/reports/components/reportCard';
import {exampleReportsViewSelector} from 'modules/folderViews/folderViewSelectors';
import BuyButton from 'modules/onboarding/components/buyButton';
import {reportPath} from 'modules/reports/reportUtils';
import Flex from 'components/layout/flex';

export class ExampleReportsView extends React.Component {
  handleClickCard = reportId => () => {
    this.props.push(reportPath, {reportId});
  };

  renderReport = result => {
    return (
      <Flex key={result.reportId} grow={1} shrink={0} basis="300px" className={styles.card}>
        <ReportCard report={result} onClick={this.handleClickCard(result.reportId)} />
      </Flex>
    );
  };

  render() {
    const {exampleReports} = this.props;

    if (!exampleReports || !exampleReports.length) {
      return null;
    }

    return (
      <div className={styles.container}>
        <hr className={styles.hr} />
        <h3>Upgrade to test more</h3>
        <p className={styles.examplesPara}>
          Our paid reports include over 32 tests, from SEO to Accessibility. Look at some examples:
        </p>
        <Flex container center wrap>
          {exampleReports && exampleReports.map(this.renderReport)}
        </Flex>
        <BuyButton cta className={styles.buyBtn}>
          Upgrade now
        </BuyButton>
      </div>
    );
  }
}

export default connect(
  exampleReportsViewSelector,
  {push}
)(ExampleReportsView);
