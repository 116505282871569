import {Map, List} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

// TODO: should this functionality be merged with missions somehow?

export const LOG_KEY = 'missionId';

const initialState = Map({
  data: Map(),
  status: Map({
    isFetchingLogs: false
  })
});

export default function logsReducer(state = initialState, action) {
  const {logs} = action;

  switch (action.type) {
    case RECEIVE_REPORT_LOGS:
      state = updateFetchingState(state, false);
      return state.setIn(['data'], keyBy(logs, LOG_KEY));
    case FETCH_REPORT_LOGS:
      return updateFetchingState(state, true);
    default:
      return state;
  }
}

export const FETCH_REPORT_LOGS = 'logs:FETCH_REPORT_LOGS';
export function fetchReportLogs() {
  return {
    type: FETCH_REPORT_LOGS
  };
}

export const RECEIVE_REPORT_LOGS = 'logs:RECEIVE_REPORT_LOGS';
export function receiveReportLogs(logs) {
  return {
    type: RECEIVE_REPORT_LOGS,
    logs
  };
}

export const REQUEST_UPDATE_REPORT_LOG = 'logs:REQUEST_UPDATE_REPORT_LOG';
export function updateReportLogState(missionId, config) {
  return {
    type: REQUEST_UPDATE_REPORT_LOG,
    missionId,
    config
  };
}

function updateFetchingState(state, isFetching) {
  return state.mergeIn(['status'], {
    isFetchingLogs: isFetching
  });
}
