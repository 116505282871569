export {default} from './reportsReducer';
import {reportSettingsPath, printReportPath} from './reportUtils';
import {push} from 'modules/location/location';
import {
  ADD_REPORT,
  ADD_REPORT_FAILURE,
  EXPORT_TYPES,
  DELETE_REPORT,
  CONFIRM_DELETE_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_RESET,
  REQUEST_ADD_REPORT,
  RECEIVE_CMS_CONFIG,
  CLEAR_CMS_CONFIG,
  VIEW_REPORT_PAGE,
  FETCH_REPORT,
  FETCH_REPORTS,
  LEAVE_REPORT_PAGE,
  VIEW_REPORT_INDEX_PAGE,
  VIEW_REPORT_LIST,
  VIEW_REPORT_SETTINGS_PAGE,
  VIEW_REPORT_CMS_SETTINGS_PAGE,
  REQUEST_RETEST_REPORT,
  RETEST_REPORT,
  REPORT_RETEST_BEGAN,
  RETEST_PAGE,
  REQUEST_CHANGE_PAGE_STATE,
  REQUEST_EDIT_PAGE,
  RECEIVE_REPORTS,
  RECEIVE_REPORT,
  RECEIVE_EXTRA_INFO,
  REPORT_DROP,
  REPORT_TO_FOLDER,
  REQUEST_REMOVE_REPORT_FROM_FOLDER,
  REMOVE_REPORT_FROM_FOLDER,
  TRIGGER_EXPORT_REPORT,
  TRIGGER_EXPORT_EXPLORER,
  EXPORT_REPORT,
  EXPORT_REPORT_COMPLETE,
  VIEW_PRINT_TESTS_PAGE,
  UPDATE_WATCHING_REPORT,
  SET_WATCHING_REPORT,
  PAGE_SIZES,
  CLEAR_REPORT,
  UPDATE_REPORT_SCHEDULE,
  UPDATE_REPORT_SCHEDULE_VALUE,
  FETCH_REPORT_SCHEDULE,
  RECIEVE_REPORT_ASSIGNED_TAGS,
  FETCH_FORCED_PAGES,
  ADD_FORCED_PAGE,
  RECEIVE_FORCED_PAGES,
  RECEIVE_FORCED_PAGE,
  CONFIRM_DELETE_FORCED_PAGE,
  DELETE_FORCED_PAGE,
  EDIT_FORCED_PAGE
} from './reportConstants';
import {analytics, events} from 'modules/analytics';
import {reportPath} from './reportUtils';

export function addReport(report) {
  return {
    type: ADD_REPORT,
    report
  };
}

export function addReportFailure() {
  return {
    type: ADD_REPORT_FAILURE,
    ...analytics(events.REPORT_ADD_FAILURE)
  };
}

// deletes a report without confirmation
export function deleteReport(reportId, redirectAfter = false) {
  return {
    type: DELETE_REPORT,
    reportId,
    redirectAfter
  };
}

// deletes a report with confirmation
export function confirmDeleteReport(reportId, folderId, redirectAfter = false) {
  return {
    type: CONFIRM_DELETE_REPORT,
    reportId,
    // we need the folderId so we know which folder to remove the
    // report from if the report is removed from this folder
    folderId,
    redirectAfter
  };
}

export function clearReport(reportId) {
  return {
    type: CLEAR_REPORT,
    reportId
  };
}

export function updateReport(reportId, data, successMessage) {
  return {
    type: UPDATE_REPORT,
    reportId,
    data,
    successMessage
  };
}

export function resetUpdatingReport() {
  return {
    type: UPDATE_REPORT_RESET
  };
}

export function requestAddReport(folderId = null) {
  return {
    type: REQUEST_ADD_REPORT,
    folderId
  };
}

export function viewReportPage(reportId, folderId) {
  return {
    type: VIEW_REPORT_PAGE,
    reportId,
    folderId
  };
}

export function fetchReport(reportId) {
  return {
    type: FETCH_REPORT,
    reportId
  };
}

export function fetchReports(simple = false, fields = []) {
  return {
    type: FETCH_REPORTS,
    simple,
    fields
  };
}

export function leaveReportPage() {
  return {
    type: LEAVE_REPORT_PAGE
  };
}

export function viewReportIndexPage(reportId) {
  return {
    type: VIEW_REPORT_INDEX_PAGE,
    reportId
  };
}

export function viewReportList(folderId) {
  return {type: VIEW_REPORT_LIST, folderId};
}

export function viewReportSettingsPage(reportId) {
  return {
    type: VIEW_REPORT_SETTINGS_PAGE,
    reportId
  };
}

export function viewReportCMSSettingsPage(reportId) {
  return {
    type: VIEW_REPORT_CMS_SETTINGS_PAGE,
    reportId
  };
}

// shows retest modal
export function requestRetestReport(reportId, reportType, preventOpenModal = false) {
  return {
    type: REQUEST_RETEST_REPORT,
    reportId,
    reportType,
    preventOpenModal
  };
}

export function retestReport(reportId, urls = []) {
  return {
    type: RETEST_REPORT,
    reportId,
    urls
  };
}

// if we refresh a page and a website mission is happening, we need to tell the pollForReportMissionSaga to begin polling
export function reportRetestBegan(missionId) {
  return {type: REPORT_RETEST_BEGAN, missionId};
}

export function retestPage(reportId, pageHash, prioritiseTestId = null) {
  return {
    type: RETEST_PAGE,
    reportId,
    pageHash,
    prioritiseTestId
  };
}

// User wants to exclude page. Optionally passing a metricName matching the metric that requested the exclusion.
function requestChangePageState(newState, reportId, pageHash, testId) {
  return {
    type: REQUEST_CHANGE_PAGE_STATE,
    newState,
    reportId,
    pageHash,
    testId
  };
}

export function requestExcludePage(reportId, pageHash, testId) {
  return requestChangePageState('exclude', ...arguments);
}

export function requestIncludePage(reportId, pageHash, testId) {
  return requestChangePageState('include', ...arguments);
}

// The user wants to edit the page in their CMS
export function requestEditPage(reportId, pageHash) {
  return {
    type: REQUEST_EDIT_PAGE,
    reportId,
    pageHash
  };
}

export function receiveReports(reports, folderId) {
  return {
    type: RECEIVE_REPORTS,
    reports,
    folderId
  };
}

export function receiveReport(report, overwrite = false) {
  return {
    type: RECEIVE_REPORT,
    report,
    overwrite
  };
}

export function receiveCMSConfig(cmsConfig) {
  return {
    type: RECEIVE_CMS_CONFIG,
    cmsConfig
  };
}

export function clearCMSConfig() {
  return {
    type: CLEAR_CMS_CONFIG
  };
}

// Extra steps in new report modal flow.
export function receiveExtraInfo(info) {
  return {
    type: RECEIVE_EXTRA_INFO,
    info
  };
}

export function handleReportDrop(reportId, fromFolder, monitor) {
  return {
    type: REPORT_DROP,
    reportId,
    fromFolder,
    didDrop: monitor.didDrop(),
    dropResult: monitor.getDropResult() || {}
  };
}

export function moveReportToFolder(reportId, fromFolder, toFolder) {
  return {type: REPORT_TO_FOLDER, reportId, fromFolder, toFolder, mode: 'move'};
}

export function copyReportToFolder(reportId, toFolder) {
  return {type: REPORT_TO_FOLDER, reportId, toFolder, mode: 'copy'};
}

export function requestRemoveReportFromFolder(reportId, folderId) {
  return {type: REQUEST_REMOVE_REPORT_FROM_FOLDER, reportId, folderId};
}

export function removeReportFromFolder(reportId, folderId) {
  return {type: REMOVE_REPORT_FROM_FOLDER, reportId, folderId};
}

// Goes to report settings page
export function editReport(reportId) {
  return push(reportSettingsPath, {reportId});
}

export function triggerExportReport(reportId, testId, exportType = null) {
  return {type: TRIGGER_EXPORT_REPORT, reportId, testId, exportType};
}

export function triggerExportExplorer(reportId, testId, table = null, filters = {}) {
  return {type: TRIGGER_EXPORT_EXPLORER, reportId, testId, table, filters};
}

// an empty array of `testIds` signifies all tests
function exportReport(reportId, testIds, exportType, exportTypeOptions = {}, filters = {}) {
  return {
    type: EXPORT_REPORT,
    reportId,
    testIds,
    exportType,
    options: exportTypeOptions,
    filters
  };
}

export function exportExplorer(reportId, testId, exportType, tableId, filters = {}) {
  return {
    ...exportReport(reportId, [testId], exportType, {tableId}, filters)
  };
}

export function exportReportCsvs(reportId, testIds = [], filters = {}) {
  return exportReport(reportId, testIds, EXPORT_TYPES.CSV, {getAll: true}, filters);
}

export function exportReportPdf(
  reportId,
  testIds = [],
  paperSize = PAGE_SIZES.LETTER,
  filters = {}
) {
  return exportReport(reportId, testIds, EXPORT_TYPES.PDF, {paperSize}, filters);
  // TODO this is the new adam way to export a pdf (re-enable when it's stable)
  // return {
  //   type: EXPORT_REPORT_PDF,
  //   reportId,
  //   testIds,
  //   paperSize,
  //   filters
  // };
}

export function printReport(reportId, testIds = null) {
  // return {type: PRINT_REPORT, reportId, testIds};
  return push(printReportPath, {reportId, testIds});
}

export function exportReportComplete({exportUrl = null, errorMessage = null} = {}) {
  return {type: EXPORT_REPORT_COMPLETE, exportUrl, errorMessage};
}

export function viewPrintTestsPage(reportId, testIds = null) {
  return {
    type: VIEW_PRINT_TESTS_PAGE,
    reportId,
    testIds // null means all tests
  };
}

export function updateWatchingReport(reportId, isWatching) {
  return {type: UPDATE_WATCHING_REPORT, reportId, isWatching};
}

export const setWatchingReport = reportId => ({isWatching}) => {
  return {type: SET_WATCHING_REPORT, reportId, isWatching};
};

export function redirectToReport(reportId) {
  return push(reportPath, {reportId}, ['accountId']);
}

export function updateReportSchedule(reportId, {scheduleInterval, schedulePeriod}) {
  return {
    type: UPDATE_REPORT_SCHEDULE,
    reportId: reportId.toString(),
    scheduleInterval,
    schedulePeriod
  };
}

export function updateReportScheduleValue(reportId, {scheduleInterval, schedulePeriod}) {
  return {
    type: UPDATE_REPORT_SCHEDULE_VALUE,
    reportId: reportId.toString(),
    scheduleInterval,
    schedulePeriod
  };
}

export function fetchReportSchedule(reportId) {
  return {type: FETCH_REPORT_SCHEDULE, reportId: reportId.toString()};
}

export function recieveReportAssignedTags(reportId, tagIds, operation) {
  return {type: RECIEVE_REPORT_ASSIGNED_TAGS, reportId, tagIds, operation};
}

export function fetchForcedPages(reportId, forcedPageId = null, pageNum = 1) {
  return {
    type: FETCH_FORCED_PAGES,
    reportId,
    forcedPageId,
    pageNum
  };
}

export function receiveForcedPages({pages, pagination}) {
  return {
    type: RECEIVE_FORCED_PAGES,
    forcedPages: pages,
    pagination
  };
}

export function receiveForcedPage(forcedPage) {
  return {
    type: RECEIVE_FORCED_PAGE,
    forcedPage
  };
}

export function addForcedPage({url}, reportId) {
  return {
    type: ADD_FORCED_PAGE,
    url,
    reportId
  };
}

export function confirmDeleteForcedPage({reportId, forcedPageId, pageNum}) {
  return {
    type: CONFIRM_DELETE_FORCED_PAGE,
    reportId,
    forcedPageId,
    pageNum
  };
}

export function deleteForcedPage(reportId, forcedPageId, pageNum) {
  return {
    type: DELETE_FORCED_PAGE,
    reportId,
    forcedPageId,
    pageNum
  };
}

export function editForcedPage({url}, reportId, forcedPageId) {
  return {
    type: EDIT_FORCED_PAGE,
    url,
    reportId,
    forcedPageId
  };
}
