exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ERu4l98JilasWCmubfdrZ{white-space:nowrap;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content}._1ERu4l98JilasWCmubfdrZ button{margin:0}._1f5Gr-jMu3lZEQqMZ0DLSu{cursor:pointer;font-size:16px;background:#343434;border:1px solid #222;color:#fff;outline:none;transition:all .3s;padding:4px 12px}._1f5Gr-jMu3lZEQqMZ0DLSu:first-of-type{border-top-left-radius:5px;border-bottom-left-radius:5px}._1f5Gr-jMu3lZEQqMZ0DLSu:last-of-type{border-top-right-radius:5px;border-bottom-right-radius:5px}._1f5Gr-jMu3lZEQqMZ0DLSu:hover,._1f5Gr-jMu3lZEQqMZ0DLSu._3Y7RffNdcwNxEsSTlv-y7N{background:#6f6f6f;border:1px solid #222;color:#fff}", ""]);

// exports
exports.locals = {
	"buttonSwitcher": "_1ERu4l98JilasWCmubfdrZ",
	"button": "_1f5Gr-jMu3lZEQqMZ0DLSu",
	"selected": "_3Y7RffNdcwNxEsSTlv-y7N"
};