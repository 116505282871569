import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {accountSelector, featuresSelector} from 'modules/auth/authSelectors';

const accountTypeState = state => state.accountTypes;

export const accountTypesSelector = createSelector(
  accountTypeState,
  state => state.get('accountTypes')
);

export const currentAccountTypeSelector = createSelector(
  accountTypesSelector,
  accountSelector,
  (accountTypes, account) => {
    if (!account) return Map();
    return accountTypes.get(account.get('accountTypeId')) || Map();
  }
);

export const currentAccountTypeGroupSelector = createSelector(
  currentAccountTypeSelector,
  accountTypeGroup => {
    return {accountTypeGroup};
  }
);

export const currentAccountTypeSearchEngineSelector = createSelector(
  currentAccountTypeSelector,
  featuresSelector,
  (accountTypeGroup, {features}) => {
    if (features.get('SearchThemesFeatureFlag')) {
      // no limits for new feature
      return {maxKeywordsAllowed: undefined, maxSearchEnginesAllowed: undefined};
    }
    return {
      maxKeywordsAllowed: accountTypeGroup.get('accountTypeGroupId') === 'enterprise' ? 75 : 15,
      maxSearchEnginesAllowed: accountTypeGroup.get('accountTypeGroupId') === 'enterprise' ? 5 : 2
    };
  }
);
