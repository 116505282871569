import React from 'react';
import {connect} from 'react-redux';
import {fromJS, Map, List} from 'immutable';
import Pill from 'components/pill';
import Help from 'modules/help/containers/help';
import Panel from 'components/panel';
import Flex from 'components/layout/flex';
import DeleteIcon from 'components/icons/deleteIcon';
import ToggleSwitch from 'components/toggleSwitch/toggleSwitch';
import {makeDefaultData} from 'modules/policies/policyUtils';
import {checksSelector} from '../selectors';
import styles from './policyRule.scss';
import SchemaFields from 'modules/form/components/schemaField';
import {useSelector} from 'hooks/redux';

export default function PolicyRuleField(props) {
  const {field, first, onDelete, ruleOperator} = props;
  const allChecks = useSelector(checksSelector, props);
  const scopeChecks = allChecks.get(props.scope, List());
  const check = scopeChecks.find(c => c.get('policyCheckId') === props.policyCheckId);
  const fields = field.getFields();

  return (
    <React.Fragment>
      <Flex container column basis="300px">
        <Flex container>
          <Flex left right container>
            <Flex right={10}>
              <Pill appearance="major">{first ? 'IF' : ruleOperator.toUpperCase()}</Pill>
            </Flex>
            <Flex>
              <ToggleSwitch
                className={styles.toggle}
                checked={!fields.isNot.input.value}
                onChange={value => {
                  fields.isNot.input.onChange(!value);
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <div className={styles.policyRule}>
            <Panel toolbar className={styles.panelHead}>
              <Flex container>
                <Flex container>
                  <h3>Match {check.get('name')}</h3>
                  {check.get('helpUrl') && <Help url={check.get('helpUrl')} />}
                </Flex>
                <Flex basis="20px" right>
                  <DeleteIcon onClick={onDelete} />
                </Flex>
              </Flex>
            </Panel>

            <Panel marginBottom hideBorderTop>
              <SchemaFields
                field={fields.options}
                supportedOptions={check.get('supportedOptions')}
                onAddRepeatingField={(fieldSchema, field) => {
                  const defaultData = makeDefaultData(fieldSchema.get('children').toJS(), {});
                  field.push(defaultData);
                  field.rerender();
                }}
              />
            </Panel>
          </div>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}
