import React from 'react';
import PropTypes from 'prop-types';
import GOOD from './good.png';
import OK from './ok.png';
import BAD from './bad.png';

const ICONS = {
  good: GOOD,
  info: OK,
  warning: OK,
  bad: BAD
};

const Face = ({appearance, className}) => {
  return <img src={ICONS[appearance]} className={className} alt=" " />;
};

Face.propTypes = {
  appearance: PropTypes.oneOf(['good', 'info', 'warning', 'bad']).isRequired
};

export default Face;
