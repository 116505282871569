exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3bZD_L79jEmUvR4LSi39Kq{width:30px}._3vq6y0fqyGy-4U-IuWj1Pg{margin-bottom:5px}._3fhQao5XCNjCAjdgC0x4FI{margin:5px 0;opacity:.5}", ""]);

// exports
exports.locals = {
	"icon": "_3bZD_L79jEmUvR4LSi39Kq",
	"name": "_3vq6y0fqyGy-4U-IuWj1Pg",
	"categories": "_3fhQao5XCNjCAjdgC0x4FI"
};