import React from 'react';
import Button from 'components/button';

const ALL_ITEMS = 0;

/**
 * Passes an array of items to children components
 */
class ShowMore extends React.Component {
  static defaultProps = {
    increment: 250,
    itemsName: 'items'
  };

  constructor(props) {
    super(props);

    const {initialCount} = props;
    this.state = {maxItems: initialCount || 100};
  }

  handleClickShowMore = () => {
    const {items, increment} = this.props;
    const {maxItems} = this.state;

    if (maxItems !== ALL_ITEMS && maxItems < items.length) {
      let newMaxItems = maxItems;

      newMaxItems += increment;

      if (newMaxItems > items.length) {
        newMaxItems = ALL_ITEMS;
      }

      this.setState({maxItems: newMaxItems});
    }
  };

  render() {
    const {items, className, children, itemsName} = this.props;
    const {maxItems} = this.state;

    if (!items || !items.length) return <div />;

    let displayItems = items;
    if (maxItems !== ALL_ITEMS) {
      displayItems = items.slice(0, maxItems);
    }

    const showMore = (
      <p className="tableExpandableFooter">
        <Button plain icon="plus" onClick={this.handleClickShowMore}>
          Load more
        </Button>
      </p>
    );

    return (
      <div className={className}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {[itemsName]: displayItems})
        )}
        {maxItems !== ALL_ITEMS && items.length > maxItems && showMore}
      </div>
    );
  }
}

export default ShowMore;
