const countryDialCodes = {
  AD: {
    name: 'Andorra',
    dialCode: '376',
    code: 'AD'
  },
  AE: {
    name: 'United Arab Emirates',
    dialCode: '971',
    code: 'AE'
  },
  AF: {
    name: 'Afghanistan',
    dialCode: '93',
    code: 'AF'
  },
  AG: {
    name: 'Antigua and Barbuda',
    dialCode: '1268',
    code: 'AG'
  },
  AI: {
    name: 'Anguilla',
    dialCode: '1 264',
    code: 'AI'
  },
  AL: {
    name: 'Albania',
    dialCode: '355',
    code: 'AL'
  },
  AM: {
    name: 'Armenia',
    dialCode: '374',
    code: 'AM'
  },
  AN: {
    name: 'Netherlands Antilles',
    dialCode: '599',
    code: 'AN'
  },
  AO: {
    name: 'Angola',
    dialCode: '244',
    code: 'AO'
  },
  AQ: {
    name: 'Antarctica',
    dialCode: '672',
    code: 'AQ'
  },
  AR: {
    name: 'Argentina',
    dialCode: '54',
    code: 'AR'
  },
  AS: {
    name: 'AmericanSamoa',
    dialCode: '1 684',
    code: 'AS'
  },
  AT: {
    name: 'Austria',
    dialCode: '43',
    code: 'AT'
  },
  AU: {
    name: 'Australia',
    dialCode: '61',
    code: 'AU'
  },
  AW: {
    name: 'Aruba',
    dialCode: '297',
    code: 'AW'
  },
  AX: {
    name: 'Land Islands',
    dialCode: '358',
    code: 'AX'
  },
  AZ: {
    name: 'Azerbaijan',
    dialCode: '994',
    code: 'AZ'
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    dialCode: '387',
    code: 'BA'
  },
  BB: {
    name: 'Barbados',
    dialCode: '1 246',
    code: 'BB'
  },
  BD: {
    name: 'Bangladesh',
    dialCode: '880',
    code: 'BD'
  },
  BE: {
    name: 'Belgium',
    dialCode: '32',
    code: 'BE'
  },
  BF: {
    name: 'Burkina Faso',
    dialCode: '226',
    code: 'BF'
  },
  BG: {
    name: 'Bulgaria',
    dialCode: '359',
    code: 'BG'
  },
  BH: {
    name: 'Bahrain',
    dialCode: '973',
    code: 'BH'
  },
  BI: {
    name: 'Burundi',
    dialCode: '257',
    code: 'BI'
  },
  BJ: {
    name: 'Benin',
    dialCode: '229',
    code: 'BJ'
  },
  BL: {
    name: 'Saint Barthélemy',
    dialCode: '590',
    code: 'BL'
  },
  BM: {
    name: 'Bermuda',
    dialCode: '1 441',
    code: 'BM'
  },
  BN: {
    name: 'Brunei Darussalam',
    dialCode: '673',
    code: 'BN'
  },
  BO: {
    name: 'Bolivia, Plurinational State of Bolivia',
    dialCode: '591',
    code: 'BO'
  },
  BV: {
    name: 'Bouvet Island',
    dialCode: '55',
    code: 'BV'
  },
  BR: {
    name: 'Brazil',
    dialCode: '55',
    code: 'BR'
  },
  BS: {
    name: 'Bahamas',
    dialCode: '1 242',
    code: 'BS'
  },
  BT: {
    name: 'Bhutan',
    dialCode: '975',
    code: 'BT'
  },
  BW: {
    name: 'Botswana',
    dialCode: '267',
    code: 'BW'
  },
  BY: {
    name: 'Belarus',
    dialCode: '375',
    code: 'BY'
  },
  BZ: {
    name: 'Belize',
    dialCode: '501',
    code: 'BZ'
  },
  CA: {
    name: 'Canada',
    dialCode: '1',
    code: 'CA'
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    dialCode: '61',
    code: 'CC'
  },
  CD: {
    name: 'Congo, The Democratic Republic of the',
    dialCode: '243',
    code: 'CD'
  },
  CF: {
    name: 'Central African Republic',
    dialCode: '236',
    code: 'CF'
  },
  CG: {
    name: 'Congo',
    dialCode: '242',
    code: 'CG'
  },
  CH: {
    name: 'Switzerland',
    dialCode: '41',
    code: 'CH'
  },
  CI: {
    name: "Cote d'Ivoire",
    dialCode: '225',
    code: 'CI'
  },
  CK: {
    name: 'Cook Islands',
    dialCode: '682',
    code: 'CK'
  },
  CL: {
    name: 'Chile',
    dialCode: '56',
    code: 'CL'
  },
  CM: {
    name: 'Cameroon',
    dialCode: '237',
    code: 'CM'
  },
  CN: {
    name: 'China',
    dialCode: '86',
    code: 'CN'
  },
  CO: {
    name: 'Colombia',
    dialCode: '57',
    code: 'CO'
  },
  CR: {
    name: 'Costa Rica',
    dialCode: '506',
    code: 'CR'
  },
  CU: {
    name: 'Cuba',
    dialCode: '53',
    code: 'CU'
  },
  CV: {
    name: 'Cape Verde',
    dialCode: '238',
    code: 'CV'
  },
  CW: {
    name: 'Curaçao',
    dialCode: '5999',
    code: 'CW'
  },
  CX: {
    name: 'Christmas Island',
    dialCode: '61',
    code: 'CX'
  },
  CY: {
    name: 'Cyprus',
    dialCode: '357',
    code: 'CY'
  },
  CZ: {
    name: 'Czech Republic',
    dialCode: '420',
    code: 'CZ'
  },
  DE: {
    name: 'Germany',
    dialCode: '49',
    code: 'DE'
  },
  DJ: {
    name: 'Djibouti',
    dialCode: '253',
    code: 'DJ'
  },
  DK: {
    name: 'Denmark',
    dialCode: '45',
    code: 'DK'
  },
  DM: {
    name: 'Dominica',
    dialCode: '1 767',
    code: 'DM'
  },
  DO: {
    name: 'Dominican Republic',
    dialCode: '1 849',
    code: 'DO'
  },
  DZ: {
    name: 'Algeria',
    dialCode: '213',
    code: 'DZ'
  },
  EC: {
    name: 'Ecuador',
    dialCode: '593',
    code: 'EC'
  },
  EE: {
    name: 'Estonia',
    dialCode: '372',
    code: 'EE'
  },
  EG: {
    name: 'Egypt',
    dialCode: '20',
    code: 'EG'
  },
  EH: {
    name: 'Western Sahara',
    dialCode: '732',
    code: 'EH'
  },
  ER: {
    name: 'Eritrea',
    dialCode: '291',
    code: 'ER'
  },
  ES: {
    name: 'Spain',
    dialCode: '34',
    code: 'ES'
  },
  ET: {
    name: 'Ethiopia',
    dialCode: '251',
    code: 'ET'
  },
  FI: {
    name: 'Finland',
    dialCode: '358',
    code: 'FI'
  },
  FJ: {
    name: 'Fiji',
    dialCode: '679',
    code: 'FJ'
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '500',
    code: 'FK'
  },
  FM: {
    name: 'Micronesia, Federated States of',
    dialCode: '691',
    code: 'FM'
  },
  FO: {
    name: 'Faroe Islands',
    dialCode: '298',
    code: 'FO'
  },
  FR: {
    name: 'France',
    dialCode: '33',
    code: 'FR'
  },
  GA: {
    name: 'Gabon',
    dialCode: '241',
    code: 'GA'
  },
  GB: {
    name: 'United Kingdom',
    dialCode: '44',
    code: 'GB'
  },
  GD: {
    name: 'Grenada',
    dialCode: '1 473',
    code: 'GD'
  },
  GE: {
    name: 'Georgia',
    dialCode: '995',
    code: 'GE'
  },
  GF: {
    name: 'French Guiana',
    dialCode: '594',
    code: 'GF'
  },
  GG: {
    name: 'Guernsey',
    dialCode: '44',
    code: 'GG'
  },
  GH: {
    name: 'Ghana',
    dialCode: '233',
    code: 'GH'
  },
  GI: {
    name: 'Gibraltar',
    dialCode: '350',
    code: 'GI'
  },
  GL: {
    name: 'Greenland',
    dialCode: '299',
    code: 'GL'
  },
  GM: {
    name: 'Gambia',
    dialCode: '220',
    code: 'GM'
  },
  GN: {
    name: 'Guinea',
    dialCode: '224',
    code: 'GN'
  },
  GP: {
    name: 'Guadeloupe',
    dialCode: '590',
    code: 'GP'
  },
  GQ: {
    name: 'Equatorial Guinea',
    dialCode: '240',
    code: 'GQ'
  },
  GR: {
    name: 'Greece',
    dialCode: '30',
    code: 'GR'
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '500',
    code: 'GS'
  },
  GT: {
    name: 'Guatemala',
    dialCode: '502',
    code: 'GT'
  },
  GU: {
    name: 'Guam',
    dialCode: '1 671',
    code: 'GU'
  },
  GW: {
    name: 'Guinea-Bissau',
    dialCode: '245',
    code: 'GW'
  },
  GY: {
    name: 'Guyana',
    dialCode: '592',
    code: 'GY'
  },
  HK: {
    name: 'Hong Kong',
    dialCode: '852',
    code: 'HK'
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    dialCode: '672',
    code: 'HM'
  },
  HN: {
    name: 'Honduras',
    dialCode: '504',
    code: 'HN'
  },
  HR: {
    name: 'Croatia',
    dialCode: '385',
    code: 'HR'
  },
  HT: {
    name: 'Haiti',
    dialCode: '509',
    code: 'HT'
  },
  HU: {
    name: 'Hungary',
    dialCode: '36',
    code: 'HU'
  },
  ID: {
    name: 'Indonesia',
    dialCode: '62',
    code: 'ID'
  },
  IE: {
    name: 'Ireland',
    dialCode: '353',
    code: 'IE'
  },
  IL: {
    name: 'Israel',
    dialCode: '972',
    code: 'IL'
  },
  IM: {
    name: 'Isle of Man',
    dialCode: '44',
    code: 'IM'
  },
  IN: {
    name: 'India',
    dialCode: '91',
    code: 'IN'
  },
  IO: {
    name: 'British Indian Ocean Territory',
    dialCode: '246',
    code: 'IO'
  },
  IQ: {
    name: 'Iraq',
    dialCode: '964',
    code: 'IQ'
  },
  IR: {
    name: 'Iran, Islamic Republic of',
    dialCode: '98',
    code: 'IR'
  },
  IS: {
    name: 'Iceland',
    dialCode: '354',
    code: 'IS'
  },
  IT: {
    name: 'Italy',
    dialCode: '39',
    code: 'IT'
  },
  JE: {
    name: 'Jersey',
    dialCode: '44',
    code: 'JE'
  },
  JM: {
    name: 'Jamaica',
    dialCode: '1 876',
    code: 'JM'
  },
  JO: {
    name: 'Jordan',
    dialCode: '962',
    code: 'JO'
  },
  JA: {
    name: 'Japan',
    dialCode: '81',
    code: 'JA'
  },
  KE: {
    name: 'Kenya',
    dialCode: '254',
    code: 'KE'
  },
  KG: {
    name: 'Kyrgyzstan',
    dialCode: '996',
    code: 'KG'
  },
  KH: {
    name: 'Cambodia',
    dialCode: '855',
    code: 'KH'
  },
  KI: {
    name: 'Kiribati',
    dialCode: '686',
    code: 'KI'
  },
  KM: {
    name: 'Comoros',
    dialCode: '269',
    code: 'KM'
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    dialCode: '1 869',
    code: 'KN'
  },
  KP: {
    name: "Korea, Democratic People's Republic of",
    dialCode: '850',
    code: 'KP'
  },
  KR: {
    name: 'Korea, Republic of',
    dialCode: '82',
    code: 'KR'
  },
  KW: {
    name: 'Kuwait',
    dialCode: '965',
    code: 'KW'
  },
  KY: {
    name: 'Cayman Islands',
    dialCode: '1345',
    code: 'KY'
  },
  KZ: {
    name: 'Kazakhstan',
    dialCode: '7',
    code: 'KZ'
  },
  LA: {
    name: "Lao People's Democratic Republic",
    dialCode: '856',
    code: 'LA'
  },
  LB: {
    name: 'Lebanon',
    dialCode: '961',
    code: 'LB'
  },
  LC: {
    name: 'Saint Lucia',
    dialCode: '1 758',
    code: 'LC'
  },
  LI: {
    name: 'Liechtenstein',
    dialCode: '423',
    code: 'LI'
  },
  LK: {
    name: 'Sri Lanka',
    dialCode: '94',
    code: 'LK'
  },
  LR: {
    name: 'Liberia',
    dialCode: '231',
    code: 'LR'
  },
  LS: {
    name: 'Lesotho',
    dialCode: '266',
    code: 'LS'
  },
  LT: {
    name: 'Lithuania',
    dialCode: '370',
    code: 'LT'
  },
  LU: {
    name: 'Luxembourg',
    dialCode: '352',
    code: 'LU'
  },
  LV: {
    name: 'Latvia',
    dialCode: '371',
    code: 'LV'
  },
  LY: {
    name: 'Libyan Arab Jamahiriya',
    dialCode: '218',
    code: 'LY'
  },
  MA: {
    name: 'Morocco',
    dialCode: '212',
    code: 'MA'
  },
  MC: {
    name: 'Monaco',
    dialCode: '377',
    code: 'MC'
  },
  MD: {
    name: 'Moldova, Republic of',
    dialCode: '373',
    code: 'MD'
  },
  ME: {
    name: 'Montenegro',
    dialCode: '382',
    code: 'ME'
  },
  MF: {
    name: 'Saint Martin',
    dialCode: '590',
    code: 'MF'
  },
  MG: {
    name: 'Madagascar',
    dialCode: '261',
    code: 'MG'
  },
  MH: {
    name: 'Marshall Islands',
    dialCode: '692',
    code: 'MH'
  },
  MK: {
    name: 'Macedonia, The Former Yugoslav Republic of',
    dialCode: '389',
    code: 'MK'
  },
  ML: {
    name: 'Mali',
    dialCode: '223',
    code: 'ML'
  },
  MM: {
    name: 'Myanmar',
    dialCode: '95',
    code: 'MM'
  },
  MN: {
    name: 'Mongolia',
    dialCode: '976',
    code: 'MN'
  },
  MO: {
    name: 'Macao',
    dialCode: '853',
    code: 'MO'
  },
  MP: {
    name: 'Northern Mariana Islands',
    dialCode: '1 670',
    code: 'MP'
  },
  MQ: {
    name: 'Martinique',
    dialCode: '596',
    code: 'MQ'
  },
  MR: {
    name: 'Mauritania',
    dialCode: '222',
    code: 'MR'
  },
  MS: {
    name: 'Montserrat',
    dialCode: '1664',
    code: 'MS'
  },
  MT: {
    name: 'Malta',
    dialCode: '356',
    code: 'MT'
  },
  MU: {
    name: 'Mauritius',
    dialCode: '230',
    code: 'MU'
  },
  MV: {
    name: 'Maldives',
    dialCode: '960',
    code: 'MV'
  },
  MW: {
    name: 'Malawi',
    dialCode: '265',
    code: 'MW'
  },
  MX: {
    name: 'Mexico',
    dialCode: '52',
    code: 'MX'
  },
  MY: {
    name: 'Malaysia',
    dialCode: '60',
    code: 'MY'
  },
  MZ: {
    name: 'Mozambique',
    dialCode: '258',
    code: 'MZ'
  },
  NA: {
    name: 'Namibia',
    dialCode: '264',
    code: 'NA'
  },
  NC: {
    name: 'New Caledonia',
    dialCode: '687',
    code: 'NC'
  },
  NE: {
    name: 'Niger',
    dialCode: '227',
    code: 'NE'
  },
  NF: {
    name: 'Norfolk Island',
    dialCode: '672',
    code: 'NF'
  },
  NG: {
    name: 'Nigeria',
    dialCode: '234',
    code: 'NG'
  },
  NI: {
    name: 'Nicaragua',
    dialCode: '505',
    code: 'NI'
  },
  NL: {
    name: 'Netherlands',
    dialCode: '31',
    code: 'NL'
  },
  NO: {
    name: 'Norway',
    dialCode: '47',
    code: 'NO'
  },
  NP: {
    name: 'Nepal',
    dialCode: '977',
    code: 'NP'
  },
  NR: {
    name: 'Nauru',
    dialCode: '674',
    code: 'NR'
  },
  NU: {
    name: 'Niue',
    dialCode: '683',
    code: 'NU'
  },
  NZ: {
    name: 'New Zealand',
    dialCode: '64',
    code: 'NZ'
  },
  OM: {
    name: 'Oman',
    dialCode: '968',
    code: 'OM'
  },
  PA: {
    name: 'Panama',
    dialCode: '507',
    code: 'PA'
  },
  PE: {
    name: 'Peru',
    dialCode: '51',
    code: 'PE'
  },
  PF: {
    name: 'French Polynesia',
    dialCode: '689',
    code: 'PF'
  },
  PG: {
    name: 'Papua New Guinea',
    dialCode: '675',
    code: 'PG'
  },
  PH: {
    name: 'Philippines',
    dialCode: '63',
    code: 'PH'
  },
  PK: {
    name: 'Pakistan',
    dialCode: '92',
    code: 'PK'
  },
  PL: {
    name: 'Poland',
    dialCode: '48',
    code: 'PL'
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    dialCode: '508',
    code: 'PM'
  },
  PN: {
    name: 'Pitcairn',
    dialCode: '870',
    code: 'PN'
  },
  PR: {
    name: 'Puerto Rico',
    dialCode: '1 939',
    code: 'PR'
  },
  PS: {
    name: 'Palestinian Territory, Occupied',
    dialCode: '970',
    code: 'PS'
  },
  PT: {
    name: 'Portugal',
    dialCode: '351',
    code: 'PT'
  },
  PW: {
    name: 'Palau',
    dialCode: '680',
    code: 'PW'
  },
  PY: {
    name: 'Paraguay',
    dialCode: '595',
    code: 'PY'
  },
  QA: {
    name: 'Qatar',
    dialCode: '974',
    code: 'QA'
  },
  RE: {
    name: 'Réunion',
    dialCode: '262',
    code: 'RE'
  },
  RO: {
    name: 'Romania',
    dialCode: '40',
    code: 'RO'
  },
  RS: {
    name: 'Serbia',
    dialCode: '381',
    code: 'RS'
  },
  RU: {
    name: 'Russia',
    dialCode: '7',
    code: 'RU'
  },
  RW: {
    name: 'Rwanda',
    dialCode: '250',
    code: 'RW'
  },
  SA: {
    name: 'Saudi Arabia',
    dialCode: '966',
    code: 'SA'
  },
  SB: {
    name: 'Solomon Islands',
    dialCode: '677',
    code: 'SB'
  },
  SC: {
    name: 'Seychelles',
    dialCode: '248',
    code: 'SC'
  },
  SD: {
    name: 'Sudan',
    dialCode: '249',
    code: 'SD'
  },
  SE: {
    name: 'Sweden',
    dialCode: '46',
    code: 'SE'
  },
  SG: {
    name: 'Singapore',
    dialCode: '65',
    code: 'SG'
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '290',
    code: 'SH'
  },
  SI: {
    name: 'Slovenia',
    dialCode: '386',
    code: 'SI'
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    dialCode: '47',
    code: 'SJ'
  },
  SK: {
    name: 'Slovakia',
    dialCode: '421',
    code: 'SK'
  },
  SL: {
    name: 'Sierra Leone',
    dialCode: '232',
    code: 'SL'
  },
  SM: {
    name: 'San Marino',
    dialCode: '378',
    code: 'SM'
  },
  SN: {
    name: 'Senegal',
    dialCode: '221',
    code: 'SN'
  },
  SO: {
    name: 'Somalia',
    dialCode: '252',
    code: 'SO'
  },
  SR: {
    name: 'Suriname',
    dialCode: '597',
    code: 'SR'
  },
  SS: {
    name: 'South Sudan',
    dialCode: '211',
    code: 'SS'
  },
  ST: {
    name: 'Sao Tome and Principe',
    dialCode: '239',
    code: 'ST'
  },
  SV: {
    name: 'El Salvador',
    dialCode: '503',
    code: 'SV'
  },
  SX: {
    name: 'Sint Maarten',
    dialCode: '1721',
    code: 'SX'
  },
  SY: {
    name: 'Syrian Arab Republic',
    dialCode: '963',
    code: 'SY'
  },
  SZ: {
    name: 'Swaziland',
    dialCode: '268',
    code: 'SZ'
  },
  TC: {
    name: 'Turks and Caicos Islands',
    dialCode: '1 649',
    code: 'TC'
  },
  TD: {
    name: 'Chad',
    dialCode: '235',
    code: 'TD'
  },
  TF: {
    name: 'French Southern and Antarctic Lands',
    dialCode: '262',
    code: 'TF'
  },
  TG: {
    name: 'Togo',
    dialCode: '228',
    code: 'TG'
  },
  TH: {
    name: 'Thailand',
    dialCode: '66',
    code: 'TH'
  },
  TJ: {
    name: 'Tajikistan',
    dialCode: '992',
    code: 'TJ'
  },
  TK: {
    name: 'Tokelau',
    dialCode: '690',
    code: 'TK'
  },
  TL: {
    name: 'Timor-Leste',
    dialCode: '670',
    code: 'TL'
  },
  TM: {
    name: 'Turkmenistan',
    dialCode: '993',
    code: 'TM'
  },
  TN: {
    name: 'Tunisia',
    dialCode: '216',
    code: 'TN'
  },
  TO: {
    name: 'Tonga',
    dialCode: '676',
    code: 'TO'
  },
  TR: {
    name: 'Turkey',
    dialCode: '90',
    code: 'TR'
  },
  TT: {
    name: 'Trinidad and Tobago',
    dialCode: '1 868',
    code: 'TT'
  },
  TV: {
    name: 'Tuvalu',
    dialCode: '688',
    code: 'TV'
  },
  TW: {
    name: 'Taiwan',
    dialCode: '886',
    code: 'TW'
  },
  TZ: {
    name: 'Tanzania, United Republic of',
    dialCode: '255',
    code: 'TZ'
  },
  UA: {
    name: 'Ukraine',
    dialCode: '380',
    code: 'UA'
  },
  UG: {
    name: 'Uganda',
    dialCode: '256',
    code: 'UG'
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    dialCode: '1581',
    code: 'UM'
  },
  US: {
    name: 'United States',
    dialCode: '1',
    code: 'US'
  },
  UY: {
    name: 'Uruguay',
    dialCode: '598',
    code: 'UY'
  },
  UZ: {
    name: 'Uzbekistan',
    dialCode: '998',
    code: 'UZ'
  },
  VA: {
    name: 'Holy See (Vatican City State)',
    dialCode: '379',
    code: 'VA'
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '1 784',
    code: 'VC'
  },
  VE: {
    name: 'Venezuela, Bolivarian Republic of',
    dialCode: '58',
    code: 'VE'
  },
  VG: {
    name: 'Virgin Islands, British',
    dialCode: '1 284',
    code: 'VG'
  },
  VI: {
    name: 'Virgin Islands, U.S.',
    dialCode: '1 340',
    code: 'VI'
  },
  VN: {
    name: 'Viet Nam',
    dialCode: '84',
    code: 'VN'
  },
  VU: {
    name: 'Vanuatu',
    dialCode: '678',
    code: 'VU'
  },
  WF: {
    name: 'Wallis and Futuna',
    dialCode: '681',
    code: 'WF'
  },
  WS: {
    name: 'Samoa',
    dialCode: '685',
    code: 'WS'
  },
  XK: {
    name: 'Kosovo, Republic of Kosovo',
    dialCode: '383',
    code: 'XK'
  },
  YE: {
    name: 'Yemen',
    dialCode: '967',
    code: 'YE'
  },
  YT: {
    name: 'Mayotte',
    dialCode: '262',
    code: 'YT'
  },
  ZA: {
    name: 'South Africa',
    dialCode: '27',
    code: 'ZA'
  },
  ZM: {
    name: 'Zambia',
    dialCode: '260',
    code: 'ZM'
  },
  ZW: {
    name: 'Zimbabwe',
    dialCode: '263',
    code: 'ZW'
  }
};

const languagesByCode = {
  '(+1)': {
    code: 'US',
    flagCode: 'en-US',
    dialCode: '1'
  },
  '(+44)': {
    code: 'GB',
    flagCode: 'cy-GB',
    dialCode: '44'
  },
  '(+33)': {
    code: 'FR',
    flagCode: 'fr-FR',
    dialCode: '33'
  },
  '(+34)': {
    code: 'ES',
    flagCode: 'ca-ES',
    dialCode: '34'
  },
  '(+49)': {
    code: 'DE',
    flagCode: 'de-DE',
    dialCode: '49'
  },
  '(+41)': {
    code: 'CH',
    flagCode: 'de-CH',
    dialCode: '41'
  },
  '(+54)': {
    code: 'AR',
    flagCode: 'es-AR',
    dialCode: '54'
  },
  '(+43)': {
    code: 'AT',
    flagCode: 'de-AT',
    dialCode: '43'
  },
  '(+61)': {
    code: 'AU',
    flagCode: 'en-AU',
    dialCode: '61'
  },
  '(+359)': {
    code: 'BG',
    flagCode: 'bg-BG',
    dialCode: '359'
  },
  '(+591)': {
    code: 'BO',
    flagCode: 'es-BO',
    dialCode: '591'
  },
  '(+55)': {
    code: 'BR',
    flagCode: 'pt-BR',
    dialCode: '55'
  },
  '(+56)': {
    code: 'CL',
    flagCode: 'es-CL',
    dialCode: '56'
  },
  '(+86)': {
    code: 'CN',
    flagCode: 'zh-CN',
    dialCode: '86'
  },
  '(+57)': {
    code: 'CO',
    flagCode: 'es-CO',
    dialCode: '57'
  },
  '(+506)': {
    code: 'CR',
    flagCode: 'es-CR',
    dialCode: '506'
  },
  '(+53)': {
    code: 'CU',
    flagCode: 'es-CU',
    dialCode: '53'
  },
  '(+420)': {
    code: 'CZ',
    flagCode: 'cs-CZ',
    dialCode: '420'
  },
  '(+45)': {
    code: 'DK',
    flagCode: 'da-DK',
    dialCode: '45'
  },
  '(+1 849)': {
    code: 'DO',
    flagCode: 'es-DO',
    dialCode: '1 849'
  },
  '(+593)': {
    code: 'EC',
    flagCode: 'es-EC',
    dialCode: '593'
  },
  '(+233)': {
    code: 'GH',
    flagCode: 'ak-GH',
    dialCode: '233'
  },
  '(+30)': {
    code: 'GR',
    flagCode: 'el-GR',
    dialCode: '30'
  },
  '(+502)': {
    code: 'GT',
    flagCode: 'es-GT',
    dialCode: '502'
  },
  '(+852)': {
    code: 'HK',
    flagCode: 'zh-HK',
    dialCode: '852'
  },
  '(+504)': {
    code: 'HN',
    flagCode: 'es-HN',
    dialCode: '504'
  },
  '(+353)': {
    code: 'IE',
    flagCode: 'ga-IE',
    dialCode: '353'
  },
  '(+972)': {
    code: 'IL',
    flagCode: 'he-IL',
    dialCode: '972'
  },
  '(+91)': {
    code: 'IN',
    flagCode: 'hi-IN',
    dialCode: '91'
  },
  '(+39)': {
    code: 'IT',
    flagCode: 'it-IT',
    dialCode: '39'
  },
  '(+81)': {
    code: 'JA',
    flagCode: 'ja-JA',
    dialCode: '81'
  },
  '(+254)': {
    code: 'KE',
    flagCode: 'sw-KE',
    dialCode: '254'
  },
  '(+505)': {
    code: 'NI',
    flagCode: 'es-NI',
    dialCode: '505'
  },
  '(+31)': {
    code: 'NL',
    flagCode: 'nl-NL',
    dialCode: '31'
  },
  '(+64)': {
    code: 'NZ',
    flagCode: 'en-NZ',
    dialCode: '64'
  },
  '(+507)': {
    code: 'PA',
    flagCode: 'es-PA',
    dialCode: '507'
  },
  '(+51)': {
    code: 'PE',
    flagCode: 'es-PE',
    dialCode: '51'
  },
  '(+48)': {
    code: 'PL',
    flagCode: 'pl-PL',
    dialCode: '48'
  },
  '(+1 939)': {
    code: 'PR',
    flagCode: 'es-PR',
    dialCode: '1 939'
  },
  '(+351)': {
    code: 'PT',
    flagCode: 'pt-PT',
    dialCode: '351'
  },
  '(+595)': {
    code: 'PY',
    flagCode: 'es-PY',
    dialCode: '595'
  },
  '(+40)': {
    code: 'RO',
    flagCode: 'ro-RO',
    dialCode: '40'
  },
  '(+7)': {
    code: 'RU',
    flagCode: 'ru-RU',
    dialCode: '7'
  },
  '(+46)': {
    code: 'SE',
    flagCode: 'sv-SE',
    dialCode: '46'
  },
  '(+65)': {
    code: 'SG',
    flagCode: 'zh-SG',
    dialCode: '65'
  },
  '(+386)': {
    code: 'SI',
    flagCode: 'sl-SI',
    dialCode: '386'
  },
  '(+421)': {
    code: 'SK',
    flagCode: 'sk-SK',
    dialCode: '421'
  },
  '(+503)': {
    code: 'SV',
    flagCode: 'es-SV',
    dialCode: '503'
  },
  '(+886)': {
    code: 'TW',
    flagCode: 'zh-TW',
    dialCode: '886'
  },
  '(+598)': {
    code: 'UY',
    flagCode: 'es-UY',
    dialCode: '598'
  },
  '(+58)': {
    code: 'VE',
    flagCode: 'es-VE',
    dialCode: '58'
  },
  '(+27)': {
    code: 'ZA',
    flagCode: 'en-ZA',
    dialCode: '27'
  }
};

export {countryDialCodes, languagesByCode};
