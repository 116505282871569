exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3B7XqyWuLElCqgVlKaYFL7{flex:0 0 55px}._1aioP2Zs4Slx1DpB3GdQ3N{flex:0 0 51px;border-bottom:1px solid #cecece}._1K-zF5n3NHQH0lcmQp9aUZ{flex:0 0 300px;overflow:hidden;border-right:1px solid #cecece;background:#f8f7f7;box-shadow:0 0 10px rgba(0,0,0,.4);overflow:auto}", ""]);

// exports
exports.locals = {
	"header": "_3B7XqyWuLElCqgVlKaYFL7",
	"subheader": "_1aioP2Zs4Slx1DpB3GdQ3N",
	"sidebar": "_1K-zF5n3NHQH0lcmQp9aUZ"
};