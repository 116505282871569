import {fromJS} from 'immutable';

const initialState = fromJS({
  isFetching: false,
  checks: []
});

export default function checksReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CHECKS:
      return state.set('isFetching', true);

    case RECEIVE_CHECKS:
      return state.set('isFetching', false).set('checks', fromJS(action.checks));
  }
  return state;
}

export const REQUEST_CHECKS = 'checks:REQUEST_CHECKS';
export function requestChecks() {
  return {type: REQUEST_CHECKS};
}

export const RECEIVE_CHECKS = 'checks:RECEIVE_CHECKS';
export function receiveChecks(checks) {
  return {type: RECEIVE_CHECKS, checks};
}

export const UPDATE_CHECKS = 'checks:UPDATE_CHECKS';
export function updateAvailableChecks(checks) {
  return {type: UPDATE_CHECKS, checks};
}

export const TRIGGER_BROKEN_LINK_RETEST = 'checks:TRIGGER_BROKEN_LINK_RETEST';
export function triggerRetestBrokenLink(reportId, linkHash) {
  return {type: TRIGGER_BROKEN_LINK_RETEST, linkHash, reportId};
}
