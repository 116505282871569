import {put, take, select, call, fork, takeLatest} from 'redux-saga/effects';
import {accountSelector} from 'modules/auth/authSelectors';
import {START_ONBOARDING, NEXT_STEP, jumpToStep, nextStep} from 'modules/onboarding';
import React from 'react';
import {showInModal} from 'modules/modal';
import OrderCompleteModal from 'modules/products/components/orderCompleteModal';
import {orderComplete, payError, ORDER_COMPLETE} from 'modules/products';
import {requestSubscriptions} from 'modules/subscriptions';
import {purchaseEndpoint} from 'modules/products/productUtils';
import fetchEntitlements from 'modules/entitlements/sagas/helpers/fetchEntitlements';
import {processRequest} from 'utils/saga/fetchUtils';
import ModalSpinner from 'components/spinner/modalSpinner';
import {hideModal} from 'modules/modal';
import handleLoginSuccess from 'modules/auth/sagas/utils/handleLoginSuccess';
import getErrorMessage from 'api/errors';
import {couldNot} from 'utils/errorUtils';
import {homePath} from 'modules/app/appUtils';
import {push} from 'modules/location';
import {stepMap, USER, PAYMENT} from 'modules/onboarding/onboardingConstants';
import {toJS} from 'utils/immutableUtils';
import {triggerAnalytics} from 'modules/analytics';
import {requestProducts} from 'modules/products';

// WTF es6? I can't import this file, but I can require it
const {
  currentStepIndexSelector,
  onboardingDataSelector,
  plainParamsSelector
} = require('modules/onboarding/onboardingSelectors');

export default function* onboardingProcessSaga() {
  yield takeLatest(START_ONBOARDING, onboardingProcess);
}

function* onboardingProcess() {
  yield put(requestProducts());

  while (true) {
    const action = yield take([NEXT_STEP, ORDER_COMPLETE]);

    if (action.type === ORDER_COMPLETE) {
      break;
    }

    yield fork(processStepChange);
  }
}

function* processStepChange() {
  const currentStep = yield select(currentStepIndexSelector);
  const step = stepMap[currentStep];

  // if we have reached the end, process the payment
  if (!step) {
    const {user, account, plan, payment} = yield select(onboardingDataSelector);

    // confirm the product selection along with the account and payment details
    yield confirmPayment(user, account, plan, payment);
    return;
  }

  // if the step exists, analyse and properties it may have
  if (step.analyticsEvents) {
    yield put(triggerAnalytics(step.analyticsEvents));
  }

  if (step.skipStep) {
    const shouldSkip = yield select(step.skipStep);

    if (shouldSkip) {
      yield put(nextStep());
    }
  }
}

function* confirmPayment(user, accountData, planId, payment) {
  let postData = {planId};

  // basically use to tell us if we have an account or if this account can pay
  const account = yield select(accountSelector);

  const hasPaymentDetails = account && account.get('hasPaymentDetails', false);

  // only pass payment and account info if the backend doesn't already know it
  if (!hasPaymentDetails) {
    const signupParams = yield select(plainParamsSelector); // querystring params: referredBy, source, urlToTest

    accountData = accountData || toJS(account);
    const accountPostData = {
      addressCountry: accountData.addressCountry,
      vatCountry: accountData.addressCountry,
      vatNumber: accountData.vatNumber,
      organizationName: accountData.organizationName
    };

    postData = {
      planId,
      ...signupParams, // referredBy, source, urlToTest
      ...accountPostData,
      currency: 'usd',
      cardToken: payment.token.id
    };

    if (user) {
      postData['contactEmail'] = user.contactEmail;
      postData['contactName'] = user.contactName;
      postData['password'] = user.password;
    }
  }

  yield put(
    showInModal(ModalSpinner, {
      dismissable: false,
      message: (
        <h2 style={{marginTop: '30px'}}>
          {!account ? `We're creating your new Silktide account` : `Adding your new subscription`}
        </h2>
      )
    })
  );

  try {
    const {user, token} = yield signupAndBuy(postData);
    yield handleNewSubscription(user, token, account);

    const accountId =
      account && account.get && account.get('accountId')
        ? account.get('accountId')
        : user.account.accountId;

    yield put(push(homePath({accountId})));
  } catch (error) {
    yield handleSignupAndBuyFailure(error, planId, postData);
  }
}

function* signupAndBuy(postData) {
  return yield processRequest(
    'POST',
    purchaseEndpoint(),
    {
      // Super long timeout to prevent chell timing out and reporting payment
      // failure when in fact the payment is still processing!
      timeout: 60000,

      success: function*({user, token}) {
        return {user, token};
      },
      error: function*(error) {
        throw error;
      },
      errorMessage: 'An error occurred whilst processing your request. No payment has been taken.'
    },
    postData
  );
}

function* handleNewSubscription(user, token, account) {
  const existingUser = user && token;

  if (existingUser) {
    yield call(handleLoginSuccess, {user, token});
  }

  yield put(orderComplete());
  yield put(requestSubscriptions());

  // Show order complete modal
  yield put(
    showInModal(OrderCompleteModal, {
      firstSubscription: !account
    })
  );

  // We need to refetch entitlements at this point so that
  // the user can use their new products
  yield call(fetchEntitlements);
}

function* handleSignupAndBuyFailure(error, planId, postData) {
  const errorMessage = !error.errorCode
    ? couldNot('make a payment')
    : getErrorMessage(error.errorCode, {
        value: postData[error.invalidProperty]
      });

  yield put(payError(errorMessage));

  // Currently we only return errors relevant to the user screen so jump there
  // TODO: smarter jumping of steps based on error field
  if (error.errorCode) {
    yield put(jumpToStep(USER, errorMessage));
  } else {
    yield put(jumpToStep(PAYMENT, errorMessage));
  }

  yield put(hideModal());
}
