exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cRtB_jhyW43mZXfyWlaJt{max-width:600px;margin:40px auto}.cRtB_jhyW43mZXfyWlaJt h2{font-size:25px;margin:50px 0 20px;font-weight:bold;text-align:center}.cRtB_jhyW43mZXfyWlaJt h2:first-child{margin-top:0}.cRtB_jhyW43mZXfyWlaJt p{font-size:16px;line-height:24px}.cRtB_jhyW43mZXfyWlaJt img{margin:20px 0;box-shadow:0 0 20px rgba(0,0,0,.2)}", ""]);

// exports
exports.locals = {
	"article": "cRtB_jhyW43mZXfyWlaJt"
};