import {put, take, takeEvery} from 'redux-saga/effects';
import {showInModal, HIDE_MODAL} from 'modules/modal';
import {
  TRIGGER_EXPORT_REPORT,
  EXPORT_REPORT,
  EXPORT_REPORT_PDF
} from 'modules/reports/reportConstants';
import ExportReportModal from 'modules/reports/components/modals/exportReportModal';
import {displayExportingFeedback} from './helpers/displayExportingFeedback';

export default function* triggerExportReportSaga() {
  yield takeEvery(TRIGGER_EXPORT_REPORT, triggerExportReport);
}

const exportLabels = {
  csv: 'Download CSVs',
  pdf: 'Open PDF'
};

// The `exportType` allows us to lock the modal to only export one type or report.
// If it is `null`, it means that it is not locked to any specific export type.
export function* triggerExportReport({reportId, testId, exportType}) {
  yield put(
    showInModal(ExportReportModal, {
      name: 'exportReportModal',
      defaultTestId: testId,
      exportType,
      reportId
    })
  );

  // TODO: this would be much nicer if it was less imperative.
  // The ExportReportModal could be directly connected to
  // exporting state and result
  // meaning we wouldn't need any of the below code.
  const {type, testIds, exportType: selectedExportType} = yield take([
    EXPORT_REPORT,
    EXPORT_REPORT_PDF,
    HIDE_MODAL
  ]);

  if (type == HIDE_MODAL) {
    return;
  }

  const willTakeTime = testIds.length === 0 || testIds.length > 10;

  yield displayExportingFeedback({
    title: 'Exporting',
    description: willTakeTime ? 'This could take up to a minute' : '',
    successMessage: 'The website finished exporting',
    submitLabel: exportLabels[selectedExportType]
  });
}
