import React from 'react';
import {connect} from 'react-redux';
import {reportPath} from 'modules/reports/reportUtils';
import Grid from 'components/grid';
import DraggableReportCard from 'modules/reports/components/draggableReportCard';
import {push} from 'modules/location';

export class ReportsGrid extends React.Component {
  clickHandler = reportId => () => this.props.push(reportPath, {reportId});

  renderReport = report => {
    return (
      <DraggableReportCard
        id={'report-' + report.name.toLowerCase().replace(' ', '-')}
        key={report.reportId}
        report={report}
        onClick={this.clickHandler(report.reportId)}
      />
    );
  };

  render() {
    const {reports} = this.props;

    return (
      <div>
        <Grid>{reports.map(this.renderReport)}</Grid>
      </div>
    );
  }
}

export default connect(
  null,
  {push}
)(ReportsGrid);
