import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_POLICY} from '../constants';
import {setPolicy, requestPolicyComplete} from '../actions';

export default function* requestPolicySaga() {
  yield takeLatest(REQUEST_POLICY, requestPolicy);
}

function* requestPolicy(action) {
  try {
    const policy = yield processRequest('GET', 'policies/' + action.policyId);
    yield put(setPolicy(policy));
    yield put(requestPolicyComplete());
  } catch (err) {
    yield put(requestPolicyComplete(err));
  }
}
