exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2CaZVlzgJEs-q1qcjSr-8M{text-align:left}._1LJcY_NNKy0dcjDPe6_qK{flex:1;text-align:center}._2m1lgepBts1RKkgtTOjCeQ{margin-left:auto;margin-right:0 !important;text-align:right;display:flex;align-items:center}._2m1lgepBts1RKkgtTOjCeQ>*{flex:0 1 auto;margin-right:10px}._2m1lgepBts1RKkgtTOjCeQ>*:last-child{margin-right:0}._2m1lgepBts1RKkgtTOjCeQ>h3{margin-top:0;margin-bottom:0}", ""]);

// exports
exports.locals = {
	"left": "_2CaZVlzgJEs-q1qcjSr-8M",
	"center": "_1LJcY_NNKy0dcjDPe6_qK",
	"right": "_2m1lgepBts1RKkgtTOjCeQ"
};