import {takeLatest, put} from 'redux-saga/effects';
import {couldNot} from 'utils/errorUtils';
import {
  FETCH_FORCED_PAGES,
  EDIT_FORCED_PAGE,
  DELETE_FORCED_PAGE
} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {forcedPagesEndpoint} from 'modules/reports/reportUtils';
import {receiveForcedPages, receiveForcedPage} from 'modules/reports';

export default function* fetchForcedPagesSaga() {
  yield takeLatest([FETCH_FORCED_PAGES, EDIT_FORCED_PAGE, DELETE_FORCED_PAGE], fetchForcedPages);
}

function* fetchForcedPages({reportId, forcedPageId = null, type, pageNum}) {
  yield processRequest(
    'GET',
    forcedPagesEndpoint({
      reportId,
      forcedPageId: type === DELETE_FORCED_PAGE ? null : forcedPageId,
      pageNum
    }),
    {
      success: function*(response = {}) {
        if (response && response.pages) {
          let {pages, pagination} = response;

          pagination = {
            page: pagination.page,
            numRows: pagination.total,
            rowsPerPage: 100, //Default rows in backend
            maxPage: pagination.numPages
          };

          return yield put(receiveForcedPages({pages, pagination}));
        }

        return yield put(receiveForcedPage(response));
      },
      errorMessage: couldNot('load website')
    }
  );
}
