exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".V2BFssUb9hqkZ0Nu4TYo9{width:100%;background:#f0f0f0;padding:30px;text-align:right;border-radius:0 0 4px 4px;box-shadow:inset 0 5px 10px rgba(0,0,0,.08);font-size:17px}._2VIuQbxZIjyrADbLI7w_QC{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"footer": "V2BFssUb9hqkZ0Nu4TYo9",
	"buttons": "_2VIuQbxZIjyrADbLI7w_QC"
};