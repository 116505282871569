import _ from 'lodash';
import {toJS} from 'utils/immutableUtils';
import {createSelector} from 'reselect';
import {createPropSelector} from 'utils/selectorUtils';
import {testSelector, testsSelector} from 'modules/tests/testSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {userSelector} from 'modules/users/userSelectors';
import {attachUserAndGroup} from 'modules/groups';
import {plainUserSelector, accountIdSelector} from 'modules/auth/authSelectors';
import {layoutsDataStateSelector} from 'modules/layouts/layoutsSelectors';

export const actionsStateSelector = state => state.actions;

export const actionIdSelector = createSelector(
  createPropSelector('actionId'),
  _.toString
);

const actionsSelector = createSelector(
  actionsStateSelector,
  state => {
    return {actions: state.get('actions')};
  }
);

export const actionSelector = createSelector(
  actionsSelector,
  actionIdSelector,
  ({actions}, actionId) => {
    return {action: toJS(actions.get(actionId))};
  }
);

export const closeQueryParamsSelector = createSelector(
  reportSelector,
  actionsStateSelector,
  (report, actionsState) => {
    return {report, queryParams: toJS(actionsState.get('closeQueryParams'))};
  }
);

export const actionWithLayoutSelector = createSelector(
  testSelector,
  testsSelector,
  actionSelector,
  layoutsDataStateSelector,
  (test, tests, {action}, layouts) => {
    if (!action) {
      return {action: null, test, parentTest: tests.get(test && test.get('parentId'))};
    }

    action.layout = layouts.has(action.layoutId) ? layouts.get(action.layoutId) : null;
    return {action: action, test, parentTest: tests.get(test && test.get('parentId'))};
  }
);

export const assigneesChangeSelector = createSelector(
  attachUserAndGroup('added'),
  attachUserAndGroup('removed'),
  userSelector,
  ({added}, {removed}, {user}) => {
    return {
      added,
      removed,
      user
    };
  }
);

export const participantsSelector = attachUserAndGroup('participants');

// tests are used to convert testIds to test names.
export const actionHeaderSelector = createSelector(
  testsSelector,
  accountIdSelector,
  (tests, accountId) => ({
    tests: tests.filter(test => !test.get('isHidden')),
    accountId
  })
);

export const latestActionResultFilters = createSelector(
  actionsStateSelector,
  state => {
    return toJS(state.get('latestActionResultFilters'));
  }
);
