import {createSelector} from 'reselect';
import {plainTestSelector} from 'modules/tests/testSelectors';
import {plainReportSelector} from 'modules/reports/reportSelectors';

export const connectToTestAndReport = createSelector(
  plainTestSelector,
  plainReportSelector,
  (test, report) => ({
    test,
    report
  })
);
