import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DASHBOARD_RESULTS} from 'modules/dashboards/constants';
import {dashboardResultsEndpoint} from 'modules/dashboards/utils';
import {requestDashboardResultsComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestDashboardResultsListener() {
  yield takeLatest(REQUEST_DASHBOARD_RESULTS, requestDashboardResultsSaga);
}

function* requestDashboardResultsSaga({dashboardId, context}) {
  const url = dashboardResultsEndpoint({dashboardId});

  yield processRequest(
    'POST',
    url,
    {
      *success(dashboard) {
        yield put(requestDashboardResultsComplete(null, dashboardId, dashboard.results));
      },
      *error(err) {
        yield put(requestDashboardResultsComplete(err));
      },
      errorMessage: couldNot('request dashboard results')
    },
    {context: context, contextTypeId: context.contextTypeId}
  );
}
