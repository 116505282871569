import {select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountOptionsEndpoint} from 'modules/accountOptions/accountOptionUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {receiveAccountOptions, updateAccountOptionsComplete} from 'modules/accountOptions';

export function* updateAccountOptions({options}) {
  const accountId = yield select(accountIdSelector);
  const url = accountOptionsEndpoint({accountId});

  return yield processRequest(
    'PUT',
    url,
    {
      success: function*(data) {
        yield put(receiveAccountOptions(data));
        yield put(updateAccountOptionsComplete());
        return data;
      },

      error: function*({errorCode} = {}) {
        yield put(updateAccountOptionsComplete());
      }
    },
    options
  );
}
