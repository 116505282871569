import React from 'react';
import Position from 'components/overlay/position';

// Creates an overlay which appears next to a component specified by ref
// <Affix to={ref} preferSide="top" />

// [menuAnchor, targetAnchor], anchor = '{vertical} {horizonal}',
const SIDE = {
  top: ['bottom middle', 'top middle'],
  left: ['middle right', 'middle left'],
  right: ['middle left', 'middle right'],
  bottom: ['top right', 'bottom right']
};

export default class Affix extends React.Component {
  static defaultProps = {
    menuAnchor: 'top right',
    nodeAnchor: 'bottom right'
  };

  render() {
    const {children, show, to, preferSide, ...props} = this.props;
    if (!to || !show) return null;

    let menuAnchor;
    let nodeAnchor;

    if (preferSide && SIDE[preferSide]) {
      [menuAnchor, nodeAnchor] = SIDE[preferSide];
    } else {
      ({menuAnchor, nodeAnchor} = this.props);
    }

    return (
      <Position node={to} {...props} menuAnchor={menuAnchor} nodeAnchor={nodeAnchor}>
        {children}
      </Position>
    );
  }
}
