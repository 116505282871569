import {createSelector} from 'reselect';
import {usersSelector, userSelector as userPropSelector} from 'modules/users/userSelectors';
import {userOptionsState} from 'modules/userOptions/userOptionSelectors';
import {toKeyValueObject} from 'modules/accountOptions/accountOptionUtils';
import {isEditingSelf, canResetPassword, canEditRole} from 'modules/users/userUtils';
import {accountOptionsSelector} from 'modules/accountOptions/accountOptionSelectors';
import {currentAccountTypeSelector} from 'modules/accountTypes/accountTypeSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {
  accountSelector,
  accountState,
  userSelector,
  userState,
  usingServicesSelector,
  accessibilityFlagSelector
} from 'modules/auth/authSelectors';
import {segmentsBreadcrumbOptionsSelector} from 'modules/app/appSelectors';
import {toJS, getProps} from 'utils/immutableUtils';
import {shallPass, REGULAR_USER} from 'modules/auth/gandalf';
import {Map, Set} from 'immutable';
import {
  alertSubscriptionsSelector,
  alertStoryTypesSelector
} from 'modules/alertSubscriptions/selectors';
import {
  servicesLoadingSelector,
  servicesErrorSelector,
  servicesDataSelector
} from 'modules/services/serviceSelectors';

export const userSettingsPageSelector = createSelector(
  userSelector,
  userPropSelector,
  userState,
  userOptionsState,
  (loggedInUser, {user}, userState, userOptions) => {
    if (!user.size) {
      user = loggedInUser;
    }

    const allowResetPassword = canResetPassword(user, loggedInUser);
    const allowEditRole = canEditRole(user, loggedInUser);
    const title = isEditingSelf(user, loggedInUser) ? 'My settings' : 'Edit user';

    if (user.get('userId') == loggedInUser.get('userId') && !user.get('options')) {
      user = {...toJS(user), options: {...toJS(userOptions)}};
    }

    return {
      title,
      user: toJS(user),
      isUpdating: userState.get('isUpdating'),
      allowResetPassword,
      allowEditRole
    };
  }
);

const selectedFields = [
  'organizationName',
  'addressLine1',
  'addressLine2',
  'addressCity',
  'addressState',
  'addressPostcode',
  'addressCountry',
  'languages',
  'vatNumber'
];

export const accountSettingsPageSelector = createSelector(
  accountSelector,
  accountState,
  accountOptionsSelector,
  currentAccountTypeSelector,
  accessibilityFlagSelector,
  (account, accountState, accountOptions, accountType, accessibilityFlag) => {
    return {
      accountId: account.get('accountId'),
      account: getProps(account, selectedFields),
      accountOptions: toKeyValueObject(accountOptions),
      accountType: toJS(accountType),
      isUpdating: accountState.get('isUpdating'),
      accessibilityFlag
    };
  }
);

export const usersPageSelector = createSelector(
  usersSelector,
  userSelector,
  (users, user) => {
    return {
      users: users && users.toList().toJS(),
      loggedInUser: user && user.toJS()
    };
  }
);

export const alertsConfigSelector = createSelector(
  userSelector,
  reportSelector,
  segmentsBreadcrumbOptionsSelector,
  alertStoryTypesSelector,
  alertSubscriptionsSelector,
  usersSelector,
  (activeUser, report, segments, storyTypes, alertSubscriptions, users) => {
    // get list of users that we have permission to modify
    const lowerUsers = users.filter(
      user =>
        activeUser.get('userId') !== user.get('userId') && shallPass(activeUser, user.get('role'))
    );

    const distinctUserIds = alertSubscriptions.reduce((set, alertSub) => {
      return alertSub.get('isSubscribed') ? set.add(alertSub.get('channelId')) : set;
    }, Set());

    const {usersWithAlerts, usersWithoutAlerts} = lowerUsers.reduce(
      (container, user) => {
        if (distinctUserIds.includes(user.get('userId').toString())) {
          container.usersWithAlerts.push(setActiveAlerts(user, alertSubscriptions).toJS());
        } else {
          container.usersWithoutAlerts.push(user.toJS());
        }
        return container;
      },
      {usersWithAlerts: [], usersWithoutAlerts: []}
    );

    usersWithAlerts.unshift(setActiveAlerts(activeUser, alertSubscriptions).toJS());

    return {
      users,
      segments,
      storyTypes,
      usersWithAlerts,
      usersWithoutAlerts,
      isRegularUser: activeUser.get('role') === REGULAR_USER
    };
  }
);

export const servicesSettingsPageSelector = createSelector(
  servicesLoadingSelector,
  servicesErrorSelector,
  servicesDataSelector,
  (isLoading, loadingError, services) => {
    return {
      isLoading,
      loadingError,
      services: services && services.toJS()
    };
  }
);

export const serviceIdPropSelector = (_, props) => {
  return props.match && props.match.params && props.match.params.serviceId
    ? props.match.params.serviceId
    : null;
};

export const serviceSettingsPageSelector = createSelector(
  servicesLoadingSelector,
  servicesErrorSelector,
  servicesDataSelector,
  serviceIdPropSelector,
  (isLoading, loadingError, services, serviceId) => {
    const servicesJs = services && services.toJS();
    const service = serviceId && servicesJs && servicesJs[serviceId] ? servicesJs[serviceId] : null;

    return {
      isLoading,
      loadingError,
      serviceId,
      service: service ? service : null
    };
  }
);

export const settingsRoutesSelector = createSelector(
  usingServicesSelector,
  usingServices => {
    return {
      usingServices
    };
  }
);

function setActiveAlerts(user, alertSubscriptions) {
  const userId = user.get('userId').toString();
  const activeSubCount = alertSubscriptions.filter(alertSub => {
    return alertSub.get('channelId') === userId && alertSub.get('isSubscribed');
  }).size;
  return user.set('activeSubs', activeSubCount);
}
