import {takeEvery, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_CHECKS} from '../constants';
import {setChecks, requestChecksComplete} from '../actions';

export default function* requestPolicyChecksSaga() {
  yield takeEvery(REQUEST_CHECKS, requestPolicyChecks);
}

function* requestPolicyChecks(action) {
  try {
    const checks = yield processRequest('GET', 'policy-scopes/' + action.scope + '/checks');

    yield put(setChecks(action.scope, checks));
    yield put(requestChecksComplete());
  } catch (err) {
    yield put(requestChecksComplete(err));
  }
}
