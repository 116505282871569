import PropTypes from 'prop-types';
import React from 'react';

const RETRY_TIME = 3000;

const DEFAULT_STATE = {
  loaded: false,
  src: null // holds src once loaded (with cache bust params)
};

class ImagePoller extends React.Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
    this.attemptLoad(true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state.src && this.props.src === nextProps.src);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState(DEFAULT_STATE);
      this.attemptLoad();
    }
  }

  getCacheBustedSrc() {
    return this.props.src + '#' + new Date().getTime();
  }

  attemptLoad = (firstLoad = false) => {
    const src = firstLoad ? this.props.src : this.getCacheBustedSrc();
    const image = new Image();
    this.image = image;
    image.onload = this.handleLoad.bind(this, src);
    image.onerror = this.handleError;
    image.src = src;
  };

  handleError = () => {
    this.timeout = setTimeout(this.attemptLoad, RETRY_TIME);
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.image.onload = null;
    this.image.onerror = null;
    this.image = null;
  }

  handleLoad(src) {
    this.setState({loaded: true, src});
    const {onLoad} = this.props;
    onLoad && onLoad();
  }

  render() {
    const {src, children, style, className, onLoad, onClick, alt, ...props} = this.props;

    if (!this.state.loaded || !src) {
      return (
        <div style={style} className={className}>
          {children}
        </div>
      );
    }

    return (
      <img
        style={style}
        className={className}
        src={this.state.src || src}
        onClick={onClick}
        alt={alt}
      />
    );
  }
}

ImagePoller.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.any // shown whilst loading
};

export default ImagePoller;
