import React from 'react';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import {logout} from 'modules/auth';
import {push} from 'modules/location';
import Buttons from 'components/buttons';
import Button from 'components/button';
import ExternalLink from 'modules/location/externalLink';
import {userSelector} from 'modules/auth/authSelectors';
import {shallPass, ACCOUNT_OWNER} from 'modules/auth/gandalf';
import {subscriptionSettingsPath, paymentSettingsPath} from 'modules/settings/settingsUtils';
import {isPastDueSelector} from 'modules/subscriptions/subscriptionSelectors';
import styles from './accountDisabledStyles.scss';
import {Modal, ModalContent} from 'modules/modal';

const AccountExpired = connect(
  createSelector(
    userSelector,
    isPastDueSelector,
    (user, pastDue) => {
      return {
        pastDue,
        accountOwner: shallPass(user, ACCOUNT_OWNER)
      };
    }
  ),
  dispatch => ({
    logout: event => {
      dispatch(logout());
    },
    viewSubs: event => {
      dispatch(push(subscriptionSettingsPath));
    },
    updateCard: event => {
      dispatch(push(paymentSettingsPath));
    }
  })
)(({logout, viewSubs, updateCard, accountOwner, pastDue}) => {
  const footer = (
    <Buttons className={styles.accountDisabledButtons}>
      <Button onClick={logout}>Log out</Button>
      {accountOwner && (
        <Button cta onClick={viewSubs}>
          View subscriptions
        </Button>
      )}
      {accountOwner && pastDue && (
        <Button cta onClick={updateCard}>
          Update card details
        </Button>
      )}
    </Buttons>
  );

  return (
    <Modal dismissable={false}>
      <ModalContent title="Account disabled" footer={footer}>
        {!accountOwner && <p>This account has been disabled. Please contact the account owner.</p>}
        {accountOwner && (
          <p>This account has been disabled because there are no active subscriptions.</p>
        )}
        {pastDue && <p>Please update card details.</p>}

        <p>
          If you think this has been done in error, please{' '}
          <ExternalLink href="https://silktide.com/contact/">contact us</ExternalLink>.
        </p>
      </ModalContent>
    </Modal>
  );
});

export default AccountExpired;
