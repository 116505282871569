import {createSelector} from 'reselect';

const reportFiltersState = state => state.reportFilters;

export const filtersSelector = createSelector(
  reportFiltersState,
  state => ({
    filters: state.get('filters')
  })
);
