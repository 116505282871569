import React from 'react';
import cx from 'classnames';
import styles from './inspectorBar.scss';

export default function InspectorBar({className, children}) {
  return (
    <div className={cx(className, styles.bar)}>
      <header className={styles.header}>{children}</header>
    </div>
  );
}
