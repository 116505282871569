import React from 'react';
import {Route, Switch} from 'react-router-dom';
import CoursePage from '../pages/coursePage';
import CourseLessonPage from '../pages/courseLessonPage';
import {useSelector, useDispatch} from 'hooks/redux';
import {requestCourse} from 'modules/courses/actions';
import {requestingCourseSelector} from 'modules/courses/selectors';
import {NotFoundPage} from 'modules/location/pages/404Page';
import Spinner from 'components/spinner';

export default function CoursesRoute({match: {params, path}, location}) {
  const dispatch = useDispatch();
  const requestingCourse = useSelector(requestingCourseSelector);

  React.useEffect(() => {
    dispatch(requestCourse(params.courseId));
  }, [params.courseId, location.pathname]); // add path so that if we change route at all and this component is rendered, we will refetch

  if (requestingCourse.get('error')) {
    return <NotFoundPage />;
  }

  if (!requestingCourse.get('loaded') || requestingCourse.get('loading')) {
    return <Spinner marginTop={100} />;
  }

  return (
    <Switch>
      <Route exact path={path} component={CoursePage} />
      <Route path={`${path}/:lessonId`} component={CourseLessonPage} />
    </Switch>
  );
}
