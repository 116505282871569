exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3M7jDDBL_Q8AB5HFzBpcPq{border:1px solid #ddd;border-top:0;background:#fff;box-shadow:0 2px 5px rgba(0,0,0,.1)}", ""]);

// exports
exports.locals = {
	"table": "_3M7jDDBL_Q8AB5HFzBpcPq"
};