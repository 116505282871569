import React from 'react';
import {connect} from 'react-redux';
import {Form, Field} from 'modules/form/components';
import {accountSettingsPageSelector} from '../settingsSelectors';
import {updateAccountSettings} from 'modules/settings';
import Button from 'components/button';
import SettingsPage from 'components/settings/settingsPage';
import SettingItem from 'components/settings/settingItem';
import styles from 'components/settings/settingsPage.scss';
import AccountBrandingLogoUploader from 'modules/accountOptions/components/accountBrandingLogoUploader';
import Gandalf from 'components/gandalf';
import {SUPER_USER} from 'modules/auth/gandalf';
import {
  DEFAULT_SCHEDULE,
  FOLDER_VIEWS_ENABLED,
  ACCESSIBILITY_STANDARD,
  DEFAULT_ROLE_FOR_EVERYONE,
  ACADEMY_SCORES_OWNER_ONLY,
  ACCESSIBILITY_STANDARD_OPTIONS
} from 'modules/accountOptions/accountOptionConstants';
import {ROLE_MANAGER, ROLE_CONTRIBUTOR, ROLE_VIEWER} from 'modules/gandalf/gandalfUtils';
import {viewOrganizationSettingsPage} from 'modules/settings';

// Options for default role
const defaultRoleOptions = [
  {value: '', label: 'Only the creator of a website has rights to see it'},
  {value: ROLE_VIEWER, label: 'Everyone can see new reports'},
  {value: ROLE_CONTRIBUTOR, label: 'Everyone can contribute to new reports'},
  {value: ROLE_MANAGER, label: 'Everyone can manage new reports'}
];

export class AccountSettingsPage extends React.Component {
  static defaultProps = {
    account: {},
    accountOptions: {},
    title: 'Account Settings'
  };

  componentWillMount() {
    this.props.viewOrganizationSettingsPage();
  }

  handleValidSubmit = data => {
    const {updateAccountSettings, accountId} = this.props;

    updateAccountSettings({accountId, ...data});
  };

  renderA11yOptions() {
    return (
      <React.Fragment>
        <hr className={styles.divider} />

        <h3>Accessibility options</h3>

        <SettingItem
          title="Accessibility standard"
          description="The default standard to use for accesibility"
        >
          <Field
            type="radios"
            name={ACCESSIBILITY_STANDARD}
            options={ACCESSIBILITY_STANDARD_OPTIONS}
            stacked
          />
        </SettingItem>
      </React.Fragment>
    );
  }

  render() {
    const {title, account, accountOptions, accountType, isUpdating, accessibilityFlag} = this.props;

    const hasAccountBranding = !!accountType.brandingLogoUrl;

    return (
      <SettingsPage name={title}>
        <Form
          name="updateAccountForm"
          defaultValue={{...account, ...accountOptions}}
          onValidSubmit={this.handleValidSubmit}
        >
          <SettingItem title="Organization name" description="Your organization's name">
            <Field type="text" name="organizationName" required />
          </SettingItem>

          {!hasAccountBranding && (
            <SettingItem
              title="Brand logo"
              description="Branding to display at the top of reports and PDF exports"
            >
              <AccountBrandingLogoUploader />
            </SettingItem>
          )}

          <hr className={styles.divider} />

          <h3>Billing details</h3>

          <SettingItem
            title="Address"
            description="The first line of your organization's billing address"
          >
            <Field type="text" name="addressLine1" />
          </SettingItem>
          <SettingItem
            title="ZIP / Postal code"
            description="Your organization's ZIP / Postal code"
          >
            <Field type="text" name="addressPostcode" />
          </SettingItem>
          <SettingItem title="Country" description="Your organization's country">
            <Field type="country" name="addressCountry" />
          </SettingItem>
          <SettingItem
            title="VAT number"
            description="Your organization's VAT number (if applicable)"
          >
            <Field type="text" name="vatNumber" />
          </SettingItem>

          <hr className={styles.divider} />

          <h3>Account defaults</h3>

          <SettingItem
            title="Languages"
            description="The default languages to be used when analysing a website"
          >
            <Field type="languages" name="languages" />
          </SettingItem>

          <SettingItem
            title="Default schedule"
            description="The default schedule when creating new reports"
          >
            <Field type="schedule" name={DEFAULT_SCHEDULE} simple />
          </SettingItem>

          <SettingItem
            title="Default website permissions"
            description="What permisssions new websites have by default"
          >
            <Field type="select" name={DEFAULT_ROLE_FOR_EVERYONE} options={defaultRoleOptions} />
          </SettingItem>

          <SettingItem
            title="Show academy scores for owner only?"
            description="Enabling this feature will only show academy scores for account owner"
          >
            <Field type="boolean" name={ACADEMY_SCORES_OWNER_ONLY} />
          </SettingItem>

          {/* Disabled from September 2020, as we now prefer to allow the user to switch in the report UI */}
          {/* {accessibilityFlag && this.renderA11yOptions()} */}

          <Gandalf shallPass={SUPER_USER}>
            <hr className={styles.divider} />

            <SettingItem
              title="Enable views (SUPER USER ONLY)"
              description="Views allow you to create customised displays of your reports"
              linear
              reverse
            >
              <Field type="boolean" name={FOLDER_VIEWS_ENABLED} />
            </SettingItem>
          </Gandalf>

          <Button type="submit" isLoading={isUpdating}>
            Save
          </Button>
        </Form>
      </SettingsPage>
    );
  }
}

export default connect(
  accountSettingsPageSelector,
  {updateAccountSettings, viewOrganizationSettingsPage}
)(AccountSettingsPage);
