import React from 'react';
import {select} from 'redux-saga/effects';
import {dashboardWidgetsSelector} from 'modules/dashboards/selectors';

export function dashboardsEndpoint() {
  return 'dashboards';
}

export function widgetTypesEndpoint() {
  return 'widget-types';
}

export function dashboardEndpoint({dashboardId}) {
  return dashboardsEndpoint() + '/' + dashboardId;
}

export function dashboardResultsEndpoint({dashboardId}) {
  return dashboardEndpoint({dashboardId}) + '/results';
}

export function mapWidgetTypeOption(widgetType) {
  return {
    value: widgetType.get('widgetTypeId'),
    label: (
      <React.Fragment>
        <strong>{widgetType.get('name')}</strong>
        <br />
        <span>{widgetType.get('description')}</span>
      </React.Fragment>
    )
  };
}

export function* removeWidgetFromDashboard(dashboard, {widgetId}) {
  const widgetIndex = dashboard
    .get('widgets')
    .findIndex(widget => widget.get('widgetId') == widgetId);

  if (widgetIndex < 0) return dashboard;

  return dashboard.removeIn(['widgets', widgetIndex]);
}

export function* addWidgetToDashboard(dashboard, {rowIndex, columnIndex, widgetTypeId, formData}) {
  const {name, options} = formData;
  const dashboardId = dashboard.getIn(['dashboard', 'dashboardId']);

  // we need to get the widgets in this rol/column so we know what to set as the new order
  // TODO does the backend do this automatically?
  const dashboardWidgets = yield select(dashboardWidgetsSelector, {
    dashboardId,
    rowIndex: rowIndex,
    columnIndex: columnIndex
  });
  const order = dashboardWidgets.size;
  const newWidget = makeWidget(
    dashboardId,
    widgetTypeId,
    name,
    rowIndex,
    columnIndex,
    order,
    options
  );

  return dashboard.update('widgets', widgets => {
    return widgets.push(newWidget);
  });
}

export function* editWidgetInDashboard(dashboard, {widgetId, formData}) {
  const {name, options} = formData;
  const widgetIndex = dashboard
    .get('widgets')
    .findIndex(widget => widget.get('widgetId') == widgetId);

  if (widgetIndex < 0) return dashboard;

  return dashboard.updateIn(['widgets', widgetIndex], widget => {
    return widget.set('name', name).set('options', options);
  });
}

export function makeWidget(dashboardId, widgetTypeId, name, row, column, order, options) {
  return {
    dashboardId,
    widgetTypeId,
    row,
    column,
    order,
    name,
    options,
    context: null,
    contextTypeId: ''
  };
}
