import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import useWindowWidth from 'hooks/useWindowWidth';
import {toggleSidebar} from 'modules/app';
import usePrevious from 'hooks/usePrevious';

export const ScreenSizeContext = React.createContext(calculateSizes(window.innerWidth));

function ScreenSizeProvider({children, ...props}) {
  const width = useWindowWidth();
  const lastWidth = usePrevious(width);

  useEffect(() => {
    const sizes = calculateSizes(width);
    const lastSizes = calculateSizes(lastWidth);

    if (sizes.tablet != lastSizes.tablet) {
      props.toggleSidebar(sizes.tablet);
    }
  }, [width]);

  return (
    <ScreenSizeContext.Provider value={calculateSizes(width)}>
      {children}
    </ScreenSizeContext.Provider>
  );
}

function calculateSizes(width) {
  return {
    largeMobile: width <= 425,
    tablet: width <= 768
  };
}

export default connect(null, dispatch => ({
  toggleSidebar: (...args) => {
    dispatch(toggleSidebar(...args));
  }
}))(ScreenSizeProvider);
