export const FETCH_LEADERBOARDS = 'leaderboards:FETCH_LEADERBOARDS';
export const FETCH_LEADERBOARD = 'leaderboards:FETCH_LEADERBOARD';
export const FETCH_LEADERBOARD_RESULTS = 'leaderboards:FETCH_LEADERBOARD_RESULTS';

export const FETCH_LEADERBOARDS_COMPLETE = 'leaderboards:FETCH_LEADERBOARDS_COMPLETE';
export const FETCH_LEADERBOARD_COMPLETE = 'leaderboards:FETCH_LEADERBOARD_COMPLETE';
export const FETCH_LEADERBOARD_REPORTS_COMPLETE = 'leaderboards:FETCH_LEADERBOARD_REPORTS_COMPLETE';

export const RECEIVE_LEADERBOARD = 'leaderboards:RECEIVE_LEADERBOARD';
export const RECEIVE_LEADERBOARDS = 'leaderboards:RECEIVE_LEADERBOARDS';
export const RECEIVE_LEADERBOARD_RESULTS = 'leaderboards:RECEIVE_LEADERBOARD_RESULTS';

export const REQUEST_CREATE_LEADERBOARD = 'leaderboards:REQUEST_CREATE_LEADERBOARD';
export const REQUEST_UPDATE_LEADERBOARD = 'leaderboards:REQUEST_UPDATE_LEADERBOARD';
export const REQUEST_DELETE_LEADERBOARD = 'leaderboards:REQUEST_DELETE_LEADERBOARD';

export const DELETE_LEADERBOARD = 'leaderboards:DELETE_LEADERBOARD';
export const RECEIVE_DEFAULT_COLUMNS = 'leaderboards:RECEIVE_DEFAULT_COLUMNS';

export const EXPORT_LEADERBOARD = 'leaderboards:EXPORT_LEADERBOARD';
export const LEADERBOARD_EXPORTED = 'leaderboards:LEADERBOARD_EXPORTED';
