exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ElUpKSWegiGMyIl8lIJRV p{line-height:24px}.ElUpKSWegiGMyIl8lIJRV span{display:inline;padding:2px;border:1px solid transparent}.ElUpKSWegiGMyIl8lIJRV span._19M8ACGgnH5S7Vm294g2d8{border-color:rgba(83,59,226,.3);background:rgba(83,59,226,.1)}", ""]);

// exports
exports.locals = {
	"wrapper": "ElUpKSWegiGMyIl8lIJRV",
	"active": "_19M8ACGgnH5S7Vm294g2d8"
};