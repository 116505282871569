import React from 'react';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class HoverListener extends React.Component {
  state = {
    isHovered: false
  };

  // anyChildrenActiveTooltips = () => {
  //   const child = this.props.children(true);
  //   return this.hasActiveTooltip(child);
  // };

  // hasActiveTooltip = child => {
  //   if (!child || typeof child === 'string') {
  //     return false;
  //   }

  //   if (child.type === HoverListener) {
  //     return child.state.isHovered;
  //   }

  //   var hasActive = false;

  //   for (var propName in child.props) {
  //     if (hasActive) continue;

  //     var prop = child.props[propName];

  //     var count;
  //     try {
  //       count =
  //         !!prop &&
  //         (!!prop.type || Array.isArray(prop)) &&
  //         React.Children.count(prop);
  //     } catch (err) {
  //       count = null;
  //     }

  //     if (propName === 'children' && typeof prop === 'function') {
  //       hasActive = this.hasActiveTooltip(prop(true));
  //     }

  //     if (!count) continue;

  //     if (count === 1) {
  //       hasActive = this.hasActiveTooltip(prop);
  //     } else {
  //       React.Children.forEach(prop, child => {
  //         if (!hasActive) {
  //           hasActive = this.hasActiveTooltip(child);
  //         }
  //       });
  //     }
  //   }
  //   return hasActive;
  // };

  hoverSetter = isHovered => e => {
    this.setState({isHovered});
    // this.setState({isHovered: isHovered && !this.anyChildrenActiveTooltips()});
  };

  render() {
    const {children, useMouseEnterLeave, ...props} = this.props;

    if (typeof children !== 'function') {
      throw new Error('children must be a function');
    }

    if (useMouseEnterLeave) {
      props.onMouseEnter = this.hoverSetter(true);
      props.onMouseLeave = this.hoverSetter(false);
    } else {
      props.onMouseOver = this.hoverSetter(true);
      props.onMouseOut = this.hoverSetter(false);
    }

    return <span {...filterInvalidDOMProps(props)}>{children(this.state.isHovered)}</span>;
  }
}
