import React from 'react';
import Pusher from 'modules/pusher/components/pusher';

export default (channel, event, onUpdate) => WrappedComponent => props => {
  return (
    <div>
      <Pusher contextProps={props} channel={channel} event={event} onUpdate={onUpdate} />
      <WrappedComponent {...props} />
    </div>
  );
};
