import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {connectContext} from 'modules/app/appUtils';
import {push} from 'modules/location';
import {usersPath} from 'modules/users/userUtils';
import styles from '../pages/homePage.scss';
import HomePageSection from './homePageSection';
import Center from 'components/center';
import Button from 'components/button';
import UserImage from 'components/user/userImage';
import RelativeDate from 'components/time/relativeDate';
import Pill from 'components/pill';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class RecentUsers extends React.Component {
  static defaultProps = {
    recentUsers: []
  };

  handleManageUsersClick = () => this.props.push(usersPath);

  renderUser = user => {
    return (
      <span className={styles.user} key={`user_${user.userId}`}>
        <UserImage user={user} className={styles.image} />
        <div className={styles.info}>
          <span>{user.name}</span>
          {!!user.lastActive && <RelativeDate timestamp={user.lastActive} />}
          {!user.lastActive && (
            <Pill appearance="bad" tiny className={styles.infoPill}>
              Never logged in
            </Pill>
          )}
        </div>
      </span>
    );
  };

  render() {
    const {recentUsers, params, screenSizes} = this.props;

    if (!recentUsers.length) {
      return null;
    }

    return (
      <HomePageSection title="Your team">
        <div className={styles.usersContainer}>{recentUsers.map(this.renderUser)}</div>
        {!screenSizes.tablet && (
          <Center marginBottom={20}>
            <Button onClick={this.handleManageUsersClick} className={styles.btn} wire tiny>
              Manage users
            </Button>
          </Center>
        )}
        <hr />
      </HomePageSection>
    );
  }
}

export default compose(
  connect(
    null,
    {push}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(RecentUsers);
