import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import styles from './input.scss';
import Autocomplete from 'react-autocomplete';
import Icon from 'components/font-awesome';

export default class Input extends React.Component {
  static defaultProps = {
    value: '',
    getItemValue: item => item
  };

  render() {
    const {invalid, className, autocomplete, loading, ...props} = this.props;
    const classes = cx(styles.input, className, {
      [styles.invalid]: invalid,
      [styles.loading]: loading,
      [styles.hasSize]: !!props.size
    });

    const inputProps = {
      ...filterInvalidDOMProps({...props, className: classes})
    };

    if (autocomplete) {
      return this.renderAutocomplete(inputProps);
    }

    return <input type="text" {...inputProps} />;
  }

  renderMenu(items, value, style) {
    return <div className={cx(styles.menu, {[styles.empty]: !items.length})}>{items}</div>;
  }

  renderItem = (item, isHighlighted) => {
    return (
      <div
        key={item.canonical_name}
        className={cx(styles.menuItem, {
          [styles.highlighted]: isHighlighted
        })}
      >
        {this.props.renderItem(item)}
      </div>
    );
  };

  renderAutocomplete(inputProps) {
    const {options, className, value, loading, getItemValue, autocomplete, onChange} = this.props;

    return (
      <div className={className}>
        <Autocomplete
          value={value}
          wrapperStyle={{}}
          inputProps={inputProps}
          getItemValue={getItemValue}
          renderMenu={this.renderMenu}
          renderItem={this.renderItem}
          items={!loading ? options : []}
          onSelect={onChange}
          onChange={event => {
            autocomplete(event.target.value);
          }}
        />
        <Icon
          spin
          name="spinner"
          className={cx(styles.inputSpinner, {[styles.isLoading]: loading})}
        />
      </div>
    );
  }
}
