import React from 'react';
import Button from 'components/button';
import {ModalContent} from 'modules/modal';
import SchemaFields from 'modules/form/components/schemaField';
import {makeDefaultData} from 'modules/policies/policyUtils';
import {useForm, Form, TypeField} from 'modules/form/hooks/useForm';

export default function WidgetModal({
  title,
  onSave,
  isLoading,
  widgetName = '',
  widgetOptions = {},
  immSupportedOptions // needs to be immutable
}) {
  const [form, {name, options}] = useForm({
    name: widgetName,
    options: makeDefaultData(immSupportedOptions.toJS(), widgetOptions)
  });

  const handleSubmit = React.useCallback(() => {
    if (form.attemptSubmit()) {
      const formData = form.getFormattedValue();
      onSave(formData);
    }
  }, [form]);

  return (
    <ModalContent
      title={title}
      footer={
        <Button isLoading={isLoading} onClick={handleSubmit}>
          Save
        </Button>
      }
    >
      <Form form={form}>
        <TypeField label="Title" type="text" field={name} autoFocus />
        <br />

        <SchemaFields
          field={options}
          supportedOptions={immSupportedOptions}
          onAddRepeatingField={(fieldSchema, field) => {
            field.push(makeDefaultData(fieldSchema, {}));
            field.rerender();
          }}
        />
      </Form>
    </ModalContent>
  );
}
