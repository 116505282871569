import {isNumerical, removeChars} from 'utils/stringUtils';
import {countryDialCodes} from 'modules/form/constants';

// get country code from a telephone number string
export function getCountryCode(number) {
  const splitNumber = number.split(')');

  if (splitNumber.length > 1) {
    return `${splitNumber[0]})`;
  }

  return '';
}

// get 2 character country code from end of hyphenated language string
export function getCountryString(str) {
  return str.split('-')[1];
}

// return telephone number without country code
export function removeCountryCode(number) {
  const splitNumber = number.split(')');

  if (splitNumber.length > 1) {
    if (splitNumber[1] !== 'undefined') {
      return splitNumber[1];
    } else {
      return '';
    }
  }

  if (number.indexOf('+') === 0) {
    return number.split('+')[1];
  }

  return number;
}

// phone number formatted correctly eg '(+44)123123123'
export function isFormattedPhoneNumber(str) {
  const sanitized = removeChars(str, ['(', ')', '+']).replace(' ', '');
  return str.indexOf(')') !== -1 && str.indexOf('(') !== -1 && isNumerical(sanitized);
}

export function getDialCode(countryCode) {
  return countryDialCodes[countryCode].dialCode;
}
