import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import SwitchComponent from 'components/switch';
import SwitchOption from 'components/switch/switchOption';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import panelStyles from '../panel/panel.scss';

export class Switch extends React.Component {
  handleClick = value => {
    // This is only used if using a selector instead of children from the back-end.
    const {
      setFilterParams,
      config: {filter: filterName, resetfilter, resetfiltervalue}
    } = this.props;

    if (setFilterParams) {
      const params = {
        [filterName]: value
      };

      if (resetfilter) {
        params[resetfilter] = resetfiltervalue;
      }

      setFilterParams(params);
    }
  };

  buildChildren = () => {
    const {
      children,
      result,
      filter,
      config: {selector, filter: filterName, resetfilter, resetfiltervalue}
    } = this.props;

    if (selector && selector.length > 0) {
      // If using a selector, then we can just build up the switch options ourselves.
      return _.map(result, (item, key) => {
        return (
          <SwitchOption
            active={key === filter[filterName]}
            key={key}
            onClick={() => this.handleClick(key)}
          >
            {item}
          </SwitchOption>
        );
      });
    }

    // If using the <Option> from layoutComponents, then we need to feed in filter.
    return React.Children.map(children, child => {
      const config = {
        ...child.props.config,
        filter: filterName,
        resetfilter,
        resetfiltervalue
      };
      return React.cloneElement(child, {config});
    });
  };

  render() {
    const {
      isArchiveReport,
      config: {stacked, theme, tabbed, large}
    } = this.props;

    if (isArchiveReport) return null;

    return (
      <SwitchComponent stacked={!!stacked} theme="default" tabbed={!!tabbed} large={!!large}>
        {this.buildChildren()}
      </SwitchComponent>
    );
  }
}

Switch.handlesLoading = true;

export default connect((state, props) => ({
  isArchiveReport: isArchiveActiveSelector(state, props)
}))(Switch);
