import React from 'react';
import styles from './styles.scss';

export default class ColorContrast extends React.Component {
  render() {
    const {data} = this.props;

    if (!data) {
      return <span>Not detected</span>;
    }

    const {
      color,
      bgColor,
      overlayColor,
      bgRepeat,
      fontWeight,
      fontSizePixels,
      bgImg,
      bgSize,
      bgPosition
    } = data;

    let backgroundImg = 'none';
    if (bgImg) {
      if (bgImg.match(/^http/)) {
        backgroundImg = 'url(' + bgImg + ')';
      } else {
        backgroundImg = bgImg;
      }
    }

    const previewStyles = {
      color,
      fontSize: fontSizePixels,
      fontWeight,
      backgroundColor: bgColor,
      backgroundImage: backgroundImg,
      backgroundRepeat: bgRepeat,
      backgroundPosition: bgPosition,
      backgroundSize: bgSize || (!bgRepeat || bgRepeat === 'no-repeat' ? 'cover' : 'auto')
    };

    const overlayStyles = {
      backgroundColor: overlayColor
    };

    return (
      <div className={styles.colorSwatch} style={previewStyles}>
        <div className={styles.colorSwatchOverlay} style={overlayStyles}>
          <span>A</span>
        </div>
      </div>
    );
  }
}
