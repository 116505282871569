import React from 'react';

export default class Unit extends React.Component {
  render() {
    const {data, options} = this.props;
    let {singular, plural, unit} = options;

    if (!plural) {
      plural = singular;
    }
    if (!singular) {
      singular = plural;
    }
    if (!unit) {
      unit = data == 1 ? singular : plural;
    }

    return (
      <span>
        {data}
        {unit}
      </span>
    );
  }
}
