exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1DwVwgt4pbBBcqgv57y1-e{width:0px;overflow-x:hidden;-webkit-overflow-scrolling:touch;overflow-y:scroll;height:100vh;position:absolute;z-index:999999;top:0;right:0;background:#fff;transition:width 500ms ease-in-out}._1DwVwgt4pbBBcqgv57y1-e.a9MuppOklnTGEA93qfUEW{box-shadow:0 0 100px rgba(0,0,0,.4);width:500px}@media(max-width: 500px){._1DwVwgt4pbBBcqgv57y1-e.a9MuppOklnTGEA93qfUEW{width:100vw}}._1DwVwgt4pbBBcqgv57y1-e iframe{display:block;border:0;width:100%;height:calc(100% - 30px);min-width:500px}@media(max-width: 500px){._1DwVwgt4pbBBcqgv57y1-e iframe{min-width:320px}}._1DwVwgt4pbBBcqgv57y1-e ._2_hEgGbNY_1I26u3Ukh3NV{display:flex;height:30px;padding:0;align-items:center;background:#fff}._1DwVwgt4pbBBcqgv57y1-e ._2_hEgGbNY_1I26u3Ukh3NV ._3-5Wsf75xYk5_m817vYndw{color:rgba(0,0,0,.5);cursor:pointer;margin-left:auto;margin-right:8px}._1DwVwgt4pbBBcqgv57y1-e ._2_hEgGbNY_1I26u3Ukh3NV ._3-5Wsf75xYk5_m817vYndw:hover{color:#000}", ""]);

// exports
exports.locals = {
	"helpSidebar": "_1DwVwgt4pbBBcqgv57y1-e",
	"isOpen": "a9MuppOklnTGEA93qfUEW",
	"helpHeading": "_2_hEgGbNY_1I26u3Ukh3NV",
	"closeIcon": "_3-5Wsf75xYk5_m817vYndw"
};