import PropTypes from 'prop-types';
import React from 'react';
import Choice from 'components/choice';

export default class MultichoiceInput extends React.Component {
  static defaultProps = {
    value: [],
    options: []
  };

  static propTypes = {
    value: PropTypes.array,
    options: PropTypes.array.isRequired
  };

  render() {
    return <Choice {...this.props} multi />;
  }
}
