import {fromJS} from 'immutable';
import {LOGOUT, SWITCH_ACCOUNT} from 'modules/auth';
import {UPDATE_SELF_CONSENTS_COMPLETE} from 'modules/auth/auth';
import {keyBy} from 'utils/immutableUtils';

const endpoint = 'users';

const INITIAL_STATE = fromJS({
  data: {},
  isFetchingUsers: false,
  isInvitingUsers: false,
  pusherPrefix: ''
});

export default function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS:
      return state.set('isFetchingUsers', true);

    case RECEIVE_USERS:
      const {users} = action;
      const keyedUsers = keyBy(users, 'userId');
      let localState = state;
      keyedUsers.forEach(user => {
        localState = localState.mergeIn(['data', user.get('userId').toString()], user);
      });
      return localState.set('isFetchingUsers', false);

    case RECEIVE_USER:
      const {user} = action;
      return state.mergeIn(['data', user.userId.toString()], fromJS(user));

    case RECEIVE_PUSHER_PREFIX:
      const {pusherPrefix} = action;
      return state.set('pusherPrefix', pusherPrefix);

    case ADD_USER:
    case UPDATE_USER:
      return state.set('isUpdating', true);

    case ADD_USER_COMPLETE:
    case UPDATE_USER_COMPLETE:
      return state.set('isUpdating', false);

    case DELETE_USER:
      return state.deleteIn(['data', action.userId]);

    case INVITE_USERS:
      return state.set('isInvitingUsers', true);
    case INVITE_USERS_COMPLETE:
      return state.set('isInvitingUsers', false);

    case LOGOUT:
      return INITIAL_STATE;

    case UPDATE_SELF_CONSENTS_COMPLETE:
      if (action.consents['email-updates'] === '') {
        // user clicked X, change value locally to hide modal, but backend will reflect ''
        // meaning the modal will show again on the next mount
        action.consents['email-updates'] = 'dont matter';
      }
      return state.mergeIn(['data', action.userId.toString(), 'consents'], action.consents);

    case CLEAR_USERS:
    case SWITCH_ACCOUNT:
      // When we switch account, we can't clear the auth user.
      const {userId, accountId} = action;
      const authUser = state.getIn(['data', userId]);

      // only clear data if we are switching to a different account
      if (authUser.get('accountId') == accountId) {
        return state;
      }

      return INITIAL_STATE.setIn(['data', userId], authUser);
  }
  return state;
}

export const FETCH_USERS = 'users:FETCH_USERS';
export function fetchUsers() {
  return {type: FETCH_USERS};
}

export const RECEIVE_USER = 'users:RECEIVE_USER';
export function receiveUser(user) {
  return {type: RECEIVE_USER, user};
}

export const RECEIVE_PUSHER_PREFIX = 'users:RECEIVE_PUSHER_PREFIX';
export function receivePusherPrefix(pusherPrefix) {
  return {type: RECEIVE_PUSHER_PREFIX, pusherPrefix};
}

export const RECEIVE_USERS = 'users:RECEIVE_USERS';
export function receiveUsers(users) {
  return {type: RECEIVE_USERS, users};
}

export const ADD_USER = 'users:ADD_USER';
export function addUser(user) {
  return {type: ADD_USER, user};
}

export const ADD_USER_COMPLETE = 'users:ADD_USER_COMPLETE';
export function addUserComplete() {
  return {type: ADD_USER_COMPLETE};
}

export const UPDATE_USER = 'users:UPDATE_USER';
export function updateUser(userId, user) {
  return {type: UPDATE_USER, user, userId: userId.toString()};
}

export const UPDATE_USER_COMPLETE = 'users:UPDATE_USER_COMPLETE';
export function updateUserComplete() {
  return {type: UPDATE_USER_COMPLETE};
}

export const DELETE_USER = 'users:DELETE_USER';
export function deleteUser(userId) {
  return {type: DELETE_USER, userId: userId.toString()};
}

export const REQUEST_DELETE_USER = 'users:REQUEST_DELETE_USER';
export function requestDeleteUser(user) {
  return {type: REQUEST_DELETE_USER, user};
}

export const REQUEST_EDIT_USER = 'users:REQUEST_EDIT_USER';
export function requestEditUser(user, isEditingSelf) {
  return {type: REQUEST_EDIT_USER, user, isEditingSelf};
}

export const TRIGGER_INVITE_USERS = 'users:TRIGGER_INVITE_USERS';
export function triggerInviteUsers({defaultValue, amountOfRows, userErrorMessages} = {}) {
  return {
    type: TRIGGER_INVITE_USERS,
    defaultValue,
    amountOfRows,
    userErrorMessages
  };
}

export const INVITE_USERS = 'users:INVITE_USERS';
export function inviteUsers({users, shouldWelcome, welcomeMessage}) {
  return {type: INVITE_USERS, users, shouldWelcome, welcomeMessage};
}

export const INVITE_USERS_COMPLETE = 'users:INVITE_USERS_COMPLETE';
export function inviteUsersComplete(errorMessage = false) {
  return {type: INVITE_USERS_COMPLETE, errorMessage};
}

export const CLEAR_USERS = 'users:CLEAR_USERS';
export function clearUsers({accountId, userId}) {
  return {
    type: CLEAR_USERS,
    accountId,
    userId: userId.toString()
  };
}

export const REQUEST_UNLOCK_USER = 'users:REQUEST_UNLOCK_USER';
export function requestUnlockUser(userId) {
  return updateUser(userId, {failedLoginAttempts: 0});
}
