import _ from 'lodash';
import emailValidator from 'email-validator';

// https://gist.github.com/dperini/729294 (notice I removed the "user:pass" authentication requirement)
const urlRegExp = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#]\S*)?$/i;

export function isEmail(value) {
  return emailValidator.validate(value);
}

export function isUrl(value) {
  return urlRegExp.test(value);
}

export function isUsable(value) {
  if (typeof value == 'string' || Array.isArray(value) || _.isPlainObject(value)) {
    return !_.isEmpty(value);
  } else if (typeof value == 'number') {
    return !isNaN(value);
  } else if (typeof value == 'boolean') {
    return true;
  } else {
    // null or undefined
    return value != null;
  }
}
