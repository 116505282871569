import React from 'react';
import styles from './circularSpinner.scss';
import cx from 'classnames';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export class MessageSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: !props.delay
    };
    this.timeout = null;
  }

  componentDidMount() {
    const {delay} = this.props;

    if (delay) {
      this.timeout = window.setTimeout(() => {
        this.setState({showMessage: true});
      }, delay);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const {className, marginTop, children, marginBottom, style, ...props} = this.props;

    const classes = cx('spin', styles.csSpinner, className);

    return (
      <div>
        <span
          {...filterInvalidDOMProps(props)}
          style={{...style, marginTop, marginBottom}}
          className={classes}
        />

        {this.state.showMessage && (
          <div style={{textAlign: 'center', marginTop: '20px'}}>{children}</div>
        )}
      </div>
    );
  }
}

export default localStyles(styles)(MessageSpinner);
