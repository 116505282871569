import {Map, fromJS} from 'immutable';
import {toJS, keyBy} from 'utils/immutableUtils';
import {havePropsChanged} from 'utils/propUtils';

import {
  SET_ROLE,
  ROLE_SET,
  ROLE_REMOVED,
  VIEW_PERMISSIONS_CONFIG_PAGE,
  RECEIVE_PERMISSIONS
} from './reportPermissions';

const initialState = Map({
  updating: false,
  success: false,
  data: Map()
});

export default function reportPermissionsReducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_PERMISSIONS_CONFIG_PAGE:
      // We only store permissions for the current report.
      return state.set('data', Map());

    case RECEIVE_PERMISSIONS:
      return receivePermissions(state, action.permissions);

    case SET_ROLE:
      return state.merge({
        updating: true,
        success: false
      });

    case ROLE_SET:
      return receivePermissions(
        state.merge({
          updating: false,
          success: true
        }),
        [action.permission]
      );

    case ROLE_REMOVED:
      return roleRemoved(state, action);
  }

  return state;
}

function receivePermissions(state, permissions) {
  return state.mergeIn(['data'], keyBy(permissions, 'permissionId'));
}

function roleRemoved(state, action) {
  const data = state.get('data');

  return state.set(
    'data',
    data.filter(permission => {
      // Remove permissions that match the action
      return !(
        permission &&
        permission.get('userGroupId') == action.userGroupId &&
        permission.get('reportId') == action.reportId
      );
    })
  );
}
