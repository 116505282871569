import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {addFilter} from 'modules/reportFilters';
import ModalContent from 'modules/modal/components/modalContent';
import Buttons from 'components/buttons';
import Button from 'components/button';
import {ModalForm, Field} from 'modules/form/components';

export class CreateFilterModal extends React.Component {
  static defaultProps = {
    name: 'createFilter'
  };

  handleValidSubmit = data => {
    const {filter, addFilter, hideModal} = this.props;

    filter.value = _.get(data, filter.field);
    addFilter(filter);
    hideModal();
  };

  render() {
    const {hideModal, filter} = this.props;

    return (
      <ModalForm title="Add filter" submitLabel="Add filter" onValidSubmit={this.handleValidSubmit}>
        <Field type={filter.type} label={filter.name} name={filter.field} />
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {addFilter}
)(CreateFilterModal);
