import React from 'react';
import styles from './tutor.scss';

export default function Tutor({tutor}) {
  const userId = tutor.get('userId');
  const name = tutor.get('name');
  const avatar = tutor.get('avatar');

  return (
    <div className={styles.tutor} key={userId}>
      <img src={avatar} alt={name} />
      <p>{name}</p>
    </div>
  );
}
