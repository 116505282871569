import {createSelector} from 'reselect';

export const signupStateSelector = state => state.signup;

export const signupParamsSelector = createSelector(
  signupStateSelector,
  state => state.get('entryParams')
);

export const urlToTestSelector = createSelector(
  signupParamsSelector,
  params => params.get('urlToTest')
);

export const plainSignupParamsSelector = createSelector(
  signupParamsSelector,
  params => params.toJS()
);
