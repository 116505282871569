import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Cover from 'modules/quote/images/q-cover.jpg';
import styles from './pages.scss';
import g2Crowd from 'modules/quote/images/g2.png';

const propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.string,
    account: PropTypes.objectOf(PropTypes.any),
    quote: PropTypes.objectOf(PropTypes.any),
    quoteTable: PropTypes.objectOf(PropTypes.any)
  })
};

const SilktideCover = ({quote}) => (
  <div className={styles.coverPage}>
    <img src={Cover} alt="Silktide Website" className={styles.coverImage} />
    <div className={styles.coverMeta}>
      <div className={styles.coverMetaLeft}>
        <img src={g2Crowd} alt="G2 Crowd" />
      </div>
      <div className={styles.coverMetaRight}>
        <h3>Proposal for {quote.account.organizationName}</h3>
        <ul>
          <li>
            <span>Reference:</span> {quote.id}
          </li>
          <li>
            <span>Date:</span> {moment.unix(quote.quote.raised).format('Do MMMM YYYY')}
          </li>
          <li>
            <span>Your contact:</span> {quote.quote.preparedBy.name} (
            <a href={'mailto:' + quote.quote.preparedBy.email}>{quote.quote.preparedBy.email}</a>)
          </li>
        </ul>
      </div>
    </div>
  </div>
);

SilktideCover.propTypes = propTypes;

export default SilktideCover;
