import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

class FullDate extends React.Component {
  render() {
    const date = moment.unix(this.props.timestamp);

    return <div {...filterInvalidDOMProps(this.props)}>{date.calendar()}</div>;
  }
}

FullDate.propTypes = {
  timestamp: PropTypes.number.isRequired
};

export default FullDate;
