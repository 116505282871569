import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {inviteUsers} from 'modules/users';
import {ModalForm, Field} from 'modules/form/components';
import {inviteUsersModalSelector} from 'modules/users/userSelectors';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import ErrorMessage from 'modules/form/components/errorMessage';

export class InviteUsersModal extends React.Component {
  static defaultProps = {
    defaultValue: {
      name: '',
      email: '',
      role: 'user',
      shouldWelcome: false,
      welcomeMessage: ''
    },
    userErrorMessages: ''
  };

  handleValidSubmit = data => {
    const {name, email, role, ...theRest} = data;

    this.props.inviteUsers({
      ...theRest,
      users: [{name, email, role}]
    });
  };

  render() {
    const {defaultValue, userErrorMessages, isInvitingUsers} = this.props;

    return (
      <ModalForm
        title="Invite user"
        submitLabel="Invite user"
        defaultValue={defaultValue}
        isLoading={isInvitingUsers}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field type="text" name="name" label="Name" required />
        <Field type="email" name="email" label="Email" required />
        <Field type="role" name="role" label="Role" required />

        <ErrorMessage message={userErrorMessages} />

        <Field description="Include welcome message" type="boolean" name="shouldWelcome" />
        <Field
          label="Welcome message"
          type="textarea"
          name="welcomeMessage"
          required
          showIf={data => data.shouldWelcome}
        />
      </ModalForm>
    );
  }
}

export default connect(
  inviteUsersModalSelector,
  {inviteUsers}
)(InviteUsersModal);
