export {default} from './resultsReducer';

export const RECEIVE_RESULTS = 'results:RECEIVE_RESULTS';
export function receiveResults(results) {
  return {
    type: RECEIVE_RESULTS,
    results
  };
}

// supports old issueActions
export const PERFORM_ISSUE_ACTION = 'results:PERFORM_ISSUE_ACTION';
export function performIssueAction(testId, metricName, issueHash, state) {
  return {
    type: PERFORM_ISSUE_ACTION,
    testId,
    metricName,
    issueHash,
    state
  };
}
