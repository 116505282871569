import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './socialBanner.scss';
import ExternalLink from 'modules/location/externalLink';

const preferredWidth = 851;
const preferredHeight = 315;

class SocialBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      container: {width: 0, height: 0},
      offset: {top: 0, left: 0},
      scale: 1,
      labelLeft: this.calculateLabelLeft(preferredWidth),
      coverError: false
    };
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener('resize', _.debounce(this.handleResize, 40));
  }

  updateContainerSize() {
    const {container, image} = this.refs;

    if (container) {
      const width = container.clientWidth;
      const height = container.clientHeight;

      this.setState({
        container: {width, height},
        scale: width / preferredWidth,
        labelLeft: this.calculateLabelLeft(width),
        imageHeight: image.clientHeight
      });
    }
  }

  handleResize = e => {
    this.updateContainerSize();
  };

  handleCoverPhoto = e => {
    const {clientHeight, clientWidth} = e.target;
    const {bannerOffsetY, bannerOffsetX} = this.props.result;

    const offset = this.calculateScaledOffset(
      bannerOffsetX,
      bannerOffsetY,
      clientHeight,
      clientWidth
    );

    this.setState({offset, imageHeight: clientHeight});
  };

  handleCoverError = () => {
    this.setState({coverError: true});
  };

  calculateLabelLeft(width) {
    // The profile image's `left` property is set to 2% of container width (in the CSS).
    // The label must be 2% + 180px (160px for image width + 20px spacing).
    return width * 0.02 + 180;
  }

  calculateScaledOffset(offsetX, offsetY, height, width) {
    // http://stackoverflow.com/questions/10393742/how-to-compute-facebook-graph-api-cover-offset-y-to-pixel#answer-10434804
    const ratio = height / width;
    return {
      top: (preferredWidth * ratio - preferredHeight) * (offsetY / 100),
      left: (preferredHeight * ratio - preferredWidth) * (offsetX / 100)
    };
  }

  // Facebook specification for the social banner
  //   https://www.facebook.com/help/www/125379114252045
  //   https://www.facebook.com/help/266520536764594
  //   https://developers.facebook.com/docs/graph-api/reference/cover-photo/
  render() {
    const {result, config} = this.props;
    const {container, offset, scale, labelLeft, coverError, imageHeight} = this.state;
    const {bannerUrl, profileUrl, url, label} = result;

    if (!label || !url || !profileUrl) {
      return <span />;
    }

    const adjustedHeight = imageHeight
      ? Math.min(preferredHeight * scale, imageHeight)
      : preferredHeight * scale;

    // the calculated offset must be modified by the scale
    const cssOffset = _.mapValues(offset, val => {
      return '-' + val * scale + 'px';
    });

    const divStyle = bannerUrl && !coverError ? {} : {background: '#40477B'};

    return (
      <div className={cx(styles.banner)} ref="container" style={{height: adjustedHeight}}>
        <ExternalLink href={url} className={cx(styles.bannerImgLink)}>
          <img
            ref="image"
            src={bannerUrl}
            style={cssOffset}
            onLoad={this.handleCoverPhoto}
            onError={this.handleCoverError}
          />
          <div style={divStyle}>{/* cover photo linear gradient for label contrast */}</div>
        </ExternalLink>
        <div className={cx(styles.profileImgCont)}>
          <img src={profileUrl} width="160" />
        </div>
        <div className={cx(styles.labelCont)} style={{left: labelLeft + 'px'}}>
          {label}
        </div>
      </div>
    );
  }
}

export default SocialBanner;
