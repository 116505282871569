exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tdZGt08DzyFjUX7b1qVlH{text-align:center;color:#fff}.tdZGt08DzyFjUX7b1qVlH._3gi4nKLCIuD2A7yhDDOeqW{color:#df1616}.tdZGt08DzyFjUX7b1qVlH._1M0_iKB0RXU7LlXxA_J4lL{color:#eba52c}.tdZGt08DzyFjUX7b1qVlH._2SHBHcjFDdH0CyCxGWddOK{color:#ebd10c}.tdZGt08DzyFjUX7b1qVlH._3xiuxQDBz4Wx_DBRMfMD9D{color:#55d272}.tdZGt08DzyFjUX7b1qVlH.R9ofY_ybXzU2HLmIPVcXo{color:#12dbc5}.tdZGt08DzyFjUX7b1qVlH._1NFmpp2BftoFbzxFh0ckl8{opacity:.2}.tdZGt08DzyFjUX7b1qVlH._11AwXuQ7scI1eAzbxh4FJg{background:transparent;color:rgba(255,255,255,.4)}", ""]);

// exports
exports.locals = {
	"score": "tdZGt08DzyFjUX7b1qVlH",
	"red": "_3gi4nKLCIuD2A7yhDDOeqW",
	"orange": "_1M0_iKB0RXU7LlXxA_J4lL",
	"yellow": "_2SHBHcjFDdH0CyCxGWddOK",
	"green": "_3xiuxQDBz4Wx_DBRMfMD9D",
	"cyan": "R9ofY_ybXzU2HLmIPVcXo",
	"muted": "_1NFmpp2BftoFbzxFh0ckl8",
	"locked": "_11AwXuQ7scI1eAzbxh4FJg"
};