import React from 'react';
import {connect} from 'react-redux';
import {doInstruction} from 'modules/instructions';
import {DropdownBody, DropdownOption} from 'components/dropdown';

export class Options extends React.Component {
  handleClickOption = idx => () => {
    const {doInstruction, config, result} = this.props;
    doInstruction(config.instruction, result[idx]);
  };

  render() {
    const {result} = this.props;

    return (
      <DropdownBody>
        {result.map((val, idx) => (
          <DropdownOption
            key={val.libraryPolicyId}
            value={val.libraryPolicyId}
            onClick={this.handleClickOption(idx)}
          >
            {val.name}
          </DropdownOption>
        ))}
      </DropdownBody>
    );
  }
}

export default connect(
  null,
  {doInstruction}
)(Options);
