export function webitPost(key) {
  const keyParts = key.split('|');

  const form = document.createElement('form');
  form.method = 'post';
  form.action = 'http://webit.who.int/workflow/manager/story/' + keyParts[1];
  form.target = 'Bricolage_webit_who_int';

  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = 'story_prof|recall_cb';
  hiddenField.value = key;

  form.appendChild(hiddenField);
  document.body.appendChild(form);

  window.open('', 'Bricolage_webit_who_int');

  form.submit();

  document.body.removeChild(form);
}
