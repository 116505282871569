export {default} from './missionsReducer';

export const RECEIVE_MISSION = 'missions:RECEIVE_MISSION';
export function receiveMission(mission) {
  return {
    type: RECEIVE_MISSION,
    mission
  };
}

export const REMOVE_MISSION = 'missions:REMOVE_MISSION';
export function removeMission(missionId) {
  return {
    type: REMOVE_MISSION,
    missionId
  };
}

export const RECEIVE_MISSIONS = 'missions:RECEIVE_MISSIONS';
export function receiveMissions(missions) {
  return {
    type: RECEIVE_MISSIONS,
    missions
  };
}

export const CANCEL_MISSION = 'missions:CANCEL_MISSION';
export function cancelMission(missionId) {
  return {type: CANCEL_MISSION, missionId};
}

export const CLEAR_MISSIONS = 'missions:CLEAR_MISSIONS';
export function clearMissions() {
  return {type: CLEAR_MISSIONS};
}

export const DELETE_MISSION = 'missions:DELETE_MISSION';
export function deleteMission(missionId) {
  return {type: DELETE_MISSION, missionId};
}

export const TRIGGER_MISSION_LIST = 'missions:TRIGGER_MISSION_LIST';
export function triggerMissionList(reportId = null) {
  return {type: TRIGGER_MISSION_LIST, reportId};
}
