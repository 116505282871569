import React from 'react';
import cx from 'classnames';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {ReportGandalf} from 'components/gandalf';
import Feature from 'components/feature';
import Button from 'components/button';
import Buttons from 'components/buttons';
import {push} from 'modules/location';
import {reportSettingsPath} from 'modules/reports/reportUtils';
import {updateTest, triggerEditTest} from 'modules/tests';
import {reportNavButtonsSelector} from 'modules/reports/reportSelectors';
import {ROLE_CONTRIBUTOR, ROLE_VIEWER} from 'modules/gandalf/gandalfUtils';
import Dropdown from 'components/dropdown';
import ExportButton from 'modules/reports/components/exportButton';
import {shareReport} from 'modules/share';
import {trendScreenPath} from 'modules/trends';
import {showUpgradeModal} from 'modules/onboarding';
import {isMonitor} from 'modules/reports/reportUtils';
import {
  confirmDeleteReport,
  updateWatchingReport,
  triggerExportReport,
  requestRetestReport
} from 'modules/reports';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import RetestButton from 'modules/app/containers/retestButton';
import ShareButton from 'modules/app/containers/shareButton';
import {connectContext} from 'modules/app/appUtils';

export class ReportNavButtons extends React.Component {
  handleClickDropdownOption = (value, event) => {
    const {
      testId,
      report: {reportId, reportType}
    } = this.props;

    if (this.props.isVirtual && !['export', 'share'].includes(value)) {
      this.props.showUpgradeModal();
      return;
    }

    switch (value) {
      case 'settings':
        this.props.push(reportSettingsPath, {}, ['reportId'], false, {}, event);
        break;

      case 'delete':
        const {confirmDeleteReport} = this.props;
        confirmDeleteReport(reportId);
        break;

      case 'test-settings':
        this.props.triggerEditTest(testId);
        break;

      case 'mute-test':
      case 'unmute-test':
        this.props.updateTest({testId, isMuted: value == 'mute-test'});
        break;

      case 'watch-report':
      case 'unwatch-report':
        const isWatching = value == 'watch-report';
        this.props.updateWatchingReport(reportId, isWatching);
        break;

      case 'trends':
        this.props.push(trendScreenPath, {trendScreenId: testId}, ['reportId']);
        break;

      case 'report-share':
        this.props.shareReport(reportId);
        break;

      // these are used when the navbar is too small and the buttons are collapsed (see renderSmall)
      case 'share':
        this.props.shareReport(reportId);
        break;

      case 'export':
        this.props.triggerExportReport(reportId, this.props.testId);
        break;

      case 'retest':
        this.props.requestRetestReport(reportId, reportType);
        break;
    }
  };

  handleClickRetest = () => {
    const {reportId, reportType} = this.props.report;
    this.props.requestRetestReport(reportId, reportType);
  };

  handleClickVirtual = () => {
    this.props.showUpgradeModal();
  };

  renderShareButton(reportId, buttonClassName) {
    return <ShareButton reportId={reportId} className={buttonClassName} />;
  }

  renderExportButton(reportId, testId, buttonClassName) {
    if (this.props.screenSizes.tablet || !this.props.report.presentationOptions.canExportPdf) {
      return null;
    }

    return (
      <ReportGandalf shallPass={ROLE_VIEWER} reportId={reportId}>
        <ExportButton
          testId={testId}
          reportId={reportId}
          className={buttonClassName}
          // exportType={EXPORT_TYPES.PRINT}
          icon="file"
        >
          Export
        </ExportButton>
      </ReportGandalf>
    );
  }

  renderRetestButton(reportId, buttonClassName) {
    return <RetestButton reportId={reportId.toString()} className={buttonClassName} />;
  }

  renderSettingsButton(isVirtual, buttonClassName) {
    const buttonProps = {
      icon: 'cog',
      className: buttonClassName,
      children: 'Settings',
      translucent: isVirtual
    };

    if (isVirtual) {
      return <Button {...buttonProps} onClick={this.handleClickVirtual} />;
    } else {
      return (
        <Button
          {...buttonProps}
          onClick={event => this.handleClickDropdownOption('settings', event)}
        />
      );
    }

    // return (
    //   <Dropdown
    //     button={<Button {...buttonProps} />}
    //     options={this.props.dropdownOptions}
    //     onChange={this.handleClickDropdownOption}
    //   />
    // );
  }

  renderSmall(isVirtual, buttonClassName) {
    const buttonProps = {
      icon: 'caret-down',
      className: buttonClassName,
      iconOnly: true
    };

    const options = [
      {label: 'Share', value: 'share'},
      {label: 'Export', value: 'export'},
      {label: 'Retest', value: 'retest'},
      {label: 'Settings', value: 'settings'}
    ];

    return (
      <div className={this.props.wrapperClass}>
        <Dropdown
          className={cx(this.props.className)}
          button={<Button {...buttonProps} />}
          options={options.filter(option => {
            return !(option.value === 'export' && this.props.tablet);
          })}
          onChange={this.handleClickDropdownOption}
        />
      </div>
    );
  }

  render() {
    const {
      report: {reportId, reportType, reportUpgradeNeeded},
      testId,
      className,
      buttonClassName,
      isRetesting,
      isVirtual,
      noSpacing,
      archiveId
    } = this.props;

    if (!testId || !reportId) return null;

    if (reportUpgradeNeeded && reportUpgradeNeeded.isBreakingChange) {
      return null;
    }

    if (this.props.small) {
      return this.renderSmall(isVirtual, buttonClassName);
    }

    const isViewingLiveReport = !archiveId || archiveId === 'live';
    return (
      <div className={this.props.wrapperClass}>
        <Buttons className={className} noSpacing={noSpacing}>
          {this.renderShareButton(reportId, buttonClassName)}
          {isViewingLiveReport && this.renderExportButton(reportId, testId, buttonClassName)}

          {!isVirtual && this.renderRetestButton(reportId, buttonClassName)}
          {reportType !== 'dashboard' &&
            !isVirtual &&
            this.renderSettingsButton(isVirtual, buttonClassName)}
        </Buttons>
      </div>
    );
  }
}

export default compose(
  connect(
    reportNavButtonsSelector,
    {
      push,
      requestRetestReport,
      triggerEditTest,
      updateTest,
      confirmDeleteReport,
      updateWatchingReport,
      shareReport,
      showUpgradeModal,
      triggerExportReport
    }
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(ReportNavButtons);
