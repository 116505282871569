exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1e3zxHVNXKOJMTZUU6UWxR{color:#df1616;border-bottom:1px dashed #df1616}.yNH1fZGf4PyTc-tFHIgn_{color:#15b272;border-bottom:1px dashed #15b272}._3cg8NGDI2pb3_WjblLdmQu{margin-top:15px;color:#888}._3LCEZuq9K07Kxm17DmxEVz{color:#d00;font-weight:bold}", ""]);

// exports
exports.locals = {
	"typo": "_1e3zxHVNXKOJMTZUU6UWxR",
	"replacement": "yNH1fZGf4PyTc-tFHIgn_",
	"description": "_3cg8NGDI2pb3_WjblLdmQu",
	"errorMessage": "_3LCEZuq9K07Kxm17DmxEVz"
};