import React from 'react';
import InspectorMetric from './inspectorMetric';
import styles from './inspectorStyle.scss';

function InspectorCategoryInfo({category}) {
  return null; // info is no longer a thing, but it might be?

  const info = category.get('info');

  return (
    <div className={info.size % 2 === 0 ? styles.endsOnLight : undefined}>
      {info.map((data, i) => {
        return (
          <InspectorMetric
            key={data.get('label')}
            dark={i % 2 == 0}
            label={data.get('label')}
            metric={data.get('metric')}
          />
        );
      })}
    </div>
  );
}

export default InspectorCategoryInfo;
