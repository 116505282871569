import React from 'react';
import {createStructuredSelector} from 'reselect';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {triggerExportReport} from 'modules/reports';
import {reportSelector} from 'modules/reports/reportSelectors';
import Button from 'components/button';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class ExportButton extends React.Component {
  static propTypes = {
    reportId: PropTypes.any.isRequired,
    testId: PropTypes.any.isRequired,
    exportType: PropTypes.string
  };

  static defaultProps = {
    exportType: null
  };

  handleClickExport = () => {
    const {triggerExportReport, reportId, testId, exportType, report} = this.props;

    triggerExportReport(reportId, testId, exportType);

    // TODO Enable this later when we support better printing
    // if (/Monitor$/.test(report.get('sku'))) {
    //   window.print();
    // } else {
    //   triggerExportReport(reportId, testId, exportType);
    // }
  };

  render() {
    const {children, ...props} = this.props;

    return (
      <ScreenSizeContext.Consumer>
        {({tablet}) =>
          tablet ? null : (
            <Button {...props} onClick={this.handleClickExport}>
              {children || 'Export'}
            </Button>
          )
        }
      </ScreenSizeContext.Consumer>
    );
  }
}

export default connect(
  createStructuredSelector({
    report: reportSelector
  }),
  {triggerExportReport}
)(ExportButton);
