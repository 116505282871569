import React from 'react';
import {useDispatch} from 'hooks/redux';
import Flex from 'components/layout/flex';
import {TypeField} from 'modules/form/hooks/useForm';
import InstructionsField from 'modules/interactions/components/instructionsField';
import styles from './interactionForm.scss';
import {DeleteIcon} from 'components/icons';
import {showConfirmModal} from 'modules/modal';

export default function StageField({field, onDelete}) {
  const dispatch = useDispatch();
  const confirmRemoveStage = createConfirmRemoveStageField(dispatch);
  const isLocked = field.fields.locked ? field.fields.locked.getValue() : false;

  return (
    <React.Fragment>
      <StageRow>
        <div className={styles.stageNo}>{field.getKey() + 1}</div>

        <div className={styles.stageField}>
          <TypeField
            autoFocus
            field={field.fields.stage.fields.name}
            label="Stage name"
            type="text"
            required
            disabled={isLocked}
          />
        </div>

        <InstructionsField field={field.fields.instructions} isLocked={isLocked} />

        {!isLocked && (
          <DeleteIcon
            size="lg"
            onClick={() => {
              confirmRemoveStage(didConfirm => {
                didConfirm && onDelete(field);
              });
            }}
          />
        )}
      </StageRow>
      <hr />
    </React.Fragment>
  );
}

export function StageRow({children}) {
  return (
    <Flex container wrap align="flex-start" bottom={20}>
      <Flex grow={1} basis="0px" right={10}>
        {children[0]}
      </Flex>
      <Flex grow={1} basis="150px" right={30} container>
        {children[3] && (
          <Flex grow={1} basis="10px" self="flex-start" right={10} top={65}>
            {children[3]}
          </Flex>
        )}
        <Flex>{children[1]}</Flex>
      </Flex>
      <Flex grow={20}>{children[2]}</Flex>
    </Flex>
  );
}

const createConfirmRemoveStageField = dispatch => callback => {
  dispatch(
    showConfirmModal({
      title: 'Remove this stage',
      content: 'Do you want to remove this stage?',
      submitLabel: 'Yes, remove stage',
      cancelLabel: 'No, go back',
      destructiveConfirm: true,
      callback
    })
  );
};
