exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZZZTXO3K-xXuT-UVy3VmE{display:block;width:100%;height:auto}.QU9bQUUSv1-Zux6eoCIH1{margin-top:60px;margin-bottom:60px}._3Lig75QSDVnN4h9R4yIyo6{color:#888;position:absolute;top:8px;right:14px;z-index:10}._3Lig75QSDVnN4h9R4yIyo6 button{padding:0 !important}._2Aqg0z53lhqvnbtCotzBce{position:absolute;top:16px;right:15px}._2MFuTow6R1H-AoxiFK0iry{position:absolute;top:8px;right:10px;font-size:30px}._1F4svQys4mQ5g3W839QCjg{border-radius:4px;border:1px solid #dadee2;box-shadow:none}._1F4svQys4mQ5g3W839QCjg:hover{box-shadow:none}._1F4svQys4mQ5g3W839QCjg figcaption{border-top:1px solid #dadee2}", ""]);

// exports
exports.locals = {
	"screenshot": "ZZZTXO3K-xXuT-UVy3VmE",
	"spinner": "QU9bQUUSv1-Zux6eoCIH1",
	"dropdown": "_3Lig75QSDVnN4h9R4yIyo6",
	"date": "_2Aqg0z53lhqvnbtCotzBce",
	"delete": "_2MFuTow6R1H-AoxiFK0iry",
	"reportCard": "_1F4svQys4mQ5g3W839QCjg"
};