import React from 'react';
import ConfigDropdown from 'components/dropdown/configDropdown';
import {connect} from 'react-redux';
import {requestAddFolder, requestDeleteFolder, editFolder} from 'modules/folders';

const options = [
  {label: 'Rename', value: 'edit'},
  {label: 'Delete', value: 'delete'},
  {label: 'Add sub folder', value: 'addFolder'}
];

export class FolderConfigDropdown extends React.Component {
  handleEdit = () => {
    this.props.editFolder(this.props.folderId);
  };

  handleDelete = () => {
    this.props.requestDeleteFolder(this.props.folderId);
  };

  handleAddFolder = () => {
    this.props.requestAddFolder(this.props.folderId);
  };

  handleChange = (value, e) => {
    e.preventDefault();
    e.stopPropagation();

    switch (value) {
      case 'edit':
        return this.handleEdit();

      case 'delete':
        return this.handleDelete();

      case 'addFolder':
        return this.handleAddFolder();
    }
  };

  getOptions() {
    if (this.props.hideDelete) {
      return options.filter(option => option.value !== 'delete');
    }
    return options;
  }

  render() {
    return <ConfigDropdown light options={this.getOptions()} onChange={this.handleChange} />;
  }
}

export default connect(
  null,
  {requestAddFolder, requestDeleteFolder, editFolder}
)(FolderConfigDropdown);
