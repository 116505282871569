import React from 'react';
import {useSelector} from 'hooks/redux';
import {useForm, Form} from 'modules/form/hooks/useForm';
import {makeNewWebpageInteraction} from 'modules/interactions/utils';
import {interactionFormSelector} from 'modules/interactions/selectors';
import StagesField from 'modules/interactions/components/stagesField';
import WebpageInteractionField from 'modules/interactions/components/webpageInteractionField';
import SavePanel from 'modules/form/components/savePanel';

function InteractionForm({interaction, onSubmit, onCancel}) {
  const {isSaving, openInstruction} = useSelector(interactionFormSelector);

  const initialValue = interaction
    ? interaction.toJS()
    : makeNewWebpageInteraction(openInstruction);

  const [form, fields] = useForm(initialValue);
  // const [form, fields] = useForm(initialValue, {key: initialValue.interactionId});

  return (
    <Form form={form} onSubmit={onSubmit}>
      <WebpageInteractionField
        field={fields.interaction}
        showUrlField={form.getField(['stages', 0, 'instructions', 0, 'options', 'url'])}
      />
      <StagesField field={fields.stages} />

      <SavePanel
        floating
        isLoading={isSaving}
        submitLabel="Save"
        floatMargin={'100px'}
        onCancel={onCancel}
        isValid={!form.hasError()}
        show
      />
    </Form>
  );
}

export default InteractionForm;
