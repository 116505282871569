import {
  REQUEST_SCOPES,
  REQUEST_SCOPES_COMPLETE,
  SET_SCOPES,
  REQUEST_CHECKS,
  REQUEST_CHECKS_COMPLETE,
  SET_CHECKS,
  REQUEST_POLICY,
  REQUEST_POLICY_COMPLETE,
  SET_POLICY,
  SAVE_POLICY,
  SAVE_POLICY_COMPLETE,
  REQUEST_DELETE_POLICY,
  REQUEST_LIBRARY_POLICIES,
  REQUEST_LIBRARY_POLICIES_COMPLETE,
  SET_LIBRARY_POLICIES,
  REQUEST_LIBRARY_POLICY,
  REQUEST_LIBRARY_POLICY_COMPLETE,
  SET_LIBRARY_POLICY,
  SAVE_LIBRARY_POLICY,
  SAVE_LIBRARY_POLICY_COMPLETE
} from './constants';

export function requestScopes() {
  return {type: REQUEST_SCOPES};
}
export function requestScopesComplete() {
  return {type: REQUEST_SCOPES_COMPLETE};
}
export function setScopes(scopes) {
  return {type: SET_SCOPES, scopes};
}

export function requestChecks(scope) {
  return {type: REQUEST_CHECKS, scope};
}
export function requestChecksComplete() {
  return {type: REQUEST_CHECKS_COMPLETE};
}
export function setChecks(scope, checks) {
  return {type: SET_CHECKS, scope, checks};
}

export function requestPolicy(policyId) {
  return {type: REQUEST_POLICY, policyId};
}
export function requestPolicyComplete() {
  return {type: REQUEST_POLICY_COMPLETE};
}
export function setPolicy(policy) {
  return {type: SET_POLICY, policy};
}
export function savePolicy(policy, redirectToPolicy = false) {
  return {type: SAVE_POLICY, policy, redirectToPolicy};
}
export function savePolicyComplete(policy) {
  return {type: SAVE_POLICY_COMPLETE, policy};
}
export function requestDeletePolicy(policyId) {
  return {type: REQUEST_DELETE_POLICY, policyId};
}

export function requestLibraryPolicies() {
  return {type: REQUEST_LIBRARY_POLICIES};
}
export function requestLibraryPoliciesComplete() {
  return {type: REQUEST_LIBRARY_POLICIES_COMPLETE};
}
export function setLibraryPolicies(libraryPolicies) {
  return {type: SET_LIBRARY_POLICIES, libraryPolicies};
}

export function requestLibraryPolicy(libraryPolicyId) {
  return {type: REQUEST_LIBRARY_POLICY, libraryPolicyId};
}
export function requestLibraryPolicyComplete() {
  return {type: REQUEST_LIBRARY_POLICY_COMPLETE};
}
export function setLibraryPolicy(libraryPolicy) {
  return {type: SET_LIBRARY_POLICY, libraryPolicy};
}
export function saveLibraryPolicy(
  libraryPolicyId,
  policy,
  options,
  redirectToPolicy = false,
  complete
) {
  return {type: SAVE_LIBRARY_POLICY, libraryPolicyId, policy, options, redirectToPolicy, complete};
}
export function saveLibraryPolicyComplete(policy) {
  return {type: SAVE_LIBRARY_POLICY_COMPLETE, policy};
}
