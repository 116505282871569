import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {languageSelectorSelector} from 'modules/languages/languageSelectors';
import {fetchLanguagesIfNecessary} from 'modules/languages';
import Select from 'components/select';

// This is a proxy to selectInput that sets options
// to a list of languages pulled from the API
export class LanguagesInput extends React.Component {
  componentDidMount() {
    this.props.fetchLanguagesIfNecessary();
  }

  getOptions() {
    return this.props.languages.map(language => {
      return {
        label: language.label,
        value: language.code
      };
    });
  }

  handleChangeEvent = option => {
    if (Array.isArray(option)) {
      let newValues = option.map(item => {
        return item.value;
      });

      this.props.onChange(newValues);
    } else {
      this.props.onChange(option.value);
    }
  };

  render() {
    const {onChange, ...props} = this.props;

    return (
      <Select
        multi
        clearable
        {...props}
        onChange={this.handleChangeEvent}
        options={this.getOptions()}
      />
    );
  }
}

export default connect(
  languageSelectorSelector,
  {fetchLanguagesIfNecessary}
)(LanguagesInput);
