import React from 'react';
import {connect} from 'react-redux';
import Image from 'components/layoutComponents/renderers/image';
import Icon from 'components/font-awesome';
import styles from './media.scss';
import cx from 'classnames';
import ColorContrast from 'components/layoutComponents/renderers/colorContrast';
import UrlComponent from 'components/url/url';
import ImageModal from 'components/modal/imageModal';
import {showInHugeModal} from 'modules/modal';

class Media extends React.Component {
  renderAudio = () => {
    return <audio src={this.props.src} controls width="180" />;
  };

  renderVideo = () => {
    return <video controls src={this.props.src} width="180" />;
  };

  handleClickImage = () => {
    this.props.showInHugeModal(ImageModal, {src: this.props.src});
  };

  renderImage = () => {
    return this.props.src ? (
      <Image data={this.props.src} onClick={this.handleClickImage} />
    ) : (
      <p>No image</p>
    );
  };

  renderUrl = () => {
    return this.props.src ? (
      <UrlComponent url={this.props.src} stopPropagation noIcon link />
    ) : (
      <p>No URL</p>
    );
  };

  renderIcon = () => {
    return (
      <div className={styles.center}>
        <Icon
          name={this.props.src}
          className={cx(styles.iconMedia, {
            [styles.greenColor]: this.props.positive,
            [styles.redColor]: this.props.negative,
            [styles.small]: this.props.small
          })}
        />
      </div>
    );
  };

  renderColorContrast = () => {
    try {
      const data = JSON.parse(this.props.src);
      return <ColorContrast data={data} />;
    } catch (e) {
      window.Rollbar &&
        window.Rollbar.error &&
        window.Rollbar.error('Failed when parsing JSON for Media compnoent', this.props);

      return null;
    }
  };

  render() {
    const {src, srcType} = this.props;

    switch (srcType) {
      case 'image':
      case 'page':
        return this.renderImage();
      case 'video':
        return this.renderVideo();
      case 'audio':
        return this.renderAudio();
      case 'icon':
        return this.renderIcon();
      case 'url':
        return this.renderUrl();
      case 'colorContrast':
        return this.renderColorContrast();
      default:
        console.warn(`srcType of ${srcType} is not yet supported in the media component.`);
        return null;
    }
  }
}

export default connect(
  null,
  {showInHugeModal}
)(Media);
