import {put} from 'redux-saga/effects';
import {deleteTagComplete} from 'modules/tags/actions';
import {tagEndpoint} from 'modules/tags/utils';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {VIEW_DELETE_TAG} from 'modules/tags/constants';

export default generateDeleteSaga('tag', VIEW_DELETE_TAG, tagEndpoint, {
  success: function*({tagId}) {
    yield put(deleteTagComplete(tagId));
  }
});
