import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import Select from 'components/select';

const suggestions = [
  ['Tomorrow', 'Yesterday', [1, 'd']],
  ['Next week', 'Last Week', [7, 'd']],
  ['Next month', 'Last Month', [1, 'M']]
];

function getMomentsForSuggestions(future = false) {
  const now = moment();
  const method = future ? 'add' : 'subtract';

  return suggestions.map(([futureName, pastName, args]) => {
    return {
      label: future ? futureName : pastName,
      value: now[method](...args).format('YYYY-M-D')
    };
  });
}

export default function Suggestions({future, onClick, selectedValue}) {
  const allSuggestions = [
    {
      label: 'Forever',
      value: 0
    },
    ...getMomentsForSuggestions(future),
    {
      label: 'Custom',
      value: 'custom'
    }
  ];

  return (
    <Select
      clearable={false}
      searchable={false}
      options={allSuggestions}
      value={selectedValue}
      onChange={select => {
        onClick(select.value);
      }}
    />
  );
}
