import React from 'react';
import styles from './flex.scss';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// <Flex container column element="span" />
//   <Flex grow={1}> // sets flex: 1
//   <Flex grow={2} shrink={1} /> // sets flex-grow: 2, flex-shrink: 1
//   <Flex grow={2} shrink={1} basis="300px" /> // sets flex-grow: 2, flex-shrink: 1, flex-basis: 300px
// </Flex>

export class Flex extends React.Component {
  static defaultProps = {
    element: 'div'
  };

  getStyles() {
    const {
      grow,
      shrink,
      basis,
      style,
      align,
      self,
      justify,
      wrap,
      right,
      left,
      top,
      bottom,
      margin
    } = this.props;

    const styles = {...style};

    if (grow !== undefined) styles.flexGrow = grow;
    if (wrap !== undefined) styles.flexWrap = wrap;
    if (self !== undefined) styles.alignSelf = self;
    if (align !== undefined) styles.alignItems = align;
    if (justify !== undefined) styles.justifyContent = justify;

    // We set defaults for shrink and basis thanks to the slightly quirky behaviour of flex vs flex-grow:
    // https://stackoverflow.com/questions/35395691/understanding-the-difference-between-the-flex-and-flex-grow-properties
    const flexBasis = basis !== undefined ? basis : grow ? 0 : undefined;
    const flexShrink = shrink !== undefined ? shrink : grow ? 1 : undefined;

    if (flexBasis !== undefined) styles.flexBasis = flexBasis;
    if (flexShrink !== undefined) styles.flexShrink = flexShrink;

    if (margin !== undefined) styles.margin = margin;

    if (right === true) styles.marginLeft = 'auto';
    else if (right !== undefined) styles.marginRight = right;

    if (left === true) styles.marginRight = 'auto';
    else if (left !== undefined) styles.marginLeft = left;

    if (top !== undefined) styles.marginTop = top;
    if (bottom !== undefined) styles.marginBottom = bottom;

    return styles;
  }

  render() {
    // NOTE pull out wrap because it is a real HTML attr.
    // Otherwise warning: Received `true` for a non-boolean attribute `wrap`.
    const {element, children, wrap, ...props} = this.props;

    return React.createElement(
      element,
      {
        ...filterInvalidDOMProps(props),
        style: this.getStyles()
      },
      children
    );
  }
}

export default localStyles(styles)(Flex);
