import _ from 'lodash';
import {delay} from 'redux-saga';
import {parse} from 'query-string';
import {push} from 'modules/location';
import {ready} from 'modules/app';
import {select, put, call, take} from 'redux-saga/effects';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {getAuthToken, getAccountId, clearAuth} from 'utils/authenticationUtils';
import {lastRequestedPageSelector} from 'modules/location/locationSelectors';
import {
  LOGIN,
  LOGIN_FAILED,
  loadToken,
  loadSelf,
  storeAccount,
  setAccountIdInPath
} from 'modules/auth';

// Tries to reauthenticate on first load
export default function* reauthenticate() {
  // we delay here, as we need the requestSelf saga to have
  // started before we dispatch the loadSelf action
  yield delay(200);

  const token = yield getToken();

  if (token) {
    // Store local storage auth token in store.
    yield put(loadToken(token));

    if (window.location && window.location.pathname && window.location.pathname.length >= 2) {
      yield put(setAccountIdInPath(window.location));
    }

    let accountId = yield select(accountIdSelector);

    if (!accountId) {
      accountId = yield call(getAccountId);

      // If we have a stored accountId, add it to the state
      if (accountId) {
        yield put(storeAccount(accountId));
      }
    }

    // Trigger API request to get the user specified by the token.
    yield put(loadSelf());

    // Wait for LOGIN/LOGIN_FAILED (success/fail actions)
    yield take([LOGIN, LOGIN_FAILED]);
  }

  yield put(ready());
}

function* getToken() {
  const queryStringToken = parse(window.location.search || '').token;

  // always use url token over user token
  if (queryStringToken) {
    return queryStringToken;
  }

  // If we have an encoding issue when trying to get the token, clear the auth instead of dying.
  try {
    return yield call(getAuthToken);
  } catch (e) {
    yield call(clearAuth);
  }

  return null;
}
