import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import {ModalForm, Field} from 'modules/form/components';
import {addFilter} from 'modules/filters';
import {connect} from 'react-redux';

// I don't *think* this is being used anymore *however* because it is being used
// by sagas it is almost impossible to tell without tracing through lots of
// code which man doesn't have time for right now.
export class FilterModal extends React.Component {
  handleValidSubmit = data => {
    const {addFilter, hideModal, filter} = this.props;
    const {name} = filter;

    addFilter({
      [name]: data.filter
    });

    hideModal();
  };

  render() {
    const {filter, title, content, name} = this.props;

    return (
      <ModalForm
        title={title}
        submitLabel="Save"
        onValidSubmit={this.handleValidSubmit}
        beforeForm={<p>{content}</p>}
      >
        <Field label={filter.label} name="filter" placeholder="Filter by" type="text" autoFocus />
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {addFilter}
)(FilterModal);
