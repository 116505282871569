import _ from 'lodash';
import React from 'react';
import filterInvalidDomProps from 'filter-invalid-dom-props';
import {formatFields} from 'modules/form/formUtils';
import Field from './field';
import {fromJS, Map} from 'immutable';
import {havePropsChanged} from 'utils/propUtils';
import cx from 'classnames';
import styles from './field.scss';

export default class Form extends React.Component {
  static defaultProps = {
    defaultValue: {},
    disableValidSubmit: false,
    onValidSubmit: data => null,
    onSubmit: (data, isValid) => null,
    onChange: (fieldName, fieldData, formData, isValid) => null
  };

  // we need a reference to each Field component,
  // as each field validates itself
  fields = {};
  originalOnChange = {};

  state = {
    formData: this.props.defaultValue
  };

  componentDidMount() {
    // get a real deep clone. Object.assign and spreads are only shallow
    this.initialValue = fromJS(this.props.defaultValue).toJS();

    this.updateFields(this.props, true);
  }

  componentWillReceiveProps(nextProps) {
    if (havePropsChanged(this.props, nextProps, ['defaultValue'])) {
      // get a real deep clone. Object.assign and spreads are only shallow
      this.initialValue = fromJS(nextProps.defaultValue).toJS();

      this.updateFields(nextProps);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  updateFields(props, isInit = false) {
    this.timeout = setTimeout(() => {
      const {defaultValue} = props;

      for (var name in defaultValue) {
        this.updateField(name, defaultValue, isInit);
      }
    }, 0);
  }

  get() {
    return this.state.formData;
  }

  set(name, value) {
    this.handleChange(name)(value);
  }

  setForm = ref => {
    this.form = ref;
  };

  setField = name => ref => {
    this.fields[name] = ref;
  };

  isFormValid(updateFieldUI = false) {
    var isFormValid = true;
    var messages = {};
    Object.keys(this.fields).forEach(name => {
      // if the field has been temporarily hidden
      if (!this.fields[name]) return true; // continue;

      let [isFieldValid, message] = this.fields[name].validate(updateFieldUI);

      if (message) messages[name] = message;

      isFormValid = isFormValid && isFieldValid;
    });

    if (isFormValid && this.props.customValidation) {
      // run the custom validators
      const {isValid, message, target} = this.props.customValidation(this.state.formData);

      if (message) messages[name] = message;

      isFormValid = isValid;

      if (target && this.fields[target]) {
        this.fields[target].setMessage({message, isValid});
      }
    }
    return [isFormValid, messages];
  }

  updateField(name, data, isInit) {
    const fieldOnChange = this.originalOnChange[name];

    // TODO this is a rather expensive call to be making for every single form update
    // consider fixing or moving to a better form system
    const [isFormValid, messages] = this.isFormValid();

    fieldOnChange && fieldOnChange(data[name], isInit);
    this.props.onChange(
      name,
      data[name],
      data,
      isFormValid,
      !_.isEqual(this.initialValue, data),
      messages
    );

    // Use the prevSTate => return instead. This makes the state maintain concurrency even whilst
    // the async nature of setState occurs. This means if we update multiple fields at the same time
    // they will not overwrite one another.
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [name]: data[name]
        }
      };
    });
  }

  handleChange = name => value => {
    const data = {
      ...this.state.formData,
      [name]: value
    };

    this.setState({formData: data}, () => {
      this.updateField(name, data);
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.submit(event);
  };

  handleReset = () => {
    for (var name in this.initialValue) {
      this.updateField(name, this.initialValue);
    }
  };

  submit(event) {
    const {onSubmit, onValidSubmit, disableValidSubmit} = this.props;

    const [isFormValid, messages] = this.isFormValid(true);
    const data = formatFields(this.state.formData, this.fields);

    onSubmit && onSubmit(data, isFormValid, event);

    // we can disable the valid submit handler and call it ourselves later
    // if we ever need to do extra validation processing
    if (isFormValid && !disableValidSubmit) {
      onValidSubmit && onValidSubmit(data);
    }
  }

  renderDescendant = descendant => {
    if (!descendant || !descendant.type) {
      return descendant;
    }

    if (descendant.type === Field) {
      const {name, showIf} = descendant.props;

      this.originalOnChange[name] = descendant.props.onChange;

      const shouldShow = typeof showIf != 'function' || showIf(this.state.formData);

      const val = this.state.formData[name];
      const value = val === null ? undefined : val;

      // Fields don't have any children, so we don't need to render them.
      return React.cloneElement(descendant, {
        value,
        ref: this.setField(name),
        onChange: this.handleChange(name),
        hide: !shouldShow,
        inline: !!this.props.inline
      });
    }

    // any children could contain a Field component
    // if descendant has children, return a clone of it with modified children
    const {children} = descendant.props;

    if (children) {
      return React.cloneElement(descendant, {}, this.renderDescendants(children));
    }

    return descendant;
  };

  renderDescendants(descendants) {
    const children = React.Children.map(descendants, this.renderDescendant);

    // Some third party descendants may render their children using `React.Children.only`.
    // If we return an array with a single element, then `React.Children.only` will fail.
    // Therefor if we have an array with a single element, we should remove it from the array.
    if (!children.length) {
      return children;
    }
    return children.length == 1 ? children[0] : children;
  }

  render() {
    const {children, inline, className, boldLabels, ...props} = this.props;

    return (
      <form
        {...filterInvalidDomProps(props)}
        ref={this.setForm}
        className={cx({[styles.inline]: inline, [styles.boldLabels]: boldLabels}, className)}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onReset={this.handleReset}
        autoComplete="off"
        noValidate // disable html5 validation messages
      >
        {this.renderDescendants(children)}
      </form>
    );
  }
}
