import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Spinner from 'components/spinner';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import EditLeaderboardForm from '../containers/editLeaderboardForm';
import LeaderboardBreadcrumb from '../containers/leaderboardBreadcrumb';
import {leaderboardSelector} from 'modules/leaderboards/selectors';
import MaxWidth from 'components/maxWidth';

class EditLeaderboardPage extends React.Component {
  render() {
    const {leaderboardId, leaderboard} = this.props;
    if (leaderboardId && !leaderboard) {
      return <Spinner marginTop={100} />;
    }

    return (
      // <PageHeader title={(leaderboardId ? 'Edit' : 'New') + ' leaderboard'} />
      <LeaderboardBreadcrumb
        leaderboard={leaderboard}
        type={leaderboardId ? 'editting' : 'creating'}
      >
        <MaxWidth>
          <PageBody>
            <EditLeaderboardForm
              leaderboardId={leaderboardId}
              dashboardId={leaderboard ? leaderboard.get('dashboardId') : ''}
            />
          </PageBody>
        </MaxWidth>
      </LeaderboardBreadcrumb>
    );
  }
}

export default connect(
  createStructuredSelector({
    leaderboard: leaderboardSelector
  })
)(EditLeaderboardPage);
