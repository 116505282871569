import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'components/button';
import {shareReport} from 'modules/share';
import {ReportGandalf} from 'components/gandalf';
import {ROLE_VIEWER} from 'modules/gandalf/gandalfUtils';

function ShareButton({onClick, reportId, className, children, ...props}) {
  return (
    <ReportGandalf shallPass={ROLE_VIEWER} reportId={reportId}>
      <Button onClick={() => onClick(reportId)} className={className} icon="fa fa-share-alt">
        {children || 'Share'}
      </Button>
    </ReportGandalf>
  );
}

ShareButton.propTypes = {
  reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default connect(
  null,
  dispatch => ({
    onClick: reportId => {
      dispatch(shareReport(reportId));
    }
  })
)(ShareButton);
