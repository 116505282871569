import {createSelector} from 'reselect';
import {loggedInSelector} from 'modules/auth/authSelectors';

export const confirmCheckoutSelector = state => state.confirmCheckout;

export const confirmCheckoutPageSelector = createSelector(
  loggedInSelector,
  confirmCheckoutSelector,
  (isLoggedIn, state) => {
    return {
      isLoggedIn,
      checkoutResponse: state.get('data').toJS(),
      isLoading: state.get('isLoading'),
      loadingError: state.get('loadingError')
    }
  }
)