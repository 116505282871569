import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {canAddUptimeMonitorsEndpoint} from '../utils';
import {receiveCanAddUptimeMonitor} from '../actions';
import {REQUEST_CAN_ADD_UPTIME_MONITOR} from '../constants';

export default function* requestCanAddUptimeMonitorSaga() {
  yield takeLatest(REQUEST_CAN_ADD_UPTIME_MONITOR, requestCanAddUptimeMonitor);
}

function* requestCanAddUptimeMonitor(action) {
  yield processRequest('GET', canAddUptimeMonitorsEndpoint(action), {
    success: function*({canAdd = false} = {}) {
      yield put(receiveCanAddUptimeMonitor(canAdd));
    }
  });
}
