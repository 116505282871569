import React from 'react';
import {connect} from 'react-redux';
import BreadcrumbBar from 'modules/breadcrumb/components/breadcrumbBar';
import BreadcrumbLink from 'modules/breadcrumb/components/breadcrumbLink';
import {searchCampaignPath, searchCampaignsPath} from '../utils';
import {searchCampaignsBreadcrumbSelector} from '../selectors';
import {push} from 'modules/location';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import SecondaryNav from 'modules/app/components/secondaryNavigation/secondaryNavigation';
import KeywordsBalance from 'modules/balances/containers/keywordsBalance';
import Flex from 'components/layout/flex';

function SearchCampaignsBreadcrumb({
  children,
  report,
  searchCampaign,
  searchCampaigns,
  searchCampaignOptions,
  viewSearchCampaigns,
  viewSearchCampaign,
  localKeywordsUsed = 0
}) {
  const sku = report.get('sku');
  const serviceId = report.get('serviceId');

  let keywordsUsed = searchCampaigns.reduce((count, theme) => {
    // if we are editting a searchCampaign, we want the count of the live form changes which get passed in
    // and are associated with the `searchCampaign`
    if (searchCampaign && searchCampaign.get('campaignId') === theme.get('campaignId')) {
      return count + localKeywordsUsed;
    }
    return count + theme.get('keywords').size * theme.get('engines').size;
  }, 0);

  // if no searchCampaign is passed, we could be adding a new one, so we can just append the count
  if (!searchCampaign) {
    keywordsUsed += localKeywordsUsed;
  }

  return (
    <PageWithFixed
      orientation="vertical"
      fixedElement={
        <SecondaryNav match={{params: {}}}>
          <Flex>
            <BreadcrumbBar>
              <BreadcrumbLink onClick={viewSearchCampaigns} showBreakline>
                Search campaigns
              </BreadcrumbLink>
              {searchCampaign && (
                <BreadcrumbLink
                  value={searchCampaign.get('campaignId')}
                  options={searchCampaignOptions.toJS()}
                  onClick={viewSearchCampaign}
                  showBreakline
                />
              )}
            </BreadcrumbBar>
          </Flex>
          <Flex right margin="10px">
            <KeywordsBalance
              reportId={report.get('reportId')}
              keywordsUsed={keywordsUsed}
              serviceId={serviceId}
              sku={sku}
            />
          </Flex>
        </SecondaryNav>
      }
    >
      {children}
    </PageWithFixed>
  );
}

export default connect(
  searchCampaignsBreadcrumbSelector,
  dispatch => ({
    viewSearchCampaign(campaignId) {
      dispatch(push(searchCampaignPath, {campaignId}, ['reportId']));
    },
    viewSearchCampaigns() {
      dispatch(push(searchCampaignsPath, {}, ['reportId']));
    }
  })
)(SearchCampaignsBreadcrumb);
