exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3p1DYHGdPplfph2wwlf-g2{position:relative;overflow:hidden}", ""]);

// exports
exports.locals = {
	"column": "_3p1DYHGdPplfph2wwlf-g2"
};