import React from 'react';
import styles from './context.scss';
import Button from 'components/button';

export default function ContextNavigation({
  children,
  pageNumber,
  numPages,
  onOpen,
  onNavigateRelative
}) {
  return (
    <div className={styles.navigation}>
      <Button
        dark
        iconOnly
        icon="chevron-left"
        onClick={() => {
          onNavigateRelative('prev');
        }}
      />
      <div className={styles.navLabel} onClick={onOpen}>
        Page {pageNumber + 1} of {numPages}
      </div>
      <Button
        dark
        iconOnly
        icon="chevron-right"
        onClick={() => {
          onNavigateRelative('next');
        }}
      />

      {children}
    </div>
  );
}
