import {accountSelector, loggedInSelector, userSelector} from 'modules/auth/authSelectors';
import ToolSelectionPage from 'modules/auth/pages/toolSelectionPage';
import ForgottenPasswordRoute from 'modules/auth/routes/forgottenPasswordRoute';
import LoginRoute from 'modules/auth/routes/loginRoute';
import LoginTwoFactorRoute from 'modules/auth/routes/loginTwoFactorRoute';
import ResetPasswordRoute from 'modules/auth/routes/resetPasswordRoute';
import UnlockUserRoute from 'modules/auth/routes/unlockUserRoute';
import ConfirmCheckoutPage from 'modules/confirmCheckout/pages/confirmCheckoutPage';
import {
  replace,
  setLastRequestedPage,
  setLocationParamItem,
  setLocationQueryParams
} from 'modules/location';
import {lastRequestedPageSelector} from 'modules/location/locationSelectors';
import SignupRoute from 'modules/onboarding/routes/onboardingRoute';
import QuoteViewPage from 'modules/quote/pages/quoteViewPage';
import {reportSelector} from 'modules/reports/reportSelectors';
import PricingCalculatorPage from 'modules/superProduct/pages/pricingCalculatorPage';
import ScreenSizeProvider from 'providers/screenSizeProvider';
import {parse} from 'query-string';
import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {createSelector} from 'reselect';
import {triggerPageViewEvent} from '../appUtils';
import RootLayout from '../pages/rootLayout';
import LoggedInRoute from './loggedInRoute';

export class RootRoute extends React.Component {
  componentDidMount() {
    const {
      location: {search, pathname},
      match,
      setLocationQueryParams,
      setLocationParamItem
    } = this.props;

    setLocationQueryParams(parse(search));

    if (match.params && match.params.accountId) {
      setLocationParamItem('accountId', match.params.accountId);
    }

    if (window.gtag) {
      triggerPageViewEvent(this.props.location, this.props.account, this.props.report);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      loggedIn,
      location: {search, pathname}
    } = this.props;
    const query = parse(search);

    // only set the lastRequestedUrl if we are not loggedIn
    if (!loggedIn && !query.token) {
      this.props.setLastRequestedPage(pathname + search);
    }

    if (this.props.location !== nextProps.location) {
      this.props.setLocationQueryParams(parse(nextProps.location.search));

      if (nextProps.match.params) {
        this.props.setLocationParamItem('accountId', nextProps.match.params.accountId);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const thisLocation = this.props.location;
    const nextLocation = nextProps.location;

    return (
      thisLocation.pathname != nextLocation.pathname ||
      thisLocation.search != nextLocation.search ||
      this.props.user != nextProps.user ||
      this.props.match.url != nextProps.match.url
    );
  }

  render() {
    const {
      loggedIn,
      location: {search}
    } = this.props;
    const query = parse(search);

    const redirectToWebsite = () => {
      window.location.href = 'https://silktide.com';
      return null;
    };

    return (
      <ScreenSizeProvider>
        <div>
          <Switch>
            <Route exact path="/login" component={LoginRoute} />
            <Route exact path="/login/two-factor-auth" component={LoginTwoFactorRoute} />
            <Route exact path="/signup" component={SignupRoute} />
            <Route exact path="/forgottenpassword" component={ForgottenPasswordRoute} />

            {/* Redirects for old routes still indexed by Google */}
            <Route path="/products/buy" component={redirectToWebsite} />
            <Route path="/products/buy/:productId" component={redirectToWebsite} />
            <Route path="/buy" component={redirectToWebsite} />
            <Route path="/buy/:productId" component={redirectToWebsite} />

            <Route path="/ecommerce-pricing" component={PricingCalculatorPage} />
            <Route path="/quotes/:quoteId/:quoteKey" component={QuoteViewPage} />
            <Route path="/confirm-checkout/:checkoutRequestId" component={ConfirmCheckoutPage} />
            <Route
              exact
              path="/:accountId/resetPassword/:userId/:token"
              component={ResetPasswordRoute}
            />
            <Route exact path="/:accountId/unlockUser/:userId/:token" component={UnlockUserRoute} />
            {(loggedIn || query.token) && (
              <Route exact path={`/:accountId/tool-select`} component={ToolSelectionPage} />
            )}
            {(loggedIn || query.token) && <Route path="/:accountId?" component={LoggedInRoute} />}
            {!loggedIn && <Redirect from="*" to="/login" />}
          </Switch>

          <RootLayout />
        </div>
      </ScreenSizeProvider>
    );
  }
}

export default connect(
  createSelector(
    loggedInSelector,
    userSelector,
    accountSelector,
    reportSelector,
    lastRequestedPageSelector,
    (isLoggedIn, user, account, report, lastRequestedUrl) => {
      return {loggedIn: isLoggedIn, user, account, report, lastRequestedUrl};
    }
  ),
  {replace, setLocationQueryParams, setLocationParamItem, setLastRequestedPage}
)(RootRoute);
