import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {hideModal} from 'modules/modal';
import {ModalForm, Field} from 'modules/form/components';
import {saveFolder} from 'modules/folders';

export class FolderModal extends React.Component {
  static defaultProps = {
    folder: {}
  };

  handleValidSubmit = data => {
    const {folder, saveFolder, hideModal} = this.props;

    saveFolder({...folder, ...data});
    hideModal();
  };

  getTitle() {
    const {folder, parentFolder} = this.props;
    const isEditing = !!folder.folderId;

    if (isEditing) {
      return 'Edit folder';
    }

    return !parentFolder ? 'Add folder' : `New child folder under "${parentFolder.name}"`;
  }

  render() {
    const {name, folder} = this.props;

    return (
      <ModalForm
        name={name}
        submitLabel="Save"
        defaultValue={folder}
        title={this.getTitle()}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field name="name" type="text" placeholder="Folder name" required autoFocus />
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {hideModal, saveFolder}
)(FolderModal);
