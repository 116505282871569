import React from 'react';
import filterInvalidDomProps from 'filter-invalid-dom-props';

export default class UL extends React.Component {
  render() {
    const {children, ...props} = this.props;

    return (
      <ul {...filterInvalidDomProps(props)}>
        {React.Children.map(children, (child, idx) => (
          <li key={idx}>{child}</li>
        ))}
      </ul>
    );
  }
}
