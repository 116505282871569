import React from 'react';
import {useForm, Form, TypeField} from 'modules/form/hooks/useForm';
import ModalContent from 'modules/modal/components/modalContent';
import {useSelector, useDispatch} from 'hooks/redux';
import {digestsSelector} from '../selectors';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Margin from 'components/margin/margin';
import {
  addUnsavedDigestSubscription,
  editDigestSubscription,
  requestDigestPreview
} from '../actions';
import {hideModal, showInModal} from 'modules/modal';
import SchemaFields, {SchemaField} from 'modules/form/components/schemaField';
import {makeDefaultData} from 'modules/policies/policyUtils';

const FORM_INDENT = '80px';
const SCHEDULE_OPTIONS = [{value: 'weekly', label: 'Weekly'}, {value: 'monthly', label: 'Monthly'}];

function DigestSubscriptionModal({reportId, userId, formState, digestSubscription}) {
  const dispatch = useDispatch();
  const isEditing = !!digestSubscription;
  const digests = useSelector(digestsSelector);
  const initialDigestId = digests.first().get('digestId');
  const [form, fields] = useForm(
    formState ||
      digestSubscription || {
        digestId: initialDigestId,
        schedulePreset: 'weekly',
        options: makeDefaultData(digests.getIn([initialDigestId, 'supportedOptions']).toJS(), {})
      }
  );
  const handleClickPreview = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      requestDigestPreview(
        reportId,
        fields.digestId.getValue(),
        fields.schedulePreset.getValue(),
        fields.options.getValue(),
        () => {
          dispatch(
            showInModal(DigestSubscriptionModal, {
              reportId,
              userId,
              formState: form.getValue(),
              digestSubscription
            })
          );
        }
      )
    );
  };
  const digestOptions = digests
    .toList()
    .map(digest => ({
      value: digest.get('digestId'),
      label: digest.get('name')
    }))
    .toJS();

  const handleClickSave = () => {
    const digestSubscriptionData = {
      ...form.getFormattedValue(),
      segmentId: 0
    };

    dispatch(
      isEditing
        ? editDigestSubscription(userId, digestSubscription, digestSubscriptionData)
        : addUnsavedDigestSubscription(userId, digestSubscriptionData)
    );
    dispatch(hideModal());
  };
  const renderCustomField = (subfield, schema) => {
    return (
      <IndentedField
        indent={FORM_INDENT}
        label={
          schema.has('label') ? <Margin marginTop="6px">{schema.get('label')}</Margin> : undefined
        }
      >
        <SchemaField field={subfield} schema={schema.remove('label')} />
      </IndentedField>
    );
  };

  const supportedOptions = digests.get(fields.digestId.getValue()).get('supportedOptions');

  return (
    <ModalContent
      title={
        isEditing
          ? `Edit ${digests
              .getIn([digestSubscription.digestId.toString(), 'name'])
              .toLowerCase()} digest subscription`
          : 'Subscribe to digest'
      }
      buttons={[isEditing ? 'Edit' : 'Add']}
      onClickButton={handleClickSave}
    >
      <Form form={form}>
        {!isEditing && (
          <IndentedField label={<Margin marginTop="6px">Digest</Margin>} indent={FORM_INDENT}>
            <TypeField
              type="radios"
              field={fields.digestId}
              options={digestOptions}
              stacked
              onChange={digestId => {
                const opts = digests.getIn([digestId, 'supportedOptions']).toJS();
                const defaultVal = makeDefaultData(opts, {});
                fields.options.setValue(defaultVal);
                fields.digestId.setValue(digestId);
                form.rerender();
              }}
            />
          </IndentedField>
        )}

        <IndentedField label={<Margin marginTop="10px">Schedule</Margin>} indent={FORM_INDENT}>
          <TypeField type="select" field={fields.schedulePreset} options={SCHEDULE_OPTIONS} />
        </IndentedField>

        {supportedOptions.size > 0 && (
          <Margin marginTop="30px" marginBottom="20px">
            <strong>Digest options</strong>
          </Margin>
        )}

        <SchemaFields
          field={fields.options}
          renderField={renderCustomField}
          supportedOptions={supportedOptions}
        />

        <Button link onClick={handleClickPreview}>
          Preview digest
        </Button>
      </Form>
    </ModalContent>
  );
}

function IndentedField({label, indent, children}) {
  return (
    <Flex container align="start" bottom="10px">
      <Flex basis={indent}>{label}</Flex>
      <Flex grow={1}>{children}</Flex>
    </Flex>
  );
}

export default DigestSubscriptionModal;
