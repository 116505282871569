import React from 'react';
import {connect} from 'react-redux';
import {usersAndGroupsSelector, loadGroups} from '..';
import Select from 'components/select';
import UserImage from 'components/user/userImage';

export class SelectUserOrGroup extends React.Component {
  componentWillMount() {
    this.props.loadGroups();
  }

  getOptions() {
    const {usersAndGroups} = this.props;
    return usersAndGroups.map(option => {
      return {
        value: option.id,
        label: option.name,
        option
      };
    });
  }

  renderOption = ({label, value, option}) => {
    return (
      <span>
        <UserImage size={30} user={option} />
        <span style={{marginLeft: 10}}>{label}</span>
      </span>
    );
  };

  handleChange = options => {
    this.props.onChange(options.map(option => option.value));
  };

  render() {
    return (
      <Select
        {...this.props}
        onChange={this.handleChange}
        options={this.getOptions()}
        optionRenderer={this.renderOption}
      />
    );
  }
}

export default connect(
  usersAndGroupsSelector,
  {loadGroups}
)(SelectUserOrGroup);
