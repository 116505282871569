//import {fromJS} from 'immutable';
//import {toJS} from 'utils/immutableUtils';
import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {userSelector} from 'modules/auth/authSelectors';
import USER_OPTIONS from './userOptionConstants';

export const userOptionsState = state => state.userOptions;

export const userOptionsSelector = createSelector(
  userOptionsState,
  userOptions => ({userOptions})
);

export const dismissedInfo = createSelector(
  userSelector,
  userOptionsState,
  (user, userOptions) => ({
    loggedInUser: user,
    isVirtual: !!user.get('virtualType'),
    dismissedInfo: toJS(userOptions.get(USER_OPTIONS.DISMISSED_INFO, []))
  })
);
