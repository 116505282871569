import Margin from 'components/margin/margin';
import Panel from 'components/panel';
import SettingsPage from 'components/settings/settingsPage';
import Spinner from 'components/spinner';
import _ from 'lodash';
import {fetchAuditLogs} from 'modules/auditLog/actions';
import AuditLogTable from 'modules/auditLog/components/auditLogTable';
import {fetchLeaderboards} from 'modules/leaderboards/actions';
import {updateQuery} from 'modules/location';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {accountAuditLogsSelector} from '../../auditLog/selectors';

export class AccountSettingsPage extends React.Component {
  static defaultProps = {
    title: 'Audit log'
  };

  filters = {};

  setFilters = newFilters => {
    this.filters = {...newFilters};
  };

  componentWillMount() {
    const {filter} = this.props;
    this.setFilters(filter);
    this.props.fetchLeaderboards();
    this.props.fetchAuditLogs({filter});
  }

  componentDidUpdate() {
    const {filter} = this.props;
    if (!_.isEqual(filter, this.filters)) {
      this.setFilters(filter);
      this.props.fetchAuditLogs({filter});
    }
  }

  handleSetFilter = (filterName, filterValue) => {
    this.filters = {...this.filters, [filterName]: filterValue};
    this.props.fetchAuditLogs({filter: this.filters});
    this.props.updateQuery({[filterName]: filterValue});
  };

  renderAuditLogs() {
    const {auditLogsLoading, auditLogs, usernameMap} = this.props;

    if (!auditLogs) return null;

    const {rows, ...pagination} = auditLogs;

    return (
      <Fragment>
        <AuditLogTable
          filters={this.filters}
          isLoading={auditLogsLoading}
          auditLogs={rows}
          pagination={pagination}
          onSetFilter={this.handleSetFilter}
        />
      </Fragment>
    );
  }

  renderContent() {
    return (
      <Fragment>
        <Margin margin="16px">
          <Panel padding={false}>{this.renderAuditLogs()}</Panel>
        </Margin>
      </Fragment>
    );
  }

  render() {
    const {title, isLoading} = this.props;

    return (
      <SettingsPage name={<Margin margin="0 16px">{title}</Margin>}>
        {isLoading ? <Spinner marginTop={100} /> : this.renderContent()}
      </SettingsPage>
    );
  }
}

export default connect(
  accountAuditLogsSelector,
  {fetchAuditLogs, fetchLeaderboards, updateQuery}
)(AccountSettingsPage);
