exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".awnJHfXBujfqTiYhJ2eqM{padding:30px}._44tmjDXTnqYdl6upsWBW-{margin-bottom:25px}", ""]);

// exports
exports.locals = {
	"status": "awnJHfXBujfqTiYhJ2eqM",
	"addBtn": "_44tmjDXTnqYdl6upsWBW-"
};