import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import BreadcrumbBar from '../components/breadcrumbBar';
import BreadcrumbLink from '../components/breadcrumbLink';
import {requestAddFolder} from 'modules/folders';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {
  changeReport,
  changeSegment,
  changeArchive,
  changeReportSettings
} from '../breadcrumbActions';
import {connectContext} from 'modules/app/appUtils';

export class ReportBreadcrumb extends React.Component {
  isSettings() {
    return (
      /reports\/\d+\/settings$/.test(this.props.location.pathname) ||
      /reports\/\d+\/settings\/[A-Za-z0-9]+$/.test(this.props.location.pathname)
    );
  }

  handleClickSegment = segmentId => {
    const {changeSegment, reportId} = this.props;

    changeSegment({reportId, segmentId});
  };

  handleClickArchive = archiveId => {
    const {changeArchive} = this.props;
    changeArchive({archiveId});
  };

  render() {
    const {
      folderId,
      folders,
      reportId,
      reports,
      segmentId,
      segments,
      archiveId,
      archives,
      screenSizes
    } = this.props;

    if (/search$/.test(location.pathname)) {
      return (
        <BreadcrumbBar>
          <BreadcrumbLink label="Search" showBreakline />
        </BreadcrumbBar>
      );
    }

    if (!reportId) {
      return null;
    }

    return (
      <BreadcrumbBar>
        <React.Fragment>
          {/* <BreadcrumbLink
              value={reportId.toString()}
              options={reports}
              onClick={this.handleClickReport}
              showBreakline
            /> */}
          {/* {!!segments.length && (
            <BreadcrumbLink
              value={segmentId.toString()}
              options={segments}
              onClick={this.handleClickSegment}
              showBreakline
            />
          )} */}
          {!this.props.screenSizes.largeMobile && (
            <BreadcrumbLink
              value={archiveId.toString()}
              options={archives}
              onClick={this.handleClickArchive}
              showBreakline
            />
          )}
        </React.Fragment>
        {this.isSettings() && <BreadcrumbLink label="Settings" showBreakline />}
      </BreadcrumbBar>
      // <BreadcrumbLink value={segmentId} options={segments} onClick={this.handleClickSegment} />
    );
  }
}

export default compose(
  connect(
    null,
    {
      requestAddFolder,
      changeReport,
      changeSegment,
      changeReportSettings,
      changeArchive
    }
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(ReportBreadcrumb);
