import _ from 'lodash';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {connectContext} from 'modules/app/appUtils';
import {push} from 'modules/location';
import {triggerDeleteFolderView, viewFolderViewPage, exitFolderViewPage} from 'modules/folderViews';
import {reportPath} from 'modules/reports/reportUtils';
import {folderViewPath} from 'modules/folderViews/folderViewUtils';
import FolderTabs from 'modules/folders/components/folderTabs';
import Tab from 'components/tabs/tab';
import ReportsTable from 'modules/reports/components/reportsTable';
import ReportsGrid from 'modules/reports/components/reportsGrid';
import Spinner from 'components/spinner';
import styles from './folderViewPage.scss';
import {folderViewPageSelector} from 'modules/folderViews/folderViewSelectors';
import EmptyFolderView from 'modules/folders/components/emptyFolderView';
import ShowMore from 'components/showMore';
import {viewFolderPage} from 'modules/folders';
import EditFolderViewButton from 'modules/folderViews/components/editFolderViewButton';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

export class FolderViewPage extends React.Component {
  static defaultProps = {
    folderViews: [],
    folderViewsData: []
  };

  componentWillMount() {
    const {
      match: {params},
      viewFolderViewPage
    } = this.props;
    viewFolderViewPage(params.folderId, params.viewId);
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {params}
    } = nextProps;

    // This is so if we are on /folders/1/1 and we click to go back to the route of /folders/1
    // we want it to trigger a new folder view to ensure we get redirected to the first folderid.
    if (this.props.match.params.viewId !== params.viewId) {
      this.props.viewFolderPage(params.folderId);
    }
  }

  componentWillUnmount() {
    this.props.exitFolderViewPage();
  }

  handleClickTab = (tabIdx, event) => {
    const {folderViews, push} = this.props;
    const {folderId, viewId} = folderViews[tabIdx];
    push(folderViewPath, {folderId, viewId});
  };

  handleClickTabIcon = (tabIdx, event) => {
    const {folderViews, triggerDeleteFolderView} = this.props;
    const {folderId, viewId} = folderViews[tabIdx];
    triggerDeleteFolderView(folderId, viewId);
  };

  handleClickRow = (row, column) => {
    const {reportId} = row;

    if (column.field == 'reportId') {
      // this is the options column with the report dropdown
      return;
    }

    this.props.push(reportPath, {reportId});
  };

  getSelected() {
    const {
      folderViews,
      match: {params}
    } = this.props;

    return _.findIndex(folderViews, c => c.viewId == params.viewId);
  }

  getCurrentFolderView = () => this.props.folderViews[this.getSelected()];

  renderView(view, data) {
    const {type, folderId} = view;

    if (type == 'grid' || this.props.screenSizes.tablet) {
      return (
        <ShowMore items={data} itemsName="results">
          <ReportsGrid reports={data} />
          <div className={styles.editFolderView}>
            {folderId !== 0 && (
              <EditFolderViewButton folderId={view.folderId} viewId={view.viewId} />
            )}
          </div>
        </ShowMore>
      );
    }

    if (type == 'table') {
      return (
        <ReportsTable
          editable
          sideScroll
          view={view}
          results={data}
          onClick={this.handleClickRow}
          className={styles.reportsTable}
        />
      );
    }

    return null;
  }

  renderContent(view) {
    const {
      loading,
      match: {
        params: {viewId, folderId}
      },
      folderViewsData,
      filter,
      reportFilters
    } = this.props;
    const hasFilters =
      filter || (folderId == 0 && reportFilters.filters && reportFilters.filters.length);

    if (loading || !view) {
      return <Spinner className={styles.spinner} large centered />;
    }

    if (view.viewId != viewId) {
      return null;
    }

    if (!folderViewsData.length) {
      return <EmptyFolderView folderId={folderId} hasFilters={hasFilters} />;
    }

    // sort by last run date if not on the welcome page (which has filters)
    const data =
      folderId == 0
        ? folderViewsData
        : folderViewsData.sort((a, b) => {
            return b.lastRunDate - a.lastRunDate;
          });

    return <div className={styles.content}>{this.renderView(view, data)}</div>;
  }

  renderTab = ({name}, idx) => {
    return <Tab key={`folderTab_${idx}_${name}`}>{name}</Tab>;
  };

  render() {
    const {
      folderViews,
      match: {params},
      screenSizes
    } = this.props;

    let selected = this.getSelected();

    // if is not welcome folder, remove welcome views
    // TODO refactor so we only add this view on the welcome page eh?
    if (params.folderId != 0 && folderViews.length && folderViews[0].folderId == 0) {
      folderViews.shift();
      selected--;
    }

    return (
      <div>
        {!screenSizes.tablet && params.folderId != 0 && (
          <FolderTabs
            selected={selected}
            onClickTab={this.handleClickTab}
            onClickTabIcon={this.handleClickTabIcon}
          >
            {folderViews.map(this.renderTab)}
          </FolderTabs>
        )}

        {this.renderContent(folderViews[selected])}
      </div>
    );
  }
}

export default compose(
  connect(
    folderViewPageSelector,
    {
      push,
      triggerDeleteFolderView,
      viewFolderViewPage,
      exitFolderViewPage,
      viewFolderPage
    }
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(FolderViewPage);
