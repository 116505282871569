import {takeLatest} from 'redux-saga/effects';
import {invoiceDownloadEndpoint} from 'modules/accounts/accountUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {DOWNLOAD_INVOICE} from 'modules/accounts';
import {couldNot} from 'utils/errorUtils';
import {showError} from 'utils/saga/alerts';

export default function* downloadInvoiceSaga() {
  yield takeLatest([DOWNLOAD_INVOICE], downloadInvoice);
}

export function* downloadInvoice({invoiceId, cb}) {
  return yield processRequest('GET', invoiceDownloadEndpoint(invoiceId), {
    success: function*(response) {
      // window.open(response.url, '_blank');
      cb && cb();
      window.location = response.url;
    },

    error: function*({errorCode} = {}) {
      cb && cb();
      yield showError(couldNot('generate invoice'));
    }
  });
}
