import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Panel from 'components/panel';
import Spinner from 'components/spinner';
import Buttons from 'components/buttons';
import Table from 'components/table/table';
import Status from 'components/status/status';
import PageHeader from 'components/header/pageHeader';
import SettingsPage from 'components/settings/settingsPage';
import {uptimeMonitorsSelector, isLoadingSelector} from 'modules/uptime/selectors';
import UptimeMonitorButton from '../components/uptimeMonitorButton';
import DeleteUptimeButton from '../components/deleteUptimeButton';
import MaxWidth from 'components/maxWidth';

const MonitorColumns = [
  {
    label: 'Monitor name',
    field: 'name',
    type: 'string'
  },
  {
    label: 'URL',
    field: 'url',
    type: 'url'
  },
  {
    label: 'Max execution time (seconds)',
    field: 'maxAllowedSeconds',
    type: 'int'
  },
  {
    label: '',
    width: '220px',
    type: 'jsx',
    jsx: ({record, reportId}) => {
      return (
        <Buttons noMargin>
          <UptimeMonitorButton monitorId={record.monitorId} />
          <DeleteUptimeButton monitorId={record.monitorId} reportId={reportId} />
        </Buttons>
      );
    }
  }
];

export class ListUptimeMonitors extends React.Component {
  renderPageHeader = () => {
    return (
      <PageHeader
        title="Uptime settings"
        inline={[<UptimeMonitorButton key="add_new_monitor" monitorId="new" />]}
      />
    );
  };

  render() {
    const {
      match: {params},
      uptimeMonitors,
      isLoading
    } = this.props;
    const {reportId} = params;

    // @todo: Need to figure out how it is we are going to be limiting these uptime monitors.
    return (
      <MaxWidth>
        <SettingsPage name="Uptime monitors" params={params} pageHeader={this.renderPageHeader()}>
          <Panel padding={false}>
            {isLoading ? (
              <Spinner marginTop={50} marginBottom={50} />
            ) : uptimeMonitors.isEmpty() ? (
              <Status appearance="warning">
                You don't have any uptime monitors yet. Why not add one?
              </Status>
            ) : (
              <Table
                rowKey="monitorId"
                cellParams={{reportId}}
                columns={MonitorColumns}
                rows={uptimeMonitors.toList().toJS()}
              />
            )}
          </Panel>
        </SettingsPage>
      </MaxWidth>
    );
  }
}

export default connect(
  createStructuredSelector({
    uptimeMonitors: uptimeMonitorsSelector,
    isLoading: isLoadingSelector
  })
)(ListUptimeMonitors);
