import {replaceUpdateQuery} from 'modules/location/location';
import {retestPage} from 'modules/reports';
import * as Actions from './inspectorConstants';

export function retestBegan(missionId) {
  return {type: Actions.RETEST_BEGAN, missionId};
}

export function retestComplete() {
  return {type: Actions.RETEST_COMPLETE};
}

// We have to keep track of the mission returned by retest page, when it's for the inspector.
export function retestPageFromInspector(...args) {
  return {
    ...retestPage(...args),
    fromInspector: true
  };
}

export function setEffect(effectId, isEnabled) {
  return {type: Actions.SET_EFFECT, effectId, isEnabled};
}

export function setDecisionId(props, decisionId, context) {
  return {type: Actions.SET_DECISION_ID, props, decisionId, context};
}

export function selectPoints(pointIds) {
  return {type: Actions.SELECT_POINTS, pointIds};
}

export function setNoRedirectInspectorOnMissing(shouldNotRedirect) {
  return {type: Actions.SET_NO_REDIRECT_INSPECTOR_ON_MISSING, shouldNotRedirect};
}

export function setRetestInspectorPage(isLoading) {
  return {type: Actions.SET_RETEST_INSPECTOR_PAGE, isLoading};
}

export function fetchInspectorPage(params, query, onComplete) {
  return {type: Actions.FETCH_INSPECTOR_PAGE, params, query, onComplete};
}

export function fetchInspectorPageComplete(error, data) {
  return {type: Actions.FETCH_INSPECTOR_PAGE_COMPLETE, error, data};
}

export function updateMarkedHtml(actionId, testId) {
  return {type: Actions.UPDATE_MARKED_HTML, actionId, testId};
}

export function setMarkedHtml(id, html) {
  return {type: Actions.SET_MARKED_HTML, id, html};
}

export function removeContext() {
  return {type: Actions.REMOVE_CONTEXT};
}

export function clearData() {
  return {type: Actions.CLEAR_DATA};
}

export function exitInspector() {
  return {type: Actions.EXIT_INSPECTOR};
}

/* Standalone inspector actions */
export function initiateStandaloneRequest(url, toolName) {
  return {type: Actions.INITIATE_STANDALONE_REQUEST, url, toolName};
}

export function fetchStandaloneInspectorData(s3Url) {
  return {type: Actions.FETCH_STANDALONE_INSPECTOR_DATA, s3Url};
}

export function fetchStandaloneInspectorDataComplete(error, data) {
  return {type: Actions.FETCH_STANDALONE_INSPECTOR_DATA_COMPLETE, error, data};
}

export function clearStandaloneRequestState() {
  return {type: Actions.CLEAR_STANDALONE_REQUEST_STATE};
}

export function setStandaloneRequestStatus(status, props) {
  return {type: Actions.SET_STANDALONE_REQUEST_STATUS, status, props};
}

export function setStandaloneRequestQueuePosition(pos) {
  return {type: Actions.SET_STANDALONE_QUEUE_POS, pos};
}

export function setStandaloneRequestResponse(data) {
  return {type: Actions.SET_STANDALONE_REQUEST_RESPONSE, data};
}

// NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
// export function actionFilterChanged(name, value) {
//   return {type: Actions.ACTION_FILTER_CHANGED, name, value};
// }

export function navigateToCategory(categoryId) {
  return replaceUpdateQuery({categoryId, actionId: undefined});
}

export function navigateToAction(actionId) {
  return replaceUpdateQuery({actionId});
}
