import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {showUpgradeModal} from 'modules/onboarding';

export class UpgradeButton extends React.Component {
  handleClick = () => {
    this.props.showUpgradeModal();
  };

  render() {
    const {children, ...buttonProps} = this.props;

    return (
      <Button {...buttonProps} onClick={this.handleClick}>
        {children || 'Upgrade now'}
      </Button>
    );
  }
}

export default connect(
  null,
  {showUpgradeModal}
)(UpgradeButton);
