import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {
  viewActionLayout,
  fetchActionResults,
  setActionResultFilters
} from 'modules/actions/actions';
import Pusher from 'modules/pusher/components/pusher';
import LayoutComponent from 'modules/tests/components/layoutComponent';
import {reportChannel} from 'modules/pusher/channels';

export class ActionLayout extends React.Component {
  handleSetFilterParams = filters => {
    this.setState(filters, this.fetchResults);
    this.props.setActionResultFilters(this.props.actionId, filters);
  };

  constructor() {
    super(...arguments);

    const {defaultFilters} = this.props;

    // start with default filters
    this.state = {...defaultFilters};

    this.props.setActionResultFilters(this.props.actionId, defaultFilters);
  }

  componentWillMount() {
    this.fetchResults();
  }

  fetchResults() {
    const {actionId} = this.props;
    this.props.viewActionLayout(actionId, this.state);
  }

  updatedActionResults = actionIds => {
    const {actionId} = this.props;

    if (actionIds.indexOf(actionId) !== -1) {
      this.props.fetchActionResults(actionId, this.state);
    }
  };

  renderLayout() {
    const {reportId, layout, testId} = this.props;

    if (!layout) {
      return null;
    }

    return _.map(layout, config => (
      <LayoutComponent
        key={config.componentId}
        filter={this.state}
        config={config}
        setFilterParams={this.handleSetFilterParams}
        params={{testId, reportId}}
        viewingContext="task"
      />
    ));
  }

  render() {
    const {reportId} = this.props;

    // @todo: Once filters have been moved into the redux store, we need to move this Pusher to the parent element of this one.
    // This one will re-render far more often than the parent element.

    return (
      <div>
        <Pusher
          channel={reportChannel({params: {reportId}})}
          event="updatedTaskResults"
          onUpdate={actionIds => this.updatedActionResults(actionIds)}
        />

        {this.renderLayout()}
      </div>
    );
  }
}

export default connect(
  null,
  {viewActionLayout, fetchActionResults, setActionResultFilters}
)(ActionLayout);
