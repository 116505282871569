import React from 'react';
import Helmet from 'components/helmet';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import SettingsSidebar from 'modules/settings/components/settingsSidebar';

export default class SettingsWrapper extends React.Component {
  render() {
    const {children, params, pathname} = this.props;
    const sidebar = <SettingsSidebar params={params} pathname={pathname} />;

    return (
      <PageWithSidebar sidebar={sidebar}>
        <Helmet title="Account settings" />
        {children}
      </PageWithSidebar>
    );
  }
}
