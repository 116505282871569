import * as Consts from './constants';

export function requestCourses() {
  return {type: Consts.REQUEST_COURSES};
}
export function requestCoursesComplete(error, courses) {
  return {type: Consts.REQUEST_COURSES_COMPLETE, error, courses};
}

export function requestCourse(courseId) {
  return {type: Consts.REQUEST_COURSE, courseId};
}
export function requestCourseComplete(error, course) {
  return {type: Consts.REQUEST_COURSE_COMPLETE, error, course};
}

export function requestLesson(lessonId) {
  return {type: Consts.REQUEST_LESSON, lessonId};
}
export function requestLessonComplete(error, lesson) {
  return {type: Consts.REQUEST_LESSON_COMPLETE, error, lesson};
}

export function saveLessonState(lesson) {
  return {type: Consts.SAVE_LESSON_STATE, lesson};
}

export function saveLessonStateComplete(error, lesson) {
  return {type: Consts.SAVE_LESSON_STATE_COMPLETE, error, lesson};
}

export function enrollOnCourse(courseId) {
  return {type: Consts.COURSE_ENROLL, courseId};
}

export function enrollOnCourseComplete(error, course) {
  return {type: Consts.COURSE_ENROLL_COMPLETE, error, course};
}

export function unEnrollFromCourse(courseId) {
  return {type: Consts.COURSE_UNENROLL, courseId};
}

export function unEnrollFromCourseComplete(error, course) {
  return {type: Consts.COURSE_UNENROLL_COMPLETE, error, course};
}

export function setEnrolledUsers(courseId, userIds) {
  return {type: Consts.SET_ENROLLED_USERS, courseId, userIds};
}
export function setEnrolledUsersComplete(error, course) {
  return {type: Consts.SET_ENROLLED_USERS_COMPLETE, error, course};
}

export function saveQuizState(lesson) {
  return {type: Consts.SAVE_QUIZ_STATE, lesson};
}

export function saveQuizStateComplete(error, lesson) {
  return {type: Consts.SAVE_QUIZ_STATE_COMPLETE, error, lesson};
}
