import {put, select, takeLatest} from 'redux-saga/effects';
import fetch from 'isomorphic-fetch';
import {GEOLOCATE, receiveLocation} from 'modules/geolocation';
import {userSelector} from 'modules/auth/authSelectors';
import {processRequest} from 'utils/saga/fetchUtils';

// Gets the country of the auth account.
// Uses geo-ip lookup with fallback.

export default function* geolocateSaga() {
  yield takeLatest(GEOLOCATE, geolocate);
}

function* geolocate() {
  try {
    const user = yield select(userSelector);

    // if user is logged in, return existing location
    if (user.get('addressCountry')) {
      yield put(receiveLocation(user.get('addressCountry')));
      return;
    }

    const {success, country_code} = yield processRequest('GET', 'geo/locate');

    if (success && country_code) {
      yield put(receiveLocation(country_code));
    }
  } catch (e) {
    if (__DEV__) {
      console.warn(`Couldn't get country from freegeoip.net: ${e}`);
    }
  }
}
