import React, {Children} from 'react';
import {findDOMNode} from 'react-dom';
import Measure from 'react-measure';
import Tabs from 'components/tabs';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import cx from 'classnames';
import styles from './responsiveTabs.scss';

const dropdownIcon = <Button plain icon="caret-down" />;

function getOffsetTop(ref) {
  return findDOMNode(ref).offsetTop;
}

export default class ResponsiveTabs extends React.Component {
  static defaultProps = {
    inlineChildren: []
  };

  constructor(props) {
    super();

    this.state = {
      headerCount: Children.count(props.children)
    };

    this.tabItems = [];
    this.shouldRecalculateHeaders = false;
  }

  componentDidMount() {
    this.recalculateHeaders();
  }

  componentDidUpdate() {
    // if we call this.recalculateHeaders directly, we get an "maximum stack trace" error
    if (this.shouldRecalculateHeaders) {
      this.recalculateHeaders();
      this.shouldRecalculateHeaders = false;
    }
  }

  componentWillReceiveProps(nextProps) {
    const {children, inlineChildren} = this.props;

    if (
      inlineChildren.length !== nextProps.inlineChildren.length ||
      Children.count(children) !== Children.count(nextProps.children)
    ) {
      this.shouldRecalculateHeaders = true;
    }
  }

  handleDropdownClick = (idx, event) => {
    const {children} = this.props;
    const {onClick} = children[idx].props;

    // call the corresponding childs click event
    onClick && onClick(event);
  };

  handleMeasure = dimensions => {
    // We can use this callback to inform us when the size has changed.

    // We don't use `dimensions` because that gives us the width of the wrapper,
    // which may not necessarily be the width of the `this.tabList` that we need.

    // @todo the above comment may no longer apply when we refactor tabs away from `inlineChildren`

    this.recalculateHeaders();
  };

  recalculateHeaders() {
    const headerCount = this.getHeaderCount();

    this.setState({headerCount});
  }

  getHeaderCount() {
    const len = this.tabItems.length;
    let headerCount = 0;

    this.tabItems.some((item, i, tabItems) => {
      const nextTab = tabItems[i + 1];

      headerCount = i;

      if (nextTab && getOffsetTop(nextTab) != getOffsetTop(item)) {
        return true;
      }
      return false;
    });

    return headerCount + 1;
  }

  getDropdownOptions() {
    const {children} = this.props;
    const {headerCount} = this.state;

    return children.slice(headerCount).map((tab, idx) => ({
      value: headerCount + idx,
      label: tab.props.children
    }));
  }

  renderDropdown() {
    return (
      <Dropdown
        alignRight
        role="presentation"
        button={dropdownIcon}
        className={styles.dropdown}
        options={this.getDropdownOptions()}
        onChange={this.handleDropdownClick}
      />
    );
  }

  renderTab = (tab, idx) => {
    // we need to add a `ref` to the tab so we can query its offsetTop
    return React.cloneElement(tab, {
      ref: ref => {
        this.tabItems[idx] = ref;
      },
      invisible: idx >= this.state.headerCount
    });
  };

  render() {
    const {headerCount} = this.state;
    const {children, className, ...props} = this.props;
    const showDropdown = Children.count(children) !== headerCount;
    const classes = cx(className, {
      [styles.showDropdown]: showDropdown
    });

    return (
      <Measure onMeasure={this.handleMeasure}>
        <div className={styles.responsiveWrapper}>
          <Tabs {...props} className={classes}>
            {children.map(this.renderTab)}
          </Tabs>

          {showDropdown && this.renderDropdown()}
        </div>
      </Measure>
    );
  }
}
