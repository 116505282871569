import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EntitySelector from './entitySelector';
import styles from './entitySelect.scss';
import {matchesSearch, reorder} from './utils';

class EntityMultiSelector extends Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        keyword: PropTypes.string,
        label: PropTypes.node
      })
    )
  };

  static defaultProps = {
    value: []
  };

  handleAdd = selectedId => {
    this.props.onChange([...this.props.value, selectedId]);
  };

  handleRemove = unselectedId => {
    this.props.onChange([...this.props.value.filter(id => id !== unselectedId)]);
  };

  handleAddAll = entityIds => {
    this.props.onChange([...this.props.value, ...entityIds]);
  };

  handleRemoveAll = entityIds => {
    this.props.onChange([...this.props.value.filter(id => !entityIds.includes(id))]);
  };

  handleOrderChange = result => {
    if (!result || !result.destination) {
      return;
    }

    const selected = reorder(this.props.value, result.source.index, result.destination.index);
    this.props.onChange(selected);
  };

  render() {
    const {
      selectedLabel,
      unselectedLabel,
      removeLabel,
      addLabel,
      sortable,
      options,
      value,
      dark
    } = this.props;

    return (
      <div className={styles.entityMultiSelect}>
        <EntitySelector
          label={unselectedLabel}
          entities={options.filter(option => !value.includes(option.value))}
          onEntityClick={this.handleAdd}
          onCheckedClick={this.handleAddAll}
          buttonText={addLabel}
          orderBy={(a, b) => {
            a = (a.keyword || a.label).toLowerCase();
            b = (b.keyword || b.label).toLowerCase();
            return a < b ? -1 : b < a ? 1 : 0;
          }}
          dark={dark}
        />
        <EntitySelector
          label={selectedLabel}
          entities={options.filter(option => value.includes(option.value))}
          onEntityClick={this.handleRemove}
          onCheckedClick={this.handleRemoveAll}
          buttonText={removeLabel}
          handleOrderChange={sortable ? this.handleOrderChange : undefined}
          dark={dark}
        />
      </div>
    );
  }
}

export default EntityMultiSelector;
