import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {fetchSearchResults, fetchRecentReports} from 'modules/searchPage/actions';
import {push} from 'modules/location';
import {searchPageSelector} from 'modules/searchPage/selectors';
import SearchInput from 'modules/searchPage/components/searchInput';
import SearchResults from 'modules/searchPage/components/searchResults';
import {leaderboardsPath} from 'modules/leaderboards/utils';
import {foldersPath} from 'modules/folders/folderUtils';
import Button from 'components/button/button';
import Center from 'components/center/center';
import Flex from 'components/layout/flex';
import Select from 'components/select/select';
import {requestTags} from 'modules/tags/actions';

class SearchReports extends React.Component {
  state = {searchTerm: '', tags: []};

  componentWillMount() {
    this.props.fetchRecentReports();

    if (!this.props.hasTagsLoaded && !this.props.tagsLoading) {
      this.props.requestTags();
    }
  }

  handleChangeTags = tags => {
    const searchTags = tags.map(tag => tag.value);
    this.setState({tags: searchTags});
    this.props.fetchSearchResults(this.state.searchTerm, searchTags, {debounce: false});
  };

  handleSubmitSearchTerm = term => {
    this.props.fetchSearchResults(term, this.state.tags, {debounce: false});
  };

  handleChangeSearchTerm = term => {
    this.setState({searchTerm: term});
    this.props.fetchSearchResults(term, this.state.tags);
  };

  handleClickViewWebsites = () => {
    const {
      push,
      match: {params}
    } = this.props;

    if (this.props.leaderboardsFlag) {
      push(leaderboardsPath(params));
    } else {
      push(foldersPath(params));
    }
  };

  render() {
    const {searchTerm, tags} = this.state;
    const {
      match: {params},
      searchResults,
      tagOptions,
      recentReports,
      searchResultsLoading,
      recentReportsLoading,
      theme
    } = this.props;

    return (
      <div>
        <Flex container bottom="30px">
          <Flex grow={1}>
            <SearchInput
              theme={theme}
              value={searchTerm}
              onChange={this.handleChangeSearchTerm}
              onSubmit={this.handleSubmitSearchTerm}
              autoFocus
              placeholder="Search all websites"
            />
          </Flex>
          <Flex basis="200px">
            <Select
              multi
              placeholder="Filter by tags"
              value={tags}
              options={tagOptions.toJS()}
              onChange={this.handleChangeTags}
            />
          </Flex>
        </Flex>
        {searchTerm || tags.length ? (
          <SearchResults
            term={searchTerm}
            label="Your search results"
            results={searchResults.toJS()}
            loading={searchResultsLoading}
          />
        ) : (
          <div>
            <SearchResults
              term={searchTerm}
              label="Your recent websites"
              results={recentReports.toJS()}
              loading={recentReportsLoading}
            />

            <Center marginTop={20}>
              <Button wire key="view" onClick={this.handleClickViewWebsites}>
                View all websites
              </Button>
            </Center>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    searchPageSelector,
    {fetchSearchResults, fetchRecentReports, requestTags, push}
  )(SearchReports)
);
