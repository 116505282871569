export {default} from './reportPermissionsReducer';

export const SET_ROLE = 'reportPermissions:SET_ROLE';
export function setRole(reportId, userGroupId, role) {
  return {
    type: SET_ROLE,
    reportId,
    userGroupId,
    role
  };
}

export const ROLE_SET = 'reportPermissions:ROLE_SET';
export function roleSet(permission) {
  return {
    type: ROLE_SET,
    permission
  };
}

export const REMOVE_ROLE = 'reportPermissions:REMOVE_ROLE';
export function removeRole(reportId, userGroupId) {
  return {
    type: REMOVE_ROLE,
    reportId,
    userGroupId
  };
}

export const ROLE_REMOVED = 'reportPermissions:ROLE_REMOVED';
export function roleRemoved(reportId, userGroupId) {
  return {
    type: ROLE_REMOVED,
    reportId,
    userGroupId
  };
}
export const VIEW_PERMISSIONS_CONFIG_PAGE = 'reports:VIEW_PERMISSIONS_CONFIG_PAGE';
export function viewPermissionsConfigPage(reportId) {
  return {
    type: VIEW_PERMISSIONS_CONFIG_PAGE,
    reportId
  };
}

export const RECEIVE_PERMISSIONS = 'reports:RECEIVE_PERMISSIONS';
export function receivePermissions(permissions) {
  return {
    type: RECEIVE_PERMISSIONS,
    permissions
  };
}
