import React from 'react';
import Button from 'components/button';
import Buttons from 'components/buttons';
import styles from './modalFooter.scss';
import cx from 'classnames';

export default function ModalFooter({
  children,
  buttons = [],
  onClick,
  focusButton,
  className,
  isLoading
}) {
  if (!buttons.length && !children) return null;
  if (!onClick && !children) {
    if (__DEV__) console.error('ModalFooter called without onClick');
    return null;
  }

  return (
    <footer className={cx(styles.footer, className)}>
      {children || (
        <Buttons className={styles.buttons} noMargin>
          {buttons.map((button, idx) => {
            const props = {
              key: button,
              onClick: () => onClick(button),
              autoFocus: focusButton && idx === 0,
              isLoading: isLoading && idx === buttons.length - 1
            };

            if (typeof button == 'string') {
              return <Button {...props}>{button}</Button>;
            }

            return React.cloneElement(button, props);
          })}
        </Buttons>
      )}
    </footer>
  );
}
