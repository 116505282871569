import Button from 'components/button';
import Buttons from 'components/buttons';
import {Map} from 'immutable';
import {updateSelfConsents} from 'modules/auth/auth';
import {isVirtualUserSelector, userSelector} from 'modules/auth/authSelectors';
import {Modal, ModalContent, hideModal} from 'modules/modal';
import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import NewsletterImg from './newsletter-signup.jpg';
import styles from './styles.scss';

const EmailConsentModal = connect(
  createSelector(
    userSelector,
    isVirtualUserSelector,
    (user, isVirtualUser) => {
      return {user, isVirtualUser};
    }
  ),
  dispatch => ({
    handleChangeUserConsent: (emailUpdatesConsent, userId) => {
      dispatch(updateSelfConsents(userId, {'email-updates': emailUpdatesConsent}));
    }
  })
)(({user, handleChangeUserConsent, isVirtualUser}) => {
  const consents = user.get('consents') || Map();

  if (consents.get('email-updates') !== '' || isVirtualUser) {
    return null;
  }

  const footer = (
    <Buttons>
      <Button wire onClick={() => handleChangeUserConsent('rejected', user.get('userId'))}>
        No thanks
      </Button>
      <Button onClick={() => handleChangeUserConsent('accepted', user.get('userId'))}>
        Yes, keep me updated
      </Button>
    </Buttons>
  );

  return (
    <Modal onClose={() => handleChangeUserConsent('', user.get('userId'))}>
      <ModalContent
        title="Become a Silktide Insider"
        footer={footer}
        className={styles.modalContent}
      >
        <p>Can we email you occassional updates about Silktide and building better websites?</p>
        <img className={styles.newsletterImg} src={NewsletterImg} alt="" />
      </ModalContent>
    </Modal>
  );
});

export default EmailConsentModal;
