import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {requestTagsSummaryComplete} from 'modules/tags/actions';
import {
  REQUEST_TAGS_SUMMARY,
  ASSIGN_TAGS_COMPLETE,
  UNASSIGN_TAGS_COMPLETE
} from 'modules/tags/constants';

// we also want to refetch the summaries when we assign or unassign something, because that changes the summary
export default function* requestTagsSummaryListener() {
  yield takeLatest(
    [REQUEST_TAGS_SUMMARY, ASSIGN_TAGS_COMPLETE, UNASSIGN_TAGS_COMPLETE],
    requestTagsSummary
  );
}

function* requestTagsSummary(action) {
  try {
    const response = yield processRequest('GET', 'tags-summary');
    yield put(requestTagsSummaryComplete(null, response.tagsSummary));
  } catch (err) {
    yield put(requestTagsSummaryComplete(err));
  }
}
