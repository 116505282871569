import {put} from 'redux-saga/effects';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {receiveSubscription} from 'modules/services/servicesActions';
import {REQUEST_DELETE_SUPER_SUBSCRIPTION} from 'modules/subscriptions';
import {cancelSuperSubscriptionEndpoint} from 'modules/subscriptions/subscriptionUtils';
import {couldNot} from 'utils/errorUtils';

export default generateDeleteSaga(
  'subscription',
  REQUEST_DELETE_SUPER_SUBSCRIPTION,
  cancelSuperSubscriptionEndpoint,
  {
    success: function*(action, data) {
      yield put(receiveSubscription(data));
    },
    errorMessage: couldNot('cancel subscription')
  },
  'Cancel'
);
