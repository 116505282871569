import {List, Map} from 'immutable';
import _ from 'lodash';
import {ROLE_CONTRIBUTOR, reportIsAllowed} from 'modules/gandalf/gandalfUtils';
import {locationSelector, queryParamsSelector} from 'modules/location/locationSelectors';
import {missionsSelector} from 'modules/missions/missionSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {createSelector, createStructuredSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {getPrefixedProps} from 'utils/propUtils';

const inspectorStateSelector = state => state.inspector;

const actionIdQuerySelector = createSelector(
  queryParamsSelector,
  query => query.actionId
);
const categoryIdQuerySelector = createSelector(
  queryParamsSelector,
  query => query.categoryId || ''
);
const actionFiltersSelector = createSelector(
  inspectorStateSelector,
  inspector => inspector.get('actionFilters')
);

const actionIdPropSelector = (_, props) => props.actionId;
const categoryIdPropSelector = (_, props) => props.categoryId;

// query string args prefixed with 'context': {contextActionId: 1, ...blah}
const contextQuerySelector = createSelector(
  locationSelector,
  ({query = {}, params}) => {
    return Map(getPrefixedProps(query, 'context'));
  }
);

const hasQueryContextSelector = createSelector(
  contextQuerySelector,
  context => {
    return !context.isEmpty();
  }
);

// context query string about but without prefix: {contextActionId: 1} > {actionId: 1}
const decodedContextQuerySelector = createSelector(
  contextQuerySelector,
  contextQuery => {
    return contextQuery.reduce((map, value, key) => {
      // transform `contextActionId` into `actionId`
      const decodedKey = key[7].toLowerCase() + key.substr(8);
      return map.set(decodedKey, value);
    }, Map());
  }
);

const pointsSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.getIn(['data', 'points']) || Map();
  }
);

const scaleSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.getIn(['data', 'scale']) || '';
  }
);

const statusSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.getIn(['data', 'status']) || 'waiting';
  }
);

const sidebarLoadingSelector = createSelector(
  inspectorStateSelector,
  state => !state.getIn(['data', 'points'], Map()).size
);

const bodyLoadingSelector = createSelector(
  inspectorStateSelector,
  state => !state.getIn(['data', 'viewHtml']) || !state.getIn(['data', 'sourceHtml'])
);

const pageLoadingSelector = createSelector(
  sidebarLoadingSelector,
  bodyLoadingSelector,
  statusSelector,
  (sidebarLoading, bodyLoading, status) => {
    return status === 'waiting' && sidebarLoading && bodyLoading;
  }
);

const makingDecisionsSelector = createSelector(
  inspectorStateSelector,
  state => state.get('makingDecisions')
);

export const sourceHtmlSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.getIn(['data', 'sourceHtml']) || '';
  }
);

const sourceHeadHtmlSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.getIn(['data', 'sourceHeadHtml']) || '';
  }
);

export const enabledEffectsSelector = createSelector(
  inspectorStateSelector,
  state => {
    return state.get('enabledEffects').toJS();
  }
);

const errorMessageSelector = createSelector(
  inspectorStateSelector,
  statusSelector,
  pageLoadingSelector,
  (state, status, isLoading) => {
    if (state.get('lastError')) return state.get('lastError');

    if (status === 'ok' || isLoading) return null;

    const message = state.getIn(['data', 'errorMessage']);

    if (!message) {
      return status === 'unavailable'
        ? 'This page is not available'
        : status === 'none'
        ? 'Unable to find a page with this issue. It may have been removed or fixed.'
        : 'An unexpected error occurred';
    }
    return message;
  }
);

const actionsSelector = createSelector(
  pointsSelector,
  inspectorStateSelector,
  (points, state) => {
    return (
      state.getIn(['data', 'actions']).map(action => {
        // does this action have any points?
        const actionPoints = points.filter(point => {
          return point.get('actionId') === action.get('actionId');
        });
        return action.set('pointsCount', actionPoints.size);
      }) || Map()
    );
  }
);

const actionSelector = createSelector(
  actionsSelector,
  actionIdQuerySelector,
  (actions, actionId) => {
    return actions.get(actionId);
  }
);

// an array of `pointId`
const currentPointIdsSelector = createSelector(
  inspectorStateSelector,
  locationSelector,
  (state, {params, query}) =>
    // only return the selected points if a actionId has been specified
    query.actionId && state.get('selectedPointIds') ? state.get('selectedPointIds') : List()
);

const inpectorRestestMissionId = createSelector(
  inspectorStateSelector,
  inspector => inspector.get('retestMissionId')
);

const inpectorRestestMissionSelector = createSelector(
  inpectorRestestMissionId,
  missionsSelector,
  (missionId, missions) => missions && missions.get(missionId)
);

export const inspectorMetadataSelector = createSelector(
  inspectorStateSelector,
  inspector => toJS(inspector.getIn(['data', 'keys'])) || {}
);

export const deviceSelector = createSelector(
  inspectorStateSelector,
  inspector => inspector.getIn(['data', 'device']) || 'Desktop'
);

const pageViewSelector = createSelector(
  locationSelector,
  deviceSelector,
  // if no pageView, default to the device ('iphone5Portrait', 'desktop', 'tablet')
  ({query}, device) => query.pageView || device
);

export const retestMissionIdSelector = createSelector(
  inspectorStateSelector,
  inspector => inspector.get('retestMissionId')
);

const inspectorContextDataSelector = createSelector(
  inspectorStateSelector,
  inspector => {
    const context = toJS(inspector.getIn(['data', 'context']));
    // `context` is weirdly an array if not specified (rather than empty object) [probably PHPs fault]
    return Array.isArray(context) && !context.length ? null : context;
  }
);

const categoriesSelector = createSelector(
  inspectorStateSelector,
  inspector => inspector.getIn(['data', 'categories'], List())
);

const categoryActionsSelector = createSelector(
  inspectorStateSelector,
  inspector => inspector.getIn(['data', 'categoryActions'], List())
);

const categoryPropSelector = createSelector(
  (_, props) => props.category,
  categoryIdPropSelector,
  categoriesSelector,
  (category, categoryId, categories) =>
    category || categories.find(category => category.get('categoryId') == categoryId)
);

const actionPointsSelector = createSelector(
  actionIdQuerySelector,
  pointsSelector,
  (actionId, points) => {
    return !actionId ? Map() : points.filter(point => point.get('actionId') == actionId);
  }
);

const actionContextPointsSelector = createSelector(
  actionPointsSelector,
  decodedContextQuerySelector,
  // actionIdQuerySelector,
  (actionPoints, context, actionId) => {
    // only filter by context if we are on the action that should be filtered
    // if (actionId && actionId === context.get('actionId')) {
    return actionPoints.filter(point => pointMatchesContext(point, context));
    // }
    // return actionPoints;
  }
);

const actionPointsSplitContextSelector = createSelector(
  actionPointsSelector,
  decodedContextQuerySelector,
  (actionPoints, context) => {
    return actionPoints
      .reduce((container, point) => {
        const containerId = pointMatchesContext(point, context) ? 'contextPoints' : 'points';
        return container.setIn([containerId, point.get('pointId')], point);
      }, Map({contextPoints: Map(), points: Map()}))
      .update('contextPoints', sortPoints)
      .update('points', sortPoints);
  }
);

const sortPoints = points => points.sortBy(p => p.get('sortIndex'));

const activeCategorySelector = createSelector(
  categoryIdQuerySelector,
  categoriesSelector,
  (categoryId, categories) => {
    return categories.find(category => category.get('categoryId') == categoryId);
  }
);

const activeParentCategorySelector = createSelector(
  activeCategorySelector,
  categoriesSelector,
  (activeCategory, categories) => {
    if (!activeCategory || !activeCategory.get('parentCategoryId')) {
      return null;
    }
    return categories.find(
      category => category.get('categoryId') == activeCategory.get('parentCategoryId')
    );
  }
);

const activeCategoryChildrenSelector = createSelector(
  categoriesSelector,
  categoryIdQuerySelector,
  (categories, categoryId) => {
    // find all the categories that are a child of the current category
    return categories
      .filter(category => category.get('parentCategoryId') == categoryId)
      .sortBy(category => category.get('sortIndex'));
  }
);

const unsetContextQuerySelector = createSelector(
  contextQuerySelector,
  contextQuery => {
    return contextQuery.keySeq().reduce((result, key) => {
      result[key] = undefined;
      return result;
    }, {});
  }
);

const currentChildrenCategoryIdsSelector = createSelector(
  categoriesSelector,
  categoryPropSelector,
  categoryIdPropSelector,
  categoryIdQuerySelector,
  (categories, categoryProp, categoryIdProp, categoryIdQuery) => {
    // try from props first, if no props, try from query string
    const categoryId = categoryProp
      ? categoryProp.get('categoryId')
      : categoryIdProp || categoryIdQuery;
    return getChildrenCategoryIds(categories, categoryId);
  }
);

const actionsByCategoryIdSelector = createSelector(
  actionsSelector,
  categoryActionsSelector,
  currentChildrenCategoryIdsSelector,
  (actions, categoryActions, categoryIds) => {
    return actions.filter(filterActions(categoryIds, Map(), categoryActions)).sort(sortActions);
  }
);

const filteredActionsByCategoryIdSelector = createSelector(
  actionsSelector,
  categoryActionsSelector,
  currentChildrenCategoryIdsSelector,
  actionFiltersSelector,
  (actions, categoryActions, categoryIds, actionFilters) => {
    return actions
      .filter(filterActions(categoryIds, actionFilters, categoryActions))
      .sort(sortActions);
  }
);

const actionCountsByCategoryIdSelector = createSelector(
  actionsByCategoryIdSelector,
  filteredActionsByCategoryIdSelector,
  (actionsByCategoryId, filteredActionsByCategoryId) => {
    return {
      totalActions: actionsByCategoryId.size,
      showingActions: filteredActionsByCategoryId.size,
      hiddenActions: actionsByCategoryId.size - filteredActionsByCategoryId.size
    };
  }
);

// no points if an action isn't selected
export const htmlPointsSelector = createSelector(
  actionPointsSelector,
  actionIdQuerySelector,
  (points, actionId) => {
    if (!actionId) return List();

    return points.toList();
  }
);

export const inspectorPageSelector = createSelector(
  inspectorStateSelector,
  pageLoadingSelector,
  errorMessageSelector,
  (inspector, isLoading, errorMessage) => ({
    isLoading,
    errorMessage,
    hasSidebar: true, // TODO some inspector responses don't have a sidebar
    pageHash: inspector.getIn(['data', 'keys', 'pageHash'])
  })
);

export const inspectorContextSelector = createSelector(
  inspectorContextDataSelector,
  hasQueryContextSelector,
  locationSelector,
  (context, hasContext, {query, params}) => ({
    context,
    hasContext,
    query,
    params
  })
);

export const inspectorHeaderSelector = createSelector(
  locationSelector,
  ({params}) => ({params})
);

export const inspectorEditCMSButtonSelector = createSelector(
  inspectorStateSelector,
  inspector => ({
    editPageUrl: inspector.getIn(['data', 'editPageUrl']),
    windowName: inspector.getIn(['data', 'editPageData', 'windowName'], '_blank'),
    windowFeatures: inspector.getIn(['data', 'editPageData', 'windowFeatures'], ''),
    cms: inspector.getIn(['data', 'editPageData', 'cms'])
  })
);

export const inspectorUrlSelector = createSelector(
  inspectorStateSelector,
  inspector => ({
    url: inspector.getIn(['data', 'url']),
    time: inspector.getIn(['data', 'lastUpdated'])
  })
);

export const inspectorViewSwitcherSelector = createSelector(
  pageViewSelector,
  enabledEffectsSelector,
  (pageView, enabledEffects) => ({pageView, enabledEffects})
);

export const inspectorSubHeaderSelector = createSelector(
  pageViewSelector,
  pageView => ({pageView})
);

export const inspectorSidebarSelector = createSelector(
  sidebarLoadingSelector,
  hasQueryContextSelector,
  actionIdQuerySelector,
  categoryIdQuerySelector,
  categoriesSelector,
  (isLoading, hasContext, actionId, categoryId, categories) => {
    return {
      isLoading,
      hasContext,
      actionId,
      categoryId,
      categories
    };
  }
);

// CLEAN ME
export const inspectorBodySelector = createSelector(
  inspectorStateSelector,
  actionSelector,
  actionPointsSelector,
  currentPointIdsSelector,
  locationSelector,
  scaleSelector,
  sourceHeadHtmlSelector,
  pageViewSelector,
  enabledEffectsSelector,
  bodyLoadingSelector,
  categoryIdQuerySelector,
  (
    inspector,
    action,
    points,
    currentPointIds,
    {query = {}, params},
    scale,
    sourceHeadHtml,
    pageView,
    enabledEffects,
    isLoading,
    categoryId
  ) => {
    const actionId = action && action.get('actionId');
    const paginatedPoints = actionId
      ? toJS(points.filter(p => p.get('actionId') == actionId).toList())
      : [];

    return {
      isLoading,
      query,
      params,
      scale,
      pageView,
      actionId,
      categoryId,
      enabledEffects,
      sourceHeadHtml,
      action: toJS(action),
      points: paginatedPoints,
      currentPointIds: toJS(currentPointIds),
      sourceHtml: inspector.get('markedSourceHtml') || '',
      viewHtml: inspector.getIn(['data', 'viewHtml']) || '',
      noRedirectInspectorOnMissing: inspector.get('noRedirectInspectorOnMissing') || false
    };
  }
);

export const inspectorRetestButtonSelector = createSelector(
  locationSelector,
  reportSelector,
  inpectorRestestMissionSelector,
  inspectorStateSelector,
  ({query, params}, report, retestMission, inspector) => {
    const reportRole = report.getIn(['ownPermissions', 'role']);
    const canRetestPages = report.getIn(['retestOptions', 'canRetestPages'], false);
    const hasRetestPermission = reportIsAllowed(ROLE_CONTRIBUTOR, reportRole);

    return {
      query,
      params,
      show: !!query.pageHash && canRetestPages && hasRetestPermission,
      label: retestMission && retestMission.get('status') === 'running' ? 'Retesting' : 'Retest',
      isLoading:
        inspector.get('retestingInspector') ||
        (retestMission &&
          (retestMission.get('status') === 'pending' || retestMission.get('status') === 'running'))
    };
  }
);

export const inspectorActionHeadingSelector = createSelector(
  actionSelector,
  categoriesSelector,
  categoryIdQuerySelector,
  (action, categories, categoryId) => {
    return {
      action,
      backCategory: categories.get(categoryId)
    };
  }
);

export const inspectorActionPointsSelector = createSelector(
  actionSelector,
  actionPointsSplitContextSelector,
  (action, splitPoints) => {
    return {
      action,
      points: splitPoints.get('points').toList(),
      contextPoints: splitPoints.get('contextPoints').toList()
    };
  }
);

export const inspectorActionPointSelector = createSelector(
  (_, props) => props.point,
  currentPointIdsSelector,
  makingDecisionsSelector,
  (point, currentPointIds, makingDecisions) => {
    const hasSelector = point.get('selectors') && !!point.get('selectors').size;
    const isMakingDecision = makingDecisions.includes(point.get('pointId'));
    return {
      hasSelector,
      isMakingDecision,
      isActive: currentPointIds.includes(point.get('pointId')) && hasSelector
    };
  }
);

export const inspectorCategoryHeadingSelector = createStructuredSelector({
  category: activeCategorySelector,
  parentCategory: activeParentCategorySelector
});

const activeActionChildrenSelector = createSelector(
  actionsSelector,
  categoryIdQuerySelector,
  actionFiltersSelector,
  categoryActionsSelector,
  (actions, categoryId, actionFilters, categoryActions) => {
    return actions
      .toList()
      .filter(filterActions([categoryId], actionFilters, categoryActions))
      .sort(sortActions);
  }
);

export const inspectorCategoryItemSelector = createSelector(
  filteredActionsByCategoryIdSelector,
  actions => {
    return {
      maxActions: 3,
      actions: actions.toList(),
      actionsCount: actions.size
    };
  }
);

// CLEAN ME
export const inspectorTopLevelCategoriesSelector = createSelector(
  sidebarLoadingSelector,
  categoriesSelector,
  categoryIdQuerySelector,
  actionIdQuerySelector,
  categoryActionsSelector,
  (isLoading, categories, categoryIdQuery, actionId, categoryActions) => {
    const topLevelCategories = categories
      .filter(category => category.get('parentCategoryId') == 0)
      .sortBy(category => category.get('sortIndex'))
      .toList();

    let categoryId;

    // if there is no categoryId in the query, we need to figure out what top category this actionId belongs to
    // (there could be multiple - in which case we always pick the first) TODO this needs discussing
    if (!categoryIdQuery) {
      const actionCategories = categoryActions.filter(
        categoryAction => categoryAction.get('actionId') === actionId
      );
      const categoryIds = actionCategories.map(actionCategory => actionCategory.get('categoryId'));

      const parentCategoryIds = categoryIds
        .map(categoryId => getTopParentCategory(categories, categoryId))
        .toSet(); // distinct

      categoryId = parentCategoryIds.first();
    } else {
      // if categoryId isn't a top level category
      if (
        !topLevelCategories.map(category => category.get('categoryId')).includes(categoryIdQuery)
      ) {
        // we have a category id, but it isn't a top level category, so we must find it's related top level
        categoryId = getTopParentCategory(categories, categoryIdQuery);
      } else {
        categoryId = categoryIdQuery;
      }
    }

    return {topLevelCategories, categoryId, isLoading};
  }
);

export const inspectorCategoryActionsSelector = createSelector(
  activeActionChildrenSelector,
  activeCategoryChildrenSelector,
  actionCountsByCategoryIdSelector,
  (actions, subCategories, actionCounts) => {
    return {
      actionCounts,
      actions: actions.toList(),
      categories: subCategories.toList()
    };
  }
);

// NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
//
// export const inspectorFiltersSelector = createSelector(
//   actionFiltersSelector,
//   actionsSelector,
//   categoryActionsSelector,
//   currentChildrenCategoryIdsSelector,
//   actionCountsByCategoryIdSelector,
//   (actionFilters, allActions, categoryActions, categoryIds, actionCounts) => {
//     const filterCounts = {
//       role: {
//         editor: allActions.filter(
//           filterActions(categoryIds, Map({role: ['editor']}), categoryActions)
//         ).size,
//         designer: allActions.filter(
//           filterActions(categoryIds, Map({role: ['designer']}), categoryActions)
//         ).size,
//         developer: allActions.filter(
//           filterActions(categoryIds, Map({role: ['developer']}), categoryActions)
//         ).size
//       },
//       severity: {
//         errors: allActions.filter(
//           filterActions(categoryIds, Map({severity: ['errors']}), categoryActions)
//         ).size,
//         warnings: allActions.filter(
//           filterActions(categoryIds, Map({severity: ['warnings']}), categoryActions)
//         ).size,
//         notices: allActions.filter(
//           filterActions(categoryIds, Map({severity: ['notices']}), categoryActions)
//         ).size
//       }
//     };

//     return {
//       actionFilters,
//       filterCounts,
//       actionCounts
//     };
//   }
// );

export const inspectorActionNextStepsSelector = createSelector(
  hasQueryContextSelector,
  inspectorContextDataSelector,
  actionsSelector,
  categoriesSelector,
  categoryActionsSelector,
  (hasContext, context, actions, categories, categoryActions) => {
    const allCategoryIds = categories
      .map(category => category.get('categoryId'))
      .toList()
      .toJS();
    const filteredActions = actions.filter(filterActions(allCategoryIds, Map(), categoryActions));
    const showNextPage = hasContext && context && context.pageNumber + 1 < context.numPages;
    return {
      showNextPage,
      openActionsOnPageCount: filteredActions.size
    };
  }
);

const sortActions = (a, b) => {
  if (a.get('priority') == b.get('priority')) {
    if (a.get('severity') == b.get('severity')) {
      return a.get('sortIndex') < b.get('sortIndex') ? -1 : 1;
    }
    return a.get('severity') < b.get('severity') ? 1 : -1;
  }
  return a.get('priority') < b.get('priority') ? 1 : -1;
};

const SeverityMap = {
  errors: [4, 5],
  warnings: [3],
  notices: [1, 2]
};

// This function maps an array of SeverityMap keys to SeverityMap values
// `severityFilters` will be a List of 'errors', 'warnings', or 'notices'
function getMappedSeverity(severityFilters) {
  return severityFilters.reduce(
    (result, severityKey) => result.concat(SeverityMap[severityKey]),
    []
  );
}

const filterActions = (categoryIds, actionFilters, categoryActions) => action => {
  // leave early if action is not open
  if (action.get('state') !== 'open') return false;

  // check UI filters
  const roleFilters = actionFilters.get('role', []);
  const severityFilters = getMappedSeverity(actionFilters.get('severity', []));

  // true if empty array, or if array includes action properties
  const doesRoleMatch = !roleFilters.length || roleFilters.includes(action.get('responsibility'));
  const doesSeverityMatch =
    !severityFilters.length || severityFilters.includes(action.get('priority'));

  // leave early if filters fail
  if (!doesRoleMatch || !doesSeverityMatch) return false;

  // filter action if it has no points
  if (action.get('pointsCount') === 0) return false;

  // get an array of categoryIds for *this* action
  const actionCategories = categoryActions
    .filter(categoryAction => categoryAction.get('actionId') === action.get('actionId'))
    .map(categoryAction => categoryAction.get('categoryId'));

  // return true if action is in these categoryIds
  return categoryIds.some(categoryId => actionCategories.includes(categoryId));
};

function getTopParentCategory(categories, categoryId) {
  const parentCategoryId = categories.getIn([categoryId, 'parentCategoryId']);

  if (!parentCategoryId) {
    return categoryId;
  }
  return getTopParentCategory(categories, parentCategoryId);
}

function getChildrenCategoryIds(categories, categoryId) {
  const allChildIds = [];
  const directChildCategoryIds = getCategoryIdsWithParentId(categories, categoryId);

  directChildCategoryIds.forEach(subcategoryId => {
    allChildIds.push(...getChildrenCategoryIds(categories, subcategoryId));
  });

  return [categoryId, ...allChildIds];
}

function getCategoriesWithParentId(categories, parentCategoryId) {
  return categories.filter(category => {
    return category.get('parentCategoryId') === parentCategoryId;
  });
}

function getCategoryIdsWithParentId(categories, parentCategoryId) {
  return getCategoriesWithParentId(categories, parentCategoryId)
    .map(category => category.get('categoryId'))
    .toList()
    .toJS();
}

function pointMatchesContext(point, context) {
  return context.reduce((last, value, key) => {
    return last && point.get(key) == value;
  }, true);
}
