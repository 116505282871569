import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/spinner';
import Chart from 'components/chart';
import {formatGivenNumber} from 'utils/numberUtils';
import GraphUtils, {highchartsConfig} from 'utils/graphUtils';

// Extra config that turns a chart into a 'sparkline'
const SPARKLINE_CONFIG = {
  chart: {
    margin: [10, -7, 0, -7]
  },
  xAxis: {
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    labels: {
      enabled: false
    },
    minorTickLength: 0,
    tickLength: 0
  },
  yAxis: {
    title: {text: ''},
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    gridLineColor: 'transparent',
    labels: {
      enabled: false
    },
    minorTickLength: 0,
    tickLength: 0
  }
};

class LineChart extends React.Component {
  static defaultProps = {
    decimals: 0,
    palette: ['#A2C849', '#C6C6C6', '#EDD457', '#fc6851', '#54cbc7']
  };

  static propTypes = {
    results: PropTypes.object,
    decimals: PropTypes.number,
    palette: PropTypes.array
  };

  render() {
    const {results} = this.props;

    if (!results || _.isEmpty(results)) {
      return <Spinner centered />;
    }

    return <Chart config={getLinegraphOptions(this.props, this.state)} />;
  }
}

LineChart.propTypes = {
  results: PropTypes.object,
  graphTitle: PropTypes.string
};

export default LineChart;

function getLinegraphOptions({
  title,
  height,
  width,
  max,
  min,
  decimals,
  invertY,
  hasLegend,
  palette,
  yaxisLabel,
  showPercentSign,
  sparkline,
  line,
  backgroundColor,
  yLabelFormat,
  results
}) {
  const chart = {
    type: line ? 'line' : invertY ? 'line' : 'area',
    backgroundColor: backgroundColor || 'transparent',
    styledMode: false
  };

  if (width) chart.width = width;
  if (height) chart.height = height;

  // Crude approximation (checking for int keys) to see if we only have a single result set.
  const singleResultTypeof = typeof results[Object.keys(results)[0]];
  const singleResult = singleResultTypeof === 'number' || results.values !== undefined;

  if (singleResult) {
    results = {series: results};
  }

  const resultsSeries = GraphUtils.lineResultsToSeries('', results);
  const [dataMin, dataMax] = GraphUtils.getDataRange(resultsSeries);
  const ticks = GraphUtils.getTickCount(min || Math.min(dataMin, 0), max || dataMax);

  const options = {
    chart,
    legend: !hasLegend ? false : {},
    colors: palette,
    series: resultsSeries,
    title: {text: title},
    tooltip: {
      headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
      pointFormat: `<b>${!singleResult ? '{series.name}: ' : ''}{point.y:,.${decimals}f}${
        showPercentSign ? '%' : ''
      }</b>`
    },
    xAxis: {
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    },
    yAxis: {
      max: max || dataMax + (sparkline ? 1 : 0),
      min: min || dataMin,
      valueDecimals: decimals,
      title: {text: yaxisLabel},
      tickAmount: ticks,
      labels: {
        style: {
          fontSize: '13px'
        },

        formatter() {
          if (showPercentSign) {
            return this.value + '%';
          }

          if (yLabelFormat && yLabelFormat === 'number') {
            return formatGivenNumber(this.value);
          }
          return this.value;
        }
      },
      reversed: invertY,
      startOnTick: !min // If min is specified, force it
    }
  };

  // Temporary fix for anything trying to be 1 - 100
  if (parseInt(min, 10) === 1 && parseInt(max, 10) === 100) {
    options.yAxis.tickAmount = null;
    options.yAxis.tickInterval = 10;
  }

  const data = highchartsConfig(options);

  if (sparkline) {
    _.merge(data, SPARKLINE_CONFIG);
  }

  return data;
}
