import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'components/helmet';
import PageBody from 'components/page';
import Button from 'components/button';
import Spinner from 'components/spinner';
import styles from './servicesSettingsPage.scss';
import {requestServices, clearServices} from 'modules/services/servicesActions';
import {productBuyPath} from 'modules/superProduct/superProductUtils';
import {servicesSettingsPageSelector} from 'modules/settings/settingsSelectors';
import ServiceBreakdown from 'modules/services/components/serviceBreakdown';
import Status from 'components/status';
import {push} from 'modules/location';
import MaxWidth from 'components/maxWidth';

export class ServicesSettingsPage extends React.Component {
  componentDidMount() {
    this.props.requestServices();
  }

  componentWillUnmount() {
    this.props.clearServices();
  }

  handleClickAddService = () => {
    this.props.push(productBuyPath);
  };

  renderNoServicesWarning() {
    return (
      <Status appearance="bad">
        <p>
          There are no currently active services for this account. Contact support to add more
          services.
        </p>
      </Status>
    );

    // return (
    //   <Status appearance="bad">
    //     <p>There are no currently active services for this account. <a href="#" onClick={this.handleClickAddService}>Add a service</a> now to get started.</p>
    //   </Status>
    // )
  }

  renderLoadingErrorWarning() {
    return (
      <Status appearance="bad">
        <p>
          There was an error trying to load your services. Please try again. If the problem
          persists, try contacting support.
        </p>
      </Status>
    );
  }

  renderServicesInformation() {
    const {isLoading, loadingError, services} = this.props;

    if (isLoading) {
      return <Spinner centered />;
    }

    if (loadingError) {
      return this.renderLoadingErrorWarning();
    }

    if (!services || !Object.keys(services).length) {
      return this.renderNoServicesWarning();
    }

    return Object.values(services).map(service => (
      <ServiceBreakdown key={service.serviceId} service={service} />
    ));
  }

  render() {
    return (
      <MaxWidth>
        <Helmet title="Services" />

        <div className={styles.header}>
          <h3>Services</h3>
          {/* <Button icon="plus" iconSide="left" onClick={this.handleClickAddService}>Add service</Button> */}
        </div>

        <PageBody>
          <div className={styles.productsWrapper}>{this.renderServicesInformation()}</div>
        </PageBody>
      </MaxWidth>
    );
  }
}

export default connect(
  servicesSettingsPageSelector,
  {requestServices, clearServices, push}
)(ServicesSettingsPage);
