import React from 'react';

export default class PageDepth extends React.Component {
  render() {
    const {data} = this.props;

    if (!data) {
      return <span>?</span>;
    }
    if (data === -1) {
      return <span style={{color: '#d00'}}>Not indexable</span>;
    }

    return <span>{data}</span>;
  }
}
