exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3jMXdMvo17CrCaCoq-cS0q{padding:0}.WVDFH4MoZ_ou1cAZRjjiq{display:flex;flex-wrap:wrap;align-items:center;margin-bottom:20px}._20TIq5ueTZ6WglTD1uZSAd{flex:1 0 250px}.tJ4wJPaFGxeTcirEGoTAv{flex:0 0 100px}._2PEoPnyZAN33xb3-ShdxRl{display:flex;font-size:27px;color:#333;align-items:center;margin:0}._1hZffZ-232yMjt6EqVB8vS{width:45px;font-size:22px}._2bhxU2aSO8RM2KEAZLHsvB{flex:1}._3KtSWRsZj4wOtdsAtX8zNp{display:block;color:#737373;font-weight:bold;margin-top:4px;font-size:16px}._3-m_pgGkq57qzTfHa5s2Xk{opacity:.4}._1mbf6ftMAicjkrTOJMn35c{background:#fff;border:1px solid #ddd;box-shadow:0 2px 5px rgba(0,0,0,.1);margin-bottom:10px}._6QcD3yEeMY1PwZ-0RIBx9{padding:15px;display:flex;flex-wrap:wrap}._1VNEadL8Y2AJ09qQt_W41B{flex:1 0 700px;max-width:100%;margin:5px}.NNRofiJEwWtZxu4RVIDIf{max-width:700px}.NNRofiJEwWtZxu4RVIDIf p:last-of-type{margin-bottom:0}._3SgcLe7tdwD_0TRr0CLikK{flex:1 0 200px;margin:5px;max-width:300px}@media(max-width: 768px){._3SgcLe7tdwD_0TRr0CLikK{max-width:none}}._1XqQmg4T6Qjf-tx-uPFkOt{font-size:16px;margin-top:-14px;margin-bottom:14px;margin-left:45px;opacity:.6}.BTlObJjkHLwOA0XE984vu{border-top:1px solid #ddd;display:flex;flex-wrap:wrap}._2egcw6QsTFCFMsNBZoc2Yu{flex:1;padding:12px 20px 15px;border-right:1px solid #ddd;display:flex;align-items:center}._2egcw6QsTFCFMsNBZoc2Yu h3{font-size:15px;font-weight:bold;margin:0 15px 0 0}._2egcw6QsTFCFMsNBZoc2Yu p{margin:1px 0 0 0}@media print{._1mbf6ftMAicjkrTOJMn35c{box-shadow:none}.tJ4wJPaFGxeTcirEGoTAv button{display:none}}", ""]);

// exports
exports.locals = {
	"ActionHeader": "_3jMXdMvo17CrCaCoq-cS0q",
	"headingWrapper": "WVDFH4MoZ_ou1cAZRjjiq",
	"headingLeft": "_20TIq5ueTZ6WglTD1uZSAd",
	"headingRight": "tJ4wJPaFGxeTcirEGoTAv",
	"heading": "_2PEoPnyZAN33xb3-ShdxRl",
	"priority": "_1hZffZ-232yMjt6EqVB8vS",
	"name": "_2bhxU2aSO8RM2KEAZLHsvB",
	"subName": "_3KtSWRsZj4wOtdsAtX8zNp",
	"actionId": "_3-m_pgGkq57qzTfHa5s2Xk",
	"panel": "_1mbf6ftMAicjkrTOJMn35c",
	"content": "_6QcD3yEeMY1PwZ-0RIBx9",
	"left": "_1VNEadL8Y2AJ09qQt_W41B",
	"description": "NNRofiJEwWtZxu4RVIDIf",
	"right": "_3SgcLe7tdwD_0TRr0CLikK",
	"affectsTests": "_1XqQmg4T6Qjf-tx-uPFkOt",
	"meta": "BTlObJjkHLwOA0XE984vu",
	"metaPoint": "_2egcw6QsTFCFMsNBZoc2Yu"
};