import cx from 'classnames';
import AnimateHeightChange from 'components/animation/animateHeightChange';
import Icon from 'components/font-awesome';
import Overlay from 'components/overlay';
import React from 'react';
import styles from './modal.scss';

// Note: to create a modal, you probably want
// the ModalContent component. This Modal wrapper is automatically
// added by our ModalContainer.
export default class Modal extends React.Component {
  static defaultProps = {
    dismissable: true
  };

  render() {
    const {
      onClose,
      huge,
      gradientBackground,
      dismissable,
      wide,
      wider,
      style,
      onClickOutside
    } = this.props;

    const classes = cx(styles.modal, {
      [styles.wide]: wide,
      [styles.huge]: huge,
      [styles.wider]: wider,
      [styles.gradientBackground]: gradientBackground
    });

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        huge,
        gradientBackground
      })
    );

    // This is a bit nasty as it means we don't animate when going between huge
    // and small modals but there isn't an easy way to do that
    const children = huge ? (
      childrenWithProps
    ) : (
      <div className={styles.animationWrapper}>{childrenWithProps}</div>
    );

    return (
      <Overlay
        center
        className={styles.overlay}
        showClose={dismissable}
        showCloseScrollLimit={75}
        onClickClose={onClose}
        onSelfClick={onClickOutside}
      >
        <div id="modal" className={classes} style={style}>
          {dismissable && (
            <Icon name="times" title="close" onClick={onClose} className={styles.closeButton} />
          )}
          {children}
        </div>
      </Overlay>
    );
  }
}
