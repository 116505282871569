import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatGivenNumber} from 'utils/numberUtils';
import {setNumPagesChoice} from 'modules/products';

import styles from './businessPlanChooser.scss';
import sharedStyles from 'modules/products/components/planChooserShared.scss';
import Button from 'components/button';
import ContactUsButton from 'components/button/contactUsButton';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import cx from 'classnames';
import {nextStep} from 'modules/onboarding';
import TaxExplainer from 'components/legal/taxExplainer';

const INITIAL_SLIDER_VALUE = 1; // corresponds to 100 pages
const SLIDER_MIN_MAX = [0, 20];
const SLIDER_STEPS = [
  50,
  100,
  150,
  250,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  2000,
  3000,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000
];

export class BusinessPlanChooser extends React.Component {
  static defaultProps = {
    productChoices: {}
  };

  static propTypes = {
    plans: PropTypes.array.isRequired
  };

  handleChange = sliderValue => {
    this.props.setNumPagesChoice(sliderValue);
  };

  getAppropriatePlan = () => {
    const {plans} = this.props;
    const numberOfPages = this.getPages();

    plans.sort((a, b) => a.size - b.size);

    return plans.find(plan => plan.size >= numberOfPages);
  };

  getPages = () => {
    const pageSliderValue = this.getPageSliderValue();

    return SLIDER_STEPS[pageSliderValue];
  };

  getPageSliderValue = () => {
    const {
      productChoices: {pageSliderValue = INITIAL_SLIDER_VALUE}
    } = this.props;

    return pageSliderValue;
  };

  handleClickVatInfo = () => {
    this.setState({showVatInfo: !this.state.showVatInfo});
  };

  handleClickBuy = planId => event => {
    this.props.nextStep(planId);
  };

  render() {
    const {plans} = this.props;
    const [min, max] = SLIDER_MIN_MAX;

    const pageSliderValue = this.getPageSliderValue();
    const numberOfPages = this.getPages();
    const appropriatePlan = this.getAppropriatePlan();

    return (
      <div className={sharedStyles.wrapper}>
        <h2 className={sharedStyles.title}>
          I want to monitor a website with
          <span className={styles.pageCount}>
            {formatGivenNumber(numberOfPages)}
            {pageSliderValue === max ? '+' : ''}
          </span>
          pages
        </h2>
        <div className={styles.sliderWrapper}>
          <Slider
            defaultValue={pageSliderValue}
            min={min}
            max={max}
            step={1}
            onChange={this.handleChange}
            trackStyle={{backgroundColor: '#3022AD', height: 10, opacity: 0.5}}
            railStyle={{height: 10, backgroundColor: '#d8d8d8'}}
            handleStyle={{
              borderColor: '#3022AD',
              backgroundColor: '#3022AD',
              height: 36,
              width: 36,
              marginLeft: -18,
              marginTop: -12
            }}
          />
        </div>
        {appropriatePlan && (
          <Button
            id="submitBuyBusinessPlan"
            onClick={this.handleClickBuy(appropriatePlan.planId)}
            cta
            large
            className={styles.buyBtn}
          >
            Buy for ${appropriatePlan.price} / month
          </Button>
        )}
        {!appropriatePlan && (
          <div>
            <ContactUsButton cta large className={styles.buyBtn}>
              Contact us for pricing
            </ContactUsButton>
          </div>
        )}

        <TaxExplainer />
      </div>
    );
  }
}

export default connect(
  null,
  {nextStep, setNumPagesChoice}
)(BusinessPlanChooser);
