export const testChannel = ({testId}) => {
  return `test${testId}`;
};
export const userChannel = ({userId}) => {
  return {literal: `user${userId}`};
};

export const reportChannel = ({params: {reportId}}) => {
  return `report${reportId}`;
};

export const missionChannel = ({reportId}) => {
  return `${reportId}-mission`;
};

export const inspectorChannel = ({testId, pageHash}) => {
  return `test${testId}-pageHash${pageHash}`;
};

export const globalListenerChannel = () => {
  return {literal: `global`};
};

export const pageChannel = ({pageHash}) => {
  return {literal: `page-${pageHash}`};
};
