exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3HxAQ8IgnYbLmuHFnDDUKy{background:#fff;border-radius:4px;border:none}._3HxAQ8IgnYbLmuHFnDDUKy figcaption{min-height:80px}._3HxAQ8IgnYbLmuHFnDDUKy div:last-child{background:#fff;color:#000}._3HxAQ8IgnYbLmuHFnDDUKy div:last-child figcaption span{color:#000}._3HxAQ8IgnYbLmuHFnDDUKy:hover{box-shadow:none}._3HxAQ8IgnYbLmuHFnDDUKy ._20tAsDMXnRySQOBklwK0_A{padding:10px 20px}._3HxAQ8IgnYbLmuHFnDDUKy ._2SB7xjBwThEE6L6TfmapqR{display:flex;justify-content:center}._3HxAQ8IgnYbLmuHFnDDUKy ._2SB7xjBwThEE6L6TfmapqR p{flex:1 1 auto}._3HxAQ8IgnYbLmuHFnDDUKy ._36V5hdnykqUtcDIxNpwu14{white-space:normal}._3HxAQ8IgnYbLmuHFnDDUKy ._36V5hdnykqUtcDIxNpwu14 .bm_dmSHhP3v3MtxvAlnvi{font-size:14px;display:inline-block;padding:2px 6px;border:1px solid #b0d0c8;background-color:#d0f8e8;color:#155724}._3HxAQ8IgnYbLmuHFnDDUKy ._36V5hdnykqUtcDIxNpwu14 ._3znzy3CprEN3Us7rgCf1L5{font-size:14px;display:inline-block;padding:2px 6px;border:1px solid #dfd3ad;background-color:#fff3cd;color:#856404}", ""]);

// exports
exports.locals = {
	"courseCard": "_3HxAQ8IgnYbLmuHFnDDUKy",
	"content": "_20tAsDMXnRySQOBklwK0_A",
	"progress": "_2SB7xjBwThEE6L6TfmapqR",
	"title": "_36V5hdnykqUtcDIxNpwu14",
	"completed": "bm_dmSHhP3v3MtxvAlnvi",
	"draft": "_3znzy3CprEN3Us7rgCf1L5"
};