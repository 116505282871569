import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REMOVE_ROLE, roleRemoved} from 'modules/reportPermissions';
import {reportPermissionEndpoint} from 'modules/reportPermissions/reportPermissionUtils';
import {couldNot} from 'utils/errorUtils';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';
import {showSuccess} from 'utils/saga/alerts';

export default function* removeRoleSaga() {
  yield takeLatest(REMOVE_ROLE, removeRole);
}

function* removeRole({reportId, userGroupId}) {
  yield processRequest('DELETE', reportPermissionEndpoint({reportId, userGroupId}), {
    success: function*() {
      yield put(roleRemoved(reportId, userGroupId));

      yield showSuccess(SUCCESS_MESSAGE);
    },
    errorMessage: couldNot('update permissions')
  });
}
