var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2qXo4S8bCd6ZE-9gfm0_Zh{height:20px;width:20px}._2qXo4S8bCd6ZE-9gfm0_Zh div[tabindex=\"0\"]{background-size:cover;background-position:center center;background-image:url(" + escape(require("./../images/slider-icon.png")) + ");box-shadow:0px 0px 5px rgba(0,0,0,.1);border:1px solid #fff;top:8px;-webkit-transform:scale(1.8);transform:scale(1.8)}._2qXo4S8bCd6ZE-9gfm0_Zh div[tabindex=\"0\"]:hover{border:1px solid #fff;box-shadow:0px 0px 5px rgba(0,0,0,.1)}._2qXo4S8bCd6ZE-9gfm0_Zh div[tabindex=\"0\"]:focus{border:1px solid #fff;box-shadow:0px 0px 5px rgba(0,0,0,.1)}", ""]);

// exports
exports.locals = {
	"customHandle": "_2qXo4S8bCd6ZE-9gfm0_Zh"
};