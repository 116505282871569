import {Map, List, fromJS} from 'immutable';

const initialState = Map();

// this just stores which requirementIds are loaded
export default function requirementsReducer(state = initialState, action) {
  const {type} = action;

  switch (type) {
    case RECEIVE_REQUIREMENTS:
      const {type: requirementId, ...requirements} = action.requirements;

      return state.set(requirementId, fromJS(requirements));
  }

  return state;
}

export const RECEIVE_REQUIREMENTS = 'requirements:RECEIVE_REQUIREMENTS';
export function receiveRequirements(requirements) {
  return {
    type: RECEIVE_REQUIREMENTS,
    requirements
  };
}

export const FETCH_REQUIREMENTS = 'requirements:FETCH_REQUIREMENTS';
export function fetchRequirements(testType) {
  return {
    type: FETCH_REQUIREMENTS,
    testType
  };
}
