import {reportSettingsPath} from 'modules/reports/reportUtils';

export const DEFAULT_CONTACT_OPTIONS = {
  email: true,
  phoneNumber: false
};

export function uptimeMonitorPath({monitorId = 'new'}) {
  return reportSettingsPath(...arguments) + '/uptime/' + monitorId;
}

export function uptimeMonitorsEndpoint({reportId}) {
  return `uptime-monitors/${reportId}`;
}

export function uptimeMonitorEndpoint(params) {
  return uptimeMonitorsEndpoint(params) + `/${params.monitorId}`;
}

export function uptimeMonitorContactsEndpoint({reportId, monitorId}) {
  return `uptime-monitors/${reportId}/${monitorId}/contacts`;
}

export function uptimeMonitorContactEndpoint({reportId, monitorId, userId}) {
  return `uptime-monitors/${reportId}/${monitorId}/contacts/${userId}`;
}

export function canAddUptimeMonitorsEndpoint() {
  return uptimeMonitorsEndpoint(...arguments) + '/can-add';
}
