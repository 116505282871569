import React from 'react';
import PageWithFixed from './pageWithFixed';
import styles from 'components/nav/navbar.scss';

export default class PageWithHeader extends React.Component {
  render() {
    const {header, children, className} = this.props;

    return (
      <PageWithFixed
        className={className}
        orientation="vertical"
        fixedElement={header}
        fixedSize={styles.mainHeader}
      >
        {children}
      </PageWithFixed>
    );
  }
}
