import {select, put, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {fromJS} from 'immutable';
import {couldNot} from 'utils/errorUtils';
import {UPDATE_CHECKPOINT} from 'modules/checkpoints';
import {receiveResults} from 'modules/results';
import {updateCheckpointEndpoint} from 'modules/checkpoints/checkpointUtils';
import {resultSelector} from 'modules/results/resultSelectors';

export default function* updateUserSaga() {
  yield takeEvery(UPDATE_CHECKPOINT, updateCheckpoint);
}

function* updateCheckpoint({checkpoint}) {
  yield processRequest(
    'PUT',
    updateCheckpointEndpoint(),
    {
      success: function*(data) {
        // Annoyingly, due to the way checkpoints are stored - to update
        // our local checkpoint, we have to find the "checkpoints" result
        // for the current test, find this checkpoint inside of it,
        // and update it
        const checkpointsResult = yield select(resultSelector, {
          resultId: 'checkpoints'
        });
        const checkpoints = checkpointsResult.get('value');
        const updatedCheckpoints = checkpoints.set(
          checkpoints.findIndex(checkpoint => checkpoint.get('checkpointId') == data.checkpointId),
          fromJS(data)
        );

        const updatedResult = checkpointsResult.set('value', updatedCheckpoints).toJS();

        yield put(receiveResults([updatedResult]));
      },

      errorMessage: couldNot('update checkpoint')
    },
    checkpoint
  );
}
