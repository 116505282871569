import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {viewReportIndexPage} from 'modules/reports';
import Spinner from 'components/spinner';

export class ReportIndexPage extends React.Component {
  componentDidMount() {
    this.props.viewReportIndexPage(this.props.match.params.reportId);
  }

  render() {
    return <Spinner marginTop={100} />;
  }
}

export default connect(
  null,
  {viewReportIndexPage}
)(ReportIndexPage);
