import {Map, OrderedMap, fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  RECEIVE_TAX,
  PRODUCTS_ERROR,
  RECEIVE_GEOLOCATION,
  SET_NUM_PAGES_CHOICE,
  RECEIVE_STRIPE_TOKEN,
  RECEIVE_STRIPE_ERROR,
  ORDER_COMPLETE,
  PAY_ERROR,
  CLEAR_ERROR,
  SELECT_CURRENCY,
  CHOOSE_PRODUCT_SIZE,
  SHOW_PRODUCTS
} from './products';

const initialState = Map({
  basket: Map(),
  data: OrderedMap(),
  productChoices: Map(), // keeps track of product choices during checkout (so we can skip back/forward)
  stripeToken: null,
  stripeError: null,
  dataIsLoading: null,
  dataError: null,
  selectedCurrency: null,
  tax: Map(),
  serverError: '',
  noProducts: false
});

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      var loadingState = setDataLoadingState(state, true, null).set('noProducts', false);

      if (action.clearExisting) {
        return loadingState.set('data', OrderedMap());
      }
      return loadingState;

    case RECEIVE_PRODUCTS:
      return receiveProducts(state, action.products);
    case PRODUCTS_ERROR:
      return setDataLoadingState(state, false, action.error);

    case RECEIVE_TAX:
      return state.set('tax', fromJS(action.tax));

    case SET_NUM_PAGES_CHOICE:
      return state.setIn(['productChoices', 'pageSliderValue'], action.pageSliderValue);

    case PAY_ERROR:
      return state.set('serverError', action.message);

    case CLEAR_ERROR:
      return state.set('serverError', '');

    case RECEIVE_STRIPE_ERROR:
      return state.set('stripeError', action.error);

    case RECEIVE_STRIPE_TOKEN:
      return state.set('stripeToken', action.token);

    case ORDER_COMPLETE:
      return state.merge({
        productChoices: Map(),
        stripeError: null,
        stripeToken: null
      });

    case SHOW_PRODUCTS:
      return state.merge({
        stripeError: null,
        stripeToken: null
      });
  }

  return state;
}

function receiveProducts(state, products) {
  if (!products.length) {
    return setDataLoadingState(state, false, null).set('noProducts', true);
  }
  return setDataLoadingState(state, false, null).set(
    'data',
    keyBy(products, 'planId', OrderedMap())
  );
}

function useVATlessPrices(state) {
  const products = state.get('data');
  const prices = products.map((product, productName) => createVATlessPrice(product.get('price')));
  return state.set('prices', fromJS(prices));
}

function createVATlessPrice(price) {
  return {
    vat: 0,
    exVatPrice: price,
    incVatPrice: price,
    vatRate: 0
  };
}

function setDataLoadingState(state, loading, error) {
  return state.set('dataIsLoading', loading).set('dataError', error);
}

function setPricesLoadingState(state, loading, error) {
  return state.set('pricesAreLoading', loading).set('pricesError', error);
}
