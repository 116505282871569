import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'components/datePicker';

// This datepicker normalises dates to a unix timestamp.
// It expects unix timestamp and passes a unix timestamp onChange.
export default class DateInput extends React.Component {
  static defaultProps = {
    inputFormat: 'X',
    displayFormat: 'dd/MM/yyyy'
  };

  static propTypes = {
    // value: PropTypes.number,
    inputFormat: PropTypes.string,
    displayFormat: PropTypes.string
  };

  handleChange = value => {
    const timestamp = Math.floor(value.getTime() / 1000);
    const {onChange} = this.props;
    onChange && onChange(timestamp);
  };

  render() {
    const {value, displayFormat, ...props} = this.props;
    let selected;

    if (value instanceof Date) {
      selected = value;
    } else if (typeof value === 'number') {
      selected = new Date(value * 1000);
    } else {
      selected = null;
    }

    return (
      <div>
        <DatePicker
          dateFormat={displayFormat}
          onChange={this.handleChange}
          {...props}
          selected={selected}
        />
      </div>
    );
  }
}
