import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import TextareaInput from './textareaInput';

export default class TextareaUrlsInput extends React.Component {
  static defaultProps = {
    spellcheck: false,
    value: ''
  };

  state = {
    value: ''
  };

  componentWillMount() {
    this.setState({value: this.props.value});
  }

  handleBlur = event => {
    const {value, onBlur, onChange} = this.props;

    onBlur && onBlur(event);

    if (this.props.value[value.length - 1] === ',') {
      onChange && onChange(this.getValue(value));
    }
  };

  handleChange = event => {
    let {value} = event.target;

    if (value[value.length - 1] === '\n') {
      if (value[value.length - 2] === '\n') {
        value = value.slice(0, -1);
      } else {
        value = this.getValue(value) + '\n';
      }
    } else {
      value = this.getValue(value);
    }
    this.props.onChange(value);
  };

  getValue(value) {
    const hasEndComma = value[value.length - 1] === ',';
    if (value.indexOf('\n') < 0) {
      return (
        value
          .split(',')
          .map(part => part.trim())
          .filter(part => part !== '')
          .join('\n') + (hasEndComma ? '\n' : '')
      );
    }

    // for each part, check if it has a comma, if so split the url into two
    return (
      value
        .split('\n')
        .reduce((urls, url) => {
          if (url.indexOf(',') < 0) {
            urls.push(url.trim());
          } else {
            const splitUrls = url
              .split(',')
              .map(part => part.trim())
              .filter(part => part !== '');

            urls.push(...splitUrls);
          }
          return urls;
        }, [])
        .map(part => part.trim())
        .filter(part => part !== '')
        .join('\n') + (hasEndComma ? '\n' : '')
    );
  }

  render() {
    return (
      <TextareaInput
        {...this.props}
        // onInput={this.handleInput}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }
}
