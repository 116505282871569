exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1bbzu1xHOyBjd7_JsqkRFk{margin-bottom:1em}._1Ifxo45Xkb_RFXr7mEQKdK{padding:0 10px;margin-bottom:25px;background:#fff;border:1px solid #ddd}._1bK7EO38NeDgbLZ0WVEu1A{margin:1em 0;padding:30px;text-align:center}", ""]);

// exports
exports.locals = {
	"checkpoints": "_1bbzu1xHOyBjd7_JsqkRFk",
	"panel": "_1Ifxo45Xkb_RFXr7mEQKdK",
	"empty": "_1bK7EO38NeDgbLZ0WVEu1A"
};