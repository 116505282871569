import React from 'react';
import PropTypes from 'prop-types';
import PaginationPage from './paginationPage';
import cx from 'classnames';
import styles from './pagination.scss';

const MAX_LINKS = 9;

export default class Pagination extends React.Component {
  static propTypes = {
    page: PropTypes.number,
    numRows: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    maxPage: PropTypes.number
  };

  static defaultProps = {
    page: 1,
    maxPage: 1
  };

  buildPage = (pageNum, onPageSelect, isActive) => (
    <PaginationPage
      isActive={isActive}
      key={'page_' + pageNum}
      text={pageNum.toString()}
      onClick={() => onPageSelect(pageNum)}
    />
  );

  buildPages(startCounter, endCounter) {
    const {numRows, rowsPerPage, maxPage, onPageSelect} = this.props;
    const page = this.getCurrentPage();
    const pages = [];

    for (let i = startCounter; i <= endCounter; i++) {
      const isActive = i === page;
      pages.push(this.buildPage(i, onPageSelect, isActive));
    }
    return pages;
  }

  calculateStartConter = () => {
    const {maxPage} = this.props;
    const page = this.getCurrentPage();

    const pagesEachSide = this.getPagesEachSide();

    if (page > pagesEachSide + 1 && maxPage >= MAX_LINKS) {
      if (page + pagesEachSide > maxPage) {
        return maxPage - (MAX_LINKS - 1);
      }
      return page - pagesEachSide;
    }

    return 1;
  };

  calculateEndCounter = () => {
    const {maxPage} = this.props;
    const page = this.getCurrentPage();

    const pagesEachSide = this.getPagesEachSide();

    if (page + pagesEachSide < maxPage && maxPage >= MAX_LINKS) {
      if (page + pagesEachSide < MAX_LINKS) {
        return MAX_LINKS;
      }
      return page + pagesEachSide;
    }

    return maxPage;
  };

  getCurrentPage = () => {
    const {page, maxPage} = this.props;

    if (page > maxPage) {
      return 1;
    }

    return page;
  };

  getPagesEachSide() {
    return Math.floor(MAX_LINKS / 2);
  }

  render() {
    const {maxPage, onPageSelect, inline, marginTop, marginBottom} = this.props;

    if (maxPage == 1) return null;

    const page = this.getCurrentPage();

    const startCounter = this.calculateStartConter();
    const endCounter = this.calculateEndCounter();

    const pages = this.buildPages(startCounter, endCounter);

    const skipBackwardsPage = startCounter > 1 ? startCounter - 1 : 1;
    const skipForwardsPage = endCounter < maxPage ? endCounter + 1 : maxPage;

    const pagesEachSide = this.getPagesEachSide();

    return (
      <ul
        className={cx(styles.container, {
          [styles.inline]: inline,
          [styles.marginTop]: marginTop,
          [styles.marginBottom]: marginBottom
        })}
      >
        {page > pagesEachSide + 1 && maxPage > MAX_LINKS && (
          <PaginationPage text="<" onClick={() => onPageSelect(skipBackwardsPage)} />
        )}
        {pages}
        {page + (pagesEachSide + 1) <= maxPage && maxPage > MAX_LINKS && (
          <PaginationPage text=">" onClick={() => onPageSelect(skipForwardsPage)} />
        )}
      </ul>
    );
  }
}
