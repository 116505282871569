const CMS_OPTIONS = {
  Wordpress: {
    downloadLink: 'https://s3.amazonaws.com/assets.silktide.com/plugins/silktide.zip',
    helpLink: 'https://support.silktide.com/guides/cms-install-wordpress-guide/'
  },
  Craft: {
    downloadLink: '',
    helpLink: 'https://support.silktide.com/guides/cms-install-craft3-guide/'
  },
  Drupal: {
    downloadLink: '',
    helpLink: 'https://support.silktide.com/guides/cms-install-drupal-guide/'
  },
  Umbraco: {
    downloadLink: '',
    helpLink: 'https://support.silktide.com/guides/cms-install-umbraco-guide/'
  }
};

export function isSupportedCMS(cmsName) {
  return CMS_OPTIONS[cmsName] !== undefined;
}

export function getCMSDownloadLink(cmsName) {
  if (!CMS_OPTIONS[cmsName] || !CMS_OPTIONS[cmsName].downloadLink.length) {
    return null;
  }

  return CMS_OPTIONS[cmsName].downloadLink;
}

export function getCMSHelpGuideLink(cmsName) {
  if (!CMS_OPTIONS[cmsName] || !CMS_OPTIONS[cmsName].helpLink.length) {
    return null;
  }

  return CMS_OPTIONS[cmsName].helpLink;
}
