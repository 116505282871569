import React from 'react';
import Spinner from 'components/spinner';
import Margin from 'components/margin';
import styles from './textSpinner.scss';

export default class TextSpinner extends React.Component {
  render() {
    const {children, ...props} = this.props;

    return (
      <Margin {...props}>
        <Spinner />
        <p className={styles.text}>{children}</p>
      </Margin>
    );
  }
}
