import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import {HIDE_MODAL} from 'modules/modal';
import {EDIT_FOLDER, SAVE_FOLDER, receiveFolders, saveFolderError} from 'modules/folders';
import {selectFolder} from 'modules/folders/folderSelectors';
import FolderModal from 'modules/folders/components/folderModal';
import {folderEndpoint} from 'modules/folders/folderUtils';
import {showInModal} from 'modules/modal';
import PUT from 'utils/saga/put';
import {processResponse} from 'utils/saga/fetchUtils';

export default function* editFoldersSaga() {
  yield takeLatest([EDIT_FOLDER], editFolder);
}

function* editFolder(action) {
  const {folderId} = action;
  const immFolder = yield select(selectFolder(folderId));

  if (!immFolder) {
    return;
  }

  const folder = immFolder && immFolder.toJS();

  yield put(showInModal(FolderModal, {folder, name: 'editFolderModal'}));

  const saveAction = yield take([SAVE_FOLDER, HIDE_MODAL]);

  if (saveAction.type === HIDE_MODAL) {
    return;
  }

  const data = {folderId, ...saveAction.folder};
  const res = yield call(PUT, folderEndpoint({folderId}), data);

  yield processResponse(res, {
    successAction: receiveFolders,
    errorAction: saveFolderError
  });
}
