import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/select';

export default class SelectInput extends React.Component {
  handleChange = option => {
    const {onChange} = this.props;

    onChange && onChange(option === null ? null : option.value);
  };

  render() {
    return (
      <Select
        clearable={false} // not clearable by default
        {...this.props}
        multi={false}
        onChange={this.handleChange}
      />
    );
  }
}
