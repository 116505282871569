import PropTypes from 'prop-types';
import React from 'react';
import PillsComponent from 'components/pill/pills';

class Pills extends React.Component {
  render() {
    let {data, className} = this.props;
    if (!data) return null;

    if (typeof data === 'string') {
      data = [{label: data}];
    } else if (Array.isArray(data)) {
      data = data.map(label => {
        return typeof label === 'string' ? {label, appearance: 'minor'} : label;
      });
    }
    return <PillsComponent data={data} className={className} />;
  }
}

Pills.propTypes = {};

export default Pills;
