import {put} from 'redux-saga/effects';
import {deleteLeaderboard} from '../actions';
import {push} from 'modules/location';
import {leaderboardEndpoint, leaderboardsPath} from '../utils';
import {REQUEST_DELETE_LEADERBOARD} from '../constants';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';

export default generateDeleteSaga('leaderboard', REQUEST_DELETE_LEADERBOARD, leaderboardEndpoint, {
  success: function*({leaderboardId}) {
    yield put(deleteLeaderboard(leaderboardId));
    yield put(push(leaderboardsPath));
  }
});
