import {fromJS} from 'immutable';

const initialState = fromJS({
  proxies: []
});

export default function proxiesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PROXY_LIST:
      return state.set('proxies', fromJS(action.proxies));
  }
  return state;
}

export const RECEIVE_PROXY_LIST = 'proxies:RECEIVE_PROXY_LIST';
export function receiveProxyList(proxies) {
  return {type: RECEIVE_PROXY_LIST, proxies};
}
