exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3IVlqUU6PfNTc2K39WwG1a{position:fixed;top:0;bottom:0;left:0;right:0}._3IVlqUU6PfNTc2K39WwG1a>*{height:100vh;position:relative;overflow-y:scroll}._3j_oYJ4nyns5JWx7b-uPKk>*{position:relative}._3j_oYJ4nyns5JWx7b-uPKk>* h1{font-size:3.2em;font-weight:400;padding:0 30px;text-align:center}._2GNBOHJv_tUKQm6BdfyWnj{position:absolute;top:0;left:0;right:0;height:65%;z-index:0;max-height:800px;background:#533be2}._32iNergNa36Cdl5aDTx2mb{max-width:800px;background:#fff;margin:0 auto;border-radius:10px;box-shadow:0 2px 20px 1px rgba(0,0,0,.25);min-height:660px}@media(max-width: 850px){._32iNergNa36Cdl5aDTx2mb{border-radius:0;width:100%;max-width:100%}}._32iNergNa36Cdl5aDTx2mb hr{width:100%;border-color:#d6d6d6;margin:0}._2nj5nfyLKjd_6nF8E5ty1u{margin:10px auto 60px auto;padding:0;display:inline}._2nj5nfyLKjd_6nF8E5ty1u li{list-style:none;display:inline-block}._2nj5nfyLKjd_6nF8E5ty1u li:last-child{border-right:none}._2nj5nfyLKjd_6nF8E5ty1u li button{background:transparent;color:rgba(255,255,255,.7);border:1px solid rgba(255,255,255,0);box-shadow:none;padding:15px 40px;font-size:1.3em;min-width:180px}._2nj5nfyLKjd_6nF8E5ty1u li button:hover{color:#fff}._2nj5nfyLKjd_6nF8E5ty1u li button._1vrw7FqxSGClYsuCdtaAas{border:1px solid rgba(255,255,255,.5);background:rgba(0,0,0,.1);color:#fff}._2vUx8Zh_2KvRDSnTjsHNM0{margin-top:60px;margin-bottom:20px;color:#fff;min-height:65px}.xjybXfqiTrTY7uA2BzYpZ{margin:60px auto;color:#000}._3IyDm2OJ_b0fW00fC23CiW{margin-top:-15px}@media(max-width: 425px){._2nj5nfyLKjd_6nF8E5ty1u li{display:block}._2nj5nfyLKjd_6nF8E5ty1u li button{width:100%}}", ""]);

// exports
exports.locals = {
	"fullScreen": "_3IVlqUU6PfNTc2K39WwG1a",
	"wrapper": "_3j_oYJ4nyns5JWx7b-uPKk",
	"background": "_2GNBOHJv_tUKQm6BdfyWnj",
	"content": "_32iNergNa36Cdl5aDTx2mb",
	"groupSwitcher": "_2nj5nfyLKjd_6nF8E5ty1u",
	"selected": "_1vrw7FqxSGClYsuCdtaAas",
	"header": "_2vUx8Zh_2KvRDSnTjsHNM0",
	"footer": "xjybXfqiTrTY7uA2BzYpZ",
	"checkoutFooter": "_3IyDm2OJ_b0fW00fC23CiW"
};