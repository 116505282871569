import {fork} from 'redux-saga/effects';
import modalSaga from 'utils/saga/modalSaga';
import {CREATE_FILTER} from 'modules/reportFilters';
import CreateFilterModal from 'modules/reportFilters/components/createFilterModal';
import {validFilters} from 'modules/reportFilters/reportFilterUtils';

export default function* addFilterSaga() {
  // create a "show modal" saga to collect the filter params
  yield fork(
    modalSaga(CREATE_FILTER, CreateFilterModal, action => {
      const filter = validFilters.find(f => f.field === action.filterField) || null;

      return {filter};
    })
  );
}
