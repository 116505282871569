exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CS1PvQUoWbPqxrorAIc78{margin:15px 0 35px}._1g1hz1e-DhBHPMzP7U2eGg{padding:30px 40px;min-height:680px}._1g1hz1e-DhBHPMzP7U2eGg.FM1GcACN8i9mW3olfHzji{padding-left:130px;padding-right:130px}@media(max-width: 767px){._1g1hz1e-DhBHPMzP7U2eGg.FM1GcACN8i9mW3olfHzji{padding-left:30px;padding-right:30px}}._1g1hz1e-DhBHPMzP7U2eGg._3kpDsFiYFkQakuF8GenawO{margin-top:40px}._1g1hz1e-DhBHPMzP7U2eGg._3kpDsFiYFkQakuF8GenawO hr{margin:40px 0}._1g1hz1e-DhBHPMzP7U2eGg._3kpDsFiYFkQakuF8GenawO ._3PgpmwrUZ8TTfFts_IDF2D{margin-top:20px}._1g1hz1e-DhBHPMzP7U2eGg._3kpDsFiYFkQakuF8GenawO ._1MCvqtaHhGPvyqKf-58Q5W{margin:20px}._1g1hz1e-DhBHPMzP7U2eGg button{min-width:120px}._1f_wKNbhwv3ALu80h3rZ03{opacity:.5;font-size:.7em;margin:10px 0}.hygHcrTJ1O62QPHM9jiCG{margin-top:20px}.hygHcrTJ1O62QPHM9jiCG ._13edi7Da6JmwJgrubQof15{float:right}", ""]);

// exports
exports.locals = {
	"title": "_3CS1PvQUoWbPqxrorAIc78",
	"content": "_1g1hz1e-DhBHPMzP7U2eGg",
	"payment": "FM1GcACN8i9mW3olfHzji",
	"confirm": "_3kpDsFiYFkQakuF8GenawO",
	"editBtn": "_3PgpmwrUZ8TTfFts_IDF2D",
	"infoSection": "_1MCvqtaHhGPvyqKf-58Q5W",
	"errorMessage": "_1f_wKNbhwv3ALu80h3rZ03",
	"buttons": "hygHcrTJ1O62QPHM9jiCG",
	"nextBtn": "_13edi7Da6JmwJgrubQof15"
};