import {takeLatest, put, call} from 'redux-saga/effects';
import {ACCEPT_QUOTE} from 'modules/quote/quoteActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {quoteAcceptByStripeEndpoint, quoteAcceptByInvoiceEndpoint} from 'modules/quote/quoteUtils';
import {couldNot} from 'utils/errorUtils';
import {showError} from 'utils/saga/alerts';
import {getStripeKey} from 'modules/payments/paymentUtils';
import {push} from 'modules/location';
import {confirmCheckoutPath} from 'modules/confirmCheckout/confirmCheckoutUtils';

export default function* acceptQuoteSaga() {
  yield takeLatest([ACCEPT_QUOTE], function*({quoteId, quoteKey, formData, failCallback}) {
    const isStripe = formData.paymentType === "stripe"
    const endpoint = isStripe ? quoteAcceptByStripeEndpoint(quoteId, quoteKey) : quoteAcceptByInvoiceEndpoint(quoteId, quoteKey);

    yield processRequest('POST', endpoint, {
      success: function*(data) {
        if (isStripe) {
          yield call(processStripeResponse, data, failCallback)
          return;
        }

        if (data.state === "accepted") {
          yield put(push(confirmCheckoutPath('invoice')));
          return;
        }
        
        yield showError(couldNot('accept quote'));
        failCallback && failCallback(err);
      },
      error: function*(err) {
        yield showError(couldNot('accept quote'));
        failCallback && failCallback(err);
      }
    }, {request: formData});
  });
}

function* processStripeResponse(data, failCallback) {
  if (!data.sessionId) {
    yield showError(couldNot('accept quote'));
    failCallback && failCallback("Missing sessionId");
    return;
  }

  const stripe = Stripe(getStripeKey());
  if (!stripe) {
    console.warn("You are missing stripe import, this cannot be fulfilled.");
    yield showError(couldNot('accept quote'));
    failCallback && failCallback("No stripe import");
    return;
  }

  const response = yield call(callStripe, stripe, data.sessionId);
  // I guess if we got here, we must have a problem, we should have been redirected!
  yield showError(couldNot('accept quote'));
  failCallback && failCallback("Bad response from Stripe");
  return;
}

function* callStripe(stripe, sessionId) {
  return yield call(stripe.redirectToCheckout, {
    sessionId: sessionId
  });
}