import {takeEvery, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {REQUEST_CHECKS, receiveChecks} from 'modules/checks';
import {checksEndpoint} from 'modules/checks/checkUtils';

export default function* requestChecksSaga() {
  yield takeEvery(REQUEST_CHECKS, function*() {
    const checks = yield processRequest('GET', checksEndpoint(), {});

    yield put(receiveChecks(checks));
  });
}
