import {Map, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';

import {
  FETCH_QUOTE,
  RECEIVE_QUOTE,
  HANDLE_QUOTE_LOAD_ERROR,
  SET_QUOTE_STATE,
  RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS,
  RECEIVE_QUOTE_WITH_PAYMENT_OPTIONS,
  HANDLE_RE_QUOTE_LOAD_ERROR
} from 'modules/quote/quoteActions';

const initialState = Map({
  data: Map(),
  isLoading: false,
  loadingError: null,
  reQuoteLoadingError: null,
  reQuoteLoading: false
});

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUOTE: 
      return setLoadingState(state, true, null).set('data', Map());
    case RECEIVE_QUOTE:
      return setLoadingState(state, false, null).set('data', fromJS(action.quote));
    case HANDLE_QUOTE_LOAD_ERROR:
      return setLoadingState(state, false, action.error).set('data', Map());
    case SET_QUOTE_STATE:
      return state.setIn(['data', 'quote', 'state'], action.state);


    case RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS: 
      return setReQuoteLoadingState(state, true, null);
    case RECEIVE_QUOTE_WITH_PAYMENT_OPTIONS:
      return setReQuoteLoadingState(state, false, null).set('data', fromJS(action.quote));
    case HANDLE_RE_QUOTE_LOAD_ERROR:
      return setReQuoteLoadingState(state, false, action.error);
    case SWITCH_ACCOUNT:
      return initialState;
  }

  return state;
}

function setLoadingState(state, loading, error) {
  return state.set('isLoading', loading).set('loadingError', error);
}

function setReQuoteLoadingState(state, loading, error) {
  return state.set('reQuoteLoading', loading).set('reQuoteLoadingError', error);
}