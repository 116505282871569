import {put} from 'redux-saga/effects';
import {generateDeleteSaga} from 'utils/saga/fetchUtils';
import {uptimeMonitorEndpoint} from '../utils';
import {deleteUptimeMonitor} from '../actions';
import {CONFIRM_DELETE_UPTIME_MONITOR} from '../constants';

export default generateDeleteSaga(
  'uptime monitor',
  CONFIRM_DELETE_UPTIME_MONITOR,
  uptimeMonitorEndpoint,
  {
    success: function*({monitorId, reportId}) {
      yield put(deleteUptimeMonitor(monitorId, reportId));
    }
  }
);
