import React from 'react';
import styles from './pagination.scss';
import cx from 'classnames';

export default function PaginationPage({text, onClick, isActive: isActivePage}) {
  const pageClick = e => {
    if (isActivePage) return;
    e.preventDefault();
    onClick && onClick();
  };

  return (
    <li
      role="button"
      onClick={pageClick}
      className={cx({[styles.disabled]: isActivePage}, 'page-item')}
    >
      {text}
    </li>
  );
}
