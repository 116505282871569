import React from 'react';
import Priority from './priority';
import Icon from 'components/font-awesome';

export default function Classification({priority, icon, label, color}) {
  const styles = color ? {color: color} : {};
  return (
    <div>
      {!!icon && (
        <span style={styles}>
          <Icon name={icon} />
        </span>
      )}
      {!!priority && <Priority priority={priority} />}
      <span style={{verticalAlign: 'middle', marginLeft: !icon && priority ? '10px' : ''}}>
        {label}
      </span>
    </div>
  );
}
