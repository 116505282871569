import React from 'react';
import cx from 'classnames';
import styles from './map.scss';
import Icon from 'components/font-awesome';
import {Marker, Popup} from 'react-leaflet';
import Map from 'components/map';
import Markdown from 'react-remarkable';
import Leaflet from 'leaflet';
import {renderToStaticMarkup} from 'react-dom/server';

export default class MapLayoutComponent extends React.Component {
  markdownOptions = {
    breaks: true,
    linkTarget: '_blank'
  };

  renderMarker = ({latitude, longitude, label, key, appearance} = {}) => {
    const {icon, type} = appearance || {icon: 'map-marker-alt', type: 'primary'};
    const faIcon = <Icon name={icon} size="3x" />;
    const divIcon = Leaflet.divIcon({
      className: cx(styles.marker, styles[type]),
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: renderToStaticMarkup(faIcon)
    });

    return (
      // TODO `key` is not always defined, need to check backend
      <Marker icon={divIcon} position={[latitude, longitude]} key={key || label}>
        <Popup>
          <Markdown options={this.markdownOptions}>{label}</Markdown>
        </Popup>
      </Marker>
    );
  };

  render() {
    const {result} = this.props;
    const rows = result.rows ? result.rows : result;

    return <Map className={styles.map}>{rows.map(this.renderMarker)}</Map>;
  }
}
