import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {openEditUserAlertsModal} from 'modules/alertSubscriptions/actions';

function EditUserAlertsButton(props) {
  return <Button {...props}>{props.children || 'Edit user alerts'}</Button>;
}

export default connect(
  null,
  (dispatch, props) => ({
    onClick: event => {
      dispatch(openEditUserAlertsModal(props.userId));
    }
  })
)(EditUserAlertsButton);
