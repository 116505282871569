// TODO: rename module from products -> ecommerce

export {default} from './productsReducer';
import {analytics, events} from 'modules/analytics';

export const REQUEST_PRODUCTS = 'products:REQUEST_PRODUCTS';
export function requestProducts({clearExisting = true} = {}) {
  return {
    type: REQUEST_PRODUCTS,
    clearExisting
  };
}

export const RECEIVE_PRODUCTS = 'products:RECEIVE_PRODUCTS';
export function receiveProducts(products) {
  return {
    type: RECEIVE_PRODUCTS,
    products
  };
}

export const PRODUCTS_ERROR = 'products:PRODUCTS_ERROR';
export function handleProductsError(response) {
  return {
    type: PRODUCTS_ERROR,
    error: response.error
  };
}

export const RECEIVE_TAX = 'products:RECEIVE_TAX';
export function receiveTax(tax) {
  return {
    type: RECEIVE_TAX,
    tax
  };
}

export const SET_NUM_PAGES_CHOICE = 'products:SET_NUM_PAGES_CHOICE';
export function setNumPagesChoice(pageSliderValue) {
  return {
    type: SET_NUM_PAGES_CHOICE,
    pageSliderValue
  };
}

export const PAY_ERROR = 'products:PAY_ERROR';
export function payError(message = '') {
  return {
    type: PAY_ERROR,
    message,
    ...analytics(events.ONBOARDING_FAILED)
  };
}

export const CLEAR_ERROR = 'products:CLEAR_ERROR';
export function clearError() {
  return {type: CLEAR_ERROR};
}

export const ORDER_COMPLETE = 'products:ORDER_COMPLETE';
export function orderComplete() {
  return {
    type: ORDER_COMPLETE,
    ...analytics(events.ONBOARDING_COMPLETE)
  };
}

export const RECEIVE_STRIPE_ERROR = 'products:RECEIVE_STRIPE_ERROR';
export function receiveStripeError(error) {
  return {
    type: RECEIVE_STRIPE_ERROR,
    error
  };
}

export const RECEIVE_STRIPE_TOKEN = 'products:RECEIVE_STRIPE_TOKEN';
export function receiveStripeToken(token) {
  return {
    type: RECEIVE_STRIPE_TOKEN,
    token
  };
}

export const SELECT_CURRENCY = 'products:SELECT_CURRENCY';
export function selectCurrency(currency) {
  return {
    type: SELECT_CURRENCY,
    currency
  };
}

export const SELECT_PRODUCT = 'products:SELECT_PRODUCT';
export function selectProduct(product) {
  return {
    type: SELECT_PRODUCT,
    product
  };
}

// this just triggers a redirect to '/signup' which triggers START_ONBOARDING
export const SHOW_PRODUCTS = 'products:SHOW_PRODUCTS';
export function showProducts() {
  return {
    type: SHOW_PRODUCTS
  };
}
