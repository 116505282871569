import React from 'react';
import {Map} from 'immutable';
// Contrary to the old modal system, this system works on the basis
// that there is only one modal shown at any one time.
// If showInModal is triggered, it will replace the old modal content in the state.

const initialState = Map();

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_IN_MODAL:
      // we use Map rather than fromJS here as we don't want to convert
      // the props object to immutable
      return Map(action);
    case HIDE_MODAL:
      return initialState;
  }

  return state;
}

export const SHOW_IN_MODAL = 'modal:SHOW_IN_MODAL';
export function showInModal(
  ContentElement,
  props = {},
  {confirmClose = false, closeOnEsc = true} = {},
  modalWrapperProps = {}
) {
  return {
    type: SHOW_IN_MODAL,
    ContentElement,
    props,
    confirmClose,
    closeOnEsc,
    ...modalWrapperProps
  };
}

export function showInHugeModal(...args) {
  return {
    ...showInModal(...args),
    huge: true
  };
}

export const HIDE_MODAL = 'modal:HIDE_MODAL';
export function hideModal() {
  return {
    type: HIDE_MODAL
  };
}

export const CONFIRM_MODAL = 'modals:CONFIRM_MODAL';
export const DENY_MODAL = 'modals:DENY_MODAL';
export function submitConfirmModal(didConfirm) {
  return {type: didConfirm ? CONFIRM_MODAL : DENY_MODAL};
}

export const SHOW_CONFIRM_MODAL = 'modals:SHOW_CONFIRM_MODAL';
export function showConfirmModal(options, modalType = null) {
  return {type: SHOW_CONFIRM_MODAL, options, modalType};
}
