import {put, take} from 'redux-saga/effects';
import {showInModal, CONFIRM_MODAL, DENY_MODAL, HIDE_MODAL} from 'modules/modal';
import ConfirmationModal from 'modals/confirmationModal';
import ConfirmationModalLms from 'modals/confirmationModal/confirmationModalLms';

export default function* confirm(options, modalType = null) {
  let modal = ConfirmationModal;
  if (modalType === 'lms') {
    modal = ConfirmationModalLms;
  }

  yield put(showInModal(modal, options));
  const action = yield take([CONFIRM_MODAL, DENY_MODAL, HIDE_MODAL]);

  return action.type == CONFIRM_MODAL;
}
