// @todo: result-rewrite

// import _ from 'lodash';
// import {put, takeLatest} from 'redux-saga/effects';
// import {VIEW_PRINT_TESTS_PAGE, RECEIVE_REPORT} from 'modules/reports/reportConstants';
// import {testsSelector} from 'modules/tests/testSelectors';
// import selectOrWait from 'utils/saga/selectOrWait';
// import {multiTestEndpoint} from 'modules/tests/testUtils';
// import {processRequest} from 'utils/saga/fetchUtils';
// import {receiveTestLayout} from 'modules/tests/tests';
// import {receiveDefaultFilters} from 'modules/filters';
// import {receiveResults} from 'modules/results';

export default function* fetchTestsToPrintSaga() {
  return;
  //   yield takeLatest(VIEW_PRINT_TESTS_PAGE, fetchTestsToPrint);
}

// TODO fix me

// function* fetchTestsToPrint({reportId, testIds}) {
//   // testIds null = all tests
//   if (!testIds) {
//     const tests = yield selectOrWait(testsSelector, RECEIVE_REPORT);
//     testIds = tests.keySeq().toJS();
//   }

//   // Special print mode filters
//   const filters = {
//     media: 'print',
//   };

//   const payload = {
//     testIds,
//     filters,
//   };

//   const res = yield processRequest(
//     'POST',
//     multiTestEndpoint({reportId}),
//     {
//       success: function*({layouts, results, defaultFilters}) {
//         // receive results requires filters.
//         // the filters differ based on the layout.
//         // Here, we do some crazy hackery to make sure that each result is stored with the correct filters.
//         const testResults = _.groupBy(results, 'testId');
//         let testFilters = {};

//         for (let idx in layouts) {
//           const layoutContainer = layouts[idx];
//           const {layout, testId} = layoutContainer;

//           const filters = defaultFilters[idx];
//           yield put(receiveDefaultFilters(reportId, testId, filters));
//           testFilters[testId] = filters;

//           yield put(receiveTestLayout(reportId, testId, layout));
//         }

//         for (const testId in testResults) {
//           // yield put(receiveResults(testResults[testId], testFilters[testId]));
//         }
//       },
//     },
//     payload,
//   );
// }
