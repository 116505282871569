import React, {Fragment} from 'react';
import {useDispatch} from 'hooks/redux';
import Button from 'components/button';
import {push} from 'modules/location';
import {courseLessonPath} from 'modules/courses/utils';
import styles from '../../pages/courses.scss';

export default function NextLessonButton({course}) {
  const dispatch = useDispatch();
  const nextLessonId = course.get('nextLessonId');

  if (!nextLessonId) return null;

  return (
    <Fragment>
      <Button
        primary
        icon="fas fa-play"
        className={styles.bigButton}
        onClick={() => {
          dispatch(
            push(courseLessonPath, {
              lessonId: nextLessonId,
              courseId: course.get('courseId')
            })
          );
        }}
      >
        {course.get('percentComplete') ? 'Continue lesson' : 'Start first lesson'}
      </Button>
    </Fragment>
  );
}
