import _ from 'lodash';
import {fromJS, Map} from 'immutable';
import {createSelector} from 'reselect';
import {List} from 'immutable';
import {getProps, toJS} from 'utils/immutableUtils';
import {
  entitlementDataSelector,
  hasRemainingProductsSelector
} from 'modules/entitlements/entitlementSelectors';
import {createPropSelector} from 'utils/selectorUtils';
import {usersSelector} from 'modules/users/userSelectors';
import {proxyListSelector} from 'modules/proxies/proxySelectors';
import {urlToTestSelector} from 'modules/signup/signupSelectors';
import USER_OPTIONS from 'modules/userOptions/userOptionConstants';
import {productsSelector} from 'modules/products/productSelectors';
import {testIdParamSelector} from 'modules/tests/testSelectors';
import {ROLE_MANAGER, reportIsAllowed} from 'modules/gandalf/gandalfUtils';
import {userOptionsSelector} from 'modules/userOptions/userOptionSelectors';
import {userSelector, defaultLanguagesSelector} from 'modules/auth/authSelectors';
import {accountDefaultScheduleSelector} from 'modules/accountOptions/accountOptionSelectors';
import {missionsSelector, isDeletingMissionSelector} from 'modules/missions/missionSelectors';
import {testSelector, testsSelector, testNameOptionsSelector} from 'modules/tests/testSelectors';
import {currentAccountTypeSearchEngineSelector} from 'modules/accountTypes/accountTypeSelectors';
import {
  assigningTagsStateSelector,
  requestingAssignedTagsStateSelector
} from 'modules/tags/selectors';
import {isVirtualUserSelector} from 'modules/auth/authSelectors';
import {
  createPropOrParamSelector,
  createParamSelector,
  locationSelector,
  createPrioritisedRouteParamPropSelector
} from 'modules/location/locationSelectors';
import {getReportIdParts} from 'modules/reports/reportUtils';
import {showAlertsFeature} from 'modules/tests/testSelectors';

export const activeArchiveIdSelector = createSelector(
  locationSelector,
  ({params}) => {
    const reportIdParts = getReportIdParts(params.reportId);
    return reportIdParts.archiveId || 'live';
  }
);

const reportsStateSelector = state => state.reports;
export const reportsSelector = state => state.reports.get('data');
export const reportsCmsConfigSelector = state => state.reports.get('cmsConfig');
export const reportsLoadingSelector = state => state.reports.get('loading');

export const reportIdParamSelector = createPropOrParamSelector('reportId');
export const prioritisedReportIdPropSelector = createPrioritisedRouteParamPropSelector('reportId');

export const reportIdSelector = createSelector(
  reportIdParamSelector,
  // yuck why parseInt TODO refactor. it was legacy but now we have complex reportIds like '1-0-3948230493284' {reportId}-{segmentId}-{archiveId}
  reportId => parseInt(reportId, 10)
);

export const reportIdPropSelector = createPropSelector('reportId');

export const configUpdateStateSelector = state => {
  return (
    state.reports &&
    getProps(state.reports, ['updatingReport', 'updateReportFailed', 'updateReportSuccess'])
  );
};

export const exportReportStatusSelector = state => {
  return (
    state.reports &&
    getProps(state.reports, ['exporting', 'exportSuccessUrl', 'exportFailedMessage'])
  );
};

export const reportsOptionsSelector = createSelector(
  reportsSelector,
  reports => {
    return reports
      .toList()
      .filter(report => !!report.get('name'))
      .map(report => ({
        label: report.get('name'),
        value: report.get('reportId').toString()
      }))
      .sortBy(report => report.label.toLowerCase());
  }
);

export const reportSwitcherSelector = createSelector(
  reportsSelector,
  reports => {
    return {
      reports: reports && reports.toList().toJS()
    };
  }
);

export const reportSelector = createSelector(
  reportsSelector,
  reportIdSelector,
  (reports, reportId) => {
    return reports.get(reportId) || Map();
  }
);

export const reportPageCountSelector = createSelector(
  reportSelector,
  report => {
    const [numPages] = report
      .toJS()
      .results.filter(({resultId}) => resultId === 'website.numPages');

    if (numPages) {
      return numPages.value;
    }

    return 0;
  }
);

export const reportIsTeaserSelector = createSelector(
  reportSelector,
  report => {
    return report.toJS().presentationOptions.isTeaser;
  }
);

export const prioritisedReportSelector = createSelector(
  reportsSelector,
  prioritisedReportIdPropSelector,
  (reports, reportId) => {
    // yuck why parseInt TODO refactor. it was legacy but now we have complex reportIds like '1-0-3948230493284' {reportId}-{segmentId}-{archiveId}
    return reports.get(parseInt(reportId, 10), null);
  }
);

export const plainReportSelector = createSelector(
  reportSelector,
  report => {
    return report && report.toJS();
  }
);

export const reportNameSelector = createSelector(
  reportSelector,
  report => ({
    name: report.get('name'),
    reportType: report.get('reportType')
  })
);

export const reportObjectSelector = createSelector(
  reportSelector,
  report => ({
    report
  })
);

export const reportSettingsPageSelector = createSelector(
  reportSelector,
  testsSelector,
  configUpdateStateSelector,
  productsSelector,
  proxyListSelector,
  currentAccountTypeSearchEngineSelector,
  reportsCmsConfigSelector,
  assigningTagsStateSelector,
  requestingAssignedTagsStateSelector,
  (
    report,
    tests,
    configUpdateState,
    products,
    {proxyList},
    keywordLimits,
    cmsConfig,
    assigningTags,
    requestingAssignedTags
  ) => {
    return {
      report: report && report.toJS(),
      websiteConfig: tests && tests.get('website').toJS(),
      availableTestKeys: Object.keys(tests.toJS()),
      configUpdateState,
      products: products && products.toJS(),
      proxyList,
      ...keywordLimits,
      cmsConfig: cmsConfig && cmsConfig.toJS(),
      requestingAssignedTags,
      assigningTags
    };
  }
);

export const reportSettingsRouteSelector = createSelector(
  isVirtualUserSelector,
  testsSelector,
  showAlertsFeature,
  reportSelector,
  (isVirtualUser, tests, showAlerts, report) => {
    return {
      showAlerts,
      availableTestKeys: Object.keys(tests.toJS()),
      reportRole: report.getIn(['ownPermissions', 'role']),
      isVirtualUser
    };
  }
);

export const reportModalSelector = createSelector(
  entitlementDataSelector,
  hasRemainingProductsSelector,
  defaultLanguagesSelector,
  urlToTestSelector,
  accountDefaultScheduleSelector,
  (entitlements, hasRemainingProducts, languages, homeUrl, defaultSchedule) => ({
    entitlements,
    hasRemainingProducts,
    languages: languages && languages.toJS(),
    homeUrl,
    defaultSchedule
  })
);

export const exportReportSelector = createSelector(
  testNameOptionsSelector,
  exportReportStatusSelector,
  userOptionsSelector,
  userSelector,
  (testNameOptions, {exporting: isLoading, exportSuccessUrl}, {userOptions}, user) => ({
    ...testNameOptions,
    isLoading,
    exportSuccessUrl,
    defaultPaperSize: userOptions.get(USER_OPTIONS.PAPER_SIZE),
    user: user && user.toJS()
  })
);

export const reportMissionsSelector = createSelector(
  missionsSelector,
  reportIdSelector,
  (missions, reportId) => {
    return {
      reportId,
      missionIds: missions.reduce((missionIds, mission, missionId) => {
        if (mission.get('reportId') == reportId) {
          missionIds.push(missionId);
        }
        return missionIds;
      }, [])
    };
  }
);

export const missionListModalSelector = createSelector(
  usersSelector,
  reportsSelector,
  missionsSelector,
  reportIdPropSelector, // if there's no reportId prop, we return all reports
  isDeletingMissionSelector,
  (users, reports, missions, reportId = null, isDeleting) => {
    let reportIds = [reportId];

    if (!reportId) {
      reportIds = _.uniq(missions.toList().map(m => m.get('reportId')));
    }

    const selectedUsers = {},
      selectedReports = {};

    // for each mission, resolve all `mission.reportId`s and `mission.userId`s
    // so we have the report and user data ready to display
    missions.forEach(mission => {
      const uId = mission.get('userId');
      const rId = mission.get('reportId');

      // if we don't have this user, add it to the list of selectedUsers
      if (!selectedUsers[uId] && uId != 0) {
        const user = users.find(c => c.get('userId') == mission.get('userId'));

        if (!user) {
          if (__DEV__) console.warn('Could not find user "' + uId + '"');
        } else {
          selectedUsers[uId] = user;
        }
      }

      // if we don't have this report, add it to this list of selectedReports
      if (!selectedReports[rId] && reportIds.indexOf(rId) >= 0) {
        const report = reports.find(c => c.get('reportId') == mission.get('reportId'));

        if (!report) {
          if (__DEV__) console.warn('Could not find report "' + rId + '"');
        } else {
          const reportMissions = missions.filter(c => c.get('reportId') == rId);
          selectedReports[rId] = report.set('missions', reportMissions);
        }
      }
    });

    return {
      users: fromJS(selectedUsers),
      reportMissions: fromJS(selectedReports).toList(),
      isDeleting
    };
  }
);

// This probably belongs in testSelectors, but there is a recursion error
export const settingsActionSelector = createSelector(
  testSelector,
  reportSelector,
  (test, report) => ({
    test,
    report
  })
);

export const draggableReportRowSelector = createSelector(
  createParamSelector('folderId'),
  folderId => ({folderId})
);

export const scoreTypeSelector = createSelector(
  reportSelector,
  report => report.get('scoreType') || 'scores'
);

const settingDropdownOptions = createSelector(
  testSelector,
  reportSelector,
  (test, report) => {
    const options = [];
    const isTestConfigurable = test && test.get('supportsConfig');
    const allowEdit = reportIsAllowed(ROLE_MANAGER, report.getIn(['ownPermissions', 'role']));

    if (allowEdit) {
      options.push({value: 'settings', label: 'Website settings'});
    }

    if (allowEdit && isTestConfigurable) {
      options.push({
        value: 'test-settings',
        label: `${test.get('name')} settings`
      });
    }

    // options.push({value: 'trends', label: 'Trends'});

    // TODO: only some tests have checks
    // options.push({value: 'test-checks', label: 'Checks for this test'});

    // options.push({value: 'report-share', label: 'Share report'});

    if (allowEdit && test && test.get('supportsMute')) {
      options.push(
        test.get('isMuted')
          ? {value: 'unmute-test', label: 'Unmute test'}
          : {value: 'mute-test', label: 'Mute test'}
      );
    }

    if (report && report.get('isWatching')) {
      options.push({value: 'unwatch-report', label: 'Unwatch website'});
    } else {
      options.push({value: 'watch-report', label: 'Watch website'});
    }

    if (allowEdit) {
      options.push({value: 'delete', label: 'Delete website'});
    }

    return options;
  }
);

export const isRetestingSelector = createSelector(
  reportMissionsSelector,
  missionIds => !!missionIds.length
);

export const reportNavButtonsSelector = createSelector(
  reportSelector,
  testIdParamSelector,
  isRetestingSelector,
  settingDropdownOptions,
  isVirtualUserSelector,
  activeArchiveIdSelector,
  (report, testId, isRetesting, dropdownOptions, isVirtual, archiveId) => {
    return {
      testId,
      report: toJS(report),
      isRetesting,
      dropdownOptions,
      isVirtual,
      archiveId
    };
  }
);

export const lastLocationSelector = createSelector(
  reportsStateSelector,
  state => state.get('lastLocation')
);

export const reportRequiresUpgradeSelector = createSelector(
  reportSelector,
  reportIdSelector,
  testIdParamSelector,
  isRetestingSelector,
  activeArchiveIdSelector,
  (report, reportId, testId, isRetesting, activeArchiveId) => {
    const lastArchive = report && report.get('archives', List()).first(null);
    const lastArchiveId = lastArchive && lastArchive.get('archiveId');
    return {
      reportId,
      testId,
      isRetesting,
      lastArchiveId,
      viewingArchive: activeArchiveId && activeArchiveId !== 'live',
      reportUpgradeNeeded: report && report.get('reportUpgradeNeeded')
    };
  }
);

export const reportScheduleSelector = createSelector(
  reportsStateSelector,
  reportIdSelector,
  (domain, reportId) => {
    return toJS(domain.getIn(['schedules', reportId.toString()]) || {});
  }
);

export const reportScheduleLoadingSelector = createSelector(
  reportsStateSelector,
  reportIdSelector,
  (domain, reportId) => !domain.hasIn(['schedules', reportId.toString()])
);

export const forcedPagesSelector = state => state.reports.get('forcedPages');
export const forcedPagesLoadingSelector = state => state.reports.get('forcedPagesLoading');
export const forcedPagesPaginationSelector = state => state.reports.get('forcedPagesPagination');
export const forcedPageSelector = state =>
  state.reports.get('forcedPage') ? state.reports.get('forcedPage').toJS() : null;

export const reportsForcedPagesSelector = createSelector(
  forcedPagesSelector,
  forcedPagesLoadingSelector,
  forcedPagesPaginationSelector,
  (forcedPages, forcedPagesLoading, pagination) => ({
    forcedPages: forcedPages ? forcedPages.toList().toJS() : null,
    forcedPagesLoading,
    pagination: pagination && pagination.toJS()
  })
);

export const saveForcedPageSelector = createSelector(
  forcedPageSelector,
  forcedPagesLoadingSelector,
  testsSelector,
  (forcedPage, forcedPagesLoading, tests) => {
    return {
      forcedPage,
      forcedPagesLoading,
      websiteConfig: tests && tests.get('website').toJS()
    };
  }
);
