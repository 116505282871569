import React from 'react';
import {GeoJSON, Map as LeafletMap, Marker, Popup, TileLayer} from 'react-leaflet';

export default class Map extends React.Component {
  static defaultProps = {
    center: [51.505, -0.09],
    zoom: 2
  };

  render() {
    const {center, zoom, children, style, ...props} = this.props;

    return (
      <LeafletMap
        center={center}
        zoom={zoom}
        scrollWheelZoom={false}
        style={{height: 438, ...style}}
        {...props}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {children}
      </LeafletMap>
    );
  }
}
