import _ from 'lodash';
import React from 'react';
import UserPermissionsSummaryViewer from './userPermissionsSummaryViewer';
import Status from 'components/status';
import SettingsPage from 'components/settings/settingsPage';
import AddUserToPermissionGroupForm from './addUserToPermissionGroupForm';
import {shallPass, ACCOUNT_OWNER} from 'modules/auth/gandalf';

export default class PermissionsConfig extends React.Component {
  // HAHAHAHAHAHAHAHAHAHAHAH best function name ever
  isThereAnyInformationAboutPermissions() {
    const {users, reportPermissions} = this.props;

    const availableUsers = _.some(users, user => {
      return (
        !shallPass(user, ACCOUNT_OWNER) && !_.find(reportPermissions, {userGroupId: user.userId})
      );
    });

    return availableUsers || reportPermissions.size > 0;
  }

  render() {
    const {
      match: {params}
    } = this.props;

    return (
      <SettingsPage name="Website permissions" params={params}>
        <AddUserToPermissionGroupForm {...this.props} />
        <UserPermissionsSummaryViewer {...this.props} />

        {!this.isThereAnyInformationAboutPermissions() && (
          <Status appearance="warning">
            Add some users to this account before setting permissions.
          </Status>
        )}
      </SettingsPage>
    );
  }
}
