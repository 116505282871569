import React from 'react';
import PropTypes from 'prop-types';
import styles from './enterprisePricing.scss';
import Button from 'components/button/button';
import PlanBenefits from '../components/planBenefits';
import {TickList, TickListItem} from 'components/layoutComponents/tickList';

const propTypes = {
  onClickContact: PropTypes.func.isRequired,
  onClickReports: PropTypes.func.isRequired
};

const EnterprisePricing = ({onClickContact, onClickReports}) => (
  <div className={styles.pricingWrapper}>
    <div className={styles.contactSection}>
      <p className={styles.pageNumber}>Need more? Talk to us</p>

      <TickList className={styles.list}>
        <TickListItem>Test over 10,000 webpages</TickListItem>
        <TickListItem>Test your PDFs</TickListItem>
        <TickListItem>Test for GDPR compliance</TickListItem>
        <TickListItem>Bespoke training</TickListItem>
        <TickListItem>Dedicated account manager</TickListItem>
        <TickListItem>Pay via invoice</TickListItem>
      </TickList>
      <Button className={styles.contactButton} onClick={onClickContact}>
        Contact us
      </Button>
    </div>
    <PlanBenefits />
  </div>
);

EnterprisePricing.propTypes = propTypes;

export default EnterprisePricing;
