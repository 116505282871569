import cx from 'classnames';
import Trademark from 'components/legal/trademark';
import {authWrapperSelector} from 'modules/auth/authSelectors';
import {loginPath} from 'modules/auth/authUtils';
import React from 'react';
import {connect} from 'react-redux';
import styles from './authWrapper.scss';
import sharedStyles from './authWrapperShared.scss';
import HeaderLink from './headerLink';

export class AuthWrapper extends React.Component {
  renderHeaderLink(pathname) {
    switch (pathname) {
      case '/signup':
        return (
          <HeaderLink path={loginPath()} prompt="Already have an account?" linkText="Log in" />
        );
    }

    return <div />;
  }

  render() {
    const {error, pathname} = this.props;
    const className = cx(styles.loginPanel, {
      [styles.shake]: error
    });

    return (
      <div className={styles.wrapper}>
        <div className={styles.lhs}>
          <div className={styles.scrollable}>
            {this.renderHeaderLink(pathname)}

            <div className={className}>{this.props.children}</div>

            <Trademark className={sharedStyles.footerLinks} />
          </div>
        </div>
        <div className={styles.rhs}>
          <img src="/images/lady-with-laptop.png" alt=" " />
        </div>
      </div>
    );
  }
}

export default connect(authWrapperSelector)(AuthWrapper);
