import {createSelector} from 'reselect';
import {alphabeticalSorter} from 'utils/immutableUtils';

export const accountsStateSelector = state => state.accounts;

export const accountsSelector = createSelector(
  accountsStateSelector,
  state => state.get('data').sort(alphabeticalSorter('organizationName'))
);

export const accountsPageSelector = createSelector(
  accountsSelector,
  accounts => {
    return {
      accounts: accounts && accounts.toList().toJS()
    };
  }
);
