import {put} from 'redux-saga/effects';
import {login, storeAccount, receiveUsingServices} from 'modules/auth';
import {receiveUser, receivePusherPrefix} from 'modules/users';
import {receiveAccount} from 'modules/accounts';
import {receiveAccountTypes} from 'modules/accountTypes';
import {receiveAccountOptions} from 'modules/accountOptions';

export default function* handleLoginSuccess({user, token}) {
  if (!token || !user) {
    throw 'Login success called without token or user';
  }

  let account;
  ({account, ...user} = user);
  if (!account) {
    throw "User doesn't have an account";
  }

  let accountType, accountOptions;
  ({accountType, accountOptions, ...account} = account);
  if (!accountType) {
    throw 'No AccountType found when loading the User!';
  }

  yield put(receiveUser(user));
  yield put(receivePusherPrefix(user.pusherPrefix));
  yield put(receiveAccount(account));
  yield put(receiveAccountTypes([accountType]));
  yield put(receiveUsingServices(user.usingServices));

  if (accountOptions) {
    yield put(receiveAccountOptions(accountOptions));
  }

  // TODO: this action seems a bit pointless, we
  // could infer a lot of this when logging in
  yield put(storeAccount(account.accountId));

  yield put(login(user, token, account));
}
