import {fromJS, List} from 'immutable';
import {EXIT_FOLDER_PAGE} from 'modules/folders';
import {
  DELETE_REPORT,
  UPDATE_REPORT,
  REMOVE_REPORT_FROM_FOLDER
} from 'modules/reports/reportConstants';
import {swap} from 'utils/immutableUtils';
import {ORDER_COMPLETE} from 'modules/products';
import {LOGIN, SWITCH_ACCOUNT} from 'modules/auth';
import {EXAMPLE_REPORTS_KEY} from 'modules/folders/folderUtils';
import {LOCATION_CHANGE} from 'connected-react-router';
import {isFolderViewPath} from './folderViewUtils';

const initialState = fromJS({
  views: List(
    // Store a 'fake' view for folder 0 (all websites view)
    fromJS([
      {
        viewId: 0,
        folderId: 0,
        type: 'grid',
        name: 'Overview',
        sortIndex: 1,
        columns: [
          {name: 'website.thumbnail', label: '', type: 'image'},
          {name: 'report.name', label: 'Name', type: 'string'},
          {name: 'website.numPages', label: 'Pages tested', type: 'int'},
          {
            name: 'website.numConfirmed',
            label: 'Confirmed issues',
            type: 'int'
          },
          {name: 'website.numPotential', label: 'Potential issues', type: 'int'}
        ]
      }
    ])
  ),
  reports: [],
  loading: true,
  currentFolderId: null,
  lastLocation: null,
  [EXAMPLE_REPORTS_KEY]: []
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_COMPLETE:
    case EXIT_FOLDER_PAGE:
      // reset state when we leave the page, but keep the example reports to save refetching them
      // Also keep lastLocation
      return initialState
        .set(EXAMPLE_REPORTS_KEY, state.get(EXAMPLE_REPORTS_KEY))
        .set('lastLocation', state.get('lastLocation'));

    case FETCH_FOLDER_VIEWS:
      return state.set('loading', true);

    case VIEW_FOLDER_VIEW_PAGE:
      return state.merge({
        loading: true,
        currentFolderId: action.folderId
      });

    case RECEIVE_FOLDER_VIEWS:
      // we only store the current views.
      return state.set('views', initialState.get('views').concat(fromJS(action.views)));

    case RECEIVE_FOLDER_VIEW:
      const {viewId} = action.view;

      // we only store the current views.
      return state.update('views', views => {
        const idx = views.findIndex(v => v.get('viewId') == viewId);

        if (idx >= 0) {
          return views.set(idx, fromJS(action.view));
        }

        return views.push(fromJS(action.view));
      });

    case RECEIVE_FOLDER_RESULTS:
      const newState = state
        .set('loading', false)
        // Count fields are only set or the 'all websites' folder currently but
        // there's no harm in setting them to undefined for other folders
        .set('totalCount', action.totalCount)
        .set('filteredCount', action.filteredCount)
        .set(action.key, fromJS(action.reports));

      const idx = newState.get('views').findKey(c => c.get('viewId') == action.viewId);

      if (idx >= 0) {
        return newState.setIn(['views', idx, 'columns'], fromJS(action.columns));
      }

      return newState;

    case RECEIVE_FOLDER_RESULTS_ERROR:
      return state.set('loading', false);

    case SWAP_FOLDER_VIEWS:
      return state.update('views', views => {
        const swapped = swap(views, action.idxA, action.idxB);

        // resets the sortIndex based on current order
        return swapped.map((view, i) => view.set('sortIndex', i));
      });

    // TODO: this is annoying.
    // We have multiple sources of truth as we store a stripped down version of
    // report in this reducer.
    // This means we have to hook into REPORT actions and modify this state
    // accordingly.
    case DELETE_REPORT:
      return state.update('reports', reports => {
        return reports.filter(report => report.get('reportId') != action.reportId);
      });

    // TODO: same as above.
    case UPDATE_REPORT:
      const {
        reportId,
        data: {folderId}
      } = action;
      // if folderId has changed, we need to remove this report from state.
      if (folderId !== state.get('currentFolderId')) {
        // why is there no filterIn? :(
        return state.updateIn(['reports'], reports =>
          reports.filter(report => report.get('reportId') != reportId)
        );
      }

    case DELETE_FOLDER_VIEW:
      return state.update('views', views => {
        return views.filter(view => view.get('viewId') != action.viewId);
      });

    case REMOVE_REPORT_FROM_FOLDER:
      return state.update('reports', reports => {
        return reports.filter(report => report.get('reportId') != action.reportId);
      });

    case LOGIN:
    case SWITCH_ACCOUNT:
      // Clear example reports when we change accounts
      return state.set(EXAMPLE_REPORTS_KEY, initialState.get(EXAMPLE_REPORTS_KEY));

    case LOCATION_CHANGE:
      if (isFolderViewPath(action.payload.location.pathname)) {
        return state.set(
          'lastLocation',
          action.payload.location.pathname + action.payload.location.search
        );
      }
      return state;
  }
  return state;
}

export const VIEW_FOLDER_VIEW_PAGE = 'folderViews:VIEW_FOLDER_VIEW_PAGE';
// key param specifies the key in state to store the returned values
export function viewFolderViewPage(folderId, viewId, key = 'reports') {
  return {
    type: VIEW_FOLDER_VIEW_PAGE,
    folderId,
    viewId,
    key
  };
}

export const EXIT_FOLDER_VIEW_PAGE = 'folderViews:EXIT_FOLDER_VIEW_PAGE';
export function exitFolderViewPage() {
  return {
    type: EXIT_FOLDER_VIEW_PAGE
  };
}

export const TRIGGER_NEW_FOLDER_VIEW = 'folderViews:TRIGGER_NEW_FOLDER_VIEW';
export function triggerNewFolderView(folderId) {
  return {type: TRIGGER_NEW_FOLDER_VIEW, folderId};
}

export const TRIGGER_DELETE_FOLDER_VIEW = 'folderViews:TRIGGER_DELETE_FOLDER_VIEW';
export function triggerDeleteFolderView(folderId, viewId) {
  return {type: TRIGGER_DELETE_FOLDER_VIEW, folderId, viewId};
}

// Swap views in a folder by their index
export const SWAP_FOLDER_VIEWS = 'folderViews:SWAP_FOLDER_VIEWS';
export function swapFolderViews(folderId, idxA, idxB) {
  return {type: SWAP_FOLDER_VIEWS, folderId, idxA, idxB};
}

export const SAVE_FOLDER_VIEW = 'folderViews:SAVE_FOLDER_VIEW';
export function saveFolderView(folderView) {
  return {type: SAVE_FOLDER_VIEW, folderView};
}

export const LOADING_FOLDER_DATA = 'folderViews:LOADING_FOLDER_DATA';
export const COMPLETE_FOLDER_DATA = 'folderViews:COMPLETE_FOLDER_DATA';

export const FETCH_FOLDER_VIEWS = 'folderViews:FETCH_FOLDER_VIEWS';
export function fetchFolderViews(folderId, viewId, key = 'reports') {
  return {type: FETCH_FOLDER_VIEWS, folderId, viewId, key};
}

export const RECEIVE_FOLDER_VIEWS = 'folderViews:RECEIVE_FOLDER_VIEWS';
export function receiveFolderViews(views) {
  return {type: RECEIVE_FOLDER_VIEWS, views};
}

export const RECEIVE_FOLDER_VIEW = 'folderViews:RECEIVE_FOLDER_VIEW';
export function receiveFolderView(view) {
  return {type: RECEIVE_FOLDER_VIEW, view};
}

export const RECEIVE_FOLDER_RESULTS = 'folderViews:RECEIVE_FOLDER_RESULTS';
export function receiveFolderResults({
  columns,
  reports,
  totalCount,
  filteredCount,
  folderId,
  viewId,
  key = 'reports'
}) {
  return {
    type: RECEIVE_FOLDER_RESULTS,
    columns,
    reports,
    totalCount,
    filteredCount,
    folderId,
    viewId,
    key
  };
}

export const RECEIVE_FOLDER_RESULTS_ERROR = 'folderViews:RECEIVE_FOLDER_RESULTS_ERROR';
export function receiveFolderResultsError() {
  return {type: RECEIVE_FOLDER_RESULTS_ERROR};
}

export const TRIGGER_EDIT_VIEW = 'folderViews:TRIGGER_EDIT_VIEW';
export function triggerEditFolderView(folderId, viewId) {
  return {type: TRIGGER_EDIT_VIEW, folderId, viewId};
}

export const DELETE_FOLDER_VIEW = 'folderViews:DELETE_FOLDER_VIEW';
export function deleteFolderView(folderId, viewId) {
  return {type: DELETE_FOLDER_VIEW, folderId, viewId};
}
