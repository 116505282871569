import config from 'config';
import {accountEndpoint} from 'modules/accounts/accountUtils';

export const paymentsEndpoint = () => 'invoices';

export const paymentDetailsEndpoint = ({accountId}) => {
  return accountEndpoint({accountId}) + '/payment-details';
};

export function getStripeKey() {
  const stripeConfig = config.STRIPE;
  return stripeConfig.LIVE_MODE ? stripeConfig.KEY_LIVE : stripeConfig.KEY_TEST;
}
