exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2zb-UrUwSQS7DvbkeIYMHp{padding-right:15px;background:#fff;border-bottom:1px solid #e1e1e1;border-top:1px solid #e1e1e1;display:flex}.DMIEk6R6o8JRWHb2LcrBf{cursor:pointer;height:50px;width:50px;line-height:50px;text-align:center;transition:all .1s ease-in-out;opacity:.2}.DMIEk6R6o8JRWHb2LcrBf:hover{-webkit-transform:scale(1.3);transform:scale(1.3)}._2i2eZVKfvzHBhXWP4A_TkW{display:flex;flex:0;margin:0;padding:0;list-style:none}._2i2eZVKfvzHBhXWP4A_TkW li{height:100%}.n3nJLVEOB9cIk-vcpFyqm{flex:1}", ""]);

// exports
exports.locals = {
	"folderTabs": "_2zb-UrUwSQS7DvbkeIYMHp",
	"addFolderViewButton": "DMIEk6R6o8JRWHb2LcrBf",
	"tabIcons": "_2i2eZVKfvzHBhXWP4A_TkW",
	"responsiveTabs": "n3nJLVEOB9cIk-vcpFyqm"
};