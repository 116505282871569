import {Map, List, fromJS} from 'immutable';
import {RECEIVE_REPORT, LEAVE_REPORT_PAGE} from 'modules/reports/reportConstants';
import {SET_CONFIG_OPTIONS, SET_SUCCESS_MESSAGE} from 'modules/configOptions/configOptions';

const initialState = Map({
  data: Map({}), // a key value object of the global values
  // options: List() // an array of option objects (global and local) [not yet used], will replace `data`

  successMessage: ''
});

// We store the tests for the current report only.
export default function testsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_REPORT:
      if (action.report.configOptions) {
        return state.set('data', new Map(action.report.configOptions));
      }
      break;

    case SET_SUCCESS_MESSAGE:
      return state.set('successMessage', action.value);

    case LEAVE_REPORT_PAGE:
      // Clear config cache
      return initialState;

    case SET_CONFIG_OPTIONS:
      // TODO this currently only works for global config settings
      //      the commented code below should work for both
      return state.update('data', function(data) {
        action.options.forEach(opt => {
          if (opt.globalName) {
            data = data.set(opt.globalName, opt.value);
          }
        });
        return data;
      });

    // return state.update('options', options => {
    //   action.options.forEach(option => {
    //     var optionIdx = options.findIndex(
    //       opt =>
    //         opt.get('name') &&
    //         (opt.get('actionId') || opt.get('testId')) &&
    //         (opt.get('testId') === option.testId ||
    //           opt.get('actionId') === option.actionId) &&
    //         opt.get('name') === option.name
    //     );

    //     if (optionIdx < 0) {
    //       optionIdx = options.findIndex(
    //         opt =>
    //           opt.get('globalName') &&
    //           opt.get('globalName') === option.globalName
    //       );
    //     }

    //     if (optionIdx < 0) {
    //       options = options.push(fromJS(option));
    //     } else {
    //       options = options.setIn([optionIdx, 'value'], option.value);
    //     }
    //   });
    //   return options;
    // });
  }
  return state;
}
