import React from 'react';
import {connect} from 'react-redux';
import {showUpdatePaymentDetailsModal} from 'modules/payments';
import {updateButtonSelector} from 'modules/payments/paymentSelectors';

import Button from 'components/button';

export class UpdatePaymentDetailsButton extends React.Component {
  handleClick = () => {
    const {showUpdatePaymentDetailsModal} = this.props;
    showUpdatePaymentDetailsModal();
  };

  render() {
    const {disabled} = this.props;
    return (
      <Button onClick={this.handleClick} disabled={disabled}>
        Update payment details
      </Button>
    );
  }
}

export default connect(
  updateButtonSelector,
  {showUpdatePaymentDetailsModal}
)(UpdatePaymentDetailsButton);
