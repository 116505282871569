import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {confirmDeleteForcedPage} from 'modules/reports';

function DeleteForcedPageButton({...props}) {
  return (
    <Button icon="trash" danger tooltip="Delete this forced page" {...props}>
      Delete
    </Button>
  );
}

export default connect(
  null,
  (dispatch, props) => ({
    onClick: () => {
      dispatch(confirmDeleteForcedPage(props));
    }
  })
)(DeleteForcedPageButton);
