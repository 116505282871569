import {getAccountIdFromPath} from 'modules/accounts/accountUtils';

export function loginRequest(state) {
  return state.set('loggingIn', true).remove('error');
}

export function login(state, {userId, accountId} = {}, token) {
  // this shouldn't happen, so log any errors
  if (!token) {
    if (__DEV__) {
      console.error('Tried to login without passing a token', arguments);
    }
    return state;
  }

  if (!state.get('accountId') && accountId) {
    state = state.set('accountId', accountId.toString());
  }

  return state
    .set('userId', userId.toString())
    .set('token', token)
    .remove('loggingIn')
    .remove('error');
}

export function logout(state, error = '') {
  return state
    .set('error', error)
    .remove('loggingIn')
    .remove('userId')
    .remove('token');
}

export function loadToken(state, token) {
  return state.set('token', token);
}

export function storeAccount(state, accountId) {
  return state.set('accountId', accountId);
}

export function setAccountIdFromLocation(state, locationDescriptor) {
  const accountIdInPath = getAccountIdFromPath(locationDescriptor.pathname);
  if (accountIdInPath && accountIdInPath !== state.get('accountId')) {
    return storeAccount(state, accountIdInPath);
  }
  return state;
}
