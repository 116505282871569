import {createSelector} from 'reselect';
import {userSelector} from 'modules/auth/authSelectors';
import {Map} from 'immutable';

export const logsStateSelector = state => state.logs;

export const reportLogsSelector = createSelector(
  logsStateSelector,
  state => {
    return state.get('data');
  }
);

export const logStatusSelector = createSelector(
  logsStateSelector,
  state => {
    return state.get('status') || Map();
  }
);

export const sortedReportLogsSelector = createSelector(
  reportLogsSelector,
  reportLogs => {
    return reportLogs.sortBy(log => -log.get('startTime'));
  }
);

export const reportLogPageSelector = createSelector(
  sortedReportLogsSelector,
  logStatusSelector,
  userSelector,
  (reportLogs, status, user) => {
    return {
      isFetchingLogs: status && status.get('isFetchingLogs'),
      reportLogs: reportLogs && reportLogs.toList().toJS(),
      user: user && user.toJS()
    };
  }
);
