exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._32cUrkhxLGLrVMyeRVJwjr{padding:0 15px 15px 15px;margin:0 0 10px 0 !important}@media print{._3EzFLPJXMcSY6RYR0VwYT{display:none}}", ""]);

// exports
exports.locals = {
	"languages": "_32cUrkhxLGLrVMyeRVJwjr",
	"button": "_3EzFLPJXMcSY6RYR0VwYT"
};