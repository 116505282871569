import React from 'react';

class PageDownloadSpeedIssue extends React.Component {
  render() {
    const {issueData, isOpen} = this.props;
    const timeInSeconds = issueData.get('timeInSeconds');

    return (
      <div>
        <strong>Time taken:</strong> {timeInSeconds} seconds
      </div>
    );
  }
}

export default PageDownloadSpeedIssue;
