import _ from 'lodash';
import {fromJS} from 'immutable';
import {toJS} from 'utils/immutableUtils';
import {createSelector} from 'reselect';
import {currentFolder} from 'modules/folders/folderSelectors';
import {createParamSelector} from 'modules/location/locationSelectors';
import {userSelector} from 'modules/auth/authSelectors';
import {statOptionsSelector} from 'modules/stats/statSelectors';
import {accountViewsEnabledSelector} from 'modules/accountOptions/accountOptionSelectors';
import {filtersSelector} from 'modules/reportFilters/reportFilterSelectors';
import {EXAMPLE_REPORTS_KEY} from 'modules/folders/folderUtils';

export const folderIdSelector = createParamSelector('folderId');

export const folderViewsState = state => state.folderViews;

export const selectFolderView = viewId =>
  createSelector(
    folderViewsSelector,
    folderViews => folderViews.find(c => c.get('viewId') == viewId)
  );

const loadingSelector = createSelector(
  folderViewsState,
  folderViews => folderViews.get('loading')
);

export const folderViewsSelector = createSelector(
  folderViewsState,
  folderViews => folderViews.get('views').sortBy(view => view.get('sortIndex'))
);

export const folderViewReportsSelector = createSelector(
  folderViewsState,
  folderViews => folderViews.get('reports')
);

export const folderViewTotalsSelector = createSelector(
  folderViewsState,
  folderViews => ({
    totalCount: folderViews.get('totalCount'),
    filteredCount: folderViews.get('filteredCount')
  })
);

export const examplesFolderViewReportSelector = createSelector(
  folderViewsState,
  folderViews => folderViews.get(EXAMPLE_REPORTS_KEY)
);

export const folderPageSelector = createSelector(
  currentFolder,
  folderViewsSelector,
  folderViewReportsSelector,
  folderViewTotalsSelector,
  ({folder}, folderViews, folderViewReports, {totalCount, filteredCount}) => {
    if (folder && folder.get('folderId') !== 0 && folderViews.size > 1) {
      // remove fake "overview" screen for screens that aren't "All websites"
      folderViews = folderViews.filter(v => v.get('folderId') !== 0);
    }
    return {
      folder: toJS(folder),
      folderViews: toJS(folderViews),
      folderViewsData: toJS(folderViewReports),
      totalCount,
      filteredCount
    };
  }
);

export const folderViewPageSelector = createSelector(
  loadingSelector,
  filtersSelector,
  (loading, reportFilters) => ({loading, reportFilters})
);

export const exampleReportsViewSelector = createSelector(
  examplesFolderViewReportSelector,
  reports => ({
    exampleReports: toJS(reports)
  })
);

export const folderViewTabsSelector = createSelector(
  folderIdSelector,
  userSelector,
  accountViewsEnabledSelector,
  (folderId, user, folderViewsEnabled) => ({folderId, user, folderViewsEnabled})
);

export const folderViewModalSelector = createSelector(
  statOptionsSelector,
  stats => ({
    stats: toJS(stats)
  })
);

export const folderLastLocationSelector = createSelector(
  folderViewsState,
  state => state.get('lastLocation')
);
