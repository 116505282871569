import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import styles from './card.scss';
import filterInvalidDomProps from 'filter-invalid-dom-props';

class Card extends React.Component {
  static propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    label: PropTypes.string
  };

  render() {
    const {
      title,
      tooltip,
      label,
      children,
      headerChildren,
      className,
      onClick,
      stretch,
      maxImageHeight,
      border,
      href,
      small,
      square,
      ...props
    } = this.props;

    const classes = cx(styles.card, className, {
      [styles.small]: small,
      [styles.square]: square,
      [styles.border]: border,
      [styles.stretch]: stretch
    });

    const imageStyle = {};
    if (maxImageHeight) {
      imageStyle.height = maxImageHeight;
    }

    const fig = (
      <figure
        {...filterInvalidDomProps(props)}
        className={classes}
        onClick={onClick}
        title={tooltip}
      >
        {headerChildren}
        <div className={styles.image} style={imageStyle}>
          {this.renderImage()}
        </div>

        <div className={styles.caption}>
          <figcaption>
            <span className={styles.title}>{title}</span>

            {label && <span className={styles.subtitle}>{label}</span>}
          </figcaption>

          {children}
        </div>
      </figure>
    );

    if (!onClick && href) {
      return (
        <Link to={href} className={styles.linkWrapper}>
          {fig}
        </Link>
      );
    }

    return fig;
  }

  renderImage() {
    const {image, alt} = this.props;

    if (_.isString(image)) {
      return <img src={image} alt={alt} />;
    }

    return image;
  }
}

export default Card;
