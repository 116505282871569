import React from 'react';
import Field from '../hooks/useForm/components/Field';
import InputForType from './inputForType';
import filterFieldRules from 'modules/form/rules/filter';
import {ensureHasScheme} from 'utils/urlUtils';

export default function TypeField({field, label, children, show, ...fieldProps}) {
  const [rules, props] = filterFieldRules(fieldProps);
  const formatter = Formatters[props.type];

  return (
    <Field field={field} label={label} format={formatter} show={show} {...rules}>
      <InputForType
        {...field.input} // contains {value, id, onChange, onBlur, etc}
        {...props} // contains {options, rows, cols, etc} properies for specific fields
        invalid={show !== false && field.hasError() && field.wasAttempted()}
        name={field.key} // NOTE some fields like checkboxInput require a `name` for its htmlFor attr
      />

      {children}
    </Field>
  );
}

const Formatters = {
  url: url => ensureHasScheme(url)
};
