import styles from './actionScore.scss';
import React from 'react';

class ActionScore extends React.Component {
  render() {
    const {score} = this.props;
    if (!score) {
      return null;
    }
    const count = score < 0.01 ? '< 0.01' : score;

    return (
      <span>
        <span className={styles.count}>{count}</span> <span className={styles.text}>points</span>
      </span>
    );
  }
}

export default ActionScore;
