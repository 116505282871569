import {API} from 'config';
import isomorphicFetch from 'isomorphic-fetch';
import {select, call} from 'redux-saga/effects';
import {tokenSelector, accountIdSelector} from 'modules/auth/authSelectors';

// Generator wrapping fetch api and adding auth headers + api root to url
export default function* FETCH(url, options = {}, ...args) {
  url = API.ROOT + url;

  const headers = yield* getHeaders();
  options.headers = {...headers, ...options.headers};

  try {
    return yield call(isomorphicFetch, url, options, ...args);
  } catch (err) {
    if (__DEV__) {
      console.error(`Failed to fetch ${url}`, options, err);
    }
    return Response.error();
  }
}

function* getHeaders() {
  const token = yield select(tokenSelector);

  const accountId = yield select(accountIdSelector);

  const returnHeaders = {
    'Content-Type': 'application/json'
  };

  if (!token) {
    return returnHeaders;
  }

  return {
    ...returnHeaders,
    Authorization: authHeader(token, accountId)
  };
}

function authHeader(token, accountId) {
  const header = `${API.AUTH_SCHEME} token=${token}`;
  if (!accountId) {
    return header;
  }
  return header + `, accountId=${accountId}`;
}
