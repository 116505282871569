import React from 'react';
import {connect} from 'react-redux';
import {usersSelector} from 'modules/users/userSelectors';
import UserImage from 'components/user/userImage';
import styles from './user.scss';

export class User extends React.Component {
  render() {
    const {users, data} = this.props;

    if (!data) {
      return null;
    }

    const userData = users.get(data.toString());
    if (userData) {
      return (
        <span className={styles.wrapper}>
          <UserImage size={30} user={userData.toJS()} className={styles.user} />
          <span>{userData.get('name')}</span>
        </span>
      );
    }

    return 'Unknown';
  }
}

export default connect(
  state => ({
    users: usersSelector(state)
  }),
  {}
)(User);
