exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes Md9pgfrr52Pax5tD8SN52{0%{opacity:0}100%{opacity:1}}@keyframes Md9pgfrr52Pax5tD8SN52{0%{opacity:0}100%{opacity:1}}@-webkit-keyframes _1XLt9sWWDubCsVzloSExn3{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes _1XLt9sWWDubCsVzloSExn3{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}._3R5LAXv3aQk0a5nftIJDh1{display:inline-block}._1T5F4fXV4ScqU03mt7PRMn{padding:0;white-space:nowrap;display:inline-block;margin-right:10px;color:#030303;font-size:2.2em}@media(max-width: 800px){._1traE5LuSzylor_hEgd46x{font-size:2em}}", ""]);

// exports
exports.locals = {
	"regularValue": "_3R5LAXv3aQk0a5nftIJDh1",
	"bigValue": "_1T5F4fXV4ScqU03mt7PRMn",
	"value": "_1traE5LuSzylor_hEgd46x",
	"fade-in": "Md9pgfrr52Pax5tD8SN52",
	"rotating": "_1XLt9sWWDubCsVzloSExn3"
};