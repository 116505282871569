import React from 'react';
import styles from '../timeline.scss';
import Icon from 'components/font-awesome';
import {friendlyUrl} from 'utils/urlUtils';
import ImagePoller from 'components/image/imagePoller';
import {createInspectorPageLink} from 'modules/instructions/instructionUtils';
import ExternalLink from 'modules/location/externalLink';

export default function PagePointItem({
  storyHash,
  storyPointId,
  time,
  props,
  removed = false,
  doCommand
}) {
  const {pageHash, url, screenshotUrl, pageName} = props;

  return (
    <li
      className={styles.pagesListItem}
      onClick={event => {
        if (removed) {
          window.open(url, '_blank');
        } else {
          const command = createInspectorPageLink(pageHash);
          doCommand(command, props, {}, event);
        }
      }}
    >
      <ImagePoller src={screenshotUrl} width={150} />
      {!removed && <div className={styles.pageName}>{pageName}</div>}
      {removed && (
        <ExternalLink href={url} title={url} className={styles.pageName}>
          {friendlyUrl(url)} <Icon name="external-link" />
        </ExternalLink>
      )}
    </li>
  );
}
