import React from 'react';
import Grid from 'components/grid';
import CourseCard from './courseCard';

export function CoursesGrid({courses}) {
  return (
    <Grid targetWidth={300} center>
      {courses.toList().map(course => (
        <CourseCard key={course.get('courseId')} course={course} />
      ))}
    </Grid>
  );
}

export default CoursesGrid;
