import {Map, fromJS, List, OrderedMap} from 'immutable';
import {LOCATION_CHANGE} from 'connected-react-router';
import {LOGOUT, SWITCH_ACCOUNT} from 'modules/auth';
import {
  REPORTS_REQUEST,
  REPORTS_RESPONSE,
  UPDATE_REPORT,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  UPDATE_REPORT_RESET,
  RECEIVE_REPORTS,
  RECEIVE_REPORT,
  DELETE_REPORT,
  EXPORT_REPORT,
  EXPORT_REPORT_PDF,
  EXPORT_REPORT_COMPLETE,
  RECEIVE_CMS_CONFIG,
  CLEAR_CMS_CONFIG,
  TRIGGER_EXPORT_REPORT,
  SET_WATCHING_REPORT,
  CLEAR_REPORT,
  UPDATE_REPORT_SCHEDULE_VALUE,
  RECIEVE_REPORT_ASSIGNED_TAGS,
  RECEIVE_FORCED_PAGES,
  RECEIVE_FORCED_PAGE,
  DELETE_FORCED_PAGE,
  FETCH_FORCED_PAGES
} from './reportConstants';
import {CANCEL_MISSION} from 'modules/missions';
import {ORDER_COMPLETE} from 'modules/products';
import {isReportSettingsPath} from 'modules/settings/settingsUtils';
import {isInspectorPath} from 'modules/inspector/inspectorUtils';

const initialState = Map({
  loading: false,
  data: Map(),
  schedules: Map(),
  updatingReport: false,
  updateReportFailed: false,
  updateReportSuccess: false,
  cmsConfig: Map(),
  exporting: false,
  exportSuccessUrl: null,
  exportFailedMessage: null,
  lastLocation: null,
  forcedPages: Map(),
  forcedPage: null,
  forcedPagesLoading: false,
  forcedPagesPagination: null
});

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
    case SWITCH_ACCOUNT:
    case ORDER_COMPLETE:
      return initialState;

    case CANCEL_MISSION:
      return state.update('data', data => {
        return data.map(report => {
          return report.update('missions', missions =>
            !missions
              ? List()
              : missions.filter(mission => mission.get('missionId') !== action.missionId)
          );
        });
      });

    case CLEAR_REPORT:
      return state.deleteIn(['data', parseInt(action.reportId, 10)]);

    case RECIEVE_REPORT_ASSIGNED_TAGS: {
      const assignedTagsPath = ['data', parseInt(action.reportId, 10), 'assignedTags'];

      switch (action.operation) {
        case 'add':
          return state.updateIn(assignedTagsPath, List(), assignedTags => {
            return assignedTags.concat(fromJS(action.tagIds));
          });
        case 'remove':
          return state.updateIn(assignedTagsPath, List(), assignedTags => {
            return assignedTags.filter(assignedTagId => {
              return !action.tagIds.includes(assignedTagId);
            });
          });
        case 'set':
          return state.setIn(assignedTagsPath, fromJS(action.tagIds));
      }
    }

    case RECEIVE_CMS_CONFIG:
      return state.set('cmsConfig', fromJS(action.cmsConfig));

    case CLEAR_CMS_CONFIG:
      return state.set('cmsConfig', Map());

    case FETCH_FORCED_PAGES:
      return state.set('forcedPagesLoading', true);

    case RECEIVE_FORCED_PAGES:
      return state
        .set('forcedPagesLoading', false)
        .set('forcedPages', fromJS(action.forcedPages))
        .set('forcedPagesPagination', fromJS(action.pagination));

    case RECEIVE_FORCED_PAGE:
      return state.set('forcedPagesLoading', false).set('forcedPage', fromJS(action.forcedPage));

    case REPORTS_REQUEST:
      return state.set('loading', true);

    case REPORTS_RESPONSE:
      return state.set('loading', false);

    case UPDATE_REPORT:
      return state.set('updatingReport', true);

    case UPDATE_REPORT_SUCCESS:
      return state
        .set('updatingReport', false)
        .set('updateReportSuccess', true)
        .set('updateReportFailed', false);

    case UPDATE_REPORT_FAILURE:
      return state
        .set('updatingReport', false)
        .set('updateReportSuccess', false)
        .set('updateReportFailed', true);

    case UPDATE_REPORT_RESET:
      return state
        .set('updatingReport', false)
        .set('updateReportSuccess', false)
        .set('updateReportFailed', false);

    case RECEIVE_REPORTS:
      return state.update('data', data => {
        action.reports.forEach(report => {
          data = receiveReport(data, report);
        });
        return data;
      });

    case RECEIVE_REPORT:
      return state.update('data', data => receiveReport(data, action.report, action.overwrite));

    case DELETE_REPORT:
      return state.deleteIn(['data', action.reportId]);

    case DELETE_FORCED_PAGE:
      return state.deleteIn(['forcedPages', action.forcedPageId.toString()]);

    case EXPORT_REPORT:
    case EXPORT_REPORT_PDF:
      return state.set('exporting', true);

    case EXPORT_REPORT_COMPLETE:
      return state
        .set('exporting', false)
        .set('exportSuccessUrl', action.exportUrl)
        .set('exportFailedMessage', action.errorMessage);

    case TRIGGER_EXPORT_REPORT:
      return state
        .set('exporting', false)
        .set('exportSuccessUrl', null)
        .set('exportFailedMessage', null);

    case LOCATION_CHANGE: {
      const {pathname} = action.payload.location;

      // just remember the last report path
      if (!isReportSettingsPath(pathname) && !isInspectorPath(pathname)) {
        return state.set(
          'lastLocation',
          action.payload.location.pathname + action.payload.location.search
        );
      }
      return state;
    }

    case SET_WATCHING_REPORT:
      return state.setIn(['data', action.reportId, 'isWatching'], action.isWatching);

    case UPDATE_REPORT_SCHEDULE_VALUE:
      return state.setIn(
        ['schedules', action.reportId],
        Map({
          schedulePeriod: action.schedulePeriod,
          scheduleInterval: action.scheduleInterval
        })
      );
  }

  return state;
}

function receiveReport(state, reportData, overwrite = false) {
  // remove tests,and missions from report without mutating.
  // these are stored by other reducers.
  const {tests, missions, configOptions, ...report} = reportData;

  if (overwrite) {
    return state.setIn([report.reportId], fromJS(report));
  }

  return state.mergeDeepIn([report.reportId], fromJS(report));
}
