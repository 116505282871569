export const REQUEST_TAGS = 'tags:REQUEST_TAGS';
export const REQUEST_TAGS_COMPLETE = 'tags:REQUEST_TAGS_COMPLETE';

export const REQUEST_TAGS_SUMMARY = 'tags:REQUEST_TAGS_SUMMARY';
export const REQUEST_TAGS_SUMMARY_COMPLETE = 'tags:REQUEST_TAGS_SUMMARY_COMPLETE';

export const CREATE_TAG = 'tags:CREATE_TAG';
export const CREATE_TAG_COMPLETE = 'tags:CREATE_TAG_COMPLETE';

export const REQUEST_ASSIGNED_TAGS = 'tags:REQUEST_ASSIGNED_TAGS';
export const REQUEST_ASSIGNED_TAGS_COMPLETE = 'tags:REQUEST_ASSIGNED_TAGS_COMPLETE';

export const REQUEST_ASSIGNED_ENTITIES = 'tags:REQUEST_ASSIGNED_ENTITIES';
export const REQUEST_ASSIGNED_ENTITIES_COMPLETE = 'tags:REQUEST_ASSIGNED_ENTITIES_COMPLETE';

export const ASSIGN_TAGS = 'tags:ASSIGN_TAGS';
export const ASSIGN_TAGS_COMPLETE = 'tags:ASSIGN_TAGS_COMPLETE';

export const UNASSIGN_TAGS = 'tags:UNASSIGN_TAGS';
export const UNASSIGN_TAGS_COMPLETE = 'tags:UNASSIGN_TAGS_COMPLETE';

export const EDIT_TAG = 'tags:EDIT_TAG';
export const EDIT_TAG_COMPLETE = 'tags:EDIT_TAG_COMPLETE';

export const VIEW_DELETE_TAG = 'tags:VIEW_DELETE_TAG';
export const DELETE_TAG_COMPLETE = 'tags:DELETE_TAG_COMPLETE';
