import React from 'react';
import PropTypes from 'prop-types';
import TickIcon from 'components/layoutComponents/tickIcon';
import styles from './tickList.scss';
import HollowTick from 'modules/superProduct/images/tick-icon.png';

const propTypes = {
  children: PropTypes.node.isRequired
};

const tickListItem = ({children, hollow}) => (
  <li className={styles.item}>
    {hollow ? <img src={HollowTick} /> : <TickIcon />}
    {children}
  </li>
);

tickListItem.propTypes = propTypes;
tickListItem.defaultProps = {
  hollow: false
};

export default tickListItem;
