import React from 'react';

class EmailIssue extends React.Component {
  getEmail = () => {
    const {
      point: {email, detail}
    } = this.props;

    return email || detail;
  };

  render() {
    const email = this.getEmail();
    return (
      <div>
        <p>
          <a href={'mailto:' + email}>{email}</a>
        </p>
      </div>
    );
  }
}

export default EmailIssue;
