import React from 'react';
import {connect} from 'react-redux';
import {replaceUpdateQuery} from 'modules/location';
import {fetchInspectorPage} from 'modules/inspector/inspectorActions';
import {inspectorContextSelector} from 'modules/inspector/inspectorSelectors';
import Context from 'modules/inspector/components/context';
import Flex from 'components/layout/flex';

class InspectorContext extends React.Component {
  // TODO I guess this would be considered hacky, make a React Context or something?
  chellroot = document.getElementById('chellroot');

  // navigate to an inspector page by page hash
  handleNavigateToPage = pageHash => {
    const {fetchInspectorPage, replaceUpdateQuery, params, query} = this.props;
    const newQuery = {...query, pageHash};

    replaceUpdateQuery(newQuery);
    fetchInspectorPage(params, newQuery);
  };

  // nagivate to inspector page via "next" or "previous" page
  handleNavigateRelative = relative => {
    const {fetchInspectorPage, params, query} = this.props;
    fetchInspectorPage(params, {...query, relative});
  };

  handleShowPagesStateChange = isOpen => () => {
    // TODO This is hacky. The alternative is to add context at a higher level that can be set at a lower level.
    // Add a blur effect to everything in chell (won't affect the overlay window which is outside #chellroot)
    this.chellroot.style.filter = isOpen ? 'blur(5px)' : '';
  };

  render() {
    const {context, hasContext} = this.props;
    if (!hasContext || !context) return null;

    const {pages, numPages, pageNumber} = context;

    if (numPages <= 1) return null;

    return (
      <Flex grow={0} left="5px">
        <Context
          pages={pages}
          numPages={numPages}
          pageNumber={pageNumber}
          onClickPage={this.handleNavigateToPage}
          onNavigateRelative={this.handleNavigateRelative}
          onOpenPages={this.handleShowPagesStateChange(true)}
          onClosePages={this.handleShowPagesStateChange(false)}
        />
      </Flex>
    );
  }
}

export default connect(
  inspectorContextSelector,
  {fetchInspectorPage, replaceUpdateQuery}
)(InspectorContext);
