import {fromJS} from 'immutable';

const initialState = fromJS({
  npsDue: null,
  isIdle: false,
  isOpen: false,
  action: ''
});

export default function npsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NPS_DUE:
      return state.set('npsDue', action.npsDue);

    case SET_IDLE:
      return state.set('isIdle', action.isIdle);

    case SET_NPS_ENABLE:
      return state.set('isOpen', action.isEnabled).set('action', action.action);
  }
  return state;
}

export const SET_NPS_DUE = 'nps:SET_NPS_DUE';
export function setNPSDue(npsDue) {
  return {type: SET_NPS_DUE, npsDue};
}

export const SET_IDLE = 'nps:SET_IDLE';
export function setIdle(isIdle) {
  return {type: SET_IDLE, isIdle};
}

export const SET_NPS_ENABLE = 'nps:SET_NPS_ENABLE';
export function setNPSEnable(isEnabled, action = '') {
  // only allow the action to be set when opening the popup
  return {type: SET_NPS_ENABLE, isEnabled, action: isEnabled ? action : ''};
}

export const SET_NPS_SCORE = 'nps:SET_NPS_SCORE';
export function setNPSScore(score, reason, contactMe, shouldThank = true) {
  return {type: SET_NPS_SCORE, score, reason, contactMe, shouldThank};
}
