import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {validateUnlockUserTokenEndpoint} from 'modules/passwords/passwordUtils';
import {
  VALIDATE_UNLOCK_USER_TOKEN,
  VALIDATE_UNLOCK_USER_TOKEN_SUCCESS,
  VALIDATE_UNLOCK_USER_TOKEN_FAILURE
} from 'modules/passwords';

export default function* validateUnlockUserTokenSaga() {
  yield takeLatest(VALIDATE_UNLOCK_USER_TOKEN, validateUnlockUserToken);
}

function* validateUnlockUserToken({userId, token}) {
  yield processRequest(
    'POST',
    validateUnlockUserTokenEndpoint({userId}),
    {
      success: function*({success}) {
        yield put({
          type: success ? VALIDATE_UNLOCK_USER_TOKEN_SUCCESS : VALIDATE_UNLOCK_USER_TOKEN_FAILURE
        });
      },
      error: function*() {
        yield put({type: VALIDATE_UNLOCK_USER_TOKEN_FAILURE});
      }
    },
    {emailToken: token}
  );
}
