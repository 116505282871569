import {put, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {READ_ACHIEVEMENT} from 'modules/achievements';
import {readAchievementEndpoint} from 'modules/achievements/achievementUtils';

export default function* readAchievementsSaga() {
  yield takeEvery(READ_ACHIEVEMENT, onReadAchievement);
}

function* onReadAchievement({achievementId}) {
  yield readAchievement(achievementId);
}

function* readAchievement(achievementId) {
  yield processRequest('POST', readAchievementEndpoint({achievementId}), {
    success: function*() {
      return null;
    },
    error: function*(err) {
      throw err;
    }
  });
}
