import {call, takeLatest} from 'redux-saga/effects';
import {storeAuthToken, storeAccountId, clearAuth} from 'utils/authenticationUtils';
// Stores auth token and accountId in LocalStorage.

import {LOGIN, LOGIN_FAILED, LOGOUT, SWITCH_ACCOUNT} from 'modules/auth';

export default function* persistAuth() {
  yield takeLatest([LOGIN, LOGOUT, LOGIN_FAILED, SWITCH_ACCOUNT], storeInLocalStorage);
}

function* storeInLocalStorage({type, user, token, accountId}) {
  try {
    // Clear auth on logout.
    if (type === LOGOUT || type === LOGIN_FAILED) {
      yield call(clearAuth);
      return;
    }

    // accountId is in result for LOGIN but root for SWITCH_ACCOUNT
    if (type === LOGIN) {
      accountId = user.accountId;
    }

    if (accountId) {
      yield call(storeAccountId, accountId);
    }

    if (token && !user.virtualType) {
      yield call(storeAuthToken, token);
    }
  } catch (e) {
    // Can be cancelled.
  }
}
