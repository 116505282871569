import PropTypes from 'prop-types';
import styles from './reason.scss';
import cx from 'classnames';
import React from 'react';
import RelativeDate from 'components/time/relativeDate';

class Reason extends React.Component {
  render() {
    const {inline, reason, untilDate} = this.props;

    if (!reason && untilDate == 0) return <span />;

    const className = cx({
      [styles.reasonInline]: inline,
      [styles.reasonBlock]: !inline
    });

    return (
      <div className={className}>
        <span className={styles.label}>Reason: </span>
        {reason}
        {untilDate > 0 && (
          <span>
            {' '}
            <RelativeDate prefix="Expires " className={styles.date} timestamp={untilDate} />
          </span>
        )}
      </div>
    );
  }
}

export default Reason;
