import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';
import ImagePoller from 'components/image/imagePoller';
import Spinner from 'components/spinner';
import styles from './pageScreenshot.scss';
import Tooltip from 'components/tooltip';

class PageScreenshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0
    };
  }

  getImageSrc(quality) {
    const {src} = this.props;
    if (!src) return '';

    let args = ['.png', '.jpg']; // jpg is low quality

    if (quality) {
      args = args.reverse();
    }

    return src.replace(...args);
  }

  calcSize = () => {
    const el = findDOMNode(this);
    this.setState({width: el.offsetWidth});
  };

  componentDidMount() {
    window.addEventListener('resize', this.calcSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calcSize);
  }

  getHeight() {
    const {aspectRatio} = this.props;
    const {width} = this.state;

    if (!aspectRatio || !width || width === 0) return null;

    return parseInt(width / aspectRatio, 10);
  }

  render() {
    const style = {};
    const height = this.getHeight();
    const {quality, expandable} = this.props;

    if (height) {
      style.height = height + 'px';
    }

    const poller = (
      <ImagePoller
        style={style}
        key={this.getImageSrc(quality)}
        {...this.props}
        src={this.getImageSrc(quality)}
        onLoad={this.calcSize}
      >
        <div className={styles.imgPlaceholder}>
          <Spinner centered className={styles.spinner} />
        </div>
      </ImagePoller>
    );

    if (!expandable) {
      return poller;
    }

    return (
      <Tooltip text={<img src={this.getImageSrc(true)} className="img-responsive" />}>
        {poller}
      </Tooltip>
    );
  }
}

PageScreenshot.propTypes = {
  src: PropTypes.string.isRequired,
  quality: PropTypes.oneOf([
    0, // low
    1 // high
  ]),
  aspectRatio: PropTypes.number,
  expandable: PropTypes.bool
};

PageScreenshot.defaultProps = {
  quality: 0,
  expandable: false
};

export default PageScreenshot;
