import React from 'react';
import {connect} from 'react-redux';
import {openHelp} from 'modules/help/actions';
import HelpComponent from 'components/help';

function ConnectedHelp({openHelp, ...props}) {
  return (
    <HelpComponent
      {...props}
      onClick={() => {
        openHelp(props.url);
      }}
    />
  );
}

export default connect(
  null,
  {openHelp}
)(ConnectedHelp);
