import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {mapWidgetTypeOption} from 'modules/dashboards/utils';
import {makeLoadingStateSelector} from 'modules/app/reduxHelpers';

export const domainSelector = state => state.dashboards;

// prop selectors
export const dashboardIdPropSelector = (_, props) =>
  props.dashboardId && props.dashboardId.toString();
export const contextTypeIdPropSelector = (_, props) => props.contextTypeId;
export const widgetIdPropSelector = (_, props) => props.widgetId;

// data selector
export const dashboardsSelector = createSelector(
  domainSelector,
  state => state.get('dashboards')
);

export const dashboardSelector = createSelector(
  domainSelector,
  dashboardIdPropSelector,
  (state, dashboardId) => state.getIn(['dashboards', dashboardId])
);

export const dashboardResultsSelector = createSelector(
  domainSelector,
  dashboardIdPropSelector,
  (state, dashboardId) => state.getIn(['results', dashboardId], Map())
);

export const widgetTypesSelector = createSelector(
  domainSelector,
  state => state.get('widgetTypes')
);

// loading state selectors
export const savingWidgetSelector = makeLoadingStateSelector(domainSelector, 'savingWidget');
export const savingDashboardSelector = makeLoadingStateSelector(domainSelector, 'savingDashboard');
export const deletingDashboardSelector = makeLoadingStateSelector(
  domainSelector,
  'deletingDashboard'
);
export const requestingDashboardSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingDashboard'
);
export const requestingDashboardsSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingDashboards'
);
export const requestingDashboardResultsSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingDashboardResults'
);
export const requestingWidgetTypesSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingWidgetTypes'
);

export const widgetSelector = createSelector(
  dashboardSelector,
  widgetIdPropSelector,
  (dashboard, widgetId) => {
    return dashboard.get('widgets').find(widget => widget.get('widgetId') === widgetId);
  }
);

export const widgetTypesContextSelector = createSelector(
  contextTypeIdPropSelector,
  widgetTypesSelector,
  (contextTypeId, widgetTypes) => {
    return widgetTypes.filter(widgetType => {
      return widgetType.get('contextTypeId') === contextTypeId;
    });
  }
);

export const widgetOptionsSelector = createSelector(
  widgetTypesContextSelector,
  widgetTypes => {
    return widgetTypes.toList().map(mapWidgetTypeOption);
  }
);

export const dashboardWidgetsSelector = createSelector(
  dashboardSelector,
  dashboardResultsSelector,
  (_, props) => props.rowIndex,
  (_, props) => props.columnIndex,
  (dashboard, results, rowIndex, columnIndex) => {
    return (
      dashboard
        .get('widgets')
        // add results to each widget
        .map(widget => {
          return widget.set('result', results.get(widget.get('widgetId').toString(), null));
        })
        // only return widgets in my column
        .filter(widget => widget.get('row') === rowIndex && widget.get('column') === columnIndex)
        .sort((widgetA, widgetB) => widgetA.get('order') - widgetB.get('order'))
    );
  }
);

export const dashboardNumWidgetsSelector = createSelector(
  dashboardSelector,
  (_, props) => props.rowIndex,
  (dashboard, rowIndex) => {
    return dashboard.get('widgets').filter(widget => {
      return widget.get('row') == rowIndex && widget.get('order') == 0;
    }).size;
  }
);
