import React from 'react';
import Quiz from '../quizes/quiz';
import styles from './lessonTypes.scss';

function QuizLessonType({...props}) {
  return (
    <div>
      <div className={styles.readingArea}>
        <p>
          Answer the following questions then press the <b>Submit answers</b> button:
        </p>
        <hr />
        <Quiz {...props} />
      </div>
    </div>
  );
}

export default QuizLessonType;
