import React from 'react';
import {List} from 'immutable';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {hideModal} from 'modules/modal';
import {createStructuredSelector} from 'reselect';
import {fetchReports} from 'modules/reports';
import {useForm, TypeField} from 'modules/form/hooks/useForm';
import {reportsOptionsSelector} from 'modules/reports/reportSelectors';
import {makeDefaultData} from 'modules/policies/policyUtils';
import {requestLibraryPolicy, saveLibraryPolicy} from 'modules/policies/actions';
import {libraryPolicySelector} from 'modules/policies/selectors';
import ModalContent from 'modules/modal/components/modalContent';
import Spinner from 'components/spinner';
import SchemaFields from 'modules/form/components/schemaField';

const selector = createStructuredSelector({
  reportsOptions: reportsOptionsSelector,
  libraryPolicy: libraryPolicySelector
});

export class NewLibraryPolicyModal extends React.Component {
  componentDidMount() {
    this.props.fetchReports(true, ['name', 'reportId']);
    this.props.requestLibraryPolicy(this.props.libraryPolicyId);
  }

  handleSubmit = data => {
    const {options, ...rest} = data;
    this.props.saveLibraryPolicy(this.props.libraryPolicyId, rest, options, false, () => {
      this.props.hideModal();
    });
  };

  render() {
    const {reportsOptions, libraryPolicy} = this.props;
    if (!libraryPolicy) return null;

    if (libraryPolicy.get('libraryPolicyId') !== this.props.libraryPolicyId) {
      return (
        <ModalContent buttons={['Add policy']} onClickButton={() => {}}>
          <Spinner />
        </ModalContent>
      );
    }

    const supportedOptions = libraryPolicy.get('supportedOptions', List()).toJS();
    const defaultValue = {
      applyToAllReports: true,
      reports: [],
      name: libraryPolicy.get('defaultName'),
      options: makeDefaultData(supportedOptions)
    };

    return (
      <LibraryPolicyModalForm
        initialValue={defaultValue}
        libraryPolicy={libraryPolicy}
        reportsOptions={reportsOptions}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

function LibraryPolicyModalForm({initialValue, libraryPolicy, reportsOptions, onSubmit}) {
  const [form] = useForm(initialValue);

  return (
    <ModalContent
      title={libraryPolicy.get('name')}
      buttons={['Add policy']}
      onClickButton={buttonId => {
        if (buttonId === 'Add policy') {
          if (form.attemptSubmit()) {
            onSubmit(form.getValue());
          }
        }
      }}
    >
      <LibraryPolicyForm
        form={form}
        libraryPolicy={libraryPolicy}
        reportsOptions={reportsOptions}
      />
    </ModalContent>
  );
}

function LibraryPolicyForm({form, libraryPolicy, reportsOptions}) {
  return (
    <div>
      <p>{libraryPolicy.get('description')}</p>

      <TypeField label="Name" type="text" field={form.fields.name} required />
      <br />
      <TypeField
        label="Apply to all websites?"
        type="checkbox"
        field={form.fields.applyToAllReports}
      />

      {!form.fields.applyToAllReports.getValue() && (
        <TypeField
          label="Select reports"
          field={form.fields.reports}
          type="listbox"
          options={reportsOptions.toJS()}
          required
          disableSort
          isLoading={reportsOptions.isEmpty()}
        />
      )}

      {!!libraryPolicy.get('supportedOptions', List()).size && (
        <React.Fragment>
          <hr />
          <h3>Policy options</h3>

          <SchemaFields
            field={form.fields.options}
            supportedOptions={libraryPolicy.get('supportedOptions', List())}
            onAddRepeatingField={(fieldSchema, field) => {
              const defaultData = makeDefaultData(fieldSchema.get('children').toJS(), {});
              field.push(defaultData);
              field.rerender();
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default connect(
  selector,
  {hideModal, push, fetchReports, requestLibraryPolicy, saveLibraryPolicy}
)(NewLibraryPolicyModal);
