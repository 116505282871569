import _ from 'lodash';
import {fromJS, List, Map} from 'immutable';
import {ENVIRONMENT} from 'config';

export function niceUrl(url, noTrailingSlash, maxLength = 100) {
  url = url.replace(/^https?:\/\//, '');

  if (noTrailingSlash) {
    if (url.substr(-1) === '/') {
      url = url.substr(0, url.length - 1);
    }
  }

  if (maxLength && url.length > maxLength) {
    url = url.substr(0, maxLength - 4) + ' ...';
  }

  return url;
}

export function isSecure(url) {
  return url.indexOf('https://') === 0;
}

export function getFilename(url) {
  const filename = url.substring(url.lastIndexOf('/') + 1);
  return filename || '/';
}

export function makePath(path, query = {}) {
  const qs = queryString(query);
  return !qs ? path : path + '?' + qs;
}

// Adds the http:// scheme to the input if it doesn't already have a scheme
export function ensureHasScheme(url) {
  if (!/^https?:\/\//.test(url)) {
    return `http://${url}`;
  }
  return url;
}

export function getGoogleSearchUrl(searchTerm) {
  return 'https://www.google.com/search?q=' + encodeURIComponent(searchTerm);
}

export function getGlobalDictionarySearchUrl(word, country, language) {
  const tcUrl =
    ENVIRONMENT === 'local'
      ? 'http://testchamber.marvel.test'
      : 'https://testchamber.insites.com:8443';

  return (
    tcUrl +
    '/global-dictionary/add?country=' +
    encodeURIComponent(country) +
    '&language=' +
    encodeURIComponent(language) +
    '&words=' +
    encodeURIComponent(word)
  );
}

export function queryString(obj, prefix = '') {
  var str = [],
    p;
  for (p in obj) {
    var k = prefix ? prefix + '[' + p + ']' : p,
      v = obj[p];

    if (typeof v == 'undefined') {
      continue;
    }

    str.push(
      v !== null && typeof v === 'object'
        ? queryString(v, k)
        : encodeURIComponent(k) + '=' + encodeURIComponent(v)
    );
  }
  return str.filter(Boolean).join('&');
}

export function addQueryString(path, query = {}) {
  let existingQueryParams = {};
  const queryPosition = path.indexOf('?');

  if (queryPosition >= 0) {
    existingQueryParams = parseQuery(path);
    path = path.slice(0, queryPosition);
  }

  // remove undefined values from the query object
  const filteredQuery = _.reduce(
    query,
    (reduction, value, key) => {
      if (value != null) {
        reduction[key] = value;
      }
      return reduction;
    },
    existingQueryParams
  );

  if (_.isEmpty(filteredQuery)) return path;

  const qs = queryString(filteredQuery);
  return qs ? path + '?' + qs : path;
}

export function parseQuery(queryString, {convertBool = false} = {}) {
  let query = fromJS({});
  if (!queryString) return query.toJS();

  let path;
  if (queryString.indexOf('?') >= 0) {
    [path, queryString] = queryString.split('?', 2);
  }

  const pairs = queryString.split('&');
  for (var i = 0; i < pairs.length; i++) {
    const [key, value] = pairs[i].split('=');
    const val = decodeURIComponent(value);
    const k = decodeURIComponent(key);
    const isArray = /^\w+(?:\[[^\[\]\s]*\])+$/.test(k);

    if (isArray) {
      const keys = getArrayKeys(k);
      query = presetLists(query, keys);

      if (val === 'true' && convertBool) {
        query = query.setIn(keys, true);
      } else if (val === 'false' && convertBool) {
        query = query.setIn(keys, false);
      } else {
        query = query.setIn(keys, val);
      }
    } else {
      if (val === 'true' && convertBool) {
        query = query.set(k, true);
      } else if (val === 'false' && convertBool) {
        query = query.set(k, false);
      } else {
        query = query.set(k, val);
      }
    }
  }
  return query.toJS();
}

function getArrayKeys(str) {
  const [first, ...rest] = str.replace(/\]$/, '').split('][');
  return [...first.split('['), ...rest].map(val => {
    if (/\d+/.test(val)) {
      return parseInt(val, 10);
    }
    return val;
  });
}

// returns the last portions of the url
export function friendlyUrl(url, noParts = 2) {
  return url
    .split('/')
    .slice(-noParts)
    .join('/');
}

// we have an immutable Collection and we want to set a deep value like ['prop', 2, 'prop']
// if we do this though, the `2` will not be created as a list, but as a map.
// to fix this, we detect integers and set the list up first
function presetLists(map, keys, defaultValue = null) {
  let build = [];

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    const next = keys[i + 1];
    build.push(key);

    const nextIsInteger = typeof next === 'number' && parseInt(next.toString(), 10) === next;
    const val = nextIsInteger ? List() : !next ? defaultValue : Map();

    map = map.setIn(build, val);
  }
  return map;
}
