import React from 'react';
import {getScoreType} from 'components/score';
import styles from './sidebarScore.scss';
import cx from 'classnames';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Icon from 'components/font-awesome';

export class SidebarScore extends React.Component {
  static defaultProps = {
    locked: false
  };

  render() {
    const {children: value, className, locked, ...props} = this.props;

    const classes = cx(
      className,
      styles.score
      // styles[getScoreType(value)]
    );

    return (
      <div {...filterInvalidDOMProps(props)} className={classes}>
        {locked ? <Icon name="lock" size="lg" /> : Math.floor(value)}
      </div>
    );
  }
}

export default localStyles(styles)(SidebarScore);
