import {put, select, takeLatest} from 'redux-saga/effects';
import {FETCH_REPORT_SCHEDULE} from 'modules/reports/reportConstants';
import {updateReportScheduleValue} from 'modules/reports';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* fetchReportScheduleSaga() {
  yield takeLatest(FETCH_REPORT_SCHEDULE, fetchReportSchedule);
}

function* fetchReportSchedule({reportId}) {
  const url = 'report-schedules/' + reportId;

  try {
    const response = yield processRequest('GET', url);
    yield put(updateReportScheduleValue(reportId, response));
  } catch (err) {
    yield put(updateReportScheduleValue(reportId, {}));
  }
}
