import React from 'react';
import styles from './pages/pages.scss';
import SilktideLogo from 'modules/quote/images/silktide-logo.png';

const QuoteTopBar = function(quote) {
  return (
    <div className={styles.topBar}>
      <img src={SilktideLogo} alt="Silktide" />
      <h3>Proposal for {quote.quote.account.organizationName}</h3>
    </div>
  );
};

export default QuoteTopBar;
