export default {
  ESCAPE: 27,
  ENTER: 13,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  ZERO: 48,
  NINE: 57,
  F: 70,
  N: 78
};
