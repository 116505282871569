import React from 'react';

class Filter extends React.Component {
  render() {
    return null; // Intentionally invisible
  }
}

Filter.handlesLoading = true;

export default Filter;
