import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import SelectInput from './selectInput';
import {usersSelector} from 'modules/users/userSelectors';
import {toJS} from 'utils/immutableUtils';

export class UserInput extends React.Component {
  render() {
    const {users} = this.props;

    if (!users) {
      return null;
    }

    const options = [
      {label: 'All users', value: ''},
      ..._.values(users).map(user => ({
        label: user.name,
        value: user.userId
      }))
    ];

    return <SelectInput {...this.props} options={options} />;
  }
}

export default connect(state => ({
  users: toJS(usersSelector(state))
}))(UserInput);
