import {fromJS} from 'immutable';
import {REQUEST_BALANCES, REQUEST_BALANCES_COMPLETE, RECEIVE_BALANCES} from './constants';

const initialState = fromJS({
  isRequesting: false,
  lastRequested: null,
  data: {}
});

export default function balancesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BALANCES:
      return state.set('isRequesting', true);

    case REQUEST_BALANCES_COMPLETE:
      return state.set('isRequesting', false).set('lastRequested', Date.now());

    case RECEIVE_BALANCES:
      return state.set('data', fromJS(action.balances));
  }
  return state;
}
