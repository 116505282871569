import React from 'react';
import {connect} from 'react-redux';
import HelpComponent from 'components/help';
import styles from 'components/help/help.scss';
import {openHelp} from 'modules/help/actions';

export class Help extends React.Component {
  handleClickHelp = () => {
    const {url} = this.props.config;
    if (url) this.props.openHelp(url);
  };

  render() {
    const {label, align} = this.props.config;

    return (
      <HelpComponent
        className={styles[align]}
        message={label || 'View help'}
        onClick={this.handleClickHelp}
      />
    );
  }
}

export default connect(
  null,
  {openHelp}
)(Help);
