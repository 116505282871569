import _ from 'lodash';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';
import React from 'react';
import Device from 'components/device';
import {deviceSelector} from 'modules/inspector/inspectorSelectors';
import Spinner from 'components/spinner';
import InteractiveIframe from 'components/iframe/interactiveIframe';
import styles from './inspectorView.scss';
import {EFFECT_CONFIG} from '../inspectorConstants';
// import SlideBetween from 'components/animation/slideBetween';
// import {isIE, isEdge, isFirefox} from 'utils/browserSniff';
// import Markdown from 'remarkable';

export class InspectorView extends React.Component {
  getViewFrame(id, html) {
    if (!html) {
      return null;
    }
    const {
      currentPointIds,
      action,
      actionId,
      categoryId,
      noRedirectInspectorOnMissing,
      allPoints,
      pageView,
      account,
      scale,
      enabledEffects
    } = this.props;

    const data = {
      id,
      // always set the scale to 1 if viewing on desktop (scale only applies to mobile views)
      scale:
        pageView.toLowerCase() === 'desktop' || pageView.toLowerCase() === 'source' ? 1 : scale,
      noRedirectInspectorOnMissing: noRedirectInspectorOnMissing,
      allPoints: allPoints, // for all actions - I.E we want to highlight all the issues on the page
      // the current action (if one is active)
      actionId,
      categoryId,
      currentAction: action && {
        name: action.name
        // description: this.getMarkdown(action.description)
      },
      // the inspector frame accepts 'page' or 'source'.
      // this works because all the other page views ('iphone5Portrait', 'desktop' etc are 'page')
      currentView: pageView === 'source' ? 'source' : 'page',
      currentPoints: currentPointIds,
      accountId: account && account.get('accountId'),
      effects: enabledEffects.reduce((effects, effectId) => {
        Object.assign(effects, EFFECT_CONFIG[effectId]);
        return effects;
      }, {})
    };
    const actions = {
      selectView: this.handleChangeView,
      selectIssue: this.handleClickIssue,
      navInspector: this.handleNavInspector,
      cannotShowIssueInspector: this.handleIssueNotFound
    };

    return <InteractiveIframe data={data} actions={actions} html={html} />;
  }

  // TODO fix semantics
  handleClickIssue = pointIds => {
    this.props.onSelectIssue(pointIds);
  };

  handleNavInspector = url => {
    window.open(url, '_blank');
  };

  handleChangeView = pageView => {
    this.props.onSelectView(pageView);
  };

  handleIssueNotFound = _.debounce(() => {
    this.props.onIssueNotFound();
  }, 100);

  render() {
    const {pageView, device, viewHtml, sourceHtml, sourceHeadHtml} = this.props;

    return (
      <React.Fragment>
        {pageView !== 'source' && viewHtml && (
          <Device model={device}>{this.getViewFrame('page', viewHtml)}</Device>
        )}
        {pageView === 'source' && sourceHtml && (
          <div className={styles.pageViewport}>
            {this.getViewFrame('source', sourceHeadHtml + sourceHtml)}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  createStructuredSelector({
    account: accountSelector,
    device: deviceSelector
  })
)(InspectorView);
