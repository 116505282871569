import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'components/spinner';
import TextSpinner from 'components/spinner/textSpinner';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import Panel from 'components/panel';
import Pill from 'components/pill';
import {parseQuery} from 'utils/urlUtils';
import Margin from 'components/margin';
import {capitalizeFirstLetter} from 'utils/stringUtils';
import {testInteractionPageSelector} from 'modules/interactions/selectors';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import InteractionComputedSource from '../components/interactionComputedSource';
import {testInteraction, viewInteraction, viewInteractions, clearTestResults} from '../actions';

class TestInteractionPage extends React.Component {
  handleClickRun = event => {
    const {testInteraction, match} = this.props;
    testInteraction({interactionId: match.params.interactionId});
  };

  componentWillUnmount() {
    this.props.clearTestResults();
  }

  componentDidMount() {
    const {testInteraction, match} = this.props;
    testInteraction({interactionId: match.params.interactionId});
  }

  render() {
    const {
      match,
      location,
      interaction,
      interactionTestResults,
      loadingState,
      runLoadingState
    } = this.props;

    if (!loadingState.get('loaded')) {
      if (!loadingState.get('loading')) {
        return null;
      }

      return <Spinner marginTop={100} />;
    }

    // we have loaded
    if (loadingState.get('loading')) {
      // we are reloading. IE we already have data but are loading again
      return <Spinner marginTop={100} />;
    }

    if (loadingState.get('error')) {
      // TODO show nice error
      return null;
    }

    return (
      <WithReportSidebar match={match} location={location}>
        <div>
          <Helmet title="Testing interaction" />

          <PageHeader
            title={
              <React.Fragment>
                {'Testing ' + interaction.getIn(['interaction', 'name']) + ' '}
                {!interactionTestResults.isEmpty() && (
                  <Margin marginLeft={10}>
                    <Pill
                      appearance={interactionTestResults.get('status') == 'ok' ? 'good' : 'bad'}
                    >
                      {capitalizeFirstLetter(interactionTestResults.get('status'))}
                    </Pill>
                  </Margin>
                )}
              </React.Fragment>
            }
            goBack={() => {
              const query = parseQuery(location.search);

              if (query.time > 0 && query.interactionId > 0) {
                this.props.viewInteraction(match.params.interactionId);
              } else {
                this.props.viewInteractions();
              }
            }}
          />

          {runLoadingState.get('loading') && (
            <TextSpinner marginTop={100} marginBottom={100}>
              Testing interaction. This can take over a minute for complicated instructions.
            </TextSpinner>
          )}

          <PageBody noPaddingBottom>
            {interactionTestResults &&
              interactionTestResults.has('stageResults') &&
              interactionTestResults.get('stageResults').map((stage, i, results) => {
                return (
                  <React.Fragment key={stage.get('name')}>
                    <div style={{marginBottom: '20px'}}>
                      <Panel>
                        <h3 style={{margin: 0}}>
                          Stage {i + 1} of {results.size} - {stage.get('name')} (took{' '}
                          {stage.get('elapsed')} seconds)
                        </h3>
                      </Panel>
                      <Panel hideBorderTop hidePadding>
                        {stage.get('hasScreenshot') && (
                          <img
                            style={{width: '100%', display: 'block'}}
                            src={stage.get('screenshot')}
                          />
                        )}
                      </Panel>
                    </div>

                    {stage.has('computedSource') && (
                      <InteractionComputedSource source={stage.get('computedSource', '')} />
                    )}
                  </React.Fragment>
                );
              })}
          </PageBody>
        </div>
      </WithReportSidebar>
    );
  }
}

export default connect(
  testInteractionPageSelector,
  {testInteraction, viewInteraction, viewInteractions, clearTestResults}
)(TestInteractionPage);
