import PropTypes from 'prop-types';
import React from 'react';
import Pill from 'components/pill';

class Count extends React.Component {
  render() {
    const {data, options, className} = this.props;

    return (
      <Pill className={className} {...options} numeric>
        {data}
      </Pill>
    );
  }
}

Count.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default Count;
