import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {push} from 'modules/location';
import {resetUpdatingReport, viewReportSettingsPage} from 'modules/reports';
import {reportSettingsRouteSelector} from 'modules/reports/reportSelectors';
import SettingsPage from '../pages/settings/reportSettingsPage';
import ReportSettingsGeneralPage from '../pages/settings/reportSettingsGeneralPage';
import ReportSettingsCMSPage from '../pages/settings/reportSettingsCMSPage';
import ReportSettingsUptimePage from '../pages/settings/reportSettingsUptimePage';
import ReportSettingsAuthenticationPage from '../pages/settings/reportSettingsAuthenticationConfigPage';
import ReportSettingsAdvancedPage from '../pages/settings/reportSettingsAdvancedConfigPage';
import ReportSettingsAlertsPage from '../pages/settings/reportSettingsAlertsPage';
import ReportSettingsLanguagesPage from '../pages/settings/reportSettingsLanguagesConfigPage';
import ReportSettingsPermissionsPage from '../pages/settings/reportSettingsPermissionsConfigPage';
import ReportSettingsSchedulePage from '../pages/settings/reportSettingsScheduleConfigPage';
import ReportSettingsNotificationsPage from '../pages/settings/reportSettingsNotificationsPage';
import ReportSettingsSearchCampaignsRoute from '../routes/reportSettingsSearchCampaignsRoute';
import ReportSettingsForcedPagesPage from '../pages/settings/reportSettingsForcedPagesPage';
import ReportSettingsDigestsPage from '../pages/settings/reportSettingsDigestsPage';
import {ROLE_MANAGER, reportIsAllowed} from 'modules/gandalf/gandalfUtils';

export class ReportSettingsRoute extends React.Component {
  componentDidMount() {
    const {
      resetUpdatingReport,
      viewReportSettingsPage,
      isVirtualUser,
      match: {
        params: {accountId, reportId}
      }
    } = this.props;

    resetUpdatingReport();
    viewReportSettingsPage(reportId);

    if (isVirtualUser) {
      this.props.push('/' + accountId + '/reports/' + reportId);
      return;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.props.resetUpdatingReport();
    }
  }

  render() {
    const {isVirtualUser, location, match, availableTestKeys, reportRole} = this.props;

    const isUptimeTestAvailable = availableTestKeys && availableTestKeys.indexOf('uptime') !== -1;
    const canUserChangeReportSettings = reportIsAllowed(ROLE_MANAGER, reportRole);

    if (isVirtualUser) {
      return null;
    }

    return (
      <SettingsPage location={location} match={match}>
        <Switch>
          <Route path={`${match.path}/general`} component={ReportSettingsGeneralPage} />
          {isUptimeTestAvailable && (
            <Route path={`${match.path}/uptime`} component={ReportSettingsUptimePage} />
          )}
          <Route path={`${match.path}/cms`} component={ReportSettingsCMSPage} />
          <Route
            path={`${match.path}/authentication`}
            component={ReportSettingsAuthenticationPage}
          />
          <Route path={`${match.path}/alerts`} component={ReportSettingsAlertsPage} />
          <Route path={`${match.path}/advanced`} component={ReportSettingsAdvancedPage} />
          <Route path={`${match.path}/languages`} component={ReportSettingsLanguagesPage} />
          <Route
            path={`${match.path}/permissions/digests/:userId`}
            component={ReportSettingsDigestsPage}
          />
          <Route path={`${match.path}/permissions`} component={ReportSettingsPermissionsPage} />
          <Route path={`${match.path}/schedule`} component={ReportSettingsSchedulePage} />
          <Route path={`${match.path}/notifications`} component={ReportSettingsNotificationsPage} />
          <Route
            path={`${match.path}/search-campaigns`}
            component={ReportSettingsSearchCampaignsRoute}
          />
          <Route path={`${match.path}/forced-pages`} component={ReportSettingsForcedPagesPage} />

          {canUserChangeReportSettings ? (
            <Redirect from="*" to={`${match.url}/general`} />
          ) : (
            <Redirect
              from="*"
              to={`${match.url}/${this.props.showAlerts ? 'alerts' : 'notifications'}`}
            />
          )}
        </Switch>
      </SettingsPage>
    );
  }
}

export default connect(
  reportSettingsRouteSelector,
  {
    resetUpdatingReport,
    viewReportSettingsPage,
    push
  }
)(ReportSettingsRoute);
