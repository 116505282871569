import React from 'react';
import Icon from 'components/font-awesome';

export default class ColorContrastRatio extends React.Component {
  render() {
    const {
      data,
      record,
      options: {data2}
    } = this.props;

    const contrast = data;
    const requiredContrast = record[data2];

    if (!contrast && !requiredContrast) {
      return null;
    }

    return (
      <div>
        <Icon name="fas fa-adjust" /> {contrast && <span>{contrast}</span>}
        {contrast && requiredContrast && <span> / </span>}
        {requiredContrast && <span>{requiredContrast}</span>}
      </div>
    );
  }
}
