import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './explorerGrid.scss';
import GridItem from './explorerGridItem';
import Grid from 'components/grid';
import {getItemKey} from 'utils/layoutUtils';

class ExplorerGrid extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    hover: false
  };

  handleClickItem = (row, column, e) => {
    const {onClick} = this.props;

    onClick && onClick(row, column, e);
  };

  renderItems() {
    const {
      itemComponent,
      items,
      itemKey,
      configKey,
      rowKey,
      carousel,
      carouselHeight, // nasty hack for now (really hard to make the height dynamic due to layout - so instead we hardcode it for now)
      ...itemProps
    } = this.props;

    // Allow user to override which component we use for a row.
    const ItemComponent = itemComponent || GridItem;

    if (!items || !items.length) return <Grid className={styles.grid} />;

    return (
      <Grid
        className={styles.grid}
        ghostClassName={styles.item}
        carousel={carousel}
        carouselHeight={carouselHeight}
        targetWidth={200}
      >
        {items.map(item => {
          const key = getItemKey(item, itemKey || rowKey || configKey);

          return (
            <ItemComponent
              key={key}
              keyValue={key} // this allows us to read key from row
              record={item}
              className={itemProps.clickable ? styles.clickable : undefined}
              {...itemProps}
            />
          );
        })}
      </Grid>
    );
  }

  render() {
    const {className, hover} = this.props;

    const classes = cx(className, {
      [styles.hover]: hover
    });

    return <div className={classes}>{this.renderItems()}</div>;
  }
}

export default ExplorerGrid;
