import React from 'react';
import Panel from 'components/panel';
import Divider from 'components/layoutComponents/divider';
import Score from 'components/layoutComponents/score';
import Button from 'components/button';
import {push} from 'modules/location';
import styles from './summaryScoreCard.scss';
import {connect} from 'react-redux';

function SummaryScoreCard({name, testId, description, score, ...props}) {
  return (
    <Panel className={styles.summaryScoreCard}>
      <Divider config={{plain: false}}>{name}</Divider>
      <Score testId={testId} center config={{large: true}} result={score} card />
      <p>{description}</p>
      <p>
        <Button onClick={() => props.push(testId)} wire>
          View {name}
        </Button>
      </p>
    </Panel>
  );
}

export default connect(
  () => ({}),
  {push}
)(SummaryScoreCard);
