exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PsPOLUkgsD586jFUifduU{display:flex;align-items:center;margin-bottom:10px}._3PsPOLUkgsD586jFUifduU button{display:flex;margin:0 0 0 auto}.uiMCLt7sTE8P25Lj5FjkR{font-size:1.2em;font-weight:bold}", ""]);

// exports
exports.locals = {
	"alertSubscription": "_3PsPOLUkgsD586jFUifduU",
	"segmentTitlePrefix": "uiMCLt7sTE8P25Lj5FjkR"
};