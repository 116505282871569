import React from 'react';
import {connect} from 'react-redux';
import Select from 'components/select';
import Flex from 'components/layout/flex';
import Modal from 'modules/modal/components/modalContent';
import AlertSubscriptions from 'modules/alertSubscriptions/containers/alertSubscriptions';
import {editUserAlertsSelector} from 'modules/alertSubscriptions/selectors';

function EditUserAlertsModal({userId, user, segments, storyTypes, isEditingSelf}) {
  const [activeSegment, setActiveSegment] = React.useState('0'); // The "All Pages" segment is always 0
  const segmentIdx = segments.findIndex(segment => segment.value === activeSegment);

  return (
    <Modal title={isEditingSelf ? 'Edit my alerts' : `Edit alerts for ${user.get('name')}`}>
      <Flex container column>
        {segments.length > 1 && (
          <Flex margin="0 0 10px 0">
            <label>Website segment</label>
            <Select
              clearable={false}
              disableFiltering
              value={activeSegment}
              options={segments}
              onChange={option => {
                setActiveSegment(option ? option.value : null);
              }}
            />
          </Flex>
        )}
        <Flex>
          <AlertSubscriptions
            channelId={userId}
            storyTypes={storyTypes}
            segment={{value: activeSegment, label: segments[segmentIdx].label}}
          />
        </Flex>
      </Flex>
    </Modal>
  );
}

export default connect(editUserAlertsSelector)(EditUserAlertsModal);
