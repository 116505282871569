import {select, put, takeEvery} from 'redux-saga/effects';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import {SET_FILTER_PARAMS} from 'modules/filters';
import {currentFilterSelector} from 'modules/filters/filterSelectors';
import {locationParamsSelector} from 'modules/location/locationSelectors';

export default function*() {
  yield takeEvery(SET_FILTER_PARAMS, setFilterParams);
}

function* setFilterParams({newFilters, tab}) {
  const currentFilter = yield select(currentFilterSelector);
  const filter = {...currentFilter, ...newFilters};
  const currentParams = yield select(locationParamsSelector);
  const pathProps = tab ? {...currentParams, filter, tab} : {...currentParams, filter};

  yield put(push(testPath, pathProps));
}
