import {List} from 'immutable';
import {createSelector} from 'reselect';
import {userSelector, userIdParamSelector} from 'modules/auth/authSelectors';
import {usersSelector} from 'modules/users/userSelectors';
import {locationParamsSelector} from 'modules/location/locationSelectors';
import {segmentsBreadcrumbOptionsSelector} from 'modules/app/appSelectors';

const alertSubscriptionsDomainSelector = state => state.alertSubscriptions;

export const alertSubscriptionsSelector = createSelector(
  alertSubscriptionsDomainSelector,
  alertSubscriptionsState => {
    return alertSubscriptionsState.get('alertSubscriptions');
  }
);

export const alertStoryTypesSelector = createSelector(
  alertSubscriptionsDomainSelector,
  alertSubscriptionsState => {
    return alertSubscriptionsState.get('alertStoryTypes').sort();
  }
);

export const editUserAlertsSelector = createSelector(
  userSelector,
  alertStoryTypesSelector,
  segmentsBreadcrumbOptionsSelector,
  usersSelector,
  (_, props) => props.userId,
  (activeUser, storyTypes, segments, users, userId) => {
    const user = users ? users.get(userId.toString()) : null;
    return {
      user,
      segments,
      storyTypes,
      isEditingSelf: user && user.get('userId') == activeUser.get('userId')
    };
  }
);

export const alertSubscriptionButtonSelector = createSelector(
  userSelector,
  alertSubscriptionsSelector,
  locationParamsSelector,
  (_, props) => props.storyTypeIds,
  (_, props) => props.userId,
  (user, alertSubscriptions, params, storyTypeIds, propsUserId) => {
    const userId = propsUserId || user.get('userId');
    const filteredAlertSubscriptions = alertSubscriptions
      .filter(alertSub => {
        return (
          alertSub.get('channelTypeId') === 'user' &&
          alertSub.get('channelId') == userId &&
          alertSub.get('reportId') == params.reportId &&
          storyTypeIds.includes(alertSub.get('storyTypeId'))
        );
      })
      .toList();

    return {
      params,
      storyTypes: storyTypeIds.map(storyTypeId => {
        const alertSub = filteredAlertSubscriptions.find(
          alertSub => alertSub.get('storyTypeId') === storyTypeId
        );
        return alertSub
          ? {
              storyTypeId,
              subscriptionId: alertSub.get('subscriptionId'),
              isSubscribed: alertSub.get('isSubscribed'),
              userId
            }
          : {
              storyTypeId,
              subscriptionId: undefined,
              isSubscribed: false,
              userId
            };
      }),
      isSubscribed:
        filteredAlertSubscriptions.size > 1
          ? filteredAlertSubscriptions.every(alertSub => alertSub.get('isSubscribed'))
          : filteredAlertSubscriptions.getIn([0, 'isSubscribed'], false)
    };
  }
);
