import React from 'react';
import ErrorMessage from '../components/errorMessage';
import {isUrl, isEmail, isUsable} from './utils';

export const required = {
  passes: value => isUsable(value),
  output: (_, value, customMessage) =>
    customMessage || <ErrorMessage>You must enter something here</ErrorMessage>
};

export const url = {
  passes: value => isUrl(value),
  output: () => <ErrorMessage>Enter a web address, e.g. example.com</ErrorMessage>
};

export const email = {
  passes: value => isEmail(value),
  output: () => <ErrorMessage>Enter an email address, e.g. username@example.com</ErrorMessage>
};

export const min = {
  passes: (value, min) => value >= min,
  output: min => <ErrorMessage>Enter {min} or above</ErrorMessage>
};

export const max = {
  passes: (value, max) => value <= max,
  output: max => <ErrorMessage>Enter {max} or below</ErrorMessage>
};

export const minLength = {
  passes: (value, minLength) => value >= minLength,
  output: minLength => <ErrorMessage>Enter at least {minLength} characters</ErrorMessage>
};

export const maxLength = {
  passes: (value, maxLength) => value <= maxLength,
  output: maxLength => <ErrorMessage>Enter up to {maxLength} characters</ErrorMessage>
};

// TODO if this is only used in one place, we should implement it into a specific field
export const searchEngines = {
  passes: value =>
    value.reduce((wasLastValid, val) => {
      const isValid =
        typeof val == 'object' &&
        !!val.engine &&
        !!val.options.language &&
        (val.engine === 'google' ? !!val.options.countryCode : true);

      return wasLastValid && isValid;
    }, true),
  output: () => <ErrorMessage>You must select a location from the list</ErrorMessage>
};
