import React from 'react';
import Measure from 'react-measure';
import {getScale} from 'utils/layoutUtils';

const SIZE_PROPS = ['width', 'height'];

// Measures child component, then scales it to fit the available space
// while keeping aspect ratio.
// Also provides scale to callback.
export default class Scale extends React.Component {
  constructor() {
    super();
    this.state = {
      childSize: null,
      availableSpace: null,
      scale: 1
    };
  }

  measureHandler = name => size => {
    this.setState({[name]: size}, this.updateScale);
  };

  // Calculate the scale from childSize and availableSpace
  calcScale() {
    const {childSize, availableSpace} = this.state;
    if (!childSize || !availableSpace) {
      return 1;
    }
    return getScale(childSize, availableSpace);
  }

  // Calculate scale, update state and onChangeScale if it changes
  updateScale() {
    const {onChangeScale} = this.props;
    const scale = this.calcScale();

    if (scale !== this.state.scale) {
      this.setState({scale});
      onChangeScale && onChangeScale(scale);
    }
  }

  render() {
    const {children, width, height} = this.props;

    return (
      <Measure onMeasure={this.measureHandler('availableSpace')} whitelist={SIZE_PROPS}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              transform: `scale(${this.state.scale})`,
              transformOrigin: 'center center',
              maxWidth: width,
              maxHeight: height
            }}
          >
            <Measure
              shouldMeasure={!this.state.childSize}
              onMeasure={this.measureHandler('childSize')}
              useClone
              whitelist={SIZE_PROPS}
            >
              {children}
            </Measure>
          </div>
        </div>
      </Measure>
    );
  }
}
