import React from 'react';
import Button from 'components/button';
import {useDispatch} from 'hooks/redux';
import {showEditWidgetModal, confirmDeleteWidget, showSelectWidgetModal} from '../actions';
import Dropdown, {DropdownOption} from 'components/dropdown';

const BUTTON_LABEL = <Button tiny subtle icon="cog" style={{margin: 0}} />;

export default function EditWidgetButton({widget, contextTypeId}) {
  const dispatch = useDispatch();

  return (
    <Dropdown button={BUTTON_LABEL}>
      <DropdownOption
        onClick={() => {
          dispatch(showEditWidgetModal(widget.get('dashboardId'), widget.get('widgetId')));
        }}
      >
        Edit widget
      </DropdownOption>
      <DropdownOption
        onClick={() => {
          dispatch(confirmDeleteWidget(widget.get('dashboardId'), widget.get('widgetId')));
        }}
      >
        Delete widget
      </DropdownOption>
      <DropdownOption divider />
      <DropdownOption
        onClick={() => {
          dispatch(
            showSelectWidgetModal(
              widget.get('dashboardId'),
              widget.get('row'),
              widget.get('column'),
              contextTypeId
            )
          );
        }}
      >
        Add another widget
      </DropdownOption>
    </Dropdown>
  );
}
