import React from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import Scale from 'components/layout/scale';
import Button from 'components/button';
import Center from 'components/center';
import Spinner from 'components/spinner';
import styles from './imageUpload.scss';
import {addMessage} from 'modules/alerts';

const whitelist = ['height', 'width'];

const defaultStyle = {
  borderColor: '#666',
  borderRadius: 5,
  borderStyle: 'dashed',
  borderWidth: 2,
  height: 200,
  width: 200
};

/*
  This has been designed to accept one image at a time.
*/
export class ImageUpload extends React.Component {
  static defaultProps = {
    preview: undefined
  };

  handleDrop = (accepted, rejected) => {
    const {uploadImage, addMessage} = this.props;

    // NOTE `accepted` will never have more than one file because
    //      the `multiple` attr below is set to false
    const image = accepted[0];

    if (rejected.length) {
      if (!image) {
        addMessage('error', 'You can only upload images');
        return;
      }

      // Some files were invalid, but we got one that was okay
    }

    uploadImage && uploadImage(image);
  };

  handleClick = () => {
    this.dropzone.open();
  };

  handleImageLoaded = event => {
    const {clientHeight: height, clientWidth: width} = event.target;

    // NOTE This has to be called because the `onMeasure` isn't called
    //      again after the image has finished loading.
    this.scale.measureHandler('childSize')({height, width});
  };

  renderContent() {
    const {loading, preview} = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (!preview) {
      return 'Upload image';
    }

    return (
      <Scale ref={this.setScale}>
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img src={preview} onLoad={this.handleImageLoaded} alt="preview of uploaded image" />
      </Scale>
    );
  }

  setScale = ref => {
    this.scale = ref;
  };

  setDropzone = ref => {
    this.dropzone = ref;
  };

  render() {
    const {preview, style} = this.props;

    return (
      <Dropzone
        accept="image/*"
        multiple={false}
        onDrop={this.handleDrop}
        ref={this.setDropzone}
        disablePreview
        style={{...defaultStyle, ...style}}
      >
        <Center fit className={styles.upload}>
          {this.renderContent()}
        </Center>
      </Dropzone>
    );
  }
}

export default connect(
  null,
  {addMessage}
)(ImageUpload);
