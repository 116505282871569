import React from 'react';
import styles from './typoIssue.scss';

export default class TypoIssue extends React.Component {
  render() {
    const {issueData} = this.props;
    const before = issueData.get('before');
    const typo = issueData.get('typo');
    const after = issueData.get('after');
    const replacement = issueData.getIn(['replacements', 0]);
    const description = issueData.get('description');

    return (
      <div>
        <div className={styles.text}>
          <span>{before}</span>
          <span className={styles.typo}>{typo}</span>
          <span>{after}</span>
        </div>
        {description && (
          <div>
            <p className={styles.description}>{description}</p>
          </div>
        )}
      </div>
    );
  }
}
