import {call, put, select, takeLatest} from 'redux-saga/effects';
import {REQUEST_SELF, SWITCH_ACCOUNT, login, loginFailed} from 'modules/auth';
import {selfEndpoint} from 'modules/auth/authUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {tokenSelector} from 'modules/auth/authSelectors';
import handleLoginSuccess from './utils/handleLoginSuccess';

export default function* requestSelfSaga() {
  yield takeLatest([SWITCH_ACCOUNT, REQUEST_SELF], requestSelf);
}

function* requestSelf() {
  yield processRequest('GET', selfEndpoint(), {
    success: function*(user) {
      const token = yield select(tokenSelector);
      yield call(handleLoginSuccess, {user, token});
    },

    error: function*({errorCode} = {}) {
      // we don't want to set an error message because this request happens in the background
      yield put(loginFailed());
    }
  });
}
