exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3_jR39Zr0_y6fsRZx_U9hr{margin-top:30px;margin-bottom:30px;text-align:right;font-size:1.1em}.GY1Dc0Cb9bEnIStaJXqk4{display:flex;padding:10px 0;max-width:250px;text-align:center;margin:0 auto}._2m9g8azYoj0FGzBU15B8_f{flex:1;text-align:left;margin-right:10px;font-weight:bold}.by4SeSshKmajvR6aSPjam{flex:1;text-align:right;min-width:90px}._30ULz0MkWdJ2lgks2PLiVE{font-size:1.3em}", ""]);

// exports
exports.locals = {
	"totals": "_3_jR39Zr0_y6fsRZx_U9hr",
	"total": "GY1Dc0Cb9bEnIStaJXqk4",
	"totalName": "_2m9g8azYoj0FGzBU15B8_f",
	"totalValue": "by4SeSshKmajvR6aSPjam",
	"summaryTotal": "_30ULz0MkWdJ2lgks2PLiVE"
};