import React from 'react';
import {connect} from 'react-redux';
import AuthWrapper from 'modules/auth/components/authWrapper';
import Helmet from 'components/helmet';
import Spinner from 'components/spinner';
import {homePath} from 'modules/app/appUtils';
import {replace, setLastRequestedPage} from 'modules/location';
import {toolSelectPageSelector} from 'modules/auth/authSelectors';
import ProductSelectionItem from 'modules/auth/components/productSelectionItem';
import ExternalLink from 'modules/location/externalLink';

export class ToolSelectionPage extends React.Component {
  onSelectTool = (link, isInternal) => {
    const {
      match: {params}
    } = this.props;

    if (isInternal) {
      if (this.props.lastRequestedUrl) {
        this.props.setLastRequestedPage(null);
        this.props.replace(this.props.lastRequestedUrl);
      } else {
        this.props.replace(homePath, {accountId: params.accountId});
      }
      return;
    }
    window.location.href = link;
  };

  renderProduct(name, link, isInternal = true) {
    return (
      <ProductSelectionItem name={name} onSelectTool={() => this.onSelectTool(link, isInternal)} />
    );
  }

  render() {
    const {topProductName, isLoading, prospectLoginUrl} = this.props;

    return (
      <AuthWrapper>
        <div className={this.props.className}>
          <Helmet title="Tool selection" />
          <div>
            <p>Please select the product you wish to use:</p>

            {isLoading && <Spinner />}
            {!isLoading && this.renderProduct(topProductName, '/')}
            {!isLoading && this.renderProduct('Insites', prospectLoginUrl, false)}
            <br />
            <p>
              Not sure? Learn{' '}
              <ExternalLink href="https://support.silktide.com/guides/which-silktide-product-am-i-using/">
                which Silktide product you're using
              </ExternalLink>
              .
            </p>
          </div>
        </div>
      </AuthWrapper>
    );
  }
}

export default connect(
  toolSelectPageSelector,
  {replace, setLastRequestedPage}
)(ToolSelectionPage);
