exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._127R17bQ6J8mtAn-uBu-tq{text-align:center}._3HBx7wVAlJbfRddCzmmmde{border-top-color:#ccc;margin-top:60px;margin-bottom:80px}._1_qe6SgPC4RjtIsHTqHwMs{margin-bottom:40px}.zV6gNMrCHbCoCs2Q6m8P3{margin:8px;max-width:320px}._37U3E0LwDoYCX5ziVuwNvL{margin-top:40px}", ""]);

// exports
exports.locals = {
	"container": "_127R17bQ6J8mtAn-uBu-tq",
	"hr": "_3HBx7wVAlJbfRddCzmmmde",
	"examplesPara": "_1_qe6SgPC4RjtIsHTqHwMs",
	"card": "zV6gNMrCHbCoCs2Q6m8P3",
	"buyBtn": "_37U3E0LwDoYCX5ziVuwNvL"
};