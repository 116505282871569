import PropTypes from 'prop-types';
import React from 'react';
import {trackEvent} from 'modules/tracking';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import SettingsPage from 'components/settings/settingsPage';
import Button from 'components/button';

export default class AuthenticationConfig extends React.Component {
  handleValidSubmit = config => {
    const {report, updateWebsite} = this.props;

    trackEvent('Updated authentication config', {
      forReport: report.reportId,
      fromFile: 'authenticationConfig.js'
    });

    updateWebsite(config);
  };

  render() {
    const {
      name,
      match: {params},
      websiteConfig
    } = this.props;
    if (!websiteConfig) {
      return null;
    }

    return (
      <SettingsPage name="Authentication" params={params}>
        <Form
          name={name}
          defaultValue={{
            username: websiteConfig.username,
            password: websiteConfig.password
          }}
          onValidSubmit={this.handleValidSubmit}
        >
          <SettingItem
            title="HTTP Authentication username"
            description="Username for HTTP Authentication"
          >
            <Field type="text" name="username" maxLength={100} />
          </SettingItem>

          <SettingItem
            title="HTTP Authentication password"
            description="Password for HTTP Authentication"
          >
            <Field
              type="password"
              name="password"
              maxLength={100}
              autoComplete="new-password" // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
            />
          </SettingItem>

          <Button type="submit">Save</Button>
        </Form>
      </SettingsPage>
    );
  }
}
