import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {usersSelector} from 'modules/users/userSelectors';
import {accountsSelector} from 'modules/accounts/accountSelectors';
import {ECOMMERCE_TRIAL} from 'modules/accounts/accountConstants';
import {toString} from 'utils/stringUtils';
import {toJS} from 'utils/immutableUtils';
import {isSuperUser} from 'modules/auth/gandalf';
import {alertsDomainSelector} from 'modules/alerts/alertSelectors';
import {createParamSelector, lastRequestedPageSelector} from 'modules/location/locationSelectors';
import {
  topEntitlementNameSelector,
  entitlementsLoadingSelector
} from 'modules/entitlements/entitlementSelectors';

const authSelector = state => state.auth;

export const tokenSelector = createSelector(
  authSelector,
  auth => auth && auth.get('token')
);

export const usingServicesSelector = createSelector(
  authSelector,
  auth => auth && auth.get('usingServices')
);

export const userIdSelector = createSelector(
  authSelector,
  auth => auth && auth.get('userId')
);

export const accountIdParamSelector = (_, props) => {
  return props && props.params && props.params.accountId;
};

export const userIdParamSelector = createParamSelector('userId');

export const accountIdSelector = createSelector(
  accountIdParamSelector,
  authSelector,
  (accountIdParam, auth) => {
    return toString(accountIdParam || (auth && auth.get('accountId')));
  }
);

export const accountIdObjectSelector = createSelector(
  accountIdSelector,
  accountId => ({
    accountId
  })
);

export const accountState = state => state.accounts;

export const accountSelector = createSelector(
  accountsSelector,
  accountIdSelector,
  (accounts, accountId) => {
    return accounts && accounts.get(accountId);
  }
);

export const accountHelpUrlSelector = createSelector(
  accountSelector,
  account => account && account.getIn(['help', 'helpUrl'])
);

export const accountPropSelector = createSelector(
  accountSelector,
  account => {
    if (!account) return null;

    return {account};
  }
);

export const loggedInSelector = createSelector(
  tokenSelector,
  userIdSelector,
  (token, userId) => {
    return !!(token && userId);
  }
);

export const userState = state => state.users;

export const userSelector = createSelector(
  usersSelector,
  userIdSelector,
  (users, userId) => {
    return users.get(userId) || new Map();
  }
);

export const isVirtualUserSelector = createSelector(
  userSelector,
  user => user && !!user.get('virtualType')
);
export const loggedInRouteSelector = createSelector(
  userSelector,
  isVirtualUserSelector,
  alertsDomainSelector,
  (user, isVirtual, alertsDomainState) => {
    return {
      user: (user && user.toJS()) || {},
      isVirtual,
      isShowingDecisionAlert: !!alertsDomainState.get('decisionAlert')
    };
  }
);

export const userParamSelector = createSelector(
  usersSelector,
  userIdParamSelector,
  (users, userId) => {
    return users.get(userId) || new Map();
  }
);

export const userObjectSelector = createSelector(
  userSelector,
  user => ({
    user
  })
);

export const plainUserSelector = createSelector(
  userSelector,
  user => ({
    user: toJS(user)
  })
);

export const plainUserIdSelector = createSelector(
  userIdSelector,
  userId => ({
    userId
  })
);

export const pusherPrefixSelector = createSelector(
  userState,
  user => user.get('pusherPrefix')
);

export const loginErrorSelector = createSelector(
  authSelector,
  auth => auth && auth.get('error')
);

export const loggingInSelector = createSelector(
  authSelector,
  auth => {
    return auth && auth.get('loggingIn', false);
  }
);

export const loginPageSelector = createSelector(
  userSelector,
  loggedInSelector,
  loginErrorSelector,
  loggingInSelector,
  (user, loggedIn, error, loggingIn) => {
    return {user, loggedIn, error, loggingIn};
  }
);

export const loginTwoFactorPageSelector = createSelector(
  userSelector,
  loggedInSelector,
  loginErrorSelector,
  loggingInSelector,
  authSelector,
  (user, loggedIn, error, loggingIn, authState) => {
    return {user, loggedIn, error, loggingIn, details: toJS(authState.get('2faDetails', {}))};
  }
);

export const featuresSelector = createSelector(
  accountSelector,
  account => {
    return {
      features: account ? account.get('features', Map()) : Map()
    };
  }
);

export const makeFeatureSelector = featureId =>
  createSelector(
    featuresSelector,
    ({features}) => features.get(featureId)
  );

// make section flag selectors
export const accessibilityFlagSelector = makeFeatureSelector('AccessibilityFeatureFlag');
export const contentFlagSelector = makeFeatureSelector('ContentFeatureFlag');
export const marketingFlagSelector = makeFeatureSelector('MarketingFeatureFlag');
export const qualityFlagSelector = makeFeatureSelector('QualityFeatureFlag');
export const privacyFlagSelector = makeFeatureSelector('PrivacyFeatureFlag');
export const uxFlagSelector = makeFeatureSelector('UxFeatureFlag');
export const seoFlagSelector = makeFeatureSelector('SeoFeatureFlag');

export const defaultLanguagesSelector = createSelector(
  accountSelector,
  account => account && account.get('languages')
);

export const currencySelector = createSelector(
  accountSelector,
  account => account && account.get('currency')
);

export const authWrapperSelector = createSelector(
  loginErrorSelector,
  error => ({error})
);

// only used by the InviteUser field set
export const inviteUserSelector = createSelector(
  userSelector,
  loggedInUser => ({loggedInUser})
);

export const accountTypeIdSelector = createSelector(
  accountSelector,
  account => account && account.get('accountTypeId')
);

export const isEcommerceTrialSelector = createSelector(
  accountTypeIdSelector,
  accountType => accountType == ECOMMERCE_TRIAL
);

export const roleInputSelector = createSelector(
  userSelector,
  user => ({
    loggedInUser: user.toJS()
  })
);

export const isShadowingSelector = createSelector(
  userSelector,
  accountSelector,
  (user, account) => {
    return {
      isShadowing: !!account && user.get('accountId') !== account.get('accountId')
    };
  }
);

export const toolSelectPageSelector = createSelector(
  topEntitlementNameSelector,
  entitlementsLoadingSelector,
  authSelector,
  lastRequestedPageSelector,
  (topEntitlementName, isLoading, authState, lastRequestedUrl) => {
    return {
      topProductName: topEntitlementName,
      isLoading,
      prospectLoginUrl: authState.get('prospectLoginUrl') || 'https://app.insites.com',
      lastRequestedUrl
    };
  }
);

export const isSuperUserSelector = createSelector(
  authSelector,
  user => isSuperUser(user)
);
