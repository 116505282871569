import React from 'react';
import cx from 'classnames';
import ReactDatePicker from 'react-datepicker';
import './datePicker.css';
import styles from './datePicker.scss';

class DatePicker extends React.Component {
  static defaultProps = {};

  render() {
    const {className, ...props} = this.props;
    return <ReactDatePicker className={cx(styles.input)} {...props} />;
  }
}

export default DatePicker;
