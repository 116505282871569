import React from 'react';
import Icon from 'components/font-awesome/icon';
import styles from './help.scss';
import ExternalLink from 'modules/location/externalLink';

export default class KeywordsHelp extends React.Component {
  render() {
    return (
      <div className={styles.help}>
        <h3>How many search keywords do you want to monitor?</h3>
        <p>
          In Silktide, a search keyword allows you to measure the ranking of a single search keyword
          in a single geographic location, e.g. "pizza in New York".
        </p>
        <p>
          Most organizations will want to their keywords in multiple locations, depending on the
          products and services you offer. For example "pizza in Chicago", "pizza in New York" and
          so on. Each of these counts as one keyword.
        </p>
        <p>
          100 keywords is usually a good starting point. You can purchase more later if you wish.
        </p>
        <p>
          Silktide measures your search rankings for every keyword, in both mobile and desktop.{' '}
          <ExternalLink href="https://silktide.com/features/seo">
            Learn more on our website
          </ExternalLink>
          .
        </p>
      </div>
    );
  }
}
