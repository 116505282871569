import {take, select, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {ADD_USER, receiveUser, addUserComplete} from 'modules/users';
import {usersEndpoint} from 'modules/users/userSelectors';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';
import getErrorMessage from 'api/errors';
import {showError, showSuccess} from 'utils/saga/alerts';

export default function* addUserSaga() {
  yield takeLatest(ADD_USER, addUser);
}

function* addUser({user}) {
  yield processRequest(
    'POST',
    usersEndpoint(),
    {
      success: function*(data) {
        yield put(receiveUser(data));
        yield put(addUserComplete());
        yield showSuccess(SUCCESS_MESSAGE);
      },

      error: function*({errorCode} = {}) {
        const message = getErrorMessage(errorCode);
        yield put(addUserComplete());
        yield showError(message);
      }
    },
    user
  );
}
