import {List} from 'immutable';
import {createSelector} from 'reselect';

const digestReducerSelector = state => state.digests;

const makeDigestReducerSelector = prop =>
  createSelector(
    digestReducerSelector,
    reducer => reducer.get(prop)
  );

export const digestsSelector = makeDigestReducerSelector('digests');
export const digestsLoadingSelector = makeDigestReducerSelector('loadingDigests');
export const digestsErrorSelector = makeDigestReducerSelector('digestsError');

export const digestSubscriptionsSelector = makeDigestReducerSelector('digestSubscriptions');
export const originalDigestSubscriptionsSelector = makeDigestReducerSelector(
  'originalDigestSubscriptions'
);
export const unsavedDigestSubscriptionsSelector = makeDigestReducerSelector(
  'unsavedDigestSubscriptions'
);
export const removeDigestSubscriptionsSelector = makeDigestReducerSelector(
  'removeDigestSubscriptions'
);

export const digestSubscriptionsLoadingSelector = makeDigestReducerSelector(
  'loadingDigestSubscriptions'
);
export const digestSubscriptionsErrorSelector = makeDigestReducerSelector(
  'digestSubscriptionsError'
);
export const lastReceivedDigestSubscriptionsSelector = makeDigestReducerSelector(
  'lastReceivedDigestSubscriptions'
);

export const digestSubscriptionsSavingSelector = makeDigestReducerSelector(
  'savingDigestSubscriptions'
);
export const digestSubscriptionsSaveErrorSelector = makeDigestReducerSelector(
  'saveDigestSubscriptionsError'
);
