import React from 'react';
import {connect} from 'react-redux';
import {receiveExtraInfo} from 'modules/reports';
import {ModalForm, Field} from 'modules/form/components';
import Alert from 'components/alert';
import ModalContent from 'modules/modal/components/modalContent';

export class UrlModal extends React.Component {
  handleValidSubmit = data => {
    this.props.receiveExtraInfo(data);
  };

  render() {
    const {message, url, value, name} = this.props;
    const formData = {...value, homeUrl: url};

    return (
      <ModalForm
        submitLabel="Save"
        title="Problem with web address"
        beforeForm={<Alert level="info">{message}</Alert>}
        defaultValue={formData}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field label="Web address" name="homeUrl" type="url" autoFocus />
      </ModalForm>
    );
  }
}

export default connect(
  null,
  {receiveExtraInfo}
)(UrlModal);
