import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import TextareaInput from 'modules/form/components/inputs/textareaInput';

export default class QuoteRejectionModal extends React.Component {
  state = {reason: ''};

  handleButtonClick = () => {
    const {onReject, quoteId, quoteKey, hideModal} = this.props;
    const {reason} = this.state;

    onReject(quoteId, quoteKey, reason);
    hideModal();
  };

  render() {
    const {reason} = this.state;

    return (
      <ModalContent
        title={'Why are you rejecting this proposal?'}
        buttons={['Reject proposal']}
        onClickButton={this.handleButtonClick}
      >
        <TextareaInput
          autoFocus={true}
          rows={6}
          placeholder="Example: We require more keywords"
          name="reason"
          value={reason}
          onChange={val => this.setState({reason: val.target.value})}
        />
      </ModalContent>
    );
  }
}
