import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PoliciesPage from '../pages/editPolicyPage';
import NewPolicyPage from 'modules/policies/pages/newPolicyPage';
import {setLocationParamItem} from 'modules/location';

export class PolicyRoute extends React.Component {
  componentDidMount() {
    const {match} = this.props;

    if (match.params.testId) {
      // testId gets forgotten when we LEAVE_TEST which we are doing
      this.props.setLocationParamItem('testId', match.params.testId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {match} = this.props;

    if (nextProps.match.params.testId !== match.params.testId) {
      // testId gets forgotten when we LEAVE_TEST which we are doing
      this.props.setLocationParamItem('testId', nextProps.match.params.testId);
    }
  }

  render() {
    const {path, params} = this.props.match;

    if (params.policyId == 'new') {
      return <Route path={path} component={NewPolicyPage} />;
    }
    return (
      <Switch>
        <Route exact path={path} component={PoliciesPage} />
      </Switch>
    );
  }
}

export default connect(
  null,
  {setLocationParamItem}
)(PolicyRoute);
