import React from 'react';
import {Form, Field} from 'modules/form/components';
import styles from 'modules/auth/components/authWrapperShared.scss';
import Button from 'components/button';
import {Link} from 'react-router-dom';

export default class ForgottenPasswordForm extends React.Component {
  render() {
    return (
      <Form {...this.props}>
        <Field
          name="email"
          type="email"
          placeholder="Email address"
          className={styles.textInput}
          autoFocus
          required
        />

        <div className={styles.buttonContainer}>
          <Button type="submit" className={styles.submit}>
            Send email
          </Button>

          <Link className={styles.inlineLink} to="/">
            Back to login
          </Link>
        </div>
      </Form>
    );
  }
}
