exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".M40Jw-BRO7tIH8UJVY4xo{background:#f4f4f4;width:600px;border-radius:4px;box-shadow:0 0 100px rgba(0,0,0,.5);color:#666;position:relative;min-height:80px;-webkit-animation:_1y3p_hUbBrAGSA5NhC9xU9 .4s ease-out;animation:_1y3p_hUbBrAGSA5NhC9xU9 .4s ease-out;max-width:100%}._2Z5qzMcEK7cfmjdVZmm1_M{width:90vw;max-height:90vh}._1s25-XTt39kdNp6hRzfnd2{width:800px}@media(min-width: 1200px){._2Z5qzMcEK7cfmjdVZmm1_M{width:1100px}}.RZZwv5Pf8qm1N-KHiUYUn{width:90%;overflow:auto;box-shadow:0 0 100px rgba(0,0,0,.8)}.RZZwv5Pf8qm1N-KHiUYUn ._1mF4ePcG3A3QmMZ1BYNGPW{background:transparent}.Q-xVuIbTXsnFGBIRdZ019{background-image:linear-gradient(135deg, #28205f, #40377b 30%, #54cbc7);text-align:center}.Q-xVuIbTXsnFGBIRdZ019 ._3LzoTTcasgx2sKiZoijp7f{color:#fff}._1mF4ePcG3A3QmMZ1BYNGPW{background:#f4f4f4;border-radius:4px}._3LzoTTcasgx2sKiZoijp7f{position:absolute;top:15px;right:20px;cursor:pointer;transition:all 500ms;color:#888;z-index:1;font-size:25px}._3LzoTTcasgx2sKiZoijp7f:hover{-webkit-transform:scale(1.2);transform:scale(1.2)}@-webkit-keyframes _1y3p_hUbBrAGSA5NhC9xU9{0%{opacity:0;-webkit-transform:scale(0.8);transform:scale(0.8)}100%{opacity:1;-webkit-transform:scale(1);transform:scale(1)}}@keyframes _1y3p_hUbBrAGSA5NhC9xU9{0%{opacity:0;-webkit-transform:scale(0.8);transform:scale(0.8)}100%{opacity:1;-webkit-transform:scale(1);transform:scale(1)}}@media(max-width: 600px){._2am5X-6G0SUDkajlAiiDOG{padding:10px 10px 50px 10px !important}}", ""]);

// exports
exports.locals = {
	"modal": "M40Jw-BRO7tIH8UJVY4xo",
	"show-modal": "_1y3p_hUbBrAGSA5NhC9xU9",
	"wide": "_2Z5qzMcEK7cfmjdVZmm1_M",
	"wider": "_1s25-XTt39kdNp6hRzfnd2",
	"huge": "RZZwv5Pf8qm1N-KHiUYUn",
	"animationWrapper": "_1mF4ePcG3A3QmMZ1BYNGPW",
	"gradientBackground": "Q-xVuIbTXsnFGBIRdZ019",
	"closeButton": "_3LzoTTcasgx2sKiZoijp7f",
	"overlay": "_2am5X-6G0SUDkajlAiiDOG"
};