exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Hi4s7Bp4mHm7nQBxUXyZg{transition:color .3s ease-out}._2Hi4s7Bp4mHm7nQBxUXyZg:hover{color:#533be2;cursor:pointer}", ""]);

// exports
exports.locals = {
	"upload": "_2Hi4s7Bp4mHm7nQBxUXyZg"
};