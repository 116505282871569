exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3ziLp8Dtvaa9KRGMGBi9hm{padding:10px;transition:background .3s;text-align:center}._3ziLp8Dtvaa9KRGMGBi9hm .ucPgCI4NvwyqLc6cXl2gy{margin-top:0;margin-left:10px}._1ek4nTNuRBGHSKZVf7ueUG{min-width:0}", ""]);

// exports
exports.locals = {
	"issue": "_3ziLp8Dtvaa9KRGMGBi9hm",
	"issueActions": "ucPgCI4NvwyqLc6cXl2gy",
	"inlineIssueComponent": "_1ek4nTNuRBGHSKZVf7ueUG"
};