import React from 'react';
import styles from './achievementTarget.scss';
import Badge from 'modules/achievements/components/badge';

export default class AchievementTarget extends React.Component {
  render() {
    const {name, description, icon, updatedTime} = this.props;

    if (!name) {
      return <p>You have not achieved this achievement</p>;
    }

    return (
      <div className={styles.achievementTarget}>
        <div className={styles.icon}>
          <Badge icon={icon} />
        </div>
        <div className={styles.details}>
          <h4>{name}</h4>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}
