import React from 'react';
import {connect} from 'react-redux';
import {folderPageSelector} from 'modules/folderViews/folderViewSelectors';
import Helmet from 'components/helmet';
import PageHeader from 'components/header/pageHeader';
import {choosePluralVersion} from 'utils/interpolationUtils';
import styles from './folderPage.scss';
import {Route} from 'react-router-dom';
import AddReportButton from 'modules/reports/containers/addReportButton';
import {havePropsChanged} from 'utils/propUtils';
import FilterBar from 'modules/reportFilters/components/filterBar';
import {formatGivenNumber} from 'utils/numberUtils';
import SearchBar from 'components/searchBar';
import FolderViewPage from 'modules/folders/pages/folderViewPage';
import {viewFolderPage, exitFolderPage} from 'modules/folders';

export class FolderPage extends React.Component {
  static defaultProps = {
    folder: {
      name: ''
    }
  };

  constructor() {
    super();

    this.state = {textFilter: ''};
  }

  componentDidMount() {
    const {
      viewFolderPage,
      match: {params}
    } = this.props;
    viewFolderPage(params.folderId);
  }

  componentWillUnmount() {
    this.props.exitFolderPage();
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {params},
      location
    } = nextProps;

    if (location.pathname !== this.props.location.pathname) {
      this.props.viewFolderPage(params.folderId);
    }
  }

  componentDidUpdate(nextProps) {
    // TODO the state of this search filter belongs in redux
    if (havePropsChanged(this.props, nextProps, ['params'])) {
      this.setState({textFilter: ''});
    }
  }

  handleFilterSearch = text => {
    this.setState({textFilter: text.toLowerCase().trim()});
  };

  getFilteredData() {
    const {textFilter} = this.state;
    const {folderViewsData} = this.props;

    if (!textFilter) {
      return folderViewsData;
    }

    return folderViewsData.filter(({name}) => {
      if (!name) {
        return false;
      }
      return textFilter == name.toLowerCase().substr(0, textFilter.length);
    });
  }

  // This used to display the number of sites and the number of pages.
  // It is not used at the moment but could be hoooked up again in the future
  renderSubtitle() {
    const {sites, pages} = this.props.details;

    return (
      <div className={styles.subtitle}>
        <span className={styles.sites}>
          {sites} {choosePluralVersion(sites, 'Site', 'Sites')}
        </span>
        <span className={styles.pages}>
          {pages} {choosePluralVersion(pages, 'Page', 'Pages')}
        </span>
      </div>
    );
  }

  render() {
    if (!this.props.folder) return null;

    var {
      folder: {name}
    } = this.props;
    const {
      folder: {folderId},
      folderViews,
      folderViewsData,
      children,
      totalCount,
      filteredCount
    } = this.props;

    if (totalCount && filteredCount != undefined && filteredCount !== totalCount) {
      name = ` ${formatGivenNumber(filteredCount)} website${
        filteredCount == 1 ? '' : 's'
      } match of ${formatGivenNumber(totalCount)}`;
    }

    const filteredData = this.getFilteredData();

    const hasSites = !!(folderViewsData && folderViewsData.length);

    return (
      <div className={this.props.className}>
        <Helmet title={name} />

        <PageHeader
          title={name}
          inline={[
            hasSites && (
              <AddReportButton key="add_report" folderId={folderId} className={styles.addButton} />
            ),
            (folderId == 0 || hasSites) && (
              <SearchBar
                className={styles.searchBar}
                onUserInput={this.handleFilterSearch}
                tabIndex="1"
              />
            )
          ]}
        />

        {folderId == 0 && <FilterBar />}

        <Route
          exact
          path="/:accountId/folders/:folderId/:viewId"
          render={props => {
            return (
              <FolderViewPage
                filter={this.state.textFilter}
                folderViewsData={filteredData}
                folderViews={folderViews}
                {...props}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default connect(
  folderPageSelector,
  {viewFolderPage, exitFolderPage}
)(FolderPage);
