exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._19KljkiSxzz58b90TsLXPI{vertical-align:middle}", ""]);

// exports
exports.locals = {
	"control": "_19KljkiSxzz58b90TsLXPI"
};