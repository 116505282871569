exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._27XL8WA13eG-zSIht5FBq3{color:#df1616}._3Ml00OPQnMpYgGkMMBbwJI{color:#ebd10c}._7a8rHceXhFV-L_4x7owpH{color:#15b272}._2aL5uZKhqZDvdUy5lmt02C{color:#533be2}._3XDfRP0txKGbWLMDq8Ip7C,.MbyK8_g0kKvI3rWo8pWnK,._25ZCb3sHSASzCgnZl7l4tQ{color:#434343}._1KplZJFIWl2cC1fZ7TyUS0{margin:20px}._2R2yLg7cDnmqY1Fg0kRgmK{margin-bottom:20px}._2R2yLg7cDnmqY1Fg0kRgmK h3{font-size:18px;margin-top:0 !important;margin-bottom:5px;color:#533be2 !important;font-weight:bold}._2R2yLg7cDnmqY1Fg0kRgmK p{opacity:.5}._2R2yLg7cDnmqY1Fg0kRgmK ._3VZrd7tuN4jBzfPCIhu4RL{margin-right:20px;font-size:2.6em}@media print{._2R2yLg7cDnmqY1Fg0kRgmK h3{color:#000 !important}._2R2yLg7cDnmqY1Fg0kRgmK p{color:#222 !important;opacity:1}}._35kBoG5r2fZ_yuGNS31Dn8{font-size:1.5em !important}._2R2yLg7cDnmqY1Fg0kRgmK:last-of-type{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"badIcon": "_27XL8WA13eG-zSIht5FBq3",
	"warningIcon": "_3Ml00OPQnMpYgGkMMBbwJI",
	"goodIcon": "_7a8rHceXhFV-L_4x7owpH",
	"infoIcon": "_2aL5uZKhqZDvdUy5lmt02C",
	"badHeading": "_3XDfRP0txKGbWLMDq8Ip7C",
	"warningHeading": "MbyK8_g0kKvI3rWo8pWnK",
	"goodHeading": "_25ZCb3sHSASzCgnZl7l4tQ",
	"column": "_1KplZJFIWl2cC1fZ7TyUS0",
	"keyPoint": "_2R2yLg7cDnmqY1Fg0kRgmK",
	"icon": "_3VZrd7tuN4jBzfPCIhu4RL",
	"iconSmall": "_35kBoG5r2fZ_yuGNS31Dn8"
};