import React from 'react';

export default function Margin({
  children,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft
}) {
  const div = React.useRef(null);

  React.useEffect(() => {
    if (div.current) {
      const [t, r, b, l] = getMargins(margin);

      setCssPixelValue(div.current, 'marginTop', marginTop, t);
      setCssPixelValue(div.current, 'marginRight', marginRight, r);
      setCssPixelValue(div.current, 'marginBottom', marginBottom, b);
      setCssPixelValue(div.current, 'marginLeft', marginLeft, l);
    }
  }, [div.current, marginTop, marginRight, marginBottom, marginLeft, margin]);

  return <div ref={div}>{children}</div>;
}

function setCssPixelValue(elem, prop, value, def) {
  elem.style[prop] = value == null ? def : Number.isFinite(value) ? value + 'px' : value;
}

function getMargins(cssMargin) {
  const e = document.createElement('div');
  e.style.margin = cssMargin;
  return [e.style.marginTop, e.style.marginRight, e.style.marginBottom, e.style.marginLeft];
}
