import React from 'react';
import cx from 'classnames';
import {push} from 'modules/location';
import {useDispatch} from 'hooks/redux';
import Card from 'components/card';
import Icon from 'components/font-awesome';
import styles from './courseCard.scss';
import {coursePath} from '../utils';
import courseStyles from '../pages/courses.scss';
import Minutes from 'components/minutes';

function CardProgress({course}) {
  const started = course.get('started');

  if (!started) {
    return null;
  }

  const percentComplete = course.get('percentComplete');
  const percentCorrect = course.get('percentCorrect');

  return (
    <div>
      <div className={styles.progress}>
        <p>
          <Icon name="fas fa-check" className={courseStyles.progressComplete} />{' '}
          {Math.round(percentComplete)}% complete
        </p>
        <p>
          <Icon name="fas fa-star" className={courseStyles.progressCorrect} />{' '}
          {Math.round(percentCorrect)}% correct
        </p>
      </div>
    </div>
  );
}

function CardTitle({course}) {
  return (
    <span className={styles.title}>
      <span style={{marginRight: '10px'}}>{course.get('name')}</span>
      {!!course.get('finished') && <span className={styles.completed}>Completed</span>}
      {course.get('state') == 'draft' && <span className={styles.draft}>Draft</span>}
    </span>
  );
}

export function CourseCard({course, className}) {
  const dispatch = useDispatch();

  return (
    <Card
      label={undefined}
      title={<CardTitle course={course} />}
      tooltip={course.get('name')}
      className={cx(styles.courseCard, className)}
      image={<img src={course.get('imageUrl')} alt="" />}
      onClick={() => {
        dispatch(push(coursePath, {courseId: course.get('courseId')}));
      }}
    >
      <div className={styles.content}>
        <p className={styles.duration}>
          <Icon name="far fa-clock" /> <Minutes minutes={course.get('totalMinutes')} minutesOnly />
        </p>
        <CardProgress course={course} />
      </div>
    </Card>
  );
}

export default CourseCard;
