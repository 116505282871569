export function validateKeywordsAndEngines({engines = [], keywords}) {
  // if we have keywords with no search engines, complain
  if ((!engines || !engines.length) && keywords && keywords.length) {
    return {
      isValid: false,
      target: 'engines',
      message: 'You must specify at least one search engine for your keywords.'
    };
  } else if ((!engines || engines.length) && (!keywords || !keywords.length)) {
    return {
      isValid: false,
      target: 'engines',
      message: 'You must specify at least one keyword for your search engines.'
    };
  }

  return {
    isValid: true,
    target: 'engines',
    message: ''
  };
}
