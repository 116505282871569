import PropTypes from 'prop-types';
import React from 'react';
import {CSSTransitionGroup} from 'react-transition-group';
import cx from 'classnames';

class TransitionGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  getChildren() {}

  render() {
    let {children} = this.props;
    const {className, transitionAppear, ...props} = this.props;
    const {mounted} = this.state;

    const classes = cx([className, 'has-transition']);

    if (transitionAppear && !mounted) {
      children = null;
    }

    return (
      <CSSTransitionGroup
        transitionAppearTimeout={2000}
        transitionEnterTimeout={2000}
        transitionLeaveTimeout={2000}
        {...this.props}
        className={classes}
      >
        {children}
      </CSSTransitionGroup>
    );
  }
}

TransitionGroup.propTypes = {
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
  transitionName: PropTypes.any.isRequired,
  transitionAppear: PropTypes.bool
};

TransitionGroup.defaultProps = {
  transitionAppear: false
};

export default TransitionGroup;
