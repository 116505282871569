import {Map} from 'immutable';
import {filterKey} from 'modules/filters/filterUtils';

const initialState = Map();
export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_DEFAULT_FILTERS:
      return state.set(filterKey(action), action.defaultFilters);
  }

  return state;
}

export const SET_FILTER_PARAMS = 'filters:SET_FILTER_PARAMS';
export function setFilterParams(newFilters, tab) {
  return {
    type: SET_FILTER_PARAMS,
    newFilters,
    tab
  };
}

export const TOGGLE_FILTER_PARAMS = 'filters:TOGGLE_FILTER_PARAMS';
export function toggleFilterParam(param) {
  return {
    type: TOGGLE_FILTER_PARAMS,
    param
  };
}

export const FILTER_ADDED = 'filters:FILTER_ADDED';
export function addFilter(filter) {
  return {type: FILTER_ADDED, filter};
}

export const FILTER_BY_STRING = 'filters:FILTER_BY_STRING';
export function filterByString(filter) {
  return {type: FILTER_BY_STRING, filter};
}

export const RECEIVE_DEFAULT_FILTERS = 'filters:RECEIVE_DEFAULT_FILTERS';
export function receiveDefaultFilters(reportId, testId, defaultFilters) {
  return {
    type: RECEIVE_DEFAULT_FILTERS,
    reportId,
    testId,
    defaultFilters
  };
}
