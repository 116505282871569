export function matchesSearch(str, searchTerm) {
  return str.toLowerCase().includes(searchTerm.toLowerCase());
}

export function sortByLabelAsc(a, b) {
  a = a.label.toLowerCase();
  b = b.label.toLowerCase();
  return a < b ? -1 : b < a ? 1 : 0;
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
