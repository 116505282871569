import React from 'react';
import {connect} from 'react-redux';
import {interpolate} from 'utils/interpolationUtils';
import styles from './upgrader.scss';
import {reportObjectSelector} from 'modules/reports/reportSelectors';
import {showUpgradeModal} from 'modules/onboarding';

export class Upgrader extends React.Component {
  handleClick = () => {
    this.props.showUpgradeModal();
  };

  render() {
    const {result, report} = this.props;
    const label = 'Only {value|int} pages were tested. ';

    if (!report || !report.get('isDemo')) {
      return null;
    }

    return (
      <div className={cx(styles.banner, styles.clickable)} onClick={this.handleClick}>
        <span>{interpolate(label, {value: result})}</span>
        <span className={styles.underline}>Upgrade to test more.</span>
      </div>
    );
  }
}

Upgrader.handlesLoading = true;

export default connect(
  reportObjectSelector,
  {showUpgradeModal}
)(Upgrader);
