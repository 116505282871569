import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {addMessage} from 'modules/alerts';
import {userResetPasswordSelectors} from 'modules/users/userSelectors';
import {sendUnlockEmail, validateUnlockUserToken} from 'modules/passwords';
import Spinner from 'components/spinner';
import Button from 'components/button';
import {loginPath} from '../authUtils';
import {Link} from 'react-router-dom';
import styles from 'modules/auth/components/authWrapperShared.scss';

export class UnlockUserPage extends React.Component {
  componentDidMount() {
    const {validateUnlockUserToken, userId, token} = this.props;

    validateUnlockUserToken(userId, token);
  }

  handleClickResend = () => {
    const {sendUnlockEmail, userId} = this.props;

    sendUnlockEmail({userId});
  };

  renderEmailStatus() {
    const {emailRequestSuccess, emailRequestFailed} = this.props;

    if (emailRequestSuccess) {
      return (
        <div>
          <p className={styles.header}>Email sent</p>
          <p className={styles.text}>The email request to unlock your account has been sent</p>
        </div>
      );
    }

    if (emailRequestFailed) {
      return (
        <div>
          <p className={styles.header}>Failed to send email</p>
          <p className={styles.text}>
            The email request to unlock your account has failed to send. Click below to send a new
            email to your email address that will contain a new link.
          </p>

          <Button fullWidth destructive onClick={this.handleClickResend}>
            Click here to send a new email
          </Button>
        </div>
      );
    }
  }

  render() {
    const {requestingToken, tokenExpired, requestingEmail, attemptSendUnlockEmail} = this.props;

    if (requestingToken || requestingEmail) {
      return <Spinner centered />;
    }

    if (attemptSendUnlockEmail) {
      return this.renderEmailStatus();
    }

    if (tokenExpired) {
      return (
        <div>
          <p className={styles.header}>Failed to unlock account</p>
          <p className={styles.text}>
            This request to unlock your account has expired. Click below to send a new email to your
            email address that will contain a new link.
          </p>

          <Button fullWidth destructive onClick={this.handleClickResend}>
            Click here to send a new email
          </Button>
        </div>
      );
    }

    return (
      <div>
        <p className={styles.header}>Account unlocked</p>
        Your account has been unlocked. Click <Link to={loginPath()}>here</Link> to log in.
      </div>
    );
  }
}

export default connect(
  userResetPasswordSelectors,
  {
    push,
    addMessage,
    sendUnlockEmail,
    validateUnlockUserToken
  }
)(UnlockUserPage);
