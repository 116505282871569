import {select, call, takeLatest} from 'redux-saga/effects';
import {REQUEST_DELETE_FOLDER, receiveFolders} from 'modules/folders';
import {folderEndpoint} from 'modules/folders/folderUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import confirm from 'utils/saga/confirm';
import {showError} from 'utils/saga/alerts';
import getErrorMessage from 'api/errors';
import {selectFolder} from 'modules/folders/folderSelectors';

export default function* deleteFolderSaga() {
  yield takeLatest(REQUEST_DELETE_FOLDER, confirmAndDeleteFolder);
}

function* confirmAndDeleteFolder({folderId}) {
  const folder = yield select(selectFolder(folderId));
  const confirmed = yield* confirm({
    title: 'Delete folder',
    content: `Are you sure you wish to delete the folder named "${folder.get('name')}"?`
  });

  if (!confirmed) return;

  const res = yield processRequest('delete', folderEndpoint({folderId}), {
    successAction: receiveFolders,
    error: function*(error, resp) {
      const {errorCode} = error;
      var message = getErrorMessage(errorCode, {}, 'Unable to delete folder');

      yield call(showError, message);
    }
  });
}
