import {inspectorPagePath} from 'modules/inspector/inspectorUtils';
import {policyPath} from 'modules/policies/policyUtils';
import {actionPath} from 'modules/actions/actionUtils';

// https://www.notion.so/silktide/Instruction-object-3c51873bc35c4dd4a16576112740d2bb
const InstructionActionUrlMap = {
  inspector: inspectorPagePath,
  policy: policyPath,
  action: actionPath
};

export function getLinkBuilder(type) {
  const builder = InstructionActionUrlMap[type];

  if (!builder) {
    console.error(`Control type "${type}" did not map to a url builder function`);
    return null;
  }

  return builder;
}
