import {fromJS} from 'immutable';
import {
  RECEIVE_ACCOUNT_DECISION_PAGE,
  RECEIVE_ACCOUNT_DECISIONS,
  FETCH_ACCOUNT_DECISIONS_PAGE,
  FETCH_ACCOUNT_DECISIONS,
  RECEIVE_DECISION
} from './decisions';

const initialState = fromJS({
  isLoading: false,
  accountDecisions: null,
  accountDecisionStats: null,
  decisions: {}
});

export default function decisionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_DECISIONS_PAGE:
      return state.set('isLoading', true);

    case RECEIVE_ACCOUNT_DECISION_PAGE:
      return state
        .set('isLoading', false)
        .set('accountDecisions', action.accountDecisions)
        .set('accountDecisionStats', action.accountDecisionStats);

    case FETCH_ACCOUNT_DECISIONS:
      return state.set('isDecisionsLoading', true);

    case RECEIVE_ACCOUNT_DECISIONS:
      return state
        .set('isDecisionsLoading', false)
        .set('accountDecisions', action.accountDecisions);

    case RECEIVE_DECISION:
      return state.setIn(['decisions', action.decision.decisionId], fromJS(action.decision));
  }
  return state;
}
