import React from 'react';
import styles from './misspelling.scss';
import Tooltip from 'components/tooltip';
import ControlLink from 'components/control/controlLink';
import defaultStyles from './styles.scss';
import {connect} from 'react-redux';
import {locationSelector} from 'modules/location/locationSelectors';

class Misspelling extends React.Component {
  render() {
    const {record, options, filters, params} = this.props;
    const {word, before, after, correction} = record;

    if (!word) return null;

    // @todo Add more useful information here, and make this look nicer

    const excerpt = (
      <div>
        <p className={styles.quote}>
          <span>{before}</span>
          <span className={styles.misspelling}>{word}</span>
          <span>{after}</span>
        </p>
      </div>
    );

    let wordChildren = !correction ? (
      <span>{word}</span>
    ) : (
      <span dangerouslySetInnerHTML={{__html: correction}} />
    );

    if (options.instruction) {
      wordChildren = (
        <ControlLink
          instruction={options.instruction}
          context={{...params, ...record}}
          filters={filters}
        >
          {wordChildren}
        </ControlLink>
      );
    }

    return (
      <Tooltip text={excerpt}>
        <div className={styles.correction}>{wordChildren}</div>
      </Tooltip>
    );
  }
}

export default connect(locationSelector)(Misspelling);
