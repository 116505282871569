import PropTypes from 'prop-types';
import React from 'react';
import {formatGivenNumber} from 'utils/numberUtils.js';
import styles from './styles.scss';

class Int extends React.Component {
  render() {
    const {data, options} = this.props;

    return <div className={styles[options.class]}>{formatGivenNumber(data)}</div>;
  }
}

Int.propTypes = {
  data: PropTypes.any,
  options: PropTypes.object
};

export default Int;
