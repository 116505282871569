import {createSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';

export const languagesSelector = state => state.languages;

// best name ever.
export const languageSelectorSelector = createSelector(
  languagesSelector,
  languages => {
    return {
      languages: languages.toList().toJS()
    };
  }
);
