import {Map, List, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';
import {
  REQUEST_SERVICES,
  RECEIVE_SERVICES,
  REQUEST_SERVICE_BREAKDOWN,
  RECEIVE_SERVICE,
  RECEIVE_SUBSCRIPTION,
  RECEIVE_USAGE_DATA_FOR,
  FETCH_USAGE_DATA_FOR,
  USAGE_DATA_FETCH_ERROR,
  SERVICES_ERROR,
  CLEAR_SERVICES
} from './servicesActions';

const initialState = Map({
  data: Map(),
  isLoading: false,
  error: null,
  usageData: List(),
  usageDataLoading: false,
  usageDataFetchError: null
});

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SERVICES:
      return setLoadingState(state, true, null).set('data', Map());
    case REQUEST_SERVICE_BREAKDOWN:
      return setLoadingState(state, true, null).set('data', Map());
    case RECEIVE_SERVICES:
      return setLoadingState(state, false, null).set('data', fromJS(action.services));
    case RECEIVE_SERVICE:
      return setLoadingState(state, false, null).setIn(
        ['data', action.service.serviceId.toString()],
        Map(action.service)
      );
    case SERVICES_ERROR:
      return setLoadingState(state, false, action.error);
    case FETCH_USAGE_DATA_FOR:
      return state
        .set('usageDataLoading', true)
        .set('usageDataFetchError', null)
        .set('usageData', List());
    case RECEIVE_USAGE_DATA_FOR:
      return state
        .set('usageDataLoading', false)
        .set('usageDataFetchError', null)
        .set('usageData', fromJS(action.usageData));
    case RECEIVE_SUBSCRIPTION:
      const serviceId = state.get('data').findKey(service => {
        return service.get('subscription').subscriptionId == action.subscription.subscriptionId;
      });
      if (!serviceId) return state;

      const subscription = state.getIn(['data', serviceId.toString(), 'subscription']);
      if (!subscription) return state;

      return state.setIn(['data', serviceId.toString(), 'subscription'], {
        ...subscription,
        state: action.subscription.state,
        endDate: action.subscription.endDate,
        startDate: action.subscription.startDate,
        canceledDate: action.subscription.canceledDate,
        planStartDate: action.subscription.planStartDate
      });
    case USAGE_DATA_FETCH_ERROR:
      return state.set('usageDataLoading', false).set('usageDataFetchError', action.err);
    case CLEAR_SERVICES:
    case SWITCH_ACCOUNT:
      return initialState;
  }

  return state;
}

function setLoadingState(state, loading, error) {
  return state.set('isLoading', loading).set('error', error);
}
