import React from 'react';
import {useSelector} from 'hooks/redux';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import {coursesPageSelector} from '../selectors';
import CoursesGrid from '../components/coursesGrid';
import styles from './coursesPage.scss';
import courseStyles from '../pages/courses.scss';

function CoursesBanner() {
  return (
    <div className={styles.banner}>
      <h1>Welcome to the Silktide Academy</h1>
      <p>Learn about Silktide and improving your websites</p>
    </div>
  );
}

function NoCourses() {
  return (
    <div>
      <h2>No courses available</h2>
      <p className={styles.notice}>
        This account doesn't have access to any courses right now. Talk to Silktide about adding
        some.
      </p>
    </div>
  );
}

function CoursesList({courses}) {
  if (!courses || !courses.size) {
    return (
      <div>
        <h2>No courses available</h2>
        <p className={styles.notice}>
          This account doesn't have access to any courses right now. Talk to Silktide about adding
          some.
        </p>
      </div>
    );
  }

  const formattedCourses = courses.sort((a, b) => b.get('sortOrder') - a.get('sortOrder'));

  const yourCourses = formattedCourses.filter(course => !!course.get('started'));
  const otherCourses = formattedCourses.filter(course => !course.get('started'));

  return (
    <div>
      {!!yourCourses.size && (
        <div>
          <h2>Your courses</h2>
          <CoursesGrid courses={yourCourses} />
        </div>
      )}

      {!!otherCourses.size && (
        <div>
          <h2>{!!yourCourses.size ? 'Other courses' : 'Available courses'}</h2>
          <CoursesGrid courses={otherCourses} />
        </div>
      )}
    </div>
  );
}

export default function CoursesPage() {
  const {courses, requestingCourses} = useSelector(coursesPageSelector);

  return (
    <div className={styles.background}>
      <Helmet title="Silktide Academy" />
      <PageBody isLoading={requestingCourses.get('loading')}>
        <div className={courseStyles.container}>
          <div className={courseStyles.titleStyle}>
            <CoursesBanner />
            <hr />
            {courses && courses.size ? <CoursesList courses={courses} /> : <NoCourses />}
          </div>
        </div>
      </PageBody>
    </div>
  );
}
