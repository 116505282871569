import {take} from 'redux-saga/effects';

import {NEXT_STEP, PREV_STEP} from './onboarding';

export function* pickProduct() {
  const {type, data} = yield take([NEXT_STEP, PREV_STEP]);

  if (type == PREV_STEP) {
    return null;
  }

  return {productId: data};
}

export function* pickAccount() {
  const {userType, data: user} = yield take(NEXT_STEP);

  if (userType == PREV_STEP) {
    return null;
  }

  const {accountType, data: account} = yield take(NEXT_STEP);

  if (accountType == PREV_STEP) {
    return null;
  }

  return {user, account};
}

export function* pickPayment() {
  const {type, data} = yield take(NEXT_STEP);

  return data;
}

export function onboardingPath() {
  return '/signup';
}
