import {takeEvery} from 'redux-saga/effects';
import {UPDATE_ACTION, receiveAction} from 'modules/actions/actions';
import {actionEndpoint} from 'modules/actions/actionUtils';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* updateActionSaga() {
  yield takeEvery(UPDATE_ACTION, updateAction);
}

function* updateAction({reportId, action}) {
  // remove description from action cos backend doesn't like it TODO fix on backend
  const {description, ...filteredAction} = action;

  yield processRequest(
    'POST',
    actionEndpoint({reportId, actionId: action.actionId}),
    {
      successAction: receiveAction
    },
    filteredAction
  );
}
