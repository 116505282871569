import React from 'react';
import cx from 'classnames';
import styles from './table.scss';
import ExplorerDataRenderer from 'components/layoutComponents/explorer/explorerDataRenderer';

export default class TableRow extends React.Component {
  render() {
    const {className, row, columns, ...props} = this.props;
    const rowClasses = cx({[className]: !!className});

    return (
      <ExplorerDataRenderer
        {...props}
        record={row}
        fields={columns}
        className={rowClasses}
        styles={styles}
        wrapperComponent="tr"
        dataComponent="td"
      />
    );
  }
}
