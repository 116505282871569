import React from 'react';
import cx from 'classnames';
import Icon from 'components/font-awesome';
import styles from './alert.scss';

class Alert extends React.Component {
  render() {
    const {children, level, icon, message, className, noMargin, padding} = this.props;

    if (!message && !children) return null;

    return (
      <div
        className={cx(styles.alert, 'alert', styles['alert-' + level], className, {
          [styles.noMargin]: noMargin,
          [styles.padding10]: padding == 10
        })}
        role="alert"
      >
        {!!icon && <Icon name={icon} aria-hidden="true" className={styles.icon} />}
        <span>{message || children}</span>
      </div>
    );
  }
}

export default Alert;
