import PropTypes from 'prop-types';
import React from 'react';
import {havePropsChanged} from 'utils/propUtils';
import styles from './animateOnChange.scss';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// Usage:
//
// <AnimateOnChange
//   animationClassName='bounce'
//   comparisonProps={['value']}
//   value={1}
// />
//
// If comparisonProps isn't set, we compare all props

// If props.comparator is set, we use that to decide if
// we should animate.

export default class AnimateOnChange extends React.Component {
  static defaultProps = {
    element: 'span'
  };

  constructor() {
    super();
    this.state = {
      // Are we currently animating?
      animating: false
    };
  }

  propsHaveChanged(nextProps) {
    if (nextProps.comparator) {
      return nextProps.comparator(nextProps, this.props);
    }

    return havePropsChanged(nextProps, this.props, nextProps.comparisonProps);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.animating && this.propsHaveChanged(nextProps)) {
      this.setState({animating: true});
    }
  }

  handleAnimationEnd = (...args) => {
    this.setState({animating: false});
    const {onAnimationEnd} = this.props;
    onAnimationEnd && onAnimationEnd(...args);
  };

  render() {
    const {element, animationClassName, children, className, ...props} = this.props;

    const classes = cx(styles.base, className, {
      [animationClassName]: this.state.animating
    });

    return React.createElement(
      element,
      {
        ...filterInvalidDOMProps(props),
        className: classes,
        onAnimationEnd: this.handleAnimationEnd
      },
      children
    );
  }
}
