exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17Wo8YkowaeoF14kxADLQl{position:relative;padding:5px 10px;color:#515151;cursor:pointer}._17Wo8YkowaeoF14kxADLQl p{margin:0}._17Wo8YkowaeoF14kxADLQl._2sogEeEEKNL12Hpa3xPtu8{opacity:1}.SwxXrzBSv97XKZkOwY2NG{font-size:16px;font-weight:600;margin:0;color:#030303}.yxUm7UWHbPtmCPImKtjUv{margin-left:15px;color:#777}.yxUm7UWHbPtmCPImKtjUv.JnpQeuNsVPXBEzvWPllYx{opacity:.7}", ""]);

// exports
exports.locals = {
	"product": "_17Wo8YkowaeoF14kxADLQl",
	"selected": "_2sogEeEEKNL12Hpa3xPtu8",
	"title": "SwxXrzBSv97XKZkOwY2NG",
	"remaining": "yxUm7UWHbPtmCPImKtjUv",
	"positive": "JnpQeuNsVPXBEzvWPllYx"
};