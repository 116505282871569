import {put, select, takeEvery} from 'redux-saga/effects';
import {DELETE_REPORT} from 'modules/reports/reportConstants';
import {reportEndpoint} from 'modules/reports/reportUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {push} from 'modules/location';
import {homePath} from 'modules/app/appUtils';
import {folderLastLocationSelector} from 'modules/folderViews/folderViewSelectors';
import {couldNot} from 'utils/errorUtils';

export default function* deleteReportSaga() {
  yield takeEvery(DELETE_REPORT, deleteReport);
}

function* deleteReport({reportId, redirectAfter}) {
  yield processRequest('DELETE', reportEndpoint({reportId}), {
    success: function*() {
      // If deleting from the reports page, redirect to the previous folder
      if (redirectAfter || /reports\/\d+/.test(window.location.href)) {
        const lastLocation = yield select(folderLastLocationSelector);
        yield put(push(lastLocation || homePath));
      }
    },
    errorMessage: couldNot('delete website')
  });
}
