import _ from 'lodash';
import {getProps} from 'utils/immutableUtils';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

// Used when you have a bag of pros and you want to split out the valid DOM props from the invalid ones
export function splitInvalidDOMProps(props) {
  const validDomProps = filterInvalidDOMProps(props);
  const invalidDomProps = Object.keys(props).reduce((result, propKey) => {
    if (!(propKey in validDomProps)) {
      result[propKey] = props[propKey];
    }
    return result;
  }, {});

  return [validDomProps, invalidDomProps];
}

export function splitProps(props, propKeys) {
  const validProps = {};
  const invalidProps = {};

  for (let propKey in props) {
    if (propKeys.includes(propKey)) {
      validProps[propKey] = props[propKey];
    } else {
      invalidProps[propKey] = props[propKey];
    }
  }
  return [validProps, invalidProps];
}

// Figures out if any prop has changed.
// if comparisonProps is set, we compare a subset.
// i.e. havePropsChanged(a, b, ['title', 'name', 'address.street'])
export function havePropsChanged(oldProps, newProps, comparisonProps) {
  if (comparisonProps) {
    oldProps = getProps(oldProps, comparisonProps);
    newProps = getProps(newProps, comparisonProps);
  }

  return Object.keys(oldProps).some(key => {
    return !_.isEqual(_.get(oldProps, key), _.get(newProps, key));
  });
}

export function getPrefixedProps(obj, prefix) {
  return _.pickBy(obj, (value, key) => {
    return key.substr(0, prefix.length) === prefix;
  });
}
