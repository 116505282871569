exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._114l8C0SlUaKXYDBlmqVEg{width:100%;height:auto}._2reBd8CcdyfXjUvEcILUUj{padding:0;position:relative;top:4px}._2reBd8CcdyfXjUvEcILUUj h2{font-weight:800;margin:20px 20px 0px 20px}._2reBd8CcdyfXjUvEcILUUj p{margin:20px}", ""]);

// exports
exports.locals = {
	"newsletterImg": "_114l8C0SlUaKXYDBlmqVEg",
	"modalContent": "_2reBd8CcdyfXjUvEcILUUj"
};