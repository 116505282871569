exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Tc0TedUFtNPav2AyLDPSV{text-align:center;margin-top:50px;padding:16px;width:100%}._1QWXbHqBZOGiR6PwE-y9sY{margin-bottom:60px}._2tv7Mtp7lfD9RHVjE3I9hF{margin-top:30px}hr{border-top:1px solid #ddd}._2_qKxWrZuDfbE9TA4isWON{margin-top:60px}._2_qKxWrZuDfbE9TA4isWON h4{margin-top:15px;color:#333}._2_qKxWrZuDfbE9TA4isWON p{color:#333;margin:0 auto 20px}._2_qKxWrZuDfbE9TA4isWON img{width:100%}._2_qKxWrZuDfbE9TA4isWON ._1O5gbVsHVRAwbG6fTsQbl2{margin-top:20px;max-width:500px;height:auto;box-shadow:0 0 20px rgba(0,0,0,.2)}", ""]);

// exports
exports.locals = {
	"noSitesContainer": "_2Tc0TedUFtNPav2AyLDPSV",
	"addWebsiteContainer": "_1QWXbHqBZOGiR6PwE-y9sY",
	"addReportBtn": "_2tv7Mtp7lfD9RHVjE3I9hF",
	"guide": "_2_qKxWrZuDfbE9TA4isWON",
	"screenshot": "_1O5gbVsHVRAwbG6fTsQbl2"
};