exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".e7vuieTLnPoXKKiy090AI{text-align:center;color:#999;margin-top:20px}", ""]);

// exports
exports.locals = {
	"text": "e7vuieTLnPoXKKiy090AI"
};