exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1l_i_spCFryZ5oahMa6sOE:focus{outline:0}._1l_i_spCFryZ5oahMa6sOE ._2P8n3yH3AfRQVvEhmGEEL5{cursor:pointer;margin-bottom:.5em}", ""]);

// exports
exports.locals = {
	"choice": "_1l_i_spCFryZ5oahMa6sOE",
	"tickSelector": "_2P8n3yH3AfRQVvEhmGEEL5"
};