import {createSelector, createStructuredSelector} from 'reselect';
import {makeLoadingStateSelector} from 'modules/app/reduxHelpers';

const domainSelector = state => state.tags;

export const tagsSelector = createSelector(
  domainSelector,
  tagState => tagState.get('tags')
);

export const tagsSummaryStateSelector = createSelector(
  domainSelector,
  tagState => tagState.get('tagsSummary')
);

export const tagsSummarySelector = createSelector(
  tagsSummaryStateSelector,
  tagsSelector,
  (tagsSummary, tags) => {
    return tagsSummary.map(tagSummary => {
      const tag = tags.get(tagSummary.get('tagId').toString());
      return !tag
        ? tagSummary
        : tagSummary.set('label', tag.get('label')).set('color', tag.get('color'));
    });
  }
);

export const assignedEntitiesSelector = createSelector(
  domainSelector,
  tagState => tagState.get('assignedEntities')
);

export const tagOptionsSelector = createSelector(
  tagsSelector,
  tags =>
    tags
      .map(tag => ({
        value: tag.get('tagId'),
        label: tag.get('label')
      }))
      .toList()
);

export const loadingTagsStateSelector = makeLoadingStateSelector(domainSelector, 'requestingTags');
export const loadingTagsSummaryStateSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingTagsSummary'
);
export const editingTagSelector = makeLoadingStateSelector(domainSelector, 'editingTag');
export const creatingTagStateSelector = makeLoadingStateSelector(domainSelector, 'creatingTag');
export const assigningTagsStateSelector = makeLoadingStateSelector(domainSelector, 'assigningTags');
export const requestingAssignedTagsStateSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingAssignedTags'
);
export const requestingAssignedEntitiesStateSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingAssignedEntities'
);

export const tagsInputSelector = createSelector(
  tagOptionsSelector,
  loadingTagsStateSelector,
  requestingAssignedTagsStateSelector,
  creatingTagStateSelector,
  (tagOptions, tagsLoadingState, requestingAssignedTags, creatingTag) => {
    return {
      tagOptions,
      hasLoaded: tagsLoadingState.get('loaded'),
      tagsLoading: tagsLoadingState.get('loading'),
      dataLoading: requestingAssignedTags.get('loading'),
      creatingTags: creatingTag.get('loading')
    };
  }
);

export const assignedTagsComponentSelector = createSelector(
  requestingAssignedTagsStateSelector,
  assigningTagsStateSelector,
  (requestingEntityTags, assigningEntityTags) => ({
    requestingEntityTags,
    assigningEntityTags // includes assignTags and unassignTags actions
  })
);

export const tagsSettingsPageSelector = createStructuredSelector({
  tags: tagsSelector,
  tagsSummary: tagsSummarySelector,
  requestingTags: loadingTagsStateSelector,
  requestingTagsSummary: loadingTagsSummaryStateSelector
});

export const tagSettingsPageSelector = createStructuredSelector({
  tags: tagsSelector,
  requestingTags: loadingTagsStateSelector,
  assignedEntities: assignedEntitiesSelector,
  requestingAssignedEntities: requestingAssignedEntitiesStateSelector
});
