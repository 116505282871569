import {take, call, select, put, takeLatest} from 'redux-saga/effects';
import {SEND_RESET_EMAIL, passwordResetFailed} from 'modules/passwords';
import POST from 'utils/saga/post';
import {addMessage} from 'modules/alerts';
import {processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* sendResetEmail() {
  yield takeLatest(SEND_RESET_EMAIL, sendEmail);
}

function* sendEmail({email}) {
  const res = yield call(POST, 'users/password/reset', {email});

  yield processResponse(res, {
    errorMessage: couldNot('send email'),
    errorAction: passwordResetFailed
  });
}
