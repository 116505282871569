exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1t5MQmXuwBVbqW1_LuQY7f{margin-top:-10px}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC{border:1px solid #ddd;box-shadow:0 2px 5px rgba(0,0,0,.1);background:#fff;margin-top:10px;margin-bottom:10px}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2jsBk78raUM-2BLH1JZsm2{display:flex;align-items:center;cursor:pointer}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2jsBk78raUM-2BLH1JZsm2 .xV8xFXjYBpiRgUvfsEIJW{height:60px;width:60px;display:flex;align-items:center;justify-content:center;font-size:20px;background:#ddd;margin-right:10px}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2jsBk78raUM-2BLH1JZsm2 .HXznAR5NsCdYCBXeOPKQQ,._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2jsBk78raUM-2BLH1JZsm2 ._3e30pxhWazdmOeOEckV2e8{margin:0 10px;font-size:1.2em}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2jsBk78raUM-2BLH1JZsm2 .HXznAR5NsCdYCBXeOPKQQ{font-weight:bold}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2RDlFfNEIubzjPmWhmsb2s{padding-left:60px}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2RDlFfNEIubzjPmWhmsb2s img{border:1px solid #ddd}._1t5MQmXuwBVbqW1_LuQY7f ._1LCGidrEnLAsaFBnkdqaiC ._2RDlFfNEIubzjPmWhmsb2s._2_jm9jUlRniS684XpFzcwh{height:0 !important}.JQMCfJwbf3CnyyCaZsvFJ{margin-top:35px}.JQMCfJwbf3CnyyCaZsvFJ:first-of-type{margin-top:0}._2EHgWUNf82SdkQaUenHrMr{margin:10px;margin-top:0}.MhmUzsMDyOfDhxHd5_f6f{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:150px}._1zgeB9X59juyrQb9SLM07V{list-style:none;display:flex;padding:0;overflow:auto}._28v6NxvvCNsO1lc0CpqiLa{display:flex;flex-direction:column;justify-content:start;align-items:center;margin:10px;flex:0 0 170px;cursor:pointer}._28v6NxvvCNsO1lc0CpqiLa img{margin-bottom:10px;max-width:175px}._28v6NxvvCNsO1lc0CpqiLa a{text-align:center}._1haPogcbxgNlFbnRxu_a3B{margin:10px}._3q-MfBu2kTJYNypAOnGIxA{text-align:center}", ""]);

// exports
exports.locals = {
	"timeline": "_1t5MQmXuwBVbqW1_LuQY7f",
	"story": "_1LCGidrEnLAsaFBnkdqaiC",
	"storyHead": "_2jsBk78raUM-2BLH1JZsm2",
	"icon": "xV8xFXjYBpiRgUvfsEIJW",
	"name": "HXznAR5NsCdYCBXeOPKQQ",
	"title": "_3e30pxhWazdmOeOEckV2e8",
	"storyBody": "_2RDlFfNEIubzjPmWhmsb2s",
	"isHidden": "_2_jm9jUlRniS684XpFzcwh",
	"timeWrapper": "JQMCfJwbf3CnyyCaZsvFJ",
	"table": "_2EHgWUNf82SdkQaUenHrMr",
	"pageName": "MhmUzsMDyOfDhxHd5_f6f",
	"pagesList": "_1zgeB9X59juyrQb9SLM07V",
	"pagesListItem": "_28v6NxvvCNsO1lc0CpqiLa",
	"showMore": "_1haPogcbxgNlFbnRxu_a3B",
	"showMoreControl": "_3q-MfBu2kTJYNypAOnGIxA"
};