import FieldGroup from './FieldGroup';

export default class Form extends FieldGroup {
  constructor(formName, fnRerender, fnValidate) {
    super(formName === undefined ? [] : [formName], fnRerender, fnValidate);
  }

  // Allows us to destructure the form instance like `[...blah] = form;`
  // Can also be used in `for..of` loops. yields [form, fields];
  // This is safe because babel polyfills `Symbol.iterator` and destructures
  // (which get's around IE) and it polyfills generator functions

  // DOESNT WORK IN CHELL. MAY NEED A POLYFILL OR SOMETHING BECAUSE IT SHOULD WORK
  // *[Symbol.iterator]() {
  //   yield this;
  //   yield this.getFields();
  // }

  // Sets the form to submitted and returns true if valid
  attemptSubmit() {
    // this will set `submitted` to true so that `wasAttempted` will return true (used in Rule)
    this.setSubmitted();
    this.rerender();

    return !this.hasError();
  }
}
