import {toJS} from 'utils/immutableUtils';
import {createSelector} from 'reselect';
import {createPropOrParamSelector} from 'modules/location/locationSelectors';

export const notificationsStateSelector = state => state.notifications;

export const notificationIdSelector = createPropOrParamSelector('notificationId');

export const notificationsLoadedSelector = createSelector(
  notificationsStateSelector,
  state => state.get('loaded')
);

export const notificationsSelector = createSelector(
  notificationsStateSelector,
  state => ({
    notifications: state
      .get('data')
      .sortBy(n => -n.get('createdTime'))
      .toList()
      .toJS()
  })
);

export const notificationSelector = createSelector(
  notificationsStateSelector,
  notificationIdSelector,
  (state, notificationId) => ({
    notification: toJS(state.getIn(['data', notificationId]))
  })
);

export const notificationsCountSelector = createSelector(
  notificationsSelector,
  ({notifications}) => ({
    count: notifications.length,
    unshownCount: notifications.filter(n => n.shownTime == 0).length
  })
);
