import React from 'react';
import {toDecimal} from 'utils/numberUtils';
import {connect} from 'react-redux';
import {currencySelector} from 'modules/products/productSelectors';
import Spinner from 'components/spinner';

function Price({price, interval, className, intervalClassName, symbol = '$'}) {
  if (typeof price === 'undefined') {
    return (
      <div className={className}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={className}>
      {symbol}
      {toDecimal(price, 2)}

      {interval && <span className={intervalClassName}> / {intervals[interval]}</span>}
    </div>
  );
}

const intervals = {
  month: 'month',
  year: 'year'
};

export default connect(currencySelector)(Price);
