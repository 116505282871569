import {randomHash, lowercaseFirstLetter} from 'utils/stringUtils';

const requireTimelinePoint = require.context('./storyTypes', false, /.*\.js/);

// E.G. from 'NewPagesStoryType' > 'newPagesStory'
function getStoryTypeFilename(storyTypeId) {
  return lowercaseFirstLetter(storyTypeId).replace(/StoryType$/, '');
}

export function getTimelinePoint(storyTypeId) {
  if (!storyTypeId) return null;

  const storyTypeFilename = getStoryTypeFilename(storyTypeId);

  try {
    return requireTimelinePoint('./' + storyTypeFilename + '.js');
  } catch (e) {}

  try {
    return requireTimelinePoint('./' + storyTypeFilename + '/index.js');
  } catch (e) {}

  console.warn('Could not find component for story type: ', storyTypeFilename);

  return null;
}
