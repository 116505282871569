export const FETCH_ALERT_SUBSCRIPTIONS = 'alertSubcriptions:FETCH_ALERT_SUBSCRIPTIONS';
export const FETCH_ALERT_SUBSCRIPTIONS_COMPLETE =
  'alertSubcriptions:FETCH_ALERT_SUBSCRIPTIONS_COMPLETE';

export const RECEIVE_ALERT_SUBSCRIPTIONS = 'alertSubcriptions:RECEIVE_ALERT_SUBSCRIPTIONS';
export const RECEIVE_AVAILABLE_STORY_TYPES = 'alertSubcriptions:RECEIVE_AVAILABLE_STORY_TYPES';
export const SET_ALERT_SUBSCRIPTION = 'alertSubcriptions:SET_ALERT_SUBSCRIPTION';
export const SET_ALERT_SUBSCRIPTION_STATE = 'alertSubcriptions:SET_ALERT_SUBSCRIPTION_STATE';

export const OPEN_EDIT_USER_ALERTS_MODAL = 'alertSubcriptions:OPEN_EDIT_USER_ALERTS_MODAL';
