// Loads table cell and grid 'renderer' components for use in layouts
export function getRendererComponent(column, component) {
  const type = column.type || 'string';
  try {
    return require(`components/layoutComponents/renderers/${type}-${component}.js`);
  } catch (e) {
    try {
      return require(`components/layoutComponents/renderers/${type}.js`);
    } catch (e) {
      if (__DEV__) {
        console.warn(`Could not load renderer ${type}. Using default.js`, e);
      }
    }
  }
  return require('components/layoutComponents/renderers/default.js');
}

// an items key can be either row[TableProps.rowKey]
// or a property of row literally called 'rowKey'
// It can also be a function that takes the row and spits out a key.
export function getItemKey(item, itemKey, index) {
  if (!itemKey) {
    if (item['rowKey'] == null) {
      return index;
    }
    return item['rowKey'];
  }

  if (typeof itemKey === 'function') {
    return itemKey(item);
  }

  if (item[itemKey] == null) {
    return index;
  }
  return item[itemKey];
}

// Returns the value to scale `inner` by in order that it fits within `outer`
export function getScale(inner, outer) {
  const hasOverflowX = inner.width > outer.width;
  const hasOverflowY = inner.height > outer.height;

  const minHorScale = outer.width / inner.width;
  const minVerScale = outer.height / inner.height;

  if (hasOverflowX && hasOverflowY) return Math.min(minHorScale, minVerScale);

  if (hasOverflowX) return minHorScale;

  if (hasOverflowY) return minVerScale;

  return 1;
}

export function filterComponentsByName(layoutComponents, name) {
  return layoutComponents.reduce((carry, layoutComponent) => {
    if (layoutComponent.type === name) {
      carry.push(layoutComponent);
    }

    if (Array.isArray(layoutComponent.children)) {
      carry.push(...filterComponentsByName(layoutComponent.children, name));
    }

    return carry;
  }, []);
}

export function layoutConfigComponentToOptions(testId, layout) {
  return filterComponentsByName(layout, 'Config').map(config => {
    if (config['global-name']) {
      return {globalName: config['global-name']};
    } else if (config.name) {
      return {name: config.name, testId};
    }
  });
}
