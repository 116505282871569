import {put, call, takeEvery} from 'redux-saga/effects';
import {REQUEST_CHANGE_PAGE_STATE} from 'modules/reports/reportConstants';
import POST from 'utils/saga/post';

export default function* changePageStateSaga() {
  yield takeEvery(REQUEST_CHANGE_PAGE_STATE, changePageState);
}

function* changePageState(action) {
  const {reportId, testId, newState, pageHash} = action;

  yield* setPageState(reportId, pageHash, newState);

  if (testId) {
    // @todo: Need to do something here to fetch new results.
  }
}

// Set page to state 'include' || 'exclude'
function* setPageState(reportId, pageHash, state) {
  return yield call(POST, `reports/${reportId}/pages/${pageHash}/${state}`);
}
