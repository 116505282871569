exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3S8esPO24NCuealCSrajD7{font-size:120%;text-align:center}._2erFbI07SVgHrRY9VUmlX3{background:#000030;background:radial-gradient(circle, #1f107e 0%, #000030 100%);min-height:100vh}._2erFbI07SVgHrRY9VUmlX3 hr{max-width:100px;margin:0 auto 50px;border:0;border-top:2px solid rgba(255,255,255,.2)}._3kDjZt4kmtunvmWLoQ3D3I{padding:50px 0;color:#fff;text-align:center}._3kDjZt4kmtunvmWLoQ3D3I h1{color:  #fff;font-size:40px}._3kDjZt4kmtunvmWLoQ3D3I p{font-size:130%;color:#b0c0f0}._3kDjZt4kmtunvmWLoQ3D3I>*:last-child{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"notice": "_3S8esPO24NCuealCSrajD7",
	"background": "_2erFbI07SVgHrRY9VUmlX3",
	"banner": "_3kDjZt4kmtunvmWLoQ3D3I"
};