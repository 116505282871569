import React from 'react';
import UrlComponent from 'components/url/url';
import styles from './styles.scss';
import Icon from 'components/font-awesome';
import pageNameStyles from './pageName.scss';
import {connect} from 'react-redux';
import {doInstruction} from 'modules/instructions';
import {withRouter} from 'react-router';

class DocumentName extends React.Component {
  renderIcon(mimeType) {
    let iconName = '';

    switch (mimeType) {
      case 'application/pdf':
        iconName = 'fal fa-file-pdf';
        break;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        iconName = 'fal fa-file-word';
        break;
    }

    if (!iconName) {
      return null;
    }

    return <Icon name={iconName} size={'lg'} />;
  }

  handleClick = event => {
    const {
      options,
      record,
      filters,
      match: {params}
    } = this.props;

    if (options.instruction) {
      this.props.doInstruction(options.instruction, {...params, ...record}, filters, event);
    }
  };

  render() {
    const {record, data} = this.props;

    const url = (data && data.originalUrl) || record.originalUrl;
    let documentName = (data && data.documentName) || record.documentName;
    let mimeType = record.mimeType || null;

    let decodedURL;

    try {
      // page name can be a url
      decodedURL = decodeURIComponent(documentName);
    } catch (err) {
      decodedURL = documentName;
    }

    return (
      <div className={styles.pageName}>
        <p className={styles.link} style={{cursor: 'pointer'}} onClick={this.handleClick}>
          <b>{(decodedURL || 'Unnamed document').substr(0, 200)}</b>
        </p>
        <p>
          {this.renderIcon(mimeType)}{' '}
          <UrlComponent url={url} className={pageNameStyles.url} stopPropagation link />
        </p>
      </div>
    );
  }
}

DocumentName.propTypes = {};

export default withRouter(
  connect(
    null,
    {doInstruction}
  )(DocumentName)
);
