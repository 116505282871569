import {basePath} from 'modules/auth/authUtils';

export function accountAuditLogPath() {
  return basePath(...arguments) + '/settings/account/audit-log';
}

export function leaderboardsAuditLogPath({leaderboardId, ...args}) {
  return (
    accountAuditLogPath(...arguments) +
    `?actionEntity=leaderboard&actionEntityId=${leaderboardId}&page=1`
  );
}

export function fetchAuditLogEndpoint() {
  return `account-audit-logs`;
}
