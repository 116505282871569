import React from 'react';
import Select from 'components/select';
import styles from './dropdownFilter.scss';
import cx from 'classnames';

class DropdownFilter extends React.Component {
  static defaultProps = {
    clearable: false,
    searchable: false
  };

  render() {
    const {label, options, value, clearable, searchable, onChange, className} = this.props;

    return (
      <div className={cx(styles.dropdownFilter, className)}>
        <label>{label}</label>
        <Select
          options={options}
          value={value}
          clearable={clearable}
          searchable={searchable}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default DropdownFilter;
