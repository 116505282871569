import {select, put, takeLatest} from 'redux-saga/effects';
import {SWITCH_ACCOUNT_IF_ALLOWED, switchAccount} from 'modules/auth';
import {accountIdSelector, userIdSelector} from 'modules/auth/authSelectors';

export default function* switchAccountIfAllowedSaga() {
  yield takeLatest(SWITCH_ACCOUNT_IF_ALLOWED, switchAccountIfAllowed);
}

function* switchAccountIfAllowed({accountId}) {
  const currentAccountId = yield select(accountIdSelector);

  if (accountId != currentAccountId) {
    const userId = yield select(userIdSelector);

    yield put(switchAccount(accountId, userId));
  }
}
