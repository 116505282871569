import {createSelector} from 'reselect';
import {userSelector, accountSelector, isVirtualUserSelector} from 'modules/auth/authSelectors';
import {currentAccountTypeSelector} from 'modules/accountTypes/accountTypeSelectors';
import {toJS} from 'utils/immutableUtils';
import {reportIdSelector} from 'modules/reports/reportSelectors';

export const intercomSelector = createSelector(
  userSelector,
  accountSelector,
  currentAccountTypeSelector,
  reportIdSelector,
  isVirtualUserSelector,
  (user, account, accountType, currentReportId, isVirtualUser) => {
    return {
      user: toJS(user),
      account: toJS(account),
      accountType: toJS(accountType),
      isVirtualUser,
      currentReportId
    };
  }
);
