import styles from './loader.scss';
import React from 'react';
import Spinner from 'components/spinner';

const loadingImage = require('./Insites-Analysing.png');

class Loader extends React.Component {
  render() {
    const {label} = this.props.config;

    return (
      <div className={styles.progress}>
        <p>
          <Spinner centered />
        </p>
        <h3>{label}</h3>
        <img className={styles.image} src={loadingImage} />
      </div>
    );
  }
}

export default Loader;
