import React from 'react';
import cx from 'classnames';
import styles from './breadcrumb.scss';

export default class BreadcrumbBar extends React.Component {
  static defaultProps = {
    theme: 'default'
  };

  render() {
    const {children, theme} = this.props;

    return (
      <nav
        className={cx(styles.bar, {
          [styles.themeDefault]: theme === 'default',
          [styles.themeLink]: theme === 'link'
        })}
      >
        {children}
      </nav>
    );
  }
}
