import React from 'react';
import Icon from 'components/font-awesome/icon';
import styles from './help.scss';

export default class PagesHelp extends React.Component {
  render() {
    return (
      <div className={styles.help}>
        <h3>How many webpages do you want to monitor?</h3>
        <p>
          Choose how many webpages you want to monitor. This is the total number of pages from all your 
          websites added together.
        </p>
        <p>
          Silktide will automatically test all of your webpages every 5 days.
          You can also re-test your websites on demand as much as you like. 
        </p>
        <p>
          You can test multiple websites within this limit. If you need to change this limit at any time, 
          you can update your plan. 
        </p>
        <p>
          Not sure how many pages you need? Press the <b>Talk to us</b> button above and 
          we'll be happy to find out for you.
        </p>      
      </div>
    );
  }
}