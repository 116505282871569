import {LOGIN, LOGOUT} from 'modules/auth';
import {call, takeLatest} from 'redux-saga/effects';

export default function* persistAuth() {
  yield takeLatest([LOGIN, LOGOUT], updateFullstory);
}

function* updateFullstory({type, user, accountId, userId}) {
  if (typeof FS == 'undefined') return;

  switch (type) {
    case LOGIN:
      // FullStory will ignore some special values as invalid, including: 0, 1, -1, "nan", "n/a",
      // "undefined", "null", and "nil" (See https://help.fullstory.com/develop-js/137379)
      FS.identify(`u${user.userId}`, supportFullstorysBullshit(user));
      break;

    case LOGOUT:
      FS.identify(false);
      break;
  }
}

// See https://help.fullstory.com/develop-js/setuservars for details on `FS`.
// They require a specific format for their data, so we need a map function
function supportFullstorysBullshit(user) {
  return {
    displayName: user.name,
    email: user.email,

    role_str: user.role,
    accountId_int: user.accountId,
    created_date: new Date(user.created * 1000),
    lastActive_date: new Date(user.lastActive * 1000),
    virtualType_str: user.virtualType,
    isEmailConfirmed_bool: false,

    version_str: '3.x'
  };
}
