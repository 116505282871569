import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {showAlertsFeature} from 'modules/tests/testSelectors';
import AlertButton from 'modules/alertSubscriptions/containers/alertButton';

function AlertMe(props) {
  if (!props.showAlerts) return null;

  const {
    isLoading,
    result: isSubscribed,
    config: {storytypes}
  } = props;

  return (
    <AlertButton
      isLoading={isLoading}
      isSubscribed={isSubscribed}
      storyTypeIds={storytypes.split(',').map(id => id.trim())}
    />
  );
}

AlertMe.handlesLoading = true;

export default connect(
  createStructuredSelector({
    showAlerts: showAlertsFeature
  })
)(AlertMe);
