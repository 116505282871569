import PropTypes from 'prop-types';
import React from 'react';
import styles from './newReportModal.scss';
import cx from 'classnames';
import _ from 'lodash';
import {connect} from 'react-redux';
import {trackEvent} from 'modules/tracking';
import {addReport} from 'modules/reports';
import {reportModalSelector} from 'modules/reports/reportSelectors';
import {SUPER_USER, SITE_CREATOR} from 'modules/auth/gandalf';
import {ModalForm, Field} from 'modules/form/components';
import {formatGivenNumber} from 'utils/numberUtils';
import {getMinMaxSizes, getAskForFields, getSku, getServiceId} from 'utils/entitlementUtils';
import Status from 'components/status';
import Button from 'components/button';
import {toJS} from 'utils/immutableUtils';
import Alert from 'components/alert/alert';

export class NewReportModal extends React.Component {
  state = {
    summarizedId: this.getDefaultSummarizedId()
  };

  handleValidFormSubmit = ({schedule: {schedulePeriod, scheduleInterval}, ...formData}) => {
    const {folderId, addReport, entitlements} = this.props;

    this.trackReportChanges(formData);

    let {summarizedId, size} = formData;
    const sku = getSku(entitlements, summarizedId);
    const serviceId = getServiceId(entitlements, summarizedId);
    const {minSize, maxSize} = getMinMaxSizes(entitlements, summarizedId);
    size = size || maxSize;

    // Just in case
    if (size < minSize) {
      size = minSize;
    }
    if (size > maxSize) {
      size = maxSize;
    }

    // Add sku, size and schedule data
    formData = {
      ...formData,
      schedulePeriod,
      scheduleInterval,
      sku,
      serviceId,
      size
    };

    addReport({...formData, folderId});
  };

  trackReportChanges({productCode, homeUrl}) {
    const trackingData = {modal: 'newReportModal', productCode, homeUrl};

    trackEvent('Attempted add report', {
      ...trackingData
    });
  }

  renderMaxPages(summarizedId) {
    const {value, entitlements} = this.props;
    const {minSize, maxSize} = getMinMaxSizes(entitlements, summarizedId);
    const formattedMin = formatGivenNumber(minSize);
    const formattedMax = formatGivenNumber(maxSize);
    const placeholder = `e.g. ${formattedMin} - ${formattedMax}`;

    return (
      <Field
        label="Max pages"
        name="size"
        type="number"
        placeholder={placeholder}
        min={minSize}
        max={maxSize}
        shallPass={SITE_CREATOR}
      />
    );
  }

  renderNoProducts() {
    // This should never get rendered as we always show the
    // buy modal if no products.
    // I've left it here on the off chance the modal somehow gets shown
    return (
      <div>
        <Status appearance="warning">You have no products left</Status>
      </div>
    );
  }

  renderPageWarning = () => {
    const {summarizedId} = this.state;

    // If we have chosen an option or not
    if (!summarizedId) {
      return null;
    }

    const entitlements = this.props.entitlements.find(
      item => item.get('summarizedId') === summarizedId
    );

    // Find the entitlement for the chosen item.
    if (!entitlements) {
      return null;
    }

    // If we are infinite, not using services or are a builder. Ignore.
    if (
      entitlements.get('isInfinite') ||
      !entitlements.get('serviceId') ||
      entitlements.get('sku').indexOf('Builder') !== -1
    ) {
      return null;
    }

    // This is how many pages you have left
    const maxSize = entitlements.get('maxSize');

    // If more than 200, don't even warn about anything.
    if (maxSize > 200) {
      return null;
    }

    // If less than 1 (0 or less incase of bug) then danger. Otherwise, between 1 and 100 just warn them.
    const level = maxSize < 1 ? 'danger' : 'warning';
    const message =
      maxSize < 1
        ? 'There are no available pages for this product.'
        : 'There are only ' + maxSize + ' pages left for this product type.';
    return (
      <Alert level={level}>
        <b>Warning:</b> {message}
      </Alert>
    );
  };

  renderFields(summarizedId) {
    const {hasRemainingProducts, value} = this.props;

    if (!hasRemainingProducts) {
      return this.renderNoProducts();
    }

    return (
      <div>
        <Field
          name="summarizedId"
          type="reportProduct"
          onChange={summarizedId => {
            this.setState({summarizedId});

            // validate maxPages field and update form UI. we have to do this because changing
            // the summarizedId will also change the validation rules on the maxPages field
            this.modal.form.fields.size.validate(true);
          }}
        />

        {this.renderPageWarning()}

        <Field
          label="Web address"
          name="homeUrl"
          type="url"
          placeholder="e.g. http://example.com/"
          help="Enter the web address for this website, e.g. example.com"
          autoFocus
          required
          onChange={this.handleChangeUrl}
        />
        <Field
          label="Languages used"
          name="languages"
          type="languages"
          help="Please specify all of the languages this website uses. This will be used to spell and grammar check this website correctly."
          required
        />
        {this.renderMaxPages(summarizedId)}
        <Field
          label="Automatically re-test"
          type="schedule"
          name="schedule"
          help="You can optionally re-test this website on a regular basis."
          simple
        />
      </div>
    );
  }

  getDefaultSummarizedId() {
    const {entitlements} = this.props;
    return entitlements.getIn([0, 'summarizedId']);
  }

  render() {
    const {summarizedId} = this.state;
    const {homeUrl, languages, entitlements, defaultSchedule, hasRemainingProducts} = this.props;
    const {maxSize} = getMinMaxSizes(entitlements, summarizedId);
    const askFor = getAskForFields(entitlements, summarizedId);

    const initialData = {
      size: maxSize > 1000 ? 1000 : maxSize,
      summarizedId,
      languages,
      homeUrl: homeUrl || (this.modal ? this.modal.form.fields.homeUrl.getValue() : ''),
      schedule: toJS(defaultSchedule)
    };

    return (
      <ModalForm
        id="newReportForm"
        title="Add website"
        defaultValue={initialData}
        ref={ref => {
          this.modal = ref;
        }}
        submitLabel={
          <Button id="newReportFormButton">
            {askFor && askFor.length ? 'Next' : 'Start testing'}
          </Button>
        }
        onValidSubmit={this.handleValidFormSubmit}
        hideSubmit={!hasRemainingProducts}
      >
        {this.renderFields(summarizedId)}
      </ModalForm>
    );
  }
}

export default connect(
  reportModalSelector,
  {addReport}
)(NewReportModal);
