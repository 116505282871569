import {takeEvery, takeLatest, take, select, put, call} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {statsSelector} from 'modules/stats/statSelectors';
import {keyBy, toJS} from 'utils/immutableUtils';
import {showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

import {REQUEST_CREATE_LEADERBOARD} from '../constants';
import {receiveLeaderboard} from '../actions';
import {leaderboardsEndpoint} from '../utils';

export default function* createLeaderboardsSaga() {
  yield takeLatest(REQUEST_CREATE_LEADERBOARD, createLeaderboard);
}

function* createLeaderboard({type, onSuccess, ...requestData}) {
  const accountId = yield select(accountIdSelector);
  const statColumns = keyBy(toJS(yield select(statsSelector)), 'name');

  requestData.columns = requestData.columns
    .filter(statId => statColumns.has(statId))
    .map(statId => ({
      name: statId.indexOf('.') < 0 ? 'report.' + statId : statId,
      label: statColumns.getIn([statId, 'label'])
    }));

  yield processRequest(
    'POST',
    leaderboardsEndpoint({accountId}),
    {
      *success(data) {
        // the endpoint returns all the leaderboards, not just the one we created
        // so we just set them

        yield put(receiveLeaderboard(data.leaderboard));
        yield showSuccess('leaderboard created');
        yield call(onSuccess, true, data.leaderboard);
      },
      *error(err) {
        yield call(onSuccess, false, err);
      },
      errorMessage: couldNot('create leaderboard')
    },
    requestData
  );
}
