import Button from 'components/button';
import styles from 'modules/auth/components/authWrapperShared.scss';
import {getProductDescription} from 'modules/products/productUtils';
import React from 'react';

export default class ProductSelectionItem extends React.Component {
  render() {
    const {name, onSelectTool, buttonText} = this.props;
    const productDescription = getProductDescription(name);
    return (
      <div className={styles.productsSelectionWrapper} onClick={onSelectTool}>
        <div className={styles.productSelectionFlexHolder}>
          <img src="/icons/silktide-s.png" width="40" alt="" />

          <div className={styles.productSelectionName}>{name}</div>

          <Button onClick={this.onSelectTool}>{buttonText || 'Use'}</Button>
        </div>

        <div className={styles.productSelectionDescriptionHolder}>
          <p>{productDescription}</p>
        </div>
      </div>
    );
  }
}
