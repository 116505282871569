import React from 'react';
import {ModalContent} from 'modules/modal';
import {useSelector} from 'hooks/redux';
import {List} from 'immutable';
import {courseSelector} from 'modules/courses/selectors';
import EnrollStudents from './enrollStudents';

export default function EnrollStudentsModal({courseId}) {
  const course = useSelector(courseSelector, {courseId});
  const students = course.get('students', List());

  if (!course) return null;

  return (
    <ModalContent title="Select enrolled students">
      <p>
        Select which students you would like to be enrolled on the "{course.get('name')}" course
      </p>
      <EnrollStudents courseId={courseId} students={students} />
    </ModalContent>
  );
}
