import React from 'react';
import {useSelector} from 'hooks/redux';
import {instructionTypeSelector, instructionTypesSelector} from '../selectors';
import {makeNewInstruction} from 'modules/interactions/utils';
import SchemaFields from 'modules/form/components/schemaField';
import styles from './interactionForm.scss';
import {DeleteIcon} from 'components/icons';

export default function InstructionField(props) {
  const {field, isLocked, onDelete} = props;
  const instructionTypeState = useSelector(instructionTypeSelector, props);

  // This is a little hacky, we have the concept of locking the InstructionField to make things easier on new forms
  // However the InstructionField is rendered using SchemaFields, meaning we need to modify the schema for a very specific
  // instructionTypeId, in this case the "Open" instruction
  const instructionType =
    isLocked && instructionTypeState.get('instructionTypeId') === 'Open'
      ? instructionTypeState.setIn(['supportedOptions', 0, 'disabled'], true)
      : instructionTypeState;

  return (
    <div className={styles.instructionField}>
      <h4>
        <b>{instructionType.get('name')}</b>: <i>{instructionType.get('description')}</i>
        {!isLocked && <DeleteIcon size="lg" onClick={() => onDelete(field)} />}
      </h4>
      <SchemaFields
        autoFocus
        field={field.fields.options}
        supportedOptions={instructionType.get('supportedOptions')}
        onAddRepeatingField={(fieldSchema, field) => {
          const defaultData = makeNewInstruction(fieldSchema, {});
          field.push(defaultData);
          field.rerender();
        }}
      />
    </div>
  );
}
