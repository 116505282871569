import React from 'react';
import Icon from 'components/font-awesome';
import styles from './lessonIcon.scss';
import cx from 'classnames';
import {lightFormat} from 'date-fns';

export default function LessonIcon({lesson}) {
  const state = lesson.get('state');
  const lessonTypeId = lesson.get('lessonTypeId');

  const complete = state == 'completed';

  const className = cx(styles.icon, {
    [styles.completed]: complete,
    [styles.light]: !!lightFormat,
    [styles.white]: !complete
  });

  if (state == 'completed') {
    return <Icon name="fas fa-check-circle" aria-hidden="true" className={className} />;
  }

  if (lessonTypeId == 'video') {
    return <Icon name="fas fa-play-circle" aria-hidden="true" className={className} />;
  }
  if (lessonTypeId == 'text') {
    return <Icon name="fas fa-book" aria-hidden="true" className={className} />;
  }
  if (lessonTypeId == 'quiz') {
    return <Icon name="fas fa-question-circle" aria-hidden="true" className={className} />;
  }

  // Fallback, should never use
  return <Icon name="fas fa-question-circle" aria-hidden="true" className={className} />;
}
