import React from 'react';
import {Collapse} from 'react-collapse';
import Center from 'components/center';
import Button from 'components/button';
import styles from './taxExplainer.scss';

const vatHelp =
  "'Value Added Tax' is a tax that only applies if you're based in the EU. If VAT applies to you we'll update your pricing once you start to buy.";

export default class TaxExplainer extends React.Component {
  state = {
    showVatInfo: false
  };

  handleClickVatInfo = event => {
    this.setState(state => ({
      showVatInfo: !state.showVatInfo
    }));
  };

  render() {
    return (
      <div>
        <Center>
          <p className={styles.label}>
            Prices exclude any applicable VAT.{' '}
            <Button link onClick={this.handleClickVatInfo}>
              What is this?
            </Button>
          </p>
        </Center>

        <Center>
          <Collapse isOpened={this.state.showVatInfo}>
            <div className={styles.description}>{vatHelp}</div>
          </Collapse>
        </Center>
      </div>
    );
  }
}
