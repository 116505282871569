exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3d2hpnPyBdFYq-KEhsYb6h{display:block;background:#fff;padding:6px 15px;color:#777;cursor:pointer;display:flex;min-height:70px;align-items:center}._3d2hpnPyBdFYq-KEhsYb6h:hover{background:#f3f2fd}._3d2hpnPyBdFYq-KEhsYb6h._2I780tHnYpgN3gHoIex6cC{background:#fff}._3d2hpnPyBdFYq-KEhsYb6h._2I780tHnYpgN3gHoIex6cC:hover{background:#f7f7f7}._3d2hpnPyBdFYq-KEhsYb6h+._3d2hpnPyBdFYq-KEhsYb6h{border-top:1px solid #ebebeb}._1zKU5RclGXOO8TCBx6y5pM{padding:5px 10px;flex:1;margin:0;align-self:center}", ""]);

// exports
exports.locals = {
	"notificationListItem": "_3d2hpnPyBdFYq-KEhsYb6h",
	"read": "_2I780tHnYpgN3gHoIex6cC",
	"text": "_1zKU5RclGXOO8TCBx6y5pM"
};