import {put, takeEvery} from 'redux-saga/effects';
import {CANCEL_MISSION, deleteMission} from 'modules/missions';
import {missionEndpoint} from 'modules/missions/missionUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* cancelMissionSaga() {
  yield takeEvery(CANCEL_MISSION, cancelMission);
}

function* cancelMission({missionId}) {
  const url = missionEndpoint({missionId});

  yield processRequest('DELETE', url, {
    success: function*() {
      yield put(deleteMission(missionId));
    },
    errorMessage: couldNot('cancel action')
  });
}
