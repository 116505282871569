import React from 'react';
import cx from 'classnames';
import InspectorMetricType from './inspectorMetricType';
import styles from './inspectorStyle.scss';

function InspectorMetric({dark, label, metric}) {
  return (
    <div className={cx(styles.inspectorMetric, {[styles.dark]: dark})}>
      <span className={styles.label}>{label}</span>
      <div className={styles.metric}>
        <InspectorMetricType type={metric.get('type')} value={metric.get('value')} />
      </div>
    </div>
  );
}

export default InspectorMetric;
