exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2G6av1g_4n7xFvUK7v1Kl{display:flex;align-items:center}._2G6av1g_4n7xFvUK7v1Kl p{margin:0}._2Bb_sA43jl3oXSkWtrLSu_{padding:10px 0px}._2Bb_sA43jl3oXSkWtrLSu_ p:last-of-type{margin:0}", ""]);

// exports
exports.locals = {
	"user": "_2G6av1g_4n7xFvUK7v1Kl",
	"markdown": "_2Bb_sA43jl3oXSkWtrLSu_"
};