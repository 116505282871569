exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1R93lHOrhmPwyDMDbNA510{position:relative;min-height:185px;min-width:828px;overflow:hidden}._1k3fK9wiuR40MK56OeQ-Wn{display:block}._1k3fK9wiuR40MK56OeQ-Wn>img{position:absolute;height:auto;width:100%}._1k3fK9wiuR40MK56OeQ-Wn>div{position:absolute;left:0;top:0;width:100%;height:100%;background:linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 30%)}._2yp5dkTv1bkmaxpRq3ozUl{position:absolute;bottom:6%;left:2%;border-radius:2px;border:4px solid #fff;overflow:hidden;width:160px;height:160px}._2yp5dkTv1bkmaxpRq3ozUl>img{position:absolute;left:50%;top:50%;-webkit-transform:translateY(-50%) translateX(-50%);transform:translateY(-50%) translateX(-50%)}.jIgVodvfX8Rw-Ytgqs1TY{position:absolute;bottom:5%;color:#fff;font-weight:bold;font-family:helvetica,arial,sans-serif;font-size:25px;text-shadow:0 1px 4px rgba(0,0,0,.8);word-break:break-word}", ""]);

// exports
exports.locals = {
	"banner": "_1R93lHOrhmPwyDMDbNA510",
	"bannerImgLink": "_1k3fK9wiuR40MK56OeQ-Wn",
	"profileImgCont": "_2yp5dkTv1bkmaxpRq3ozUl",
	"labelCont": "jIgVodvfX8Rw-Ytgqs1TY"
};