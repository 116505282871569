import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {accountSelector} from 'modules/auth/authSelectors';

const selector = createStructuredSelector({account: accountSelector});

const CanBuyGandalf = ({children, account}) => {
  if (account.get('hasPaymentDetails') || account.get('sitebeamPlanId')) {
    return children;
  }
  return null;
};

export default connect(selector)(CanBuyGandalf);
