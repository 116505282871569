import React from 'react';
import {connect} from 'react-redux';
import Action from '../action';
import styles from '../inspectorSidebar.scss';
import {navigateToAction} from 'modules/inspector/inspectorActions';
import Pill from 'components/pill';

function InspectorActionRow({action, onClick}) {
  return (
    <div className={styles.action} onClick={onClick}>
      {/* CLEAN ME */}
      <Action
        {...action.toJS()}
        hideDescription
        hideState
        hideProgress
        notLink
        testIds={[]}
        showPriority
        smaller
      />
      <Pill appearance="minor" circle>
        {action.get('pointsCount')}
      </Pill>
    </div>
  );
}

export default connect(
  null,
  (dispatch, {action}) => ({
    onClick: event => {
      dispatch(navigateToAction(action.get('actionId')));
    }
  })
)(InspectorActionRow);
