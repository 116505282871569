exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h4{margin:10px 0 15px 0;font-size:18px}.G6Ahvm4A5jBXjV18fS-TW{text-align:left;margin:10px 0 20px;border-top:1px solid #ddd;width:100%}.G6Ahvm4A5jBXjV18fS-TW tr td{padding:5px 0;border-bottom:1px solid #ddd;overflow-wrap:break-word;word-break:break-word}.G6Ahvm4A5jBXjV18fS-TW tr td p{margin:0 0 5px 0}.G6Ahvm4A5jBXjV18fS-TW tr td:first-of-type{vertical-align:top;color:#666;width:40px}.G6Ahvm4A5jBXjV18fS-TW tr strong{color:#533be2}a._1FvNV0WQGVMjYFoIzS48KF,a._1FvNV0WQGVMjYFoIzS48KF:visited{color:#006621}", ""]);

// exports
exports.locals = {
	"table": "G6Ahvm4A5jBXjV18fS-TW",
	"host": "_1FvNV0WQGVMjYFoIzS48KF"
};