exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2hJdu0I_LXIwAuMUWLu9GM{position:relative;top:1px;left:1px}._2n2RUWDmohEdUixzbtOJP0{margin:0}", ""]);

// exports
exports.locals = {
	"tagIcon": "_2hJdu0I_LXIwAuMUWLu9GM",
	"tags": "_2n2RUWDmohEdUixzbtOJP0"
};