import {List} from 'immutable';
import {createSelector} from 'reselect';
import {resultsSelector} from 'modules/results/resultSelectors';
import {
  reportSelector,
  scoreTypeSelector,
  prioritisedReportSelector
} from 'modules/reports/reportSelectors';
import {testSelector, testsSelector} from 'modules/tests/testSelectors';
import {getScore} from 'modules/results/resultUtils';
import {getIssueCounts} from 'modules/inspector/issueUtils';
import {locationParamsSelector} from 'modules/location/locationSelectors';

// Enhances tests with score, isOpen and isActive.
export const enhancedNavItemsSelector = createSelector(
  testsSelector,
  testSelector,
  resultsSelector,
  (tests, currentTest, results) => {
    return tests.map(test => {
      const testId = test.get('testId');

      const issueCounts = getIssueCounts(results, testId);

      return test
        .set('score', getScore(results, testId))
        .set('issueCounts', issueCounts)
        .set('isActive', isActive(testId, currentTest))
        .set('isOpen', isOpen(testId, currentTest));
    });
  }
);

export const sidebarTestsSelector = createSelector(
  scoreTypeSelector,
  enhancedNavItemsSelector,
  (state, props) => props.parentId, // null = top level
  (scoreType, tests, parentId) => {
    const navItems = tests
      .filter(test => {
        return !test.get('isTest') && test.get('parentId') == parentId && !test.get('isHidden');
      })
      .toList();

    return {scoreType, navItems};
  }
);

// TODO better selector name
export const whichSidebarSelector = createSelector(
  scoreTypeSelector,
  enhancedNavItemsSelector,
  locationParamsSelector,
  (_, props) => props.testId,
  prioritisedReportSelector,
  (scoreType, tests, {testId}, propTestId, report) => {
    testId = testId || propTestId;

    if (!testId) {
      return {scoreType, navItems: List(), parentId: null, parentTest: null, report};
    }

    const test = tests.find(test => {
      return test.get('testId') === testId;
    });

    if (!test) {
      return {scoreType, navItems: List(), parentId: null, parentTest: null, report};
    }

    const parentId = test.get('parentId');
    const parentTest = tests.find(test => {
      return test.get('testId') === parentId;
    });

    const navItems = tests
      .filter(test => {
        return !test.get('isTest') && test.get('parentId') == parentId;
      })
      .toList();

    return {scoreType, navItems, parentId, parentTest, report};
  }
);

export const sidebarUpgradeItemSelector = createSelector(
  reportSelector,
  report => ({
    report: report.toJS()
  })
);

function isActive(testId, currentTest) {
  return currentTest && testId == currentTest.get('testId');
}

function isOpen(testId, currentTest) {
  return currentTest && (isActive(testId, currentTest) || testId == currentTest.get('parentId'));
}
