import {toJS} from 'utils/immutableUtils';

// Return the min/max report sizes for the products the user has entitlements for
export function getMinMaxSizes(entitlements, summarizedId) {
  // default sizes if no products exist
  let minSize = 1;
  let maxSize = 10000;

  // get the min and max sizes of from the selects product
  if (summarizedId) {
    const selectedProduct = getSelectedProduct(entitlements, summarizedId);

    if (selectedProduct) {
      minSize = parseInt(selectedProduct.get('minSize'), 10);
      maxSize = parseInt(selectedProduct.get('maxSize'), 10);
    }
  }

  return {minSize, maxSize};
}

/*
 * Return a list of fields that the backend has asked for. The ask for askFor
 * fields are used to prompt the user for extra information e.g. auth details
 */
export function getAskForFields(entitlements, summarizedId) {
  if (summarizedId) {
    const selectedProduct = getSelectedProduct(entitlements, summarizedId);

    if (selectedProduct) {
      return toJS(selectedProduct.getIn(['product', 'askFor'], []));
    }
  }

  return [];
}

/*
 * Returns the SKU for the product corresponding to the summarizedId.
 *
 * Knowledge of what this means requires an understanding of the entitlements
 * system and here is not the place to explain that!
 */
export function getSku(entitlements, summarizedId) {
  if (summarizedId) {
    const selectedProduct = getSelectedProduct(entitlements, summarizedId);

    if (selectedProduct) {
      return selectedProduct.get('sku');
    }
  }

  return '';
}

/*
 * Returns the serviceId for the product corresponding to the summarizedId.
 *
 * This is a bastard hack to allow us to get the new entitlement system
 * working without rewriting Chell. Sorry.
 */
export function getServiceId(entitlements, summarizedId) {
  if (summarizedId) {
    const selectedProduct = getSelectedProduct(entitlements, summarizedId);

    if (selectedProduct) {
      return selectedProduct.get('serviceId');
    }
  }

  return '';
}

const getSelectedProduct = (entitlements, summarizedId) => {
  return entitlements.find(c => c.get('summarizedId') == summarizedId);
};
