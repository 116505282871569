exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QwssXUvNiLPdqbdaTFfNO{width:73px}._30GSu5GxrJQgr46RMjRB0-{background:#fff;font-size:18px;color:#000}._30GSu5GxrJQgr46RMjRB0- td{padding:15px}._30GSu5GxrJQgr46RMjRB0- td div{display:flex;align-items:center}._30GSu5GxrJQgr46RMjRB0- td div>*{margin-right:10px}._30GSu5GxrJQgr46RMjRB0-:hover{background:#fff !important}._5mfmXZMD6Au9syAMsLvDB{color:  #000;font-weight:bold}@media print{._34HuzqwuJmJA3uBuMM4qDK{display:none}}", ""]);

// exports
exports.locals = {
	"inspectorButton": "QwssXUvNiLPdqbdaTFfNO",
	"rowGroup": "_30GSu5GxrJQgr46RMjRB0-",
	"classification": "_5mfmXZMD6Au9syAMsLvDB",
	"issueActionColumn": "_34HuzqwuJmJA3uBuMM4qDK"
};