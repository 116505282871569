import React from 'react';
import {connect} from 'react-redux';
import {requestAddReport} from '../reports';
import Button from 'components/button';
import Gandalf from 'components/gandalf';
import {SITE_CREATOR} from 'modules/auth/gandalf';
import {addReportButtonSelector} from 'modules/folders/folderSelectors';

export class AddReportButton extends React.Component {
  static defaultProps = {
    folderId: 1,
    allowed: true
  };

  handleClick = () => {
    const {requestAddReport, folderId} = this.props;
    requestAddReport(folderId);
  };

  render() {
    const {children, allowed, ...props} = this.props;

    if (!allowed) return null;

    return (
      <Gandalf shallPass={SITE_CREATOR}>
        <Button {...props} onClick={this.handleClick} icon="plus" id="addReportButton">
          {children || 'Add website'}
        </Button>
      </Gandalf>
    );
  }
}

export default connect(
  addReportButtonSelector,
  {requestAddReport}
)(AddReportButton);
