import {processRequest} from 'utils/saga/fetchUtils';
import {accountOptionEndpoint} from 'modules/accountOptions/accountOptionUtils';

export default function* deleteAccountOption({accountId, optionId}) {
  const url = accountOptionEndpoint({accountId, optionId});

  return yield processRequest('DELETE', url, {
    error: function*(err) {
      throw err;
    }
  });
}
