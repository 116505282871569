import Button from 'components/button';
import Input from 'components/input';
import Flex from 'components/layout/flex';
import Panel from 'components/panel';
import Spinner from 'components/spinner';
import Switch from 'components/switch';
import SwitchOption from 'components/switch/switchOption';
import emailValidator from 'email-validator';
import {List} from 'immutable';
import {saveDashboard} from 'modules/dashboards/actions';
import EditDashboard from 'modules/dashboards/containers/editDashboard';
import {Field, Form} from 'modules/form/components';
import ErrorMessage from 'modules/form/components/errorMessage';
import SavePanel from 'modules/form/components/savePanel';
import {requestCreateLeaderboard, requestUpdateLeaderboard} from 'modules/leaderboards/actions';
import {editLeaderboardFormSelector} from 'modules/leaderboards/selectors';
import {leaderboardPath, leaderboardsPath} from 'modules/leaderboards/utils';
import {push} from 'modules/location';
import {fetchReports} from 'modules/reports';
import {fetchStats} from 'modules/stats';
import React from 'react';
import {connect} from 'react-redux';
import {toJS} from 'utils/immutableUtils';
import {havePropsChanged} from 'utils/propUtils';
import styles from './editLeaderboardForm.scss';

const intervalOptions = [
  {value: '', label: 'Never'},
  {value: 'week', label: 'Weekly'},
  {value: 'month', label: 'Monthly'}
];

const sortOptions = [
  {value: 'asc', label: 'Ascending order'},
  {value: 'desc', label: 'Descending order'}
];

export class EditLeaderboardForm extends React.Component {
  state = {
    tab: 'general', // the current active tab
    scheduleInterval: '', // the selected option on the emails tab
    currentColumns: [], // used to add the current selected columns into the selectbox options of the default column
    selectedReports: [], // used to help filter the reports that we don't want to see in the report selector
    loading: false, // true if the report is creating/updating
    emailError: false, // true if the email addresses on the schedule tab are invalid
    filterReportsText: '', // contains state from the search text input on the reports tab
    reportsDisabled: !this.props.leaderboardId, // used to disable the reports tab
    scheduleDisabled: !this.props.leaderboardId // used to disable the schedule tab
  };

  componentWillReceiveProps(nextProps) {
    if (
      havePropsChanged(this.props, nextProps, ['stats']) &&
      !this.initialData.stats.length &&
      nextProps.stats.size
    ) {
      this.initialData = this.getInitialData(nextProps);

      // if we were on edit page but moved to new page, then we need to reset state
      const stats = nextProps.stats.toJS();
      this.setState({
        currentColumns: stats
          ? stats.filter(stat => this.initialData.stats.includes(stat.value))
          : []
      });
    }

    if (this.props.leaderboard !== nextProps.leaderboard) {
      this.initialData = this.getInitialData(nextProps);

      // if we were on edit page but moved to new page, then we need to reset state
      this.resetState(nextProps);
    }

    if (this.props.leaderboardReports !== nextProps.leaderboardReports) {
      this.initialData = this.getInitialData(nextProps);

      // if we were on edit page but moved to new page, then we need to reset state
      this.resetState(nextProps);
    }
  }

  componentDidMount() {
    this.props.fetchReports(true, ['name', 'reportId']);
    this.props.fetchStats();

    this.initialData = this.getInitialData(this.props);

    // if we were on edit page but moved to new page, then we need to reset state
    this.resetState(this.props);
  }

  resetState({stats, leaderboard, leaderboardReports}) {
    this.setState({
      scheduleInterval: leaderboard ? leaderboard.get('scheduleInterval') || '' : '',
      selectedReports: leaderboardReports.map(report => report.get('reportId').toString()).toJS(),
      loading: false,
      emailError: false,
      filterReportsText: '',
      currentColumns: stats ? stats.filter(stat => this.initialData.stats.includes(stat.value)) : []
    });
  }

  handleValidSubmit = data => {
    const emailArr = data.scheduleEmailAddresses.split('\n').map(str => str.trim());
    const emailError =
      !emailArr.length || !emailArr[0]
        ? false
        : emailArr.some(email => !emailValidator.validate(email));

    this.setState({emailError}, () => {
      if (emailError) return;

      const {
        leaderboardId,
        requestCreateLeaderboard,
        requestUpdateLeaderboard,
        saveDashboard
      } = this.props;
      const {name, reports, stats, ...theRest} = data;
      const {columns: notUsed, ...rest} = theRest; // remove `columns` from the rest because it overrides `stats` below

      // always force report.name as the first column
      const columns = stats.filter(stats => stats !== 'report.name');

      columns.unshift('report.name');

      if (rest.scheduleFromDate && rest.scheduleFromDate.getTime) {
        rest.scheduleFromDate = Math.floor(rest.scheduleFromDate.getTime() / 1000);
      }
      rest.scheduleEmailAddresses = emailArr;
      rest.schedulePeriod = 1; // hardcode to 1 for now

      const onSuccess = (success, data) => {
        this.setState({loading: false});
        this.props.push(leaderboardPath, {leaderboardId: data.leaderboardId}, ['accountId']);
      };

      this.setState({loading: true});

      if (leaderboardId) {
        requestUpdateLeaderboard(leaderboardId, name, columns, reports, rest, onSuccess);
        // the dashboard widgets are stored separately, so we only care about saving the dashboards `rows`
        // When we add a row/col, we mutate the dashboard in redux, but it is not saved.
        // So the dashboard that we get connected may have different `rows` than the one on the backend.

        if (this.props.dashboard) saveDashboard(this.props.dashboard);
      } else {
        requestCreateLeaderboard(name, columns, reports, rest, onSuccess);
      }
    });
  };

  handleClickNext = () => {
    const [isValid] = this.form.isFormValid(true);
    if (!isValid) return;

    if (!this.props.leaderboardId) {
      if (this.state.scheduleDisabled) {
        if (this.state.tab === 'general') {
          this.setState({tab: 'reports', reportsDisabled: false});
        } else if (this.state.tab === 'reports') {
          this.setState({tab: 'notified', scheduleDisabled: false});
        }
      } else {
        this.form.submit();
      }
    } else {
      this.form.submit();
    }
  };

  getScheduleEmailAddresses(leaderboard) {
    const emails = toJS(leaderboard.get('scheduleEmailAddresses'));
    if (!emails) return '';
    if (Array.isArray(emails)) {
      return emails.join('\n'); // cos we are displaying in a textarea
    }
    try {
      const parsed = JSON.parse(emails);
      if (Array.isArray(parsed)) {
        return parsed.join('\n'); // cos we are displaying in a textarea
      } else if (typeof parsed === 'string') {
        return parsed; // already a string
      }
    } catch (err) {
      console.log('JSON ERR', emails, err);
      return '';
    }
  }

  getInitialData(props) {
    return props.leaderboard
      ? {
          name: props.leaderboard.get('name'),
          description: props.leaderboard.get('description'),
          scheduleFromDate:
            props.leaderboard.get('scheduleFromDate') || Math.floor(Date.now() / 1000),
          scheduleInterval: props.leaderboard.get('scheduleInterval') || '',
          schedulePeriod: props.leaderboard.get('schedulePeriod') || 1,
          allowEveryone: props.leaderboard.get('allowEveryone') || 0,
          defaultSortOrder:
            this.state.defaultSortOrder || props.leaderboard.get('defaultSortOrder') || 'asc',
          defaultSortBy:
            this.state.defaultSortBy || props.leaderboard.get('defaultSortBy') || 'report.name',
          scheduleEmailAddresses: this.getScheduleEmailAddresses(props.leaderboard),
          reports: props.leaderboardReports.map(report => report.get('reportId').toString()).toJS(),
          stats: [
            ...new Set([
              ...props.leaderboard
                .get('columns', List())
                .filter(column => column.get('name') !== 'report.name')
                .map(column => column.get('name'))
                .toJS()
            ])
          ]
        }
      : {
          name: '',
          description: '',
          scheduleInterval: '',
          scheduleFromDate: Math.floor(Date.now() / 1000),
          allowEveryone: 1,
          schedulePeriod: 1,
          defaultSortOrder: 'asc',
          defaultSortBy: 'report.name',
          scheduleEmailAddresses: '',
          reports: [],
          stats: [
            ...new Set([
              ...props.defaultColumns
                .map(col => col.get('name'))
                .toList()
                .toJS()
            ])
          ]
        };
  }

  renderTab(tabId, label, disabled, tooltip) {
    return (
      <SwitchOption
        disabled={disabled}
        tooltip={tooltip}
        active={this.state.tab === tabId}
        onClick={() => this.setState({tab: tabId})}
      >
        {label}
      </SwitchOption>
    );
  }

  renderTabHeadings() {
    const reportsTooltip = this.state.reportsDisabled ? 'Click "next" below to advance' : undefined;
    const scheduleTooltip = this.state.scheduleDisabled
      ? 'Click "next" below to advance'
      : undefined;

    return (
      <Switch tabbed large>
        {this.renderTab('general', 'General')}
        {this.props.leaderboardId != '1' &&
          this.renderTab('reports', 'Websites', this.state.reportsDisabled, reportsTooltip)}
        {this.props.canEditLeaderboardWidgets &&
          !!this.props.leaderboardId &&
          !!this.props.leaderboard.get('dashboardId') &&
          this.renderTab('dashboard', 'Widgets')}
        {this.renderTab('notified', 'Notifications', this.state.scheduleDisabled, scheduleTooltip)}
      </Switch>
    );
  }

  renderBodyGeneral() {
    // We can guarantee that leaderboardId of `1` is the 'All websites' leaderboard
    const isMainLeaderboard = this.props.leaderboardId == 1;

    return (
      <Panel>
        {!isMainLeaderboard && (
          <React.Fragment>
            <Field
              label="What is the name of this leaderboard?"
              muted="This will be used to identify this leaderboard"
              name="name"
              type="text"
              required
              autoFocus
            />
            <Field
              label="What is the purpose of this leaderboard? (optional)"
              muted="This will be shown as a description of what this leaderboard is for"
              name="description"
              type="textarea"
              rows={3}
            />
          </React.Fragment>
        )}

        <Field
          label="What columns should we show for this leaderboard?"
          name="stats"
          type="listbox"
          options={this.props.stats.toJS()}
          required
          maxLength={50}
          onChange={values => {
            const stats = this.props.stats.toJS();
            this.setState({
              currentColumns: stats.filter(stat => values.includes(stat.value))
            });
          }}
          customMessages={{
            required: 'Please select at least 1 column',
            maxLength: max => `Please choose up to ${max} columns`
          }}
        />

        <Flex container>
          <Flex grow={1} right={10}>
            <Field
              label="Which column should be sorted by default?"
              name="defaultSortBy"
              type="select"
              options={[
                {value: 'report.name', label: 'Website name'},
                ...this.state.currentColumns
              ]}
            />
          </Flex>
          <Flex grow={1}>
            <Field
              label="Which order should we sort the default column?"
              name="defaultSortOrder"
              type="select"
              options={sortOptions}
            />
          </Flex>
        </Flex>

        <br />
        <Field
          description="Allow everyone to see all websites?"
          name="allowEveryone"
          type="checkbox"
        />
      </Panel>
    );
  }

  renderBodyReports() {
    // const leaderboardReports = this.props.leaderboardReports
    //   .map(report => report.get('reportId').toString())
    //   .toJS();
    const filterText = this.state.filterReportsText.toLowerCase();
    const filteredReports = [
      ...new Set([
        ...this.props.reports
          .filter(report => {
            return !filterText || report.label.toLowerCase().indexOf(filterText) >= 0;
          })
          .map(report => report.value)
          .toJS(),
        ...this.state.selectedReports
      ])
    ];
    const myReports = filteredReports.map(reportId => {
      return this.props.reports.find(report => report.value === reportId);
    });

    return (
      <Panel>
        {this.props.reports.isEmpty() ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className={styles.searchFilter}>
              <label className={styles.label}>Search reports</label>
              <Input
                type="text"
                value={filterText}
                onChange={event => {
                  this.setState({filterReportsText: event.target.value.trim()});
                }}
              />
              <p className={styles.muted}>Filter the list of reports below using this search box</p>
            </div>

            <Field
              label="What websites do you want to see on this leaderboard?"
              muted="The websites you pick will show up as results for this leaderboard"
              name="reports"
              type="listbox"
              options={myReports}
              onChange={selectedReports => {
                this.setState({selectedReports});
              }}
              required
              disableSort
              customMessages={{
                required: 'You must select at least 1 report'
              }}
            />
          </React.Fragment>
        )}
      </Panel>
    );
  }

  renderBodySchedule() {
    return (
      <Panel>
        <Field
          width="200px"
          label="When should we email updates for this leaderboard?"
          type="select"
          name="scheduleInterval"
          options={intervalOptions}
          onChange={scheduleInterval => {
            this.setState({scheduleInterval});
          }}
        />

        {this.state.scheduleInterval && (
          <React.Fragment>
            <Field
              label="Who would you like to receive email notifications?"
              muted="Enter each email address on a new line. Each email address will be notified"
              rows="3"
              name="scheduleEmailAddresses"
              type="textarea"
              required
              customMessages={{
                required:
                  'You should enter one or more email addresses. Each email address should be on a new line'
              }}
            />

            {this.state.emailError && (
              <ErrorMessage>
                All emails must be separated by new lines and each new line must contain a valid
                email addresses
              </ErrorMessage>
            )}

            {/* <label>How often do you want to receive an email about this leaderboard?</label> */}
            {/* <Field
              label="From what date should we send the email?"
              muted="Enter the date you would like the email notification to start from."
              name="scheduleFromDate"
              type="date"
              dateFormat="MMMM d, yyyy"
              required
              customMessages={{
                required: 'You should enter a date that the periodic email will be sent from'
              }}
            /> */}
          </React.Fragment>
        )}
      </Panel>
    );
  }

  renderBodyDashboard() {
    const {leaderboard} = this.props;
    if (!leaderboard) return null;

    return (
      <Panel>
        <EditDashboard
          contextTypeId="reports"
          dashboardId={leaderboard.get('dashboardId')}
          leaderboardId={leaderboard.get('leaderboardId')}
        />
      </Panel>
    );
  }

  render() {
    // const initialData = this.getInitialData(this.props);
    const isLoading =
      (this.props.leaderboardId && !this.props.leaderboard) || !this.props.stats.size;

    return (
      <Form
        ref={ref => (this.form = ref)}
        defaultValue={this.initialData}
        onValidSubmit={this.handleValidSubmit}
      >
        {this.renderTabHeadings()}

        {isLoading ? (
          <Panel>
            <Spinner marginTop={100} marginBottom={100} />
          </Panel>
        ) : (
          <React.Fragment>
            {this.state.tab === 'general'
              ? this.renderBodyGeneral()
              : this.state.tab === 'reports'
              ? this.renderBodyReports()
              : this.state.tab === 'notified'
              ? this.renderBodySchedule()
              : this.renderBodyDashboard()}

            <SavePanel
              floating
              noSidebar
              isLoading={this.state.loading}
              onCancel={() => {
                if (!this.props.leaderboardId) {
                  this.props.push(leaderboardsPath);
                } else {
                  this.props.push(leaderboardPath, {leaderboardId: this.props.leaderboardId});
                }
              }}
              onSubmit={this.handleClickNext}
              submitLabel={this.state.scheduleDisabled ? 'Next' : 'Save'}
              floatMargin={'65px'}
              isValid
              show
            />
          </React.Fragment>
        )}
      </Form>
      // <Button type="submit">{this.props.leaderboardId ? 'Save' : 'Create'}</Button>
    );
  }
}

export default connect(
  editLeaderboardFormSelector,
  {
    fetchReports,
    fetchStats,
    requestCreateLeaderboard,
    requestUpdateLeaderboard,
    saveDashboard,
    push
  }
)(EditLeaderboardForm);
