export function formatHtml(str) {
  if (!str) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(str.trim(), 'text/html');
  const formattedDom = formatHtmlRecursive(doc.documentElement, 0);

  return formattedDom.outerHTML;
}

export function formatHtmlRecursive(node, level) {
  var indentBefore = new Array(level + 1).join('  '),
    indentAfter = new Array(level).join('  '),
    textNode;

  if (node.nodeType === Node.ELEMENT_NODE) {
    // Sometimes the className has new lines and tabs in it and stuff (maybe from old scool php templates)
    // This just forces to the className to be formatted correctly

    try {
      // Allow this to fail without bringing down the entire app.
      // this can fail on some elements where the className is read-only
      node.className = [...node.classList].join(' ');
      // node.classList.toString() somehow keeps the extras white-space, so we convert to array and join
    } catch (err) {}
  }

  if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE') {
    if (!node.textContent.trim()) {
      node.textContent = '';
    } else {
      node.textContent =
        '\n' + indentBefore + node.textContent.replace(/\s*\n+\s*/, '').trim() + '\n' + indentAfter;
    }

    return node;
  }

  for (var i = 0; i < node.childNodes.length; i++) {
    if (node.childNodes[i].nodeType === Node.TEXT_NODE) {
      // if the text node contains only whitespace, remove it
      if (/^[\s\n]+$/.test(node.childNodes[i].textContent)) {
        node.removeChild(node.childNodes[i]);
      } else {
        node.childNodes[i].textContent = node.childNodes[i].textContent.trim();
      }
    }
  }

  for (var i = 0; i < node.children.length; i++) {
    textNode = document.createTextNode('\n' + indentBefore);
    node.insertBefore(textNode, node.children[i]);

    formatHtmlRecursive(node.children[i], level + 1);

    if (node.lastElementChild == node.children[i]) {
      textNode = document.createTextNode('\n' + indentAfter);
      node.appendChild(textNode);
    }
  }

  return node;
}
