import React from 'react';
import styles from './insideContext.scss';
import Tooltip from 'components/tooltip';

export default class InsideContext extends React.Component {
  render() {
    const {insideContext} = this.props;
    if (!insideContext) return null;

    if (insideContext === 'alt') {
      return (
        <Tooltip text="This text is inside alternative text, which is not normally visible to users. It can be seen by search engines and users with tools to aid accessibility.">
          <p className={styles.annotation}>In alternative text</p>
        </Tooltip>
      );
    }
    if (insideContext === 'title-attribute') {
      return (
        <Tooltip text="This text is inside a title attribute, which is normally invisible unless a user hovers their mouse over it.">
          <p className={styles.annotation}>In title attribute</p>
        </Tooltip>
      );
    }
    if (insideContext === 'screen-reader') {
      return (
        <Tooltip text="This text is only visible to screen reader users.">
          <p className={styles.annotation}>In screen reader text</p>
        </Tooltip>
      );
    }
    if (insideContext === 'structured-data') {
      return (
        <Tooltip text="This text appears as structured data, which is exposed to search engines.">
          <p className={styles.annotation}>In structured markup</p>
        </Tooltip>
      );
    }
    if (insideContext === 'title') {
      return <p className={styles.annotation}>In page title</p>;
    }
    if (insideContext === 'meta') {
      return <p className={styles.annotation}>In meta tag</p>;
    }
    if (insideContext === 'url') {
      return <p className={styles.annotation}>In URL</p>;
    }
    if (insideContext === 'page') {
      return <p className={styles.annotation}>Whole page</p>;
    }
    return null;
  }
}
