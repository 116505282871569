import React from 'react';
import Icon from 'components/font-awesome/icon';
import styles from './help.scss';
import ExternalLink from 'modules/location/externalLink';

export default class MarketingHelp extends React.Component {
  render() {
    return (
      <div className={styles.help}>
        <h3>What marketing covers</h3>
        <p>
          Measure and optimize your website's marketing, including Search Engine Optimization, user
          experience and more:
        </p>

        <ul className={styles.helpList}>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Measure your search
            rankings
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Learn how to improve your
            SEO
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Test for mobile layout
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Optimize website speed
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Identify marketing best
            practices
          </li>
        </ul>

        <p>
          You can{' '}
          <ExternalLink href="https://silktide.com/features/seo">
            learn more on our website
          </ExternalLink>
          .
        </p>
      </div>
    );
  }
}
