import React from 'react';
import styles from './buttons.scss';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class Buttons extends React.Component {
  renderChildren(children) {
    if (!children) return null;

    // all direct descendants should be a `button` class
    return React.Children.map(children, child => {
      if (!child) return null;

      return React.cloneElement(child, {
        className: cx(styles.button, child.props.className)
      });
    });
  }

  render() {
    const {
      children,
      bar,
      right,
      className,
      centered,
      stretch,
      noSpacing,
      noMargin,
      ...props
    } = this.props;
    const classes = cx(styles.buttons, className, {
      [styles.bar]: bar,
      [styles.right]: right,
      [styles.centered]: centered,
      [styles.stretch]: stretch,
      [styles.noSpacing]: noSpacing,
      [styles.noMargin]: noMargin
    });

    return (
      <div {...filterInvalidDOMProps(props)} className={classes}>
        <div className={styles.inner}>{this.renderChildren(children)}</div>
      </div>
    );
  }
}
