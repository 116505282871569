import {takeLatest, put} from 'redux-saga/effects';
import {showInModal} from 'modules/modal';
import {processRequest} from 'utils/saga/fetchUtils';
import {SHARE_REPORT, reportShareEndpoint, receiveShareReportUrl} from 'modules/share';
import ShareReportModal from 'modules/share/components/shareReportModal';

export default function* shareReportSaga() {
  yield takeLatest(SHARE_REPORT, shareReport);
}

export function* shareReport({reportId}) {
  yield put(
    showInModal(ShareReportModal, {
      name: 'shareReportModal',
      reportId
    })
  );

  const url = reportShareEndpoint({reportId});
  return yield processRequest('GET', url, {
    successAction: receiveShareReportUrl
  });
}
