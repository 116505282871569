import React, {Fragment} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import Status from 'components/status';
import styles from 'modules/inspector/components/inspectorSidebar.scss';
import {inspectorActionPointsSelector} from 'modules/inspector/inspectorSelectors';
import InspectorActionPointsList from '../inspectorActionPointsList';
import posed, {PoseGroup, tween} from 'react-pose';

function InspectorActionPoints({action, points, contextPoints}) {
  if (!action) return null;

  if (!points.size && !contextPoints.size) {
    return (
      <Status noFit appearance="good" className={styles.empty}>
        Nothing left to do for this page
      </Status>
    );
  }

  return (
    <div className={cx(styles.points)}>
      <PoseGroup animateOnMount>
        {contextPoints.size && (
          <Fragment key="contextPoints">
            <InspectorActionPointsList action={action} points={contextPoints} />
            {!!points.size && <h3 className={styles.contextSeparator}>Other points</h3>}
          </Fragment>
        )}

        <InspectorActionPointsList key="points" action={action} points={points} />
      </PoseGroup>
    </div>
  );
}

export default connect(inspectorActionPointsSelector)(InspectorActionPoints);
