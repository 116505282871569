import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import cx from 'classnames';
import {productChooserSelector} from 'modules/products/productSelectors';
import {PLAN_GROUPS} from 'modules/products/productConstants';
import {selectProduct} from 'modules/products';
import styles from './planChooser.scss';
import Flex from 'components/layout/flex';
import Button from 'components/button';
import Center from 'components/center';
import SlideBetween from 'components/animation/slideBetween';
import PlanFeatures from 'modules/products/components/planFeatures';
import DotSwitcher from 'components/tabs/dotSwitcher';
import Spinner from 'components/spinner';
import ExternalLink from 'modules/location/externalLink';

export class ProductChooser extends React.Component {
  handleSelectProduct = planId => {
    this.props.selectProduct(planId);
  };

  render() {
    const {hasProducts, plans, paymentDetails, productChoices, selectedGroupId} = this.props;

    if (!hasProducts) {
      return (
        <Center>
          <div className={styles.errorMessage}>
            <h4>Sorry but your account does not allow you to buy new plans</h4>
            <p>
              Please{' '}
              <ExternalLink href="https://silktide.com/about/contact-us/">contact us</ExternalLink>{' '}
              if you wish to upgrade your account.
            </p>
          </div>
        </Center>
      );
    }

    if (_.isEmpty(plans)) {
      return <Spinner style={{top: '280px'}} />;
    }

    const currentIndex = PLAN_GROUPS.findIndex(group => group.id === selectedGroupId);

    return (
      <div>
        <SlideBetween currentIndex={currentIndex}>
          {PLAN_GROUPS.map(group => (
            <Flex container alignStretch wrap="true" className={styles.content} key={group.id}>
              <group.chooserComponent
                plans={_.values(plans).filter(plan => plan.planGroupId === group.id)}
                paymentDetails={paymentDetails}
                productChoices={productChoices}
                selectProduct={this.handleSelectProduct}
              />
              {group.id === 'business' && (
                <DotSwitcher arrows={true} dots={false}>
                  <div className={styles.img}>
                    <img src={require('./summary.png')} />
                    <p>
                      Silktide tests your website's quality, marketing, accessibility and much more
                    </p>
                  </div>
                  <div className={styles.img}>
                    <img src={require('./spelling.png')} />
                    <p>Spell check your pages in 50 different languages</p>
                  </div>
                  <div className={styles.img}>
                    <img src={require('./inspector.png')} />
                    <p>See errors highlighted visually on your webpages</p>
                  </div>
                  <div className={styles.img}>
                    <img src={require('./mobile.png')} />
                    <p>Check your webpages are mobile optimized</p>
                  </div>
                </DotSwitcher>
              )}
              <PlanFeatures features={group.features} />
            </Flex>
          ))}
        </SlideBetween>
      </div>
    );
  }
}

export default connect(
  productChooserSelector,
  {selectProduct}
)(ProductChooser);
