import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './quiz.scss';

const propTypes = {
  onChange: PropTypes.func,
  answered: PropTypes.bool,
  answers: PropTypes.arrayOf(PropTypes.any)
};

const SingleChoiceAnswers = ({options, onChange, answered, answer: currentAnswer}) => {
  const handleChange = answer => {
    onChange([answer]);
  };

  return options.map((answer, i) => {
    return (
      <li
        key={answer + i}
        onClick={() => {
          if (!answered) handleChange(answer);
        }}
        className={answered ? styles.answered : ''}
      >
        <input
          type="radio"
          value={answer}
          name={answer.replace(/ /g, '')}
          onChange={() => {}}
          disabled={answered}
          checked={currentAnswer.includes(answer)}
        />
        {answer}
      </li>
    );
  });
};

SingleChoiceAnswers.propTypes = propTypes;

export default SingleChoiceAnswers;
