import {takeEvery, put, select} from 'redux-saga/effects';
import {REQUEST_SET_CONFIG_OPTIONS, setConfigOptions, setSuccessMessage} from '../configOptions';
import {couldNot} from 'utils/errorUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {addMessage} from 'modules/alerts';

const SUCCESS_MESSAGE =
  'The settings were saved. You need to retest the site in order for the settings to take effect.';

export default function* setConfigOptionSaga() {
  yield takeEvery(REQUEST_SET_CONFIG_OPTIONS, requestSetConfigOptions);
}

function* requestSetConfigOptions({options}) {
  const reportId = yield select(reportIdSelector);
  yield processRequest(
    'PUT',
    'reports/' + reportId + '/configOptions',
    {
      success: function*() {
        yield put(setConfigOptions(options));
        yield put(addMessage('success', 'The settings were saved'));
        yield put(setSuccessMessage(SUCCESS_MESSAGE));
      },
      error: function*() {
        yield put(addMessage('error', couldNot('save settings')));
      }
    },
    {options}
  );
}
