import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import TickSelector from 'components/checkboxes/tickSelector';
import {updateUptimeMonitorContact} from '../actions';
import {uptimeContactMethodSelector} from '../selectors';

function UptimeContactMethod({value, onChange, method, userId, monitorId, recordId}) {
  return (
    <div>
      <TickSelector selected={value} onClick={event => onChange(!value)} />
    </div>
  );
}

export default compose(
  connect(uptimeContactMethodSelector),
  connect(
    null,
    (dispatch, props) => ({
      onChange: value => {
        dispatch(
          updateUptimeMonitorContact(
            {
              userId: props.userId,
              contactOptions: {...props.contactOptions.toJS(), [props.method]: value}
            },
            props.reportId,
            props.monitorId
          )
        );
      }
    })
  )
)(UptimeContactMethod);

function Email() {}
