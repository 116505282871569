exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._18mp-fAXyPoYt3qbOIJAVM{position:relative}._18mp-fAXyPoYt3qbOIJAVM ._1nKUmnd5m5ot2lfN_WbP66{top:14px;right:50px;position:absolute;z-index:10}", ""]);

// exports
exports.locals = {
	"superTagInput": "_18mp-fAXyPoYt3qbOIJAVM",
	"icon": "_1nKUmnd5m5ot2lfN_WbP66"
};