exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PIvVoSSWEbWXom-7JxWtG{display:flex;flex-direction:row;margin:18px 0;align-items:center;line-height:140%}._3PIvVoSSWEbWXom-7JxWtG span{margin-right:12px}", ""]);

// exports
exports.locals = {
	"item": "_3PIvVoSSWEbWXom-7JxWtG"
};