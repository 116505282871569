import {generateFetchSaga} from 'utils/saga/fetchUtils';
import {put, select} from 'redux-saga/effects';
import {VIEW_ACTION, FETCH_ACTION, receiveAction} from 'modules/actions/actions';
import {actionEndpoint} from 'modules/actions/actionUtils';
import {replace} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import {locationParamsSelector} from 'modules/location/locationSelectors';

export default generateFetchSaga([FETCH_ACTION, VIEW_ACTION], actionEndpoint, {
  success: function*(resp) {
    if (resp.screenId) {
      const currentParams = yield select(locationParamsSelector);
      yield put(
        replace(
          testPath,
          {
            accountId: currentParams.accountId,
            reportId: currentParams.reportId,
            testId: resp.screenId
          },
          {},
          {},
          resp.filters
        )
      );
      return;
    }

    yield put(receiveAction(resp));
  }
});
