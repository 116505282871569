exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1YQZgRuv1rUSvdl01I70CV{margin:0 -15px}._23rJNYG1V9yrj-ssgH-rwf{display:flex;align-items:center;justify-content:center;flex-wrap:wrap;margin:20px 20px}._23rJNYG1V9yrj-ssgH-rwf>*{margin-right:10px}._2Dif4B0pWMFnQV00EG2CqL{font-size:45px}@media screen and (max-width: 992px){._2Dif4B0pWMFnQV00EG2CqL{display:none}}.osth_3Wn1akgWnQA_4W0H{font-size:45px;font-weight:bold}._3yXuOof5IReBW1Qw6nhE6q{font-size:20px}._1OVZ2iAQbvLVjp1hwbYe9D{background:#ddd;height:10px}._2PQ1Rn3uC8Do9MpBjOesW0{height:10px}", ""]);

// exports
exports.locals = {
	"wrapper": "_1YQZgRuv1rUSvdl01I70CV",
	"stat": "_23rJNYG1V9yrj-ssgH-rwf",
	"icon": "_2Dif4B0pWMFnQV00EG2CqL",
	"value": "osth_3Wn1akgWnQA_4W0H",
	"suffix": "_3yXuOof5IReBW1Qw6nhE6q",
	"bar": "_1OVZ2iAQbvLVjp1hwbYe9D",
	"progress": "_2PQ1Rn3uC8Do9MpBjOesW0"
};