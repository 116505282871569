import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {locationSelector} from 'modules/location/locationSelectors';
import ControlsComponent from 'components/control/controls';
import {userSelector} from 'modules/auth/authSelectors';
import {isSuperUser} from 'modules/auth/gandalf';

export class Controls extends React.Component {
  render() {
    const {
      testId,
      actionId,
      record,
      className,
      isSuperUser,
      options: {controls, filters, align}
    } = this.props;

    const alignClass = align ? 'align-' + align : undefined;

    const formattedControls = controls
      .filter(ctrl => {
        const {value} = ctrl.instruction.type;
        return isSuperUser || value !== 'global-dictionary';
      })
      .map(c => {
        return {type: 'button', ...c};
      });

    return (
      <ControlsComponent
        className={cx('control', alignClass)}
        controls={formattedControls}
        context={{actionId, testId, ...record}}
        filters={filters}
        small
      />
    );
  }
}

export default connect(
  createSelector(
    locationSelector,
    userSelector,
    ({query = {}, params}, user) => {
      return {testId: params.testId, actionId: params.actionId, isSuperUser: isSuperUser(user)};
    }
  )
)(Controls);
