import React, {Fragment} from 'react';
import {TickList, TickListItem} from 'components/layoutComponents/tickList';
import Icon from 'components/font-awesome';
import styles from './planBenefits.scss';

const PlanBenefits = ({...rest}) => (
  <div {...rest}>
    <div className={styles.summary}>
      <h3>All plans include</h3>
      <div className={styles.benefitWrapper}>
        <div className={styles.benefits}>
          <ul className={styles.benefitList}>
            <li>
              <span>
                <span className={styles.benefitIcon}>
                  <img src="/icons/users.svg" />
                </span>
                <h4>Unlimited users</h4>
              </span>
              <p>Invite as many users as you need, and control what websites they access.</p>
            </li>
            <li>
              <span>
                <span className={styles.benefitIcon}>
                  <img src="/icons/test-tube.svg" />
                </span>
                <h4>Policies</h4>
              </span>
              <p>Create your own custom policies to check your webpages.</p>
            </li>
            <li>
              <span>
                <span className={styles.benefitIcon}>
                  <img src="/icons/database.svg" />
                </span>
                <h4>Leaderboards</h4>
              </span>
              <p>Compare multiple websites, ranking them by criteria you choose.</p>
            </li>
          </ul>
        </div>
        <div className={styles.benefits}>
          <TickList>
            <TickListItem>Unlimited testing of your webpages</TickListItem>
            <TickListItem>All webpages tested automatically every 5 days</TickListItem>
            <TickListItem>Alerts via email and text message</TickListItem>
            <TickListItem>Online support via email and realtime chat</TickListItem>
            <TickListItem>No obligation - cancel your subscription at any time</TickListItem>
          </TickList>
        </div>
      </div>
    </div>
    <p className={styles.footnote}>
      Prices in the EU exclude any applicable VAT. This will be calculated on the next screen before
      you pay.
    </p>
  </div>
);

export default PlanBenefits;
