import AbstractField from './AbstractField';
import FieldInput from './FieldInput';

export default class FieldGroup extends AbstractField {
  initialise(value) {
    super.initialise();

    this.setValue(value);
    this.initialValue = this.getSerialValue();
  }

  setValue(value) {
    this.fields = this.map(value, this.createNewField);
  }

  getField(keys) {
    const keysPath = [...keys];
    let field = this;

    while (keysPath.length) {
      const path = keysPath.shift();
      if (field.fields[path]) {
        field = field.fields[path];
      } else {
        return null;
      }
    }
    return field;
  }

  getValue() {
    return this.map(this.fields, field => field.getValue());
  }

  getFormattedValue() {
    const formatted = this.map(this.fields, field => field.getFormattedValue());
    return this.formatter ? this.formatter(formatted) : formatted;
  }

  getFields() {
    return this.fields;
  }

  getHtmlFor() {
    return undefined;
  }

  setSubmitted() {
    this.submitted = true;

    this.map(this.fields, field => {
      field.setSubmitted();
    });
  }

  // used internally for correcting field values when splicing
  setPathIndex(pathIdx, value) {
    super.setPathIndex(pathIdx, value);

    // we *need* to set the `key` for our child fields so they match up with their actual positions
    for (let prop in this.fields) {
      this.fields[prop].setPathIndex(pathIdx, value);
    }
  }

  createNewField = (item, keys) => {
    const FieldClass = this.constructor.isIterable(item) ? FieldGroup : FieldInput;
    const field = new FieldClass([...this.path, ...keys], this.fnRerender, this.fnValidate);
    field.initialise(item);
    return field;
  };

  /**
   * Like a typical splice operation on an array, but the new values are turned into fields (just like the useForm hook)
   *
   * @param {number} index The index where the first new value should start
   * @param {number} removeCount The number of items to remove starting at index
   * @param  {...any} newValues The new values to be entered starting at index
   */
  splice(index, removeCount, ...newValues) {
    if (!Array.isArray(this.fields)) return;

    const newFields = newValues.map((value, i) => this.createNewField(value, [index + i]));

    this.fields.splice(index, removeCount, ...newFields);

    const pathIdx = this.path.length;
    for (let i = index + newValues.length; i < this.fields.length; i++) {
      this.fields[i].setPathIndex(pathIdx, i);
    }
    this.changed = true;
  }

  push(...values) {
    this.splice(this.fields.length, 0, ...values);
  }

  unshift(...values) {
    this.splice(0, 0, ...values);
  }

  removeIndex(index) {
    this.splice(index, 1);
  }

  // I dont recommend using this. fields should be initialised the normal way.
  setChild(key, value) {
    if (!this.constructor.isObject(this.fields)) return;

    this.fields[key] = this.createNewField(value, [key]);
  }

  // I don't recommend using this. If fields are no longer needed, they should be hidden in the UI only and ignored
  // there is not need to remove children. if you *need* to though...
  removeChild(key) {
    if (!this.constructor.isObject(this.fields)) return;

    delete this.fields[key];
  }

  hasError() {
    if (!this.isValid) return true;
    return this.some(field => field.hasError());
  }

  hasChanged() {
    if (this.changed) return true;
    return this.some(field => field.hasChanged());
  }

  // This iterates only the direct descendant fields.
  // This becomes recursive when the callback also calls `some`.
  some(callback) {
    if (Array.isArray(this.fields)) {
      return this.fields.some(callback);
    } else {
      return Object.keys(this.fields).some(name => callback(this.fields[name], name));
    }
  }

  // used internally for iterating `fields` that can be object or array
  map(value, callback, keys = []) {
    return Array.isArray(value)
      ? value.map((item, key) => {
          return callback(item, [...keys, key]);
        })
      : Object.keys(value).reduce((obj, key) => {
          obj[key] = callback(value[key], [...keys, key]);
          return obj;
        }, {});
  }
}
