import {
  FETCH_UPTIME_MONITORS,
  CLEAR_UPTIME_MONITORS,
  RECEIVE_UPTIME_MONITORS,
  RECEIVE_UPTIME_MONITOR,
  UPDATE_UPTIME_MONITOR,
  DELETE_UPTIME_MONITOR,
  DELETE_UPTIME_CONTACT,
  REQUEST_DELETE_UPTIME_MONITOR,
  CONFIRM_DELETE_UPTIME_MONITOR,
  RECEIVE_UPTIME_MONITOR_CONTACTS,
  RECEIVE_UPTIME_MONITOR_CONTACT,
  FETCH_UPTIME_MONITOR_CONTACTS,
  UPDATE_UPTIME_MONITOR_CONTACT,
  CONFIRM_DELETE_UPTIME_CONTACT,
  REQUEST_DELETE_UPTIME_CONTACT,
  REQUEST_CAN_ADD_UPTIME_MONITOR,
  RECEIVE_CAN_ADD_UPTIME_MONITOR
} from './constants';

export function fetchUptimeMonitors(reportId) {
  return {
    type: FETCH_UPTIME_MONITORS,
    reportId
  };
}

export function fetchUptimeMonitorContacts(reportId, monitorId) {
  return {
    type: FETCH_UPTIME_MONITOR_CONTACTS,
    reportId,
    monitorId
  };
}

export function clearUptimeMonitors() {
  return {
    type: CLEAR_UPTIME_MONITORS
  };
}

export function receiveUptimeMonitors(uptimeMonitors) {
  return {
    type: RECEIVE_UPTIME_MONITORS,
    uptimeMonitors
  };
}

export function receiveUptimeMonitor(uptimeMonitor) {
  return {
    type: RECEIVE_UPTIME_MONITOR,
    uptimeMonitor
  };
}

export function updateMonitor(data, reportId, monitorId = null) {
  return {
    type: UPDATE_UPTIME_MONITOR,
    monitorId,
    data,
    reportId
  };
}

export function updateUptimeMonitorContact(data, reportId, monitorId = null) {
  return {
    type: UPDATE_UPTIME_MONITOR_CONTACT,
    monitorId,
    data,
    reportId
  };
}

export function deleteUptimeMonitor(monitorId, reportId) {
  return {
    type: DELETE_UPTIME_MONITOR,
    monitorId,
    reportId
  };
}

export function deleteUptimeContact(userId, monitorId, reportId) {
  return {
    type: DELETE_UPTIME_CONTACT,
    monitorId,
    reportId,
    userId
  };
}

export function confirmDeleteMonitor(monitorId, reportId) {
  return {
    type: CONFIRM_DELETE_UPTIME_MONITOR,
    monitorId,
    reportId
  };
}

export function requestDeleteMonitor(monitorId, reportId) {
  return {
    type: REQUEST_DELETE_UPTIME_MONITOR,
    monitorId,
    reportId
  };
}

export function confirmDeleteUptimeContact(userId, monitorId, reportId) {
  return {
    type: CONFIRM_DELETE_UPTIME_CONTACT,
    monitorId,
    reportId,
    userId
  };
}

export function requestDeleteUptimeContact(userId, monitorId, reportId) {
  return {
    type: REQUEST_DELETE_UPTIME_CONTACT,
    monitorId,
    reportId,
    userId
  };
}

export function receiveUptimeMonitorContacts(monitorId, contacts = []) {
  return {
    type: RECEIVE_UPTIME_MONITOR_CONTACTS,
    monitorId,
    contacts
  };
}

// `uptimeContacts` looks like {monitorId, userId, contactOptions: {email: true, phoneNumber: true}}
export function receiveUptimeMonitorContact(uptimeContacts) {
  return {
    type: RECEIVE_UPTIME_MONITOR_CONTACT,
    uptimeContacts
  };
}

export function requestCanAddUptimeMonitor(reportId) {
  return {
    type: REQUEST_CAN_ADD_UPTIME_MONITOR,
    reportId
  };
}

export function receiveCanAddUptimeMonitor(canAdd) {
  return {
    type: RECEIVE_CAN_ADD_UPTIME_MONITOR,
    canAdd
  };
}
