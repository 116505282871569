exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rOMX7jxGMD87R1nNvCqw{display:flex;align-items:center;justify-content:flex-start}._2rOMX7jxGMD87R1nNvCqw p{margin:0 15px 0 0}._2rOMX7jxGMD87R1nNvCqw p:first-child{opacity:.7}._3QunJoGf3DHEPz6yrKLLG8{display:flex;align-items:center}._3QunJoGf3DHEPz6yrKLLG8 img{width:80px;border-radius:50%;height:auto;flex:0 0 auto;margin-right:10px}._3QunJoGf3DHEPz6yrKLLG8 p{font-weight:bold}", ""]);

// exports
exports.locals = {
	"tutors": "_2rOMX7jxGMD87R1nNvCqw",
	"tutor": "_3QunJoGf3DHEPz6yrKLLG8"
};