import React from 'react';
import {List} from 'immutable';
import {requestChecks} from 'modules/policies/actions';
import {makeDefaultData} from 'modules/policies/policyUtils';
import {checksSelector, checksLoadedSelector} from '../selectors';
import PolicyRulesFields from './policyRulesFields';
import Flex from 'components/layout/flex';
import Button from 'components/button';
import Center from 'components/center';
import Dropdown from 'components/dropdown';
import {useSelector, useDispatch} from 'hooks/redux';

const addRuleButton = <Button icon="plus">Add rule</Button>;

function makeNewRule(check) {
  return {
    policyCheckId: check.policyCheckId,
    isNot: false,
    options: makeDefaultData(check.supportedOptions, {})
  };
}

function PolicyRulesForm(props) {
  const {form, scope, ruleOperator} = props;

  const dispatch = useDispatch();
  const checks = useSelector(checksSelector, props);
  const checksLoaded = useSelector(checksLoadedSelector, props);

  const scopeChecks = checks.get(props.scope, List());
  const checkOptions = scopeChecks.map(check => ({
    label: check.get('name'),
    value: check.get('policyCheckId')
  }));

  React.useEffect(() => {
    dispatch(requestChecks(scope));
  }, [scope]);

  const handleCreateNewRule = checkId => {
    const check = scopeChecks.find(check => check.get('policyCheckId') === checkId);
    const newRule = makeNewRule(check.toJS());

    form.push(newRule);
    form.rerender();
  };

  const dropdown = (
    <Dropdown
      button={addRuleButton}
      options={checkOptions.toJS()}
      isLoading={!checkOptions.size}
      onChange={handleCreateNewRule}
    />
  );

  return (
    <React.Fragment>
      <h3>Rules for this policy</h3>
      {checksLoaded && (
        <PolicyRulesFields
          scope={scope}
          field={form}
          ruleOperator={ruleOperator}
          addRuleButton={dropdown}
        />
      )}
      {!!form.fields.length && <Center marginBottom={40}>{dropdown}</Center>}
    </React.Fragment>
  );
}

export default PolicyRulesForm;
