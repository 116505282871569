import React from 'react';

class Filters extends React.Component {
  render() {
    return null; // Intentionally invisible
  }
}

Filters.handlesLoading = true;

export default Filters;
