import {delay} from 'redux-saga';
import {List} from 'immutable';
import {takeLatest, select, put, call} from 'redux-saga/effects';
import {setMarkedHtml} from '../inspectorActions';
import {markupHtmlSource} from '../inspectorUtils';
import {UPDATE_MARKED_HTML} from '../inspectorConstants';
import {sourceHtmlSelector, htmlPointsSelector} from '../inspectorSelectors';

export default function* updateMarkedHtmlSaga() {
  yield takeLatest(UPDATE_MARKED_HTML, updateMarkedHtml);
}

// some shitty local caching
let lastSource = '';
let lastPointIds = List();

function* updateMarkedHtml({actionId, testId}) {
  // the points to represent in the DOM
  const points = yield select(htmlPointsSelector, {match: {query: {actionId, testId}}});
  const origSourceHtml = yield select(sourceHtmlSelector);

  let newPoints = points.map(p => p.get('pointId'));

  const hasInputsChanged =
    lastSource !== origSourceHtml ||
    lastPointIds.size !== newPoints.size ||
    !lastPointIds.every(pointId => newPoints.includes(pointId));

  if (hasInputsChanged && origSourceHtml) {
    const sourceHtml = markupHtmlSource(origSourceHtml, points);

    yield put(setMarkedHtml('source', sourceHtml));

    lastSource = origSourceHtml;
    lastPointIds = newPoints;
  }
}
