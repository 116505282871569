import {take, select, cancel, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SET_USER_OPTION, receiveUserOption} from 'modules/userOptions';
import {userOptionsEndpoint} from 'modules/userOptions/userOptionUtils';
import {couldNot} from 'utils/errorUtils';

export default function* setUserOptionSaga() {
  yield takeLatest(SET_USER_OPTION, setUserOption);
}

function* setUserOption({option, value}) {
  const postData = {value};

  yield processRequest(
    'POST',
    userOptionsEndpoint({option}),
    {
      success: function*(data) {
        yield put(receiveUserOption(option, data));
      },

      errorMessage: couldNot('set user option')
    },
    postData
  );
}
