import React from 'react';
import cx from 'classnames';
import {PLAN_GROUPS} from 'modules/products/productConstants';
import styles from './planPage.scss';
import PlanChooser from 'modules/products/components/planChooser';
import Center from 'components/center';
import Button from 'components/button';
import Faqs from 'modules/products/components/faqs';
import Logos from 'components/logos';
import Copyright from 'components/legal/copyright';
import {connect} from 'react-redux';
import {loggedInSelector} from 'modules/auth/authSelectors';

export class PlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: props.isLoggedIn ? 'business' : 'agency'
    };
  }

  handleSwitcherClick = groupId => () => {
    this.setState({selectedGroup: groupId});
  };

  renderPlan = group => {
    const {id, title} = group;
    const classes = cx({
      [styles.selected]: id === this.state.selectedGroup
    });

    return (
      <li key={id}>
        <Button onClick={this.handleSwitcherClick(id)} className={classes} squareCorners large>
          {title}
        </Button>
      </li>
    );
  };

  render() {
    const {contentClassName, isLoggedIn} = this.props;

    return (
      <div className={styles.fullScreen}>
        <div>
          <div className={styles.background} />
          <div className={styles.wrapper}>
            <Center className={styles.header}>
              <h1>Choose the right plan for you</h1>
            </Center>
            {isLoggedIn && (
              <Center>
                <ul className={styles.groupSwitcher}>{PLAN_GROUPS.map(this.renderPlan)}</ul>
              </Center>
            )}

            <div className={cx(styles.content, contentClassName)}>
              <PlanChooser selectedGroupId={this.state.selectedGroup} />
            </div>
            <div className={styles.footer}>
              <Faqs className={styles.footer} />

              <div className={styles.checkoutFooter}>
                <Logos />
                <Copyright />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state, props) => ({
  isLoggedIn: loggedInSelector(state, props)
}))(PlanPage);
