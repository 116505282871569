exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._351PIHLYrelWds85jj68YH{width:100%;align-self:flex-start;margin:3px 0 0 3px}._351PIHLYrelWds85jj68YH h1{width:100%;overflow:hidden}._351PIHLYrelWds85jj68YH h1,._351PIHLYrelWds85jj68YH h3{text-overflow:ellipsis;white-space:nowrap;margin:0}._351PIHLYrelWds85jj68YH h3{font-size:18px;opacity:.5}._2JauP-Ey8a36fjcQM2NHtM{display:flex;align-items:center}._1JCyxgyOOxiDHrDN31w2Co{padding:0 10px;color:#777;margin-top:4px}@media(max-width: 1080px){._1JCyxgyOOxiDHrDN31w2Co{display:none}}._17MeFggPNM1sX1KlwkzQCJ{font-size:14px;color:#55d272;position:relative;top:-6px}._2pRuGxyMSbDjrpxEemfw7a{position:relative;top:-10px}._1uWs2fySFbY_5P13hHH62V{font-size:.8em;opacity:.5}@media print{._1uWs2fySFbY_5P13hHH62V{display:none}}@media(max-width: 425px){._351PIHLYrelWds85jj68YH h1,._2JauP-Ey8a36fjcQM2NHtM h1{font-size:1.6em}}", ""]);

// exports
exports.locals = {
	"reportTitle": "_351PIHLYrelWds85jj68YH",
	"screenTitle": "_2JauP-Ey8a36fjcQM2NHtM",
	"description": "_1JCyxgyOOxiDHrDN31w2Co",
	"beta": "_17MeFggPNM1sX1KlwkzQCJ",
	"withScore": "_2pRuGxyMSbDjrpxEemfw7a",
	"help": "_1uWs2fySFbY_5P13hHH62V"
};