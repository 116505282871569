import {take, call, put, select, takeLatest} from 'redux-saga/effects';
import FolderViewModal from 'modules/folderViews/components/folderViewModal';
import {TRIGGER_DELETE_FOLDER_VIEW, deleteFolderView} from 'modules/folderViews';
import {folderPath} from 'modules/folders/folderUtils';
import {folderViewEndpoint} from 'modules/folderViews/folderViewUtils';
import {processRequest} from 'utils/saga/fetchUtils';
import confirm from 'utils/saga/confirm';
import {push} from 'modules/location';
import {locationParamsSelector} from 'modules/location/locationSelectors';

export default function* deleteFolderViewSaga() {
  yield takeLatest([TRIGGER_DELETE_FOLDER_VIEW], removeFolderView);
}

function* removeFolderView(action) {
  const {folderId, viewId} = action;

  const confirmDelete = yield call(confirm, {
    title: 'Are you sure?',
    content: 'Are you sure you wish to delete this folder view?'
  });

  if (!confirmDelete) {
    return;
  }

  yield processRequest('DELETE', folderViewEndpoint(action), {
    success: function*() {
      yield put(deleteFolderView(folderId, viewId));

      const params = yield select(locationParamsSelector);

      // redirect if the view being deleted is also the active view
      if (params.viewId == viewId) {
        yield put(push(folderPath(params)));
      }
    },
    errorMessage: 'Failed to delete folder view'
  });
}
