import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import CanBuyGandalf from 'components/gandalf/canBuyGandalf';
import {showProducts} from 'modules/products';

export class BuyButton extends React.Component {
  handleClick = () => {
    this.props.showProducts();
  };

  render() {
    const {children, shallPassStatus, ...buttonProps} = this.props;

    return (
      <CanBuyGandalf shallPassStatus={shallPassStatus}>
        <Button {...buttonProps} cta onClick={this.handleClick}>
          {children || 'Buy more'}
        </Button>
      </CanBuyGandalf>
    );
  }
}

export default connect(
  null,
  {showProducts}
)(BuyButton);
