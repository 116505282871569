import {createSelector} from 'reselect';

const proxyState = state => state.proxies;

export const proxyListSelector = createSelector(
  proxyState,
  state => {
    return {
      proxyList: state.get('proxies').toJS()
    };
  }
);
