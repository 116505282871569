import React from 'react';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';

// Abstract for Draggable/Droppable.
// i.e. makeDNDComponent('connectDragSource', DragSource(...))
export const makeDNDDecorator = (connectMethodName, DNDSource) => WrappedComponent => {
  class DNDComponent extends React.Component {
    // we use this method name as it's the same as the one used be react-dnd
    // making things easy for us.
    getDecoratedComponentInstance() {
      return this.wrappedComponent;
    }

    render() {
      const connectSource = this.props[connectMethodName];

      return (
        <WrappedComponent
          {...this.props}
          ref={instance => {
            connectSource(findDOMNode(instance));
            this.wrappedComponent = instance;
          }}
        />
      );
    }
  }

  return DNDSource(DNDComponent);
};

function unwrapComponent(component) {
  if (component.getDecoratedComponentInstance) {
    return unwrapComponent(component.getDecoratedComponentInstance());
  }
  return component;
}

// handlerMap = {
//   canDrag: () => true,
//   beginDrag: 'handleBeginDrag'
// }
//
// would call component.handleBeginDrag() on beginDrag.
export function handlerMapToSource(handlerMap) {
  return _.mapValues(
    handlerMap,
    (method, handlerName) =>
      function(...args) {
        const [props, monitor, unwrappedComponent] = args;

        // allow methods to be callbacks or methodName strings
        if (typeof method === 'function') {
          return method(...args);
        }

        if (handlerName === 'canDrag') {
          throw new Error(
            'canDrag does not have access to a component so cannot be a component method'
          );
        }

        const component = unwrapComponent(unwrappedComponent);

        // No need to pass props or component through as we already can access them from the component
        return component && component[method] && component[method](monitor);
      }
  );
}
