import React from 'react';
import Helmet from 'components/helmet';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import ReportSettingsSidebar from 'modules/settings/components/reportSettingsSidebar';

export default class ReportSettingsPage extends React.Component {
  render() {
    const {children, match, location, ...props} = this.props;

    return (
      <div style={{height: '100vh'}}>
        <Helmet title="Settings" />

        <PageWithSidebar
          sidebar={<ReportSettingsSidebar params={match.params} location={location} />}
        >
          <div {...filterInvalidDOMProps(props)}>{children}</div>
        </PageWithSidebar>
      </div>
    );
  }
}
