export default [
  {value: 'en', label: 'English'},
  {value: 'ach', label: 'Luo'},
  {value: 'af', label: 'Afrikaans'},
  {value: 'ak', label: 'Akan'},
  {value: 'am', label: 'Amharic'},
  {value: 'ar', label: 'Arabic'},
  {value: 'az', label: 'Azerbaijani'},
  {value: 'be', label: 'Belarusian'},
  {value: 'bem', label: 'Bemba'},
  {value: 'bg', label: 'Bulgarian'},
  {value: 'bh', label: 'Bihari'},
  {value: 'bn', label: 'Bengali'},
  {value: 'br', label: 'Breton'},
  {value: 'bs', label: 'Bosnian'},
  {value: 'ca', label: 'Catalan'},
  {value: 'chr', label: 'Cherokee'},
  {value: 'ckb', label: 'Kurdish (Soranî)'},
  {value: 'co', label: 'Corsican'},
  {value: 'crs', label: 'Seychellois Creole'},
  {value: 'cs', label: 'Czech'},
  {value: 'cy', label: 'Welsh'},
  {value: 'da', label: 'Danish'},
  {value: 'de', label: 'German'},
  {value: 'ee', label: 'Ewe'},
  {value: 'el', label: 'Greek'},
  {value: 'eo', label: 'Esperanto'},
  {value: 'es', label: 'Spanish'},
  {value: 'es-419', label: 'Spanish (Latin American)'},
  {value: 'et', label: 'Estonian'},
  {value: 'eu', label: 'Basque'},
  {value: 'fa', label: 'Persian'},
  {value: 'fi', label: 'Finnish'},
  {value: 'fo', label: 'Faroese'},
  {value: 'fr', label: 'French'},
  {value: 'fy', label: 'Frisian'},
  {value: 'ga', label: 'Irish'},
  {value: 'gaa', label: 'Ga'},
  {value: 'gd', label: 'Scots Gaelic'},
  {value: 'gl', label: 'Galician'},
  {value: 'gn', label: 'Guarani'},
  {value: 'gu', label: 'Gujarati'},
  {value: 'ha', label: 'Hausa'},
  {value: 'haw', label: 'Hawaiian'},
  {value: 'hi', label: 'Hindi'},
  {value: 'hr', label: 'Croatian'},
  {value: 'ht', label: 'Haitian Creole'},
  {value: 'hu', label: 'Hungarian'},
  {value: 'hy', label: 'Armenian'},
  {value: 'ia', label: 'Interlingua'},
  {value: 'id', label: 'Indonesian'},
  {value: 'ig', label: 'Igbo'},
  {value: 'is', label: 'Icelandic'},
  {value: 'it', label: 'Italian'},
  {value: 'iw', label: 'Hebrew'},
  {value: 'ja', label: 'Japanese'},
  {value: 'jw', label: 'Javanese'},
  {value: 'ka', label: 'Georgian'},
  {value: 'kg', label: 'Kongo'},
  {value: 'kk', label: 'Kazakh'},
  {value: 'km', label: 'Cambodian'},
  {value: 'kn', label: 'Kannada'},
  {value: 'ko', label: 'Korean'},
  {value: 'kri', label: 'Krio (Sierra Leone)'},
  {value: 'ku', label: 'Kurdish'},
  {value: 'ky', label: 'Kyrgyz'},
  {value: 'la', label: 'Latin'},
  {value: 'lg', label: 'Luganda'},
  {value: 'ln', label: 'Lingala'},
  {value: 'lo', label: 'Laothian'},
  {value: 'loz', label: 'Lozi'},
  {value: 'lt', label: 'Lithuanian'},
  {value: 'lua', label: 'Tshiluba'},
  {value: 'lv', label: 'Latvian'},
  {value: 'mfe', label: 'Mauritian Creole'},
  {value: 'mg', label: 'Malagasy'},
  {value: 'mi', label: 'Maori'},
  {value: 'mk', label: 'Macedonian'},
  {value: 'ml', label: 'Malayalam'},
  {value: 'mn', label: 'Mongolian'},
  {value: 'mo', label: 'Moldavian'},
  {value: 'mr', label: 'Marathi'},
  {value: 'ms', label: 'Malay'},
  {value: 'mt', label: 'Maltese'},
  {value: 'ne', label: 'Nepali'},
  {value: 'nl', label: 'Dutch'},
  {value: 'nn', label: 'Norwegian (Nynorsk)'},
  {value: 'no', label: 'Norwegian'},
  {value: 'nso', label: 'Northern Sotho'},
  {value: 'ny', label: 'Chichewa'},
  {value: 'nyn', label: 'Runyakitara'},
  {value: 'oc', label: 'Occitan'},
  {value: 'om', label: 'Oromo'},
  {value: 'or', label: 'Oriya'},
  {value: 'pa', label: 'Punjabi'},
  {value: 'pcm', label: 'Nigerian Pidgin'},
  {value: 'pl', label: 'Polish'},
  {value: 'ps', label: 'Pashto'},
  {value: 'pt-BR', label: 'Portuguese (Brazil)'},
  {value: 'pt-PT', label: 'Portuguese (Portugal)'},
  {value: 'qu', label: 'Quechua'},
  {value: 'rm', label: 'Romansh'},
  {value: 'rn', label: 'Kirundi'},
  {value: 'ro', label: 'Romanian'},
  {value: 'ru', label: 'Russian'},
  {value: 'rw', label: 'Kinyarwanda'},
  {value: 'sd', label: 'Sindhi'},
  {value: 'sh', label: 'Serbo-Croatian'},
  {value: 'si', label: 'Sinhalese'},
  {value: 'sk', label: 'Slovak'},
  {value: 'sl', label: 'Slovenian'},
  {value: 'sn', label: 'Shona'},
  {value: 'so', label: 'Somali'},
  {value: 'sq', label: 'Albanian'},
  {value: 'sr', label: 'Serbian'},
  {value: 'sr-ME', label: 'Montenegrin'},
  {value: 'st', label: 'Sesotho'},
  {value: 'su', label: 'Sundanese'},
  {value: 'sv', label: 'Swedish'},
  {value: 'sw', label: 'Swahili'},
  {value: 'ta', label: 'Tamil'},
  {value: 'te', label: 'Telugu'},
  {value: 'tg', label: 'Tajik'},
  {value: 'th', label: 'Thai'},
  {value: 'ti', label: 'Tigrinya'},
  {value: 'tk', label: 'Turkmen'},
  {value: 'tl', label: 'Filipino'},
  {value: 'tn', label: 'Setswana'},
  {value: 'to', label: 'Tonga'},
  {value: 'tr', label: 'Turkish'},
  {value: 'tt', label: 'Tatar'},
  {value: 'tum', label: 'Tumbuka'},
  {value: 'tw', label: 'Twi'},
  {value: 'ug', label: 'Uighur'},
  {value: 'uk', label: 'Ukrainian'},
  {value: 'ur', label: 'Urdu'},
  {value: 'uz', label: 'Uzbek'},
  {value: 'vi', label: 'Vietnamese'},
  {value: 'wo', label: 'Wolof'},
  {value: 'xh', label: 'Xhosa'},
  {value: 'xx-bork', label: 'Bork, bork, bork!'},
  {value: 'xx-elmer ', label: 'Elmer Fudd'},
  {value: 'xx-hacker ', label: 'Hacker'},
  {value: 'xx-klingon', label: 'Klingon'},
  {value: 'xx-pirate', label: 'Pirate'},
  {value: 'yi', label: 'Yiddish'},
  {value: 'yo', label: 'Yoruba'},
  {value: 'zh-CN', label: 'Chinese (Simplified)'},
  {value: 'zh-TW', label: 'Chinese (Traditional)'},
  {value: 'zu', label: 'Zulu'}
];
