import {take, select, call, put, cancel, fork, takeEvery} from 'redux-saga/effects';
import {REQUEST_EDIT_USER, editUser} from 'modules/users';
import {addMessage} from 'modules/alerts';
import {showInModal} from 'modules/modal';
import EditUserModal from 'modules/users/modals/editUserModal';
import {userSelector} from 'modules/auth/authSelectors';
import {isEditingSelf, canResetPassword, canEditRole} from 'modules/users/userUtils';
import {toJS} from 'utils/immutableUtils';

export default function* editEveryUserSaga() {
  yield takeEvery(REQUEST_EDIT_USER, editUserSaga);
}

function* editUserSaga({user}) {
  const loggedInUser = toJS(yield select(userSelector));

  const allowResetPassword = canResetPassword(user, loggedInUser);
  const allowEditRole = canEditRole(user, loggedInUser);

  yield put(
    showInModal(EditUserModal, {
      allowEditRole,
      isEditingSelf: isEditingSelf(user, loggedInUser),
      allowResetPassword,
      defaultValue: user,
      name: 'editUserModal'
    })
  );
}
