exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3NUAriYOGWYsZFig2kZ83c{margin:0}._3mj2phiaPNdZfCG1NX5iuJ{color:#df1616;border-bottom:1px dashed #df1616}._2blm8FTooZV2OfSaCk3PGp{font-size:17px}._2blm8FTooZV2OfSaCk3PGp del{text-decoration:underline;color:#b00}", ""]);

// exports
exports.locals = {
	"quote": "_3NUAriYOGWYsZFig2kZ83c",
	"misspelling": "_3mj2phiaPNdZfCG1NX5iuJ",
	"correction": "_2blm8FTooZV2OfSaCk3PGp"
};