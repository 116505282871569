import React from 'react';

// In our backend, these are defined in Responsibilities.php
const RESPONSIBILITIES = {
  ['']: 'Everyone',
  editor: 'Editor',
  designer: 'Designer',
  developer: 'Developer'
};

export const OPTIONS = Object.keys(RESPONSIBILITIES).map(id => ({
  value: id,
  label: RESPONSIBILITIES[id]
}));

export default class Responsibility extends React.Component {
  render() {
    const {value} = this.props;
    if (!value) {
      return <span>Everyone</span>;
    }

    const responsibility = RESPONSIBILITIES[value] || 'Everyone';

    return <span>{responsibility}</span>;
  }
}
