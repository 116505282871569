import React from 'react';
import Center from 'components/center';
import AddWidgetButton from 'modules/dashboards/containers/addWidgetButton';
import {Wrapper, Header, Body, Title} from 'modules/dashboards/components/widget';

export default function EmptyWidget({dashboardId, rowIndex, columnIndex, contextTypeId}) {
  return (
    <Wrapper>
      <Header>
        <Title>New widget</Title>
      </Header>

      <Body heightUnit={1}>
        <Center>
          <AddWidgetButton
            dashboardId={dashboardId}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            contextTypeId={contextTypeId}
          />
        </Center>
      </Body>
    </Wrapper>
  );
}
