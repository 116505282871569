import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {hideModal} from 'modules/modal';
import {ModalForm, Field} from 'modules/form/components';
import {saveFolderView} from 'modules/folderViews';
import {toJS} from 'utils/immutableUtils';
import {folderViewModalSelector} from 'modules/folderViews/folderViewSelectors';

const viewOptions = [{label: 'Grid', value: 'grid'}, {label: 'Table', value: 'table'}];

export class FolderViewModal extends React.Component {
  static propTypes = {
    folderView: PropTypes.object
  };

  static defaultProps = {
    folderView: {
      name: '',
      type: 'grid',
      columns: ['report.name']
    }
  };

  handleValidSubmit = data => {
    const {folderView, saveFolderView, hideModal} = this.props;

    saveFolderView({...folderView, ...data});
    hideModal();
  };

  getTitle() {
    return `${this.isEditing() ? 'Edit' : 'New'} View`;
  }

  isEditing() {
    return this.props.folderView.folderId > 0;
  }

  render() {
    const {value, name, folderView} = this.props;
    const description = 'A view defines how you view your websites ';

    return (
      <ModalForm
        title={this.getTitle()}
        description={description}
        name={name}
        submitLabel={this.isEditing() ? 'Save' : 'Create'}
        defaultValue={folderView}
        onValidSubmit={this.handleValidSubmit}
      >
        <Field label="Name" name="name" type="text" required autoFocus placeholder="e.g. SEO" />
        <Field label="Display as" name="type" type="select" options={viewOptions} />

        <Field
          label="Columns"
          name="columns"
          type="listbox"
          options={this.props.stats}
          maxLength={100}
          customMessages={{
            maxLength: max => `Please choose up to ${max} columns`
          }}
          showIf={data => data.type == 'table'}
        />
      </ModalForm>
    );
  }
}

export default connect(
  folderViewModalSelector,
  {hideModal, saveFolderView}
)(FolderViewModal);
