import {delay} from 'redux-saga';
import {take, put, select, takeLatest} from 'redux-saga/effects';
import {
  SEARCH_FOR_LOCATION,
  receiveLocationResults,
  loadingLocationResults
} from 'modules/geosearch';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* searchForLocationSaga() {
  yield takeLatest(SEARCH_FOR_LOCATION, searchForLocation);
}

function* searchForLocation({query, limit = 10}) {
  const url = 'geo/search?q=' + encodeURIComponent(query) + '&limit=' + limit;

  if (!query) {
    // clear results if no query
    yield put(receiveLocationResults([]));
    yield put(loadingLocationResults(false));
    return;
  }

  yield put(loadingLocationResults(true));

  yield delay(300); // debounce

  yield processRequest('GET', url, {
    success: function*({success, locations} = {}) {
      if (success && locations.length) {
        yield put(receiveLocationResults(locations));
      }
      yield put(loadingLocationResults(false));
    },
    error: function*(err) {
      yield put(loadingLocationResults(false));
    }
  });
}
