import {takeLatest, put} from 'redux-saga/effects';
import {EDIT_FORCED_PAGE} from 'modules/reports/reportConstants';
import {processRequest} from 'utils/saga/fetchUtils';
import {forcedPagesEndpoint, forcedPagesSettingsPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location';
import {showError, showSuccess} from 'utils/saga/alerts';

export default function* editForcedPageSaga() {
  yield takeLatest(EDIT_FORCED_PAGE, editForcedPage);
}

function* editForcedPageProcessResult() {
  yield put(push(forcedPagesSettingsPath, {}, ['reportId']));
  yield showSuccess('Forced page edited');
}

function* editForcedPage({reportId, url, forcedPageId}) {
  const postData = {
    url
  };

  yield processRequest(
    'PUT',
    forcedPagesEndpoint({reportId, forcedPageId}),
    {
      *success(response = {}) {
        const {url} = response;
        if (url) {
          yield editForcedPageProcessResult();
        }
      },
      *error({message}) {
        yield showError(message);
      }
    },
    postData
  );
}
