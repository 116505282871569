exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Z8jCEiTkTjXSjSY6Daalf{margin-bottom:30px;min-height:250px;min-width:250px}._3Z8jCEiTkTjXSjSY6Daalf img{width:100%}._3eddQNfRCsZSCT_ZEJHV8Y,._3eddQNfRCsZSCT_ZEJHV8Y:visited{color:#15b272}._3BLkrI3WD0i6Wx_dg9c1Gn{cursor:pointer;border:1px solid #ddd;transition:all .2s}._3BLkrI3WD0i6Wx_dg9c1Gn:hover,._3BLkrI3WD0i6Wx_dg9c1Gn:active{border:1px solid #bbb}._1F7woxTC09B9n-FlmCpQ0j{padding-top:15px;position:relative;display:flex;overflow:hidden}._1F7woxTC09B9n-FlmCpQ0j h2{font-size:1.1em;text-overflow:ellipsis;overflow:hidden;color:#533be2;white-space:nowrap}._1F7woxTC09B9n-FlmCpQ0j h2 ._1JeIKr_pAJo5dT2q_s-X6d{float:left;margin-right:10px}._1F7woxTC09B9n-FlmCpQ0j h2 a,._1F7woxTC09B9n-FlmCpQ0j h2 a:visited{color:#533be2;transition:color .2s}._1F7woxTC09B9n-FlmCpQ0j h2 a:hover,._1F7woxTC09B9n-FlmCpQ0j h2 a:active{color:#2b189f}._1j8OK1aHf4cRA50hqTxklR{flex:1;overflow:hidden}._2LVdmbfHcAkCA2wlGiBvTR{min-width:25px;margin-top:-10px}.i5VV5EEX0X35Y2K7SKIDF{position:absolute;top:3px;right:0px}.i5VV5EEX0X35Y2K7SKIDF button{padding:0 !important}.rPIt9MND5IoNHGqFc8-cD{color:#777;margin:5px auto 0}._3gNRAvVkyDgHyhgZQFKW6e a{color:#df1616 !important}", ""]);

// exports
exports.locals = {
	"pageCard": "_3Z8jCEiTkTjXSjSY6Daalf",
	"pageCarda": "_3eddQNfRCsZSCT_ZEJHV8Y",
	"screenshot": "_3BLkrI3WD0i6Wx_dg9c1Gn",
	"caption": "_1F7woxTC09B9n-FlmCpQ0j",
	"spinner": "_1JeIKr_pAJo5dT2q_s-X6d",
	"captionLeft": "_1j8OK1aHf4cRA50hqTxklR",
	"captionRight": "_2LVdmbfHcAkCA2wlGiBvTR",
	"settingsBtn": "i5VV5EEX0X35Y2K7SKIDF",
	"time": "rPIt9MND5IoNHGqFc8-cD",
	"actionPage": "_3gNRAvVkyDgHyhgZQFKW6e"
};