exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3aoPUa1LHY9Las5c2N8qoo{min-width:0;position:relative;word-break:keep-all;padding:0px 20px}._3aoPUa1LHY9Las5c2N8qoo.YISx-ikDMwH94CEYODBPG{border-right:1px solid #cecece}._3aoPUa1LHY9Las5c2N8qoo._2PIEyhNgJTWi4GmYfWSYb6 ._1UKeoFFssI65ewIdaiRQ6n{color:#533be2}._3aoPUa1LHY9Las5c2N8qoo._2PIEyhNgJTWi4GmYfWSYb6 ._1UKeoFFssI65ewIdaiRQ6n:after{left:0;right:0}._1UKeoFFssI65ewIdaiRQ6n{position:relative;cursor:pointer;white-space:nowrap;display:block;padding:5px 2px;text-align:center;text-transform:uppercase;color:rgba(3,3,3,.5);transition:color 200ms}._1UKeoFFssI65ewIdaiRQ6n:after{content:\" \";position:absolute;bottom:0;left:51%;right:50%;height:3px;background:#533be2;display:block;transition:left 200ms,right 200ms}._1UKeoFFssI65ewIdaiRQ6n:hover:after,._1UKeoFFssI65ewIdaiRQ6n:active:after,._1UKeoFFssI65ewIdaiRQ6n:focus:after{left:0;right:0}.DsL1KE3zLWOQzIYb3b26w{position:absolute;top:17px;right:0px;cursor:pointer}._2UNJn19XJKf5vSLF3dkyEI{visibility:hidden}._1yDLSVhQVMUCh3lDhpqS3v{margin-left:auto}", ""]);

// exports
exports.locals = {
	"tab": "_3aoPUa1LHY9Las5c2N8qoo",
	"separator": "YISx-ikDMwH94CEYODBPG",
	"active": "_2PIEyhNgJTWi4GmYfWSYb6",
	"tabHeader": "_1UKeoFFssI65ewIdaiRQ6n",
	"icon": "DsL1KE3zLWOQzIYb3b26w",
	"hidden": "_2UNJn19XJKf5vSLF3dkyEI",
	"alignRight": "_1yDLSVhQVMUCh3lDhpqS3v"
};