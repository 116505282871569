import {take, select, cancel, put, takeLatest} from 'redux-saga/effects';
import {processResponse} from 'utils/saga/fetchUtils';
import fetch from 'utils/saga/fetch';
import {uploadBrandingLogoEndpoint} from 'modules/accountOptions/accountOptionUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {showSuccess, showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';
import {getBlobAsBase64} from 'utils/blobUtils';
import {
  UPLOAD_BRANDING_LOGO,
  uploadBrandingLogoComplete,
  fetchAccountOptions
} from 'modules/accountOptions';

export default function* uploadBrandingLogoSaga() {
  yield takeLatest(UPLOAD_BRANDING_LOGO, uploadBrandingLogo);
}

function* uploadBrandingLogo({blob}) {
  const accountId = yield select(accountIdSelector);
  const url = uploadBrandingLogoEndpoint({accountId});
  const base64 = yield getBlobAsBase64(blob);

  if (!base64) {
    yield put(showError(couldNot('upload image')));
    return;
  }

  const response = yield fetch(url, {
    method: 'PUT',
    body: base64,
    headers: {
      'Content-Type': blob.type
    }
  });

  yield processResponse(response, {
    success: function*(data) {
      yield put(uploadBrandingLogoComplete());

      // we need to get the account option we just saved
      // TODO get backend to send option
      yield put(fetchAccountOptions(accountId));

      yield showSuccess('Brand logo saved');
    },
    error: function*() {
      yield put(uploadBrandingLogoComplete());
    },
    errorMessage: couldNot('save brand logo')
  });
}
