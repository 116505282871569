import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './buttonSwitcher.scss';

// buttons is a simple object of key, label.
// selected is the key
export default function ButtonSwitcher({buttons, selected, onChange, hidden = []}) {
  return (
    <div className={styles.buttonSwitcher}>
      {_.map(buttons, (label, key) => {
        const classes = cx(styles.button, {
          [styles.selected]: selected === key
        });

        if (hidden.includes(key)) return null;

        return (
          <button type="button" key={key} className={classes} onClick={() => onChange(key)}>
            {label}
          </button>
        );
      })}
    </div>
  );
}
