import {getLastXMonths} from 'utils/dateTimeUtils';

const booleanOptions = [
  {value: null, label: 'Select...'},
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

var timeOptions = [{value: null, label: 'Select...'}];
timeOptions = timeOptions.concat(
  getLastXMonths(12).map(month => {
    return {
      value: `${month.month}-${month.year}`,
      label: month.str
    };
  })
);

const sortOptions = [
  {value: 'lastTested', label: 'Last tested'},
  {value: 'newest', label: 'Newest'},
  {value: 'oldest', label: 'Oldest'}
];

export const defaultFilters = [
  {field: 'userId', name: 'Creator', type: 'user', value: ''},
  {
    field: 'isScheduled',
    name: 'Scheduled',
    type: 'select',
    options: booleanOptions
  },
  {field: 'created', name: 'Created', type: 'select', options: timeOptions},
  {
    field: 'sort',
    name: 'Sort',
    type: 'select',
    options: sortOptions,
    value: 'lastTested'
  }
];

export const validFilters = [
  ...defaultFilters,
  {field: 'report.lastRun', name: 'Last run', type: 'dateTime', value: ''}
];

export const toKeyValuePairs = filters =>
  filters.reduce((obj, filter) => {
    obj[filter.field] = filter.value;
    return obj;
  }, {});
