exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2DMp1boDjyJtvmiVPOAAeZ{width:100%;box-sizing:border-box}._2DMp1boDjyJtvmiVPOAAeZ tr{border-bottom:1px solid rgba(255,255,255,.1)}._2DMp1boDjyJtvmiVPOAAeZ tr:last-of-type{border-bottom:0}._2tuAiOq8GnH_BaCVRgEQzD{width:50px;padding:5px 10px 5px 0}._2tuAiOq8GnH_BaCVRgEQzD img{width:100%;height:auto;margin:0}", ""]);

// exports
exports.locals = {
	"students": "_2DMp1boDjyJtvmiVPOAAeZ",
	"icon": "_2tuAiOq8GnH_BaCVRgEQzD"
};