import {connect} from 'react-redux';
import React from 'react';
import styles from './explorerHeader.scss';
import ExplorerSearch from './explorerSearch.js';
import ExplorerSummaries from './explorerSummaries.js';
import {triggerExportExplorer} from 'modules/reports';
import Flex from 'components/layout/flex';
import Button from 'components/button';
import {showComponentDetail} from 'modules/tests';
import {fromJS} from 'immutable';
import {isPrintMode} from 'modules/filters/filterUtils';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';

export class ExplorerHeader extends React.Component {
  handleExportExplorer = () => {
    const {filter, params, config} = this.props;
    const {reportId, testId} = params;

    this.props.triggerExportExplorer(reportId, testId, config.resultId, filter);
  };

  handleClickDetail = () => {
    const {showComponentDetail, config} = this.props;

    showComponentDetail(fromJS(config));
  };

  render() {
    const {filter, setFilterParams, viewingContext} = this.props;

    if (isPrintMode(filter)) {
      return null;
    }

    const summaryValues = this.props.result.summaries;
    const {views, search, display} = this.props.config;
    let {summaries} = this.props.config;

    if ((!summaries || !summaries.length) && (!views || views.length < 2)) {
      return null;
    }

    // Remove numRows from the summaries
    // TODO: eventually this shouldn't be sent by the backend!
    summaries =
      summaries &&
      summaries.filter(summary => {
        return summary.data !== 'numRows';
      });

    if (this.props.isArchiveReport) return null;

    return (
      <div className={styles.header}>
        <div className={styles.filters}>
          <ExplorerSearch search={search} filter={filter} setFilterParams={setFilterParams} />
        </div>
        <Flex container className={styles.summaries}>
          <ExplorerSummaries summaries={summaries} summaryValues={summaryValues} filter={filter} />
          {display !== 'detail' && viewingContext !== 'task' && (
            <Button
              plain
              icon="fas fa-expand-arrows-alt"
              onClick={this.handleClickDetail}
              className={styles.detailsButton}
            />
          )}
          <a className={styles.link} onClick={this.handleExportExplorer}>
            Export
          </a>
        </Flex>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    isArchiveReport: isArchiveActiveSelector(state, props)
  }),
  {triggerExportExplorer, showComponentDetail}
)(ExplorerHeader);
