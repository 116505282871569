import {take, call, put, cancel, fork, takeEvery} from 'redux-saga/effects';
import {FILTER_BY_STRING, FILTER_ADDED, setFilterParams} from 'modules/filters';
import FilterModal from 'modals/filterModal/filterModal';
import {showInModal} from 'modules/modal';

export default function* filterByStringSaga() {
  yield takeEvery(FILTER_BY_STRING, filterByString);
}

function* filterByString(action) {
  const {filter} = action;

  yield put(
    showInModal(FilterModal, {
      title: 'Add filter',
      content: 'Write a thing to filter by',
      name: 'testFiltersFormModal',
      filter
    })
  );

  // FilterModal emits a FILTER_ADDED on submit
  var filterAction = yield take(FILTER_ADDED);

  yield put(setFilterParams(filterAction.filter));
}
