exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2sQihQkb_iZeX21AuYAeLc{margin-bottom:40px;background-color:#fff;box-shadow:0 0 36px #d8dde5;border-radius:4px}._2_oVRmcMMnXuv5zwqQZOig{background-color:#f6f7f9;padding:10px 20px;width:100%;display:flex}.eq38xqWSQVCuggwCGggD5{flex:10}.sVNdTAM6RQRu4IK0exA48{flex:2;text-align:right}.WWPwQJiH88fxb5SIjitYm{font-size:1.5em;color:#030303}._2waT5chvpc4OeQ6N4BRjcU{color:gray;text-transform:uppercase;font-size:.8em;display:block}._3BTi3rqWnVZHGpkUNAzB_a{padding:20px;width:100%;margin:0 auto;display:flex}._3ZLbmgy-FjBvVNwhtX1Kxb{flex:6;border-right:1px solid #ddd;padding-right:20px}._2aNqMD_FssZfExPAnrFLzi{flex:6;padding-left:20px}._1yxS3jWK1ztDNhJKFhx47B{margin-right:10px}._3pU3KSCEIA6CGgXuRaAFY4{margin-bottom:20px;font-size:1.1rem;font-weight:400;line-height:28px;color:#030303}.PSrxyUz6JojqvfqRliPKt{margin-right:10px;margin-bottom:10px}._2wqLczMPLNkEX6IyuIoXMG{width:100%;margin-bottom:1rem;color:#575757;border-collapse:collapse}._2wqLczMPLNkEX6IyuIoXMG th{color:#575757;background-color:#f6f7f9;text-align:left;border-color:#e4e7ed;border-top:none;border-bottom:none;font-weight:600;font-size:.9em;text-transform:uppercase;vertical-align:bottom;padding:10px}._2wqLczMPLNkEX6IyuIoXMG td{padding:10px;border-top:1px solid #e4e7ed;vertical-align:middle}._3m9SS5z5sOi22bW5eo_Cir{width:105px}.SaIUTQfrAkmHb7Z3-iHFv{border-right:0 !important;padding-right:0}._4_7DGYqDGsuxPLT_BD8RS{margin-left:8px;color:#777;opacity:.6;font-size:.8em}._3e-sLzLdfG_IPzwCmPzhxB{color:#707070}@media(max-width: 1200px){._3BTi3rqWnVZHGpkUNAzB_a{display:block;margin-bottom:20px}._3ZLbmgy-FjBvVNwhtX1Kxb{border-right:0;padding-right:0}._2aNqMD_FssZfExPAnrFLzi{padding-left:0}}", ""]);

// exports
exports.locals = {
	"productGroup": "_2sQihQkb_iZeX21AuYAeLc",
	"headingBar": "_2_oVRmcMMnXuv5zwqQZOig",
	"headingLeft": "eq38xqWSQVCuggwCGggD5",
	"headingRight": "sVNdTAM6RQRu4IK0exA48",
	"title": "WWPwQJiH88fxb5SIjitYm",
	"subTitle": "_2waT5chvpc4OeQ6N4BRjcU",
	"productBody": "_3BTi3rqWnVZHGpkUNAzB_a",
	"productBodyLeft": "_3ZLbmgy-FjBvVNwhtX1Kxb",
	"productBodyRight": "_2aNqMD_FssZfExPAnrFLzi",
	"headingIcon": "_1yxS3jWK1ztDNhJKFhx47B",
	"bodyHeader": "_3pU3KSCEIA6CGgXuRaAFY4",
	"pill": "PSrxyUz6JojqvfqRliPKt",
	"table": "_2wqLczMPLNkEX6IyuIoXMG",
	"small": "_3m9SS5z5sOi22bW5eo_Cir",
	"noBorderRight": "SaIUTQfrAkmHb7Z3-iHFv",
	"helpInHeading": "_4_7DGYqDGsuxPLT_BD8RS",
	"lighterText": "_3e-sLzLdfG_IPzwCmPzhxB"
};