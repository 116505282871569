import PropTypes from 'prop-types';
import React from 'react';
import AffixHelper from 'components/overlay/affixHelper';
import HoverListener from 'components/events/hoverListener';
import styles from './tooltip.scss';
import cx from 'classnames';
import {capitalizeFirstLetter} from 'utils/stringUtils';

export default class AffixTooltip extends React.Component {
  static defaultProps = {
    // TODO: rename to preferSide for consistency
    side: 'top',
    padding: 0
  };

  renderChild = isHovering => {
    const {children, side, text, padding, wide} = this.props;

    // disallow left/right because it doesn't handle moving around very well
    // const preferSide = side === 'left' || side === 'right' ? 'top' : side;

    return (
      <AffixHelper
        padding={padding}
        align="center"
        show={isHovering}
        component={children}
        preferSide={side}
      >
        <Tooltip side={side} wide={wide}>
          {text}
        </Tooltip>
      </AffixHelper>
    );
  };

  render() {
    const {text, children, className, useMouseEnterLeave} = this.props;

    if (!text) {
      return children;
    }

    return (
      <HoverListener useMouseEnterLeave={useMouseEnterLeave} className={className}>
        {this.renderChild}
      </HoverListener>
    );
  }
}

export function Tooltip({children, side, wide}) {
  const classes = cx(styles.tooltip, styles[`side${capitalizeFirstLetter(side)}`], {
    [styles.wide]: wide
  });

  return (
    <div role="tooltip" className={classes}>
      {/* <div className={styles.tooltipArrow} /> */}
      <div className={styles.tooltipInner}>{children}</div>
    </div>
  );
}
