import React from 'react';
import {connect} from 'react-redux';
import {addMessage} from 'modules/alerts/alerts';
import {betterPromises} from 'utils/promiseUtils';
import {assignedTagsComponentSelector} from 'modules/tags/selectors';
import {requestAssignedTags, assignTags, unassignTags} from 'modules/tags/actions';

class AssignedTags extends React.Component {
  state = {
    hasChanged: false
  };

  componentDidMount() {
    const {entityType, entityId, entityTags, requestingEntityTags} = this.props;

    // request new assigned tags on init, and every time entityTags is not an array
    const shouldRequest =
      (!requestingEntityTags.get('loaded') && !requestingEntityTags.get('loading')) ||
      !Array.isArray(entityTags);

    if (shouldRequest) {
      this.props.requestAssignedTags(entityType, entityId);
    }
  }

  handleEntityTagsChanged = newEntityTags => {
    this.setState({hasChanged: this.hasChanged(newEntityTags)});
  };

  setAssignedEntitiesTags = (newEntityTags, onComplete) => {
    const {addMessage} = this.props;
    const promises = betterPromises(2);
    const [deletedTags, addedTags] = this.getChanged(newEntityTags);

    // because we call 2 endpoints, we need to know when they both finish
    Promise.all(promises.map(p => p.promise)).then(([addSuccess, deleteSuccess]) => {
      // this will get called when both POST and DELETE endpoint have complete

      // this is very unlikely
      if (deleteSuccess !== addSuccess) {
        // one of the requests failed and one passed. Why oh why lord. Why u do dis.
        addMessage(
          'error',
          'Failed to correctly save all the website tags. Please refresh the page and try again'
        );
      } else {
        // addSuccess and deleteSuccess are equal, so we only need to check one of them to get the other
        if (addSuccess) {
          // addMessage('success', 'Successfully saved website tags');
        } else {
          addMessage('error', 'Failed to save website tags');
        }
        onComplete && onComplete(addSuccess);
      }
    });

    this.toggleAssignReport(this.props.assignTags, addedTags, promises[0]);
    this.toggleAssignReport(this.props.unassignTags, deletedTags, promises[1]);
  };

  toggleAssignReport(fn, tags, promise) {
    const {entityType, entityId} = this.props;

    if (!tags.length) {
      promise.resolve(true);
    } else {
      fn(entityType, entityId, tags, success => {
        promise.resolve(success);
      });
    }
  }

  getChanged(newEntityTags = []) {
    const assignedTags = this.props.entityTags || [];

    // find assignedTags that are not in newEntityTags
    const deletedTags = assignedTags.filter(reportTagId => {
      return !newEntityTags.includes(reportTagId);
    });

    // find newEntityTags that are not in assignedTags
    const addedTags = newEntityTags.filter(reportTagId => {
      return !assignedTags.includes(reportTagId);
    });

    return [deletedTags, addedTags];
  }

  hasChanged(newEntityTags) {
    const [deletedTags, addedTags] = this.getChanged(newEntityTags);

    return deletedTags.length > 0 || addedTags.length > 0;
  }

  render() {
    const {assigningEntityTags, requestingEntityTags, entityTags, children} = this.props;

    return children({
      setAssignedEntitiesTags: this.setAssignedEntitiesTags,
      isLoadingEntityTags: !entityTags || requestingEntityTags.get('loading'),
      isAssigningEntityTags: assigningEntityTags.get('loading'),
      onEntityTagsChanged: this.handleEntityTagsChanged,
      hasEntityTagsChanged: this.state.hasChanged
    });
  }
}

export default connect(
  assignedTagsComponentSelector,
  {requestAssignedTags, assignTags, unassignTags, addMessage}
)(AssignedTags);
