import React from 'react';
import styles from '../topic.scss';
import Progress from 'components/progress';
import Icon from 'components/font-awesome';

export default class TopicHeader extends React.Component {
  render() {
    const {name, icon} = this.props;

    return (
      <div className={styles.topicHeading}>
        <Icon name={icon || 'exclamation-triangle'} className={styles.topicIcon} />
        <div className={styles.topicTitle}>{name}</div>
      </div>
    );
  }
}
