import {createSelector} from 'reselect';

export const npsState = state => state.nps;

export const isIdleSelector = createSelector(
  npsState,
  nps => nps.get('isIdle')
);
export const npsDueSelector = createSelector(
  npsState,
  nps => nps.get('npsDue')
);
export const isOpenSelector = createSelector(
  npsState,
  nps => nps.get('isOpen')
);
export const npsActionSelector = createSelector(
  npsState,
  nps => nps.get('action')
);

export const npsSelector = createSelector(
  isOpenSelector,
  isOpen => ({
    isOpen
  })
);
