import React from 'react';
import cx from 'classnames';
import styles from './inspectorScore.scss';
import Score from 'components/score';

function InspectorScore(props) {
  return <Score {...props} hideLabel />;
}

export default InspectorScore;
