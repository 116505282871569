import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import Status from 'components/status';
import AddReportButton from 'modules/reports/containers/addReportButton';
import Center from 'components/center';
import styles from './orderCompleteModal.scss';

export default class OrderCompleteModal extends React.Component {
  static defaultProps = {
    firstSubscription: true
  };

  render() {
    const {firstSubscription} = this.props;

    return (
      <ModalContent>
        <Status appearance="good" className={styles.status}>
          <p id="orderCompleteMessage">Your subscription is now active!</p>
          {firstSubscription && <p>Let's get started by adding your first website.</p>}
        </Status>
        <Center>
          <AddReportButton className={styles.addBtn} />
        </Center>
      </ModalContent>
    );
  }
}
