import React from 'react';
import Topic from 'components/topic';
import Status from 'components/status';

class Actions extends React.Component {
  render() {
    const {result} = this.props;
    if (!result || !result.length) {
      return <Status appearance="good">No actions found</Status>;
    }

    return result.map(topic => <Topic key={topic.topicId} {...topic} />);
  }
}

export default Actions;
