import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {isRetestingSelector, reportsSelector} from 'modules/reports/reportSelectors';
import {isVirtualUserSelector} from 'modules/auth/authSelectors';
import RetestButton from '../components/retestButton';
import {requestRetestReport} from 'modules/reports';
import {showUpgradeModal} from 'modules/onboarding';

function ConnectedRetestButton({onClick, ...props}) {
  return <RetestButton {...props} onClick={event => onClick(event, props)} />;
}

ConnectedRetestButton.propTypes = {
  reportId: PropTypes.string.isRequired
};

export default connect(
  createSelector(
    isVirtualUserSelector,
    isRetestingSelector,
    (_, props) => props.reportId,
    reportsSelector,
    (isVirtual, isRetesting, reportId, reports) => {
      return {
        isVirtual,
        isRetesting,
        reportType: reports.getIn([parseInt(reportId, 10), 'reportType'])
      };
    }
  ),
  dispatch => ({
    onClick: (event, {isVirtual, reportId, reportType}) => {
      if (isVirtual) {
        dispatch(showUpgradeModal());
      } else {
        dispatch(requestRetestReport(reportId, reportType));
      }
    }
  })
)(ConnectedRetestButton);
