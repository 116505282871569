import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {userIdSelector, userSelector} from 'modules/auth/authSelectors';
import {usersSelector, usersOrderedByName, usersStateSelector} from 'modules/users/userSelectors';
import {reportIdSelector} from 'modules/reports/reportSelectors';
import {getProps} from 'utils/immutableUtils';

export const reportPermissionsSelector = state => state.reportPermissions.get('data');

export const permissionsStateSelector = state =>
  getProps(state.reportPermissions, ['updating', 'success']);

export const permissionsForUsersSelector = createSelector(
  reportPermissionsSelector,
  reportIdSelector,
  usersSelector,
  (permissions, reportId, users) => {
    if (!permissions) {
      return Map();
    }

    return permissions.reduce((reportPermissions, permission, permissionKey) => {
      const pReportId = permission.get('reportId');
      const userGroupId = permission.get('userGroupId');

      // Ensure the report Id matches that of the permission to ensure it is for this site.
      // Then ensure we do not add any users that are not available in the users entity.
      if (pReportId === reportId && (users.has(userGroupId) || userGroupId === 'g1')) {
        return reportPermissions.set(userGroupId, permission);
      }

      return reportPermissions;
    }, Map());
  }
);

export const reportPermissionsPageSelector = createSelector(
  usersOrderedByName,
  reportIdSelector,
  permissionsForUsersSelector,
  userIdSelector,
  permissionsStateSelector,
  usersStateSelector,
  (users, reportId, reportPermissions, currentUserId, permissionsState, usersStateSelector) => {
    return {
      users: users && users.toJS(),
      reportId,
      reportPermissions,
      currentUserId,
      permissionsState,
      isFetchingUsers: !!usersStateSelector.get('isFetchingUsers')
    };
  }
);
