exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3T2pU_VrCHdTvZC7URttrV{overflow:auto;height:100vh;background-color:#f0f2f5}._2zQmKfWnupat1FWhF5GOCv{max-width:800px;background-color:#fff;margin:0 auto;display:block;margin-top:20px;margin-bottom:20px;padding:40px;box-shadow:0 0 36px #d8dde5;border-radius:4px}@media print{._2zQmKfWnupat1FWhF5GOCv{border-radius:0;box-shadow:0;margin-bottom:0;margin-top:0}._3T2pU_VrCHdTvZC7URttrV{height:auto}}", ""]);

// exports
exports.locals = {
	"wrapper": "_3T2pU_VrCHdTvZC7URttrV",
	"dataWrapper": "_2zQmKfWnupat1FWhF5GOCv"
};