exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2KfVvFtIaRRBBwIwTbr-m9{overflow:hidden;width:100%;flex:1 1 300px;padding:0;margin:8px;flex-wrap:wrap}._2xP6NIma3jUv1UC85R4USh{text-align:center}._2Sz_fI9CRz9V5GzYbv20v4{align-self:center}@media(max-width: 425px){._2KfVvFtIaRRBBwIwTbr-m9{padding:4px 0px;margin:0 -1px}}@media print{._2KfVvFtIaRRBBwIwTbr-m9{position:relative;display:block}}", ""]);

// exports
exports.locals = {
	"column": "_2KfVvFtIaRRBBwIwTbr-m9",
	"center": "_2xP6NIma3jUv1UC85R4USh",
	"middle": "_2Sz_fI9CRz9V5GzYbv20v4"
};