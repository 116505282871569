exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "pre.v_O40YDUWK0mWRxw6JlJO{border:0;font-family:Menlo,Monaco,Consolas,\"Courier New\",monospace;padding:10px;white-space:pre-wrap;background:#f59999;color:#b11111;text-align:left;word-break:break-word}._28G_0P7wi0RaQWtt5UZZaL{text-align:left;padding:0 0 10px 20px;margin:0;list-style-type:square}._3BMI345B91cGvlpMaCQi15{padding:5px 0}", ""]);

// exports
exports.locals = {
	"source": "v_O40YDUWK0mWRxw6JlJO",
	"trace": "_28G_0P7wi0RaQWtt5UZZaL",
	"tracePart": "_3BMI345B91cGvlpMaCQi15"
};