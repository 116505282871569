import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {createPropOrParamSelector} from 'modules/location/locationSelectors';

const trendsStateSelector = state => state.trends;

const trendScreenIdSelector = createPropOrParamSelector('trendScreenId');

export const trendScreensSelector = createSelector(
  trendsStateSelector,
  trends => ({
    trendScreens: toJS(trends.get('screens'))
  })
);

export const trendScreenSelector = createSelector(
  trendsStateSelector,
  trendScreensSelector,
  trendScreenIdSelector,
  (trends, {trendScreens}, trendScreenId) => {
    return {
      loading: trends.get('loading'),
      trendScreen: trendScreens[trendScreenId]
    };
  }
);

export const trendSourcesSelector = createSelector(
  trendsStateSelector,
  // optional sources prop filters collection
  (state, props) => props.sources,
  (trends, sources) => {
    let trendSources = trends.get('sources');

    if (sources) {
      trendSources = sources
        .map(source => toJS(trendSources.get(source.trendSourceId)))
        .filter(Boolean);
    } else {
      trendSources = toJS(trendSources.toList());
    }

    return {trendSources};
  }
);

export const trendDatesSelector = createSelector(
  trendsStateSelector,
  trends => ({
    startTime: trends.get('startTime'),
    endTime: trends.get('endTime')
  })
);

export const accuracySelector = createSelector(
  trendsStateSelector,
  trends => ({
    accuracy: trends.get('accuracy')
  })
);

export const trendFiltersSelector = createSelector(
  trendDatesSelector,
  accuracySelector,
  (dates, accuracy) => ({...dates, ...accuracy})
);
