import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Icon from 'components/font-awesome';
import Tooltip from 'components/tooltip';
import styles from './decisionCount.scss';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import {filterSelector} from 'modules/filters/filterSelectors';
import cx from 'classnames';

export class DecisionCount extends React.Component {
  // @todo Can't we write these as one function?
  clickHandlerApproved = e => {
    const {push, filter} = this.props;
    filter['decisions-state'] = 'done';
    push(testPath, {tab: 'Decisions', filter}, ['reportId', 'testId']);
  };

  clickHandlerIgnored = e => {
    const {push, filter} = this.props;
    filter['decisions-state'] = 'ignored';
    push(testPath, {tab: 'Decisions', filter}, ['reportId', 'testId']);
  };

  renderCount(count, text, icon, handler) {
    if (!count) return null;

    return (
      <span onClick={handler}>
        <Tooltip text={text}>
          <span>
            <Icon name={icon} /> {count}{' '}
          </span>
        </Tooltip>
      </span>
    );
  }

  render() {
    const {result, config} = this.props;
    const {withScore} = config;

    if (_.isNull(result)) return null;

    const classes = cx(styles.wrapper, {
      [styles.withScore]: withScore
    });

    return (
      <span className={classes}>
        {this.renderCount(
          result.approved,
          'Approved manually',
          'fas fa-check-circle',
          this.clickHandlerApproved
        )}
        {this.renderCount(
          result.ignored,
          'Ignored manually',
          'fas fa-times-circle',
          this.clickHandlerIgnored
        )}
      </span>
    );
  }
}

DecisionCount.handlesLoading = true;

export default connect(
  filterSelector,
  {push}
)(DecisionCount);
