import * as Consts from './constants';
import {push} from 'modules/location';
import {tagSettingsPath, tagsSettingsPath} from 'modules/settings/settingsUtils';
import {showInModal} from 'modules/modal';
import EditTagModal from 'modules/tags/components/editTagModal';

export function viewTag({tagId}) {
  return push(tagSettingsPath, {tagId}, ['accountId'], false);
}

export function viewTags() {
  return push(tagsSettingsPath, {}, ['accountId'], false);
}

export function viewEditTag(tagId) {
  return showInModal(EditTagModal, {tagId});
}

export function viewDeleteTag(tagId) {
  return {type: Consts.VIEW_DELETE_TAG, tagId};
}

export function requestTags() {
  return {type: Consts.REQUEST_TAGS};
}

export function requestTagsComplete(error, pagedTags) {
  return {type: Consts.REQUEST_TAGS_COMPLETE, error, pagedTags};
}

export function requestTagsSummary() {
  return {type: Consts.REQUEST_TAGS_SUMMARY};
}

export function requestTagsSummaryComplete(error, tagsSummary) {
  return {type: Consts.REQUEST_TAGS_SUMMARY_COMPLETE, error, tagsSummary};
}

export function createTag({label}, onSuccess) {
  return {type: Consts.CREATE_TAG, label, onSuccess};
}

export function createTagComplete(error, tag) {
  return {type: Consts.CREATE_TAG_COMPLETE, error, tag};
}

export function requestAssignedTags(entityType, entityId) {
  return {type: Consts.REQUEST_ASSIGNED_TAGS, entityType, entityId};
}

export function requestAssignedTagsComplete(error) {
  return {type: Consts.REQUEST_ASSIGNED_TAGS_COMPLETE, error};
}

export function requestAssignedEntities(tagId, entityType) {
  return {type: Consts.REQUEST_ASSIGNED_ENTITIES, tagId, entityType};
}

export function requestAssignedEntitiesComplete(error, tagId, entityType, assignedEntities) {
  return {
    type: Consts.REQUEST_ASSIGNED_ENTITIES_COMPLETE,
    error,
    tagId,
    entityType,
    assignedEntities
  };
}

export function assignTags(entityType, entityId, tagIds, callback) {
  return {type: Consts.ASSIGN_TAGS, entityType, entityId, tagIds, callback};
}

export function assignTagsComplete(error, entityType, entityId, tagIds) {
  return {type: Consts.ASSIGN_TAGS_COMPLETE, error, entityType, entityId, tagIds};
}

export function unassignTags(entityType, entityId, tagIds, callback) {
  return {type: Consts.UNASSIGN_TAGS, entityType, entityId, tagIds, callback};
}

export function unassignTagsComplete(error, entityType, entityId, tagIds) {
  return {type: Consts.UNASSIGN_TAGS_COMPLETE, error, entityType, entityId, tagIds};
}

export function editTag(tagId, label, color) {
  return {type: Consts.EDIT_TAG, tagId, label, color};
}

export function editTagComplete(error, tagId, label, color) {
  return {type: Consts.EDIT_TAG_COMPLETE, error, tagId, label, color};
}

// we don't need the associated deleteTag action because that's handled by generateDeleteSaga
export function deleteTagComplete(tagId) {
  return {type: Consts.DELETE_TAG_COMPLETE, tagId};
}
