export {default} from './configOptionsReducer';

export const SET_GLOBAL_CONFIG_OPTION = 'configOptions:SET_GLOBAL_CONFIG_OPTION';
export function setGlobalConfigOption(globalName, value) {
  return {type: SET_GLOBAL_CONFIG_OPTION, globalName, value};
}

export const SET_CONFIG_OPTION = 'configOptions:SET_CONFIG_OPTION';
export function setConfigOption(name, value, testId = null, actionId = 0) {
  return {type: SET_CONFIG_OPTION, name, value, testId, actionId};
}

export const SET_CONFIG_OPTIONS = 'configOptions:SET_CONFIG_OPTIONS';
export function setConfigOptions(options) {
  return {type: SET_CONFIG_OPTIONS, options};
}

export const REQUEST_SET_CONFIG_OPTIONS = 'configOptions:REQUEST_SET_CONFIG_OPTIONS';
export function requestSetConfigOptions(options) {
  return {type: REQUEST_SET_CONFIG_OPTIONS, options};
}

export const SET_CONFIG_OPTIONS_COMPLETE = 'configOptions:SET_CONFIG_OPTIONS_COMPLETE';
export function setConfigOptionsComplete(options) {
  return {type: SET_CONFIG_OPTIONS_COMPLETE, options};
}

export const SET_SUCCESS_MESSAGE = 'configOptions:SET_SUCCESS_MESSAGE';
export function setSuccessMessage(value) {
  return {type: SET_SUCCESS_MESSAGE, value};
}
