exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".njgtLdveyDLKkSsMZ4K12{font-size:40px}._1luNQaaWSK-R29Vy3aqSlK{text-align:center}._2BhNVP3T-v0SkpBo-p_dQ5{color:green}._3fQu24m_0sot8-YHyqHPmq{color:darkred}._1NxAima9wPfnnriGkt-GsA{font-size:25px}", ""]);

// exports
exports.locals = {
	"iconMedia": "njgtLdveyDLKkSsMZ4K12",
	"center": "_1luNQaaWSK-R29Vy3aqSlK",
	"greenColor": "_2BhNVP3T-v0SkpBo-p_dQ5",
	"redColor": "_3fQu24m_0sot8-YHyqHPmq",
	"small": "_1NxAima9wPfnnriGkt-GsA"
};