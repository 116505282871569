import React from 'react';
import PropTypes from 'prop-types';
import styles from './teaser.scss';
import Icon from 'components/font-awesome';
import {getSalesUrl, contactSales} from './utils';
import ExternalLink from 'modules/location/externalLink';
import {useParams} from 'react-router';

const ICON_TYPES = ['fa-exclamation-circle'];

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  icon: PropTypes.oneOf(ICON_TYPES),
  width: PropTypes.number
};

const Teaser = ({children, icon, width, colCount}) => {
  const {accountId} = useParams();

  return (
    <tr className={styles.teaser} width={`${width}px`} onClick={() => contactSales(accountId)}>
      <td width={`${width}px`} colSpan={colCount}>
        <h3>
          <Icon name={`fa ${icon}`} />
          Only a sample of this website was tested
        </h3>
        <p>
          <ExternalLink href={getSalesUrl(accountId)}>Talk to us about testing more</ExternalLink>
        </p>
      </td>
    </tr>
  );
};

Teaser.propTypes = propTypes;
Teaser.defaultProps = {
  icon: ICON_TYPES[0]
};

export default Teaser;
