exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._29xoydc5iE5iH3jCGebHyq{margin:10px 40px}._2_Oqsg8a5vXE57rSSlUQN9{margin-bottom:30px}._2_Oqsg8a5vXE57rSSlUQN9 h1{font-size:24px;margin-bottom:10px}._3iu-1iimKIG6456fliakYw{display:flex;align-items:flex-start;margin-bottom:40px}._3iu-1iimKIG6456fliakYw div{width:50%;margin-right:10px}._3iu-1iimKIG6456fliakYw div:last-of-type{margin-right:0}._3iu-1iimKIG6456fliakYw div img{width:100%;height:auto;border:1px solid #ddd;box-shadow:0 0 20px rgba(0,0,0,.2)}", ""]);

// exports
exports.locals = {
	"wrapper": "_29xoydc5iE5iH3jCGebHyq",
	"header": "_2_Oqsg8a5vXE57rSSlUQN9",
	"screenshots": "_3iu-1iimKIG6456fliakYw"
};