import React, {useState} from 'react';
import Grid from 'components/grid/grid';
import Card from 'components/card/card';
import Flex from 'components/layout/flex';
import Switch from 'components/switch/switch';
import SwitchOption from 'components/switch/switchOption';
import PRESETS from './presets.json';

const requireIcon = require.context('./images', false, /\.jpg$/);

export default function PresetEngines({added, onClick}) {
  const [tab, setTab] = useState('GB');

  return (
    <React.Fragment>
      <Flex container column>
        <Flex bottom={15}>
          <Switch>
            <SwitchOption active={!tab} onClick={() => setTab(null)}>
              All countries
            </SwitchOption>
            <SwitchOption active={tab === 'US'} onClick={() => setTab('US')}>
              US
            </SwitchOption>
            <SwitchOption active={tab === 'GB'} onClick={() => setTab('GB')}>
              UK
            </SwitchOption>
          </Switch>
        </Flex>
        <Flex>
          <Grid small carousel>
            {PRESETS.filter(preset => !added.includes(preset.location))
              .filter(preset => (!tab ? true : preset.options.countryCode === tab))
              .map(preset => (
                <Card
                  small
                  square
                  key={preset.location}
                  title={preset.location}
                  image={getPresetIcon(preset)}
                  onClick={() => onClick(preset)}
                />
              ))}
          </Grid>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}

function getPresetIcon({location, options}) {
  return requireIcon(
    `./${location.replace(/ /g, '_').toLowerCase()}_${options.countryCode.toLowerCase()}.jpg`
  );
}
