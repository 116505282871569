exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2sVXjrtqA73xH0axfcgf7C{order:3}._2sVXjrtqA73xH0axfcgf7C img{max-width:280px;max-height:70px}@media(max-width: 959px){._2sVXjrtqA73xH0axfcgf7C{display:none}}@media print{._2sVXjrtqA73xH0axfcgf7C{display:block}}", ""]);

// exports
exports.locals = {
	"logo": "_2sVXjrtqA73xH0axfcgf7C"
};