import React from 'react';
import {connect} from 'react-redux';
import {reportRequiresUpgradeSelector} from 'modules/reports/reportSelectors';
import StatusComponent from 'components/status';
import {push} from 'modules/location';
import Button from 'components/button';
import WithBreadcrumbHeader from 'modules/app/components/withBreadcrumbHeader';
import {retestReport} from 'modules/reports';
import {selectArchive} from 'modules/archives';
import {fetchReport} from 'modules/reports/reports';
import {ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';
import {ReportGandalf} from 'components/gandalf';

export class ReportUpgradeChecker extends React.Component {
  constructor(props) {
    super(props);
    this.intervalTimer = null;
  }

  componentDidMount() {
    // If we are retesting and come back onto the report, start the interval timer
    if (this.props.isRetesting && this.isUpgradeNeeded(this.props)) {
      this.setIntervalTimer(this.props.reportId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.isUpgradeNeeded(nextProps)) {
      clearInterval(this.intervalTimer);
    } else if (
      nextProps.isRetesting &&
      this.isUpgradeNeeded(this.props) !== this.isUpgradeNeeded(nextProps)
    ) {
      this.setIntervalTimer(this.props.reportId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  isUpgradeNeeded = props => {
    const {reportUpgradeNeeded, viewingArchive} = props;
    return (
      !viewingArchive && reportUpgradeNeeded && reportUpgradeNeeded.get('isBreakingChange', false)
    );
  };

  onClickRetestReport = () => {
    const {reportId} = this.props;
    this.props.retestReport(reportId);
    this.setIntervalTimer(reportId);
  };

  setIntervalTimer = reportId => {
    clearInterval(this.intervalTimer);

    this.intervalTimer = window.setInterval(() => {
      this.props.fetchReport(reportId);
    }, 7000);
  };

  onClickViewLegacy = () => {
    const {lastArchiveId} = this.props;
    this.props.selectArchive(lastArchiveId);
  };

  render() {
    const {
      children,
      reportUpgradeNeeded,
      location,
      match,
      testId,
      reportId,
      push,
      lastArchiveId,
      isRetesting
    } = this.props;

    if (this.isUpgradeNeeded(this.props)) {
      const title = reportUpgradeNeeded.get('title');
      const message = reportUpgradeNeeded.get('message');
      const url = reportUpgradeNeeded.get('url');
      const retestPossible = reportUpgradeNeeded.get('retestPossible');
      return (
        <WithBreadcrumbHeader
          push={push}
          location={location}
          reportId={reportId}
          testId={testId}
          match={match}
        >
          <StatusComponent appearance={'info'}>
            <div>
              <h1>{title}</h1>
              <p>{message}</p>

              <div style={{marginTop: '25px'}}>
                {retestPossible && (
                  <span style={{marginRight: '20px'}}>
                    <ReportGandalf shallPass={ROLE_CONTRIBUTOR} reportId={reportId}>
                      <Button
                        primary
                        icon="redo-alt"
                        isLoading={isRetesting}
                        onClick={this.onClickRetestReport}
                      >
                        {isRetesting ? 'Retesting' : 'Retest now'}
                      </Button>
                    </ReportGandalf>
                  </span>
                )}
                {lastArchiveId && (
                  <span style={{marginRight: '20px'}}>
                    <Button icon="clock" onClick={this.onClickViewLegacy}>
                      View last report
                    </Button>
                  </span>
                )}
                {url && <ExternalLink href={url}>Learn more</ExternalLink>}
              </div>
            </div>
          </StatusComponent>
        </WithBreadcrumbHeader>
      );
    }

    return children;
  }
}

export default connect(
  reportRequiresUpgradeSelector,
  {push, retestReport, selectArchive, fetchReport}
)(ReportUpgradeChecker);
