import React from 'react';
import {connect} from 'react-redux';
import ReportSidebar from 'modules/reports/components/reportSidebar';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';

function WithReportSidebar({children, ...routeProps}) {
  const {match} = routeProps;
  return (
    <PageWithSidebar
      sidebar={<ReportSidebar testId={match.params.testId} params={match.params} {...routeProps} />}
    >
      {children}
    </PageWithSidebar>
  );
}

export default connect()(WithReportSidebar);
