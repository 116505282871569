import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import styles from './pill.scss';
import {formatGivenNumber} from 'utils/numberUtils';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default class Pill extends React.Component {
  static defaultProps = {
    narrow: true
  };

  // this function is only bound and called if `onDelete` exists
  // no need to check for it's existance
  onClickDelete = e => {
    // you want to stop the pill itself from being clicked
    e.preventDefault();
    e.stopPropagation();

    // if onDelete is passed, it is used
    // 90% of the time, children will be text. but it can be a component
    const {onDelete, url, children} = this.props;

    // pass useful props to the callback so the caller knows which pill was clicked
    onDelete({url, children});
  };

  renderPill() {
    const {
      children,
      appearance,
      onDelete,
      className,
      square,
      narrow,
      numeric,
      showZero,
      tiny,
      onClick,
      color,
      circle,
      ...props
    } = this.props;

    if (numeric && !showZero && !children) {
      return null;
    }

    const classes = cx(
      styles.label,
      styles[`appearance-${appearance}`],
      {
        // TODO: this should really be using localStyles decorator
        [styles.sq]: square,
        [styles.tiny]: tiny,
        [styles.narrow]: narrow,
        [styles.clickable]: !!onClick,
        [styles.circle]: circle
      },
      className
    );

    return (
      <span
        {...filterInvalidDOMProps(props)}
        className={classes}
        onClick={onClick}
        style={{backgroundColor: color}}
      >
        {numeric ? formatGivenNumber(children) : children}
        {onDelete && (
          <span onClick={this.onClickDelete} className={styles.cross}>
            X
          </span>
        )}
      </span>
    );
  }

  render() {
    const {url} = this.props;
    const pill = this.renderPill();

    return url ? <Link to={url}>{pill}</Link> : pill;
  }
}
