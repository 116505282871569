exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1rqh0AO-w8qp5XZ2ID9b7j{display:flex;padding:30px 20px;align-items:center}._1rqh0AO-w8qp5XZ2ID9b7j h1,._1rqh0AO-w8qp5XZ2ID9b7j h2,._1rqh0AO-w8qp5XZ2ID9b7j h3,._1rqh0AO-w8qp5XZ2ID9b7j h4,._1rqh0AO-w8qp5XZ2ID9b7j h5,._1rqh0AO-w8qp5XZ2ID9b7j h6{font-weight:bold;font-size:25px;margin-bottom:0;margin-right:auto}._1rqh0AO-w8qp5XZ2ID9b7j>*{margin-right:10px}._1rqh0AO-w8qp5XZ2ID9b7j>*:last-child{margin-right:0}", ""]);

// exports
exports.locals = {
	"header": "_1rqh0AO-w8qp5XZ2ID9b7j"
};