import {createSelector} from 'reselect';
import {currentProductSelector, pricesSelector} from 'modules/products/productSelectors';
import {currencySelector} from 'modules/auth/authSelectors';

const paymentsStateSelector = state => state.payments;

export const paymentsLoadingSelector = createSelector(
  paymentsStateSelector,
  state => state.get('paymentsLoading')
);
export const paymentsErrorSelector = createSelector(
  paymentsStateSelector,
  state => state.get('paymentsError')
);
export const paymentsSelector = createSelector(
  paymentsStateSelector,
  state => state.get('payments')
);
export const paymentsDescSelector = createSelector(
  paymentsSelector,
  payments => payments.sortBy(payment => payment.get('dateDue'), (a, b) => b - a) // sort by dateDue desc
);
export const hasPaymentsSelector = createSelector(
  paymentsSelector,
  payments => payments.size
);

export const paymentsPageSelector = createSelector(
  paymentsDescSelector,
  paymentsLoadingSelector,
  paymentsErrorSelector,
  (payments, loading, error) => ({payments: payments.toJS(), loading, error})
);

export const subscriptionExpiryDateSelector = createSelector(
  paymentsDescSelector,
  payments => {
    return payments.size ? payments.first().get('expiryDate') : null;
  }
);

// payment details
export const paymentDetailsLoadingSelector = createSelector(
  paymentsStateSelector,
  state => state.get('paymentDetailsLoading')
);

export const paymentDetailsErrorSelector = createSelector(
  paymentsStateSelector,
  state => state.get('paymentDetailsError')
);

export const paymentDetailsSelector = createSelector(
  paymentsStateSelector,
  state => state.get('paymentDetails').toJS()
);

export const hasPaymentDetailsSelector = createSelector(
  paymentsStateSelector,
  state => state.hasIn(['paymentDetails', 'last4'])
);

export const paymentDetailsComponentSelector = createSelector(
  paymentDetailsLoadingSelector,
  paymentDetailsErrorSelector,
  paymentDetailsSelector,
  hasPaymentDetailsSelector,
  (loading, error, details, hasDetails) => ({
    loading,
    error,
    details,
    hasDetails
  })
);

export const updateButtonSelector = createSelector(
  paymentDetailsLoadingSelector,
  paymentDetailsSelector,
  (isLoading, details) => {
    return {
      disabled: !details && isLoading,
      isLoading: details && isLoading
    };
  }
);

export const confirmResubscribeSelector = createSelector(
  currentProductSelector,
  pricesSelector,
  currencySelector,
  (plan, prices, currency) => {
    const planValue = prices.getIn([plan.get('planId'), 'incVatValue']);
    return {
      planName: plan.get('name'),
      planValue,
      planCurrency: currency,
      planInterval: plan.get('interval')
    };
  }
);
