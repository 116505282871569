exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2n0JYh5uvGKAG51gjouxvN{position:absolute;width:100vw;height:40px;bottom:0;z-index:10}._3YwVBVkaWB9z48zByiOCDZ{height:100%;-webkit-animation:_34etq7_t9FsEABSpWvK9-f 5s ease-in-out;animation:_34etq7_t9FsEABSpWvK9-f 5s ease-in-out;-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);background:#000;color:#fff;padding:8px}._3YwVBVkaWB9z48zByiOCDZ ._2g7Kd8bXSl9yiR_NdwE8ZI{height:100%;margin:0 auto;max-width:80%;display:flex;align-items:center;justify-content:center}._3YwVBVkaWB9z48zByiOCDZ ._2g7Kd8bXSl9yiR_NdwE8ZI p{margin:0;margin-right:10px}._3YwVBVkaWB9z48zByiOCDZ ._2g7Kd8bXSl9yiR_NdwE8ZI button{height:auto;transition:none;padding:1px 4px !important}@-webkit-keyframes _34etq7_t9FsEABSpWvK9-f{0%{-webkit-clip-path:polygon(0 0, 0% 0, 0% 100%, 0 100%);clip-path:polygon(0 0, 0% 0, 0% 100%, 0 100%)}10%{-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%)}90%{-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%)}100%{-webkit-clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%);clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%)}}@keyframes _34etq7_t9FsEABSpWvK9-f{0%{-webkit-clip-path:polygon(0 0, 0% 0, 0% 100%, 0 100%);clip-path:polygon(0 0, 0% 0, 0% 100%, 0 100%)}10%{-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%)}90%{-webkit-clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%)}100%{-webkit-clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%);clip-path:polygon(100% 0, 100% 0, 100% 100%, 100% 100%)}}", ""]);

// exports
exports.locals = {
	"decisionAlerts": "_2n0JYh5uvGKAG51gjouxvN",
	"decisionAlert": "_3YwVBVkaWB9z48zByiOCDZ",
	"swipeIn": "_34etq7_t9FsEABSpWvK9-f",
	"content": "_2g7Kd8bXSl9yiR_NdwE8ZI"
};