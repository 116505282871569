import _ from 'lodash';
import {intercomSelector} from 'modules/intercom/intercomSelectors';
import PropTypes from 'prop-types';
import React from 'react';
import Intercom from 'react-intercom';
import {connect} from 'react-redux';

const API_ID = 'fzbqnrme';

export class IntercomContainer extends React.Component {
  render() {
    const {user, account, accountType, currentReportId, isVirtualUser} = this.props;

    if (isVirtualUser || (accountType && accountType.disableIntercom)) {
      return <span />;
    }

    // Used the individual item names as opposed to {...user} so we get control over what goes into intercom, even if the variables were to change.
    return (
      <Intercom
        appID={API_ID}
        accountId={account && account.accountId}
        user_id={user && user.userId}
        user_hash={user && user.intercomUserHash}
        email={user && user.email}
        name={user && user.name}
        accountName={account && account.organizationName}
        role={user && user.role}
        accountUrl={account && 'https://app.silktide.com/' + account.accountId + '/home'}
        currentReport={
          account && currentReportId
            ? 'https://app.silktide.com/' + account.accountId + '/reports/' + currentReportId
            : ''
        }
        accountTypeId={account && account.accountTypeId}
        paymentStatus={accountType && accountType.state}
      />
    );
  }
}

export default connect(intercomSelector, {})(IntercomContainer);
