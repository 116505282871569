import BusinessPlanChooser from 'modules/products/components/planChooser/businessPlanChooser';
import AgencyPlanChooser from 'modules/products/components/planChooser/agencyPlanChooser';

import qualityIcon from 'modules/tour/components/icon__quality.png';
import experienceIcon from 'modules/tour/components/icon__experience.png';
import marketingIcon from 'modules/tour/components/icon__marketing.png';
import accessibilityIcon from 'modules/tour/components/icon__accessibility.png';
import technologyIcon from 'modules/tour/components/icon__technology.png';
import contentIcon from 'modules/tour/components/icon__content.png';

const ALL_PLAN_FEATURES = ['Automatically schedule new reports'];
const EXTRA_PLAN_FEATURES = ['Add your logo to reports'];

export const PLAN_GROUPS = [
  /*{
    id: 'personal',
    title: 'Personal',
    chooserComponent: AgencyPlanChooser
  },*/
  {
    id: 'business',
    title: 'Monitor',
    chooserComponent: BusinessPlanChooser,
    features: [
      'Find spelling errors, broken links and more',
      'Measure your search engine ranking',
      'Test your accessibility',
      'Automatic reports every 5 days',
      'Retest your website as much as you like',
      'Unlimited users'
    ]
  },
  {
    id: 'agency',
    title: 'Agency',
    chooserComponent: AgencyPlanChooser,
    features: [
      'Find spelling errors, broken links and more',
      'Review your search engine ranking',
      'Comprehensive list of speed improvements',
      'Test any combination of websites you like',
      'Add your own logo to reports',
      'Unlimited users'
    ]
  }
];

const qualityTests = [
  {title: 'Spelling'},
  {title: 'Grammar'},
  {title: 'Broken links'},
  {title: 'Broken pages'},
  {title: 'Missing files'},
  {title: 'Prohibited content'}
];

const experienceTests = [
  {title: 'Mobile layout'},
  {title: 'Desktop speed'},
  {title: 'Mobile speed'},
  {title: 'Reading age'}
];

const marketingTests = [
  {title: 'SEO fundamentals'},
  {title: 'Popularity'},
  {title: 'Duplicate content'},
  {title: 'Backlinks'},
  {title: 'Social sharing'},
  {title: 'Facebook page'},
  {title: 'Twitter account'}
];

const accessibilityTests = [{title: 'WCAG 2.0 A'}, {title: 'Section 508'}];

const technologyTests = [
  {title: 'JavaScript errors'},
  {title: 'Analytics'},
  {title: 'Favicons'},
  {title: 'Cookie law'},
  {title: 'Server behaviour'}
];

const contentTests = [
  {title: 'Desktop pages'},
  {title: 'Mobile pages'},
  {title: 'Contact details'},
  {title: 'Redirections'},
  {title: 'Outgoing links'},
  {title: 'Technology'}
];

export const TESTS_INFO = [
  {
    sectionTitle: 'Quality',
    icon: qualityIcon,
    tests: qualityTests
  },
  {
    sectionTitle: 'Experience',
    icon: experienceIcon,
    tests: experienceTests
  },
  {
    sectionTitle: 'Marketing',
    icon: marketingIcon,
    tests: marketingTests
  },
  {
    sectionTitle: 'Accessiblity',
    icon: accessibilityIcon,
    tests: accessibilityTests
  },
  {
    sectionTitle: 'Technology',
    icon: technologyIcon,
    tests: technologyTests
  },
  {
    sectionTitle: 'Content',
    icon: contentIcon,
    tests: contentTests
  }
];
