exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1XK4uli8y9TAbT0ORHs4_i{color:#333;line-height:1.45}", ""]);

// exports
exports.locals = {
	"modalContent": "_1XK4uli8y9TAbT0ORHs4_i"
};