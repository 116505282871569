import React from 'react';
import styles from './quotePresenter.scss';
import moment from 'moment';
import cx from 'classnames';
import {formatGivenNumber} from 'utils/numberUtils';
import Remarkable from 'react-remarkable';
import QuoteTopBar from './quoteTopBar.js';

export default class QuotePresenter extends React.Component {
  renderLineItemRow = (key, lineItem) => {
    const currency = this.props.quoteTable.currency;
    const markdownOptions = {linkTarget: '_blank'};

    let term = '';
    if (lineItem.numMonths === 12) {
      term = 'year';
    } else if (lineItem.numMonths > 1) {
      term = lineItem.numMonths + ' monthly';
    }

    return (
      <tr key={'line_item_' + key}>
        <td className={styles.strongText}>{lineItem.section}</td>
        <td>
          <Remarkable options={markdownOptions}>{lineItem.description}</Remarkable>
        </td>
        <td className={styles.textRight}>
          {this.renderPrice(lineItem.amount, currency)} {term ? ' / ' + term : null}
        </td>
      </tr>
    );
  };

  renderLineItemRows = lineItems => {
    let i = 1;
    return lineItems.map(lineItem => this.renderLineItemRow(i++, lineItem));
  };

  renderPrice = (price, currencyCode) => {
    const formattedPrice = Math.abs(price);
    const prefix = price < 0 ? '-' : '';
    switch (currencyCode) {
      case 'usd':
        return prefix + '$' + formatGivenNumber(formattedPrice, 2);
      case 'gbp':
        return prefix + '£' + formatGivenNumber(formattedPrice, 2);
      case 'eur':
        return prefix + '€' + formatGivenNumber(formattedPrice, 2);
      default:
        return prefix + formattedPrice + ' ' + currencyCode.toUpperCase();
    }
  };

  render() {
    const {quote, quoteTable, account, quoteId, standalone} = this.props;
    if (!quoteTable) return null;

    let title = quote.title ? quote.title : quoteTable.title;

    // If we're not standalone, act as if we're just a pricing page
    if (!standalone) {
      title = 'Pricing';
    }

    return (
      <div className={cx(styles.wrapper, styles.quoteWrapper)}>
        <div className={styles.quoteBody}>
          <div className={styles.quoteBodyWrapper}>
            <QuoteTopBar quote={this.props} />

            <div className={styles.title}>{title}</div>

            {standalone && (
              <table className={styles.propTable}>
                <tbody>
                  <tr>
                    <td className={styles.leftTd}>Prepared for:</td>
                    <td>{account.organizationName}</td>
                  </tr>
                  <tr>
                    <td className={styles.leftTd}>Prepared by:</td>
                    <td>
                      {quote.preparedBy.name} (
                      <a href={'mailto:' + quote.preparedBy.email}>{quote.preparedBy.email}</a>)
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.leftTd}>Date:</td>
                    <td>{moment.unix(quote.published).format('MMMM Do YYYY')}</td>
                  </tr>
                  <tr>
                    <td className={styles.leftTd}>Expires:</td>
                    <td>{moment.unix(quote.expires).format('MMMM Do YYYY')}</td>
                  </tr>
                  <tr>
                    <td className={styles.leftTd}>Reference:</td>
                    <td>{quoteId}</td>
                  </tr>
                </tbody>
              </table>
            )}

            <table className={styles.breakdownTable}>
              <thead>
                <tr>
                  <th style={{minWidth: '120px'}}>Section</th>
                  <th>Item</th>
                  <th style={{minWidth: '120px'}} className={styles.textRight}>
                    Price {quoteTable.tax && <span>(ex. {quoteTable.tax.name})</span>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderLineItemRows(quoteTable.lineItems)}
                <tr className={styles.strongText}>
                  <td colSpan="2" className={cx(styles.textRight, styles.noBottomBorder)}>
                    Total
                  </td>
                  <td className={cx(styles.noBottomBorder, styles.textRight)}>
                    {this.renderPrice(quoteTable.amountSubtotal, quoteTable.currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
