exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25TcOQhvTLLKtaxZoJoyF4{position:relative;padding:15px;margin-bottom:1rem;border:1px solid transparent;border-radius:5px}.BGk_vQqUuNYlcKokyoPXG{margin:0}._1nUoklO1zFcvETfjdKdPDI{padding:10px}._1Iit_6i_Qv95OJgq632Lje{margin-right:10px}._2mmowTbZXWk_U3wB7_5DMd{border-color:#b0d0c8;background-color:#d0f8e8;color:#155724}._2zdRO0AEX_dIrGkySFZ0Bu{border-color:#dfd3ad;background-color:#ffeeba;color:#856404}._19rK6I1uN787OXx6L-SaHj{border-color:#b1ccd1;background-color:#e1f0ff;color:#003040}.b3eNL0xYyXGKG4qLfNeMB{border-color:#f5c6cb;background-color:#f8d7da;color:#721c24}", ""]);

// exports
exports.locals = {
	"alert": "_25TcOQhvTLLKtaxZoJoyF4",
	"noMargin": "BGk_vQqUuNYlcKokyoPXG",
	"padding10": "_1nUoklO1zFcvETfjdKdPDI",
	"icon": "_1Iit_6i_Qv95OJgq632Lje",
	"alert-success": "_2mmowTbZXWk_U3wB7_5DMd",
	"alert-warning": "_2zdRO0AEX_dIrGkySFZ0Bu",
	"alert-info": "_19rK6I1uN787OXx6L-SaHj",
	"alert-danger": "b3eNL0xYyXGKG4qLfNeMB"
};