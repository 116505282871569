import React, {useContext} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {toggleSidebar} from 'modules/app';
import Icon from 'components/font-awesome';
import styles from './sidebarToggleTrigger.scss';
import {createStructuredSelector} from 'reselect';
import {sidebarIsHiddenSelector} from 'modules/app/appSelectors';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

function SidebarToggleTrigger({isSidebarHidden, onClick}) {
  const {tablet} = useContext(ScreenSizeContext);

  return (
    <div
      onClick={onClick}
      className={cx(styles.SidebarToggleTrigger, {
        // [styles.hide]: !tablet,
        [styles.active]: !isSidebarHidden
      })}
    >
      <Icon name="bars" />
    </div>
  );
}

export default connect(
  createStructuredSelector({
    isSidebarHidden: sidebarIsHiddenSelector
  }),
  dispatch => ({
    onClick: () => {
      dispatch(toggleSidebar(false));
    }
  })
)(SidebarToggleTrigger);
