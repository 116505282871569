import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_WIDGET_TYPES} from 'modules/dashboards/constants';
import {widgetTypesEndpoint} from 'modules/dashboards/utils';
import {requestWidgetTypesComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestWidgetTypesListener() {
  yield takeLatest(REQUEST_WIDGET_TYPES, requestWidgetTypesSaga);
}

function* requestWidgetTypesSaga() {
  const url = widgetTypesEndpoint();

  yield processRequest('GET', url, {
    *success(widgetTypes) {
      yield put(requestWidgetTypesComplete(null, widgetTypes));
    },
    *error(err) {
      yield put(requestWidgetTypesComplete(err));
    },
    errorMessage: couldNot('request widget types')
  });
}
