import Buttons from 'components/buttons';
import {connectContext} from 'modules/app/appUtils';
import styles from 'modules/inspector/components/sidebar/inspectorStyle.scss';
import InspectorEditCMSButton from 'modules/inspector/containers/inspectorEditCMSButton';
import InspectorEffectDropdown from 'modules/inspector/containers/inspectorEffectDropdown';
import InspectorRetestButton from 'modules/inspector/containers/inspectorRetestButton';
import InspectorShareButton from 'modules/inspector/containers/inspectorShareButton';
import InspectorViewSwitcher from 'modules/inspector/containers/inspectorViewSwitcher';
import {inspectorSubHeaderSelector} from 'modules/inspector/inspectorSelectors';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

class InspectorSubHeader extends React.Component {
  render() {
    const {screenSizes} = this.props;
    const {largeMobile, tablet} = screenSizes;
    const isMobile = largeMobile || tablet;
    const showEffectDropdown = this.props.pageView !== 'source' && !isMobile;
    return (
      <div className={styles.inspectorSubHeader}>
        <InspectorViewSwitcher />
        <div style={{marginLeft: 'auto', display: 'flex'}}>
          {showEffectDropdown && <InspectorEffectDropdown />}
          <InspectorShareButton />
          <InspectorEditCMSButton />
          <InspectorRetestButton />
        </div>
      </div>
    );
  }
}

export default compose(
  connectContext(ScreenSizeContext, 'screenSizes'),
  connect(inspectorSubHeaderSelector)
)(InspectorSubHeader);
