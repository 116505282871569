import React from 'react';
import Icon from 'components/font-awesome/icon';
import styles from './help.scss';
import ExternalLink from 'modules/location/externalLink';

export default class AccessibilityHelp extends React.Component {
  render() {
    return (
      <div className={styles.help}>
        <h3>What accessibility covers</h3>
        <p>
          Measure and optimize how accessible your website is against the latest WCAG guidelines:
        </p>

        <ul className={styles.helpList}>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Test WCAG 2.1 or WCAG 2.0
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Covers A, AA, AAA success
            criteria
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> Test mobile accessibility
          </li>
          <li>
            <Icon className={styles.check} name="fas fa-check-circle" /> See issues on your pages
          </li>
        </ul>

        <p>
          You can{' '}
          <ExternalLink href="https://silktide.com/features/accessibility">
            learn more on our website
          </ExternalLink>
          .
        </p>
      </div>
    );
  }
}
