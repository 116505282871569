exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QlkRz3l80GsVbiHb8mlJI{display:flex;align-items:center}._3iJP7V8zyZ63YruY34WJf1{margin:0 10px 0 0}", ""]);

// exports
exports.locals = {
	"wrapper": "QlkRz3l80GsVbiHb8mlJI",
	"user": "_3iJP7V8zyZ63YruY34WJf1"
};