import {Map, List, fromJS} from 'immutable';
import {defaultFilters} from './reportFilterUtils';

const initialState = Map({
  filters: defaultFilters
});

export default function reportFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILTER:
      return state.update('filters', list => list.push(action.filter));
    case REMOVE_FILTER:
      return state.update('filters', list => list.filter(f => !(f.field === action.filterField)));
    case UPDATE_FILTERS:
      let filterUpdates = action.filters;
      return state.set(
        'filters',
        state.get('filters').map(filter => {
          if (filterUpdates.hasOwnProperty(filter.field)) {
            filter.value = filterUpdates[filter.field];
          }
          return filter;
        })
      );
  }
  return state;
}

export const CREATE_FILTER = 'reportFilters:CREATE_FILTER';
export function createFilter(filterField) {
  return {
    type: CREATE_FILTER,
    filterField
  };
}

export const ADD_FILTER = 'reportFilters:ADD_FILTER';
export function addFilter(filter) {
  return {
    type: ADD_FILTER,
    filter
  };
}

export const UPDATE_FILTERS = 'reportFilters:UPDATE_FILTERS';
export function updateFilters(filters) {
  return {
    type: UPDATE_FILTERS,
    filters
  };
}

export const REMOVE_FILTER = 'reportFilters:REMOVE_FILTER';
export function removeFilter(filterField) {
  return {
    type: REMOVE_FILTER,
    filterField
  };
}
