import React from 'react';
import ContextPages from './contextPages';
import ContextNavigation from './contextNavigation';

export default class Context extends React.Component {
  state = {
    showOverlay: false
  };

  handleOpen = () => {
    this.setState({showOverlay: true}, () => {
      this.props.onOpenPages();
    });
  };

  handleClose = () => {
    this.setState({showOverlay: false}, () => {
      this.props.onClosePages();
    });
  };

  render() {
    const {pages, numPages, pageNumber, onClickPage, onNavigateRelative} = this.props;

    return (
      <ContextNavigation
        numPages={numPages}
        pageNumber={pageNumber}
        onNavigateRelative={onNavigateRelative}
        onOpen={this.handleOpen}
      >
        {this.state.showOverlay && (
          <ContextPages pages={pages} onClickPage={onClickPage} onClose={this.handleClose} />
        )}
      </ContextNavigation>
    );
  }
}
