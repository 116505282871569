import {Map, List, fromJS} from 'immutable';
import {push} from 'modules/location';
import {folderPath} from './folderUtils';
import {ORDER_COMPLETE} from 'modules/products';
import {SWITCH_ACCOUNT} from 'modules/auth';

const initialState = Map({
  dragging: false, // dragging sidebar folder item?
  data: List()
  // fromJS([
  //   {
  //     left: null,
  //     right: null,
  //     children: null,
  //     folderId: 0,
  //     name: 'All websites',
  //     type: 'folder',
  //     options: null
  //   }
  // ])
});

export default function foldersReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_FOLDERS:
      return state.set('data', initialState.get('data').concat(fromJS(action.folders)));

    case STARTED_DRAGGING_FOLDER:
      return state.set('dragging', true);

    case STOPPED_DRAGGING_FOLDER:
      return state.set('dragging', false);

    case SWITCH_ACCOUNT:
    case ORDER_COMPLETE:
      return initialState;
  }

  return state;
}

export const RECEIVE_FOLDERS = 'folders:RECEIVE_FOLDERS';
export function receiveFolders(folders) {
  return {type: RECEIVE_FOLDERS, folders};
}

export const TOGGLE_EDIT_MODE = 'folders:TOGGLE_EDIT_MODE';
export function toggleEditMode() {
  return {type: TOGGLE_EDIT_MODE};
}

export const STARTED_DRAGGING_FOLDER = 'folders:STARTED_DRAGGING_FOLDER';
export function startedDraggingFolder(folderId) {
  return {
    type: STARTED_DRAGGING_FOLDER,
    folderId
  };
}

export const STOPPED_DRAGGING_FOLDER = 'folders:STOPPED_DRAGGING_FOLDER';
export function stoppedDraggingFolder(folderId) {
  return {
    type: STOPPED_DRAGGING_FOLDER,
    folderId
  };
}

export const REQUEST_ADD_FOLDER = 'folders:ADD_FOLDER';
export function requestAddFolder(parentId = null) {
  return {type: REQUEST_ADD_FOLDER, parentId};
}

export const REQUEST_DELETE_FOLDER = 'folders:REQUEST_DELETE_FOLDER';
export function requestDeleteFolder(folderId) {
  return {type: REQUEST_DELETE_FOLDER, folderId};
}

export const EDIT_FOLDER = 'folders:EDIT_FOLDER';
export function editFolder(folderId) {
  return {type: EDIT_FOLDER, folderId};
}

export const SAVE_FOLDER = 'folders:SAVE_FOLDER';
export function saveFolder(folder) {
  return {type: SAVE_FOLDER, folder};
}

export const SAVE_FOLDER_ERROR = 'folders:SAVE_FOLDER_ERROR';
export function saveFolderError(error) {
  return {type: SAVE_FOLDER_ERROR, error};
}

export const SWITCH_FOLDER = 'folders:SWITCH_FOLDER';
export function switchFolder(folderId) {
  return push(folderPath, {folderId});
}

export const MOVE_FOLDER_TO_FOLDER = 'folders:MOVE_FOLDER_TO_FOLDER';
export function moveFolderToFolder({fromFolder, toFolder}) {
  return {type: MOVE_FOLDER_TO_FOLDER, fromFolder, toFolder};
}

export const VIEW_FOLDERS_PAGE = 'folders:VIEW_FOLDERS_PAGE';
export function viewFoldersPage() {
  return {type: VIEW_FOLDERS_PAGE};
}

export const EXIT_FOLDER_PAGE = 'folders:EXIT_FOLDER_PAGE';
export function exitFolderPage() {
  return {type: EXIT_FOLDER_PAGE};
}

export const VIEW_FOLDERS_INDEX_PAGE = 'folders:VIEW_FOLDERS_INDEX_PAGE';
export function viewFoldersIndexPage() {
  return {
    type: VIEW_FOLDERS_INDEX_PAGE
  };
}

export const VIEW_FOLDER_PAGE = 'folders:VIEW_FOLDER_PAGE';
export function viewFolderPage(folderId) {
  return {
    type: VIEW_FOLDER_PAGE,
    folderId
  };
}
