import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {recieveBalances, requestBalancesComplete} from '../actions';
import {REQUEST_BALANCES} from '../constants';

export default function* loadBalancesSaga() {
  yield takeLatest(REQUEST_BALANCES, loadBalances);
}

function* loadBalances({reportId, sku, serviceId}) {
  const url = !reportId ? 'balances' : 'balances/' + reportId;
  const data = {};

  if (sku) data.sku = sku;
  if (serviceId) data.serviceId = serviceId;

  yield processRequest(
    'GET',
    url,
    {
      success: function*(data) {
        yield put(recieveBalances(data));
        yield put(requestBalancesComplete());
      },
      error: function*(err) {
        yield put(requestBalancesComplete(err));
      }
    },
    data
  );
}
