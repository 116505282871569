import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import {selectArchive} from 'modules/archives/archives';
import {reportPath, reportSettingsPath, generateReportId} from 'modules/reports/reportUtils';

export function changeReport({reportId, testId}) {
  // set testId to empty string so that we redirect to the correct testId
  return push(testPath, {reportId: reportId, testId: ''}, ['accountId']);
}

export function changeReportSettings({reportId}) {
  // set testId to empty string so that we redirect to the correct testId
  return push(reportSettingsPath, {reportId: reportId}, ['accountId']);
}

export function changeSegment({reportId, segmentId, testId}) {
  return push(reportPath, {reportId: generateReportId(reportId, segmentId)}, ['accountId']);
}

export function changeArchive({archiveId}) {
  return selectArchive(archiveId);
}
