exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jSBQJVDaeUkqWEdG_40YG{opacity:.5}.PeyrtYrnQEa42W6D2NG2u{cursor:pointer}.M7jgXNN-g1ixY1Xn9OzVQ{margin-bottom:10px}.M7jgXNN-g1ixY1Xn9OzVQ:last-of-type{margin-bottom:0}._2t_K5lxm8GQ2RXm_ByW5Zc{border-right:1px solid #ddd}@media print{._2bmTM1xue618dvwGwnUESG{display:none}}._2d3cKLVnMY9cRak6F5axlh{font-size:12px;padding:1px 5px;border-radius:3px;border:1px solid #533be2;overflow:hidden;font-weight:bold;white-space:nowrap;display:inline-block;color:#533be2}", ""]);

// exports
exports.locals = {
	"opaque": "jSBQJVDaeUkqWEdG_40YG",
	"clickable": "PeyrtYrnQEa42W6D2NG2u",
	"combinedField": "M7jgXNN-g1ixY1Xn9OzVQ",
	"sticky": "_2t_K5lxm8GQ2RXm_ByW5Zc",
	"hideOnPrint": "_2bmTM1xue618dvwGwnUESG",
	"tag": "_2d3cKLVnMY9cRak6F5axlh"
};