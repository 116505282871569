import {delay} from 'redux-saga';
import {takeLatest, select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {FETCH_LEADERBOARD_RESULTS} from '../constants';
import {receiveLeaderboardResults, fetchLeaderboardResultsComplete} from '../actions';
import {leaderboardResultsEndpoint} from '../utils';
import {handle404} from 'modules/location';

export default function* fetchLeaderboardResultsSaga() {
  yield takeLatest(FETCH_LEADERBOARD_RESULTS, fetchLeaderboardResults);
}

function* fetchLeaderboardResults({leaderboardId, limit, offset, order, search}) {
  yield delay(300); // acts as a debounce

  const accountId = yield select(accountIdSelector);

  yield processRequest(
    'GET',
    leaderboardResultsEndpoint({accountId, leaderboardId}),
    {
      *success({leaderboardData, maxReports} = {}) {
        yield put(fetchLeaderboardResultsComplete());
        yield put(receiveLeaderboardResults(leaderboardId, leaderboardData, maxReports));
      },
      *error(err) {
        yield put(fetchLeaderboardResultsComplete(err));
      },
      *notFound() {
        yield put(handle404());
      }
    },
    {
      limit,
      offset,
      order,
      search
    }
  );
}
