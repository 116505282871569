import React from 'react';
import cx from 'classnames';
import styles from './panel.scss';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export default function Panel({
  children,
  padding = true,
  hidePadding = false,
  hideBorder = false,
  hideBorderTop = false,
  hideBorderBottom = false,
  marginBottom = false,
  toolbar = false,
  overflow = null,
  shadow = false,
  className,
  ...props
}) {
  const classes = cx(styles.newpanel, className, {
    [styles.padding]: padding,
    [styles.hidePadding]: hidePadding,
    [styles.hideBorder]: hideBorder,
    [styles.hideBorderTop]: hideBorderTop,
    [styles.hideBorderBottom]: hideBorderBottom,
    [styles.marginBottom]: marginBottom,
    [styles.shadow]: shadow,
    [styles.toolbar]: toolbar,
    [styles.overflowAuto]: overflow === 'auto'
  });

  return (
    <div className={classes} {...filterInvalidDOMProps(props)}>
      {children}
    </div>
  );
}
