import {Map} from 'immutable';
import {createSelector} from 'reselect';
import {locationParamsSelector} from 'modules/location/locationSelectors';

export const alertsDomainSelector = state => state.alerts;

export const alertsContainerSelector = createSelector(
  alertsDomainSelector,
  locationParamsSelector,
  (alertsDomainState, params) => {
    const alerts = alertsDomainState.get('alerts');
    const decision = alertsDomainState.get('decisionAlert');
    const decisionId = alertsDomainState.get('decisionId');

    return {alerts, decision, decisionId, params};
  }
);
