import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {RESET_PASSWORD, resetPasswordComplete, setErrorMessage} from 'modules/passwords';
import {resetPasswordEndpoint} from 'modules/passwords/passwordUtils';
import handleLoginSuccess from 'modules/auth/sagas/utils/handleLoginSuccess';
import {push} from 'modules/location';
import {homePath} from 'modules/app/appUtils';
import getErrorMessage from 'api/errors';

export default function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

function* resetPassword({userId, password, emailToken}) {
  const postData = {password, emailToken};

  yield processRequest(
    'PUT',
    resetPasswordEndpoint({userId}),
    {
      success: function*(data) {
        yield put(resetPasswordComplete(true));
        yield handleLoginSuccess(data);
        yield put(push(homePath));
      },

      error: function*(err) {
        if (err && err.errorCode) {
          var message = getErrorMessage(err.errorCode, {
            passwordHelp: err.passwordHelp
          });
          yield put(setErrorMessage(message));
        } else {
          yield put(resetPasswordComplete(false));
        }
      }
    },
    postData
  );
}
