exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ZgXadpxfvEDHOOz0nsC6H{display:inline-block;width:35px;height:35px;display:flex;justify-content:center;align-items:center;font-size:20px;color:#fff;background:rgba(255,255,255,.1);border-radius:50%}._2ZgXadpxfvEDHOOz0nsC6H._3Myao00dsR30q_Hv5o-8eb{color:#55d272;background:rgba(85,210,114,.1)}", ""]);

// exports
exports.locals = {
	"icon": "_2ZgXadpxfvEDHOOz0nsC6H",
	"completed": "_3Myao00dsR30q_Hv5o-8eb"
};