import _ from 'lodash';
import {isFloat} from 'utils/numberUtils';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const DESIRED_TICKS = 5;
export const MINIMUM_TICKS = 3;

function padTime(time) {
  if (time.toString().length < 2) {
    return '0' + time;
  }
  return time;
}

function formatDateTime(time) {
  const currDate = new Date(time * 1000);
  return {
    time: padTime(currDate.getHours()) + ':' + padTime(currDate.getMinutes()),
    date: currDate.getDate() + ' ' + monthNames[currDate.getMonth()]
  };
}

export default {
  hasDataChange(results) {
    const uniqueData = _.uniq(_.values(results));
    return uniqueData.length > 1;
  },

  getMinValueFromObjectKeyResults(results) {
    return _.min(_.values(results));
  },

  mergeCategoryResults(results) {
    let keys = [];
    _.each(results, function(result) {
      _.merge(keys, _.keys(result));
    });
    return _.uniq(_.sortBy(keys));
  },

  lineResultsToSeries(primaryResultId, results, type) {
    let hasSelected = false;
    let lineMap = [];

    _.each(results, (rawData, resultId) => {
      let data = [];

      let rawDataToLoop = rawData.values || rawData;

      _.each(rawDataToLoop, (value, timestamp) => {
        if (_.isNumber(value)) {
          // Convert the timestamp to microseconds
          data.push([timestamp * 1000, value]);
        }
      });

      if (data.length === 0) {
        return;
      }

      if (data.length === 1) {
        // Double up the data if we only have one data point to ensure the graphs actually render.
        data.push(data[0]);
      }

      let lineChartData = {
        name: resultId,
        data: data,
        lineWidth: 3,
        primary: resultId == primaryResultId
      };

      if (rawData.color) lineChartData['color'] = rawData.color;
      if (type) lineChartData['type'] = type;

      lineMap.push(lineChartData);
    });

    lineMap = _.orderBy(lineMap, ['primary'], ['desc']);

    if (!hasSelected && lineMap.length > 0) {
      lineMap[0].visible = true;
    }
    return lineMap;
  },

  getDataRange(lineMap) {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;

    _.each(lineMap, line => {
      _.each(line.data, point => {
        let value = point[1];
        min = Math.min(min, value);
        max = Math.max(max, value);
      });
    });

    return [min, max];
  },

  getTickCount(dataMin, dataMax) {
    if (isFloat(dataMin) || isFloat(dataMax)) {
      return DESIRED_TICKS;
    }

    const diff = dataMax - dataMin;

    return diff < DESIRED_TICKS ? Math.max(diff + 1, MINIMUM_TICKS) : DESIRED_TICKS;
  },

  getSparklineResults(data) {
    data = _.values(data);

    if (data.length === 1) {
      data.push(data[0]);
    }

    return [
      {
        data: data
      }
    ];
  }
};

export function highchartsConfig(overrides) {
  const config = {
    chart: {
      backgroundColor: '#ffffff'
    },
    credits: false,
    title: {align: 'left'},
    tooltip: {
      headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>'
    },
    plotOptions: {
      area: {
        allowPointSelect: true,
        connectNulls: true,
        pointPlacement: 'off'
      },
      line: {
        allowPointSelect: true,
        connectNulls: true,
        pointPlacement: 'off'
      },
      series: {
        fillOpacity: 0.05,
        animation: false,
        marker: {
          enabled: false,
          fillColor: '#ffffff',
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: 'circle'
        }
      },
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    xAxis: {
      labels: {style: {fontSize: '9px'}},
      type: 'datetime'
    },
    yAxis: {
      floor: 0,
      labels: {style: {fontSize: '9px'}},
      minPadding: 0.2
    }
  };

  return _.merge(config, overrides);
}
