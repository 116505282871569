exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Q9s06zOIzz7aa5w4xrgIu{opacity:.8;margin:15px 0 5px 0}._3Q9s06zOIzz7aa5w4xrgIu a{color:inherit;text-decoration:underline}._2HP4oojNgxI-biFfE_oIsk{padding:20px;color:#6b7c93;max-width:600px}", ""]);

// exports
exports.locals = {
	"label": "_3Q9s06zOIzz7aa5w4xrgIu",
	"description": "_2HP4oojNgxI-biFfE_oIsk"
};