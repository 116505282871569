import {take, put, fork, call, select, takeLatest} from 'redux-saga/effects';
import shallPass from 'utils/saga/shallPass';
import POST from 'utils/saga/post';
import {addReportFailure} from 'modules/reports';
import {ADD_REPORT, RECEIVE_EXTRA_INFO} from 'modules/reports/reportConstants';
import {reportPath} from 'modules/reports/reportUtils';
import {showInModal, hideModal} from 'modules/modal';
import AuthCredentialsModal from 'modules/reports/components/modals/authCredentialsModal';
import ReportExistsModal from 'modules/reports/components/modals/reportExistsModal';
import UrlModal from 'modules/reports/components/modals/urlModal';
import AskForModal from 'modules/reports/components/modals/askForModal';
import InfoModal from 'components/modal/infoModal';
import _ from 'lodash';
import ModalSpinner from 'components/spinner/modalSpinner';
import {push} from 'modules/location';
import ensureEntitlement from 'modules/entitlements/sagas/helpers/ensureEntitlement';
import {SITE_CREATOR} from 'modules/auth/gandalf';
import fetchEntitlements from 'modules/entitlements/sagas/helpers/fetchEntitlements';
import {entitlementDataSelector} from 'modules/entitlements/entitlementSelectors';
import {getAskForFields} from 'utils/entitlementUtils';

export default function* addReportSaga() {
  yield takeLatest(ADD_REPORT, handleAddReportAction);
}

function* handleAddReportAction({report}) {
  yield call(addReport, report);
}

function fuckTheBackend(data) {
  const {
    homeUrl,
    username,
    password,
    languages,
    summarizedId, // Not sent at all as the backend cries if it's present
    ignoreExistingReports,
    keywords,
    engines,
    ...report
  } = data;

  return {
    ignoreExistingReports,
    report,
    campaign: {keywords, engines},
    website: {homeUrl, username, password, languages}
  };
}

function* addReport(report) {
  const allowed = yield shallPass(SITE_CREATOR);
  if (!allowed) return;

  // Make sure user has entitlement to add this product.
  const hasEntitlement = yield ensureEntitlement(report.summarizedId);
  if (!hasEntitlement) return;

  const entitlements = yield select(entitlementDataSelector);
  const askFor = getAskForFields(entitlements, report.summarizedId);

  // dont ask for stuff after we've ignored existing reports
  if (askFor && askFor.length && !report.ignoreExistingReports) {
    // If askFor is set, we need to ask for the additional config.
    report = yield call(handleAskFor, report, askFor);
  }

  // Spin whilst we attempt to add.
  yield put(
    showInModal(ModalSpinner, {
      message: 'One minute. We are setting up your report.'
    })
  );

  const res = yield call(POST, 'reports', fuckTheBackend(report), {
    timeout: 60000
  });

  if (!res.ok) {
    return yield call(addReportFailed, res, report);
  }

  yield addReportProcessResult(yield res.json());
}

function* addReportFailed(res, report) {
  yield put(addReportFailure());

  const jsonResponse = yield res.json();

  // 400 = extra info needed
  if (res.status === 400) {
    const extraInfo = yield call(handleMissingConfig, jsonResponse);

    if (extraInfo) {
      yield call(addReport, _.merge(report, extraInfo));
    }
    yield put(hideModal());
    return;
  }

  // Other error
  return yield call(triggerFailureModal);
}

function* addReportProcessResult({report, askFor}) {
  try {
    // This line breaks the redirect. It's not really needed
    // as we fetch the report when we enter reportPath
    // yield put(receiveReport(report));

    // Redirect to the new report.
    yield put(push(reportPath, {reportId: report.reportId}));

    return yield call(addReportSuccess);
  } catch (e) {
    if (__DEV__) console.error(e);
  }
}

function* addReportSuccess() {
  yield put(hideModal());
  // We need to refetch entitlements at this point to reflect the
  // new report being added
  yield call(fetchEntitlements);
}

function* handleAskFor(report, askFor) {
  yield put(showInModal(AskForModal, {report, askFor, name: 'addReportAskForModal'}));
  const {info} = yield take(RECEIVE_EXTRA_INFO);
  return {...info, ...report};
}

// If we need extra info, we trigger a modal
// and wait for the user to submit the info
function* handleMissingConfig(res) {
  yield triggerMissingConfigModal(res);
  const {info} = yield take(RECEIVE_EXTRA_INFO);
  return info;
}

// Different modal depdending on the info we need
function* triggerMissingConfigModal({status, httpCode, url, reportId}) {
  switch (status) {
    case 'reportExists':
      return yield call(triggerReportExistsModal, url, reportId);
    case 'httpAuth':
      return yield call(triggerAuthModal);
    case 'httpAuthInvalid':
      return yield call(
        triggerAuthModal,
        'danger',
        'Username and password incorrect. Please check your details.'
      );
    case 'invalidUrl':
      return yield call(triggerUrlModal, url, 'Web address invalid. Please check the web address.');
    case 'insecure':
      return yield call(
        triggerUrlModal,
        url,
        'Web address not allowed. Please check the web address.'
      );
    case 'hostNotFound':
      return yield call(triggerUrlModal, url, 'Website not found. Please check the web address.');
    case 'timeout':
      return yield call(
        triggerUrlModal,
        url,
        'Website did not respond. Check the website is available.'
      );
    case 'httpError':
      return yield call(
        triggerUrlModal,
        url,
        `Website responded with an error (${httpCode}). Check the website is available.`
      );
    case 'redirect':
      return yield call(
        triggerUrlModal,
        url,
        'Website redirected to different address. Is this correct?'
      );
  }

  // If no status was matched, show a generic failure modal.
  return yield call(triggerFailureModal);
}

function* triggerAuthModal(level, message) {
  yield put(
    showInModal(AuthCredentialsModal, {
      level,
      message,
      name: 'addReportAuthCredentialsModal'
    })
  );
}

function* triggerReportExistsModal(url, reportId) {
  yield put(showInModal(ReportExistsModal, {url, reportId}));
}

function* triggerUrlModal(url, message) {
  yield put(showInModal(UrlModal, {url, message, name: 'addReportUrlModal'}));
}

function* triggerFailureModal() {
  yield put(
    showInModal(InfoModal, {
      message: 'Something went wrong when adding this report. Please try again later.'
    })
  );
}
