import _ from 'lodash';
import {createSelector} from 'reselect';

const shareStateSelector = state => state.share;

export const shareUrlSelector = createSelector(
  shareStateSelector,
  share => {
    return {
      token: share ? share.get('token') : ''
    };
  }
);
