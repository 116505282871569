import PropTypes from 'prop-types';
import React from 'react';
import {Form} from 'modules/form/components';
import ModalContent from 'modules/modal/components/modalContent';

export default class ModalForm extends React.Component {
  static defaultProps = {
    submitLabel: 'Submit',
    hideSubmit: false,
    beforeForm: null,
    afterForm: null,
    buttons: []
  };

  handleClickButton = label => {
    if (label === this.props.submitLabel) {
      this.form.submit();
    } else {
      this.props.onClickButton(label);
    }
  };

  render() {
    const {
      title,
      description,
      submitLabel,
      buttons,
      children,
      beforeForm,
      afterForm,
      isLoading,
      hideSubmit,
      ...formProps
    } = this.props;
    const modalButtons = hideSubmit ? buttons : [...buttons, submitLabel];

    return (
      <ModalContent
        title={title}
        buttons={modalButtons}
        description={description}
        isLoading={isLoading}
        onClickButton={this.handleClickButton}
      >
        {beforeForm}
        <Form {...formProps} ref={ref => (this.form = ref)}>
          {children}
        </Form>
        {afterForm}
      </ModalContent>
    );
  }
}
