import React from 'react';
import Button from 'components/button';
import Dropdown, {DropdownOption} from 'components/dropdown';
import {useDispatch} from 'hooks/redux';
import {setDashboardColumnCount, insertDashboardRow, removeDashboardRow} from '../actions';

const MAX_COLS = 6;
const BUTTON_LABEL = <Button narrow icon="ellipsis-v" tooltip="Row options" />;

export default function DashboardRowOptions({dashboardId, rowIndex}) {
  const dispatch = useDispatch();

  return (
    <Dropdown button={BUTTON_LABEL}>
      {Array(MAX_COLS)
        .fill()
        .map((_, i) => {
          return (
            <DropdownOption
              key={i}
              onClick={() => {
                dispatch(setDashboardColumnCount(dashboardId, rowIndex, i + 1));
              }}
            >
              {i + 1} Column{i > 0 ? 's' : ''}
            </DropdownOption>
          );
        })}

      <DropdownOption divider key="row_divider" />

      <DropdownOption
        key="remove_row"
        onClick={() => {
          dispatch(removeDashboardRow(dashboardId, rowIndex));
        }}
      >
        Remove row
      </DropdownOption>

      <DropdownOption
        key="insert_above"
        onClick={() => {
          dispatch(insertDashboardRow(dashboardId, rowIndex));
        }}
      >
        Insert row above
      </DropdownOption>

      <DropdownOption
        key="insert_below"
        onClick={() => {
          dispatch(insertDashboardRow(dashboardId, rowIndex + 1));
        }}
      >
        Insert row below
      </DropdownOption>
    </Dropdown>
  );
}
