exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".X32TDIM3N120VyUBHS3K8{box-sizing:border-box;background-size:100%;background-position:0 0;background-repeat:no-repeat}.X32TDIM3N120VyUBHS3K8 iframe{margin:0;border:0;height:100%;width:100%}.-hWzQs1H90w7004NpsnoX{overflow:none}", ""]);

// exports
exports.locals = {
	"device": "X32TDIM3N120VyUBHS3K8",
	"inner": "-hWzQs1H90w7004NpsnoX"
};