import React from 'react';
import cx from 'classnames';

import Center from 'components/center';
import santander from './logo-santander.png';
import ebay from './logo-ebay.png';
import heroku from './logo-heroku.png';
import styles from './logos.scss';

export default function Logos({className}) {
  return (
    <div className={cx(styles.logos, className)}>
      <h4>Over 1,000 happy customers including</h4>
      <Center wrap>
        <img src={ebay} alt="eBay" />
        <img src={santander} alt="Santander" />
        <img src={heroku} alt="Heroku" />
      </Center>
    </div>
  );
}
