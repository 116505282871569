import React from 'react';
import InspectorPage from 'modules/inspector/pages/inspectorPage';
import useRouter from 'use-react-router';
import {parseQuery} from 'utils/urlUtils';

export default function InspectorRoute() {
  const {location, match} = useRouter();
  const query = parseQuery(location.search);

  return <InspectorPage params={match.params} query={query} />;
}
