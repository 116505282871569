import React from 'react';
import {connect} from 'react-redux';
import {updateWebsite} from 'modules/tests';
import {trackEvent} from 'modules/tracking';
import LanguagesConfig from '../../components/reportSettings/languagesConfig';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';

export class ReportSettingsLanguagesConfigPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed languages config page', {
      forReport: this.props.match.params.reportId,
      fromFile: 'languagesConfigPage.js'
    });
  }

  render() {
    return <LanguagesConfig {...this.props} name="languagesConfig" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateWebsite}
)(ReportSettingsLanguagesConfigPage);
