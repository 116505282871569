import React from 'react';
import Spinner from 'components/spinner';
import Center from 'components/center';
// import styles from './loading.scss';

// This component wraps it's children with display: none until the
// loaded prop becomes truthy. It also shows a centered spinner until that time.
//
// The display none is so that the component can still run any lifecycle/loading
// it needs.
export default class Loading extends React.Component {
  render() {
    const {loaded, children} = this.props;

    if (loaded) return children;

    return (
      <Center fit>
        <Spinner />
        <div style={{display: 'none'}}>{children}</div>
      </Center>
    );
  }
}
