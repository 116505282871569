import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';
import Tooltip from 'components/tooltip';

class LocationCanonical extends React.Component {
  render() {
    const {data, options} = this.props;

    if (!data || !data.length) {
      return null;
    }

    const classNames = cx({
      [styles[options.class]]: true
    });

    if (options.short) {
      const parts = data.split(',');
      return (
        <Tooltip text={data}>
          <span className={classNames}>{parts[0]}</span>
        </Tooltip>
      );
    }

    return <span className={classNames}>{data}</span>;
  }
}

LocationCanonical.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default LocationCanonical;
