import {List, fromJS} from 'immutable';

const initialState = List();

export default function languagesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LANGUAGES:
      return fromJS(action.languages);
  }

  return state;
}

export const RECEIVE_LANGUAGES = 'languages:RECEIVE_LANGUAGES';
export function receiveLanguages(languages) {
  return {
    type: RECEIVE_LANGUAGES,
    languages
  };
}

export const FETCH_LANGUAGES_IF_NECESSARY = 'languages:FETCH_LANGUAGES_IF_NECESSARY';
export function fetchLanguagesIfNecessary() {
  return {
    type: FETCH_LANGUAGES_IF_NECESSARY
  };
}
