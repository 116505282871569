import {call} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {processResponse} from 'utils/saga/fetchUtils';
import {entitlementsEndpoint} from 'modules/entitlements/entitlementUtils';
import {receiveEntitlements, entitlementsError} from 'modules/entitlements';

export default function* fetchEntitlements() {
  const res = yield call(fetch, entitlementsEndpoint());
  yield processResponse(res, {
    successAction: receiveEntitlements,
    errorAction: entitlementsError
  });
  return res;
}
