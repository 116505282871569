import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {paymentSettingsPath} from 'modules/settings/settingsUtils';
import upgraderStyles from 'components/layoutComponents/upgrader/upgrader.scss';
import {isPastDueSelector} from 'modules/subscriptions/subscriptionSelectors';
import {shallPass, ACCOUNT_OWNER} from 'modules/auth/gandalf';
import {userSelector} from 'modules/auth/authSelectors';
import {push} from 'modules/location';

function PastDueBanner({pastDue, accountOwner, onClickCardDetails}) {
  if (!pastDue || !accountOwner) return null;

  return (
    <div className={cx(upgraderStyles.banner, upgraderStyles.noHover, upgraderStyles.middle)}>
      <p>
        There was an issue charging the payment card on file, please update your{' '}
        <a onClick={onClickCardDetails}>card details</a>.
      </p>
    </div>
  );
}

export default connect(
  createSelector(
    userSelector,
    isPastDueSelector,
    (user, pastDue) => ({pastDue, accountOwner: shallPass(user, ACCOUNT_OWNER)})
  ),
  dispatch => ({
    onClickCardDetails: event => {
      dispatch(push(paymentSettingsPath));
    }
  })
)(PastDueBanner);
