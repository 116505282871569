import PropTypes from 'prop-types';
import React from 'react';
import ListBox from 'components/listbox';

export default class ListBoxInput extends React.Component {
  render() {
    const {value, ...props} = this.props;

    return <ListBox {...props} selected={value || []} />;
  }
}
