import React from 'react';
import cx from 'classnames';
import styles from './navbar.scss';
import HomeIcon from 'components/homeIcon';
import Center from 'components/center';

const OnboardingNavbar = ({className}) => {
  return (
    <header className={cx(styles.navbar, className)}>
      <Center>
        <HomeIcon className={styles.homeIcon} />
      </Center>
    </header>
  );
};

export default OnboardingNavbar;
