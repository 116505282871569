exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2cyrDdvD1jq9T0lv2zcTHT{height:100%}._2cyrDdvD1jq9T0lv2zcTHT ._3hfEfy4KljoLG-OSiCyyza{width:30px;height:30px;border-radius:30px;line-height:30px;text-align:center;background:#371ecc;color:#fff}._2cyrDdvD1jq9T0lv2zcTHT._3jQmk2l85OKRYNuSFWzXa3{-webkit-transform:scale(0.8);transform:scale(0.8)}._2cyrDdvD1jq9T0lv2zcTHT._3kdPdHBdgPTC3MQ4aftXWe ._3hfEfy4KljoLG-OSiCyyza{background:#df1616}", ""]);

// exports
exports.locals = {
	"notificationsButton": "_2cyrDdvD1jq9T0lv2zcTHT",
	"circle": "_3hfEfy4KljoLG-OSiCyyza",
	"small": "_3jQmk2l85OKRYNuSFWzXa3",
	"unread": "_3kdPdHBdgPTC3MQ4aftXWe"
};