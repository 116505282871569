import {delay} from 'redux-saga';
import {put, takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {
  FETCH_UNREAD_ACHIEVEMENTS,
  fetchUnreadAchievementsComplete,
  receiveAchievements
} from 'modules/achievements/achievements';
import {unreadAchievementsEndpoint} from 'modules/achievements/achievementUtils';

export default function* fetchUnreadAcievementsSaga() {
  yield takeEvery(FETCH_UNREAD_ACHIEVEMENTS, fetchUnreadAchievements);
}

function* fetchUnreadAchievements(action) {
  try {
    const unreadAchievements = yield requestUnreadAchievements();

    yield put(fetchUnreadAchievementsComplete());

    // This action is fired on LOGIN, which is called pretty early.
    // So we want to wait for the page to finish painting before we receive a load of achievements.
    // Ideally we'd have a PAGE_PAINTED event or something.

    if (Array.isArray(unreadAchievements) && unreadAchievements.length) {
      yield delay(3000);
      yield put(receiveAchievements(unreadAchievements));
    }
  } catch (err) {
    yield put(fetchUnreadAchievementsComplete(err.message));
  }
}

function* requestUnreadAchievements() {
  return yield processRequest('GET', unreadAchievementsEndpoint(), {
    success: function*(unreadAchievements) {
      return unreadAchievements;
    },
    error: function*(err) {
      throw err;
    }
  });
}
