import _ from 'lodash';
import React from 'react';
import Pill from 'components/pill';

class Count extends React.Component {
  render() {
    const {result, config} = this.props;
    const {appearance} = config;
    const className = 'minor';
    let number;

    if (_.isNull(result)) {
      number = '-';
    } else if (_.isUndefined(result.numRows)) {
      number = _.size(result);
    } else {
      number = result.numRows;
    }

    return (
      <Pill className={className} showZero={true} appearance={appearance} numeric>
        {number}
      </Pill>
    );
  }
}

Count.handlesLoading = true;

export default Count;
