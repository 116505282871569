import React, {Fragment} from 'react';
import Margin from 'components/margin';
import Table from 'components/table';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import Dropdown from 'components/dropdown/dropdown';
import Link from 'modules/location/link';
import {usersPath} from 'modules/users/userUtils';
import Status from 'components/status';

const titleMap = {
  'last-7-days': 'Last 7 days',
  'last-30-days': 'Last 30 days',
  'last-year': 'Last year'
};

const dataMap = {
  'last-7-days': 'usersWithMostDecisions7Days',
  'last-30-days': 'usersWithMostDecisions30Days',
  'last-year': 'usersWithMostDecisions1Year'
};

const dropdownOptions = Dropdown.mapOptionsObject(titleMap);

export default function UserDecisionTable({userDecisionsSummary, usernameMap, filterByUser}) {
  const [currentTable, setCurrentTable] = React.useState('last-30-days');
  const userDecisions = userDecisionsSummary[dataMap[currentTable]];
  const cols = [
    {
      label: 'User',
      field: 'userName',
      type: 'jsx',
      jsx: ({data}) => (
        <Link builder={usersPath} query={{search: data}}>
          {data}
        </Link>
      )
    },
    {label: 'Number of decisions', field: 'decisionCount', type: 'string'}
  ];

  if (typeof filterByUser === 'function') {
    cols.push({
      type: 'jsx',
      width: '80px',
      jsx: ({record}) => {
        return <a onClick={() => filterByUser(record.userId)}>View</a>;
      }
    });
  }

  const noResults = Array.isArray(userDecisions) && !userDecisions.length;
  const rows = noResults
    ? []
    : userDecisions.map(row => {
        return {...row, userName: usernameMap.get(row.userId.toString(), 'Unknown')};
      });

  return (
    <Fragment>
      <Margin margin="10px">
        <Flex container>
          <Flex right>
            <Dropdown
              button={
                <Button icon="caret-down" wire>
                  {titleMap[currentTable]}
                </Button>
              }
              options={dropdownOptions}
              onChange={value => setCurrentTable(value)}
            />
          </Flex>
        </Flex>
      </Margin>
      {noResults ? (
        <Status appearance="info">No decisions have been made yet</Status>
      ) : (
        <Table rows={rows} columns={cols} />
      )}
    </Fragment>
  );
}
