import React from 'react';
import styles from './match.scss';

class Match extends React.Component {
  render() {
    const {record} = this.props;
    const {match, before, after} = record;

    if (!match) return <em>None</em>;

    return (
      <div>
        <span>{before}</span>
        <span className={styles.match}>{match}</span>
        <span>{after}</span>
      </div>
    );
  }
}

export default Match;
