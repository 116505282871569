import {select} from 'redux-saga/effects';
import {entitlementDataSelector} from 'modules/entitlements/entitlementSelectors';
import {showError} from 'utils/saga/alerts';

// Shows an error alert if the entitlement isn't available.
export default function* ensureEntitlement(summarizedId) {
  const entitlements = yield select(entitlementDataSelector);
  const entitlement = entitlements.find(
    entitlement => entitlement.get('summarizedId') == summarizedId
  );

  const hasEntitlement =
    entitlement && (entitlement.get('isInfinite') || entitlement.get('remaining') > 0);

  if (!hasEntitlement) {
    yield showError('You do not have any of those products available');
  }

  return hasEntitlement;
}
