import {delay} from 'redux-saga';
import {select, take, put, takeLatest} from 'redux-saga/effects';
import {VIEW_FOLDERS_INDEX_PAGE, RECEIVE_FOLDERS} from 'modules/folders';
import {folderPath} from 'modules/folders/folderUtils';
import {foldersSelector} from 'modules/folders/folderSelectors';
import {replace} from 'modules/location';
import selectOrWait from 'utils/saga/selectOrWait';

export default function* redirectToFirstFolderSaga() {
  yield takeLatest(VIEW_FOLDERS_INDEX_PAGE, redirectToFirstFolder);
}

// If we view /folders without a folderId, redirect to first
function* redirectToFirstFolder() {
  const folders = yield selectOrWait(foldersSelector, RECEIVE_FOLDERS);

  const firstFolderId = folders.getIn([0, 'folderId']);

  // Without this delay, sometimes the redirect doesn't work.
  yield delay(200);

  yield put(replace(folderPath, {folderId: firstFolderId}));
}
