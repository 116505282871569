import React, {useState} from 'react';
import {useSelector, useDispatch} from 'hooks/redux';
import {List} from 'immutable';
import Helmet from 'components/helmet/helmet';
import PageBody from 'components/page';
import {coursePageSelector} from '../selectors';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import CourseSidebar from '../components/courseSidebar';
import Markdown from 'components/layoutComponents/renderers/markdown';
import CourseSummary from 'modules/courses/components/summary/courseSummary';
import Banner from 'components/banner';
import Button from 'components/button';
import styles from './courses.scss';
import Progress from 'components/progress';
import {enrollOnCourse, unEnrollFromCourse} from 'modules/courses/actions';
import CourseTitle from '../components/title/courseTitle';
import Tutors from '../components/tutors/tutors';
import StudentsTable from '../components/students/studentsTable';
import moment from 'moment';
import Icon from 'components/font-awesome';
import NextLessonButton from 'modules/courses/components/buttons/nextLessonButton';
import EnrollStudentsModalButton from 'modules/courses/components/buttons/enrollStudentsModalButton';
import Flex from 'components/layout/flex';
import Gandalf from 'components/gandalf/gandalf';
import {SITE_CREATOR, ACCOUNT_OWNER, REGULAR_USER} from 'modules/auth/gandalf';

function CourseProgress({finished, percentComplete, percentCorrect}) {
  const finishedDate = moment.unix(finished);

  return (
    <div>
      {!!finished && (
        <div className={styles.completed}>
          You completed this course on {finishedDate.format('Do MMM YYYY')}.
        </div>
      )}
      <div>
        <div className={styles.progress}>
          <p>
            <Icon name="fas fa-check" className={styles.progressComplete} />{' '}
            {Math.round(percentComplete)}% complete
          </p>
          <Progress
            value={percentComplete}
            denominator={100}
            color="#55d272"
            className={styles.progressBar}
            displayAsPercentage
          />
        </div>
        <div className={styles.progress}>
          <p>
            <Icon name="fas fa-star" className={styles.progressCorrect} />{' '}
            {Math.round(percentCorrect)}% correct
          </p>
          <Progress
            value={percentCorrect}
            denominator={100}
            color="#45a1f7"
            className={styles.progressBar}
            displayAsPercentage
          />
        </div>
      </div>
    </div>
  );
}

export default function CoursePage({
  match: {
    params: {courseId}
  }
}) {
  const dispatch = useDispatch();
  const {course, requestingCourse, academyScoresOwnerOnly} = useSelector(coursePageSelector, {
    courseId
  });
  const sidebar = <CourseSidebar course={course} />;
  const description = course.get('description');
  const started = course.get('started');
  const finished = course.get('finished');
  const percentComplete = course.get('percentComplete');
  const percentCorrect = course.get('percentCorrect');
  const enrolled = started > 0;

  // @todo We default these to List() here because some unexpected behaviour in
  // our Sagas can leave them undefined.
  //
  // Right now if we request the list of all Courses first (e.g. we visit the Academy
  // homepage) the list of Courses we keep in memory won't contain a tutors or students
  // property (as these are expensive for the backend to send). But if we view the Course
  // page directly, they will.
  //
  // To get around this, I've temporarily made Wheatley pass all of these properties, mashed
  // together, in the /courses API response. This is not performant, as it's essentially the
  // same as requesting every Course in parallel with all of their properties.
  //
  // @todo Ask Alex MF for help
  // @todo Also related: holy shit look at our follow/unfollow Sagas
  const tutors = course.get('tutors', List());
  const students = course.get('students', List());

  const [activeTab, setActiveTab] = useState('syllabus');

  const setTab = tab => {
    if (tab !== activeTab) setActiveTab(tab);
  };

  return (
    <div className={styles.background}>
      <Helmet title={course.get('name')} />
      <PageWithSidebar sidebar={null}>
        <div className={styles.container}>
          <PageBody isLoading={requestingCourse.get('loading')}>
            <CourseTitle course={course} />

            <div className={styles.banner}>
              <Banner src={course.get('imageUrl')} opacity={1} height={260} />
            </div>

            {!!enrolled && (
              <div>
                <h2 id="progress">Your progress</h2>

                <div className={styles.panel}>
                  <div className={styles.meta}>
                    {!!course.get('nextLessonId') && (
                      <div className={styles.start}>
                        <NextLessonButton course={course} />
                      </div>
                    )}
                    <div className={styles.progress}>
                      <CourseProgress
                        finished={finished}
                        percentComplete={percentComplete}
                        percentCorrect={percentCorrect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!!enrolled && <h2 id="syllabus">Course overview</h2>}
            <div className={styles.panel}>
              <div className={styles.meta}>
                <div className={styles.description}>
                  <div className={styles.descriptionText}>
                    <Markdown data={description} />
                  </div>
                </div>

                <div className={styles.status}>
                  {!enrolled && (
                    <Button
                      icon="fas fa-check"
                      onClick={() => dispatch(enrollOnCourse(course.get('courseId')))}
                      good
                      className={styles.bigButton}
                    >
                      Take this course
                    </Button>
                  )}
                </div>
              </div>
              <CourseSummary course={course} enrolled={enrolled} />
            </div>

            <Gandalf shallPass={academyScoresOwnerOnly ? ACCOUNT_OWNER : REGULAR_USER}>
              <Flex container>
                <Flex grow={1}>
                  <h2 id="students">Students</h2>
                </Flex>
                <Flex>
                  <Gandalf shallPass={SITE_CREATOR}>
                    <EnrollStudentsModalButton courseId={courseId} />
                  </Gandalf>
                </Flex>
              </Flex>

              <div className={styles.panel}>
                {!!students.size && (
                  <div>
                    <StudentsTable students={students} />
                  </div>
                )}
                {!students.size && (
                  <p>No students have enrolled on this course yet. Why not be the first one?</p>
                )}
              </div>
            </Gandalf>

            {!!tutors.size && (
              <div>
                <h2 id="syllabus">Tutors</h2>
                <div className={styles.panel}>
                  <div className={styles.tutors}>
                    <Tutors tutors={tutors} />
                  </div>
                </div>
              </div>
            )}

            {!!enrolled && (
              <div className={styles.panel}>
                <p>
                  Changed your mind?{' '}
                  <a onClick={() => dispatch(unEnrollFromCourse(course.get('courseId')))}>
                    Unenrol from this course.
                  </a>
                </p>
              </div>
            )}
          </PageBody>
        </div>
      </PageWithSidebar>
    </div>
  );
}
