import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {LOGOUT, SWITCH_ACCOUNT} from 'modules/auth';
import {
  ACCOUNT_OPTIONS,
  DEFAULT_SCHEDULE,
  DEFAULT_ROLE_FOR_EVERYONE,
  ACCESSIBILITY_STANDARD
} from './accountOptionConstants';

const INITIAL_STATE = fromJS({
  data: {
    [DEFAULT_SCHEDULE]: {
      option: DEFAULT_SCHEDULE,
      value: ACCOUNT_OPTIONS[DEFAULT_SCHEDULE],
      public: true
    },
    [DEFAULT_ROLE_FOR_EVERYONE]: {
      option: DEFAULT_ROLE_FOR_EVERYONE,
      value: ACCOUNT_OPTIONS[DEFAULT_ROLE_FOR_EVERYONE],
      public: true
    },
    [ACCESSIBILITY_STANDARD]: {
      option: ACCESSIBILITY_STANDARD,
      value: ACCOUNT_OPTIONS[ACCESSIBILITY_STANDARD],
      public: true
    }
  },
  isFetchingOptions: false,
  isUploadingImage: false,
  isDeletingImage: false
});

export default function accountOptionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT:
    case SWITCH_ACCOUNT:
      return INITIAL_STATE;

    case UPDATE_ACCOUNT_OPTIONS:
      return state.set('isUpdatingOptions', true);

    case UPDATE_ACCOUNT_OPTIONS_COMPLETE:
      return state.set('isUpdatingOptions', false);

    case FETCH_ACCOUNT_OPTIONS:
      return state.set('isFetchingOptions', true);

    case RECEIVE_ACCOUNT_OPTIONS:
      return state
        .set('isFetchingOptions', false)
        .mergeIn(['data'], keyBy(action.options, 'option'));

    case DELETE_ACCOUNT_OPTION:
      return state.deleteIn(['data', action.optionId]);

    case UPLOAD_BRANDING_LOGO:
      return state.set('isUploadingImage', true);

    case UPLOAD_BRANDING_LOGO_COMPLETE:
      return state.set('isUploadingImage', false);

    case DELETE_ACCOUNT_BRANDING_LOGO:
      return state.set('isDeletingImage', true);

    case DELETE_ACCOUNT_BRANDING_LOGO_COMPLETE:
      return state.set('isDeletingImage', false);
  }
  return state;
}

export const UPLOAD_BRANDING_LOGO = 'images:UPLOAD_BRANDING_LOGO';
export function uploadBrandingLogo(blob) {
  return {type: UPLOAD_BRANDING_LOGO, blob};
}

export const UPLOAD_BRANDING_LOGO_COMPLETE = 'images:UPLOAD_BRANDING_LOGO_COMPLETE';
export function uploadBrandingLogoComplete() {
  return {type: UPLOAD_BRANDING_LOGO_COMPLETE};
}

export const UPDATE_ACCOUNT_OPTIONS = 'accounts:UPDATE_ACCOUNT_OPTIONS';
export function updateAccountOptions(options) {
  return {type: UPDATE_ACCOUNT_OPTIONS, options};
}

export const UPDATE_ACCOUNT_OPTIONS_COMPLETE = 'accounts:UPDATE_ACCOUNT_OPTIONS_COMPLETE';
export function updateAccountOptionsComplete() {
  return {type: UPDATE_ACCOUNT_OPTIONS_COMPLETE};
}

export const FETCH_ACCOUNT_OPTIONS = 'accounts:FETCH_ACCOUNT_OPTIONS';
export function fetchAccountOptions() {
  return {type: FETCH_ACCOUNT_OPTIONS};
}

export const RECEIVE_ACCOUNT_OPTIONS = 'accounts:RECEIVE_ACCOUNT_OPTIONS';
export function receiveAccountOptions(options) {
  return {type: RECEIVE_ACCOUNT_OPTIONS, options};
}

export const DELETE_ACCOUNT_OPTION = 'accounts:DELETE_ACCOUNT_OPTION';
export function deleteAccountOption(optionId) {
  return {type: DELETE_ACCOUNT_OPTION, optionId};
}

export const DELETE_ACCOUNT_BRANDING_LOGO = 'accounts:DELETE_ACCOUNT_BRANDING_LOGO';
export function deleteAccountBrandingLogo() {
  return {type: DELETE_ACCOUNT_BRANDING_LOGO};
}

export const DELETE_ACCOUNT_BRANDING_LOGO_COMPLETE =
  'accounts:DELETE_ACCOUNT_BRANDING_LOGO_COMPLETE';
export function deleteAccountBrandingLogoComplete() {
  return {type: DELETE_ACCOUNT_BRANDING_LOGO_COMPLETE};
}
