import React from 'react';
import Table from 'components/table';
import Button from 'components/button';
import styles from '../timeline.scss';
import {createInspectorSpellingLink} from 'modules/instructions/instructionUtils';

function linkToSpelling(doCommand, record, event) {
  const command = createInspectorSpellingLink(record.props.actionId, record.props.word);
  doCommand(command, record, {}, event);
}

const COLS = [
  {
    label: '',
    type: 'jsx',
    width: '50px',
    jsx: ({doCommand, record}) => {
      return (
        <Button
          small
          square
          icon="search"
          onClick={event => linkToSpelling(doCommand, record, event)}
        />
      );
    }
  },
  {
    label: 'Word',
    field: 'props.word',
    type: 'fakeLink',
    onClick: (event, {doCommand, record}) => {
      linkToSpelling(doCommand, record, event);
    }
  },
  {
    label: 'Occurrences',
    field: 'props.numPoints',
    type: 'count',
    appearance: 'minor'
  }
];

export default function NewSpelling({story, storyPoints, doCommand}) {
  return (
    <React.Fragment>
      <div className={styles.table}>
        <Table
          cellParams={{doCommand}}
          rowKey="storyPointId"
          columns={COLS}
          rows={Object.keys(storyPoints).map(storyPointId => storyPoints[storyPointId])}
        />
      </div>
    </React.Fragment>
  );
}
