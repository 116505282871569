import React from 'react';
import Flex from 'components/layout/flex';
import Select from 'components/select';
import Button from 'components/button';
import Help from 'components/help';

export default function UsersSelectbox({users, onSelectUser, button, buttonLabel, helpMessage}) {
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    const firstUser = users[0];
    setValue(firstUser ? firstUser.userId : null);
  }, [users]);

  return (
    <React.Fragment>
      <Flex container bottom={15}>
        <Flex basis="300px" right={15}>
          <Select
            clearable={false}
            disableFiltering
            value={value}
            onChange={option => setValue(option ? option.value : null)}
            options={users.map(user => ({
              value: user.userId,
              label: user.name
            }))}
          />
        </Flex>
        <Flex margin="0 0 10px 20px">
          {button || (
            <Button
              onClick={() => {
                onSelectUser(value);
              }}
            >
              {buttonLabel || 'Select user'}
            </Button>
          )}
        </Flex>
        {helpMessage && (
          <Flex margin="0 0 10px 10px">
            <Help message={helpMessage} />
          </Flex>
        )}
      </Flex>
    </React.Fragment>
  );
}
