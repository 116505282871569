import cx from 'classnames';
import _ from 'lodash';
import React from 'react';
import styles from './row.scss';

class Row extends React.Component {
  render() {
    const {config = {}} = this.props;
    const {noIndent} = config;
    const columnCount = React.Children.count(this.props.children);

    const classes = cx(this.props.className, styles.row, {
      [`row-of-${columnCount}`]: true,
      [styles.noIndent]: noIndent
    });

    return <div className={classes}>{this.props.children}</div>;
  }
}

export default Row;
