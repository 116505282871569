import {Map, fromJS} from 'immutable';
import {createSelector} from 'reselect';
import {makeLoadingStateSelector} from 'modules/app/reduxHelpers';
import {getCourseLesson} from 'modules/courses/utils';
import {academyScoresOwnerOnlySelector} from 'modules/accountOptions/accountOptionSelectors';
import {createPrioritisedRouteParamPropSelector} from 'modules/location/locationSelectors';

const domainSelector = state => state.courses;

// will try to select a courseId from props.courseId, then props.params.courseId, then props.match.params.courseId
const courseIdSelector = createPrioritisedRouteParamPropSelector('courseId');
const stageIdSelector = createPrioritisedRouteParamPropSelector('stageId');
const courseLessonIdSelector = createPrioritisedRouteParamPropSelector('lessonId');

export const coursesSelector = createSelector(
  domainSelector,
  state => {
    return state.get('courses', Map());
  }
);

export const courseSelector = createSelector(
  coursesSelector,
  courseIdSelector,
  (courses, courseId) => {
    return courses.get(courseId.toString());
  }
);

export const stageSelector = createSelector(
  courseSelector,
  stageIdSelector,
  (course, stageId) => {
    return course.get('stages').find(stage => stage.get('stageId') == stageId);
  }
);

export const lessonSelector = createSelector(
  coursesSelector,
  courseLessonIdSelector,
  (courses, lessonId) => {
    return getCourseLesson(courses, lessonId);
  }
);

export const nextLessonSelector = createSelector(
  courseSelector,
  stageSelector,
  lessonSelector,
  (course, stage, lesson) => {
    // find next lesson that isn't complete
    const sortedLessons = stage.get('lessons').sortBy(l => l.get('sortOrder'));
    const index = sortedLessons.findIndex(l => l.get('lessonId') === lesson.get('lessonId'));
    let offset = 1; // 1 because we want to start at the next

    while (index + offset < sortedLessons.size) {
      const state = sortedLessons.get(index + offset).get('state');

      if (state !== 'completed') {
        return sortedLessons.get(index + offset);
      }

      offset++;
    }

    // if not, find next stage that isn't complete
    const sortedStages = course.get('stages').sortBy(s => s.get('sortOrder'));
    const stageIndex = sortedStages.findIndex(s => s.get('stageId') === lesson.get('stageId'));

    // if not found or last
    if (stageIndex < 0 || stageIndex === sortedStages.size - 1) {
      return null;
    }

    // return the first lesson in the next stage
    return sortedStages
      .get(stageIndex + 1)
      .get('lessons')
      .first();
  }
);

export const requestingCoursesSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingCourses'
);
export const requestingCourseSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingCourse'
);
export const requestingLessonSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingLesson'
);

export const savingLessonSelector = createSelector(
  domainSelector,
  state => {
    return state.get('savingLesson', {});
  }
);

export const savingQuizSelector = createSelector(
  domainSelector,
  state => {
    return state.get('savingQuiz', {});
  }
);

export const enrollingOnCourseSelector = makeLoadingStateSelector(
  domainSelector,
  'requestingEnrollment'
);

export const enrollingUsersSelector = makeLoadingStateSelector(domainSelector, 'enrollingUsers');

// ===================================== Direct component selectors =====================================

export const coursesPageSelector = createSelector(
  coursesSelector,
  requestingCoursesSelector,
  (courses, requestingCourses) => ({
    courses,
    requestingCourses
  })
);

export const coursePageSelector = createSelector(
  courseSelector,
  requestingCourseSelector,
  // enrollingOnCourseSelector,
  academyScoresOwnerOnlySelector,
  (course, requestingCourse, academyScoresOwnerOnly) => ({
    course,
    requestingCourse,
    academyScoresOwnerOnly
  })
);

export const courseLessonPageSelector = createSelector(
  courseSelector,
  lessonSelector,
  requestingLessonSelector,
  savingLessonSelector,
  savingQuizSelector,
  (course, lesson, requestingLesson, savingLesson, savingQuiz) => {
    return {
      course,
      lesson,
      requestingLesson,
      savingLesson,
      savingQuiz
    };
  }
);
