import React from 'react';
import {connect} from 'react-redux';
import {changeActionState} from 'modules/actions/actions';
import Button from 'components/button';
import styles from './ignoreActionButton.scss';
import {reportSelector} from 'modules/reports/reportSelectors';
import {ROLE_CONTRIBUTOR, reportIsAllowed} from 'modules/gandalf/gandalfUtils';

export class IgnoreActionButton extends React.Component {
  handleChangeState = e => {
    const {reportId, actionId, state} = this.props;
    const newState = state === 'ignored' ? 'open' : 'ignored';
    this.props.changeActionState(reportId, actionId, newState);
  };

  render() {
    const {state, reportRole} = this.props;

    if (!reportIsAllowed(ROLE_CONTRIBUTOR, reportRole)) {
      return null;
    }

    if (state === 'ignored') {
      return (
        <Button
          onClick={this.handleChangeState}
          square
          small
          wire
          className={styles.ignoreButton}
          icon="check"
        >
          Stop ignoring
        </Button>
      );
    }
    return (
      <Button
        onClick={this.handleChangeState}
        square
        small
        wire
        className={styles.ignoreButton}
        icon="times"
      >
        Ignore this action
      </Button>
    );
  }
}

export default connect(
  (state, props) => ({
    reportRole: reportSelector(state, props).getIn(['ownPermissions', 'role'])
  }),
  {changeActionState}
)(IgnoreActionButton);
