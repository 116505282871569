import React from 'react';
import {connect} from 'react-redux';
import {replaceUpdateQuery} from 'modules/location';
import ButtonSwitcher from 'components/button/buttonSwitcher';
import {setNoRedirectInspectorOnMissing, setEffect} from 'modules/inspector/inspectorActions';
import {inspectorViewSwitcherSelector} from 'modules/inspector/inspectorSelectors';

const ViewButtons = {
  page: 'Page',
  // Desktop: 'Desktop',
  // iPhone5Portrait: 'Mobile',
  // iPhone5Landscape: 'Mobile Landscape',
  // tablet: 'Tablet',
  source: 'HTML'
};

class InspectorViewSwitcher extends React.Component {
  handleChangeView = pageView => {
    const {
      replaceUpdateQuery,
      setNoRedirectInspectorOnMissing,
      setEffect,
      enabledEffects
    } = this.props;

    if (pageView.toLowerCase() === 'source') {
      enabledEffects.forEach(effectId => {
        setEffect(effectId, false);
      });
    }

    setNoRedirectInspectorOnMissing(true);
    replaceUpdateQuery({pageView: this.getTempPageView(pageView)});
  };

  getTempPageView(pageView) {
    // NOTE temporarily simplifying until we can clean this up properly
    return pageView === 'Desktop' || pageView === 'iPhone5Portrait' || pageView === 'Mobile'
      ? 'page'
      : pageView;
  }

  render() {
    const {pageView} = this.props;

    return (
      <ButtonSwitcher
        selected={this.getTempPageView(pageView)}
        buttons={ViewButtons}
        onChange={this.handleChangeView}
      />
    );
  }
}

export default connect(
  inspectorViewSwitcherSelector,
  {replaceUpdateQuery, setNoRedirectInspectorOnMissing, setEffect}
)(InspectorViewSwitcher);
