import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {requestTagsComplete} from 'modules/tags/actions';
import {REQUEST_TAGS} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* requestTagsListener() {
  yield takeLatest(REQUEST_TAGS, requestTags);
}

function* requestTags(action) {
  try {
    const pagedTags = yield processRequest('GET', 'tags');
    yield put(requestTagsComplete(null, pagedTags));
  } catch (err) {
    yield put(requestTagsComplete(err));
  }
}
