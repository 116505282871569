import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Tab from 'components/tabs/tab';
import {testPath} from 'modules/tests/testUtils';
import styles from './tabHead.scss';
import LayoutComponent from 'modules/tests/components/layoutComponent';
import Dropdown from 'components/dropdown';
import Icon from 'components/font-awesome';
import {getActiveTab, getTabFromLayoutChildren} from 'components/tabs/tab';
import {havePropsChanged} from 'utils/propUtils';

export class TabHead extends React.Component {
  static defaultProps = {
    maxLabels: 3
  };

  componentWillReceiveProps(nextProps) {
    if (havePropsChanged(nextProps, this.props, ['filter'])) {
      if (nextProps.filter.tab && nextProps.filter.tab.toLowerCase() !== nextProps.params.tabId) {
        const {tab = 'summary', ...otherFilters} = nextProps.filter;
        this.props.push(testPath, {tab: tab.toLowerCase(), filter: otherFilters}, [
          'reportId',
          'testId'
        ]);
      }
    }
  }

  handleClickTab = tab => event => {
    const {filter} = this.props;

    this.props.push(testPath, {tab: tab.id, filter}, ['reportId', 'testId']);
  };

  handleClickMoreTab = (tabValue, event) => {
    const {config} = this.props;

    const tab = getTabFromLayoutChildren(config.children, tabValue);
    if (tab) {
      this.handleClickTab(tab)(event);
    }
  };

  renderTabHead = tab => {
    const {config, filter, params} = this.props;
    const activeTab = getActiveTab(this.props.params, config);

    return (
      <Tab key={tab.componentId} selected={tab.id === activeTab} onClick={this.handleClickTab(tab)}>
        <LayoutComponent
          config={tab}
          filter={filter}
          params={params}
          setFilterParams={this.props.setFilterParams}
        />
      </Tab>
    );
  };

  render() {
    const {config, params, filter} = this.props;
    const firstButtons = config.children.slice(0, this.props.maxLabels).map(this.renderTabHead);
    const dropdownButtons = config.children.slice(this.props.maxLabels).map(tab => ({
      value: tab.id,
      label: (
        <LayoutComponent
          config={tab}
          filter={filter}
          params={params}
          setFilterParams={this.props.setFilterParams}
        />
      )
    }));

    const dropdownIds = dropdownButtons.map(option => option.value);
    const activeTab = getActiveTab(params, config);
    const tabButton = (
      <Tab selected={dropdownIds.includes(activeTab)}>
        More <Icon name="caret-down" />
      </Tab>
    );

    return (
      <div className={styles.tabHead}>
        {firstButtons}
        {!!dropdownButtons.length && (
          <Dropdown
            preferSide="bottom"
            button={tabButton}
            options={dropdownButtons}
            onChange={this.handleClickMoreTab}
          />
        )}
      </div>
    );
  }
}

export default connect(
  null,
  {push}
)(TabHead);
