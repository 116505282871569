import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Gravatar from 'react-gravatar';
import styles from './userImage.scss';
import cx from 'classnames';
import Flex from 'components/layout/flex';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Spinner from 'components/spinner';
import {userSelector} from 'modules/users/userSelectors';

const DEFAULT_IMAGE = 'https://s3.amazonaws.com/assets.silktide.com/insites/person-placeholder.png';
const CLEARBIT_IMAGE = 'https://logo.clearbit.com/';

function getImageUrl(domain) {
  return !domain ? DEFAULT_IMAGE : CLEARBIT_IMAGE + domain;
}

export class UserImage extends React.Component {
  static defaultProps = {
    // if no size is defined set a default so we don't end up with different sized images
    size: 50
  };

  state = {
    imageError: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.email !== this.props.user.email) {
      this.setState({imageError: false});
    }
  }

  getClassName() {
    const {className, inline} = this.props;

    return cx(styles.userImage, {[styles.inline]: inline}, className);
  }

  onImageError = () => {
    this.setState({
      imageError: true
    });
  };

  renderImageTag(src, alt) {
    const {size, className, user, ...props} = this.props;
    return (
      <img
        {...filterInvalidDOMProps(props)}
        src={src}
        alt={alt}
        width={size}
        height={size}
        className={this.getClassName()}
      />
    );
  }

  renderGravatar() {
    const {size, user, ...props} = this.props;
    const {email} = user;

    if (!_.isString(email) || !email.length) {
      return this.renderImageTag(DEFAULT_IMAGE, email);
    }

    const pos = email.indexOf('@');
    const domain = pos < 0 ? null : email.substr(pos + 1);

    // if `email` has no '@', the Clearbit logo is not attempted
    const defaultImageUrl = getImageUrl(domain);

    // Gravatar only fires `onError` if (no avatar is found for `email` AND `default` is not usable)
    // Therefore `onImageError` only fires if both Gravatar and Clearbit APIs fail
    return (
      <Gravatar
        {...filterInvalidDOMProps(props)}
        email={email}
        alt={email}
        size={size}
        default={defaultImageUrl}
        className={this.getClassName()}
        onError={this.onImageError}
      />
    );
  }

  renderIcon = () => {
    const {imageError} = this.state;
    const {user, size} = this.props;

    // handle group image
    // TODO: find better place for this
    if (user.isGroup) {
      return <img src={require('./group-icon.png')} width={size} alt="user profile" />;
    }

    if (imageError) {
      return this.renderImageTag(DEFAULT_IMAGE, user.email);
    }

    return this.renderGravatar();
  };

  render() {
    const {user, children, inline} = this.props;

    if (!user) return <Spinner />;

    if (typeof this.props.children === 'function') {
      return (
        <Flex container row={inline} column={!inline}>
          <Flex>{this.renderIcon()}</Flex>
          <Flex style={{marginLeft: '10px'}}>{children(user)}</Flex>
        </Flex>
      );
    }

    return this.renderIcon();
  }
}

UserImage.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.number
};

export default connect((state, {user, ...props}) => {
  // if user isnt passed, attempt to use user selector
  // with userId
  if (!user) {
    return userSelector(state, props);
  }

  return {user};
})(UserImage);
