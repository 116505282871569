import {REQUEST_BALANCES, REQUEST_BALANCES_COMPLETE, RECEIVE_BALANCES} from './constants';

export function requestBalances(reportId, {sku, serviceId}) {
  return {type: REQUEST_BALANCES, reportId, sku, serviceId};
}

export function requestBalancesComplete() {
  return {type: REQUEST_BALANCES_COMPLETE};
}

export function recieveBalances(balances = {}) {
  return {type: RECEIVE_BALANCES, balances};
}
