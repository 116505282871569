import {
  FETCH_SEARCH_RESULTS,
  RECEIVE_SEARCH_RESULTS,
  FETCH_RECENT_REPORTS,
  RECEIVE_RECENT_REPORTS,
  APPEND_RECENT_REPORTS,
  NO_MORE_RECENT_REPORTS,
  RECENT_REPORTS_LIMIT
} from './constants';

export function fetchSearchResults(
  searchTerm,
  tags = [],
  {debounce = true, limit = 10, offset = 0, append = false} = {}
) {
  return {
    type: FETCH_SEARCH_RESULTS,
    searchTerm: searchTerm.trim(),
    tags,
    shouldDebounce: debounce,
    limit,
    offset,
    append
  };
}

export function receiveSearchResults(reports = [], append = false) {
  return {type: RECEIVE_SEARCH_RESULTS, reports, append};
}

export function fetchRecentReports({
  limit = RECENT_REPORTS_LIMIT,
  offset = 0,
  append = false
} = {}) {
  return {type: FETCH_RECENT_REPORTS, limit, offset, append};
}

export function receiveRecentReports(reports = []) {
  return {type: RECEIVE_RECENT_REPORTS, reports};
}

export function appendRecentReports(reports = []) {
  return {type: APPEND_RECENT_REPORTS, reports};
}

export function noMoreRecentReports() {
  return {type: NO_MORE_RECENT_REPORTS};
}
