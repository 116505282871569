import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {unassignTagsComplete} from 'modules/tags/actions';
import {UNASSIGN_TAGS} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';
import {tagsChanged} from '../utils';

export default function* assignTagsListener() {
  yield takeLatest(UNASSIGN_TAGS, unassignTags);
}

function* unassignTags({entityType, entityId, tagIds, callback}) {
  try {
    yield processRequest('DELETE', `assigned-tags/${entityType}/${entityId}`, {}, {tagIds});
    yield put(unassignTagsComplete(null, entityType, entityId, tagIds));

    yield call(tagsChanged, entityType, entityId, tagIds, 'remove');
    if (callback) yield call(callback, true);
  } catch (err) {
    yield put(unassignTagsComplete(err));
    if (callback) yield call(callback, false);
  }
}
