import {Map, fromJS} from 'immutable';

const initialState = Map({
  location: null
});

export default function geolocationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_LOCATION:
      return state.set('location', action.location);
  }

  return state;
}

export const RECEIVE_LOCATION = 'geolocation:RECEIVE_LOCATION';
export function receiveLocation(location) {
  return {
    type: RECEIVE_LOCATION,
    location
  };
}

export const GEOLOCATE = 'geolocation:GEOLOCATE';
export function geolocate() {
  return {
    type: GEOLOCATE
  };
}
