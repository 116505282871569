const DEMO_URL = 'https://silktide.com/request-a-demo/';
const SOCITM_URL = 'https://silktide.com/socitm-upgrades/';

const SOCITM_ACCOUNTS = [
  '5645', // - Councils
  '5646', // - NHS Trust
  '5622', // - UK Universities
  '5806', // - Fire and Rescue
  '5810', // - Police Forces
  '5811' // - UK Ambulances
];

const isSocitmAccount = accountId => SOCITM_ACCOUNTS.includes(accountId + '');

const getSalesUrl = accountId => (isSocitmAccount(accountId) ? SOCITM_URL : DEMO_URL);

const contactSales = accountId => window.open(getSalesUrl(accountId));

export {contactSales, getSalesUrl, isSocitmAccount};
