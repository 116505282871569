exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1S1tAGMz1NUwD6VJhGCdpy{text-align:center;margin:5px 0 0}", ""]);

// exports
exports.locals = {
	"caption": "_1S1tAGMz1NUwD6VJhGCdpy"
};