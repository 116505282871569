import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import pusher from 'modules/pusher/decorators/pusherDecorator';
import Achievement from 'components/achievement';
import styles from './userAchievement.scss';
import {receiveAchievements, hideAchievement} from 'modules/achievements';
import {currentAchievementSelector} from 'modules/achievements/achievementSelectors';
import {userChannel} from 'modules/pusher/channels';

export class UserAchievement extends React.Component {
  handleClickAchievement = event => {
    this.props.hideAchievement();
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.achievement != this.props.achievement;
  }

  render() {
    const {achievement} = this.props;

    if (!achievement) return null;

    return (
      <div className={styles.userAchievement}>
        <Achievement
          onClick={this.handleClickAchievement}
          title={achievement.get('name')}
          src={achievement.get('src')}
          icon={achievement.get('icon')}
          description={achievement.get('description')}
          animate
        />
      </div>
    );
  }
}

export default compose(
  connect(
    currentAchievementSelector,
    {hideAchievement}
  ),
  pusher(userChannel, 'achievement', achievements => {
    if (Array.isArray(achievements)) {
      return receiveAchievements(achievements);
    }
    return receiveAchievements([achievements]);
  })
)(UserAchievement);
