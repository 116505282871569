import {testPath} from 'modules/tests/testUtils';

export function actionEndpoint({reportId, actionId}) {
  return `reports/${reportId}/actions/${actionId}`;
}

export function commentsEndpoint() {
  return actionEndpoint(...arguments) + '/comments';
}

export function commentEndpoint({actionEventId}) {
  return commentsEndpoint(...arguments) + `/${actionEventId}`;
}

export function changeActionStateEndpoint() {
  return actionEndpoint(...arguments) + '/state';
}

export function actionPath({actionId}) {
  let [path, query] = testPath(...arguments).split('?');
  query = query ? `?${query}` : '';
  return path + `/actions/${actionId}${query}`;
}
