import React from 'react';
import styles from './fields.scss';
import cx from 'classnames';
import Icon from 'components/font-awesome';

export default class Fields extends React.Component {
  constructor(options) {
    super(options);
    this.state = {showAll: false};
  }

  handleShowClick = e => {
    e.stopPropagation();
    this.setState({showAll: true});
  };

  handleHideClick = e => {
    e.stopPropagation();
    this.setState({showAll: false});
  };

  render() {
    const {fields, max = 2} = this.props;
    if (!fields) return null;

    const total = fields.length;
    const amountToRender = this.state.showAll ? total : max;
    const leftToShow = total - amountToRender;
    const showFields = fields.slice(0, amountToRender);

    return (
      <div>
        <div className={styles.fields}>
          {showFields.map(field => {
            return (
              <div key={field.label} className={cx(styles.field, styles[field.type])}>
                {field.label}
              </div>
            );
          })}
        </div>
        <div className={styles.showMore}>
          {leftToShow > 0 && <Icon name="fal fa-plus-square" onClick={this.handleShowClick} />}
          {this.state.showAll && <Icon name="fal fa-minus-square" onClick={this.handleHideClick} />}
        </div>
      </div>
    );
  }
}
