import React from 'react';
import MediaComponent from 'components/media';

export default class Media extends React.Component {
  render() {
    const {
      data,
      record,
      options: {datatype, positive, negative, small}
    } = this.props;
    if (!datatype || !data) {
      return null;
    }

    const srcType = record[datatype];

    const additionalAttributes = {};

    if (positive !== undefined) {
      additionalAttributes.positive = true;
    }

    if (negative !== undefined) {
      additionalAttributes.negative = true;
    }

    if (small !== undefined) {
      additionalAttributes.small = true;
    }

    return <MediaComponent src={data} srcType={srcType} {...additionalAttributes} />;
  }
}
