import _ from 'lodash';
import cx from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import SearchBar from 'components/searchBar';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import styles from './search.scss';

export class Search extends React.Component {
  handleSearchText = value => {
    const {
      setFilterParams,
      config: {filter: searchFilter, resetfilter, resetfiltervalue}
    } = this.props;

    if (setFilterParams) {
      const params = {
        [searchFilter]: value || ''
      };

      if (resetfilter) {
        params[resetfilter] = resetfiltervalue;
      }

      setFilterParams(params);
    }
  };

  debounceSearchText = _.debounce(value => {
    this.handleSearchText(value);
  }, 700);

  render() {
    const {
      filter,
      isArchiveReport,
      config: {filter: searchFilter, appearance, placeholder}
    } = this.props;

    if (isArchiveReport) return null;

    return (
      <SearchBar
        name={searchFilter}
        className={cx(styles.searchBar, styles[appearance])}
        value={filter[searchFilter] || ''}
        placeholder={placeholder || 'Search'}
        onEnter={this.handleSearchText}
        onUserInput={this.debounceSearchText}
        autoFocus={false}
      />
    );
  }
}

export default connect((state, props) => ({
  isArchiveReport: isArchiveActiveSelector(state, props)
}))(Search);
