import React from 'react';
import {formatGivenNumber} from 'utils/numberUtils';

export default class Money extends React.Component {
  static defaultProps = {
    className: ''
  };

  getSign(currency) {
    switch (currency.toUpperCase()) {
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return currency.toUpperCase() + ' ';
    }
  }

  render() {
    const {value, currency, className, decimals} = this.props;
    if ((!value && value !== 0) || !currency) {
      return null;
    }

    const sign = this.getSign(currency);

    return (
      <span className={className}>
        {sign}
        {decimals ? formatGivenNumber(value, decimals) : formatGivenNumber(value)}
      </span>
    );
  }
}
