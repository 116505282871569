import {basePath} from 'modules/auth/authUtils';
import React, {useContext} from 'react';

export function homePath(...args) {
  return basePath(...args) + '/home';
}

export function toolSelectionPath(...args) {
  return basePath(...args) + '/tool-select';
}

export function contactUs() {
  window.open('https://silktide.com/contact/', '_blank');
}

export function upgradeAccount() {
  window.open('https://app.insites.com/signup?source=demo', '_blank');
}

export function contactUsEmailEndpoint() {
  return 'contact-us';
}

// `Context` is the React Context created via React.createContext.
// `keyName` is the props key name that the context value will be merged into
export const connectContext = (Context, keyName) => Component => props => {
  if (typeof keyName !== 'string' || !keyName.length) {
    throw new Error('required "keyName" for connectContext HOC');
  }

  const contextValue = useContext(Context);
  const componentProps = {...props, [keyName]: contextValue};
  return <Component {...componentProps} />;
};

export function triggerPageViewEvent(location, account, report) {
  if (window.gtag) {
    const pageView = {
      event: 'pageView',
      pagePath: location.pathname
    };

    if (account && account.get && account.get('organizationName')) {
      pageView.accountName = account.get('organizationName');
    }

    if (report && report.get) {
      if (report.get('reportId')) {
        pageView.reportId = report.get('reportId');
      }
      if (report.get('name')) {
        pageView.reportName = report.get('name');
      }
    }

    window.dataLayer.push(pageView);
  }
}

export function triggerEvent(eventName, extra) {
  if (window.gtag) {
    const pageView = {
      event: eventName,
      ...extra
    };

    window.dataLayer.push(pageView);
  }
}
