exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ExLy78XfPrV9wX20cVUXK{width:80px;height:80px;border-radius:50%;border:1px solid #9b9b9b;display:flex;align-items:center;justify-content:center;font-size:38px}._1ExLy78XfPrV9wX20cVUXK._3XqTlotsBqHomMOypksBSE{font-size:16px;width:30px;height:30px}", ""]);

// exports
exports.locals = {
	"wrapper": "_1ExLy78XfPrV9wX20cVUXK",
	"small": "_3XqTlotsBqHomMOypksBSE"
};