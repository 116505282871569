import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SAVE_POLICY} from '../constants';
import {policyPath} from '../policyUtils';
import {setPolicy, savePolicyComplete} from '../actions';
import {push} from 'modules/location';
import {showSuccess, showError} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

export default function* savePolicySaga() {
  yield takeLatest(SAVE_POLICY, savePolicy);
}

function* savePolicy({policy, redirectToPolicy}) {
  const isNew = !policy.policyId;
  const url = isNew ? 'policies' : 'policies/' + policy.policyId;
  try {
    const result = yield processRequest(isNew ? 'POST' : 'PUT', url, {}, policy);

    yield put(setPolicy(result));
    yield put(savePolicyComplete());

    if (redirectToPolicy) {
      yield put(push(policyPath, {policyId: result.policyId}, ['reportId', 'testId']));
    }
    yield showSuccess(isNew ? 'New policy was added' : 'Policy updated');
  } catch (err) {
    yield showError(couldNot('save policy'));
    yield put(savePolicyComplete(err));
  }
}
