import React from 'react';
// TODO dynamically load these types?
import {toJS} from 'utils/immutableUtils';
import VideoLessonType from './lessonTypes/videoLessonType';
import QuizLessonType from './lessonTypes/quizLessonType';
import TextLessonType from './lessonTypes/textLessonType';
import {useDispatch} from 'hooks/redux';
import {saveLessonState, saveQuizState} from './../actions';

const map = {
  video: VideoLessonType,
  quiz: QuizLessonType,
  text: TextLessonType
};

export default function LessonType({lesson, saving, savingQuiz}) {
  const type = lesson.get('lessonTypeId');
  const options = toJS(lesson.get('options', {}));
  const questions = toJS(lesson.get('questions'), {});
  const answers = toJS(lesson.get('answers'), {});
  const minPercentCorrect = toJS(lesson.get('minPercentCorrect'));
  const dispatch = useDispatch();
  const DynamicLessonType = map[type];

  return (
    <DynamicLessonType
      lesson={lesson}
      options={options}
      onComplete={lesson => dispatch(saveLessonState(lesson.toJS()))}
      onCompleteQuiz={lesson => dispatch(saveQuizState(lesson.toJS()))}
      questions={questions}
      minPercent={minPercentCorrect}
      submittedAnswers={answers}
      saving={saving}
      savingQuiz={savingQuiz}
    />
  );
}
