exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3BiQqsAfnavtKmhnMIZX-K{text-align:center}._3BiQqsAfnavtKmhnMIZX-K p{margin:10px 0 0}._1g5jukpr5d3RMNiabsqEJ9{margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"wrapper": "_3BiQqsAfnavtKmhnMIZX-K",
	"open": "_1g5jukpr5d3RMNiabsqEJ9"
};