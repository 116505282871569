import * as Consts from './constants';

export function requestDigests() {
  return {type: Consts.REQUEST_DIGESTS};
}
export function requestDigestsComplete(error) {
  return {type: Consts.REQUEST_DIGESTS_COMPLETE, error};
}
export function receiveDigests(digests) {
  return {type: Consts.RECEIVE_DIGESTS, digests};
}

export function requestDigestSubscriptions(reportId, userId) {
  return {type: Consts.REQUEST_DIGEST_SUBSCRIPTIONS, reportId, userId};
}
export function requestDigestSubscriptionsComplete(error) {
  return {type: Consts.REQUEST_DIGEST_SUBSCRIPTIONS_COMPLETE, error};
}
export function receiveDigestSubscriptions(userId, digestSubscriptions) {
  return {type: Consts.RECEIVE_DIGEST_SUBSCRIPTIONS, userId, digestSubscriptions};
}

export function saveDigestSubscriptions(reportId, userId, digestSubscriptions) {
  return {type: Consts.SAVE_DIGEST_SUBSCRIPTIONS, reportId, userId, digestSubscriptions};
}
export function saveDigestSubscriptionsComplete(error) {
  return {type: Consts.SAVE_DIGEST_SUBSCRIPTIONS_COMPLETE, error};
}

export function addUnsavedDigestSubscription(userId, digestSubscription) {
  return {type: Consts.ADD_UNSAVED_DIGEST_SUBSCRIPTION, userId, digestSubscription};
}
export function editDigestSubscription(userId, digestSubscription, newData) {
  return {type: Consts.EDIT_DIGEST_SUBSCRIPTION, userId, digestSubscription, newData};
}
export function removeDigestSubscription(userId, digestSubscription) {
  return {type: Consts.REMOVE_DIGEST_SUBSCRIPTION, userId, digestSubscription};
}
export function removeUnsavedDigestSubscriptions(userId) {
  return {type: Consts.REMOVE_UNSAVED_DIGEST_SUBSCRIPTIONS, userId};
}

export function requestDigestPreview(reportId, digestId, schedulePreset, options, onClose) {
  return {
    type: Consts.REQUEST_DIGEST_PREVIEW,
    reportId,
    digestId,
    schedulePreset,
    options,
    onClose
  };
}
