import React from 'react';
import ModalContent from 'modules/modal/components/modalContent';
import Status from 'components/status';

export default class ExportComplete extends React.Component {
  static defaultProps = {
    submitLabel: 'Open Website',
    successMessage: 'The report finished exporting'
  };

  handleClickButton = exportUrl => event => {
    window.open(exportUrl, '_blank');
  };

  render() {
    const {exportUrl, submitLabel, successMessage} = this.props;

    return (
      <ModalContent
        title="Exported"
        buttons={[submitLabel]}
        onClickButton={this.handleClickButton(exportUrl)}
      >
        <Status appearance="good">{successMessage}</Status>
      </ModalContent>
    );
  }
}
