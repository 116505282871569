import Spinner from 'components/spinner';
import {addMessage} from 'modules/alerts';
import InspectorView from 'modules/inspector/components/inspectorView';
import {selectPoints} from 'modules/inspector/inspectorActions';
import {inspectorBodySelector} from 'modules/inspector/inspectorSelectors';
import {replace} from 'modules/location';
import React from 'react';
import {connect} from 'react-redux';
import {toJS} from 'utils/immutableUtils';

export class InspectorBody extends React.PureComponent {
  handleClickView = view => {
    this.props.replace(location.pathname, {}, [], true, {pageView: view});
  };

  handleClickPoint = pointIds => {
    this.props.selectPoints(pointIds);
  };

  // This is debounced because we get multiple calls in quick succession.
  // I think it is a feedback effect from the re-render of the iframe.
  // TODO remove this debounce when investigated.
  handleIssueNotFound = () => {
    this.props.addMessage(
      'error',
      'Unable to show this issue, as it appears in an invisible or dynamic part of the page.'
    );
  };

  render() {
    const {
      pageView,
      action,
      points,
      scale,
      actionId,
      categoryId,
      viewHtml,
      sourceHtml,
      sourceHeadHtml,
      currentPointIds,
      noRedirectInspectorOnMissing,
      enabledEffects,
      isLoading
    } = this.props;

    if (isLoading) {
      return <Spinner marginTop={100} />;
    }

    return (
      <InspectorView
        scale={scale}
        pageView={pageView}
        enabledEffects={enabledEffects}
        noRedirectInspectorOnMissing={noRedirectInspectorOnMissing}
        actionId={actionId}
        categoryId={categoryId}
        action={toJS(action)}
        allPoints={toJS(points)}
        viewHtml={viewHtml}
        sourceHtml={sourceHtml}
        sourceHeadHtml={sourceHeadHtml}
        currentPointIds={toJS(currentPointIds)}
        onSelectView={this.handleClickView}
        onSelectIssue={this.handleClickPoint}
        onIssueNotFound={this.handleIssueNotFound}
      />
    );
  }
}

export default connect(
  inspectorBodySelector,
  {
    replace,
    addMessage,
    selectPoints
  }
)(InspectorBody);
