exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3R0UqDxmyIIuKvA8am-Stk{width:250px}._3R0UqDxmyIIuKvA8am-Stk ._1ASigqHp0EYRpkYSnV39Zz{font-size:30px;opacity:.8}._3R0UqDxmyIIuKvA8am-Stk h3{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;margin:0 0 20px}._3R0UqDxmyIIuKvA8am-Stk ._3u9eseFHhTSyuypibY-UAX{opacity:.8;min-height:65px}", ""]);

// exports
exports.locals = {
	"libraryPolicy": "_3R0UqDxmyIIuKvA8am-Stk",
	"icon": "_1ASigqHp0EYRpkYSnV39Zz",
	"description": "_3u9eseFHhTSyuypibY-UAX"
};