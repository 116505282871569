exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2pkkpY7iaDvGBcwdbps2Mr{width:7px;height:7px;border-radius:50%;margin-right:3px}._3iWWp6BKhDjkFjDvXp-WG2{display:flex;align-items:center}._2F5ywESquuOF_4u2AQTtzF{margin-left:10px}", ""]);

// exports
exports.locals = {
	"difficultyPoint": "_2pkkpY7iaDvGBcwdbps2Mr",
	"wrapper": "_3iWWp6BKhDjkFjDvXp-WG2",
	"label": "_2F5ywESquuOF_4u2AQTtzF"
};