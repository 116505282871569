import {delay} from 'redux-saga';
import {fromJS} from 'immutable';
import {select, take, put, takeLatest} from 'redux-saga/effects';
import {VIEW_REPORT_INDEX_PAGE, RECEIVE_REPORT} from 'modules/reports/reportConstants';
import {VIEW_TEST} from 'modules/tests/testConstants';
import {testSelector, testsSelector, testEnabled} from 'modules/tests/testSelectors';
import {testPath, willHaveLayout} from 'modules/tests/testUtils';
import {reportSelector, activeArchiveIdSelector} from 'modules/reports/reportSelectors';
import {replace} from 'modules/location';

// If the user ends up on the report index page or a test which doesn't have a layout
// we should redirect to a test with a layout.
export default function* redirectToFirstTestSaga() {
  yield takeLatest([VIEW_REPORT_INDEX_PAGE, VIEW_TEST], redirectToFirstTest);
}

function* redirectToFirstTest(action) {
  const testId = yield getRedirectTestId(action);
  if (!testId) return;

  // really, we shouldn't need this delay.
  // But without, we run into a weird issue where sometimes the TestPage
  // isn't passed as props.children to the reportPage
  // even the the route matches.
  // also without this, the browser seems to crash when you go from report to home back to report
  yield delay(20);
  yield put(replace(testPath, {testId}, ['reportId'], {}, {}));
}

function* getRedirectTestId({testId}) {
  const selectedReport = yield select(reportSelector);

  const report = selectedReport.get('defaultTestId')
    ? selectedReport
    : fromJS((yield take(RECEIVE_REPORT)).report);

  const defaultTest = report.get('defaultTestId');

  if (testId) {
    if (testId === defaultTest) {
      return null;
    }

    // Has test been disabled
    const enabled = yield select(testEnabled);
    if (!enabled) {
      return defaultTest;
    }

    // We're on the test page
    let test = yield select(testSelector);
    if (!test) {
      // If we don't have a test, wait 3 seconds before trying again, it may have just not loaded yet?
      yield delay(3000);
    }

    test = yield select(testSelector);
    if (!test) {
      return defaultTest;
    }

    if (willHaveLayout(test)) {
      // No need to redirect
      return null;
    }

    // TODO: wheatley should specify redirectTestId so we don't have to do this.
    const tests = yield select(testsSelector);
    const firstTest = tests
      .filter(test => test.get('parentId') == testId && !test.get('isHidden'))
      .sortBy(test => test.get('sortIndex'))
      .first();
    return firstTest && firstTest.get('testId');
  }

  // We're on the report page
  return defaultTest;
}
