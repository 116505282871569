import _ from 'lodash';
import {fromJS} from 'immutable';
import {push as routerPush, replace as routerReplace} from 'connected-react-router';

const initialState = fromJS({
  lastSearch: {
    query: null,
    results: [],
    isLoading: false
  }
});

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_FOR_LOCATION:
      const currentQuery = state.getIn(['lastSearch', 'query']);

      // only update state if the search query has changed
      if (currentQuery !== action.query) {
        return state
          .setIn(['lastSearch', 'query'], action.query)
          .setIn(['lastSearch', 'results'], []);
      }

    case RECEIVE_LOCATION_RESULTS:
      return state.setIn(['lastSearch', 'results'], action.results);

    case LOADING_LOCATION_RESULTS:
      return state.setIn(['lastSearch', 'isLoading'], action.isLoading);
  }
  return state;
}

export const SEARCH_FOR_LOCATION = 'locations:SEARCH_FOR_LOCATION';
export function searchForLocation(query, limit) {
  return {type: SEARCH_FOR_LOCATION, query, limit};
}

export const RECEIVE_LOCATION_RESULTS = 'locations:RECEIVE_LOCATION_RESULTS';
export function receiveLocationResults(results) {
  return {type: RECEIVE_LOCATION_RESULTS, results};
}

export const LOADING_LOCATION_RESULTS = 'locations:LOADING_LOCATION_RESULTS';
export function loadingLocationResults(isLoading) {
  return {type: LOADING_LOCATION_RESULTS, isLoading};
}
