import React from 'react';
import {connect} from 'react-redux';
// NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
// import {actionFilterChanged} from 'modules/inspector/inspectorActions';
import Status from 'components/status';
import styles from './inspectorStyle.scss';

function InspectorEmptyActions({filtered, clearFilters}) {
  let contents;

  // NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
  // if (!filtered) {
  contents = (
    <Status appearance="good">
      <p>This page is all complete!</p>
    </Status>
  );
  // } else {
  //   contents = (
  //     <Status appearance="info">
  //       <p>
  //         Everything is being hidden by the filters above. Click here to{' '}
  //         <a onClick={() => clearFilters()}>clear filters</a>
  //       </p>
  //     </Status>
  //   );
  // }
  return <div className={styles.emptyActions}>{contents}</div>;
}

export default connect(
  null,
  dispatch => ({
    // NOTE INSPECTOR_FILTERS currently removed but keeping in case we ever bring any kind of filters back which is likely
    // clearFilters: () => {
    //   dispatch(actionFilterChanged('role', []));
    //   dispatch(actionFilterChanged('severity', []));
    // }
  })
)(InspectorEmptyActions);
