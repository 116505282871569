import React from 'react';
import styles from './graphWrapper.scss';
import cx from 'classnames';

export default class GraphWrapper extends React.Component {
  render() {
    const {children, noBorder, hideMargin} = this.props;

    const graph = (
      <div
        className={cx(styles.GraphWrapper, {
          [styles.noBorder]: noBorder,
          [styles.hideMargin]: hideMargin
        })}
      >
        {children}
      </div>
    );

    return graph;
  }
}
