exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._9elshhCx9zJBUhfNEgYIC{display:inline-block;margin:2px}._9elshhCx9zJBUhfNEgYIC img{width:30px;vertical-align:middle}._9elshhCx9zJBUhfNEgYIC ._3IKwVIkDcJDvv3Ji0EIB9W{vertical-align:middle;padding-left:5px;padding-right:10px}", ""]);

// exports
exports.locals = {
	"countryFlag": "_9elshhCx9zJBUhfNEgYIC",
	"title": "_3IKwVIkDcJDvv3Ji0EIB9W"
};