import React from 'react';
import ReactPlayer from 'react-player';
import Quiz from '../quizes/quiz';
import VideoCaptions from 'modules/courses/components/videoCaptions';
import {toJS} from 'utils/immutableUtils';
import CompleteLessonButton from 'modules/courses/components/buttons/completeLessonButton';
import Markdown from 'components/layoutComponents/renderers/markdown';
import styles from './lessonTypes.scss';
import cx from 'classnames';

function VideoLessonType(props) {
  const {
    lesson,
    onComplete,
    options: {src, srt, intro, credit}
  } = props;
  const underwayLock = React.useRef(false);
  const endLock = React.useRef(false);
  const [progressTime, setProgressTime] = React.useState(0);
  const [ready, setReady] = React.useState(false);
  const player = React.useRef(null);
  const currentState = lesson.get('state');
  const questions = toJS(lesson.get('questions'));
  const hasQuiz = questions && !!questions.length;
  const seek = lesson.getIn(['stateProps', 'videoProgress'], 0);
  const seekSeconds = lesson.getIn(['stateProps', 'videoProgressSeconds'], 0);
  const lastSavedTime = React.useRef(seekSeconds);
  const videoDuration = React.useRef(null);
  const [showAfterVideo, setShowAfterVideo] = React.useState(currentState === 'completed');

  React.useEffect(() => {
    if (player.current && ready && seek) {
      player.current.seekTo(seek);
    }
  }, [lesson.get('lessonId'), ready, player.current]);

  const renderVideo = () => (
    <div className={styles.playerWrapper}>
      <ReactPlayer
        className={styles.reactPlayer}
        ref={p => {
          player.current = p;
        }}
        config={{
          youtube: {
            playerVars: {
              cc_lang_pref: 'en',
              cc_load_policy: 1
            }
          },
          wistia: {
            plugin: {
              'captions-v1': {
                onByDefault: true
              }
            }
          }
        }}
        url={src}
        controls
        width={'100%'}
        progressInterval={100}
        onEnded={() => {
          // I am a terrible, terrible person
          const quizHeading = document.getElementById('quiz');
          if (quizHeading && quizHeading.scrollIntoView) {
            quizHeading.scrollIntoView({behaviour: 'smooth'});
          }
        }}
        onReady={() => {
          if (!ready) setReady(true);
        }}
        onDuration={duration => {
          videoDuration.current = duration;
        }}
        onProgress={({played, playedSeconds}) => {
          if (!videoDuration.current) {
            // we need the duration before anything else
            return;
          }

          // HACKY we have to do this becuase the bullshit onStart and onPlay callback are unpredictable as fuck. F react-player
          if (!underwayLock.current && played > 0 && (!currentState || currentState === 'viewed')) {
            // if we have a quiz, only set the videoProgress. Otherwise also set the state.
            onComplete(lesson.set('state', 'underway'));
            underwayLock.current = true;
          }

          setProgressTime(playedSeconds);

          const savedSecondsLesson = lesson
            .setIn(
              ['stateProps', 'videoProgress'],
              Math.max(seek, played) // don't let us set a lower value if the user goes back
            )
            .setIn(['stateProps', 'videoProgressSeconds'], playedSeconds);

          const remaining = videoDuration.current - playedSeconds;
          const isNearEnd = remaining <= 5;

          if (!endLock.current && isNearEnd && currentState !== 'completed') {
            // if we have a quiz, only set the videoProgress. Otherwise also set the state.
            // HACKY setTimeout fixes a race condition
            setShowAfterVideo(true);
            endLock.current = true;
            setTimeout(function() {
              onComplete(
                (hasQuiz ? savedSecondsLesson : savedSecondsLesson.set('state', 'completed'))
                  // clear progress
                  .setIn(['stateProps', 'videoProgress'], 0)
                  .setIn(['stateProps', 'videoProgressSeconds'], 0)
              );
            }, 1000);
          }

          // this just updates the videoProgress
          if (
            (!endLock.current && !currentState) ||
            currentState === 'viewed' ||
            currentState === 'underway'
          ) {
            // if we have a quiz, only set the videoProgress. Otherwise also set the state.
            // we don't want to spam the backend every 50ms, so we only send if the time is X seconds past the last
            if (playedSeconds - lastSavedTime.current > 5) {
              // 5 seconds
              // set underway just incase lesson is out of date
              onComplete(savedSecondsLesson.set('state', 'underway'));
              lastSavedTime.current = playedSeconds;
            }
          }
        }}
      />
    </div>
  );

  const renderIntro = () => (
    <div className={cx(styles.videoIntro, styles.readingArea, styles.markdown)}>
      <Markdown data={intro} />
    </div>
  );

  const renderCredit = () => (
    <div className={cx(styles.videoCredit, styles.markdown)}>
      <Markdown data={credit} />
    </div>
  );

  const renderCaptions = () => (
    <div className={styles.readingArea}>
      <div className={styles.captions}>
        <h3>Transcript</h3>
        <VideoCaptions time={progressTime} srt={srt} />
      </div>
    </div>
  );

  const renderQuiz = () => (
    <div>
      <div className={cx(styles.readingArea, styles.quizArea)}>
        <p>
          Answer the following questions then press the <b>Submit answers</b> button:
        </p>
        <hr />

        <div className={styles.readingArea} id="quiz">
          <Quiz {...props} />
        </div>
      </div>
    </div>
  );

  const videoContent = (
    <React.Fragment>
      {!!intro && <div>{renderIntro()}</div>}
      {renderVideo()}
      {!!credit && <div>{renderCredit()}</div>}
    </React.Fragment>
  );

  if (!showAfterVideo) {
    // only show video (hide quiz or complete button until end)
    return (
      <div>
        {videoContent}

        {!!srt && <div>{renderCaptions()}</div>}
      </div>
    );
  }

  if (hasQuiz) {
    return (
      <div>
        {videoContent}
        {!!srt && renderCaptions()}
        {renderQuiz()}
      </div>
    );
  }

  return (
    <div>
      {videoContent}

      <div className={cx(styles.buttons, styles.marginTop)}>
        {<CompleteLessonButton lesson={lesson} />}
      </div>

      {!!srt && <div>{renderCaptions()}</div>}
    </div>
  );
}

export default VideoLessonType;
