import React from 'react';
import styles from './linkIssue.scss';
import ExternalLink from 'modules/location/externalLink';

class LinkIssue extends React.Component {
  render() {
    const {
      point: {src, text}
    } = this.props;

    if (!src) return null;

    return (
      <div>
        {text && <p className={styles.text}>{text}</p>}
        <p className={styles.link}>
          <ExternalLink href={src}>{src}</ExternalLink>
        </p>
      </div>
    );
  }
}

export default LinkIssue;
