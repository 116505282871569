import {createSelector} from 'reselect';

export const subscriptionState = state => {
  return state.subscriptions;
};

export const subscriptionsSelector = createSelector(
  subscriptionState,
  state => state.get('subscriptions')
);

export const subscriptionLoadingSelector = createSelector(
  subscriptionState,
  state => state.get('loading')
);

export const subscriptionFetchedSelector = createSelector(
  subscriptionState,
  state => state.get('fetched')
);

export const subscriptionErrorSelector = createSelector(
  subscriptionState,
  state => state.get('error')
);

export const subscriptionStateSelector = createSelector(
  subscriptionsSelector,
  subscriptionLoadingSelector,
  subscriptionErrorSelector,
  (subscriptions, loading, error) => ({error, loading, subscriptions})
);

export const hasActiveSubscriptionsSelector = createSelector(
  subscriptionsSelector,
  subscriptionFetchedSelector,
  (subscriptions, hasFetchedSubs) => {
    const hasActiveSubscription =
      subscriptions &&
      subscriptions.some(item => {
        return !item.get('canceledAt');
      });

    // const now = Math.floor(Date.now() / 1000);
    // const activeSubs = subscriptions
    //   .toJS()
    //   .filter(
    //     sub => sub.currentPeriodStart < now && sub.currentPeriodEnd > now && sub.status == 'active'
    //   );

    // // if we haven't fetched subs yet, assume they have got active subscription
    // // otherwise we might boot them out before we know for sure.
    // return !hasFetchedSubs || !!activeSubs.length;
    return !hasFetchedSubs || hasActiveSubscription;
  }
);

export const isPastDueSelector = createSelector(
  subscriptionsSelector,
  subscriptionFetchedSelector,
  (subscriptions, hasFetchedSubs) => {
    const hasPastDueSubscription =
      subscriptions &&
      subscriptions.some(item => {
        return item.get('status') === 'past_due';
      });

    return !hasFetchedSubs || hasPastDueSubscription;
  }
);
