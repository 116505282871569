import React from 'react';
import {Link} from 'react-router-dom';
import styles from './legacyHelp.scss';
import ExternalLink from 'modules/location/externalLink';

class LegacyHelp extends React.Component {
  renderLink(href) {
    if (!href) return '';
    return <ExternalLink href={href}>Learn more</ExternalLink>;
  }

  render() {
    const {config} = this.props;
    const {label} = config;

    return (
      <div className={styles.help}>
        <p>
          <span dangerouslySetInnerHTML={{__html: label}} /> {this.renderLink(config.href)}
        </p>
      </div>
    );
  }
}

export default LegacyHelp;
