exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mPJtsKe5hbR1kFzJ_mp8_{margin-right:15px}._3uslfKuS5zGbz6dSFeHqYN:empty{display:none}._1O2cv4o2Bu4zRe0sZh-yJZ{align-items:center;border:7px solid #cbcbcb;border-radius:50%;color:#533be2;font-size:30px;display:flex;flex-direction:column;justify-content:center}._3_7R7cSHDN6Jvc9yYzyAJQ{justify-self:flex-end;color:#333;text-align:center;font-size:12px;margin-bottom:7px}.lucCFQJefsDRRqgUR1XTb{margin-right:0}._2P2o1_7RRy9lYQkF1RCRUJ{margin:0 auto}", ""]);

// exports
exports.locals = {
	"normalScore": "_2mPJtsKe5hbR1kFzJ_mp8_",
	"noScore": "_3uslfKuS5zGbz6dSFeHqYN",
	"locked": "_1O2cv4o2Bu4zRe0sZh-yJZ",
	"lockedLabel": "_3_7R7cSHDN6Jvc9yYzyAJQ",
	"normalScoreCard": "lucCFQJefsDRRqgUR1XTb",
	"center": "_2P2o1_7RRy9lYQkF1RCRUJ"
};