import React from 'react';
import styles from './difficulty.scss';
import Tooltip from 'components/tooltip';

// In our backend, these are defined in Difficulties.php
const DIFFICULTIES = {
  1: {color: '#00bb00', label: 'Trivial'},
  2: {color: '#66bb00', label: 'Easy'},
  3: {color: '#eedd00', label: 'Moderate'},
  4: {color: '#f08800', label: 'Hard'},
  5: {color: '#f00000', label: 'Very hard'}
};

export const OPTIONS = Object.keys(DIFFICULTIES).map(id => ({
  value: id,
  label: DIFFICULTIES[id].label
}));

export default class Difficulty extends React.Component {
  renderDifficultyPoint = (index, color) => {
    return (
      <div key={index} className={styles.difficultyPoint} style={{backgroundColor: color}}>
        {' '}
      </div>
    );
  };

  render() {
    const {value, label} = this.props;
    const difficulty = DIFFICULTIES[value];
    if (!difficulty) return null;

    const points = [];
    const text = difficulty.label;
    const tooltip = <span>Estimated difficulty: {text}</span>;

    for (let i = 0; i < 5; i++) {
      points.push(this.renderDifficultyPoint(i, i < value ? difficulty.color : '#eee'));
    }

    return (
      <Tooltip text={tooltip}>
        <span className={styles.wrapper}>
          {points}
          {label && <span className={styles.label}>{text}</span>}
        </span>
      </Tooltip>
    );
  }
}
