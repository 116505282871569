import React from 'react';
import {useDispatch} from 'hooks/redux';
import {useHistory, useParams} from 'react-router-dom';
import {exitInspector} from 'modules/inspector/inspectorActions';
import styles from 'modules/inspector/components/inspectorHeader.scss';
import InspectorHeaderButton from 'modules/inspector/components/inspectorHeaderButton';
import {reportPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location/location';

export default function InspectorExitButton() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const handleClickExit = () => {
    dispatch(exitInspector()); // clears necessary redux state
    if (history.length < 3) {
      dispatch(push(reportPath(params)));
    } else {
      history.goBack();
    }
  };

  return (
    <InspectorHeaderButton
      dark
      icon="times"
      tooltip="Exit inspector"
      className={styles.closeButton}
      onClick={handleClickExit}
    />
  );
}
