import React from 'react';
import cx from 'classnames';
import styles from './panel.scss';

export default class Panel extends React.Component {
  render() {
    const {config, children} = this.props;
    // duplicated cos the backend playing up
    const {hideBorderTop, hidebordertop, padding} = config;

    const className = cx(styles.panel, config.className, styles[config.layout], {
      [styles.padding10]: padding == 10,
      [styles.padding20]: padding == 20,
      [styles.hideBorderTop]: hideBorderTop || hidebordertop === '' || hidebordertop === 'true'
    });

    return <div className={className}>{children}</div>;
  }
}
