import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {reportSelector} from 'modules/reports/reportSelectors';
import {testsSelector} from 'modules/tests/testSelectors';
import MessageSpinner from 'components/spinner/messageSpinner';
import Helmet from 'components/helmet';
import {createSelector} from 'reselect';
import {receiveResults} from 'modules/results';
import {receiveMission} from 'modules/missions';
import {missionChannel, reportChannel} from 'modules/pusher/channels';
import Pusher from 'modules/pusher/components/pusher';
import {fetchLayout} from 'modules/layouts';
import {fetchAction, fetchActionResults} from 'modules/actions/actions';
import {fetchReport} from 'modules/reports';

const reportPageSelector = createSelector(
  reportSelector,
  testsSelector,
  (report, tests) => {
    return {
      report,
      tests
    };
  }
);

export class ReportListeners extends React.Component {
  updatedTests = (testIds, forceGetNewLayout) => {
    const {
      match: {params}
    } = this.props;
    const {testId: currentTestId} = params;

    if (!testIds) {
      return null;
    }

    // the backend sometimes passes objects
    if (_.isPlainObject(testIds)) {
      testIds = Object.values(testIds);
    }

    if (testIds.indexOf(currentTestId) !== -1) {
      this.props.fetchLayout(currentTestId, forceGetNewLayout);
    }
  };

  refetchReport = reportId => {
    // Refetches the report
    reportId && this.props.fetchReport(reportId);
  };

  render() {
    const {report, location, className, ...props} = this.props;
    if (!report || !report.size) {
      return (
        <MessageSpinner marginTop={100} centered delay={4000}>
          <p>Loading your report, this may take a minute.</p>
        </MessageSpinner>
      );
    }

    // prefer Pusher component over a pusherDecorator because the decorator wraps the
    // component in a div which breaks the flex styles that make up the layout
    return (
      <React.Fragment>
        <Helmet title={report.get('name') || report.get('homeUrl')} />

        {this.props.children}

        <Pusher
          channel={reportChannel(this.props.match)}
          event="updatedReport"
          onUpdate={item => report && this.refetchReport(report.get('reportId', ''))}
        />

        <Pusher
          channel={reportChannel(this.props.match)}
          event="results"
          onUpdate={results => this.props.receiveResults(results)}
        />

        <Pusher
          channel={reportChannel(this.props.match)}
          event="updatedTests"
          onUpdate={testIds => this.updatedTests(testIds, true)}
        />

        <Pusher
          channel={reportChannel(this.props.match)}
          event="updatedTestResults"
          onUpdate={testIds => this.updatedTests(testIds, false)}
        />

        <Pusher
          channel={missionChannel(this.props.match.params)}
          event="missionChange"
          onUpdate={mission => this.props.receiveMission(mission)}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  reportPageSelector,
  {
    receiveResults,
    fetchLayout,
    receiveMission,
    fetchActionResults,
    fetchAction,
    fetchReport
  }
)(ReportListeners);
