import {select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {plansEndpoint} from 'modules/products/productUtils';
import {receiveProducts, receiveTax, handleProductsError} from 'modules/products';
import {geolocationSelector} from 'modules/geolocation/geolocationSelectors';

export default function* fetchPlans(countryCode, vatNumber) {
  if (!countryCode) {
    countryCode = yield select(geolocationSelector);

    if (!countryCode) {
      // backend will complain if we don't send a valid country codes
      // and we don't wanna risk stringifying null or undefined
      countryCode = '';
    }
  }
  yield processRequest('GET', plansEndpoint(countryCode, vatNumber), {
    success: handleSuccess,
    errorAction: handleProductsError
  });
}

function* handleSuccess(data) {
  yield put(receiveProducts(data.plans));
  yield put(receiveTax(data.tax));
}
