import PropTypes from 'prop-types';
import React from 'react';
import {getProps} from 'utils/immutableUtils';
import {trackEvent} from 'modules/tracking';
import {Form, Field} from 'modules/form/components';
import SettingItem from 'components/settings/settingItem';
import SettingsPage from 'components/settings/settingsPage';
import Button from 'components/button';

// list of all properties we need from websiteConfig
// ENSURE THIS IS UPDATED WHEN ADDING NEW SETTINGS OPTIONS
const FIELDS = [
  'timeout',
  'pageWait',
  'clickSelector',
  'maxConnections',
  'userAgentSuffix',
  'disableAuto404',
  'engine',
  'disableBlocklist',
  'proxyPreset',
  'exclude3rdPartyPluginIssues'
];

export default class AdvancedConfig extends React.Component {
  handleValidSubmit = config => {
    const {report, updateWebsite} = this.props;

    trackEvent('Updated advanced config', {
      forReport: report.reportId,
      fromFile: 'advancedConfig.js'
    });

    // engine needs to be a string but we want to display a boolean to the user
    if (config.engine) {
      config.engine = 'chrome';
    } else {
      config.engine = 'phantom';
    }

    updateWebsite(config);
  };

  render() {
    const {
      name,
      match: {params},
      websiteConfig
    } = this.props;

    if (typeof websiteConfig !== 'undefined') {
      if (websiteConfig.engine === 'chrome') {
        websiteConfig.engine = true;
      } else {
        websiteConfig.engine = false;
      }
    }

    return (
      <SettingsPage name="Advanced settings" params={params}>
        <Form
          name={name}
          defaultValue={getProps(websiteConfig, FIELDS)}
          onValidSubmit={this.handleValidSubmit}
        >
          <SettingItem
            title="Download timeout (seconds)"
            description="Max time to wait when downloading a page"
          >
            <Field type="number" name="timeout" />
          </SettingItem>

          <SettingItem
            title="Wait (seconds)"
            description="Wait this many seconds after downloading a page before testing"
          >
            <Field type="number" name="pageWait" />
          </SettingItem>

          <SettingItem
            title="Click selector"
            description="Click this CSS selector if it appears on a page"
          >
            <Field type="text" name="clickSelector" />
          </SettingItem>

          <SettingItem
            title="Max connections"
            description="Max number of connections Silktide can make to this site"
          >
            <Field type="number" name="maxConnections" />
          </SettingItem>

          <SettingItem
            title="User agent"
            description="Optionally add this text to the user agent when testing"
          >
            <Field type="text" name="userAgentSuffix" />
          </SettingItem>

          <SettingItem
            title="Proxy location"
            description="Optionally enter a proxy to use when scanning your site"
          >
            <Field type="select" name="proxyPreset" options={this.props.proxyList} />
          </SettingItem>

          <SettingItem
            title="Disable automatic 404 check"
            description="If checked, a 404 page will not be automatically tested"
            linear
            reverse
          >
            <Field type="boolean" name="disableAuto404" />
          </SettingItem>

          <SettingItem
            title="Automatically block analytics and ads"
            description="If checked, attempt to block all known analytics and ads on tested pages."
            linear
            reverse
          >
            <Field type="boolean" name="disableBlocklist" />
          </SettingItem>

          <SettingItem
            title="Ignore issues flagged by 3rd party plugins?"
            description="If checked, this will stop issues being created for 3rd party plugins. e.g. Google Maps."
            linear
            reverse
          >
            <Field type="boolean" name="exclude3rdPartyPluginIssues" />
          </SettingItem>

          <Button type="submit">Save</Button>
        </Form>
      </SettingsPage>
    );
  }
}
