import {basePath} from 'modules/auth/authUtils';
import {getProductNameFromSkus} from 'modules/products/productUtils';

export const REPORT_DRAG_TYPE = 'report';

export const REPORT_SCORE_TYPES = {
  SCORES: 'scores',
  ISSUES: 'issues'
};

/**
 * Create a reportId that will look like "23-1-asdfsdfds" (reportId-segementId-archiveId)
 * @param {*} reportId
 * @param {*} segmentId
 * @param {*} archiveId
 */
export function generateReportId(reportId, segmentId, archiveId) {
  let id = `${reportId}`;

  if (segmentId && segmentId !== '0') {
    id += `-${segmentId}`;
  }

  if (archiveId && archiveId !== 'live') {
    // If no segmentId, make sure this one goes first.
    if (!segmentId || segmentId == 0) {
      id += '-0';
    }

    id += `-${archiveId}`;
  }

  return id;
}

export function getReportIdParts(reportId) {
  let recordId, segmentId, archiveId;

  if (!reportId) {
    return {reportId, segmentId, archiveId};
  }

  [recordId, segmentId = 0, archiveId = 'live'] = reportId.split('-', 3);
  return {
    reportId: recordId,
    segmentId,
    archiveId
  };
}

export function allReportsEndpoint() {
  return `reports`;
}

export function reportPath({reportId}) {
  return basePath(...arguments) + `/reports/${reportId}`;
}

export function archiveRedirectPath({reportId, testId}) {
  const url = basePath(...arguments) + `/reports/${reportId}`;
  if (testId) {
    return `${url}/${testId}`;
  }
  return url;
}

//Return true if user has reports that aren't symlinked
export function hasOwnReports(reports) {
  return !!reports.filter(report => !report.isSymlink).length;
}

export function reportSettingsPath(args) {
  return reportPath(args) + '/settings';
}

export function reportSettingPath({settingsId, ...props}) {
  return reportPath(props) + '/settings/' + settingsId;
}

export function printReportPath({testIds}) {
  const path = reportPath(...arguments) + '/print-tests';
  if (!testIds) return path;
  return `${path}?testIds=${testIds.join(',')}`;
}

export function scheduleSettingsPath(args) {
  return reportSettingsPath(args) + '/schedule';
}

export function generalSettingsPath(args) {
  return reportSettingsPath(args) + '/general';
}

export function cmsSettingsPath(args) {
  return reportSettingsPath(args) + '/cms';
}

export function searchCampaignsSettingsPath(args) {
  return reportSettingsPath(args) + '/search-campaigns';
}

export function uptimeSettingsPath(args) {
  return reportSettingsPath(args) + '/uptime';
}

export function notificationsSettingsPath(args) {
  return reportSettingsPath(args) + '/notifications';
}

export function languageSettingsPath(args) {
  return reportSettingsPath(args) + `/languages`;
}

export function authenticationSettingsPath(args) {
  return reportSettingsPath(args) + `/authentication`;
}

export function restrictionsSettingsPath(args) {
  return reportSettingsPath(args) + `/restrictions`;
}

export function permissionsSettingsPath(args) {
  return reportSettingsPath(args) + `/permissions`;
}

export function digestsSettingsPath({userId}) {
  return permissionsSettingsPath(...arguments) + `/digests/${userId}`;
}

export function advancedSettingsPath(args) {
  return reportSettingsPath(args) + `/advanced`;
}

export function alertSubscriptionsPath(args) {
  return reportSettingsPath(args) + `/alerts`;
}

export function forcedPagesSettingsPath(args) {
  return reportSettingsPath(args) + `/forced-pages`;
}

export function forcedPagesPath({forcedPageId = 'new'}) {
  return reportSettingsPath(...arguments) + `/forced-pages/` + forcedPageId;
}

// Api endpoints
export function reportEndpoint({reportId}) {
  return `${reportsEndpoint()}/${reportId}`;
}
export function reportsEndpoint() {
  return `reports`;
}

export function editPageEndpoint({pageHash}) {
  return reportEndpoint(...arguments) + `/pages/${pageHash}/cms-edit`;
}

export function exportReportEndpoint({reportId}) {
  return reportEndpoint(...arguments) + '/export';
}

export function exportPDFEndpoint({reportId}) {
  return reportEndpoint(...arguments) + '/pdf';
}

export function retestPageEndpoint({reportId, pageHash}) {
  return reportEndpoint(...arguments) + `/pages/${pageHash}/retest`;
}

export function canRetestEndpoint({reportId}) {
  return reportEndpoint(...arguments) + `/can-retest`;
}

export function reportToFolderEndpoint({reportId, mode = 'copy'}) {
  return `reports/${reportId}/${mode}`;
}

export function requestDeleteEndpoint({reportId}) {
  return `reports/${reportId}/can-delete`;
}

export function reportFoldersEndpoint({reportId, folderId}) {
  let url = `reports/${reportId}/folders`;

  if (!folderId) {
    return url;
  }

  return url + '/' + folderId;
}

export function forcedPagesEndpoint({reportId, forcedPageId = '', pageNum = 1}) {
  let url = `reports/${reportId}/forced-pages`;
  let page = `?page=${pageNum}`;

  if (!forcedPageId) {
    return url + page;
  }

  return url + `/${forcedPageId}${page}`;
}

export function isMonitor(report) {
  const sku = report.get ? report.get('sku') : report.sku;
  return getProductNameFromSkus([sku]) === 'Monitor';
}
