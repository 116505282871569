import React, {useState, useEffect} from 'react';
import Button from 'components/button';
import styles from './timeline.scss';

const SHOW_POINTS = 5;

function ShowMoreButton({items, setItems}) {
  const [showMore, setShowMore] = useState(false);
  const isNeeded = items.length > SHOW_POINTS;
  const filteredItems = isNeeded && !showMore ? items.slice(0, SHOW_POINTS) : items;

  useEffect(() => {
    setItems(showMore ? items : filteredItems);
  }, [showMore]);

  if (!isNeeded) return null;

  return (
    <Button className={styles.showMore} onClick={() => setShowMore(!showMore)}>
      {!showMore ? 'Show more' : 'Hide'}
    </Button>
  );
}

export default ShowMoreButton;
