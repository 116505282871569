import _ from 'lodash';
import {createSelector} from 'reselect';
import {plainTestSelector} from 'modules/tests/testSelectors';
import {toJS} from 'utils/immutableUtils';

export const layoutsStateSelector = state => state.layouts;

export const layoutsDataStateSelector = state => state.layouts.get('data');

export const currentLayoutSelector = createSelector(
  plainTestSelector,
  layoutsDataStateSelector,
  (test, layouts) => {
    const currentTest = test || {};
    if (_.isEmpty(currentTest) || !currentTest.layoutId) {
      return null;
    }
    return toJS(layouts.get(currentTest.layoutId));
  }
);

export const testLayoutSelector = createSelector(
  currentLayoutSelector,
  layout => {
    return layout ? layout.layout : null;
  }
);
