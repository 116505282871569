import React from 'react';
import {useSelector} from 'hooks/redux';
import {widgetTypesSelector} from '../selectors';
import Margin from 'components/margin';
import ReportMetricWidget from 'modules/dashboards/components/widgets/report/metric';
import ReportsStatWidget from 'modules/dashboards/components/widgets/reports/stat';
import ReportsDistributionWidget from 'modules/dashboards/components/widgets/reports/distribution';
import ReportsTrendWidget from 'modules/dashboards/components/widgets/reports/trend';

const WidgetTypes = {
  ['Report/Metric']: ReportMetricWidget,
  ['Reports/Stat']: ReportsStatWidget,
  ['Reports/Distribution']: ReportsDistributionWidget,
  ['Reports/StatTrend']: ReportsTrendWidget
};

export default function Widget({widget, ...props}) {
  const widgetTypes = useSelector(widgetTypesSelector);
  const widgetType = widgetTypes.get(widget.get('widgetTypeId'));
  const WidgetComponent = WidgetTypes[widget.get('widgetTypeId')];

  if (!WidgetComponent) {
    // TODO return unexpected error
    return null;
  }

  return (
    <Margin marginTop={widget.get('order') === 0 ? undefined : '20px'}>
      <WidgetComponent widgetType={widgetType} widget={widget} {...props} />
    </Margin>
  );
}
