import CopyToClipboard from 'components/copyToClipboard';
import Spinner from 'components/spinner';
import {closeShareReport, shareReport, shareUrlSelector} from 'modules/share';
import React from 'react';
import {connect} from 'react-redux';
import {addQueryString} from 'utils/urlUtils';

export class ShareReportContainer extends React.Component {
  render() {
    const {token} = this.props;
    if (!token) return <Spinner centered />;

    return (
      <div>
        <p>Share this link to allow anyone to view this website:</p>
        <CopyToClipboard value={`${addQueryString(window.location.href, {token})}`} />
      </div>
    );
  }
}

export default connect(
  shareUrlSelector,
  {closeShareReport, shareReport}
)(ShareReportContainer);
