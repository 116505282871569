import {basePath} from 'modules/auth/authUtils';

export function searchPath(params) {
  return `${basePath(params)}/search`;
}

export function recentReportsEndpoint() {
  return 'homepage';
}

export function searchResultsEndpoint() {
  return 'search';
}
