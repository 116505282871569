import PropTypes from 'prop-types';
import React from 'react';

// @todo: Style this message.
class TableWarning extends React.Component {
  render() {
    const {message} = this.props;
    return <span>{message}</span>;
  }
}

export default TableWarning;
