import {takeLatest, select, call, put} from 'redux-saga/effects';
import {REQUEST_BUY_PLAN, receiveBuyPlanResponse} from 'modules/superProduct/superProductActions';
import {processRequest} from 'utils/saga/fetchUtils';
import {accountIdSelector} from 'modules/auth/authSelectors';
import {superProductsBuyEndpoint} from 'modules/superProduct/superProductUtils';
import {getStripeKey} from 'modules/payments/paymentUtils';
import {couldNot} from 'utils/errorUtils';
import {showError} from 'utils/saga/alerts';

export default function* requestBuyPlanSaga() {
  yield takeLatest([REQUEST_BUY_PLAN], function*({productId, planId, subConfig, accountData, failCallback, paymentOptions}) {
    const accountId = yield select(accountIdSelector);
    accountData.accountId = accountId;

    yield processRequest('POST', superProductsBuyEndpoint(productId, planId), {
      success: function*(data) {
        yield put(receiveBuyPlanResponse(data));
        
        if (!data.sessionId) {
          yield showError(couldNot('purchase plan'));
          failCallback && failCallback();
          return;
        }

        const stripe = Stripe(getStripeKey());
        if (!stripe) {
          yield showError(couldNot('purchase plan'));
          failCallback && failCallback();
          return;
        }

        const response = yield call(callStripe, stripe, data.sessionId, accountId);

        yield showError(couldNot('purchase plan'));
        failCallback && failCallback();

        return data;
      },
      error: function*(err) {
        yield showError(err && err.errors && err.errors.length ? err.errors[0].errorMessage : couldNot('purchase plan'));
        failCallback && failCallback(err);
      }
    }, {
      subConfig,
      request: accountData,
      ...paymentOptions
    });
  });
}

export function* callStripe(stripe, sessionId) {
  return yield call(stripe.redirectToCheckout, {
    sessionId: sessionId
  });
}
