exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1AF1SXt0E6hORKemPuCFcK{margin:40px 0 30px 0}._3Rz-daXLjTjrEzB4eW5w38{font-size:20px}", ""]);

// exports
exports.locals = {
	"hr": "_1AF1SXt0E6hORKemPuCFcK",
	"title": "_3Rz-daXLjTjrEzB4eW5w38"
};