exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4rkKrj8EhvF95Zq9P79eR ._27uD8HEX981LhjXEK2emMD{background:#fff0f0;border:1px solid #df1616;display:flex;align-items:center;padding:15px}._4rkKrj8EhvF95Zq9P79eR ._27uD8HEX981LhjXEK2emMD h4{color:#df1616;margin:0;flex:1}._4rkKrj8EhvF95Zq9P79eR ._27uD8HEX981LhjXEK2emMD ._3DPDc5zBPFk9YOyInUZAYf{margin-left:auto;min-width:100px}._4rkKrj8EhvF95Zq9P79eR ._24J_Ck0N5sLMvXv_5Ijjue p{text-align:center;font-size:120%;margin:30px}._4rkKrj8EhvF95Zq9P79eR ._3yvXDB76aMG7FqEd8w467e{position:relative;border-radius:10px;overflow:hidden;border:1px solid #bbb}._4rkKrj8EhvF95Zq9P79eR ._3yvXDB76aMG7FqEd8w467e .HVXnHspsKIQiCYr5gxAgm{background:#ddd;padding:14px}._4rkKrj8EhvF95Zq9P79eR ._3yvXDB76aMG7FqEd8w467e img{padding:20px;max-width:100%;height:auto;display:block;margin:0 auto}._2nnV1cqr3eWw__7dTwuZh-{display:flex;opacity:.8}._2nnV1cqr3eWw__7dTwuZh- div{border-radius:50%;width:13px;height:13px;margin-right:10px}._2nnV1cqr3eWw__7dTwuZh- div:nth-child(1){background:#b11111}._2nnV1cqr3eWw__7dTwuZh- div:nth-child(2){background:#baa60a}._2nnV1cqr3eWw__7dTwuZh- div:nth-child(3){background:#108455}.nJoEgzuEIw_OBX4Z4PqiV{background:#371ecc;height:50px;width:300px;position:absolute;top:63px;right:-63px;-webkit-transform-origin:50% 50%;transform-origin:50% 50%;-webkit-transform:rotate(45deg);transform:rotate(45deg);color:#fff;text-align:center;line-height:1.7;font-size:30px}@media(max-width: 768px){.nJoEgzuEIw_OBX4Z4PqiV{height:40px;width:200px;top:20px;right:-60px;line-height:1.9;font-size:23px}}@media(max-width: 425px){._27uD8HEX981LhjXEK2emMD{flex-direction:column}._27uD8HEX981LhjXEK2emMD h4{text-align:center}._27uD8HEX981LhjXEK2emMD ._3DPDc5zBPFk9YOyInUZAYf{margin:10px auto 0 auto}}", ""]);

// exports
exports.locals = {
	"upgrade": "_4rkKrj8EhvF95Zq9P79eR",
	"header": "_27uD8HEX981LhjXEK2emMD",
	"buyButton": "_3DPDc5zBPFk9YOyInUZAYf",
	"body": "_24J_Ck0N5sLMvXv_5Ijjue",
	"preview": "_3yvXDB76aMG7FqEd8w467e",
	"previewHeader": "HVXnHspsKIQiCYr5gxAgm",
	"macBar": "_2nnV1cqr3eWw__7dTwuZh-",
	"banner": "nJoEgzuEIw_OBX4Z4PqiV"
};