import React from 'react';
import {connect} from 'react-redux';
import styles from './notificationListItem.scss';
import NotificationImage from './notificationImage';
import RelativeDate from 'components/time/relativeDate';
import {magicallyInterpolate} from 'utils/interpolationUtils';
import {openNotification} from 'modules/notifications';
import cx from 'classnames';

export class NotificationListItem extends React.Component {
  handleClick = () => {
    this.props.openNotification(this.props.notification.notificationId);
  };

  render() {
    const {notification} = this.props;
    const {message, image, createdTime, context, readTime} = notification;

    const classes = cx(styles.notificationListItem, this.props.className, {
      [styles.read]: readTime > 0
    });

    return (
      <a className={classes} onClick={this.handleClick}>
        <NotificationImage height={50} image={image} />
        <div className={styles.text}>
          {magicallyInterpolate(notification)}
          <div>
            <RelativeDate timestamp={createdTime} tooltipPreferSide="bottom" />
          </div>
        </div>
      </a>
    );
  }
}

export default connect(
  null,
  {openNotification}
)(NotificationListItem);
