import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {
  RECIEVE_THEMES,
  RECIEVE_THEME,
  FETCH_THEMES,
  FETCH_THEMES_COMPLETE,
  SET_IS_UPDATING,
  REQUEST_DELETE_THEME,
  DELETE_THEME
} from './constants';

const initialState = fromJS({
  data: [],
  isLoading: false,
  updatingTheme: null
});

export default function searchCampaignsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_THEMES:
      return state.set('isLoading', true);

    case FETCH_THEMES_COMPLETE:
      return state.set('isLoading', false);

    case RECIEVE_THEMES:
      return state.set('data', keyBy(action.themes, 'campaignId'));

    case RECIEVE_THEME:
      return state.setIn(['data', action.campaignId], fromJS(action.theme));

    case SET_IS_UPDATING:
      return state.set('updatingTheme', action.campaignId);

    case REQUEST_DELETE_THEME:
      return state.set('isDeleting', true);

    case DELETE_THEME:
      return state.set('isDeleting', false).deleteIn(['data', action.campaignId]);
  }
  return state;
}
