import React from 'react';
import {API} from 'config';
import styles from './styles.scss';
import Spinner from 'components/spinner';
import ImagePoller from 'components/image/imagePoller';
import {showInHugeModal} from 'modules/modal';
import ImageModal from 'components/modal/imageModal';
import {connect} from 'react-redux';

class Img extends React.Component {
  render() {
    const {config, filter: filters} = this.props;
    let {src: configSrc, enlargeable, wide, filter} = config;
    let src = filter ? filters[filter] : configSrc;

    if (src && !src.match(/:\/\//)) src = API.ROOT + src;

    // @todo Support enlargeable here
    const handleClick = enlargeable
      ? () =>
          this.props.showInHugeModal(ImageModal, {
            src
          })
      : undefined;

    const image = (
      <ImagePoller src={src} className={styles.img} onClick={handleClick}>
        <Spinner centered className={styles.spinner} marginTop={30} marginBottom={30} />
      </ImagePoller>
    );

    return wide ? <div className={styles.wide}>{image}</div> : image;
  }
}

export default connect(
  () => ({}),
  {showInHugeModal}
)(Img);
