import React from 'react';
import TopicAction from './topicAction';
import TopicLesson from './topicLesson';
import {actionPath} from 'modules/actions/actionUtils';
import styles from '../topic.scss';
import {withRouter} from 'react-router';

class TopicItems extends React.Component {
  renderHeader() {
    const {done, nodifficulty, headless, scoreless} = this.props;

    if (headless) {
      return null;
    }

    return (
      <div className={styles.topicItemHeader}>
        <div className={styles.topicItemIcon} />
        <div className={styles.topicItemName}>Action</div>
        <div className={styles.topicItemIssues}>Issues</div>
        {!nodifficulty && false && <div className={styles.topicItemDifficulty}>Difficulty</div>}
        {!scoreless && <div className={styles.topicProgress}>Progress</div>}
      </div>
    );
  }

  renderItems() {
    const {
      items,
      showInspectorButon,
      done,
      scoreless,
      nodifficulty,
      nosubname,
      match: {params}
    } = this.props;

    return items.map(item => {
      if (item.itemType === 'lesson') {
        return <TopicLesson key={item.lessonId} done={done} {...item} />;
      }
      return (
        <TopicAction
          done={done}
          key={item.actionId}
          nodifficulty={nodifficulty}
          nosubname={nosubname}
          scoreless={scoreless}
          showInspectorButon={showInspectorButon}
          href={actionPath({...params, actionId: item.actionId})}
          {...item}
        />
      );
    });
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderItems()}
      </div>
    );
  }
}

export default withRouter(TopicItems);
