import React from 'react';
import {connect} from 'react-redux';
import styles from './quoteActionsBar.scss';
import Button from 'components/button/button';
import {showInModal} from 'modules/modal';
import QuoteRejectionModal from './quoteRejectionModal';

export class QuoteActionsBar extends React.Component {
  triggerRejectionModal = () => {
    this.props.showInModal(QuoteRejectionModal, {
      onReject: this.props.onRejectQuote,
      quoteId: this.props.quoteId,
      quoteKey: this.props.quoteKey
    });
  };

  printQuote = () => {
    window.print();
  };

  render() {
    const {quote} = this.props.quote;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>Your Silktide proposal</h1>
          <p>Reference: {quote.quoteId}</p>
        </div>
        <div className={styles.approvalActions}>
          <Button
            icon={'far fa-check'}
            iconSide="left"
            onClick={this.props.onAcceptQuote}
            good
            tooltip="Selecting this will accept this proposal."
            style={{marginRight: '10px', width: '120px'}}
          >
            Accept
          </Button>
          <Button
            icon={'far fa-times'}
            iconSide="left"
            onClick={this.triggerRejectionModal}
            bad
            tooltip="Selecting this will reject this proposal and let Silktide know."
            style={{width: '120px'}}
          >
            Reject
          </Button>
        </div>
        <div className={styles.otherActions}>
          <Button
            icon={'far fa-print'}
            iconSide="left"
            onClick={this.printQuote}
            wire
            style={{width: '120px'}}
          >
            Print
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {showInModal}
)(QuoteActionsBar);
