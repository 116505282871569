import _ from 'lodash';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import React from 'react';
import moment from 'moment';
import Helmet from 'components/helmet';
import Status from 'components/status';
import PageBody from 'components/page';
import SearchBar from 'components/searchBar';
import {capitalizeFirstLetter} from 'utils/stringUtils';
import {shallPass, SUPER_USER} from 'modules/auth/gandalf';
import {getUniqueFormattedTimestamps} from 'utils/dateTimeUtils';
import SortableTable from 'components/table/sortableTable';
import KeyValuePills from 'components/pill/keyValuePills';
import {reportPath} from 'modules/reports/reportUtils';
import styles from './reportLog.scss';
import FilterInfo from './filterInfo';
import DropdownFilter from './dropdownFilter';
import cx from 'classnames';

const DEFAULT_STATE = {
  filterText: '',
  filterReport: null,
  filterBillable: 'all',
  filterDate: null,
  filterType: 'all'
};

const DATE_FILTER_FORMAT = 'MMM Y';

const COLUMNS = [
  {
    label: 'Screenshot',
    field: 'screenshot',
    type: 'image',
    collapse: '450'
  },
  {label: 'Website', field: 'name', type: 'string'},
  {
    label: 'Pages',
    field: 'sizeFoundWithTooltip',
    type: 'string',
    appearance: 'minor',
    showZero: true,
    cellClassName: styles.pagesCell,
    sortable: 'sizeFound'
  },
  {
    label: 'Type',
    field: 'type',
    type: 'string',
    width: '80px',
    collapse: '800',
    combineWith: 'Website',
    sortable: 'type'
  },
  {
    label: 'Status',
    field: 'runStatus',
    type: 'pills',
    cellClassName: styles.statusCell,
    width: '100px',
    collapse: '700',
    combineWith: 'Pages',
    sortable: 'runStatus'
  },
  {
    label: 'Tested by',
    field: 'testedBy',
    type: 'string',
    width: '110px',
    collapse: '600',
    combineWith: 'Website',
    sortable: 'testedBy'
  },
  {
    label: 'Started',
    field: 'testedOn',
    type: 'date',
    format: 'Do MMM YYYY, h:mm a',
    width: 'auto',
    collapse: '500',
    combineWith: 'Pages',
    sortable: 'testedOn'
  }
];

class ReportLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
    this.dateOptions = getUniqueFormattedTimestamps(
      props.reportLogs.map(row => row.startTime),
      DATE_FILTER_FORMAT
    );
    this.state.filterDate = this.dateOptions[0];
  }

  clearState = () => {
    this.setState(_.merge(DEFAULT_STATE, {filterBillable: 'all'}));
  };

  setFilterText = filterText => {
    this.setState({filterText});
  };

  setBillableType = ({value}) => {
    this.setState({filterBillable: value});
  };

  setDateFilter = obj => {
    const {value = null} = obj || {};
    this.setState({filterDate: value});
  };

  setTypeFilter = ({value}) => {
    this.setState({filterType: value});
  };

  getFilteredRows() {
    const {reportLogs, user} = this.props;

    let filteredReportLogs = reportLogs.filter(currentValue => {
      const {filterText, filterReport, filterBillable, filterDate, filterType} = this.state;

      // If filterText is given, ensure the filterText is in the name. If not, return false!
      if (
        filterText.length > 0 &&
        currentValue.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
      ) {
        return false;
      }

      // Ensure only super users can see hidden rows
      if (!shallPass(user, SUPER_USER) && currentValue.isHidden) {
        return false;
      }

      if (filterReport !== null && currentValue.name !== filterReport) {
        return false;
      }

      // Ensure the rows is billable if we are to only show billable rows.
      if (filterBillable === 'billable' && !currentValue.isBillable) {
        return false;
      }

      // Ensure the row is not billable if we are only to show nonbillable rows.
      if (filterBillable === 'notbillable' && currentValue.isBillable) {
        return false;
      }

      if (filterBillable === 'scheduled' && currentValue.userFullName) {
        return false;
      }

      if (filterBillable === 'not-scheduled' && !currentValue.userFullName) {
        return false;
      }

      if (
        filterDate !== null &&
        filterDate !== moment.unix(currentValue.startTime).format(DATE_FILTER_FORMAT)
      ) {
        return false;
      }

      if (filterType !== 'all' && currentValue.type.toLowerCase() !== filterType) {
        return false;
      }

      return true;
    });

    return filteredReportLogs;
  }

  getAppearenceByStatus(status) {
    switch (status) {
      case 'completed':
        return 'good';
      case 'running':
        return 'warning';
      case 'cancelled':
        return 'bad';
      default:
        return 'default';
    }
  }

  getRunStatus(status) {
    return [
      {
        label: capitalizeFirstLetter(status),
        appearance: this.getAppearenceByStatus(status)
      }
    ];
  }

  renderPagesTooltip({sizeFound, size}) {
    const tooltipText = `${sizeFound} / ${size} max`;
    return tooltipText; //<Tooltip text={tooltipText}>{sizeFound}</Tooltip>;
  }

  renderName(row) {
    const {name, reportId} = row;

    return (
      <span
        className={styles.clickableField}
        onClick={event => {
          this.props.push(reportPath, {reportId}, ['accountId'], false, {}, event);
        }}
      >
        {name}
      </span>
    );
  }

  getRows() {
    const {params} = this.props;

    return this.getFilteredRows().map(report => {
      report.linkLocation = reportPath({
        reportId: report.reportId,
        accountId: params.accountId
      });

      return {
        reportId: report.reportId,
        rowDisabled: report.isHidden ? true : false,
        rowKey: report.missionId,
        testLink: report.linkLocation,
        screenshot: report.thumbnail,
        name: this.renderName(report),
        type: report.type,
        sizeFoundWithTooltip: this.renderPagesTooltip(report),
        sizeFound: report.sizeFound,
        size: report.size,
        runStatus: this.getRunStatus(report.status),
        testedBy: report.userFullName ? report.userFullName : 'Scheduled',
        testedOn: report.startTime
      };
    });
  }

  renderReportLogTable(rows) {
    if (rows.length === 0) {
      return <Status appearance="warning">No logs found.</Status>;
    }

    return <SortableTable rows={rows} columns={COLUMNS} />;
  }

  renderReportStatistics(rows) {
    if (rows.length === 0) {
      return null;
    }

    const stats = [
      {
        appearance: 'major',
        showZero: true,
        label: 'Reports ran',
        value: rows.length
      },
      {
        appearance: 'minor',
        showZero: true,
        label: 'Total pages',
        value: _.sumBy(rows, 'sizeFound')
      },
      {
        appearance: 'minor',
        showZero: true,
        label: 'Number of sites',
        value: _.uniq(rows.map(r => r.reportId)).length
      }
    ];

    return <KeyValuePills data={stats} />;
  }

  renderFilterInfo() {
    const {reportLogs} = this.props;

    if (reportLogs.length === 0) {
      return null;
    }

    const filters = [];

    if (this.state.filterReport !== null) {
      filters.push('Website: "' + this.state.filterReport + '"');
    }

    if (this.state.filterText.length > 0) {
      filters.push('Search term: "' + this.state.filterText + '"');
    }

    if (this.state.filterBillable === 'billable') {
      filters.push('Billable reports');
    }

    if (this.state.filterBillable === 'notbillable') {
      filters.push('Non billable reports');
    }

    if (this.state.filterBillable === 'scheduled') {
      filters.push('Scheduled reports');
    }

    if (this.state.filterBillable === 'not-scheduled') {
      filters.push('Non scheduled reports');
    }

    if (this.state.filterDate !== null) {
      filters.push(this.state.filterDate);
    }

    if (this.state.filterType === 'report') {
      filters.push('Whole website');
    }

    if (this.state.filterType === 'pages') {
      filters.push('Selected pages');
    }

    if (!filters.length) {
      return null;
    }

    return <FilterInfo filters={filters} onClose={this.clearState} />;
  }

  renderBillableFilter(rows) {
    const {user} = this.props;
    if (!shallPass(user, SUPER_USER)) {
      return null;
    }

    const options = [
      {value: 'all', label: 'All reports'},
      {value: 'billable', label: 'Billable reports only'},
      {value: 'notbillable', label: 'Non billable reports only'},
      {value: 'scheduled', label: 'Scheduled reports only'},
      {value: 'not-scheduled', label: 'Non scheduled reports only'}
    ];

    return (
      <DropdownFilter
        label="Billable"
        options={options}
        className={styles.filter}
        value={this.state.filterBillable}
        onChange={this.setBillableType}
      />
    );
  }

  renderDateFilter(rows) {
    return (
      <DropdownFilter
        label="Date"
        className={styles.filter}
        options={this.dateOptions}
        value={this.state.filterDate}
        onChange={this.setDateFilter}
        clearable={true}
      />
    );
  }

  renderTypeFilter(rows) {
    const options = [
      {value: 'all', label: 'All website types'},
      {value: 'report', label: 'Whole website'},
      {value: 'pages', label: 'Selected pages'}
    ];

    return (
      <DropdownFilter
        label="Type"
        options={options}
        className={styles.filter}
        value={this.state.filterType}
        onChange={this.setTypeFilter}
      />
    );
  }

  render() {
    const rows = this.getRows();

    const classes = cx(this.props.className, styles.pageWrapper);

    return (
      <div className={classes}>
        <Helmet title="Usage" />

        <div className={styles.heading}>
          <h3>Usage</h3>
          <SearchBar tabIndex="1" onUserInput={this.setFilterText} />
        </div>

        <div className={styles.filterBar}>
          {this.renderBillableFilter(rows)}
          {this.renderTypeFilter(rows)}
          {this.renderDateFilter(rows)}
        </div>

        <PageBody>
          <div className={styles.flexWrapper}>
            <div className={styles.reportStats}>
              {this.renderFilterInfo()}
              {this.renderReportStatistics(rows)}
            </div>
          </div>

          {this.renderReportLogTable(rows)}
        </PageBody>
      </div>
    );
  }
}

export default connect(
  null,
  {push}
)(ReportLog);
