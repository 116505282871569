import React from 'react';
import Code from 'components/code';
import styles from './htmlIssue.scss';

class HtmlWithTextIssue extends React.Component {
  render() {
    const {point} = this.props;
    const {html, text, language} = point;

    if (!html) return null;

    return (
      <div>
        <Code language={!language ? 'html' : language}>{html}</Code>
        {text && <p className={styles.caption}>{text}</p>}
      </div>
    );
  }
}

export default HtmlWithTextIssue;
