import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {FETCH_THEMES} from '../constants';
import {receiveThemes, fetchThemesComplete} from '../actions';
import {searchCampaignsEndpoint} from '../utils';

export default function* fetchSearchCampaignSaga() {
  yield takeLatest(FETCH_THEMES, fetchSearchCampaign);
}

function* fetchSearchCampaign({reportId}) {
  const data = yield processRequest('GET', searchCampaignsEndpoint({reportId}), {});

  yield put(fetchThemesComplete(reportId));

  if (Array.isArray(data)) {
    yield put(receiveThemes(reportId, data));
  }
}
