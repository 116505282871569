import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {triggerEditTest} from 'modules/tests';
import Button from 'components/button';

export class EditTestButton extends React.Component {
  static propTypes = {
    testId: PropTypes.any.isRequired
  };

  handleClick = () => {
    const {triggerEditTest, testId} = this.props;

    triggerEditTest(testId);
  };

  render() {
    const {children, ...buttonProps} = this.props;

    return (
      <Button cta {...buttonProps} onClick={this.handleClick}>
        {children || 'Edit Test'}
      </Button>
    );
  }
}

export default connect(
  null,
  {triggerEditTest}
)(EditTestButton);
