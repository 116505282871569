import React from 'react';
import {Route, Switch} from 'react-router-dom';
import CourseRoute from './courseRoute';
import CoursesPage from '../pages/coursesPage';
import {useSelector, useDispatch} from 'hooks/redux';
import {requestCourses} from 'modules/courses/actions';
import {requestingCoursesSelector} from 'modules/courses/selectors';
import {NotFoundPage} from 'modules/location/pages/404Page';
import Spinner from 'components/spinner';

export default function CoursesRoute({match: {params, path}}) {
  const dispatch = useDispatch();
  const requestingCourses = useSelector(requestingCoursesSelector);

  React.useEffect(() => {
    dispatch(requestCourses());
  }, [params.accountId]);

  if (requestingCourses.get('error')) {
    return <NotFoundPage />;
  }

  if (!requestingCourses.get('loaded') || requestingCourses.get('loading')) {
    return <Spinner marginTop={100} />;
  }

  return (
    <Switch>
      <Route exact path={path} component={CoursesPage} />
      <Route path={`${path}/:courseId`} component={CourseRoute} />
    </Switch>
  );
}
