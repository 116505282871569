import {checkpointKey} from './checkpointUtils';

export const UPDATE_CHECKPOINT = 'checkpoints:UPDATE_CHECKPOINT';

export function updateCheckpoint(checkpoint) {
  const {testId, checkpointId} = checkpoint;
  if (!testId || !checkpointId) throw 'invalid checkpoint';

  return {
    type: UPDATE_CHECKPOINT,
    checkpoint
  };
}

export function ignoreCheckpoint(checkpoint, isIgnored = true) {
  return updateCheckpoint({...checkpoint, isIgnored});
}

export function changeCheckpointTarget(checkpoint, newValue) {
  return updateCheckpoint({
    ...checkpoint,
    targetValue: newValue,
    hasTarget: !!newValue
  });
}
