import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'hooks/redux';
import {saveLessonState} from 'modules/courses/actions';
import {nextLessonSelector} from 'modules/courses/selectors';
import Button from 'components/button';
import {push} from 'modules/location';
import {courseLessonPath, coursePath} from 'modules/courses/utils';
import Confetti from 'react-dom-confetti';
import styles from '../../pages/courses.scss';
import buttonStyles from './completeLessonButton.scss';
import cx from 'classnames';

const config = {
  angle: 90,
  spread: 120,
  startVelocity: 40,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 4000,
  stagger: 1,
  width: '18px',
  height: '5px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

export default function CompleteLessonButton({children, lesson}) {
  const dispatch = useDispatch();
  const lessonId = lesson.get('lessonId');
  const nextLesson = useSelector(nextLessonSelector, {
    courseId: lesson.get('courseId'),
    stageId: lesson.get('stageId'),
    lessonId
  });
  const [confettiActive, setConfettiActive] = useState(false);

  return (
    <div className={styles.buttonWrapper}>
      <Confetti active={confettiActive} config={config} />
      <Button
        good
        icon="fas fa-chevron-right"
        iconSide="right"
        className={cx(styles.bigButton, buttonStyles.button)}
        onClick={() => {
          if (!confettiActive) {
            setConfettiActive(true);

            setTimeout(() => {
              dispatch(saveLessonState(lesson.set('state', 'completed').toJS()));

              if (nextLesson) {
                dispatch(
                  push(courseLessonPath, {
                    lessonId: nextLesson.get('lessonId'),
                    courseId: nextLesson.get('courseId')
                  })
                );
              } else {
                dispatch(
                  push(coursePath, {
                    courseId: lesson.get('courseId')
                  })
                );
              }
            }, 1500);
          }
        }}
      >
        {children ? children : 'Complete and continue'}
      </Button>
    </div>
  );
}
