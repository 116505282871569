exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Tmoh0nx9bVQPL4kx0OgGz{margin-right:12px;padding:5px 10px;color:#fff;display:flex;align-items:center;font-size:20px;justify-content:center}.BERxQzZ3BT8UfBH3qSTB_{margin-right:10px;font-size:14px;opacity:.5}._2ebgR_G6_cO8o23Bluiu40{flex:1}.xKYi-QEExMbT_HtctOfR8{font-size:22px;min-height:auto;padding:5px 18px}", ""]);

// exports
exports.locals = {
	"title": "_1Tmoh0nx9bVQPL4kx0OgGz",
	"icon": "BERxQzZ3BT8UfBH3qSTB_",
	"text": "_2ebgR_G6_cO8o23Bluiu40",
	"closeButton": "xKYi-QEExMbT_HtctOfR8"
};