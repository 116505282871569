import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DIGEST_SUBSCRIPTIONS} from 'modules/digests/constants';
import {digestSubscriptionsEndpoint} from 'modules/digests/utils';
import {
  receiveDigestSubscriptions,
  requestDigestSubscriptionsComplete
} from 'modules/digests/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestDigestSubscriptionsListener() {
  yield takeLatest(REQUEST_DIGEST_SUBSCRIPTIONS, requestDigestSubscriptionsSaga);
}

function* requestDigestSubscriptionsSaga(action) {
  const url = digestSubscriptionsEndpoint(action);

  yield processRequest('GET', url, {
    *success(data) {
      yield put(receiveDigestSubscriptions(action.userId, data.digestSubscriptions));
      yield put(requestDigestSubscriptionsComplete());
    },
    *error(err) {
      yield put(requestDigestSubscriptionsComplete(err));
    },
    errorMessage: couldNot('request digest subscriptions')
  });
}
