import {fromJS} from 'immutable';

// Note: this is an array/List so that we can keep the order intact.
// Severities in order from bad to good.
export default fromJS([
  {
    label: 'Problems',
    appearance: 'bad',
    severityId: '4',
    type: 'confirmed' // type is used as a general identifier on the front end as it's clearer than the numeric id
  },
  {
    label: 'Decisions',
    appearance: 'warning',
    severityId: '3',
    type: 'potential'
  },
  {
    label: 'Suggestions',
    appearance: 'major',
    severityId: '0',
    type: 'suggested'
  },
  {
    label: 'Ignored',
    appearance: 'minor',
    severityId: '2',
    type: 'ignored'
  },
  {
    label: 'Approved',
    appearance: 'good',
    severityId: '1',
    type: 'approved'
  }
]);
