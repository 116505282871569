import InspectorHeaderButton from 'modules/inspector/components/inspectorHeaderButton';
import {retestPageFromInspector} from 'modules/inspector/inspectorActions';
import {inspectorRetestButtonSelector} from 'modules/inspector/inspectorSelectors';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

class InspectorRetestButton extends React.Component {
  render() {
    const {show, label, ...props} = this.props;
    if (!show) return null;

    return (
      <InspectorHeaderButton icon="redo-alt" {...props}>
        {label}
      </InspectorHeaderButton>
    );
  }
}

export default compose(
  connect(inspectorRetestButtonSelector),
  connect(
    null,
    (dispatch, props) => {
      return {
        onClick() {
          const {
            params: {reportId},
            query: {testId, pageHash}
          } = props;

          dispatch(retestPageFromInspector(reportId, pageHash, testId));
        }
      };
    }
  )
)(InspectorRetestButton);
