import {takeLatest, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {UPDATE_CHECKS, receiveChecks} from 'modules/checks';
import {checksEndpoint} from 'modules/checks/checkUtils';
import {couldNot} from 'utils/errorUtils';
import {addMessage} from 'modules/alerts';

export default function* requestChecksSaga() {
  yield takeLatest(UPDATE_CHECKS, function*({checks}) {
    // checks in the format {[checkType]: isIncluded, ...}
    // needs to be in format {[checkType]: check}
    // however we don't need ALL the check information, just the `isIncluded` key

    checks = Object.keys(checks).reduce((obj, checkType) => {
      obj[checkType] = {isIncluded: checks[checkType]};
      return obj;
    }, {});

    yield processRequest(
      'POST',
      checksEndpoint(),
      {
        success: function*(updatedChecks) {
          yield put(receiveChecks(updatedChecks));
          yield put(addMessage('success', 'Changes saved.'));
        },
        errorMessage: couldNot('modify which checks are enabled')
      },
      checks
    );
  });
}
