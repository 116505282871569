import React from 'react';

class Tab extends React.Component {
  render() {
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          if (child.props.config.type === 'Row') {
            const newConfig = {...child.props.config};
            child = React.cloneElement(child, {config: newConfig});
          }
          return child;
        })}
      </div>
    );
  }
}

export default Tab;
