import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {leaderboardRouteSelector} from 'modules/leaderboards/selectors';
import LeaderboardPage from 'modules/leaderboards/pages/leaderboardPage';
import LeaderboardsPage from 'modules/leaderboards/pages/leaderboardsPage';
import {fetchLeaderboards, fetchLeaderboardResults} from 'modules/leaderboards/actions';
import {parseQuery} from 'utils/urlUtils';
import {setLocationParamItem} from 'modules/location/location';

export class LeaderboardRoute extends React.Component {
  componentWillMount() {
    const {
      match: {params},
      location: {search},
      fetchLeaderboards,
      fetchLeaderboardResults,
      shouldRequestLeaderboards
    } = this.props;
    const query = parseQuery(search);

    if (shouldRequestLeaderboards) {
      fetchLeaderboards();
    }
    if (params.leaderboardId) {
      // if the leaderboardId exists and it changes, request the results for that leaderboard
      fetchLeaderboardResults(
        params.leaderboardId,
        query.limit,
        query.offset,
        query.order,
        query.search
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      fetchLeaderboardResults,
      match: {params},
      location: {search}
    } = this.props;
    const query = parseQuery(search);
    const nextQuery = parseQuery(nextProps.location.search);

    const {
      match: {
        params: {leaderboardId}
      }
    } = nextProps;

    if (
      leaderboardId &&
      (leaderboardId !== params.leaderboardId ||
        query.limit !== nextQuery.limit ||
        query.offset !== nextQuery.offset ||
        query.order !== nextQuery.order ||
        query.search !== nextQuery.search)
    ) {
      // if the leaderboardId exists and it changes, request the results for that leaderboard
      fetchLeaderboardResults(
        leaderboardId,
        nextQuery.limit,
        nextQuery.offset,
        nextQuery.order,
        nextQuery.search
      );
    }
  }

  render() {
    const {
      match: {params},
      location: {search}
    } = this.props;

    if (params.leaderboardId) {
      // show specific leaderboard
      return (
        <LeaderboardPage
          leaderboardId={params.leaderboardId}
          params={params}
          query={parseQuery(search)}
        />
      );
    } else {
      // show all leaderboards
      return <LeaderboardsPage />;
    }
  }
}

export default compose(
  connect(
    leaderboardRouteSelector,
    {fetchLeaderboards, fetchLeaderboardResults}
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(LeaderboardRoute);
