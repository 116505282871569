exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2FGBez2nroIqISnGjOfZes{display:inline-block;padding:2px 6px;border:1px solid #df1616;border-radius:5px;color:#df1616}._30CqZVPjOsflKv_CuVuZra{color:#888 !important}", ""]);

// exports
exports.locals = {
	"label": "_2FGBez2nroIqISnGjOfZes",
	"url": "_30CqZVPjOsflKv_CuVuZra"
};