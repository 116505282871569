import React from 'react';
import Dropdown, {DropdownOption} from 'components/dropdown';
import Icon from 'components/font-awesome';
import {connect} from 'react-redux';
import styles from './actionPriority.scss';
import {changeActionState} from 'modules/actions/actions';
import {reportSelector} from 'modules/reports/reportSelectors';
import {ROLE_VIEWER, reportIsAllowed, ROLE_CONTRIBUTOR} from 'modules/gandalf/gandalfUtils';

const PRIORITIES = [
  {
    value: 5,
    label: 'Highest',
    icon: 'fas fa-exclamation-circle',
    color: '#ed4b4b'
  },
  {
    value: 4,
    label: 'High',
    icon: 'fas fa-exclamation-triangle',
    color: '#eba52c'
  },
  {
    value: 3,
    label: 'Medium',
    icon: 'fas fa-exclamation-square',
    color: '#ebd12c'
  },
  {
    value: 2,
    label: 'Low',
    icon: 'fas fa-info-circle',
    color: '#808080'
  },
  {
    value: 1,
    label: 'Lowest',
    icon: 'fas fa-info-square',
    color: '#c0c0c0'
  }
];

const forceStates = ['done', 'ignored'];

export class ActionPriority extends React.Component {
  ignoreAction = () => {
    const {reportId, actionId} = this.props;
    this.props.changeActionState(reportId, actionId, 'ignored', null);
  };

  isForceState() {
    const {actionState} = this.props;
    return forceStates.indexOf(actionState) !== -1;
  }

  renderButton() {
    const {value, showLabel, actionState, onChange, hideOnChangeIcon, reportRole} = this.props;
    const priority = PRIORITIES[5 - value];
    const isForceState = this.isForceState();

    const extraParams = {};

    if (!priority) {
      return null;
    }

    if (priority.iconType) {
      extraParams.type = priority.iconType;
    }

    if (isForceState) {
      const forceIcon = actionState === 'done' ? 'check' : 'times';
      const forceColor = actionState === 'done' ? '#78d87c' : '#333';

      return (
        <div className={styles.buttonWrapper}>
          <Icon
            {...extraParams}
            name={forceIcon}
            size="lg"
            className={styles.priorityIcon}
            style={{color: forceColor}}
          />
          {showLabel ? (
            <span className={styles.labelTextButton}>
              {actionState === 'done' ? 'Done' : 'Ignored'}
            </span>
          ) : (
            ''
          )}
        </div>
      );
    }

    return (
      <div className={styles.buttonWrapper}>
        <Icon
          {...extraParams}
          name={priority.icon}
          size="lg"
          className={styles.priorityIcon}
          style={{color: priority.color}}
        />
        {showLabel ? <span className={styles.labelTextButton}>{priority.label}</span> : ''}
        {reportIsAllowed(ROLE_CONTRIBUTOR, reportRole) && onChange && !hideOnChangeIcon && (
          <Icon name="angle-down" size="lg" className={styles.angleDown} />
        )}
      </div>
    );
  }

  render() {
    const {onChange, value, actionState, reportId, actionId, reportRole} = this.props;

    if (!reportIsAllowed(ROLE_CONTRIBUTOR, reportRole) || !onChange || this.isForceState()) {
      return this.renderButton();
    }

    return (
      <Dropdown button={this.renderButton()}>
        {PRIORITIES.map(priority => {
          const extraParams = {};

          if (priority.iconType) {
            extraParams.type = priority.iconType;
          }

          return (
            <DropdownOption
              key={priority.value}
              onClick={e => onChange && onChange(priority.value)}
            >
              <Icon
                {...extraParams}
                size="lg"
                name={priority.icon}
                style={{color: priority.color}}
              />{' '}
              {priority.label}
            </DropdownOption>
          );
        })}

        {reportId && actionId && (
          <DropdownOption onClick={this.ignoreAction}>
            <Icon size="lg" name={'times'} style={{color: '#333'}} /> Ignored
          </DropdownOption>
        )}
      </Dropdown>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      reportRole: reportSelector(state, props).getIn(['ownPermissions', 'role'])
    };
  },
  {changeActionState}
)(ActionPriority);
