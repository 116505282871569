// Map of our internal analytic events to FB events
// If event isn't in this map, it won't get tracked by FB
const eventTranslators = {
  SIGNUP_SUCCESS: () => ['CompleteRegistration'],
  CONVERSION_START: () => ['InitiateCheckout'],
  CONVERSION_ADD_PAYMENT_DETAILS: () => ['AddPaymentInfo'],
  CONVERSION_CONFIRM: () => ['Purchase']

  /*
  SAMPLE_EVENT = (props) => ['fb_event_name', {...fbProps}]
  */
};

export default function trackWithFacebook(eventName, props = {}) {
  const eventTranslator = eventTranslators[eventName];

  if (!eventTranslator || !window.fbq) return;

  window.fbq('track', ...eventTranslator(props));
}
