import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

class Quote extends React.Component {
  render() {
    return <blockquote className={styles.quote}>{this.props.data}</blockquote>;
  }
}

Quote.propTypes = {};

export default Quote;
