exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3LMqFbnzdh8KBOKAHxhj2R{background:#fff;border:1px solid #e1e1e1}._3LMqFbnzdh8KBOKAHxhj2R._1E4jVATa6j7W180sJ-WQ3Q{padding:10px}._3LMqFbnzdh8KBOKAHxhj2R h3{margin:0;font-size:18px}._3f-wtaw4R419CqTYVjY6fh{padding:0 !important}.wdh7kW3UuP9BEIm_Ol_ai{padding:20px}._2gcG8A7C_NM0xjpbOnF3jt{border:0}._1PLEaIFo9szSAVqm9DLcdx{border-top:0}.yLnGkQf4Hw8uop2ZiKxbR{border-bottom:0}._3jBwAJ5q77ZAb0d3AXZyDv{margin-bottom:20px}._2sWymOEzU9xL8ftxHiQhY5{overflow:auto}._29STTELREH5Aeno-ML9MSM{box-shadow:0 2px 10px rgba(0,0,0,.1)}@media print{._3LMqFbnzdh8KBOKAHxhj2R{border:0}}", ""]);

// exports
exports.locals = {
	"newpanel": "_3LMqFbnzdh8KBOKAHxhj2R",
	"toolbar": "_1E4jVATa6j7W180sJ-WQ3Q",
	"hidePadding": "_3f-wtaw4R419CqTYVjY6fh",
	"padding": "wdh7kW3UuP9BEIm_Ol_ai",
	"hideBorder": "_2gcG8A7C_NM0xjpbOnF3jt",
	"hideBorderTop": "_1PLEaIFo9szSAVqm9DLcdx",
	"hideBorderBottom": "yLnGkQf4Hw8uop2ZiKxbR",
	"marginBottom": "_3jBwAJ5q77ZAb0d3AXZyDv",
	"overflowAuto": "_2sWymOEzU9xL8ftxHiQhY5",
	"shadow": "_29STTELREH5Aeno-ML9MSM"
};