import React from 'react';
import styles from './inspectorWrapper.scss';

export default function InspectorWrapper({children}) {
  return (
    <div className={styles.container}>
      <div className={styles.middle}>{children}</div>
    </div>
  );
}
