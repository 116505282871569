import {
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARDS,
  FETCH_LEADERBOARD_RESULTS,
  FETCH_LEADERBOARD_COMPLETE,
  FETCH_LEADERBOARDS_COMPLETE,
  FETCH_LEADERBOARD_REPORTS_COMPLETE,
  RECEIVE_LEADERBOARD,
  RECEIVE_LEADERBOARDS,
  RECEIVE_LEADERBOARD_RESULTS,
  REQUEST_CREATE_LEADERBOARD,
  REQUEST_UPDATE_LEADERBOARD,
  REQUEST_DELETE_LEADERBOARD,
  DELETE_LEADERBOARD,
  RECEIVE_DEFAULT_COLUMNS,
  EXPORT_LEADERBOARD,
  LEADERBOARD_EXPORTED
} from './constants';

export function fetchLeaderboards() {
  return {type: FETCH_LEADERBOARDS};
}

export function fetchLeaderboard(leaderboardId) {
  return {type: FETCH_LEADERBOARD, leaderboardId};
}

export function fetchLeaderboardResults(leaderboardId, limit, offset, order, search) {
  return {type: FETCH_LEADERBOARD_RESULTS, leaderboardId, limit, offset, order, search};
}

export function fetchLeaderboardsComplete(error) {
  return {type: FETCH_LEADERBOARDS_COMPLETE, error};
}

export function fetchLeaderboardComplete(error) {
  return {type: FETCH_LEADERBOARD_COMPLETE, error};
}

export function fetchLeaderboardResultsComplete(error) {
  return {type: FETCH_LEADERBOARD_REPORTS_COMPLETE, error};
}

export function receiveLeaderboards(leaderboards, append = false) {
  return {type: RECEIVE_LEADERBOARDS, leaderboards, append};
}

export function receiveLeaderboard(leaderboard) {
  return {type: RECEIVE_LEADERBOARD, leaderboard};
}

export function receiveLeaderboardResults(leaderboardId, results, maxReports) {
  return {
    type: RECEIVE_LEADERBOARD_RESULTS,
    leaderboardId: leaderboardId.toString(),
    results,
    maxReports
  };
}

export function requestCreateLeaderboard(name, columns, reportIds, config, onSuccess) {
  return {type: REQUEST_CREATE_LEADERBOARD, name, columns, reportIds, onSuccess, ...config};
}

export function requestUpdateLeaderboard(
  leaderboardId,
  name,
  columns,
  reportIds,
  config,
  onSuccess
) {
  return {
    type: REQUEST_UPDATE_LEADERBOARD,
    leaderboardId: leaderboardId.toString(),
    name,
    columns,
    reportIds,
    onSuccess,
    ...config
  };
}

export function requestDeleteLeaderboard(leaderboardId) {
  return {type: REQUEST_DELETE_LEADERBOARD, leaderboardId: leaderboardId.toString()};
}

export function deleteLeaderboard(leaderboardId) {
  return {type: DELETE_LEADERBOARD, leaderboardId: leaderboardId.toString()};
}

export function receiveDefaultColumns(defaultColumns) {
  return {type: RECEIVE_DEFAULT_COLUMNS, defaultColumns};
}

export function exportLeaderboard(leaderboardId) {
  return {type: EXPORT_LEADERBOARD, leaderboardId};
}

export function leaderboardExported() {
  return {type: LEADERBOARD_EXPORTED};
}
