import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/button';
import {getProps} from 'utils/immutableUtils';
import FormComponent from 'modules/form/components/form';
import {configOptionsFormSelector} from 'modules/configOptions/configOptionsSelectors';
import {FormContext} from 'components/layoutComponents/form/formContext';
import {requestSetConfigOptions, setSuccessMessage} from 'modules/configOptions';

export class Form extends React.Component {
  state = {
    formApi: {
      values: {},
      setValue: (name, value) => {},
      setFieldRef: name => ref => {}
    }
  };

  setValue = (name, value) => {
    this.myRef.current && this.myRef.current.handleChange(name)(value);
  };

  setFieldRef = name => ref => {
    this.myRef.current && this.myRef.current.setField(name)(ref);
  };

  componentDidMount() {
    const {configData} = this.props;
    const defaultValues = getProps(configData, this.getFields());

    this.myRef = React.createRef();

    this.setState({
      formApi: {
        values: defaultValues,
        setValue: this.setValue.bind(this),
        setFieldRef: this.setFieldRef.bind(this)
      }
    });
  }

  componentWillUnmount() {
    this.props.setSuccessMessage('');
  }

  handleChange = (name, value, data, isValid) => {
    this.setState({
      formApi: {
        values: data,
        setValue: this.setValue.bind(this),
        setFieldRef: this.setFieldRef.bind(this)
      }
    });
  };

  handleValidSubmit = data => {
    const {testId} = this.props.params;

    // from {[name]: value, ...} into [{testId, name, value}, {globalName, value}]
    const options = Object.keys(data).map(name => {
      const value = data[name];
      const isGlobal = name.indexOf('.') >= 0;
      return isGlobal ? {globalName: name, value} : {testId, name, value};
    });

    this.props.requestSetConfigOptions(options);
  };

  getFields() {
    return this.props.config.children
      .filter(c => {
        return c.type === 'Config';
      })
      .map(c => {
        return c.name || c['global-name'];
      });
  }

  render() {
    const {children, configData, successMessage} = this.props;
    const defaultValues = getProps(configData, this.getFields());

    return (
      <FormContext.Provider value={this.state.formApi}>
        <FormComponent
          ref={this.myRef}
          defaultValue={defaultValues}
          onChange={this.handleChange}
          onValidSubmit={this.handleValidSubmit}
        >
          {children}

          <p>{successMessage}</p>

          <Button type="submit">Save</Button>
        </FormComponent>
      </FormContext.Provider>
    );
  }
}

export default connect(
  configOptionsFormSelector,
  {requestSetConfigOptions, setSuccessMessage}
)(Form);
