import {fromJS} from 'immutable';
import {basePath} from 'modules/auth/authUtils';

export const FOLDER_DRAG_TYPE = 'folder';

export const EXAMPLES_FOLDER = 2;
export const EXAMPLES_VIEW = 2;
// The key to use when storing exampleReports to display at the bottom of folders (for ECOMMERCE_TRIAL users)
export const EXAMPLE_REPORTS_KEY = 'exampleReports';

export function handleSidebarFolderDrop(monitor) {
  // If a child component has already handled the drop, ignore.
  if (monitor.didDrop()) {
    return monitor.getDropResult();
  }

  return {folderId: this.props.folderId};
}

export function foldersPath() {
  return basePath(...arguments) + '/folders';
}

export function folderPath({folderId}) {
  return foldersPath(...arguments) + `/${folderId}`;
}

export function examplesFolderPath() {
  return folderPath({folderId: EXAMPLES_FOLDER});
}

export function foldersEndpoint() {
  return 'folders';
}

export function folderEndpoint({folderId}) {
  return `${foldersEndpoint()}/${folderId}`;
}

export function moveFolderToFolderEndpoint({fromFolder: folderId}) {
  return `${folderEndpoint({folderId})}/move`;
}

export function findImmutableFolder(folders, folderId, level = 0) {
  let cat = null;

  for (let i = 0, l = folders.size; i < l; ++i) {
    let curr = folders.get(i);

    if (curr.get('folderId') == folderId) {
      // we add the level information so we can easily tell what level this folder is at
      cat = curr.set('level', level);
      break;
    }

    if (curr.get('children')) {
      cat = findImmutableFolder(curr.get('children'), folderId, level + 1);

      if (cat) {
        break;
      }
    }
  }

  return cat;
}
