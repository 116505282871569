import _ from 'lodash';
import React from 'react';
import {formatGivenNumber, getBytes, getOrdinal} from './numberUtils.js';
import {niceUrl, getFilename} from 'utils/urlUtils';

const BETWEEN_BRACES = /\{(.*?)\}/g;

const FILTERS = {
  ordinal(value) {
    return getOrdinal(value);
  },

  int(value) {
    return formatGivenNumber(value, 0);
  },

  percentage(value) {
    return formatGivenNumber(value, 1) + '%';
  },

  bytes(value) {
    return getBytes(value);
  },

  url(value) {
    return `<a href="${value}" target="_blank">${niceUrl(value, true)}</a>`;
  },

  filename(value) {
    return `<a href="${value}" target="_blank">${getFilename(value)}</a>`;
  },

  bold(value) {
    return `<b>${value}</b>`;
  },

  // color(value) {
  // if (!value || (value.indexOf('rgb') === -1 && value.indexOf('#') === -1)) {
  // return value;
  // }
  // return `<span style="background-color: ${value}; width: 20px; height: 20px; display: inline-block; border: 1px solid #999; box-shadow: 1px 1px #999; margin: 0; padding: 0;"></span>`
  // },

  float(value, context, decimals = 2) {
    return formatGivenNumber(value, decimals);
  },

  link(value, context, urlKey) {
    let url = getFromContext(context, urlKey);

    if (_.isUndefined(url) || _.isNull(url) || !url) {
      return value;
    }

    return `<a href="${url}" target="_blank">${value}</a>`;
  }
};

// Interpolates an HTML string. This is potentially dangerous and should only be
// used on trusted strings.
export function interpolateToJSX(string, context) {
  const markup = {__html: interpolate(...arguments)};
  return <span dangerouslySetInnerHTML={markup} />;
}

// Interpolates the `string` with the values provided in `context`
export function interpolate(string, context = {}) {
  if (_.isUndefined(string)) return '';

  return string.replace(BETWEEN_BRACES, function(match, expr) {
    const {key, filters} = parseExpression(expr);
    let value = getFromContext(context, key);

    if (_.isUndefined(value)) {
      value = key;
    }

    if (_.isNull(value)) return '';
    if (_.isEmpty(filters)) return value;

    return _.reduce(filters, applyFilter, {value: value, context: context});
  });
}

export function choosePluralVersion(value, singleString, pluralString, zeroString) {
  if (value == 1.0 || !pluralString) {
    return singleString;
  }

  if (value == 0 && zeroString) {
    return zeroString;
  }

  return pluralString;
}

// Does this string contain interpolation expressions i.e. "user: {name}"
export function hasInterpolation(string) {
  // cannot do `BETWEEN_BRACES.test(string)` because regex is mutable
  return !!string.match(BETWEEN_BRACES);
}

// This takes an object that looks like
// {props: {value: int, ...context}, plural, singular, ...any} and
// returns an interpolated string
export function magicallyInterpolate({singular, plural, zero, props}, toJSX = true) {
  const {value = 1} = props || {};
  const string = choosePluralVersion(value, singular, plural, zero);
  const method = toJSX ? interpolateToJSX : interpolate;
  return method(string, props);
}

// Internal functions:

function parseExpression(expr) {
  const [key, ...filters] = expr.split('|');
  return {key, filters};
}

function applyFilter(value, filter) {
  let [filterName, ...args] = filter.split(':');

  if (!FILTERS[filterName] && __DEV__) {
    console.error(`filter ${filterName} doesn't exist`);
  }

  return (FILTERS[filterName] || _.identity)(value.value, value.context, ...args);
}

// Get value from context.
// Abstracted so that context can be immutable.
// key can potentially be deep i.e. user.name.
function getFromContext(context, key) {
  if (typeof context.getIn === 'function') {
    return context.getIn(key.split('.'));
  }
  return _.get(context, key);
}
