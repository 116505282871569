export const REQUEST_DASHBOARDS = 'dashboards:REQUEST_DASHBOARDS';
export const REQUEST_DASHBOARDS_COMPLETE = 'dashboards:REQUEST_DASHBOARDS_COMPLETE';

export const REQUEST_DASHBOARD = 'dashboards:REQUEST_DASHBOARD';
export const REQUEST_DASHBOARD_COMPLETE = 'dashboards:REQUEST_DASHBOARD_COMPLETE';

export const REQUEST_DASHBOARD_RESULTS = 'dashboards:REQUEST_DASHBOARD_RESULTS';
export const REQUEST_DASHBOARD_RESULTS_COMPLETE = 'dashboards:REQUEST_DASHBOARD_RESULTS_COMPLETE';

export const SAVE_DASHBOARD = 'dashboards:SAVE_DASHBOARD';
export const SAVE_DASHBOARD_COMPLETE = 'dashboards:SAVE_DASHBOARD_COMPLETE';

export const DELETE_DASHBOARD = 'dashboards:DELETE_DASHBOARD';
export const DELETE_DASHBOARD_COMPLETE = 'dashboards:DELETE_DASHBOARD_COMPLETE';

export const REQUEST_WIDGET_TYPES = 'dashboards:REQUEST_WIDGET_TYPES';
export const REQUEST_WIDGET_TYPES_COMPLETE = 'dashboards:REQUEST_WIDGET_TYPES_COMPLETE';

export const ADD_WIDGET = 'dashboards:ADD_WIDGET';
export const EDIT_WIDGET = 'dashboards:EDIT_WIDGET';
export const SAVE_WIDGET_COMPLETE = 'dashboards:SAVE_WIDGET_COMPLETE';

export const CONFIRM_DELETE_WIDGET = 'dashboards:CONFIRM_DELETE_WIDGET';
export const CONFIRM_DELETE_WIDGET_COMPLETE = 'dashboards:CONFIRM_DELETE_WIDGET_COMPLETE';

export const ADD_DASHBOARD_ROW = 'dashboards:ADD_DASHBOARD_ROW';
export const ADD_DASHBOARD_COLUMN = 'dashboards:ADD_DASHBOARD_COLUMN';
export const REMOVE_DASHBOARD_ROW = 'dashboards:REMOVE_DASHBOARD_ROW';
export const REMOVE_DASHBOARD_COLUMN = 'dashboards:REMOVE_DASHBOARD_COLUMN';

export const INSERT_DASHBOARD_ROW = 'dashboards:INSERT_DASHBOARD_ROW';
export const SET_DASHBOARD_COLUMN_COUNT = 'dashboards:SET_DASHBOARD_COLUMN_COUNT';
export const SET_DASHBOARD_COLUMN_COUNT_COMPLETE = 'dashboards:SET_DASHBOARD_COLUMN_COUNT_COMPLETE';
