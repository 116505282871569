import {fromJS} from 'immutable';
import {FETCH_AUDIT_LOGS, FETCH_AUDIT_LOGS_COMPLETE} from 'modules/auditLog/constants';

const initialState = fromJS({
  isLoading: false,
  auditLogs: null
});

export default function auditLogReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUDIT_LOGS:
      return state.set('isLoading', true);

    case FETCH_AUDIT_LOGS_COMPLETE:
      return state.set('isLoading', false).set('auditLogs', action.auditLogs);
  }

  return state;
}
