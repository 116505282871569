import {delay} from 'redux-saga';
import {put, select, race, call, take, fork, cancel, takeLatest} from 'redux-saga/effects';
import {unreadAchievements} from 'modules/achievements/achievementSelectors';
import {
  RECEIVE_ACHIEVEMENTS,
  HIDE_ACHIEVEMENT,
  showAchievement,
  readAchievement
} from 'modules/achievements';
import {LOGOUT} from 'modules/auth';
import {isOpenSelector} from 'modules/nps/npsSelectors';
import {SET_NPS_ENABLE} from 'modules/nps';

export default function* onReceiveAchievementsSaga() {
  yield takeLatest(RECEIVE_ACHIEVEMENTS, function*() {
    // cancel the saga if logout
    const process = yield fork(onReceiveAchievements);
    const action = yield take(LOGOUT);

    yield cancel(process);
  });
}

function* onReceiveAchievements() {
  // We get `achievements` passed as arguments, but those relate to the achievements that were just received,
  // not the achievements in the queue. We ignore the arguments and select the unread achievements from the queue.
  // The RECEIVE_ACHIEVEMENTS action in the achievements reducer has just stored the recieved achievements in state.
  // We just need to access the queue of unread achievements.

  const achievements = yield select(unreadAchievements);

  while (achievements.length) {
    const npsOpen = yield select(isOpenSelector);

    // wait for NPS to close before showing achievement
    if (npsOpen) {
      let npsAction = yield take(SET_NPS_ENABLE);
      while (npsAction.isEnabled) {
        npsAction = yield take(SET_NPS_ENABLE);
      }
    }

    const {achievementId} = achievements.shift();

    // sets the `currentAcheivementId` in state
    yield put(showAchievement(achievementId));

    const {didHide, timeout} = yield race({
      didHide: take(HIDE_ACHIEVEMENT),
      timeout: call(delay, 6000)
    });

    yield put(readAchievement(achievementId));
    yield delay(2000);
  }
}
