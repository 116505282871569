import React from 'react';
import cx from 'classnames';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {doInstruction} from 'modules/instructions';
import ButtonComponent from 'components/button';
import {evalInstruction} from 'utils/instructionUtils';
import Gandalf, {ReportGandalf} from 'components/gandalf';
import {isAccountGandalf} from 'modules/auth/gandalf';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {testPath} from 'modules/tests/testUtils';
import Icon from 'components/font-awesome';
import styles from './control.scss';
import {isVirtualUserSelector} from 'modules/auth/authSelectors';
import {addQueryString} from 'utils/urlUtils';

export class Control extends React.Component {
  handleClick = () => {
    const {config, filter} = this.props;
    const {instruction} = config;

    this.props.doInstruction(instruction, config, filter);
  };

  renderButtonComponent = tablet => {
    const {config, filter} = this.props;
    const {appearance, instruction, square, special} = config;

    // we need to apply an ID to the button (IF it is of type "dropdown")
    const command = evalInstruction(instruction, config, filter);

    const classes = {
      [appearance]: true
    };

    if (command.type === 'export' && tablet) {
      return null;
    }

    if (special === 'back') {
      return (
        <Icon
          name="chevron-left"
          size="2x"
          className={styles.headerIcon}
          onClick={this.handleClick}
        />
      );
    }

    const id = command.type === 'dropdown' ? command.id : undefined;

    if (command.type === 'test') {
      return (
        <Link
          to={addQueryString(
            testPath({...this.props.match.params, testId: command.testId}),
            command.filters
          )}
        >
          <ButtonComponent
            id={id}
            className={cx('control', {['controlType-' + command.type]: true})}
            square={square}
            {...classes}
            tooltip={config && config.tooltip}
          >
            {this.props.children}
          </ButtonComponent>
        </Link>
      );
    }

    return (
      <ButtonComponent
        id={id}
        className={cx('control', {['controlType-' + command.type]: true})}
        {...classes}
        square={square}
        onClick={this.handleClick}
        tooltip={config && config.tooltip}
      >
        {this.props.children}
      </ButtonComponent>
    );
  };

  reportAfterGandalf() {
    const {rightposition} = this.props.config;

    return (
      <ScreenSizeContext.Consumer>
        {({tablet}) =>
          rightposition ? (
            <span style={{position: 'absolute', right: 0}}>
              {this.renderButtonComponent(tablet)}
            </span>
          ) : (
            this.renderButtonComponent(tablet)
          )
        }
      </ScreenSizeContext.Consumer>
    );
  }

  render() {
    const {
      isArchiveReport,
      config: {shallpass},
      isVirtualUser
    } = this.props;

    if (isVirtualUser) return null;

    if (isArchiveReport) return null;

    if (shallpass) {
      if (isAccountGandalf(shallpass)) {
        return <Gandalf shallPass={shallpass}>{this.reportAfterGandalf()}</Gandalf>;
      } else {
        return <ReportGandalf shallPass={shallpass}>{this.reportAfterGandalf()}</ReportGandalf>;
      }
    }

    return this.reportAfterGandalf();
  }
}

Control.handlesLoading = true;

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      isArchiveReport: isArchiveActiveSelector(state, props),
      isVirtualUser: isVirtualUserSelector(state, props)
    }),
    {doInstruction}
  )
)(Control);
