import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import styles from './test.scss';
import LayoutComponent from './layoutComponent';
import cx from 'classnames';
import Spinner from 'components/spinner';
import {setFilterParams} from 'modules/filters';
import {addingTestSelector} from 'modules/tests/testSelectors';
import {testLayoutSelector} from 'modules/layouts/layoutsSelectors';
import {createSelector} from 'reselect';
import {reportSelector, reportIsTeaserSelector} from 'modules/reports/reportSelectors';
import {filterSelector} from 'modules/filters/filterSelectors';
import {selectArchive} from 'modules/archives';
import ReportRetestProgressStrip from 'modules/reports/components/reportRetestProgressStrip';
import {archiveSelector} from 'modules/archives/archiveSelectors';
import upgraderStyles from 'components/layoutComponents/upgrader/upgrader.scss';
import PastDueBanner from 'modules/accounts/components/pastDueBanner';
import {TeaserBanner} from 'components/teaser';

const testSelector = createSelector(
  testLayoutSelector,
  addingTestSelector,
  reportSelector,
  filterSelector,
  (state, props) => _.get(props, 'match.params.tab'),
  archiveSelector,
  reportIsTeaserSelector,
  (layout, adding, report, {filter}, tab, archive, reportIsTeaser) => {
    return {
      // test: test || {}, TODO already passing test, comment for now
      layout,
      tab,
      adding,
      filter,
      report,
      archive,
      reportIsTeaser
    };
  }
);

export class Test extends React.Component {
  static propTypes = {
    test: PropTypes.object.isRequired,
    tab: PropTypes.string
  };

  handleClickNavigateLiveWebsite = () => {
    this.props.selectArchive('live');
  };

  // shouldComponentUpdate({report, params, test, filter, layout, archive}) {
  //   // TODO: check if this is more of performance hit than a win.
  //   return (
  //     report !== this.props.report ||
  //     !_.isEqual(params, this.props.params) ||
  //     !_.isEqual(test, this.props.test) ||
  //     !_.isEqual(filter, this.props.filter) ||
  //     !_.isEqual(layout, this.props.layout) ||
  //     !_.isEqual(archive, this.props.archive)
  //   );
  // }

  renderLayout() {
    const {report, layout, results, match, filter} = this.props;
    if (!layout || !layout.length) {
      return <Spinner centered marginTop={100} />;
    }

    return _.map(layout, config => (
      <LayoutComponent
        {...this.props}
        params={match.params}
        filter={filter}
        key={config.componentId}
        config={config}
        results={results}
        report={report}
      />
    ));
  }

  renderArchiveBar() {
    const {archive} = this.props;
    if (archive.archiveId === 'live') return null;

    return (
      <div className={upgraderStyles.banner}>
        You are viewing an archive from{' '}
        {moment.unix(archive.startTime).format('MMMM Do YYYY, h:mm a')}.
        <span className={upgraderStyles.underline} onClick={this.handleClickNavigateLiveWebsite}>
          View latest report
        </span>
      </div>
    );
  }

  render() {
    const {test, className, report, reportIsTeaser} = this.props;

    if (!test) {
      return <Spinner centered marginTop={100} />;
    }

    const contentClasses = cx({
      [styles.content]: true
    });

    return (
      <div>
        {this.renderArchiveBar()}

        <ReportRetestProgressStrip />
        {reportIsTeaser && false && (
          <TeaserBanner>This is a teaser website. Some features have been limited.</TeaserBanner>
        )}
        <PastDueBanner />

        <div className={cx(styles.test, className)}>
          <div className={contentClasses}>{this.renderLayout()}</div>
        </div>
      </div>
    );
  }
}

export default connect(
  testSelector,
  {setFilterParams, selectArchive}
)(Test);
