import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {addForcedPage, updateForcedPage, deleteForcedPage, fetchForcedPages} from 'modules/reports';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';
import ForcedPagesList from 'modules/reports/components/reportSettings/forcedPagesList';
import SaveForcedPage from 'modules/reports/components/reportSettings/saveForcedPage';

class ReportSettingsForcedPagesPage extends Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route exact path={match.path} component={ForcedPagesList} />
        <Route path={`${match.path}/:forcedPageId`} component={SaveForcedPage} />
      </Switch>
    );
  }
}

export default connect(
  reportSettingsPageSelector,
  {fetchForcedPages, addForcedPage, updateForcedPage, deleteForcedPage}
)(ReportSettingsForcedPagesPage);
