exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jtkTIna0SbX8ogTTRpkWY{transition:background-color .5s ease;text-align:center;cursor:pointer}.jtkTIna0SbX8ogTTRpkWY:hover{background:rgba(255,255,255,.1)}.F_jAVPoVOUgjYXv7JVK38{width:250px;height:200px;margin:10px 0;display:inline-block;background:#bfbfbf}._2CoNvEH7c6c97olV6tQVl2{max-width:250px;display:inline-block}._1-6w61Bxw5vPUWCbIPQoXE{color:#fff;margin-right:10px}.lX05GJNTN7Rw9ty17BAhX{margin-top:70px}._1531dL8fUdLnA0gEAXVTu_{display:flex;background:#202020}.s9Ez0kEHYArUFjvepeCJY{cursor:pointer;color:#fff;display:flex;flex:1;align-items:center;justify-content:center;padding:0 10px;height:51px}", ""]);

// exports
exports.locals = {
	"page": "jtkTIna0SbX8ogTTRpkWY",
	"image": "F_jAVPoVOUgjYXv7JVK38",
	"caption": "_2CoNvEH7c6c97olV6tQVl2",
	"label": "_1-6w61Bxw5vPUWCbIPQoXE",
	"spinner": "lX05GJNTN7Rw9ty17BAhX",
	"navigation": "_1531dL8fUdLnA0gEAXVTu_",
	"navLabel": "s9Ez0kEHYArUFjvepeCJY"
};