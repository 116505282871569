import React from 'react';
import CheckboxesComponent, {CheckboxOption} from 'components/checkboxes';

export default class Checkboxes extends React.Component {
  handleChangeSeverity = severity => {
    const {setFilterParams, config} = this.props;

    setFilterParams({[config.filter]: severity});
  };

  render() {
    const {result, config, filter} = this.props;
    const filterName = config.filter;
    const filterValue = filter[filterName] || ''; // "" needed as unspecified filters in our query strings are removed

    return (
      <CheckboxesComponent
        value={filterValue}
        stacked={!!config.stacked}
        maxChecked={config.maxchecked ? parseInt(config.maxchecked, 10) : null}
        onChange={this.handleChangeSeverity}
      >
        {Object.keys(result).map(severityId => (
          <CheckboxOption key={'severity_' + severityId} value={severityId}>
            {result[severityId]}
          </CheckboxOption>
        ))}
      </CheckboxesComponent>
    );
  }
}
