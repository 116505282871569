import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import {paymentDetailsComponentSelector} from 'modules/payments/paymentSelectors';
import Spinner from 'components/spinner';
import styles from './paymentDetails.scss';
import UpdatePaymentDetailsButton from './updatePaymentDetailsButton';

const placeholder = '...';

function CardField({label, value}) {
  return (
    <div className={cx(styles.field, styles.name)}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
}

export class PaymentDetails extends React.Component {
  static defaultProps = {
    hideUpdateButton: false
  };

  renderDetails() {
    const {details} = this.props;

    return (
      <div className={styles.details}>
        <CardField label="Type" value={details.brand || placeholder} />
        <CardField label="Card number" value={this.renderCardNumber(details.last4)} />
        <CardField
          label="Expiry date"
          value={this.renderExpiry(details.expiryMonth, details.expiryYear)}
        />
      </div>
    );
  }

  renderCardNumber(last4) {
    return last4 ? '**** **** **** ' + last4 : placeholder;
  }

  renderExpiry(month, year) {
    // parse the date to see if it's expired
    if (!month || !year) {
      return placeholder;
    }
    const expiry = moment();
    expiry.year(year + 2000);
    expiry.month(month * 1);
    const className = expiry.unix() > moment().unix() ? 'in-date' : 'expired';

    return (
      <span className={styles[className]}>
        {month} / {year}
      </span>
    );
  }

  render() {
    const {loading, error, hasDetails, hideUpdateButton, details} = this.props;
    const {sitebeamPlanId} = details;

    if (sitebeamPlanId && sitebeamPlanId.length > 0 && !hasDetails) {
      return (
        <div className={styles.error}>
          As a Sitebeam customer who has migrated to Silktide, your Sitebeam subscription is still
          active but is now applied to Silktide. Please contact us if you wish to migrate your
          subscription and/or update any card information.
        </div>
      );
    }

    return (
      <div className={styles.paymentDetails}>
        {loading && (
          <div className={styles.fade}>
            <Spinner />
          </div>
        )}

        {!loading && !hasDetails && !sitebeamPlanId && (
          <div className={styles.error}>Please update your payment details.</div>
        )}

        {hasDetails && this.renderDetails()}

        {!hideUpdateButton && (
          <div className={styles.button}>
            <UpdatePaymentDetailsButton />
          </div>
        )}
      </div>
    );
  }
}

export default connect(paymentDetailsComponentSelector)(PaymentDetails);
