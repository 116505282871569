exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2qErBQizwbcFE700VJUPrc{background:#fff;color:#222;height:120px !important;box-shadow:inset 0 5px 5px rgba(0,0,0,.1);text-align:center}._2qErBQizwbcFE700VJUPrc:hover{background:#fff !important}._2qErBQizwbcFE700VJUPrc h3{font-weight:bold;font-size:18px;margin-top:0 !important}._2qErBQizwbcFE700VJUPrc p{margin:15px 0}._2qErBQizwbcFE700VJUPrc p:last-child{margin-bottom:0}._2qErBQizwbcFE700VJUPrc td{padding:16px}._2qErBQizwbcFE700VJUPrc a{font-size:16px}._2qErBQizwbcFE700VJUPrc span{color:#df1616;display:inline-block;font-size:20px;margin-right:10px}._209n5g_fhgQV5cDWdIGAFc{align-items:center;background:#533be2;color:#fff !important;cursor:pointer;display:flex;justify-content:center;padding:8px;transition:.2s ease-in-out}._209n5g_fhgQV5cDWdIGAFc span{margin-right:8px}._209n5g_fhgQV5cDWdIGAFc:hover{opacity:.9}._3TGStv2cV79CaoDs1HDeQI{background:#2f209e;border-radius:4px;color:#fff;display:flex;flex-direction:column;padding:8px;margin:15px;margin-top:auto;text-align:center}._3TGStv2cV79CaoDs1HDeQI button{background:#2f209e;color:#fff;border-color:#fff;font-weight:600;margin-top:16px}._3TGStv2cV79CaoDs1HDeQI button:hover{background:#2f209e;color:#fff}", ""]);

// exports
exports.locals = {
	"teaser": "_2qErBQizwbcFE700VJUPrc",
	"teaserBanner": "_209n5g_fhgQV5cDWdIGAFc",
	"teaserCount": "_3TGStv2cV79CaoDs1HDeQI"
};