export function notificationsEndpoint() {
  return 'notifications';
}

export function notificationsShownEndpoint() {
  return notificationsEndpoint(...arguments) + '/shown';
}

export function notificationEndpoint({notificationId}) {
  return notificationsEndpoint(...arguments) + '/' + notificationId;
}

export function notificationReadEndpoint() {
  return notificationEndpoint(...arguments) + '/read';
}
