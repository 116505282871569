exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1wgczbBDLUpB3-wmvskL67{margin-left:4px}._2MiZQ2qkQGRoTI3BEtrIFu{margin:0}", ""]);

// exports
exports.locals = {
	"linkIcon": "_1wgczbBDLUpB3-wmvskL67",
	"description": "_2MiZQ2qkQGRoTI3BEtrIFu"
};