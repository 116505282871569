import React from 'react';
import {connect} from 'react-redux';
import {receiveMission} from 'modules/missions';
import {missionChannel} from 'modules/pusher/channels';
import {inspectorHeaderSelector} from 'modules/inspector/inspectorSelectors';
import Pusher from 'modules/pusher/components/pusher';
import InspectorBar from 'modules/inspector/components/inspectorBar';
import InspectorTitle from 'modules/inspector/components/inspectorTitle';
import InspectorUrl from 'modules/inspector/containers/inspectorUrl';
import InspectorExitButton from 'modules/inspector/containers/inspectorExitButton';
import InspectorContext from 'modules/inspector/containers/inspectorContext';

class InspectorHeader extends React.Component {
  render() {
    const {params} = this.props;
    const {reportId} = params;

    return (
      <div>
        <Pusher
          event="missionChange"
          channel={missionChannel({reportId})}
          onUpdate={this.props.receiveMission}
        />

        <InspectorBar>
          <InspectorExitButton />
          <InspectorTitle>Inspector</InspectorTitle>
          <InspectorUrl />
          <InspectorContext />
        </InspectorBar>
      </div>
    );
  }
}

export default connect(
  inspectorHeaderSelector,
  {receiveMission}
)(InspectorHeader);
