exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1kaIiwzEke1hyMyHaYdZHL span{margin:0 0 0 5px}", ""]);

// exports
exports.locals = {
	"button": "_1kaIiwzEke1hyMyHaYdZHL"
};