import React from 'react';
import AuthWrapper from 'modules/auth/components/authWrapper';
import TwoFactorPage from 'modules/auth/pages/twoFactorPage';

export default class LoginTwoFactorRoute extends React.Component {
  render() {
    const {
      location: {pathname}
    } = this.props;

    return (
      <AuthWrapper pathname={pathname}>
        <TwoFactorPage />
      </AuthWrapper>
    );
  }
}
