import React from 'react';
import cx from 'classnames';
import styles from './pageWithFixed.scss';
import filterInvalidDomProps from 'filter-invalid-dom-props';

export default function PageWithFixed({
  children,
  orientation,
  fixedElement,
  fixedSize,
  className,
  pageClass,
  reverse,
  noOverflow,
  onClickFixed,
  resetScroll,
  ...props
}) {
  const scrollRef = React.useRef(null);
  const classes = cx(styles.container, styles[orientation], className, {
    [styles.reverse]: reverse
  });

  React.useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [scrollRef, resetScroll]);

  return (
    <div {...filterInvalidDomProps(props)} className={classes}>
      {fixedElement && (
        <div className={fixedSize} onClick={onClickFixed}>
          {fixedElement}
        </div>
      )}
      <div
        className={cx(styles.page, {[styles.noOverflow]: noOverflow}, pageClass)}
        ref={scrollRef}
      >
        {children}
      </div>
    </div>
  );
}
