import _ from 'lodash';
import Table from 'components/table';
import ExplorerGrid from './explorerGrid';
import React from 'react';
import Status from 'components/status';
import {testPath} from 'modules/tests/testUtils';
import {openWindow} from 'utils/navigationUtils';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import styles from './explorerData.scss';
import {hideModal} from 'modules/modal';
import {shallPassAny} from 'modules/auth/gandalf';
import {actionPath} from 'modules/actions/actionUtils';
import {setActionCloseParams} from 'modules/actions/actions';
import {isPrintMode, evalFilterCondition} from 'modules/filters/filterUtils';
import {tabletSize} from 'constants/sizes';
import {doInstruction} from 'modules/instructions';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';
import Classification from 'modules/actions/components/classification';
import RowHeader from 'components/table/rowHeader';
import {userSelector} from 'modules/auth/authSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';

function getMetricName(config) {
  return config.metric;
}

export class ExplorerData extends React.Component {
  state = {
    isMobile: false
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const isMobile = window.innerWidth < tabletSize;

    if (isMobile !== this.state.isMobile) {
      this.setState({isMobile});
    }
  };

  handleClick = (row, column) => {
    // ignore click events if on archive
    if (this.props.isArchiveReport) return;

    const {params, config, filter} = this.props;
    const {linkType, linkSource, instruction} = config;
    const {pageHash, actionId} = row;
    let {testId} = params;

    if (row.testId) testId = row.testId;

    if (instruction && _.isPlainObject(instruction)) {
      // send row props and config props as the `context` value

      if (instruction.type && instruction.type.value === 'action') {
        this.props.setActionCloseParams(window.location.search);
      }

      this.props.doInstruction(instruction, {testId, actionId, ...row, ...config}, filter);
      return;
    }

    if (column && column.setFilters) {
      return this.handleFilter(row, column);
    }

    if (linkType === 'url') {
      const url = row[linkSource];
      openWindow(url);
    }

    if (linkType === 'test') {
      const testId = row[linkSource];
      this.props.push(testPath, {testId}, ['reportId']);
      this.props.hideModal();
    }

    if (linkType === 'action') {
      this.props.hideModal();
      this.props.setActionCloseParams(window.location.search);
      this.props.push(actionPath, {actionId}, ['reportId', 'testId']);
    }
  };

  handleFilter(row, column) {
    const {setFilterParams} = this.props;
    const {setFilters, setTab} = column;

    const populatedFilters = _.mapValues(setFilters, (value, filter) => {
      return _.isArray(value) ? row[value[0]] : value;
    });

    if (populatedFilters) {
      setFilterParams(populatedFilters, setTab);
    }
  }

  getRecords() {
    const {result} = this.props;

    if (!result) return [];

    let records = result;

    // In the *hopefully* not too distant future we will start using this format... 21/06/17
    if (result.records) records = result.records;

    // Detect new-style table format, convert to old format
    if (result.rows) records = result.rows;

    return records.map(this.normalizeRecord);
  }

  normalizeRecord = record => {
    const {
      config: {resultId, key, linkType}
    } = this.props;

    return {
      ...record,
      resultId,
      key,
      clickable: record.clickable || !!linkType
    };
  };

  // Make fields a format Table/Grid understands.
  normalizeField = field => {
    const {data} = field;
    const newField = {
      ...field,
      metricName: getMetricName(this.props.config), // It is handy to know what metric we are currently exploring from within a table row.
      clickable: 'clickable' in field || !_.isUndefined(field.setTab || field.setFilters),
      hideOnPrint: field.type === 'controls'
    };

    // `data` can be undefined and `field.field` can be set.
    // we only want to overwrite `field` if data is not undefined
    if (data) {
      newField.field = data;
    }
    return newField;
  };

  getFields() {
    const {config, filter} = this.props;

    // In the *hopefully* not too distant future the backend will pass fields instead of columns.
    // For now, replicate the new format... 21/06/17
    if (!config.fields) config.fields = config.columns;

    const fields = config.fields.map(this.normalizeField);

    if (!filter) {
      return fields;
    }

    const printMode = isPrintMode(filter);

    return fields.filter(field => {
      if (printMode && field.type === 'issueActions') {
        return false;
      }
      return evalFilterCondition(filter, field.matchFilter);
    });
  }

  // filters fields based on screen size
  filterFields(fields) {
    // add the class `issueActionColumn` to each `issueAction` column
    // TODO probably better to add a `column_{name}` class to ALL columns,
    //      if `styles[name]` exists.

    // fields = fields.map(f => {
    //   if (!f.sortable) return f;
    //   return {...f, sortable: true};
    // });

    fields = fields.map(f => {
      if (f.type === 'issueActions') {
        f.className = styles.issueActionColumn;
      }
      return f;
    });

    if (this.props.isArchiveReport) {
      fields = fields.filter(
        field =>
          field.type !== 'issueActions' && field.type !== 'controls' && field.type !== 'priority'
      );
    }

    return fields;
  }

  renderEmpty() {
    const {currentView} = this.props;
    const {emptyLabel, emptyAppearance} = currentView;

    if (_.isUndefined(emptyLabel)) return null;

    return <Status appearance={emptyAppearance}>{emptyLabel}</Status>;
  }

  renderGroup = (classification, maxCols) => {
    return classification ? (
      <RowHeader maxCols={maxCols} className={styles.classification}>
        <Classification {...classification} />
      </RowHeader>
    ) : null;
  };

  render() {
    const {config, report, filter, user, isTeaser} = this.props;
    const {layout, carousel, carouselHeight, rowKey, key, highlight} = config;

    const records = this.getRecords();
    const fields = this.getFields();
    const ignoreTeaser = this.props.result.ignoreTeaser || false;
    const classifications = this.props.result.classifications;

    const hasInstruction = config && !!config.instruction;
    const shallPassInstruction =
      hasInstruction &&
      (!config.actionshallpass || shallPassAny(config.actionshallpass, {user, report}));

    const layoutProps = {
      rowKey,
      highlight,
      configKey: key,
      carousel,
      carouselHeight,
      hideHeader: config && config.hideheader,
      onClick: shallPassInstruction ? this.handleClick : null,
      clickable: shallPassInstruction,
      sortfilter: config.sortfilter
    };

    if (!config || !records.length) return this.renderEmpty();

    const cols = this.filterFields(fields);

    switch (layout) {
      case 'grid':
        return (
          <ExplorerGrid
            {...layoutProps}
            items={records}
            fields={fields}
            itemParams={report}
            isTeaser
          />
        );

      default:
        return (
          // <Panel hideBorderTop padding={false}>
          <Table
            {...layoutProps}
            filter={filter}
            rows={records}
            classifications={classifications}
            renderGroup={this.renderGroup}
            columns={cols}
            cellParams={{report}}
            currentSort={filter.sort}
            onClickColumn={cell => {
              if (cell.sortable) {
                const current = filter[config.sortfilter];
                const [field, order] = current.split(':', 2);
                let nextOrder;

                if (cell.sortable !== field) nextOrder = 'a';
                else if (order === 'a') nextOrder = 'd';
                else if (order === 'd') nextOrder = '';
                else if (!order) nextOrder = 'a';

                this.props.setFilterParams({
                  [config.sortfilter]: nextOrder
                    ? `${cell.sortable}:${nextOrder}`
                    : `${cell.sortable}`
                });
              }
            }}
            isTeaser={isTeaser && !ignoreTeaser}
          />
          // </Panel>
        );
    }
  }
}

export default connect(
  (state, props) => ({
    isArchiveReport: isArchiveActiveSelector(state, props),
    user: userSelector(state, props),
    report: reportSelector(state, props)
  }),
  {push, hideModal, doInstruction, setActionCloseParams}
)(ExplorerData);
