exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._31Hpsm-x12OzRN16KBRDyG tr{height:50px !important}.uDCtiyP1yNhIi-EWrXoe{display:flex;align-items:center}._1RlFySgIfxgQ5ht1tFRM2r{margin-right:16px}", ""]);

// exports
exports.locals = {
	"leaderboardTable": "_31Hpsm-x12OzRN16KBRDyG",
	"headerRight": "uDCtiyP1yNhIi-EWrXoe",
	"exportButton": "_1RlFySgIfxgQ5ht1tFRM2r"
};