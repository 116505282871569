import React from 'react';
import Panel from 'components/panel';
import Flex from 'components/layout/flex';

export default function Header({children}) {
  return (
    <Panel toolbar hideBorderBottom>
      <Flex container>{children}</Flex>
    </Panel>
  );
}
