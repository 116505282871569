import {fromJS} from 'immutable';
import {
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARDS,
  FETCH_LEADERBOARD_RESULTS,
  FETCH_LEADERBOARD_COMPLETE,
  FETCH_LEADERBOARDS_COMPLETE,
  FETCH_LEADERBOARD_REPORTS_COMPLETE,
  RECEIVE_LEADERBOARD,
  RECEIVE_LEADERBOARDS,
  RECEIVE_LEADERBOARD_RESULTS,
  REQUEST_CREATE_LEADERBOARD,
  REQUEST_UPDATE_LEADERBOARD,
  REQUEST_DELETE_LEADERBOARD,
  DELETE_LEADERBOARD,
  RECEIVE_DEFAULT_COLUMNS,
  EXPORT_LEADERBOARD,
  LEADERBOARD_EXPORTED
} from './constants';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  fetching: {
    leaderboard: false,
    leaderboards: false,
    leaderboardResults: false,
    leaderboardExport: false
  },
  leaderboards: {},
  defaultColumns: {},
  resultsFor: null,
  maxReports: null,
  results: {
    columns: [],
    reports: []
  }
});

export default function leaderboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD:
      return state.setIn(['fetching', 'leaderboard'], true);
    case FETCH_LEADERBOARDS:
      return state.setIn(['fetching', 'leaderboards'], true);
    case FETCH_LEADERBOARD_RESULTS:
      return state.setIn(['fetching', 'leaderboardResults'], true);

    case FETCH_LEADERBOARD_COMPLETE:
      return state.setIn(['fetching', 'leaderboard'], false);
    case FETCH_LEADERBOARDS_COMPLETE:
      return state.setIn(['fetching', 'leaderboards'], false);
    case FETCH_LEADERBOARD_REPORTS_COMPLETE:
      return state.setIn(['fetching', 'leaderboardResults'], false);

    case RECEIVE_LEADERBOARD:
      return state.setIn(
        ['leaderboards', action.leaderboard.leaderboardId.toString()],
        fromJS(action.leaderboard)
      );

    case RECEIVE_LEADERBOARDS:
      if (!action.append) {
        return state.set('leaderboards', keyBy(action.leaderboards, 'leaderboardId'));
      }

      return state.update('leaderboards', leaderboards => {
        action.leaderboards.forEach(leaderboard => {
          leaderboards = leaderboards.set(leaderboard.leaderboardId.toString, fromJS(leaderboard));
        });
        return leaderboards;
      });

    case RECEIVE_LEADERBOARD_RESULTS:
      return state
        .set('maxReports', action.maxReports)
        .set('resultsFor', action.leaderboardId)
        .set('results', fromJS(action.results));

    case DELETE_LEADERBOARD:
      return state.deleteIn(['leaderboards', action.leaderboardId.toString()]);

    case RECEIVE_DEFAULT_COLUMNS:
      return state.set('defaultColumns', fromJS(action.defaultColumns));

    case EXPORT_LEADERBOARD:
      return state.setIn(['fetching', 'leaderboardExport'], true);
    case LEADERBOARD_EXPORTED:
      return state.setIn(['fetching', 'leaderboardExport'], false);
  }
  return state;
}
