import PropTypes from 'prop-types';
import React from 'react';
import Pills from './pills';
import {formatGivenNumber} from 'utils/numberUtils';

class KeyValuePills extends React.Component {
  renderKeyValuePillsData() {
    const {data} = this.props;

    return data.map(stat => {
      const {label, value, ...statProps} = stat;

      statProps.key = label;
      statProps.label = (
        <span>
          {label}: <strong>{formatGivenNumber(value)}</strong>
        </span>
      );
      return statProps;
    });
  }

  render() {
    const data = this.renderKeyValuePillsData();
    return <Pills data={data} />;
  }
}

KeyValuePills.propTypes = {};

export default KeyValuePills;
