import _ from 'lodash';
import React from 'react';
import Chart from 'components/chart';
import {GraphWrapper} from '../shared/graphs';
import {highchartsConfig} from 'utils/graphUtils';
import {magicallyInterpolate} from 'utils/interpolationUtils';

// TODO: this is very similar to BarChart
// the two could probably be unified
export default class PieChart extends React.Component {
  getResult() {
    return this.props.result; // _.sortBy(this.props.result, 'value');
  }

  getChartConfig(result) {
    const {
      config: {label, height, ...config}
    } = this.props;

    return highchartsConfig({
      chart: {
        type: 'pie',
        height: typeof height === 'number' ? height : undefined
      },
      tooltip: {
        pointFormatter() {
          // The tooltip needs to show interpolated data.
          const matchingResultId = Object.keys(result).find(
            name => result[name].label === this.name
          );
          return magicallyInterpolate({...config, props: result[matchingResultId]}, false);
        }
      },
      title: {text: label},
      plotOptions: {
        pie: {
          innerSize: config.innerSize ? config.innerSize + '%' : 0,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: this.getSeries(result)
    });
  }

  getSeries(result) {
    const data = _.map(result, item => {
      return {
        name: item.label,
        y: item.value,
        color: item.color
      };
    });

    return [
      {
        data
      }
    ];
  }

  render() {
    const result = this.getResult();
    if (!result) return null;

    return (
      <GraphWrapper>
        <Chart config={this.getChartConfig(result)} />
      </GraphWrapper>
    );
  }
}
