import Alert from 'components/alert/alert';
import Button from 'components/button/button';
import {sendContactUsEmail} from 'modules/app/app';
import {triggerEvent} from 'modules/app/appUtils';
import {Field, Form} from 'modules/form/components';
import ModalContent from 'modules/modal/components/modalContent';
import React from 'react';
import {connect} from 'react-redux';

export class ContactUsModal extends React.Component {
  state = {sent: false, error: false, showSpinner: false};

  handleSubmit = (formData, isValid) => {
    const {contactType} = this.props;

    this.setState({showSpinner: true, error: false, sent: false});

    if (isValid) {
      this.props.sendContactUsEmail(
        contactType,
        formData,
        () => {
          triggerEvent('price-page-enquiry', {
            pagePath: window.location.pathname
          });
          this.setState({sent: true, error: false});
        },
        () => {
          this.setState({sent: false, error: true});
        }
      );
    }
  };

  render() {
    const {title} = this.props;
    const {error, showSpinner, sent} = this.state;

    if (sent) {
      return (
        <ModalContent title={'Details submitted'}>
          <Alert level={'success'} icon={'fas fa-check'}>
            Your details have been submitted. We will be in touch shortly.
          </Alert>
        </ModalContent>
      );
    }

    return (
      <ModalContent title={title || 'Contact us'}>
        {error && (
          <Alert level={'danger'} icon={'fas fa-times'}>
            Their was an error submitting your details, please try again shortly.
          </Alert>
        )}
        {!error && (
          <Alert level={'info'} icon={'fas fa-comment'}>
            Leave your details and we will get back to you shortly.
          </Alert>
        )}
        <Form autoComplete="off" ref={input => (this.form = input)} onSubmit={this.handleSubmit}>
          <Field
            autoFocus={true}
            label="Your name"
            minLength={3}
            placeholder="John Smith"
            name="name"
            type="text"
          />
          <Field
            label="Work email"
            minLength={3}
            placeholder="johnsmith@example.com"
            name="email"
            type="email"
          />
          <Field label="Work phone" placeholder="(123) 456-7890" name="phoneNumber" type="phone" />
          <Field
            label="Your message"
            minLength={5}
            placeholder="Tell us more about what you're looking for"
            rows={6}
            name="comments"
            type="textarea"
          />

          <Button isLoading={showSpinner} disabled={showSpinner} onClick={() => this.form.submit()}>
            Send
          </Button>
        </Form>
      </ModalContent>
    );
  }
}

export default connect(
  null,
  {sendContactUsEmail}
)(ContactUsModal);
