import {queryString} from 'utils/urlUtils';

export function purchaseEndpoint() {
  return 'plans/buy';
}

export function plansEndpoint(countryCode, vatNumber) {
  return `plans?${queryString({countryCode, vatNumber})}`;
}

export function monthlyPriceDivisor(product) {
  const interval = product.get('interval');

  switch (interval) {
    case 'month':
      return 1;
    case 'year':
      return 12;
  }

  throw 'unknown interval' + interval;
}

export function getProductDescription(productName) {
  if (productName.indexOf('Monitor') !== -1) {
    return 'Monitor your web properties for spelling, accessibility and more.';
  }

  if (productName.indexOf('Builder') !== -1) {
    return 'Quality check your websites to ensure high quality output.';
  }

  if (productName.indexOf('Snapshot') !== -1) {
    return 'One-off reports to get an overview of website performance.';
  }

  if (productName.indexOf('Insites') !== -1) {
    return 'Quick reports, designed to help you sell websites, SEO and more.';
  }

  return 'Understand & improve your websites.';
}

export function getProductNameFromSkus(skus) {
  if (
    skus.indexOf('MasterMonitor') !== -1 ||
    skus.indexOf('MasterMonitor2') !== -1 ||
    skus.indexOf('LegacyMonitor') !== -1
  ) {
    return 'Monitor';
  }

  if (skus.indexOf('MasterBuilder') !== -1 || skus.indexOf('LegacyBuilder') !== -1) {
    return 'Builder';
  }

  if (skus.indexOf('MasterSnapshot') !== -1 || skus.indexOf('LegacySnapshot') !== -1) {
    return 'Snapshot';
  }

  return null;
}
