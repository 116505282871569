import {Map, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';
import {ROUTE_REPLACE} from 'modules/location/location';

import {
  ATTEMPT_CONFIRM_CHECKOUT,
  RECEIVE_ATTEMPT_CONFIRM_CHECKOUT_RESPONSE,
  HANDLE_BAD_CHECKOUT_ERROR
} from 'modules/confirmCheckout/confirmCheckoutActions';

const initialState = Map({
  data: Map(),
  isLoading: false,
  loadingError: null
});

export default function confirmCheckoutReducer(state = initialState, action) {
  switch (action.type) {
    case ATTEMPT_CONFIRM_CHECKOUT: 
      return setLoadingState(state, true, null).set('data', Map());
    case RECEIVE_ATTEMPT_CONFIRM_CHECKOUT_RESPONSE:
      return setLoadingState(state, false, null).set('data', fromJS(action.response));
    case HANDLE_BAD_CHECKOUT_ERROR:
      return setLoadingState(state, false, action.error).set('data', Map());
    case SWITCH_ACCOUNT:
    case ROUTE_REPLACE:
      return initialState;
  }

  return state;
}

function setLoadingState(state, loading, error) {
  return state.set('isLoading', loading).set('loadingError', error);
}