import React from 'react';

export default class Idle extends React.Component {
  static defaultProps = {
    defaultIdle: false,
    onChange: () => {},
    timeout: 1000
  };

  state = {
    idle: this.props.defaultIdle
  };

  timeout = null;

  componentDidMount() {
    this.attachEvents();
    this.setTimeout();
  }

  componentWillUnmount() {
    this.removeEvents();
    clearTimeout(this.timeout);
  }

  attachEvents() {
    window.addEventListener('mousemove', this.handleEvent);
    window.addEventListener('keypress', this.handleEvent);
  }

  removeEvents() {
    window.removeEventListener('mousemove', this.handleEvent);
    window.removeEventListener('keypress', this.handleEvent);
  }

  handleChange(idle) {
    this.props.onChange(idle);
    this.setState({idle});
  }

  handleEvent = () => {
    if (this.state.idle) {
      this.handleChange(false);
    }
    clearTimeout(this.timeout);
    this.setTimeout();
  };

  setTimeout() {
    this.timeout = setTimeout(() => {
      this.handleChange(true);
    }, this.props.timeout);
  }

  render() {
    return null;
  }
}
