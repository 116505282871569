import React from 'react';
import {connect} from 'react-redux';
import Affix from 'components/overlay/affix';
import {DropdownBody} from 'components/dropdown';
import {isDropdownActiveSelector} from 'modules/instructions/instructionSelectors';
import {toggleFlagState} from 'modules/instructions';
import styles from './portal.scss';
import cx from 'classnames';

export class Portal extends React.Component {
  componentDidMount() {
    // On first render, `geElementById(id)` fails.
    // If we force an update again, it will exist.
    this.forceUpdate();
  }

  render() {
    const {children, config, isActive} = this.props;
    const {id, smaller} = config;

    const elem = document.getElementById(id);

    return (
      <Affix
        to={elem}
        preferSide="bottom"
        show={!!elem && isActive}
        onClickOutside={() => {
          this.props.toggleFlagState(id, false);
        }}
      >
        <DropdownBody
          onClick={() => {
            if (config['close-on-click']) this.props.toggleFlagState(id, false);
          }}
          className={cx(styles.portalDropdown, {
            [styles.maxHeight]: smaller,
            [styles.noPadding]: config['no-padding']
          })}
        >
          {children}
        </DropdownBody>
      </Affix>
    );
  }
}

export default connect(
  isDropdownActiveSelector,
  {toggleFlagState}
)(Portal);
