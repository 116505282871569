import React from 'react';
import SearchPageWrapper from '../components/wrapper';
import WithBreadcrumbHeader from 'modules/app/components/withBreadcrumbHeader';
import {connectContext} from 'modules/app/appUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import SearchReports from 'modules/homePage/components/searchReports';

export class SearchPage extends React.Component {
  render() {
    const {largeMobile} = this.props.screenSizes;
    const {push, match, location} = this.props;

    const searchPage = (
      <SearchPageWrapper>
        <SearchReports />
      </SearchPageWrapper>
    );

    return largeMobile ? (
      searchPage
    ) : (
      <WithBreadcrumbHeader push={push} location={location} match={match}>
        {searchPage}
      </WithBreadcrumbHeader>
    );
  }
}

export default connectContext(ScreenSizeContext, 'screenSizes')(SearchPage);
