import React from 'react';
import Panel from 'components/panel';
import AlertButton from 'modules/alertSubscriptions/containers/alertButton';
import styles from './alertSubscriptions.scss';

export default function AlertSubscriptions({channelId, segment, storyTypes}) {
  return (
    <Panel>
      <p className={styles.segmentTitlePrefix}>Alerts for {segment.label}</p>
      {storyTypes.keySeq().map(storyTypeId => (
        <AlertSubscription
          key={storyTypeId}
          segmentId={segment.value}
          storyTypeId={storyTypeId}
          label={storyTypes.get(storyTypeId)}
          channelId={channelId}
        />
      ))}
    </Panel>
  );
}

function AlertSubscription({
  channelId,
  channelTypeId,
  created,
  createdUserId,
  isSubscribed,
  reportId,
  segmentId,
  storyTypeId,
  subscriptionId,
  label
}) {
  return (
    <div className={styles.alertSubscription}>
      <span>{label}</span>
      <AlertButton userId={channelId} segmentId={segmentId} storyTypeIds={[storyTypeId]} />
    </div>
  );
}
