import React from 'react';
import {connect} from 'react-redux';
import {accountBrandingLogoUploaderSelector} from 'modules/accountOptions/accountOptionSelectors';
import styles from './logo.scss';

export class Logo extends React.Component {
  render() {
    const {brandingLogoUrl} = this.props;

    if (!brandingLogoUrl) {
      return null;
    }

    return (
      <div className={styles.logo}>
        <img src={brandingLogoUrl} />
      </div>
    );
  }
}

export default connect(accountBrandingLogoUploaderSelector)(Logo);
