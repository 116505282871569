import React from 'react';
import cx from 'classnames';
import {parse} from 'query-string';
import {Link as RouterLink} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {addQueryString} from 'utils/urlUtils';
import {splitInvalidDOMProps} from 'utils/propUtils';
import styles from './linkStyles.scss';

export default function Link({
  children,
  builder, // for route builders, expects the function signature ({...params, ...query}) => `path?query`
  replace, // should be true to replace the last history item
  setQuery: passedSetQuery, // should only be set if we want to clear the existing query
  query: passedQuery, // will override the existing query
  className,
  ...props // should be DOM props added to the Link OR route `params` like `accountId` etc
}) {
  const {
    location: {pathname, search},
    match: {params: existingParams}
  } = useReactRouter();

  const [domProps, passedParams] = splitInvalidDOMProps(props);
  const params = {...existingParams, ...passedParams};
  const query = passedSetQuery ? passedSetQuery : {...parse(search), ...passedQuery};

  // Passing `query` is a little dodgy but some of the builder functions accept
  // query params and manage their own query strings so we need this for BC
  const href = typeof builder == 'function' ? builder({...params, ...query}) : pathname;

  // addQueryString handles `href` if it already has a query string
  const fullHref = addQueryString(href, query);

  return (
    <RouterLink
      className={cx(styles.break, className)}
      to={fullHref}
      replace={replace}
      {...domProps}
    >
      {children}
    </RouterLink>
  );
}
