exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1WKbQF1bSWTnlM6Fvuqkh6{margin-left:10px}p{margin:10px 0}._2RkdeswSjB0oOCH6_DbfC9{color:#df1616;border-bottom:1px dashed #df1616}._20_G6TURuNtmocNYB9SdcK{color:#15b272;border-bottom:1px dashed #15b272}", ""]);

// exports
exports.locals = {
	"buttonWrapper": "_1WKbQF1bSWTnlM6Fvuqkh6",
	"typo": "_2RkdeswSjB0oOCH6_DbfC9",
	"correction": "_20_G6TURuNtmocNYB9SdcK"
};