import {take, call, put, cancel, fork, takeEvery} from 'redux-saga/effects';
import {REQUEST_DELETE_USER, deleteUser} from 'modules/users';
import {addMessage} from 'modules/alerts';
import confirm from 'utils/saga/confirm';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';
import {userEndpoint} from 'modules/users/userUtils';

export default function* deleteEveryUserSaga() {
  yield takeEvery(REQUEST_DELETE_USER, deleteUserSaga);
}

function* deleteUserSaga({user}) {
  const {name, email, userId} = user;

  const confirmed = yield* confirm({
    title: 'Permanently delete this user?',
    content: `Are you sure you want to delete the user "${name}" (${email})`,
    submitLabel: `Yes, delete "${name}"`
  });

  if (!confirmed) return;

  yield processRequest('DELETE', userEndpoint({userId}), {
    success: function*() {
      yield put(deleteUser(userId));
    },

    errorMessage: couldNot('delete user')
  });
}
