import PropTypes from 'prop-types';
import React from 'react';
import {interpolateToJSX} from 'utils/interpolationUtils';

class Interpolation extends React.Component {
  render() {
    const {
      data,
      options: {values},
      record
    } = this.props;
    if (!data || !data.length || (!values || !values.length) || !record[values]) {
      return null;
    }

    return interpolateToJSX(data, record[values]);
  }
}

Interpolation.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object,
  record: PropTypes.object
};

export default Interpolation;
