import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {CSSTransitionGroup} from 'react-transition-group';
import {propertySearcher} from 'modules/filters/filterUtils';
import InviteUsersButton from 'modules/users/components/inviteUsersButton';
import {requestDeleteUser, requestEditUser, requestUnlockUser} from 'modules/users';
import {SUPER_USER, ACCOUNT_OWNER, SITE_CREATOR, REGULAR_USER} from 'modules/auth/gandalf';
import Table from 'components/table';
import Helmet from 'components/helmet';
import PageBody from 'components/page';
import Button from 'components/button';
import {fetchUsers} from 'modules/users';
import SearchBar from 'components/searchBar';
import UserImage from 'components/user/userImage';
import DeleteIcon from 'components/icons/deleteIcon';
import PageHeader from 'components/header/pageHeader';
import TableWarning from 'components/table/tableWarning';
import {usersPageSelector} from '../settingsSelectors';
import cx from 'classnames';
import styles from './usersPage.scss';
import {parseQuery} from 'utils/urlUtils';
import Panel from 'components/panel/panel';

export class UsersPage extends React.Component {
  static propTypes = {
    users: PropTypes.array
  };

  state = {
    filterText: '',
    currentSort: undefined
  };

  componentWillMount() {
    this.props.fetchUsers();
  }

  componentDidMount() {
    const query = parseQuery(this.props.location.search);

    if (query.search) this.setState({filterText: query.search});
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.filterText !== this.state.filterText) {
      return true;
    }
    if (nextState.currentSort !== this.state.currentSort) {
      return true;
    }

    // NOTE this check doesn't do a deep equlity check.
    // TODO make this immutable so the deep equality can be checked properly
    if (nextProps.users !== this.props.users) {
      return true;
    }

    return false;
  }

  getCols() {
    var cols = [
      {
        label: '',
        field: 'user',
        type: 'jsx',
        jsx: this.renderUserImage,
        width: '60px',
        collapse: '400'
      },
      {label: 'Name', field: 'user', type: 'jsx', jsx: this.renderUserName, sortable: 'name'},
      {
        label: 'Created',
        field: 'created',
        type: 'string',
        collapse: '500',
        combineWith: 'Name',
        sortable: 'created'
      },
      {
        label: 'Last active',
        field: 'lastActiveString',
        type: 'string',
        sortable: 'lastActive'
      },
      {
        label: 'Role',
        field: 'perms',
        type: 'pills',
        width: '115px',
        collapse: '600',
        combineWith: 'Last active',
        sortable: 'permSort'
      },
      // `isOptionsColumn` is used in this file to disable row clicks on this column
      {
        label: '',
        field: 'user',
        type: 'jsx',
        jsx: this.renderRowIcons,
        isOptionsColumn: true,
        className: styles.optionsColumn,
        width: '35px'
      }
    ];

    const hasLockedUsers = this.props.users.reduce((carry, user) => {
      if (carry) return true;
      return user.failedLoginAttempts >= 10;
    }, false);

    if (
      hasLockedUsers &&
      (this.props.loggedInUser.role == 'super' || this.props.loggedInUser.role == 'owner')
    ) {
      cols.splice(-1, 0, {
        label: '',
        field: 'user',
        type: 'jsx',
        jsx: this.renderUnlockBtn
      });
    }

    return cols;
  }

  getRows() {
    const {users, loggedInUser} = this.props;
    let filteredUsers = getFilteredUsers(users, this.state.filterText);

    // filter super users if we are not a super user
    if (loggedInUser.role != 'super') {
      filteredUsers = filteredUsers.filter(user => user.role != 'super');
    }

    // find the current user in the filtered list
    const selfIndex = filteredUsers.reduce((resultIndex, user, index) => {
      if (resultIndex >= 0) return resultIndex;
      return user.userId === loggedInUser.userId ? index : -1;
    }, -1);

    // if we exist in the filtered list, put ourself at the top of the list
    if (selfIndex >= 0) {
      filteredUsers.splice(selfIndex, 1);
      filteredUsers.unshift(loggedInUser);
    }

    return filteredUsers.map(user => {
      // 'X' denotes a unix timestamp
      var created = moment(user.created, 'X').fromNow();
      var lastActive =
        !user.lastActive || user.lastActive === '0'
          ? 'Never'
          : moment(user.lastActive, 'X').fromNow();
      var perms = [this.getRolePill(user.role)];

      return {
        user,
        perms,
        created,
        lastActive: user.lastActive,
        lastActiveString: lastActive,
        rowKey: 'userid_' + user.userId,

        // these are only used for sorting
        permSort: perms[0].label,
        name: user.name
      };
    });
  }

  getRolePill(role) {
    switch (role) {
      case SUPER_USER:
        return {label: 'Super User', appearance: 'bad'};
      case ACCOUNT_OWNER:
        return {label: 'Owner', appearance: 'major'};
      case SITE_CREATOR:
        return {label: 'Creator', appearance: 'good'};
      case REGULAR_USER:
        return {label: 'User', appearance: 'minor'};

      default:
        return {label: ''};
    }
  }

  handleClickRow = ({user}, column, event) => {
    const {requestEditUser, loggedInUser} = this.props;

    // ignore the row click if the column was the options column
    if (column.isOptionsColumn) return;

    requestEditUser(user, loggedInUser.userId == user.userId);
  };

  handleDeleteUser = user => event => {
    this.props.requestDeleteUser(user);
  };

  handleUnlockUser = userId => event => {
    event.stopPropagation();
    this.props.requestUnlockUser(userId);
  };

  renderUserName = ({data: {name, email}}) => {
    return (
      <div>
        <div>{name}</div>
        {email && (
          <a style={{wordBreak: 'break-word'}} href={'mailto:' + email}>
            {email}
          </a>
        )}
      </div>
    );
  };

  renderUserImage = ({data: user}) => {
    if (!user) return null;

    return <UserImage user={user} size={40} />;
  };

  renderRowIcons = ({data: user}) => {
    const {loggedInUser} = this.props;
    const isEditingSelf = loggedInUser.userId == user.userId;

    if (!user || isEditingSelf) {
      return null;
    }

    return <DeleteIcon onClick={this.handleDeleteUser(user)} />;
  };

  renderUnlockBtn = ({data: user}) => {
    const {loggedInUser} = this.props;
    const isEditingSelf = loggedInUser.userId == user.userId;

    if (!user || isEditingSelf || user.failedLoginAttempts < 10) {
      return null;
    }

    return (
      <Button destructive onClick={this.handleUnlockUser(user.userId)}>
        Unlock
      </Button>
    );
  };

  renderUsersTable() {
    var rows = this.getRows();
    const cols = this.getCols();

    if (!rows || !rows.length) {
      return <TableWarning message="No users found." />;
    }

    if (this.state.currentSort) {
      var [sortName, order] = this.state.currentSort.split(':');
      var asc = order === 'a';

      rows = rows.sort((a, b) => {
        if (a[sortName] == b[sortName]) return 0;
        return (asc ? a[sortName] >= b[sortName] : a[sortName] < b[sortName]) ? 1 : -1;
      });
    }

    return (
      <Panel padding={false}>
        <Table
          currentSort={this.state.currentSort}
          hover
          onClick={this.handleClickRow}
          columns={cols}
          rows={rows}
          onClickColumn={(col, b, c) => {
            if (!col.sortable) return;

            if (!this.state.currentSort) {
              this.setState({currentSort: col.sortable + ':a'});
              return;
            }

            var [_, currentOrder] = this.state.currentSort.split(':');

            if (currentOrder == 'a') currentOrder = ':d';
            else if (currentOrder == 'd') currentOrder = '';
            else if (!currentOrder) currentOrder = ':a';

            if (currentOrder) {
              this.setState({currentSort: col.sortable + currentOrder});
            } else {
              this.setState({currentSort: undefined});
            }
          }}
        />
      </Panel>
    );
  }

  render() {
    const {className} = this.props;
    const classes = cx(className, styles.pageWrapper);

    return (
      <div className={classes}>
        <Helmet title="Users" />

        <div className={styles.header}>
          <h3>Users</h3>
          <InviteUsersButton key="invite_users">Invite</InviteUsersButton>
          <SearchBar
            value={this.state.filterText}
            onUserInput={filterText => this.setState({filterText})}
            tabIndex="1"
          />
        </div>

        <PageBody>
          <CSSTransitionGroup
            transitionName="fade"
            component="div"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {this.renderUsersTable()}
          </CSSTransitionGroup>
        </PageBody>
      </div>
    );
  }
}

function getFilteredUsers(users, term) {
  const trimmedTerm = term.trim().toLowerCase();
  if (!trimmedTerm) return users;

  return users.filter(user => {
    return (
      user.name.toLowerCase().indexOf(trimmedTerm) > -1 ||
      user.email.toLowerCase().indexOf(trimmedTerm) > -1
    );
  });
}

export default connect(
  usersPageSelector,
  {requestDeleteUser, requestEditUser, requestUnlockUser, fetchUsers}
)(UsersPage);
