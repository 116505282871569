import React from 'react';
import {connect} from 'react-redux';
import {trackEvent} from 'modules/tracking';
import NotificationsConfig from '../../components/reportSettings/notificationsConfig';
import {updateWatchingReport} from 'modules/reports/reports';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';

export class ReportSettingsNotificationsPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed alerts config page', {
      forReport: this.props.match.params.reportId,
      fromFile: 'reportSettingsAlertsPage.js'
    });
  }

  render() {
    return <NotificationsConfig {...this.props} name="notificationsConfig" />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateWatchingReport}
)(ReportSettingsNotificationsPage);
