exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2MsDk4VYhNvvB7bWH7w3Oh{border-radius:50%;background:#bbb;margin:10px}._2MsDk4VYhNvvB7bWH7w3Oh._1IKpDxE1wPHtZxr1AWLRYy{margin:0px;display:block}", ""]);

// exports
exports.locals = {
	"userImage": "_2MsDk4VYhNvvB7bWH7w3Oh",
	"inline": "_1IKpDxE1wPHtZxr1AWLRYy"
};