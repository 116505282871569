import {Map, Set, fromJS} from 'immutable';
import {LEAVE_REPORT_PAGE} from 'modules/reports/reportConstants';

const initialState = fromJS({
  closeQueryParams: {},
  actions: {},
  latestActionResultFilters: {} // These exist because the ActionLayout currently stores it's filters in local state
  // but we need to access the filters in the `actionsPoll`.
});

export default function actionsReducer(state = initialState, reduxAction) {
  switch (reduxAction.type) {
    case SET_ACTION_RESULT_FILTERS:
      return state.set('latestActionResultFilters', reduxAction.filters);

    case LEAVE_ACTION:
      return state.set('latestActionResultFilters', {});

    case LEAVE_REPORT_PAGE:
      return initialState;

    case SET_ACTION_CLOSE_PARAMS:
      if (reduxAction.queryParams && reduxAction.queryParams.length) {
        const queryParams = reduxAction.queryParams.split('&').reduce((carry, option) => {
          const opt = option.replace('?', '').split('=');
          carry[opt[0]] = decodeURIComponent(opt[1]);
          return carry;
        }, {});
        return state.set('closeQueryParams', queryParams);
      }
      return state.set('closeQueryParams', {});

    case CHANGE_ACTION_STATE:
      return state.setIn(['actions', reduxAction.actionId.toString(), 'state'], reduxAction.state);

    case RECEIVE_ACTION:
      let {action} = reduxAction;
      // make participants a set so they stay unique
      action.participants = Set(action.participants);

      const {layout, ...actionItems} = action;
      actionItems.layoutId = layout && layout.layoutId ? layout.layoutId : null;
      actionItems.resultIds = layout && layout.resultIds ? layout.resultIds : [];
      actionItems.defaultFilters = layout ? layout.defaultFilters : [];
      return state.setIn(['actions', action.actionId.toString()], fromJS(actionItems));

    case RECEIVE_ACTION_EVENT:
      // add userId to participants set
      state = state.updateIn(
        ['actions', reduxAction.actionId.toString(), 'participants'],
        participants => participants.add(reduxAction.actionEvent.userId)
      );

      return state.updateIn(['actions', reduxAction.actionId.toString(), 'events'], events => {
        const {layout, ...actionItems} = reduxAction.actionEvent;
        const actionEvent = fromJS(actionItems);

        const idx = events.findIndex(
          event => event.get('actionEventId') == reduxAction.actionEvent.actionEventId
        );

        // if it already exists, replace existing
        if (idx) {
          return events.set(idx, actionEvent);
        }

        return events.push(actionEvent);
      });

    case DELETE_ACTION_EVENT:
      return state.updateIn(['actions', reduxAction.actionId.toString(), 'events'], events =>
        events.filter(event => event.get('actionEventId') != reduxAction.actionEventId)
      );
  }
  return state;
}

export const RECEIVE_ACTION = 'actions:RECEIVE_ACTION';
export function receiveAction(action) {
  return {
    type: RECEIVE_ACTION,
    action
  };
}

export const VIEW_ACTION = 'actions:VIEW_ACTION';
export function viewAction(reportId, actionId, testId) {
  return {
    type: VIEW_ACTION,
    reportId,
    actionId,
    testId
  };
}

export const LEAVE_ACTION = 'actions:LEAVE_ACTION';
export function leaveAction() {
  return {
    type: LEAVE_ACTION
  };
}

export const FETCH_ACTION = 'actions:FETCH_ACTION';
export function fetchAction(reportId, actionId) {
  return {
    type: FETCH_ACTION,
    reportId,
    actionId
  };
}

export const FETCH_ACTION_RESULTS = 'actions:VIEW_ACTION_LAYOUT';
export function fetchActionResults(actionId, filters = {}) {
  return {
    type: FETCH_ACTION_RESULTS,
    actionId,
    filters
  };
}

export const VIEW_ACTION_LAYOUT = 'actions:VIEW_ACTION_LAYOUT';
export function viewActionLayout(actionId, filters) {
  return {
    type: VIEW_ACTION_LAYOUT,
    actionId,
    filters
  };
}

export const DELETE_ACTION_EVENT = 'actions:DELETE_ACTION_EVENT';
export function deleteActionEvent(reportId, actionId, actionEventId) {
  return {
    type: DELETE_ACTION_EVENT,
    reportId,
    actionId,
    actionEventId
  };
}

export const CHANGE_ACTION_STATE = 'actions:CHANGE_ACTION_STATE';
export function changeActionState(reportId, actionId, state, comment = null) {
  return {
    type: CHANGE_ACTION_STATE,
    reportId,
    actionId,
    state,
    comment
  };
}

export const RECEIVE_ACTION_EVENT = 'actions:RECEIVE_ACTION_EVENT';
export function receiveActionEvent(actionId, tasactionEventkEvent) {
  return {
    type: RECEIVE_ACTION_EVENT,
    actionId,
    actionEvent
  };
}

export const UPDATE_ACTION = 'actions:UPDATE_ACTION';
export function updateAction(reportId, action) {
  return {
    type: UPDATE_ACTION,
    reportId,
    action
  };
}

export const SET_ACTION_CLOSE_PARAMS = 'actions:SET_ACTION_CLOSE_PARAMS';
export function setActionCloseParams(queryParams) {
  return {
    type: SET_ACTION_CLOSE_PARAMS,
    queryParams
  };
}

export const SET_ACTION_RESULT_FILTERS = 'actions:SET_ACTION_RESULT_FILTERS';
export function setActionResultFilters(actionId, filters) {
  return {
    type: SET_ACTION_RESULT_FILTERS,
    actionId,
    filters
  };
}
