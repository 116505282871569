exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3bMlMps6u83QxVYYUIT64L{display:flex;align-items:stretch;align-content:stretch;flex-wrap:wrap}._2CgeZC-JgQmOFT51XHJLMN{white-space:nowrap;flex:2 0;flex-basis:115px;border:2px solid #ddd;background:#fff;padding:10px;height:45px;color:#888;margin:2px 2px;border-radius:5px;text-align:left;overflow-x:hidden;text-overflow:ellipsis}._2zxolozs_9lyiuYvQdI1LH{flex-basis:230px}._2vKhFhjGLDFIIy9CZ-7gnF,._1Spjhh-csUGRyNoi7nEIZv{flex-basis:60px;flex:1 1;background:#ccc;color:#222 !important;text-align:center;border:2px outset #eee}._2yaXmsNfQEpyZGuLHXhg7D{text-align:center}", ""]);

// exports
exports.locals = {
	"fields": "_3bMlMps6u83QxVYYUIT64L",
	"field": "_2CgeZC-JgQmOFT51XHJLMN",
	"textarea": "_2zxolozs_9lyiuYvQdI1LH",
	"submit": "_2vKhFhjGLDFIIy9CZ-7gnF",
	"button": "_1Spjhh-csUGRyNoi7nEIZv",
	"showMore": "_2yaXmsNfQEpyZGuLHXhg7D"
};