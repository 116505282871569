import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_INTERACTION} from 'modules/interactions/constants';
import {interactionEndpoint} from 'modules/interactions/utils';
import {requestInteractionComplete} from 'modules/interactions/actions';
import {reportIdParamSelector} from 'modules/reports/reportSelectors';
import {makeRequest, processResponse} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestInteractionSaga() {
  yield takeLatest(REQUEST_INTERACTION, requestInteractionListener);
}

function* requestInteractionListener({type, ...filters}) {
  const {interactionId} = filters;
  const reportId = yield select(reportIdParamSelector);
  const url = interactionEndpoint({reportId, interactionId});

  const res = yield makeRequest('GET', url);

  return yield processResponse(res, {
    *success(data) {
      yield put(requestInteractionComplete(null, data));
    },
    *error(err) {
      if (res.status == 404) {
        yield put(requestInteractionComplete('NOT_FOUND'));
      } else {
        yield put(requestInteractionComplete(err));
      }
    },
    errorMessage: couldNot('request interaction')
  });
}
