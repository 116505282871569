import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import VideoPlayerModal from 'components/modal/videoPlayerModal';
import {showInModal} from 'modules/modal';
import {API} from 'config';
import styles from 'components/topic/topic.scss';

export class Lesson extends React.Component {
  openLesson = () => {
    const {name, url, type} = this.props;

    if (!url) {
      console.error('Lesson not yet supported');
      return;
    }

    if (type === 'url') {
      window.open(url, '_blank');
      return;
    }

    if (type === 'help') {
      this.props.openHelp(url);
      return;
    }

    this.props.showInModal(
      VideoPlayerModal,
      {
        videoUrl: url,
        videoType: 'youtube',
        videoTitle: name
      },
      {},
      {wider: true}
    );
  };

  render() {
    const {thumbnail, name, minutes, big} = this.props;

    return (
      <div className={cx(styles.topicItemName, {[styles.big]: big})} onClick={this.openLesson}>
        {thumbnail && (
          <div className={styles.topicItemThumbnail}>
            <img src={thumbnail.charAt(0) === '/' ? API.ROOT + thumbnail : thumbnail} alt="" />
          </div>
        )}
        <a className={styles.topicItemLink}>{name}</a>
        <p className={styles.topicItemMeta}>
          About {minutes == 1 ? <span>1 minute</span> : <span>{minutes} minutes</span>}
        </p>
      </div>
    );
  }
}

export default connect(
  null,
  {showInModal}
)(Lesson);
