import React from 'react';
import styles from './student.scss';
import courseStyles from '../../pages/courses.scss';
import UserImage from 'components/user/userImage';
import Icon from 'components/font-awesome';

function StudentRow({student}) {
  const name = student.get('name');
  const percentComplete = student.get('percentComplete');
  const percentCorrect = student.get('percentCorrect');

  return (
    <tr key={student.get('userId')}>
      <td className={styles.icon}>
        <UserImage user={student.toJS()} />
      </td>
      <td>
        <b>{name}</b>
      </td>
      <td>
        <Icon name="fas fa-check" className={courseStyles.progressComplete} />{' '}
        {Math.round(percentComplete)}% complete
      </td>
      <td>
        <Icon name="fas fa-star" className={courseStyles.progressCorrect} />{' '}
        {Math.round(percentCorrect)}% correct
      </td>
    </tr>
  );
}

export default function StudentsTable({students}) {
  return (
    <table className={styles.students}>
      <tbody>
        {students.map(student => (
          <StudentRow key={student.get('userId')} student={student} />
        ))}
      </tbody>
    </table>
  );
}
