import React from 'react';
import PropTypes from 'prop-types';

export default class SvgScore extends React.Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    borderSize: PropTypes.number.isRequired,
    text: PropTypes.string,
    colour: PropTypes.string,
    background: PropTypes.string,
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    fillClass: PropTypes.string
  };

  static defaultProps = {
    text: null
  };

  render() {
    const {
      size,
      borderSize,
      text,
      colour,
      background,
      startAngle,
      endAngle,
      fillClass
    } = this.props;
    const halfSize = size / 2;
    const radius = halfSize - borderSize / 2; // minus half the bordersize so the border isn't clipped

    // the +8 is a random number which vertically aligns the text, the `size / 70` scales the 8 depending on the requied size
    const textTop = halfSize + (size / 70) * 8;
    const pathString = describeArc(halfSize, halfSize, radius, startAngle, endAngle);

    return (
      <svg height={size} width={size}>
        <circle
          cx={halfSize}
          cy={halfSize}
          r={radius}
          fill="none"
          stroke={background}
          strokeWidth={borderSize}
        />
        <circle cx={halfSize} cy={halfSize} r={halfSize - borderSize} fill="#fff" />
        <path
          className={fillClass}
          d={pathString}
          fill="none"
          stroke={colour}
          strokeWidth={borderSize}
        />
        {text && (
          <text
            x={halfSize}
            y={textTop}
            textAnchor="middle"
            // the font size is 25 when size is 70, and scales accordingly
            fontSize={(size / 70) * 25 + 'px'}
          >
            {text}
          </text>
        )}
      </svg>
    );
  }
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const isLargeArc = endAngle - startAngle <= 180 ? '0' : '1';

  return ['M', start.x, start.y, 'A', radius, radius, 0, isLargeArc, 0, end.x, end.y].join(' ');
}
