import React from 'react';
import Tooltip from 'components/tooltip';
import Link from 'modules/location/link';
import {evalInstruction} from 'utils/instructionUtils';
import {prefixObjectKeys} from 'utils/collectionUtils';
import {getLinkBuilder} from './controlUtils';

// Must pass a `command` or the props to build a command (I.E instruction, context, filters)
export default function ControlLink({
  command,
  instruction,
  context,
  filters,
  className,
  tooltip,
  children
}) {
  // get the command from the props or calculate it from instruction
  const {type, context: actionContext, filters: actionFilters, ...params} =
    command || evalInstruction(instruction, context, filters);

  // if context is not iterable, we'll just get an empty object back
  const prefixedContext = prefixObjectKeys(actionContext, 'context');
  // build the query from the context and the params (NOTE we may only want to do this for specific types)
  const query =
    type === 'filter' ? actionFilters : type === 'inspector' ? {...prefixedContext, ...params} : {};

  // get the url builder using the type I.E "inspector" type
  // the type 'filter' only changes the query string, so no builder is needed
  const builder = type === 'filter' ? null : getLinkBuilder(type);
  if (!builder && type !== 'filter') return null;

  const link = (
    // we pass `params` becuase the command might have props it needs to pass to the builder function
    // the builder function will ignore any params it doesn't care about
    <Link className={className} builder={builder} query={query} {...params}>
      {children}
    </Link>
  );

  if (tooltip) {
    return (
      <Tooltip text={tooltip}>
        <div>{link}</div>
      </Tooltip>
    );
  }

  return link;
}
