import {createSelector} from 'reselect';
import {evalInstruction} from 'utils/instructionUtils';
import {enabledEffectsSelector} from 'modules/inspector/inspectorSelectors';

const instructionStateSelector = state => state.instructions;

const idPropSelector = (state, props) => {
  return props.config && props.config.id;
};

export const isDropdownActiveSelector = createSelector(
  instructionStateSelector,
  idPropSelector,
  (instructions, id) => {
    return {
      isActive: id ? !!instructions.getIn(['flags', id]) : false
    };
  }
);

const controlStateHandlersSelector = createSelector(
  enabledEffectsSelector,

  // must return object of `[command.type]: fn(command) => state`.
  // Where `state` is a string that corresponds with the `control.state[state]`
  effects => ({
    toggleEffect: command => (effects.includes(command.query) ? 'active' : null)
  })
);

export const controlStateSelector = createSelector(
  controlStateHandlersSelector,
  (_, props) => props.controls,
  (_, props) => props.context,
  (_, props) => props.filters,
  (handlers, controls, context, filters) => {
    return controls.map(control => {
      if (control.has('state')) {
        const command = evalInstruction(control.get('instruction').toJS(), context.toJS(), filters);
        const handler = handlers[command.type];
        if (handler) {
          const state = handler(command);
          if (state && control.hasIn(['state', state])) {
            const newState = control.getIn(['state', state]);
            return control.remove('state').merge(newState);
          }
        }
      }
      return control;
    });
  }
);
