export const FETCH_SUPER_PRODUCTS = 'app:FETCH_SUPER_PRODUCTS';
export function fetchSuperProducts() {
  return {
    type: FETCH_SUPER_PRODUCTS
  };
}

export const RECEIVE_SUPER_PRODUCTS = 'app:RECEIVE_SUPER_PRODUCTS';
export function recevieSuperProducts(products) {
  return {
    type: RECEIVE_SUPER_PRODUCTS,
    products
  };
}

export const HANDLE_SUPER_PRODUCTS_LOAD_FAILURE = 'app:HANDLE_SUPER_PRODUCTS_LOAD_FAILURE';
export function handleSuperProductsLoadFailure(error) {
  return {
    type: HANDLE_SUPER_PRODUCTS_LOAD_FAILURE,
    error
  };
}

export const REQUEST_QUOTES = 'app:SERVICES_REQUEST_QUOTES';
export function requestQuotes(productId, subConfigs, paymentOptions = {}) {
  return {
    type: REQUEST_QUOTES,
    productId,
    subConfigs,
    paymentOptions
  };
}

export const REQUEST_MULTIPLE_QUOTES = 'app:SERVICES_REQUEST_MULTIPLE_QUOTES';
export function requestMultipleQuotes(productId, subConfigs, paymentOptions = {}) {
  return {
    type: REQUEST_MULTIPLE_QUOTES,
    productId,
    subConfigs,
    paymentOptions
  };
}

export const RECEIVE_QUOTES = 'app:SERVICES_RECEIVE_QUOTES';
export function receiveQuote(quotes) {
  return {
    type: RECEIVE_QUOTES,
    quotes
  };
}

export const RECEIVE_MULTIPLE_QUOTES = 'app:SERVICES_RECEIVE_MULTIPLE_QUOTES';
export function receiveMultipleQuotes(quotes) {
  return {
    type: RECEIVE_MULTIPLE_QUOTES,
    quotes
  };
}

export const HANDLE_QUOTE_ERROR = 'app:SERVICES_HANDLE_QUOTE_ERROR';
export function handleQuoteError(error) {
  return {
    type: HANDLE_QUOTE_ERROR,
    error
  };
}

export const REQUEST_BUY_PLAN = 'app:SERVICES_REQUEST_BUY_PLAN';
export function requestBuyPlan(
  productId,
  planId,
  subConfig,
  accountData,
  paymentOptions,
  failCallback
) {
  return {
    type: REQUEST_BUY_PLAN,
    productId,
    planId,
    subConfig,
    accountData,
    paymentOptions,
    failCallback
  };
}

export const HANDLE_BUY_PLAN_RESPONSE = 'app:SERVICES_HANDLE_BUY_PLAN_RESPONSE';
export function receiveBuyPlanResponse(response) {
  return {
    type: HANDLE_BUY_PLAN_RESPONSE,
    response
  };
}
