import * as Rules from './Rules';

export default function filterFieldRules(obj) {
  const props = {};
  const ruleNames = Object.keys(Rules);
  const defaultValidators = DefaultValidators[obj.type];
  const rules = defaultValidators ? {...defaultValidators} : {};

  for (let key in obj) {
    if (ruleNames.includes(key)) {
      rules[key] = obj[key];
    } else {
      props[key] = obj[key];
    }
  }
  return [rules, props];
}

const DefaultValidators = {
  url: {url: true}, // for the url type, apply the url rule
  email: {email: true} // for the email type, apply the email rule
};
