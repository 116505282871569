import {put, select, takeLatest} from 'redux-saga/effects';
import {SPOTLIGHT_SEARCH, recieveResults} from '..';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* spotlightSearchSaga() {
  yield takeLatest(SPOTLIGHT_SEARCH, spotlightSearch);
}

function* spotlightSearch({searchText}) {
  yield processRequest(
    'GET',
    'accounts/search',
    {
      successAction: recieveResults
    },
    {searchText}
  );
}
