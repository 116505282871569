import React from 'react';
import Spinner from 'components/spinner';
import Button from 'components/button';

const buttonStyle = {display: 'flex', marginLeft: 'auto'};

const AlertMeButton = ({children, ...props}) => (
  <Button icon="check" {...props}>
    Alert me {children}
  </Button>
);

const UnalertMeButton = ({children, ...props}) => (
  <Button icon="times" {...props}>
    Disable alert {children}
  </Button>
);

export default function AlertButton(props) {
  const {onClick, isLoading, isSubscribed} = props;
  const BaseButtonComponent = isSubscribed ? UnalertMeButton : AlertMeButton;

  return (
    <BaseButtonComponent style={buttonStyle} onClick={onClick} grey>
      {isLoading && <Spinner small light marginLeft={10} />}
    </BaseButtonComponent>
  );
}
