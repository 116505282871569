import React from 'react';

const quotePageCounts = ['100', '500', '1000', '2500', '5000', '7500'];

const pricingPlans = [
  {
    name: 'Editor',
    coverDescription: <p>Covers:</p>,
    covers: ['Spelling', 'Grammar', 'Broken Links', 'Readability'],
    extras: null
  },
  {
    name: 'Marketer',
    coverDescription: (
      <p>
        All of <strong>Editor</strong> plus:
      </p>
    ),
    covers: ['Search Ranking', 'SEO', 'Mobile', 'Speed'],
    extras: ['100 search keywords']
  },
  {
    name: 'Executive',
    coverDescription: (
      <p>
        All of <strong>Marketer</strong> plus:
      </p>
    ),
    covers: ['Accessibility', 'GDPR', 'Policies', 'Uptime'],
    extras: ['300 search keywords']
  },
  {
    name: 'Custom',
    coverDescription: <p>We can offer:</p>,
    covers: [
      'Test more pages',
      'Test PDFs',
      'Bespoke training',
      'Account manager',
      'Pay by invoice'
    ],
    extras: null
  }
];

const editorConfig = {
  hasContent: true,
  supportLevel: 10,
  hasPrivacy: false,
  numKeywords: 0,
  numDocuments: 0,
  numUptimeMonitors: 0
};
const marketerConfig = {
  hasContent: true,
  hasMarketing: true,
  hasUx: true,
  supportLevel: 10,
  hasPrivacy: false,
  numKeywords: 100,
  numDocuments: 0,
  numUptimeMonitors: 0
};

const executiveConfig = {
  hasContent: true,
  hasMarketing: true,
  hasUx: true,
  hasAccessibility: true,
  supportLevel: 10,
  hasPrivacy: true,
  numKeywords: 300,
  numDocuments: 0,
  numUptimeMonitors: 15
};

const MONITOR_PRODUCT_ID = 'monitor-nov-2020';

export default {
  quotePageCounts,
  editorConfig,
  marketerConfig,
  executiveConfig,
  pricingPlans,
  MONITOR_PRODUCT_ID
};
