import Helmet from 'components/helmet';
import Status from 'components/status';
import styles from 'modules/auth/components/authWrapperShared.scss';
import ExternalLink from 'modules/location/externalLink';
import {sendResetEmail} from 'modules/passwords';
import {passwordsSelector} from 'modules/passwords/passwordSelectors';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ForgottenPasswordForm from '../components/forgottenPasswordForm';

export class ForgottenPasswordPage extends React.Component {
  renderEmailSentMessage() {
    return (
      <div>
        <Status className={styles.status} appearance="good">
          <p className={styles.text}>
            An email has been sent to your address if it exists in our system. It may take a few
            minutes to arrive.
          </p>
        </Status>

        <Link className={styles.blockLink} to="/">
          Go back
        </Link>

        <div style={{marginTop: '30px'}}>{this.renderProspectWarning()}</div>
      </div>
    );
  }

  renderProspectWarning() {
    return (
      <p className={styles.prospectWarning}>
        If you're trying to reset your password for{' '}
        <strong>
          <ExternalLink
            style={{color: '#a94442'}}
            href="https://insites.com/?utm_source=silktide-login&utm_medium=web&utm_campaign=forgotten-password"
          >
            Insites
          </ExternalLink>
        </strong>
        , please{' '}
        <ExternalLink href="https://app.insites.com/en_GB/user/forgotten?utm_source=silktide-login&utm_medium=web&utm_campaign=forgotten-password">
          use this reset form instead
        </ExternalLink>
        .
      </p>
    );
  }

  renderForgottenPasswordForm() {
    const {sentEmail, sendResetEmail} = this.props;

    if (sentEmail) {
      return this.renderEmailSentMessage();
    }

    return (
      <div>
        <h1 className={styles.header}>Forgot your password?</h1>

        <p className={styles.text}>
          Enter your email address below and we'll send you a link to reset it.
        </p>

        {this.renderProspectWarning()}

        <ForgottenPasswordForm
          name="forgottenPasswordForm"
          onValidSubmit={({email}) => sendResetEmail(email)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        <Helmet title="Forgotten password" />

        {this.renderForgottenPasswordForm()}
      </div>
    );
  }
}

export default connect(passwordsSelector, {sendResetEmail})(ForgottenPasswordPage);
