import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import SettingsPage from 'components/settings/settingsPage';
import PageHeader from 'components/header/pageHeader';
import {forcedPagesSettingsPath} from 'modules/reports/reportUtils';
import {Form, Field} from 'modules/form/components';
import {saveForcedPageSelector} from 'modules/reports/reportSelectors';
import {addForcedPage, editForcedPage, fetchForcedPages} from 'modules/reports';
import SavePanel from 'modules/form/components/savePanel';

class SaveForcedPage extends Component {
  state = {
    isValid: true,
    hasChanged: false
  };

  componentDidMount() {
    const {
      fetchForcedPages,
      match: {params}
    } = this.props;

    const {forcedPageId, reportId} = params;

    if (forcedPageId && forcedPageId !== 'new') {
      fetchForcedPages(reportId, forcedPageId);
    }
  }

  handleGoBack = () => {
    this.props.push(forcedPagesSettingsPath, {}, ['reportId']);
  };

  handleValidSubmit = data => {
    const {addForcedPage, editForcedPage, match} = this.props;
    const {forcedPageId, reportId} = match.params;

    if (forcedPageId === 'new') {
      addForcedPage(data, reportId);
    } else {
      editForcedPage(data, reportId, forcedPageId);
    }
  };

  handleChange = () => {
    const {hasChanged} = this.state;

    if (!hasChanged) {
      this.setState({
        hasChanged: true
      });
    }
  };

  render() {
    const {
      match: {params},
      forcedPage,
      forcedPagesLoading,
      title,
      websiteConfig
    } = this.props;

    const isEditing = params.forcedPageId !== 'new';
    const newDefaultValue = {url: '', maxAllowedSeconds: 30};
    const defaultValue = isEditing && forcedPage ? forcedPage : newDefaultValue;

    return (
      <SettingsPage
        params={params}
        pageHeader={
          <PageHeader
            title={(isEditing ? 'Edit' : 'New') + ' forced page'}
            goBack={this.handleGoBack}
          />
        }
      >
        <Form
          boldLabels
          name={name}
          submitLabel="Save"
          defaultValue={defaultValue}
          title={title}
          onChange={this.handleChange}
          onValidSubmit={this.handleValidSubmit}
          isLoading={forcedPagesLoading}
        >
          {!forcedPagesLoading && (
            <Field
              name="url"
              type="url"
              label="Forced page URL"
              required
              autoFocus
              placeholder="e.g. https://silktide.com/page-to-force"
            />
          )}
          <SavePanel
            floating
            onCancel={this.handleGoBack}
            isLoading={forcedPagesLoading}
            isValid={this.state.isValid}
            show={this.isNewPage || this.state.hasChanged}
          />
        </Form>
      </SettingsPage>
    );
  }
}

export default connect(
  saveForcedPageSelector,
  {addForcedPage, editForcedPage, fetchForcedPages, push}
)(SaveForcedPage);
