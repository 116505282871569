import React from 'react';
import {connect} from 'react-redux';
import {trackEvent} from 'modules/tracking';
import {updateReport} from 'modules/reports';
import {updateWebsite} from 'modules/tests';
import {reportSettingsPageSelector} from 'modules/reports/reportSelectors';
import DigestsConfig from 'modules/reports/components/reportSettings/digestsConfig';

export class ReportSettingsDigestsPage extends React.Component {
  componentDidMount() {
    trackEvent('Viewed digests page', {
      forReport: this.props.match.params.reportId
    });
  }

  render() {
    return <DigestsConfig {...this.props} />;
  }
}

export default connect(
  reportSettingsPageSelector,
  {updateReport, updateWebsite}
)(ReportSettingsDigestsPage);
