import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Flex from 'components/layout/flex';
import Icon from 'components/font-awesome';
import SidebarScore from './sidebarScore';
import styles from './sidebarItem.scss';

export class SidebarItem extends React.Component {
  static defaultProps = {
    hoverable: true,
    clickable: true,
    translucent: false
  };

  handleClick = e => {
    const {href, onClick, push} = this.props;

    if (!this.props.clickable) {
      return;
    }

    if (onClick) {
      const ret = onClick(e);
      if (ret === false) return;
    }
  };

  // Calls props.onClickIcon if defined otherwise defaults to onClickLabel
  handleClickIcon = e => {
    if (!this.props.clickable) {
      return null;
    }

    const {onClickIcon} = this.props;

    if (!onClickIcon) {
      return this.handleClick(e);
    }

    return onClickIcon(e);
  };

  handleClickScore = e => {
    const {onClickScore, onClickAfterIcon} = this.props;

    if (!this.props.clickable) {
      return null;
    }

    if (!onClickScore && !onClickAfterIcon) {
      return this.handleClick(e);
    }

    onClickScore && onClickScore(e);
    onClickAfterIcon && onClickAfterIcon(e);
    return true;
  };

  renderLabel() {
    const {label, children} = this.props;
    const value = label || children;

    return (
      <Flex grow={2} className={styles.label} onClick={this.handleClick}>
        {value}
      </Flex>
    );
  }

  renderIcon() {
    const {icon} = this.props;

    if (!icon) return null;

    return (
      <Flex grow={0} className={styles.icon} onClick={this.handleClickIcon}>
        <Icon name={icon} />
      </Flex>
    );
  }
  renderBefore() {
    const {before} = this.props;

    // If props.after is set, we render that instead of a score
    if (before) {
      return <div className={styles.before}>{before}</div>;
    }

    return null;
  }

  renderAfter() {
    const {score, after, active, muted} = this.props;

    // If props.after is set, we render that instead of a score
    if (after) {
      return (
        <div className={styles.after} onClick={this.handleClickScore}>
          {after}
        </div>
      );
    }

    if (score == null || score === -1) return null;

    return (
      <div className={styles.after} onClick={this.handleClickScore}>
        <SidebarScore active={active} muted={muted} locked={score === -2}>
          {score}
        </SidebarScore>
      </div>
    );
  }

  // All the onClicks are set on the Flex components
  // to maximise hitbox when clicking
  // ClassNames are there as padding is ignored when set on their children.
  render() {
    const {
      href,
      active,
      score,
      bigger,
      onClickIcon,
      className,
      hoverable,
      clickable,
      translucent,
      newStyle,
      ...props
    } = this.props;

    const classes = cx(styles.item, className, {
      [styles.active]: active,
      [styles.bigger]: bigger,
      [styles.newStyle]: newStyle,
      [styles.hoverable]: hoverable,
      [styles.clickable]: clickable,
      [styles.translucent]: translucent
    });

    const item = (
      <Flex container className={classes}>
        {this.renderIcon()}
        {this.renderBefore()}

        {this.renderLabel()}

        {this.renderAfter()}
      </Flex>
    );

    return href ? <Link to={href}>{item}</Link> : item;
  }
}

export default SidebarItem;
