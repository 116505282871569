import React from 'react';
import pageNameStyles from './monitorName.scss';
import UrlComponent from 'components/url/url';
import styles from './styles.scss';

export default class MonitorName extends React.Component {
  render() {
    const {data, record} = this.props;
    return (
      <div className={styles.pageName}>
        <p className={styles.link}>
          <b>{data}</b>
        </p>
        <p>
          <UrlComponent url={record.url} className={pageNameStyles.url} />
        </p>
      </div>
    );
  }
}
