import React from 'react';
import Helmet from 'components/helmet';
import {connect} from 'react-redux';
import {profileRoutePageSelector} from 'modules/profile/profileSelectors';
import Profile from 'modules/profile/components/profilePage';
import ProfileSidebar from 'modules/profile/components/profileSidebar';
import PageWithSidebar from 'components/wrappers/pageWithSidebar';
import NotFoundPage from 'modules/location/pages/404Page';
import Spinner from 'components/spinner';
import {fetchUserAchievements} from 'modules/achievements';

export class ProfilePage extends React.Component {
  componentDidMount() {
    const {fetchUserAchievements, userId} = this.props;

    fetchUserAchievements(userId);
  }

  render() {
    const {userId, loading, should404} = this.props;

    if (loading) {
      return <Spinner marginTop={100} />;
    }

    if (should404) {
      return <NotFoundPage />;
    }

    const sidebar = <ProfileSidebar userId={userId} />;

    return (
      <PageWithSidebar sidebar={sidebar}>
        <Helmet title="Profile" />

        <Profile userId={userId} />
      </PageWithSidebar>
    );
  }
}

export default connect(
  profileRoutePageSelector,
  {fetchUserAchievements}
)(ProfilePage);
