import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {editTagComplete} from 'modules/tags/actions';
import {EDIT_TAG} from 'modules/tags/constants';
import {processRequest} from 'utils/saga/fetchUtils';
import {hideModal} from 'modules/modal';

export default function* editTagListener() {
  yield takeLatest(EDIT_TAG, editTag);
}

function* editTag({tagId, label, color}) {
  try {
    const newTag = yield processRequest('PUT', 'tags/' + tagId, {}, {label, color});
    yield put(editTagComplete(null, newTag.tagId, newTag.label, newTag.color));
    yield put(hideModal());
  } catch (err) {
    yield put(editTagComplete(err));
  }
}
