import _ from 'lodash';
import React from 'react';
import Spinner from 'components/spinner';
import Icon from 'components/font-awesome';
import styles from 'components/stat/stat.scss';

class Tick extends React.Component {
  renderValue(value) {
    if (_.isNull(value)) return <Spinner centered />;

    if (value) {
      return <Icon name="check" aria-hidden="true" />;
    }

    return <Icon name="times" aria-hidden="true" />;
  }

  renderInnerContent() {
    const {config} = this.props;
    const {label} = config;
    const {result} = this.props;

    return [
      <div className={styles.valueWrapperNoCompetitors}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{this.renderValue(result)}</div>
      </div>
    ];
  }

  render() {
    return <div className={styles.stat}>{this.renderInnerContent()}</div>;
  }
}

Tick.handlesLoading = true;

export default Tick;
