import React from 'react';
import cx from 'classnames';
import home from './home2.png';
import homeHover from './home-hover2.png';
import styles from './homeIcon.scss';

export default class HomeIcon extends React.Component {
  render() {
    const {className, ...props} = this.props;
    const classes = cx(className, styles.homeIcon);

    return <img {...props} className={classes} src={home} alt="Home" />;
  }
}
