import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/layout/flex';
import SelectInput from './selectInput';
import NumberInput from './numberInput';
import {choosePluralVersion} from 'utils/interpolationUtils';
import styles from './scheduleInput.scss';
import {havePropsChanged} from 'utils/propUtils';

// The period property specifies the value for schedulePeriod
const simpleOptions = [
  {value: '', label: 'Never', period: 0},
  {value: 'day', label: 'Every 5 days', period: 5},
  {value: 'week', label: 'Weekly', period: 1},
  {value: 'month', label: 'Monthly', period: 1}
];

export default class ScheduleInput extends React.Component {
  static defaultProps = {
    value: {scheduleInterval: '', schedulePeriod: 1},
    simple: false
  };

  state = {
    schedulePeriod: '',
    scheduleInterval: ''
  };

  componentWillMount() {
    if (!this.props.value) {
      this.props.onChange(this.state);
    }
  }

  componentWillReceiveProps = ({value}) => {
    if (havePropsChanged(this.state, value, ['scheduleInterval', 'schedulePeriod'])) {
      this.setState(value);
      this.props.onChange(value);
    }
  };

  getIntervalOptions = period => {
    return [
      {value: '', label: 'Never'},
      {value: 'day', label: choosePluralVersion(period, 'Day', 'Days')},
      {value: 'week', label: choosePluralVersion(period, 'Week', 'Weeks')},
      {value: 'month', label: choosePluralVersion(period, 'Month', 'Months')},
      {value: 'year', label: choosePluralVersion(period, 'Year', 'Years')}
    ];
  };

  handleFieldChange = fieldName => event => {
    const value = event && event.target ? event.target.value : event;
    const {simple} = this.props;
    var scheduleInterval, schedulePeriod;

    if (simple) {
      const schedule = simpleOptions.find(obj => {
        return obj.value === value;
      });

      ({value: scheduleInterval, period: schedulePeriod} = schedule);
    } else {
      ({scheduleInterval, schedulePeriod} = this.state);
    }

    this.setState({[fieldName]: value});

    this.props.onChange({
      schedulePeriod,
      scheduleInterval,
      // Overwrite the value we extracted from state because setState is async
      [fieldName]: value
    });
  };

  renderComplex() {
    const {
      value: {scheduleInterval, schedulePeriod},
      onChange
    } = this.props;

    return (
      <Flex container>
        <Flex grow={1} className={styles.label}>
          {scheduleInterval && <span>Every</span>}
        </Flex>

        <Flex grow={1} className={styles.period}>
          {scheduleInterval && (
            <NumberInput
              name="schedulePeriod"
              value={schedulePeriod}
              onChange={this.handleFieldChange('schedulePeriod')}
            />
          )}
        </Flex>
        <Flex grow={2} className={styles.interval}>
          <SelectInput
            name="scheduleInterval"
            options={this.getIntervalOptions(schedulePeriod)}
            value={scheduleInterval}
            onChange={this.handleFieldChange('scheduleInterval')}
          />
        </Flex>
      </Flex>
    );
  }

  renderSimple() {
    const {
      value: {scheduleInterval, schedulePeriod},
      onChange
    } = this.props;

    return (
      <SelectInput
        name="scheduleInterval"
        options={simpleOptions}
        value={scheduleInterval}
        onChange={this.handleFieldChange('scheduleInterval')}
      />
    );
  }

  render() {
    const {simple} = this.props;

    return (
      <div>
        {!simple && this.renderComplex()}
        {simple && this.renderSimple()}
      </div>
    );
  }
}
