import React from 'react';
import localStyles from 'components/decorators/localStyles';
import styles from './cog.scss';
import Icon from 'components/font-awesome';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

export class Cog extends React.Component {
  render() {
    return <Icon {...filterInvalidDOMProps(this.props)} name="cog" />;
  }
}

export default localStyles(styles)(Cog);
