import React from 'react';
import {Form, TypeField} from 'modules/form/hooks/useForm';
import InlineFields from 'modules/form/components/inlineFields';
import {reportsOptionsSelector} from 'modules/reports/reportSelectors';
import {OPTIONS as DIFFICULTIES} from 'components/difficulty';
import {OPTIONS as RESPONSIBILITIES} from 'components/responsibility';
import {OPTIONS as PRIORITIES} from 'modules/actions/components/priority';
import {useSelector} from 'hooks/redux';
import ForEachChild from 'components/forEachChild';
import Margin from 'components/margin';

const RULE_OPERATOR = [{label: 'AND', value: 'and'}, {label: 'OR', value: 'or'}];

function PolicySettingsForm({form, onSubmit}) {
  const reportsOptions = useSelector(reportsOptionsSelector);
  const fields = form.getFields();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <ForEachChild render={child => <Margin marginBottom="15px">{child}</Margin>}>
        <TypeField field={fields.name} label="Name" type="text" required />
        <TypeField field={fields.description} label="Description" type="textarea" rows="3" />

        <InlineFields>
          <TypeField
            field={fields.responsibility}
            label="Responsibility"
            type="select"
            options={RESPONSIBILITIES}
          />
          <TypeField
            field={fields.difficulty}
            label="Difficulty"
            type="select"
            options={DIFFICULTIES}
          />
          <TypeField field={fields.priority} label="Priority" type="select" options={PRIORITIES} />
          <TypeField
            field={fields.ruleOperator}
            label="Rule operation"
            type="select"
            options={RULE_OPERATOR}
          />
        </InlineFields>

        <TypeField
          field={fields.applyToAllReports}
          type="checkbox"
          description="Apply to all websites?"
        />

        {!fields.applyToAllReports.getValue() && (
          <TypeField
            field={fields.applyToReports}
            value={fields.applyToReports.getValue().map(id => id.toString())}
            onChange={value => {
              fields.applyToReports.setValue(value.map(id => id.toString()));
              fields.applyToReports.rerender();
            }}
            label="Select reports"
            type="listbox"
            options={reportsOptions.toJS()}
            isLoading={reportsOptions.isEmpty()}
            required
            disableSort
          />
        )}
      </ForEachChild>
    </Form>
  );
}

export default PolicySettingsForm;
