import React from 'react';
import Icon from 'components/font-awesome/icon';
import styles from './help.scss';

export default class DocumentsHelp extends React.Component {
  render() {
    return (
      <div className={styles.help}>
        <h3>How many PDF documents do you want to monitor?</h3>
        <p>
          Choose how many PDF documents you want to monitor. This is the total number of PDFs
          on all your websites added together.
        </p>
        <p>
          With the Accessibility module enabled, this will test your PDFs for accessibility 
          against the WCAG 2.1 or WCAG 2.0 standard.
        </p>
        <p>
          Not sure how many PDFs you need? Press the <b>Talk to us</b> button above and 
          we'll be happy to find out for you.
        </p>      
      </div>
    );
  }
}