import {createSelector} from 'reselect';
import {locationSelector} from 'modules/location/locationSelectors';
import {usernameMapSelector} from 'modules/users/userSelectors';

const decisionStateSelector = state => state.decisions;

export const decisionComponentSelector = createSelector(
  decisionStateSelector,
  locationSelector,
  (decisionState, location) => {
    return {
      query: location.query,
      params: location.params
    };
  }
);

export const accountDecisionsSettingsPageSelector = createSelector(
  usernameMapSelector,
  decisionStateSelector,
  (usernameMap, state) => {
    return {
      usernameMap,
      isLoading: state.get('isLoading'),
      isDecisionsLoading: state.get('isDecisionsLoading'),
      accountDecisions: state.get('accountDecisions'),
      accountDecisionStats: state.get('accountDecisionStats')
    };
  }
);

export const decisionSelector = createSelector(
  decisionStateSelector,
  (_, props) => props.decisionId,
  (decisionReducer, decisionId) => {
    return decisionReducer.getIn(['decisions', decisionId]);
  }
);
