import {Map, OrderedMap, fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {
  ADD_TEST,
  ADD_TEST_SUCCESS,
  ADD_TEST_FAILED,
  RECEIVE_TEST,
  RECEIVE_TEST_CONFIG
} from './testConstants';
import {RECEIVE_LAYOUT} from 'modules/layouts';

import {RECEIVE_REPORT, LEAVE_REPORT_PAGE} from 'modules/reports/reportConstants';

import {ORDER_COMPLETE} from 'modules/products';

const initialState = Map({
  data: OrderedMap()
});

// We store the tests for the current report only.
export default function testsReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_COMPLETE:
      return initialState;

    case ADD_TEST:
      return state.set('adding', true);

    case ADD_TEST_SUCCESS:
    case ADD_TEST_FAILED:
      return state.set('adding', false);

    case LEAVE_REPORT_PAGE:
      // Clear tests cache
      return initialState;

    case RECEIVE_REPORT:
      // store tests
      if (action.report.tests) {
        return receiveTests(state, action.report.tests);
      }
      break;

    case RECEIVE_TEST:
      if (action.overwrite) {
        return state.setIn(['data', action.test.testId], fromJS(action.test));
      }

      // Third param of false means we don't remove other tests when updating a single test
      return receiveTests(state, [action.test], false);

    case RECEIVE_LAYOUT:
      return updateTest(state, action.testId, 'layoutId', action.layoutId);

    case RECEIVE_TEST_CONFIG:
      return state.mergeIn(['data', action.testId], action.config);
  }

  return state;
}

function receiveTests(state, tests, removeMissing = true) {
  // Sometimes we can remove tests (e.g. by disabling) so we need to check if
  // any old keys need to be removed.
  if (removeMissing) {
    const currentKeys = [...state.get('data', Map()).keys()];
    const newKeys = tests.map(test => test.testId);
    const difference = currentKeys.filter(x => !newKeys.includes(x));
    for (let testId of difference) {
      state = state.deleteIn(['data', testId]);
    }
  }

  // we use a merge so that if we receive say a config before a test,
  // it doesn't get overwritten.
  return state.mergeDeepIn(['data'], keyBy(tests, 'testId', OrderedMap()));
}

function updateTest(state, testId, prop, value) {
  if (!state.hasIn(['data', testId])) {
    // If we don't have this test, then it may be old data. Just drop it on its head.
    return state;
  }
  return state.setIn(['data', testId, prop], fromJS(value));
}
