import React from 'react';
import GaugeChart from 'react-gauge-chart';
import styles from './gauge.scss';

class Gauge extends React.Component {
  renderLabel(value) {
    if (value < 0) return '';
    if (value < 30) return 'Very poor';
    if (value < 50) return 'Poor';
    if (value < 60) return 'Fair';
    if (value < 80) return 'Good';
    if (value < 90) return 'Great';
    if (value < 100) return 'Excellent';
    return 'Perfect';
  }

  render() {
    const {result, config} = this.props;
    const numberResult = parseInt(result, 10);

    // No value yet? Negative scores are unknown
    if (numberResult <= 0) {
      return (
        <div>
          <GaugeChart
            id={`gauge-chart-${config.componentId}`}
            nrOfLevels={20}
            arcsLength={[1]}
            colors={['#ddd']}
            arcPadding={0.0}
            arcWidth={0.3}
            cornerRadius={0}
            needleColor="transparent"
            needleBaseColor="transparent"
            hideText={true}
            percent={0}
            className={styles.gauge}
          />
          <p className={styles.footer}>
            <span className={styles.value}>Pending</span>
            <span className={styles.label}>No result yet</span>
          </p>
        </div>
      );
    }

    // GaugeChart takes a decimal to render the percentage
    const value = Number.isFinite(numberResult) ? numberResult / 100 : null;

    return (
      <div>
        <GaugeChart
          id={`gauge-chart-${config.componentId}`}
          nrOfLevels={20}
          // arcsLength={[0.3, 0.3, 0.3]}
          // colors={['#df1616', '#ebd12c', '#55d272']}
          arcsLength={[0.3, 0.3, 0.3, 0.3, 0.3]}
          colors={['#ED4B4B', '#EBA52C', '#ebd10c', '#55d272', '#12DCE5']}
          // arcsLength={[0.8, 0.2]}
          // colors={['#533be2', '#12CBB5']}
          arcPadding={0.0}
          arcWidth={0.3}
          cornerRadius={0}
          needleColor="#444"
          needleBaseColor="#444"
          hideText={true}
          percent={value}
          className={styles.gauge}
        />
        <p className={styles.footer}>
          <span className={styles.value}>{Math.round(value * 100)}%</span>
          <span className={styles.label}>{this.renderLabel(value * 100)}</span>
        </p>
      </div>
    );
  }
}

export default Gauge;
