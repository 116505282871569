import React from 'react';
import cx from 'classnames';
import Button from 'components/button';
import Dropdown from 'components/dropdown';
import {useSelector} from 'hooks/redux';
import {Field} from 'modules/form/hooks/useForm';
import {makeNewInstruction} from 'modules/interactions/utils';
import InstructionField from 'modules/interactions/components/instructionField';
import {instructionTypesSelector, instructionTypeOptionsSelector} from '../selectors';
import styles from './interactionForm.scss';
import FieldsIssue from 'modules/inspector/components/issues/fieldsIssue';

const addInstructionButton = <Button icon="plus">Add instruction</Button>;

export default function InstructionsField(props) {
  const {field, isLocked} = props;
  const instructionTypes = useSelector(instructionTypesSelector, props);
  const instructionTypeOptions = useSelector(instructionTypeOptionsSelector, props);

  const handleCreateNewInstruction = instructionTypeId => {
    const instructionType = instructionTypes.find(
      instructionType => instructionType.get('instructionTypeId') === instructionTypeId
    );

    if (instructionType) {
      const newRule = makeNewInstruction(instructionType, {});

      field.push(newRule);
      field.rerender();
    }
  };
  const handleDeleteInstruction = subfield => {
    field.removeIndex(subfield.getKey());
    field.rerender();
  };

  return (
    <React.Fragment>
      <Field field={field} required>
        {field.fields.map(subfield => {
          return (
            <InstructionField
              isLocked={isLocked}
              key={subfield.key}
              field={subfield}
              instructionTypeId={subfield.fields.instructionTypeId.getValue()}
              onDelete={handleDeleteInstruction}
            />
          );
        })}
      </Field>

      {!isLocked && (
        <Dropdown
          className={cx({[styles.empty]: !field.fields.length})}
          button={addInstructionButton}
          options={instructionTypeOptions.toJS()}
          isLoading={!instructionTypeOptions.size}
          onChange={handleCreateNewInstruction}
        />
      )}
    </React.Fragment>
  );
}
