import React, {Fragment} from 'react';
import ScoreComponent from 'components/score';
import {toDecimal} from 'utils/numberUtils';
import styles from './score.scss';
import {mobileSize} from 'constants/sizes';
import Icon from 'components/font-awesome';
import cx from 'classnames';

class Score extends React.Component {
  state = {
    isMobile: false
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const isMobile = window.innerWidth <= mobileSize;

    if (isMobile !== this.state.isMobile) {
      this.setState({isMobile});
    }
  };

  render() {
    const {result, config, isLoading, card = false} = this.props;

    const numberResult = parseInt(result, 10); // this will floor the value if it is a float (which is what we want)
    const value = Number.isFinite(numberResult) ? numberResult : -1;

    const isLarge = config.large;
    const center = config.center;

    const borderSize = isLarge ? 7 : 5;
    const className = isLarge ? '' : styles.normalScore;
    const scoreSize = this.state.isMobile ? (isLarge ? 120 : 60) : isLarge ? 120 : 70;

    // @todo Investigate and probably refactor this
    // Looks like we bizarrely add our own logic here to take over from <ScoreComponent>, when
    // half this logic is also duplicated (but differently) in there.
    if (value === -1) return <div className={styles.noScore} />;
    if (value === -2) {
      const className = cx(styles.locked, {[styles.center]: center});

      return (
        <Fragment>
          <div className={className} style={{height: `${scoreSize}px`, width: `${scoreSize}px`}}>
            <Icon name="fa fa-lock" />
          </div>
          <p className={styles.lockedLabel}>Not available</p>
        </Fragment>
      );
    }

    return (
      <div className={cx(className, card ? styles.normalScoreCard : null)}>
        <ScoreComponent
          size={scoreSize}
          center={center}
          borderSize={borderSize}
          value={value}
          isLoading={isLoading}
          animate
        />
        {config.label}
      </div>
    );
  }
}

Score.handlesLoading = true;

export default Score;
