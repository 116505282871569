import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import UserImage from 'components/user/userImage';
import styles from './userPermissionSummary.scss';
import DeleteIcon from 'components/icons/deleteIcon';
import {Form, Field} from 'modules/form/components';
import LinkButton from 'modules/location/linkButton';
import {digestsSettingsPath} from 'modules/reports/reportUtils';
import {createStructuredSelector} from 'reselect';
import {reportSelector} from 'modules/reports/reportSelectors';

class UserPermissionSummary extends React.Component {
  removeUser = () => {
    const {user, permissionId} = this.props;
    this.props.onRemoveUser(user.userId, permissionId);
  };

  editUserRole = role => {
    const {user, permissionId} = this.props;
    this.props.onEditUserRole(user.userId, permissionId, role);
  };

  render() {
    const {user, isCurrentUser, role, reportId, report} = this.props;
    if (!user) return null;

    return (
      <Form className={styles.summaryWrapper} defaultValue={{role}} name={`user${user.userId}`}>
        <div className={styles.summaryImage}>
          <UserImage className={styles.userImage} user={user} size={50} />
        </div>
        <div className={styles.summaryName}>{user.name}</div>
        <div className={styles.summaryRole}>
          <Field
            type="role"
            name="role"
            useReportRoles
            onChange={(role, isInit) => {
              if (!isInit) this.editUserRole(role);
            }}
            disabled={isCurrentUser}
          />
        </div>
        {report.getIn(['presentationOptions', 'canUseDigests']) && (
          <div className={styles.summaryButton}>
            <LinkButton builder={digestsSettingsPath} reportId={reportId} userId={user.userId}>
              Digests
            </LinkButton>
          </div>
        )}
        <div className={styles.summaryDelete}>
          <DeleteIcon onClick={this.removeUser} />
        </div>
      </Form>
    );
  }
}

UserPermissionSummary.propTypes = {
  user: PropTypes.object
};

export default connect(createStructuredSelector({report: reportSelector}))(UserPermissionSummary);
