import styles from './banner.scss';
import React from 'react';

export default class Banner extends React.Component {
  render() {
    const {children} = this.props;

    return (
      <div className={styles.banner}>
        <div className={styles.bannerInner}>{children}</div>
      </div>
    );
  }
}
