import {Map, List, fromJS} from 'immutable';
import {SWITCH_ACCOUNT} from 'modules/auth';

const initialState = Map({
  data: [],
  isLoading: false,
  error: null
});

export default function entitlementsReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ENTITLEMENTS:
      return setLoadingState(state, true, null).set('data', List());
    case RECEIVE_ENTITLEMENTS:
      return setLoadingState(state, false, null).set('data', fromJS(action.entitlements));
    case ENTITLEMENTS_ERROR:
      return setLoadingState(state, false, action.error);
    case SWITCH_ACCOUNT:
      return initialState;
  }

  return state;
}

function setLoadingState(state, loading, error) {
  return state.set('isLoading', loading).set('error', error);
}

export const REQUEST_ENTITLEMENTS = 'entitlements:REQUEST_ENTITLEMENTS';
export function requestEntitlements() {
  return {
    type: REQUEST_ENTITLEMENTS
  };
}

export const RECEIVE_ENTITLEMENTS = 'entitlements:RECEIVE_ENTITLEMENTS';
export function receiveEntitlements(entitlements) {
  return {
    type: RECEIVE_ENTITLEMENTS,
    entitlements
  };
}

export const ENTITLEMENTS_ERROR = 'entitlements:ENTITLEMENTS_ERROR';
export function entitlementsError(response) {
  return {
    type: ENTITLEMENTS_ERROR,
    error: response.error
  };
}
