exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._LrSh1sb-KJnXfj7_0nFY{padding:0 !important;background:#fbfbfb;width:100%}@media(max-width: 500px){._LrSh1sb-KJnXfj7_0nFY{display:block}}._LrSh1sb-KJnXfj7_0nFY>*{padding:30px;border-right:1px solid #d6d6d6;height:100%}@media(max-width: 500px){._LrSh1sb-KJnXfj7_0nFY>*{height:auto;width:100%}._LrSh1sb-KJnXfj7_0nFY>*:first-child{padding-bottom:0}._LrSh1sb-KJnXfj7_0nFY>*:last-child{padding-top:0}}._LrSh1sb-KJnXfj7_0nFY>*:last-child{border-right:none}._LrSh1sb-KJnXfj7_0nFY ul{list-style:none;padding-left:0}._LrSh1sb-KJnXfj7_0nFY ul li{padding-left:50px;margin-bottom:25px;line-height:2.5}._LrSh1sb-KJnXfj7_0nFY ul ._3Y22c-IqwyBkV4oA7ZtYLq{margin-right:20px;margin-left:-50px;color:#15b272;font-size:2.4em;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"planFeatures": "_LrSh1sb-KJnXfj7_0nFY",
	"icon": "_3Y22c-IqwyBkV4oA7ZtYLq"
};