export function getBlobAsBase64(url) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = reject;
    fileReader.onloadend = event => {
      // dataUrl is in the format "data: image/png;base64,{base64}"
      // we only want the base64 component of that string.
      const dataUrl = event.target.result;
      const [metadata, data] = dataUrl.split(';', 2);
      const base64 = /^base64,/.test(data) ? data.substr(7) : null;

      resolve(base64);
    };

    fileReader.readAsDataURL(url);
  });
}
