import React, {useState} from 'react';
import styles from '../timeline.scss';
import ShowMoreButton from '../showMoreButton';
import PagePointItem from '../storyPoints/pagePointItem';

export default function NewPage({story, storyPoints, doCommand}) {
  // const [items, setItems] = useState(Object.keys(storyPoints));

  return (
    <React.Fragment>
      <ul className={styles.pagesList}>
        {Object.keys(storyPoints).map(pointId => (
          <PagePointItem key={pointId} {...storyPoints[pointId]} doCommand={doCommand} />
        ))}
      </ul>
    </React.Fragment>
    // <ShowMoreButton items={Object.keys(storyPoints)} setItems={item => setItems(item)} />
  );
}
