import {basePath} from 'modules/auth/authUtils';

export function reportLogPath() {
  return basePath(...arguments) + '/settings/account/report-log';
}

// api endpoints

export function reportLogsEndpoint() {
  return 'report-logs';
}

export function reportLogEndpoint({missionId}) {
  return reportLogsEndpoint(...arguments) + `/${missionId}`;
}
