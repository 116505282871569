exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2jNRe_eYm2Y1tlHdB4ZBt_{margin-top:100px}._1fGRNmEGGZs_SpoKMhoX17{padding:16px}._1CROfxqt8nbS7tycINc86l{margin-bottom:1em}._1OiVZmaCm6EVllKkj0mJnr{margin-top:30px}", ""]);

// exports
exports.locals = {
	"spinner": "_2jNRe_eYm2Y1tlHdB4ZBt_",
	"content": "_1fGRNmEGGZs_SpoKMhoX17",
	"reportsTable": "_1CROfxqt8nbS7tycINc86l",
	"editFolderView": "_1OiVZmaCm6EVllKkj0mJnr"
};