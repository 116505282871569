import {takeEvery} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {TRIGGER_BROKEN_LINK_RETEST} from 'modules/checks';
import {retestBrokenLinkEndpoint} from 'modules/checks/checkUtils';
import {showError, showSuccess} from 'utils/saga/alerts';
import {couldNot} from 'utils/errorUtils';

export default function* triggerRetestBrokenLinksSaga() {
  yield takeEvery(TRIGGER_BROKEN_LINK_RETEST, function*(action) {
    const {reportId, linkHash} = action;

    try {
      yield processRequest('POST', retestBrokenLinkEndpoint(reportId, linkHash), {});
      yield showSuccess('Link marked for retest. The link will be checked shortly.');
    } catch (e) {
      if (e && e.exception === 'BrokenLinkAlreadyRunningException') {
        yield showError(e.message);
      } else {
        yield showError(couldNot('retest this broken link right now'));
      }
      return;
    }
  });
}
