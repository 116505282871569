import React, {useContext} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {homePath} from 'modules/app/appUtils';
import {folderPath} from 'modules/folders/folderUtils';
import {leaderboardsPath} from 'modules/leaderboards/utils';
import {searchPath} from 'modules/searchPage/utils';
import {coursesPath} from 'modules/courses/utils';
import SettingsDropdown from 'components/nav/settingsDropdown';
import {mainNavigationSelector} from 'modules/app/appSelectors';
import NotificationsDropdown from 'modules/notifications/components/notificationsDropdown';
import MainNavigationLink from './mainNavigationLink';
import UL from './ul';
import {openHelp} from 'modules/help/actions';
import SilktideIcon from './icons/nav_icon_silktide-logo.svg';
import HomeIcon from './icons/nav_icon_home.svg';
import SearchIcon from './icons/nav_icon_search.svg';
import ProfileIcon from './icons/nav_icon_account.svg';
import FoldersIcon from './icons/nav_icon_folders.svg';
import styles from './mainNavigation.scss';
import {ScreenSizeContext} from 'providers/screenSizeProvider';

function MainNavigation({
  params,
  user,
  pathname,
  currentReportId,
  hasLeaderboards,
  hasAcademy,
  isAccountDisabled,
  helpUrl = 'https://monitor.support.silktide.com/',
  openHelp,
  notificationInfo,
  ...props
}) {
  const {tablet, largeMobile} = useContext(ScreenSizeContext);
  const isHomePage = /^\/\d+\/home\/?/.test(pathname);
  const isFoldersPage = /^\/\d+\/folders\/?/.test(pathname);
  const isLeaderboardsPage = /^\/\d+\/leaderboards\/?/.test(pathname);
  const isSearchPage = /^\/\d+\/search\/?/.test(pathname);
  const isCoursesPage = /^\/\d+\/academy\/?/.test(pathname);

  if (isAccountDisabled) {
    return (
      <nav className={styles.wrapper}>
        <UL>
          <MainNavigationLink iconSrc={SilktideIcon} iconAlt="Silktide" logo />
        </UL>
        <UL>
          <MainNavigationLink>
            {!user && <img src={ProfileIcon} alt="User" />}
            {!!user && <SettingsDropdown params={params} reportId={currentReportId} user={user} />}
          </MainNavigationLink>
        </UL>
      </nav>
    );
  }

  return (
    <nav className={cx(styles.wrapper, {[styles.horizontal]: largeMobile})}>
      <UL>
        <MainNavigationLink
          href={homePath(params)}
          iconSrc={SilktideIcon}
          iconAlt="Silktide"
          logo
        />
        <MainNavigationLink
          href={homePath(params)}
          iconSrc={HomeIcon}
          iconAlt="Home"
          active={isHomePage}
          tooltip="Home"
        />
        {hasLeaderboards ? (
          <MainNavigationLink
            icon="trophy"
            iconAlt="Leaderboards"
            href={leaderboardsPath(params)}
            active={isLeaderboardsPage}
            tooltip="Leaderboards"
          />
        ) : (
          <MainNavigationLink
            href={folderPath(params)}
            iconSrc={FoldersIcon}
            iconAlt="Folders"
            active={isFoldersPage}
            tooltip="Folders"
          />
        )}
        {hasAcademy && (
          <MainNavigationLink
            href={coursesPath(params)}
            icon="graduation-cap"
            iconAlt="Silktide Academy"
            active={isCoursesPage}
            tooltip="Silktide Academy"
          />
        )}
        <MainNavigationLink
          href={searchPath(params)}
          iconSrc={SearchIcon}
          iconAlt="Search"
          active={isSearchPage}
          tooltip="Search"
        />
      </UL>

      <UL>
        {helpUrl && (
          <MainNavigationLink
            small
            external
            href={helpUrl}
            icon="question-circle"
            iconAlt="Help"
            tooltip="Help"
          />
        )}
        {!tablet && !!notificationInfo.count && (
          <MainNavigationLink small>
            <NotificationsDropdown />
          </MainNavigationLink>
        )}
        <MainNavigationLink>
          {!user && <img src={ProfileIcon} alt="User" />}
          {!!user && <SettingsDropdown params={params} reportId={currentReportId} user={user} />}
        </MainNavigationLink>
      </UL>
    </nav>
  );
}

export default connect(
  mainNavigationSelector,
  {openHelp}
)(MainNavigation);
