import React from 'react';
import {injectStripe, CardElement} from 'react-stripe-elements';

const styles = {
  base: {
    fontSize: '16px',
    color: '#495057',
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    '::placeholder': {
      color: '#868e96'
    }
  },
  invalid: {
    color: '#9e2146'
  }
};

export default injectStripe(props => <CardElement style={styles} hidePostalCode />);
