import React from 'react';
import {Map} from 'immutable';
import {connect} from 'react-redux';
import {cancelMission} from 'modules/missions';
import Flex from 'components/layout/flex';
import Tooltip from 'components/tooltip';
import Progress from 'components/progress';
import UserImage from 'components/user/userImage';
import DeleteIcon from 'components/icons/deleteIcon';
import styles from './missionStatus.scss';
import Icon from 'components/font-awesome';

class MissionStatus extends React.Component {
  static defaultProps = {
    user: Map()
  };

  handleClickDelete = () => {
    this.props.cancelMission(this.props.missionId);
  };

  renderUserImage() {
    const {user} = this.props;

    if (!user) {
      return null;
    }

    return (
      <Tooltip text={`Started by ${user.get('name')}`}>
        <UserImage user={user.toJS()} className={styles.profileImg} />
      </Tooltip>
    );
  }

  render() {
    const {
      name,
      progressDone,
      progressTotal,
      missionId,
      displayAsPercentage,
      isDeleting
    } = this.props;

    return (
      <Flex container className={styles.missionStatus}>
        <Flex grow={1} className={styles.label}>
          <span>{name}</span>
        </Flex>

        <Flex>{this.renderUserImage()}</Flex>

        <Flex grow={3}>
          <Progress
            value={progressDone}
            denominator={progressTotal}
            displayAsPercentage={displayAsPercentage}
            appearance="good"
          />
        </Flex>

        <Flex end className={styles.deleteIcon}>
          {isDeleting ? (
            <Icon spin name="spinner" />
          ) : (
            <DeleteIcon onClick={this.handleClickDelete} />
          )}
        </Flex>
      </Flex>
    );
  }
}

export {MissionStatus};

// we use this syntax and not @connect because of storybook
export default connect(
  null,
  {cancelMission}
)(MissionStatus);
