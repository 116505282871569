import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/font-awesome';
import _ from 'lodash';
import cx from 'classnames';
import styles from './button.scss';
import CircularSpinner from 'components/spinner';
import localStyles from 'components/decorators/localStyles';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import Tooltip from 'components/tooltip';

export class Button extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    iconSide: PropTypes.string
  };

  static defaultProps = {
    iconSide: 'left'
  };

  renderIcon() {
    const {icon, iconOnly, children, isLoading, iconSide, iconClass, iconSize} = this.props;
    if (!icon) return null;

    const classes = cx(styles.iconEl, iconClass, {
      [styles.loneIcon]: iconOnly || !children || !children.length,
      'animated spin': isLoading,
      [styles.iconRight]: iconSide === 'right'
    });

    return (
      <span className={classes}>
        <Icon name={icon} size={iconSize} />
      </span>
    );
  }

  handleClick = (e, ...args) => {
    const {onClick, isLoading} = this.props;
    !isLoading && onClick && onClick(e, ...args);
  };

  render() {
    const {
      children,
      disabled,
      icon,
      iconOnly,
      iconSide,
      isLoading,
      className,
      tooltip,
      square,
      wire,
      ...props
    } = this.props;

    const button = (
      <button
        type="button"
        className={cx(styles.base, styles[iconSide], className, {
          [styles.disabled]: disabled,
          [styles.square]: square
        })}
        {...filterInvalidDOMProps(props)}
        disabled={disabled || isLoading}
        onClick={this.handleClick}
      >
        {iconSide === 'left' && this.renderIcon()}

        {!iconOnly && children}

        {iconSide === 'right' && this.renderIcon()}

        {isLoading && !icon && <CircularSpinner light={!wire} small relative />}
      </button>
    );

    if (tooltip) {
      return <Tooltip text={tooltip}>{button}</Tooltip>;
    }

    return button;
  }
}

export default localStyles(styles)(Button);
