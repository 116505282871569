import {accountEndpoint} from 'modules/accounts/accountUtils';
import {toJS} from 'utils/immutableUtils';

export function accountOptionsEndpoint({accountId}) {
  return accountEndpoint(...arguments) + `/options`;
}

export function accountOptionEndpoint({accountId, optionId}) {
  return accountOptionsEndpoint(...arguments) + `/${optionId}`;
}

export function uploadBrandingLogoEndpoint({accountId}) {
  return accountEndpoint(...arguments) + `/branding-logo`;
}

export function toKeyValueObject(options) {
  options = toJS(options);

  const optionsObj = {};
  for (let option in options) {
    if (options.hasOwnProperty(option)) {
      optionsObj[option] = options[option].value;
    }
  }
  return optionsObj;
}
