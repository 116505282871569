import React from 'react';
import PaginationComponent from 'components/pagination';

class Pagination extends React.Component {
  selectPage = value => {
    const {
      setFilterParams,
      page,
      config: {filter}
    } = this.props;

    if (page === value) {
      return;
    }

    setFilterParams({
      [filter]: value
    });
  };

  render() {
    const {filter, setFilterParams, result, config} = this.props;

    if (!result) {
      return null;
    }

    const {maxPage, numRows, page, rowsPerPage} = result;

    if (maxPage <= 1) {
      return null;
    }

    return (
      <div>
        <PaginationComponent
          page={page}
          numRows={numRows}
          rowsPerPage={rowsPerPage}
          maxPage={maxPage}
          onPageSelect={this.selectPage}
        />
      </div>
    );
  }
}

Pagination.handlesLoading = true;
export default Pagination;
