import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import BingIcon from 'components/searchEngine/bing-icon.png';
import GoogleIcon from 'components/searchEngine/google-icon.png';

class SearchEngine extends React.Component {
  renderIcon(data) {
    switch (data) {
      case 'google':
        return <img src={GoogleIcon} alt="Google" />;
      case 'bing':
        return <img src={BingIcon} alt="Bing" />;
      default:
        return data;
    }
  }

  render() {
    const {data, options, className} = this.props;
    if (!data) return <span />;

    const classNames = cx('cell-data wordBreak', className || '', {
      [styles[options.class]]: true,
      [styles.noText]: true
    });

    return <div className={classNames}>{this.renderIcon(data)}</div>;
  }
}

SearchEngine.propTypes = {
  data: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default SearchEngine;
