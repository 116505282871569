import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'components/helmet/helmet';
import Loading from 'components/spinner/loading';
import PageBody from 'components/page';
import PageHeader from 'components/header/pageHeader';
import Table from 'components/table/table';
import TableWarning from 'components/table/tableWarning';
import TransitionGroup from 'components/transitionGroup';
import BuyButton from 'modules/onboarding/components/buyButton';
import {subscriptionStateSelector} from 'modules/subscriptions/subscriptionSelectors';
import {requestSubscriptions, requestDeleteSubscription} from 'modules/subscriptions';
import Money from 'components/money';
import Pill from 'components/pill';
import {capitalizeFirstLetter} from 'utils/stringUtils';
import FormattedDate from 'components/time/formattedDate';
import Button from 'components/button';
import styles from './subscriptionsPage.scss';

const pillColourMap = {
  trialing: 'warning',
  active: 'good',
  past_due: 'warning',
  canceled: 'bad',
  unpaid: 'bad'
};

export class SubscriptionsPage extends React.Component {
  componentWillMount() {
    this.tableColumns = [
      {
        label: 'Plan',
        field: 'planName',
        type: 'jsx',
        jsx: this.renderPlanName
      },
      {
        label: 'Status',
        field: 'status',
        width: '110px',
        type: 'jsx',
        collapse: '500',
        combineWith: 'Plan',
        jsx: this.renderStatus
      },
      {
        label: 'Start/end date',
        field: 'status',
        width: '140px',
        type: 'jsx',
        jsx: this.renderDates
      },
      {
        field: 'subscriptionId',
        width: '110px',
        type: 'jsx',
        collapse: '400',
        combineWith: 'Start/end date',
        jsx: this.renderActions
      }
    ];
  }

  componentDidMount() {
    const {subscriptions, requestSubscriptions} = this.props;

    if (!subscriptions || !subscriptions.size) {
      requestSubscriptions();
    }
  }

  handleClickCancel = subscription => event => {
    this.props.requestDeleteSubscription(subscription);
  };

  renderPlanName = ({data, record}) => (
    <div className={styles.planName}>
      <h4>{data}</h4>
      {record.currency && (
        <span>
          <Money value={record.amount} currency={record.currency} /> / {record.interval}
        </span>
      )}
    </div>
  );

  renderStatus = ({data, record}) => {
    if (record.cancelAtPeriodEnd) {
      data = 'canceled';
    }
    return (
      <Pill appearance={pillColourMap[data]}>{capitalizeFirstLetter(data.replace('_', ' '))}</Pill>
    );
  };

  renderActions = ({record}) => {
    if (record.cancelAtPeriodEnd || record.status == 'canceled') {
      return <div />;
    }
    return (
      <Button
        icon="times"
        minor
        small
        className={styles.cancelButton}
        onClick={this.handleClickCancel(record)}
      >
        Cancel
      </Button>
    );
  };

  renderDates = ({data, record}) => {
    const endDate = record.cancelAtPeriodEnd ? record.currentPeriodEnd : record.endedAt;

    return (
      <div>
        <FormattedDate timestamp={record.start} format="Do MMM YYYY" />

        {!!endDate && ' - '}
        {!!endDate && <FormattedDate timestamp={endDate} format="Do MMM YYYY" />}
      </div>
    );
  };

  renderSubscriptions() {
    const {subscriptions, loading, error} = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <TableWarning message="We're having trouble loading your subscription history. Please try again later or contact us." />
      );
    }

    if (!subscriptions.size) {
      return <TableWarning message="You do not have any subscriptions." />;
    }

    // TODO: Add pagination (this currently only shows the last 12 months)
    return (
      <Table columns={this.tableColumns} rows={subscriptions.toJS()} rowKey="subscriptionId" />
    );
  }

  render() {
    const title = 'Subscriptions';

    return (
      <div>
        <Helmet title={title} />

        <PageHeader
          title={title}
          inline={[<BuyButton key="buy_button">Buy new subscription</BuyButton>]}
        />

        <PageBody>
          <TransitionGroup
            transitionName="fade"
            component="div"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            <p className={styles.lead}>Here you can manage your active subscriptions.</p>
            {this.renderSubscriptions()}
          </TransitionGroup>
        </PageBody>
      </div>
    );
  }
}

export default connect(
  subscriptionStateSelector,
  {
    requestSubscriptions,
    requestDeleteSubscription
  }
)(SubscriptionsPage);
