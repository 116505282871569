exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rJ0O_k5xHlVjqDjPBCD-1{height:auto;width:100%}", ""]);

// exports
exports.locals = {
	"image": "_2rJ0O_k5xHlVjqDjPBCD-1"
};