exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yuoOUwBo2WnMam0n1npD9{position:fixed;top:0;left:0;width:100%;padding:15px;background-color:#333;box-shadow:0 0 20px rgba(0,0,0,.3);z-index:1;display:flex;align-items:center}._3yuoOUwBo2WnMam0n1npD9 ._3bIK-Y95HaGGiYb9Afwq27{flex:1 0 auto;text-align:left}._3yuoOUwBo2WnMam0n1npD9 ._3bIK-Y95HaGGiYb9Afwq27 h1{font-size:22px;color:#fff;margin:0}._3yuoOUwBo2WnMam0n1npD9 ._3bIK-Y95HaGGiYb9Afwq27 p{color:#ddd;margin:0}._3yuoOUwBo2WnMam0n1npD9 ._35BrBEvyq8b_fyHZX09n0l{flex:1 1 auto;text-align:center}._3yuoOUwBo2WnMam0n1npD9 ._1DdJUZTXAB-t9e42OM4kfD{flex:1 0 auto;text-align:right}@media print{._3yuoOUwBo2WnMam0n1npD9{display:none}}", ""]);

// exports
exports.locals = {
	"wrapper": "_3yuoOUwBo2WnMam0n1npD9",
	"title": "_3bIK-Y95HaGGiYb9Afwq27",
	"approvalActions": "_35BrBEvyq8b_fyHZX09n0l",
	"otherActions": "_1DdJUZTXAB-t9e42OM4kfD"
};