import {delay} from 'redux-saga';
import {call, race, takeLatest, take} from 'redux-saga/effects';
import {VIEW_HOME_PAGE, LEAVE_HOME_PAGE, receiveHomePage} from 'modules/homePage';
import {processRequest} from 'utils/saga/fetchUtils';
import {foldersEndpoint} from 'modules/folders/folderUtils';
import {receiveFolders} from 'modules/folders';
import {DISABLE_BACKGROUND_POLL} from 'config';

export default function* viewHomePageSaga() {
  yield takeLatest(VIEW_HOME_PAGE, loadHomePageData);
}

function* loadHomePageData() {
  // fetch folders (only need to do this once)
  yield processRequest('GET', foldersEndpoint(), {
    successAction: receiveFolders
  });

  while (true) {
    // request results
    yield processRequest('GET', 'homepage', {
      successAction: receiveHomePage
    });

    if (DISABLE_BACKGROUND_POLL) {
      return;
    }

    // wait for 20 seconds or LEAVE_HOME_PAGE
    const {leaveHomePage, timeout} = yield race({
      leaveHomePage: take(LEAVE_HOME_PAGE),
      timeout: call(delay, 20 * 1000)
    });

    // if leave home page, do not request
    if (leaveHomePage) return;
  }
}
