import cx from 'classnames';
import _ from 'lodash';
import {doInstruction} from 'modules/instructions';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import styles from './styles.scss';

class Cell extends React.Component {
  handleClick = event => {
    const {
      options,
      record,
      filters,
      match: {params}
    } = this.props;
    const customClasses = options.class ? options.class.split(' ') : [];

    if (options.instruction && customClasses.includes('link')) {
      this.props.doInstruction(options.instruction, {...params, ...record}, filters, event);
    }
  };

  render() {
    const {data, options, className} = this.props;
    if (!data) return null;

    const customClasses = options.class ? options.class.split(' ') : [];
    const customClassesMapped = customClasses.map(c => styles[c]);

    const classNames = cx('cell-data wordBreak', className, customClassesMapped);

    // NOTE When displaying "languages" we sometimes get an object instead of an array.
    //      This is in prod so needs handling
    const renderData =
      _.isPlainObject(data) && !React.isValidElement(data) ? Object.values(data) : data;
    const outputData = Array.isArray(renderData) ? renderData.join(', ') : renderData;

    return (
      <div className={classNames} onClick={this.handleClick}>
        {outputData}
      </div>
    );
  }
}

Cell.propTypes = {
  data: PropTypes.any,
  options: PropTypes.object
};

export default withRouter(
  connect(
    null,
    {doInstruction}
  )(Cell)
);
