exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2B6cLydfGcpGLrp5MWMcjb{color:#777}._3jWrZxl4bVOxbu3SN633L1{padding:0 15px 5px 50px}._2IpgQC4d5v1QQypP8a1Lt p{margin:0}._2IpgQC4d5v1QQypP8a1Lt h3{margin:15px 0 15px;font-size:140%}._1aByRDIpZJrdmAHgFSX0-C{height:auto;opacity:1}", ""]);

// exports
exports.locals = {
	"explanation": "_2B6cLydfGcpGLrp5MWMcjb",
	"content": "_3jWrZxl4bVOxbu3SN633L1",
	"section": "_2IpgQC4d5v1QQypP8a1Lt",
	"expanded": "_1aByRDIpZJrdmAHgFSX0-C"
};