import React from 'react';
import cx from 'classnames';
import styles from './faviconIssue.scss';
import AnimateOnChange from 'components/animation/animateOnChange';
import {interpolateToJSX} from 'utils/interpolationUtils';
import MediaComponent from 'components/media';
import ExternalLink from 'modules/location/externalLink';

class FaviconIssue extends React.Component {
  shouldAnimate = (nextProps, currentProps) => {
    return nextProps.isOpen && !currentProps.isOpen;
  };

  render() {
    const {issueData, isOpen} = this.props;

    const src = issueData.get('src');
    const srcType = issueData.get('srcType');

    const classes = cx(styles.wrapper, {
      [styles.open]: isOpen
    });

    return (
      <div className={classes}>
        <div>
          <AnimateOnChange
            isOpen={isOpen}
            comparator={this.shouldAnimate}
            animationClassName="animated pulse"
          >
            <MediaComponent src={src} srcType={srcType} />
          </AnimateOnChange>
          <p>
            <ExternalLink href={src}>{src}</ExternalLink>
          </p>
        </div>
      </div>
    );
  }
}

export default FaviconIssue;
