import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {addMessage} from 'modules/alerts';
import {resetToken, resetPassword} from 'modules/passwords';
import {userResetPasswordSelectors} from 'modules/users/userSelectors';
import Spinner from 'components/spinner';
import ResetPasswordPage from '../components/resetPasswordPage';
import TokenExpiredPage from '../components/tokenExpiredPage';

export class ResetPasswordContainer extends React.Component {
  handleSendResetToken = () => {
    const {resetToken, userId} = this.props;

    resetToken(userId);
  };

  componentWillReceiveProps({success}) {
    if (success) {
      const {push, addMessage} = this.props;

      push('/');
      addMessage('success', 'Password has been reset. Please login to continue.');
    }
  }

  handleSetPassword = data => {
    const {password} = data;
    const {token, resetPassword, userId} = this.props;

    resetPassword(userId, token, password);
  };

  render() {
    const {requestingToken, tokenExpired} = this.props;

    if (requestingToken) {
      return <Spinner centered />;
    }

    return (
      <div>
        {tokenExpired && (
          <TokenExpiredPage {...this.props} onClickResetToken={this.handleSendResetToken} />
        )}
        {!tokenExpired && (
          <ResetPasswordPage {...this.props} onSetNewPassword={this.handleSetPassword} />
        )}
      </div>
    );
  }
}

export default connect(
  userResetPasswordSelectors,
  {
    resetToken,
    push,
    addMessage,
    resetPassword
  }
)(ResetPasswordContainer);
