import React from 'react';
import {select, takeLatest} from 'redux-saga/effects';
import {REQUEST_PRODUCTS} from 'modules/products';
import fetchPlans from 'modules/products/sagas/helpers/fetchPlans';
import {onboardingDataSelector} from 'modules/onboarding/onboardingSelectors';
import {accountSelector} from 'modules/auth/authSelectors';
import {toJS} from 'utils/immutableUtils';

export default function* paySaga() {
  yield takeLatest(REQUEST_PRODUCTS, requestPlans);
}

function* requestPlans(action) {
  let {account} = yield select(onboardingDataSelector);
  // If we skipped the account step because we have an existing account, get
  // the existing account data instead
  if (!account) {
    account = toJS(yield select(accountSelector));
  }

  const {addressCountry, vatNumber} = account || {};

  yield fetchPlans(addressCountry, vatNumber);
}
