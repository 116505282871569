exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".n1JGdSLkqRwHVnYdQXhGs{margin-bottom:0}._3OdBE5NB850SEdsrrzbQjn{margin:10px;word-wrap:break-word}._21pOtSBYfa7MJ0teA3Rb2T{display:inline-block;padding:2px 6px;border:1px solid #533be2;border-radius:5px;color:#533be2}", ""]);

// exports
exports.locals = {
	"text": "n1JGdSLkqRwHVnYdQXhGs",
	"link": "_3OdBE5NB850SEdsrrzbQjn",
	"annotation": "_21pOtSBYfa7MJ0teA3Rb2T"
};