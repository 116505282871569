import {takeLatest, call, put} from 'redux-saga/effects';
import {fetchResultsByIds} from 'modules/tests/sagas/fetchLayoutAndResults';
import {receiveReportSegments} from 'modules/segments/segmentActions';
import {REQUEST_REPORT_SEGMENTS} from 'modules/segments/segmentConstants';

export default function* requestReportSegmentsSaga() {
  yield takeLatest([REQUEST_REPORT_SEGMENTS], function*({reportId, testId}) {
    const segments = yield call(getSegments, reportId, testId);

    if (segments) {
      yield put(receiveReportSegments(reportId, testId, segments));
    }
  });
}

function* getSegments(reportId, testId) {
  const resultId = testId + '.SegmentsSelector';
  const results = yield call(fetchResultsByIds, reportId, [resultId]);
  const result = results.filter(result => result.resultId === resultId);
  return result[0] ? result[0].value : null;
}
