import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {renderChildren} from './gandalf';
import {reportGandalfSelector} from 'modules/gandalf/gandalfSelectors';
import {reportIsAllowed} from 'modules/gandalf/gandalfUtils';

/*
 * The idea with this is that any roles with a higher value than the
 * specified role will be allowed in.
 *
 * I.e. if shallPass='contributor', manager will also be allowed to 'pass'
 * But viewer SHALL NOT PASS
 */
export class ReportGandalf extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired
  };

  render() {
    const {shallPass, report, children, className, isVirtual} = this.props;

    if (isVirtual) {
      return renderChildren(children, className);
    }

    if (!report || (report.isEmpty && report.isEmpty())) return null;

    const role = report.getIn(['ownPermissions', 'role']);

    if (reportIsAllowed(shallPass, role)) {
      return renderChildren(children, className);
    }

    return null;
  }
}

export default connect(reportGandalfSelector)(ReportGandalf);
