import _ from 'lodash';
import {compose} from 'redux';
import cx from 'classnames';
import {Map} from 'immutable';
import React from 'react';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import {DragDropContext} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {Route, Switch, Redirect} from 'react-router-dom';
import KeyCodes from 'constants/keyCodes';
import Idle from 'components/idle';
import Helmet from 'components/helmet';
import PageReloadListener from 'components/pageReload';
import PageWithFixed from 'components/wrappers/pageWithFixed';
import {replace} from 'modules/location';
import NPS from 'modules/nps/components/nps';
import HelpSidebar from 'modules/help/containers/helpSidebar';
import {setIdle, setNPSEnable} from 'modules/nps';
import NotFoundPage from 'modules/location/pages/404Page';
import HomePage from 'modules/homePage/pages/homePage';
import SearchPage from 'modules/searchPage/pages/searchPage';
import {homePath, toolSelectionPath, connectContext} from 'modules/app/appUtils';
import MainNavigation from 'modules/app/components/mainNavigation/mainNavigation';
import {fetchAccountTypes} from 'modules/accountTypes';
import ReportsRoute from 'modules/reports/routes/reportsRoute';
import FoldersRoute from 'modules/folders/routes/foldersRoute';
import LeaderboardRoute from 'modules/leaderboards/routes/leaderboardRoute';
import EditLeaderboardRoute from 'modules/leaderboards/routes/editLeaderboardRoute';
import ProfileRoute from 'modules/profile/routes/profileRoute';
import CoursesRoute from 'modules/courses/routes/coursesRoute';
import SettingsRoute from 'modules/settings/routes/settingsRoute';
import NotificationsRoute from 'modules/notifications/routes/notificationsRoute';
import {loggedInRouteSelector} from 'modules/auth/authSelectors';
import {isSuperUser, shallPass, SUPER_USER} from 'modules/auth/gandalf';
import UserAchievement from 'modules/achievements/components/userAchievement';
import SpotlightContainer from 'modules/spotlight/components/spotlightContainer';
import {showSpotlight, hideSpotlight, clearResults} from 'modules/spotlight';
import styles from './loggedInRoute.scss';
import {parse} from 'query-string';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import AccountDisabled from '../components/accountDisabled';
import {isAccountDisabledSelector} from 'modules/accounts/accountDisabledSelector';
import {requestSubscriptions} from 'modules/subscriptions';
import {fatalErrorSelector} from '../appSelectors';
import {Modal, ModalContent} from 'modules/modal';

export class LoggedInRoute extends React.Component {
  componentWillMount() {
    const {
      user,
      location: {search}
    } = this.props;
    const searchParams = parse(search);

    this.props.requestSubscriptions();

    // If you have came from the main silktide.com website and were already logged in, it will re-ask your tool
    // Only if you have multiple of them.
    if (searchParams.check_product && user.hasProspect) {
      this.props.replace(toolSelectionPath, {accountId: user.accountId});
      return;
    }

    this.redirectIfRequired();

    if (shallPass(user, SUPER_USER)) {
      this.props.fetchAccountTypes();
      window.addEventListener('keyup', this.handleKeypress);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.redirectIfRequired();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeypress);
  }

  handleKeypress = _.throttle(event => {
    if (event.which == KeyCodes.ESCAPE) {
      this.props.hideSpotlight();
      this.props.clearResults();
    }
    if (event.which == KeyCodes.F && event.altKey) {
      this.props.showSpotlight();
    }
    // if (event.which == KeyCodes.N && event.altKey) {
    // this.props.setNPSEnable(true);
    // }
  }, 200);

  handleIdleChange = isIdle => {
    this.props.setIdle(isIdle);
  };

  redirectIfRequired() {
    const {
      user,
      match: {params},
      location: {pathname},
      replace
    } = this.props;

    if (!params.accountId) {
      replace('/' + user.accountId + '/home');
      return;
    }

    if (
      pathname === '/' ||
      pathname === '/' + params.accountId ||
      pathname === '/' + params.accountId + '/'
    ) {
      replace(homePath({accountId: user.accountId}));
    }
  }

  shouldComponentUpdate(nextProps) {
    const thisLocation = this.props.location;
    const nextLocation = nextProps.location;

    return (
      thisLocation.pathname != nextLocation.pathname ||
      thisLocation.search != nextLocation.search ||
      !Map(this.props.user).equals(Map(nextProps.user)) ||
      !Map(this.props.screenSizes).equals(Map(nextProps.screenSizes)) ||
      this.props.match.url != nextProps.match.url ||
      this.props.isShowingDecisionAlert != nextProps.isShowingDecisionAlert ||
      this.props.isAccountDisabled != nextProps.isAccountDisabled ||
      this.props.fatalError != nextProps.fatalError
    );
  }

  renderRoutes(path, accountId) {
    const {fatalError} = this.props;

    if (fatalError) {
      return (
        <Modal dismissable={false}>
          <ModalContent title="Not available">
            <p>{fatalError}</p>
          </ModalContent>
        </Modal>
      );
    }

    if (this.props.isAccountDisabled) {
      // only allow user to access settings pages and expired page
      return (
        <Switch>
          <Route path={`${path}/settings`} component={SettingsRoute} />
          <Route exact path={`${path}/account-expired`} component={AccountDisabled} />
          <Redirect from="*" to={`/${accountId}/account-expired`} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route exact path={`${path}/home`} component={HomePage} />
        <Route exact path={`${path}/search`} component={SearchPage} />
        <Route exact path={`${path}/profile/:userId`} component={ProfileRoute} />
        <Route path={`${path}/academy`} component={CoursesRoute} />
        <Route
          exact
          path={`${path}/notifications/:notificationId/go`}
          component={NotificationsRoute}
        />
        <Route path={`${path}/folders/:folderId?`} component={FoldersRoute} />
        <Route path={`${path}/leaderboards/new`} component={EditLeaderboardRoute} />
        <Route exact path={`${path}/leaderboards/:leaderboardId?`} component={LeaderboardRoute} />
        <Route path={`${path}/leaderboards/:leaderboardId/edit`} component={EditLeaderboardRoute} />
        <Route path={`${path}/reports/:reportId`} component={ReportsRoute} />
        <Route path={`${path}/settings`} component={SettingsRoute} />
        <Route exact path={`${path}/page-not-found`} component={NotFoundPage} />

        <Redirect from={`${path}/account-expired`} to={`/${accountId}/home`} />
        <Redirect from="*" to={`/${accountId}/page-not-found`} />
      </Switch>
    );
  }

  render() {
    const {
      user,
      match: {params, path},
      location: {pathname},
      isVirtual,
      isShowingDecisionAlert
    } = this.props;

    if (!params || !params.accountId) {
      return null;
    }

    const {largeMobile} = this.props.screenSizes;

    return (
      <div className={cx(styles.wrapper, {[styles.decisionAlertPadding]: isShowingDecisionAlert})}>
        <Idle onChange={this.handleIdleChange} timeout={5000} />
        {isSuperUser(user) && <SpotlightContainer />}
        <UserAchievement />
        <NPS />
        <HelpSidebar />
        <PageReloadListener />

        <Helmet title="Websites" />

        {isVirtual ? (
          this.renderRoutes(path, params.accountId)
        ) : (
          <PageWithFixed
            orientation={largeMobile ? 'vertical' : 'horizontal'}
            fixedElement={
              <MainNavigation
                mobile={largeMobile}
                user={user}
                pathname={pathname}
                // pass the reportId for the `reportPath` function and the folderId for the `folderPath`
                params={{...params, folderId: '1'}}
              />
            }
            fixedSize={styles.mainHeader}
          >
            {this.renderRoutes(path, params.accountId)}
          </PageWithFixed>
        )}
      </div>
    );
  }
}

export default compose(
  DragDropContext(HTML5Backend),
  connect(
    // I had to do it here because including accountTypeSelectors in authSelectors results in a circular dependancy.
    // Ain't nobody got time for that shit.
    createSelector(
      loggedInRouteSelector,
      fatalErrorSelector,
      isAccountDisabledSelector,
      (props, fatalError, isAccountDisabled) => ({
        ...props,
        fatalError,
        isAccountDisabled
      })
    ),
    {
      replace,
      fetchAccountTypes,
      hideSpotlight,
      clearResults,
      showSpotlight,
      setNPSEnable,
      setIdle,
      requestSubscriptions
    }
  ),
  connectContext(ScreenSizeContext, 'screenSizes')
)(LoggedInRoute);
