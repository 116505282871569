import {UPDATE_SELF_CONSENTS, updateSelfConsentsComplete} from 'modules/auth';
import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* updateSelfConsentsSaga() {
  yield takeLatest(UPDATE_SELF_CONSENTS, function*({userId, consents}) {
    yield processRequest(
      'POST',
      'users/self/consents',
      {
        *success() {
          yield put(updateSelfConsentsComplete(userId, consents));
        },
        *error() {
          // dont care about errors here cos its unlikely plus time and importance
          yield put(updateSelfConsentsComplete(userId, consents));
        }
      },
      {consents}
    );
  });
}
