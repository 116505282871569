import React from 'react';
import silktideLogo from './silktide-logo.png';

import {APP_NAME} from 'config';

export default function Brand(props) {
  let logo = silktideLogo;
  const {version} = props;

  switch (version) {
    case SILKTIDE_LOGO:
      logo = silktideLogo;
      break;
  }

  return <img {...props} src={logo} alt={APP_NAME} />;
}

export const SILKTIDE_LOGO = 'silktide';
