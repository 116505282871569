import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_DASHBOARD} from 'modules/dashboards/constants';
import {dashboardEndpoint} from 'modules/dashboards/utils';
import {requestDashboardComplete} from 'modules/dashboards/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestDashboardListener() {
  yield takeLatest(REQUEST_DASHBOARD, requestDashboardSaga);
}

function* requestDashboardSaga({dashboardId}) {
  const url = dashboardEndpoint({dashboardId});

  yield processRequest('GET', url, {
    *success(dashboard) {
      yield put(requestDashboardComplete(null, dashboard));
    },
    *error(err) {
      yield put(requestDashboardComplete(err));
    },
    errorMessage: couldNot('request dashboard')
  });
}
