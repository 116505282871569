import {put, takeLatest} from 'redux-saga/effects';
import {showInModal, showInHugeModal} from 'modules/modal';

// generates a show modal saga
const modalSagaBuilder = openModalAction => (
  triggerAction,
  Modal,
  actionToProps = null,
  modalConfig = {}
) =>
  function*() {
    yield takeLatest(triggerAction, function*(action) {
      let props = {};

      if (typeof actionToProps === 'function') {
        props = actionToProps(action);
      }

      yield put(openModalAction(Modal, props, modalConfig));
    });
  };

export const modalSaga = modalSagaBuilder(showInModal);
export const hugeModalSaga = modalSagaBuilder(showInHugeModal);

export default modalSaga;
