import {exportReportComplete} from 'modules/reports';
import {EXPORT_REPORT_PDF} from 'modules/reports/reportConstants';
import {exportPDFEndpoint} from 'modules/reports/reportUtils';
import {call, put, take, select, takeEvery} from 'redux-saga/effects';
import {push} from 'modules/location';
import {processRequest} from 'utils/saga/fetchUtils';
import {showError} from 'utils/saga/alerts';
import {currentFilterSelector} from 'modules/filters/filterSelectors';

export default function* exportPdfSaga() {
  yield takeEvery(EXPORT_REPORT_PDF, exportPdf);
}

// TODO: similar to exportReport - DRY
export function* exportPdf({reportId, filters, testIds}) {
  const url = exportPDFEndpoint({reportId});

  const currentFilters = yield select(currentFilterSelector);

  if (Array.isArray(testIds)) {
    testIds = testIds.join(',');
  }

  const payload = {testIds, ...currentFilters, ...filters};

  return yield processRequest(
    'GET',
    url,
    {
      // the exporter times out at 60 seconds, so we'll add an extra 10 for the request
      timeout: 60000 + 10000,

      successAction: ({url}) => exportReportComplete({exportUrl: url}),

      error: function*() {
        const errorMessage = 'An error occurred when exporting this test';
        yield put(exportReportComplete());
        yield call(showError, errorMessage);
      }
    },
    payload
  );
}
