import React from 'react';
import cx from 'classnames';
import styles from './inspectorIssue.scss';

export default class InspectorIssue extends React.Component {
  static defaultProps = {};

  renderSubComponent(renderDropdownInline) {
    const {componentName, issueData, count, action, point, isActive} = this.props;

    if (!componentName || !issueData || componentName === 'Issue') return null;

    const Component = getSubComponent(componentName);
    if (!Component) return null;

    return (
      <Component
        issueData={issueData}
        count={count}
        action={action}
        point={point}
        isActive={isActive}
      />
    );
  }

  render() {
    const {className, issueHash, onClick} = this.props;
    return (
      <div
        id={`issue_${issueHash}`} // this was used for old scrollbar code, not sure if necessary
        className={cx(styles.issue, className)}
        onClick={onClick}
      >
        {this.renderSubComponent()}
      </div>
    );
  }
}

function getSubComponent(name) {
  try {
    return require(`modules/inspector/components/issues/${name}`);
  } catch (e) {
    if (__DEV__) {
      console.error(`Could not load issue sub component ${name}`, e);
    }
    return null;
  }
}
