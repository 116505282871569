import {List, Map, fromJS} from 'immutable';
import _ from 'lodash';

// Safely calls the `toJS` function
export function toJS(obj) {
  if (obj && obj.toJS) {
    return obj.toJS();
  }
  return obj;
}

// Gets the matching props from an object/Immutable map.
// getProps(obj, ['name', 'title']) => {name: '', title: ''}
export function getProps(map = {}, props) {
  if (!map && !map.toJS && !_.isObject(map)) {
    if (__DEV__) {
      console.warn('getProps expects an object or immutable.Map');
    }
  }

  const hasGetter = typeof map.get === 'function';

  return props.reduce((obj, propName) => {
    obj[propName] = toJS(hasGetter ? map.get(propName) : map[propName]);
    return obj;
  }, {});
}

// Alphabetical sort function for Maps
export function alphabeticalSorter(field) {
  return (a, b) => {
    return a.get(field).localeCompare(b.get(field));
  };
}

// Checks if an Immutable Map matches the passed props
export function mapContains(map, props = {}) {
  return Object.keys(props).every(key => {
    return map.get(key) === props[key];
  });
}

// Predicate version of mapContains.
// i.e. blah.find(whereContains({a: 1, b: 2}))
export function whereContains(props) {
  return map => mapContains(map, props);
}

// Convert an array into a Map
export function keyBy(arr, keyProp, initialValue = Map()) {
  const isImmutable = arr instanceof List || arr instanceof Map;
  const iterator = Array.isArray(arr) ? arr : Object.values(arr);
  return iterator.reduce((result, obj) => {
    const key = isImmutable ? obj.get(keyProp) : obj[keyProp];
    return result.set(key.toString(), isImmutable ? obj : fromJS(obj));
  }, initialValue);
}

// Swap idxA with idxB within a list
export function swap(list, idxA, idxB) {
  const a = list.get(idxA);
  const b = list.get(idxB);

  return list.set(idxA, b).set(idxB, a);
}
