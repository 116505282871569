import React, {Fragment} from 'react';
import {Map, List} from 'immutable';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
  digestsSelector,
  digestSubscriptionsSelector,
  originalDigestSubscriptionsSelector,
  lastReceivedDigestSubscriptionsSelector,
  unsavedDigestSubscriptionsSelector,
  digestSubscriptionsSavingSelector,
  digestSubscriptionsLoadingSelector,
  removeDigestSubscriptionsSelector
} from 'modules/digests/selectors';
import {showInModal} from 'modules/modal';
import {
  requestDigests,
  requestDigestSubscriptions,
  saveDigestSubscriptions,
  removeUnsavedDigestSubscriptions,
  removeDigestSubscription,
  requestDigestPreview
} from 'modules/digests/actions';
import SettingsPage from 'components/settings/settingsPage';
import {usersSelector} from 'modules/users/userSelectors';
import PageHeader from 'components/header/pageHeader';
import Button from 'components/button';
import Table from 'components/table';
import Loading from 'components/spinner/loading';
import DeleteIcon from 'components/icons/deleteIcon';
import Panel from 'components/panel/panel';
import Status from 'components/status/status';
import SavePanel from 'modules/form/components/savePanel';
import DigestSubscriptionModal from 'modules/digests/containers/digestSubscriptionModal';
import Icon from 'components/font-awesome';
import Margin from 'components/margin';
import {permissionsSettingsPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location';
import {Prompt} from 'react-router';
import {EditIcon} from 'components/icons';

const SCHEDULE_MAP = {
  weekly: 'Weekly',
  monthly: 'Monthly'
};

class DigestsConfig extends React.Component {
  componentDidMount() {
    const {
      digests,
      match: {params}
    } = this.props;

    if (!digests.size) {
      this.props.requestDigests();
    }
    this.props.requestDigestSubscriptions(params.reportId, params.userId);
  }

  componentWillUnmount() {
    this.removeUnsaved();
  }

  handleClickPreview = record => () => {
    const {
      match: {params}
    } = this.props;

    this.props.requestDigestPreview(
      params.reportId,
      record.digestId,
      record.schedulePreset,
      record.options
    );
  };

  handleClickEdit = digestSubscription => () => {
    const {
      match: {params}
    } = this.props;

    this.props.showInModal(DigestSubscriptionModal, {
      reportId: params.reportId,
      userId: params.userId,
      digestSubscription
    });
  };

  handleClickRemove = record => () => {
    const {
      match: {params}
    } = this.props;

    this.props.removeDigestSubscription(params.userId, record);
  };

  handleClickCancel = () => {
    this.removeUnsaved();
  };

  handleClickSave = () => {
    const {
      match: {params}
    } = this.props;
    const digestSubscriptions = this.getAllDigestSubscriptions();

    this.props.saveDigestSubscriptions(params.reportId, params.userId, digestSubscriptions.toJS());
  };

  handleClickAddDigest = () => {
    const {
      match: {params}
    } = this.props;

    this.props.showInModal(DigestSubscriptionModal, {
      reportId: params.reportId,
      userId: params.userId
    });
  };

  removeUnsaved() {
    const {
      match: {params}
    } = this.props;

    this.props.removeUnsavedDigestSubscriptions(params.userId);
  }

  getAllDigestSubscriptions() {
    const {
      match: {params},
      digestSubscriptions: allSubscriptions,
      unsavedDisgestSubscriptions: allUnsavedSubscriptions,
      digestsToRemove
    } = this.props;

    return allSubscriptions
      .get(params.userId, Map())
      .toList()
      .filter(digestSubs => {
        return !digestsToRemove
          .get(params.userId, List())
          .includes(digestSubs.get('digestSubscriptionId'));
      })
      .concat(allUnsavedSubscriptions.get(params.userId, List()));
  }

  hasSavedDigestsChanged() {
    const {
      match: {params},
      digestSubscriptions,
      originalDigestSubscriptions
    } = this.props;

    return !digestSubscriptions
      .get(params.userId, Map())
      .equals(originalDigestSubscriptions.get(params.userId, Map()));
  }

  render() {
    const {
      users,
      loading,
      saving,
      lastReceived,
      match: {params},
      unsavedDisgestSubscriptions,
      digestsToRemove
    } = this.props;

    const digestSubscriptions = this.getAllDigestSubscriptions();

    const user = users.get(params.userId);
    const pageHeader = (
      <PageHeader
        goBack={() => {
          this.props.push(permissionsSettingsPath, {reportId: params.reportId});
        }}
        title={`Digests for ${user.get('name')}`}
        inline={[
          <Button key="new_digest" onClick={this.handleClickAddDigest}>
            New digest
          </Button>
        ]}
      />
    );

    const empty = !lastReceived || !digestSubscriptions.size;

    const DigestSubscriptionColumns = [
      {
        field: 'digestId',
        width: '80px',
        type: 'jsx',
        jsx: ({data}) => {
          const iconName = this.props.digests.getIn([data, 'icon']);
          return (
            <Margin margin="10px">
              <Icon name={iconName} size="2x" />
            </Margin>
          );
        }
      },
      {
        field: 'digestId',
        label: 'Digest',
        type: 'jsx',
        jsx: ({data}) => {
          return this.props.digests.getIn([data, 'name']);
        }
      },
      {
        field: 'schedulePreset',
        label: 'Schedule',
        width: '200px',
        type: 'jsx',
        jsx: ({data}) => {
          return SCHEDULE_MAP[data];
        }
      },
      {
        type: 'jsx',
        width: '100px',
        jsx: ({record}) => {
          return (
            <Button link onClick={this.handleClickPreview(record)}>
              Preview
            </Button>
          );
        }
      },
      {
        type: 'jsx',
        width: '80px',
        jsx: ({record}) => {
          return (
            <Fragment>
              <EditIcon onClick={this.handleClickEdit(record)} />
              <DeleteIcon onClick={this.handleClickRemove(record)} />
            </Fragment>
          );
        }
      }
    ];

    const hasChanges =
      !!unsavedDisgestSubscriptions.get(params.userId, Map()).size ||
      !!digestsToRemove.get(params.userId, List()).size ||
      this.hasSavedDigestsChanged();

    return (
      <SettingsPage params={params} pageHeader={pageHeader}>
        <Prompt
          when={hasChanges}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        <Panel padding={loading}>
          {loading ? (
            <Loading />
          ) : empty ? (
            <Status appearance="info">There are no digests for this user</Status>
          ) : (
            <Table rows={digestSubscriptions.toJS()} columns={DigestSubscriptionColumns} />
          )}
        </Panel>

        <SavePanel
          floating
          isLoading={saving}
          submitLabel="Save"
          floatMargin={'100px'}
          onSubmit={this.handleClickSave}
          onCancel={this.handleClickCancel}
          isValid
          show={hasChanges}
        />
      </SettingsPage>
    );
  }
}

export default connect(
  createStructuredSelector({
    users: usersSelector,
    digests: digestsSelector,
    loading: digestSubscriptionsLoadingSelector,
    saving: digestSubscriptionsSavingSelector,
    lastReceived: lastReceivedDigestSubscriptionsSelector,
    digestSubscriptions: digestSubscriptionsSelector,
    originalDigestSubscriptions: originalDigestSubscriptionsSelector,
    unsavedDisgestSubscriptions: unsavedDigestSubscriptionsSelector,
    digestsToRemove: removeDigestSubscriptionsSelector
  }),
  {
    requestDigests,
    requestDigestSubscriptions,
    saveDigestSubscriptions,
    removeUnsavedDigestSubscriptions,
    removeDigestSubscription,
    requestDigestPreview,
    showInModal,
    push
  }
)(DigestsConfig);
