exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._23DJQjbVEdHKLtj2WvPZ7u{width:100%;height:auto}.jp2XOT90UKa859dgZJYzV{margin:20px -80px}@media screen and (max-width: 1200px){.jp2XOT90UKa859dgZJYzV{margin:20px 0}}", ""]);

// exports
exports.locals = {
	"img": "_23DJQjbVEdHKLtj2WvPZ7u",
	"wide": "jp2XOT90UKa859dgZJYzV"
};