import {fromJS} from 'immutable';
import {
  FETCH_SEARCH_RESULTS,
  RECEIVE_SEARCH_RESULTS,
  FETCH_RECENT_REPORTS,
  RECEIVE_RECENT_REPORTS,
  APPEND_RECENT_REPORTS,
  NO_MORE_RECENT_REPORTS
} from './constants';
import {DELETE_REPORT} from 'modules/reports/reportConstants';

const initialState = fromJS({
  loadingRecentReports: false,
  loadingSearchResults: false,
  noMoreRecentReports: false,

  recentReports: [],
  searchResults: []
});

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECENT_REPORTS:
      if (action.offset == 0) {
        return state.set('loadingRecentReports', true).set('noMoreRecentReports', false);
      } else {
        return state.set('loadingRecentReports', true);
      }
    case RECEIVE_RECENT_REPORTS:
      return state.set('loadingRecentReports', false).set('recentReports', fromJS(action.reports));

    case FETCH_SEARCH_RESULTS:
      return state.set('loadingSearchResults', true);
    case RECEIVE_SEARCH_RESULTS:
      return state.set('loadingSearchResults', false).update('searchResults', searchResults => {
        return action.append
          ? searchResults.concat(fromJS(action.reports))
          : fromJS(action.reports);
      });

    case APPEND_RECENT_REPORTS:
      return state
        .set('loadingRecentReports', false)
        .update('recentReports', recentReports => recentReports.concat(fromJS(action.reports)));

    case NO_MORE_RECENT_REPORTS:
      return state.set('noMoreRecentReports', true);

    case DELETE_REPORT:
      const recentReports = state.get('recentReports', []);
      return state.set(
        'recentReports',
        recentReports.filter(report => report.get('reportId') != action.reportId)
      );

    default:
      return state;
  }
}
