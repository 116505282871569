exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2REeqw3B8U1SaY5O8ES19m pre{overflow:auto}._2REeqw3B8U1SaY5O8ES19m table{background:#fff;width:100%;border:1px solid rgba(0,0,0,.2);margin:20px 0;box-shadow:0 0 10px rgba(0,0,0,.2)}._2REeqw3B8U1SaY5O8ES19m table td,._2REeqw3B8U1SaY5O8ES19m table th{padding:10px;border:1px solid rgba(0,0,0,.2);text-align:left}._2REeqw3B8U1SaY5O8ES19m table th{font-weight:bold;background:#e0f0ff;border-bottom:2px solid rgba(0,0,0,.4)}._2REeqw3B8U1SaY5O8ES19m h1{margin:30px 0 20px}._3YAotNzcKL86fik4GeNbt2 p{display:inline}ul{margin-top:15px;margin-bottom:15px}._3x92qn8NL2R6ur23aAiaom{display:block;margin-top:20px}._2REeqw3B8U1SaY5O8ES19m pre{overflow:auto}", ""]);

// exports
exports.locals = {
	"actionDescription": "_2REeqw3B8U1SaY5O8ES19m",
	"markdownClosed": "_3YAotNzcKL86fik4GeNbt2",
	"showLess": "_3x92qn8NL2R6ur23aAiaom"
};