import _ from 'lodash';

let config, overrides;
let local_config = {};

config = require('../config.base.json');

try {
  local_config = require('../config.json');
} catch (e) {}

if (window.CONFIG) {
  overrides = window.CONFIG;
  delete window.CONFIG;
}

export default _.merge(config, local_config, overrides);
