import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import Button from 'components/button';
import {doInstruction, doCommand} from 'modules/instructions';
import {locationSelector} from 'modules/location/locationSelectors';
import {DropdownOption} from 'components/dropdown';
import {reportSelector} from 'modules/reports/reportSelectors';
import {ROLE_CONTRIBUTOR, reportIsAllowed} from 'modules/gandalf/gandalfUtils';
import {ScreenSizeContext} from 'providers/screenSizeProvider';
import {filterSelector} from 'modules/filters/filterSelectors';
import {evalInstruction} from 'utils/instructionUtils';
import ControlLinkButton from './controlLinkButton';
import ControlLink from './controlLink';

const controlSelector = createSelector(
  locationSelector,
  reportSelector,
  filterSelector,
  (_, props) => props.reportRole,
  ({query = {}}, report, {filter = {}}, passedReportRole) => {
    return {
      // Grab all items in the current query string
      // Also grab any items that are in our filters redux store etc.
      // This makes sure actions filters get passed into here.
      filters: {...filter, ...query},
      isDemo: report.get('isDemo'),
      reportRole: passedReportRole || report.getIn(['ownPermissions', 'role'])
    };
  }
);

// defines what function is called depending on the given `type`
const typeToRenderMap = {
  button: 'renderButton',
  dropdown: 'renderDropdownOption'
};

export class Control extends React.Component {
  static defaultProps = {
    type: 'button'
  };

  static propTypes = {
    instruction: PropTypes.object.isRequired
  };

  state = {
    loadingOnClick: false
  };

  handleClick = event => {
    event.stopPropagation(); // don't click the table row we are in
    // (TODO how about we ignore the event in the table if `event.target` is not a row?)

    const {command, instruction, context, filters, loadingOnClick} = this.props;

    if (loadingOnClick) {
      this.setState({
        loadingOnClick: true
      });
    }

    if (command) {
      this.props.doCommand(command, context, filters, event);
    } else {
      this.props.doInstruction(instruction, context, filters, event);
    }
  };

  renderButton = () => {
    const {children, icon, tooltip, appearance, label, className, small, ...props} = this.props;
    const {command, instruction, context, filters} = props;
    const theCommand = command || evalInstruction(instruction, context, filters);

    const buttonStyleProps = {
      small,
      square: small,
      narrow: !small,
      [appearance]: true
    };

    // We render the inspector type as an A tag (that looks like a button) rather than a regular button.
    // Some controls we want to display as an A tag, others we don't. This needs standarising somehow
    if (theCommand.type === 'inspector') {
      return (
        <ControlLinkButton
          command={theCommand}
          className={className}
          tooltip={tooltip}
          icon={icon}
          {...buttonStyleProps}
        >
          {label || children}
        </ControlLinkButton>
      );
    }

    if (theCommand.type === 'filter' && appearance === 'link') {
      return (
        <ControlLink command={theCommand} className={className}>
          {label || children}
        </ControlLink>
      );
    }

    return (
      <Button
        {...buttonStyleProps}
        isLoading={this.state.loadingOnClick}
        disabled={this.state.loadingOnClick}
        icon={icon}
        tooltip={tooltip}
        onClick={this.handleClick}
        className={className}
        {...props}
      >
        {label || children}
      </Button>
    );
  };

  renderDropdownOption = () => {
    const {icon, tooltip, appearance, label, className} = this.props;
    return (
      <DropdownOption icon={icon} value={label} className={className} onClick={this.handleClick}>
        {label}
      </DropdownOption>
    );
  };

  render() {
    const {type, command, instruction, isDemo} = this.props;
    const renderFunction = this[typeToRenderMap[type]];
    if (typeof renderFunction !== 'function') return null;

    const value = command ? command.type : instruction.type.value;

    if (isDemo) return renderFunction();

    if (
      (value === 'decision' || value === 'undoDecision') &&
      !reportIsAllowed(ROLE_CONTRIBUTOR, this.props.reportRole)
    ) {
      return null;
    }

    return (
      <ScreenSizeContext.Consumer>
        {({tablet}) => {
          return tablet && value === 'export' ? null : renderFunction();
        }}
      </ScreenSizeContext.Consumer>
    );
  }
}

export default connect(
  controlSelector,
  {doInstruction, doCommand}
)(Control);
