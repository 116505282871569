exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3HhmcatacsVI0eCMBQZS1M{height:20px;width:20px;display:inline-block;vertical-align:sub;margin-right:10px}", ""]);

// exports
exports.locals = {
	"colorPreview": "_3HhmcatacsVI0eCMBQZS1M"
};