import React from 'react';
import cx from 'classnames';
import Pill from './pill';
import styles from './pills.scss';

export default class Pills extends React.Component {
  constructor(options) {
    super(options);
    this.state = {showAll: false};
  }

  handleClick = e => {
    e.stopPropagation();
    this.setState({showAll: true});
  };

  render() {
    const {data, className, max = 5} = this.props;

    const total = data.length;
    const amountToRender = this.state.showAll ? total : max;
    const leftToShow = total - amountToRender;

    return (
      <div className={cx(styles.pills, className)}>
        {data.slice(0, amountToRender).map(pill => {
          const {label, ...pillProps} = pill;
          const key = pill.key || label;

          return (
            <Pill key={key} {...pillProps}>
              {label}
            </Pill>
          );
        })}

        {leftToShow > 0 && <ShowMore amount={leftToShow} onClick={this.handleClick} />}
      </div>
    );
  }
}

function ShowMore({amount, ...props}) {
  return (
    <button type="button" className="label appearance-minor" {...props}>
      + {amount}
    </button>
  );
}
