import PropTypes from 'prop-types';
import React from 'react';
import ReasonComponent from 'components/reason';

class Reason extends React.Component {
  render() {
    const {record} = this.props;
    const {reason, untilDate} = record;

    return <ReasonComponent reason={reason} untilDate={untilDate} inline />;
  }
}

Reason.propTypes = {};

export default Reason;
