import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import styles from './tabs.scss';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import {testPath} from 'modules/tests/testUtils';
import ResponsiveTabs from 'components/tabs/responsiveTabs';
import Tab from 'components/tabs/tab';
import {isPrintMode} from 'modules/filters/filterUtils';
import {filterSelector} from 'modules/filters/filterSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import {reportIsAllowed} from 'modules/gandalf/gandalfUtils';

export class LayoutComponentTabs extends React.Component {
  getCurrentIndex() {
    const {tab} = this.props.params;

    if (!tab) return 0;

    return React.Children.toArray(this.props.children).findIndex(
      child => child.props.config.label == tab
    );
  }

  clickHandler = (tabName, tab) => e => {
    const {push, filter} = this.props;

    if (tab.resetfilter) {
      filter[tab.resetfilter] = tab.resetfiltervalue;
    }

    push(testPath, {tab: tabName, filter}, ['reportId', 'testId']);
  };

  renderTabs(currentIndex) {
    return (
      <ResponsiveTabs>
        {React.Children.map(this.props.children, (child, idx) => {
          if (!child) return;

          const reportRole = child.props.config['report-role'];

          if (!reportRole || reportIsAllowed(reportRole, this.props.reportRole)) {
            return this.renderTab(child.props.config, idx === currentIndex);
          }
        })}
      </ResponsiveTabs>
    );
  }

  renderTab(tab, active) {
    const {componentId, label, align} = tab;
    return (
      <Tab
        selected={active}
        key={componentId}
        align={align}
        onClick={this.clickHandler(label, tab)}
      >
        {label}
      </Tab>
    );
  }

  renderTabContent(currentIndex) {
    return React.Children.toArray(this.props.children)[currentIndex];
  }

  render() {
    const currentIndex = this.getCurrentIndex();

    if (isPrintMode(this.props.filter)) {
      return <div className={styles.tabContent}>{this.renderTabContent(currentIndex)}</div>;
    }

    return (
      <div className={styles.container}>
        {this.renderTabs(currentIndex)}
        <div className={styles.tabContent}>{this.renderTabContent(currentIndex)}</div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    ...filterSelector(state, props),
    reportRole: reportSelector(state, props).getIn(['ownPermissions', 'role'])
  }),
  {push}
)(LayoutComponentTabs);
