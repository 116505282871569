import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {REQUEST_INSTRUCTION_TYPES} from 'modules/interactions/constants';
import {instructionTypesEndpoint} from 'modules/interactions/utils';
import {requestInstructionTypesComplete} from 'modules/interactions/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* requestInstructionTypesListener() {
  yield takeLatest(REQUEST_INSTRUCTION_TYPES, requestInstructionTypesSaga);
}

function* requestInstructionTypesSaga({type, ...action}) {
  const requestData = {...action};
  const url = instructionTypesEndpoint(action);

  yield processRequest(
    'GET',
    url,
    {
      *success(data) {
        yield put(requestInstructionTypesComplete(null, data));
      },
      *error(err) {
        yield put(requestInstructionTypesComplete(err));
      },
      errorMessage: couldNot('request instruction types')
    },
    requestData
  );
}
