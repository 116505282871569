export function createInspectorSpellingLink(actionId, word) {
  const testId = 'spelling';
  return {
    testId,
    actionId,
    type: 'inspector',
    context: {actionId, word}
  };
}

export function createInspectorBrokenLink(actionId, fullHash) {
  const testId = 'broken-links';
  return {
    testId,
    actionId,
    type: 'inspector',
    context: {testId, actionId, fullHash}
  };
}

export function createInspectorPageLink(pageHash) {
  return {
    type: 'inspector',
    pageHash
  };
}
