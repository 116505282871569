import {put, call} from 'redux-saga/effects';
import {addMessage} from 'modules/alerts';

// Shows an error to the user
export function* showError(message) {
  yield put(addMessage('error', message));
}

// Shows a success message to the user
export function* showSuccess(message) {
  yield put(addMessage('success', message));
}
