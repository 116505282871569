import {takeEvery, put} from 'redux-saga/effects';
import {UNDO_DECISION, UNDO_DECISION_COMPLETE} from '../decisions';
import {decisionEndpoint} from '../decisionUtils';
import {processRequest} from 'utils/saga/fetchUtils';

export default function* undoDecisionSaga() {
  yield takeEvery(UNDO_DECISION, undoDecision);
}

function* undoDecision({reportId, decisionId, context}) {
  try {
    yield processRequest('DELETE', decisionEndpoint({reportId, decisionId}));
    yield put({type: UNDO_DECISION_COMPLETE, context});
  } catch (err) {}
}
