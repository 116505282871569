import React from 'react';
import {connect} from 'react-redux';
import UserImage from 'components/user/userImage';
import {navSelector} from 'components/nav/navSelectors';
import styles from './accountMenu.scss';
import {logout} from 'modules/auth';
import {usersPath} from 'modules/users/userUtils';
import {reportPath} from 'modules/reports/reportUtils';
import {showSpotlight} from 'modules/spotlight';
import {reportLogPath} from 'modules/logs/logUtils';
import {toggleAccountMenu} from 'modules/app';
import {roadmapUrl, supportUrl} from 'constants/externalUrls';
import CanBuyGandalf from 'components/gandalf/canBuyGandalf';
import ReportNavButtons from 'modules/reports/components/reportNavButtons';
import MenuItem from './accountMenuItem';
import FakeIntercom from './fakeIntercom';
import {
  isSuperUser,
  shallPass,
  ACCOUNT_OWNER,
  SUPER_USER,
  isVirtualUser
} from 'modules/auth/gandalf';
import {
  userSettingsPath,
  checkSettingsPath,
  accountSettingsPath,
  paymentSettingsPath,
  subscriptionSettingsPath
} from 'modules/settings/settingsUtils';

export class AccountMenu extends React.Component {
  toggleMenu = () => {
    // this.props.toggleAccountMenu();
  };

  logout = () => {
    this.toggleMenu();
    this.props.logout();
  };

  handleClickSpotlight = () => {
    this.toggleMenu();
    this.props.showSpotlight();
  };

  getOptions() {
    const {user, params, accountOptions} = this.props;

    const menuOptions = [
      <MenuItem key="userSettings" href={userSettingsPath(params)} onClick={this.toggleMenu}>
        My settings
      </MenuItem> // ,
      // <MenuItem
      //   key="profile"
      //   href={profilePath({params, userId: user.userId})}
      //   onClick={this.toggleMenu}
      // >
      //   Profile
      // </MenuItem>
    ];

    if (isSuperUser(user)) {
      menuOptions.unshift(<MenuItem divider key="spotlightDivider" />);

      menuOptions.unshift(
        <MenuItem key="spotlight" onClick={this.handleClickSpotlight}>
          Spotlight search
        </MenuItem>
      );
    }

    if (!shallPass(user, ACCOUNT_OWNER)) {
      return menuOptions;
    }

    menuOptions.push(
      ...[
        <MenuItem
          key="accountSettings"
          href={accountSettingsPath(params)}
          onClick={this.toggleMenu}
        >
          Account settings
        </MenuItem>,
        <MenuItem divider key="settingsDivider" />,
        <MenuItem key="enabledChecks" href={checkSettingsPath(params)} onClick={this.toggleMenu}>
          Enabled checks
        </MenuItem>,
        <MenuItem key="payments" href={paymentSettingsPath(params)} onClick={this.toggleMenu}>
          Invoices
        </MenuItem>,
        <MenuItem key="userAdmin" href={usersPath(params)} onClick={this.toggleMenu}>
          Users
        </MenuItem>,
        <CanBuyGandalf key="subscriptions">
          <MenuItem href={subscriptionSettingsPath(params)} onClick={this.toggleMenu}>
            Subscriptions
          </MenuItem>
        </CanBuyGandalf>,
        <MenuItem key="reportsLog" href={reportLogPath(params)} onClick={this.toggleMenu}>
          Usage
        </MenuItem>
      ]
    );

    if (accountOptions && accountOptions.accountReportId) {
      menuOptions.push(
        <MenuItem
          key="accountDashboard"
          onClick={this.toggleMenu}
          href={reportPath({
            ...params,
            reportId: accountOptions.accountReportId.value
          })}
        >
          Account dashboard
        </MenuItem>
      );
    }

    return menuOptions;
  }

  render() {
    const {user} = this.props;
    if (isVirtualUser(user)) {
      return (
        <div className={styles.accountMenu}>
          <ReportNavButtons buttonClassName={styles.menuButtons} isVirtual />

          <MenuItem key="help" href={supportUrl} external>
            Help
          </MenuItem>
          <MenuItem key="roadmap" href={roadmapUrl} external>
            Product roadmap
          </MenuItem>
        </div>
      );
    }

    return (
      <div className={styles.accountMenu}>
        <div className={styles.userBar}>
          <UserImage user={user} />
          <p>{user.name}</p>
        </div>

        <ReportNavButtons buttonClassName={styles.menuButtons} />

        {this.getOptions()}

        <MenuItem divider key="supportDivider" />
        <MenuItem key="help" href={supportUrl} external>
          Help
        </MenuItem>
        <MenuItem key="roadmap" href={roadmapUrl} external>
          Product roadmap
        </MenuItem>
        <MenuItem divider key="logoutDivider" />
        <MenuItem onClick={this.logout}>Log out</MenuItem>

        <FakeIntercom />
      </div>
    );
  }
}

export default connect(
  navSelector,
  {
    toggleAccountMenu,
    showSpotlight,
    logout
  }
)(AccountMenu);
