import React from 'react';
import RelativeDate from 'components/time/relativeDate.js';

class Time extends React.Component {
  render() {
    const {data, options} = this.props;
    if (data < 0) return null;

    return (
      <RelativeDate
        timestamp={this.props.data}
        locale={options.locale === 'true'}
        absolute={options.absolute === 'true'}
      />
    );
  }
}

Time.propTypes = {};

export default Time;
