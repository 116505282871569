import React from 'react';
import {connect} from 'react-redux';
import Flex from 'components/layout/flex';
import InspectorUrlComponent from 'modules/inspector/components/inspectorUrl';
import {inspectorUrlSelector} from 'modules/inspector/inspectorSelectors';

const myStyle = {minWidth: '250px'};

class InspectorUrl extends React.Component {
  render() {
    const {url, time} = this.props;

    return (
      <Flex grow={1} style={myStyle}>
        <InspectorUrlComponent url={url} time={time} />
      </Flex>
    );
  }
}

export default connect(inspectorUrlSelector)(InspectorUrl);
