import {fromJS} from 'immutable';
import {LOGOUT} from 'modules/auth';

const initialState = fromJS({
  currentAchievementId: null, // the current achievement being displayed
  achievementQueue: [], // the queue of unread achievements to display
  isLoading: true,
  badges: [], // an array of achievements only (used for displaying the Badges interface)
  achievements: [] // an array of achievements WITH progress (used for displaying progress)
});

export default function achievementReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;

    case FETCH_USER_ACHIEVEMENTS:
      return state.set('isLoading', true).set('errorMessage', null);

    case FETCH_USER_ACHIEVEMENTS_COMPLETE:
      return state.set('isLoading', false).set('errorMessage', action.errorMessage);

    case RECEIVE_ACHIEVEMENTS:
      const unreadAchievements = fromJS(action.achievements).filter(item => !item.get('readTime'));

      return state.update('achievementQueue', queue => {
        return queue
          .concat(unreadAchievements)
          .sort((a, b) => a.get('awardedTime') - b.get('awardedTime'));
      });

    case SHOW_ACHIEVEMENT:
      return state.set('currentAchievementId', action.achievementId);

    case READ_ACHIEVEMENT:
      // We need to set the readTime locally for this achievement (so it is not selected for display again).
      // A request is also made on the READ_ACHIEVEMENT action to update the achievements `readTime` in the backend.
      const currentAchievementId = state.get('currentAchievementId');
      const currentAchievementIndex = state
        .get('achievementQueue')
        .findIndex(achievement => achievement.get('achievementId') === currentAchievementId);

      if (currentAchievementIndex < 0) {
        console.error('Attempted to read achievement that does not exist');
        return state;
      }

      const achievement = state
        .getIn(['achievementQueue', currentAchievementIndex])
        .set('readTime', Math.floor(Date.now() / 1000));

      return (
        state
          // remove item from the queue
          .update('achievementQueue', queue => queue.splice(currentAchievementIndex, 1))
          // add item to list of badges
          // TODO sort badges
          .update('badges', badges => badges.push(achievement))
          // clear current achievement ID
          .set('currentAchievementId', null)
      );

    case RECEIVE_USER_ACHIEVEMENTS:
      const {achievements, progress} = action.achievements;

      return state.set('badges', fromJS(achievements)).set('achievements', fromJS(progress));
  }
  return state;
}

export const FETCH_USER_ACHIEVEMENTS = 'achievements:FETCH_USER_ACHIEVEMENTS';
export function fetchUserAchievements(userId) {
  return {type: FETCH_USER_ACHIEVEMENTS, userId};
}

export const FETCH_USER_ACHIEVEMENTS_COMPLETE = 'achievements:FETCH_USER_ACHIEVEMENTS_COMPLETE';
export function fetchUserAchievementsComplete(errorMessage) {
  return {type: FETCH_USER_ACHIEVEMENTS_COMPLETE, errorMessage};
}

export const FETCH_UNREAD_ACHIEVEMENTS = 'achievements:FETCH_UNREAD_ACHIEVEMENTS';
export function fetchUnreadAchievements() {
  return {type: FETCH_UNREAD_ACHIEVEMENTS};
}

// TODO dont need this anymore - remove with caution
export const FETCH_UNREAD_ACHIEVEMENTS_COMPLETE = 'achievements:FETCH_UNREAD_ACHIEVEMENTS_COMPLETE';
export function fetchUnreadAchievementsComplete(errorMessage = null) {
  return {
    type: FETCH_UNREAD_ACHIEVEMENTS_COMPLETE,
    errorMessage
  };
}

export const RECEIVE_ACHIEVEMENTS = 'achievements:RECEIVE_ACHIEVEMENTS';
export function receiveAchievements(achievements) {
  return {
    type: RECEIVE_ACHIEVEMENTS,
    achievements
  };
}

export const SHOW_ACHIEVEMENT = 'achievements:SHOW_ACHIEVEMENT';
export function showAchievement(achievementId) {
  return {
    type: SHOW_ACHIEVEMENT,
    achievementId
  };
}

export const READ_ACHIEVEMENT = 'achievements:READ_ACHIEVEMENT';
export function readAchievement(achievementId) {
  return {
    type: READ_ACHIEVEMENT,
    achievementId
  };
}

export const RECEIVE_USER_ACHIEVEMENTS = 'achievements:RECEIVE_USER_ACHIEVEMENTS';
export function recieveUserAchievements(achievements) {
  return {type: RECEIVE_USER_ACHIEVEMENTS, achievements};
}

export const HIDE_ACHIEVEMENT = 'achievements:HIDE_ACHIEVEMENT';
export function hideAchievement() {
  return {type: HIDE_ACHIEVEMENT};
}
