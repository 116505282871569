import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';

const geosearchState = (state, props) => state.geosearch;

export const searchDataSelector = createSelector(
  geosearchState,
  geosearchState => {
    return {
      lastSearchResults: toJS(geosearchState.getIn(['lastSearch', 'results'])),
      isLoadingLocation: toJS(geosearchState.getIn(['lastSearch', 'isLoading']))
    };
  }
);
