import React from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import Test from 'modules/tests/components/test';
import Helmet from 'components/helmet';
import Spinner from 'components/spinner';
import {push} from 'modules/location';
import {testSelector} from 'modules/tests/testSelectors';
import {reportSelector} from 'modules/reports/reportSelectors';
import WithBreadcrumbHeader from 'modules/app/components/withBreadcrumbHeader';
import WithReportSidebar from 'modules/reports/containers/withReportSidebar';
import styles from 'modules/tests/components/test.scss';

export default connect(
  createSelector(
    reportSelector,
    testSelector,
    (report, test) => ({
      report,
      test
    })
  ),
  {push}
)(TestPageContainer);

// TODO ideally this shouldn't be given `match` or `location`, it should receive the specific data
//      I.E reportId etc but they are currently required by sub components
function TestPageContainer({push, match, location, reportId, testId, report, test}) {
  if (!test) return <Spinner marginTop={100} />;
  const type = report.get('reportType');

  return type === undefined || type == 'website' ? (
    <WithReportSidebar match={match} location={location}>
      <WithBreadcrumbHeader
        push={push}
        location={location}
        reportId={reportId}
        testId={testId}
        match={match}
      >
        <TestPage match={match} location={location} test={test.toJS()} />
      </WithBreadcrumbHeader>
    </WithReportSidebar>
  ) : (
    <TestPage match={match} location={location} test={test.toJS()} />
  );
}

function TestPage({className, test, match}) {
  if (!test) return <Spinner marginTop={100} />;

  return (
    <div className={cx(styles.testPage, className)} role="main">
      <Helmet title={test.name} />

      <Test match={match} test={test} key={test.testId} />
    </div>
  );
}
