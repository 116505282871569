import React from 'react';
import {connect} from 'react-redux';
import Table from 'components/table';
import Panel from 'components/panel';
import Button from 'components/button';
import Buttons from 'components/buttons';
import Spinner from 'components/spinner';
import Status from 'components/status/status';
import PageHeader from 'components/header/pageHeader';
import SettingsPage from 'components/settings/settingsPage';
import {tagsSettingsPageSelector} from 'modules/tags/selectors';
import {
  viewTag,
  requestTags,
  requestTagsSummary,
  viewEditTag,
  viewDeleteTag
} from 'modules/tags/actions';
import ColorPreview from 'components/colorPreview';

const COLS = [
  {
    label: 'Label',
    field: 'label',
    type: 'jsx',
    jsx: ({data, record}) => {
      return <ColorPreview color={record.color} label={data} />;
    }
  },
  {
    label: 'Websites',
    field: 'numItems',
    appearance: 'major',
    type: 'count',
    width: '100',
    showZero: true
  },
  {
    label: '',
    field: '',
    type: 'jsx',
    width: '100',
    jsx: ({record, viewEditTag, viewDeleteTag}) => {
      return (
        <Buttons noMargin>
          <Button
            table
            icon="pencil"
            onClick={event => {
              event.stopPropagation();
              viewEditTag(record.tagId.toString());
            }}
          />
          <Button
            table
            cta
            icon="times"
            onClick={event => {
              event.stopPropagation();
              viewDeleteTag(record.tagId.toString());
            }}
          />
        </Buttons>
      );
    }
  }
];

export class AccountTagsSettingsPage extends React.Component {
  componentWillMount() {
    const {requestingTags, requestTags, requestingTagsSummary, requestTagsSummary} = this.props;

    if (!requestingTags.get('loading') && !requestingTags.get('loaded')) {
      requestTags();
    }

    if (!requestingTagsSummary.get('loading') && !requestingTagsSummary.get('loaded')) {
      requestTagsSummary();
    }
  }

  handleClick = (row, col) => {
    this.props.viewTag({tagId: row.tagId});
  };

  renderHeader(numRows) {
    return <PageHeader title="Tags" />;
  }

  renderContent() {
    const {tagsSummary, requestingTagsSummary} = this.props;

    if (!tagsSummary.size) {
      if (requestingTagsSummary.get('loaded')) {
        return <Status appearance="good">There are no tags that are assigned to anything</Status>;
      } else if (requestingTagsSummary.get('loading')) {
        return <Spinner marginTop={100} />;
      }
    }

    return (
      <Panel padding={false}>
        <Table
          rowKey="tagId"
          rows={tagsSummary
            .toList()
            .sortBy(row => row.numItems)
            .toJS()}
          columns={COLS}
          onClick={this.handleClick}
          cellParams={{
            viewEditTag: this.props.viewEditTag,
            viewDeleteTag: this.props.viewDeleteTag
          }}
        />
      </Panel>
    );
  }

  render() {
    return <SettingsPage pageHeader={this.renderHeader()}>{this.renderContent()}</SettingsPage>;
  }
}

export default connect(
  tagsSettingsPageSelector,
  {requestTags, requestTagsSummary, viewTag, viewEditTag, viewDeleteTag}
)(AccountTagsSettingsPage);
