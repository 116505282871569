import _ from 'lodash';

export function evalInstruction(exprObj, context = {}, filter = {}) {
  const props = {};
  _.each(exprObj, ({type, name, value}, key) => {
    if (type === 'prop') {
      props[key] = context[name || value];
    } else if (type === 'filter') {
      props[key] = filter[name || value];
    } else if (type === 'value' || type === 'fixed') {
      props[key] = value;
    } else if (type === 'object') {
      props[key] = evalInstruction(value, context, filter);
    }
  });
  return props;
}
