import React from 'react';
import CodeComponent from 'components/code';

export default class Code extends React.Component {
  render() {
    const {result, config} = this.props;
    const {language} = config;

    if (!result) {
      return null;
    }

    return <CodeComponent language={language}>{result}</CodeComponent>;
  }
}
