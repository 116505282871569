import {takeEvery, takeLatest, take, select, put} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {FETCH_ALERT_SUBSCRIPTIONS} from 'modules/alertSubscriptions/constants';
import {alertSubscriptionsEndpoint} from 'modules/alertSubscriptions/utils';
import {
  receiveAlertSubscriptions,
  receiveAvailableStoryTypes,
  fetchAlertSubscriptionsComplete
} from 'modules/alertSubscriptions/actions';

export default function* fetchAlertSubscriptionSaga() {
  yield takeLatest(FETCH_ALERT_SUBSCRIPTIONS, fetchAlertSubscription);
}

function* fetchAlertSubscription({reportId}) {
  yield processRequest('GET', alertSubscriptionsEndpoint({reportId}), {
    success: function*({subscriptions, availableStoryTypes}) {
      yield put(receiveAlertSubscriptions(subscriptions));
      yield put(receiveAvailableStoryTypes(availableStoryTypes));
      yield put(fetchAlertSubscriptionsComplete());
    },
    error: function*(err) {
      yield put(fetchAlertSubscriptionsComplete(err));
    }
  });
}
