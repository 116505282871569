import cx from 'classnames';
import React from 'react';
import PageScreenshot from '../pages/pageScreenshot';

class Screenshots extends React.Component {
  render() {
    const {data, record, options} = this.props;
    const {device} = record;

    const className = cx('screenshot', 'screenshot' + device);

    if (!data || !data.length) return <span />;

    return (
      <span className="screenshotList">
        {data.map(url => {
          if (!url) return <span />;
          return <PageScreenshot src={url} className={className} expandable />;
        })}
      </span>
    );
  }
}

export default Screenshots;
