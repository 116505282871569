// TODO: decouple this file from inspector module

import _ from 'lodash';
import severities from './severities';
import {getResultValue} from 'modules/results/resultUtils';
import {capitalizeFirstLetter} from 'utils/stringUtils';

// ['confirmed', 'potential', ...]
const severityTypes = severities.map(severity => severity.get('type')).toJS();

// returns either obj.confirmed or obj.numConfirmed when provided severity 'confirmed'
function getIssueCount(object, severity) {
  const issueCount = object[severity] || object[`num${capitalizeFirstLetter(severity)}`];
  if (issueCount) {
    return +issueCount;
  }
}

// Takes an object of props (i.e. component props)
// and gets all the props that contain issue counts
// {confirmed: 5, potential: 2} for example
// Values are converted to integers if not already.
// Keys are normalized i.e. numConfirmed becomes confirmed
export function getAllIssueCountsFromObject(object) {
  return _.reduce(
    severityTypes,
    (issueCounts, severity) => {
      const count = getIssueCount(object, severity);
      if (Number.isInteger(count)) {
        issueCounts[severity] = count;
      }
      return issueCounts;
    },
    {}
  );
}

// Takes an object of issue counts and returns true
// if any of them are above 0
export function hasIssues(issueCounts) {
  return Array.some(Object.values(issueCounts), value => value > 0);
}

const ISSUE_COUNTS = {
  confirmed: 'numConfirmedCount',
  potential: 'numPotentialCount'
};

// pulls out issue counts from results for the specified testId
export function getIssueCounts(results, testId) {
  return _.mapValues(ISSUE_COUNTS, resultId => {
    return getResultValue(results, resultId);
  });
}
