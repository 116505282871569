import React from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import styles from './searchTermsInput.scss';
import Input from 'components/input';
import Flex from 'components/layout/flex';
import Select from 'components/select';
import Button from 'components/button';
import {DeleteIcon} from 'components/icons';
import {getOrdinal} from 'utils/numberUtils';

const Targets = Array(100)
  .fill()
  .map((_, i) => i + 1)
  .map(ord => ({value: ord, label: getOrdinal(ord)}));

export default class SearchTargetsInput extends React.Component {
  static defaultProps = {
    value: [
      {
        keyword: '',
        targetRank: 1
      }
    ]
  };

  static propTypes = {
    value: PropTypes.array
  };

  focusMe = 0;

  inputs = {};

  state = {
    newSearchTermText: ''
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate() {
    if (this.focusMe != null) {
      this.focus(this.inputs[this.focusMe]);
      this.focusMe = null;
    }
  }

  focus(elem) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      elem.focus();
    }, 0);
  }

  handleClickAddSearchTerm = () => {
    const nextIdx = this.props.value.length;

    event.preventDefault();
    event.stopPropagation();

    this.props.onChange([...this.props.value, {keyword: '', targetRank: 1}]);

    setTimeout(() => {
      const nextInput = this.inputs[nextIdx];

      if (nextInput) {
        this.focus(nextInput);
      }
    }, 0);
  };

  handleRemoveSearchTerm = idx => event => {
    const searchTerms = [...this.props.value];

    searchTerms.splice(idx, 1);
    this.props.onChange(searchTerms);
  };

  setSearchRow(idx, name, value) {
    const newValue = [...this.props.value];
    newValue[idx][name] = value;
    this.props.onChange(newValue);
  }

  handleKeyDown = idx => event => {
    if (event.keyCode == 13) {
      const nextIdx = idx + 1;
      const nextInput = this.inputs[nextIdx];

      event.preventDefault();
      event.stopPropagation();

      if (nextInput) {
        this.focus(nextInput);
      } else {
        this.focusMe = nextIdx;
        this.handleClickAddSearchTerm();
      }
    }
  };

  renderSearchTerm = ({keyword, targetRank}, idx) => {
    return (
      <div className={styles.inputRow} key={'search_term_' + idx}>
        <Flex grow={1}>
          <Input
            ref={ref => (this.inputs[idx] = findDOMNode(ref))}
            onKeyDown={this.handleKeyDown(idx)}
            value={keyword}
            onChange={event => {
              this.setSearchRow(idx, 'keyword', event.target.value);
            }}
          />
        </Flex>
        {!this.props.legacyFormat && (
          <Flex style={{margin: '0 10px'}}>
            <Select
              value={targetRank}
              clearable={false}
              onChange={option => {
                this.setSearchRow(idx, 'targetRank', option.value);
              }}
              options={Targets}
            />
          </Flex>
        )}

        <div className={styles.closeIcon}>
          <DeleteIcon onClick={this.handleRemoveSearchTerm(idx)} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.value.map(this.renderSearchTerm)}
        <Button icon="plus" iconSize="lg" iconOnly onClick={this.handleClickAddSearchTerm} />
      </div>
    );
  }
}
