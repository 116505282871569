import React from 'react';
import ActionScoreComponent from 'components/actionScore';

class ActionScore extends React.Component {
  render() {
    const {data} = this.props;
    if (!data) {
      return null;
    }

    return <ActionScoreComponent score={data} />;
  }
}

export default ActionScore;
