import React from 'react';
import styles from './javaScriptErrorIssue.scss';
import ExternalLink from 'modules/location/externalLink';

class JavaScriptErrorIssue extends React.Component {
  renderTrace(trace) {
    return trace.map(this.renderTracePart);
  }

  renderTracePart = (tracePart, i) => {
    const file = tracePart.file;
    const line = tracePart.line;
    const col = tracePart.col;
    const functionName = tracePart.function;
    const filename = this.getFilenameFromUrl(file);

    return (
      <li className={styles.tracePart} key={i}>
        {line && <span>Line {line} </span>}
        {col && <span>Column {col} </span>}
        {functionName && (
          <span>
            <code>
              {functionName}
              ()
            </code>{' '}
          </span>
        )}
        {file && (
          <span>
            in <ExternalLink href={file}>{filename}</ExternalLink>
          </span>
        )}
      </li>
    );
  };

  getFilenameFromUrl(url) {
    return url.substring(url.lastIndexOf('/') + 1) || url;
  }

  getStrippedMessage(message) {
    message = message.replace(/\n|\r|\t/g, ' ');
    return message.replace(/ +/g, ' ');
  }

  render() {
    const {point} = this.props;

    return (
      <div>
        <pre className={styles.source}>{this.getStrippedMessage(point.message)}</pre>
        <ul className={styles.trace}>{this.renderTrace(point.trace)}</ul>
      </div>
    );
  }
}

export default JavaScriptErrorIssue;
