import {Map} from 'immutable';
import {RECEIVE_RESULTS} from './results';
import {RECEIVE_REPORT, CLEAR_REPORT} from 'modules/reports/reportConstants';
import {getFilterHash} from './resultUtils';
import {ORDER_COMPLETE} from 'modules/products';

const initialState = Map({
  // we track which reportId we are storing results for
  // so that we can clear them only when user goes to a new report
  reportId: null,
  data: Map() // results for current report.
});

export default function resultsReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_COMPLETE:
      return initialState;

    case RECEIVE_RESULTS:
      return receiveResults(state, action.results);

    case CLEAR_REPORT:
      return initialState;

    case RECEIVE_REPORT:
      const {results} = action.report;
      // Sometimes a report contains a list of default results (for issue counts in sidebar etc)
      if (results) {
        return receiveResults(state, results);
      }

      break;
  }

  return state;
}

function receiveResults(state, results) {
  return results.reduce((state, result) => {
    return receiveResult(state, result);
  }, state);
}

function receiveResult(state, result) {
  const {usesFilters, filters, resultId, value, error} = result;

  if (error) {
    return state.setIn(['data', resultId, 'error'], error);
  }

  // store usesFilters in the root of the result
  state = state.setIn(['data', resultId, 'usesFilters'], usesFilters);

  // store the actual filtered result against filter hash
  const filterHash = getFilterHash(filters, usesFilters);

  if (!usesFilters || !usesFilters.length) {
    return state.setIn(['data', resultId, 'result'], value);
  }

  return state.setIn(['data', resultId, 'results', filterHash], value);
}
