import React from 'react';
import cx from 'classnames';
import Spinner from 'components/spinner';
import Icon from 'components/font-awesome';
import styles from './helpSidebar.scss';

export default class HelpSidebar extends React.Component {
  componentDidMount() {
    this.sidebarRef.addEventListener('transitionend', this.animationComplete);
  }

  componentWillUnmount() {
    this.sidebarRef.removeEventListener('transitionend', this.animationComplete);
  }

  animationComplete = () => {
    if (!this.props.isOpen) this.props.clearUrl();
  };

  getHelpUrl = helpUrl => {
    if (helpUrl && helpUrl.indexOf('support.silktide.com') !== -1) {
      // If we are going to the support site from inside the tool, then let us not show intercom.
      const queryStringChar = helpUrl.indexOf('?') === -1 ? '?' : '&';
      return helpUrl + queryStringChar + 'hideIntercom=true';
    }
    return helpUrl;
  };

  render() {
    const {isOpen, helpUrl, closeHelp} = this.props;

    return (
      <div
        ref={ref => {
          this.sidebarRef = ref;
        }}
        className={cx(styles.helpSidebar, {[styles.isOpen]: isOpen})}
      >
        <div className={styles.helpHeading}>
          <Icon
            name="times"
            size="lg"
            className={styles.closeIcon}
            onClick={() => {
              closeHelp();
            }}
          />
        </div>
        <iframe src={this.getHelpUrl(helpUrl)} title="Help sidebar">
          <Spinner marginTop={100} />
        </iframe>
      </div>
    );
  }
}
