exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ZD3E-nB7SaG7y2LzsZsOt{color:#777}._32FV19lydv-Yj9qDI5qx1_{color:#000;margin-right:10px}", ""]);

// exports
exports.locals = {
	"label": "_1ZD3E-nB7SaG7y2LzsZsOt",
	"icon": "_32FV19lydv-Yj9qDI5qx1_"
};