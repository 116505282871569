exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2iDIv97Khj8T2b_FU4XtyP{width:100%;color:#62696f;background:#e5f1fb;border-radius:10px;padding:14px 20px;float:right}._2iDIv97Khj8T2b_FU4XtyP>span:first-of-type{font-weight:bold}._2iDIv97Khj8T2b_FU4XtyP .XtDQNd9kyklj2bTvj81jd:after{display:block;white-space:pre;content:\"\\A\";line-height:.8}", ""]);

// exports
exports.locals = {
	"explainer": "_2iDIv97Khj8T2b_FU4XtyP",
	"spacer": "XtDQNd9kyklj2bTvj81jd"
};