import React from 'react';
import LayoutComponent from 'modules/tests/components/layoutComponent';
import {getActiveTab, getTabFromLayoutChildren} from 'components/tabs/tab';

export default class TabBody extends React.Component {
  render() {
    const {config, filter, params} = this.props;
    const activeTab = getActiveTab(params, config);
    const activeChild = getTabFromLayoutChildren(config.children, activeTab);

    if (!activeChild) return null;
    return (
      <LayoutComponent
        config={activeChild}
        filter={filter}
        params={params}
        setFilterParams={this.props.setFilterParams}
      />
    );
  }
}
