let LS = null;
try {
  const testKey = '_*_LS_Test_*_';
  window.localStorage.setItem(testKey, testKey);
  if (window.localStorage.getItem(testKey) !== testKey) {
    throw "local storage didn't set";
  }
  window.localStorage.removeItem(testKey);
  LS = window.localStorage;
} catch (e) {}

export default {
  get(key) {
    return LS && LS.getItem(key);
  },

  set(key, value) {
    LS && LS.setItem(key, value);
  },

  remove(key) {
    LS && LS.removeItem(key);
  }
};
