export const FETCH_QUOTE = 'app:FETCH_QUOTE';
export function fetchQuote(quoteId, quoteKey) {
  return {
    type: FETCH_QUOTE,
    quoteId,
    quoteKey
  }
}

export const RECEIVE_QUOTE = 'app:RECEIVE_QUOTE';
export function receiveQuote(quote) {
  return {
    type: RECEIVE_QUOTE,
    quote
  }
}

export const HANDLE_QUOTE_LOAD_ERROR = 'app:HANDLE_QUOTE_LOAD_ERROR';
export function handleQuoteLoadError(error) {
  return {
    type: HANDLE_QUOTE_LOAD_ERROR,
    error
  }
}


export const REJECT_QUOTE = 'app:REJECT_QUOTE';
export function rejectQuote(quoteId, quoteKey, reason) {
  return {
    type: REJECT_QUOTE,
    reason,
    quoteKey,
    quoteId
  }
}

export const SET_QUOTE_STATE = 'app:SET_QUOTE_STATE';
export function setQuoteState(state) {
  return {
    type: SET_QUOTE_STATE,
    state
  }
}

export const RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS = 'app:RE_FETCH_QUOTE_WITH_PAYMENTS_OPTIONS';
export function refetchQuoteWithPaymentOptions(quoteId, quoteKey, paymentOptions) {
  return {
    type: RE_FETCH_QUOTE_WITH_PAYMENT_OPTIONS,
    quoteId,
    quoteKey,
    paymentOptions
  }
}

export const RECEIVE_QUOTE_WITH_PAYMENT_OPTIONS = 'app:RECEIVE_QUOTE_WITH_PAYMENT_OPTIONS';
export function receiveQuoteWithPaymentOptions(quote) {
  return {
    type: RECEIVE_QUOTE_WITH_PAYMENT_OPTIONS,
    quote
  }
}

export const HANDLE_RE_QUOTE_LOAD_ERROR = 'app:HANDLE_RE_QUOTE_LOAD_ERROR';
export function handleReQuoteLoadError(error) {
  return {
    type: HANDLE_RE_QUOTE_LOAD_ERROR,
    error
  }
}

export const ACCEPT_QUOTE = 'app:ACCEPT_QUOTE';
export function acceptQuote(quoteId, quoteKey, formData, failCallback) {
  return {
    type: ACCEPT_QUOTE,
    quoteId,
    quoteKey,
    formData,
    failCallback
  }
}