import {takeLatest, put, take, select, call} from 'redux-saga/effects';
import {SAVE_LESSON_STATE, SAVE_QUIZ_STATE} from 'modules/courses/constants';
import {lessonStateEndpoint} from 'modules/courses/utils';
import {saveLessonStateComplete, saveQuizStateComplete} from 'modules/courses/actions';
import {processRequest} from 'utils/saga/fetchUtils';
import {couldNot} from 'utils/errorUtils';

export default function* saveLessonStateListener() {
  yield takeLatest(SAVE_LESSON_STATE, saveLessonStateSaga);
  yield takeLatest(SAVE_QUIZ_STATE, saveLessonStateSaga);
}

function* saveLessonStateSaga({lesson, type}) {
  // have to map answers from state because endpoint
  // needs hashmap of [questionId]: answer

  if (!lesson.answers) {
    lesson.answers = [];
  }

  const answers = {};
  lesson.answers.forEach(({questionId, answer}) => {
    if (typeof answer === 'string') {
      answers[questionId] = [answer];
    } else {
      answers[questionId] = answer;
    }
  });

  const state = lesson.state;
  const stateProps = lesson.stateProps || {};

  const url = lessonStateEndpoint({lessonId: lesson.lessonId.toString()});

  yield processRequest(
    'POST',
    url,
    {
      *success({answers}) {
        if (type === SAVE_QUIZ_STATE) {
          yield put(saveQuizStateComplete(null, {...lesson, answers}));
        } else {
          yield put(saveLessonStateComplete(null, {...lesson, answers}));
        }
      },
      *error(err) {
        if (type === SAVE_QUIZ_STATE) {
          yield put(saveQuizStateComplete(err));
        } else {
          yield put(saveLessonStateComplete(err));
        }
      },
      errorMessage: couldNot('save lesson progress')
    },
    {state, stateProps, answers}
  );
}
