import React from 'react';
import InspectorCategoryInfo from 'modules/inspector/components/sidebar/inspectorCategoryInfo';
import InspectorScore from 'modules/inspector/components/inspectorScore';
import Link from 'modules/location/link';
import styles from './inspectorStyle.scss';

function InspectorCategoryRow({category, actionsCount}) {
  return (
    <React.Fragment>
      <div className={styles.categoryRow}>
        <h4 className={styles.label}>
          <Link replace query={{categoryId: category.get('categoryId'), actionId: undefined}}>
            {category.get('name')}
          </Link>
        </h4>
        {category.has('score') && (
          <div className={styles.score}>
            <InspectorScore small value={category.get('score')} />
          </div>
        )}
      </div>
      <InspectorCategoryInfo category={category} />
    </React.Fragment>
  );
}

export default InspectorCategoryRow;
