exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._12sCxywLgyjyqhOHL0gg78{padding:10px;text-align:center}._12sCxywLgyjyqhOHL0gg78 button{min-width:120px}", ""]);

// exports
exports.locals = {
	"footer": "_12sCxywLgyjyqhOHL0gg78"
};