import {put, takeLatest} from 'redux-saga/effects';
import {processRequest} from 'utils/saga/fetchUtils';
import {SET_ROLE, roleSet} from 'modules/reportPermissions';
import {reportPermissionEndpoint} from 'modules/reportPermissions/reportPermissionUtils';
import {couldNot} from 'utils/errorUtils';
import {SUCCESS_MESSAGE} from 'modules/settings/settingsUtils';
import {showSuccess} from 'utils/saga/alerts';

export default function* setRoleSaga() {
  yield takeLatest(SET_ROLE, setRole);
}

function* setRole({reportId, userGroupId, role}) {
  yield processRequest(
    'PUT',
    reportPermissionEndpoint({reportId, userGroupId}),
    {
      success: function*(data) {
        yield put(roleSet(data));

        yield showSuccess(SUCCESS_MESSAGE);
      },
      errorMessage: couldNot('add user permissions')
    },
    {role}
  );
}
