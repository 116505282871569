import {List, OrderedMap, Map} from 'immutable';
import {createSelector} from 'reselect';
import {createPropOrParamSelector, createParamSelector} from 'modules/location/locationSelectors';
import {alphabeticalSorter} from 'utils/immutableUtils';
import {queryParamsSelector} from 'modules/location/locationSelectors';
import {getQueryTestIds} from './testUtils';
import _ from 'lodash';

export const addingTestSelector = state => !!state.tests.get('adding');

export const testIdSelector = createPropOrParamSelector('testId');
export const testIdParamSelector = createParamSelector('testId');

export const testsStateSelector = state => state.tests || Map();

export const testsSelector = state => state.tests.get('data') || OrderedMap();

export const testNameOptionsSelector = createSelector(
  testsSelector,
  tests => ({
    testNameOptions: tests
      .filter(test => !test.get('isTest') && /-screen$/.test(test.get('testId')))
      .sort((a, b) => {
        if (a.get('parentId') && b.get('parentId')) {
          return a.get('parentId') < b.get('parentId') ? -1 : 1;
        }
        return a.get('sortIndex') - b.get('sortIndex');
      })
      .map(test => {
        // prefix label with parent test name (if exists)
        const label = test.get('parentId')
          ? tests.get(test.get('parentId')).get('name') + ' > ' + test.get('name')
          : test.get('name');

        return {value: test.get('testId'), label};
      })
      .toList()
      .toJS()
  })
);

export const testSelector = createSelector(
  testsSelector,
  testIdSelector,
  (tests, testId = 'website') => {
    return tests.get(testId);
  }
);

export const plainTestSelector = createSelector(
  testSelector,
  test => {
    return test && test.toJS();
  }
);

export const connectToTest = createSelector(
  plainTestSelector,
  test => ({
    test
  })
);

export const testNameSelector = createSelector(
  testSelector,
  test => ({
    name: test.get('name')
  })
);

export const printTestsSelector = createSelector(
  testsSelector,
  queryParamsSelector,
  (tests, query) => {
    let testIds = getQueryTestIds(query);

    if (!testIds) {
      // only include tests that have layouts, and tests that are not demos
      tests = tests.filter(
        test => test.get('hasLayout') && test.get('testId').substr(0, 5) !== 'demo-'
      );
      testIds = tests.keySeq().toJS();
    }

    return {
      testIds
    };
  }
);

// TODO: Refactor so that availableTestsSelector only returns availableTests
// which would make 'testEnabled' selector neater
export const availableTestsSelector = createSelector(
  testsStateSelector,
  createParamSelector('reportId'), // create a new selector instead of importing from reportSelectors because of circular imports :/
  (tests, reportId) => {
    reportId = parseInt(reportId, 10) || 'global';
    return {
      availableTests: tests.getIn(['availableTests', reportId], {}),
      isLoading: tests.get('isFetchingAvailableTests', true)
    };
  }
);

export const testEnabled = createSelector(
  testIdSelector,
  availableTestsSelector,
  (testId = 'website', availableTests) =>
    _.get(availableTests, ['availableTests', testId, 'isEnabled'], true)
);

export const showAlertsFeature = createSelector(
  testsSelector,
  tests => tests.has('timeline-screen')
);
