import {put, select, takeEvery} from 'redux-saga/effects';
import {evalInstruction} from 'utils/instructionUtils';
import {DO_INSTRUCTION, doCommand} from 'modules/instructions';
// import {queryParamsSelector} from 'modules/location/locationSelectors';

export default function* doActionSaga() {
  yield takeEvery(DO_INSTRUCTION, doInstruction);
}

function* doInstruction({expression, context, filters, event}) {
  // const query = yield select(queryParamsSelector) || {};

  // transform the expression into a command
  const command = evalInstruction(expression, context, filters);

  // command no longer needs `context` or `filters`, however, the sagas that
  // `command` will invoke may require access to them, so we pass them along anyway
  yield put(doCommand(command, context, filters, event));
}
