import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {List} from 'immutable';
import Overlay from 'components/overlay';
import Spotlight from 'modules/spotlight/components/spotlight';
import {spotlightSearch, clearResults, showSpotlight, hideSpotlight} from 'modules/spotlight';
import {spotlightSelector} from 'modules/spotlight/spotlightSelectors';
import {switchAccountIfAllowed} from 'modules/auth';
import {homePath} from 'modules/app/appUtils';
import {profilePath} from 'modules/profile/profileUtils';
import {push} from 'modules/location';
import {receiveAccount} from 'modules/accounts';
import {usersPath} from 'modules/users/userUtils';
import {clearUsers} from 'modules/users';
import styles from './spotlight.scss';

export class SpotlightContainer extends React.Component {
  static defaultProps = {
    isOpen: false,
    isSearching: false,
    results: List()
  };

  handleSelectResult = ({type, label, key, data} = {}) => {
    switch (type) {
      case 'account':
        this.props.receiveAccount(data);
        this.props.switchAccountIfAllowed(data.accountId);
        this.props.push(homePath, {accountId: data.accountId});
        break;

      case 'user':
        this.props.clearUsers(data);
        this.props.push(usersPath, data);
        // this.props.push(profilePath, data);
        break;
    }

    if (type) this.handleClose();
  };

  handleSearch = searchText => {
    this.props.spotlightSearch(searchText);
  };

  handleClose = () => {
    this.props.hideSpotlight();
    this.props.clearResults();
  };

  render() {
    const {results, isOpen, isSearching} = this.props;

    if (!isOpen) return null;

    return (
      <Overlay subtle center showClose showCloseScrollLimit={-1} onClickClose={this.handleClose}>
        <div className={styles.spotlightContainer}>
          <Spotlight
            results={results.toJS()}
            isSearching={isSearching}
            onSearch={this.handleSearch}
            onSelectResult={this.handleSelectResult}
          />
        </div>
      </Overlay>
    );
  }
}

export default connect(
  spotlightSelector,
  {
    spotlightSearch,
    clearResults,
    switchAccountIfAllowed,
    receiveAccount,
    hideSpotlight,
    push,
    clearUsers
  }
)(SpotlightContainer);
