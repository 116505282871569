import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {userParamSelector} from 'modules/auth/authSelectors';
import {Form, Field} from 'modules/form/components';
import styles from 'modules/auth/components/authWrapperShared.scss';
import Button from 'components/button';
import ErrorMessage from 'modules/form/components/errorMessage';

export class ResetPasswordForm extends React.Component {
  static defaultProps = {
    onSubmit: () => null,
    onValidSubmit: () => null
  };

  state = {
    isValid: false,
    showConfirm: false,
    passwordMatches: true, // hide message initially
    blurConfirm: false
  };

  handleChange = (key, value, data) => {
    const [isValid, messages] = this.form.isFormValid();
    const passwordMatches = data.confirmPassword && data.password === data.confirmPassword;

    this.setState({
      passwordMatches,
      isValid: isValid && passwordMatches,
      showConfirm: isValid
    });
  };

  handleSubmit = (data, isValid) => {
    if (!isValid) {
      this.props.onSubmit(data, false);
      return;
    }

    // ensure password matches.
    if (!data.confirmPassword || data.password !== data.confirmPassword) {
      this.setState({passwordMatches: false});
      this.props.onSubmit(data, false);
      return;
    }
    this.setState({passwordMatches: true});

    this.props.onSubmit(data, isValid);
    this.props.onValidSubmit(data);
  };

  setForm = ref => {
    this.form = ref;
  };

  handleBlur = () => {
    if (!this.state.blurConfirm) {
      this.setState({blurConfirm: true});
    }
  };

  render() {
    return (
      <Form
        autoComplete="off"
        ref={this.setForm}
        {...this.props}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        disableValidSubmit
      >
        {/*
          This is for the autosave password feature in browsers.
          If we don't do this, the browser tries to save the password without a username, weird.
        */}
        <input type="text" name="email" defaultValue={this.props.user.get('email')} hidden />

        <Field
          label="Your new password"
          type="passwordStrength"
          name="password"
          placeholder="Password"
          className={styles.textInput}
          autoComplete="new-password"
          required
          autoFocus
        />
        {this.state.showConfirm && (
          <Field
            label="Confirm new password"
            type="password"
            name="confirmPassword"
            placeholder="Confirm password"
            className={styles.textInput}
            autoComplete="confirm"
            onBlur={this.handleBlur}
            onChange={this.handleBlur}
            invalid={this.state.blurConfirm && !this.state.isValid}
            validationMessage={
              this.state.blurConfirm && !this.state.passwordMatches
                ? 'Your passwords do not match'
                : null
            }
          />
        )}

        <ErrorMessage message={this.props.errorMessage} />

        <div className={styles.buttonContainer}>
          <Button disabled={!this.state.isValid} type="submit" className={styles.submit}>
            Confirm password
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(
  createStructuredSelector({
    user: userParamSelector
  })
)(ResetPasswordForm);
