exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11RVeEzbwKffj0qFtstzlV{display:table;table-layout:fixed}.xKKWsIlLBMxrvnQFmQQVF{vertical-align:middle;cursor:pointer;margin-right:10px;opacity:.5;font-size:.6em}.xKKWsIlLBMxrvnQFmQQVF:hover{opacity:1}", ""]);

// exports
exports.locals = {
	"wrapper": "_11RVeEzbwKffj0qFtstzlV",
	"close": "xKKWsIlLBMxrvnQFmQQVF"
};