import React from 'react';
import {useDispatch, useSelector} from 'hooks/redux';
import {fetchUsers} from 'modules/users/users';
import {userOptionsSelector} from 'modules/users/userSelectors';
import Select from 'react-select';

export default function UserSelector({value, onChange}) {
  const dispatch = useDispatch();
  const userOptions = useSelector(userOptionsSelector);
  const [userId, setUserId] = React.useState(value || null);

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  React.useEffect(() => {
    setUserId(value);
    onChange(value);
  }, [value]);

  return (
    <Select
      placeholder="Filter user"
      value={userId}
      options={userOptions.toJS()}
      onChange={option => {
        const selectedUserId = !option ? null : option.value;
        setUserId(selectedUserId);
        onChange(selectedUserId);
      }}
    />
  );
}
