import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import CountryFlag from 'components/countryFlag';
import styles from './languages.scss';
import Pill from 'components/pill';
import Button from 'components/button';
import Flex from 'components/layout/flex';
import {languageSettingsPath} from 'modules/reports/reportUtils';
import {push} from 'modules/location';
import {ReportGandalf} from 'components/gandalf';
import {ROLE_MANAGER} from 'modules/gandalf/gandalfUtils';
import {isPrintMode} from 'modules/filters/filterUtils';
import {filterSelector} from 'modules/filters/filterSelectors';
import {userSelector} from 'modules/auth/authSelectors';
import {showUpgradeModal} from 'modules/onboarding';
import {isArchiveActiveSelector} from 'modules/archives/archiveSelectors';

export class Languages extends React.Component {
  static propTypes = {
    result: PropTypes.array,
    limit: PropTypes.number
  };

  static defaultProps = {
    result: [],
    limit: 3
  };

  handleButtonClick = () => {
    this.props.push(languageSettingsPath, this.props.params);
  };

  handleVirtual = () => {
    this.props.showUpgradeModal();
  };

  renderLanguage = languageCode => {
    return <CountryFlag code={languageCode} key={languageCode} showTitle />;
  };

  render() {
    const {result, limit, filter, isArchiveReport} = this.props;
    const {reportId} = this.props.params;
    const isLimited = result.length > limit;

    return (
      <div className={styles.languages}>
        <Flex container>
          <Flex>
            {result.slice(0, limit).map(this.renderLanguage)}
            {isLimited && `+ ${result.length - limit} more`}
          </Flex>
          {!this.props.isVirtual && (
            <ReportGandalf shallPass={ROLE_MANAGER} reportId={reportId}>
              {!isPrintMode(filter) && !isArchiveReport && (
                <Flex alignRight>
                  <Button className={styles.button} onClick={this.handleButtonClick}>
                    Change languages
                  </Button>
                </Flex>
              )}
            </ReportGandalf>
          )}
        </Flex>
      </div>
    );
  }
}

export default connect(
  state => {
    const user = userSelector(state);
    return {
      filter: filterSelector(state).filter,
      isVirtual: user && !!user.get('virtualType'),
      isArchiveReport: isArchiveActiveSelector(state)
    };
  },
  {push, showUpgradeModal}
)(Languages);
