import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Button from 'components/button';
import Buttons from 'components/buttons';
import {reportPath} from 'modules/reports/reportUtils';
import ModalContent from 'modules/modal/components/modalContent';
import {receiveExtraInfo} from 'modules/reports';
import ExternalLink from 'modules/location/externalLink';

export class ReportExistsModal extends React.Component {
  handleAdd = () => {
    this.props.receiveExtraInfo({ignoreExistingReports: true});
  };

  handleRedirect = () => {
    const {reportId} = this.props;
    this.props.receiveExtraInfo(); // closes the modal
    this.props.push(reportPath, {reportId});
  };

  render() {
    const {url} = this.props;

    return (
      <ModalContent
        title="Website already exists"
        footer={
          <Buttons>
            <Button onClick={this.handleAdd}>Add it anyway</Button>
            <Button cta onClick={this.handleRedirect}>
              Go to website
            </Button>
          </Buttons>
        }
      >
        The website <ExternalLink href={url}>{url}</ExternalLink> already exists. Do you want to add
        it anyway?
      </ModalContent>
    );
  }
}

export default connect(
  null,
  {push, receiveExtraInfo}
)(ReportExistsModal);
