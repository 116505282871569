import React from 'react';
import {connect} from 'react-redux';
import {push} from 'modules/location';
import Button from 'components/button';
import {forcedPagesPath} from 'modules/reports/reportUtils';

const ForcedPageButton = ({children, ...props}) => {
  return <Button {...props}>{children}</Button>;
};

export default connect(
  null,
  (dispatch, props) => ({
    onClick: event => {
      if (props.key === 'delete') {
        dispatch(
          push(forcedPagesPath, {forcedPageId: props.forcedPageId}, ['reportId'], false, {}, event)
        );
      } else {
        dispatch(
          push(forcedPagesPath, {forcedPageId: props.forcedPageId}, ['reportId'], false, {}, event)
        );
      }
    }
  })
)(ForcedPageButton);
