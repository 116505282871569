import React from 'react';
import cx from 'classnames';
import styles from './mediaIssue.scss';
import AnimateOnChange from 'components/animation/animateOnChange';
import {interpolateToJSX} from 'utils/interpolationUtils';

class PageIssue extends React.Component {
  shouldAnimate = (nextProps, currentProps) => {
    return nextProps.isOpen && !currentProps.isOpen;
  };

  render() {
    const {issueData, isOpen} = this.props;

    const text = issueData.get('text') ? interpolateToJSX(issueData.get('text'), issueData) : '';

    const classes = cx(styles.wrapper, {
      [styles.open]: isOpen
    });

    return (
      <div className={classes}>
        <div>
          <AnimateOnChange
            isOpen={isOpen}
            comparator={this.shouldAnimate}
            animationClassName="animated pulse"
          >
            This issue applies to the whole page
          </AnimateOnChange>
          {text && <p>{text}</p>}
        </div>
      </div>
    );
  }
}

export default PageIssue;
