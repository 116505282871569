import _ from 'lodash';
import React from 'react';
import UserPermissionSummary from './userPermissionSummary';
import SettingItem from 'components/settings/settingItem';
import {ACCOUNT_OWNER} from 'modules/auth/gandalf';
import {ROLE_MANAGER} from 'modules/gandalf/gandalfUtils';
import {Map} from 'immutable';

export default class UserPermissionsSummaryViewer extends React.Component {
  editUserRole = (userGroupId, permissionId, role) => {
    const {reportId} = this.props;
    this.props.setRole(reportId, userGroupId, role);
  };

  removeUser = (userGroupId, permissionId) => {
    const {reportId} = this.props;
    this.props.removeRole(reportId, userGroupId, permissionId);
  };

  renderUsersWithPermissions() {
    const {users, reportPermissions, currentUserId, reportId} = this.props;

    return _.reduce(
      users,
      (output, user) => {
        let permission;
        if (user.role === ACCOUNT_OWNER) {
          // If the user is an account owner add manager permissions manually (nothing is returned from the backend)
          // so that they are displayed in the UI. permissionId of 'owner' can be any arbitrary string - it is not sent to the backend.
          permission = Map({permissionId: 'owner', role: ROLE_MANAGER});
        } else {
          permission = reportPermissions.get(user.userId.toString());
        }

        if (!permission) return output;

        const permissionId = permission.get('permissionId');

        output.push(
          <UserPermissionSummary
            key={`user-${user.userId}-${permissionId}`}
            permissionId={permissionId}
            role={permission.get('role')}
            reportId={reportId}
            isCurrentUser={user.userId == currentUserId}
            user={user}
            onRemoveUser={this.removeUser}
            onEditUserRole={this.editUserRole}
          />
        );
        return output;
      },
      []
    );
  }

  render() {
    const {reportPermissions} = this.props;

    if (!reportPermissions.size) {
      return null;
    }

    return (
      <div>
        <SettingItem
          title="Current permissions"
          description="Who has permission to manage, edit and view this website"
        >
          {this.renderUsersWithPermissions()}
        </SettingItem>
      </div>
    );
  }
}
