import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from './datePicker';
import moment from 'moment';
import DateSuggestions from './dateSuggestions';
import Button from 'components/button';
import filterInvalidDOMProps from 'filter-invalid-dom-props';

const initialState = {
  date: 0, // forever
  calendarMode: false
};

export default class SuggestionDatePicker extends React.Component {
  static defaultProps = {
    dateFormat: 'YYYY-M-D'
  };

  state = initialState;

  // public API
  getDate() {
    return this.state.date;
  }

  componentWillMount() {
    this.props.onChange(this.state.date);
  }

  handleSelectSuggestion = suggestion => {
    if (suggestion !== 'custom') {
      this.handleSelectDate(suggestion);
      return;
    }

    const minDate = this.getDefaultDate();

    this.setState({
      date: minDate,
      calendarMode: true
    });

    this.props.onChange(minDate);
  };

  handleSelectDate = date => {
    this.setState({date});

    this.props.onChange(date);
  };

  showSuggestions = () => {
    this.setState(initialState);

    this.props.onChange(initialState.date);
  };

  getDefaultDate() {
    const now = moment();
    const date = !this.props.future ? now : now.add(1, 'd');

    return this.getFormattedDate(date);
  }

  getFormattedDate(date) {
    return date.format(this.props.dateFormat);
  }

  renderDatePicker() {
    return (
      <div>
        <Button onClick={this.showSuggestions}>Back</Button>
        <DatePicker minDate={this.getDefaultDate()} onChange={this.handleSelectDate} />
      </div>
    );
  }

  renderSuggestions() {
    return (
      <DateSuggestions
        selectedValue={this.state.date}
        onClick={this.handleSelectSuggestion}
        future={this.props.future}
      />
    );
  }

  render() {
    return (
      <div {...filterInvalidDOMProps(this.props)}>
        {this.state.calendarMode ? this.renderDatePicker() : this.renderSuggestions()}
      </div>
    );
  }
}
