exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".qXxKM8-YLgstvIEP1WlTv{max-width:640px;margin:0 auto}.qXxKM8-YLgstvIEP1WlTv p{line-height:24px}.qXxKM8-YLgstvIEP1WlTv._1gvvVWvzg-0beovke9Oyoo{margin:0}._2hbCKv_GeukLp6NUbMwGgG{margin:30px 0}._2hbCKv_GeukLp6NUbMwGgG h3{font-size:22px;margin:30px 0 20px}._2hbCKv_GeukLp6NUbMwGgG :first-child{margin-top:0}._1XSPtTJNGhakl9FgArTuJu h1{margin:30px 0 20px}._1XSPtTJNGhakl9FgArTuJu h1:first-child{margin-top:0}._2b3pg4Fgab2JGo8PzaYNz9{display:flex;justify-content:center;text-align:center}._2b3pg4Fgab2JGo8PzaYNz9 button{min-width:120px}._1E7Edtqnrlb53Q_j4GJeRh{margin-top:20px}.jAhlOiW96aCLxntFitkdb{width:auto;height:auto;box-shadow:0 0 20px rgba(0,0,0,.5)}._2sIW-88ZQRCA99GNQjwWGQ{text-align:center;padding:15px;margin-bottom:20px;font-size:120%}._2sIW-88ZQRCA99GNQjwWGQ p{margin-bottom:0}._2sIW-88ZQRCA99GNQjwWGQ p a{color:#b0c0f0;text-decoration:underline;-webkit-text-decoration-color:rgba(176,192,240,.5);text-decoration-color:rgba(176,192,240,.5)}.HQYcPCbzgFWiLhiq2OcKQ{background:#000030;text-align:center;padding:15px;margin-bottom:40px}.HQYcPCbzgFWiLhiq2OcKQ p{margin-bottom:0}.HQYcPCbzgFWiLhiq2OcKQ p a{color:#b0c0f0;text-decoration:underline;-webkit-text-decoration-color:rgba(176,192,240,.5);text-decoration-color:rgba(176,192,240,.5)}._3nsYGphOg1a-dPzHrJ71Eg{padding-top:56.25%;position:relative;border:0;background:#000030}@media screen and (max-width: 767px){._3nsYGphOg1a-dPzHrJ71Eg{max-height:10vw}}._3nsYGphOg1a-dPzHrJ71Eg>div{position:absolute;top:0}._2YVVIN5bpzA4qXZ3VFupRC{padding-top:30px}._2YVVIN5bpzA4qXZ3VFupRC h3{font-size:22px;margin-bottom:20px;text-align:left}._24nWXrVmXI0lv9GjvgT3k_{text-align:center}", ""]);

// exports
exports.locals = {
	"readingArea": "qXxKM8-YLgstvIEP1WlTv",
	"readingAreaLeft": "_1gvvVWvzg-0beovke9Oyoo",
	"captions": "_2hbCKv_GeukLp6NUbMwGgG",
	"markdown": "_1XSPtTJNGhakl9FgArTuJu",
	"buttons": "_2b3pg4Fgab2JGo8PzaYNz9",
	"marginTop": "_1E7Edtqnrlb53Q_j4GJeRh",
	"playerWrapper": "jAhlOiW96aCLxntFitkdb",
	"videoIntro": "_2sIW-88ZQRCA99GNQjwWGQ",
	"videoCredit": "HQYcPCbzgFWiLhiq2OcKQ",
	"reactPlayer": "_3nsYGphOg1a-dPzHrJ71Eg",
	"quizArea": "_2YVVIN5bpzA4qXZ3VFupRC",
	"prompt": "_24nWXrVmXI0lv9GjvgT3k_"
};