import React, {Children} from 'react';
import cx from 'classnames';
import styles from './tabs.scss';

export default class Tabs extends React.Component {
  render() {
    const {className, hideBorder, children, dark} = this.props;

    const classes = cx(styles.tabs, className, {
      [styles.dark]: dark,
      [styles.hideBorder]: hideBorder
    });

    return (
      <nav className={classes}>
        <ul role="tablist" className={styles.ul}>
          {children}
        </ul>
      </nav>
    );
  }
}
