import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './onboardingWrapper.scss';

export default class OnboardingFormWrapper extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    intro: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  };

  render() {
    const {title, intro, children} = this.props;

    return (
      <div>
        <h1>{title}</h1>
        {intro && <p className={styles.intro}>{intro}</p>}

        {children}
      </div>
    );
  }
}
