import React from 'react';
import {connect} from 'react-redux';
import {removeMessage} from 'modules/alerts';
import {alertsContainerSelector} from 'modules/alerts/alertSelectors';
import Alert from './alert';
import AlertsWrapper from './alertsWrapper';
import {undoDecision} from 'modules/decisions/decisions';
import DecisionAlert from 'modules/decisions/components/decisionAlert';
import DecisionAlerts from 'modules/decisions/components/decisionAlerts';

export class AlertsContainer extends React.Component {
  handleClickAlert = messageId => e => {
    e.stopPropagation();
    this.props.removeMessage(messageId);
  };

  handleClickUndo = (decision, messageId) => e => {
    e.stopPropagation();
    this.props.undoDecision(this.props.params.reportId, decision.decisionId, decision.context);
    this.props.removeMessage(messageId);
  };

  renderAlert = (message, id) => {
    const {content, severity} = message;

    return (
      <Alert key={id} severity={severity} onClick={this.handleClickAlert(id)}>
        {content}
      </Alert>
    );
  };

  renderDecision = (decision, messageId) => {
    return (
      <DecisionAlert
        key={messageId}
        decision={decision}
        onClose={this.handleClickAlert(messageId)}
        onUndo={this.handleClickUndo(decision, messageId)}
      />
    );
  };

  render() {
    const {alerts, decision, decisionId} = this.props;

    if (alerts.isEmpty() && !decision) return null;

    return (
      <div>
        {!alerts.isEmpty() && (
          <AlertsWrapper>{alerts.map(this.renderAlert).toList()}</AlertsWrapper>
        )}
        {decision && <DecisionAlerts>{this.renderDecision(decision, decisionId)}</DecisionAlerts>}
      </div>
    );
  }
}

export default connect(
  alertsContainerSelector,
  {removeMessage, undoDecision}
)(AlertsContainer);
