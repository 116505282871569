exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1aj78M5-ZdFHXUJLSilNVs{display:flex;height:100%;width:100%;position:relative;overflow:hidden}._3zzIxOrTroOPRhFCd921iV{flex-direction:row}._3ls9BViipKuLGOv1ZUt7cj{flex-direction:column}.yciiAn-2OBxmTGW6cr1qi{flex:1 1 auto;overflow-x:hidden;overflow-y:auto;height:100%;-webkit-overflow-scrolling:touch}._2BjyekAnO7m10GzeTPsfTE{overflow-y:hidden}", ""]);

// exports
exports.locals = {
	"container": "_1aj78M5-ZdFHXUJLSilNVs",
	"horizontal": "_3zzIxOrTroOPRhFCd921iV",
	"vertical": "_3ls9BViipKuLGOv1ZUt7cj",
	"page": "yciiAn-2OBxmTGW6cr1qi",
	"noOverflow": "_2BjyekAnO7m10GzeTPsfTE"
};