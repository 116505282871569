import React from 'react';
import {delay} from 'redux-saga';
import {put, takeLatest, select} from 'redux-saga/effects';
import {subscriptionsEndpoint} from 'modules/subscriptions/subscriptionUtils';
import {REQUEST_DELETE_SUBSCRIPTION, updateSubscription} from 'modules/subscriptions';
import {showError} from 'utils/saga/alerts';
import {userIdSelector} from 'modules/auth/authSelectors';
import {subscriptionsSelector} from 'modules/subscriptions/subscriptionSelectors';
import {couldNot} from 'utils/errorUtils';
import ModalSpinner from 'components/spinner/modalSpinner';
import {showInModal, hideModal} from 'modules/modal/modal';
import confirm from 'utils/saga/confirm';
import {processRequest} from 'utils/saga/fetchUtils';
import FormattedDate from 'components/time/formattedDate';
import {setNPSEnable} from 'modules/nps';

export default function* deleteSubscriptionSaga() {
  yield takeLatest(REQUEST_DELETE_SUBSCRIPTION, deleteSubscription);
}

function* deleteSubscription({subscriptionId, planName, currentPeriodEnd, accountId}) {
  const confirmed = yield* confirm({
    title: `Cancel subscription`,
    content: (
      <div>
        <p>
          This will cancel your subscription to <strong>{planName}</strong>.
        </p>
        <p>
          We won’t charge you for this subscription again. You’ll be able to continue using this
          subscription until <FormattedDate timestamp={currentPeriodEnd} format="Do MMM YYYY" />.{' '}
          After then, your data will be deleted.
        </p>
      </div>
    ),
    submitLabel: 'Yes, I want to cancel this subscription',
    cancelLabel: 'No, keep this subscription',
    destructiveConfirm: true
  });

  if (!confirmed) return;

  const userId = yield select(userIdSelector);

  yield delay(1);
  yield put(showInModal(ModalSpinner));

  yield processRequest('DELETE', subscriptionsEndpoint({subscriptionId}), {
    success: function*(responseData) {
      yield put(updateSubscription(subscriptionId, responseData));
      yield put(hideModal());

      const subscriptions = yield select(subscriptionsSelector);
      const hasActiveSubscription =
        subscriptions &&
        subscriptions.some(item => {
          return !item.get('canceledAt');
        });

      if (!hasActiveSubscription) {
        window.location.href = `https://silktide.typeform.com/to/DTD3W1?uid=${userId}&aid=${accountId}`;
      } else {
        yield put(setNPSEnable(true, 'cancel'));
      }
    },
    error: function*(err) {
      yield put(hideModal());
      yield showError(couldNot('cancel subscription'));
    }
  });
}
