import PropTypes from 'prop-types';
import React from 'react';
import mobile from './mobile-icon.png';
import desktop from './desktop-icon.png';
import both from './both-icon.png';

class Device extends React.Component {
  renderDevice() {
    const style = {height: 21};
    const device = this.props.data;

    if (device === 'Mobile' || device === 'iPhone5Portrait') {
      return <img src={mobile} alt="Mobile" style={style} />;
    }

    if (device === 'Desktop') {
      return <img src={desktop} alt="Desktop" style={style} />;
    }

    if (device === 'Both' || !device) {
      return <img src={both} alt="Desktop + Mobile" style={style} />;
    }

    return <span />;
  }

  render() {
    const divStyle = {display: 'inline-block', textAlign: 'center'};

    // if (this.props.width) {
    divStyle.width = '100%';
    // }

    return <div style={divStyle}>{this.renderDevice()}</div>;
  }
}

export default Device;
